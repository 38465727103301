import styles from "./AudienceBudget.module.scss";
import { Button } from "src/components/Button/Button";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { AudienceBudgetLoader } from "./AudienceBudgetLoader";

export function AudienceBudget() {
  const { values, handleSubmit } = useFormikContext<ActivityInputV2>();
  const adsets = values.Adsets ?? [];

  return (
    <div className={styles.section}>
      <h2 className={styles.heading}>Allocating Budget</h2>
      <h3 className={styles.subheading}>
        Your ad set budget is the daily or lifetime amount that you want to
        spend on this ad set.
      </h3>

      <div className={styles.cardList}>
        <AudienceBudgetLoader adsets={adsets} />
      </div>

      <div className={styles.actions}>
        <Button onClick={handleSubmit} size="micro">
          Save & Continue
        </Button>
      </div>
    </div>
  );
}
