import instagramIcon from "../assets/instagram.svg";
import websiteViewsIcon from "../assets/websiteViews.svg";
import postPromotionIcon from "../assets/postPromotion.svg";
import { SelectOption } from "./SelectOption";

export function TrafficAndAwarenessOptions() {
  return (
    <>
      {/* <SelectOption */}
      {/*   value="INSTAGRAM_PROFILE_VIEWS" */}
      {/*   icon={ */}
      {/*     <img */}
      {/*       src={instagramIcon} */}
      {/*       alt="instagram logo" */}
      {/*       width={32} */}
      {/*       height={32} */}
      {/*     /> */}
      {/*   } */}
      {/*   label="Instagram profile visits" */}
      {/* /> */}
      <SelectOption
        value="PROMOTE_POST"
        icon={
          <img
            src={postPromotionIcon}
            alt="post views"
            width={32}
            height={32}
          />
        }
        label="Make more people view your post"
      />
      <SelectOption
        value="WEBSITE_VIEWS"
        icon={
          <img
            src={websiteViewsIcon}
            alt="website views"
            width={32}
            height={32}
          />
        }
        label="Make more people visit your website"
      />
    </>
  );
}
