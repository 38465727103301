import { getIn, useFormikContext } from "formik";
import Card from "src/components/Card/Card";
import ComboBox from "src/components/ComboBox/ComboBox";
import { Label } from "src/components/Label/Label";
import { useAudiencesQuery } from "src/graphql/generated/schema";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import { AudienceInput } from "./CreateAudienceInput";
import styles from "./CreateLookalikeAudienceForm.module.scss";
import Select from "src/components/Select/Select";
import countries from "src/countries.json";
import * as Slider from "@radix-ui/react-slider";
import Notice from "src/modules/global/components/Notice";
import classNames from "classnames";
import { Button } from "src/components/Button/Button";
import { FacebookAccountSettings } from "src/components/AudienceCreate/PlatformBasedAudienceForms/FacebookAccountSettings";
import { InputFormik } from "src/components/InputFormik/InputFormik";

interface ICreateLookalikeAudienceForm {
  selectedAudienceIndex: number;
  activeStep: number;
  setActiveStep: (i: number) => void;
}

const countryCodeOptions = countries.map((country) => ({
  value: country["alpha-2"],
  label: country.name,
}));

export function CreateLookalikeAudienceForm({
  selectedAudienceIndex,
  activeStep,
  setActiveStep,
}: ICreateLookalikeAudienceForm) {
  const { values, setFieldValue, isSubmitting, isValid, handleSubmit } =
    useFormikContext<AudienceInput>();

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const audienceValuesKey = `audiences[${selectedAudienceIndex}].values`;
  const audienceValues: AudienceInput["audiences"][0]["values"] = getIn(
    values,
    audienceValuesKey
  );
  return (
    <Card title="Create a lookalike audience">
      <InputFormik
        name={`audiences[${selectedAudienceIndex}].audienceName`}
        label={"Audience Name"}
        width="auto"
        placeholder="Name of the audience"
      />
      <FacebookAccountSettings
        audienceValues={audienceValues}
        setFieldValue={setFieldValue}
        audienceValuesKey={audienceValuesKey}
        isLookalike={true}
      />
      <div className={styles.fieldContainer}>
        <Label label="Source of your lookalike" />
        <AudiencesSearchComboBox
          selected={audienceValues?.seedAudienceId}
          setSelected={(v: string) => {
            setFieldValue(`${audienceValuesKey}.seedAudienceId`, v);
          }}
        />
      </div>

      <div className={styles.fieldContainer}>
        <Label label="Source of your lookalike" />
        <Select
          options={countryCodeOptions}
          value={audienceValues?.countries || []}
          multiple={true}
          onChange={(value) => {
            console.log(value);
            setFieldValue(`${audienceValuesKey}.countries`, value);
          }}
          placeholder="Select Country"
        />
      </div>

      <div className={styles.fieldContainer}>
        <Label label={"Select audience size"} />
        <Slider.Root
          className={styles.SliderRoot}
          defaultValue={[audienceValues?.percentage || 0]}
          onValueChange={(values) => {
            setFieldValue(`${audienceValuesKey}.percentage`, values[0]);
          }}
          max={10}
          step={1}
          minStepsBetweenThumbs={1}
        >
          <Slider.Track className={styles.SliderTrack}>
            <Slider.Range className={styles.SliderRange} />
            <ul className={styles.SliderStepLabels}>
              {new Array(11).fill("").map((_, i) => (
                <li className={styles.SliderStepLabel}>{i}%</li>
              ))}
            </ul>
          </Slider.Track>
          <Slider.Thumb className={styles.SliderThumb} />
        </Slider.Root>
      </div>
      <div className={styles.noticeContainer}>
        <Notice
          color={"surface"}
          content={
            <div>
              A 1% lookalike consists of the Accounts Centre accounts most
              similar to your Lookalike Audience source. Increasing the
              percentage creates a bigger, broader audience.
            </div>
          }
        />
      </div>

      <div className={classNames(styles.fieldContainer, styles.btnGroup)}>
        <Button
          color="primary"
          type="button"
          disabled={!isValid}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Save and continue
        </Button>
        <Button
          // eslint-disable-next-line react/style-prop-object
          style="plain"
          color="subdued"
          type="button"
          disabled={isSubmitting}
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}

function AudiencesSearchComboBox({ selected, setSelected }) {
  const { data, loading, refetch } = useAudiencesQuery({
    variables: {
      filter: {
        platform: "META",
        name: "",
        take: 5,
      },
      sortBy: {
        direction: "ASC",
        field: "NAME",
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const debouncedSearch = useDebouncedCallback(async (value: string) => {
    await refetch({
      filter: {
        platform: "META",
        name: value,
        take: 5,
      },
    });
  }, 500);

  const options =
    data && data.audiences
      ? data.audiences.edges.map((v) => ({
          label: v.node.name,
          value: v.node.platformAudienceId,
        }))
      : [];

  return (
    <div className={styles.audienceSearchComboBoxContainer}>
      <ComboBox
        multiple={false}
        selected={selected}
        setSelected={setSelected}
        onChange={(v) => debouncedSearch(v)}
        loading={loading}
        options={options}
        placeholder={selected ? selected : "Search existing audience data"}
      />
    </div>
  );
}
