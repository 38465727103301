import EmptyState from "src/components/EmptyState/EmptyState";
import styles from "./SingleCustomerActivity.module.scss";
import { SingleCustomerActivityItem } from "./SingleCustomerActivityItem";
import emptyCustomers from "../assets/emptyCustomers.png";

interface ISingleCustomerActivity {
  outreaches: Array<{ name: string; id: string; createdAt: string }>;
  dateFrom: string;
  dateTo: string;
}

export function SingleCustomerActivity({
  outreaches,
  dateFrom,
  dateTo,
}: ISingleCustomerActivity) {
  if (outreaches.length === 0) {
    return (
      <div className={styles.emptyStateContainer}>
        <EmptyState
          title={"No Activities Found"}
          illustration={emptyCustomers}
        />
      </div>
    );
  }
  return (
    <div>
      <h2 className={styles.heading}>List of activities in last 7 days</h2>
      <div className={styles.customerActivity}>
        {outreaches.map((item, index) => (
          <SingleCustomerActivityItem
            dateFrom={dateFrom}
            dateTo={dateTo}
            key={index}
            outreach={item}
          />
        ))}
        {/* <CustomerEmptyPage /> */}
      </div>
    </div>
  );
}
