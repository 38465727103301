import sub from "date-fns/sub";

export type DateFilter =
  | "YESTERDAY"
  | "TODAY"
  | "LAST_7_DAYS"
  | "LAST_28_DAYS"
  | "LAST_3_MONTHS"
  | "LAST_6_MONTHS"
  | "LAST_YEAR"
  | "ALL_TIME";

interface DateFilterGraphql {
  gte?: any;
  lte?: any;
}

export function getDateFilterFromFilterEnum(
  filterString: DateFilter
): DateFilterGraphql {
  switch (filterString) {
    case "ALL_TIME":
      return null;
    case "LAST_7_DAYS":
      return {
        lte: new Date(),
        gte: sub(new Date(), { days: 7 }),
      };
    case "LAST_28_DAYS":
      return {
        lte: new Date(),
        gte: sub(new Date(), { days: 28 }),
      };
    case "LAST_3_MONTHS":
      return {
        lte: new Date(),
        gte: sub(new Date(), {
          months: 3,
        }),
      };
    case "LAST_6_MONTHS":
      return {
        lte: new Date(),
        gte: sub(new Date(), {
          months: 6,
        }),
      };
    case "LAST_YEAR":
      return {
        lte: new Date(),
        gte: sub(new Date(), {
          years: 1,
        }),
      };
    case "YESTERDAY":
      return {
        lte: new Date(),
        gte: sub(new Date(), {
          days: 1,
        }),
      };
    case "TODAY":
      const date = new Date();
      const startDate = new Date();
      startDate.setHours(0, 0, 0);
      return {
        lte: date,
        gte: startDate,
      };
    default:
      return null;
  }
}
