import { AdInput, CampaignInput } from "../misc/createCampaignTypes";
import { getIn, useFormikContext } from "formik";
import { IBanner } from "src/components/Banner/Banner";
import staticImageOrVideo from "../assets/staticAsset.svg";
import carousel from "../assets/carousel.svg";
import { TabInput } from "./TabInput";

const adTypeTabs = [
  {
    image: { src: staticImageOrVideo, size: 48 },
    label: "Single Image or Video",
    value: "STATIC_IMAGE_OR_VIDEO",
  },
  {
    image: { src: carousel, size: 48 },
    value: "CAROUSEL",
    label: "Carousel Ads",
  },
];

interface Props {
  name: string;
  adKey: string;
  currentAd: AdInput;
  setErrorBanner: (i: IBanner | null) => void;
}

export function AdManualTypeField({
  name,
  adKey,
  currentAd,
  setErrorBanner,
}: Props) {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<CampaignInput>();
  const value = getIn(values, name);

  function handleAdTypeChange(value: "CAROUSEL" | "STATIC_IMAGE_OR_VIDEO") {
    if (value === "STATIC_IMAGE_OR_VIDEO" && currentAd.LineItems.length > 1) {
      // alert( "Data for first carousel card will retain others will be reset");
      setErrorBanner({
        content:
          "Data for first carousel card will retain others will be reset",
        action: (
          <button
            type="button"
            style={{
              padding: "1rem 0",
            }}
            onClick={() => {
              setFieldValue(`${adKey}.LineItems`, [currentAd.LineItems[0]]);
              setFieldValue(name, value);
              setErrorBanner(null);
            }}
          >
            Ok
          </button>
        ),
        isOpen: true,
        onClose: () => {
          setErrorBanner(null);
        },
        type: "warning",
      });
      return;
    }
    setFieldValue(name, value);
  }

  return (
    <TabInput
      value={value}
      onBlur={() => setFieldTouched(name)}
      onChange={handleAdTypeChange}
      tabs={adTypeTabs}
    />
  );
}
