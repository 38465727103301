import * as Yup from "yup";

export type FACEBOOK_AUDIENCE_SOURCES =
  | "FACEBOOK_ENGAGERS"
  | "FACEBOOK_PIXEL_BASED"
  | "INSTAGRAM_ENGAGERS"
  | "FACEBOOK_SEGMENT_BASED";

export const audienceInput: AudienceInput = {
  audiences: [
    {
      audienceType: "PLATFORM_BASED",
      audienceName: "",
      audienceSource: "" as undefined,
      values: {} as any,
    },
  ],
};

const audienceVideosSchema = Yup.object({
  videoId: Yup.string(),
  watchTime: Yup.string(),
})
  .nullable()
  .notRequired();

const audienceValuesSchema = Yup.object({
  adAccountId: Yup.string().required(),
  enabledAccountId: Yup.string().required(),
  pixelId: Yup.string().notRequired(),
  metaPageId: Yup.string().notRequired(),
  instagramAccountId: Yup.string().notRequired(),
  countries: Yup.array(Yup.string()).notRequired(),
  duration: Yup.number().notRequired(),
  eventName: Yup.string().notRequired(),
  isValueBased: Yup.boolean().notRequired(),
  percentage: Yup.number().notRequired(),
  pixelEventName: Yup.string().notRequired(),
  seedAudienceId: Yup.string().notRequired(),
  segmentId: Yup.string().notRequired(),
  videos: audienceVideosSchema.notRequired().nullable(),
  platform: Yup.mixed<"INSTAGRAM" | "FACEBOOK">().oneOf([
    "FACEBOOK",
    "INSTAGRAM",
  ]),
}).notRequired();

export const createAudienceSchema = Yup.object({
  audiences: Yup.array(
    Yup.object({
      audienceName: Yup.string().required("Name is required field"),
      audienceType: Yup.string(),
      audienceSource: Yup.mixed<FACEBOOK_AUDIENCE_SOURCES>().oneOf([
        "FACEBOOK_ENGAGERS",
        "FACEBOOK_PIXEL_BASED",
        "INSTAGRAM_ENGAGERS",
        "FACEBOOK_SEGMENT_BASED",
      ] as FACEBOOK_AUDIENCE_SOURCES[]),
      values: audienceValuesSchema.typeError("values should be object"),
    })
  ).min(1),
});

export type AudienceInput = Yup.InferType<typeof createAudienceSchema>;

export type AudienceValues = Yup.InferType<typeof audienceValuesSchema>;

export const durationOptions = [
  {
    label: "30 days",
    value: 2592000,
  },
  {
    label: "90 days",
    value: 7776000,
  },
  {
    label: "180 days",
    value: 15552000,
  },
];

export const metaEventsOption = [
  {
    label: "Visit",
    value: "visit",
  },
  {
    label: "Add To Cart",
    value: "AddToCart",
  },
  {
    label: "Purchase",
    value: "Purchase",
  },
];

const websiteAudiences = [
  {
    description:
      "All the users who have visited your website in the last 30 days",
    title: "Recent Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_30",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 2592000,
    },
  },
  {
    description:
      "All the users who have added something to cart in the last 30 days",
    title: "Recent Add To Cart",
    id: "FACEBOOK_PIXEL_BASED_ATC_30",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "AddToCart",
      duration: 2592000,
    },
  },
  {
    description:
      "All the users who have visited your website in the past 3 months",
    title: "Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_90",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 7776000,
    },
  },
  {
    description:
      "All the users who have added something to cart in the past 3 months",
    title: "Add To Cart",
    id: "FACEBOOK_PIXEL_BASED_ATC_90",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "AddToCart",
      duration: 7776000,
    },
  },
  {
    description:
      "All the users who have visited your website in the past 6 months",
    title: "Long Term Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_180",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 15552000,
    },
  },
  {
    description:
      "All the users who have added something to cart in the past 6 months",
    title: "Long Term Add To Cart",
    id: "FACEBOOK_PIXEL_ATC_BASED_180",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 15552000,
    },
  },
];

const audienceTypes = [
  {
    description: "Similar people to a custom audience created on facebook",
    title: "Facebook Lookalike Audience",
    // titleMetadata: "Lookalike",
    id: "FACEBOOK_LOOKALIKE",
    type: "FACEBOOK_LOOKALIKE",
    icon: "/to-be-uploaded/Facebook.svg",
    initialValues: {
      seedAudienceId: "",
      seedAudienceName: "",
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have engaged with your facebook page in the last 30 days",
    title: "Recent Facebook Page Engagers",
    // titleMetadata: "Last 30 days",
    id: "FACEBOOK_ENGAGERS_30",
    type: "FACEBOOK_ENGAGERS",
    icon: "/to-be-uploaded/Facebook.svg",
    initialValues: {
      duration: 2592000,
    },
  },
  {
    description:
      "All the users who have engaged with your facebook page in the past 3 months",
    title: "Facebook Page Engagers",
    // titleMetadata: "Last 90 days",
    id: "FACEBOOK_ENGAGERS_90",
    type: "FACEBOOK_ENGAGERS",
    icon: "/to-be-uploaded/Facebook.svg",
    initialValues: {
      duration: 7776000,
    },
  },
  {
    description:
      "All the users who have engaged with your facebook page in the past 6 months",
    title: "Long Term Facebook Page Engagers",
    id: "FACEBOOK_ENGAGERS_180",
    type: "FACEBOOK_ENGAGERS",
    icon: "/to-be-uploaded/Facebook.svg",
    initialValues: {
      duration: 15552000,
    },
  },

  {
    description:
      "All the users who have visited your website in the last 30 days",
    title: "Recent Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_30",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 2592000,
    },
  },
  {
    description:
      "All the users who have added something to cart in the last 30 days",
    title: "Recent Add To Cart",
    id: "FACEBOOK_PIXEL_BASED_ATC_30",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "AddToCart",
      duration: 2592000,
    },
  },
  {
    description:
      "All the users who have visited your website in the past 3 months",
    title: "Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_90",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 7776000,
    },
  },
  {
    description:
      "All the users who have added something to cart in the past 3 months",
    title: "Add To Cart",
    id: "FACEBOOK_PIXEL_BASED_ATC_90",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "AddToCart",
      duration: 7776000,
    },
  },
  {
    description:
      "All the users who have visited your website in the past 6 months",
    title: "Long Term Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_180",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 15552000,
    },
  },
  {
    description:
      "All the users who have added something to cart in the past 6 months",
    title: "Long Term Add To Cart",
    id: "FACEBOOK_PIXEL_ATC_BASED_180",
    type: "FACEBOOK_PIXEL_BASED",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 15552000,
    },
  },
  {
    description:
      "All the users who have visited your instagram page in the last 30 days",
    title: "Recent Instagram Engagers",
    id: "INSTAGRAM_ENGAGERS_30",
    type: "INSTAGRAM_ENGAGERS",
    icon: "/to-be-uploaded/instagram.svg",
    initialValues: {
      duration: 2592000,
    },
  },
  {
    description:
      "All the users who have visited your instagram page in the past 3 months",
    title: "Instagram Engagers",
    id: "INSTAGRAM_ENGAGERS_90",
    type: "INSTAGRAM_ENGAGERS",
    icon: "/to-be-uploaded/instagram.svg",
    initialValues: {
      duration: 7776000,
    },
  },
  {
    description:
      "All the users who have visited your instagram page in past 6 months",
    title: "Long Term Instagram Engagers",
    id: "INSTAGRAM_ENGAGERS_180",
    type: "INSTAGRAM_ENGAGERS",
    icon: "/to-be-uploaded/instagram.svg",
    initialValues: {
      duration: 15552000,
    },
  },
  // lookalike
  {
    description:
      "All the users who have engaged with your facebook page in the last 30 days",
    title: "Lookalike Recent Facebook Page Engagers",
    id: "FACEBOOK_ENGAGERS_30_LOOKALIKE",
    type: "FACEBOOK_ENGAGERS_LOOKALIKE",
    icon: "/to-be-uploaded/Facebook.svg",
    initialValues: {
      duration: 2592000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have engaged with your facebook page in the past 3 months",
    title: "Lookalike Facebook Page Engagers",
    id: "FACEBOOK_ENGAGERS_90_LOOKALIKE",
    type: "FACEBOOK_ENGAGERS_LOOKALIKE",
    icon: "/to-be-uploaded/Facebook.svg",
    initialValues: {
      duration: 7776000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have engaged with your facebook page in the past 6 months",
    title: "Lookalike Long Term Facebook Page Engagers",
    id: "FACEBOOK_ENGAGERS_180_LOOKALIKE",
    type: "FACEBOOK_ENGAGERS_LOOKALIKE",
    icon: "/to-be-uploaded/Facebook.svg",
    initialValues: {
      duration: 15552000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have visited your website in the last 30 days",
    title: "Lookalike Recent Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_30_LOOKALIKE",
    type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 2592000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have visited your website in the past 3 months",
    title: "Lookalike Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_90_LOOKALIKE",
    type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 7776000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have visited your website in the past 6 months",
    title: "Lookalike Long Term Website Visitors",
    id: "FACEBOOK_PIXEL_BASED_180_LOOKALIKE",
    type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "visit",
      duration: 15552000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who added to cart your website in the last 30 days",
    title: "Lookalike Recent Add To Cart",
    id: "FACEBOOK_PIXEL_BASED_ATC_30_LOOKALIKE",
    type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "AddToCart",
      duration: 2592000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who added to cart your website in the past 3 months",
    title: "Lookalike Add To Cart",
    id: "FACEBOOK_PIXEL_BASED_ATC_90_LOOKALIKE",
    type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "AddToCart",
      duration: 7776000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who added to cart your website in the past 6 months",
    title: "Lookalike Long Term Add To Cart",
    id: "FACEBOOK_PIXEL_BASED_ATC_180_LOOKALIKE",
    type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
    icon: "/to-be-uploaded/facebook-pixel.svg",
    initialValues: {
      eventName: "AddToCart",
      duration: 15552000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have visited your instagram page in the last 30 days",
    title: "Lookalike Recent Instagram Engagers",
    id: "INSTAGRAM_ENGAGERS_30_LOOKALIKE",
    type: "INSTAGRAM_ENGAGERS_LOOKALIKE",
    icon: "/to-be-uploaded/instagram.svg",
    initialValues: {
      duration: 2592000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have visited your instagram page in the past 3 months",
    title: "Lookalike Instagram Engagers",
    id: "INSTAGRAM_ENGAGERS_90_LOOKALIKE",
    type: "INSTAGRAM_ENGAGERS_LOOKALIKE",
    icon: "/to-be-uploaded/instagram.svg",
    initialValues: {
      duration: 7776000,
      percentage: 1,
      countries: ["IN"],
    },
  },
  {
    description:
      "All the users who have visited your instagram page in past 6 months",
    title: "Lookalike Long Term Instagram Engagers",
    id: "INSTAGRAM_ENGAGERS_180_LOOKALIKE",
    type: "INSTAGRAM_ENGAGERS_LOOKALIKE",
    icon: "/to-be-uploaded/instagram.svg",
    initialValues: {
      duration: 15552000,
      percentage: 1,
      countries: ["IN"],
    },
  },
];
