import { useContext } from "react";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { convertFromArrayToObject } from "src/lib/convertFromArrayToObject";
import { formatNumber } from "src/lib/formatNumber";
import { GraphHeader } from "src/modules/global/components/GraphHeader";
import AuthContext from "src/stores/AuthContext";

interface ISegmentOverviewGraphHeader {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}

export function SegmentOverviewGraphHeader({
  segmentId,
  dateFrom,
  dateTo,
}: ISegmentOverviewGraphHeader) {
  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "engage_aggregate",
    userToken,
    {
      segmentId,
      dateFrom,
      dateTo,
    }
  );

  if (error) {
    return null;
  }

  if (loading) {
    return null;
  }

  const insight = convertFromArrayToObject(
    data,
    (i) => i.action,
    (i) => i.actionCount
  );

  return (
    <GraphHeader
      stats={[
        // {
        //   color: "#4FBCC4",
        //   dataKey: "send_email",
        //   label: "Sents",
        //   value: insight?.send_email,
        //   valueType: "NUMBER",
        // },
        {
          color: "#B98900",
          dataKey: "view_email",
          label: "Views",
          value: insight?.view_email,
          valueType: "NUMBER",
        },
        {
          color: "#2E997E",
          dataKey: "click_link",
          label: "Clicks",
          value: insight?.click_link,
          valueType: "NUMBER",
        },
        {
          color: "#2E997E",
          dataKey: "unsubscribe_email",
          label: "Unsubscribe",
          value: insight?.unsubscribe_email,
          valueType: "NUMBER",
        },
      ]}
      title={
        insight?.send_email ? `${formatNumber(insight?.send_email)} Sends` : ""
      }
      caption=""
    />
  );
}
