import { Form, Formik } from "formik";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  useShopifyIntegrationQuery,
  useShopifyIntegrationUpdateMutation,
} from "src/graphql/generated/schema";
import { ShopifyCustomerSettings } from "./ShopifyCustomerSettings";
import { ShopifyPixelSettings } from "./ShopifyPixelSettings";
import styles from "./ShopifyIntegrationSettings.module.scss";

export function ShopifyIntegrationSettings() {
  const { data, loading, error } = useShopifyIntegrationQuery();
  const [shopifyIntegrationUpdate] = useShopifyIntegrationUpdateMutation();

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  const initialValues = {
    contactData: {
      enabled: data.shopifyIntegration.syncFromShopify,
      contacts: true,
      marketingCommunications:
        data.shopifyIntegration.syncToShopifyEmailConsent,
      updateBack: data.shopifyIntegration.syncToShopify,
    },
    shopifyPixel: {
      enabled: true,
      captureUTM: true,
      anonymousProfile: false,
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await shopifyIntegrationUpdate({
          variables: {
            input: {
              syncFromShopify: values.contactData.enabled,
              syncToShopifyEmailConsent:
                values.contactData.marketingCommunications,
              syncToShopify: values.contactData.updateBack,
            },
          },
        });
      }}
    >
      <Form>
        <div className={styles.layout}>
          <ShopifyCustomerSettings />
          <ShopifyPixelSettings />
        </div>
      </Form>
    </Formik>
  );
}
