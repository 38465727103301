import { Link } from "react-router-dom";
import styles from "./IntegrationNeedHelp.module.scss";
import { ReactComponent as ArrowOutward } from "@material-design-icons/svg/outlined/arrow_outward.svg";

export function IntegrationNeedHelp() {
  return (
    <div>
      <h3 className={styles.title}>Need Help?</h3>
      <div className={styles.linksWrapper}>
        <Link to={"/"} className={styles.link}>
          Visit Help and Support
        </Link>
        <Link to={"/"} className={styles.link}>
          <span>Contact Support</span>
          <ArrowOutward className={styles.ico} />
        </Link>
      </div>
    </div>
  );
}
