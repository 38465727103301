import { ReactComponent as ArrowUp } from "@material-design-icons/svg/outlined/arrow_upward.svg";
import { ReactComponent as ArrowDownward } from "@material-design-icons/svg/outlined/arrow_downward.svg";

const sortOptions = [
  {
    type: "Created At",
    value: "CREATED_AT",
    options: [
      {
        label: "Newest",
        value: "DESC",
        icon: <ArrowUp fill="currentColor" />,
      },
      {
        label: "Oldest",
        value: "ASC",
        icon: <ArrowDownward fill="currentColor" />,
      },
    ],
  },
  {
    type: "Alphabetic",
    value: "NAME",
    options: [
      {
        label: "A to Z",
        value: "ASC",
        icon: <ArrowUp fill="currentColor" />,
      },
      {
        label: "Z to A",
        value: "DESC",
        icon: <ArrowDownward fill="currentColor" />,
      },
    ],
  },
];

export default sortOptions;
