import { User } from "src/graphql/generated/schema";

const userDetailStorageKey = "user";

export function userDetailGet(): User | null {
  const data = localStorage.getItem(userDetailStorageKey);

  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
}

export function userDetailSet(data: User) {
  localStorage.setItem(userDetailStorageKey, JSON.stringify(data));
}

export function userDetailRemove() {
  localStorage.removeItem(userDetailStorageKey);
}
