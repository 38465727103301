import styles from "./CodewordResourcesList.module.scss";
import TipsAndTricks from "../../assets/tips-and-tricks.png";
import ClearDoubts from "../../assets/clear-doubts.png";
import MeetOurExperts from "../../assets/meet-our-experts.png";
import { Link } from "react-router-dom";

export default function CodewordResourcesList() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Resources</div>
      <div className={styles.cardGrid}>
        <Link
          to={"https://macrostack.co"}
          target="_blank"
          className={styles.cardContainer}
        >
          <div className={styles.cardImage}>
            <img src={TipsAndTricks} alt="Tips and Tricks" />
          </div>
          <div className={styles.cardContainerInner}>
            <div className={styles.cardTitle}>Tips and Tricks</div>
            <div className={styles.cardDescription}>
              Looking for Tips to grow your Business? Checkout our Articles
            </div>
          </div>
        </Link>
        <Link
          to={"https://macrostack.co"}
          target="_blank"
          className={styles.cardContainer}
        >
          <div className={styles.cardImage}>
            <img src={ClearDoubts} alt="Clear Doubts" />
          </div>
          <div className={styles.cardContainerInner}>
            <div className={styles.cardTitle}>Clear Doubts</div>
            <div className={styles.cardDescription}>
              Have doubts? Check our Help Center and learn more about Macro.
            </div>
          </div>
        </Link>
        <Link
          to={"https://macrostack.co"}
          target="_blank"
          className={styles.cardContainer}
        >
          <div className={styles.cardImage}>
            <img src={MeetOurExperts} alt="Meet Our Experts" />
          </div>
          <div className={styles.cardContainerInner}>
            <div className={styles.cardTitle}>Meet Our Experts</div>
            <div className={styles.cardDescription}>
              Looking for Expert? Schedule a Free call with Macro Expert.
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
