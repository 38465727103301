import { MetaCatalogueProducts } from "src/graphql/generated/schema";
import { LineItems } from "src/lib/types";

export function generateLineItemsFromCatalogueProducts(
  products: MetaCatalogueProducts[]
) {
  const lineItems: LineItems[] = products.map((v, i) => ({
    description: "",
    headline: v.name,
    link: v.url,
    order: i,
    PostAsset: {
      contentType: "IMAGE",
      name: v.name,
      uri: v.imageUrl,
    },
    StoryAsset: {
      contentType: "IMAGE",
      name: v.name,
      uri: v.imageUrl,
    },
  }));
  return lineItems;
}
