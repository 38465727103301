import {
  FormFieldStatus,
  IFormFieldStatus,
} from "../FormFieldStatus/FormFieldStatus";

import { Label } from "../Label/Label";
import classNames from "classnames";
import styles from "./MultilineInput.module.scss";
import { CharacterCounter } from "src/modules/global/components/CharacterCounter";

export interface ITextArea {
  value?: string;
  rows?: number;
  onChange?: (i: string) => void;
  onBlur?: (e: any) => void;
  status?: IFormFieldStatus;
  width?: "full" | "auto";
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  size?: "small" | "default";
  autocomplete?: string;
  showCharacterCount?: boolean;
  maxCharacterCount?: number;
}

export interface IMultilineInput extends ITextArea {
  label?: string;
}

export function MultilineInput(props: IMultilineInput) {
  const {
    label,
    status,
    autocomplete = "off",
    rows = 3,
    disabled,
    value,
    width = "full",
    onBlur,
    name,
    onChange,
    placeholder,
    size = "default",
    showCharacterCount,
    maxCharacterCount,
  } = props;

  return (
    <>
      {label ? (
        <div>
          <div className={styles.labelWrapper}>
            <Label
              label={label}
              status={status?.type === "error" ? "error" : "default"}
              htmlFor={name}
            />
            {showCharacterCount && (
              <CharacterCounter
                characterCount={value?.length}
                maxCharacterCount={maxCharacterCount}
              />
            )}
          </div>
          <TextArea
            autocomplete={autocomplete}
            rows={rows}
            disabled={disabled}
            value={value}
            width={width}
            onBlur={onBlur}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            size={size}
            status={status}
          />
          {status && (
            <FormFieldStatus message={status.message} type={status.type} />
          )}
        </div>
      ) : (
        <TextArea
          autocomplete={autocomplete}
          rows={rows}
          disabled={disabled}
          value={value}
          width={width}
          onBlur={onBlur}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          size={size}
          status={status}
        />
      )}
    </>
  );
}

function TextArea({
  autocomplete = "off",
  rows = 3,
  disabled,
  value,
  width = "full",
  onBlur,
  name,
  onChange,
  placeholder,
  size = "default",
  status,
}: ITextArea) {
  return (
    <textarea
      className={classNames(styles.textarea, {
        [styles.defaultSize]: size === "default",
        [styles.smallSize]: size === "small",
        [styles.errorStatus]: status?.type === "error",
        [styles.warningStatus]: status?.type === "warning",
        [styles.infoStatus]: status?.type === "info",
        [styles.fullWidth]: width === "full",
        [styles.autoWidth]: width === "auto",
        [styles.disabledState]: disabled,
      })}
      autoComplete={autocomplete}
      rows={rows}
      disabled={disabled}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      placeholder={placeholder}
    />
  );
}
