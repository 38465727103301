import { Link } from "react-router-dom";
import styles from "./InfoCard.module.scss";

interface IInfoCard {
  title: string;
  description: string;
  link: {
    label: string;
    to: string;
  };
}

export function InfoCard({ title, description, link }: IInfoCard) {
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{description}</p>
      <Link to={link.to} className={styles.link}>
        {link.label}
      </Link>
    </div>
  );
}
