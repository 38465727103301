import { SectionHeading } from "src/modules/global/components/SectionHeading";
import styles from "./AudienceSingleAbout.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "@material-design-icons/svg/outlined/arrow_outward.svg";
import Badge from "src/components/Badge/Badge";
import { ReactComponent as Setting } from "@material-design-icons/svg/outlined/settings.svg";
import Instagram from "../assets/instaIco.svg";
import format from "date-fns/format";
import { AudienceIcon } from "src/modules/global/components/AudienceIcon";
import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { generateRangeString } from "src/lib/generateRangeString";

interface IAudienceSingleAbout {
  audience: Audience | MetaAudienceSet;
}

export function AudienceSingleAbout({ audience }: IAudienceSingleAbout) {
  const isAudienceSet = audience.__typename === "MetaAudienceSet";

  const details = isAudienceSet
    ? [
        [
          {
            label: "Audience ID",
            value: audience.id,
          },
          {
            label: "Estimate Size",
            value: generateRangeString(
              audience?.MetaInsights?.lowerBoundCount,
              audience?.MetaInsights?.upperBoundCount
            ),
          },
          {
            label: "Created on",
            value: format(new Date(audience.createdAt), "dd MMM, yyyy"),
          },
          {
            label: "Facebook Ad Account",
            value: audience?.PlatformAccount?.metaName ?? "-",
          },
        ],
      ]
    : [
        [
          {
            label: "Audience ID",
            value: audience.id,
          },
          {
            label: "Estimate Size",
            value: generateRangeString(
              audience?.MetaInsights?.lowerBoundCount,
              audience?.MetaInsights?.upperBoundCount
            ),
          },
          {
            label: "Created on",
            value: format(new Date(audience.createdAt), "dd MMM, yyyy"),
          },
          {
            label: "Facebook Ad Account",
            value: audience?.PlatformAccount?.metaName ?? "-",
          },
        ],
      ];

  return (
    <div>
      <SectionHeading
        heading={"About this Audience"}
        description={
          "Information about this audience. You will get to know about brief of this audience."
        }
      />
      <div className={styles.container}>
        <div className={styles.tile}>
          <div className={styles.infoContainer}>
            <div className={styles.iconWrapper}>
              <AudienceIcon audience={audience} />
            </div>
            <div className={styles.infoText}>
              <span className={styles.segmentName}>{audience.name}</span>
              {/* <ArrowOutward className={styles.arrowIco} /> */}
              <span className={styles.segmentType}>
                Type:{" "}
                {isAudienceSet
                  ? "Audience Set"
                  : ` ${snakeCaseToTitleCase(
                      audience.type || ""
                    )} | ${snakeCaseToTitleCase(audience.platform || "")}`}
              </span>
            </div>
          </div>
          <div>
            <span className={styles.sourceLabel}>Source</span>
            <div className={styles.badgeWrapper}>
              <Badge
                label={"Custom"}
                leftIconButton={{
                  icon: Setting,
                }}
                // icon={<Setting className={styles.settingIco} />}
                className={styles.customBadge}
              />
              <Badge
                label={"Instagram"}
                className={styles.instaBadge}
                leftAvatar={{
                  src: Instagram,
                }}
                // icon={
                //   <img
                //     src={Instagram}
                //     alt="instagram"
                //     className={styles.instaIco}
                //   />
                // }
              />
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.itemsGrid}>
          {details[0].map((item, index) => (
            <AboutItem value={item.value} label={item.label} key={index} />
          ))}
        </div>
        {/* <hr className={styles.line} />
        <div className={styles.itemsGrid}>
          {details[1].map((item, index) => (
            <AboutItem value={item.value} label={item.label} key={index} />
          ))}
        </div> */}
      </div>
    </div>
  );
}

function AboutItem({ label, value }) {
  return (
    <div className={styles.aboutItem}>
      <span className={styles.aboutItemLabel}>{label}</span>
      <span className={styles.aboutItemValue}>{value}</span>
    </div>
  );
}
