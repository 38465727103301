import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./TemplateSingleLayout.module.scss";
import ButtonOptions from "src/components/ButtonOptions/ButtonOptions";
import { TemplateSingleSendModal } from "../components/TemplateSingleSendModal";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { TemplateListingDeleteModal } from "../components/TemplateListingDeleteModal";

export function TemplateSingleLayout() {
  const [showTestModal, setShowTestModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  return (
    <div>
      <PageHeader
        title={"Sales_Whatsapp_Template"}
        back="/templates"
        actions={
          <ButtonOptions
            items={[
              {
                children: "Test Template",
                onClick: () => setShowTestModal(true),
              },
              {
                children: "Delete",
                onClick: () => setShowDeleteAlert(true),
                color: "danger",
              },
            ]}
            children={"Duplicate and Edit"}
            color="primary"
            size="small"
          />
        }
      />
      <TemplateSingleSendModal
        isOpen={showTestModal}
        onClose={() => setShowTestModal(false)}
      />
      <TemplateListingDeleteModal
        isOpen={showDeleteAlert}
        onClose={() => setShowDeleteAlert(false)}
      />
      <div className={styles.container}>
        <div className={styles.infoWrapper}>
          <Outlet />
        </div>
        <div className={styles.mobileWrapper}>
          {/* <Mobile templateType={"WHATSAPP"} /> */}
          mobile
        </div>
      </div>
    </div>
  );
}
