import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  MetaActivityAdTemplatesQuery,
  MetaAdTypeEnum,
  useMetaActivityAdTemplatesLazyQuery,
} from "src/graphql/generated/schema";
import { AdTemplateItem } from "./AdTemplateItem";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "src/modules/global/misc/dateFilterUtils";
import { useEffect } from "react";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./AdTemplatesLoader.module.scss";
import UserAddIcon from "../assets/userAddIcon.svg";
import createAdImage from "../assets/createAds.svg";
import noResultAd from "../assets/noResultAds.svg";
import { Button } from "src/components/Button/Button";

type AdTemplate =
  MetaActivityAdTemplatesQuery["metaActivityAdTemplates"]["edges"][0];

interface IAdTemplatesLoader {
  selectedAdTemplates: AdTemplate[];
  setSelectedAdTemplates: (i: AdTemplate[]) => void;
  selectedFilters: {
    searchQuery: string;
    dateFilter: DateFilter;
    adType: MetaAdTypeEnum | null;
  };
  selectedSortBy: { direction: "DESC" | "ASC"; field: "CREATED_AT" };
}

export function AdTemplatesLoader({
  selectedFilters,
  selectedSortBy,
  selectedAdTemplates,
  setSelectedAdTemplates,
}: IAdTemplatesLoader) {
  const debouncedSearchQuery = useDebouncedVariable(
    selectedFilters.searchQuery,
  );
  const [
    metaActivityAdTemplatesFn,
    { data, loading, called, error, fetchMore },
  ] = useMetaActivityAdTemplatesLazyQuery();

  const areFiltersApplied =
    debouncedSearchQuery !== "" ||
    selectedFilters.adType !== null ||
    selectedFilters.dateFilter !== "ALL_TIME";

  useEffect(() => {
    metaActivityAdTemplatesFn({
      variables: {
        sortBy: selectedSortBy,
        filter: {
          createdAt: getDateFilterFromFilterEnum(selectedFilters.dateFilter),
          ...(selectedFilters.adType && { adType: selectedFilters.adType }),
          name: debouncedSearchQuery,
          take: 25,
        },
      },
    });
  }, [
    metaActivityAdTemplatesFn,
    selectedSortBy,
    debouncedSearchQuery,
    selectedFilters.adType,
    selectedFilters.dateFilter,
  ]);

  if (loading || !called) {
    return <Spinner height={400} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  async function fetchMoreAdTemplates() {
    await fetchMore({
      variables: {
        filter: {
          cursor: data.metaActivityAdTemplates.pageInfo.endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.metaActivityAdTemplates?.edges ?? [];
        const oldEdges = previousResult?.metaActivityAdTemplates?.edges ?? [];

        return {
          metaActivityAdTemplates: {
            ...fetchMoreResult.metaActivityAdTemplates,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  // this should be above the generic handler of no result
  // because that one will match both of these cases
  if (!data.metaActivityAdTemplates.edges.length && areFiltersApplied) {
    return (
      <div className={styles.noResultScreen}>
        <img src={noResultAd} width={48} height={48} alt="no result audience" />
        <span className={styles.noResultText}>
          No search results found. Try changing the filters or use a different
          keyword.
        </span>
      </div>
    );
  }

  if (!data.metaActivityAdTemplates.edges.length) {
    return (
      <div className={styles.emptyScreen}>
        <img src={createAdImage} width={48} height={48} alt="create audience" />
        <span className={styles.emptyScreenText}>
          Oops! It looks like you don't have an ads for your campaign launch.
        </span>
        <div className={styles.buttonWrapper}>
          <Button size="micro" to="/library/ad-library">
            <div className={styles.createAudienceBtnContent}>
              <img
                src={UserAddIcon}
                width={16}
                height={16}
                role="none"
                alt="user add icon"
              />
              Create Ads
            </div>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={data.metaActivityAdTemplates.edges.length}
      scrollableTarget="scrollTargetForInfiniteScroll"
      next={fetchMoreAdTemplates}
      hasMore={data.metaActivityAdTemplates.pageInfo.hasNextPage}
      loader={<Spinner height={200} />}
    >
      {data.metaActivityAdTemplates.edges.map((metaActivityAdTemplate) => (
        <AdTemplateItem
          selectedAdTemplates={selectedAdTemplates}
          setSelectedAdTemplates={setSelectedAdTemplates}
          metaActivityAdTemplate={metaActivityAdTemplate}
        />
      ))}
    </InfiniteScroll>
  );
}
