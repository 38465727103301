import Modal from "src/components/Modal/Modal";
import styles from "./AudienceSetViewSelection.module.scss";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";
import { Tabs } from "src/components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { audienceAndInterestTabs } from "../misc/audienceAndInterestTabs";
import { Button } from "src/components/Button/Button";
import { Form, Formik, useFormikContext } from "formik";
import Alert from "src/components/Alert/Alert";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceWorkshop";
import { InterestsAndDemographicsSelector } from "./InterestsAndDemographicsSelector";
import { AudiencePresetSelector } from "./AudiencePresetSelector";
import audiencePresets from "../misc/audiencePresets";

interface IAudienceSetViewSelection {
  isShowSelectionOpen: boolean;
  setIsShowSelectionOpen: (i: boolean) => void;
  initialValues: AudiencePresetAndInterestSelectorFormState;
  initialSelectedTab: string;
  onChange: (i: AudiencePresetAndInterestSelectorFormState) => void;
}

export function AudienceSetViewSelection({
  isShowSelectionOpen,
  setIsShowSelectionOpen,
  initialSelectedTab,
  initialValues,
  onChange,
}: IAudienceSetViewSelection) {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const { values } =
    useFormikContext<AudiencePresetAndInterestSelectorFormState>();
  const interests = Object.values(values.interests);
  const selectedAudiencePresets = Object.keys(values.audiencePresets).map(
    (aId) => audiencePresets.find((preset) => preset.id === aId),
  );

  useEffect(() => {
    setSelectedTab(initialSelectedTab);
  }, [initialSelectedTab]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        setIsShowSelectionOpen(false);
        onChange(values);
      }}
    >
      {({ dirty, handleSubmit, resetForm }) => {
        // show alert only when user made any changes
        function handleBack() {
          if (dirty) {
            setIsDiscardAlertShowing(true);
          } else {
            setIsShowSelectionOpen(false);
          }
        }

        function stay() {
          setIsDiscardAlertShowing(false);
        }

        function discardChanges() {
          // reset the internal form state
          resetForm();
          setIsDiscardAlertShowing(false);
          setIsShowSelectionOpen(false);
        }

        return (
          <Modal
            maxWidth={"75rem"}
            title="Manage Selection"
            isOpen={isShowSelectionOpen}
            onClose={() => handleBack()}
          >
            <Form onSubmit={handleSubmit}>
              <div className={styles.wrapper}>
                <div className={styles.helpText}>
                  <InfoIcon
                    fill="var(--color-disabled)"
                    width={18}
                    height={18}
                  />
                  Here are your selected Interests, Behaviour and Demographics.
                  These selection will be used for the creation of your audience
                </div>

                <div className={styles.container}>
                  <div className={styles.tabs}>
                    <Tabs
                      onTabChange={(value) => setSelectedTab(value)}
                      selectedTab={selectedTab}
                      tabs={audienceAndInterestTabs}
                    />
                  </div>
                  <div className={styles.tableWrapper}>
                    {selectedTab === "INTERESTS" && (
                      <InterestsAndDemographicsSelector
                        shouldShowSelection={false}
                        interests={interests}
                      />
                    )}

                    {selectedTab === "AUDIENCE_PRESET" && (
                      <AudiencePresetSelector
                        shouldShowSelection={false}
                        audiencePresets={selectedAudiencePresets}
                      />
                    )}
                  </div>
                </div>

                <div className={styles.footer}>
                  <Button type="submit">Save and Exit</Button>
                  <Button onClick={handleBack} color="subdued" style="outline">
                    Back
                  </Button>
                </div>
              </div>
              <Alert
                isOpen={isDiscardAlertShowing}
                onClose={() => setIsDiscardAlertShowing(false)}
                secondaryAction={{
                  children: "Yes",
                  color: "primary",
                  onClick: discardChanges,
                }}
                primaryAction={{
                  children: "No",
                  color: "subdued",
                  style: "outline",
                  onClick: stay,
                }}
                title="Do you want to leave without saving the changes?"
              >
                The changes you made inside the modal will be lost
              </Alert>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
