import styles from "./SegmentSingleStats.module.scss";
import StatsCard from "src/components/StatCard/StatCard";
import { SegmentQuery } from "src/graphql/generated/schema";

export function SegmentSingleStats({
  segment,
}: {
  segment: SegmentQuery["segment"];
}) {
  const segmentStats = [
    {
      title: "Segment Size",
      subtitle: "No. of customers in segment",
      value: segment.customersCount,
      valueType: "NUMBER" as const,
    },
    {
      title: "Avg LTV",
      subtitle: "Average lifetime value of customers",
      valueType: "NUMBER" as const,
      value: segment.Insights.averageLifetimeValue ?? 0,
    },
    {
      title: "Email Opts",
      subtitle: "% of Customers opted for Email",
      valueType: "PERCENTAGE" as const,
      // prevent divide by zero
      value: segment.customersCount
        ? (segment.Insights.emailSubscribers / segment.customersCount) * 100
        : 0,
    },
    {
      title: "SMS Opts",
      subtitle: "% of Customers opted for SMS",
      valueType: "PERCENTAGE" as const,
      value: segment.customersCount
        ? (segment.Insights.messageSubscribers / segment.customersCount) * 100
        : 0,
    },
    {
      title: "WhatsApp Opt",
      subtitle: "% of Customers opted for WhatsApp",
      valueType: "PERCENTAGE" as const,
      value: segment.customersCount
        ? (segment.Insights.whatsappSubscribers / segment.customersCount) * 100
        : 0,
    },
  ];

  return (
    <div className={styles.statsContainer}>
      {segmentStats.map((item, index) => (
        <StatsCard {...item} key={index} />
      ))}
    </div>
  );
}
