import styles from "./MetaIntegrationAccountDetailsSlider.module.scss";
import { useState } from "react";
import { Tabs } from "src/components/Tabs/Tabs";
import { MetaPagesList } from "./MetaPagesList";
import { MetaInstagramAccountsList } from "./MetaInstagramAccountsList";
import { MetaPixelsList } from "./MetaPixelsList";
import { MetaCataloguesList } from "./MetaCataloguesList";

type IntegrationSliderTabs =
  | "AD_ACCOUNTS"
  | "PAGES"
  | "IG_ACCOUNTS"
  | "CATALOGUES"
  | "PIXELS";

export function MetaIntegrationAccountDetailsContent({ selectedAccountId }) {
  const [selectedTab, setSelectedTab] =
    useState<IntegrationSliderTabs>("PAGES");
  function contentBasedOnTabs() {
    switch (selectedTab) {
      case "CATALOGUES":
        return <MetaCataloguesList selectedAccountId={selectedAccountId} />;
      case "IG_ACCOUNTS":
        return (
          <MetaInstagramAccountsList selectedAccountId={selectedAccountId} />
        );
      case "PAGES":
        return <MetaPagesList selectedAccountId={selectedAccountId} />;
      case "PIXELS":
        return <MetaPixelsList selectedAccountId={selectedAccountId} />;
      default:
        return <>Not Found</>;
    }
  }
  return (
    <div>
      <div className={styles.tabList}>
        <Tabs
          tabs={
            [
              {
                label: "Pages",
                value: "PAGES",
              },
              {
                label: "Instagram Accounts",
                value: "IG_ACCOUNTS",
              },
              {
                label: "Catalogues",
                value: "CATALOGUES",
              },
              {
                label: "Pixels",
                value: "PIXELS",
              },
            ] as { label: string; value: IntegrationSliderTabs }[]
          }
          selectedTab={selectedTab}
          onTabChange={function (i: any): void {
            setSelectedTab(i);
          }}
        ></Tabs>
      </div>
      <div className={styles.container}>{contentBasedOnTabs()}</div>
    </div>
  );
}
