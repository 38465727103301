import { Searchbar } from "src/components/Searchbar/Searchbar";
import { Separator } from "src/components/Separator/Separator";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";
import styles from "./RetargetAudiencesField.module.scss";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Button } from "src/components/Button/Button";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { retargetingAudiences } from "../misc/retargetingAudiences";
import {
  CheckboxGroup,
  CheckboxOption,
} from "src/components/CheckboxGroup/CheckboxGroup";
import { useFormikContext } from "formik";

interface IRetargetAudiencesField {
  handleBack: () => unknown;
}

export function RetargetAudiencesField({
  handleBack,
}: IRetargetAudiencesField) {
  const { values, isSubmitting, setFieldValue } = useFormikContext<{
    selectedPresets: string[];
  }>();
  const [query, setQuery] = useState("");
  const [selectedType, setSelectedType] = useState("ALL");
  // const adsets = Activities[selectedActivityIndex].Adsets;

  const categoryOptions = Object.entries(sectionHeaders).map(
    ([key, value]) => ({ label: value, value: key }),
  );

  const categoryOptionsWithAll = [
    { label: "All", value: "ALL" },
    ...categoryOptions,
  ];

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.heading}>Select audiences to target</h1>
        <h3 className={styles.subheading}>
          Choose from audiences who have interacted with your catalogue
        </h3>
      </div>

      {/* <Searchbar
        onChange={({ inputValue, select1Value }) => {
          setQuery(inputValue);
          setSelectedType(select1Value);
        }}
        submitLabel="Apply Filter"
        select1={{
          options: categoryOptionsWithAll,
          placeholder: "Select Categories",
        }}
        input={{ placeholder: "Search..." }}
      /> */}

      <CheckboxGroup
        value={values.selectedPresets}
        name="selectedPresets"
        // onBlur={handleBlur}
        onChange={(newValue) => {
          setFieldValue("selectedPresets", newValue);
        }}
      >
        <div className={styles.recommendations}>
          {sections
            // only filter if user has selected a type
            .filter((s) => (selectedType !== "ALL" ? s === selectedType : true))
            .map((type) => {
              const filteredAudiences = filterBasedOnFilter({
                query,
                type,
                audiences: retargetingAudiences,
              });

              return (
                <>
                  <Separator
                    title={
                      <span className={styles.groupHeading}>
                        {sectionHeaders[type]}
                        <InfoIcon width={18} height={18} fill="currentColor" />
                      </span>
                    }
                  />
                  <div className={styles.options}>
                    {filteredAudiences.map((a) => (
                      <CheckboxOption key={a.id} value={a.id}>
                        {({ checked }) => (
                          <AudienceItemCard
                            iconBackgroundColor={a.iconBackgroundColor}
                            checked={checked}
                            description={a.description}
                            icon={a.icon}
                            title={a.title}
                          />
                        )}
                      </CheckboxOption>
                    ))}
                  </div>
                </>
              );
            })}
        </div>
      </CheckboxGroup>

      <StickyFooter
        label={
          <span className={styles.footerText}>
            Audience Selected{" "}
            <span className={styles.footerTextHighlighted}>
              {values.selectedPresets.length}/20
            </span>
          </span>
        }
        actions={
          <>
            <Button
              style="outline"
              onClick={() => {
                handleBack();
              }}
              color="subdued"
              children="Back"
            />
            <Button
              loading={isSubmitting}
              type="submit"
              children="Continue"
              disabled={values.selectedPresets.length === 0}
            />
          </>
        }
      />
    </>
  );
}

interface IAudienceItemCard {
  title: string;
  description: string;
  icon: ReactNode;
  checked: boolean;
  iconBackgroundColor: string;
}

function AudienceItemCard({
  title,
  description,
  iconBackgroundColor,
  icon,
  checked,
}: IAudienceItemCard) {
  return (
    <div
      className={classNames(styles.audienceItem, {
        [styles.audienceItemChecked]: checked,
      })}
    >
      <span
        style={{ backgroundColor: iconBackgroundColor }}
        className={styles.audienceItemIcon}
      >
        {icon}
      </span>
      <h3
        className={classNames(styles.audienceItemTitle, {
          [styles.catalogueTitleChecked]: checked,
        })}
      >
        {title}
      </h3>
      <p className={styles.audienceItemDescription}>{description}</p>
    </div>
  );
}

const sectionHeaders: Record<(typeof retargetingAudiences)[0]["type"], string> =
  {
    VIEWERS: "People who viewed or added to cart",
    ABANDONED: "People who have abandoned their carts",
    ADD_TO_CART: "People who have added products to their cart",
  };

const sections = retargetingAudiences.reduce(
  (acc, a) => (acc.includes(a.type) ? acc : [...acc, a.type]),
  [],
);

function filterBasedOnFilter({
  audiences,
  query,
  type,
}: {
  type: string;
  audiences: typeof retargetingAudiences;
  query: string;
}) {
  const _query = query.toLowerCase();

  return audiences
    .filter((a) => a.type === type)
    .filter(
      (a) =>
        a.title.toLowerCase().includes(_query) ||
        a.description.toLowerCase().includes(_query),
    );
}
