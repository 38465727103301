import { AllCampaignsQuery } from "src/graphql/generated/schema";
import { CampaignItem } from "./CampaignItem";
import styles from "./CampaignList.module.scss";
import { CampaignsEmptyState } from "./CampaignsEmptyState";

interface ICampaignList {
  campaigns: AllCampaignsQuery["allCampaigns"]["edges"];
}

export function CampaignList({ campaigns }: ICampaignList) {
  if (campaigns.length === 0) {
    return <CampaignsEmptyState />;
  }

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {campaigns.map((campaignNode) => (
          <CampaignItem campaign={campaignNode.node} />
        ))}
      </ul>
    </div>
  );
}
