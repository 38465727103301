import { useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Logo from "src/components/Logo/Logo";
import styles from "./OnboardingPage4.module.scss";
import classNames from "classnames";
import commandK from "../assets/commandK.png";
import audienceBuilder from "../assets/audienceBuilder.png";
import AI from "../assets/AI.png";
import { AboutMacroSection } from "../components/AboutMacroSection";
import { CreateClientForm } from "../components/CreateClientForm";

export function OnboardingPage4() {
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  const sections = [
    <div className={styles.section1}>
      <Logo width={64} height={64} style="primary" type="icon" />
      <h1 className={styles.section1Heading}>Welcome to Macro Suite</h1>
      <p className={styles.section1Copy}>
        Macro Suite provides a comprehensive set of tools to manage, automate,
        and analyze all your digital marketing efforts across multiple platforms
        from one intuitive dashboard
      </p>
      <div className={styles.section1ButtonWrapper}>
        <ButtonV2
          onClick={() => setActiveSectionIndex(activeSectionIndex + 1)}
          size="large"
          isFullWidth
        >
          Continue
        </ButtonV2>
      </div>
    </div>,

    <AboutMacroSection
      title="Meet the command menu"
      description="Complete any action in seconds by typing it into the command menu"
      onClick={() => {
        setActiveSectionIndex(activeSectionIndex + 1);
      }}
      image={{
        alt: "use command + K to open the command palette",
        src: commandK,
        width: 580,
        height: 216,
      }}
    />,

    <AboutMacroSection
      title="Say hello, to new Activity & Audience Builder"
      description="Activities are the marketing content like ads, email, broadcast which
        you use to market your business"
      onClick={() => {
        setActiveSectionIndex(activeSectionIndex + 1);
      }}
      image={{
        alt: "globe with people",
        src: audienceBuilder,
        width: 800,
        height: 272,
      }}
    />,
    <AboutMacroSection
      title="Introducing AI and hell lot more..."
      description="Accelerate your ad creation with AI's swift, new Media Library, Integration, Analytics and lot more..."
      onClick={() => {
        setActiveSectionIndex(activeSectionIndex + 1);
      }}
      image={{
        alt: "chatbot",
        src: AI,
        width: 800,
        height: 272,
      }}
    />,
    <CreateClientForm />,
  ];

  return (
    <div className={styles.wrapper}>
      {sections[activeSectionIndex]}
      <div className={styles.navigationDots}>
        {Array(sections.length)
          .fill(null)
          .map((_, index) => (
            <button
              onClick={() => {
                setActiveSectionIndex(index);
              }}
              className={classNames(styles.dot, {
                [styles.activeDot]: index === activeSectionIndex,
              })}
              type="button"
            ></button>
          ))}
      </div>
    </div>
  );
}
