import Table from "src/components/Table/Table";
import styles from "./SegmentHistoryTable.module.scss";
import { Link } from "react-router-dom";
import { Pagination } from "src/components/Pagination/Pagination";
import { SegmentQuery } from "src/graphql/generated/schema";
import format from "date-fns/format";
import emailImage from "../../global/assets/email.svg";
import whatsappImage from "../../global/assets/whatsapp.svg";

const HEADINGS = ["Activities", "Created On", "Campaign Name"];

export function SegmentHistoryTable({
  segment,
}: {
  segment: SegmentQuery["segment"];
}) {
  const ROWS = segment.Outreaches.edges.map((outreachNode) => [
    <ActivityItem outreach={outreachNode.node} />,
    format(new Date(outreachNode.node.createdAt), "dd MMM, yyyy"),
    <Link to={`/campaigns/${outreachNode.node.Campaign.id}`}>
      {outreachNode.node.Campaign.name}
    </Link>,
  ]);

  return (
    <div className={styles.tableWrapper}>
      <Table headings={HEADINGS} rows={ROWS} className={styles.activityTable} />
      <div className={styles.paginationWrapper}>
        <Pagination
          hasPrevious={false}
          hasNext={false}
          onPrevious={function (): void {
            throw new Error("Function not implemented.");
          }}
          onNext={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </div>
    </div>
  );
}

function ActivityItem({
  outreach,
}: {
  outreach: SegmentQuery["segment"]["Outreaches"]["edges"][0]["node"];
}) {
  return (
    <div className={styles.activityItem}>
      <img
        src={outreach.outreachType === "EMAIL" ? emailImage : whatsappImage}
        alt={`activity platform is ${outreach.outreachType}`}
        className={styles.activityImg}
      />
      <div>
        <p className={styles.activityName}>{outreach.name}</p>
        {outreach.publishedAt && (
          <span className={styles.activityTime}>
            Published at{" "}
            {format(new Date(outreach.publishedAt), "dd MMM, yyyy")}
          </span>
        )}
      </div>
    </div>
  );
}
