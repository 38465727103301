import * as Yup from "yup";
import { campaignAndActivityRegex } from "./campaignName";
import { adsetBudgetSchema } from "./adsetBudgetSchema";

export const reviewActivitySchema = Yup.object({
  activityName: Yup.string()
    .required("Activity name is required field")
    .matches(
      // Resource: https://saasbase.dev/tools/regex-generator
      campaignAndActivityRegex.regex,
      campaignAndActivityRegex.message
    )
    .max(50, "Activity name should be more than 150 length"),
  // Adsets: Yup.array(
  //   Yup.object({
  //     budget: adsetBudgetSchema,
  //   })
  // ),
  // EmailObject: Yup.object({
  //   senderEmailId: Yup.string().required("sender is required"),
  //   senderName: Yup.string().required("sender is required"),
  //   subject: Yup.string().required("subject is required"),
  //   emailDesignTemplateId: Yup.string().nullable(),
  //   emailHTMLTemplateId: Yup.string().nullable(),
  //   emailTemplate: Yup.object(),
  // })
  //   .default(null)
  //   .nullable(),
  // TextMessageObject: Yup.object({
  //   textMessageTemplateId: Yup.string().required(
  //     "templateId is required to create textMessageCampaign"
  //   ),
  // })
  //   .default(null)
  //   .nullable(),
});

export const reviewSchema = Yup.object({
  // campaignName: Yup.string()
  //   .required("Campaign name is required")
  //   .matches(
  //     // Resource: https://saasbase.dev/tools/regex-generator
  //     campaignAndActivityRegex.regex,
  //     campaignAndActivityRegex.message
  //   )
  //   .max(50, "Campaign name cannot be more than 150 characters"),
  // can be nullable but null is not a valid value
  // objective: Yup.string().required("Objective is required").nullable(),
  Activities: Yup.array(reviewActivitySchema).min(
    1,
    "Atleast one activity required to publish"
  ),
});
