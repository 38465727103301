const majorConditions = [
  {
    label: "Select a condition",
    value: "",
  },
  {
    label: "What someone has done (or not)",
    value: "What someone has done (or not)",
  },
  {
    label: "Properties about someone",
    value: "Properties about someone",
  },
  {
    label: "If someone is (or not) in GDPR",
    value: "If someone is (or not) in GDPR",
  },
  {
    label: "Someone's proximity to a location",
    value: "Someone's proximity to a location",
  },
  {
    label: "If someone is in or not in a list",
    value: "If someone is in or not in a list",
  },
  {
    label: "If someone is or is not suppressed for email",
    value: "If someone is or is not suppressed for email",
  },
  {
    label: "If someone is or is not consented to receive an email",
    value: "If someone is or is not consented to receive an email",
  },
  {
    label: "Predictive analytics about someone",
    value: "Predictive analytics about someone",
  },
  {
    label: "Random sample",
    value: "Random sample",
  },
];

const customerActions = [
  {
    label: "Choose Metric",
    value: "",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Bounce Email",
    value: "Bounce Email",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Bounce Push",
    value: "Bounce Push",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Clicked Email",
    value: "Clicked Email",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Clicked SMS",
    value: "Clicked SMS",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Consented to Receive SMS",
    value: "Consented to Receive SMS",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Dropped Email",
    value: "Dropped Email",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Failed to deliver Automate",
    value: "Failed to deliver Automate",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Failed to Deliver Push",
    value: "Failed to Deliver Push",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Failed to Deliver SMS",
    value: "Failed to Deliver SMS",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Marked Email as Spam",
    value: "Marked Email as Spam",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Merged Profile",
    value: "Merged Profile",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Opened Email",
    value: "Opened Email",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Opened Push",
    value: "Opened Push",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Received Automated Response",
    value: "Received Automated Response",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Received Email",
    value: "Received Email",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Received Push",
    value: "Received Push",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Received SMS",
    value: "Received SMS",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Sent SMS",
    value: "Sent SMS",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Subscribed to Back in Stock",
    value: "Subscribed to Back in Stock",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Subscribed to List",
    value: "Subscribed to List",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Unsubscribed",
    value: "Unsubscribed",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Unsubscribed from List",
    value: "Unsubscribed from List",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Unsubscribed from SMS",
    value: "Unsubscribed from SMS",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  {
    label: "Updated Email Preferences",
    value: "Updated Email Preferences",
    icon: "/imagesAndIcons/BounceEmail.svg",
  },
  // FIXME: To Be Used Later and Change icon
  // {
  //   label: "Active on Site",
  //   value: "Added to Cart",
  //   icon: "/imagesAndIcons/BounceEmail.svg",
  // },
  // {
  //   label: "Added to Cart",
  //   value: "Added to Cart",
  //   icon: "/imagesAndIcons/BounceEmail.svg",
  // },
  // {
  //   label: "Ticket closed",
  //   value: "Ticked closed",
  //   icon: "/imagesAndIcons/BounceEmail.svg",
  // },
  // {
  //   label: "Ticket created",
  //   value: "Ticket created",
  //   icon: "/imagesAndIcons/BounceEmail.svg",
  // },
  // {
  //   label: "Viewed Product",
  //   value: "Viewed Product",
  //   icon: "/imagesAndIcons/BounceEmail.svg",
  // },
  {
    label: "Cancelled Order",
    value: "Cancelled Order",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Cancelled Order Value",
    value: "Cancelled Order Value",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Checkout Started",
    value: "Checkout Started",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Checkout Started Value",
    value: "Checkout Started Value",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Fulfilled Order",
    value: "Fulfilled Order",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Fulfilled Order Value",
    value: "Fulfilled Order Value",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Fulfilled Partial Order",
    value: "Fulfilled Partial Order",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Fulfilled Partial Order Value",
    value: "Fulfilled Partial Order Value",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Ordered Product",
    value: "Ordered Product",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Ordered Product Value",
    value: "Ordered Product Value",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Placed Order",
    value: "Placed Order",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Revenue",
    value: "Revenue",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Refunded Order",
    value: "Refunded Order",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
  {
    label: "Refunded Order Value",
    value: "Refunded Order Value",
    icon: "/imagesAndIcons/ShopifyIco.svg",
  },
];

const logicalConditions = [
  {
    label: "at least once",
    value: "at least once",
  },
  {
    label: "zero times",
    value: "zero times",
  },
  {
    label: "equals",
    value: "equals",
  },
  {
    label: "doesn't equal",
    value: "doesn't equal",
  },
  {
    label: "is at least",
    value: "is at least",
  },
  {
    label: "is greater than",
    value: "is greater than",
  },
  {
    label: "is less than",
    value: "is less than",
  },
  {
    label: "is at most",
    value: "is at most",
  },
];

const timeConditions = [
  {
    label: "over all time",
    value: "over all time",
  },
  {
    label: "in the last",
    value: "in the last",
  },
  {
    label: "between",
    value: "between",
  },
  {
    label: "before",
    value: "before",
  },
  {
    label: "after",
    value: "after",
  },
  {
    label: "between dates",
    value: "between dates",
  },
  {
    label: "since starting this flow",
    value: "since starting this flow",
  },
];

export { majorConditions, customerActions, logicalConditions, timeConditions };
