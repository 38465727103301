import { Link } from "react-router-dom";
import { Activity } from "src/graphql/generated/schema";
import styles from "./ActivityAboutCard.module.scss";
import { ReactComponent as ArrowOutward } from "@material-design-icons/svg/outlined/arrow_outward.svg";
import metaPresetIcon from "../assets/metaPresetIcon.png";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import format from "date-fns/format";

export function ActivityAboutCard({
  activity,
  details,
}: {
  activity: Activity;
  details: {
    label: string;
    value: any;
  }[];
}) {
  function getFacebookLink() {
    if (
      activity.status === "ACTIVE" ||
      activity.status === "COMPLETED" ||
      activity.status === "ARCHIVED"
    ) {
      return (
        `https://adsmanager.facebook.com/adsmanager/manage/campaigns/edit?` +
        `act=${activity.MetaActivity.MetaAdAccount.metaAccountId}` +
        `&selected_campaign_ids=${activity.platformCampaignId}`
      );
    }
  }

  const facebookLink = getFacebookLink();

  return (
    <div className={styles.container}>
      <div className={styles.tile}>
        <div className={styles.infoContainer}>
          <img src={metaPresetIcon} alt="ico" className={styles.image} />
          <div className={styles.infoText}>
            {/* TODO: add facebook link */}
            <Link to={facebookLink} target="_blank" className={styles.link}>
              <span className={styles.segmentName}>{activity.name}</span>
              <ArrowOutward className={styles.arrowIco} />
            </Link>
            <span className={styles.segmentType}>
              Type: {snakeCaseToTitleCase(activity.MetaActivity.activityType)}
            </span>
          </div>
        </div>
        <div>
          <AboutItem
            label={"Created On"}
            value={format(new Date(activity.createdAt), "dd MMM, yyyy")}
          />
        </div>
      </div>
      <div className={styles.items}>
        {details.map((item, index) => (
          <AboutItem {...item} key={index} />
        ))}
      </div>
    </div>
  );
}
function AboutItem({ label, value }) {
  return (
    <div className={styles.aboutItem}>
      <span className={styles.aboutItemLabel}>{label}</span>
      <span className={styles.aboutItemValue}>{value}</span>
    </div>
  );
}
