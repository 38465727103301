import { InputFormik } from "src/components/InputFormik/InputFormik";
import Modal from "src/components/Modal/Modal";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";
import styles from "./PageWelcomeMessageTemplateEditorModal.module.scss";
import { ReactComponent as PlusIcon } from "@material-design-icons/svg/filled/add.svg";
import { ReactComponent as DeleteIcon } from "@material-symbols/svg-400/rounded/delete.svg";
import { getIn, useFormikContext } from "formik";
import { generateRandomNumber } from "src/lib/generateRandomNumber";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import staticImageOrVideo from "../assets/staticAsset.svg";
import carousel from "../assets/carousel.svg";
import { TabInput } from "../../campaign-wizard/components/TabInput";
import { MetaMessageOption } from "../../../graphql/generated/schema";
import { InstagramMessagePreview } from "./InstagramMessagePreview";
import { WhatsappPreview } from "./WhatsappPreview";
import { SmartphoneShell } from "./SmartphoneShell";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as WhatsappIcon } from "../assets/whatsapp-outline.svg";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const whatsappCustomerActionTabs = [
  {
    label: "Frequently asked questions",
    image: { src: carousel, size: 32 },
    value: "MESSAGE_OPTIONS" as const,
  },
  {
    label: "Pre-filled Message",
    image: { src: staticImageOrVideo, size: 32 },
    value: "PREFILLED_MESSAGE" as const,
  },
];

export function PageWelcomeMessageTemplateEditorModal<T = any>({
  baseKey,
  isOpen,
  onClose,
  onSubmit,
  metaPage,
  loading = false,
}: {
  baseKey: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  metaPage?: {
    name?: string;
    picture?: string;
  };
  loading?: boolean;
}) {
  const { values, setFieldValue } = useFormikContext<T>();
  const selectedPlatformKey = `${baseKey}.selectedPlatform`;
  const selectedPlatform = getIn(values, selectedPlatformKey);
  const pageWelcomeMessage = getIn(values, baseKey);
  const greetingKey = `${baseKey}.greeting`;
  const messageOptionsBaseKey = `${baseKey}.MessageOptions`;
  const messageOptions: MetaMessageOption[] =
    getIn(values, `${baseKey}.MessageOptions`) || [];

  function handleAddNewMessageOption() {
    setFieldValue(messageOptionsBaseKey, [
      ...messageOptions,
      {
        text: "",
        automatedReply: "",
        id: generateRandomNumber({ min: 100, max: 999 }).toString(),
      } as MetaMessageOption,
    ]);
  }

  function handleMessageOptionDelete(
    messageOption: Pick<MetaMessageOption, "id">,
  ) {
    setFieldValue(
      messageOptionsBaseKey,
      messageOptions.filter(
        (m: Pick<MetaMessageOption, "id">) => m.id !== messageOption.id,
      ),
    );
  }

  const selectedWhatsappCustomerAction =
    pageWelcomeMessage?.selectedWhatsappCustomerAction;

  function handleChangeActionType(
    value: "PREFILLED_MESSAGE" | "MESSAGE_OPTIONS",
  ) {
    setFieldValue(`${baseKey}.selectedWhatsappCustomerAction`, value);
  }

  return (
    <Modal
      maxWidth={"1200px"}
      isOpen={isOpen}
      noContainer
      onClose={onClose}
      title="Choose templates for response"
      subtitle="Create new segments using customizable templates."
      footer={{
        footerActions: null,
        footerContent: (
          <StickyFooter
            actions={
              <>
                <ButtonV2 onClick={onSubmit} loading={loading}>
                  Save & Exit
                </ButtonV2>
                <ButtonV2 onClick={onClose} variant="outline" loading={loading}>
                  Back
                </ButtonV2>
              </>
            }
          />
        ),
      }}
    >
      <section className={styles.messageTemplateEditor}>
        <div className={styles.formArea}>
          <div className={styles.greetingSection}>
            <div className={styles.header}>
              <p className={styles.copyText}>Add name to this template</p>
            </div>
            <InputFormik
              width="full"
              placeholder="Name for the template"
              name={`${baseKey}.name`}
            />
          </div>
          <div className={styles.greetingSection}>
            <div className={styles.header}>
              <h2 className={styles.subheading}>Greeting</h2>
              <p className={styles.copyText}>
                Welcome people to the conversation after they've tapped on your
                ad
              </p>
            </div>
            <InputFormik
              width="full"
              placeholder="Write a greeting text"
              name={greetingKey}
              showError={true}
            />
          </div>
          <div className={styles.faqSection}>
            <div className={styles.header}>
              <h2 className={styles.subheading}>Customer actions</h2>
              <p className={styles.copyText}>
                Suggest questions or messages that people can send.
              </p>
            </div>
            {selectedPlatform === "WHATSAPP" && (
              <div>
                <TabInput
                  onBlur={() => {}}
                  onChange={handleChangeActionType}
                  value={selectedWhatsappCustomerAction}
                  tabs={whatsappCustomerActionTabs}
                  justifyContent="center"
                />
              </div>
            )}

            {selectedWhatsappCustomerAction === "PREFILLED_MESSAGE" ? (
              <>
                <InputFormik
                  width="full"
                  label="Pre-filled Message"
                  name={`${baseKey}.prefilledText`}
                  maxCharacterCount={80}
                  showCharacterCount={true}
                  helpText="This message will be pre-filled"
                />
              </>
            ) : (
              <>
                {messageOptions.map((messageOption, index) => (
                  <div className={styles.messageOption}>
                    <div className={styles.formControl}>
                      <InputFormik
                        width="full"
                        name={`${messageOptionsBaseKey}[${index}].text`}
                        label={`Option ${index + 1}`}
                      />
                      {selectedPlatform === "INSTAGRAM" && (
                        <MultilineInputFormik
                          rows={3}
                          name={`${messageOptionsBaseKey}[${index}].automatedReply`}
                          label="Automated response - Optional"
                        />
                      )}
                    </div>
                    {messageOptions.length > 1 && (
                      <button
                        className={styles.deleteMessageOption}
                        type="button"
                        onClick={() => handleMessageOptionDelete(messageOption)}
                      >
                        <DeleteIcon width={20} height={20} />
                      </button>
                    )}
                  </div>
                ))}

                <div className={styles.addMoreQuestionBtn}>
                  <ButtonV2
                    onClick={handleAddNewMessageOption}
                    size="small"
                    variant="outline"
                  >
                    <span className={styles.addNewQuestionText}>
                      Add more question <PlusIcon width={16} height={16} />
                    </span>
                  </ButtonV2>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.smartphonePreview}>
          <SmartphoneShell screenSize="35rem">
            {selectedPlatform === "WHATSAPP" ? (
              <WhatsappPreview
                selectedPreviewTab={selectedWhatsappCustomerAction}
                metaPageName={metaPage?.name}
                metaPagePicture={metaPage?.picture}
                pageWelcomeMessage={pageWelcomeMessage}
              />
            ) : (
              <InstagramMessagePreview
                metaPageName={metaPage?.name}
                metaPagePicture={metaPage?.picture}
                pageWelcomeMessage={pageWelcomeMessage}
              />
            )}
          </SmartphoneShell>
        </div>
        <div className={styles.sidebar}>
          {(["INSTAGRAM", "WHATSAPP"] as const).map((v) => (
            <button
              type="button"
              onClick={() => {
                setFieldValue(selectedPlatformKey, v);
              }}
            >
              {v === "INSTAGRAM" ? (
                <InstagramIcon
                  width={24}
                  height={24}
                  fill={
                    selectedPlatform === v
                      ? "var(--color-primary)"
                      : "currentColor"
                  }
                />
              ) : (
                <WhatsappIcon
                  width={24}
                  height={24}
                  fill={
                    selectedPlatform === v
                      ? "var(--color-primary)"
                      : "currentColor"
                  }
                />
              )}
            </button>
          ))}
        </div>
      </section>
    </Modal>
  );
}
