import styles from "./MetaIntegrationInstructions.module.scss";
import WhatDoesItDo from "../assets/whatDoesItDo.png";
import BeforeYouStart from "../assets/beforeYouStart.png";
import MetaIntegrationBanner from "../assets/metaIntegrationBanner.png";
import { IntegrationBanner } from "src/modules/global/components/IntegrationBanner";
import { IntegrationNeedHelp } from "src/modules/global/components/IntegrationNeedHelp";
import enableMetaIntegration from "../functions/enableMetaIntegration";
import { IntegrationSteps } from "src/modules/global/components/IntegrationSteps";
import meta from "../assets/meta.svg";
import metaBanner from "../assets/metaBanner.svg";

const beforeArr = [
  "You'll need an active Facebook account that manages at least one Facebook Page in order to connect this integration.",
  "An Instagram business account must be connected to your Facebook account to access all available features.",
  "When you set up the integration, you must approve each of Facebook's permission requests. To change the permissions, first disconnect, then reconnect the integration.",
  <span>
    Using this integration for ads will create a Facebook ad account for you
    that's exclusively associated with one specific{" "}
    <strong className={styles.imp}>Macro Client account</strong>. Multiple{" "}
    <strong className={styles.imp}>Macro Client account </strong>
    can't use the same Facebook account for ads.
  </span>,
  "Some Facebook and Instagram features may be limited depending on your plan.",
];

const whatArr = [
  "Create Facebook and Instagram ads",
  "Publish and schedule Campaigns and Activity",
  "Single Platform for manage your various kinds of Campaigns and Ads types for Facebook",
  "Manage your Campaigns, create multiple types of Ads like Single Single/Video, Carousal Ads and Dynamic Ads.",
  "Manage how your Campaign is performing with respect to certain audience types",
  "Create Audiences at Facebook level and Manage Budgetings.",
  "Create Audiences Sets and Schedule ads",
];

export default function MetaIntegrationInstructions() {
  return (
    <div className={styles.page}>
      <IntegrationBanner
        title="Integrate Meta platform"
        subtitle={
          "Connect Meta Platform to publish facebook and Instagram, ads, add signup forms, and access your Instagram ads in Macro's Campaign builder. Manage Facebook and Audiences and Accounts"
        }
        action={{
          label: "Integrate Meta",
          onClick: enableMetaIntegration,
        }}
        background={`url(${metaBanner})`}
        image={meta}
      />

      <IntegrationSteps
        heading={"Before you start"}
        steps={beforeArr}
        image={BeforeYouStart}
      />
      <IntegrationSteps
        heading={"What does it do?"}
        steps={whatArr}
        image={WhatDoesItDo}
        subHeading="Here are the following"
      />
      <IntegrationNeedHelp />
    </div>
  );
}
