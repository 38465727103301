import { MetaActivityAd } from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";

export function getAdTypeText(ad: MetaActivityAd) {
  if (ad.adType === "CUSTOM_CREATIVE") {
    return ad.LineItems.length > 1 ? "Carousel" : "Single Image/Video";
  }

  return snakeCaseToTitleCase(ad?.adType);
}
