import {
  NotificationReadStatus,
  useNotificationsQuery,
} from "src/graphql/generated/schema";
import NotificationList from "./NotificationList";
import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";
import { useState } from "react";
import { INotification } from "../misc/notification";
import { NotificationTabs } from "./NotificationTabs";

interface INotificationListLoader {
  selectedNotification: INotification | null;
  setSelectedNotification: (i: INotification) => void;
}

export function NotificationListLoader({
  selectedNotification,
  setSelectedNotification,
}: INotificationListLoader) {
  const [selectedReadStatus, setSelectedReadStatus] =
    useState<NotificationReadStatus>("ALL");

  const { data, loading, error, fetchMore } = useNotificationsQuery({
    variables: {
      filter: { readStatus: selectedReadStatus },
      take: 12,
    },
  });

  if (loading) {
    return (
      <>
        <NotificationTabs
          selectedReadStatus={selectedReadStatus}
          setSelectedReadStatus={setSelectedReadStatus}
        />
        <Spinner height="screen" />
      </>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const notifications = data.notifications.edges.map((n) => n.node);

  async function fetchMoreNotifications() {
    await fetchMore({
      variables: {
        cursor: data.notifications.edges.at(-1)?.cursor,
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.notifications?.edges ?? [];
        const oldEdges = previousResult?.notifications?.edges ?? [];

        return {
          notifications: {
            ...fetchMoreResult.notifications,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  return (
    <>
      <NotificationTabs
        selectedReadStatus={selectedReadStatus}
        setSelectedReadStatus={setSelectedReadStatus}
      />

      <NotificationList
        hasMoreNotifications={data.notifications.pageInfo.hasNextPage}
        fetchMoreNotifications={fetchMoreNotifications}
        notifications={notifications}
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
      />
    </>
  );
}
