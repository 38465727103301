export const currencyList = [
  {
    country: "USD",
    currencyName: "US Dollar",
    symbol: "$",
    code: "USD",
  },
  {
    country: "CAD",
    currencyName: "Canadian Dollar",
    symbol: "CA$",
    code: "CAD",
  },
  {
    country: "EUR",
    currencyName: "Euro",
    symbol: "€",
    code: "EUR",
  },
  {
    country: "AED",
    currencyName: "United Arab Emirates Dirham",
    symbol: "AED",
    code: "AED",
  },
  {
    country: "AFN",
    currencyName: "Afghan Afghani",
    symbol: "Af",
    code: "AFN",
  },
  {
    country: "ALL",
    currencyName: "Albanian Lek",
    symbol: "ALL",
    code: "ALL",
  },
  {
    country: "AMD",
    currencyName: "Armenian Dram",
    symbol: "AMD",
    code: "AMD",
  },
  {
    country: "ARS",
    currencyName: "Argentine Peso",
    symbol: "AR$",
    code: "ARS",
  },
  {
    country: "AUD",
    currencyName: "Australian Dollar",
    symbol: "AU$",
    code: "AUD",
  },
  {
    country: "AZN",
    currencyName: "Azerbaijani Manat",
    symbol: "man.",
    code: "AZN",
  },
  {
    country: "BAM",
    currencyName: "Bosnia-Herzegovina Convertible Mark",
    symbol: "KM",
    code: "BAM",
  },
  {
    country: "BDT",
    currencyName: "Bangladeshi Taka",
    symbol: "Tk",
    code: "BDT",
  },
  {
    country: "BGN",
    currencyName: "Bulgarian Lev",
    symbol: "BGN",
    code: "BGN",
  },
  {
    country: "BHD",
    currencyName: "Bahraini Dinar",
    symbol: "BD",
    code: "BHD",
  },
  {
    country: "BIF",
    currencyName: "Burundian Franc",
    symbol: "FBu",
    code: "BIF",
  },
  {
    country: "BND",
    currencyName: "Brunei Dollar",
    symbol: "BN$",
    code: "BND",
  },
  {
    country: "BOB",
    currencyName: "Bolivian Boliviano",
    symbol: "Bs",
    code: "BOB",
  },
  {
    country: "BRL",
    currencyName: "Brazilian Real",
    symbol: "R$",
    code: "BRL",
  },
  {
    country: "BWP",
    currencyName: "Botswanan Pula",
    symbol: "BWP",
    code: "BWP",
  },
  {
    country: "BYN",
    currencyName: "Belarusian Ruble",
    symbol: "Br",
    code: "BYN",
  },
  {
    country: "BZD",
    currencyName: "Belize Dollar",
    symbol: "BZ$",
    code: "BZD",
  },
  {
    country: "CDF",
    currencyName: "Congolese Franc",
    symbol: "CDF",
    code: "CDF",
  },
  {
    country: "CHF",
    currencyName: "Swiss Franc",
    symbol: "CHF",
    code: "CHF",
  },
  {
    country: "CLP",
    currencyName: "Chilean Peso",
    symbol: "CL$",
    code: "CLP",
  },
  {
    country: "CNY",
    currencyName: "Chinese Yuan",
    symbol: "CN¥",
    code: "CNY",
  },
  {
    country: "COP",
    currencyName: "Colombian Peso",
    symbol: "CO$",
    code: "COP",
  },
  {
    country: "CRC",
    currencyName: "Costa Rican Colón",
    symbol: "₡",
    code: "CRC",
  },
  {
    country: "CUP",
    currencyName: "Cuban peso",
    symbol: "MN$",
    code: "CUP",
  },
  {
    country: "CVE",
    currencyName: "Cape Verdean Escudo",
    symbol: "CV$",
    code: "CVE",
  },
  {
    country: "CZK",
    currencyName: "Czech Republic Koruna",
    symbol: "Kč",
    code: "CZK",
  },
  {
    country: "DJF",
    currencyName: "Djiboutian Franc",
    symbol: "Fdj",
    code: "DJF",
  },
  {
    country: "DKK",
    currencyName: "Danish Krone",
    symbol: "Dkr",
    code: "DKK",
  },
  {
    country: "DOP",
    currencyName: "Dominican Peso",
    symbol: "RD$",
    code: "DOP",
  },
  {
    country: "DZD",
    currencyName: "Algerian Dinar",
    symbol: "DA",
    code: "DZD",
  },
  {
    country: "EEK",
    currencyName: "Estonian Kroon",
    symbol: "Ekr",
    code: "EEK",
  },
  {
    country: "EGP",
    currencyName: "Egyptian Pound",
    symbol: "EGP",
    code: "EGP",
  },
  {
    country: "ERN",
    currencyName: "Eritrean Nakfa",
    symbol: "Nfk",
    code: "ERN",
  },
  {
    country: "ETB",
    currencyName: "Ethiopian Birr",
    symbol: "Br",
    code: "ETB",
  },
  {
    country: "GBP",
    currencyName: "British Pound Sterling",
    symbol: "£",
    code: "GBP",
  },
  {
    country: "GEL",
    currencyName: "Georgian Lari",
    symbol: "GEL",
    code: "GEL",
  },
  {
    country: "GHS",
    currencyName: "Ghanaian Cedi",
    symbol: "GH₵",
    code: "GHS",
  },
  {
    country: "GNF",
    currencyName: "Guinean Franc",
    symbol: "FG",
    code: "GNF",
  },
  {
    country: "GTQ",
    currencyName: "Guatemalan Quetzal",
    symbol: "GTQ",
    code: "GTQ",
  },
  {
    country: "HKD",
    currencyName: "Hong Kong Dollar",
    symbol: "HK$",
    code: "HKD",
  },
  {
    country: "HNL",
    currencyName: "Honduran Lempira",
    symbol: "HNL",
    code: "HNL",
  },
  {
    country: "HRK",
    currencyName: "Croatian Kuna",
    symbol: "kn",
    code: "HRK",
  },
  {
    country: "HUF",
    currencyName: "Hungarian Forint",
    symbol: "Ft",
    code: "HUF",
  },
  {
    country: "IDR",
    currencyName: "Indonesian Rupiah",
    symbol: "Rp",
    code: "IDR",
  },
  {
    country: "ILS",
    currencyName: "Israeli New Sheqel",
    symbol: "₪",
    code: "ILS",
  },
  {
    country: "INR",
    currencyName: "Indian Rupee",
    symbol: "₹",
    code: "INR",
  },
  {
    country: "IQD",
    currencyName: "Iraqi Dinar",
    symbol: "IQD",
    code: "IQD",
  },
  {
    country: "IRR",
    currencyName: "Iranian Rial",
    symbol: "IRR",
    code: "IRR",
  },
  {
    country: "ISK",
    currencyName: "Icelandic Króna",
    symbol: "Ikr",
    code: "ISK",
  },
  {
    country: "JMD",
    currencyName: "Jamaican Dollar",
    symbol: "J$",
    code: "JMD",
  },
  {
    country: "JOD",
    currencyName: "Jordanian Dinar",
    symbol: "JD",
    code: "JOD",
  },
  {
    country: "JPY",
    currencyName: "Japanese Yen",
    symbol: "¥",
    code: "JPY",
  },
  {
    country: "KES",
    currencyName: "Kenyan Shilling",
    symbol: "Ksh",
    code: "KES",
  },
  {
    country: "KHR",
    currencyName: "Cambodian Riel",
    symbol: "KHR",
    code: "KHR",
  },
  {
    country: "KMF",
    currencyName: "Comorian Franc",
    symbol: "CF",
    code: "KMF",
  },
  {
    country: "KRW",
    currencyName: "South Korean Won",
    symbol: "₩",
    code: "KRW",
  },
  {
    country: "KWD",
    currencyName: "Kuwaiti Dinar",
    symbol: "KD",
    code: "KWD",
  },
  {
    country: "KZT",
    currencyName: "Kazakhstani Tenge",
    symbol: "KZT",
    code: "KZT",
  },
  {
    country: "LBP",
    currencyName: "Lebanese Pound",
    symbol: "L.L.",
    code: "LBP",
  },
  {
    country: "LKR",
    currencyName: "Sri Lankan Rupee",
    symbol: "SLRs",
    code: "LKR",
  },
  {
    country: "LTL",
    currencyName: "Lithuanian Litas",
    symbol: "Lt",
    code: "LTL",
  },
  {
    country: "LVL",
    currencyName: "Latvian Lats",
    symbol: "Ls",
    code: "LVL",
  },
  {
    country: "LYD",
    currencyName: "Libyan Dinar",
    symbol: "LD",
    code: "LYD",
  },
  {
    country: "MAD",
    currencyName: "Moroccan Dirham",
    symbol: "MAD",
    code: "MAD",
  },
  {
    country: "MDL",
    currencyName: "Moldovan Leu",
    symbol: "MDL",
    code: "MDL",
  },
  {
    country: "MGA",
    currencyName: "Malagasy Ariary",
    symbol: "MGA",
    code: "MGA",
  },
  {
    country: "MKD",
    currencyName: "Macedonian Denar",
    symbol: "MKD",
    code: "MKD",
  },
  {
    country: "MMK",
    currencyName: "Myanma Kyat",
    symbol: "MMK",
    code: "MMK",
  },
  {
    country: "MOP",
    currencyName: "Macanese Pataca",
    symbol: "MOP$",
    code: "MOP",
  },
  {
    country: "MUR",
    currencyName: "Mauritian Rupee",
    symbol: "MURs",
    code: "MUR",
  },
  {
    country: "MXN",
    currencyName: "Mexican Peso",
    symbol: "MX$",
    code: "MXN",
  },
  {
    country: "MYR",
    currencyName: "Malaysian Ringgit",
    symbol: "RM",
    code: "MYR",
  },
  {
    country: "MZN",
    currencyName: "Mozambican Metical",
    symbol: "MTn",
    code: "MZN",
  },
  {
    country: "NAD",
    currencyName: "Namibian Dollar",
    symbol: "N$",
    code: "NAD",
  },
  {
    country: "NGN",
    currencyName: "Nigerian Naira",
    symbol: "₦",
    code: "NGN",
  },
  {
    country: "NIO",
    currencyName: "Nicaraguan Córdoba",
    symbol: "C$",
    code: "NIO",
  },
  {
    country: "NOK",
    currencyName: "Norwegian Krone",
    symbol: "Nkr",
    code: "NOK",
  },
  {
    country: "NPR",
    currencyName: "Nepalese Rupee",
    symbol: "NPRs",
    code: "NPR",
  },
  {
    country: "NZD",
    currencyName: "New Zealand Dollar",
    symbol: "NZ$",
    code: "NZD",
  },
  {
    country: "OMR",
    currencyName: "Omani Rial",
    symbol: "OMR",
    code: "OMR",
  },
  {
    country: "PAB",
    currencyName: "Panamanian Balboa",
    symbol: "B/.",
    code: "PAB",
  },
  {
    country: "PEN",
    currencyName: "Peruvian Nuevo Sol",
    symbol: "S/.",
    code: "PEN",
  },
  {
    country: "PHP",
    currencyName: "Philippine Peso",
    symbol: "₱",
    code: "PHP",
  },
  {
    country: "PKR",
    currencyName: "Pakistani Rupee",
    symbol: "PKRs",
    code: "PKR",
  },
  {
    country: "PLN",
    currencyName: "Polish Zloty",
    symbol: "zł",
    code: "PLN",
  },
  {
    country: "PYG",
    currencyName: "Paraguayan Guarani",
    symbol: "₲",
    code: "PYG",
  },
  {
    country: "QAR",
    currencyName: "Qatari Rial",
    symbol: "QR",
    code: "QAR",
  },
  {
    country: "RON",
    currencyName: "Romanian Leu",
    symbol: "RON",
    code: "RON",
  },
  {
    country: "RSD",
    currencyName: "Serbian Dinar",
    symbol: "din.",
    code: "RSD",
  },
  {
    country: "RUB",
    currencyName: "Russian Ruble",
    symbol: "RUB",
    code: "RUB",
  },
  {
    country: "RWF",
    currencyName: "Rwandan Franc",
    symbol: "RWF",
    code: "RWF",
  },
  {
    country: "SAR",
    currencyName: "Saudi Riyal",
    symbol: "SR",
    code: "SAR",
  },
  {
    country: "SDG",
    currencyName: "Sudanese Pound",
    symbol: "SDG",
    code: "SDG",
  },
  {
    country: "SEK",
    currencyName: "Swedish Krona",
    symbol: "Skr",
    code: "SEK",
  },
  {
    country: "SGD",
    currencyName: "Singapore Dollar",
    symbol: "S$",
    code: "SGD",
  },
  {
    country: "SOS",
    currencyName: "Somali Shilling",
    symbol: "Ssh",
    code: "SOS",
  },
  {
    country: "SYP",
    currencyName: "Syrian Pound",
    symbol: "SY£",
    code: "SYP",
  },
  {
    country: "THB",
    currencyName: "Thai Baht",
    symbol: "฿",
    code: "THB",
  },
  {
    country: "TND",
    currencyName: "Tunisian Dinar",
    symbol: "DT",
    code: "TND",
  },
  {
    country: "TOP",
    currencyName: "Tongan Paʻanga",
    symbol: "T$",
    code: "TOP",
  },
  {
    country: "TRY",
    currencyName: "Turkish Lira",
    symbol: "TL",
    code: "TRY",
  },
  {
    country: "TTD",
    currencyName: "Trinidad and Tobago Dollar",
    symbol: "TT$",
    code: "TTD",
  },
  {
    country: "TWD",
    currencyName: "New Taiwan Dollar",
    symbol: "NT$",
    code: "TWD",
  },
  {
    country: "TZS",
    currencyName: "Tanzanian Shilling",
    symbol: "TSh",
    code: "TZS",
  },
  {
    country: "UAH",
    currencyName: "Ukrainian Hryvnia",
    symbol: "₴",
    code: "UAH",
  },
  {
    country: "UGX",
    currencyName: "Ugandan Shilling",
    symbol: "USh",
    code: "UGX",
  },
  {
    country: "UYU",
    currencyName: "Uruguayan Peso",
    symbol: "$U",
    code: "UYU",
  },
  {
    country: "UZS",
    currencyName: "Uzbekistan Som",
    symbol: "UZS",
    code: "UZS",
  },
  {
    country: "VEF",
    currencyName: "Venezuelan Bolívar",
    symbol: "Bs.F.",
    code: "VEF",
  },
  {
    country: "VND",
    currencyName: "Vietnamese Dong",
    symbol: "₫",
    code: "VND",
  },
  {
    country: "XAF",
    currencyName: "CFA Franc BEAC",
    symbol: "FCFA",
    code: "XAF",
  },
  {
    country: "XCD",
    currencyName: "Eastern Caribbean dollar",
    symbol: "EC$",
    code: "XCD",
  },
  {
    country: "XOF",
    currencyName: "CFA Franc BCEAO",
    symbol: "CFA",
    code: "XOF",
  },
  {
    country: "YER",
    currencyName: "Yemeni Rial",
    symbol: "YR",
    code: "YER",
  },
  {
    country: "ZAR",
    currencyName: "South African Rand",
    symbol: "R",
    code: "ZAR",
  },
  {
    country: "ZMK",
    currencyName: "Zambian Kwacha",
    symbol: "ZK",
    code: "ZMK",
  },
  {
    country: "ZWL",
    currencyName: "Zimbabwean Dollar",
    symbol: "ZWL$",
    code: "ZWL",
  },
];
