import styles from "./WhatsappBusinessDetails.module.scss";
import WhatsappBusinessBg from "../assets/whatsappBusinessAccountBg.png";
import {
  useWhatsappIntegrationRevokeMutation,
  WhatsappBusinessAccountAndPhoneNumberQuery,
} from "src/graphql/generated/schema";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { useNavigate } from "react-router-dom";

interface IWhatsappBusinessDetails {
  details: WhatsappBusinessAccountAndPhoneNumberQuery;
}

export function WhatsappBusinessDetails({ details }: IWhatsappBusinessDetails) {
  const navigate = useNavigate();
  const [whatsappIntegrationRevoke, { loading }] =
    useWhatsappIntegrationRevokeMutation();

  return (
    <div className={styles.container}>
      <div
        className={styles.headingContainer}
        style={{ backgroundImage: `url(${WhatsappBusinessBg})` }}
      >
        <h2 className={styles.heading}>WABA Details</h2>
        <p className={styles.description}>
          These details are your WaBa account details approved by WhatsApp.
          These are non-editable. Some of these details will be shown in your
          WhatsApp business profile to your users.
        </p>
        <hr className={styles.hr} />
      </div>
      <div className={styles.itemsContainer}>
        <div className={styles.item}>
          <span className={styles.label}>DISPLAY NAME</span>
          <span>{details.whatsappPhoneNumber.verifiedName}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>BUSINESS ENTITY NAME</span>
          <span>{details.whatsappBusinessAccount.name}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>WABA ID</span>
          <span>{details.whatsappBusinessAccount.id}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>PHONE NUMBER</span>
          <span>{details.whatsappPhoneNumber.displayPhoneNumber}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>TIME ZONE</span>
          <span>GMT+05:30 (Asia/Kolkata)</span>
        </div>
        <div className={styles.fullSizeRow}>
          <ButtonV2
            loading={loading}
            onClick={async () => {
              await whatsappIntegrationRevoke();
              navigate("/settings/integrations/whatsapp/setup");
            }}
            size="small"
            isDestructive
            variant="outline"
          >
            Revoke Integration
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}
