import StatCard from "src/components/StatCard/StatCard";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";

const statCardOptions: {
  title: string;
  subtitle: string;
  valueType: "NUMBER" | "CURRENCY" | "PERCENTAGE";
  valueKey: keyof GrowAggregateType;
}[] = [
  {
    title: "Orders Count",
    subtitle: "Total order counts",
    valueType: "NUMBER" as const,
    valueKey: "countPurchase",
  },
  {
    title: "Avg. Order Value",
    subtitle: "average order value",
    valueType: "CURRENCY" as const,
    valueKey: "costPerAddToCart",
  },
  {
    title: "Add to carts",
    subtitle: "No. of times items were added",
    valueType: "NUMBER" as const,
    valueKey: "countAddToCart",
  },
  {
    title: "Initiate Checkouts",
    subtitle: "No. of times checkout has started",
    valueType: "NUMBER" as const,
    valueKey: "countInitiateCheckout",
  },
  {
    title: "CPM",
    subtitle: "CPM in facebook activity",
    valueType: "CURRENCY" as const,
    valueKey: "cpm",
  },
];

interface Props {
  activityStat: GrowAggregateType;
}

export function ActivitySingleStatCards({ activityStat }: Props) {
  return (
    <>
      {statCardOptions.map((statOption) => (
        <StatCard {...statOption} value={activityStat?.[statOption.valueKey]} />
      ))}
    </>
  );
}
