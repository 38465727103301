import * as Yup from "yup";

export const facebookSettingsSchema = Yup.object({
  adAccountId: Yup.string().required("Ad account is required"),
  pixelId: Yup.string().required("Pixel is required").nullable(),
  instagramPageId: Yup.string().required("Instagram is required").nullable(),
  pageId: Yup.string().required("Meta pages is required").nullable(),
  conversionDomain: Yup.string()
    .test(
      "isValidConversionDomain",
      "Should be a valid domain name",
      async (value) => {
        const validConversionDomainRegex =
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[\w.-]*)*/g;
        if (!value) {
          return true;
        }
        return validConversionDomainRegex.test(value);
      }
    )
    .notRequired(),
});
