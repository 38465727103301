import {
  EmailDesignTemplatesGlobalDocument,
  TemplatesDocument,
  useEmailDesignTemplateCreateMutation,
  useEmailDesignTemplateQuery,
  useEmailDesignTemplateUpdateMutation,
} from "src/graphql/generated/schema";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmailTemplateEditor from "src/modules/global/components/EmailTemplateEditor";
import Header from "src/components/Header/Header";
import { toast } from "react-hot-toast";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function EmailBuilderPage() {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const {
    data: templateData,
    loading: templateLoading,
    error: templateError,
  } = useEmailDesignTemplateQuery({
    variables: {
      emailDesignTemplateId: templateId,
    },
  });
  const [emailDesignTemplateUpdate, { loading: updateLoading }] =
    useEmailDesignTemplateUpdateMutation();

  const [emailDesignTemplateCreate, { loading: copyLoading }] =
    useEmailDesignTemplateCreateMutation();

  const [templateName, setTemplateName] = useState("New Template");

  // to update name
  useEffect(() => {
    if (!templateData) return;
    if (!templateData.emailDesignTemplate) return;
    setTemplateName(templateData.emailDesignTemplate.name);
  }, [templateData, templateLoading]);

  async function handleSave(html: string, json: string) {
    try {
      // This page will only show emailDesignTemplate which are already saved hence only update and create feature
      if (!templateData.emailDesignTemplate?.clientId) {
        await emailDesignTemplateCreate({
          variables: {
            input: {
              designHtml: html,
              designJson: json,
              name: templateName.trim(),
              isSaved: true,
            },
          },
        });
        toast.success("Created a new template");
        navigate("/templates");
        return;
      }

      await emailDesignTemplateUpdate({
        variables: {
          input: {
            designHtml: html,
            designJson: json,
            name: templateName.trim(),
            id: templateId,
          },
        },
        refetchQueries: [
          {
            query: EmailDesignTemplatesGlobalDocument,
          },
          {
            query: TemplatesDocument,
          },
        ],
        onCompleted: (data) => {
          if (data) {
            toast.success("Updated template successfully");
          }
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  }

  if (templateLoading) {
    return <PageLoader />;
  }

  if (templateError) {
    return <ErrorHandler error={templateError} />;
  }

  return (
    <div
      style={{
        height: "calc(100vh - 72px)",
      }}
    >
      <Header title="Email Template Builder" mode="DISTRACTION_FREE" />
      <EmailTemplateEditor
        templateName={templateName}
        setTemplateName={setTemplateName}
        templateId={templateId === "new" ? "" : templateId}
        handleSave={handleSave}
        loading={updateLoading || copyLoading}
      />
    </div>
  );
}
