import React, { ReactNode, useEffect, useRef, useState } from "react";

import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { ReactComponent as DeviceReset } from "@material-symbols/svg-400/rounded/device_reset.svg";
import { IconButton } from "src/components/IconButton/IconButton";
import { ReactComponent as Remove } from "@material-design-icons/svg/outlined/remove.svg";
import styles from "./PanAndZoom.module.scss";

/**
 * This component implements pan and zoom functionality using dom elements and not canvas.
 * A container ref is initialized which acts as canvas and then there is the itemsContainer which
 * contains the all the items.
 *
 * Events for drag and wheel up and down are on container component which changes the scale (for zoom) and top / left
 * for the items container
 *
 */
export interface IPanAndZoom {
  children: ReactNode;
  config?: {
    zoomBy: number;
    dragInertia: number;
  };
}

export function PanAndZoom({
  children,
  config = { zoomBy: 0.1, dragInertia: 12 },
}: IPanAndZoom) {
  const { zoomBy, dragInertia } = config;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemsContainerRef = useRef<HTMLDivElement | null>(null);
  const [scale, setScale] = useState(1);

  const [{ left, top }, setPosition] = useState({
    left: 0,
    top: 0,
  });

  const [{ clientX, clientY }, setClient] = useState({
    clientX: 0,
    clientY: 0,
  });

  // TODO: handle default zoom according to the children
  function zoomInOrOut(value: "zoomIn" | "zoomOut" | "reset") {
    if (value === "reset") {
      setScale(1);
      return;
    }

    if (value === "zoomIn") {
      // max scale
      if (scale >= 2.5) return;
      setScale(scale + zoomBy);
    } else {
      // min scale
      if (scale <= 0.2) return;
      setScale(scale - zoomBy);
    }
  }

  // aligns to center of canvas by default
  function resetFlowContainerPosition() {
    const { clientHeight, clientWidth } = containerRef.current;
    const {
      clientHeight: itemsContainerHeight,
      clientWidth: itemsContainerWidth,
    } = itemsContainerRef.current;

    const top = (clientHeight - itemsContainerHeight) / 2;
    const left = (clientWidth - itemsContainerWidth) / 2;

    setPosition({
      left: left,
      top: top,
    });
  }

  useEffect(() => {
    zoomInOrOut("reset");
    resetFlowContainerPosition();
  }, []);

  function handleOnWheel(e: React.WheelEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (e.shiftKey) {
      if (e.deltaY < 0) {
        zoomInOrOut("zoomIn");
      }
      if (e.deltaY > 0) {
        zoomInOrOut("zoomOut");
      }
      return;
    }

    const deltaX =
      itemsContainerRef.current.offsetLeft - e.deltaX / dragInertia;
    const deltaY = itemsContainerRef.current.offsetTop - e.deltaY / dragInertia;

    if (deltaX > 800 || deltaX < -500) {
      return;
    }

    if (deltaY > 800 || deltaY < -500) {
      return;
    }
    setPosition({
      left: deltaX,
      top: deltaY,
    });
  }

  // hides preview image with a empty div
  function handleOnDragStart(e: React.DragEvent<HTMLDivElement>) {
    const preview = document.createElement("div");
    preview.style.display = "none";
    e.dataTransfer.setDragImage(preview, 0, 0);

    setClient({ clientX: e.clientX, clientY: e.clientY });
  }

  function handleOnDrag(e: React.DragEvent<HTMLDivElement>) {
    if (e.clientX && e.clientY) {
      const deltaX =
        itemsContainerRef.current.offsetLeft -
        (clientX - e.clientX) / dragInertia;
      const deltaY =
        itemsContainerRef.current.offsetTop -
        (clientY - e.clientY) / dragInertia;

      // max limits
      if (deltaX > 2000 || deltaX < -500) {
        return;
      }

      // max limits
      if (deltaY > 2000 || deltaY < -1000) {
        return;
      }
      setPosition({
        left: deltaX,
        top: deltaY,
      });
    }
  }

  return (
    <>
      <div
        ref={containerRef}
        className={styles.main}
        onWheel={handleOnWheel}
        draggable
        onDragStart={handleOnDragStart}
        onDrag={handleOnDrag}
      >
        <div
          ref={itemsContainerRef}
          style={{
            top: top,
            left: left,
            transform: `scale(${scale})`,
          }}
          onWheel={handleOnWheel}
          draggable
          onDragStart={handleOnDragStart}
          onDrag={handleOnDrag}
          className={styles.itemsContainer}
        >
          {children}
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <IconButton
          onClick={() => zoomInOrOut("zoomOut")}
          shape="square"
          style="outline"
          icon={<Remove />}
        />
        <IconButton
          onClick={() => zoomInOrOut("zoomIn")}
          style="outline"
          shape="square"
          icon={<Add />}
        />
        <IconButton
          onClick={() => {
            zoomInOrOut("reset");
            resetFlowContainerPosition();
          }}
          style="outline"
          shape="square"
          icon={<DeviceReset />}
        />
      </div>
    </>
  );
}
