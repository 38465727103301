import {
  ActivityPlatformEnum,
  ActivityStatusEnum,
} from "src/graphql/generated/schema";
import styles from "./CampaignActivityItem.module.scss";
import { ActivityItemLogo } from "./ActivityItemLogo";
import format from "date-fns/format";
import { ReactComponent as LocalMallIcon } from "@material-symbols/svg-400/rounded/local_mall.svg";
import { ReactComponent as Coins } from "../assets/coins.svg";
import { ReactComponent as TrackPadInputIcon } from "@material-symbols/svg-400/rounded/trackpad_input.svg";
import { ReactComponent as ProgressActivityIcon } from "@material-symbols/svg-400/rounded/progress_activity.svg";
import { useActivityAggregatedStats } from "../../global/functions/useActivityAggregatedStats";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { GrowAggregateType } from "src/modules/global/misc/GrowAggregateType";
import { ActivityStatusBadge } from "./ActivityStatusBadge";
import { ActivityItemMoreOptions } from "./ActivityItemMoreOptions";
import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Tooltip from "src/components/Tooltip/Tooltip";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface ICampaignActivityItem {
  campaignId: string;
  activity: {
    id?: string;
    name: string;
    platform: ActivityPlatformEnum;
    createdAt: string;
    status: ActivityStatusEnum;
  };
  // only for campaign presets
  campaignPresetId?: string;
  activityIndex?: number;
}

export function CampaignActivityItem({
  campaignPresetId,
  activityIndex,
  campaignId,
  activity,
}: ICampaignActivityItem) {
  const [isActivityUpdating, setIsActivityUpdating] = useState(false);
  const formattedCreatedAt = format(
    new Date(activity.createdAt),
    "dd MMMM, yyyy"
  );

  const link =
    activity.status === "DRAFT"
      ? `/campaigns/new?presetId=${campaignPresetId}`
      : `/campaigns/${campaignId}/activities/${activity.id}`;

  return (
    <div
      className={classNames(styles.activityItem, {
        [styles.isActivityUpdating]: isActivityUpdating,
      })}
    >
      <Link to={link} className={styles.activityNameAndIcon}>
        <div>
          <ActivityItemLogo platform={activity.platform} />
        </div>
        <div className={styles.activityNameWrapper}>
          <h4 className={styles.activityName}>{activity.name}</h4>
          <time className={styles.createdAt} dateTime={activity.createdAt}>
            {formattedCreatedAt}
          </time>
        </div>

        {isActivityUpdating && (
          <ProgressActivityIcon
            className={styles.loadingIcon}
            fill="currentColor"
            width={20}
            height={20}
          />
        )}
      </Link>

      <Link className={styles.badgeWrapper} to={link}>
        <ActivityStatusBadge
          activityId={activity.id}
          status={activity.status}
        />
      </Link>

      <Link className={styles.statsWrapper} to={link}>
        {activity.id ? <ActivityStats id={activity.id} /> : <EmptyStats />}
      </Link>

      <div className={styles.activityMoreOptions}>
        <ActivityItemMoreOptions
          campaignPresetId={campaignId}
          activityIndex={activityIndex}
          setIsActivityUpdating={setIsActivityUpdating}
          status={activity.status}
          activityId={activity.id}
        />
      </div>
    </div>
  );
}

function ActivityStats({ id }: { id?: string }) {
  const { data } = useActivityAggregatedStats(
    id,
    { dateFrom: null, dateTo: null },
    false
  );
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const stats: GrowAggregateType | null = data ? data : null;

  return (
    <StatsView
      orderCount={formatNumberByType(stats?.countPurchase, "NUMBER")}
      orderValue={formatNumberByType(stats?.totalValuePurchase, "CURRENCY", {
        showDecimal: true,
        selectedCurrencyCode,
      })}
      clicks={formatNumberByType(stats?.clicks, "NUMBER")}
    />
  );
}

function EmptyStats() {
  return <StatsView orderCount={"-"} orderValue={"-"} clicks={"-"} />;
}

function StatsView({
  orderCount,
  orderValue,
  clicks,
}: {
  orderCount: string | number;
  orderValue: string | number;
  clicks: string | number;
}) {
  return (
    <div className={styles.stats}>
      <span className={styles.statItem}>
        {orderCount}

        <Tooltip supportingText="Order Counts">
          <LocalMallIcon
            strokeWidth={10}
            fill="rgb(140, 145, 150)"
            width={16}
            height={16}
          />
        </Tooltip>
      </span>
      <span className={styles.statItem}>
        {orderValue}
        <Tooltip supportingText="Order Value">
          <Coins strokeWidth={10} width={16} height={16} />
        </Tooltip>
      </span>
      <span className={styles.statItem}>
        {clicks}
        <Tooltip supportingText="Clicks">
          <TrackPadInputIcon
            strokeWidth={10}
            fill="rgb(140, 145, 150)"
            width={16}
            height={16}
          />
        </Tooltip>
      </span>
    </div>
  );
}
