import { ReactNode } from "react";
import styles from "./Label.module.scss";
import classNames from "classnames";
import Tooltip from "../Tooltip/Tooltip";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";

export interface ILabel {
  children?: ReactNode;
  label: ReactNode | string;
  srOnly?: boolean;
  helpText?: string;
  htmlFor?: string;
  className?: string;
  status?: "default" | "error";
}

export function Label({
  children,
  label,
  htmlFor,
  className,
  status = "default",
  srOnly,
  helpText,
}: ILabel) {
  return (
    <label className={classNames(styles.label)} htmlFor={htmlFor}>
      <span
        className={classNames(
          styles.labelText,
          {
            [styles.srOnly]: srOnly,
            [styles.error]: status === "error",
            [styles.default]: status === "default",
          },
          className
        )}
      >
        {label}
        {helpText && (
          <Tooltip
            children={<InfoIcon fill="currentColor" width={16} height={16} />}
            supportingText={helpText}
          />
        )}
      </span>
      {children}
    </label>
  );
}
