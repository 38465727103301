import { Button, IButton } from "../Button/Button";
import Modal from "../Modal/Modal";
import { ReactNode } from "react";
import styles from "./Alert.module.scss";
import defaultAlert from "./assets/defaultAlert.svg";
import classNames from "classnames";

interface PrimaryBtn extends IButton {}

export interface IAlert {
  illustration?: React.ReactNode;
  title?: string;
  maxWidth?: string;
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  primaryAction: PrimaryBtn;
  secondaryAction?: PrimaryBtn;
}

export default function Alert(props: IAlert) {
  const {
    illustration = <img src={defaultAlert} alt={defaultAlert} />,
    title,
    children,
    primaryAction,
    secondaryAction,
    isOpen,
    onClose,
    showCloseButton = false,
    className,
    maxWidth = "582px",
  } = props;

  return (
    <Modal
      showDismiss={showCloseButton}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      className={classNames(className, styles.modalContainer)}
      noContainer
    >
      <div className={styles.headerContainer}>
        <div className={styles.illustration}>{illustration}</div>
        <h3 className={styles.title}>{title}</h3>
      </div>
      <p className={styles.description}>{children}</p>
      <div className={styles.actions}>
        <Button onClick={primaryAction.onClick} {...primaryAction}>
          {primaryAction.children}
        </Button>
        {secondaryAction && (
          <Button {...secondaryAction}>{secondaryAction?.children}</Button>
        )}
      </div>
    </Modal>
  );
}
