import { CreateCampaignPageLayout } from "./CreateCampaignPageLayout";
import { ActivityInput, CampaignInput } from "../misc/createCampaignTypes";
import { Form, Formik } from "formik";
import { Review } from "./Review";
import { useContext, useEffect, useState } from "react";
import allCampaigns from "src/graphql/allCampaigns";
import {
  useCampaignCreateMutation,
  useCampaignPublishMutation,
  useCampaignUpdateMutation,
  useCanPublishCampaignLazyQuery,
} from "src/graphql/generated/schema";
import { useNavigate, useSearchParams } from "react-router-dom";
import { transformCampaignInput } from "../functions/transformCampaignInput";
import { parseCanPublishError } from "../functions/parseCanPublishError";
import toast from "react-hot-toast";
import { reviewSchema } from "../misc/reviewSchema";
import { WizardContext } from "src/modules/global/misc/WizardContext2";
import { defaultInitialValues } from "../misc/defaultInitialValue";
import { SaveCampaignWizardFormState } from "./SaveCampaignWizardFormState";
import { getValidActivities } from "../functions/getValidActivities";

export function ReviewStep() {
  const {
    breadcrumbs,
    markNodeAsComplete,
    data,
    goBack,
    setData,
    setSelectedActivityIndex,
  } = useContext(WizardContext);
  const [searchParams] = useSearchParams();
  const [createCampaign] = useCampaignCreateMutation();
  const [updateCampaign] = useCampaignUpdateMutation();
  const [publishCampaign] = useCampaignPublishMutation();
  const navigate = useNavigate();
  const [showUpgradePlan, setShowUpgradePlan] = useState(false);
  const [canPublishCampaign, { data: canPublishData }] =
    useCanPublishCampaignLazyQuery();
  const initialErrorBannerState = {
    content: "",
    isOpen: false,
    type: "error" as const,
    onClose: () => {},
  };
  const [errorBanner, setErrorBanner] = useState(initialErrorBannerState);
  const [upsertLoading, setUpsertLoading] = useState(false);

  const addNewActivity = async (campaignInput: CampaignInput) => {
    // remove activities that are not valid before appending new activity
    const validActivities = getValidActivities(campaignInput.Activities);
    setSelectedActivityIndex(
      validActivities.length,
      "choosePlatformAndActivityName",
      {
        ...campaignInput,
        Activities: [...validActivities, defaultInitialValues.Activities[0]],
      }
    );
  };

  async function handleSubmit(values: CampaignInput) {
    try {
      // don't save the campaign if we don't have to save
      if (!values.publishAfterSave) {
        setData(values);
        toast.success(`Campaign saved as draft`);
        navigate("/campaigns");
        return;
      }
      // FIXME: in case presetId is not available
      const presetId = searchParams.get("presetId");
      const campaignName = searchParams.get("campaignName");

      // easier to transform data this way
      // especially when everything is nested
      const transformedValues = transformCampaignInput({
        ...values,
        presetId,
        campaignName,
      });

      let campaign: any;

      if ("id" in transformedValues) {
        const { data } = await updateCampaign({
          variables: {
            input: transformedValues,
          },
        });

        campaign = data.campaignUpdate;
      } else {
        const { data } = await createCampaign({
          variables: {
            input: transformedValues,
          },
        });
        campaign = data.campaignCreate;
      }

      if (!showUpgradePlan) {
        const { data: publishDataPayload } = await publishCampaign({
          variables: {
            id: campaign.id,
          },
          refetchQueries: [
            {
              query: allCampaigns,
              variables: {
                sortBy: {
                  direction: "DESC",
                  field: "CREATED_AT",
                },
                filters: {
                  limit: 10,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        if (publishDataPayload.campaignPublish.userError) {
          throw publishDataPayload.campaignPublish.userError;
        }

        toast.success("Campaign publish in progress");
        navigate("/campaigns");
      } else {
        navigate("/settings/billing");
      }
    } catch (e: unknown) {
      let message =
        typeof e["message"] === "string"
          ? e["message"]
          : "Something went wrong";

      setErrorBanner({
        content: message,
        isOpen: true,
        type: "error",
        onClose: () => {
          setErrorBanner(initialErrorBannerState);
        },
      });
    }
  }

  function handleBack() {
    goBack(data);
  }

  useEffect(() => {
    const activities: ActivityInput[] = data.Activities;
    const selectedActivityStep =
      activities[data.selectedActivityIndex].currentStep;

    // Don't check if current step is not review.
    // This check is to avoid triggering can publish when new activity is added.
    // Also currentNode is not used here as this useEffect is triggered
    // before currentNode is set.
    if (selectedActivityStep !== "review") return;

    const validActivities = getValidActivities(data.Activities);
    canPublishCampaign({
      variables: {
        input: {
          activities: validActivities.map((a) => ({
            id: a?.id,
            // TODO: add sms and whatsapp filter
            type: a.EmailObject ? "OUTREACH" : "ACTIVITY",
            segmentIds: a?.segmentIds,
            // TODO: add sms and whatsapp filter
            outreachType: a.EmailObject ? "EMAIL" : null,
            activityType: a.platform,
            adAccountId: a.platformAccountId,
          })),
        },
      },
    })
      .then((payload) => {
        if (payload.data.canPublishCampaign?.userError) {
          setShowUpgradePlan(true);
        } else {
          setShowUpgradePlan(false);
        }
      })
      .catch((e) => toast.error(e.message));
  }, [data.Activities]);

  const { shouldShowBanner: shouldShowUpgradeBanner, message } =
    parseCanPublishError(canPublishData?.canPublishCampaign?.userError);

  const upgradeBanner = {
    content: message,
    type: "warning" as const,
    onClose: null,
    isOpen: shouldShowUpgradeBanner,
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validationSchema={reviewSchema}
      initialValues={data}
      onSubmit={async (values) => {
        await handleSubmit(values);
      }}
    >
      {({ values }) => (
        <Form>
          <SaveCampaignWizardFormState />
          <CreateCampaignPageLayout
            breadcrumbs={breadcrumbs}
            campaignInput={data}
            campaignPresetUpsert={markNodeAsComplete}
            errorBanner={shouldShowUpgradeBanner ? upgradeBanner : errorBanner}
          >
            <Review
              showUpgradePlan={showUpgradePlan}
              // data from WizardContext cannot be used here as the it is updated after the Formik values
              // This can result in stale data being saved to the overall campaign preset.
              addNewActivity={() => addNewActivity(values)}
              upsertLoading={upsertLoading}
              setActiveFormStep={async (i) => {
                markNodeAsComplete(data);
              }}
              handleBack={handleBack}
            />
          </CreateCampaignPageLayout>
        </Form>
      )}
    </Formik>
  );
}
