import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import styles from "./Stepper.module.scss";

export interface IStepper extends Record<string, any> {
  steps: { icon: ReactNode; label: string }[];
  activeStepIndex: number;
  className?: string;
  onClick?: (step: { label: string; icon: ReactNode }) => void;
}

interface IStep {
  icon: ReactNode;
  label: string;
  onClick: () => void;
  active?: boolean;
}

export default function Stepper(props: IStepper) {
  const { steps, className, onClick, activeStepIndex = 0 } = props;

  const arrayLastIndex = steps.length - 1;

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.stepper}>
        {steps.map((item, index) => {
          const isActive = index <= activeStepIndex;
          const isLineActive = index < activeStepIndex;

          return (
            <Fragment key={index}>
              <Step
                icon={item.icon}
                label={item.label}
                active={isActive}
                key={index}
                onClick={() => {
                  if (onClick) {
                    onClick(item);
                  }
                }}
              />
              {index === arrayLastIndex ? null : (
                <div
                  className={classNames(
                    styles.line,
                    isLineActive && styles.activeLine
                  )}
                >
                  <span />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

function Step({ icon, label, active, onClick }: IStep) {
  return (
    <button type="button" onClick={onClick} className={styles.step}>
      <div className={classNames(styles.icon, active && styles.activeIcon)}>
        {icon}
      </div>
      <span
        className={classNames(styles.stepText, active && styles.activeText)}
      >
        {label}
      </span>
    </button>
  );
}
