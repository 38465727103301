import classNames from "classnames";
import styles from "./AutocompleteList.module.scss";

import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Command, CommandProps, Editor, Range } from "@tiptap/core";

export const AutocompleteList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      if (item.value) {
        props.command({ id: item.value });
      }

      if (item.command) {
        const { selection } = props.editor.state;
        const $anchor = selection.$anchor;
        const range = {
          from: $anchor.posAtIndex(0, 1),
          to: $anchor.posAtIndex(1, 1),
        };
        item.command({ editor: props.editor, range });
      }
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className={styles.items}>
      {props.items.length ? (
        props.items.map(
          (
            item: {
              label: string;
              value?: string;
              command?: ({
                editor,
                range,
              }: {
                editor: Editor;
                range: Range;
              }) => void;
            },
            index: number,
          ) => (
            <button
              type="button"
              className={classNames(styles.item, {
                [styles.isSelected]: index === selectedIndex,
              })}
              key={index}
              onClick={() => {
                setSelectedIndex(index);
              }}
            >
              {item?.label}
            </button>
          ),
        )
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});
