import { useFormikContext } from "formik";
import {
  AudienceInput,
  AudienceSetCreateType,
} from "../misc/audienceSetCreateType";
import styles from "./AudiencesSelectionTable.module.scss";
import { AudienceIcon } from "src/modules/global/components/AudienceIcon";
import { SwitchSelectionTable } from "./SwitchSelectionTable";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

export function AudiencesSelectionTable({
  audiences,
  shouldShowSelection,
  onShowSelection,
}: {
  shouldShowSelection: boolean;
  audiences: Array<Omit<AudienceInput, "value">>;
  onShowSelection?: () => void;
}) {
  const selectedCurrencySymbol = useGetActiveCurrencyCode();
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<AudienceSetCreateType>();

  return (
    <SwitchSelectionTable
      shouldShowSelection={shouldShowSelection}
      gridTemplate="3fr 1fr repeat(3, 0.75fr) 1fr"
      headers={[
        "Audience List",
        "Audience Type",
        "ROAS (%)",
        "CTR (%)",
        `CPL (${selectedCurrencySymbol})`,
        "Include/Exclude",
      ]}
      items={audiences.map((audience) => ({
        id: audience.id,
        description: "N/A",
        icon: (
          <span className={styles.icon}>
            <AudienceIcon
              audience={{ type: audience.type, __typename: "Audience" }}
            />
          </span>
        ),
        title: audience.name,
        otherRows: [snakeCaseToTitleCase(audience.type), "-", "-", "-"],
      }))}
      values={values.audiences}
      onShowSelection={onShowSelection}
      onChange={(value: "INCLUDE" | "EXCLUDE" | "NONE", item) => {
        const newAudiences = structuredClone(values.audiences);
        const selectedAudience = audiences.find((a) => a.id === item.id);

        newAudiences[item.id] = {
          id: selectedAudience.id,
          name: selectedAudience.name,
          type: selectedAudience.type,
          platform: selectedAudience.platform,
          description: selectedAudience.description,
          createdAt: selectedAudience.createdAt,
          value,
        };

        setFieldTouched("audiences");
        setFieldValue("audiences", newAudiences);
      }}
    />
  );
}
