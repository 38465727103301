import { Button } from "src/components/Button/Button";
import styles from "./RecentlyLaunchedCampaigns.module.scss";
import { ReactComponent as ArrowRight } from "@material-design-icons/svg/outlined/keyboard_arrow_right.svg";
import { useCampaignsQuery } from "src/graphql/generated/schema";
import CampaignCard from "./CampaignCard";
import { Spinner } from "src/components/Spinner/Spinner";
import EmptyStateIcon from "../../assets/metricItem.svg";
import EmptyState from "src/components/EmptyState/EmptyState";

export default function RecentlyLaunchedCampaigns() {
  const { data, loading } = useCampaignsQuery({
    variables: {
      sortBy: {
        field: "CREATED_AT",
        direction: "DESC",
      },
      filters: {
        limit: 3,
      },
    },
  });

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <Spinner height="screen" />
      </div>
    );
  }

  const campaignsList = data?.campaigns
    ? data.campaigns.edges.map((v) => v.node)
    : [];

  if (campaignsList.length === 0) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.emptyStateContainer}>
          <EmptyState
            title={"Recently created campaigns"}
            illustration={{
              width: 60,
              height: 60,
              source: EmptyStateIcon,
            }}
            action={{
              title: "Create Campaign",
              link: "/campaigns",
            }}
          >
            <div className={styles.emptyState}>
              All your recently added campaign will be shown here.
            </div>
          </EmptyState>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <div className={styles.header}>
        <div className={styles.title}>Recently launched campaigns</div>
        <div className={styles.action}>
          <Button to="/campaigns" style="plain" color="primary">
            <span className={styles.buttonWithIcon}>
              <span>All Campaigns</span>
              <ArrowRight fill="currentColor" />
            </span>
          </Button>
        </div>
      </div>

      <div className={styles.campaignsList}>
        {campaignsList.map((v) => (
          <CampaignCard campaign={v} key={v.id} />
        ))}
      </div>
    </div>
  );
}
