import { TextMessageTemplateQuery } from "src/graphql/generated/schema";
import { FormInput, WhatsappFormInput } from "../CreateMessageTemplateTypes";

export function generateFormikInput(
  platform: "SMS" | "WHATSAPP",
  data: TextMessageTemplateQuery
): FormInput {
  if (platform === "WHATSAPP") {
    return {
      name: "",
      objective: "",
      body: {},
      language: "en_US",
      footer: "",
      header: {
        headerType: "NONE",
      },
      buttons: {
        type: "NONE",
      },
    } as WhatsappFormInput;
  }
  return data && data.textMessageTemplate
    ? {
        type: data.textMessageTemplate.dltTemplateId ? "DLT_BASED" : "NO_DLT",
        name: data.textMessageTemplate.name,
        dltId: data.textMessageTemplate.dltTemplateId,
        content: data.textMessageTemplate.message,
        objective: "",
        optOutLink: `https//macrostack.co/u/optOut?id=${data.textMessageTemplate?.id}`,
        language: "English",
      }
    : {
        type: "NO_DLT",
        name: "",
        dltId: "",
        content: "",
        objective: "",
        optOutLink: "https//macrostack.co/u/optOut?id={{TEMPLATE_ID}}",
        language: "English",
      };
}
