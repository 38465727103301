import { ReactNode, useState } from "react";

import { Checkbox } from "../Checkbox/Checkbox";
import classNames from "classnames";
import styles from "./AudienceCard.module.scss";
import { FormikErrors } from "formik";

export interface IAudienceCard {
  name: string;
  value: string;
  image: string;
  title: string;
  subtitle?: React.ReactNode;
  primaryAction?: { label: string | ReactNode; action: () => void };
  badge?: string | number;
  onChange: (newChecked: boolean) => void;
  onBlur?: (e: any) => void;
  checked: boolean;
  error?: FormikErrors<any>;
  noCheck?: boolean;
  variant?: "default" | "small";
  noBorder?: boolean;
}

export default function AudienceCard(props: IAudienceCard) {
  const {
    title,
    checked,
    image,
    subtitle,
    primaryAction,
    badge,
    value,
    onChange,
    onBlur,
    noBorder = false,
    variant = "default",
    noCheck = false,
    error = null,
  } = props;
  const [showCheckbox, setShowCheckbox] = useState(false);

  function handleMouseEnter() {
    setShowCheckbox(true);
  }

  function handleMouseLeave() {
    if (checked) return;
    setShowCheckbox(false);
  }

  return (
    // makes the whole card clickable as input area
    <label
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(styles.card, noCheck && styles.noHover, {
        [styles.cardChecked]: checked,
        [styles.noCardError]: !Boolean(error),
        [styles.cardError]: Boolean(error),
        [styles.noCheck]: noCheck,
        [styles.hasCheck]: !noCheck,
        [styles.small]: variant === "small",
        [styles.noBorder]: noBorder && !checked,
      })}
    >
      <div className={styles.container}>
        <div
          className={classNames(styles.imageContainer, {
            [styles.smallImageContainer]: variant === "small",
          })}
        >
          <img
            src={image}
            alt={title}
            width={variant === "default" ? 48 : "auto"}
            height={variant === "default" ? 48 : "auto"}
          />
        </div>
        <div className={styles.text}>
          <p
            className={classNames(styles.title, checked && styles.textChecked)}
            title={title}
          >
            {title}
          </p>
          {subtitle && (
            <p
              className={classNames(styles.subtitle, {
                [styles.smallSubtitle]: variant === "small",
              })}
            >
              {subtitle}
            </p>
          )}
          <div className={styles.badgeAndAction}>
            {badge && <div className={styles.badge}>{badge}</div>}
            {primaryAction && (
              <button
                type="button"
                className={styles.action}
                onClick={primaryAction.action}
              >
                {primaryAction.label}
              </button>
            )}
          </div>
        </div>
        {!Boolean(error) && !noCheck && (
          <span
            className={classNames(
              styles.check,
              showCheckbox && styles.showCheck
            )}
          >
            <Checkbox
              onBlur={onBlur}
              value={value}
              checked={checked}
              onChange={onChange}
            />
          </span>
        )}
      </div>
    </label>
  );
}
