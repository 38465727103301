import { ReactNode, useState } from "react";
import {
  StepWizardContext,
  StepWizardResetAlert,
} from "../misc/StepWizardContext";
import Alert from "src/components/Alert/Alert";

interface IStepWizard<T> {
  initialData: T;
  children: ReactNode;
  onStepComplete: (nextStep: number, data: T) => Promise<void> | void;
  initialActiveStepIndex?: number;
  onResetField: (
    fieldKey: string,
    fieldValue: string,
    data: T,
    stepIndex: number,
  ) => T;
}

export function StepWizard<T>({
  onStepComplete,
  initialActiveStepIndex,
  initialData,
  onResetField,
  children,
}: IStepWizard<T>) {
  const [data, setData] = useState<T>(initialData);
  const [activeStepIndex, setActiveStepIndex] = useState(
    initialActiveStepIndex ?? 0,
  );
  const resetAlertDefaultValue = {
    isShowing: false,
    fieldKey: "",
    data: data,
    fieldValue: "",
    stepIndex: 0,
  };
  const [resetAlert, setResetAlert] = useState<StepWizardResetAlert<T>>(
    resetAlertDefaultValue,
  );

  async function markStepAsDone(newIndex: number, data: any) {
    setData(data);
    setActiveStepIndex(newIndex);
    await onStepComplete(newIndex, data);
  }

  function purgeDataWhenFieldReset(
    fieldName: string,
    fieldValue: any,
    data: T,
    stepIndex: number,
  ) {
    const newData = onResetField(fieldName, fieldValue, data, stepIndex);
    setData(newData);
  }

  return (
    <StepWizardContext.Provider
      value={{
        data,
        activeStepIndex,
        markStepAsDone,
        resetAlert,
        setResetAlert,
      }}
    >
      {children}
      <Alert
        isOpen={resetAlert.isShowing}
        onClose={() => setResetAlert({ ...resetAlert, isShowing: false })}
        secondaryAction={{
          children: "Yes",
          color: "primary",
          onClick() {
            purgeDataWhenFieldReset(
              resetAlert.fieldKey,
              resetAlert.fieldValue,
              resetAlert.data,
              resetAlert.stepIndex,
            );
            setResetAlert(resetAlertDefaultValue);
          },
        }}
        primaryAction={{
          children: "No",
          color: "subdued",
          style: "outline",
          onClick() {
            setResetAlert({ ...resetAlert, isShowing: false });
          },
        }}
        title="Doing this change will reset your progress"
      >
        By doing this change you will reset all the changes done in steps after
        this. Do you still want to continue?
      </Alert>
    </StepWizardContext.Provider>
  );
}
