import Badge from "src/components/Badge/Badge";
import { Button } from "src/components/Button/Button";
import { Campaign, CampaignsQuery } from "src/graphql/generated/schema";
import CampaignTitleWithIcon from "src/modules/campaign/components/CampaignTitleWithIcon";
import { ReactComponent as MetaIcon } from "src/modules/global/assets/meta.svg";
import styles from "./CampaignCard.module.scss";
import { campaignBadgeDropdownOptions } from "src/modules/campaign/misc/campaignBadgeDropdownOptions";

function CampaignCard({
  campaign,
}: {
  campaign: CampaignsQuery["campaigns"]["edges"][0]["node"];
}) {
  // const activitiesCountByPlatform = campaign.Activities.reduce(
  //   (acc, v) => {
  //     if (v.platform === "META") {
  //       acc.meta += 1;
  //     }
  //
  //     if (v.platform === "EMAIL") {
  //       acc.email += 1;
  //     }
  //
  //     return acc;
  //   },
  //   {
  //     meta: 0,
  //     email: 0,
  //   }
  // );

  const badgeProps = campaignBadgeDropdownOptions.find(
    (v) => v.status === campaign.status
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CampaignTitleWithIcon campaign={campaign} />
      </div>

      <div className={styles.activityCount}>
        <div className={styles.activityCountTitle}>Activity Count</div>
        <div className={styles.activityCountStats}>
          <div className={styles.totalCount}>
            {campaign.activitiesCount < 9
              ? `0${campaign.activitiesCount}`
              : campaign.activitiesCount}
          </div>
          <div className={styles.platformCountsList}>
            {/* TODO: Handle all platforms */}
            <div className={styles.platformCount}>
              <MetaIcon height={20} width={20} />
              {campaign.activitiesCount < 9
                ? `0${campaign.activitiesCount}`
                : campaign.activitiesCount}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Badge
          label={badgeProps.label}
          status={"success"}
          leftIconButton={{ icon: badgeProps.icon }}
        />

        <Button style="plain" color="primary" to={`/campaigns/${campaign.id}`}>
          View Campaign
        </Button>
      </div>
    </div>
  );
}

export default CampaignCard;
