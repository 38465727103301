import { WhatsappDetailSection } from "../WhatsappDetailSection/WhatsappDetailSection";
import styles from "./WhatsappTemplateHeader.module.scss";
import { ReactComponent as Clip } from "@material-design-icons/svg/outlined/attach_file.svg";
import { ReactComponent as Location } from "@material-design-icons/svg/outlined/location_on.svg";
import { ReactComponent as TextField } from "@material-design-icons/svg/outlined/text_fields.svg";
import { ReactComponent as None } from "@material-design-icons/svg/outlined/do_not_disturb_on.svg";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import classNames from "classnames";
import { RadioGroup } from "@headlessui/react";
import { ReactNode, useState } from "react";
import { IconButton } from "src/components/IconButton/IconButton";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import Tooltip from "src/components/Tooltip/Tooltip";
import {
  HeaderTypeOptionValue,
  WhatsappFormInput,
} from "src/pages/CreateTemplate/CreateMessageTemplateTypes";
import {
  addNewVariableToText,
  removeAllVariables,
  suggestions,
} from "../WhatsappDetailSection/WhatsappTemplateDetails";
import { TipTapEditor } from "src/modules/global/components/TipTapEditor";

const templateTypeOptions: { label: HeaderTypeOptionValue; icon: ReactNode }[] =
  [
    {
      label: "NONE",
      icon: <None className={styles.ico} />,
    },
    {
      label: "TEXT",
      icon: <TextField className={styles.ico} />,
    },
    {
      label: "UPLOAD",
      icon: <Clip className={classNames(styles.ico, styles.clipIco)} />,
    },
    {
      label: "LOCATION",
      icon: <Location className={styles.ico} />,
    },
  ];

interface IWhatsappTemplateHeader {
  values: WhatsappFormInput;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}
export function WhatsappTemplateHeader({
  values,
  setFieldValue,
}: IWhatsappTemplateHeader) {
  const [addVariable, setAddVariable] = useState(false);
  return (
    <>
      <WhatsappDetailSection
        title="Header"
        subtitle="Add a 60 character title to your message. Only one variable is supported in the header."
      >
        <RadioGroup
          as="div"
          className={styles.templateOptions}
          name={"headerType"}
          value={values.header.headerType}
          // onBlur={() => {
          //   setFieldTouched(platformKey);
          // }}
          onChange={(value: HeaderTypeOptionValue) => {
            setFieldValue("header.headerType", value);
          }}
        >
          {templateTypeOptions.map((item, index) => (
            <RadioGroup.Option
              value={item.label}
              //   disabled={platform.disable}
              title={item.label}
              key={index}
              className={styles.option}
            >
              {({ checked }) => (
                <TemplateTypeCard {...item} checked={checked} />
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>

        {/* For Text Template Type*/}
        {values?.header?.headerType === "TEXT" && (
          <div>
            <TipTapEditor
              content={values?.header?.text}
              onChange={(value) => {
                setFieldValue("header.text", value);
                const variables = value.match(/{{\w+}}/g);
                setFieldValue(
                  "header.variables",
                  variables
                    ? variables.map((v) => v.replace(/[{{}}]/g, ""))
                    : []
                );
              }}
              autoCompleteSuggestions={suggestions}
            />
            <p className={styles.headerChr}>
              {(values?.header?.text && values.header.text.length) || 0}
              /60
            </p>
            {/* <Button
              className={styles.addBtn}
              color="subdued"
              style="outline"
              disabled={addVariable}
              onClick={() => {
                addNewVariableToText(values?.header?.text || "", (v) => {
                  const matches = v.match(/{{\d+}}/g);
                  setFieldValue("header.text", v);
                  setFieldValue(
                    "header.example[0].variableName",
                    matches.length.toString()
                  );
                });
                setAddVariable(true);
                return;
              }}
              size="micro"
            >
              Add Variables <Add className={styles.addIco} />
            </Button> */}
          </div>
        )}

        {/* For File Template Type */}
        {/* TODO: make this work */}
        {/* {values?.header?.headerType === "UPLOAD" && <UplaodFileTemplate />} */}
      </WhatsappDetailSection>

      {/* For Template Type Text */}
      {addVariable && values?.header?.headerType === "TEXT" && (
        <WhatsappDetailSection
          title={
            <div className={styles.addVariableTitle}>
              <p className={styles.addVariableText}>
                Define sample variable value for header
                <Tooltip
                  supportingText={
                    <>
                      <p>
                        What data this variable will show in your WhatsApp
                        campaign. Give example.
                      </p>
                      <p className={styles.secondText}>
                        This is important for the template approval from
                        WhatsApp.
                      </p>
                    </>
                  }
                >
                  <Info className={styles.infoIco} />
                </Tooltip>
              </p>
              <IconButton
                icon={<Close />}
                onClick={() => {
                  removeAllVariables(values.header.text, (v) => {
                    setFieldValue("header.text", v);
                    setFieldValue("header.example", []);
                  });
                  setAddVariable(false);
                }}
                style="plain"
                color="subdued"
              />
            </div>
          }
        >
          <div className={styles.inputWrapper}>
            <InputFormik
              name="header.example[0].variableName"
              label="Variable"
              disabled
            />
            <InputFormik
              name="header.example[0].variableExample"
              label="Sample Value"
            />
          </div>
        </WhatsappDetailSection>
      )}
    </>
  );
}

function TemplateTypeCard({ icon, label, checked }) {
  return (
    <div className={classNames(styles.card, { [styles.darkCard]: checked })}>
      <span
        className={classNames(styles.circle, { [styles.darkCircle]: checked })}
      >
        {icon}
      </span>
      <span
        className={classNames(styles.label, { [styles.darkLabel]: checked })}
      >
        {label}
      </span>
    </div>
  );
}
