import { CampaignCreateForm } from "./CampaignCreateForm";
import * as Yup from "yup";
import { RadioGroup } from "@headlessui/react";
import CollectionItem from "src/components/CollectionItem/CollectionItem";
import SmartPlacement from "../assets/SmartPlacement.svg";
import AdvancePlacement from "../assets/AdvancePlacement.svg";
import Card from "src/components/Card/Card";
import { CampaignInput } from "../misc/createCampaignTypes";
import { getIn, useFormikContext } from "formik";
import { Label } from "src/components/Label/Label";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import { Button } from "src/components/Button/Button";
import styles from "./ChooseMetaAdPlacementType.module.scss";

const chooseAdPlacementTypeSchema = Yup.object({
  adPlacementType: Yup.mixed()
    .oneOf(["SMART", "ADVANCED"])
    .required("Please select a placement type to proceed"),
});

interface IChooseMetaAdPlacementTypeForm {
  campaignInput: CampaignInput;
  campaignPresetUpsert: (data: CampaignInput) => void;
  handleBack: (data: CampaignInput) => void;
}

export default function ChooseMetaAdPlacementTypeForm({
  campaignInput,
  campaignPresetUpsert,
  handleBack,
}: IChooseMetaAdPlacementTypeForm) {
  const selectedActivityIndex = campaignInput.selectedActivityIndex ?? 0;

  return (
    <CampaignCreateForm
      campaignPresetUpsert={campaignPresetUpsert}
      campaignInput={campaignInput}
      schema={chooseAdPlacementTypeSchema}
    >
      <ChooseMetaAdPlacementType
        selectedActivityIndex={selectedActivityIndex}
        handleBack={handleBack}
        disabled={
          campaignInput.Activities[selectedActivityIndex].currentStep !==
          "chooseAdPlacementType"
        }
      />
    </CampaignCreateForm>
  );
}

const placementOptions = [
  {
    title: "Smart Placements",
    description:
      "Enable us to get the best results available from across all default placements.",
    value: "SMART",
    image: SmartPlacement,
  },
  {
    title: "Advance Placements",
    description:
      "Manually choose the places to show your ad. Only for advance user who knows whom to target.",
    value: "ADVANCED",
    image: AdvancePlacement,
  },
];
function ChooseMetaAdPlacementType({
  disabled,
  selectedActivityIndex,
  handleBack,
}) {
  const { values, isSubmitting, setFieldValue, errors, touched, isValid } =
    useFormikContext<CampaignInput>();

  const key = `Activities[${selectedActivityIndex}].adPlacementType`;
  const fieldError = getIn(errors, key);
  const isFieldTouched = getIn(touched, key);

  return (
    <Card disabled={disabled}>
      <Label label="Select Ad Placement type">
        <RadioGroup
          as="div"
          value={getIn(values, key)}
          className={styles.container}
          onChange={(val) => {
            // if (isCurrentNodeCompleted) {
            //   setResetAlert({
            //     data: values,
            //     fieldKey: key,
            //     fieldValue: val,
            //     isShowing: true,
            //   });
            //   return;
            // }
            setFieldValue(key, val);
          }}
        >
          {placementOptions.map((v, index) => (
            <CollectionItem
              title={v.title}
              image={v.image}
              value={v.value}
              subtitle={v.description}
              className={styles.card}
              key={index}
            />
          ))}
        </RadioGroup>
        {isFieldTouched && fieldError && (
          <FormFieldStatus type="error" message={fieldError} />
        )}
      </Label>

      <div className={styles.btnGroup}>
        <Button
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
          type="submit"
        >
          Save and Continue
        </Button>
        <Button
          color="subdued"
          style="outline"
          type="button"
          disabled={isSubmitting}
          onClick={() => handleBack(values)}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}
