import styles from "./NotificationPanel.module.scss";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { IconButton } from "src/components/IconButton/IconButton";
import { ReactComponent as Settings } from "@material-symbols/svg-400/rounded/manufacturing-fill.svg";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import NotificationMsgDetails from "./NotificationMsgDetails";
import { useContext, useEffect, useRef, useState } from "react";
import NotificationSettingsContent from "./NotificationSettingsContent";
import classNames from "classnames";
import { NotificationListLoader } from "./NotificationListLoader";
import { INotification } from "../misc/notification";
import AuthContext from "src/stores/AuthContext";
import { NotificationSettingsForm } from "./NotificationSettingsForm";

interface INotificationPanel {
  setIsNotificationOpen: (arg0: boolean) => void;
}

export default function NotificationPanel({
  setIsNotificationOpen,
}: INotificationPanel) {
  const { user } = useContext(AuthContext);
  const panelRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [selectedNotification, setSelectedNotification] =
    useState<INotification | null>(null);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // close on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!panelRef.current) return;

      if (!panelRef.current.contains(event.target)) {
        // if the sidebar is open; it have to be outside of that as well
        if (sidebarRef.current) {
          if (!sidebarRef.current.contains(event.target)) {
            setIsNotificationOpen(false);
          }
          // else if there is no sidebar; and the click is outside of panel close it
        } else {
          setIsNotificationOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.panel}>
      <div
        ref={panelRef}
        className={classNames(styles.notificationListingPanel)}
      >
        <div className={styles.notificationHeader}>
          <h3 className={styles.notificationTitle}>
            {isSettingsOpen && (
              <IconButton
                icon={
                  <ArrowBack
                    height={24}
                    width={24}
                    fill={"rgba(28, 27, 31, 1)"}
                  />
                }
                onClick={() => {
                  setSelectedNotification(null);
                  setIsSettingsOpen(false);
                }}
                style="plain"
              />
            )}
            {isSettingsOpen ? "Notification Settings" : "Notification"}
          </h3>
          <button
            type="button"
            onClick={() => {
              setIsNotificationOpen(false);
            }}
            className={styles.notificationPanelClose}
          >
            <Close fill="#1C1B1F" width={20} height={20} />
          </button>
        </div>
        <div
          id="scrollTargetForInfiniteScroll"
          className={styles.contentWrapper}
        >
          {isSettingsOpen ? (
            <NotificationSettingsForm>
              <NotificationSettingsContent
                selectedSetting={selectedSetting}
                setSelectedSetting={setSelectedSetting}
              />
            </NotificationSettingsForm>
          ) : (
            <NotificationListLoader
              setSelectedNotification={setSelectedNotification}
              selectedNotification={selectedNotification}
            />
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.user}>
            <span className={styles.userInt}>
              {user.name?.[0].toUpperCase()}
            </span>
            <span className={styles.userName}>{user.name}</span>
          </div>
          <IconButton
            icon={
              <Settings
                className={classNames({
                  [styles.settingOn]: isSettingsOpen,
                })}
              />
            }
            onClick={() => {
              setSelectedNotification(null);
              setSelectedSetting(null);
              setIsSettingsOpen(!isSettingsOpen);
            }}
          />
        </div>
      </div>
      {isSettingsOpen && !!selectedSetting && (
        <NotificationSettingsForm>
          <div ref={sidebarRef} className={styles.notificationSinglePanel}>
            {selectedSetting?.subSettings}
          </div>
        </NotificationSettingsForm>
      )}

      {!!selectedNotification && !isSettingsOpen && (
        <div ref={sidebarRef} className={styles.notificationSinglePanel}>
          <NotificationMsgDetails
            onDismiss={() => setSelectedNotification(null)}
            notification={selectedNotification}
          />
        </div>
      )}
    </div>
  );
}
