import classNames from "classnames";
import styles from "./Pagination.module.scss";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back_ios.svg";
import { ReactComponent as ArrowForward } from "@material-design-icons/svg/outlined/arrow_forward_ios.svg";
import { Button } from "../Button/Button";

export interface IPagination {
  label?: React.ReactNode;
  hasPrevious: boolean;
  hasNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
  borderLess?: boolean;
  noTopPadding?: boolean;
}

export function Pagination({
  label,
  hasNext,
  hasPrevious,
  onNext,
  borderLess = false,
  noTopPadding = false,
  onPrevious,
}: IPagination) {
  return (
    <div
      className={classNames(styles.pagination, {
        [styles.paginationBorder]: !borderLess,
        [styles.noTopPadding]: noTopPadding,
      })}
    >
      <span className={styles.footerLabel}>{label}</span>
      <div className={styles.btnGrp}>
        <Button
          color="subdued"
          style="plain"
          onClick={onPrevious}
          disabled={hasPrevious ? false : true}
        >
          <span
            className={classNames(styles.navBtnContent, {
              [styles.activeBtn]: hasPrevious,
            })}
          >
            <ArrowBack
              width={18}
              height={18}
              className={classNames({
                [styles.hasPrev]: hasPrevious,
                [styles.notPrev]: !hasPrevious,
              })}
            />{" "}
            Prev
          </span>
        </Button>
        <hr className={styles.separator} />
        <Button
          color="subdued"
          style="plain"
          onClick={onNext}
          disabled={hasNext ? false : true}
        >
          <span
            className={classNames(styles.navBtnContent, {
              [styles.activeBtn]: hasNext,
            })}
          >
            Next
            <ArrowForward
              width={16}
              height={16}
              className={classNames({
                [styles.hasNext]: hasNext,
                [styles.notNext]: !hasNext,
              })}
            />
          </span>
        </Button>
      </div>
    </div>
  );
}
