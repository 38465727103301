import { getIn, useFormikContext } from "formik";
import Card from "src/components/Card/Card";
import { CampaignInput } from "../misc/createCampaignTypes";
import styles from "./ChooseMetaObjectiveAndSubtypes.module.scss";
import { SectionTabs } from "src/modules/global/components/SectionTabs";
import { useState } from "react";
import salesImage from "../assets/salesAndLeadsMeta.svg";
import trafficImage from "../assets/trafficMeta.svg";
import engagementImage from "../assets/engagementMeta.svg";
import { RadioGroup } from "@headlessui/react";
import { Button } from "src/components/Button/Button";
import { SalesAndLeadsOptions } from "./SalesAndLeadsOptions";
import { EngagementOptions } from "./EngagementOptions";
import { TrafficAndAwarenessOptions } from "./TrafficAndAwarenessOptions";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";

interface IChooseMetaObjectiveAndSubtypes {
  handleBack: (i: CampaignInput) => unknown;
  selectedActivityIndex: number;
  disabled: boolean;
}

export function ChooseMetaObjectiveAndSubtypes({
  handleBack,
  selectedActivityIndex,
  disabled,
}: IChooseMetaObjectiveAndSubtypes) {
  const { isCurrentNodeCompleted, data, resetAlert, setResetAlert } =
    useWizardContext();
  const { values, isSubmitting, setFieldValue, handleBlur } =
    useFormikContext<CampaignInput>();
  const fieldKey = `Activities[${selectedActivityIndex}].metaPurpose`;
  const fieldValue = getIn(values, fieldKey);
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  return (
    <div>
      <Card disabled={disabled}>
        <h2 className={styles.cardTitle}>
          What do you want from this activity?
        </h2>
        <div className={styles.form}>
          <SectionTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          <div>
            <RadioGroup
              value={fieldValue}
              name={fieldKey}
              onChange={(value) => {
                if (isCurrentNodeCompleted) {
                  setResetAlert({
                    isShowing: true,
                    fieldKey: fieldKey,
                    fieldValue: value,
                    data: data,
                  });
                  return;
                }
                setFieldValue(fieldKey, value);
              }}
              onBlur={handleBlur}
            >
              {selectedTab === "SALES_AND_LEADS" && <SalesAndLeadsOptions />}
              {selectedTab === "TRAFFIC_AND_AWARENESS" && (
                <TrafficAndAwarenessOptions />
              )}
              {selectedTab === "ENGAGEMENT" && <EngagementOptions />}
            </RadioGroup>
          </div>
        </div>

        <div className={styles.formActions}>
          <Button type="submit">Save and Continue</Button>
          <Button
            onClick={() => handleBack(values)}
            loading={isSubmitting}
            style="outline"
            color="subdued"
          >
            Back
          </Button>
        </div>
      </Card>
    </div>
  );
}

const tabs = [
  {
    icon: <img src={salesImage} width={32} height={32} alt="Sales & Leads" />,
    title: "Sales & Leads",
    id: "SALES_AND_LEADS",
    subtitle: "Get people to engage with your brand",
  },
  {
    icon: (
      <img
        src={trafficImage}
        width={32}
        height={32}
        alt="Traffic & Awareness"
      />
    ),
    title: "Traffic & Awareness",
    id: "TRAFFIC_AND_AWARENESS",
    subtitle: "Increase engagement on your post",
  },
  {
    icon: <img src={engagementImage} width={32} height={32} alt="Engagement" />,
    title: "Engagement",
    id: "ENGAGEMENT",
    subtitle: "Get people to engage with your website.",
  },
];
