import { CampaignInput } from "./createCampaignTypes";
import { FacebookSubSteps } from "../components/FacebookSubSteps";
import { ReviewStep } from "../components/ReviewStep";
import { CreateFacebookAdForm } from "../components/CreateFacebookAdForm";
import { RetargetAudiencesStep } from "../components/RetargetAudiencesStep";
import { SelectFacebookAudiencesStep } from "../components/SelectFacebookAudiencesStep";
import { Breadcrumb, Node } from "src/modules/global/misc/wizardTypes";
import { ReactComponent as PlatformAndReachIcon } from "../assets/platformAndReachIcon.svg";
import { ReactComponent as AudienceAndBudgetIcon } from "../assets/audienceAndBudgetIcon.svg";
import { ReactComponent as CreateFacebookAdIcon } from "../assets/createFacebookAdIcon.svg";
import { ReactComponent as ReviewIcon } from "../assets/reviewIcon.svg";
import { defaultInitialValues } from "./defaultInitialValue";
import { ActivityPlatformEnum } from "src/graphql/generated/schema";

const facebookStaticBreadcrumbs = (
  campaignInput: CampaignInput,
  index: number
): Breadcrumb[] => [
  // {
  //   startingStep: "chooseObjectiveAndName",
  //   icon: <SetupCampaignIcon />,
  //   label: "Setup Campaign",
  //   value: "SETUP_CAMPAIGN",
  //   isActive: index === 0,
  // },
  {
    startingStep: "choosePlatformAndActivityName",
    icon: <PlatformAndReachIcon />,
    label: "Platform & Reach",
    value: "PLATFORM_REACH",
    isActive: index === 0,
  },
  {
    startingStep:
      campaignInput.Activities[campaignInput.selectedActivityIndex]
        .audienceType === "RETARGETED_AUDIENCE"
        ? "selectRetargetAudiences"
        : "selectAudiences",
    icon: <AudienceAndBudgetIcon />,
    label: "Audience & Budget",
    value: "AUDIENCE_BUDGET",
    isActive: index === 1,
  },
  {
    startingStep: "createFacebookAd",
    icon: <CreateFacebookAdIcon />,
    label: "Create Ads",
    value: "CREATE_ADS",
    isActive: index === 2,
  },
  {
    startingStep: "review",
    icon: <ReviewIcon />,
    label: "Review & Publish",
    value: "REVIEW_PUBLISH",
    isActive: index === 3,
  },
];

export function createCampaignWizardTree(): Record<
  string,
  Node<CampaignInput>
> {
  const nodes: Record<string, Node<CampaignInput>> = {
    choosePlatformAndActivityName: {
      id: "choosePlatformAndActivityName",
      purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
        const fieldName = fieldKey.split(".").at(-1);
        if (typeof fieldValue === "string" && fieldName === "platform") {
          const clonedObject = structuredClone(data);
          let selectedActivity =
            clonedObject.Activities[clonedObject.selectedActivityIndex];

          clonedObject.Activities[clonedObject.selectedActivityIndex] = {
            activityName: selectedActivity.activityName,
            platform: fieldValue as ActivityPlatformEnum,
            metaPurpose: "SALES_STANDARD",
            visitedSteps: selectedActivity.visitedSteps,
            completedSteps: selectedActivity.completedSteps,
            currentStep: selectedActivity.currentStep,
            facebookActivityType: "META_STANDARD",
          };

          return {
            ...defaultInitialValues,
            Activities: clonedObject.Activities,
            campaignName: clonedObject.campaignName,
            objective: clonedObject.objective,
            selectedActivityIndex: clonedObject.selectedActivityIndex,
          } as CampaignInput;
        }

        return data;
      },
      getNextStep(input) {
        const { platform, useDefaultSettings } =
          input.Activities[input.selectedActivityIndex];
        if (platform === "META") {
          if (!useDefaultSettings) {
            return "chooseFacebookAccount";
          }

          return "chooseObjectiveAndSubtypes";
        } else if (platform === "EMAIL") {
          return "chooseEmailTemplate";
        }
        throw new Error("Invalid platform");
      },
      isRoot: true,
      component: <FacebookSubSteps />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 1),
    },
    chooseFacebookAccount: {
      id: "chooseFacebookAccount",
      getNextStep() {
        return "chooseObjectiveAndSubtypes";
      },
      getPreviousStep() {
        return "choosePlatformAndActivityName";
      },
      purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
        const fieldName = fieldKey.split(".").at(-1);

        // value of invalid type
        if (fieldValue === null || typeof fieldValue !== "object") {
          return data;
        }

        // TODO: can be optimized just clone the activity
        const clonedObject = structuredClone(data);
        let selectedActivity =
          clonedObject.Activities[clonedObject.selectedActivityIndex];

        if (fieldName === "adAccount" && "adAccountId" in fieldValue) {
          clonedObject.Activities[clonedObject.selectedActivityIndex] = {
            activityName: selectedActivity.activityName,
            platform: selectedActivity.platform,
            metaPurpose: selectedActivity.metaPurpose,
            adAccountId: fieldValue.adAccountId,
            platformAccountId: fieldValue.platformAccountId,
            adAccountName: fieldValue.metaName,
            instagramPageId: "",
            metaPageConnectedInstagramBusinessAccountId: "",
            metaPageId: "",
            pixelId: "",
            visitedSteps: selectedActivity.visitedSteps,
            completedSteps: selectedActivity.completedSteps,
            currentStep: selectedActivity.currentStep,
            facebookActivityType: "META_STANDARD",
          };

          return {
            ...defaultInitialValues,
            Activities: clonedObject.Activities,
            campaignName: clonedObject.campaignName,
            objective: clonedObject.objective,
            selectedActivityIndex: clonedObject.selectedActivityIndex,
          } as CampaignInput;
        } else if (
          fieldName === "metaPageId" &&
          "metaPageId" in fieldValue &&
          "metaPageName" in fieldValue
        ) {
          clonedObject.Activities[clonedObject.selectedActivityIndex] = {
            activityName: selectedActivity.activityName,
            platform: selectedActivity.platform,
            metaPurpose: selectedActivity.metaPurpose,
            metaPageId: fieldValue.metaPageId,
            metaPageName: fieldValue.metaPageName,
            metaPagePicture: fieldValue.metaPagePicture,
            metaPageConnectedInstagramBusinessAccountId:
              fieldValue.connectedInstagramAccount,
            adAccountId: selectedActivity.adAccountId,
            platformAccountId: selectedActivity.platformAccountId,
            adAccountName: selectedActivity.adAccountName,
            visitedSteps: selectedActivity.visitedSteps,
            completedSteps: selectedActivity.completedSteps,
            conversionDomain: selectedActivity.conversionDomain,
            currentStep: selectedActivity.currentStep,
            facebookActivityType: "META_STANDARD",
          };

          return {
            ...defaultInitialValues,
            Activities: clonedObject.Activities,
            campaignName: clonedObject.campaignName,
            objective: clonedObject.objective,
            selectedActivityIndex: clonedObject.selectedActivityIndex,
          } as CampaignInput;
        }

        return data;
      },
      isRoot: false,
      component: <FacebookSubSteps />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 1),
    },
    chooseObjectiveAndSubtypes: {
      id: "chooseObjectiveAndSubtypes",
      purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
        const fieldName = fieldKey.split(".").at(-1);

        //
        if (fieldName === "metaPurpose") {
          const clonedObject = structuredClone(data);
          let selectedActivity =
            clonedObject.Activities[clonedObject.selectedActivityIndex];

          clonedObject.Activities[clonedObject.selectedActivityIndex] = {
            ...selectedActivity,
            Ads: [],
            Adsets: [],
            metaPurpose: fieldValue as any,
          };

          return {
            ...defaultInitialValues,
            Activities: clonedObject.Activities,
            campaignName: clonedObject.campaignName,
            objective: clonedObject.objective,
            selectedActivityIndex: clonedObject.selectedActivityIndex,
          } as CampaignInput;
        }

        return data;
      },
      getNextStep(input) {
        return "chooseAdPlacementType";
      },
      getPreviousStep(input) {
        if (input.Activities[input.selectedActivityIndex].useDefaultSettings) {
          return "choosePlatformAndActivityName";
        }

        return "chooseFacebookAccount";
      },
      isRoot: false,
      component: <FacebookSubSteps />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 1),
    },
    chooseAdPlacementType: {
      id: "chooseAdPlacementType",
      purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
        return data;
      },
      component: <FacebookSubSteps />,
      getPreviousStep(input) {
        return "chooseObjectiveAndSubtypes";
      },
      getNextStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];
        if (selectedActivity.adPlacementType === "ADVANCED") {
          return "chooseAdPlacementOptions";
        }

        if (selectedActivity.metaPurpose === "SALES_CATALOG") {
          return "selectCatalogue";
        }

        return "selectAudiences";
      },
      isRoot: false,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 1),
    },
    chooseAdPlacementOptions: {
      id: "chooseAdPlacementOptions",
      purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
        return data;
      },
      component: <FacebookSubSteps />,
      getPreviousStep(input) {
        return "chooseAdPlacementType";
      },
      getNextStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];
        if (selectedActivity.metaPurpose === "SALES_CATALOG") {
          return "selectCatalogue";
        }

        return "selectAudiences";
      },
      isRoot: false,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 1),
    },
    // chooseFacebookAdType: {
    //   id: "chooseFacebookAdType",
    //   purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
    //     const fieldName = fieldKey.split(".").at(-1);

    //     if (fieldName === "facebookActivityType") {
    //       const clonedObject = structuredClone(data);
    //       let selectedActivity =
    //         clonedObject.Activities[clonedObject.selectedActivityIndex];

    //       clonedObject.Activities[clonedObject.selectedActivityIndex] = {
    //         ...selectedActivity,
    //         Ads: [],
    //         Adsets: [],
    //         productCatalogueId: undefined,
    //         facebookActivityType: fieldValue as FacebookAdTypeEnum,
    //         audienceType: undefined,
    //       };

    //       return {
    //         ...defaultInitialValues,
    //         Activities: clonedObject.Activities,
    //         campaignName: clonedObject.campaignName,
    //         objective: clonedObject.objective,
    //         selectedActivityIndex: clonedObject.selectedActivityIndex,
    //       } as CampaignInput;
    //     }

    //     return data;
    //   },
    //   getNextStep(input) {
    //     const selectedActivity = input.Activities[input.selectedActivityIndex];
    //     if (selectedActivity.facebookActivityType === "META_STANDARD") {
    //       return "selectAudiences";
    //     } else {
    //       return "selectCatalogue";
    //     }
    //   },
    //   getPreviousStep(input) {
    //     const selectedActivity = input.Activities[input.selectedActivityIndex];
    //     if (selectedActivity.adPlacementType === "ADVANCED") {
    //       return "chooseAdPlacementOptions";
    //     }
    //     return "chooseAdPlacementType";
    //   },
    //   isRoot: false,
    //   component: <FacebookSubSteps />,
    //   getBreadcrumbs: (campaignInput) =>
    //     facebookStaticBreadcrumbs(campaignInput, 1),
    // },
    // chooseFacebookEngagementType: {
    //   id: "chooseFacebookEngagementType",
    //   purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
    //     const fieldName = fieldKey.split(".").at(-1);

    //     if (fieldName === "engagementConversionLocation") {
    //       const clonedObject = structuredClone(data);
    //       let selectedActivity =
    //         clonedObject.Activities[clonedObject.selectedActivityIndex];

    //       clonedObject.Activities[clonedObject.selectedActivityIndex] = {
    //         ...selectedActivity,
    //         Ads: [],
    //         Adsets: [],
    //         productCatalogueId: undefined,
    //         facebookActivityType: "META_STANDARD",
    //         engagementConversionLocation:
    //           fieldValue as MetaEngagementConversionLocation,
    //         audienceType: "STANDARD_AUDIENCE",
    //       };

    //       return {
    //         ...defaultInitialValues,
    //         Activities: clonedObject.Activities,
    //         campaignName: clonedObject.campaignName,
    //         objective: clonedObject.objective,
    //         selectedActivityIndex: clonedObject.selectedActivityIndex,
    //       } as CampaignInput;
    //     }

    //     return data;
    //   },
    //   getNextStep(input) {
    //     const selectedActivity = input.Activities[input.selectedActivityIndex];
    //     if (selectedActivity.facebookActivityType === "META_STANDARD") {
    //       return "selectAudiences";
    //     } else {
    //       return "selectCatalogue";
    //     }
    //   },
    //   getPreviousStep(input) {
    //     const selectedActivity = input.Activities[input.selectedActivityIndex];
    //     if (selectedActivity.adPlacementType === "ADVANCED") {
    //       return "chooseAdPlacementOptions";
    //     }
    //     return "chooseAdPlacementType";
    //   },
    //   isRoot: false,
    //   component: <FacebookSubSteps />,
    //   getBreadcrumbs: (campaignInput) =>
    //     facebookStaticBreadcrumbs(campaignInput, 1),
    // },
    selectCatalogue: {
      id: "selectCatalogue",
      getNextStep() {
        return "selectAudienceType";
      },
      purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
        const fieldName = fieldKey.split(".").at(-1);

        //
        if (fieldName === "productCatalogueId") {
          const clonedObject = structuredClone(data);
          let selectedActivity =
            clonedObject.Activities[clonedObject.selectedActivityIndex];

          clonedObject.Activities[clonedObject.selectedActivityIndex] = {
            ...selectedActivity,
            Ads: [],
            Adsets: [],
            productCatalogueId: fieldValue as string,
            audienceType: undefined,
          };

          return {
            ...defaultInitialValues,
            Activities: clonedObject.Activities,
            campaignName: clonedObject.campaignName,
            objective: clonedObject.objective,
            selectedActivityIndex: clonedObject.selectedActivityIndex,
          } as CampaignInput;
        }

        return data;
      },
      getPreviousStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];
        if (selectedActivity.adPlacementType === "ADVANCED") {
          return "chooseAdPlacementOptions";
        }

        return "chooseAdPlacementType";
      },
      isRoot: false,
      component: <FacebookSubSteps />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 1),
    },
    selectAudienceType: {
      id: "selectAudienceType",
      getNextStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];
        if (selectedActivity.audienceType === "STANDARD_AUDIENCE") {
          return "selectAudiences";
        } else {
          return "selectRetargetAudiences";
        }
      },
      getPreviousStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];

        if (selectedActivity.metaPurpose === "SALES_CATALOG") {
          return "selectCatalogue";
        } else {
          return "chooseObjectiveAndSubtypes";
        }
      },
      purgeDataWhenFieldReset(fieldKey, fieldValue, data) {
        const fieldName = fieldKey.split(".").at(-1);

        //
        if (fieldName === "audienceType") {
          const clonedObject = structuredClone(data);
          let selectedActivity =
            clonedObject.Activities[clonedObject.selectedActivityIndex];

          clonedObject.Activities[clonedObject.selectedActivityIndex] = {
            ...selectedActivity,
            Ads: [],
            Adsets: [],
            audienceType: fieldValue as
              | "STANDARD_AUDIENCE"
              | "RETARGETED_AUDIENCE",
          };

          return {
            ...defaultInitialValues,
            Activities: clonedObject.Activities,
            campaignName: clonedObject.campaignName,
            objective: clonedObject.objective,
            selectedActivityIndex: clonedObject.selectedActivityIndex,
          } as CampaignInput;
        }

        return data;
      },
      isRoot: false,
      component: <FacebookSubSteps />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 1),
    },
    selectAudiences: {
      id: "selectAudiences",
      getNextStep() {
        return "setAudiencesBudget";
      },
      getPreviousStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];

        if (selectedActivity.adPlacementType === "ADVANCED") {
          return "chooseAdPlacementOptions";
        }

        if (selectedActivity.metaPurpose === "SALES_CATALOG") {
          return "selectAudienceType";
        }

        return "chooseAdPlacementType";
      },
      isRoot: false,
      component: <SelectFacebookAudiencesStep />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 2),
    },
    setAudiencesBudget: {
      id: "setAudiencesBudget",
      getNextStep() {
        return "createFacebookAd";
      },
      getPreviousStep() {
        return "selectAudiences";
      },
      isRoot: false,
      component: <SelectFacebookAudiencesStep />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 2),
    },
    selectRetargetAudiences: {
      id: "selectRetargetAudiences",
      getNextStep() {
        return "setRetargetAudiencesBudget";
      },
      getPreviousStep() {
        return "selectAudienceType";
      },
      isRoot: false,
      component: <RetargetAudiencesStep />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 2),
    },
    setRetargetAudiencesBudget: {
      id: "setRetargetAudiencesBudget",
      getNextStep() {
        return "createFacebookAd";
      },
      getPreviousStep() {
        return "selectRetargetAudiences";
      },
      isRoot: false,
      component: <RetargetAudiencesStep />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 2),
    },
    // chooseEmailTemplate: {
    //   id: "chooseEmailTemplate",
    //   getNextStep(input) {
    //     return "emailBuilder";
    //   },
    //   getPreviousStep(input) {
    //     return "choosePlatformAndActivityName";
    //   },
    //   isRoot: false,
    //   component: (
    //     <EmailSubSteps
    //       handleBack={async () => {}}
    //       campaignInput={initialData}
    // campaignPresetUpsert={campaignPresetUpsert}
    //       presetId={presetId}
    //     />
    //   ),
    // },
    // emailBuilder: {
    //   id: "emailBuilder",
    //   getNextStep(input) {},
    //   getPreviousStep(input) {},
    //   isRoot: false,
    //   component: (
    //     <EmailBuilderStep
    //       campaignInput={initialData}
    // // campaignPresetUpsert={campaignPresetUpsert}
    //       presetId={presetId}
    //     />
    //   ),
    // },
    // textEditor: {
    //   id: "textEditor",
    //   getNextStep(input) {},
    //   getPreviousStep(input) {},
    //   isRoot: false,
    //   component: (
    //     <EmailTextStep
    //       campaignInput={initialData}
    // // campaignPresetUpsert={campaignPresetUpsert}
    //       presetId={presetId}
    //     />
    //   ),
    // },
    // htmlEditor: {
    //   id: "htmlEditor",
    //   getNextStep(input) {},
    //   getPreviousStep(input) {},
    //   isRoot: false,
    //   component: (
    //     <EmailHtmlStep
    //       campaignInput={initialData}
    // // campaignPresetUpsert={campaignPresetUpsert}
    //       presetId={presetId}
    //     />
    //   ),
    // },
    // createEmail: {
    //   id: "createEmail",
    //   getNextStep(input) {},
    //   getPreviousStep(input) {},
    //   isRoot: false,
    //   component: (
    //     <CreateEmailStep
    //       campaignInput={initialData}
    // // campaignPresetUpsert={campaignPresetUpsert}
    //       presetId={presetId}
    //     />
    //   ),
    // },
    // createWhatsapp: {
    //   id: "createWhatsapp",
    //   getNextStep(input) {},
    //   getPreviousStep(input) {},
    //   isRoot: false,
    //   component: (
    //     <CreateWhatsapp
    //       campaignInput={initialData}
    // // campaignPresetUpsert={campaignPresetUpsert}
    //     />
    //   ),
    // },
    // createSms: {
    //   id: "createSms",
    //   getNextStep(input) {},
    //   getPreviousStep(input) {},
    //   isRoot: false,
    //   component: (
    //     <CreateSms
    //       campaignInput={initialData}
    // // campaignPresetUpsert={campaignPresetUpsert}
    //     />
    //   ),
    // },
    createFacebookAd: {
      id: "createFacebookAd",
      getNextStep() {
        return "review";
      },
      getPreviousStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];
        if (selectedActivity.audienceType === "RETARGETED_AUDIENCE") {
          return "setRetargetAudiencesBudget";
        } else {
          return "setAudiencesBudget";
        }
      },
      isRoot: false,
      component: <CreateFacebookAdForm />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 3),
    },
    review: {
      id: "review",
      getPreviousStep(input) {
        const selectedActivity = input.Activities[input.selectedActivityIndex];

        switch (selectedActivity.platform) {
          case "META": {
            return "createFacebookAd";
          }
          case "EMAIL": {
            return "createEmail";
          }
          default: {
            throw new Error("Invalid platform");
          }
        }
      },
      isRoot: false,
      component: <ReviewStep />,
      getBreadcrumbs: (campaignInput) =>
        facebookStaticBreadcrumbs(campaignInput, 4),
    },
  };

  return nodes;
}
