import { Label } from "../../../components/Label/Label";
import CreatableSelect from "react-select/async-creatable";
import { useOrganizationUsersLazyQuery } from "../../../graphql/generated/schema";
import styles from "./OrganizationUserSelector.module.scss";
import { ActionMeta, MultiValue } from "react-select";

interface IOrganizationUserSelector {
  onChange: (newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => void;
}

// TODO: search should not be instant
export function OrganizationUserSelector({
  onChange,
}: IOrganizationUserSelector) {
  const [getOrganizationUsersFunc, { loading }] =
    useOrganizationUsersLazyQuery();

  async function handleSearch(inputValue: string) {
    const { data } = await getOrganizationUsersFunc({
      variables: {
        filter: {
          hideCurrentUser: true,
          ...(inputValue && {
            name: inputValue,
          }),
        },
        sortBy: {
          field: "CREATED_AT",
          direction: "DESC",
        },
      },
    });

    if (data) {
      let options =
        data && data?.organizationUsers
          ? data?.organizationUsers?.edges.map((v) => ({
              value: v.node?.id,
              label: `${v.node?.email} - ${v.node?.name}`,
            }))
          : [];
      return options;
    }
  }

  return (
    <Label label="Enter email to add or invite member">
      <CreatableSelect
        placeholder="Invite or add members"
        // idk why but it showing type working yet still working
        // goes away if you use options instead of value but docs say use value
        // and we don't even need nested options here
        // @ts-ignore
        loadOptions={handleSearch}
        isLoading={loading}
        allowCreateWhileLoading={false}
        formatCreateLabel={(value) => `Invite user with email: ${value}`}
        isClearable
        isMulti
        classNames={{
          valueContainer: () => styles.valueContainer,
        }}
        onChange={(newValue, actionMeta) => {
          onChange(newValue, actionMeta);
        }}
        defaultOptions
      />
    </Label>
  );
}
