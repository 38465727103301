import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import classNames from "classnames";
import styles from "./WhatsappTemplateItem.module.scss";
import { Checkbox } from "src/components/Checkbox/Checkbox";

export function WhatsappTemplateItem({
  selectedWhatsappTemplate,
  setSelectedWhatsappTemplate,
  whatsappTemplate,
}) {
  const isSelected =
    selectedWhatsappTemplate?.node?.id === whatsappTemplate.node.id;

  function handleSelect() {
    setSelectedWhatsappTemplate(whatsappTemplate);
  }

  return (
    <div className={classNames(styles.templateItem)}>
      <Checkbox
        size="small"
        checked={isSelected}
        onChange={handleSelect}
        value={whatsappTemplate.node.id}
      />
      <div className={styles.mainRow}>
        {/* <Asset metaActivityAdTemplate={metaActivityAdTemplate} /> */}
        <div className={styles.titleContainer}>
          <span className={classNames(styles.title)}>
            {whatsappTemplate.node.name}
          </span>

          <span className={styles.activityCount}>
            {/* <img src={adsCount} width={24} height={24} alt="ad count" /> */}
            {whatsappTemplate?.node.outreachCount || "-"}
          </span>
        </div>
      </div>
      <div className={styles.badgeSection}>
        <span
          className={classNames(styles.badge, {
            [styles.inUseBadge]: whatsappTemplate.node.adsCount > 0,
          })}
        >
          {whatsappTemplate.node.outreachCount > 0 ? "In use" : "Not in use"}
        </span>
      </div>
      <div className={styles.adType}>
        {/* {getTemplateType(metaActivityAdTemplate)} */}
      </div>
    </div>
  );
}
