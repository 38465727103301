import { useContext } from "react";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { formatNumber } from "src/lib/formatNumber";
import { GraphHeader } from "src/modules/global/components/GraphHeader";
import AuthContext from "src/stores/AuthContext";

export function SegmentCustomerEngageGraphHeader({
  dateFrom,
  dateTo,
  segmentId,
}: {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}) {
  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "engage_engagement",
    userToken,
    {
      segmentId,
      dateFrom,
      dateTo,
    }
  );

  if (error) {
    return null;
  }

  if (loading) {
    return null;
  }

  let insights = data[0];

  const graphStats = [
    {
      label: "No engagement",
      color: "#D5F3F8",
      textColor: "#575959",
      value: insights?.noEngagement,
      valueType: "NUMBER" as const,
      dataKey: "noEngagement",
    },
    {
      label: "Barely engaged",
      color: "#A4E8F2",
      textColor: "#916A00",
      value: insights?.barelyEngaged,
      valueType: "NUMBER" as const,
      dataKey: "barelyEngaged",
    },
    {
      label: "Very engaged",
      color: "#52C4CF",
      textColor: "#D72C0D",
      value: insights?.veryEngaged,
      valueType: "NUMBER" as const,
      dataKey: "veryEngaged",
    },
  ];

  return (
    <GraphHeader
      stats={graphStats}
      title={`${
        insights?.totalCount ? formatNumber(insights?.totalCount) : 0
      } Sends`}
      caption="Updated 4 hours ago"
    />
  );
}
