import {
  ActivityCreateInput,
  ActivityPlatformEnum,
  CampaignCreateInput,
  CampaignObjectiveEnum,
  CampaignUpdateInput,
  MetaActivityAdsetCreateInput,
  MetaEngagementConversionLocation,
  MetaEngagementDestinationEnum,
  OutreachCreateInput,
  OutreachTypeEnum,
} from "src/graphql/generated/schema";
import {
  ActivityInput,
  ActivityInputV2,
  CampaignInput,
} from "../misc/createCampaignTypes";

type CampaignTransformedValues = CampaignCreateInput | CampaignUpdateInput;

const facebookPlacements = [
  "FACEBOOK_FEED",
  "FACEBOOK_RIGHT_HAND_COLUMN",
  "FACEBOOK_MARKETPLACE",
  "FACEBOOK_VIDEO_FEEDS",
  "FACEBOOK_STORY",
  "FACEBOOK_SEARCH",
  "FACEBOOK_INSTREAM_VIDEO",
  "FACEBOOK_REELS",
  "FACEBOOK_PROFILE_FEED",
];

const instagramPlacements = [
  "INSTAGRAM_STREAM",
  "INSTAGRAM_STORY",
  "INSTAGRAM_EXPLORE",
  "INSTAGRAM_EXPLORE_HOME",
  "INSTAGRAM_REELS",
  "INSTAGRAM_PROFILE_FEED",
  "INSTAGRAM_IG_SEARCH",
];

function adPlacementOptionsBasedOnType(
  adPlacementType: ActivityInput["adPlacementType"],
  adPlacementOptions: ActivityInput["adPlacementOptions"],
) {
  if (adPlacementType === "ADVANCED") {
    let placements = [];
    if (adPlacementOptions.includes("FACEBOOK")) {
      placements.push(...facebookPlacements);
    }
    if (adPlacementOptions.includes("INSTAGRAM")) {
      placements.push(...instagramPlacements);
    }
    return placements;
  }

  return [...facebookPlacements, ...instagramPlacements];
}

export function getMetaObjective(
  activity: ActivityInputV2,
): CampaignObjectiveEnum {
  switch (activity.metaPurpose) {
    case "MESSAGE_INSTAGRAM":
    case "MESSAGE_MESSENGER":
    case "MESSAGE_WHATSAPP":
    case "CALL":
    case "PROMOTE_POST":
    case "VIDEO_2_SEC_VIEW":
    case "VIDEO_THRUPLAY":
      return "ENGAGEMENT";

    case "SALES_CATALOG":
    case "SALES_STANDARD":
      return "CONVERSIONS";

    // TODO: handle awareness and leads
    case "WEBSITE_VIEWS":
      return "TRAFFIC";

    default:
      return "CONVERSIONS";
  }
}

function getEngagementConversionLocation(
  activity: ActivityInputV2,
): MetaEngagementConversionLocation {
  switch (activity.metaPurpose) {
    case "MESSAGE_INSTAGRAM":
    case "MESSAGE_MESSENGER":
    case "MESSAGE_WHATSAPP":
      return "MESSAGE";

    case "SALES_CATALOG":
    case "SALES_STANDARD":
      return null;

    case "WEBSITE_VIEWS":
      return null;

    default:
      // meta purpose is like engagement conversion location but flat
      return activity.metaPurpose;
  }
}

function getEngagementDestination(
  activity: ActivityInputV2,
): MetaEngagementDestinationEnum[] {
  switch (activity.metaPurpose) {
    case "MESSAGE_INSTAGRAM":
      return ["INSTAGRAM_DIRECT"];
    case "MESSAGE_MESSENGER":
      return ["MESSENGER"];
    case "MESSAGE_WHATSAPP":
      return ["WHATSAPP"];

    default:
      return [];
  }
}

function getLink(activity: ActivityInputV2, fallbackLink: string): string {
  switch (activity.metaPurpose) {
    case "MESSAGE_INSTAGRAM":
      return "https://www.instagram.com";
    case "MESSAGE_MESSENGER":
      return "https://www.messenger.com";
    case "MESSAGE_WHATSAPP":
      return "https://web.whatsapp.com";

    default:
      return fallbackLink;
  }
}

export function transformCampaignInput(values: CampaignInput) {
  const transformedValues: CampaignTransformedValues = {
    ...(values.id && { id: values.id }),
    presetId: values.presetId,
    name: values.campaignName,
    objective: values.objective,
    // status: "DRAFT",
    // Outreaches: values.Activities.filter(
    //   (v) => v.EmailObject || v.TextMessageObject || v.WhatsappMessageObject,
    // ).map((v) => ({
    //   ...(v.id && {
    //     id: v.id,
    //   }),
    //   name: v.activityName,
    //   scheduledAt: v.scheduledAt,
    //   status: "DRAFT",
    //   ...(v.TextMessageObject && {
    //     TextMessageObject: {
    //       params: v.TextMessageObject.params,
    //       textMessageTemplateId: v.TextMessageObject.textMessageTemplateId,
    //     },
    //   }),
    //   ...(v.WhatsappMessageObject && {
    //     WhatsappMessageObject: {
    //       whatsappTemplateId: v.WhatsappMessageObject.whatsappTemplateId,
    //     },
    //   }),
    //   ...(v.EmailObject && {
    //     EmailObject: {
    //       ...(v.EmailObject.id && {
    //         id: v.EmailObject.id,
    //       }),
    //       emailDesignTemplateId: v.EmailObject.emailDesignTemplateId,
    //       emailTextTemplateId: v.EmailObject.emailTextTemplateId,
    //       emailHTMLTemplateId: v.EmailObject.emailHTMLTemplateId,
    //       params: v.EmailObject.params,
    //       senderEmailId: v.EmailObject.senderEmailId,
    //       subject: v.EmailObject.subject,
    //     },
    //   }),
    //   segmentIds: v.segmentIds,
    //   outreachType: v.platform as OutreachTypeEnum,
    // })),
    // Activities: values.Activities.filter((v) => v.metaPurpose).map(
    //   (activity) => newFunction(activity)),
  } as CampaignTransformedValues;

  return transformedValues;
}

export function transformActivity(
  activity: ActivityInputV2,
): ActivityCreateInput {
  return {
    campaignId: activity.campaignId,
    campaignName: activity.campaignName,
    shouldCreateCampaign: activity.shouldCreateCampaign,
    name: activity.activityName,
    platform: activity.platform,
    scheduledAt: activity.scheduledAt,
    description: activity.activityDescription,
    MetaActivity: transformMetaActivity(activity),
  };
}

function transformMetaActivity(activity: ActivityInputV2) {
  return {
    objective: getMetaObjective(activity),
    productCatalogueId: activity.Ads[0].catalogueId,
    activityType:
      activity.metaPurpose === "SALES_CATALOG"
        ? ("META_DYNAMIC" as const)
        : ("META_STANDARD" as const),
    conversionDomain: activity.conversionDomain,
    enabledAccountId: activity.adAccountId,
    // FIXME: As of now all fields provided are facebook ids not DB ids. This needs to be changed
    metaInstagramId: activity.metaInstagramPageId,
    metaPageConnectedInstagramBusinessAccountId:
      activity.metaPageConnectedInstagramBusinessAccountId,
    metaPageId: activity.metaPageId,
    metaPixelId: activity.metaPixelId,
    engagementConversionLocation: getEngagementConversionLocation(activity),
    engagementDestinations: getEngagementDestination(activity),
    adPlacements: adPlacementOptionsBasedOnType(
      activity.adPlacementType,
      activity.adPlacementOptions,
    ),
    MetaActivityAds: activity.Ads.map((ad, i) => ({
      ...(ad.adTemplateId && {
        adTemplateId: ad.adTemplateId,
      }),
      ...(ad.callToActionLabel && {
        CTAText: ad.callToActionLabel,
      }),
      ...(ad.phoneNumber && { phoneNumber: ad.phoneNumber }),
      ...(ad.phoneCountryCode && {
        phoneCountryCode: ad.phoneCountryCode,
      }),
      ...(ad.PageWelcomeMessage && {
        PageWelcomeMessage: {
          greeting: ad.PageWelcomeMessage.greeting,
          prefilledText:
            ad.PageWelcomeMessage.selectedWhatsappCustomerAction ===
            "PREFILLED_MESSAGE"
              ? ad.PageWelcomeMessage.prefilledText
              : null,
          MessageOptions: ad.PageWelcomeMessage.MessageOptions.map((o) => ({
            text: o.text,
            automatedReply: o.automatedReply,
          })),
        },
      }),
      LineItems: ad.LineItems.map((lineItem, index) => ({
        description: lineItem.description ?? "",
        headline: lineItem.headline ?? "",
        link: getLink(activity, lineItem.link),
        // because when lineItems are first created they might not have the order field
        order: lineItem.order ?? index,
        postAssetId: lineItem.PostAsset ? lineItem.PostAsset.id : null,
        storyAssetId: lineItem.StoryAsset ? lineItem.StoryAsset.id : null,
      })),
      adType: ad.adType,
      existingPostCTALink: ad.existingPostCTALink,
      existingPostId: ad.existingPostId,
      name: `${activity.activityName} | ad ${i + 1}`,
      primaryText: ad.primaryText,
    })),
    // filter only adsets that are selected
    MetaActivityAdsets: activity.Adsets.map(
      (adset) =>
        ({
          promotedProductSetId: activity.Ads[0].metaProductSetId,
          // incase ProductSetSpecs is null then return empty array
          ProductSetSpecs: adset?.ProductSetSpecs
            ? adset.ProductSetSpecs.map((p) => ({
                Rules: p.Rules,
                // TODO: add feature to mark every single rule with its own product set id
                // instead of reusing the same single product set id
                productSetId: activity.Ads[0].metaProductSetId,
              }))
            : [],
          budget: parseInt(adset.budget) * 100,
          audienceId: adset.audienceId,
          audienceSetId: adset.audienceSetId,
        } as MetaActivityAdsetCreateInput),
    ),
  };
}

export function transformOutreach(
  activity: ActivityInputV2,
): OutreachCreateInput {
  return {
    name: activity.activityName,
    scheduledAt: activity.scheduledAt,
    campaignId: activity.campaignId,
    campaignName: activity.campaignName,
    shouldCreateCampaign: activity.shouldCreateCampaign,
    status: "DRAFT" as const,
    ...(activity.TextMessageObject && {
      TextMessageObject: {
        params: activity.TextMessageObject.params,
        textMessageTemplateId: activity.TextMessageObject.textMessageTemplateId,
      },
    }),
    ...(activity.WhatsappMessageObject && {
      WhatsappMessageObject: {
        whatsappTemplateId: activity.WhatsappMessageObject.id,
      },
    }),
    ...(activity.EmailObject && {
      EmailObject: {
        ...(activity.EmailObject.id && {
          id: activity.EmailObject.id,
        }),
        emailDesignTemplateId: activity.EmailObject.emailDesignTemplateId,
        emailTextTemplateId: activity.EmailObject.emailTextTemplateId,
        emailHTMLTemplateId: activity.EmailObject.emailHTMLTemplateId,
        params: activity.EmailObject.params,
        senderEmailId: activity.EmailObject.senderEmailId,
        subject: activity.EmailObject.subject,
      },
    }),
    segmentIds: activity.segmentIds,
    outreachType: getOutreachType(activity.platform),
  };
}

function getOutreachType(platform: ActivityPlatformEnum): OutreachTypeEnum {
  switch (platform) {
    case "EMAIL":
      return "EMAIL";
    case "TEXT_MESSAGE":
      return "TEXT_MESSAGE";
    case "WHATSAPP":
      return "WHATSAPP";
    case "PUSH_NOTIFICATION":
      return "PUSH_NOTIFICATION";
    case "GOOGLE":
    case "META":
      throw new Error("invalid platform for outreach");
  }
}
