import styles from "./EmailSent.module.scss";
import FeedbackCard from "src/components/FeedbackCard/FeedbackCard";
import emailConfirm from "../assets/emailConfirm.svg";

export function EmailConfirmation() {
  return (
    <div className={styles.container}>
      <FeedbackCard
        image={emailConfirm}
        title="Email Confirmation"
        description={<p>Your Email has been confirmed.</p>}
      />
    </div>
  );
}
