import { useSegmentTemplatesQuery } from "src/graphql/generated/schema";
import { SegmentCreateModal } from "./SegmentCreateModal";

interface ISegmentCreateModalLoader {
  isCreateModalOpen: boolean;
  onCreateModalClose: () => void;
}

export function SegmentCreateModalLoader({
  isCreateModalOpen,
  onCreateModalClose,
}: ISegmentCreateModalLoader) {
  const { data: segmentTemplatesData } = useSegmentTemplatesQuery({
    variables: {
      filter: {
        take: 10,
      },
    },
  });

  const segmentTemplates = segmentTemplatesData?.segmentTemplates.edges.map(
    (v) => v.node
  );

  if (segmentTemplates?.length) {
    return (
      <SegmentCreateModal
        isOpen={isCreateModalOpen}
        segmentTemplates={segmentTemplates}
        onClose={onCreateModalClose}
      />
    );
  }

  // return null in case of loading and error
  return null;
}
