import { Label } from "src/components/Label/Label";
import Select from "src/components/Select/Select";
import styles from "./AudienceAdvanceTargeting.module.scss";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-400/outlined/info.svg";
import { ReactComponent as SearchIcon } from "@material-symbols/svg-400/outlined/search.svg";
import Tooltip from "src/components/Tooltip/Tooltip";
import { AudienceLocationInput } from "./AudienceLocationInput";
import { metaLanguages } from "../misc/metaLanguages";
import ComboBox from "src/components/ComboBox/ComboBox";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  useFindDuplicateFromAudienceSetInputLazyQuery,
  useFindDuplicateFromAudienceSetInputQuery,
  useMetaAudienceSetCreateMutation,
} from "src/graphql/generated/schema";
import { MutableRefObject, useContext, useState } from "react";
import { StepWizardContext } from "../../global/misc/StepWizardContext";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";

const schema = Yup.object({
  locations: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      name: Yup.string().required(),
      type: Yup.string().required(),
      countryName: Yup.string().required(),
      countryCode: Yup.string().required(),
      regionId: Yup.string().nullable(),
      region: Yup.string().nullable(),
    })
  ),
  languages: Yup.array(Yup.string().required()).required(
    "language is required"
  ),
  // at-least 2 digit (rest is handled in submit)
  minAge: Yup.string()
    .matches(/\d\d+/, "min age must be a digit")
    .required("min age is required"),
  maxAge: Yup.string()
    .matches(/\d\d+/, "max age must be a digit")
    .required("max age is required"),
});

interface IAudienceAdvanceTargeting {
  stepRef: MutableRefObject<HTMLDivElement>;
  setExisitingAudience: (i: { id: string; name: string }) => void;
}

export function AudienceAdvanceTargeting({
  stepRef,
  setExisitingAudience,
}: IAudienceAdvanceTargeting) {
  const { data, activeStepIndex } = useContext(StepWizardContext);
  const [createMetaAudienceSet] = useMetaAudienceSetCreateMutation();
  const [findDuplicateFromAudienceSetInputFunc] =
    useFindDuplicateFromAudienceSetInputLazyQuery();

  const isStepInactive = activeStepIndex < 2;
  const navigate = useNavigate();
  const [isSkipped, setIsSkipped] = useState(false);

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        minAge: "18",
        maxAge: "65",
        gender: "ALL",
        languages: [],
        Locations: [],
      }}
      onSubmit={async (values, { setFieldError }) => {
        const maxAge = parseInt(values.maxAge);
        const minAge = parseInt(values.minAge);

        // this can't be done in yup because of the casting from string to number
        if (maxAge > 65) {
          setFieldError("maxAge", "max age can't be above 65");
          return;
        }

        if (maxAge < minAge) {
          setFieldError("maxAge", "max age can't be below min age");
          return;
        }

        if (minAge < 18) {
          setFieldError("minAge", "min age can't be below 18");
          return;
        }

        const { data: payload } = await findDuplicateFromAudienceSetInputFunc({
          variables: {
            input: {
              languages: values.languages,
              name: data.name,
              description: "",
              interestRelation: "OR",
              adAccountId: data.adAccountId,
              metaPageId: data.pageId,
              instagramAccountId: data.instagramAccountId,
              pixelId: data.pixelId,
              audiencePresets: data.audiencePresets,
              excludeAudiencePresets: data.excludeAudiencePresets,
              ExcludeInterests: data.ExcludeInterests,
              Interests: data.Interests,
              Locations: values.Locations.map((l) => ({
                metaLocationId: l.id,
                name: l.name,
                type: l.type,
                countryName: l.countryName,
                countryCode: l.countryCode,
                regionId: l.regionId,
                region: l.region,
              })),
              gender: values.gender as "ALL",
              minAge: parseInt(values.minAge),
              maxAge: parseInt(values.maxAge),
            },
          },
        });

        if (payload && payload.findDuplicateFromAudienceSetInput) {
          setExisitingAudience(payload.findDuplicateFromAudienceSetInput);
          window.scrollTo(0, 0);
          return;
        }

        try {
          await createMetaAudienceSet({
            variables: {
              input: {
                languages: values.languages,
                name: data.name,
                description: "",
                interestRelation: "OR",
                adAccountId: data.adAccountId,
                metaPageId: data.pageId,
                instagramAccountId: data.instagramAccountId,
                pixelId: data.pixelId,
                audiencePresets: data.audiencePresets,
                excludeAudiencePresets: data.excludeAudiencePresets,
                ExcludeInterests: data.ExcludeInterests,
                Interests: data.Interests,
                Locations: values.Locations.map((l) => ({
                  metaLocationId: l.id,
                  name: l.name,
                  type: l.type,
                  countryName: l.countryName,
                  countryCode: l.countryCode,
                  regionId: l.regionId,
                  region: l.region,
                })),
                gender: values.gender as "ALL",
                minAge: parseInt(values.minAge),
                maxAge: parseInt(values.maxAge),
              },
            },
          });
          navigate("/audiences");
          toast.success("Audience Creation Started");
        } catch (e) {
          toast.error(e.message);
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        return (
          <Form>
            <div ref={stepRef} className={styles.wrapper}>
              {isStepInactive && <div className={styles.inactiveOverlay} />}
              <div className={styles.header}>
                <h3 className={styles.heading}>Advance targeting</h3>
                <p className={styles.subheading}>
                  Target audience based on the below give information, this will
                  narrow down your target size. Better the targeting better the
                  results.
                </p>
              </div>

              <div className={styles.advanceTargetingForm}>
                <AudienceLocationInput
                  setLocations={(newValues) => {
                    setFieldValue("Locations", newValues);
                  }}
                  locations={values.Locations}
                />
                <Label
                  label={
                    <span className={styles.languageLabel}>
                      Select Language
                      <Tooltip
                        placement="TOP_CENTER"
                        supportingText="Select Language"
                      >
                        <InfoIcon width={16} height={16} />
                      </Tooltip>
                    </span>
                  }
                >
                  <ComboBoxV2
                    multiple
                    isFullWidth
                    renderSelectedValue={(value) =>
                      metaLanguages.find(
                        (l) => l.auxiliaryData.key.toString() === value
                      ).name
                    }
                    icon={SearchIcon}
                    value={values.languages}
                    onChange={(newValues) => {
                      setFieldValue("languages", newValues);
                    }}
                    placeholder="Search for language"
                    options={metaLanguages.map((l) => ({
                      label: l.name,
                      value: l.auxiliaryData.key.toString(),
                    }))}
                  />
                </Label>

                <div>
                  <Label label="Age and Gender" />
                  <div className={styles.ageAndGender}>
                    <InputFormik width="full" name="minAge" />
                    <InputFormik width="full" name="maxAge" />
                    <SelectV2
                      isFullWidth
                      onChange={(value) => {
                        setFieldValue("gender", value);
                      }}
                      name="gender"
                      options={[
                        { label: "All Genders", value: "ALL" },
                        { label: "Male", value: "MALE" },
                        { label: "Female", value: "FEMALE" },
                      ]}
                      value={values.gender}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.buttonGroup}>
                <ButtonV2 loading={isSubmitting && !isSkipped} type="submit">
                  Save & Finish
                </ButtonV2>
                <ButtonV2
                  loading={isSubmitting && isSkipped}
                  type="submit"
                  variant="outline"
                  onClick={() => {
                    setIsSkipped(true);
                  }}
                >
                  Skip & Finish
                </ButtonV2>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
