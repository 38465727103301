import Badge from "src/components/Badge/Badge";
import { Button } from "src/components/Button/Button";
import styles from "./MetaAccountItem.module.scss";
import classNames from "classnames";
import { Switch } from "@headlessui/react";
import { ReactComponent as ArrowRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import { ReactComponent as ProgressActivity } from "@material-symbols/svg-400/rounded/progress_activity.svg";
import { MetaAdAccountStatus } from "src/graphql/generated/schema";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface IMetaIntegrationConnectedAccount {
  name?: string;
  amountSpent?: number;
  imageUrl?: string;
  enabled?: boolean;
  status?: MetaAdAccountStatus;
  onToggle: () => void;
  loading?: boolean;
  onViewDetails: () => void;
}

export function MetaAccountItem({
  name,
  amountSpent,
  imageUrl,
  enabled,
  status,
  onToggle,
  loading,
  onViewDetails,
}: IMetaIntegrationConnectedAccount) {
  const showBadge = enabled || status === "DISABLED";
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  return (
    <div className={styles.card}>
      <div className={styles.imageTile}>
        <div className={styles.accountInfo}>
          <img
            src={
              imageUrl || `https://api.dicebear.com/7.x/shapes/svg?seed=${name}`
            }
            alt="profilePicture"
            className={styles.profile}
          />
          <div>
            <span className={styles.name}>{name}</span>
            <span className={styles.accountId}>
              Spend Last 28 Days:{" "}
              {formatNumberByType(amountSpent, "CURRENCY", {
                showDecimal: true,
                selectedCurrencyCode,
              })}
            </span>
          </div>
        </div>
        {showBadge && (
          <Badge
            label={status === "DISABLED" ? "Violation Issue" : "Active"}
            status={status === "DISABLED" ? "danger" : "success"}
          />
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.switchWrapper}>
          {loading ? (
            <>
              <span className={styles.footerLoadingText}>Loading</span>
              <span>
                <ProgressActivity
                  height={16}
                  width={16}
                  className={styles.loadingIcon}
                />
              </span>
            </>
          ) : (
            <>
              <span className={styles.footerText}>Enable</span>
              <Switch
                checked={enabled}
                disabled={loading}
                onChange={onToggle}
                className={classNames(styles.switchContainer, {
                  [styles.darkContainer]: enabled,
                  [styles.lightContainer]: !enabled,
                })}
              >
                <span className={styles.srOnly}>{name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(styles.circle, {
                    [styles.enabledCircle]: enabled,
                    [styles.disabledCircle]: !enabled,
                  })}
                />
              </Switch>
            </>
          )}
        </div>
        <Button
          onClick={onViewDetails}
          // eslint-disable-next-line react/style-prop-object
          style="plain"
          color="primary"
        >
          <div className={styles.viewDetailBtn}>
            <span className={styles.viewBtnText}>View Detail</span>
            <ArrowRight className={styles.arrowIco} />
          </div>
        </Button>
      </div>
      {/* <Button
        size="micro"
        color={enabled ? "subdued" : "primary"}
        style={enabled ? "outline" : "solid"}
        className={styles.btn}
        onClick={onClick}
        loading={loading}
        disabled={loading}
      >
        {enabled ? "Revoke Access" : "Enable Account"}
      </Button> */}
    </div>
  );
}
