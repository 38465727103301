import * as Yup from "yup";

export const messageTemplateCreateSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  body: Yup.object({
    text: Yup.string()
      .required("Body is required")
      .max(1024, "Max character limit is 1024"),
    variables: Yup.array(Yup.string()).required("Body is required"),
  }),
  header: Yup.object({
    format: Yup.string()
      .oneOf(["NONE", "MEDIA", "TEXT"])
      .required("Header format is required"),
    asset: Yup.object({}).when("format", (value, schema) => {
      if (value === "MEDIA") {
        return Yup.object({ uri: Yup.string().required("Media is required") });
      }

      return schema.nullable();
    }),

    text: Yup.string()
      .max(1024, "Max character limit is 1024")
      .when("format", (value, schema) => {
        if (value === "TEXT") {
          return schema.required("Header text is required");
        }

        return schema;
      }),
    variables: Yup.array(Yup.string()).required("header variables is required"),
  }),
  category: Yup.string().oneOf([
    "AUTHENTICATION",
    "MARKETING",
    "REMINDERS",
    "SUPPORT",
    "TRANSACTIONAL",
    "UTILITY",
  ]),
  footer: Yup.string().max(1024, "Max character limit is 1024"),
  cta: Yup.object({
    type: Yup.string().oneOf(["ACTION", "NONE", "QUICK_REPLIES"]),
    quickReplies: Yup.array(Yup.string()).when("type", (value, schema) => {
      if (value === "QUICK_REPLIES") {
        return Yup.array(
          Yup.string().min(1, "Can't be empty").required("option is required"),
        )
          .max(3, "you can only have max of 3 replies")
          .min(1, "Atleast one reply is required");
      }

      return schema;
    }),
    action: Yup.object({}).when("type", (value, schema) => {
      if (value === "ACTION") {
        return Yup.object({
          label: Yup.string().required("Label is required"),
          type: Yup.string().oneOf(["URL"]).required("Label is required"),
          link: Yup.string().required("Label is required"),
        });
      }

      return schema;
    }),
  }),
});
