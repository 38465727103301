import styles from "./Review.module.scss";
import Select from "src/components/Select/Select";
import { useFormikContext } from "formik";
import { AudiencePresetCard } from "./AudiencePresetCard";
import {
  useMetaEnabledAdAccountsQuery,
  useMetaInstagramAccountsQuery,
  useMetaPagesQuery,
  useMetaPixelsQuery,
} from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Button } from "src/components/Button/Button";
import {
  IAudiencePreset,
  IAudienceWizardPreset,
  ICreatePreviewBasedAudienceFormInput,
  WizardFormStepValue,
} from "../misc/createPreviewBasedAudienceTypes";
import { MetaPixelSelector } from "src/modules/global/components/MetaPixelSelector";
import { MetaPageSelector } from "src/modules/campaign-wizard/components/MetaPageSelector";
import { MetaInstagramPageSelector } from "src/modules/campaign-wizard/components/MetaInstagramPageSelector";

interface IReview {
  changeStep: (stepName: WizardFormStepValue) => void;
}

// TODO: add validation according to the audience platform
// all of the instagram audiences require instagramId,
// similarly the facebook audiences require metaPageId
export function Review({ changeStep }: IReview) {
  const { values, setFieldValue, handleSubmit, isSubmitting } =
    useFormikContext<ICreatePreviewBasedAudienceFormInput>();
  const { data: metaAdAccountsData, loading: metaAdAccountsLoading } =
    useMetaEnabledAdAccountsQuery();

  const selectedPresetsPlatform = Array.from(
    new Set(values.selectedPresets.flatMap((v) => v.platforms)),
  );

  if (metaAdAccountsLoading) {
    return (
      <div>
        <Spinner height="screen" />
      </div>
    );
  }

  return (
    <div>
      <section>
        <h1 className={styles.pageTitle}>Review your audience</h1>

        <div className={styles.fieldsContainer}>
          <Select
            name="adAccountId"
            label="Ad Account"
            options={
              metaAdAccountsData && metaAdAccountsData.metaEnabledAdAccounts
                ? metaAdAccountsData.metaEnabledAdAccounts.map((v) => ({
                    label: v.metaName,
                    value: v.id,
                  }))
                : []
            }
            value={values.enabledAccountId}
            hideArrow
            disabled
            onChange={() => {}}
          />
        </div>
      </section>

      <section>
        <div className={styles.sectionTitle}>Audience Sources</div>
        <div className={styles.fieldsContainer}>
          {/* <Select
            options={
              metaPixelsData && metaPixelsData
                ? metaPixelsData.metaPixels.map((value) => ({
                    value: value.id,
                    label: value.name,
                  }))
                : []
            }
            label="Connected Pixel"
            value={values?.pixelId}
            onChange={(v) => {
              setFieldValue("pixelId", v);
            }}
          /> */}
          <MetaPixelSelector
            adAccountId={values.enabledAccountId}
            name={"pixelId"}
            setSelectedPixel={(v) => {
              setFieldValue("pixelId", v);
            }}
            selectedPixelId={values?.pixelId}
          />

          <MetaPageSelector
            selectedAdAccountId={values.enabledAccountId}
            selectedPageId={values?.metaPageId}
            setSelectedPageId={(v) => {
              setFieldValue("metaPageId", v);
            }}
            formKey={"metaPageId"}
          />
          {/* <Select
            options={
              metaPagesData && metaPagesData.metaPages
                ? metaPagesData.metaPages.map((v) => ({
                    label: v.name,
                    value: v.id,
                  }))
                : []
            }
            label="Connected Facebook Page"
            value={values?.metaPageId}
            onChange={(v) => {
              setFieldValue("facebookPageId", v);
            }}
          /> */}
          {selectedPresetsPlatform.includes("INSTAGRAM") && (
            <MetaInstagramPageSelector
              selectedAdAccountId={values.enabledAccountId}
              selectedInstagramPageId={values?.instagramPageId}
              setSelectedInstagramPageId={(v) => {
                setFieldValue("instagramPageId", v);
              }}
              formKey={"instagramPageId"}
            />
          )}
          {/* <Select
                options={
                  metaInstagramAccountsData &&
                  metaInstagramAccountsData.metaInstagramAccounts
                    ? metaInstagramAccountsData.metaInstagramAccounts.map(
                        (v) => ({
                          label: v.name,
                          value: v.id,
                        })
                      )
                    : []
                }
                label="Connected Instagram Page"
                value={values?.instagramPageId}
                onChange={(v) => {
                  setFieldValue("instagramPageId", v);
                }}
              /> */}
        </div>
      </section>

      <section>
        <div className={styles.sectionTitle}>Audience Presets</div>
        <div className={styles.section}>
          <div className={styles.cardGrid}>
            {values.selectedPresets.map((v) => (
              <AudiencePresetCard
                audiencePreset={v}
                isActive={values.selectedPresets.some(
                  (item: IAudienceWizardPreset) => item.id === v.id,
                )}
              />
            ))}
          </div>
        </div>
      </section>

      <StickyFooter
        actions={
          <>
            <Button
              onClick={() => changeStep("addAudienceDetails")}
              size="small"
              color="subdued"
              style="outline"
            >
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              size="small"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Create Audience
            </Button>
          </>
        }
      />
    </div>
  );
}
