import noResultsFound from "../assets/noResultsFound.png";
import styles from "./NoFilteredResultsFound.module.scss";

export function NoFilteredResultsFound() {
  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src={noResultsFound}
        alt="no result"
        width={133}
        height={130}
      />
      <h2 className={styles.title}>
        Oops! It seems we're on a wild goose chase
      </h2>
      <p className={styles.description}>
        Double-check your spelling or try another search - the information might
        be playing hide and seek!
      </p>
    </div>
  );
}
