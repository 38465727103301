import { CampaignPublishUserError } from "src/graphql/generated/schema";

interface parseCanPublishErrorReturns {
  shouldShowBanner: boolean;
  message: string;
  // showEditSegmentButton: boolean;
}

export function parseCanPublishError(
  userError: CampaignPublishUserError
): parseCanPublishErrorReturns {
  if (!userError) {
    return {
      message: "",
      shouldShowBanner: false,
      // showEditSegmentButton: false,
    };
  }

  switch (userError.code) {
    case "NOT_AVAILABLE_IN_CURRENT_PLAN":
      return {
        message: "This functionality is not available in your current plan.",
        shouldShowBanner: true,
        // showEditSegmentButton: false,
      };
    case "PLAN_EXHAUSTED":
      return {
        message:
          "You have exhausted your current plan. Please consider upgrade plan.",
        shouldShowBanner: true,
        // showEditSegmentButton: false,
      };
    case "PLAN_WILL_EXHAUST":
      return {
        message:
          "Selected segments contains too many customers. Right now you don't have enough credit to send email all of them. Either reduce customer or upgrade plan",
        shouldShowBanner: true,
        // showEditSegmentButton: true,
      };
  }
}
