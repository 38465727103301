import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StatCard from "src/components/StatCard/StatCard";
import {
  CampaignFilterEnum,
  CampaignsByIdQuery,
  CampaignsQuery,
  useCampaignStatusUpdateMutation,
} from "src/graphql/generated/schema";
import { useLazyTinybirdQuery } from "src/lib/analyticsApi";
import { OverviewTable } from "src/modules/global/components/OverviewTable";
import AuthContext from "src/stores/AuthContext";
import CampaignTitleWithIcon from "./CampaignTitleWithIcon";
import styles from "./CampaignOverviewContent.module.scss";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import BadgeDropdown from "src/components/BadgeDropdown/BadgeDropdown";
import PlatformIcons from "src/modules/global/components/PlatformIcons";
import noRecentCampaign from "../assets/noRecentCampaign.png";
import noAudience from "../assets/audienceEmpty.png";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import {
  StatCardKeys,
  statCardPropByKeys,
  itemsPerPage,
} from "../pages/CampaignOverviewPage";

interface ICampaignOverviewContent {
  stats: Record<StatCardKeys, number>;
  selectedCampaignsTab: CampaignFilterEnum;
  setSelectedCampaignsTab: (i: CampaignFilterEnum) => void;
  campaignLoading: boolean;
  topCampaignsLoading: boolean;
  campaignData: CampaignsQuery;
  topCampaignsData: CampaignsByIdQuery;
}

export function CampaignOverviewContent({
  stats,
  selectedCampaignsTab,
  setSelectedCampaignsTab,
  campaignLoading,
  topCampaignsLoading: topCampaignsLoading,
  campaignData,
  topCampaignsData: topCampaignsData,
}: ICampaignOverviewContent) {
  const { userToken } = useContext(AuthContext);
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>("");

  const [campaignStatusUpdateFunc, { loading: campaignStatusUpdateLoading }] =
    useCampaignStatusUpdateMutation();
  const [queryFn, { data, error }] = useLazyTinybirdQuery(
    "global_aggregate",
    userToken
  );

  useEffect(() => {
    if (
      topCampaignsData &&
      topCampaignsData.campaignsById &&
      topCampaignsData?.campaignsById.length > 0
    ) {
      const campaignIds = topCampaignsData.campaignsById.map((c) => c.id);
      queryFn({ campaignIds });
    }
  }, [topCampaignsData]);

  const campaignTabList = [
    {
      label: "Active",
      value: "ACTIVE" as CampaignFilterEnum,
    },
    {
      label: "Scheduled",
      value: "SCHEDULED" as CampaignFilterEnum,
    },
  ];
  const CampaignOverviewTable = OverviewTable<CampaignFilterEnum>;

  // TODO: find a better way to get latest updated
  const lastUpdatedAt: Date =
    campaignData &&
    campaignData.campaigns &&
    campaignData.campaigns.edges.length > 0
      ? new Date(campaignData.campaigns.edges[0].node.updatedAt)
      : new Date();

  const campaigns =
    campaignData && campaignData.campaigns
      ? campaignData.campaigns.edges.map((v) => v.node)
      : [];

  const topCampaigns =
    topCampaignsData && topCampaignsData.campaignsById
      ? topCampaignsData.campaignsById.map((v) => v)
      : [];

  const topCampaignInsights = data ? data : [];
  const topCampaignsWithStats = topCampaigns.map((c) => ({
    ...c,
    Stats: topCampaignInsights.find(
      (i: any) => i.engageCampaignId === c.id || i.growCampaignId === c.id
    ),
  }));

  return (
    <>
      {" "}
      <>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>Monitor Performance</div>
          <div className={styles.sectionSubtitle}>
            Last update : {formatDistanceToNow(lastUpdatedAt)}
          </div>
        </div>
        <div className={styles.actionCardsContainer}>
          {Object.keys(stats)
            .filter((v) => v !== "conversionCurrencyCode")
            .map((v) => (
              <StatCard
                title={statCardPropByKeys[v].title}
                subtitle={statCardPropByKeys[v].subtitle}
                value={stats[v] || 0}
                valueType={statCardPropByKeys[v].valueType}
              />
            ))}
        </div>
      </>
      <CampaignOverviewTable
        tabsList={campaignTabList}
        title={"Recently added campaigns"}
        rows={campaigns.map((v, i) => [
          <Link to={`/campaigns/${v.id}`}>
            <CampaignTitleWithIcon campaign={v} key={i} />
          </Link>,
          <BadgeDropdown
            loading={selectedCampaign === v.id}
            onChange={async (status) => {
              setSelectedCampaign(v.id);
              await campaignStatusUpdateFunc({
                variables: {
                  input: {
                    id: v.id,
                    status: status as any,
                  },
                },
              });
            }}
            type={v.status}
          />,
          <PlatformIcons
            platforms={v.platforms.map((i) => {
              return { platform: i };
            })}
          />,
        ])}
        headings={["Campaign", "Status", "Activity"]}
        selectedTab={selectedCampaignsTab}
        setSelectedTab={setSelectedCampaignsTab}
        loading={campaignLoading}
        itemsPerPage={itemsPerPage}
        emptyState={{
          title: "No campaigns found",
          icon: noRecentCampaign,
          action: {
            link: "/campaigns/create",
            title: "Create Campaign",
          },
        }}
      />
      {/* Top Campaigns Table */}
      {!error && (
        <OverviewTable
          title="Top performing campaigns by conversion"
          rows={topCampaignsWithStats.map((v, i) => [
            <Link to={`/campaigns/${v.id}`}>
              <CampaignTitleWithIcon campaign={v} key={i} />
            </Link>,
            <BadgeDropdown
              loading={v.id === selectedCampaign}
              onChange={async (status) => {
                setSelectedCampaign(v.id);
                await campaignStatusUpdateFunc({
                  variables: {
                    input: {
                      id: v.id,
                      status: status as any,
                    },
                  },
                });
              }}
              type={v.status}
            />,
            <PlatformIcons
              platforms={v.platforms.map((i) => {
                return { platform: i };
              })}
            />,
            formatNumberByType(v?.Stats?.impressions, "NUMBER") ?? "-",
            formatNumberByType(v?.Stats?.clicks, "NUMBER") ?? "-",
            v?.Stats?.clicks && v?.Stats?.impressions
              ? formatNumberByType(
                  (v?.Stats?.clicks / v?.Stats?.impressions) * 100,
                  "PERCENTAGE"
                )
              : "-",
          ])}
          headings={[
            "Campaign",
            "Status",
            "Activity",
            "Imprs.",
            "Clicks",
            "Convs.",
          ]} // selectedTab={selectedCampaignsTab}
          // setSelectedTab={setSelectedCampaignsTab}
          loading={topCampaignsLoading}
          itemsPerPage={itemsPerPage}
          border={false}
          emptyState={{
            title: "No top audiences found",
            icon: noAudience,
            action: {
              link: "/audiences/create/templates",
              title: "Create Audiences",
            },
          }}
        />
      )}
    </>
  );
}
