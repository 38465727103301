import instagramIcon from "../assets/instagram.svg";
import messengerIcon from "../assets/messengerSelectIcon.svg";
import whatsappIcon from "../assets/whatsapp.svg";
import videoThruplayIcon from "../assets/videoThruplay.svg";
import videoTwoSecondViewsIcon from "../assets/twoSecondViews.svg";
import callIcon from "../assets/call.svg";
import { SelectOption } from "./SelectOption";

export function EngagementOptions() {
  return (
    <>
      <SelectOption
        value="MESSAGE_INSTAGRAM"
        icon={
          <img src={instagramIcon} alt="meta dynamic" width={32} height={32} />
        }
        label="More messages and leads on Instagram"
      />
      <SelectOption
        value="MESSAGE_WHATSAPP"
        icon={
          <img src={whatsappIcon} alt="meta standard" width={32} height={32} />
        }
        label="More messages and leads on WhatsApp"
      />
      <SelectOption
        value="MESSAGE_MESSENGER"
        icon={
          <img src={messengerIcon} alt="meta standard" width={32} height={32} />
        }
        label="More messages and leads on Messenger"
      />
      <SelectOption
        value="CALL"
        icon={<img src={callIcon} alt="meta dynamic" width={32} height={32} />}
        label="Get more calls"
      />
      <SelectOption
        value="VIDEO_THRUPLAY"
        icon={
          <img
            src={videoThruplayIcon}
            alt="meta standard"
            width={32}
            height={32}
          />
        }
        label="Maximise video views on fully watched videos"
      />
      <SelectOption
        value="VIDEO_2_SEC_VIEW"
        icon={
          <img
            src={videoTwoSecondViewsIcon}
            alt="meta standard"
            width={32}
            height={32}
          />
        }
        label="Maximise views on partially watched videos (2 sec or more)"
      />
    </>
  );
}
