import { FormikErrors, FormikTouched, getIn } from "formik";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";

export function FormikError<T = any>({
  formikError,
  touched,
  submitCount,
  fieldName,
}: {
  formikError: FormikErrors<T>;
  touched?: FormikTouched<T>;
  submitCount?: number;
  fieldName: string;
}) {
  const fieldError = getIn(formikError, `${fieldName}`);
  // if formikTouched is passed then check for the field otherwise return true
  const isFieldTouched = touched ? getIn(touched, `${fieldName}`) : true;

  return (
    <>
      {typeof fieldError === "string" && (isFieldTouched || submitCount > 0) ? (
        <FormFieldStatus type="error" message={fieldError} />
      ) : null}
    </>
  );
}
