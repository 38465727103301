import styles from "./AudienceList.module.scss";
import { ReactComponent as MetaIcon } from "../assets/meta.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as WebsiteIcon } from "../assets/website.svg";
import { SVGProps } from "react";
import { ReactComponent as AdCount } from "../assets/adCount.svg";
import { ReactComponent as AutoRenewIcon } from "@material-symbols/svg-400/rounded/autorenew.svg";
import { ReactComponent as TrackpadInput } from "@material-symbols/svg-400/rounded/trackpad_input.svg";
import { generateRangeString } from "../../../lib/generateRangeString";
import {
  AUDIENCE_CATEGORY,
  AUDIENCE_PLATFORMS,
  IAudienceAudienceSetIndexNode,
} from "../misc/audienceListingTypes";
import { getAudienceAudienceSetSourcePlatform } from "../misc/getAudienceAudienceSetSourcePlatforms";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { formatNumberByType } from "../../global/functions/formatNumberByType";
import { useGrowAggregatedStats } from "../../global/functions/useGrowAggregatedStats";
import { AudienceCategoryBadge } from "../../global/components/AudienceCategoryBadge";
import classNames from "classnames";
import { Link } from "react-router-dom";

type DateFilters = {
  dateFrom: Date | null;
  dateTo: Date | null;
};

export function AudienceList({
  items,
  dateFilters,
}: {
  items: IAudienceAudienceSetIndexNode[];
  dateFilters: DateFilters;
}) {
  return (
    <div className={styles.container}>
      {items.map((v) => (
        <AudienceItem item={v} dateFilters={dateFilters} />
      ))}
    </div>
  );
}

function AudienceItem({
  item,
  dateFilters: { dateTo, dateFrom },
}: {
  item: IAudienceAudienceSetIndexNode;
  dateFilters: DateFilters;
}) {
  const audiencePlatforms = getAudienceAudienceSetSourcePlatform(item);
  const metaElement = item.Audience ? item.Audience : item.MetaAudienceSet;
  const categories =
    metaElement.__typename === "Audience" ? [] : metaElement.AudienceCategories;
  const insights = metaElement.MetaInsights;
  const count = metaElement.activitiesCount;
  return (
    <Link
      to={
        metaElement.__typename === "Audience"
          ? `/audiences/${metaElement.id}`
          : `/audience-sets/${metaElement.id}`
      }
      className={styles.itemWrapper}
    >
      {/* TITLE SECTION */}
      <div className={styles.titleSection}>
        {/* Platform  */}
        <div className={styles.iconWithTitle}>
          <div className={styles.iconStacking}>
            {audiencePlatforms.map((v) => (
              <PlatformIcons platform={v} className={styles.platformIcon} />
            ))}
          </div>

          <div className={styles.iconTitle}>{item.name}</div>
        </div>
        {/* Activity Count */}
        <Tooltip
          placement={"BOTTOM_CENTER"}
          supportingText="Shows how many activities are using this audience"
        >
          <div className={styles.activityCount}>
            <AdCount className={styles.icon} />
            <span className={styles.countValue}>
              {count > 9 ? count : `0${count}`}
            </span>
          </div>
        </Tooltip>
      </div>

      {/* TAGS */}
      <div className={classNames(styles.center, styles.flexList)}>
        {categories.map((v) => (
          <Tooltip supportingText={v.description}>
            <AudienceCategoryBadge
              label={v.name}
              value={String(v.name).toUpperCase() as AUDIENCE_CATEGORY}
            />
          </Tooltip>
        ))}
      </div>

      <div className={styles.itemText}>
        Est. Size:{" "}
        {generateRangeString(
          insights?.lowerBoundCount,
          insights?.upperBoundCount,
        )}
      </div>

      <AudienceAudienceSetStats
        id={metaElement.id}
        typename={metaElement.__typename}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
    </Link>
  );
}

function AudienceAudienceSetStats({
  id,
  typename,
  dateFrom,
  dateTo,
}: {
  id: string;
  typename: "Audience" | "MetaAudienceSet";
  dateFrom: Date | null;
  dateTo: Date | null;
}) {
  const { data } = useGrowAggregatedStats({
    others: {
      ...(typename === "Audience" && {
        audienceId: id,
      }),
      ...(typename === "MetaAudienceSet" && {
        audienceSetId: id,
      }),
      insightType: "ADSET_INSIGHT",
    },
    dateTo,
    dateFrom,
  });

  const stats = [
    {
      value: formatNumberByType(data?.roas, "MULTIPLIER", {
        showDecimal: true,
      }),
      icon: <AutoRenewIcon width={16} height={16} />,
      tooltip: "ROAS",
    },
    {
      value: formatNumberByType(data?.clicks, "NUMBER", {
        showDecimal: true,
      }),
      icon: <TrackpadInput width={16} height={16} />,
      tooltip: "Clicks",
    },
  ];

  return (
    <div className={styles.statsList}>
      {stats.map((v) => (
        <Tooltip
          placement="BOTTOM_CENTER"
          supportingText={v.tooltip}
          children={
            <div className={styles.stat}>
              <span>{v.value}</span>
              {v.icon}
            </div>
          }
        />
      ))}
    </div>
  );
}

interface IPlatformIcons extends SVGProps<any> {
  platform: AUDIENCE_PLATFORMS;
}

function PlatformIcons({ platform, ...props }: IPlatformIcons) {
  switch (platform) {
    case "META":
      return <MetaIcon {...props} />;
    case "INSTAGRAM":
      return <InstagramIcon {...props} />;
    default:
      return <WebsiteIcon {...props} />;
  }
}
