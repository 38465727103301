import styles from "./PasswordResetSuccessful.module.scss";
import MacroLogo from "../assets/macro-logo-primary-full.svg";
import passwordChanged from "../assets/passwordChanged.png";
import { Button } from "src/components/Button/Button";

export default function PasswordResetSuccessful() {
  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <nav>
          <img src={MacroLogo} alt="macro_logo" className={styles.logo} />
        </nav>
      </header>
      <div className={styles.content}>
        <div className={styles.container}>
          <img
            src={passwordChanged}
            alt="password_changed"
            className={styles.image}
          />
          <p className={styles.heading}>Password has been changed</p>
          <p className={styles.description}>
            Your password has been changed. You can login to Macro with new
            password
          </p>
          <Button to="/auth/login" width="full">
            Back to Macro
          </Button>
        </div>
      </div>
    </div>
  );
}
