import { useEffect, useRef } from "react";
import styles from "./RunningTrainCarousel.module.scss";
import classNames from "classnames";

type SlideWithHeightWidth = {
  height: number;
  width: number;
  src: string;
};

type Slide = string | SlideWithHeightWidth;

interface Props {
  slides: Slide[];
  slideHeight?: number;
  slideWidth?: number;
  /** gap is in pixel  */
  gap: number;
  /** slides.length * duplicateCount = total slides */
  duplicateCount?: number;
}

// height variable is calculated with items * itemsHeight + totalGap
function calculateContainerHeight({
  slides,
  slideHeight,
  gap,
  duplicateCount = 1,
}) {
  if (slides.length === 0) return 0;
  if (typeof slides[0] === "string") {
    return (
      slides.length * slideHeight * duplicateCount +
      (slides.length * duplicateCount - 1) * gap
    );
  } else {
    const totalHeight: number = slides.reduce((acc, v: any) => {
      return (acc += v.height);
    }, 0);
    return (
      totalHeight * duplicateCount + (slides.length * duplicateCount - 1) * gap
    );
  }
}

export default function RunningTrainCarousel({
  slides: originalSlides,
  slideHeight,
  slideWidth,
  gap,
  duplicateCount = 1,
}: Props) {
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!elRef) return;
    if (!elRef.current) return;
    elRef.current.scroll({
      top: elRef.current.scrollHeight,
    });
  }, [elRef]);

  // height variable is calculated with items * itemsHeight + totalGap
  const containerHeight = calculateContainerHeight({
    slides: originalSlides,
    slideHeight,
    gap,
    duplicateCount,
  });

  const slides: Slide[] = new Array<any>(duplicateCount)
    .fill("")
    .flatMap((v) => originalSlides);

  return (
    <div
      style={{
        // @ts-ignore
        "--height": `${containerHeight}px`,
      }}
      className={styles.carouselContainer}
    >
      <div
        ref={elRef}
        className={classNames(styles.carousels, styles.animationScrollDown)}
      >
        {slides.map((slide, index) => (
          <img
            key={index}
            src={typeof slide === "string" ? slide : slide.src}
            alt={typeof slide === "string" ? slide : slide.src}
            className={styles.slide}
            style={{
              height:
                typeof slide === "string"
                  ? `${slideHeight}px`
                  : `${slide.height}px`,
              width:
                typeof slide === "string"
                  ? `${slideWidth}px`
                  : `${slide.width}px`,
            }}
          />
        ))}
      </div>
      <div className={classNames(styles.carousels, styles.animationScrollUp)}>
        {slides.map((slide, index) => (
          <img
            key={index}
            src={typeof slide === "string" ? slide : slide.src}
            alt={typeof slide === "string" ? slide : slide.src}
            className={styles.slide}
            style={{
              height:
                typeof slide === "string"
                  ? `${slideHeight}px`
                  : `${slide.height}px`,
              width:
                typeof slide === "string"
                  ? `${slideWidth}px`
                  : `${slide.width}px`,
            }}
          />
        ))}
      </div>
    </div>
  );
}
