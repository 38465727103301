import {
  ActivityInput,
  CampaignInput,
  FormSteps,
} from "../misc/createCampaignTypes";
import { getIn, useFormikContext } from "formik";
import { AddRecipientModal } from "./AddRecipientModal";
import { AudienceBudgetCard } from "./AudienceBudgetCard";
import { ContentDetails } from "./ContentDetails";
import { ReceiveMailSegment } from "./ReceiveMailSegment";
import styles from "./ReviewActivityFields.module.scss";
import { useState } from "react";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { useEmailIntegrationQuery } from "src/graphql/generated/schema";
import { TextMessageObjectDetails } from "./TextMessageObjectDetails";
import { EmailObjectDetails } from "./EmailObjectDetails";
import { WhatsappMessageObjectDetails } from "./WhatsappMessageObjectDetails";
import { ReviewAdsPreviewModal } from "./ReviewAdsPreviewModal";
import { ReactComponent as ContentCopy } from "@material-design-icons/svg/outlined/content_copy.svg";
import { ReactComponent as EditDocument } from "@material-symbols/svg-400/rounded/edit_document.svg";
import Tooltip from "src/components/Tooltip/Tooltip";
import { ReactComponent as MoreInfo } from "@material-design-icons/svg/outlined/info.svg";
import { Button } from "src/components/Button/Button";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { ReviewActivityAdItem } from "./ReviewActivityAdItem";

interface Props {
  activityIndex: number;
  setActiveFormStep: (i: FormSteps) => void;
}

export function ReviewActivityFields({
  activityIndex,
  setActiveFormStep,
}: Props) {
  const [showAddRecipient, setShowAddRecipient] = useState(false);
  const [showAdPreviewModal, setShowAdPreviewModal] = useState({
    id: null,
    open: false,
  });
  const { data: emailIntegrationData } = useEmailIntegrationQuery();
  const sendersOptions =
    emailIntegrationData && emailIntegrationData.emailIntegration
      ? emailIntegrationData.emailIntegration.SenderEmails.map((value) => ({
          label: `${value.email}@${emailIntegrationData.emailIntegration.domain}`,
          value: value.id,
          name: value.name,
        }))
      : [];

  const { values } = useFormikContext<CampaignInput>();
  const { setData, setSelectedActivityIndex } = useWizardContext();
  const baseKey = `Activities[${activityIndex}]`;
  const selectedActivity: ActivityInput = getIn(values, baseKey);

  function handleDuplicateActivity() {
    const activities = structuredClone(values.Activities);
    const selectedActivity = values.Activities[activityIndex];
    activities.push({
      ...selectedActivity,
      activityName: `${selectedActivity.activityName} - Duplicate`,
    });
    setData({ ...values, Activities: activities });
  }

  function handleEditActivity() {
    setSelectedActivityIndex(activityIndex, "choosePlatformAndActivityName");
  }

  function handleEditAd(adIndex: number) {
    const activity = values.Activities.map((a, index) => {
      if (index === activityIndex) {
        const updatedActivity = {
          ...a,
          selectedAdIndex: adIndex,
        };

        return updatedActivity;
      }

      return a;
    });

    setSelectedActivityIndex(activityIndex, "createFacebookAd", {
      Activities: activity,
    });
  }

  return (
    <div className={styles.activityItem}>
      <AddRecipientModal
        showAddRecipient={showAddRecipient}
        setShowAddRecipient={setShowAddRecipient}
      />
      {showAdPreviewModal?.id !== null && (
        <ReviewAdsPreviewModal
          isOpen={showAdPreviewModal?.open}
          onClose={() => setShowAdPreviewModal({ open: false, id: null })}
          activity={selectedActivity}
          defaultSelectedAd={showAdPreviewModal.id}
        />
      )}
      <div>
        {/* <h3 className={styles.activitySectionSubtitleWrapper}>
          <span className={styles.activitySectionSubtitle}>
            Activity Details
          </span>
        </h3> */}

        <div className={styles.activityFields}>
          <InputFormik name={`${baseKey}.activityName`} label="Activity Name" />
          <InputFormik name={`${baseKey}.platform`} label="Platform" disabled />
          {selectedActivity.facebookActivityType && (
            <InputFormik
              name={`${baseKey}.facebookActivityType`}
              label="Activity Type"
              disabled
            />
          )}
        </div>

        {selectedActivity.segments?.length && (
          <ReceiveMailSegment
            setShowAddRecipient={setShowAddRecipient}
            segments={selectedActivity.segments}
          />
        )}

        {selectedActivity.Adsets && selectedActivity.Adsets.length > 0 && (
          <>
            <h3 className={styles.activitySectionSubtitleWrapper}>
              <span className={styles.activitySectionSubtitle}>
                Audiences and Budget details
              </span>
            </h3>

            <div className={styles.audienceFields}>
              {selectedActivity.Adsets.map((_adset, audienceIndex) => (
                <AudienceBudgetCard
                  baseKey={`${baseKey}.Adsets[${audienceIndex}]`}
                  index={audienceIndex}
                  hideEdit={true}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {selectedActivity.WhatsappMessageObject && (
        <WhatsappMessageObjectDetails
          selectedActivity={selectedActivity}
          selectedActivityKey={baseKey}
        />
      )}

      {selectedActivity.TextMessageObject && (
        <TextMessageObjectDetails
          // setFieldValue={setFieldValue}
          selectedActivity={selectedActivity}
          selectedActivityKey={baseKey}
        />
      )}

      {selectedActivity.EmailObject && (
        <>
          <div>
            <EmailObjectDetails
              sendersOptions={sendersOptions}
              selectedActivity={selectedActivity}
              selectedActivityKey={baseKey}
            />
          </div>
          <div>
            <ContentDetails
              setActiveFormStep={setActiveFormStep}
              previewHtml={selectedActivity?.EmailObject?.emailTemplate?.html}
              typeOfEmail={selectedActivity?.EmailObject}
            />
          </div>
        </>
      )}

      {selectedActivity.Ads && selectedActivity.Ads.length > 0 && (
        <div className={styles.adSection}>
          <h3 className={styles.activitySectionSubtitleWrapper}>
            <span className={styles.activitySectionSubtitle}>
              Ads Details ({selectedActivity.Ads.length})
            </span>
          </h3>

          <div className={styles.adGrid}>
            {selectedActivity.Ads.map((ad, idx) => {
              return (
                <ReviewActivityAdItem
                  ad={ad}
                  handleEditAd={handleEditAd}
                  idx={idx}
                  setShowAdPreviewModal={(open) => {
                    console.log({ idx });
                    setShowAdPreviewModal({ id: idx, open: open });
                  }}
                />
              );
            })}
          </div>
        </div>
      )}

      <div className={styles.otherDetailsSection}>
        <h3 className={styles.activitySectionSubtitleWrapper}>
          <span className={styles.activitySectionSubtitle}>Other Details</span>
        </h3>

        <div className={styles.otherDetailGridOne}>
          <div>
            <span className={styles.detailLabel}>
              Facebook Ad Account
              <Tooltip
                children={<MoreInfo width={14} height={14} />}
                supportingText={"Select Ad account to run your Ads"}
              />
            </span>
            <span className={styles.detailValue}>
              {selectedActivity.adAccountName}
            </span>
          </div>
          <div>
            <span className={styles.detailLabel}>
              Facebook Page
              <Tooltip
                children={<MoreInfo width={14} height={14} />}
                supportingText="Your Facebook Page represents your business in ads"
              />
            </span>
            <span className={styles.detailValue}>
              {selectedActivity.metaPageName}
            </span>
          </div>
          <div>
            <span className={styles.detailLabel}>
              Facebook Pixel
              <Tooltip
                children={<MoreInfo width={14} height={14} />}
                supportingText="Add your Pixel ID for better tracking and analytics"
              />
            </span>
            <span className={styles.detailValue}>
              {selectedActivity.metaPixelName}
            </span>
          </div>
          <div>
            <span className={styles.detailLabel}>
              Instagram Account{" "}
              <Tooltip
                children={<MoreInfo width={14} height={14} />}
                supportingText="The Instagram account is connected to Facebook Page. You can change this setting from your Facebook Page Admin setting."
              />
            </span>
            <span className={styles.detailValue}>
              {selectedActivity.instagramPageName}
            </span>
          </div>
          <div>
            <span className={styles.detailLabel}>
              Conversion Domain{" "}
              <Tooltip
                children={<MoreInfo width={14} height={14} />}
                supportingText="Add your domain to track and measure conversion from your ads"
              />
            </span>
            <span className={styles.detailValue}>
              {selectedActivity.conversionDomain}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.activityActions}>
        <Button
          onClick={handleEditActivity}
          size="small"
          style="outline"
          color="subdued"
        >
          <span className={styles.buttonWithIcon}>
            Edit this Activity <EditDocument width={18} height={18} />
          </span>
        </Button>
        <Button
          onClick={handleDuplicateActivity}
          size="small"
          style="outline"
          color="subdued"
        >
          <span className={styles.buttonWithIcon}>
            Duplicate this Activity <ContentCopy width={18} height={18} />
          </span>
        </Button>
      </div>
    </div>
  );
}
