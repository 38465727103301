import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as Account } from "@material-design-icons/svg/outlined/account_circle.svg";
import { ReactComponent as Group } from "@material-design-icons/svg/outlined/group.svg";
import { ReactComponent as Language } from "@material-design-icons/svg/outlined/language.svg";
import { ReactComponent as Logout } from "@material-design-icons/svg/outlined/logout.svg";
import { ReactComponent as Person } from "@material-design-icons/svg/outlined/person.svg";
import { ReactComponent as School } from "@material-design-icons/svg/outlined/school.svg";
import { ReactComponent as Help } from "@material-symbols/svg-400/rounded/help.svg";
import { Fragment, useContext } from "react";
import Avatar from "react-avatar";
import { User } from "src/graphql/generated/schema";
import AuthContext from "src/stores/AuthContext";
import { Button } from "../Button/Button";
import styles from "./UserMenu.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface IUserMenu {
  user?: User;
}

const adminOptions = [
  {
    icon: <Group />,
    label: "Manage Team",
    link: "/manage-team",
  },
  {
    icon: <Account />,
    label: "Account Settings",
    link: "/settings/account",
  },
  {
    icon: <Language />,
    label: "Browse Plans",
    link: "",
  },
  {
    icon: <School />,
    label: "Knowledge Base",
    link: "",
  },
  {
    icon: <Help />,
    label: "Help & Support",
    link: "",
  },
];

const userOptions = [
  {
    icon: <Group />,
    label: "Manage Clients",
    link: "",
  },
  {
    icon: <School />,
    label: "Knowledge Base",
    link: "",
  },
  {
    icon: <Help fill={"var(--color-disabled)"} />,
    label: "Help & Support",
    link: "",
  },
];

export default function UserMenu({ user }: IUserMenu) {
  const { logout } = useContext(AuthContext);

  const isAdmin =
    user && user.role?.toLowerCase() === "admin" ? "Admin" : "Member";

  const menuOptions = isAdmin === "Admin" ? adminOptions : userOptions;

  return (
    <Menu as="div" className={styles.menu}>
      <Menu.Button className={styles.menuBtn}>
        <div className={styles.userInfo}>
          <Avatar
            name={`${user.firstName} ${user.lastName}`}
            size="37"
            alt="User Avatar"
            round={true}
            textSizeRatio={2.8}
          />
          <div className={styles.userDetails}>
            <span className={styles.accountType}>
              {user.firstName}
              <span className={styles.dot}></span>
              <span className={styles.isAdmin}>{isAdmin}</span>
            </span>
            <span className={styles.userEmail}>{user.email}</span>
          </div>
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter={styles.menuEnter}
        enterFrom={styles.menuEnterFrom}
        enterTo={styles.menuEnterTo}
        leave={styles.menuLeave}
        leaveFrom={styles.menuLeaveFrom}
        leaveTo={styles.menuLeaveTo}
      >
        <Menu.Items className={styles.menuItems}>
          <Menu.Item as="div" className={styles.menuItem}>
            <div className={styles.userInfo}>
              <Avatar
                name={`${user.firstName} ${user.lastName}`}
                size="37"
                alt="User Avatar"
                round={true}
                textSizeRatio={2.8}
              />
              <div className={styles.userDetails}>
                <span className={styles.accountType}>
                  {user.firstName}
                  <span className={styles.dot}></span>
                  <span className={styles.isAdmin}>{isAdmin}</span>
                </span>
                <span className={styles.userEmail}>{user.email}</span>
              </div>
            </div>
          </Menu.Item>
          {menuOptions.map((item, index) => (
            <Menu.Item as="div" className={styles.menuItem} key={index}>
              <Link className={styles.menuLink} to={item.link}>
                <div className={styles.menuIconText}>
                  {item.icon}
                  <span className={styles.menuText}>{item.label}</span>
                </div>
              </Link>
            </Menu.Item>
          ))}
          <Menu.Item as="div" className={styles.menuItem}>
            <a
              className={classNames(styles.menuLink, styles.logout)}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}
            >
              <div className={styles.menuIconText}>
                <Logout width={18} height={18} />
                <span>Logout</span>
              </div>
            </a>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
