import { useNavigate } from "react-router-dom";
import { Button, IButton } from "../Button/Button";
import styles from "./OverviewBanner.module.scss";
import classNames from "classnames";
import Balancer from "react-wrap-balancer";
interface IOverviewBanner {
  title: string;
  description: string;
  action?: IButton;
  bgGradient: string;
  leftImage?: any;
  rightImage?: any;
  link?: string;
}

export default function OverviewBanner({
  title,
  description,
  action,
  bgGradient,
  leftImage,
  rightImage,
  link,
}: IOverviewBanner) {
  const navigate = useNavigate();
  return (
    <div className={styles.slide} style={{ background: bgGradient }}>
      <img
        className={classNames(styles.leftImage, styles.image)}
        src={leftImage}
      />

      {/* Integration progress bar */}
      <div className={styles.slideContent}>
        <div className={styles.slideContentTitle}>{title}</div>
        <div className={styles.slideContentDescription}>{description}</div>
        {action && (
          <div className={styles.slideAction}>
            <Button
              onClick={() => {
                navigate(link);
              }}
              {...action}
            />
          </div>
        )}
      </div>
      <img
        className={classNames(styles.rightImage, styles.image)}
        src={rightImage}
      />
    </div>
  );
}
