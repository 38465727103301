import { formatNumber } from "src/lib/formatNumber";

export function generateRangeString(lowerBound?: number, upperBound?: number) {
  if (!lowerBound || !upperBound) return `-`;
  if (lowerBound === -1) return `-`;
  if (upperBound === -1) return `-`;

  if (lowerBound === upperBound) {
    return `around ${formatNumber(lowerBound)}`;
  }
  return `${formatNumber(lowerBound)} - ${formatNumber(upperBound)}`;
}
