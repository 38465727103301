import { Input } from "src/components/Input/Input";
import { WhatsappDetailSection } from "../WhatsappDetailSection/WhatsappDetailSection";
import styles from "./WhatsappTemplateFooter.module.scss";
import { WhatsappFormInput } from "src/pages/CreateTemplate/CreateMessageTemplateTypes";
import { InputFormik } from "src/components/InputFormik/InputFormik";

interface IWhatsappTemplateFooter {
  values: WhatsappFormInput;
}

export function WhatsappTemplateFooter({ values }: IWhatsappTemplateFooter) {
  return (
    <WhatsappDetailSection
      title="Footer"
      subtitle="Add a 60 character title to your message. Only one variable is supported in the header."
    >
      <div>
        <InputFormik name="footer" />
        <p className={styles.footerText}>{values?.footer?.length || 0}/60</p>
      </div>
    </WhatsappDetailSection>
  );
}
