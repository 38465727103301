import React, { ReactNode, useContext } from "react";
import { toggleArray } from "src/lib/toggleArray";
import styles from "./CheckboxGroup.module.scss";

type Value = string | number;

export interface ICheckboxGroup {
  children: ReactNode;
  name?: string;
  value: Value[];
  onChange: (values: Value[]) => void;
}

interface ContextTypes {
  name?: string;
  selectedValues: Value[];
  onChange: (value: Value) => void;
}

const CheckboxContext = React.createContext<ContextTypes>({
  name: "",
  selectedValues: [],
  onChange: () => {},
});

export function CheckboxGroup({
  value: selectedValues,
  children,
  onChange,
  name,
}: ICheckboxGroup) {
  function handleChange(value: Value) {
    let newValue = toggleArray(selectedValues, value);

    onChange(newValue);
  }

  return (
    <CheckboxContext.Provider
      value={{ name, selectedValues, onChange: handleChange }}
    >
      {children}
    </CheckboxContext.Provider>
  );
}

interface RenderPropArgs {
  checked: boolean;
  onChange: (value: string) => void;
}

interface ICheckboxOption {
  value: Value;
  disabled?: boolean;
  children: ReactNode | ((arg: RenderPropArgs) => ReactNode);
  className?: string;
}

export function CheckboxOption({
  value,
  disabled = false,
  className,
  children,
}: ICheckboxOption) {
  const { name, selectedValues, onChange } = useContext(CheckboxContext);
  const isChecked = selectedValues.includes(value);

  return (
    <label className={className}>
      <input
        disabled={disabled}
        className={styles.hiddenCheckbox}
        name={name}
        type="checkbox"
        value={value}
        checked={isChecked}
        onChange={() => onChange(value)}
      />
      {typeof children === "function"
        ? children({ checked: isChecked, onChange })
        : children}
    </label>
  );
}
