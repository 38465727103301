import { useMetaProductCataloguesQuery } from "src/graphql/generated/schema";
import { ChooseFacebookCatalogueFields } from "./ChooseFacebookCatalogueFields";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { CampaignInput } from "../misc/createCampaignTypes";
import { useFormikContext } from "formik";

interface IChooseFacebookCatalogueLoader {
  handleBack: (i: CampaignInput) => unknown;
}

export function ChooseFacebookCatalogueLoader({
  handleBack,
}: IChooseFacebookCatalogueLoader) {
  const { values } = useFormikContext<CampaignInput>();
  const { data, loading, error } = useMetaProductCataloguesQuery({
    variables: {
      adAccountId: values.Activities[values.selectedActivityIndex].adAccountId,
    },
  });

  if (loading) {
    return <Spinner height="auto" />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <ChooseFacebookCatalogueFields
      handleBack={handleBack}
      catalogues={data.metaCatalogues}
    />
  );
}
