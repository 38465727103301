import { AssetsQuery } from "src/graphql/generated/schema";
import styles from "./MediaPicker.module.scss";
import { ReactComponent as DescriptionIcon } from "@material-symbols/svg-400/outlined/description.svg";
import { ReactComponent as AspectRatioIcon } from "@material-symbols/svg-400/outlined/aspect_ratio.svg";
import { ReactComponent as PackageIcon } from "@material-symbols/svg-400/outlined/package_2.svg";
import Badge from "src/components/Badge/Badge";
import { getAspectRatio } from "../../misc/getAspectRatio";
import { formatFileSize } from "src/lib/formatFileSize";
import classNames from "classnames";
import { Checkbox } from "src/components/Checkbox/Checkbox";

interface IMediaPicker {
  multiple: boolean;
  assets: AssetsQuery["assets"]["edges"];
  selectedAssets: AssetsQuery["assets"]["edges"];
  setSelectedAssets: (i: AssetsQuery["assets"]["edges"]) => void;
}

export function MediaPicker({
  multiple,
  assets,
  selectedAssets,
  setSelectedAssets,
}: IMediaPicker) {
  function handleSelect(asset: IMediaPicker["assets"][0]) {
    // if single select just select it
    if (!multiple) {
      return setSelectedAssets([asset]);
    }

    const isSelected = selectedAssets.find((a) => a.node.id === asset.node.id);
    // if already selected remove it it
    if (isSelected) {
      return setSelectedAssets(
        selectedAssets.filter((a) => a.node.id !== asset.node.id),
      );
    }

    // if is multiple select and not already selected, select it
    setSelectedAssets([...selectedAssets, asset]);
  }

  return (
    <div className={styles.wrapper}>
      {assets.map((asset) => {
        const isSelected = selectedAssets.find(
          (a) => a.node.id === asset.node.id,
        );

        return (
          <button
            onClick={() => handleSelect(asset)}
            type="button"
            className={classNames(styles.assetItem, {
              [styles.selectedAssetItem]: isSelected,
              [styles.isMultiSelect]: multiple,
            })}
          >
            {multiple && (
              <div>
                <Checkbox
                  size="small"
                  checked={!!isSelected}
                  value={asset.node.id}
                  onChange={() => handleSelect(asset)}
                />
              </div>
            )}

            {asset.node.contentType === "IMAGE" ? (
              <img
                className={styles.assetPreview}
                src={asset.node.uri}
                width={32}
                height={32}
                alt=""
              />
            ) : (
              <video className={styles.assetPreview} autoPlay muted loop>
                <source src={asset.node.uri} />
              </video>
            )}

            <div className={styles.titleWrapper}>
              <span
                className={classNames(styles.title, {
                  [styles.titleSelected]: isSelected,
                })}
              >
                <span className={styles.titleContentEllipsis}>
                  {asset.node.name}
                </span>
              </span>
              <span className={styles.titleAdditionalContent}>
                <span className={classNames(styles.statItem)}>
                  <span
                    className={classNames(styles.statItemIcon, styles.adCount)}
                  >
                    <DescriptionIcon
                      fill="currentColor"
                      width={15}
                      height={15}
                    />
                  </span>
                  {asset.node.adsCount}
                </span>
                <span className={classNames(styles.statItem)}>
                  <span
                    className={classNames(
                      styles.statItemIcon,
                      styles.templateCount,
                    )}
                  >
                    <DescriptionIcon
                      fill="currentColor"
                      width={15}
                      height={15}
                    />
                  </span>
                  -
                </span>
              </span>
            </div>

            <div>
              {asset.node.adsCount > 0 ? (
                <Badge label="In Use" />
              ) : (
                <Badge label="Not in Use" />
              )}
            </div>
            <div>
              <span className={styles.aspectRatio}>
                {getAspectRatio(asset.node.width, asset.node.height)}
              </span>
            </div>
            <div>
              <span className={styles.dimensions}>
                {asset.node.width}x{asset.node.height}
                <AspectRatioIcon
                  fill="var(--color-disabled)"
                  width={20}
                  height={20}
                />
              </span>
            </div>

            <div>
              <span className={styles.fileSize}>
                {formatFileSize(asset.node.fileSize)}
                <PackageIcon
                  fill="var(--color-disabled)"
                  width={20}
                  height={20}
                />
              </span>
            </div>
          </button>
        );
      })}
    </div>
  );
}
