import AuthContext from "src/stores/AuthContext";
import {
  ClientResponse,
  PipeParams,
  QueryPipe,
  QuerySQL,
  QueryError,
} from "./analyticsApiTypes";
import { useCallback, useContext, useEffect, useState } from "react";

export async function client<T>(
  path: string,
  token: string,
  clientId: string,
  params?: RequestInit
): Promise<ClientResponse<T>> {
  const apiUrl = `${process.env.REACT_APP_FUNCTIONS_URI}/tinybird`;

  const response = await fetch(`${apiUrl}${path}`, {
    headers: {
      authorization: `Bearer ${token}`,
      "x-active-client": clientId,
    },

    ...params,
  });

  const data = (await response.json()) as ClientResponse<T>;

  if (!response.ok) {
    throw new QueryError(
      data?.error ?? "Something went wrong",

      response.status
    );
  }

  return data;
}

export function queryPipe<T>(
  name: string,
  token: string,
  clientId: string,
  params: Partial<PipeParams<T>> = {}
): Promise<QueryPipe<T>> {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (!value) return;

    searchParams.set(key, value as string);
  });

  return client(`/pipes/${name}?${searchParams}`, token, clientId);
}

export function querySQL<T>(
  sql: string,
  token: string,
  clientId: string
): Promise<QuerySQL<T>> {
  return client(`/sql?q=${sql}`, token, clientId);
}

export function useTinybirdQuery<T>(
  name: string,
  token: string,
  params: Partial<PipeParams<T>> = {}
) {
  const { activeClient } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const paramsDeps = JSON.stringify(Object.values(params));

  useEffect(() => {
    if (!activeClient) {
      return;
    }
    queryPipe(name, token, activeClient?.id, params)
      .then((data: any) => {
        setData(data.data);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paramsDeps, activeClient, name, token]);

  return { error, loading, data };
}

export function useLazyTinybirdQuery<T, U = unknown>(
  name: string,
  token: string
): [
  (i: Partial<PipeParams<T>>) => Promise<U>,
  {
    data: any;
    called: boolean;
    loading: boolean;
    error: { name: string; message: string };
  }
] {
  const { activeClient } = useContext(AuthContext);
  const [called, setCalled] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const queryFn = useCallback(
    async function queryFn(params: Partial<PipeParams<T>> = {}) {
      if (!activeClient) {
        return;
      }
      console.log({ params });
      const resp = await queryPipe(name, token, activeClient.id, params)
        .then((data: any) => {
          setData(data.data);
          return data;
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
          setCalled(true);
        });

      return resp;
    },
    [name, token, activeClient]
  );

  return [queryFn, { error, loading, data, called }];
}
