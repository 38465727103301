import styles from "./IntegrationSteps.module.scss";

interface IIntegrationSteps {
  heading: string;
  subHeading?: string;
  steps: React.ReactNode[];
  image?: string;
}

export function IntegrationSteps({
  heading,
  subHeading,
  steps,
  image,
}: IIntegrationSteps) {
  return (
    <div className={styles.stepContainer}>
      <h4 className={styles.stepTitle}>{heading}</h4>
      <div className={styles.stepsWrapper}>
        <ul className={styles.stepsContainer}>
          {subHeading && <span className={styles.stepIntro}>{subHeading}</span>}
          {steps.map((item, index) => (
            <li key={index} className={styles.step}>
              {item}
            </li>
          ))}
        </ul>
        {image && <img src={image} alt="image" width={205} height={200} />}
      </div>
    </div>
  );
}
