import { useContext } from "react";
import { StatFilterOptions } from "../../global/functions/StatFilterOptions";
import AuthContext from "src/stores/AuthContext";
import differenceInDays from "date-fns/differenceInDays";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { convertDatetimeToDate } from "../../global/functions/convertDatetimeToDate";
import { GrowPerTickResponse } from "../../global/misc/growPerTickResponse";

export function useOverallPerTickStats({
  dateFrom,
  dateTo,
}: StatFilterOptions): GrowPerTickResponse {
  const groupMonthly =
    dateFrom && dateTo ? differenceInDays(dateTo, dateFrom) >= 90 : true;

  const { userToken } = useContext(AuthContext);
  const payload = useTinybirdQuery("grow_per_tick", userToken, {
    insightType: "ACTIVITY_INSIGHT",
    dateFrom: convertDatetimeToDate(dateFrom),
    dateTo: convertDatetimeToDate(dateTo),
    groupMonthly,
  });

  return { ...payload, interval: groupMonthly ? "MONTH" : "DAY" };
}
