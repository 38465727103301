import { IHeader } from "./Header";
import classNames from "classnames";
import styles from "./DistractionFreeHeader.module.scss";
import Logo from "../Logo/Logo";
import Badge from "../Badge/Badge";

export default function DistractionFreeHeader({
  primaryAction,
  actions,
  title,
  titleMetaData,
}: IHeader) {
  return (
    <header className={classNames(styles.header)}>
      <nav className={styles.nav}>
        <div className={styles.logoSection}>
          <Logo
            goToHomeWhenClicked={false}
            type="icon"
            style="white"
            width={28}
            height={28}
          />
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>{title}</h1>
            {titleMetaData && (
              <div className={styles.titleMetaData}>
                <button
                  type="button"
                  onClick={titleMetaData.onClick}
                  className={styles.titleMetadata}
                >
                  <Badge
                    label={titleMetaData.content}
                    status={titleMetaData.status}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.actions}>
          {actions}
          {primaryAction && (
            <button
              type="button"
              className={styles.primaryAction}
              onClick={primaryAction.onClick}
            >
              {primaryAction.label}
            </button>
          )}
        </div>
      </nav>
    </header>
  );
}
