import { Spinner } from "../../../components/Spinner/Spinner";
import {
  useGetBillingQuery,
  useOrganizationAdSpendsPerTickQuery,
  useOrganizationGrowOverallUsageQuery,
  useOrganizationMetaUsagePerTickQuery,
  useOrganizationUsageQuery,
} from "../../../graphql/generated/schema";
import { formatNumber } from "../../../lib/formatNumber";
import { GraphCard } from "../../campaign/components/GraphCard";
import { SectionHeading } from "../../global/components/SectionHeading";
import { OverallUsageStats } from "../components/OverallUsageStats";
import styles from "./Usage.module.scss";
import { ReactComponent as Wallet } from "../assets/wallet.svg";
import { ReactComponent as Group } from "../assets/group.svg";
import { ReactComponent as Cycle } from "../assets/exchange.svg";
import { ReactComponent as KeyboardArrowRight } from "@material-symbols/svg-600/rounded/keyboard_arrow_right.svg";
import DateFilterDropdown from "../../global/components/DateFilterDropdown";
import { DateFilter } from "../../global/misc/dateFilterUtils";
import { getMonthFromNumber } from "../../campaign/misc/getMonthFromNumber";
import { getTinybirdDateFiltersFromDateFilter } from "../../campaign/misc/campaignSingleFilters";
import { useMemo, useState } from "react";
import { convertDatetimeToDate } from "../../global/functions/convertDatetimeToDate";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

const filterOptions = [
  {
    label: "Last 28 days",
    value: "LAST_28_DAYS" as const,
  },
  {
    label: "Last 3 Months",
    value: "LAST_3_MONTHS" as const,
  },
  {
    label: "Last 6 Months",
    value: "LAST_6_MONTHS" as const,
  },
  {
    label: "Last Year",
    value: "LAST_YEAR" as const,
  },
  {
    label: "All Time",
    value: "ALL_TIME" as const,
  },
];

export function Usage() {
  const {
    data: organizationUsageData,
    loading: organizationUsageLoading,
    error: _error,
  } = useOrganizationUsageQuery();
  const { data: billingData, loading: billingLoading } = useGetBillingQuery();
  // usage is always in dollars
  const selectedCurrencyCode = "$";

  const [metaUsageDateFilter, setMetaUsageDateFilter] =
    useState<DateFilter>("LAST_6_MONTHS");
  const { dateTo: metaUsageDateTo, dateFrom: metaUsageDateFrom } =
    useMemo(() => {
      return getTinybirdDateFiltersFromDateFilter(metaUsageDateFilter);
    }, [metaUsageDateFilter]);
  const { data: metaUsagePerTickDate, loading: metaUsageLoading } =
    useOrganizationMetaUsagePerTickQuery({
      variables: {
        filter: {
          createdAt: {
            ...(metaUsageDateFrom && {
              gte: metaUsageDateFrom,
            }),
            ...(metaUsageDateTo && {
              lte: metaUsageDateTo,
            }),
          },
        },
      },
    });

  const [adSpendsDateFilter, setAdSpendsDateFilter] =
    useState<DateFilter>("LAST_6_MONTHS");
  const { dateTo: adSpendsDateTo, dateFrom: adSpendsDateFrom } = useMemo(() => {
    return getTinybirdDateFiltersFromDateFilter(adSpendsDateFilter);
  }, [adSpendsDateFilter]);
  const { data: adSpendsPerTick, loading: adSpendsLoading } =
    useOrganizationAdSpendsPerTickQuery({
      variables: {
        filter: {
          createdAt: {
            ...(adSpendsDateFrom && {
              gte: adSpendsDateFrom,
            }),
            ...(adSpendsDateTo && {
              lte: adSpendsDateTo,
            }),
          },
        },
      },
    });

  const {
    data: organizationGrowOverallUsageData,
    loading: organizationGrowOverallUsageLoading,
  } = useOrganizationGrowOverallUsageQuery();

  const stats = useMemo(
    () => [
      {
        label: "Activities Count",
        value: formatNumber(
          organizationGrowOverallUsageData?.organizationGrowOverallUsage
            ?.activitiesCount || 0,
        ),
        valueIcon: <Cycle height={24} width={24} />,
        action: {
          children: (
            <span className={styles.btnWithIcon}>
              <span>View All Activities</span>{" "}
              <KeyboardArrowRight
                width={18}
                height={18}
                fill={"currentColor"}
              />
            </span>
          ),
          to: "/campaigns",
        },
      },
      {
        label: "Ad Spends (Monthly)",
        value: (
          <div>
            {selectedCurrencyCode}{" "}
            <b>
              {formatNumber(
                organizationUsageData?.organizationUsage?.OrganizationUsage
                  ?.totalAdSpend / 100 || 0,
                {
                  showDecimal: true,
                },
              )}
            </b>{" "}
            /{" "}
            {formatNumber(
              billingData?.billing?.BillingGrowPlan?.monthlyAdSpend / 100 || 0,
              {
                showDecimal: true,
              },
            )}{" "}
          </div>
        ),
        valueIcon: <Wallet height={24} width={24} />,
        action: {
          children: "limit as per current plan",
          to: "/settings/pricing",
          color: "subdued" as const,
        },
      },
      {
        label: "Audience Count",
        value: formatNumber(
          organizationGrowOverallUsageData?.organizationGrowOverallUsage
            ?.audienceCount || 0,
        ),
        valueIcon: <Group height={24} width={24} />,
        action: {
          children: (
            <span className={styles.btnWithIcon}>
              <span>View Audiences</span>{" "}
              <KeyboardArrowRight
                width={18}
                height={18}
                fill={"currentColor"}
              />
            </span>
          ),
          to: "/audiences",
        },
      },
    ],
    [organizationGrowOverallUsageData, billingData, organizationUsageData],
  );

  if (
    organizationUsageLoading ||
    organizationGrowOverallUsageLoading ||
    billingLoading ||
    adSpendsLoading ||
    metaUsageLoading
  ) {
    return <Spinner height="screen" />;
  }

  const adSpendsChartData =
    adSpendsPerTick && adSpendsPerTick.organizationAdSpendsPerTick
      ? adSpendsPerTick.organizationAdSpendsPerTick.data.map((v: any) => ({
          ...v,
          time:
            "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
        }))
      : [];

  const activityUsageChartData =
    metaUsagePerTickDate && metaUsagePerTickDate.organizationMetaUsagePerTick
      ? metaUsagePerTickDate.organizationMetaUsagePerTick.map((v) => ({
          count: v.count,
          time: v.date
            ? convertDatetimeToDate(new Date(v.date))
            : `${getMonthFromNumber(
                new Date(v.month).getMonth() + 1,
              )} ${new Date(v.month).getFullYear()}`,
        }))
      : [];

  return (
    <div className={styles.container}>
      <SectionHeading
        heading={"Overall Usage"}
        description={"Here are your overall usage"}
      />
      <div className={styles.statsWrapper}>
        <OverallUsageStats stats={stats} />
        <GraphCard
          action={
            <DateFilterDropdown
              showLabel={true}
              filterOptions={filterOptions}
              setDateFilter={setAdSpendsDateFilter}
              dateFilter={adSpendsDateFilter}
            />
          }
          graphClassName={styles.graph}
          cardClassName={styles.graphSection}
          title={"Ad Spends (Monthly)"}
          subtitle={"Ad Spends (Monthly)"}
          value={""}
          chartData={adSpendsChartData}
          chartDataKey={"spend"}
          yAxis={{
            dataKey: "spend",
            label: "Ad Spends Monthly",
            tickFormatter: (v) =>
              formatNumber(v, {
                showDecimal: false,
              }),
            tooltipFormatter: (v) => formatNumber(v),
          }}
        />
        <GraphCard
          action={
            <DateFilterDropdown
              showLabel={true}
              filterOptions={filterOptions}
              setDateFilter={setMetaUsageDateFilter}
              dateFilter={metaUsageDateFilter}
            />
          }
          graphClassName={styles.graph}
          cardClassName={styles.graphSection}
          title={"Meta Activity Usage"}
          subtitle={"Activity usage based on Meta Platform"}
          value={""}
          chartData={activityUsageChartData}
          chartDataKey={"count"}
          yAxis={{
            dataKey: "count",
            label: "Meta Usage",
            tickFormatter: (v) =>
              formatNumber(v, {
                showDecimal: false,
              }),
          }}
        />
      </div>
    </div>
  );
}
