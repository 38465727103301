import { Form, Formik, FormikContextType } from "formik";
import { toast } from "react-hot-toast";
import { FormValues } from "../misc/adTemplateCreateTypes";
import styles from "./AdTemplateCreatePage.module.scss";
import {
  MetaActivityAdInput,
  MetaActivityAdTemplate,
  useMetaActivityAdTemplateCreateMutation,
  useMetaActivityAdTemplateQuery,
  useMetaIntegrationDefaultSettingsQuery,
} from "../../../graphql/generated/schema";
import { adTemplateCreateSchema } from "../misc/adTemplateCreateSchema";
import { AdTemplateCreateForm } from "../components/AdTemplateCreateSlideOver/AdTemplateCreateForm";
import { AdTemplatePreviewSection } from "../components/AdTemplateCreateSlideOver/AdTemplatePreviewSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "../../../components/Spinner/Spinner";
import { checkForDuplicatedAssetInLineItems } from "src/lib/checkForDuplicatedAssetInLineItems";
import { verifyLineItemAssets } from "../misc/verifyLineItems";

const initialValues: FormValues = {
  primaryText: "",
  title: "",
  type: "SINGLE_IMAGE_OR_VIDEO",
  LineItems: [
    {
      order: 0,
      headline: "",
      description: "",
      link: "",
    },
  ],
};

function generateInputFromActivityAdTemplate(
  input: MetaActivityAdTemplate
): FormValues {
  const type =
    input.adType === "CUSTOM_CREATIVE"
      ? input.LineItems.length > 1
        ? ("CAROUSEL" as const)
        : ("SINGLE_IMAGE_OR_VIDEO" as const)
      : input.adType === "CATALOG"
      ? ("CATALOGUE_AD" as const)
      : input.adType;
  const baseValues: FormValues = {
    phoneNumber: input.phoneNumber,
    primaryText: input.primaryText,
    title: input.name,
    type,
    MetaProductSetId: input.metaProductSetId,
    useDefaultSettings: true,
    adPhoneNumber: input.phoneNumber,
    phoneCountryCode: input.phoneCountryCode,
    link: "",
    existingPost: input.ExistingPost,
    existingPostId: input.existingPostId,
    LineItems: input.LineItems.map(
      (v) =>
        ({
          ...v,
          postAssetId: v.PostAsset?.id,
          storyAssetId: v.StoryAsset?.id,
        } as any)
    ),
    pageWelcomeId: input.pageWelcomeMessageId,
    ...(input.PageWelcomeMessage && {
      PageWelcomeMessage: {
        name: input.PageWelcomeMessage?.name,
        greeting: input.PageWelcomeMessage?.greeting,
        prefilledText: input.PageWelcomeMessage?.prefilledText,
        MessageOptions: input.PageWelcomeMessage?.MessageOptions.map((v) => ({
          text: v.text,
          automatedReply: v.automatedReply,
        })),
      },
    }),
  };
  return baseValues;
}

function generateActivityAdTemplateFromInput(
  input: FormValues
): MetaActivityAdInput {
  const type = input.type;

  switch (type) {
    case "SINGLE_IMAGE_OR_VIDEO":
    case "CAROUSEL":
      return {
        primaryText: input.primaryText,
        name: input.title.trim(),
        adType: "CUSTOM_CREATIVE",
        phoneNumber: input.adPhoneNumber,
        phoneCountryCode: input.phoneCountryCode,
        pageWelcomeMessageId: input.pageWelcomeId,
        LineItems: input.LineItems.map((v, i) => ({
          order: i,
          link: v.link,
          headline: v.headline,
          description: v.description,
          postAssetId: v.PostAsset?.id,
          storyAssetId: v.StoryAsset?.id,
        })),
      };
    case "CATALOGUE_AD":
      return {
        name: input.title.trim(),
        adType: "CATALOG",
        primaryText: input.primaryText,
        enabledAccountId: input.adAccountId,
        catalogueId: input.catalogueId,
        MetaProductSetId: input.MetaProductSetId,
        phoneNumber: input.adPhoneNumber,
        phoneCountryCode: input.phoneCountryCode,
        pageWelcomeMessageId: input.pageWelcomeId,
        LineItems: input.LineItems.map((v, i) => ({
          order: i,
          headline: v.headline,
          description: v.description,
        })),
      };
    case "EXISTING_POST":
      return {
        name: input.title.trim(),
        adType: "EXISTING_POST",
        primaryText: input.existingPost.caption,
        enabledAccountId: input.adAccountId,
        metaPageId: input.metaPageId,
        metaInstagramId: input.metaInstagramPageId,
        metaPageConnectedInstagramBusinessAccountId:
          input.metaPageConnectedInstagramBusinessAccountId,
        existingPostId: input.existingPostId,
        phoneNumber: input.adPhoneNumber,
        phoneCountryCode: input.phoneCountryCode,
        pageWelcomeMessageId: input.pageWelcomeId,
        LineItems: [],
      };
    default:
      throw new Error("type not found");
  }
}

export default function AdTemplateCreatePage() {
  let formValues = initialValues;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { data, loading, error } = useMetaIntegrationDefaultSettingsQuery();
  const {
    data: metaActivityAdTemplateData,
    loading: metaActivityAdTemplateDataLoading,
  } = useMetaActivityAdTemplateQuery({
    variables: {
      id: id,
    },
  });
  const [metaActivityAdTemplateFunc] =
    useMetaActivityAdTemplateCreateMutation();

  if (metaActivityAdTemplateDataLoading) {
    return <Spinner height="screen" />;
  }

  if (
    metaActivityAdTemplateData &&
    metaActivityAdTemplateData.metaActivityAdTemplate
  ) {
    const metaActivityAdTemplate =
      metaActivityAdTemplateData.metaActivityAdTemplate;
    formValues = generateInputFromActivityAdTemplate(
      metaActivityAdTemplate as any
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      validationSchema={adTemplateCreateSchema}
      onSubmit={async (values: typeof initialValues, formikHelpers) => {
        const payload = verifyLineItemAssets({
          values,
          setFieldError: formikHelpers.setFieldError,
        });

        if (payload) {
          return;
        }

        const input = generateActivityAdTemplateFromInput(values);
        await metaActivityAdTemplateFunc({
          variables: {
            input,
          },
          refetchQueries: ["metaActivityAdTemplates"],
          awaitRefetchQueries: true,
        });
        toast.success("Successfully created ad template");
        navigate("/library/ad-library");
      }}
    >
      <Form>
        <div className={styles.layout}>
          <div className={styles.layoutForm}>
            <AdTemplateCreateForm />
          </div>
          <div className={styles.layoutPreview}>
            <AdTemplatePreviewSection
              // instagramAccountName={
              //   data?.metaIntegrationDefaultSettings?.MetaInstagramAccount?.name
              // }
              metaPageName={
                data?.metaIntegrationDefaultSettings?.MetaPage?.name
              }
              profilePicture={
                data?.metaIntegrationDefaultSettings?.MetaPage?.Business
                  ?.profilePicture
              }
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
}
