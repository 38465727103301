import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./CustomerTagListingPage.module.scss";
import { Button } from "src/components/Button/Button";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { ReactComponent as ArrowRight } from "@material-design-icons/svg/outlined/arrow_right_alt.svg";
import Table from "src/components/Table/Table";
import { useState } from "react";
import { Tabs } from "src/components/Tabs/Tabs";
import Dropdown from "src/components/Dropdown/Dropdown";

const HEADINGS = ["Tag", "Last Used", "Counts", "Created on"];

const ROWS = [
  [
    "User",
    "23 May,2023",
    <div className={styles.count}>10</div>,
    "20 May,2022",
  ],
  [
    "Random",
    "29 May,2023",
    <div className={styles.count}>10</div>,
    "20 May,2022",
  ],
  [
    "Expensive Buyer",
    "03 June,2023",
    <div className={styles.count}>10</div>,
    "20 May,2022",
  ],
  [
    "Worthy",
    "23 May,2023",
    <div className={styles.count}>10</div>,
    "20 May,2022",
  ],
  [
    "User",
    "23 May,2023",
    <div className={styles.count}>10</div>,
    "20 May,2022",
  ],
];

const TABS = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Archived",
    value: "Archived",
  },
  {
    label: "Trash",
    value: "Trash",
  },
];

export function CustomerTagListingPage() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Active");
  return (
    <div>
      <PageHeader
        title={
          <div className={styles.title}>
            <ArrowBack />
            <h1>Manage Tags</h1>
          </div>
        }
        actions={<Button color="primary">New tag</Button>}
      />
      {/* TODO: Manage Tags Table */}
      <div className={styles.tabsContainer}>
        <Tabs
          tabs={TABS}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          // actions={
          //   <Dropdown
          //     title={<span>Sort by</span>}
          //     items={[
          //       {
          //         children: "Recently Added",
          //         onClick: () => console.log("Recently Added"),
          //       },
          //       {
          //         children: (
          //           <p className={styles.alphabet}>
          //             <span>Alphabet</span>
          //             <ArrowRight
          //               width={14}
          //               height={14}
          //               fill="#D9D9D9"
          //               style={{ transform: "rotate(-90deg)" }}
          //             />
          //           </p>
          //         ),
          //         onClick: () => console.log("Alphabet"),
          //       },
          //       {
          //         children: "Old to New",
          //         onClick: () => console.log("Old to New"),
          //       },
          //     ]}
          //   />
          // }
        />
      </div>
      <div className={styles.manageTagTable}>
        <Table
          headings={HEADINGS}
          rows={ROWS}
          selectable={true}
          selectedItems={selectedRows}
          setSelectedItems={() => setSelectedRows}
        />
      </div>
    </div>
  );
}
