import styles from "./SingleCustomerAnalytics.module.scss";
import StatCard from "src/components/StatCard/StatCard";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { useContext, useState } from "react";
import AuthContext from "src/stores/AuthContext";
import { convertFromArrayToObject } from "src/lib/convertFromArrayToObject";
import { CustomerEngageActionHistory } from "./CustomerEngageActionHistory";
import { Tabs } from "src/components/Tabs/Tabs";
import { CustomerWebsiteActionHistory } from "./CustomerWebsiteActionHistory";
import { SectionHeading } from "src/modules/global/components/SectionHeading";

interface SingleCustomerAnalyticsProps {
  // TODO: fix type
  customer: any;
  dateFrom: string;
  dateTo: string;
}

export function SingleCustomerAnalytics({
  customer,
  dateFrom,
  dateTo,
}: SingleCustomerAnalyticsProps) {
  const [selectedHistoryTab, setSelectedHistoryTab] =
    useState<(typeof historyTabs)[number]["value"]>("ENGAGE");
  const { userToken } = useContext(AuthContext);
  const { data, loading } = useTinybirdQuery("engage_aggregate", userToken, {
    customerId: customer.id,
    dateFrom,
    dateTo,
  });
  const { data: websiteConversionData, loading: websiteConversionLoading } =
    useTinybirdQuery("website_conversion", userToken, {
      customerId: customer.id,
      dateFrom,
      dateTo,
    });

  const engageInsights = convertFromArrayToObject(
    data ?? [],
    (i) => i.action,
    (i) => i.actionCount
  );

  const websiteConversionInsights = websiteConversionData?.[0];

  const insights = [
    {
      title: "Open Rates",
      subtitle: "Opens by this customer per 100 sends",
      loading,
      value: data
        ? (engageInsights.view_email / engageInsights.send_email) * 100
        : null,
      valueType: "PERCENTAGE" as const,
    },
    {
      title: "Click Rates",
      subtitle: "Clicks by this customer per 100 sends",
      loading,
      value: data
        ? (engageInsights.click_link / engageInsights.send_email) * 100
        : null,
      valueType: "PERCENTAGE" as const,
    },
    {
      title: "Total Revenue",
      subtitle: "Revenue from this customer in selected time frame",
      loading: websiteConversionLoading,
      value: websiteConversionInsights
        ? websiteConversionInsights.sumAmount
        : null,
      valueType: "CURRENCY" as const,
    },
    {
      title: "Order Count",
      subtitle: "Orders by this customer in selected time frame",
      loading: websiteConversionLoading,
      value: websiteConversionInsights
        ? websiteConversionInsights.orderCount
        : null,
      valueType: "NUMBER" as const,
    },
  ];

  return (
    <div>
      <div>
        <h2 className={styles.heading}>List of activities in last 7 days</h2>
        <div className={styles.analyticsContainer}>
          {insights.map((item, index) => (
            <StatCard
              title={item.title}
              value={item.value}
              loading={loading}
              subtitle={item.subtitle}
              valueType={item.valueType}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className={styles.performContainer}>
        <div className={styles.performanceHeader}>
          <SectionHeading heading="Customer Event History" description="" />

          <Tabs
            onTabChange={setSelectedHistoryTab}
            selectedTab={selectedHistoryTab}
            tabs={historyTabs}
          />
        </div>

        <div>
          {selectedHistoryTab === "ENGAGE" && (
            <CustomerEngageActionHistory
              dateFrom={dateFrom}
              dateTo={dateTo}
              customerId={customer.id}
            />
          )}
          {selectedHistoryTab === "WEBSITE" && (
            <CustomerWebsiteActionHistory
              dateFrom={dateFrom}
              dateTo={dateTo}
              customerId={customer.id}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const historyTabs = [
  {
    label: "Engage",
    value: "ENGAGE" as const,
  },
  {
    label: "Website",
    value: "WEBSITE" as const,
  },
];
