import { Segment, useSegmentsQuery } from "src/graphql/generated/schema";
import AudienceCard from "src/components/AudienceItem/AudienceCard";
import { Button } from "src/components/Button/Button";
import { CampaignInput } from "../misc/createCampaignTypes";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import Modal from "src/components/Modal/Modal";
import format from "date-fns/format";
import styles from "./ReceiveMailSegment.module.scss";
import { useFormikContext } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import segmentsIcon from "../assets/segmentsIcon.svg";
import { ErrorHandler } from "src/components/ErrorHandler";

// const segments = [
//   {
//     id: "1",
//     name: "Convert subscribers to first-time customers",
//   },
//   {
//     id: "2",
//     name: "First-time customers who have opted in to email marketing",
//   },
//   {
//     id: "3",
//     name: "Engage high-value, high-intent customers",
//   },
//   {
//     id: "4",
//     name: "High spend customers who purchased products with a specific tag",
//   },
//   {
//     id: "5",
//     name: "Engage high-value, high-intent customers",
//   },
//   {
//     id: "6",
//     name: "First-time customers who have opted in to email marketing",
//   },
// ];

interface Props {
  showAddRecipient: boolean;
  setShowAddRecipient: (i: boolean) => void;
}

export function AddRecipientModal({
  setShowAddRecipient,
  showAddRecipient,
}: Props) {
  // TODO : pass multiple activities from props
  const selectedActivityIndex = 0;
  const { values, setFieldValue } = useFormikContext<CampaignInput>();
  const { data, loading, error } = useSegmentsQuery({
    variables: {
      filter: {
        take: 10,
      },
    },
  });
  const segments =
    data && data.segments ? data.segments.edges.map((v) => v.node) : [];

  const [selectedItems, setSelectedItems] = useState(
    values.Activities[selectedActivityIndex]?.segments || []
  );

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <>
      <Modal
        isOpen={showAddRecipient}
        onClose={() => setShowAddRecipient(false)}
        title="Add recipients"
        subtitle="Add no. of recipients (segments), who will receive your email"
        maxWidth="77.25rem"
        footer={{
          footerContent: (
            <p className={styles.footerText}>
              <Info />
              <span>
                Want to add different segments?
                <Button style="plain" size="small" to="/segments">
                  Create new one
                </Button>
              </span>
            </p>
          ),
          footerActions: (
            <Button
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(
                  `Activities[${selectedActivityIndex}].segmentIds`,
                  selectedItems.map((v) => v.id)
                );
                setFieldValue(
                  `Activities[${selectedActivityIndex}].segments`,
                  selectedItems
                );
                setShowAddRecipient(false);
                toast.success("Recipient added successfully");
              }}
            >
              Add Segments
            </Button>
          ),
        }}
        noContainer
      >
        <div className={styles.optionsWrapper}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <AddRecipientModalContent
              segments={segments}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

function AddRecipientModalContent({
  segments,
  setSelectedItems,
  selectedItems,
}: {
  segments: Segment[];
  setSelectedItems: (i: any) => void;
  selectedItems: Segment[];
}) {
  return (
    <div className={styles.options}>
      {segments.map((segment, index) => (
        <AudienceCard
          name="segments"
          onBlur={() => {}}
          checked={selectedItems.some((v) => v.id === segment.id)}
          onChange={(v) => {
            if (v) {
              setSelectedItems([...selectedItems, segment]);
              return;
            }
            setSelectedItems(selectedItems.filter((v) => v.id !== segment.id));
          }}
          image={segmentsIcon}
          title={segment.name}
          value={segment.id}
          subtitle={`Created on: ${format(new Date(), "dd MMM, yy")}`}
          key={index}
        />
      ))}
    </div>
  );
}
