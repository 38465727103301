import classNames from "classnames";
import styles from "./Notice.module.scss";
import { ReactComponent as EmojiObjects } from "@material-symbols/svg-400/rounded/emoji_objects-fill.svg";
import { ReactNode } from "react";

export interface INotice {
  color: "surface" | "default";
  content: ReactNode;
}

export default function Notice({ color, content }: INotice) {
  return (
    <div className={classNames(styles[color], styles.container)}>
      <div className={styles.header}>
        <EmojiObjects className={classNames(styles[color], styles.icon)} />
        <span className={classNames(styles[color], styles.headerTitle)}>
          Notice
        </span>
      </div>
      <div className={styles.contentContainer}>{content}</div>
    </div>
  );
}
