import { ReactComponent as Account } from "@material-design-icons/svg/outlined/account_circle.svg";
import { ReactComponent as Assignment } from "@material-design-icons/svg/outlined/assignment.svg";
import { AutomationSidebarHeader } from "./AutomationSidebarHeader";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import { ReactComponent as Drafts } from "@material-design-icons/svg/outlined/drafts.svg";
import { ReactComponent as Feed } from "@material-design-icons/svg/outlined/feed.svg";
import { MenuItem } from "./MenuItem";
import { useFormikContext } from "formik";

const activities = [
  {
    icon: <Drafts />,
    title: "Signups",
    value: "SIGNUP",
  },
  {
    icon: <Account />,
    title: "Customer Added on Macro",
  },
  {
    icon: <Feed />,
    title: "Newsletter",
  },
  {
    icon: <Assignment />,
    title: "Survey and Forms",
  },
];

export function AutomationContactActivity() {
  const { setFieldValue } = useFormikContext();
  return (
    <div>
      <AutomationSidebarHeader
        onBack={() => {
          setFieldValue("section", "START");
        }}
        title="Contact Activity"
      />
      <div style={{ marginTop: "2.75rem" }}>
        <ul>
          {activities.map((activity, index) => (
            <MenuItem
              icon={activity.icon}
              title={activity.title}
              key={index}
              action={{
                onClick: () => {
                  setFieldValue("section", activity.value);
                },
                label: <ChevronRight />,
              }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
