import { ReactNode, useEffect, useRef, useState } from "react";
import { Checkbox } from "../Checkbox/Checkbox";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";
import styles from "./Table.module.scss";
import {
  DropdownButton,
  Dropdown,
} from "src/modules/global/components/Dropdown";
import { Button, IButton } from "../Button/Button";

// interface SelectableRow {
//   children: ReactNode[];
//   rowId: string;
// }

interface TableActionsDropdown extends Omit<DropdownButton, "onClick"> {
  onClick: (e: any, index: number) => void;
  isLoading: (index: number) => boolean;
}

interface TableAction extends Omit<IButton, "onClick"> {
  onClick: (e: any, index: number) => void;
}

// TODO: need a better implementation of select in table
export interface ITable {
  headings: ReactNode[];
  rows: ReactNode[][];
  selectable?: boolean;
  highlightedRowIndex?: number;
  selectedItems?: number[];
  setSelectedItems?: (i: number[]) => void;
  rounded?: boolean;
  loading?: boolean;
  hasPagination?: boolean;
  // noOfRows used for loading
  noOfRows?: number;
  className?: string;
  selectActions?: ReactNode;
  actionDropdown?: {
    title: ReactNode;
    items: TableActionsDropdown[];
  };
  action?: TableAction;
}

export default function Table(props: ITable) {
  const footerRef = useRef<HTMLDivElement | null>(null);
  const {
    headings,
    rows,
    hasPagination,
    highlightedRowIndex,
    selectable,
    selectedItems,
    setSelectedItems,
    noOfRows,
    className,
    rounded = false,
    loading = false,
    selectActions,
    actionDropdown,
    action,
  } = props;

  useEffect(() => {
    if (!footerRef.current) return;
    if (selectedItems && selectedItems.length > 0) {
      footerRef.current.style.position = "sticky";
    } else {
      footerRef.current.style.position = "static";
    }
  }, [selectedItems]);

  function onSelectionChange(value: number | "ALL" | "NONE") {
    if (value === "NONE") {
      setSelectedItems([]);
      return;
    }
    if (value === "ALL") {
      setSelectedItems(rows.map((_, i) => i));
      return;
    }
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((v) => v !== value));
      return;
    }
    setSelectedItems([...selectedItems, value]);
  }

  return (
    <div className={styles.tableWrapper}>
      <table
        className={classNames(styles.table, className, {
          [styles.rounded]: rounded,
          [styles.hasPagination]: hasPagination,
        })}
      >
        <thead>
          <tr className={styles.tableHeader}>
            {selectable && !loading ? (
              <th
                className={classNames(styles.tableHead, styles.checkDimension)}
                align="justify"
              >
                <div className={styles.checkWrap}>
                  {/* This can be checked manually or only when every row in the table is selected. */}
                  <Checkbox
                    value=""
                    checked={selectedItems.length === rows.length}
                    onChange={() =>
                      onSelectionChange(
                        rows.length === selectedItems.length ? "NONE" : "ALL"
                      )
                    }
                  />
                </div>
              </th>
            ) : null}
            {headings.map((heading, index) => (
              <th
                key={index}
                className={classNames(styles.tableHead, {
                  [styles.tableHeadRound]: index + 1 === headings.length,
                  [styles.unCheckDimension]: !selectable,
                })}
                align={index === 0 ? "justify" : "center"}
              >
                {heading}
              </th>
            ))}
            {actionDropdown && (
              <th
                className={classNames(styles.tableHead, styles.tableHeadRound)}
                align="justify"
              >
                {" "}
              </th>
            )}
            {action && (
              <th
                align="justify"
                className={classNames(styles.tableHead, styles.tableHeadRound)}
              >
                {""}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {loading
            ? new Array(noOfRows).fill("").map((_, idx) => (
                <tr key={idx} className={classNames(styles.bodyRow)}>
                  {headings.map((_, hidx) => (
                    <td
                      key={hidx}
                      className={styles.loadingCol}
                      align={hidx === 0 ? "justify" : "center"}
                    >
                      <Skeleton className={styles.skeleton} />
                    </td>
                  ))}
                </tr>
              ))
            : rows.map((row, index) => (
                <tr
                  key={index}
                  // className={classNames(styles.bodyRow, {
                  //   [styles.lastRow]: index + 1 === rows.length,
                  // })}
                  className={classNames(styles.bodyRow, {
                    [styles.highlightedRowIndex]: highlightedRowIndex === index,
                  })}
                >
                  {selectable && (
                    <td
                      className={classNames(
                        styles.tableHead,
                        styles.checkDimension
                      )}
                    >
                      <div
                        className={classNames(styles.bodyCheckWrap, {
                          [styles.hideBodyCheck]: selectedItems.length === 0,
                          [styles.highlightedRowIndex]:
                            highlightedRowIndex === index,
                        })}
                      >
                        <Checkbox
                          value=""
                          checked={selectedItems.includes(index)}
                          onChange={() => onSelectionChange(index)}
                        />
                      </div>
                    </td>
                  )}

                  {/* mapping over headings prevents extra rows and helps with auto fill empty fields */}
                  {headings.map((_, idx) => (
                    <td
                      key={idx}
                      className={classNames(styles.tableDimension, {
                        [styles.unCheckDimension]: !selectable,
                        [styles.secondDark]: selectable,
                        [styles.highlightedRowIndex]:
                          highlightedRowIndex === index,
                      })}
                      align={idx === 0 ? "justify" : "center"}
                    >
                      {row[idx] ? row[idx] : "-"}
                    </td>
                  ))}

                  {/* dropdown that is only visible on hover */}
                  {actionDropdown && (
                    <td
                      align="center"
                      className={classNames(
                        styles.tableDimension,
                        styles.actionDropdown,
                        styles.bodyCheckWrap,
                        styles.hideBodyCheck
                      )}
                    >
                      <Dropdown
                        title={actionDropdown.title}
                        items={actionDropdown.items.map((v) => ({
                          ...v,
                          children: v.children,
                          onClick: (e: any) => v.onClick(e, index),
                          loading: v.isLoading(index),
                        }))}
                      />
                    </td>
                  )}

                  {action && (
                    <td
                      className={classNames(
                        styles.tableDimension,
                        styles.bodyCheckWrap,
                        styles.hideBodyCheck
                      )}
                    >
                      <Button
                        {...action}
                        onClick={(e: any) => action.onClick(e, index)}
                      >
                        {action.children}
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
        </tbody>
      </table>
      {selectActions && selectedItems && selectedItems.length > 0 ? (
        <div className={styles.footer} ref={footerRef}>
          <div>
            <p className={styles.title}>
              {selectedItems.length} items selected of {rows.length}
            </p>
          </div>
          {selectActions}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
