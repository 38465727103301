import { useParams } from "react-router-dom";
import { useEmailDesignTemplateQuery } from "src/graphql/generated/schema";
import styles from "./PreviewEmail.module.scss";
import { useState } from "react";
import classNames from "classnames";
import { Spinner } from "src/components/Spinner/Spinner";
import { PreviewEmailFooter } from "./PreviewEmailFooter";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function PreviewEmail() {
  const { id } = useParams();
  const { data, loading, error } = useEmailDesignTemplateQuery({
    variables: { emailDesignTemplateId: id },
  });
  const [selectedMode, setSelectedMode] = useState<"Desktop" | "Mobile">(
    "Desktop"
  );

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div className={styles.container}>
      <iframe
        title="emailPreview"
        srcDoc={data.emailDesignTemplate.designHtml}
        className={classNames(styles.preview, {
          [styles.mobilePreview]: selectedMode === "Mobile",
        })}
      />
      <PreviewEmailFooter
        selectedMode={selectedMode}
        setSelectedMode={setSelectedMode}
      />
    </div>
  );
}
