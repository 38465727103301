import { Pagination } from "src/components/Pagination/Pagination";
import styles from "./MessageTemplatesListingTable.module.scss";
import { itemsPerPage } from "../misc/itemsPerPage";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  PageInfoFragment,
  WhatsappTemplateCategory,
  WhatsappTemplatesQuery,
} from "src/graphql/generated/schema";
import Tooltip from "src/components/Tooltip/Tooltip";
import { ReactComponent as ActivityCount } from "../assets/activityCount.svg";
import classNames from "classnames";
import { ReactComponent as WhatsappOutline } from "../../global/assets/whatsapp-outline.svg";
import { useMemo } from "react";
import format from "date-fns/format";
import authenticationCategory from "../assets/authenticationCategory.svg";
import transactionCategory from "../assets/transactionCategory.svg";
import supportCategory from "../assets/supportCategory.svg";
import promotionCategory from "../assets/promotionCategory.svg";
import reminderCategory from "../assets/reminderCategory.svg";
import { titleCase } from "src/lib/titleCase";
import { NoFilteredResultsFound } from "./NoFilteredResultsFound";

interface IMessageTemplatesListingTable {
  messageTemplates: WhatsappTemplatesQuery["whatsappTemplates"]["edges"];
  pageInfo: PageInfoFragment;
  loading: boolean;
  setPagination: (i: { take: number; cursor: string }) => void;
}

export function MessageTemplatesListingTable({
  messageTemplates,
  pageInfo,
  loading,
  setPagination,
}: IMessageTemplatesListingTable) {
  if (loading) {
    return <Spinner height={400} />;
  }

  if (!messageTemplates.length) {
    return <NoFilteredResultsFound />;
  }

  return (
    <div>
      <div>
        {messageTemplates.map((v) => (
          <MessageTemplateItem messageTemplate={v} onClick={() => {}} />
        ))}
      </div>
      <Pagination
        hasPrevious={pageInfo?.hasPreviousPage}
        hasNext={pageInfo?.hasNextPage}
        onPrevious={function (): void {
          setPagination({
            take: -itemsPerPage,
            cursor: pageInfo?.startCursor,
          });
        }}
        onNext={function (): void {
          setPagination({
            take: itemsPerPage,
            cursor: pageInfo?.endCursor,
          });
        }}
      />
    </div>
  );
}

interface IMessageTemplateItem {
  messageTemplate: WhatsappTemplatesQuery["whatsappTemplates"]["edges"][0];
  onClick: () => void;
}

function MessageTemplateItem({
  messageTemplate,
  onClick,
}: IMessageTemplateItem) {
  const count = messageTemplate.node.outreachCount;
  const formattedCreatedAt = useMemo(
    () => format(new Date(messageTemplate.node.createdAt), "dd MMMM, yyyy"),
    [messageTemplate],
  );
  const icon = getThumbnailImageBasedOnCategory(messageTemplate.node.category);

  return (
    <button onClick={onClick} className={styles.itemWrapper}>
      <div className={styles.primarySection}>
        <div className={styles.titleAndIcon}>
          <div>
            <img src={icon} width={32} height={32} alt="category icon" />
          </div>
          <div className={styles.title}>
            <div className={styles.assetTitle}>{messageTemplate.node.name}</div>
          </div>
        </div>
        <div className={styles.countContainer}>
          {/* Activity Count */}
          <Tooltip
            placement={"BOTTOM_CENTER"}
            supportingText="Shows how many Activities are using this assets"
          >
            <div className={styles.countItemStat}>
              <ActivityCount className={styles.icon} />
              <span className={styles.countValue}>
                {count > 9 ? count : `0${count}`}
              </span>
            </div>
          </Tooltip>
        </div>

        <div className={styles.statusContainer}>
          <span
            className={classNames(styles.status, {
              [styles.statusApproved]:
                messageTemplate.node.status === "APPROVED",
              [styles.statusRejected]:
                messageTemplate.node.status === "REJECTED",
              [styles.statusPending]: messageTemplate.node.status === "PENDING",
            })}
          >
            {titleCase(messageTemplate.node.status, "_")}
          </span>
        </div>
      </div>

      <div
        className={classNames(styles.badge, {
          [styles.inactive]: count === 0,
          [styles.active]: count > 0,
        })}
      >
        {count > 0 ? "In Use" : "Not In Use"}
      </div>

      <div className={styles.messagePlatform}>
        <WhatsappOutline
          width={20}
          height={20}
          fill="var(--color-text-disabled)"
        />
        Whatsapp
      </div>

      <div className={styles.category}>
        {titleCase(messageTemplate.node.category, "_")}
      </div>
      <div className={styles.createdAt}>{formattedCreatedAt}</div>
    </button>
  );
}

function getThumbnailImageBasedOnCategory(category: WhatsappTemplateCategory) {
  switch (category) {
    case "AUTHENTICATION":
      return authenticationCategory;
    case "MARKETING":
      return promotionCategory;
    // case "REMINDERS":
    //   return reminderCategory;
    // case "SUPPORT":
    //   return supportCategory;
    // case "TRANSACTIONAL":
    //   return transactionCategory;
    case "UTILITY":
      // FIXME: no icon for utility
      return transactionCategory;
  }
}
