import classNames from "classnames";
import styles from "./AutomationTableHeader.module.scss";
import { Button } from "src/components/Button/Button";
import { ReactComponent as Sort } from "@material-design-icons/svg/outlined/sort.svg";
import { ReactComponent as Down } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";

// const views = ["All", "Active", "Inactive", "Draft"];
const views = ["All"];

interface Props {
  view: string;
  setView: (i: string) => void;
  setShowFilters: (i: boolean) => void;
  showFilters: boolean;
}

export function AutomationTableHeader({
  view,
  setView,
  setShowFilters,
  showFilters,
}: Props) {
  return (
    <div className={styles.header}>
      <ul className={styles.viewContainer}>
        {views.map((item, index) => (
          <li
            key={index}
            onClick={() => setView(item)}
            className={classNames(styles.view, {
              [styles.highlight]: item === view,
            })}
          >
            {item}
          </li>
        ))}
      </ul>
      {/* <div className={styles.btnGroup}>
        <button
          className={styles.actionBtn}
          onClick={() => setShowFilters(!showFilters)}
        >
          <span>Show filters</span>
          <Sort />
        </button>
        <button className={styles.actionBtn}>
          <span>More action</span>
          <Down />
        </button>
      </div> */}
    </div>
  );
}
