import { useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCampaignPresetLazyQuery } from "src/graphql/generated/schema";
import { CampaignInput } from "../misc/createCampaignTypes";
import { defaultInitialValues } from "../misc/defaultInitialValue";

export function useGetCampaignPreset() {
  // Get URL search params to see if presetId is passed
  const [qs] = useSearchParams();
  // we only need it first time; not memoization it cause re-render
  // when we will save the campaign and add a presetId to the url
  const presetIdOnlyOnce = useMemo(() => qs.get("presetId"), []);

  // If presetId is passed in query params then call this query
  const [getCampaignPreset, { called, data, loading, error }] =
    useCampaignPresetLazyQuery({
      variables: { id: presetIdOnlyOnce },
    });

  // If presetId is present in query params then fetch preset values from DB
  // only fetch the first time
  useEffect(() => {
    if (presetIdOnlyOnce) {
      getCampaignPreset();
    }
  }, []);

  const isNotCalled = !called && presetIdOnlyOnce;

  // if presetId is present then attach it to the presetId data
  // and assign it to campaignInput if not then use the
  // default values above to create a new preset
  const initialData: CampaignInput =
    presetIdOnlyOnce && data
      ? {
          ...data.campaignPreset.data,
          presetId: presetIdOnlyOnce,
          completedSteps: data.campaignPreset.data.completedSteps || [],
        }
      : defaultInitialValues;

  return { data: initialData, loading: loading || isNotCalled, error };
}
