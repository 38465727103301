import { ReactNode } from "react";
import sidebarDefaultSteps from "../../modules/global/components/SidebarDefaultSteps";
import { Sidebar } from "../../modules/global/components/Sidebar";
import styles from "./Layout.module.scss";
import { User } from "src/graphql/generated/schema";
import classNames from "classnames";
import { HelpButton } from "src/modules/global/components/HelpButton";

export interface ILayout {
  children: ReactNode;
  header?: ReactNode;
  user?: User;
  showSidebar?: boolean;
}

export default function Layout({
  children,
  header,
  user,
  showSidebar = true,
}: ILayout) {
  return (
    <div className={classNames(styles.layout)}>
      {showSidebar && <Sidebar config={sidebarDefaultSteps} user={user} />}
      <div
        className={classNames({
          [styles.contentPage]: showSidebar,
          [styles.fullContentPage]: !showSidebar,
        })}
      >
        {header && header}
        {children}
        {/*
        <HelpButton />
        */}
      </div>
    </div>
  );
}
