import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "../../../components/Spinner/Spinner";
import { useUserInviteCodeQuery } from "../../../graphql/generated/schema";
import { ReactComponent as MacroLogo } from "../../../components/Logo/macro-logo-primary-icon.svg";
import styles from "./AcceptInvite.module.scss";
import acceptInviteIllustration from "../assets/acceptInvite.png";
import inviteExpiredIllustration from "../assets/inviteLInkBroken.png";
import { ErrorHandler } from "../../../components/ErrorHandler";
import { Button } from "../../../components/Button/Button";
import isAfter from "date-fns/isAfter";

export function AcceptInvite() {
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("code");
  const { data, loading, error } = useUserInviteCodeQuery({
    variables: {
      code: inviteCode,
    },
  });
  const navigate = useNavigate();

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const isExpired = isAfter(
    new Date(),
    new Date(data.userInviteCode.expiresAt)
  );

  console.log(isExpired);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <MacroLogo width={16} height={16} />
        <div className={styles.title}>
          Invitation for {data.userInviteCode.Client.name}
        </div>
      </div>
      <div className={styles.container}>
        {isExpired ? (
          <>
            <img
              src={inviteExpiredIllustration}
              alt={acceptInviteIllustration}
              className={styles.illustration}
            />
            <div className={styles.content}>
              <div className={styles.contentTitle}>
                Looks like this link has been broken
              </div>
              <div className={styles.contentDescription}>
                Contact to your admin to get invite of client{" "}
                <b>{data.userInviteCode?.Client?.name}</b> again
              </div>
            </div>
          </>
        ) : (
          <>
            <img
              src={acceptInviteIllustration}
              alt={acceptInviteIllustration}
              className={styles.illustration}
            />
            <div className={styles.content}>
              <div className={styles.contentTitle}>
                You were invited to {data.userInviteCode.Client.name}
              </div>
              <div className={styles.contentDescription}>
                To accept the invitation please signup
              </div>
            </div>
            <div className={styles.buttonGroupVertical}>
              <Button
                size="micro"
                onClick={() => {
                  navigate(`/auth/signup?code=${inviteCode}`);
                }}
              >
                Signup with {data.userInviteCode.Client.name}
              </Button>
            </div>
          </>
        )}
      </div>

      {/*TODO: add intercom action here*/}
      <div className={styles.footer}>
        <span className={styles.footerContent}>
          Need any help from us? <Button style="plain">Contact Support</Button>
        </span>
      </div>
    </div>
  );
}
