import styles from "./StatCard.module.scss";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";
import { ReactComponent as ExclamationIcon } from "@material-design-icons/svg/outlined/error_outline.svg";
import { formatNumberByType } from "../../modules/global/functions/formatNumberByType";
import metaIcon from "../../modules/global/assets/meta.svg";
import Skeleton from "react-loading-skeleton";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

export type IValueType = "NUMBER" | "CURRENCY" | "PERCENTAGE" | "MULTIPLIER";
type IComparisonWindow = "DAY" | "WEEK" | "MONTH" | "YEAR" | "ALL_TIME";

export interface IStatCard {
  title: string;
  subtitle: string;
  loading?: boolean;
  value: number | undefined;
  learnMoreText?: string;
  valueType: IValueType;
  comparisonValue?: number;
  comparisonWindow?: IComparisonWindow;
  comparisonLayout?: "VERTICAL" | "HORIZONTAL";
}

export default function StatCard({
  title,
  subtitle,
  value,
  loading,
  learnMoreText,
  valueType,
  comparisonValue,
  comparisonWindow,
  comparisonLayout = "VERTICAL",
}: IStatCard) {
  // using boolean(comparisonValue) will fail if the comparison value is 0
  // const hasComparisonValue = typeof comparisonValue === "number";
  // const changePercentage = useMemo(
  //   () =>
  //     hasComparisonValue ? getRelativePercentage(value, comparisonValue) : 0,
  //   [value, comparisonValue, hasComparisonValue]
  // );

  // const isChangePositive = changePercentage > 0;
  // const shouldShowComparison = hasComparisonValue && changePercentage !== 0;

  // let formattedComparisonWindow =
  //   getFormattedComparisonWindow(comparisonWindow);
  const selectedCurrencyCode = useGetActiveCurrencyCode();
  let formattedValue = formatNumberByType(value, valueType, {
    selectedCurrencyCode: selectedCurrencyCode,
    showDecimal: true,
  });

  // const comparisonBadge = shouldShowComparison ? (
  //   <div className={styles.comparisonPercentage}>
  //     <Badge
  //       label={`${Math.abs(changePercentage)} %`}
  //       status={isChangePositive ? "success" : "danger"}
  //       icon={
  //         isChangePositive ? (
  //           <ArrowUp stroke="currentColor" width={12} height={12} />
  //         ) : (
  //           <ArrowDown stroke="currentColor" width={12} height={12} />
  //         )
  //       }
  //     />
  //   </div>
  // ) : null;

  return (
    <div className={styles.card}>
      <h4 className={styles.title}>
        {title}
        {!!learnMoreText && (
          <InfoIcon
            title={learnMoreText}
            width={18}
            height={18}
            className={styles.learnMoreIcon}
          />
        )}
      </h4>
      <div className={styles.subtitle}>{subtitle}</div>

      <div className={styles.value}>
        {loading ? (
          <Skeleton width={"5.5rem"} height={"2.25rem"} />
        ) : (
          formattedValue ?? "-"
        )}
      </div>
      {/* {comparisonLayout === "VERTICAL" && comparisonBadge}

      {shouldShowComparison && (
        <div className={styles.footer}>
          <span className={styles.comparisonWindow}>
            <DeviceReset
              strokeWidth={2}
              width={18}
              height={18}
              className={styles.deviceResetIcon}
            />
            vs. {formattedComparisonWindow}
          </span>
          {comparisonLayout === "HORIZONTAL" && comparisonBadge}
        </div>
      )} */}

      {!formattedValue && (
        <span className={styles.noDataMessage}>
          <ExclamationIcon width={20} height={20} fill="currentColor" />
          Your Insights will appear here soon.
        </span>
      )}

      {formattedValue && (
        <span className={styles.macroAttribution}>
          Meta
          <span className={styles.metaIcon}>
            <img src={metaIcon} alt="Meta icon" />
          </span>
        </span>
      )}
    </div>
  );
}

function getFormattedComparisonWindow(comparisonWindow: IComparisonWindow) {
  switch (comparisonWindow) {
    case "DAY":
      return "yesterday";
    case "WEEK":
      return "previous week";
    case "MONTH":
      return "previous month";
    case "YEAR":
      return "previous year";
    case "ALL_TIME":
      return "till now";
  }
}
