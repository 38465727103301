// import { useState } from "react";
// import { Tabs } from "../../../../components/Tabs/Tabs";
import { Asset as IAsset } from "../../../../graphql/generated/schema";
import Slider from "../../../global/components/Slider";
import AssetDetails from "./AssetDetails";

interface IAssetDetailsSlideOver {
  asset: IAsset;
  isOpen: boolean;
  onClose: () => void;
}

export default function AssetDetailsSlideOver({
  asset,
  isOpen,
  onClose,
}: IAssetDetailsSlideOver) {
  // const [selectedTab, setSelectedTab] = useState("DETAILS");

  return (
    <Slider isOpen={isOpen} onClose={onClose} title={asset.name}>
      {/*
      <Tabs
        tabs={[
          {
            label: "Details",
            value: "DETAILS",
          },
          {
            label: "Use in Activities",
            value: "USE_IN_ACTIVITIES",
          },
          {
            label: "Use in Ad templates",
            value: "USE_IN_AD_TEMPLATES",
          },
        ]}
        selectedTab={selectedTab}
        onTabChange={(i) => setSelectedTab(i)}
      />
      <div>{selectedTab === "DETAILS" && <AssetDetails asset={asset} />}</div>
    */}
      <AssetDetails asset={asset} />
    </Slider>
  );
}
