import { CnameSteps } from "./CnameAndDomainSteps";
import { ReactComponent as Copy } from "@material-design-icons/svg/outlined/content_copy.svg";
import { Button } from "src/components/Button/Button";
import { IconButton } from "src/components/IconButton/IconButton";
import SimpleTable from "src/components/SimpleTable/SimpleTable";
import { EmailCname } from "./EmailCname";
import styles from "./EmailCnameAndDomain.module.scss";
import { toast } from "react-hot-toast";

interface Props {
  dnsRecords: {
    type: string;
    name: string;
    value: string;
  }[];
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      toast.success("Copied to Clipboard");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export function EmailCnameAndDomain({ dnsRecords }: Props) {
  const generateTableValues = (dnsRecords: Props["dnsRecords"]) => {
    return dnsRecords.map((record: any) => [
      <span>{record.type}</span>,
      <div className={styles.copyTile}>
        <pre>{record.name}</pre>
        <IconButton
          icon={<Copy />}
          style="plain"
          onClick={() => copyTextToClipboard(record.name)}
        />
      </div>,
      <div className={styles.copyTile}>
        <pre>{record.value}</pre>
        <IconButton
          icon={<Copy />}
          style="plain"
          onClick={() => copyTextToClipboard(record.value)}
        />
      </div>,
    ]);
  };
  return (
    <div className={styles.dnsRecordsContainer}>
      <h3 className={styles.sectionHeading}>Add CNAME Record to send Email</h3>
      <div className={styles.highlightContainer}>
        <p className={styles.tableTitle}>Add these records to get started</p>
        <SimpleTable
          headings={["Type", "Name", "Value"]}
          rows={generateTableValues(dnsRecords)}
          showHeadings={true}
        />
        <h4 className={styles.stepsTitle}>
          Follow these steps to change your MX Record
        </h4>
        <EmailCname steps={CnameSteps} />
        <div className={styles.btnWrapper}>
          <Button size="small">Verify MX Records</Button>
        </div>
      </div>
    </div>
  );
}
