import styles from "./AuthLayout.module.scss";
import { Link, Outlet } from "react-router-dom";
import MacroLogo from "../assets/macro-logo-primary-full.svg";

export function AuthLayout() {
  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <nav>
          <img src={MacroLogo} alt="macro_logo" className={styles.logo} />
        </nav>
      </header>
      <main className={styles.content}>
        <Outlet />
      </main>
      <footer className={styles.footer}>
        <p>
          <span>&copy;</span>
          <span>2023 Macro,Inc. All Rights Reserved.</span>
        </p>
        <p className={styles.footerLinks}>
          <Link to="/privacy" className={styles.footerLink}>
            Privacy Policy
          </Link>
          <div className={styles.circle} />
          <Link to="/terms-conditions" className={styles.footerLink}>
            Terms and Conditions
          </Link>
        </p>
      </footer>
    </div>
  );
}
