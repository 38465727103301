import { Link } from "react-router-dom";
import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import { AudienceTitleWithIcon } from "src/modules/global/components/AudienceTitleWIthIcon";
import { OverviewTable } from "src/modules/global/components/OverviewTable";
import { AudienceTabs, itemsPerPage } from "../pages/AudienceOverviewPage";

interface IAudienceOverviewTableBasedOnView {
  selectedTab: AudienceTabs;
  setSelectedTab: (i: AudienceTabs) => void;
  audiencesLoading: boolean;
  audienceSetsLoading: boolean;
  audiences: Audience[];
  audienceSets: MetaAudienceSet[];
}
export function AudienceOverviewTableBasedOnView({
  selectedTab,
  setSelectedTab,
  audiencesLoading,
  audienceSetsLoading,
  audiences,
  audienceSets,
}: IAudienceOverviewTableBasedOnView) {
  const audienceTableRows =
    selectedTab === "AUDIENCE"
      ? audiences.map((v) => [
          <Link to={`/audiences/${v.id}`}>
            <AudienceTitleWithIcon audience={v} />
          </Link>,
          "",
          v?.activitiesCount,
        ])
      : audienceSets.map((v) => [
          <Link to={`/audiences-sets`}>
            <AudienceTitleWithIcon audience={v as MetaAudienceSet} />
          </Link>,
          "",
          v.activitiesCount,
        ]);

  const audienceTableHeadings =
    selectedTab === "AUDIENCE"
      ? ["Audiences", "Status", "Activity"]
      : ["Audience Sets", "Status", "Activity"];

  const OverviewTableWithTypes = OverviewTable<AudienceTabs>;

  return (
    <OverviewTableWithTypes
      tabsList={[
        {
          label: "Audiences",
          value: "AUDIENCE",
        },
        {
          label: "Audience Sets",
          value: "AUDIENCE_SET",
        },
      ]}
      title={"Recently added audiences"}
      rows={audienceTableRows}
      headings={audienceTableHeadings}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      loading={audiencesLoading || audienceSetsLoading}
      itemsPerPage={itemsPerPage}
    />
  );
}
