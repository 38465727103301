import "./styles/core.css";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://01d26d82169cb3a2e59c0cbaa7ce7834@o1152986.ingest.sentry.io/4506693247696896",
    environment: process.env.REACT_APP_ENV,
    integrations: [
      // new Sentry.Feedback({
      //   // Additional SDK configuration goes in here, for example:
      //   colorScheme: "light",
      // }),
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
