export async function getImageDimensions(file: File) {
  let img = new Image();
  const objectUrl = URL.createObjectURL(file);
  img.src = objectUrl;
  await img.decode();
  let width = img.width;
  let height = img.height;
  URL.revokeObjectURL(objectUrl);
  return {
    width,
    height,
  };
}
