import { CampaignInput, FormSteps } from "../misc/createCampaignTypes";
import { Disclosure } from "@headlessui/react";
import { ReactComponent as KeyboardArrowDown } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { ReviewActivityFields } from "./ReviewActivityFields";
import classNames from "classnames";
import styles from "./ActivityListFields.module.scss";
import { useFormikContext } from "formik";
import { getValidActivities } from "../functions/getValidActivities";

interface Props {
  setActiveFormStep: (i: FormSteps) => void;
}

export function ActivityListFields({ setActiveFormStep }: Props) {
  const { values } = useFormikContext<CampaignInput>();

  const validActivities = getValidActivities(values?.Activities);
  return (
    <ul className={styles.activityList}>
      {validActivities.map((activity, activityIndex: number) => (
        <Disclosure
          // open the first one by default
          defaultOpen={values.selectedActivityIndex === activityIndex}
          as="li"
          className={styles.activityItem}
        >
          <Disclosure.Button className={styles.disclosureTrigger}>
            {({ open }) => (
              <>
                <div className={styles.triggerCopy}>
                  <span
                    className={classNames(styles.activityName, {
                      [styles.activeActivityName]: open,
                    })}
                  >
                    {activity.activityName}
                  </span>
                </div>
                <div
                  className={classNames(styles.triggerArrow, {
                    [styles.activeTriggerArrow]: open,
                  })}
                >
                  <KeyboardArrowDown width={24} height={24} />
                </div>
              </>
            )}
          </Disclosure.Button>
          <Disclosure.Panel className={styles.disclosurePanel}>
            <ReviewActivityFields
              setActiveFormStep={setActiveFormStep}
              activityIndex={activityIndex}
            />
          </Disclosure.Panel>
        </Disclosure>
      ))}
    </ul>
  );
}
