import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import styles from "./AdTemplateCreatePage.module.scss";
import {
  useMetaIntegrationDefaultSettingsQuery,
  useWhatsappTemplateCreateMutation,
  WhatsappTemplateCategory,
} from "../../../graphql/generated/schema";
import { useNavigate } from "react-router-dom";
import { WhatsappMessageTemplateForm } from "../components/MessageTemplateCreateForm";
import { FormValues } from "../misc/whatsappTemplateCreateTypes";
import { WhatsappMessageTemplatePreviewSection } from "../components/WhatsappMessageTemplatePreviewSection";
import { messageTemplateCreateSchema } from "../misc/messageTemplateCreateSchema";

const initialValues: FormValues = {
  name: "",
  body: {
    text: "",
    variables: [],
  },
  header: { asset: null, format: "NONE", text: "", variables: [] },
  category: "MARKETING" as WhatsappTemplateCategory,
  footer: "",
  cta: {
    type: "ACTION",
    quickReplies: [""],
    action: {
      label: "",
      type: "URL",
      link: "",
    },
  },
};

export default function WhatsappMessageTemplateCreatePage() {
  const navigate = useNavigate();
  const { data } = useMetaIntegrationDefaultSettingsQuery();
  const [whatsappTemplateCreateFn] = useWhatsappTemplateCreateMutation();

  return (
    <Formik
      enableReinitialize
      validationSchema={messageTemplateCreateSchema}
      initialValues={initialValues as FormValues}
      onSubmit={async (values: typeof initialValues) => {
        await whatsappTemplateCreateFn({
          variables: {
            input: {
              body: values.body,
              ...(values.cta.type === "ACTION" && {
                buttons: [
                  {
                    type: values.cta.action.type,
                    url: values.cta.action.link,
                    text: values.cta.action.label,
                  },
                ],
              }),
              ...(values.cta.type === "QUICK_REPLIES" && {
                buttons: values.cta.quickReplies.map((reply) => ({
                  text: reply,
                  type: "QUICK_REPLY",
                })),
              }),
              language: "en_GB",
              category: values.category,
              name: values.name,
              ...(values.header.format === "TEXT" && {
                header: {
                  format: values.header.format,
                  text: values.header.text,
                  variables: values.header.variables,
                },
              }),
              ...(values.header.format === "MEDIA" && {
                header: {
                  format:
                    values.header.asset.contentType === "IMAGE"
                      ? "IMAGE"
                      : "VIDEO",
                  assetUrl: values.header.asset.uri,
                  variables: [],
                  text: "",
                },
              }),
              footer: {
                text: values.footer,
              },
            },
          },
          refetchQueries: ["whatsappTemplates"],
          awaitRefetchQueries: true,
        });
        toast.success("Successfully created whatsapp template");
        navigate("/library/ad-library");
      }}
    >
      <Form>
        <div className={styles.layout}>
          <div className={styles.layoutForm}>
            <WhatsappMessageTemplateForm />
          </div>
          <div className={styles.layoutPreview}>
            <WhatsappMessageTemplatePreviewSection
              metaPageName={
                data?.metaIntegrationDefaultSettings?.MetaPage?.name
              }
              profilePicture={
                data?.metaIntegrationDefaultSettings?.MetaPage?.Business
                  ?.profilePicture
              }
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
}
