import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useOrganizationWithClientCreateMutation } from "src/graphql/generated/schema";
import { ReactComponent as AddBusiness } from "@material-design-icons/svg/outlined/add_business.svg";
import AuthContext from "src/stores/AuthContext";
import { Button } from "src/components/Button/Button";
import Cookies from "js-cookie";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { OnboardingLayout } from "../components/OnboardingLayout";
import styles from "./AboutBusiness.module.scss";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Select from "src/components/Select/Select";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import onboardingStep2 from "../assets/onBoardingStep2.png";
import { FormikError } from "src/components/FormikError/FormikError";

const OnBoardingBusinessSchema = Yup.object().shape({
  businessName: Yup.string().trim().min(3).required("Enter your businessName"),
  businessEmail: Yup.string().trim().email("Enter valid Email"),
  country: Yup.string().trim().min(1).required("  country is required"),
  city: Yup.string().trim().min(2).required("city is required"),
  address: Yup.string().trim().min(3).required("address is required"),
  zipCode: Yup.string().trim().required("zipCode is required"),
  gstNumber: Yup.string(),
  state: Yup.string().required("state is required"),
});

type FormValues = Yup.InferType<typeof OnBoardingBusinessSchema>;

export function AboutBusiness() {
  const navigate = useNavigate();
  const initialValues = {
    businessName: "",
    businessEmail: "",
    country: "",
    city: "",
    state: "",
    address: "",
    zipCode: "",
    gstNumber: "",
  };
  const { setActiveClient } = useContext(AuthContext);

  const [organizationWithClientCreateFunc, { loading }] =
    useOrganizationWithClientCreateMutation();

  const handleSubmit = async (values: FormValues) => {
    const { data } = await organizationWithClientCreateFunc({
      variables: {
        input: {
          address: values.address,
          city: values.city,
          country: values.country,
          state: values.state,
          zipCode: values.zipCode,
          gstNumber: values.gstNumber,
          name: values.businessName,
          client: {
            name: values.businessName,
          },
        },
      },
    });

    const shopifyIntegrationCookie = localStorage.getItem("shopifyIntegration");

    if (shopifyIntegrationCookie) {
      setActiveClient(
        data.organizationWithClientCreate.Clients[0],
        "/settings/integrations/shopify",
      );
    } else {
      setActiveClient(
        data.organizationWithClientCreate.Clients[0],
        "/onboarding/pricing",
      );
    }
  };

  return (
    <OnboardingLayout color="#F2FAFF" imageUrl={onboardingStep2}>
      <div className={styles.onboardingFormBusiness}>
        <p className={styles.stepContainer}>2/3</p>
        <p className={styles.formTitle}>Tell us about your business</p>
        <Formik
          initialValues={initialValues}
          validationSchema={OnBoardingBusinessSchema}
          onSubmit={handleSubmit}
        >
          {({
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
            submitCount,
          }) => (
            <Form>
              <div className={styles.fieldWrapper}>
                <InputFormik
                  name="businessName"
                  placeholder="Business Name"
                  type="text"
                  icon={AddBusiness}
                  showError={true}
                />
                {/* <InputFormik
                type="email"
                name="businessEmail"
                placeholder="Your Business Email (if any)"
                icon={<Email fill="#5C5F62" />}
              /> */}
                {/* <div className={styles.phoneWrapper}>
                <div className={styles.country}>
                  <CountryFlag
                    countryCode={"IN"}
                    rounded={false}
                    showCountryCode={false}
                  />
                  <span>+91</span>
                </div>
                <InputFormik name="phone" placeholder="Your Phone No." />
              </div> */}
                <Select
                  options={[
                    { label: "Select Country", value: "" },
                    { label: "India", value: "IN" },
                    { label: "United States of America", value: "US" },
                    { label: "United Kingdom", value: "UK" },
                    { label: "Pakistan", value: "PK" },
                  ]}
                  value={values.country}
                  name="country"
                  onChange={(value) => {
                    setFieldTouched("country");
                    setFieldValue("country", value);
                  }}
                  placeholder="Country"
                />
                <div className={styles.flexRow}>
                  <InputFormik
                    name={"state"}
                    placeholder="State/Province/Region"
                    showError={true}
                  />
                  <InputFormik
                    name={"city"}
                    placeholder="City"
                    showError={true}
                  />
                </div>
                <MultilineInput
                  placeholder="Company's address"
                  name="address"
                  value={values.address}
                  onChange={(value) => {
                    setFieldValue("address", value);
                  }}
                  label=""
                />
                <FormikError
                  formikError={errors}
                  touched={touched}
                  submitCount={submitCount}
                  fieldName={"address"}
                />
                <InputFormik
                  placeholder="Zip Code"
                  name="zipCode"
                  autoComplete="zipCode"
                  showError={true}
                />
                <InputFormik
                  placeholder="GSTIN/TAX ID/VAT (optional)"
                  name="gstNumber"
                  showError={true}
                />
              </div>

              <Button
                loading={loading}
                type="submit"
                width="full"
                className={styles.formBtn}
              >
                Next
              </Button>
            </Form>
          )}
        </Formik>
        <div className={styles.footer}>
          <Link to="/onboarding/about-you" className={styles.back}>
            {/* <ArrowBack height={10} width={10} /> */}
            <span>Back</span>
          </Link>
          {/* <Link to="/" className={styles.doLater}>
            I'll do this later
          </Link> */}
        </div>
      </div>
    </OnboardingLayout>
  );
}
