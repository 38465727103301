import { Label } from "src/components/Label/Label";
import styles from "./TextMessageObjectDetails.module.scss";
import Select from "src/components/Select/Select";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import ComboBox from "src/components/ComboBox/ComboBox";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import { useTextMessageTemplatesQuery } from "src/graphql/generated/schema";

interface ITextMessageObjectDetails {
  selectedActivity: any;
  selectedActivityKey: string;
  //   setFieldValue: (key: string, value: any, shouldValidate?: boolean) => void;
}
export function TextMessageObjectDetails({
  selectedActivity,
  selectedActivityKey,
}: //   setFieldValue,
ITextMessageObjectDetails) {
  const { data, loading: templatesLoading } = useTextMessageTemplatesQuery();
  const templates =
    data && data.textMessageTemplates
      ? data.textMessageTemplates.edges.map(({ node }) => ({
          label: node.name,
          value: node.id,
          message: node.message,
        }))
      : [];

  return (
    <div className={styles.campaign}>
      <div className={styles.section}>
        <span className={styles.sectionTitle}>Campaign Details</span>
        <hr />
      </div>
      <div className={styles.campaignDetails}>
        <Label label="Send From*">
          <InputFormik
            name={`${selectedActivityKey}.TextMessageObject.sendFrom`}
          />
        </Label>
        <Label label={"Select Template"}>
          <Select
            value={
              selectedActivity?.TextMessageObject?.textMessageTemplateId || ""
            }
            onChange={() => {}}
            placeholder={templatesLoading ? "Loading" : ""}
            options={templates}
            disabled={true}
          />
        </Label>
      </div>
    </div>
  );
}
