import { Form, Formik } from "formik";
import styles from "./MagicSignup.module.scss";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { ReactComponent as Mail } from "@material-design-icons/svg/outlined/mail.svg";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button/Button";
import * as Yup from "yup";

export const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
});

export function MagicSignup() {
  return (
    <div className={styles.form}>
      <h2 className={styles.formHeading}>Signup for free</h2>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <Form>
          <div className={styles.input}>
            <InputFormik
              name="email"
              placeholder="Enter your email"
              icon={Mail}
              type="email"
            />
          </div>
          <Button width="full">Signup with Password</Button>
          <p className={styles.magicSignup}>
            <Link to="/auth/signup" className={styles.magicLink}>
              Manual Signup Instead
            </Link>
          </p>
          <p className={styles.login}>
            <span>
              Already have account?
              <Link to="/auth/login" className={styles.loginLink}>
                Login
              </Link>
            </span>
          </p>
        </Form>
      </Formik>
    </div>
  );
}
