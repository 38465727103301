import { StatFilterOptions } from "./StatFilterOptions";
import { useGrowAggregatedStats } from "./useGrowAggregatedStats";

/**
 * returns aggregated stats for campaign with comparison values
 */
export function useActivityAggregatedStats(
  activityId: string,
  { dateFrom, dateTo }: StatFilterOptions,
  shouldShowComparisonValues: boolean = true,
) {
  const growAggregatedStats = useGrowAggregatedStats({
    others: { activityId, insightType: "ACTIVITY_INSIGHT" },
    dateFrom,
    shouldShowComparisonValues,
    dateTo,
  });

  return growAggregatedStats;
}
