import { useEffect } from "react";
import {
  AudiencesSortByField,
  useAudienceAudienceSetIndexesLazyQuery,
} from "src/graphql/generated/schema";
import { AudienceSelectorItem } from "./AudienceSelectorItem";
import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "src/modules/global/misc/dateFilterUtils";
import { Button } from "src/components/Button/Button";
import UserAddIcon from "../assets/userAddIcon.svg";
import createAudienceImage from "../assets/createAudiences.svg";
import noResultAudiences from "../assets/noResultAudiences.svg";

import styles from "./AudienceSelectorLoader.module.scss";

interface IAudienceSelectorLoader {
  adAccountId: string;
  selectedFilters: {
    platform: "META";
    searchQuery: string;
    take: number;
    audienceCategoryId: string;
    date: DateFilter;
  };
  selectedSortBy: {
    direction: "DESC" | "ASC";
    field: AudiencesSortByField;
  };
}

export function AudienceSelectorLoader({
  adAccountId,
  selectedFilters,
  selectedSortBy,
}: IAudienceSelectorLoader) {
  const debouncedSearchQuery = useDebouncedVariable(
    selectedFilters.searchQuery,
  );
  const [audienceSetIndexesFn, { data, loading, called, error, fetchMore }] =
    useAudienceAudienceSetIndexesLazyQuery();
  const areFiltersApplied =
    debouncedSearchQuery !== "" ||
    selectedFilters.audienceCategoryId !== null ||
    selectedFilters.date !== "ALL_TIME";

  useEffect(() => {
    audienceSetIndexesFn({
      variables: {
        sortBy: {
          direction: selectedSortBy.direction,
          field: selectedSortBy.field,
        },
        filter: {
          name: debouncedSearchQuery,
          platform: selectedFilters.platform,
          adAccountId: adAccountId,
          take: selectedFilters.take,
          audienceCategoryId: selectedFilters.audienceCategoryId,
          createdAt: getDateFilterFromFilterEnum(selectedFilters.date),
        },
      },
    });
  }, [
    audienceSetIndexesFn,
    selectedSortBy.direction,
    selectedSortBy.field,
    debouncedSearchQuery,
    selectedFilters.platform,
    selectedFilters.audienceCategoryId,
    selectedFilters.date,
    adAccountId,
    selectedFilters.take,
  ]);

  if (loading || !called) {
    return <Spinner height={300} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  async function fetchMoreAudiences() {
    await fetchMore({
      variables: {
        filter: {
          cursor: data.audienceAudienceSetIndexes.pageInfo.endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges =
          fetchMoreResult?.audienceAudienceSetIndexes?.edges ?? [];
        const oldEdges =
          previousResult?.audienceAudienceSetIndexes?.edges ?? [];

        return {
          audienceAudienceSetIndexes: {
            ...fetchMoreResult.audienceAudienceSetIndexes,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  // this should be above the generic handler of no result
  // because that one will match both of these cases
  if (!data.audienceAudienceSetIndexes.edges.length && areFiltersApplied) {
    return (
      <div className={styles.noResultScreen}>
        <img
          src={noResultAudiences}
          width={48}
          height={48}
          alt="no result audience"
        />
        <span className={styles.noResultText}>
          No search results found. Try changing the filters or use a different
          keyword.
        </span>
      </div>
    );
  }

  if (!data.audienceAudienceSetIndexes.edges.length) {
    return (
      <div className={styles.emptyScreen}>
        <img
          src={createAudienceImage}
          width={48}
          height={48}
          alt="create audience"
        />
        <span className={styles.emptyScreenText}>
          Oops! It looks like you don't have an audience for your campaign
          launch.
        </span>
        <div className={styles.buttonWrapper}>
          <Button size="micro" to="/audiences/new">
            <div className={styles.createAudienceBtnContent}>
              <img
                src={UserAddIcon}
                width={16}
                height={16}
                role="none"
                alt="user add icon"
              />
              Create Audiences
            </div>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={data.audienceAudienceSetIndexes.edges.length}
      scrollableTarget="scrollTargetForInfiniteScroll"
      next={fetchMoreAudiences}
      hasMore={data.audienceAudienceSetIndexes.pageInfo.hasNextPage}
      loader={<Spinner height={200} />}
    >
      {data.audienceAudienceSetIndexes.edges.map((audience) => (
        <AudienceSelectorItem audience={audience} />
      ))}
    </InfiniteScroll>
  );
}
