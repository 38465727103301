import onboarding3 from "../assets/onboarding3.png";
import {
  BillingPlanCycleEnum,
  CustomerCountRange,
  MonthlyAdSpendRange,
  useCreateBillingMutation,
} from "src/graphql/generated/schema";
import styles from "./OnboardingPage3.module.scss";
import { OnboardingLayout2 } from "../components/OnboardingLayout2";
import classNames from "classnames";
import { useState } from "react";
import { formatPrice } from "src/lib/formatPrice";
import GET_BILLING from "src/modules/billing/api/getBilling";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

export const adSpendOptions = [
  {
    label: "1 - 1,00,000",
    value: "AD_SPENT_1_TO_10000",
    price: {
      standardMonthlyAmount: 9_000_00,
      standardAnnuallyAmount: 9_000_00 * 10,
      premiumMonthlyAmount: 15_000_00,
      premiumAnnuallyAmount: 15_000_00 * 10,
      currencyCode: "INR",
    },
  },
  {
    label: "1,00,000 - 5,00,000",
    value: "AD_SPENT_10000_TO_100000",
    price: {
      standardMonthlyAmount: 18_000_00,
      standardAnnuallyAmount: 18_000_00 * 10,
      premiumMonthlyAmount: 25_000_00,
      premiumAnnuallyAmount: 25_000_00 * 10,
      currencyCode: "INR",
    },
  },
  {
    label: "5,00,000 - 10,00,000",
    value: "AD_SPENT_100000_TO_500000",
    price: {
      standardMonthlyAmount: 30_000_00,
      standardAnnuallyAmount: 30_000_00 * 10,
      premiumMonthlyAmount: 50_000_00,
      premiumAnnuallyAmount: 50_000_00 * 10,
      currencyCode: "INR",
    },
  },
];

export const customerCountOptions = [
  {
    label: "1 - 5000",
    value: "CUSTOMER_COUNT_1_TO_2500",
    price: {
      standardMonthlyAmount: 5_000_00,
      standardAnnuallyAmount: 5_000_00 * 10,
      premiumMonthlyAmount: 500000,
      premiumAnnuallyAmount: 500000 * 10,
      currencyCode: "INR",
    },
  },
  {
    label: "5000 - 25,000",
    value: "CUSTOMER_COUNT_2500_TO_10000",
    price: {
      standardMonthlyAmount: 15_000_00,
      standardAnnuallyAmount: 15_000_00 * 10,
      premiumMonthlyAmount: 1500000,
      premiumAnnuallyAmount: 1500000 * 10,
      currencyCode: "INR",
    },
  },
  {
    label: "25,000 - 50,000",
    value: "CUSTOMER_COUNT_10000_TO_50000",
    price: {
      standardMonthlyAmount: 2500000,
      standardAnnuallyAmount: 2500000 * 10,
      premiumMonthlyAmount: 2500000,
      premiumAnnuallyAmount: 2500000 * 10,
      currencyCode: "INR",
    },
  },
];

export function OnboardingPage3() {
  const [selectedTab, setSelectedTab] = useState<"GROW" | "ENGAGE">("GROW");
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingPlanCycleEnum>("MONTHLY");
  const [selectedCustomerCount, setSelectedCustomerCount] =
    useState<CustomerCountRange>("CUSTOMER_COUNT_1_TO_2500");
  const [selectedAdSpend, setSelectedAdSpend] = useState<MonthlyAdSpendRange>(
    "AD_SPENT_1_TO_10000",
  );
  const [billingCreate, { loading }] = useCreateBillingMutation({
    refetchQueries: [{ query: GET_BILLING }],
  });
  const [processingPlanType, setProcessingPlanType] = useState<
    "STANDARD" | "PREMIUM"
  >("STANDARD");

  const isGrowTabSelected = selectedTab === "GROW";
  const selectedCustomerCountValues = customerCountOptions.find(
    (c) => c.value === selectedCustomerCount,
  );
  const selectedAdSpendValues = adSpendOptions.find(
    (c) => c.value === selectedAdSpend,
  );

  async function handlePlanPurchase(planType: "STANDARD" | "PREMIUM") {
    setProcessingPlanType(planType);
    const shopifyIntegration = localStorage.getItem("shopifyIntegration");
    const { tokenId, shopifyShopName } = shopifyIntegration
      ? JSON.parse(shopifyIntegration)
      : { tokenId: "", shopifyShopName: "" };
    const isEngageTabSelected = !isGrowTabSelected;

    const payload = await billingCreate({
      variables: {
        input: {
          paymentProvider: shopifyIntegration ? "SHOPIFY" : "STRIPE",
          shopifyTokenId: tokenId,
          cycle: selectedBillingCycle,
          ...(isEngageTabSelected && {
            customerCountRange: selectedCustomerCount,
          }),
          ...(!isEngageTabSelected && {
            monthlyAdSpendRange: selectedAdSpend,
          }),
          [isEngageTabSelected ? "engagePlanType" : "growPlanType"]: planType,
        },
      },
    });

    window.location.href = payload.data.billingCreate.paymentIntent;
  }

  return (
    <OnboardingLayout2 sideImage={onboarding3}>
      <div className={styles.card}>
        <div className={styles.tabs}>
          {tabOptions.map((tabOption) => (
            <button
              className={classNames(styles.tabOption, {
                [styles.activeTabOption]: selectedTab === tabOption.value,
              })}
              onClick={() => {
                setSelectedTab(tabOption.value);
              }}
              type="button"
            >
              {tabOption.label}
            </button>
          ))}
        </div>

        <div className={styles.estimationForm}>
          <span className={styles.estimatePriceText}>ESTIMATE YOUR PRICE</span>
          <span className={styles.howManyProfilesText}>
            {isGrowTabSelected
              ? "What is your monthly ad spend?"
              : "How many active profiles do you have?"}
          </span>
          {isGrowTabSelected ? (
            <SelectV2
              // why key? so it resets internal state (if any) of select
              // when the tabs change
              key="grow"
              value={selectedAdSpend}
              onChange={setSelectedAdSpend}
              options={adSpendOptions}
            />
          ) : (
            <SelectV2
              key="engage"
              value={selectedCustomerCount}
              onChange={setSelectedCustomerCount}
              options={customerCountOptions}
            />
          )}
        </div>

        <div className={styles.priceCardList}>
          <PriceCard
            name="Standard"
            description="For Individual and Small Business"
            selectedBillingCycle={selectedBillingCycle}
            setSelectedBillingCycle={setSelectedBillingCycle}
            annualPrice={
              isGrowTabSelected
                ? selectedAdSpendValues.price.standardAnnuallyAmount
                : selectedCustomerCountValues.price.standardAnnuallyAmount
            }
            price={
              isGrowTabSelected
                ? selectedAdSpendValues.price.standardMonthlyAmount
                : selectedCustomerCountValues.price.standardMonthlyAmount
            }
            loading={loading && processingPlanType === "STANDARD"}
            onClick={() => handlePlanPurchase("STANDARD")}
          />
          <PriceCard
            name="Premium"
            description="For Medium to Large Businesses"
            selectedBillingCycle={selectedBillingCycle}
            setSelectedBillingCycle={setSelectedBillingCycle}
            annualPrice={
              isGrowTabSelected
                ? selectedAdSpendValues.price.premiumAnnuallyAmount
                : selectedCustomerCountValues.price.premiumAnnuallyAmount
            }
            price={
              isGrowTabSelected
                ? selectedAdSpendValues.price.premiumMonthlyAmount
                : selectedCustomerCountValues.price.premiumMonthlyAmount
            }
            loading={loading && processingPlanType === "PREMIUM"}
            onClick={() => handlePlanPurchase("PREMIUM")}
          />
        </div>

        <div className={styles.tocAndNavigation}>
          <ButtonV2 variant="plain">Logout</ButtonV2>
        </div>
      </div>
    </OnboardingLayout2>
  );
}

function PriceCard({
  name,
  description,
  selectedBillingCycle,
  setSelectedBillingCycle,
  price,
  onClick,
  annualPrice,
  loading,
}) {
  const formattedPrice =
    selectedBillingCycle === "MONTHLY"
      ? // amount is in paisa
        formatPrice(price / 100)
      : formatPrice(annualPrice / 100);

  const priceFormattedDuration =
    selectedBillingCycle === "MONTHLY" ? `/mo` : `/yr`;

  return (
    <div className={styles.priceCard}>
      <span className={styles.priceName}>{name}</span>
      <span className={styles.priceDescription}>{description}</span>
      <div className={styles.billingTabsWrapper}>
        <div className={styles.billingPeriodTabs}>
          <button
            type="button"
            onClick={() => {
              setSelectedBillingCycle("MONTHLY");
            }}
            className={classNames(styles.billingPeriodTabOption, {
              [styles.activeBillingPeriodTabOption]:
                selectedBillingCycle === "MONTHLY",
            })}
          >
            Monthly
          </button>
          <button
            type="button"
            onClick={() => {
              setSelectedBillingCycle("ANNUAL");
            }}
            className={classNames(styles.billingPeriodTabOption, {
              [styles.activeBillingPeriodTabOption]:
                selectedBillingCycle === "ANNUAL",
            })}
          >
            Annually <span className={styles.discountText}> 20% off</span>
          </button>
        </div>
      </div>

      <span>
        <span className={styles.price}>{formattedPrice}</span>
        <span className={styles.priceDuration}>{priceFormattedDuration}</span>
      </span>
      <span className={styles.overchargeLimit}>
        Overages apply if customer sent limit is exceeded
      </span>

      <ButtonV2 loading={loading} onClick={onClick} isFullWidth>
        <span className={styles.buttonContent}>Choose {name}</span>
      </ButtonV2>
    </div>
  );
}

const tabOptions = [
  {
    label: "Grow",
    value: "GROW" as const,
  },
  {
    label: "Engage",
    value: "ENGAGE" as const,
  },
];
