import { useMemo, useState } from "react";
import styles from "./NextStepCard.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import classNames from "classnames";
import integrateMeta from "../../assets/integrateMeta.png";
import createAudience from "../../assets/AudienceDashboard.png";
import launchActivityDashboard from "../../assets/LaunchActivityDashboard.png";
import newAdsDashboard from "../../assets/moreAdsDashboard.png";
import metaOutlineIcon from "../../assets/metaOutlineIcon.svg";
import shopifyOutlineIcon from "../../assets/shopifyOutlineIcon.svg";
import websiteOutlineIcon from "../../assets/websiteOutlineIcon.svg";
import {
  useActivitiesCountQuery,
  useAudienceSetsCountQuery,
  useMetaActivityAdTemplatesQuery,
  useMetaIntegrationStatusQuery,
} from "src/graphql/generated/schema";
import { ReactComponent as CheckSmall } from "@material-symbols/svg-600/rounded/check_small.svg";
import { Spinner } from "src/components/Spinner/Spinner";

interface INextStepCard {
  setIsOnboarded: (i: boolean) => void;
}

export function NextStepCard({ setIsOnboarded }: INextStepCard) {
  const { data: audienceData, loading: audienceCountLoading } =
    useAudienceSetsCountQuery();
  const { data: integrationData, loading: metaIntegrationStatusLoading } =
    useMetaIntegrationStatusQuery();
  const { data: adData, loading: adTemplateLoading } =
    useMetaActivityAdTemplatesQuery({
      variables: {
        filter: {
          createdAt: null,
        },
      },
    });
  const { data: activitiesCount, loading: activitiesLoading } =
    useActivitiesCountQuery({
      variables: {
        filters: {
          statuses: ["ACTIVE", "PAUSED"],
        },
      },
    });

  const activitiesTotalCount = activitiesCount
    ? activitiesCount.activitiesCountByStatus?.reduce(
        (acc, item) => acc + item.value,
        0,
      )
    : 0;

  const [activeStepId, setActiveStepId] = useState("INTEGRATE_PLATFORM");

  const steps = useMemo(
    () => [
      {
        title: "Integrate Platform",
        id: "INTEGRATE_PLATFORM",
        image: integrateMeta,
        isCompleted:
          integrationData?.metaIntegrationStatus === "ACTIVE" ? true : false,
        gradient: `linear-gradient(83.59deg, #FFFFFF 17.55%, #EBF0FF 123.57%)`,
        content: (
          <StepContent
            title="Integrate the required platforms"
            platforms={[
              {
                icon: metaOutlineIcon,
                iconAlt: "meta Outline Icon",
                label: "Meta Platform",
              },

              {
                icon: shopifyOutlineIcon,
                iconAlt: "shopify Outline Icon",
                label: "Shopify Store",
              },

              {
                icon: websiteOutlineIcon,
                iconAlt: "website Outline Icon",
                label: "E-commerce Website",
              },
            ]}
            description="By integrating platform in your macro, you will be able to run Facebook Ads, send emails, send messages and lot more"
            buttonLink={"/settings/integrations/meta"}
            buttonLabel="Integrate Meta"
          />
        ),
      },
      {
        title: "Create Audience",
        id: "CREATE_AUDIENCE",
        image: createAudience,
        isCompleted: audienceData?.audienceSetsCount?.count > 0 ? true : false,
        gradient: `linear-gradient(87.29deg, #FFFFFF 21.35%, #FFF2FC 103.07%)`,
        content: (
          <StepContent
            title="Elevate Your Sales Funnel with Audience"
            description="Crafted by market experts, these presets span the entire sales funnel, ensuring your campaigns hit the mark."
            buttonLink={"/audiences/new"}
            buttonLabel="Create Audience"
          />
        ),
      },
      {
        title: "Create New Ads",
        id: "CREATE_NEW_ADS",
        image: newAdsDashboard,
        isCompleted:
          adData?.metaActivityAdTemplates.edges.length > 0 ? true : false,
        gradient: `linear-gradient(85.01deg, #FFFFFF 32.28%, #FFF2E2 98.96%)`,
        content: (
          <StepContent
            title="Highly impactful ads with no hassle"
            description="Transform your Ads strategy with our powerful ad creation tools. Publish ads without any hassle and delay"
            buttonLink={"/library/ad-library/create"}
            buttonLabel="Create Ads"
          />
        ),
      },
      {
        title: "Launch Activity",
        id: "LAUNCH_ACTIVITY",
        image: launchActivityDashboard,
        isCompleted: activitiesTotalCount ? true : false,
        gradient: `linear-gradient(84.35deg, #FFFFFF 27.81%, #ECFCFF 98.93%)`,
        content: (
          <StepContent
            title="Streamlined marketing activity creation for maximum impact"
            description="Effortlessly create Standard Ads, Dynamic Ads, and Existing Post Ads with our user-friendly interface."
            buttonLink={"/campaigns/new"}
            buttonLabel="Create Activity"
          />
        ),
      },
    ],
    [audienceData, integrationData, adData, activitiesCount],
  );

  function markSteps() {
    const incompleteStep = steps.find((s) => !s.isCompleted);

    if (!incompleteStep) {
      // switch from onboarding screen if no step is pending
      setIsOnboarded(true);
    } else {
      if (incompleteStep.id !== activeStepId) {
        setActiveStepId(incompleteStep.id);
      }
    }
  }

  if (
    metaIntegrationStatusLoading ||
    activitiesLoading ||
    audienceCountLoading ||
    adTemplateLoading
  ) {
    return <Spinner height={"screen"} />;
  }

  markSteps();

  // find first incomplete step
  const activeStep = steps.find((s) => s.id === activeStepId);

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.listSection}>
          <div className={styles.followSteps}>Follow these steps</div>
          <div className={styles.list}>
            {steps.map((step, index) => (
              <button
                type="button"
                onClick={() => {
                  setActiveStepId(step.id);
                }}
                className={classNames(styles.listItem, {
                  [styles.listItemActive]: step.id === activeStepId,
                  [styles.listItemCompleted]: step.isCompleted,
                })}
              >
                {step.isCompleted ? (
                  <span className={styles.checkIconWrapper}>
                    <CheckSmall
                      fill="var(--color-white)"
                      width={12}
                      height={12}
                    />
                  </span>
                ) : (
                  <span className={styles.listItemCounter}>{index + 1}</span>
                )}
                <span>{step.title}</span>
              </button>
            ))}
          </div>
        </div>
        <div
          className={styles.contentSection}
          style={{
            backgroundImage: `${activeStep.gradient}`,
          }}
        >
          <div className={styles.content}>{activeStep.content}</div>
          <div className={styles.imageWrapper}>
            <img
              src={activeStep.image}
              width={416}
              height={264}
              alt="meta logo illustration"
            />
          </div>
        </div>

        {/* <button type="button" className={styles.collapseButton}>
          Collapse
        </button> */}
      </div>
    </div>
  );
}

function StepContent({
  title,
  description,
  platforms,
  buttonLabel,
  buttonLink,
}: {
  title: string;
  description: string;
  platforms?: { label: string; icon: string; iconAlt: string }[];
  buttonLabel: string;
  buttonLink: string;
}) {
  return (
    <div>
      <h2 className={styles.contentTitle}>{title}</h2>
      <p className={styles.contentDescription}>{description} </p>
      {platforms && (
        <span className={styles.platformList}>
          {platforms.map((platform) => (
            <span className={styles.platform}>
              <img
                src={platform.icon}
                alt={platform.iconAlt}
                width={16}
                height={16}
              />
              {platform.label}
            </span>
          ))}
        </span>
      )}
      <div className={styles.contentButtonWrapper}>
        <ButtonV2 to={buttonLink}>{buttonLabel}</ButtonV2>
      </div>
    </div>
  );
}
