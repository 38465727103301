interface fileDimension {
  height: number;
  width: number;
}

// TODO: use reader api instead of revokeURLObject
// because revokeURLObject is deprecated
export default function getHeightAndWidthFromDataUrl(
  url: string,
  contentType: "IMAGE" | "VIDEO"
): Promise<fileDimension> {
  if (contentType === "VIDEO") {
    return new Promise<fileDimension>((resolve) => {
      const video = document.createElement("video");
      video.src = url;
      video.load();
      video.onloadedmetadata = (_e) => {
        // Revoke when you don't need the url any more to release any reference
        URL.revokeObjectURL(url);
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
    });
  }
  if (contentType === "IMAGE") {
    return new Promise<fileDimension>((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        // Revoke when you don't need the url any more to release any reference
        URL.revokeObjectURL(url);
        resolve({
          height: img.height,
          width: img.width,
        });
      };
    });
  }
}
