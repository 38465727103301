import { PageInfoFragment } from "src/graphql/generated/schema";

export function showPaginationChecks({
  itemEdges,
  pageInfo,
  itemsPerPage,
}: {
  itemEdges: any[];
  pageInfo: PageInfoFragment;
  itemsPerPage: number;
}) {
  if (pageInfo.totalItems === 0) return false;
  return itemEdges.length > 0 && pageInfo.totalItems > itemsPerPage;
}
