import {
  MetaActivityAdLineItem,
  MetaAdTypeEnum,
} from "../../../graphql/generated/schema";

type AdTemplate = {
  adType: MetaAdTypeEnum;
  LineItems?: Pick<MetaActivityAdLineItem, "headline">[];
};

export function getAdTemplateType(adTemplate: AdTemplate) {
  if (adTemplate.adType === "CUSTOM_CREATIVE") {
    if (adTemplate.LineItems.length === 1) {
      return "Single Image / Video";
    }

    return "Carousel";
  }

  if (adTemplate.adType === "CATALOG") {
    return "Catalogue";
  }

  if (adTemplate.adType === "EXISTING_POST") {
    return "Existing Post";
  }

  return "";
}
