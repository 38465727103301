import styles from "./MetaIntegrationSingle.module.scss";
import { MetaIntegrationAdmin } from "./MetaIntegrationAdmin";
import { useState } from "react";
import MetaIntegrate from "../assets/metaIntegrateFooter.png";
import Alert from "src/components/Alert/Alert";
import {
  User,
  useMetaIntegrationDefaultSettingsQuery,
  useMetaUserQuery,
} from "src/graphql/generated/schema";
import { MetaIntegrationAccountDetailsSlider } from "./MetaIntegrationAccountDetailsSlider";
import { MetaIntegrationDefaultSettingForm } from "./MetaIntegrationDefaultSettingForm";
import { MetaAdAccountsList } from "./MetaAdAccountsList";
import { Tabs } from "src/components/Tabs/Tabs";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";

interface IMetaIntegrationSingle {
  me: User;
  revokeAccess: () => Promise<void>;
  revokeLoading: boolean;
}

const metaIntegrationTabs = [
  {
    label: "Ad Account",
    value: "AD_ACCOUNT",
  },
  {
    label: "Settings",
    value: "SETTING",
  },
];

type TAccountDetailSlider = {
  isOpen: boolean;
  id: string | null;
};

export function MetaIntegrationSingle({
  me,
  revokeAccess,
  revokeLoading,
}: IMetaIntegrationSingle) {
  const [showAlert, setShowAlert] = useState(false);
  const [selectedTab, setSelectedTab] = useState<"AD_ACCOUNT" | "SETTING">(
    "AD_ACCOUNT",
  );
  const { data, loading, error } = useMetaIntegrationDefaultSettingsQuery();
  const { data: metaUserData, loading: metaUserLoading } = useMetaUserQuery();

  const [showAccountDetailsSlider, setShowAccountDetailsSlider] =
    useState<TAccountDetailSlider>({ isOpen: false, id: null });

  if (loading || metaUserLoading) {
    return <Spinner height="screen" />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const user = metaUserData?.metaUser;

  return (
    <div>
      <div className={styles.content}>
        <MetaIntegrationAdmin
          user={user}
          revokeAccess={() => {
            setShowAlert(true);
          }}
        />
        <hr className={styles.line} />
        <div className={styles.wrapper}>
          <Tabs
            tabs={metaIntegrationTabs}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
          />
          {/* styles.wrapper has same padding as this section*/}
          <div className={styles.wrapper}>
            {selectedTab === "AD_ACCOUNT" ? (
              <MetaAdAccountsList
                setShowAdAccountDetails={setShowAccountDetailsSlider}
              />
            ) : (
              <MetaIntegrationDefaultSettingForm
                metaDefaultSettings={data.metaIntegrationDefaultSettings}
              />
            )}
          </div>
        </div>
        <div className={styles.learnContainer}>
          <div className={styles.learnMoreText}>
            <h3 className={styles.spendsHeading}>
              Learn how your spends affects your bills?
            </h3>
            <p className={styles.infoDescription}>
              Your monthly bill is calculated by the combined ad spend of active
              ad accounts if your ad spend exceeds the limit of your plan, we
              put your account in hold that means, you can see your existing
              campaigns or ads but you will be not able to launch new campaigns
              unless you clear your dues.
            </p>
            <p className={styles.boldText}>
              Here's how we calculate your bills.
            </p>
            <p className={styles.infoDescription}>
              If Account A and B spends 110K & 120K respectively but you choose
              plan up to 150K ad spends limit, you will see additional charge in
              your next bill.
            </p>
          </div>
          <img
            src={MetaIntegrate}
            alt="meta integrate"
            className={styles.footerImage}
          />
        </div>
      </div>
      <Alert
        isOpen={showAlert}
        title="Are you sure ?"
        onClose={() => setShowAlert(false)}
        primaryAction={{
          children: "Revoke Access",
          onClick: async () => {
            await revokeAccess();
          },
          color: "danger",
          size: "small",
          loading: revokeLoading,
          disabled: revokeLoading,
        }}
        secondaryAction={{
          children: "Cancel",
          onClick: () => setShowAlert(false),
          color: "subdued",
          size: "small",
          style: "outline",
          disabled: revokeLoading,
        }}
      >
        <p className={styles.alertContent}>
          You may no longer to add Meta Activity based on this Ad Account. Also
          your analytics may get affected.
        </p>
      </Alert>
      <MetaIntegrationAccountDetailsSlider
        user={user}
        isOpen={showAccountDetailsSlider?.isOpen}
        onClose={() =>
          setShowAccountDetailsSlider({
            isOpen: false,
            id: null,
          })
        }
        selectedAdAccountId={showAccountDetailsSlider?.id}
      />
    </div>
  );
}
