import * as Yup from "yup";
import {
  CustomersDocument,
  useCustomerCreateMutation,
  useCustomerTagsCreateMutation,
  useSegmentsLazyQuery,
} from "src/graphql/generated/schema";
import { Form, Formik } from "formik";
import { Label } from "src/components/Label/Label";
import Modal from "src/components/Modal/Modal";
import countries from "src/countries.json";
import styles from "./AddCustomerModal.module.scss";
import { ErrorHandler } from "src/components/ErrorHandler";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";
import SelectV2 from "src/components/SelectV2/SelectV2";

interface IAddCustomerModal {
  setShowAddCustomerModal: (i: boolean) => void;
  showAddCustomerModal: boolean;
}

const addCustomerSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  dob: Yup.object(),
  phone: Yup.string(),
  email: Yup.string().email().required("Email is required to create customer"),
  segments: Yup.array(),
  tags: Yup.array(Yup.object()),
  countryCode: Yup.string(),
  city: Yup.string(),
});

const countryCodeOptions = countries.map((country) => ({
  value: country["alpha-2"],
  label: country.name,
}));

export function AddCustomerModal({
  setShowAddCustomerModal,
  showAddCustomerModal,
}: IAddCustomerModal) {
  const [customerCreate, { loading: creatingCustomer }] =
    useCustomerCreateMutation();

  // const [selectedTags, setSelectedTags] = useState([]);
  const [addTagsFunc, { loading: addTagsLoading }] =
    useCustomerTagsCreateMutation();

  const [segmentsFn, { data, loading, error }] = useSegmentsLazyQuery();

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        dob: {},
        countryCode: "91",
        phone: "",
        phoneCountryCode: "91",
        email: "",
        city: "",
        segments: [],
        tags: [],
      }}
      validationSchema={addCustomerSchema}
      onSubmit={async (values, helpers) => {
        console.log("values", values);

        let country;

        if (values.countryCode) {
          country = countryCodeOptions.find(
            (v) => v.value === values.countryCode,
          ).label;
        }

        await customerCreate({
          variables: {
            input: {
              email: values.email,
              lastName: values.lastName,
              firstName: values.firstName,
              // Send both the phone and phoneCountryCode
              ...(values.phone && { phone: values.phone }),
              city: values.city,
              country: country,
              countryCode: values.countryCode,
              tags: values.tags.map((v) => v.label),
              segmentIds: values.segments,
              isEmailVerified: true,
            },
          },
          refetchQueries: [
            {
              query: CustomersDocument,
              variables: {
                input: {
                  take: 9,
                  filters: {},
                  cursor: null,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        setShowAddCustomerModal(false);
        helpers.resetForm();
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Modal
            isOpen={showAddCustomerModal}
            onClose={() => setShowAddCustomerModal(false)}
            maxWidth="38.4375rem"
            noContainer
            title="Add Customer"
            subtitle="You can directly add customers, add customer information "
          >
            <div className={styles.modalContent}>
              <div className={styles.contentWrapper}>
                <div className={styles.namesWrapper}>
                  <InputFormikV2
                    name={"firstName"}
                    label="First Name*"
                    placeholder="eg:John"
                  />
                  <InputFormikV2
                    name="lastName"
                    label="Last Name*"
                    placeholder="eg:Doe"
                  />
                </div>
                <InputFormikV2
                  name="email"
                  label="Email*"
                  placeholder="eg: someone@example.com"
                />
                <div className={styles.namesWrapper}>
                  {/* <Label label="Birth Date">
                    <div className={styles.namesWrapper}>
                      <InputFormik name="dob.date" placeholder="DD" />
                      /
                      <InputFormik name="dob.month" placeholder="MM" />
                      /
                      <InputFormik name="dob.year" placeholder="YYYY" />
                    </div>
                  </Label> */}
                  <PhoneNumberInput
                    defaultPhoneCode={values.phoneCountryCode}
                    value={values.phone}
                    countryCodeValue={values.phoneCountryCode}
                    countryCodeOnChange={(value) => {
                      setFieldValue("phoneCountryCode", value);
                    }}
                    onChange={(value) => {
                      setFieldValue("phone", value);
                    }}
                    name="phone"
                    label="Phone Number"
                    placeholder="eg: (+91) 0987 665 43321"
                  />
                </div>
                <div>
                  <Label label="Address">
                    <div className={styles.addressWrapper}>
                      <div className={styles.namesWrapper}>
                        <SelectV2
                          options={countryCodeOptions}
                          value={values.countryCode}
                          onChange={(value) => {
                            setFieldValue("countryCode", value);
                          }}
                          placeholder="Select Country"
                        />
                        <InputFormikV2 name="city" placeholder="City" />
                        {/* <InputFormik
                          name="state"
                          placeholder="State/Prov/Region"
                        />
                        <InputFormik name="postal" placeholder="Postal/Zip" /> */}
                      </div>
                    </div>
                  </Label>
                </div>
                <Label label="Add in Segment">
                  {/* TODO: handle pagination here */}
                  <ComboBoxV2
                    multiple
                    value={values.segments}
                    onChange={(v) => {
                      setFieldValue("segments", v);
                    }}
                    placeholder={
                      values?.segments?.length
                        ? `Selected segments: ${values?.segments?.length}`
                        : "Search to add"
                    }
                    loadOptions={async (query) => {
                      const payload = await segmentsFn({
                        variables: {
                          filter: {
                            take: 5,
                            name: query,
                          },
                        },
                      });

                      const options = payload.data.segments.edges.map((v) => ({
                        value: v.node.id,
                        label: v.node.name,
                      }));

                      return options;
                    }}
                  />

                  {/* <ComboTagMaker
                    multiple={true}
                    onChange={(value) => {
                      setSegmentQuery(value);
                    }}
                    selected={values.segments}
                    setSelected={(v) => {
                      setFieldValue("segments", v);
                    }}
                    options={segmentOptions}
                    showTags={true}
                  /> */}
                </Label>
                <Label label="Add in tags">
                  {/* <CustomerTagsComboBox
                    selectedTags={values.tags}
                    setSelectedTags={(v) => {
                      setFieldValue("tags", v);
                    }}
                    addTags={addTagsFunc}
                    addTagsLoading={addTagsLoading}
                  /> */}
                </Label>
              </div>
            </div>
            <div className={styles.footer}>
              <ButtonV2 variant="outline">Cancel</ButtonV2>
              <ButtonV2
                onClick={() => handleSubmit()}
                loading={creatingCustomer}
              >
                Add Contact
              </ButtonV2>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
