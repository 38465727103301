import styles from "./BillingInformation.module.scss";
import {
  BillingLatestCardQuery,
  GetBillingQuery,
  OrganizationAddress,
  User,
} from "src/graphql/generated/schema";
import format from "date-fns/format";
import { titleCase } from "../../../lib/titleCase";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { SectionHeading } from "../../global/components/SectionHeading";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ReactComponent as Plus } from "@material-symbols/svg-600/rounded/add.svg";

interface IBillingInformation {
  billing: GetBillingQuery["billing"];
  organization: {
    Address?: OrganizationAddress;
    Creator?: User;
    id: string;
    name: string;
    email: string;
  };
  billingCard: BillingLatestCardQuery["billingLatestCard"];
}

function getCardImage(brand: string) {
  switch (brand) {
    case "amex":
      return "/imagesAndIcons/amex.svg";
    case "mastercard":
      return "/imagesAndIcons/mastercard.svg";
    case "visa":
      return "/imagesAndIcons/visa.png";
    default:
      return "/imagesAndIcons/generic.svg";
  }
}

export function BillingInformation({
  billing,
  billingCard,
  organization,
}: IBillingInformation) {
  const billingInformationList = [
    {
      label: "Billing Cycle",
      value: (
        <div className={styles.billInfoWrapper}>
          {billing.startedAt && billing.renewsAt
            ? `${format(
                new Date(billing.startedAt),
                "dd MMM, yyyy",
              )} to ${format(new Date(billing.renewsAt), "dd MMM, yyyy")}`
            : "-"}
          <div>
            Billing cycle resets at midnight on{" "}
            {format(new Date(billing.renewsAt), "MMM dd")} in your account's
            timezone
          </div>
        </div>
      ),
    },
    {
      label: "Payment Method",
      value: !billingCard ? (
        <div className={styles.billInfoWrapper}>
          <div>
            <ButtonV2
              children={"Add Payment Method"}
              size="medium"
              variant="outline"
              leftIcon={Plus}
            />
          </div>
        </div>
      ) : (
        <div className={styles.billInfoWrapper}>
          <div className={styles.cardContainer}>
            <img
              src={getCardImage(billingCard?.brand)}
              alt={getCardImage(billingCard.brand)}
              width={30}
              height={20}
            />
            <p className={styles.subtitle}>
              {titleCase(billingCard.brand)} ending in {billingCard?.last4},
              expiring {billingCard.expMonth}/{billingCard.expYear}
            </p>
          </div>
          <div>
            <ButtonV2
              children={"Update Payment Method"}
              size="small"
              variant="link"
            />
          </div>
        </div>
      ),
    },
    {
      label: "Billing Address",
      value: (
        <div className={styles.billInfoWrapper}>
          <div>
            {organization.Address
              ? `${[
                  organization.Address?.addressLine1,
                  organization.Address?.city,
                  organization.Address?.state,
                  organization.Address?.country,
                ].join(",")}`
              : "No address mentioned"}
          </div>
          <div className={styles.cardContainer}>
            <Checkbox value={"true"} checked={true} onChange={() => {}} />
            Use same address as my organization's contact information
          </div>
        </div>
      ),
    },
    {
      label: "Billing Email",
      value: organization.email,
    },
  ];
  return (
    <div>
      <SectionHeading heading={"Billing Information"} />
      <div className={styles.container}>
        {billingInformationList.map((v) => (
          <div className={styles.infoItem} key={v.label}>
            <div className={styles.title}>{v.label}</div>
            <div className={styles.subtitle}>{v.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
