import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import styles from "./AdFieldsShowcase.module.scss";
import { StaticAdFieldsShowcase } from "./StaticAdFieldsShowcase";
import { ExistingPostAdFieldsShowcase } from "./ExistingPostAdFieldsShowcase";
import { CatalogueFieldsShowcase } from "./CatalogueFieldsShowcase";

export function AdFieldsShowcase() {
  const { values } = useFormikContext<ActivityInputV2>();
  const selectedAdIndex = values.selectedAdIndex ?? 0;
  const selectedAd = values.Ads ? values.Ads[selectedAdIndex] : null;

  if (!selectedAd) {
    return <div />;
  }

  if (selectedAd.adType === "CATALOG") {
    return (
      <CatalogueFieldsShowcase
        selectedAdIndex={selectedAdIndex}
        selectedAd={selectedAd}
      />
    );
  }

  if (selectedAd.adType === "CUSTOM_CREATIVE") {
    return (
      <StaticAdFieldsShowcase
        selectedAdIndex={selectedAdIndex}
        selectedAd={selectedAd}
      />
    );
  }

  if (selectedAd.adType === "EXISTING_POST") {
    return (
      <ExistingPostAdFieldsShowcase
        selectedAdIndex={selectedAdIndex}
        selectedAd={selectedAd}
      />
    );
  }
}
