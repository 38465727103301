import { Asset } from "../../../../graphql/generated/schema";
import { MultilineInputFormik } from "../../../../components/MultilineInputFormik/MultilineFormik";
import { InputFormik } from "../../../../components/InputFormik/InputFormik";
import { AddMedia } from "./AddMedia";
import { SelectedAssetItem } from "./SelectedAssetItem";
import { FormValues } from "../../misc/adTemplateCreateTypes";
import { FormikError } from "src/components/FormikError/FormikError";
import { useFormikContext } from "formik";
import { getErrorAssetType } from "../../misc/getErrorAssetType";

export function AdTemplateSingleForm({
  handleSelect,
  lineItems,
}: {
  handleSelect: (assets: Asset[], cardIndex: number) => void;
  lineItems: FormValues["LineItems"];
}) {
  const { errors, submitCount, touched } = useFormikContext<FormValues>();

  const errorAssetType = getErrorAssetType(errors);
  return (
    <>
      <MultilineInputFormik
        label="Primary Text*"
        rows={3}
        placeholder="Tip: Primary text should span 2-3 lines at most."
        name={"primaryText"}
        showCharacterCount={true}
        maxCharacterCount={125}
      />

      <InputFormik
        name={`LineItems[0].headline`}
        label="Ad Title"
        placeholder="Write a short headline"
        showCharacterCount={true}
        maxCharacterCount={40}
        width="full"
      />

      <InputFormik
        name={`LineItems[0].description`}
        label="Ad Description"
        placeholder="Add additional details of your ads"
        showCharacterCount={true}
        maxCharacterCount={125}
        width="full"
      />

      <InputFormik
        name={`LineItems[0].link`}
        label="Ad Link"
        placeholder="https://example.com/products"
        showCharacterCount={true}
        maxCharacterCount={125}
        width="full"
      />

      <AddMedia
        onSelect={(assets) => handleSelect(assets, 0)}
        multiple={false}
      />
      <FormikError
        fieldName={`LineItems[0].${errorAssetType}`}
        formikError={errors}
        submitCount={submitCount}
        touched={touched}
      />

      <FormikError
        fieldName={`LineItems[0].${errorAssetType}.id`}
        formikError={errors}
        submitCount={submitCount}
        touched={touched}
      />
      {lineItems?.[0]?.PostAsset && (
        <SelectedAssetItem asset={lineItems[0].PostAsset} />
      )}
    </>
  );
}
