import classNames from "classnames";
import styles from "./PageHeader.module.scss";
import { ReactNode } from "react";
import { ReactComponent as ChevronLeft } from "@material-symbols/svg-600/rounded/chevron_left.svg";
import { ReactComponent as ChevronRight } from "@material-symbols/svg-600/rounded/chevron_right.svg";
import { Link } from "react-router-dom";

export interface Breadcrumb {
  label: string;
  link: string;
}

export interface IPageHeader {
  title: ReactNode;
  titleMetadata?: ReactNode;
  actions?: ReactNode;
  back?: string | (() => void);
  breadcrumbs?: Breadcrumb[];
}

export function PageHeader({
  title,
  titleMetadata,
  breadcrumbs,
  actions,
  back,
}: IPageHeader) {
  const backProps =
    typeof back === "string"
      ? {
          to: back,
        }
      : {
          to: "#",
          onClick: back,
        };

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.leftSide}>
        <div className={styles.titleAndBackWrapper}>
          {back && (
            <Link {...backProps} className={styles.backButton} type="button">
              <ChevronLeft width={20} height={20} fill="rgba(28, 27, 31, 1)" />
            </Link>
          )}
          <div className={styles.titleContainer}>
            <span className={styles.title}>{title}</span>
            {titleMetadata && (
              <span className={styles.titleMetaData}>{titleMetadata}</span>
            )}
          </div>
        </div>
        {breadcrumbs && (
          <div className={styles.breadcrumbsWrapper}>
            {breadcrumbs.map((breadcrumb, index) => (
              <>
                <Link
                  className={classNames(styles.breadcrumb, {
                    [styles.activeBreadcrumb]: breadcrumbs.length === index + 1,
                  })}
                  to={breadcrumb.link}
                >
                  {breadcrumb.label}
                </Link>
                {breadcrumbs.length !== index + 1 && (
                  <ChevronRight className={styles.breadcrumbSeparator} />
                )}
              </>
            ))}
          </div>
        )}
      </div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
}
