import { Formik, Form } from "formik";
import { useContext } from "react";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import styles from "./StoreManageIntegration.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ShopifyCustomerWizardSettings } from "./ShopifyCustomerWizardSettings";
import { ShopifyPixelWizardSettings } from "./ShopifyPixelWizardSettings";
import { useShopifyIntegrationUpdateMutation } from "src/graphql/generated/schema";
import { FormData } from "../misc/shopifyIntegrationTypes";
import { useNavigate } from "react-router-dom";

export function StoreManageIntegration() {
  const navigate = useNavigate();
  const [shopifyIntegrationUpdate] = useShopifyIntegrationUpdateMutation();
  const { data, activeStepIndex, markStepAsDone } =
    useContext(StepWizardContext);

  return (
    <Formik
      initialValues={data as FormData}
      onSubmit={async (values) => {
        await shopifyIntegrationUpdate({
          variables: {
            input: {
              syncFromShopify: values.contactData.enabled,
              syncToShopify: values.contactData.updateBack ? "ALL" : "NONE",
              syncToShopifyEmailConsent:
                values.contactData.marketingCommunications,
              syncToShopifyProfileData: values.contactData.updateBack,
              syncToShopifyTextMessageConsent:
                values.contactData.marketingCommunications,
            },
          },
        });
        markStepAsDone(1, values);
        navigate("/settings/integrations/shopify");
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <StepWizardStep
              isLastStep={true}
              isStepNotActive={activeStepIndex !== 1}
              isCompleted={activeStepIndex > 1}
            >
              <div className={styles.section}>
                <h2 className={styles.heading}>Manage Settings</h2>
                <h3 className={styles.subheading}>
                  Manually select permissions which you want to give access from
                  shopify
                </h3>

                <div className={styles.configurationList}>
                  <ShopifyCustomerWizardSettings />
                  <ShopifyPixelWizardSettings />
                </div>

                <div className={styles.buttonGroup}>
                  <ButtonV2 loading={isSubmitting} size="large" type="submit">
                    Integrate Shopify
                  </ButtonV2>
                </div>
              </div>
            </StepWizardStep>
          </Form>
        );
      }}
    </Formik>
  );
}
