import {
  AssetContentType,
  useAssetCreateMutation,
  useCustomerManualImportMutation,
} from "src/graphql/generated/schema";
import FileUploadPage, {
  FileUploadScreens,
} from "src/modules/global/components/FileUpload/FileUploadPage";
import { ReactNode, useEffect, useState } from "react";
import Stepper, { IStepper } from "src/components/Stepper/Stepper";
import AddTags from "../components/AddTags";
import { ReactComponent as DownloadForOffline } from "@material-design-icons/svg/outlined/download_for_offline.svg";
import Header from "src/components/Header/Header";
import MapColumns from "../components/MapColumns";
import Review from "../components/Review";
import { ReactComponent as Reviews } from "@material-design-icons/svg/outlined/device_hub.svg";
import { ReactComponent as Label } from "@material-design-icons/svg/outlined/label.svg";
import { ReactComponent as Task } from "@material-design-icons/svg/outlined/task.svg";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useUploadFile } from "src/lib/useUploadFile";
import styles from "./CustomerImportPage.module.scss";
import { Link } from "react-router-dom";
import fileUpload from "../assets/fileUpload.png";
import {
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from "src/modules/global/misc/maxSizes";

const importCustomerSteps: IStepper["steps"] = [
  {
    icon: <DownloadForOffline fill="currentColor" />,
    label: "Upload CSV file",
  },
  {
    icon: <Label fill="currentColor" />,
    label: "Add Tags",
  },
  {
    icon: <Reviews fill="currentColor" />,
    label: "Organize Table",
  },
  {
    icon: <Task fill="currentColor" />,
    label: "Review and Done",
  },
];

export type CustomerManualImportStepsType =
  | "UPLOAD_CSV"
  | "ADD_TAGS"
  | "MAP_COLUMNS"
  | "REVIEW";

const customerManualImportSteps: CustomerManualImportStepsType[] = [
  "UPLOAD_CSV",
  "ADD_TAGS",
  "MAP_COLUMNS",
  "REVIEW",
];

// Max file size for file input is 2mb
const MAX_FILE_SIZE = 2 * 1024 * 1024;

// TODO: Add logic to upload file
// TODO: Connect with verifyCustomers
export function CustomerImportPage() {
  const [files, setFiles] = useState<any[]>([]);
  const [screen, setScreen] = useState<FileUploadScreens>("UPLOAD");
  const [activeStep, setActiveStep] = useState<CustomerManualImportStepsType>(
    customerManualImportSteps[0]
  );
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [columnMapping, setColumnMapping] = useState({});
  const [asset, setAsset] = useState<any>();
  const [assetCreate] = useAssetCreateMutation();
  const navigate = useNavigate();
  const upload = useUploadFile({
    maxImageFileSizeInBytes: MAX_FILE_SIZE_IMAGE,
    maxVideoFileSizeInBytes: MAX_FILE_SIZE_VIDEO,
    supportedMimeTypes: ["text/csv"],
  });

  const [customerManualImportFunc, { loading }] =
    useCustomerManualImportMutation();

  function goToNextStep() {
    const currentStepPosition = customerManualImportSteps.findIndex(
      (v) => v === activeStep
    );

    if (currentStepPosition === customerManualImportSteps.length - 1) {
      setActiveStep(customerManualImportSteps[currentStepPosition]);
      return;
    }

    setActiveStep(customerManualImportSteps[currentStepPosition + 1]);
  }

  function goToPreviousStep() {
    const currentStepPosition = customerManualImportSteps.findIndex(
      (v) => v === activeStep
    );

    if (currentStepPosition === 0) {
      setActiveStep(customerManualImportSteps[currentStepPosition]);
      return;
    }

    setActiveStep(customerManualImportSteps[currentStepPosition - 1]);
  }

  async function handleContinue() {
    goToNextStep();
  }

  async function handleUpload() {
    if (files.length === 0) return;
    setScreen("LOADING");

    // only single file upload is supported
    const file = files[0];
    const payload = await upload(file);
    // console.log({ payload });

    const { data } = await assetCreate({
      variables: {
        input: {
          contentType: payload.contentType as AssetContentType,
          name: payload.name,
          fileSize: payload.fileSize,
          height: 0,
          width: 0,
          uri: payload.uri,
        },
      },
    });

    setAsset(data?.assetCreate);

    setScreen("COMPLETED");
  }

  useEffect(() => {
    handleUpload();
  }, [files]);

  const ImportCustomerSteps: Record<CustomerManualImportStepsType, ReactNode> =
    {
      UPLOAD_CSV: (
        <FileUploadPage
          files={files}
          setFiles={setFiles}
          fileUploadScreen={screen}
          width="800px"
          title="Upload CSV or Drag & Drop here"
          illustration={fileUpload}
          action={{
            title: "Upload Items",
          }}
          description="File format supported .csv | File max-size is 2MB"
          content={
            <>
              <div className={styles.uploadAreaHeader}>
                {screen === "LOADING"
                  ? "Please wait, you file is being uploading"
                  : " File has been uploaded, click import to proceed further."}
              </div>
              <div className={styles.uploadAreaDescription}>
                <p>
                  By importing customers from csv, your exisiting customer will
                  get overwrite.{" "}
                  <Link to="#">
                    <span className={styles.howItWorks}>
                      Learn how it works
                    </span>
                  </Link>
                </p>
              </div>
            </>
          }
          sampleFileLink="/customer-sample-file.csv"
          accept={{
            "text/csv": [".csv"],
          }}
          maxSize={MAX_FILE_SIZE}
          onUpload={handleContinue}
        />
      ),
      ADD_TAGS: (
        <AddTags
          next={goToNextStep}
          back={goToPreviousStep}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      ),
      MAP_COLUMNS: (
        <MapColumns
          mappingColumns={columnMapping}
          setMappingColumns={setColumnMapping}
          assetId={asset ? asset.id : ""}
          next={goToNextStep}
          back={goToPreviousStep}
        />
      ),
      REVIEW: (
        <Review
          selectedTags={selectedTags}
          columnMapping={columnMapping}
          assetId={asset ? asset.id : ""}
          back={goToPreviousStep}
          loading={loading}
          handleSubmit={async () => {
            const fieldMap = Object.fromEntries(
              Object.entries(columnMapping).map(
                ([k, v]) => v !== null && [v, k]
              )
            );
            try {
              const { data } = await customerManualImportFunc({
                variables: {
                  input: {
                    assetId: asset.id,
                    fieldMap: fieldMap,
                    tags: selectedTags.map((v) => v.label),
                  },
                },
              });

              if (data?.customerManualImport?.userError) {
                toast.error(data.customerManualImport.userError.message);
                return;
              }
              if (data?.customerManualImport?.response) {
                toast.success("Contact has been uploaded");
                navigate("/contacts");
              }
            } catch (e) {
              toast.error(e.message);
            }
          }}
        />
      ),
    };

  const primaryAction = {
    label: (
      <>
        <span>Cancel</span>
        <Close fill="white" width={18} height={18} />
      </>
    ),
    onClick() {
      navigate("/contacts");
    },
  };

  return (
    <div className={styles.page}>
      <Header
        title="Import Customers"
        mode="DISTRACTION_FREE"
        primaryAction={primaryAction}
      />
      <Stepper
        steps={importCustomerSteps}
        activeStepIndex={customerManualImportSteps.findIndex(
          (v) => v === activeStep
        )}
      />

      <div className={styles.importCustomers}>
        {ImportCustomerSteps[activeStep]}
      </div>
    </div>
  );
}
