import { Form, Formik } from "formik";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import Select from "src/components/Select/Select";
import styles from "./WhatsappIntegrationSingle.module.scss";
import { WhatsappBusinessDetails } from "../components/WhatsappBusinessDetails";
import { Button } from "src/components/Button/Button";
import {
  useWhatsappBusinessAccountAndPhoneNumberQuery,
  useWhatsappBusinessProfilePictureUpdateMutation,
  useWhatsappBusinessProfileUpdateMutation,
} from "src/graphql/generated/schema";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { ErrorHandler } from "src/components/ErrorHandler";
import clientIndustries from "src/modules/global/misc/clientIndustries";
import { ReactComponent as AddLink } from "@material-symbols/svg-600/rounded/add_link.svg";
import toast from "react-hot-toast";
import { ChangeEvent, useRef } from "react";
import { useUploadFile } from "src/lib/useUploadFile";
import blankProfilePhoto from "../../global/assets/blankProfilePhoto.png";

const maximumFileSizeInBytes = 2 * 1024 * 1024; // 2MB
const validMimeType = ["image/jpg", "image/jpeg", "image/png"]; // 2MB

export function WhatsappIntegrationSingle() {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { data, loading, error } =
    useWhatsappBusinessAccountAndPhoneNumberQuery();
  const [updateWhatsappProfile] = useWhatsappBusinessProfileUpdateMutation();
  const [whatsappBusinessProfilePictureUpdate] =
    useWhatsappBusinessProfilePictureUpdateMutation();
  const uploadFile = useUploadFile({
    maxVideoFileSizeInBytes: maximumFileSizeInBytes,
    maxImageFileSizeInBytes: maximumFileSizeInBytes,
    supportedMimeTypes: validMimeType,
    minimumHeight: 256,
    minimumWidth: 256,
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  function handleFilePick() {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  async function handleFileUpload(
    e: ChangeEvent<HTMLInputElement>,
    { setFieldValue },
  ) {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const resp = await uploadFile(file);
    const updateToFacebookResponse = await whatsappBusinessProfilePictureUpdate(
      {
        variables: {
          input: {
            fileLength: resp.fileSize,
            fileName: resp.name,
            fileType: file.type,
            fileUri: resp.uri,
          },
        },
      },
    );
    // upload the to facebook

    setFieldValue("profilePictureUrl", resp.uri);
    setFieldValue(
      "profilePictureHandle",
      updateToFacebookResponse.data.whatsappBusinessProfilePictureUpdate,
    );
  }

  return (
    <>
      <PageHeader title="WhatsApp Integration" back="/settings/integrations" />
      <div className={styles.page}>
        <div className={styles.formSection}>
          <Formik
            initialValues={{
              name: data.whatsappBusinessAccount.name,
              address: data.whatsappBusinessProfile.address,
              industry: data.whatsappBusinessProfile.industry,
              description: data.whatsappBusinessProfile.description,
              email: data.whatsappBusinessProfile.email,
              websites: data.whatsappBusinessProfile.websites,
              profilePictureHandle: "",
              profilePictureUrl: data.whatsappBusinessProfile.profilePictureUrl,
            }}
            onSubmit={async (values) => {
              await updateWhatsappProfile({
                variables: {
                  input: {
                    address: values.address,
                    industry: values.industry,
                    description: values.description,
                    email: values.email,
                    websites: values.websites,
                    ...(values.profilePictureHandle && {
                      profilePictureHandle: values.profilePictureHandle,
                    }),
                  },
                },
              });
              toast.success("Saved Successfully");
            }}
          >
            {({ values, setFieldValue, dirty, isSubmitting }) => {
              return (
                <Form>
                  <div className={styles.formContent}>
                    <div>
                      <h3 className={styles.heading}>
                        Manage your Whatsapp profile
                      </h3>
                      <p className={styles.subHeading}>
                        Add relevant details and build trust among the your
                        customer when you reach them out
                      </p>
                      <div className={styles.formContainer}>
                        <div className={styles.userSection}>
                          <img
                            alt="profile"
                            src={values.profilePictureUrl ?? blankProfilePhoto}
                            width={72}
                            height={72}
                            className={styles.profile}
                          />

                          <div className={styles.userInfo}>
                            <div className={styles.userInfoContent}>
                              <span className={styles.userName}>
                                {data.whatsappBusinessAccount.name}
                              </span>
                              <span className={styles.label}>
                                Your business Profile picture
                              </span>
                            </div>
                            <div>
                              <input
                                ref={inputRef}
                                type="file"
                                onChange={(e) =>
                                  handleFileUpload(e, { setFieldValue })
                                }
                                accept="jpeg,jpg,png"
                                hidden
                              />
                              <Button
                                style="outline"
                                size="micro"
                                color="subdued"
                                onClick={handleFilePick}
                              >
                                Upload Profile Picture
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className={styles.formItems}>
                          <InputFormik
                            disabled
                            label="Business Name"
                            name="name"
                          />
                          <Select
                            options={clientIndustries}
                            onChange={(value) =>
                              setFieldValue("industry", value)
                            }
                            value={values.industry}
                            label="Industry"
                            name="name"
                          />
                          <InputFormik label="Business Email" name="email" />

                          <InputFormik
                            icon={AddLink}
                            label="Website Link"
                            name="websites[0]"
                          />

                          <div className={styles.fullSizeRow}>
                            <MultilineInput
                              label="Business Description"
                              name="description"
                              value={values.description}
                              onChange={(value) => {
                                setFieldValue("description", value);
                              }}
                            />
                            <InputFormik name="address" label="Address" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Button
                        type="submit"
                        disabled={!dirty}
                        loading={isSubmitting}
                        size="micro"
                      >
                        Save Details
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <WhatsappBusinessDetails details={data} />
      </div>
    </>
  );
}
