import { Button } from "src/components/Button/Button";
import styles from "./PlanDetails.module.scss";
import {
  GetBillingQuery,
  OrganizationUsageQuery,
} from "src/graphql/generated/schema";
import { formatPrice } from "src/lib/formatPrice";
import format from "date-fns/format";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

interface PlanDetailsProps {
  billing: GetBillingQuery["billing"];
  organizationUsage?: OrganizationUsageQuery["organizationUsage"];
}

type Stats = Array<{
  label: string;
  value: React.ReactNode;
  action?: {
    to?: string;
    children: React.ReactNode;
    onClick?: () => void;
  };
}>;

// TODO: handle if user has taken both plans
export function PlanDetails({ billing }: PlanDetailsProps) {
  const plan = billing.BillingGrowPlan
    ? billing.BillingGrowPlan
    : billing.BillingEngagePlan;

  const planType = plan.__typename === "BillingEngagePlan" ? "ENGAGE" : "GROW";

  const billingSummary: Stats = [
    {
      label: "Next Invoice issue date",
      value: billing.renewsAt
        ? format(new Date(billing.renewsAt), "dd MMM, yyyy")
        : "-",
    },
    {
      label: "Invoice Amount",
      value: `${
        billing.cycle === "ANNUAL"
          ? formatPrice(billing.totalAmount / 100 / 12)
          : formatPrice(billing.totalAmount / 100)
      }/mo `,
      ...(billing.cycle === "MONTHLY" && {
        action: {
          children: "Switch to yearly plan",
          to: "/settings/billing",
        },
      }),
    },
    {
      label: "Subscribed to",
      value: planType === "ENGAGE" ? "Macro Engage" : "Macro Grow",
      action: {
        children: "Change Plan",
        to: "/settings/billing",
      },
    },
    // {
    //   label: "Payment Method",
    //   value: (
    //     <div className={styles.cardContainer}>
    //       <img src="/imagesAndIcons/visaCard.png" alt="card" />
    //       <p className={styles.subtitle}>
    //         Visa ending in 5978, expiring 8/2027
    //       </p>
    //     </div>
    //   ),
    //   action: {
    //     children: "Change payment method",
    //     onClick: () => {},
    //   },
    // },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div className={styles.heading}>Billing Summary</div>
          <div className={styles.description}>
            Control overall spends of your macro
          </div>
        </div>
      </div>
      <div className={styles.statsList}>
        {billingSummary.map((v) => (
          <div className={styles.stat}>
            <div className={styles.label}>{v.label}</div>
            <div className={styles.value}>{v.value}</div>
            <div>
              {v.action && (
                <ButtonV2 size="small" variant="link" {...v.action} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
