import {
  AudienceInput,
  FACEBOOK_AUDIENCE_SOURCES,
  metaEventsOption,
} from "src/modules/audience/components/CreateAudienceInput";

import { Button } from "../../Button/Button";
import Card from "../../Card/Card";
import { InputFormik } from "../../InputFormik/InputFormik";
import { Label } from "../../Label/Label";
import Select from "../../Select/Select";
import { durationOptions } from "src/lib/durationOptions";
import styles from "../CreateCustomAudience/CreateCustomAudience.module.scss";
import { FormikErrors, useFormikContext } from "formik";
import { FacebookAccountSettings } from "./FacebookAccountSettings";
import { StringSchema, ArraySchema, NumberSchema, BooleanSchema } from "yup";
import { OptionalArraySchema } from "yup/lib/array";
import { MixedSchema } from "yup/lib/mixed";
import {
  AssertsShape,
  OptionalObjectSchema,
  AnyObject,
  TypeOfShape,
} from "yup/lib/object";
import { RequiredStringSchema } from "yup/lib/string";

interface IWebsiteEngagerForm {
  selectedAudienceIndex: number;
  handleBack: () => void;
}

export function WebsiteEngagerForm({
  selectedAudienceIndex,
  handleBack,
}: IWebsiteEngagerForm) {
  const { values, setFieldValue, isValid, handleSubmit, isSubmitting } =
    useFormikContext<AudienceInput>();
  const audienceKey = `audiences[${selectedAudienceIndex}]`;
  const audienceValuesKey = `audiences[${selectedAudienceIndex}].values`;
  const audienceValues = values.audiences[selectedAudienceIndex]?.values;
  return (
    <Card>
      <Label
        label="Create a website Custom Audience"
        className={styles.cardTitle}
      >
        <FacebookAccountSettings
          audienceValuesKey={audienceValuesKey}
          audienceValues={audienceValues}
          setFieldValue={setFieldValue}
        />
        <div className={styles.container}>
          <div>
            <Label
              label="Audience Name"
              htmlFor={`${audienceKey}.audienceName`}
            >
              <InputFormik
                placeholder="Max limit 50 characters"
                name={`${audienceKey}.audienceName`}
              />
            </Label>
          </div>
          <div>
            <Label
              label="Select Events"
              htmlFor={`${audienceValuesKey}.pixelEventName`}
            >
              <Select
                options={metaEventsOption}
                value={audienceValues?.pixelEventName}
                onChange={(value) => {
                  setFieldValue(`${audienceValuesKey}.pixelEventName`, value);
                }}
                name={`${audienceValuesKey}.pixelEventName`}
              />
            </Label>
          </div>
          <div>
            <Label label="Retention" htmlFor={`${audienceValuesKey}.duration`}>
              {/* <Input placeholder="30(in Days)" /> */}
              <Select
                options={durationOptions}
                value={audienceValues?.duration}
                onChange={(value) => {
                  setFieldValue(`${audienceValuesKey}.duration`, value);
                }}
                name={`${audienceValuesKey}.duration`}
              />
            </Label>
            {/* <InputFormik
              placeholder="30(in Days)"
              name={`${audienceValuesKey}.duration`}
            /> */}
          </div>
        </div>
      </Label>

      <div className={styles.btnGrp}>
        <Button
          onClick={handleSubmit}
          type="button"
          disabled={!isValid}
          loading={isSubmitting}
        >
          Save and Continue
        </Button>
        <Button
          type="button"
          style="plain"
          color="subdued"
          onClick={handleBack}
          disabled={isSubmitting}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}
