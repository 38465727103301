import styles from "./MetaProfileSection.module.scss";
import { MetaUser } from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";

export function MetaProfileSection({ user }: { user: MetaUser }) {
  return (
    <div className={styles.userContainer}>
      <div className={styles.userInfo}>
        <img
          src={
            user?.picture ??
            `https://api.dicebear.com/7.x/shapes/svg?seed=${user.name}`
          }
          alt={user?.picture}
          className={styles.profile}
        />
        <div className={styles.info}>
          <div className={styles.connectionStatus}>
            <span className={styles.name}>
              {snakeCaseToTitleCase(user.name)}
            </span>
            <span className={styles.status}>Connected</span>
          </div>
          <span className={styles.email}>{user.email}</span>
        </div>
      </div>
      {/* FIXME: MAKE FEATURE SYNC NOW */}
      {/* <Button
        size="micro"
        width="auto"
        type="button"
        color="subdued"
        style="outline"
      >
        Sync Now
      </Button> */}
    </div>
  );
}
