import styles from "./CustomerImportOptions.module.scss";
import { Button } from "src/components/Button/Button";
import customerImport from "../assets/customerImport.png";
import syncFromShopify from "../assets/syncFromShopify.png";
import comingSoon from "../assets/importComingSoon.png";
import createCustomer from "../assets/createCustomer.png";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

interface ICustomerImportOptions {
  setSelectedPlatform: (i: any) => void;
  setShowShopifyImportModal: (i: boolean) => void;
  setShowAddCustomerModal: (i: boolean) => void;
}

export function CustomerImportOptions({
  setSelectedPlatform,
  setShowShopifyImportModal,
  setShowAddCustomerModal,
}: ICustomerImportOptions) {
  const navigate = useNavigate();

  const customerImportOptions = [
    {
      icon: customerImport,
      heading: "Import from CSV file",
      description:
        "Have a customer list? No issues you can directly import your customer list into your Macro to target them.",
      button: {
        label: "Upload File",
        action: function () {
          setSelectedPlatform("CSV");
          navigate("/contacts/import");
        },
      },
    },
    {
      icon: syncFromShopify,
      heading: "Integrate Shopify Store",
      description:
        "Integrate your shopify store with Macro and we will directly import customers from your store, and they will be synced",
      button: {
        label: "Integrate Shopify",
        action: function () {
          setSelectedPlatform("SHOPIFY");
          setShowShopifyImportModal(true);
        },
      },
    },
    {
      icon: createCustomer,
      heading: "Create Customer Manually",
      description:
        "Don't have any source to import customer? No worries, you can create customer manually",
      button: {
        label: "Create Customer",
        action: function () {
          setShowAddCustomerModal(true);
        },
      },
    },
    {
      icon: comingSoon,
      heading: "More option coming soon",
      description:
        "We are working on more platform where you can import customer to your Macro",
    },
  ];

  return (
    <div className={styles.list}>
      {customerImportOptions.map((item, idx) => (
        <div
          className={classNames(styles.sourceTile, {
            [styles.showBorder]: idx !== customerImportOptions.length,
          })}
          key={idx}
        >
          <div className={styles.tileInfo}>
            <img
              src={item.icon}
              alt={item.heading}
              className={styles.tileIcon}
            />
            <div className={styles.tileText}>
              <p className={styles.tileHeading}>{item.heading}</p>
              <p className={styles.tileDescription}>{item.description}</p>
            </div>
          </div>
          {"button" in item && (
            <Button
              color="subdued"
              style="outline"
              width="full"
              onClick={item.button.action}
              className={styles.tileAction}
            >
              {item.button.label}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}
