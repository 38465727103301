import { ReactNode } from "react";
import styles from "./SmartphoneShell.module.scss";
import classNames from "classnames";

interface ISmartphoneShell {
  children: ReactNode;
  className?: string;
  screenSize?: string;
}

/** Looks like Motorola E first gen */
export function SmartphoneShell({
  children,
  screenSize,
  className,
}: ISmartphoneShell) {
  return (
    <div
      // @ts-ignore
      style={screenSize ? { "--display-height": screenSize } : {}}
      className={classNames(styles.phonePreview, className)}
    >
      <div className={styles.phoneTopChin}>
        <div className={styles.microphone}></div>
      </div>
      <div className={styles.phoneScreen}>
        <div className={styles.display}>{children}</div>
      </div>
      <div className={styles.phoneBottomChin}>
        <div className={styles.metalRing}></div>
      </div>
    </div>
  );
}
