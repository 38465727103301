import React from "react";
import styles from "./HappenedStats.module.scss";
import { ReactComponent as ScheduledActivityIcon } from "@material-symbols/svg-400/rounded/event-fill.svg";
import { ReactComponent as ActiveActivityIcon } from "@material-symbols/svg-400/rounded/campaign-fill.svg";
import { ReactComponent as FailedActivityIcon } from "@material-symbols/svg-400/sharp/do_not_disturb_on-fill.svg";
import { ReactComponent as DraftActivitiesIcon } from "@material-symbols/svg-400/rounded/edit-fill.svg";
import { ReactComponent as CreativesAddedIcon } from "@material-symbols/svg-400/rounded/note_stack-fill.svg";
import { ReactComponent as ActiveFlowsIcon } from "@material-symbols/svg-400/rounded/conversion_path-fill.svg";
import { ReactComponent as DraftFlowsIcon } from "@material-symbols/svg-400/rounded/conversion_path_off-fill.svg";
import { ReactComponent as AccountCircleIcon } from "@material-symbols/svg-400/rounded/account_circle-fill.svg";
import { ReactComponent as SegmentAddedIcon } from "@material-symbols/svg-400/rounded/supervised_user_circle-fill.svg";
import { ReactComponent as AudienceAddedIcon } from "@material-symbols/svg-400/rounded/group-fill.svg";
import { CoreInsightsQuery } from "src/graphql/generated/schema";

function HappenedStats({
  stats,
}: {
  stats: CoreInsightsQuery["coreInsights"];
}) {
  // Define your items and their respective details and queries here
  const items = [
    {
      name: "Scheduled Activities",
      icon: <ScheduledActivityIcon height={20} width={20} fill="#1F5199" />,
      value: stats?.scheduleCampaignsCount || 0,
    },
    {
      name: "Active Activities",
      icon: <ActiveActivityIcon height={22} width={22} fill="#008060" />,
      value: stats?.activeCampaignsCount || 0,
    },
    {
      name: "Failed Activities",
      icon: <FailedActivityIcon height={20} width={20} fill="#D72C0D" />,
      value: stats?.failedCampaignsCount,
    },
    {
      name: "Draft Activities",
      icon: <DraftActivitiesIcon fill="#5C5F62" height={20} width={20} />,
      value: stats?.draftCampaignsCount,
    },
    {
      name: "Creatives Added",
      icon: <CreativesAddedIcon fill="#449DA7" height={20} width={20} />,
      value: 0,
    },
    {
      name: "Active Flows",
      icon: <ActiveFlowsIcon fill="#D97416" height={20} width={20} />,
      value: 0,
    },
    {
      name: "Draft Flows",
      icon: <DraftFlowsIcon fill="#BD946E" height={20} width={20} />,
      value: 0,
    },
    {
      name: "Customer Added",
      icon: <AccountCircleIcon fill="#008060" height={22} width={22} />,
      value: stats?.customersCount || 0,
    },
    {
      name: "Segment Added",
      icon: <SegmentAddedIcon fill="#347C84" height={20} width={20} />,
      value: stats?.segmentsCount || 0,
    },
    {
      name: "Audience Added",
      icon: <AudienceAddedIcon fill="#CD3B70" height={20} width={20} />,
      value: stats?.audiencesCount || 0,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Happened so far</h3>

      <div className={styles.container}>
        <div className={styles.grid}>
          {items.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.itemInner}>
                <div className={styles.itemType}>
                  {item.icon}
                  <span>{item.name}</span>
                </div>
                <div>
                  <span>{item.value}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HappenedStats;
