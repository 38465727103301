import { useMemo } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { getMonthFromNumber } from "src/modules/campaign/misc/getMonthFromNumber";
import { ResourceOverview } from "src/modules/global/components/ResourceOverview";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { GrowAggregateType } from "src/modules/global/misc/GrowAggregateType";
import { useAudiencePerTickStats } from "src/modules/global/functions/useAudiencePerTickStats";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface IResourceOverviewLoader {
  audienceId: string;
  statData: GrowAggregateType;
  dateFrom: Date;
  dateTo: Date;
}

export function ResourceOverviewLoader({
  audienceId,
  statData,
  dateFrom,
  dateTo,
}: IResourceOverviewLoader) {
  const { data, loading, error } = useAudiencePerTickStats(audienceId, {
    dateFrom,
    dateTo,
  });
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const keyStats = useMemo(
    () => [
      {
        label: "Impressions",
        description: "No. of users who saw your ads",
        value: formatNumberByType(statData?.impressions ?? 0, "NUMBER"),
        id: "IMPRESSION",
        statKey: "impressions",
        statType: "NUMBER" as const,
      },
      {
        label: "Reach",
        description: "No. of unique users who saw your ads",
        value: formatNumberByType(statData?.reach ?? 0, "NUMBER"),
        id: "REACH",
        statKey: "reach",
        statType: "NUMBER" as const,
      },
      {
        label: "Clicks",
        description: "No. of times your ads was clicked",
        value: formatNumberByType(statData?.clicks ?? 0, "NUMBER"),
        id: "CLICKS",
        statKey: "clicks",
        statType: "NUMBER" as const,
      },
      {
        label: "CPC",
        description: "Cost per click",
        value: formatNumberByType(statData?.clicks ?? 0, "CURRENCY", {
          showDecimal: true,
          selectedCurrencyCode,
        }),
        id: "CPC",
        statKey: "cpc",
        statType: "CURRENCY" as const,
      },
      {
        label: "CTR",
        description: "Click through Rate",
        // TODO: this needs a different stat
        value: formatNumberByType(statData?.ctr ?? 0, "MULTIPLIER", {
          showDecimal: false,
        }),
        id: "CTR",
        statKey: "ctr",
        statType: "MULTIPLIER" as const,
      },
    ],
    [statData],
  );

  if (loading) {
    return <Spinner height={400} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const formattedData = data
    ? data.map((v) => ({
        ...v,
        time:
          "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
      }))
    : [];

  return <ResourceOverview stats={keyStats} chartData={formattedData} />;
}
