const socialLinks: { to: string; icon: React.ReactNode }[] = [
  {
    to: "https://instagram.com/codeword-tech",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66536 2C4.0927 2 2 4.09464 2 6.66797V13.3346C2 15.9073 4.09464 18 6.66797 18H13.3346C15.9073 18 18 15.9054 18 13.332V6.66536C18 4.0927 15.9054 2 13.332 2H6.66536ZM14.6667 4.66667C15.0347 4.66667 15.3333 4.96533 15.3333 5.33333C15.3333 5.70133 15.0347 6 14.6667 6C14.2987 6 14 5.70133 14 5.33333C14 4.96533 14.2987 4.66667 14.6667 4.66667ZM10 6C12.206 6 14 7.794 14 10C14 12.206 12.206 14 10 14C7.794 14 6 12.206 6 10C6 7.794 7.794 6 10 6ZM10 7.33333C9.29276 7.33333 8.61448 7.61428 8.11438 8.11438C7.61428 8.61448 7.33333 9.29276 7.33333 10C7.33333 10.7072 7.61428 11.3855 8.11438 11.8856C8.61448 12.3857 9.29276 12.6667 10 12.6667C10.7072 12.6667 11.3855 12.3857 11.8856 11.8856C12.3857 11.3855 12.6667 10.7072 12.6667 10C12.6667 9.29276 12.3857 8.61448 11.8856 8.11438C11.3855 7.61428 10.7072 7.33333 10 7.33333Z"
          fill="#5C5F62"
        />
      </svg>
    ),
  },
  {
    to: "https://twitter.com/codeword-tech",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.87052 3.61133L8.3399 10.9667L2.83594 16.9444H4.07467L8.89358 11.71L12.7882 16.9447H17.0026L11.2251 9.17692L16.3484 3.61133H15.1096L10.6718 8.43135L7.08576 3.61133H2.87052ZM4.69229 4.52904H6.62887L15.18 16.0267H13.244L4.69229 4.52904Z"
          fill="#5C5F62"
        />
      </svg>
    ),
  },
  {
    to: "https://facebook.com/codeword-tech",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99922 1.19995C5.13922 1.19995 1.19922 5.13995 1.19922 9.99995C1.19922 14.412 4.44922 18.0548 8.68402 18.6912V12.3324H6.50682V10.0192H8.68402V8.47995C8.68402 5.93155 9.92562 4.81275 12.0436 4.81275C13.058 4.81275 13.5944 4.88795 13.8484 4.92235V6.94155H12.4036C11.5044 6.94155 11.1904 7.79395 11.1904 8.75475V10.0192H13.8256L13.468 12.3324H11.1904V18.71C15.4856 18.1272 18.7992 14.4548 18.7992 9.99995C18.7992 5.13995 14.8592 1.19995 9.99922 1.19995Z"
          fill="#5C5F62"
        />
      </svg>
    ),
  },
  {
    to: "https://linkdin.com/codeword-tech",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4016 1.59961H3.60156C2.49756 1.59961 1.60156 2.49561 1.60156 3.59961V16.3996C1.60156 17.5036 2.49756 18.3996 3.60156 18.3996H16.4016C17.5056 18.3996 18.4016 17.5036 18.4016 16.3996V3.59961C18.4016 2.49561 17.5056 1.59961 16.4016 1.59961ZM6.80156 7.99961V15.5996H4.40156V7.99961H6.80156ZM4.40156 5.78761C4.40156 5.22761 4.88156 4.79961 5.60156 4.79961C6.32156 4.79961 6.77356 5.22761 6.80156 5.78761C6.80156 6.34761 6.35356 6.79961 5.60156 6.79961C4.88156 6.79961 4.40156 6.34761 4.40156 5.78761ZM15.6016 15.5996H13.2016C13.2016 15.5996 13.2016 11.8956 13.2016 11.5996C13.2016 10.7996 12.8016 9.99961 11.8016 9.98361H11.7696C10.8016 9.98361 10.4016 10.8076 10.4016 11.5996C10.4016 11.9636 10.4016 15.5996 10.4016 15.5996H8.00156V7.99961H10.4016V9.02361C10.4016 9.02361 11.1736 7.99961 12.7256 7.99961C14.3136 7.99961 15.6016 9.09161 15.6016 11.3036V15.5996Z"
          fill="#5C5F62"
        />
      </svg>
    ),
  },
];
export default socialLinks;
