export function FacebookLikeIcon({
  ...options
}: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="100"
      height="100"
      fill="none"
      {...options}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 50C20 47.5147 22.0147 45.5 24.5 45.5H36.5C37.8807 45.5 39 46.6193 39 48V77C39 78.3807 37.8807 79.5 36.5 79.5H24.5C22.0147 79.5 20 77.4853 20 75V50ZM25 50.5V74.5H34V50.5H25Z" />
      <path d="M46.4996 24.5C46.2234 24.5 45.9996 24.7239 45.9996 25V34C45.9996 34.3881 45.9092 34.7709 45.7356 35.118L38.2356 50.118C37.6182 51.353 36.1165 51.8535 34.8815 51.2361C33.6466 50.6186 33.146 49.1169 33.7635 47.882L40.9996 33.4098V25C40.9996 21.9624 43.462 19.5 46.4996 19.5H48.4996C53.7463 19.5 57.9996 23.7533 57.9996 29V40H69.6562C76.7252 40 81.7745 46.844 79.6887 53.5983L75.2183 68.0736C73.2122 74.5696 67.2071 79 60.4085 79H35.9996C34.6189 79 33.4996 77.8807 33.4996 76.5C33.4996 75.1193 34.6189 74 35.9996 74H60.4085C65.014 74 69.082 70.9987 70.441 66.5983L74.9113 52.1229C76.0039 48.585 73.359 45 69.6562 45H55.4996C54.1189 45 52.9996 43.8807 52.9996 42.5V29C52.9996 26.5147 50.9849 24.5 48.4996 24.5H46.4996Z" />
    </svg>
  );
}
