import { Form, Formik } from "formik";
import { AudienceSetCreateType } from "../misc/audienceSetCreateType";
import styles from "./AudienceDemographicsForm.module.scss";
import { useContext, useMemo } from "react";
import { WizardContext } from "src/modules/global/misc/WizardContext";
import Card from "src/components/Card/Card";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Label } from "src/components/Label/Label";
import Select from "src/components/Select/Select";
import { Button } from "src/components/Button/Button";
import Stepper from "src/components/Stepper/Stepper";
import { AudienceLocationInput } from "./AudienceLocationInput";
import ComboBox from "src/components/ComboBox/ComboBox";
import { metaLanguages } from "../misc/metaLanguages";

export function AudienceDemographicsForm() {
  const { breadcrumbs, currentNode, data, markNodeAsComplete, goBack } =
    useContext(WizardContext);
  const languageOptions = useMemo(
    () =>
      metaLanguages.map((l) => ({
        label: l.name,
        value: l.auxiliaryData.key.toString(),
      })),
    []
  );

  return (
    <Formik
      initialValues={data}
      onSubmit={(values) => {
        markNodeAsComplete({
          ...values,
          currentStep: currentNode.getNextStep(values),
        });
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Stepper
            activeStepIndex={breadcrumbs.findIndex((b) => b.isActive)}
            steps={breadcrumbs}
          />
          <div className={styles.layout}>
            <Card>
              <div className={styles.sectionHeader}>
                <h2 className={styles.sectionHeading}>Demographic Details</h2>
                <p className={styles.sectionCopy}>
                  Target audience based on the below give information, this will
                  help you to narrow down your target size. Better the targeting
                  better the results.
                </p>
              </div>

              <div className={styles.inputGroup}>
                <AudienceLocationInput
                  setLocations={(values) => {
                    setFieldValue("locations", values);
                  }}
                  locations={values.locations}
                />
                <Label label="Search Language">
                  <ComboBox
                    multiple
                    showTags
                    renderTagLabel={(value) =>
                      languageOptions.find((l) => l.value === value).label
                    }
                    options={languageOptions}
                    selected={values.languages}
                    setSelected={(values) => {
                      setFieldValue("languages", values);
                    }}
                    placeholder="Search language"
                  />
                </Label>
                <fieldset>
                  <Label label="Add age and gender" />
                  <div className={styles.otherFields}>
                    <InputFormik name="minAge" label="" placeholder="" />
                    <InputFormik name="maxAge" label="" placeholder="" />
                    <Select
                      value={values.gender}
                      onChange={(value) => {
                        setFieldValue("gender", value);
                      }}
                      options={[
                        { label: "All Genders", value: "ALL" },
                        { label: "Male", value: "MALE" },
                        { label: "Female", value: "FEMALE" },
                      ]}
                      name="gender"
                      label=""
                      placeholder=""
                    />
                  </div>
                </fieldset>
              </div>

              <div className={styles.cardFooter}>
                <div className={styles.submitGroup}>
                  <Button type="submit">Save and Continue</Button>
                  <Button
                    onClick={() => {
                      goBack(values);
                    }}
                    type="button"
                    style="outline"
                    color="subdued"
                  >
                    Back
                  </Button>
                </div>
                <Button
                  onClick={() => {
                    markNodeAsComplete(data);
                  }}
                  type="button"
                  style="outline"
                  color="subdued"
                >
                  Skip this Option
                </Button>
              </div>
            </Card>
          </div>
        </Form>
      )}
    </Formik>
  );
}
