import { WhatsappTemplateHeader } from "../WhatsappTemplateHeader/WhatsappTemplateHeader";
import { WhatsappTemplateBody } from "../WhatsappTemplateBody/WhatsappTemplateBody";
import { WhatsappTemplateFooter } from "../WhatsappTemplateFooter/WhatsappTemplateFooter";
import { WhatsappTemplateButtons } from "../WhatsappTemplateButtons/WhatsappTemplateButtons";
import { Button } from "src/components/Button/Button";
import styles from "./WhatsappTemplateDetails.module.scss";
import { useFormikContext } from "formik";
import { WhatsappFormInput } from "../../../CreateMessageTemplateTypes";

interface ITemplateDetails {
  creationStep: number;
  setCreationStep: (i: number) => void;
}

export function removeAllVariables(text: string, setText: (i: string) => void) {
  const variablesCount = text ? text.match(/{{\d+}}/g).length : 0;
  if (variablesCount === 0) return;
  setText(text.replaceAll(/{{\d+}}/g, ""));
  return;
}

export function addNewVariableToText(
  text: string,
  setText: (i: string) => void
) {
  const matches = text.match(/{{\d+}}/g);
  const variablesCount = matches ? matches.length : 0;
  const newVariableCount = variablesCount + 1;
  setText(text ? text + `{{${newVariableCount}}}` : `{{${newVariableCount}}}`);
  return;
}

export const suggestions = [
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Email",
    value: "email",
  },
];

// TODO: Custom variables in body, header editors feature
export function WhatsappTemplateDetails({
  creationStep,
  setCreationStep,
}: ITemplateDetails) {
  const { values, setFieldValue, handleSubmit } =
    useFormikContext<WhatsappFormInput>();
  return (
    <div className={styles.detailsForm}>
      <WhatsappTemplateHeader values={values} setFieldValue={setFieldValue} />
      <WhatsappTemplateBody values={values} setFieldValue={setFieldValue} />
      <WhatsappTemplateFooter values={values} />
      <WhatsappTemplateButtons values={values} setFieldValue={setFieldValue} />
      <div className={styles.btnGrp}>
        <Button
          color="subdued"
          style="outline"
          onClick={() => setCreationStep(creationStep - 1)}
        >
          Back
        </Button>
        <Button onClick={handleSubmit}>Save and Continue</Button>
      </div>
    </div>
  );
}
