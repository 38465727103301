import { Switch } from "@headlessui/react";
import styles from "./ToggleSwitch.module.scss";
import classNames from "classnames";

interface IToggleSwitch {
  checked: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange: () => void;
  title: string;
}

export function ToggleSwitch({
  checked,
  disabled,
  loading,
  onChange,
  title,
}: IToggleSwitch) {
  return (
    <Switch
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      className={classNames(styles.switchContainer, {
        [styles.darkContainer]: checked,
        [styles.lightContainer]: !checked,
      })}
    >
      <span className={styles.srOnly}>{title}</span>
      <span
        aria-hidden="true"
        className={classNames(styles.circle, {
          [styles.enabledCircle]: checked,
          [styles.disabledCircle]: !checked,
        })}
      />
    </Switch>
  );
}
