import * as Yup from "yup";
import { MetaEngagementConversionLocation } from "./createCampaignTypes";
import { MetaAdTypeEnum } from "src/graphql/generated/schema";

const AssetValidationFieldSchema = {
  name: Yup.string().nullable(),
  uri: Yup.string().url("Should be a valid url"),
  metaVideoId: Yup.string().nullable(),
  contentType: Yup.mixed().oneOf(["IMAGE", "VIDEO"]).nullable(),
};

const engagementLineItemsFieldSchema = {
  order: Yup.number(),
  headline: Yup.string()
    .required("Headline is required")
    .max(50, "Headline should be less than 50 characters"),
  description: Yup.string()
    .required("Description is required")
    .max(50, "Description should be less than 50 characters"),
  // PromotedObject: Yup.object({
  //   objectType: Yup.mixed().oneOf(["PRODUCT", "COLLECTION"]),
  //   platformObjectHandle: Yup.string(),
  //   platformObjectId: Yup.string(),
  //   platform: Yup.mixed().oneOf(["SHOPIFY", "WOOCOMMERCE"]),
  // })
  //   .nullable()
  //   .notRequired(),
  PostAsset: Yup.object()
    .default(undefined)
    .shape(AssetValidationFieldSchema)
    .typeError("Post asset is required")
    .required("Post asset is required"),
  StoryAsset: Yup.object()
    .default(undefined)
    .shape(AssetValidationFieldSchema)
    .nullable(),
};

const LineItemsFieldSchema = {
  order: Yup.number(),
  headline: Yup.string()
    .required("Headline is required")
    .max(50, "Headline should be less than 50 characters"),
  description: Yup.string()
    .required("Description is required")
    .max(50, "Description should be less than 50 characters"),
  // .max(50, "Description should be less than 50 characters"),
  link: Yup.string()
    .url("Link should be a valid url")
    .required("Link is required"),
  // PromotedObject: Yup.object({
  //   objectType: Yup.mixed().oneOf(["PRODUCT", "COLLECTION"]),
  //   platformObjectHandle: Yup.string(),
  //   platformObjectId: Yup.string(),
  //   platform: Yup.mixed().oneOf(["SHOPIFY", "WOOCOMMERCE"]),
  // })
  //   .nullable()
  //   .notRequired(),
  PostAsset: Yup.object()
    .default(undefined)
    .shape(AssetValidationFieldSchema)
    .nullable()
    .required("To create ad you need to upload asset"),
  // .when("StoryAsset", (value: any, schema: any) => {
  //   if (!value) {
  //     return Yup.object(AssetValidationFieldSchema)
  //       .required()
  //       .typeError("Post Asset is required")
  //   }
  //   return schema
  // }),
  StoryAsset: Yup.object()
    .default(undefined)
    .shape(AssetValidationFieldSchema)
    .nullable(),
  // .nullable()
  // .when("PostAsset", {
  //   is: undefined,
  //   then: Yup.object(AssetValidationFieldSchema)
  //     .required()
  //     .typeError("Story asset is required"),
  // }),
};

export const createFacebookVideoActivitySchema = Yup.object({
  Ads: Yup.array(
    Yup.object({
      adType: Yup.mixed().oneOf(["CUSTOM_CREATIVE", "EXISTING_POST"]),
      primaryText: Yup.string().when("adType", (value: MetaAdTypeEnum) => {
        if (value === "EXISTING_POST") {
          return Yup.string();
        }
        return Yup.string()
          .required("Primary text is required")
          .max(500, "Please keep primary text under 500 characters");
      }),
      existingPostId: Yup.string().when("adType", (value: MetaAdTypeEnum) => {
        if (value === "EXISTING_POST") {
          return Yup.string().required("Existing Post is required");
        }
        return Yup.string().nullable();
      }),
      LineItems: Yup.array()
        .required("Line items are required")
        .typeError("Line items are required")
        .when("adType", (value: MetaAdTypeEnum) => {
          if (value === "EXISTING_POST") {
            return Yup.array();
          }

          return Yup.array(
            Yup.object({
              PostAsset: Yup.object()
                .default(undefined)
                .shape(AssetValidationFieldSchema)
                .required("Asset is required"),
              StoryAsset: Yup.object()
                .default(undefined)
                .shape(AssetValidationFieldSchema)
                .nullable(),
            }).required(),
          )
            .max(1, "Line items should not be more than 1")
            .min(1, "Atleast one line item is required to create static ad")
            .required("Line items are required");
        }),
    }),
  ),
});

export const createFacebookPromotePostSchema = Yup.object({
  Ads: Yup.array(
    Yup.object({
      adType: Yup.mixed().oneOf(["CUSTOM_CREATIVE", "EXISTING_POST"]),
      existingPostId: Yup.string().required("Existing Post is required"),
      callToActionLabel: Yup.string().required("Please Select A CTA"),
      LineItems: Yup.array(
        Yup.object({
          link: Yup.string().required("Link is required"),
        }),
      )
        .required("Line items are required")
        .typeError("Line items are required")
        .max(1, "Line items should not be more than 1")
        .min(1, "Atleast one line item is required to create static ad")
        .required("Line items are required"),
    }),
  ),
});

export const createFacebookEngagementActivitySchema = Yup.object({
  engagementConversionLocation: Yup.string(),
  Ads: Yup.array(
    Yup.object({
      phoneNumber: Yup.string().when(
        "engagementConversionLocation",
        (value: MetaEngagementConversionLocation) => {
          if (value === "CALL") {
            return Yup.string()
              .matches(/\d+/, "Should only include numbers")
              .length(10, "Should be 10 digits")
              .required("Phone number is required");
          }

          return Yup.string().nullable();
        },
      ),

      phoneCountryCode: Yup.string().when(
        "engagementConversionLocation",
        (value: MetaEngagementConversionLocation) => {
          if (value === "CALL") {
            return Yup.string()
              .matches(/\d+/, "Should only include numbers")
              .required("Phone country code is required");
          }

          return Yup.string().nullable();
        },
      ),
      PageWelcomeMessage: Yup.object().when(
        "engagementConversionLocation",
        (value: MetaEngagementConversionLocation) => {
          if (value === "MESSAGE") {
            return Yup.object({
              greeting: Yup.string()
                .max(300, "300 is the max character count for greeting")
                .required("Greeting is required"),
              MessageOptions: Yup.array(
                Yup.object({
                  text: Yup.string().required("Message Text is required"),
                  automatedReply: Yup.string(),
                }),
              )
                .min(1)
                .required("Message is required"),
            }).required("Page welcome message is important");
          }

          return Yup.object().nullable();
        },
      ),
      adType: Yup.mixed().oneOf([
        "CATALOG",
        "CUSTOM_CREATIVE",
        "EXISTING_POST",
      ]),
      primaryText: Yup.string().when("adType", (value: MetaAdTypeEnum) => {
        if (value === "EXISTING_POST") {
          return Yup.string();
        }
        return Yup.string()
          .required("Primary text is required")
          .max(500, "Please keep primary text under 500 characters");
      }),
      callToActionLabel: Yup.string().required("Please Select A CTA"),
      existingPostId: Yup.string().when("adType", (value: MetaAdTypeEnum) => {
        if (value === "EXISTING_POST") {
          return Yup.string().required("Existing Post is required");
        }
        return Yup.string().nullable();
      }),
      LineItems: Yup.array()
        .required("Line items are required")
        .typeError("Line items are required")
        .when("adType", (value: MetaAdTypeEnum) => {
          if (value === "EXISTING_POST") {
            return Yup.array();
          }

          return (
            Yup.array()
              .of(
                Yup.object()
                  .shape(engagementLineItemsFieldSchema, [
                    ["PostAsset", "StoryAsset"],
                  ])
                  .required(),
              )
              .max(10, "Line items should not be more than 10")
              // .min(3, "Atleast 3 line items are required to create carousel ad")
              .required("Line items are required")
          );
        }),
    }),
  ),
});

export const createFacebookExistingPostActivitySchema = Yup.object({
  Ads: Yup.array(
    Yup.object({
      primaryText: Yup.string(),
      existingPostId: Yup.string().required("Existing Post is required"),
      callToActionLabel: Yup.string().required("Please Select A CTA"),

      LineItems: Yup.array(
        Yup.object()
          .shape({
            link: Yup.string()
              .url("Link should be a valid url")
              .required("Link is required"),
          })
          .required(),
      )
        .required("Line items are required")
        .typeError("Line items are required"),
    }),
  ),
});

export const createFacebookStaticActivitySchema = Yup.object({
  Ads: Yup.array(
    Yup.object({
      adType: Yup.mixed().oneOf([
        "CATALOG",
        "CUSTOM_CREATIVE",
        "EXISTING_POST",
      ]),
      primaryText: Yup.string().when("adType", (value: MetaAdTypeEnum) => {
        if (value === "EXISTING_POST") {
          return Yup.string();
        }
        return Yup.string()
          .required("Primary text is required")
          .max(500, "Please keep primary text under 500 characters");
      }),
      existingPostId: Yup.string().nullable(),
      callToActionLabel: Yup.string().required("Please Select A CTA"),
      LineItems: Yup.array().when(
        "adType",
        (value: MetaAdTypeEnum, schema: any) => {
          if (value === "CUSTOM_CREATIVE") {
            return schema
              .of(
                Yup.object()
                  .shape(LineItemsFieldSchema, [["PostAsset", "StoryAsset"]])
                  .required(),
              )
              .max(10, "Line items should not be more than 10")
              .required("Line items are required");
          }

          return schema
            .of(
              Yup.object({
                link: Yup.string()
                  .url("Link should be a valid url")
                  .required("Link is required"),
              }).required(),
            )
            .max(1, "Line items should not be more than 10")
            .required("Line items are required");
        },
      ),
    }),
  ),
});

export const createFacebookDynamicActivitySchema = Yup.object({
  Ads: Yup.array(
    Yup.object({
      adType: Yup.mixed().oneOf(["CATALOG"]),
      primaryText: Yup.string().required("Primary text is required field"),
      callToActionLabel: Yup.string().required("Please Select A CTA"),
      LineItems: Yup.array()
        .required("Line items are required")
        .max(1, "Atleast one line item is required to make ad")
        .of(
          Yup.object().shape({
            order: Yup.number(),
            headline: Yup.string()
              .required("Headline is required")
              .max(50, "Please keep headline under 50 characters"),
            description: Yup.string()
              .required("Description is required")
              .max(50, "Description should be less than 50 characters"),
          }),
        ),
    }),
  ),
});
