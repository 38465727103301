import { UpgradePlanBanner } from "./UpgradePlanBanner";
import { IUpgradePlanCard, UpgradePlanCard } from "./UpgradePlanCard";
import { UpgradePlanSectionHeading } from "./UpgradePlanSectionHeading";
import styles from "./PaywallTemplate.module.scss";
import { ReactNode } from "react";
import classNames from "classnames";

export interface IPaywallTemplate {
  sectionIco: string;
  header: {
    title: ReactNode;
    description: string;
    details: JSX.Element;
  };
  featuresSection: {
    title: ReactNode;
    description: string;
    features: IUpgradePlanCard[];
  };
  bannerImage: string;
  // engage uses orange
  // grow uses green
  primaryColor: "engage" | "grow";
  planName: "engage" | "grow";
}

export function PaywallTemplate({
  header,
  sectionIco,
  featuresSection,
  bannerImage,
  primaryColor = "engage",
  planName,
}: IPaywallTemplate) {
  return (
    <div>
      <main className={classNames(styles.container, styles[primaryColor])}>
        <section>
          <UpgradePlanSectionHeading
            icon={sectionIco}
            title={header.title}
            description={header.description}
          />
          {header.details}
        </section>

        <section>
          <UpgradePlanSectionHeading
            icon={sectionIco}
            title={featuresSection.title}
            description={featuresSection.description}
          />
          <div className={styles.features}>
            {featuresSection.features.map((item, idx) => (
              <div className={styles.featureCard}>
                <UpgradePlanCard
                  title={item.title}
                  subtitle={item.subtitle}
                  key={idx}
                  icon={item.icon}
                  primaryColor={primaryColor}
                />
              </div>
            ))}
          </div>
        </section>

        <UpgradePlanBanner
          planName={planName}
          bannerImage={bannerImage}
          primaryColor={primaryColor}
        />
      </main>
    </div>
  );
}
