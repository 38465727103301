import { RadioGroup } from "@headlessui/react";
import styles from "./TabInput.module.scss";
import classNames from "classnames";

interface ITabInput {
  value: string;
  onChange: (value: any) => void;
  onBlur: () => void;
  justifyContent?: string;
  tabs: {
    label: string;
    value: string;
    image: { src: string; size: number };
  }[];
}

export function TabInput({
  value,
  onChange,
  onBlur,
  tabs,
  justifyContent,
}: ITabInput) {
  return (
    <RadioGroup
      as="div"
      className={styles.adTabs}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      {tabs.map((adTab) => (
        <RadioGroup.Option
          style={{ justifyContent }}
          className={({ checked }) =>
            classNames(styles.adTab, {
              [styles.active]: checked,
            })
          }
          value={adTab.value}
        >
          <img
            width={adTab.image.size}
            height={adTab.image.size}
            src={adTab.image.src}
          />
          {adTab.label}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
}
