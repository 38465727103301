import * as Yup from "yup";

export function generateValidationSchema({ platform }) {
  if (platform === "WHATSAPP") {
    return Yup.object({
      body: Yup.object({
        text: Yup.string(),
        example: Yup.array(
          Yup.object({
            variableName: Yup.string().notRequired(),
            variableExample: Yup.string().notRequired(),
          })
        ),
      }),
      buttons: Yup.object({
        type: Yup.string().oneOf(
          ["CTA", "QUICK_REPLY", "NONE"],
          "Not correct value"
        ),
        quickReplies: Yup.array(Yup.string()),
        ctas: Yup.array(
          Yup.object({
            type: Yup.string().oneOf(["URL", "PHONE"]).required(),
            text: Yup.string().notRequired(),
            url: Yup.string().notRequired(),
            phone: Yup.string().notRequired(),
          })
        ),
      }),
      footer: Yup.string(),
      header: Yup.object({
        headerType: Yup.string()
          .oneOf(["NONE", "TEXT", "UPLOAD", "LOCATION"])
          .required("Header type should be provided"),
        text: Yup.string().notRequired(),
        exampleLink: Yup.string().notRequired(),
        example: Yup.array(
          Yup.object({
            variableName: Yup.string().notRequired(),
            variableExample: Yup.string().notRequired(),
          })
        ),
      }),
      name: Yup.string().required(
        "Name is required to create whatsapp template"
      ),
      objective: Yup.string().required("Objective is required"),
    });
  }
  if (platform === "SMS") {
    return Yup.object({});
  }
}
