import { getIn, useFormikContext } from "formik";
import { AudienceInput } from "src/modules/audience/components/CreateAudienceInput";
import { AudienceTypeEnum } from "src/graphql/generated/schema";
import { Button } from "src/components/Button/Button";
import Card from "src/components/Card/Card";
import CollectionItem from "src/components/CollectionItem/CollectionItem";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import { Label } from "src/components/Label/Label";
import { RadioGroup } from "@headlessui/react";
import styles from "./ChooseAudienceType.module.scss";
import customAudience from "../assets/customAudienceType.svg";
import lookAlike from "../assets/lookAlike.svg";

interface Props {
  disabled: boolean;
  activeStep: number;
  setActiveStep: (i: number) => void;
  selectedAudienceIndex: number;
}

const audiences: {
  title: string;
  subtitle: string;
  image: string;
  value: AudienceTypeEnum;
}[] = [
  {
    title: "Custom Audience",
    subtitle:
      "Connect with the people who have already shown an interest in your business or products.",
    image: customAudience,
    value: "PLATFORM_BASED",
  },
  {
    title: "Lookalike Audience",
    subtitle:
      "Lookalike Audience based on people who like your Page, or any existing custom audiences.",
    image: lookAlike,
    value: "LOOKALIKE",
  },
  // {
  //   title: "Saved Audience",
  //   subtitle:
  //     "Choose demographics, interest and behaviour, then save them to use again in future ads.",
  //   image: "/imagesAndIcons/SavedAudience.svg",
  //   value: "CUSTOMER_LIST",
  // },
];

export function ChooseAudienceType({
  activeStep,
  setActiveStep,
  selectedAudienceIndex,
  disabled,
}: Props) {
  const { values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<AudienceInput>();
  const audienceKey = `audiences[${selectedAudienceIndex}]`;
  const fieldError = getIn(errors, `${audienceKey}.audienceType`);
  const isFieldTouched = getIn(touched, `${audienceKey}.audienceType`);
  const fieldValue = getIn(values, `${audienceKey}.audienceType`);
  return (
    <Card disabled={disabled}>
      <Label label="Start from scratch">
        <RadioGroup
          as="div"
          className={styles.audienceTypeInput}
          name={`${audienceKey}.audienceType`}
          onBlur={() => {
            setFieldTouched(`${audienceKey}.audienceType`);
          }}
          value={fieldValue}
          onChange={(value) => {
            setFieldValue(`${audienceKey}.audienceType`, value);
          }}
        >
          {audiences.map((item, index) => (
            <CollectionItem
              title={item.title}
              image={item.image}
              value={item.value}
              subtitle={item.subtitle}
              key={index}
            />
          ))}
        </RadioGroup>
        {isFieldTouched && fieldError && (
          <FormFieldStatus type="error" message={fieldError} />
        )}
      </Label>

      <div className={styles.btnGrp}>
        <Button
          disabled={
            values.audiences[selectedAudienceIndex]?.audienceType ? false : true
          }
          onClick={() => setActiveStep(activeStep + 1)}
        >
          Save and Continue
        </Button>
        <Button style="plain" color="subdued">
          Back
        </Button>
      </div>
    </Card>
  );
}
