import { IconButton } from "src/components/IconButton/IconButton";
import { ReactComponent as CodeBlock } from "@material-symbols/svg-400/rounded/code_blocks.svg";
import { ReactComponent as ControlPoint } from "@material-design-icons/svg/outlined/control_point_duplicate.svg";
import { ReactComponent as MoreOptions } from "@material-design-icons/svg/outlined/more_vert.svg";
import Dropdown from "src/components/Dropdown/Dropdown";
import styles from "./TemplateListingAction.module.scss";
import classNames from "classnames";
import { TemplateSingleSendModal } from "./TemplateSingleSendModal";
import { useState } from "react";
import { TemplateListingDeleteModal } from "./TemplateListingDeleteModal";

export function TemplateListingAction() {
  const [showTestModal, setShowTestModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <div className={styles.action}>
        <IconButton
          icon={<CodeBlock className={styles.ico} />}
          style="plain"
          onClick={() => setShowTestModal(true)}
        />
        <IconButton
          icon={<ControlPoint className={styles.ico} />}
          style="plain"
        />
        <Dropdown
          btnClass={styles.dropBtn}
          title={
            <MoreOptions className={classNames(styles.ico, styles.optionIco)} />
          }
          items={[
            {
              children: "View Template",
              onClick: () => {},
            },
            {
              children: "Delete",
              onClick: () => setShowDeleteModal(true),
              isDestructive: true,
            },
          ]}
        />
      </div>
      <TemplateSingleSendModal
        isOpen={showTestModal}
        onClose={() => setShowTestModal(false)}
      />
      <TemplateListingDeleteModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
}
