import { Button } from "src/components/Button/Button";
import FeedbackCard from "src/components/FeedbackCard/FeedbackCard";
import styles from "./EmailSent.module.scss";
import emailSent from "../assets/emailSent.svg";

interface Props {
  email?: string;
  resendMail?: () => void;
}

export function EmailSent({ email, resendMail }: Props) {
  return (
    <div className={styles.container}>
      <FeedbackCard
        image={emailSent}
        description={
          <>
            <p className={styles.description}>
              We have sent you email confirmation link to your email
              <strong> {email}</strong>, Please make sure to check your spam
              folder.
            </p>
            <p className={styles.description}>
              If you not got email{" "}
              <Button
                style="plain"
                className={styles.undo}
                onClick={() => resendMail()}
              >
                Resend Email
              </Button>
            </p>
          </>
        }
      />
    </div>
  );
}
