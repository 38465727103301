import { Button } from "src/components/Button/Button";
import styles from "./NotificationMsgDetails.module.scss";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { Link } from "react-router-dom";
import NotificationMsgIcon from "./NotificationMsgIcon";
import { INotification } from "../misc/notification";
import { useMemo } from "react";
import { formatTime } from "src/lib/formatTime";

interface INotificationMsgDetails {
  notification: INotification;
  onDismiss: () => void;
}

export default function NotificationMsgDetails({
  notification,
  onDismiss,
}: INotificationMsgDetails) {
  const formattedDate = useMemo(
    () =>
      formatTime(new Date(notification.createdAt), {
        showTimeWithDate: true,
        showRelativeTimeForToday: false,
      }),
    [notification]
  );

  return (
    <div>
      <NotificationMsgIcon
        status={notification.state}
        icon={notification.iconType}
      />
      <p className={styles.title}>{notification.title}</p>
      <span className={styles.date}>{formattedDate}</span>
      <p className={styles.description}>{notification.description}</p>
      <div className={styles.footer}>
        {notification.actionLabel && (
          <Link to={notification.actionLink}>
            <span className={styles.viewCampaign}>
              {notification.actionLabel}
            </span>
          </Link>
        )}
        <Button onClick={onDismiss} color="subdued" style="plain">
          <span className={styles.dismissBtn}>
            Dismiss
            <Close className={styles.closeIco} />
          </span>
        </Button>
      </div>
    </div>
  );
}
