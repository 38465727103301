import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { Button } from "src/components/Button/Button";
import { IconButton } from "src/components/IconButton/IconButton";
import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Label } from "src/components/Label/Label";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import Select from "src/components/Select/Select";
import styles from "./SmsTemplateDetails.module.scss";
import { useFormikContext } from "formik";

interface ISmsTemplateDetails {
  creationStep: number;
  setCreationStep: (i: number) => void;
}

export function SmsTemplateDetails({
  creationStep,
  setCreationStep,
}: ISmsTemplateDetails) {
  const { setFieldValue, values, handleSubmit } = useFormikContext<any>();

  return (
    <div className={styles.smsDetails}>
      {" "}
      {values.type === "DLT_BASED" && (
        <Label
          label={
            <span className={styles.label}>
              DLT Template ID <Info className={styles.infoIco} />
            </span>
          }
        >
          <InputFormik name="dltId" />
        </Label>
      )}
      <Select
        options={[{ label: "English", value: "English" }]}
        value={values.language}
        onChange={(value) => {
          setFieldValue("language", value);
        }}
        label="Select Language"
      />
      <Label label="Template Body">
        <p className={styles.multiLineText}>
          Enter the text for your message in the language you have selected.
        </p>
        <div className={styles.multiLineWrapper}>
          {/* TODO: Need a better input with variables highlight and edit */}
          <MultilineInput
            name="content"
            value={values.content}
            onChange={(i) => {
              setFieldValue("content", i);
            }}
            rows={6}
          />
          <div className={styles.btnWrapper}>
            <IconButton
              icon={<Add className={styles.ico} />}
              onClick={() => {
                const variables = (values.content as string).match(/{{\d+}}/g);
                console.log({ variables });
                const newVariableCount = variables ? variables.length + 1 : 1;
                setFieldValue(
                  "content",
                  `${values.content} {{${newVariableCount}}}`
                );
              }}
              color="subdued"
              style="outline"
              className={styles.btn}
            />
          </div>
        </div>
      </Label>
      <InputFormik label="Opt-out link" name="optOutLink" disabled />
      <div className={styles.btnGrp}>
        <Button
          color="subdued"
          style="outline"
          onClick={() => setCreationStep(creationStep - 1)}
        >
          Back
        </Button>
        <Button onClick={handleSubmit}>Save and Continue</Button>
      </div>
    </div>
  );
}
