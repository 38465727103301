import { AutomationSidebarFooter } from "./AutomationSidebarFooter";
import { AutomationSidebarHeader } from "./AutomationSidebarHeader";
import { Button } from "src/components/Button/Button";
import FilePreview from "src/components/FilePreview/FilePreview";
import { Label } from "src/components/Label/Label";
import { FilesInput } from "src/modules/global/components/FileUpload/FileUploadSimple";
import styles from "./AutomationUploadEmail.module.scss";
import { useState } from "react";

const MAX_HTML_FILE_SIZE = 102 * 1024;

export function AutomationUploadEmail() {
  const [file, setFile] = useState([]);

  return (
    <div>
      <AutomationSidebarHeader title="Upload Email" onBack={() => {}} />
      <div className={styles.sidebar}>
        <Label label="Upload file here!" />
        <FilesInput
          accept={{ "text/html": [".html"] }}
          files={file}
          setFiles={setFile}
          title="Upload HTML File"
          description="You can upload only 1 file at a time."
          maxSize={MAX_HTML_FILE_SIZE}
          height="16.25rem"
        />
      </div>
      {file.length > 0 && (
        <div className={styles.uploadedFiles}>
          <Label label="Your uploaded email" />
          <ul className={styles.files}>
            {file.map((item, index) => (
              <FilePreview
                file={item}
                action={() => setFile(file.filter((_, i) => index !== i))}
                key={index}
              />
            ))}
          </ul>
        </div>
      )}
      <AutomationSidebarFooter>
        <Button width="full" color="primary">
          Save and upload
        </Button>
      </AutomationSidebarFooter>
    </div>
  );
}
