import {
  AssetContentType,
  MetaActivityAd,
  MetaActivityAdTemplate,
} from "src/graphql/generated/schema";
import adThumbnailImage from "../../campaign-wizard/assets/adThumbnail.svg";

const fallBackThumbnailAsset = {
  uri: adThumbnailImage,
  contentType: "IMAGE" as AssetContentType,
  name: "Fallback",
  id: "FALLBACK",
};

// TODO: handle carousel fallback
// const adThumbnail = {
//   STATIC_IMAGE_OR_VIDEO: adThumbnailImage,
//   CAROUSEL: adCarouselThumbnail,
// };

export function getThumbnailAssetFromAd(
  ad:
    | Pick<MetaActivityAd, "adType" | "LineItems" | "ExistingPost">
    | MetaActivityAdTemplate,
  preview?: "FEED" | "STORY"
) {
  if (ad?.adType === "CUSTOM_CREATIVE") {
    const postAsset =
      ad.LineItems.length > 0 ? ad.LineItems[0].PostAsset : null;
    const storyAsset =
      ad.LineItems.length > 0 ? ad.LineItems[0].StoryAsset : null;
    if (!postAsset) {
      return fallBackThumbnailAsset;
    }

    if (preview === "STORY" && storyAsset) {
      return {
        uri: storyAsset?.uri,
        contentType: storyAsset?.contentType,
        name: storyAsset?.name,
        id: storyAsset.id,
      };
    }

    return {
      uri: postAsset?.uri,
      contentType: postAsset?.contentType,
      name: postAsset?.name,
      id: postAsset.id,
    };
  }

  if (ad?.adType === "EXISTING_POST" && ad?.ExistingPost) {
    const existingPost = ad.ExistingPost;
    return {
      uri: existingPost.url,
      contentType: existingPost.type as AssetContentType,
      name: existingPost.caption,
      id: existingPost.id,
    };
  }

  return fallBackThumbnailAsset;
}
