import classNames from "classnames";
import styles from "./TemplateSingleSmsInfo.module.scss";
import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import Badge from "src/components/Badge/Badge";

export function TemplateSingleSmsInfo() {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <span className={styles.label}>Template Name</span>
        <span className={styles.value}>Sales SMS Template</span>
      </div>
      <div className={styles.tile}>
        <div className={styles.item}>
          <span className={styles.label}>Category</span>
          <span className={styles.value}>Promotional</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Language</span>
          <span className={styles.value}>English</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Status</span>
          <span>
            <Badge label={"In Review"} />
          </span>
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.item}>
          <span className={styles.label}>Template type</span>
          <span className={styles.value}>DLT based</span>
        </div>
        <div>
          <span className={classNames(styles.label, styles.dlt)}>
            DLT ID <Info className={styles.infoIco} />
          </span>
          <span className={styles.value}>09884382910109</span>
        </div>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>Body</span>
        <p className={styles.bodyText}>
          *Our new {2} collection* We have a wide variety of Kurtis to choose
          from, so you're sure to find something that you love. To celebrate the
          launch of our new collection, we're offering a special discount of {1}{" "}
          off.
        </p>
      </div>
    </div>
  );
}
