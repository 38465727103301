import { Link } from "react-router-dom";
import styles from "./Logo.module.scss";
import { ReactComponent as LogoPrimaryFullSVG } from "./macro-logo-primary-full.svg";
import { ReactComponent as LogoWhiteIconSVG } from "./macro-logo-white-icon.svg";
import { ReactComponent as LogoPrimaryIconSVG } from "./macro-logo-primary-icon.svg";

interface ILogo {
  type: "icon" | "full";
  style: "white" | "primary";
  width?: number;
  height?: number;
  goToHomeWhenClicked?: boolean;
}

export default function Logo(props: ILogo) {
  const { type, style, width, height, goToHomeWhenClicked = true } = props;

  const Parent = ({ children }) =>
    goToHomeWhenClicked ? (
      <Link to="/" className={styles.wrapper}>
        {children}
      </Link>
    ) : (
      <div className={styles.wrapper}>{children}</div>
    );

  if (type === "icon" && style === "white") {
    return (
      <Parent>
        <LogoWhiteIconSVG width={width} height={height} />
      </Parent>
    );
  }
  if (type === "icon" && style === "primary") {
    return (
      <Parent>
        <LogoPrimaryIconSVG width={width} height={height} />
      </Parent>
    );
  }
  if (type === "full" && style == "white") {
    // FIXME: add a logo type here; N/A for now
    return <></>;
  }

  if (type === "full" && style == "primary") {
    return (
      <Parent>
        <LogoPrimaryFullSVG width={width} height={height} />
      </Parent>
    );
  }
}
