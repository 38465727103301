import { Checkbox } from "src/components/Checkbox/Checkbox";
import { MetaActivityAdTemplatesQuery } from "src/graphql/generated/schema";
import styles from "./AdTemplateItem.module.scss";
import { CatalogueProductImage } from "../../global/components/CatalogueProductImage";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { checkAdTemplateIsSupported } from "../functions/checkAdTemplateIsSupported";
import classNames from "classnames";
import { ReactComponent as ErrorIcon } from "@material-symbols/svg-600/rounded/error-fill.svg";
import adsCount from "../assets/adCount.svg";
import { getAdTemplateType } from "../functions/getAdTemplateType";

type AdTemplate =
  MetaActivityAdTemplatesQuery["metaActivityAdTemplates"]["edges"][0];

interface IAdTemplateItem {
  metaActivityAdTemplate: AdTemplate;
  selectedAdTemplates: AdTemplate[];
  setSelectedAdTemplates: (i: AdTemplate[]) => void;
}

export function AdTemplateItem({
  selectedAdTemplates,
  setSelectedAdTemplates,
  metaActivityAdTemplate,
}: IAdTemplateItem) {
  const { values } = useFormikContext<ActivityInputV2>();
  const isAdTemplatedSupportedInActivity = checkAdTemplateIsSupported(
    values,
    metaActivityAdTemplate
  );

  const isSelected = !!selectedAdTemplates.find(
    (s) => s.node.id === metaActivityAdTemplate.node.id
  );

  function handleSelect() {
    if (isSelected) {
      const filteredItems = selectedAdTemplates.filter(
        (adTemplate) => adTemplate.node.id !== metaActivityAdTemplate.node.id
      );

      return setSelectedAdTemplates(filteredItems);
    }

    setSelectedAdTemplates([...selectedAdTemplates, metaActivityAdTemplate]);
  }

  return (
    <div
      className={classNames(styles.adTemplateItem, {
        [styles.disabledItem]: !isAdTemplatedSupportedInActivity,
      })}
    >
      <Checkbox
        disabled={!isAdTemplatedSupportedInActivity}
        size="small"
        checked={isSelected}
        onChange={handleSelect}
        value={metaActivityAdTemplate.node.id}
      />
      <div className={styles.mainRow}>
        <Asset metaActivityAdTemplate={metaActivityAdTemplate} />
        <div className={styles.titleContainer}>
          <span
            className={classNames(styles.title, {
              [styles.titleDisabled]: !isAdTemplatedSupportedInActivity,
            })}
          >
            {metaActivityAdTemplate.node.name}
            {!isAdTemplatedSupportedInActivity && (
              <span className={styles.errorIcon}>
                <ErrorIcon fill="var(--color-warning)" width={14} height={14} />
              </span>
            )}
          </span>

          <span className={styles.activityCount}>
            <img src={adsCount} width={24} height={24} alt="ad count" />
            {metaActivityAdTemplate?.node.adsCount || "-"}
          </span>
        </div>
      </div>
      <div className={styles.badgeSection}>
        <span
          className={classNames(styles.badge, {
            [styles.inUseBadge]: metaActivityAdTemplate.node.adsCount > 0,
          })}
        >
          {metaActivityAdTemplate.node.adsCount > 0 ? "In use" : "Not in use"}
        </span>
      </div>
      <div className={styles.adType}>
        {getAdTemplateType(metaActivityAdTemplate.node)}
      </div>
    </div>
  );
}

function Asset({
  metaActivityAdTemplate,
}: {
  metaActivityAdTemplate: AdTemplate;
}) {
  const isCatalogueAd = !!metaActivityAdTemplate.node.metaProductSetId;
  const existingPost = metaActivityAdTemplate.node.ExistingPost;
  const firstLineItem = metaActivityAdTemplate.node.LineItems[0];
  const isExistingPostTemplate = Boolean(
    metaActivityAdTemplate.node.existingPostId && existingPost
  );

  if (isCatalogueAd) {
    return (
      <CatalogueProductImage
        catalogueId={metaActivityAdTemplate.node.catalogueId}
      />
    );
  }

  if (isExistingPostTemplate) {
    // TODO: handle carousel album
    if (existingPost.type === "IMAGE") {
      return (
        <img
          className={styles.creativeThumbnail}
          src={existingPost.url}
          alt="post"
          width={40}
          height={40}
        />
      );
    }

    return (
      <video
        className={styles.creativeThumbnail}
        width={40}
        height={40}
        loop
        muted
      >
        <source src={existingPost.url} />
      </video>
    );
  }

  if (firstLineItem?.PostAsset?.contentType === "IMAGE") {
    return (
      <img
        className={styles.creativeThumbnail}
        width={40}
        height={40}
        alt="post asset"
        src={firstLineItem?.PostAsset.uri}
      />
    );
  }

  if (firstLineItem?.PostAsset?.contentType === "VIDEO") {
    return (
      <video
        className={styles.creativeThumbnail}
        width={40}
        height={40}
        loop
        muted
      >
        <source src={firstLineItem.PostAsset.uri} />
      </video>
    );
  }

  return null;
}
