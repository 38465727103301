import { InputFormik } from "src/components/InputFormik/InputFormik";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";

interface Props {
  name: string;
}

export function AdDescriptionField({ name }: Props) {
  return (
    <InputFormik
      label="Ad Description"
      placeholder="Add additional details of your ad"
      name={name}
    />
  );
}
