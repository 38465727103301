import styles from "./SelectorNoResults.module.scss";
import noResultsFound from "../assets/noResultsFound.png";

export function SelectorNoResults() {
  return (
    <div className={styles.emptyAudience}>
      <img
        src={noResultsFound}
        alt="no results found"
        width={127}
        height={124}
      />
      <h3 className={styles.emptyTitle}>
        Uh-oh! Can't find what you're after?
      </h3>
      <p className={styles.emptyText}>
        Take a second look at your spelling, or start fresh by making a create
        new audience
      </p>
    </div>
  );
}
