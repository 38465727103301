import { FormikContextType } from "formik";

import { FlowItems } from "./CreateAutomationPage";

/**
 * A newItem has onClick which uses setFieldValue() from formik.
 * The selectedIndex is increased by + 1 as "new" FlowItem exist
 * after the normal FlowItem
 */
function addNewVariantItem(
  items: FlowItems[],
  setFieldValue: (name: string, value: any) => void,
  // parentIndex is used for conditional items
  // optionIndex is used for conditional items
  parentIndex?: number,
  optionIndex?: number
): any[] {
  const newArr = [];

  if (items.length === 0) {
    return [
      {
        variant: "new",
        onClick: () => {
          setFieldValue("section", "RULES_ACTION");
          setFieldValue("selectedItem", {
            selectedIndex: 1,
            parentIndex,
            optionIndex,
          });
        },
      },
    ];
  }

  items.map((value, i) => {
    value.id = i;
    const newItem = {
      variant: "new",
      onClick: () => {
        setFieldValue("section", "RULES_ACTION");
        setFieldValue("selectedItem", {
          selectedIndex: i + 1,
          parentIndex,
          optionIndex,
        });
      },
    };

    if (value.variant === "conditional" && "options" in value) {
      newArr.push({
        ...value,
        options:
          value.options && value.options.length > 0
            ? value.options.map((v: FlowItems[], index: number) =>
                addNewVariantItem(v, setFieldValue, i, index)
              )
            : // since conditional values should have least two items in the array
              [
                [
                  {
                    ...newItem,
                    onClick: () => {
                      setFieldValue("section", "RULES_ACTION");
                      setFieldValue("selectedItem", {
                        selectedIndex: 1,
                        parentIndex: i,
                        optionIndex: 0,
                      });
                    },
                  },
                ],
                [
                  {
                    ...newItem,
                    onClick: () => {
                      setFieldValue("section", "RULES_ACTION");
                      setFieldValue("selectedItem", {
                        selectedIndex: 1,
                        parentIndex: i,
                        optionIndex: 1,
                      });
                    },
                  },
                ],
              ],
      });
    } else {
      newArr.push(value, newItem);
    }
  });
  return newArr;
}

function addExit(items: FlowItems[]) {
  const lastItem = items[items.length - 1];
  if ("options" in lastItem) {
    const [rhs, lhs] = lastItem.options;
    if (lastItem.options.length === 0) return;
    addExit(rhs);
    addExit(lhs);
  } else {
    items.push({ variant: "exit" });
  }
}

export function formatFlowItemList(
  flowItems: FlowItems[],
  setFieldValue: (name: string, value: any) => void
): FlowItems[] {
  if (flowItems.length === 0) {
    return flowItems;
  }

  const items = addNewVariantItem(flowItems, setFieldValue);
  // addExit(items);
  return items;
}
