import React, { SVGAttributes } from "react";
import { Listbox } from "@headlessui/react";
import classNames from "classnames";
import styles from "./SelectButton.module.scss";
import { ReactComponent as ArrowDown } from "@material-symbols/svg-600/rounded/keyboard_arrow_down.svg";

interface Option {
  label: string;
  value: string | number;
  icon?: string;
  disabled?: boolean;
}

export interface ISelectButton<T = string> {
  size?: "small" | "medium" | "large";
  label?: React.ReactNode;
  icon?: React.FC<SVGAttributes<any>>;
  onChange: (value: T) => void;
  value: T;
  disabled?: boolean;
  options: Option[];
}

export function SelectButton<T = string>({
  onChange,
  options,
  disabled,
  icon: Icon,
  label,
  value,
  size = "medium",
}: ISelectButton<T>) {
  const iconSize = size === "large" ? 20 : size === "medium" ? 18 : 16;

  return (
    <Listbox
      value={value}
      onChange={onChange}
      disabled={disabled}
      as="div"
      className={styles.selectButton}
    >
      <Listbox.Button
        className={classNames(styles.triggerButton, {
          [styles.triggerButtonSmall]: size === "small",
          [styles.triggerButtonMedium]: size === "medium",
          [styles.triggerButtonLarge]: size === "large",
          [styles.triggerButtonDisabled]: disabled,
        })}
      >
        <div className={classNames(styles.buttonContent)}>
          {!!Icon && (
            <Icon
              width={iconSize}
              height={iconSize}
              className={classNames(styles.icon, {
                [styles.iconDisabled]: disabled,
              })}
            />
          )}
          {!!label && label}
          <ArrowDown
            width={iconSize}
            height={iconSize}
            className={classNames(styles.chevronIcon, {
              [styles.chevronIconDisabled]: disabled,
            })}
          />
        </div>
      </Listbox.Button>
      <Listbox.Options className={styles.selectOptions}>
        {options.map((option) => (
          <Listbox.Option
            className={({ active, selected }) =>
              classNames(styles.selectOption, {
                [styles.selectOptionWithIcon]: option.icon,
                [styles.hoverSelectOption]: active,
                [styles.selectedSelectOption]: selected,
              })
            }
            value={option.value}
          >
            <span className={styles.selectOptionText}>{option.label}</span>
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}
