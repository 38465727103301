import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import styles from "./AudienceBudgetInput.module.scss";
import classNames from "classnames";

export function AudienceBudgetInput({
  value,
  error,
  onBlur,
  onChange,
}: {
  value: string;
  error: string;
  onBlur: () => void;
  onChange: (value: string) => void;
}) {
  return (
    <>
      <div
        className={classNames(styles.budgetInput, {
          [styles.budgetInputError]: error,
        })}
      >
        <div className={styles.inputPrefix}>Daily Budget &#x20B9;</div>
        <input
          className={classNames(styles.input)}
          value={value}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder="100"
        />
        <div className={styles.inputSuffix}>
          <span className={styles.backText}>INR</span>
        </div>
      </div>
      {error && <FormFieldStatus message={error} type="error" />}
    </>
  );
}
