import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import styles from "./AdSelector.module.scss";
import { ReactComponent as DescriptionIcon } from "@material-symbols/svg-600/rounded/description.svg";
import { AdSelectorModal } from "./AdSelectorModal";
import { useContext, useState } from "react";
import { Form, Formik } from "formik";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { AdFieldsShowcase } from "./AdFieldsShowcase";
import { SelectedAdSwitcher } from "./SelectedAdSwitcher";
import { AdPreviewSection } from "./AdPreviewSection";
import { AdEmptyScreen } from "./AdEmptyScreen";
import {
  createFacebookDynamicActivitySchema,
  createFacebookEngagementActivitySchema,
  createFacebookPromotePostSchema,
  createFacebookStaticActivitySchema,
  createFacebookVideoActivitySchema,
} from "../misc/adSchema";
import { FacebookAdPreviewModal } from "./FacebookAdPreviewModal";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { getAdTemplateType } from "../functions/getAdTemplateType";

export function AdSelector({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const [isAdSelectorModalOpen, setIsAdSelectorModalOpen] = useState(false);
  const [isShowingFullPreview, setIsShowingFullPreview] = useState(false);
  const { activeStepIndex, data, markStepAsDone } =
    useContext(StepWizardContext);

  const activity = data;
  const isCatalogueActivity = activity.metaPurpose === "SALES_CATALOG";
  const isVideoEngagementActivity =
    activity.metaPurpose === "VIDEO_THRUPLAY" ||
    activity.metaPurpose === "VIDEO_2_SEC_VIEW";
  const isPromotePostActivity = activity.metaPurpose === "PROMOTE_POST";
  const isEngagementActivity =
    activity.metaPurpose !== "SALES_CATALOG" &&
    activity.metaPurpose !== "SALES_STANDARD";

  const campaignCreateSchema = isPromotePostActivity
    ? createFacebookPromotePostSchema
    : isVideoEngagementActivity
    ? createFacebookVideoActivitySchema
    : isEngagementActivity
    ? createFacebookEngagementActivitySchema
    : isCatalogueActivity
    ? createFacebookDynamicActivitySchema
    : createFacebookStaticActivitySchema;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...(data as ActivityInputV2),
        selectedAdIndex: 0,
      }}
      validationSchema={campaignCreateSchema}
      onSubmit={(values) => {
        markStepAsDone(4, values);
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 3}
            isCompleted={activeStepIndex > 3}
          >
            <section ref={sectionRef} className={styles.section}>
              <div className={styles.header}>
                <div>
                  <h2 className={styles.heading}>
                    Choose the Perfect Ads for your marketing need
                  </h2>
                  <h3 className={styles.subheading}>
                    Marketing creative can be your Ads, Emails, SMS or WhatsApp
                    templates which have created in Library
                  </h3>
                </div>

                {values.Ads?.length && (
                  <div>
                    <ButtonV2
                      onClick={() => {
                        setIsAdSelectorModalOpen(true);
                      }}
                      variant="outline"
                      leftIcon={DescriptionIcon}
                    >
                      Select Ad
                    </ButtonV2>
                  </div>
                )}
              </div>

              <AdSelectorModal
                onSelect={(adTemplates) => {
                  if (values.Ads?.length) {
                    setFieldValue("Ads", [...values.Ads, ...adTemplates]);
                  } else {
                    setFieldValue("Ads", adTemplates);
                  }

                  setIsAdSelectorModalOpen(false);
                }}
                isOpen={isAdSelectorModalOpen}
                onClose={() => setIsAdSelectorModalOpen(false)}
              />

              <div className={styles.adSection}>
                {values.Ads?.length ? (
                  <>
                    <SelectedAdSwitcher
                      onCreateAd={() => setIsAdSelectorModalOpen(true)}
                    />
                    <div className={styles.currentAdEditor}>
                      <div className={styles.currentAdEditorHeader}>
                        <h3 className={styles.currentAdHeaderTitle}>
                          Ad {values?.selectedAdIndex + 1}
                        </h3>
                        <p className={styles.currentAdEditorSubtitle}>
                          {getAdTemplateType(
                            values.Ads?.[values.selectedAdIndex],
                          )}
                        </p>
                      </div>

                      <AdFieldsShowcase />
                    </div>
                  </>
                ) : (
                  <AdEmptyScreen
                    onSelectAd={() => setIsAdSelectorModalOpen(true)}
                  />
                )}

                <AdPreviewSection
                  onExpandPreview={() => {
                    setIsShowingFullPreview(true);
                  }}
                />
                <FacebookAdPreviewModal
                  isOpen={isShowingFullPreview}
                  adIndex={values.selectedAdIndex}
                  values={values}
                  onClose={() => setIsShowingFullPreview(false)}
                />
              </div>

              <div className={styles.buttonGroup}>
                <ButtonV2
                  type="submit"
                  disabled={!values.Ads?.length}
                  onClick={handleSubmit}
                >
                  Save & Continue
                </ButtonV2>
              </div>
            </section>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
