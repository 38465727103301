import { gql } from "@apollo/client";

const WEBSITE_INTEGRATION = gql`
  query websiteIntegration {
    websiteIntegration {
      id
      createdAt
      updatedAt
      isConnected
      domain
      name
    }
  }
`;

export default WEBSITE_INTEGRATION;
