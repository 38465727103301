import { useContext } from "react";
import { ButtonV2 } from "../../../components/ButtonV2/ButtonV2";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import styles from "./ShopifyBillingOverlay.module.scss";
import { ReactComponent as Person } from "@material-symbols/svg-600/rounded/person.svg";
import AuthContext from "src/stores/AuthContext";
import { useShopifyIntegrationQuery } from "src/graphql/generated/schema";
import { Spinner } from "../../../components/Spinner/Spinner";
import { ErrorHandler } from "../../../components/ErrorHandler";
import payBill from "../assets/billingOverlayIllustration.png";

export function ShopifyBillingOverlay() {
  const { logout } = useContext(AuthContext);
  const { data, loading, error } = useShopifyIntegrationQuery();

  if (loading) {
    return <Spinner height="screen" />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const storeAdminLink = `https://${data.shopifyIntegration.shop}/admin`;

  return (
    <div className={styles.page}>
      <PageHeader
        title={"Access Restricted"}
        actions={
          <>
            <ButtonV2 leftIcon={Person} variant="link" onClick={logout}>
              Logout
            </ButtonV2>
          </>
        }
      />
      <div className={styles.billingOverlay}>
        <div className={styles.billingOverlayContent}>
          <div className={styles.billingHeaderAndIllustration}>
            <img
              className={styles.payBillImage}
              src={payBill}
              alt="credit card"
            />
            <h1 className={styles.payBillHeading}>
              Oops! Unpaid bill causing the fuss.
            </h1>
            <p className={styles.payBillCopy}>
              Pay your bills now to fuel the amazing services of Macro and keep
              growing your brand! <br />
              <strong className={styles.bold}>
                Either your shopify store is frozen or the app is uninstalled
              </strong>
            </p>
          </div>

          <div className={styles.invoiceList}>
            <ButtonV2
              target="_blank"
              rel="noopener noreferrer"
              to={storeAdminLink}
              loading={loading}
              size="small"
            >
              Go to Shopify Store
            </ButtonV2>
          </div>
        </div>
      </div>
    </div>
  );
}
