import { Link } from "react-router-dom";
import { ButtonHTMLAttributes } from "react";
import { ButtonV2 } from "../ButtonV2/ButtonV2";

export interface IButton extends Omit<ButtonHTMLAttributes<any>, "style"> {
  children: React.ReactNode;
  to?: string;
  onClick?: (e: any) => void;
  color?: "primary" | "subdued" | "danger" | "normal";
  style?: "solid" | "outline" | "plain";
  size?: "default" | "small" | "micro";
  width?: "auto" | "full";
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  borderRadius?: string;
  // if `to` is passed
  target?: string;
}

/**
 *
 * @deprecated Use ButtonV2
 */
export function Button(props: IButton) {
  const {
    children,
    onClick,
    to,
    className,
    disabled,
    borderRadius = "0.375rem",
    loading = false,
    color = "primary",
    style = "solid",
    width = "auto",
    size = "default",
    type = "button",
    target,
    ...otherProps
  } = props;

  return (
    <ButtonV2
      disabled={disabled}
      isDestructive={color === "danger"}
      variant={style}
      isFullWidth={width === "full"}
      loading={loading}
      onClick={onClick ? () => onClick({}) : undefined}
      target={target}
      to={to}
      type={type}
    >
      {children}
    </ButtonV2>
  );
}
