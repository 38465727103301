import classNames from "classnames";
import { Form, Formik } from "formik";
import styles from "./MagicLogin.module.scss";
import { ReactComponent as Mail } from "@material-design-icons/svg/outlined/mail.svg";
import { Button } from "src/components/Button/Button";
import { Link } from "react-router-dom";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import * as Yup from "yup";

export const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
});

export function MagicLogin() {
  return (
    <div className={styles.formContainer}>
      <h2 className={styles.loginTitle}>Log into your account</h2>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ values, setFieldValue, handleBlur, errors }) => (
          <Form>
            <div
              className={classNames(styles.input, {
                [styles.inputError]: errors.email,
              })}
            >
              <InputFormik
                name="email"
                type="email"
                placeholder="Enter your email"
                icon={Mail}
              />
            </div>
            <Button type="submit" width="full">
              Login using Magic Link
            </Button>
            <p className={styles.passwordLogin}>
              <Link to="/auth/login" className={styles.loginWithPassword}>
                Login using Password
              </Link>
            </p>
          </Form>
        )}
      </Formik>
      <p className={styles.signup}>
        <span>Don't have account? </span>
        <Link to="/auth/signup" className={styles.createAccount}>
          Create account
        </Link>
      </p>
    </div>
  );
}
