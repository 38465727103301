import { AdAssetField } from "./AdAssetField";
import { AdCallToActionLabelField } from "./AdCallToActionLabelField";
import { AdDescriptionField } from "./AdDescriptionField";
import { AdInput, CampaignInput } from "../misc/createCampaignTypes";
import { AdLinkField } from "./AdLinkField";
import { AdTitleField } from "./AdTitleField";
import { Disclosure } from "@headlessui/react";
import { ReactComponent as DragIcon } from "@material-design-icons/svg/outlined/drag_indicator.svg";
import { ReactComponent as DocumentIcon } from "@material-design-icons/svg/outlined/article.svg";
import { ReactComponent as PencilIcon } from "@material-design-icons/svg/outlined/edit.svg";
import { IBanner } from "src/components/Banner/Banner";
import { ReactComponent as MoreIcon } from "@material-design-icons/svg/outlined/more_vert.svg";
import styles from "./AdLineItemField.module.scss";
import Dropdown from "src/components/Dropdown/Dropdown";
import { getIn, useFormikContext } from "formik";
import { Button } from "src/components/Button/Button";
import { ExistingPostThumbnail } from "./ExistingPostThumbnail";
import { AdPhoneNumberField } from "./AdPhoneNumberField";
import { FormikError } from "src/components/FormikError/FormikError";

interface Props {
  ad: AdInput;
  baseKey: string;
  showManageAssetModal: boolean;
  setShowManageAssetModal: (i: number) => void;
  isCatalogueActivity: boolean;
  isVideoEngagementActivity: boolean;
  isCallActivity: boolean;
  isPromotedPostActivity: boolean;
  setErrorBanner: (i: IBanner | null) => void;
  handleChooseExistingPost: () => void;
}

export function AdLineItemField({
  baseKey,
  ad,
  handleChooseExistingPost,
  showManageAssetModal,
  setShowManageAssetModal,
  setErrorBanner,
  isVideoEngagementActivity,
  isCatalogueActivity,
  isCallActivity,
  isPromotedPostActivity,
}: Props) {
  const { values, setFieldValue, errors } = useFormikContext<CampaignInput>();
  const { selectedActivityIndex } = values;
  // const selectedActivity = Activities[selectedActivityIndex];
  const activityKey = `Activities[${selectedActivityIndex}]`;
  const activityValues: CampaignInput["Activities"][number] = getIn(
    values,
    activityKey
  );
  const isSalesConversionActivity =
    activityValues.metaPurpose === "SALES_CATALOG" ||
    activityValues.metaPurpose === "SALES_STANDARD";

  if (!isCallActivity && ad.staticAdType === "EXISTING_POST") {
    return (
      <>
        <ExistingPostField />

        {!isVideoEngagementActivity && (
          <AdCallToActionLabelField
            adType={ad.staticAdType}
            value={ad.callToActionLabel}
            name={`${baseKey}.callToActionLabel`}
          />
        )}

        {(isSalesConversionActivity || isPromotedPostActivity) && (
          <AdLinkField name={`${baseKey}.LineItems[0].link`} />
        )}

        {/* <div className={styles.accountSection}>
          <div className={styles.connectedAccounts}>
            <h3 className={styles.connectedAccountsHeading}>
              Connected Accounts
            </h3>
            <p className={styles.connectedAccountsCopy}>
              This ad will be posted on the following Meta accounts
            </p>
          </div>

          <MetaPageSelector
            disabled={true}
            selectedAdAccountId={activityValues?.adAccountId}
            selectedPageId={activityValues?.pageId}
            setSelectedPageId={(value) => setActivityValue("pageId", value)}
            formKey={`${activityKey}.pageId`}
          />
          <MetaInstagramPageSelector
            disabled={true}
            selectedAdAccountId={activityValues?.adAccountId}
            selectedInstagramPageId={activityValues?.instagramPageId}
            setSelectedInstagramPageId={(value) =>
              setActivityValue("instagramPageId", value)
            }
            formKey={`${activityKey}.instagramPageId`}
          />
        </div> */}
      </>
    );
  }

  if (isVideoEngagementActivity && ad.staticAdType === "CUSTOM_CREATIVE") {
    return (
      <>
        <AdAssetField
          shouldTriggerAssetManageModal={isCallActivity ? false : true}
          lineItemIndex={0}
          assetType={isVideoEngagementActivity ? "VIDEO" : "IMAGE_AND_VIDEO"}
          baseKey={`${baseKey}.LineItems[0]`}
          showManageAssetModal={showManageAssetModal}
          setShowManageAssetModal={setShowManageAssetModal}
          setErrorBanner={setErrorBanner}
          adAccountId={activityValues?.adAccountId}
        />
        <FormikError
          formikError={errors}
          fieldName={`${baseKey}.LineItems[0].StoryAsset`}
        />
        <FormikError
          formikError={errors}
          fieldName={`${baseKey}.LineItems[0].PostAsset`}
        />
      </>
    );
  }

  if (ad.manualAdType === "STATIC_IMAGE_OR_VIDEO" || isCatalogueActivity) {
    return (
      <>
        <AdTitleField name={`${baseKey}.LineItems[0].headline`} />

        <AdDescriptionField name={`${baseKey}.LineItems[0].description`} />

        <AdCallToActionLabelField
          adType={ad.staticAdType}
          value={ad.callToActionLabel}
          name={`${baseKey}.callToActionLabel`}
        />

        {isCallActivity && <AdPhoneNumberField baseKey={baseKey} />}

        {!isCatalogueActivity && (
          <>
            {isSalesConversionActivity && (
              <AdLinkField name={`${baseKey}.LineItems[0].link`} />
            )}

            <AdAssetField
              shouldTriggerAssetManageModal={isCallActivity ? false : true}
              lineItemIndex={0}
              baseKey={`${baseKey}.LineItems[0]`}
              showManageAssetModal={showManageAssetModal}
              setShowManageAssetModal={setShowManageAssetModal}
              setErrorBanner={setErrorBanner}
              adAccountId={activityValues?.adAccountId}
            />
            <FormikError
              formikError={errors}
              fieldName={`${baseKey}.LineItems[0].StoryAsset`}
            />
            <FormikError
              formikError={errors}
              fieldName={`${baseKey}.LineItems[0].PostAsset`}
            />
          </>
        )}
      </>
    );
  }

  function ExistingPostField() {
    return (
      <div className={styles.chooseExistingPostSection}>
        <h3 className={styles.existingPostHeading}>Select Existing Post</h3>
        <p className={styles.existingPostCopy}>
          Add the media and text content for your ad from an already existing
          post on Meta
        </p>
        {ad.existingPostId ? (
          <>
            <ExistingPostThumbnail post={ad.existingPost} />
            <Button
              onClick={handleChooseExistingPost}
              size="micro"
              style="outline"
              color="subdued"
            >
              <span className={styles.btnIcon}>
                Change Post
                <PencilIcon width={16} height={16} fill="currentColor" />
              </span>
            </Button>
          </>
        ) : (
          <Button onClick={handleChooseExistingPost} size="micro">
            <div className={styles.btnIcon}>
              <DocumentIcon width={16} height={16} fill="white" />
              Select Post
            </div>
          </Button>
        )}
      </div>
    );
  }

  function handleLineItemDelete(lineItemIndex: number) {
    const ad: AdInput = getIn(values, baseKey);

    setFieldValue(
      `${baseKey}.LineItems`,
      ad.LineItems.filter((_l, index) => index !== lineItemIndex)
    );
  }

  return (
    <>
      {isCallActivity && <AdPhoneNumberField baseKey={baseKey} />}
      <AdCallToActionLabelField
        adType={ad.staticAdType}
        value={ad.callToActionLabel}
        name={`${baseKey}.callToActionLabel`}
      />

      {ad.LineItems.map((_, lineItemIndex) => (
        <Disclosure defaultOpen={true} as="div" className={styles.carouselCard}>
          <h3 className={styles.carouselTitleWrapper}>
            <Disclosure.Button className={styles.disclosureTrigger}>
              <span className={styles.titleWithDrag}>
                <DragIcon
                  fill="var(--color-disabled)"
                  width={24}
                  height={24}
                  style={{ transform: "rotate(90deg)" }}
                />
                Carousel Ad - {lineItemIndex + 1}
              </span>
            </Disclosure.Button>
            {/* Don't show if there is single lineItem */}
            {ad.LineItems.length > 1 && (
              <Dropdown
                title={
                  <MoreIcon
                    width={24}
                    height={24}
                    fill="var(--color-disabled)"
                  />
                }
                items={[
                  {
                    children: "Delete",
                    onClick() {
                      handleLineItemDelete(lineItemIndex);
                    },
                  },
                ]}
              />
            )}
          </h3>
          <Disclosure.Panel className={styles.disclosurePanel}>
            <AdTitleField
              name={`${baseKey}.LineItems[${lineItemIndex}].headline`}
            />

            <AdDescriptionField
              name={`${baseKey}.LineItems[${lineItemIndex}].description`}
            />

            {isSalesConversionActivity && (
              <AdLinkField
                name={`${baseKey}.LineItems[${lineItemIndex}].link`}
              />
            )}

            <AdAssetField
              baseKey={`${baseKey}.LineItems[${lineItemIndex}]`}
              lineItemIndex={lineItemIndex}
              setErrorBanner={setErrorBanner}
              shouldTriggerAssetManageModal={isCallActivity ? false : true}
              setShowManageAssetModal={setShowManageAssetModal}
              adAccountId={activityValues?.adAccountId}
            />

            <FormikError
              formikError={errors}
              fieldName={`${baseKey}.LineItems[${lineItemIndex}].StoryAsset`}
            />
            <FormikError
              formikError={errors}
              fieldName={`${baseKey}.LineItems[${lineItemIndex}].PostAsset`}
            />
          </Disclosure.Panel>
        </Disclosure>
      ))}
    </>
  );

  // switch (values.objective) {
  //   case "CONVERSIONS":
  //     switch (selectedActivity.facebookActivityType) {
  //       case "META_STANDARD":
  //         switch (ad.staticAdType) {
  //           case "CUSTOM_CREATIVE":
  //             switch (ad.manualAdType) {
  //               case "CAROUSEL":
  //                 break;
  //               case "STATIC_IMAGE_OR_VIDEO":
  //                 return (

  //                 )
  //                 break;
  //             }
  //             break;
  //           case "EXISTING_POST":
  //             break;
  //         }
  //         break;
  //       case "META_DYNAMIC":
  //         break;
  //     }
  //     break;
  //   case "ENGAGEMENT":
  //     switch (selectedActivity.engagementConversionLocation) {
  //       case "CALL":
  //         break;
  //       case "MESSAGE":
  //         break;
  //       case "VIDEO_2_SEC_VIEW":
  //         break;
  //       case "VIDEO_THRUPLAY":
  //         break;
  //     }
  //     break;
  // }
}
