import { InputFormik } from "../../../components/InputFormik/InputFormik";
import { LineHeading } from "../../media/components/LineHeading";
import styles from "./GeneralInformation.module.scss";

export function GeneralInformation() {
  return (
    <div className={styles.wrapper}>
      <LineHeading content={"General"} />
      <div className={styles.container}>
        <InputFormik name={"name"} label={"Organization Name"} />
        <InputFormik name={"email"} label={"Owner Email"} disabled />
      </div>
    </div>
  );
}
