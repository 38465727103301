// import { useContext } from "react";
import styles from "./AudienceQuickOverview.module.scss";
import StatCard from "src/components/StatCard/StatCard";
// import { useTinybirdQuery } from "src/lib/analyticsApi";
import { SectionHeading } from "src/modules/global/components/SectionHeading";
// import AuthContext from "src/stores/AuthContext";
// import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";
// import { convertDatetimeToDate } from "src/modules/global/functions/convertDatetimeToDate";

type IAudienceQuickOverview = {
  insights: any;
  loading?: boolean;
  dateFrom?: string;
  dateTo?: string;
};

export function AudienceQuickOverview({
  insights,
  loading,
}: IAudienceQuickOverview) {
  const quickStats = [
    // {
    //   title: "Estimate Size",
    //   subtitle: "Total size of Audience",
    //   value: 19000,
    //   valueType: "NUMBER" as const,
    // },
    {
      title: "ROAS",
      subtitle: "Return on Ad Spend by Audience",
      value: insights?.roas,
      valueType: "MULTIPLIER" as const,
      // learnMoreText: "asd",
    },
    {
      title: "CTR",
      subtitle: "Click through Rate by Audience",
      value: insights?.ctr,
      valueType: "MULTIPLIER" as const,
    },
    {
      title: "Total Spends",
      subtitle: "Spent on website by Audience",
      value: insights?.spend,
      valueType: "CURRENCY" as const,
    },
    // {
    //   title: "Avg Spends",
    //   subtitle: "Total budget on Audience",
    //   value: insights.spends,
    //   valueType: "CURRENCY" as const,
    // },
  ];

  return (
    <div>
      <SectionHeading heading="Other relevant metrics" />
      <div className={styles.quickOverview}>
        {quickStats.map((item, index) => (
          <div key={index}>
            <StatCard
              loading={loading}
              value={item.value}
              valueType={item.valueType}
              title={item.title}
              subtitle={item.subtitle}
              // learnMoreText={item.learnMoreText}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
