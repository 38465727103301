import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as ExpandMore } from "@material-design-icons/svg/outlined/expand_more.svg";
import { ReactComponent as AccountCircle } from "@material-design-icons/svg/outlined/account_circle.svg";
import { Fragment, useContext } from "react";
import styles from "./LogoutDropdown.module.scss";
import { Button } from "../Button/Button";
import AuthContext from "src/stores/AuthContext";

export default function LogoutDropdown() {
  const { logout } = useContext(AuthContext);
  return (
    <div>
      <Menu as="div" className={styles.menu}>
        <div>
          <Menu.Button className={styles.menuBtn}>
            <AccountCircle className={styles.ico} />
            <ExpandMore className={styles.ico} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter={styles.menuEnter}
          enterFrom={styles.menuEnterFrom}
          enterTo={styles.menuEnterTo}
          leave={styles.menuLeave}
          leaveFrom={styles.menuLeaveFrom}
          leaveTo={styles.menuLeaveTo}
        >
          <Menu.Items className={styles.menuItems}>
            <Menu.Item>
              <Button
                style="plain"
                type="button"
                color="subdued"
                onClick={() => logout()}
              >
                Logout
              </Button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
