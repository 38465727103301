import { Command } from "cmdk";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import styles from "./CommandBar.module.css";

import { ReactComponent as AppsIcon } from "@material-design-icons/svg/outlined/apps.svg";
import { ReactComponent as CampaignIcon } from "@material-design-icons/svg/outlined/campaign.svg";
import { ReactComponent as ChecklistIcon } from "@material-design-icons/svg/outlined/checklist.svg";
import { ReactComponent as SaveIcon } from "@material-design-icons/svg/outlined/save.svg";
import { ReactComponent as AccountCircleIcon } from "@material-design-icons/svg/outlined/account_circle.svg";
import { ReactComponent as AppRegistrationIcon } from "@material-design-icons/svg/outlined/app_registration.svg";
import { ReactComponent as BookIcon } from "@material-design-icons/svg/outlined/book.svg";
import { ReactComponent as CallIcon } from "@material-design-icons/svg/outlined/call.svg";
import { ReactComponent as DashboardCustomizeIcon } from "@material-design-icons/svg/outlined/dashboard_customize.svg";
import { ReactComponent as DescriptionIcon } from "@material-design-icons/svg/outlined/description.svg";
import { ReactComponent as RecentActorsIcon } from "@material-design-icons/svg/outlined/recent_actors.svg";

// campaigns
import commandbarCreateNewCampaign from "../../modules/global/assets/commandbar-create-campaign.svg";
import commandbarViewActiveCampaigns from "../../modules/global/assets/commandbar-view-active-campaigns.svg";
import commandbarViewAllCampaigns from "../../modules/global/assets/commandbar-view-all-audiences.svg";
import commandbarViewDraftCampaigns from "../../modules/global/assets/commandbar-view-draft-campaigns.svg";

// Audiences
import commandbarCreateLookalikeAudience from "../../modules/global/assets/commandbar-create-lookalike-audience.svg";
import {
  default as commandbarCreateAudienceFromTemplate,
  default as commandbarViewAllAudiences,
} from "../../modules/global/assets/commandbar-view-all-audiences.svg";

// Help
import commandbarContactSupport from "../../modules/global/assets/commandbar-contact-support.svg";
import commandbarShareFeedback from "../../modules/global/assets/commandbar-feedback.svg";
import commandbarSearchDocs from "../../modules/global/assets/commandbar-search-docs.svg";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

const campaignItems = [
  {
    to: "/campaigns/new",
    image: {
      alt: "campaign-new",
      src: commandbarCreateNewCampaign,
    },
    icon: <CampaignIcon />,
    label: "Create New Campaign",
  },
  {
    to: "/campaigns",
    image: {
      alt: "campaigns",
      src: commandbarViewAllCampaigns,
    },
    icon: <AppsIcon />,
    label: "View All Campaigns",
  },
  {
    to: "/campaigns?tab=ACTIVE",
    image: {
      alt: "active-campaigns",
      src: commandbarViewActiveCampaigns,
    },
    icon: <ChecklistIcon />,
    label: "View Active Campaigns",
  },
  {
    to: "/campaigns?tab=DRAFT",
    image: {
      alt: "draft-campaigns",
      src: commandbarViewDraftCampaigns,
    },
    icon: <SaveIcon />,
    label: "View Draft Campaigns",
  },
];

const audienceItems = [
  {
    to: "/audiences/template/create",
    image: {
      alt: "audience-template-create",
      src: commandbarCreateAudienceFromTemplate,
    },
    icon: <AppRegistrationIcon />,
    label: "Create Audience From Templates",
  },
  {
    to: "/audiences-sets/new",
    image: {
      alt: "create-audience-set",
      src: commandbarViewAllAudiences,
    },
    icon: <DashboardCustomizeIcon />,
    label: "Create Interest Based Audience",
  },
  {
    to: "/audiences/custom/create",
    image: {
      alt: "audience-advanced-create",
      src: commandbarCreateLookalikeAudience,
    },
    icon: <RecentActorsIcon />,
    label: "Create Lookalike or Custom Audience",
  },
  {
    to: "/audiences",
    image: {
      alt: "audiences",
      src: commandbarViewAllAudiences,
    },
    icon: <AccountCircleIcon />,
    label: "View All Audiences",
  },
];

const helpItems = [
  {
    to: "https://docs.macrostack.co/",
    image: {
      alt: "docs",
      src: commandbarSearchDocs,
    },
    icon: <DescriptionIcon />,
    label: "Search Docs",
  },
  {
    to: "https://macro.featurebase.app/",
    image: {
      alt: "feature-base",
      src: commandbarShareFeedback,
    },
    icon: <BookIcon />,
    label: "Send Feedback",
  },
  {
    to: "https://wa.me/919821476137?text=Hello%21%20I%27m%20using%20macro%20and%20I%20need%20help%20with%20something",
    image: {
      alt: "contact-support",
      src: commandbarContactSupport,
    },
    icon: <CallIcon />,
    label: "Contact Support",
  },
];

export function CommandBar({
  showCommandK,
  setShowCommandK,
}: {
  showCommandK: boolean;
  setShowCommandK: any;
}) {
  const navigate = useNavigate();
  const [value, setValue] = useState("create-new-campaign");
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);
  return (
    <Modal
      onClose={() => setShowCommandK(false)}
      showDismiss={false}
      isOpen={showCommandK}
      className={styles.modal}
      maxWidth="600px"
    >
      <Command
        label="Command Menu"
        value={value}
        onValueChange={(v) => setValue(v)}
      >
        <Command.Input
          ref={inputRef}
          autoFocus
          className={styles.input}
          placeholder="Type a command or search..."
        />
        <Command.List className={styles.commandList}>
          <Command.Empty
            className={classNames(styles.commandItem, styles.commandItemLink)}
          >
            No results found.
          </Command.Empty>

          <Command.Group
            heading="Campaigns"
            className={styles.commandGroupHeading}
          >
            {campaignItems.map((v, i) => (
              <Command.Item
                value={v.to}
                onSelect={() => {
                  navigate(v.to);
                  setShowCommandK(false);
                }}
                key={"campaigns" + i}
                className={classNames(
                  styles.commandItem,
                  styles.commandItemLink
                )}
              >
                {v.icon}
                {v.label}
              </Command.Item>
            ))}
          </Command.Group>

          <Command.Group
            heading="Audiences"
            className={styles.commandGroupHeading}
          >
            {audienceItems.map((v, i) => (
              <Command.Item
                value={v.to}
                onSelect={() => {
                  navigate(v.to);
                  setShowCommandK(false);
                }}
                key={"audiences" + i}
                className={classNames(
                  styles.commandItem,
                  styles.commandItemLink
                )}
              >
                {v.icon}
                {v.label}
              </Command.Item>
            ))}
          </Command.Group>

          {/* External links */}
          <Command.Group heading="Help" className={styles.commandGroupHeading}>
            {helpItems.map((v, i) => (
              <Command.Item
                value={v.to}
                onSelect={() => window.open(v.to, "_blank")}
                key={"help" + i}
                className={classNames(
                  styles.commandItem,
                  styles.commandItemLink
                )}
              >
                {v.icon}
                {v.label}
              </Command.Item>
            ))}
          </Command.Group>
        </Command.List>
      </Command>
    </Modal>
  );
}
