import { campaignFilters, campaignSortBy } from "../misc/campaignFilter";
import { CampaignListingStatusItem } from "./CampaignListingStatusItem";
import activeStatus from "../assets/activeStatus.svg";
import styles from "./CampaignListingStatusGrouped.module.scss";
import { ReactComponent as MotionPhotosPausedIcon } from "@material-symbols/svg-400/outlined/motion_photos_paused.svg";
import { ReactComponent as CancelIcon } from "@material-symbols/svg-400/outlined/cancel-fill.svg";
import {
  ActivityStatusEnum,
  useActivitiesCountQuery,
} from "src/graphql/generated/schema";
import { Spinner } from "../../../components/Spinner/Spinner";
import { CampaignsEmptyState } from "./CampaignsEmptyState";

interface ICampaignListingStatusGrouped {
  filters: campaignFilters;
  sortBy: campaignSortBy;
}

export function CampaignListingStatusGrouped({
  filters,
  sortBy,
}: ICampaignListingStatusGrouped) {
  const { data, loading } = useActivitiesCountQuery({
    variables: {
      filters: {
        statuses: ["ACTIVE", "PAUSED", "ERROR"],
        platforms: ["META", "GOOGLE"],
        ...(filters.createdAt && {
          createdAt: filters.createdAt as any,
        }),
      },
    },
  });
  const statuses = [
    {
      color: "var(--color-success)",
      icon: <img src={activeStatus} width={18} height={18} alt="active icon" />,
      name: "Active",
      value: "ACTIVE" as ActivityStatusEnum,
    },
    {
      color: "var(--color-warning-text)",
      icon: (
        <MotionPhotosPausedIcon
          fill="var(--color-warning-text)"
          width={18}
          height={18}
        />
      ),
      name: "Paused",
      value: "PAUSED" as ActivityStatusEnum,
    },
    {
      color: "var(--color-danger)",
      icon: <CancelIcon fill="var(--color-danger)" width={18} height={18} />,
      name: "Failed",
      value: "ERROR" as ActivityStatusEnum,
    },
  ];

  if (loading) {
    return <Spinner height="screen" />;
  }

  const countByStatus = data.activitiesCountByStatus.reduce(
    (acc, v) => {
      acc[v.key] = v.value;
      return acc;
    },
    {
      ACTIVE: 0,
      ERROR: 0,
      PAUSED: 0,
    },
  );

  if (
    countByStatus.ERROR === 0 &&
    countByStatus.PAUSED === 0 &&
    countByStatus.ACTIVE === 0
  ) {
    return <CampaignsEmptyState />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        {statuses.map((status) => (
          <CampaignListingStatusItem
            color={status.color}
            icon={status.icon}
            name={status.name}
            value={status.value}
            filters={filters}
            sortBy={sortBy}
            activitiesCount={countByStatus[status.value]}
          />
        ))}
      </div>
    </div>
  );
}
