import { Button } from "src/components/Button/Button";
import Modal from "src/components/Modal/Modal";
import styles from "./RevokeIntegrationModal.module.scss";

interface Props {
  isOpen: boolean;
  onClose: (i: boolean) => void;
  onRevokeIntegration: () => void;
  loading?: boolean;
}

export function RevokeIntegrationModal({
  isOpen,
  onClose,
  onRevokeIntegration,
  loading,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth="36.375rem">
      <h2 className={styles.title}>Want to revoke Integration?</h2>
      <p className={styles.subtitle}>
        Removing integration will also stop the syncing and some of the
        functionality may not be available
      </p>
      <div className={styles.btnGrp}>
        <Button
          color="subdued"
          onClick={onClose}
          style="outline"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button color="danger" onClick={onRevokeIntegration} loading={loading}>
          Revoke
        </Button>
      </div>
    </Modal>
  );
}
