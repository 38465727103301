import { Radio } from "src/components/Radio/Radio";
import styles from "./TemplateSingleWhatsappInfo.module.scss";
import { Link } from "react-router-dom";

export function TemplateSingleWhatsappInfo() {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <span className={styles.label}>Template Name</span>
        <span className={styles.value}>Sales Whatsapp Template</span>
      </div>
      <div className={styles.tile}>
        <div className={styles.item}>
          <span className={styles.label}>Category</span>
          <span className={styles.value}>Utility</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Language</span>
          <span className={styles.value}>English</span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Status</span>
          <span className={styles.value}>In Review</span>
        </div>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>Header</span>
        <span className={styles.value}>Hi Customer Name</span>
      </div>
      <div>
        <span className={styles.label}>Body</span>
        <p className={styles.bodyText}>
          Our new collection We have a wide variety of Kurtis to choose from, so
          you're sure to find something that you love. To celebrate the launch
          of our new collection, we're offering a special discount of 20% off. I
          hope you'll take a look at our new collection
        </p>
      </div>
      <div>
        <span className={styles.label}>Footer</span>
        <p className={styles.value}>
          I hope you'll take a look at our new collection
        </p>
      </div>
      <div>
        <span className={styles.label}>Button</span>
        <div className={styles.btnWrapper}>
          <label htmlFor="button" className={styles.radioLabel}>
            <Radio
              label="Call to Action"
              value={""}
              checked={true}
              onChange={function (newChecked: boolean): void {
                throw new Error("Function not implemented.");
              }}
              disabled
            />
            Call to Action
          </label>
          <label htmlFor="button" className={styles.radioLabel}>
            <Radio
              label="Quick Reply"
              value={""}
              checked={false}
              onChange={function (newChecked: boolean): void {
                throw new Error("Function not implemented.");
              }}
              disabled
            />
            Quick Reply
          </label>
        </div>
      </div>
      <div className={styles.item}>
        <span className={styles.value}>Shop Now</span>
        <Link to={"/"} className={styles.link}>
          https://gooogle.com
        </Link>
      </div>
      <div className={styles.item}>
        <span className={styles.value}>Call Here</span>
        <Link to={"/"} className={styles.link}>
          https://gooogle.com
        </Link>
      </div>
    </div>
  );
}
