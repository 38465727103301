import Alert from "src/components/Alert/Alert";
import styles from "./CustomerCreatePage.module.scss";
import { useCustomerShopifyImportMutation } from "src/graphql/generated/schema";
import { useState } from "react";
import { SectionHeading } from "src/modules/global/components/SectionHeading";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { CustomerImportOptions } from "../components/CustomerImportOptions";
import { AddCustomerModal } from "../components/AddCustomerModal";

export function CustomerCreatePage() {
  const [selectedPlatform, setSelectedPlatform] = useState<
    "SHOPIFY" | "CSV" | ""
  >("");
  const [showShopifyImportModal, setShowShopifyImportModal] = useState(false);
  const [customerShopifyImportFunc, { loading }] =
    useCustomerShopifyImportMutation();
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  return (
    <div>
      <AddCustomerModal
        showAddCustomerModal={showAddCustomerModal}
        setShowAddCustomerModal={setShowAddCustomerModal}
      />
      <Alert
        isOpen={showShopifyImportModal}
        onClose={() => setShowShopifyImportModal(false)}
        title="Import Customers"
        primaryAction={{
          children: "Proceed",
          disabled: loading,
          loading: loading,
          onClick: async () => {
            await customerShopifyImportFunc();
            setShowShopifyImportModal(false);
          },
        }}
      >
        <div className={styles.importCustomerModalContent}>
          Import all shopify customers. This may take a while. Any existing
          record (i.e. with same email / phone ) will be merged with imported
          customer
        </div>
      </Alert>
      <PageHeader title="Customer Import" />
      <div className={styles.main}>
        <SectionHeading
          heading="Select source for import"
          description="Choose from where you want to import customers"
        />
        <CustomerImportOptions
          setSelectedPlatform={setSelectedPlatform}
          setShowShopifyImportModal={setShowShopifyImportModal}
          setShowAddCustomerModal={setShowAddCustomerModal}
        />
        {/* <RadioGroup
          value={selectedPlatform}
          onChange={(value) => {
            setSelectedPlatform(value);
          }}
        >
          <div className={styles.list}>
            <CollectionItem
              image={speaker}
              subtitle="These customers are required and are used to create segment based audience."
              title="CSV Import"
              value="CSV"
              size="small"
              action={{
                children: "Manual Import",
                onClick: () => {
                  setSelectedPlatform("CSV");
                  navigate("/customers/import");
                },
              }}
            />
            <CollectionItem
              image={magnet}
              subtitle="These customers are required and are used to create segment based audience."
              title="Shopify"
              value="SHOPIFY"
              size="small"
              action={{
                children: "Import from Shopify",
                onClick: () => {
                  setSelectedPlatform("SHOPIFY");
                  setShowShopifyImportModal(true);
                },
              }}
            />
          </div>
        </RadioGroup> */}
        {/* <div className={styles.supportText}>
          <Help />
          <div>
            Add customers from integrated platform to macro. These customers are
            required and are used to create segment based audience.
          </div>
        </div> */}
      </div>
    </div>
  );
}
