import { useMemo } from "react";
import { MetaActivity } from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { GrowAggregateType } from "src/modules/global/misc/GrowAggregateType";
import styles from "./ActivityOverviewStatCards.module.scss";
import AudienceCount from "@untitled-ui/icons-react/build/esm/Users01";
import DailyBudget from "@untitled-ui/icons-react/build/esm/Wallet02";
import { ReactComponent as Facebook } from "../../../icons/facebook.svg";
import { ReactComponent as Instagram } from "../../../icons/instagram.svg";
import { ReactComponent as MetaActivityType } from "../../../icons/meta-activity-type.svg";
import TotalSpends from "@untitled-ui/icons-react/build/esm/CoinsStacked01";
import classNames from "classnames";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface IActivityOverviewStatCards {
  statData: GrowAggregateType;
  metaActivity: Pick<
    MetaActivity,
    "activityType" | "MetaActivityAdsets" | "adPlacements" | "dailyBudget"
  >;
}

export function ActivityOverviewStatCards({
  statData,
  metaActivity,
}: IActivityOverviewStatCards) {
  const currencyCode = useGetActiveCurrencyCode();
  const facebookPlacementsCount = metaActivity.adPlacements.filter((v) =>
    v.includes("FACEBOOK"),
  ).length;
  const instagramPlacementsCount = metaActivity.adPlacements.filter((v) =>
    v.includes("INSTAGRAM"),
  ).length;

  const stats = useMemo(
    () => [
      {
        label: "Meta Activity Type",
        value: snakeCaseToTitleCase(metaActivity.activityType),
        icon: <MetaActivityType />,
      },
      {
        label: "Audiences Count",
        value: formatNumberByType(
          metaActivity?.MetaActivityAdsets?.length || 0,
          "NUMBER",
        ),
        icon: <AudienceCount />,
      },
      {
        label: "Total Spends",
        value: statData?.spend
          ? formatNumberByType(statData?.spend ?? 0, "CURRENCY", {
              selectedCurrencyCode: currencyCode,
              showDecimal: false,
            })
          : "-",
        icon: <TotalSpends />,
      },
      {
        label: "Daily Budget",
        value: formatNumberByType(metaActivity?.dailyBudget / 100, "CURRENCY", {
          selectedCurrencyCode: currencyCode,
          showDecimal: false,
        }),
        icon: <DailyBudget />,
      },
      {
        label: "Placement",
        value: [
          {
            icon: <Facebook />,
            value: formatNumberByType(facebookPlacementsCount, "NUMBER", {
              showDecimal: false,
            }),
          },
          {
            icon: <Instagram />,
            value: formatNumberByType(instagramPlacementsCount, "NUMBER", {
              showDecimal: false,
            }),
          },
        ],
      },
    ],
    [statData, metaActivity],
  );
  return (
    <div className={styles.statsList}>
      {stats.map((v) => (
        <div className={styles.stat}>
          <div className={styles.label}>{v.label}</div>
          <div className={styles.contentContainer}>
            {Array.isArray(v.value) ? (
              v.value.map((s) => (
                <div
                  className={classNames(
                    styles.valueContainer,
                    styles.isMultiValue,
                  )}
                >
                  {s.icon && <div className={styles.valueIcon}>{s.icon}</div>}
                  <div className={styles.value}>{s.value}</div>
                </div>
              ))
            ) : (
              <div className={styles.valueContainer}>
                {v.icon && <div className={styles.valueIcon}>{v.icon}</div>}
                <div className={styles.value}>{v.value}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
