import { useState } from "react";
import Modal from "src/components/Modal/Modal";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import styles from "./WhatsappTemplateSelectorModal.module.scss";
import { ReactComponent as FilterListIcon } from "@material-symbols/svg-600/rounded/filter_list.svg";
import { ReactComponent as SearchIcon } from "@material-symbols/svg-600/rounded/search.svg";
import { ReactComponent as CloseIcon } from "@material-symbols/svg-600/rounded/close.svg";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-600/rounded/info.svg";
import sortOptions from "src/modules/global/misc/defaultSortOptions";
import { Input } from "src/components/Input/Input";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import Dropdown from "src/components/Dropdown/Dropdown";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import { dateFilterOptions } from "src/modules/global/misc/dateFilterOptions";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Button } from "src/components/Button/Button";
import { WhatsappTemplatesLoader } from "./WhatsappTemplatesLoader";

interface IWhatsappTemplateSelectorModal {
  // FIXME: type
  onSelect: (whatsappTemplate: any) => void;
  isOpen: boolean;
  onClose: (i: boolean) => void;
}

export function WhatsappTemplateSelectorModal({
  onSelect,
  isOpen,
  onClose,
}: IWhatsappTemplateSelectorModal) {
  const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] =
    useState(null);

  const [selectedFilters, setSelectedFilters] = useState({
    searchQuery: "",
    dateFilter: "ALL_TIME" as DateFilter,
    adType: null,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "ASC" | "DESC",
    field: "CREATED_AT" as const,
  });

  function handleSelect() {
    if (!selectedWhatsappTemplate) return;

    onSelect({
      ...selectedWhatsappTemplate.node,
      adTemplateId: selectedWhatsappTemplate.node.id,
    });
    // reset on select
    setSelectedWhatsappTemplate(null);
  }

  return (
    <Modal maxWidth="1200px" onClose={onClose} isOpen={isOpen}>
      <div className={styles.modalHeader}>
        <h2 className={styles.modalHeading}>Select Marketing Creative</h2>
        <button
          onClick={() => onClose(!isOpen)}
          className={styles.closeBtn}
          type="button"
        >
          <CloseIcon fill="var(--color-disabled)" width={24} height={24} />
        </button>
      </div>

      <div className={styles.filters}>
        <Input
          icon={SearchIcon}
          placeholder="Search your audience here"
          label=""
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, searchQuery: value });
          }}
        />
        <SortByDropdown
          sortBy={selectedSortBy}
          setSortBy={setSelectedSortBy}
          sortOptions={sortOptions}
        />
        <Dropdown
          btnClass={styles.filterButton}
          title={
            <span className={styles.filterButtonContent}>
              <FilterListIcon width={18} height={18} />
              <span>Filter</span>
            </span>
          }
          items={[
            {
              children: "All",
              onClick() {
                setSelectedFilters({ ...selectedFilters, adType: null });
              },
            },
            {
              children: "Custom Creative",
              onClick() {
                setSelectedFilters({
                  ...selectedFilters,
                  adType: "CUSTOM_CREATIVE",
                });
              },
            },
            {
              children: "Catalogue",
              onClick() {
                setSelectedFilters({ ...selectedFilters, adType: "CATALOG" });
              },
            },
            {
              children: "Existing Post",
              onClick() {
                setSelectedFilters({
                  ...selectedFilters,
                  adType: "EXISTING_POST",
                });
              },
            },
          ]}
        />
        <DateFilterDropdown
          dateFilter={selectedFilters.dateFilter}
          setDateFilter={(value) => {
            setSelectedFilters({
              ...selectedFilters,
              dateFilter: value,
            });
          }}
          filterOptions={dateFilterOptions}
        />
      </div>

      <div className={styles.templateBodyWrapper}>
        <div id="scrollTargetForInfiniteScroll" className={styles.templateBody}>
          <WhatsappTemplatesLoader
            selectedFilters={selectedFilters}
            selectedSortBy={selectedSortBy}
            selectedWhatsappTemplate={selectedWhatsappTemplate}
            setSelectedWhatsappTemplate={setSelectedWhatsappTemplate}
          />
        </div>
      </div>

      <StickyFooter
        label={
          <span className={styles.label}>
            <InfoIcon width={20} height={20} />
            Marketing creative templates from library, you can select upto 10
            Ads
          </span>
        }
        actions={
          <div className={styles.buttonGroup}>
            <Button
              onClick={onClose}
              color="subdued"
              style="outline"
              size="micro"
            >
              Cancel
            </Button>
            <Button onClick={handleSelect} size="micro">
              Insert Ads
            </Button>
          </div>
        }
      />
    </Modal>
  );
}
