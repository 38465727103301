import { useFormikContext } from "formik";
import shopifyIngress from "../assets/shopifyIngress.svg";
import { ShopifyWizardSettings } from "./ShopifyWizardSettings";
import { FormData } from "../misc/shopifyIntegrationTypes";

export function ShopifyCustomerWizardSettings() {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FormData>();

  const options = [
    {
      value: "contactData.contacts",
      title:
        "Data like contact list, segments, contact profiles, and others will be synced",
      required: true,
      checked: values.contactData.contacts && values.contactData.enabled,
      onChange(newValue: boolean) {
        setFieldTouched("contactData.contacts");
        setFieldValue("contactData.contacts", newValue);
      },
      helpText: "",
    },
    {
      value: "contactData.marketingCommunications",
      title: "Opt-ins on marketing communications like Email, SMS, WhatsApp",
      required: true,
      checked:
        values.contactData.marketingCommunications &&
        values.contactData.enabled,
      onChange(newValue: boolean) {
        setFieldTouched("contactData.marketingCommunications");
        setFieldValue("contactData.marketingCommunications", newValue);
      },
      helpText: "",
    },
    {
      value: "contactData.updateBack",
      title: "Update data in shopify when changed in Macro",
      required: true,
      checked: values.contactData.updateBack && values.contactData.enabled,
      onChange(newValue: boolean) {
        setFieldTouched("contactData.updateBack");
        setFieldValue("contactData.updateBack", newValue);
      },
      helpText:
        "When you make any changes in customer's data in Macro it will be updated in shopify",
    },
  ];

  return (
    <ShopifyWizardSettings
      imageSrc={shopifyIngress}
      isChecked={values.contactData.enabled}
      onChange={(newValue) => {
        setFieldTouched("contactData.enabled");
        setFieldValue("contactData.enabled", newValue);
      }}
      title="Customer Data"
      subtitle="Allow permissions to sync customers from shopify"
      options={options}
    />
  );
}
