import Select from "src/components/Select/Select";
import { useMetaPageWelcomeMessagesQuery } from "src/graphql/generated/schema";

interface IPageWelcomeMessageSelector {
  value: string;
  onChange: (i: string) => void;
}

export function PageWelcomeMessageSelector({
  value,
  onChange,
}: IPageWelcomeMessageSelector) {
  const { data } = useMetaPageWelcomeMessagesQuery({
    variables: {
      filter: {
        take: 20,
      },
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
    },
  });

  const options =
    data && data.metaPageWelcomeMessages
      ? data.metaPageWelcomeMessages.edges.map(({ node }) => ({
          label: node.name,
          value: node.id,
        }))
      : [];

  return (
    <Select
      disabled
      label="Choose a template for beginning the chat after people tap on your ad"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}
