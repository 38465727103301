import { useEffect, useState } from "react";
import styles from "./GrowthOverviewWidget.module.scss";
import { LineGraphV2 } from "./LineGraphV2";
import classNames from "classnames";
import { getGrowAggregateStatsByType } from "../functions/getGrowAggregateStatsByType";
import metaPresetIcon from "src/modules/campaign/assets/metaPresetIcon.png";
import { formatNumberByType } from "../functions/formatNumberByType";
import { GrowAggregateType } from "../misc/GrowAggregateType";
import { camelCaseToTitleCase } from "src/lib/camelCaseToTitleCase";
import { ReactComponent as Help } from "@material-symbols/svg-400/rounded/help.svg";
import { DetailTooltip } from "./DetailTooltip";
import growOverviewDetailTooltip from "../assets/growOverviewDetailTooltip.png";
import { SectionTabs } from "./SectionTabs";
import { useGetActiveCurrencyCode } from "../functions/useGetActiveCurrencyCode";

type Tabs = "TRANSACTIONAL" | "ENGAGEMENT" | "AWARENESS" | "TRAFFIC" | "LEADS";

function GrowthOverviewWidget({ insights, statValues }) {
  const [activeTab, setActiveTab] = useState<Tabs>("TRANSACTIONAL");
  const [selectedGraphKey, setSelectedGraphKey] = useState<
    keyof GrowAggregateType | null | "not_tracked"
  >("totalValuePurchase");

  const selectedCurrencyCode = useGetActiveCurrencyCode();
  const stats = getGrowAggregateStatsByType({
    insights: insights,
    type: activeTab,
  });

  useEffect(() => {
    setSelectedGraphKey(stats[0]?.valueKey);
  }, [activeTab]);

  const tabs = [
    {
      title: "Transactions",
      id: "TRANSACTIONAL",
      subtitle: "Conversion events on your website",
    },
    {
      title: "Traffic",
      id: "TRAFFIC",
      subtitle: "Users visiting your website",
    },
    {
      title: "Engagement",
      id: "ENGAGEMENT",
      subtitle: "Users engaging with your content",
    },
    {
      title: "Awareness",
      id: "AWARENESS",
      subtitle: "Users viewing your content",
    },
    // {
    //   title: "Leads",
    //   id: "LEADS",
    //   subtitle:
    //     "User engagement or actions that indicate potential interest or intent",
    // },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.sectionHeader}>Growth overview</div>
        <div>
          <DetailTooltip
            trigger={<Help width={20} height={20} />}
            placement="BOTTOM_RIGHT"
            title={"About growth overview"}
            description={
              "Growth overview combines all the stats derived from each activities and categories on the basis of their nature. It gives you a holistic view what’s happening in Macro."
            }
            image={{
              src: growOverviewDetailTooltip,
              width: "267px",
              height: "132px",
              alt: growOverviewDetailTooltip,
            }}
          />
        </div>
      </div>
      <div className={styles.container}>
        <SectionTabs
          selectedTab={activeTab}
          setSelectedTab={(e: Tabs) => {
            setActiveTab(e);
          }}
          tabs={tabs}
        />

        <div className={styles.graphArea}>
          <LineGraphV2
            height={175}
            xAxis={{
              dataKey: "time",
              // label: "Date",
            }}
            yAxis={{
              dataKey: selectedGraphKey,
              label: selectedGraphKey,
              tickFormatter: (v) => formatNumberByType(Number(v), "NUMBER"),
              tooltipFormatter: (v) => formatNumberByType(Number(v), "NUMBER"),
            }}
            statLabel={{
              color: "var(--color-primary)",
              dataKey: selectedGraphKey,
              name: camelCaseToTitleCase(selectedGraphKey),
            }}
            statValues={statValues}
            width={"100%"}
          />
        </div>

        <div className={styles.statsArea}>
          {stats.map((v) => (
            <GraphStatButton
              selected={v.valueKey === selectedGraphKey}
              title={v.title}
              value={formatNumberByType(v.value, v.valueType, {
                showDecimal: true,
                selectedCurrencyCode,
              })}
              compareAtValue={"-"}
              onClick={() => {
                setSelectedGraphKey(v.valueKey);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function GraphStatButton({ title, value, compareAtValue, onClick, selected }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.statButton, {
        [styles.selected]: selected,
      })}
    >
      <div className={styles.statTitle}>{title}</div>
      <div>
        <div className={styles.statValue}>{value}</div>
      </div>
      <div className={styles.contribution}>
        <img
          src={metaPresetIcon}
          alt={metaPresetIcon}
          className={styles.contributionIcon}
        />
      </div>
    </button>
  );
}

export default GrowthOverviewWidget;
