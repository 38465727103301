interface Options {
  showDecimal?: boolean;
  separator?: string;
}

export function formatFileSize(sizeInBytes: number, options?: Options) {
  const decimalsToShow = options?.showDecimal ? 2 : 0;
  const separator = options?.separator ?? "";

  if (sizeInBytes / 1000_000_000 > 1) {
    return `${(sizeInBytes / 1000_000_000).toFixed(
      decimalsToShow,
    )}${separator}GB`;
  }

  if (sizeInBytes / 1000_000 > 1) {
    return `${(sizeInBytes / 1000_000).toFixed(decimalsToShow)}${separator}MB`;
  }

  if (sizeInBytes / 1000 > 1) {
    return `${(sizeInBytes / 1000).toFixed(decimalsToShow)}${separator}KB`;
  }

  return `${sizeInBytes} B`;
}
