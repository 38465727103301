import { getImageDimensions } from "./getImageDimensions";
import { getVideoDimensions } from "./getVideoDimensions";

interface Args {
  file: File;
  maxVideoFileSizeInBytes: number;
  maxImageFileSizeInBytes: number;
  supportedMimeTypes: string[];
  minimumHeight?: number;
  minimumWidth?: number;
}

// TODO: separate video and image minimumHeight and minimumWidth
export async function validateFile(args: Args) {
  const {
    file,
    maxImageFileSizeInBytes,
    maxVideoFileSizeInBytes,
    supportedMimeTypes,
  } = args;
  const minimumHeight = args.minimumHeight || 0;
  const minimumWidth = args.minimumWidth || 0;
  const fileSizeInBytes = file.size;

  if (file.type.includes("image")) {
    const { height, width } = await getImageDimensions(file);

    if (height < minimumHeight)
      throw new Error(
        `${file.name} could not be uploaded. The image should be above ${minimumWidth}x${minimumHeight}`
      );
    if (width < minimumWidth)
      throw new Error(
        `${file.name} could not be uploaded. The image should be above ${minimumWidth}x${minimumHeight}`
      );

    if (fileSizeInBytes > maxImageFileSizeInBytes) {
      throw new Error(
        `Image size exceeds maximum limit. Image should be under ${
          maxImageFileSizeInBytes / (1024 * 1024)
        }MB`
      );
    }
  }

  if (file.type.includes("video")) {
    const {
      width,
      height,
      //@ts-ignore
      duration: _duration,
    } = await getVideoDimensions(file);

    if (height < minimumHeight)
      throw new Error(
        `${file.name} could not be uploaded. The video should be above ${minimumWidth}x${minimumHeight}`
      );
    if (width < minimumWidth)
      throw new Error(
        `${file.name} could not be uploaded. The video should be above ${minimumWidth}x${minimumHeight}`
      );

    if (fileSizeInBytes > maxVideoFileSizeInBytes) {
      throw new Error(
        `Video size exceeds maximum limit. Video should be under ${
          maxVideoFileSizeInBytes / (1024 * 1024)
        }MB`
      );
    }
  }

  if (!supportedMimeTypes.includes(file.type)) {
    throw new Error("File type not supported");
  }
}
