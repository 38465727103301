import styles from "./TemplateListingDeleteModal.module.scss";
import Alert from "src/components/Alert/Alert";

interface ITemplateListingDeleteModal {
  isOpen: boolean;
  onClose: () => void;
}

export function TemplateListingDeleteModal({
  isOpen,
  onClose,
}: ITemplateListingDeleteModal) {
  return (
    <Alert
      isOpen={isOpen}
      onClose={onClose}
      title="Looks like you are deleting?"
      primaryAction={{
        children: "Yes delete it",
        color: "danger",
        size: "small",
      }}
      secondaryAction={{
        children: "Cancel",
        color: "subdued",
        style: "outline",
        size: "small",
        onClick: () => {
          onClose();
        },
      }}
    >
      <div>
        <p className={styles.text}>
          This is non reversible actions once done can't be undo. Are you sure?
        </p>
      </div>
    </Alert>
  );
}
