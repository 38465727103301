import React, { useState, MutableRefObject } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "../functions/canvasPreview";
import { useDebounceEffect } from "../functions/useDebounceEffect";
import styles from "./AssetEditor.module.scss";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

interface IAssetEditor {
  imageUrl: string;
  setCompletedCrop: (i: PixelCrop) => void;
  completedCrop: PixelCrop;
  aspectRatio: number;
  imgRef: MutableRefObject<HTMLImageElement>;
  previewCanvasRef: MutableRefObject<HTMLCanvasElement>;
}

export function AssetEditor({
  imageUrl,
  aspectRatio,
  setCompletedCrop,
  completedCrop,
  imgRef,
  previewCanvasRef,
}: IAssetEditor) {
  const [crop, setCrop] = useState<Crop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    200,
    [completedCrop],
  );

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectRatio) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspectRatio));
    }
  }

  return (
    <div className={styles.editor}>
      <div className={styles.editorControls}>
        <div className={styles.editorControl}>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="range"
            min="1"
            max="3"
            step="0.1"
            value={scale}
            disabled={!imageUrl}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div className={styles.editorControl}>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            min="0"
            max="360"
            step="1"
            type="range"
            value={rotate}
            disabled={!imageUrl}
            onChange={(e) =>
              setRotate(Math.min(360, Math.max(0, Number(e.target.value))))
            }
          />
        </div>
      </div>
      <div className={styles.editorView}>
        {!!imageUrl && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspectRatio}
            minWidth={400}
            minHeight={200}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imageUrl}
              className={styles.editorImage}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        {completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              position: "absolute",
              left: -9999,
              top: -9999,
              pointerEvents: "none",
              visibility: "hidden",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
    </div>
  );
}
