import { useContext, useEffect, useState } from "react";
import leftOverviewImage from "../assets/CampaignOverviewLeft.png";
import rightOverviewImage from "../assets/CampaignOverviewRight.png";
import OverviewBanner from "src/components/OverviewBanner/OverviewBanner";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { IStatCard } from "src/components/StatCard/StatCard";
import {
  CampaignFilterEnum,
  useCampaignsByIdLazyQuery,
  useCampaignsQuery,
  useIntegrationsStatusQuery,
} from "src/graphql/generated/schema";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import CodewordResourcesList from "src/modules/dashboard/components/CodewordResourcesList/CodewordResourcesList";
import AuthContext from "src/stores/AuthContext";
import styles from "./CampaignOverviewPage.module.scss";
import OverviewIntegrationCards from "../../global/components/OverviewIntegrationCards";
import { checkIntegrationStatus } from "../../global/functions/checkIntegrationStatus";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { CampaignOverviewContent } from "../components/CampaignOverviewContent";

export const itemsPerPage = 5;
export type StatCardKeys =
  | "orderCount"
  | "sumAmount"
  | "avgAmount"
  | "conversionRate";

export const statCardPropByKeys: Record<
  StatCardKeys,
  Omit<IStatCard, "value">
> = {
  orderCount: {
    title: "Total Orders",
    subtitle: "Total count of orders placed",
    valueType: "NUMBER",
  },
  sumAmount: {
    title: "Total Revenue",
    subtitle: "Total revenue generated by campaigns",
    valueType: "CURRENCY",
  },
  avgAmount: {
    title: "Avg. Order Value",
    subtitle: "Average of revenue generated per campaign",
    valueType: "CURRENCY",
  },
  conversionRate: {
    title: "Conversion Rate",
    subtitle: "Total Conversion rate",
    valueType: "PERCENTAGE",
  },
};

export default function CampaignOverviewPage() {
  const [selectedCampaignsTab, setSelectedCampaignsTab] =
    useState<CampaignFilterEnum>("ACTIVE");
  const [stats, setStats] = useState<Record<StatCardKeys, number>>({
    orderCount: 0,
    sumAmount: 0,
    avgAmount: 0,
    conversionRate: 0,
  });
  const { activeClient } = useContext(AuthContext);
  const { userToken } = useContext(AuthContext);
  const { data: campaignData, loading: campaignLoading } = useCampaignsQuery({
    variables: {
      filters: {
        limit: itemsPerPage,
        filter: selectedCampaignsTab,
      },
    },
  });

  const { data: integrationsStatusData, loading: integrationStatusLoading } =
    useIntegrationsStatusQuery();

  const {
    data: tinybirdTopCampaignsData,
    // error: tinybirdTopError
  } = useTinybirdQuery("top_campaigns", userToken);

  const {
    data: tinybirdWebsiteConversionsData,
    // error: tinybirdWebsiteConversionsError,
  } = useTinybirdQuery("website_conversion", userToken);

  const {
    data: tinybirdWebsiteAggData,
    // error: tinybirdWebsiteAggError
  } = useTinybirdQuery("website_aggregate", userToken, {
    actions: ["view_page", "place_order"],
  });

  const [
    campaignsByIdFunc,
    {
      data: campaignsData,
      loading: campaignsLoading,
      // error: audiencesError
    },
  ] = useCampaignsByIdLazyQuery();

  const integrationStatus = checkIntegrationStatus(integrationsStatusData);

  useEffect(() => {
    if (integrationStatusLoading) return;
    if (integrationStatus !== "ACTIVE") return;

    if (!tinybirdTopCampaignsData) return;
    if (tinybirdTopCampaignsData.length === 0) return;
    const getCampaignsById = async (ids: string[]) => {
      await campaignsByIdFunc({
        variables: {
          ids: ids,
        },
      });
    };

    getCampaignsById(tinybirdTopCampaignsData.map((v: any) => v.campaignId));
  }, [tinybirdTopCampaignsData, integrationStatus, integrationStatusLoading]);

  useEffect(() => {
    if (integrationStatusLoading) return;
    if (integrationStatus !== "ACTIVE") return;

    if (!tinybirdWebsiteConversionsData) return;
    if (!tinybirdWebsiteAggData) return;
    if (tinybirdWebsiteConversionsData.length === 0) return;
    if (tinybirdWebsiteAggData.length === 0) return;

    const placeOrderData = tinybirdWebsiteAggData.find(
      (v: any) => v.action === "place_order"
    );
    const viewPageData = tinybirdWebsiteAggData.find(
      (v: any) => v.action === "view_page"
    );
    const formattedStats: typeof stats = {
      ...tinybirdWebsiteConversionsData[0],
      conversionRate:
        placeOrderData && viewPageData
          ? Math.ceil(
              (placeOrderData.actionCount / viewPageData.actionCount) * 100
            )
          : 0,
    };
    setStats(formattedStats);
  }, [
    tinybirdWebsiteConversionsData,
    tinybirdWebsiteAggData,
    integrationStatusLoading,
    integrationStatus,
  ]);

  if (integrationStatusLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader title={"Campaign Overview"}></PageHeader>
      <div className={styles.page}>
        <OverviewBanner
          title={"Tailor Campaigns with Precision, Amplify Impact!"}
          description={
            "From defining your audience to choosing compelling creatives, every step is simplified for a seamless experience and impact"
          }
          bgGradient="linear-gradient(180deg, #FFF 0%, rgba(204, 226, 251, 0.24) 53.91%, #FFF 100%)"
          link="/campaigns/new"
          leftImage={leftOverviewImage}
          rightImage={rightOverviewImage}
          action={{
            children: "Launch New Campaign",
          }}
        />
        {integrationStatus !== "ACTIVE" ? (
          <OverviewIntegrationCards integrationStatus={integrationStatus} />
        ) : (
          <CampaignOverviewContent
            stats={stats}
            campaignData={campaignData}
            topCampaignsData={campaignsData}
            selectedCampaignsTab={selectedCampaignsTab}
            setSelectedCampaignsTab={setSelectedCampaignsTab}
            campaignLoading={campaignLoading}
            topCampaignsLoading={campaignsLoading}
          />
        )}

        <div>
          <CodewordResourcesList />
        </div>
      </div>
    </>
  );
}
