import { Input } from "../../../components/Input/Input";
import styles from "./OrganizationMembersTableHeader.module.scss";
import { ReactComponent as Search } from "@material-symbols/svg-600/rounded/search.svg";
import { SortByInput } from "../../../graphql/generated/schema";
import { Tabs } from "../../../components/Tabs/Tabs";

export function OrganizationMembersTableHeader({
  filter,
  setFilter,
  selectedTab,
  setSelectedTab,
}: {
  sortBy: SortByInput;
  setSortBy: (i: SortByInput) => void;
  filter: any;
  setFilter: (i: any) => void;
  selectedTab: "MEMBERS" | "INVITES";
  setSelectedTab: (i: "MEMBERS" | "INVITES") => void;
}) {
  return (
    <div className={styles.tableHeader}>
      <Tabs
        tabs={[
          {
            value: "MEMBERS",
            label: "Members",
          },
          {
            value: "INVITES",
            label: "Invites",
          },
        ]}
        selectedTab={selectedTab}
        onTabChange={(i) => {
          setSelectedTab(i);
        }}
      />
      <Input
        icon={Search}
        placeholder="Search client"
        width="auto"
        value={filter.name}
        onChange={(value) =>
          setFilter({
            ...filter,
            name: value,
          })
        }
      />
      {/*
        <SortByDropdown
          sortOptions={defaultSortOptions}
          sortBy={sortBy}
          setSortBy={(i) => {
            setSortBy(i);
          }}
        />
        <DateFilterDropdown
          showLabel={true}
          setDateFilter={function (i: DateFilter): void {
            setFilter({
              ...filter,
              createdAt: i,
            });
          }}
          dateFilter={filter.createdAt}
        />
      */}
    </div>
  );
}
