import { ReactRenderer } from "@tiptap/react";
import tippy, { Instance } from "tippy.js";
import { AutocompleteList } from "./AutocompleteList";
import { Editor, Range } from "@tiptap/core";

export interface ISuggestion {
  label: string;
  value?: string;
  command?: ({ editor, range }: { editor: Editor; range: Range }) => void;
}

// TODO : Toggle popup
export function Suggestion(suggestions: ISuggestion[]) {
  return {
    items: ({ query }) => {
      return suggestions.filter((item) =>
        item.label.toLowerCase().startsWith(query.toLowerCase())
      );
    },

    render: () => {
      let reactRenderer;
      let popup: Instance<any>[];

      return {
        onStart: (props) => {
          if (!props.clientRect) {
            return;
          }

          reactRenderer = new ReactRenderer(AutocompleteList, {
            props,
            editor: props.editor,
          });

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props) {
          reactRenderer.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: { event: KeyboardEvent }) {
          if (props.event.key === "Escape") {
            popup[0].hide();
            return true;
          }

          return reactRenderer.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          reactRenderer.destroy();
        },
      };
    },
  };
}
