import Balancer from "react-wrap-balancer";
import styles from "./UpgradePlanCard.module.scss";
import classNames from "classnames";

export interface IUpgradePlanCard {
  icon: React.ReactNode;
  title?: string;
  subtitle?: string;
  primaryColor?: "engage" | "grow";
}

export function UpgradePlanCard({
  icon,
  title,
  subtitle,
  primaryColor = "engage",
}: IUpgradePlanCard) {
  return (
    <div className={styles.cardWrapper}>
      <span className={classNames(styles.iconWrapper, styles[primaryColor])}>
        {icon}
      </span>
      <div className={classNames(styles.card, styles[primaryColor])}>
        <span className={styles.title}>{title}</span>
        <p className={styles.subtitle}>
          <Balancer>{subtitle}</Balancer>
        </p>
      </div>
    </div>
  );
}
