import { MetaActivityAdTemplatesQuery } from "src/graphql/generated/schema";
import { ActivityInputV2 } from "../misc/createCampaignTypes";

export function checkAdTemplateIsSupported(
  activity: ActivityInputV2,
  ad: MetaActivityAdTemplatesQuery["metaActivityAdTemplates"]["edges"][0],
) {
  switch (activity.metaPurpose) {
    case "SALES_CATALOG":
      return ad.node.adType === "CATALOG";
    case "SALES_STANDARD":
    case "CALL":
    case "MESSAGE_INSTAGRAM":
    case "MESSAGE_MESSENGER":
    case "MESSAGE_WHATSAPP":
    case "WEBSITE_VIEWS":
      return ad.node.adType !== "CATALOG";
    case "PROMOTE_POST":
      return ad.node.adType === "EXISTING_POST";
    case "VIDEO_2_SEC_VIEW":
    case "VIDEO_THRUPLAY": {
      // catalog are incompatible
      if (ad.node.adType === "CATALOG") {
        return false;
      }

      if (ad.node.adType === "EXISTING_POST") {
        // only video existing post
        if (ad.node.ExistingPost?.type === "VIDEO") {
          return true;
        }

        return false;
      }

      if (ad.node.adType === "CUSTOM_CREATIVE") {
        // carousel are not supported
        if (ad.node.LineItems.length !== 1) {
          return false;
        }

        // multiple placements not supported
        if (ad.node.LineItems[0].StoryAsset) {
          return false;
        }

        // assets should be a video
        if (ad.node.LineItems[0].PostAsset.contentType !== "VIDEO") {
          return false;
        }

        return true;
      }

      return false;
    }
  }
}
