// import toFixed from "../../../lib/toFixed";

export function getAspectRatio(width: number, height: number): string {
  // NOTE: calculates decimal ratio
  // let aspectRatio: number;
  //
  // if (width > height) {
  //   aspectRatio = width / height;
  // } else {
  //   aspectRatio = height / width;
  // }
  //
  // return toFixed(aspectRatio, 1);

  const gcd = (x: number, y: number): number => {
    return !y ? x : gcd(y, x % y);
  };

  const gcdResult = gcd(width, height);

  return `${width / gcdResult}:${height / gcdResult}`;
}
