import EmptyState from "src/components/EmptyState/EmptyState";
import screenSizeNotSupported from "../assets/screenSizeUnsupported.png";
import styles from "./ScreenNotSupported.module.scss";

export function ScreenNotSupported() {
  return (
    <div className={styles.layout}>
      <EmptyState
        title={"Screen size unsupported"}
        illustration={screenSizeNotSupported}
      >
        <p className={styles.subtitle}>
          We do not currently support this size.
        </p>
      </EmptyState>
    </div>
  );
}
