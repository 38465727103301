import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { LineHeading } from "src/modules/media/components/LineHeading";
import Select from "src/components/Select/Select";
import { ReactComponent as ArticleIcon } from "@material-symbols/svg-600/rounded/article.svg";
import { useFormikContext } from "formik";
import styles from "./CatalogueFieldsShowcase.module.scss";
import { AdCatalogueSelector } from "../../global/components/AdCatalogueSelector";
import { MetaCatalogueProductSetSelector } from "src/modules/global/components/MetaCatalogueProductSetSelector";

interface ICatalogueFieldsShowcase {
  selectedAdIndex: number;
  selectedAd: AdInputV2;
}

export function CatalogueFieldsShowcase({
  selectedAd,
  selectedAdIndex,
}: ICatalogueFieldsShowcase) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  return (
    <div className={styles.adFields}>
      <MultilineInput
        disabled={true}
        showCharacterCount={true}
        maxCharacterCount={125}
        value={selectedAd.primaryText}
        onChange={(value) => {
          setFieldValue(`Ads[${selectedAdIndex}].primaryText`, value);
        }}
        label="Primary Text"
        rows={2}
      />
      <InputFormik
        disabled={true}
        name={`Ads[${selectedAdIndex}].LineItems[0].headline`}
        label="Ad Title"
        maxCharacterCount={40}
        showCharacterCount={true}
      />
      <InputFormik
        disabled={true}
        name={`Ads[${selectedAdIndex}].LineItems[0].description`}
        label="Ad Description"
        maxCharacterCount={40}
        showCharacterCount={true}
      />

      <div className={styles.catalogueFields}>
        <AdCatalogueSelector
          adAccountId={values.adAccountId}
          value={selectedAd.catalogueId}
          onChange={(value) => {
            setFieldValue("catalogueId", value.id);
          }}
        />
        <MetaCatalogueProductSetSelector
          catalogueId={selectedAd.catalogueId}
          onChange={(value) => {
            setFieldValue("metaProductSetId", value);
          }}
          value={selectedAd.metaProductSetId}
        />
      </div>

      <LineHeading
        icon={<ArticleIcon width={14} height={14} />}
        content="Action"
      />
      <div className={styles.linksSection}>
        <Select
          label="Call to action*"
          value={selectedAd.callToActionLabel}
          onChange={(value) => {
            setFieldValue(`Ads[${selectedAdIndex}].callToActionLabel`, value);
          }}
          options={[{ label: "Learn More", value: "LEARN_MORE" }]}
        />
      </div>
    </div>
  );
}
