import { useEffect, useRef, useState } from "react";
import { ReactComponent as OpenInBrowser } from "@material-design-icons/svg/outlined/open_in_browser.svg";
import { ProgressBar } from "../../../../components/ProgressBar/ProgressBar";
import classNames from "classnames";
import { formatFileSize } from "src/lib/formatFileSize";
import styles from "./FileUploadSimple.module.scss";
import { useDropzone } from "react-dropzone";

interface IFilesInput {
  setFiles: (i: any[]) => void;
  files: File[];
  showErrors?: boolean;
  onError?: (err: any) => void;
  disabled?: boolean;
  accept?: Record<string, any>;
  title?: React.ReactNode;
  description?: string;
  maxFiles?: number;
  /**
   * maxSize is bytes
   */
  maxSize: number;
  height?: string;
  showLoading?: boolean;
  loading?: boolean;
}

export function FilesInput({
  setFiles,
  files,
  accept = {
    "image/*": [],
  },
  maxSize,
  height = "auto",
  maxFiles = 1,
  description = "Files (PNG, JPG) | Max size : 20MB",
  disabled = false,
  title,
  onError,
  showErrors = true,
  showLoading = false,
  loading,
}: IFilesInput) {
  const {
    fileRejections,
    getRootProps,
    acceptedFiles,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDropAccepted: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
    disabled: disabled,
    maxFiles: maxFiles,
    maxSize: maxSize,
  });
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef<NodeJS.Timeout | null>(null);

  // TODO: use in built onError instead
  useEffect(() => {
    if (onError && fileRejections.length > 0) {
      onError(fileRejections);
    }
  }, [fileRejections, onError]);

  useEffect(() => {
    if (loading) {
      progressInterval.current = setInterval(() => {
        setProgress((prev) => (prev >= 90 ? prev : prev + 5));
      }, 500);
    } else {
      setProgress(0);
      clearInterval(progressInterval.current);
    }

    return () => {
      setProgress(0);
      clearInterval(progressInterval.current);
    };
  }, [loading]);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.container, {
          [styles.containerDisabled]: disabled,
          [styles.acceptStyle]: isDragAccept,
          [styles.rejectStyle]:
            isDragReject || onError || fileRejections.length > 0,
        })}
        style={{ height: height }}
      >
        <div className={styles.fileInputLabel} {...getRootProps()}>
          <OpenInBrowser
            className={classNames(styles.icon, {
              [styles.acceptStyle]: isDragAccept,
            })}
          />
          <input {...getInputProps()} />
          <div className={styles.uploadAreaContent}>
            <div className={styles.uploadAreaHeader}>{title}</div>
            {description && (
              <div
                className={classNames(styles.uploadAreaFooter, {
                  [styles.acceptStyle]: isDragAccept,
                })}
              >
                {description}
              </div>
            )}
          </div>
        </div>
      </div>
      {showLoading &&
        loading && // TODO: Support for Multiple Files
        acceptedFiles.map((item, idx) => (
          <div key={idx} className={styles.progressCard}>
            <p className={styles.progressFileName}>{item.name}</p>
            <span className={styles.progressFileSize}>
              {formatFileSize(item.size)}
            </span>
            <ProgressBar width={progress} />
          </div>
        ))}
      {showErrors && fileRejections.length > 0 && (
        <p className={styles.error}>{fileRejections[0].errors[0].message}</p>
      )}
    </div>
  );
}
