import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { ReactComponent as KeyboardArrowRight } from "@material-design-icons/svg/outlined/keyboard_arrow_right.svg";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import styles from "./MonitorReturns.module.scss";
import { GraphCard } from "src/modules/campaign/components/GraphCard";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Tooltip from "src/components/Tooltip/Tooltip";
import { GrowAggregateType } from "src/modules/global/misc/GrowAggregateType";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface MonitorReturnStat {
  label: string;
  id: string;
  tooltipText: string;
  value: string;
  valueKey: string;
}

export default function MonitorReturns({
  insights,
  statValues,
}: {
  insights: GrowAggregateType;
  statValues: any[];
}) {
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const stats = useMemo(
    () => [
      {
        label: "ROAS",
        id: "ROAS",
        tooltipText:
          "ROAS (Return on Ad Spend) summarizes overall campaign performance at a macro level. For specific campaign ROAS, check 'View platform based splits'.",
        value: formatNumberByType(insights?.roas, "MULTIPLIER", {
          showDecimal: true,
        }),
        valueKey: "roas",
      },
      {
        label: "CAC",
        id: "CAC",
        tooltipText: "CAC tooltip text",
        value: formatNumberByType(0, "CURRENCY", {
          showDecimal: true,
          selectedCurrencyCode,
        }),
        valueKey: "not_tracked",
      },
      {
        label: "Avg. LTV",
        id: "LTV",
        tooltipText: "Avg lifetime value",
        value: formatNumberByType(0, "NUMBER"),
        valueKey: "not_tracked",
      },
      {
        label: "CPM",
        id: "CPM",
        tooltipText: "cost per miles",
        value: formatNumberByType(insights?.cpm, "CURRENCY", {
          showDecimal: true,
          selectedCurrencyCode,
        }),
        valueKey: "cpm",
      },
      {
        label: "CPA",
        id: "CPA",
        tooltipText: "Cost per acquisition",
        value: formatNumberByType(0, "CURRENCY", {
          showDecimal: true,
          selectedCurrencyCode,
        }),
        valueKey: "not_tracked",
      },
    ],
    [insights],
  );

  const [selectedStat, setSelectedStat] = useState<MonitorReturnStat>(stats[0]);

  useEffect(() => {
    setSelectedStat(stats[0]);
  }, [stats]);

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeading}>
        Monitor your returns and value
      </div>

      <div className={styles.container}>
        <div className={styles.statsContainer}>
          <div className={styles.statsList}>
            {stats.map((v) => (
              <button
                type="button"
                className={classNames(styles.stats, {
                  [styles.statsActive]: selectedStat.id === v.id,
                })}
                onClick={() => setSelectedStat(v)}
              >
                <div className={styles.statsTitle}>
                  <span>{v.label}</span>
                  <Tooltip
                    placement="BOTTOM_RIGHT"
                    children={
                      <Info height={16} width={16} fill="currentColor" />
                    }
                    supportingText={v.tooltipText}
                  />
                </div>
                <div className={styles.statValue}>
                  <span>{v.value}</span>
                  {v.value !== "-" && (
                    <span>
                      <KeyboardArrowRight
                        height={18}
                        width={18}
                        fill={"currentColor"}
                      />
                    </span>
                  )}
                </div>
              </button>
            ))}
          </div>
          <div className={styles.action}>
            <Info height={14} width={14} fill={"currentColor"} />
            <div>Aggregated values based on Macro Attributes</div>
            {/*
            <Button to="/campaigns" color="primary" style="plain">
              View platform based stats
            </Button>
            */}
          </div>
        </div>
        <GraphCard
          graphClassName={styles.graph}
          cardClassName={styles.graphSection}
          title={selectedStat.label}
          subtitle={"As per business growth"}
          value={selectedStat.value}
          chartData={statValues}
          chartDataKey={selectedStat.valueKey}
          yAxis={{
            tooltipFormatter: (value) =>
              formatNumberByType(Number(value), "MULTIPLIER"),
            label: selectedStat.label,
            dataKey: selectedStat.valueKey,
            hide: true,
          }}
        />
      </div>
    </div>
  );
}
