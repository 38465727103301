import { Button } from "../../Button/Button";
import EmptyState from "../../EmptyState/EmptyState";
import styles from "./AutomationEmptyState.module.scss";
import emptyAutomation from "./emptyAutomation.png";

export function AutomationEmptyState() {
  return (
    <div className={styles.emptyPage}>
      <EmptyState
        image={
          <img
            src={emptyAutomation}
            alt="emptyAutomation"
            width={313}
            height={200}
          />
        }
        title="Create Automation Flow"
        children={
          <>
            <p className={styles.description}>
              Right now there is no activity to show.
            </p>
            <div className={styles.btnGroup}>
              <Button>Create from Scratch</Button>

              <Button style="outline" color="subdued">
                Choose from Template
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
}
