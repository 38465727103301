import { useFormikContext } from "formik";

import { InputFormik } from "src/components/InputFormik/InputFormik";
import Select from "src/components/Select/Select";
import { OrganizationAddress, User } from "src/graphql/generated/schema";
import { LineHeading } from "../../media/components/LineHeading";
import styles from "./ContactInformation.module.scss";

interface ContactInformation {
  user: User;
  address: OrganizationAddress;
}

const countryOptions = [
  {
    value: "IN",
    label: "India",
  },
  {
    value: "US",
    label: "United States",
  },
  {
    value: "PK",
    label: "Pakistan",
  },
];

export function ContactInformation() {
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <div>
      <div className={styles.section}>
        <LineHeading content={"Contact information"} />
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <div className={styles.spanTwo}>
              <InputFormik name="address" label="Street address" disabled />
            </div>
            <InputFormik name="city" label="City" disabled />
            <InputFormik name="state" label="State" disabled />
            <Select
              label="Country"
              value={values.countryCode}
              onChange={function (i: any): void {
                const country = countryOptions.find((v) => v.value === i);
                setFieldValue("countryCode", i);
                setFieldValue("country", country.label);
              }}
              options={countryOptions}
              disabled
            />
            <InputFormik name="zip" label="Postal / Zip Code" disabled />
          </div>
        </div>
      </div>
    </div>
  );
}
