import { Input } from "src/components/Input/Input";
import Modal from "src/components/Modal/Modal";
import styles from "./ManageCustomerModal.module.scss";
import { Button } from "src/components/Button/Button";
import shopifyAuto from "../assets/shopifyAuto.svg";
import PlatformIcons from "src/modules/global/components/PlatformIcons";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  showDeleteAlert: () => void;
}

export function ManageCustomerModal({
  isOpen,
  onClose,
  showDeleteAlert,
}: Props) {
  return (
    <Modal
      title={"Manage customer"}
      subtitle="Edit the details and make changes accordingly"
      onClose={onClose}
      isOpen={isOpen}
      maxWidth="38.4375rem"
      noContainer
    >
      <div className={styles.content}>
        <div className={styles.personalDetails}>
          <div className={styles.nameContainer}>
            {/* FIXME: USE InputFormik Component instead of Input */}
            <Input label="First Name" />
            <Input label="Last Name" />
          </div>
          <Input label="Email" />
          <Input label="Phone Number" />
        </div>
        <div>
          <p className={styles.title}>Subscribed to</p>
          <div className={styles.subscribeContainer}>
            <div className={styles.subscribeHeader}>
              <div className={styles.platform}>
                <p className={styles.platformName}>Whatsapp</p>
                <PlatformIcons platforms={[{ platform: "WHATSAPP" }]} />
              </div>
              <Button style="plain" color="primary">
                Unsubscribe
              </Button>
            </div>
            <div className={styles.subscribeContent}>
              <p className={styles.subscribeValueWrapper}>
                <span className={styles.subscribeAttr}>Phone</span>
                <span className={styles.subscribeValue}>+91 09876543210</span>
              </p>
              <p className={styles.subscribeValueWrapper}>
                <span className={styles.subscribeAttr}>Status</span>
                <span className={styles.subscribeValue}>Subscribed</span>
              </p>
              <p className={styles.subscribeValueWrapper}>
                <span className={styles.subscribeAttr}>Method</span>
                <span className={styles.subscribeValue}>Shopify</span>
              </p>
              <p className={styles.subscribeValueWrapper}>
                <span className={styles.subscribeAttr}>Date</span>
                <span className={styles.subscribeValue}>7 June,2021</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className={styles.title}>Customer added from</p>
          <div className={styles.customerPlatform}>
            <img alt="shopify logo" src={shopifyAuto} />
            <span className={styles.customer}>Shopify</span>
          </div>
        </div>
        <div>
          <Button
            color="danger"
            style="plain"
            onClick={() => {
              onClose();
              showDeleteAlert();
            }}
          >
            Delete this user
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <span className={styles.footerText}>Export this customer</span>
        <div className={styles.btnGrp}>
          <Button
            style="plain"
            color="subdued"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button size="small">OK</Button>
        </div>
      </div>
    </Modal>
  );
}
