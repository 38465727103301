import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button/Button";
import styles from "./IntegrationPageHeader.module.scss";

interface IIntegrationPageHeader {
  title: string;
}
export default function IntegrationPageHeader({
  title,
}: IIntegrationPageHeader) {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <Button
            style="plain"
            size="micro"
            onClick={() => navigate("/settings/integrations")}
          >
            <ArrowBack />
          </Button>
          <h3 className={styles.title}>{title}</h3>
        </div>
      </div>
    </>
  );
}
