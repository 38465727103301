export async function getVideoDimensions(
  file: File
): Promise<{ height: number; width: number; duration: number }> {
  return new Promise((resolve) => {
    // create the video element
    const video = document.createElement("video");
    const objectUrl = URL.createObjectURL(file);

    // place a listener on it
    video.addEventListener(
      "loadedmetadata",
      function () {
        // retrieve dimensions
        const height = video.videoHeight;
        const width = video.videoWidth;
        const duration = video.duration;
        // send back result
        URL.revokeObjectURL(objectUrl);
        resolve({ height, width, duration });
      },
      false
    );

    // start download meta-data
    video.src = objectUrl;
  });
}
