import styles from "./PresetAudienceSelector.module.scss";
import { titleCase } from "src/lib/titleCase";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import { Button } from "src/components/Button/Button";
import { RadioGroup } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  IAudienceGroup,
  IAudiencePreset,
  IAudienceWizardPreset,
} from "../misc/createPreviewBasedAudienceTypes";
import { useFormikContext } from "formik";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { AudiencePresetCard } from "./AudiencePresetCard";
import { Separator } from "src/components/Separator/Separator";
import { Searchbar } from "src/components/Searchbar/Searchbar";
import customAudiencePresets from "../misc/audiencePresets";

const presetSections = [
  // ...new Set<IAudienceGroup>(customAudiencePresets.map((v) => v.group.value)),
];

function checkAll(item: "ALL" | string, condition: boolean) {
  if (!item || item === "ALL") return true;

  return condition;
}

export function PresetAudienceSelector() {
  // const [selectedPresets, setSelectedPresets] = useState<IAudiencePreset[]>([]);
  const { values, setFieldValue, handleSubmit } = useFormikContext<any>();
  const [filteredPresets, setFilteredPresets] = useState<
    IAudienceWizardPreset[]
  >(customAudiencePresets);
  const selectedPresets = values.selectedPresets;
  const setSelectedPresets = (value: any[]) =>
    setFieldValue("selectedPresets", value);

  const applyFilters = ({ select1Value, select2Value, inputValue }) => {
    const newPresets = customAudiencePresets.filter((v) => {
      return (
        checkAll(select1Value, v.group === select1Value) &&
        checkAll(select2Value, v.platforms.includes(select2Value as any)) &&
        checkAll(inputValue, v.name.includes(inputValue))
      );
    });
    // console.log({ newPresets, select1Value, select2Value, inputValue });
    setFilteredPresets(newPresets);
  };

  const FooterAction = (
    <Button onClick={handleSubmit} disabled={selectedPresets.length === 0}>
      Next
    </Button>
  );

  return (
    <div>
      <div className={styles.head}>
        <div className={styles.heading}>Choose pre-set audiences</div>
        <div className={styles.subHeading}>
          We have pre set audiences which has been created by our expert for
          best possible result
        </div>
        {/* TODO: fix styling and add functionality */}
        <div className={styles.searchWrapper}>
          {/* <Searchbar
            onChange={applyFilters}
            submitLabel="Apply"
            input={{
              placeholder: "Search terms, category or name of audience",
            }}
            select1={{
              placeholder: "Select Categories",
              options: [
                {
                  label: "Prospecting",
                  value: "PROSPECTING",
                },
                {
                  label: "Retention",
                  value: "RETENTION",
                },
                {
                  label: "All",
                  value: "ALL",
                },
              ],
            }}
            select2={{
              placeholder: "Select Platform",
              options: [
                {
                  label: "All",
                  value: "ALL",
                },
                {
                  label: "Facebook",
                  value: "FACEBOOK",
                },
                {
                  label: "Instagram",
                  value: "INSTAGRAM",
                },
              ],
            }}
          /> */}
        </div>
      </div>
      {/* TODO: Extract Preset Cards */}
      {presetSections.map((sectionName) => (
        <RadioGroup
          value={selectedPresets}
          onChange={(v: IAudiencePreset) => {
            if (
              selectedPresets.length > 0 &&
              selectedPresets.some((item) => item.id === v.id)
            ) {
              setSelectedPresets(
                selectedPresets.filter((item) => item.id !== v.id),
              );
            } else {
              setSelectedPresets([...selectedPresets, v]);
            }
          }}
        >
          <div className={styles.audiencePresetSection}>
            <div className={styles.audiencePresetSectionTitle}>
              <Separator
                title={
                  <span className={styles.titleText}>
                    <span>{titleCase(sectionName)}</span>{" "}
                    <Info fill="currentColor" height={18} width={18} />
                  </span>
                }
              />
            </div>
            <div className={styles.audiencePresetSectionCardsGrid}>
              {filteredPresets
                .filter((v) => v.group === sectionName)
                .map((v, i) => (
                  <RadioGroup.Option value={v} key={i} as={Fragment}>
                    <div>
                      <AudiencePresetCard
                        isActive={selectedPresets.some(
                          (item) => item.id === v.id,
                        )}
                        audiencePreset={v}
                      />
                    </div>
                  </RadioGroup.Option>
                ))}
            </div>
          </div>
        </RadioGroup>
      ))}

      <StickyFooter actions={FooterAction} />
    </div>
  );
}
