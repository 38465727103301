import styles from "./AudienceListEmptyScreen.module.scss";
import { Button } from "src/components/Button/Button";
import Balancer from "react-wrap-balancer";
import emptyAudience from "../assets/emptyAudience.png";

export function AudienceListEmptyScreen() {
  return (
    <div className={styles.emptyAudience}>
      <img
        src={emptyAudience}
        alt="no audiences found"
        width={174}
        height={174}
      />
      <p className={styles.emptyText}>
        <Balancer>
          Looks like you don't have any audience on Macro. You need to create
          Audience on Macro in order to run Ads. You can create audience right
          away and comeback here anytime to continue.
        </Balancer>
      </p>
      <Button size="small" to="/audiences/template/create">
        Create Audience
      </Button>
    </div>
  );
}
