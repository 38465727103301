import { getIn, useFormikContext } from "formik";
import { ReactComponent as AddLink } from "@material-design-icons/svg/outlined/add_link.svg";
import { CampaignInput } from "../misc/createCampaignTypes";
import { InputFormik } from "src/components/InputFormik/InputFormik";

interface Props {
  name: string;
}

export function AdLinkField({ name }: Props) {
  const { errors, touched } = useFormikContext<CampaignInput>();

  return (
    <InputFormik label="Destination Website link" name={name} icon={AddLink} />
  );
}
