import { Spinner } from "src/components/Spinner/Spinner";
import {
  useMetaEnablePixelMutation,
  useMetaPixelsFromPlatformQuery,
  useMetaRemovePixelMutation,
} from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import styles from "./MetaPagesList.module.scss";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";
import { useState } from "react";

export function MetaPixelsList({ selectedAccountId }) {
  const [selectedPixel, setSelectedPixel] = useState<null | string>(null);
  const { data: metaPixelsData, loading: metaPixelsLoading } =
    useMetaPixelsFromPlatformQuery({
      variables: {
        adAccountId: selectedAccountId,
      },
    });

  const [
    metaEnablePixelFunc,
    { loading: metaEnableLoading, error: metaEnablePixelError },
  ] = useMetaEnablePixelMutation();
  const [metaRemovePixelFunc, { loading: metaRemoveLoading }] =
    useMetaRemovePixelMutation();

  if (metaPixelsLoading) {
    return <Spinner height="screen" />;
  }

  const metaPixels =
    metaPixelsData && metaPixelsData.metaPixelsFromPlatform
      ? metaPixelsData?.metaPixelsFromPlatform
      : [];

  if (metaPixels.length === 0) {
    return (
      <div className={styles.emptyStateContainer}>
        <MetaIntegrationDetailsEmptyState
          text={
            "No pages found on your account. Please ensure that you have enabled accounts in macro. Enabled accounts should have a page on facebook"
          }
          link={"null"}
        />
      </div>
    );
  }

  return (
    <div>
      {metaPixels.map((pixel, index) => (
        <MetaDetailItem
          isDefault={pixel?.isDefault}
          loading={
            selectedPixel === pixel.id &&
            (metaEnableLoading || metaRemoveLoading)
          }
          error={selectedPixel === pixel.id && metaEnablePixelError}
          enableFunc={async () => {
            setSelectedPixel(pixel.id);
            await metaEnablePixelFunc({
              variables: {
                input: {
                  adAccountId: selectedAccountId,
                  metaPixelId: pixel.id,
                },
              },
              refetchQueries: [
                "metaPixelsFromPlatform",
                "metaPixelsForEnabledAdAccounts",
              ],
              awaitRefetchQueries: true,
            });
            setSelectedPixel(null);
          }}
          removeFunc={async () => {
            setSelectedPixel(pixel.id);
            await metaRemovePixelFunc({
              variables: {
                input: {
                  adAccountId: selectedAccountId,
                  metaPixelId: pixel.id,
                },
              },
              refetchQueries: [
                "metaPixelsFromPlatform",
                "metaPixelsForEnabledAdAccounts",
              ],
              awaitRefetchQueries: true,
            });
            setSelectedPixel(null);
          }}
          title={pixel?.name}
          isEnabled={pixel?.enabled}
          key={index}
          description={`Pixel ID - ${pixel?.id}`}
        />
      ))}
    </div>
  );
}
