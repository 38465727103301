import { ReactComponent as AddIcon } from "@material-design-icons/svg/outlined/add.svg";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { Asset } from "src/components/Smartphone/Asset";
import { AdInput, CampaignInput } from "../misc/createCampaignTypes";
import { getNewAdTemplate } from "../misc/newAdTemplate";
import styles from "./AdSwitcherSlider.module.scss";
import { AdActionsDropdown } from "./AdActionsDropdown";
import { getThumbnailAssetFromAd } from "../../campaign/functions/getThumbnailAssetFromAd";

interface Props {
  selectedAdIndex: number;
  setSelectedAdIndex: (i: number) => void;
  selectedActivityIndex: number;
}

export function AdSwitcherSlider({
  setSelectedAdIndex,
  selectedAdIndex,
  selectedActivityIndex,
}: Props) {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<CampaignInput>();
  const activity = values.Activities[selectedActivityIndex];
  const baseKey = `Activities[${selectedActivityIndex}].Ads`;

  function handleCreateNewAd(value: AdInput) {
    setFieldValue(baseKey, [...(activity?.Ads ?? []), value]);
    setFieldTouched(baseKey);
    // set the selected ad to last item
    setSelectedAdIndex(activity?.Ads.length);
  }

  function handleRemoveAd(index: number) {
    const filteredAds = activity.Ads.filter((_i, idx) => idx !== index);
    if (filteredAds.length === 0) {
      return;
    }
    setFieldValue(baseKey, filteredAds);
    setFieldTouched(baseKey);
    setSelectedAdIndex(filteredAds.length - 1);
  }

  return (
    <>
      <div className={styles.content}>
        {activity?.Ads.map((ad, index) => {
          const thumbnail = getThumbnailAssetFromAd({
            ...ad,
            adType: ad.staticAdType,
          } as any);

          return (
            <button
              onClick={() => {
                setSelectedAdIndex(index);
              }}
              type="button"
              className={styles.adSwatch}
              key={index}
            >
              <div className={styles.thumbnailWrapper}>
                <Asset
                  type={thumbnail.contentType}
                  preview={
                    /* ad.existingPost?.url ||
                    ad.LineItems[0]?.PostAsset?.uri || */
                    thumbnail.uri
                  }
                  name={ad.LineItems[0]?.PostAsset?.name}
                  className={styles.thumbnailAsset}
                  loop={true}
                />
                {activity?.Ads?.length > 1 && (
                  <div className={styles.cancelBtn}>
                    <AdActionsDropdown
                      items={[
                        {
                          children: "Delete",
                          color: "danger",
                          onClick: (e) => {
                            e.stopPropagation();
                            handleRemoveAd(index);
                          },
                        },
                        {
                          children: "Duplicate",
                          color: "normal",
                          onClick: () => {
                            handleCreateNewAd(ad);
                          },
                        },
                      ]}
                    />
                  </div>
                )}
              </div>
              <span
                className={classNames(styles.adName, {
                  [styles.activeAdName]: index === selectedAdIndex,
                })}
              >
                Ad - {index + 1}
              </span>
            </button>
          );
        })}
        <button
          type="button"
          onClick={() => handleCreateNewAd(getNewAdTemplate(values))}
          className={styles.createBtn}
        >
          <AddIcon fill="var(--color-disabled)" width={24} height={24} />
        </button>
      </div>
    </>
  );
}
