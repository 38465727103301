import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { ReactComponent as Call } from "@material-design-icons/svg/filled/call.svg";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/filled/more_vert.svg";
import { ReactComponent as VideoCam } from "@material-design-icons/svg/filled/videocam.svg";
import classNames from "classnames";
import styles from "./Mobile.module.scss";
import {
  FormInput,
  MessageFormInput,
  WhatsappFormInput,
} from "src/pages/CreateTemplate/CreateMessageTemplateTypes";

interface IMobile {
  templateType: "WHATSAPP" | "SMS";
  input?: FormInput;
}

export function Mobile({ templateType, input }: IMobile) {
  return (
    <div className={styles.mobile}>
      <div className={styles.speakerWrapper}>
        <span className={styles.speaker}></span>
      </div>
      <div className={styles.screen}>
        {templateType === "WHATSAPP" ? (
          <Whatsapp {...(input as WhatsappFormInput)} />
        ) : (
          <Sms message={(input as MessageFormInput)?.content || ""} />
        )}
      </div>
      <div className={styles.speakerWrapper}>
        <span className={styles.speaker}></span>
      </div>
    </div>
  );
}

function Whatsapp({ header, body, footer, buttons }: WhatsappFormInput) {
  return (
    <div>
      <div className={styles.wtHeader}>
        <div className={styles.wtContainer}>
          <ArrowBack className={styles.ico} />
          <img
            src="/imagesAndIcons/MacroCircle.svg"
            alt="logo"
            className={styles.wtProfile}
          />
          <div className={styles.wtText}>
            <span className={styles.wtTitle}>Macro</span>
            <span className={styles.wtSubtitle}>Business Account</span>
          </div>
        </div>
        <div className={styles.wtAction}>
          <VideoCam className={styles.ico} />
          <Call className={styles.ico} />
          <MoreVert className={styles.ico} />
        </div>
      </div>
      <div className={styles.whatsappMessage}>
        <div className={styles.whatsappMessageHeader}>
          {header.headerType === "TEXT" ? (
            header?.text
          ) : (
            <WhatsappHeaderImage />
          )}
        </div>

        <div className={styles.whatsappMessageBody}>
          {replaceTextWithExample(body?.text, body?.example || [])}
        </div>

        <div className={styles.whatsappMessageFooter}>{footer}</div>

        {/* TODO: Complete buttons ui */}
        <div className={styles.whatsappMessageButtons}>
          <hr className={styles.whatsappMessageButtonsSeparator} />
        </div>
      </div>
    </div>
  );
}

function replaceTextWithExample(
  text?: string,
  example?: WhatsappFormInput["header"]["example"]
) {
  if (!text) {
    return "";
  }
  if (!example || example.length === 0) {
    return text;
  }

  return text.split(/{{\d+}}/g).reduce((acc, value, index, arr) => {
    acc +=
      index === arr.length - 1
        ? `${value}`
        : `${value}${example[index].variableExample}`;
    return acc;
  }, "");
}

function WhatsappHeaderImage() {
  return <div></div>;
}

interface ISms {
  message?: string;
}

function Sms({ message }: ISms) {
  return (
    <div>
      <div className={styles.smsHeader}>
        <div className={styles.wtContainer}>
          <ArrowBack className={classNames(styles.ico, styles.smsIco)} />
          <img
            src="/imagesAndIcons/MacroCircle.svg"
            alt="logo"
            className={styles.wtProfile}
          />
          <span>CP-SBJCNT</span>
        </div>
        <MoreVert className={classNames(styles.ico, styles.smsIco)} />
      </div>
      {message.trim().length > 0 && (
        <div className={styles.messageContainer}>{message}</div>
      )}
    </div>
  );
}
