import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./AudienceOverviewPage.module.scss";
import { useState, useContext, useEffect } from "react";
import leftOverviewImage from "../assets/audienceOverviewLeftImage.png";
import rightOverviewImage from "../assets/audienceOverviewRightImage.png";
import { Link } from "react-router-dom";
import Badge from "src/components/Badge/Badge";
import OverviewBanner from "src/components/OverviewBanner/OverviewBanner";
import StatCard, { IStatCard } from "src/components/StatCard/StatCard";
import {
  MetaAudienceSet,
  useAudienceSetsQuery,
  useAudiencesByIdLazyQuery,
  useAudiencesCountQuery,
  useAudiencesQuery,
  useIntegrationsStatusQuery,
} from "src/graphql/generated/schema";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import CodewordResourcesList from "src/modules/dashboard/components/CodewordResourcesList/CodewordResourcesList";
import { AudienceTitleWithIcon } from "src/modules/global/components/AudienceTitleWIthIcon";
import { OverviewTable } from "src/modules/global/components/OverviewTable";
import AuthContext from "src/stores/AuthContext";
import { Spinner } from "src/components/Spinner/Spinner";
import { AudienceOverviewTableBasedOnView } from "../components/AudienceOverviewTableBasedOnView";
import { checkIntegrationStatus } from "src/modules/global/functions/checkIntegrationStatus";
import OverviewIntegrationCards from "src/modules/global/components/OverviewIntegrationCards";
import { PageLoader } from "src/modules/global/components/PageLoader";
import noAudience from "../../campaign/assets/audienceEmpty.png";

// TODO: add error handling
export const itemsPerPage = 5;
const statCardPropByKeys: { [x: string]: Omit<IStatCard, "value"> } = {
  engagementRate: {
    title: "Engagement Rate",
    subtitle: "Avg. Engagement of Audience",
    valueType: "PERCENTAGE",
    learnMoreText: "",
  },
  audiencesCount: {
    title: "Audiences in Macro",
    subtitle: "Total audiences in macro",
    valueType: "NUMBER",
    learnMoreText: "",
  },
  avgCtr: {
    title: "Avg. CTR rate",
    subtitle: "Avg. Click Rate by Audience",
    valueType: "PERCENTAGE",
    learnMoreText: "",
  },
  avgCpm: {
    title: "Avg. CPM",
    subtitle: "Avg. cost per miles",
    valueType: "CURRENCY",
    learnMoreText: "",
  },
};

export type AudienceTabs = "AUDIENCE_SET" | "AUDIENCE";

export default function AudienceOverviewPage() {
  const [selectedTab, setSelectedTab] = useState<AudienceTabs>("AUDIENCE");
  const [stats, setStats] = useState<{
    engagementRate: number;
    audiencesCount: number;
    avgCtr: number;
    avgCpm: number;
  }>(null);
  const { userToken } = useContext(AuthContext);
  const { data: integrationStatusData, loading: integrationStatusLoading } =
    useIntegrationsStatusQuery();

  const integrationStatus = checkIntegrationStatus(integrationStatusData);

  const { data: audiencesData, loading: audiencesLoading } = useAudiencesQuery({
    variables: {
      filter: {
        take: itemsPerPage,
      },
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
    },
  });

  const { data: audiencesCountData, loading: audiencesCountLoading } =
    useAudiencesCountQuery();

  const { data: audienceSetsData, loading: audienceSetsLoading } =
    useAudienceSetsQuery({
      variables: {
        filter: {
          take: itemsPerPage,
        },
      },
    });

  const { data: tinybirdTopAudiencesData } = useTinybirdQuery(
    "top_audiences",
    userToken
  );
  const { data: tinybirdGrowAggData } = useTinybirdQuery(
    "grow_aggregate",
    userToken
  );

  const [
    audiencesByIdFunc,
    { data: audiencesByIdData, loading: audiencesByIdLoading },
  ] = useAudiencesByIdLazyQuery();

  useEffect(() => {
    if (integrationStatusLoading) return;
    if (integrationStatus !== "ACTIVE") return;

    if (!tinybirdTopAudiencesData) return;
    if (tinybirdTopAudiencesData.length === 0) return;
    const getAudiencesById = async (ids: string[]) => {
      await audiencesByIdFunc({
        variables: {
          ids: ids,
        },
      });
    };

    getAudiencesById(tinybirdTopAudiencesData.map((v: any) => v.audienceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tinybirdTopAudiencesData, integrationStatus, integrationStatusLoading]);

  useEffect(() => {
    if (integrationStatusLoading) return;
    if (integrationStatus !== "ACTIVE") return;

    if (!tinybirdGrowAggData) return;
    if (!audiencesCountData) return;
    if (tinybirdGrowAggData.length === 0) return;
    if (!audiencesCountData.audiencesCount) return;

    const countPageEngagement = tinybirdGrowAggData[0].countPageEngagement;
    const countPostEngagement = tinybirdGrowAggData[0].countPostEngagement;
    const avgCtr = tinybirdGrowAggData[0].ctr;
    const avgCpm = tinybirdGrowAggData[0].cpm;
    const impressions = tinybirdGrowAggData[0].impressions;
    const count = audiencesCountData.audiencesCount.count;

    const formattedStats = {
      engagementRate:
        impressions && countPageEngagement && countPostEngagement
          ? ((countPageEngagement + countPostEngagement) * 100) / impressions
          : 0,
      audiencesCount: count,
      avgCtr: avgCtr,
      avgCpm: avgCpm,
    };
    console.log(formattedStats);
    setStats(formattedStats);
    // TODO: fix unnecessary deps
  }, [
    tinybirdGrowAggData,
    audiencesCountData,
    integrationStatusLoading,
    integrationStatus,
  ]);

  if (integrationStatusLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <PageHeader title={"Audiences Overview"}></PageHeader>
      <div className={styles.page}>
        <OverviewBanner
          title={"Tailor Campaign with Precision, Amplify Impact!"}
          description={
            "From defining your audience to choosing compelling creatives, every step is simplified for a seamless experience impact with precision targeting"
          }
          bgGradient="linear-gradient(180deg, #FFF 0%, rgba(251, 204, 246, 0.50) 53.91%, #FFF 100%)"
          link="/audiences/template/create"
          leftImage={leftOverviewImage}
          rightImage={rightOverviewImage}
          action={{
            children: "Create New Audience",
          }}
        />

        {integrationStatus !== "ACTIVE" ? (
          <OverviewIntegrationCards integrationStatus={integrationStatus} />
        ) : (
          <AudienceOverviewContent
            audiencesCountLoading={audiencesCountLoading}
            stats={stats}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            audiencesLoading={audiencesLoading}
            audienceSetsLoading={audienceSetsLoading}
            audiencesData={audiencesData}
            audienceSetsData={audienceSetsData}
            audiencesByIdData={audiencesByIdData}
            audiencesByIdLoading={audiencesByIdLoading}
            itemsPerPage={itemsPerPage}
          />
        )}
        <div>
          <CodewordResourcesList />
        </div>
      </div>
    </div>
  );
}

function AudienceOverviewContent({
  audiencesCountLoading,
  stats,
  selectedTab,
  setSelectedTab,
  audiencesLoading,
  audienceSetsLoading,
  audiencesData,
  audienceSetsData,
  audiencesByIdData,
  audiencesByIdLoading,
  itemsPerPage,
}) {
  const audiences =
    audiencesData && audiencesData.audiences
      ? audiencesData.audiences.edges.map((v) => v.node)
      : [];

  const audienceSets =
    audienceSetsData && audienceSetsData.audienceSets
      ? audienceSetsData.audienceSets.edges.map((v) => v.node)
      : [];

  const topAudiences =
    audiencesByIdData && audiencesByIdData.audiencesById
      ? audiencesByIdData.audiencesById.map((v) => v)
      : [];

  return (
    <>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>Overview of Audiences</div>
        <div className={styles.sectionSubtitle}>Last update : 4 hours ago</div>
      </div>
      <div className={styles.actionCardsContainer}>
        {audiencesCountLoading ? (
          <Spinner height="auto" />
        ) : (
          <div className={styles.statCards}>
            {stats != null &&
              Object.keys(stats)
                .filter((v) => v !== "conversionCurrencyCode")
                .map((v) => (
                  <StatCard
                    title={statCardPropByKeys[v].title}
                    subtitle={statCardPropByKeys[v].subtitle}
                    value={stats[v] || 0}
                    valueType={statCardPropByKeys[v].valueType}
                    learnMoreText={statCardPropByKeys[v].learnMoreText}
                  />
                ))}
          </div>
        )}
      </div>
      <AudienceOverviewTableBasedOnView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        audiencesLoading={audiencesLoading}
        audienceSetsLoading={audienceSetsLoading}
        audiences={audiences}
        audienceSets={audienceSets as MetaAudienceSet[]}
      />
      {/* Audience Table */}
      <OverviewTable
        title="Top 10 performing audience"
        rows={topAudiences.map((v, i) => [
          <Link to={`/audiences/${v.id}`}>
            <AudienceTitleWithIcon audience={v} key={i} />
          </Link>,
          <Badge label={"Scheduled"} status="success" />,
        ])}
        headings={["Audiences", "Status", "Activity"]} // selectedTab={selectedCampaignsTab}
        // setSelectedTab={setSelectedCampaignsTab}
        loading={audiencesByIdLoading}
        itemsPerPage={itemsPerPage}
        border={false}
        emptyState={{
          title: "No top audiences found",
          icon: noAudience,
          action: {
            link: "/audiences/create/templates",
            title: "Create Audiences",
          },
        }}
      />
    </>
  );
}
