import {
  CustomerEdge,
  CustomersSortBy,
  Pagination as IPagination,
} from "src/graphql/generated/schema";
import { CustomersTable } from "./CustomerTable";
import { Pagination } from "src/components/Pagination/Pagination";
import { itemsPerPage } from "../../global/misc/itemsPerPage";
import styles from "./CustomersTableWrapper.module.scss";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";

interface CustomersTableWrapperProps {
  customers: CustomerEdge[];
  filters: { searchQuery: string; dateFilter: DateFilter };
  setPagination: (i: { cursor: any; take: number }) => void;
  paginationPayload: IPagination;
  setFilters: (i: CustomersTableWrapperProps["filters"]) => void;
  sortBy: CustomersSortBy;
  setSortBy: (i: CustomersSortBy) => void;
  showPagination: boolean;
  loading: boolean;
}

export function CustomersTableWrapper({
  customers,
  paginationPayload,
  setPagination,
  loading,
  filters,
  setFilters,
  sortBy,
  setSortBy,
  showPagination,
}: CustomersTableWrapperProps) {
  return (
    <div className={styles.page}>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.customerTableWrapper}>
            <CustomersTable
              loading={loading}
              customers={customers}
              filters={filters}
              sortBy={sortBy}
            />
          </div>
          {showPagination && (
            <div className={styles.paginationWrapper}>
              <Pagination
                hasPrevious={paginationPayload.hasPreviousPage}
                hasNext={paginationPayload.hasNextPage}
                label={`Showing ${
                  paginationPayload.itemsPerPage > paginationPayload.totalItems
                    ? paginationPayload.totalItems
                    : itemsPerPage
                } out ${paginationPayload.totalItems}`}
                onPrevious={() => {
                  setPagination({
                    cursor: paginationPayload.startCursor,
                    take: -itemsPerPage,
                  });
                }}
                onNext={() => {
                  setPagination({
                    cursor: paginationPayload.endCursor,
                    take: itemsPerPage,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
