import { useGrowAggregatedStats } from "./useGrowAggregatedStats";

type FilterOptions = {
  dateFrom: Date | null;
  dateTo: Date | null;
};

export function useAudienceSetAggregatedStats(
  audienceSetId: string,
  { dateFrom, dateTo }: FilterOptions,
) {
  const growAggregatedStats = useGrowAggregatedStats({
    others: { audienceSetId, insightType: "ADSET_INSIGHT" },
    dateFrom,
    dateTo,
  });

  return growAggregatedStats;
}
