import { Button } from "src/components/Button/Button";
import { Input } from "src/components/Input/Input";
import Modal from "src/components/Modal/Modal";
import styles from "./SegmentCreateModal.module.scss";
import { toast } from "react-hot-toast";

interface ISegmentCreateModal {
  showCreateSegmentModal: boolean;
  setShowCreateSegmentModal: (i: boolean) => void;
  segmentName: string;
  setSegmentName: (i: string) => void;
  segmentCreateLoading: boolean;
  handleCreateSegment: () => Promise<{
    __typename?: "Segment";
    id: string;
    name: string;
    filter: string;
  }>;
  setSelectedItems: (i: number[]) => void;
}

export function SegmentCreateModal({
  showCreateSegmentModal,
  handleCreateSegment,
  segmentCreateLoading,
  segmentName,
  setSegmentName,
  setShowCreateSegmentModal,
  setSelectedItems,
}: ISegmentCreateModal) {
  return (
    <Modal
      isOpen={showCreateSegmentModal}
      onClose={() => {
        setShowCreateSegmentModal(false);
      }}
      maxWidth="600px"
      className={styles.modal}
      noContainer
    >
      <div className={styles.segementModalContent}>
        <h3 className={styles.title}>Name your new segment</h3>
        <p className={styles.description}>
          Give name to your new created segment which may define the purpose of
          segment
        </p>

        <Input
          value={segmentName}
          onChange={(value) => setSegmentName(value)}
          name="name"
          placeholder="Enter Segment Name"
        />
        <Button
          width="full"
          style="solid"
          color="primary"
          disabled={segmentName.trim().length === 0 || segmentCreateLoading}
          onClick={async () => {
            const createdSegment = await handleCreateSegment();

            if (!createdSegment) {
              return;
            }

            setShowCreateSegmentModal(false);
            // toast.success(
            //   `Successfully created segment ${createdSegment.name}`
            // );
            toast.success("Segment has been created successfully");
            setSelectedItems([]);
            setSegmentName("");
          }}
          loading={segmentCreateLoading}
        >
          Save Segment
        </Button>
      </div>
    </Modal>
  );
}
