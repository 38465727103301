import { ReactNode } from "react";
import styles from "./SelectionTable.module.scss";
import classNames from "classnames";
import { Checkbox } from "src/components/Checkbox/Checkbox";

interface SelectionTableProps {
  headers: Array<ReactNode>;
  items: Array<{
    id: string;
    icon: ReactNode;
    title: ReactNode;
    description: ReactNode;
    otherRows: Array<ReactNode>;
  }>;
  onChange: (i: boolean, item: { id: string }) => void;
  gridTemplate: string;
  values: string[];
}

export function SelectionTable({
  items,
  headers,
  onChange,
  gridTemplate,
  values,
}: SelectionTableProps) {
  return (
    <div
      // @ts-ignore
      style={{ "--grid-template": gridTemplate }}
      className={styles.table}
    >
      <ul className={styles.tableHead}>
        {headers.map((header) => (
          <li className={styles.tableHeading}>{header}</li>
        ))}
      </ul>
      <ul className={styles.tableBody}>
        {items.map((item, index) => {
          const isSelectedItem = values.includes(item.id);

          return (
            <li
              key={index}
              className={classNames(styles.tableRow, {
                [styles.zebraRow]:
                  // if the value is not selected
                  !isSelectedItem &&
                  // we want to color every odd row
                  index % 2 === 1,
                [styles.selectedRow]: isSelectedItem,
                [styles.notSelectedRow]: !isSelectedItem && index % 2 === 0,
              })}
            >
              <div className={styles.tableMainCell}>
                <Checkbox
                  checked={isSelectedItem}
                  value={item.id}
                  onChange={(value) => {
                    onChange(value, { id: item.id });
                  }}
                />
                {item.icon}
                <button
                  className={styles.tableMainCellButton}
                  type="button"
                  onClick={() => {
                    onChange(!isSelectedItem, { id: item.id });
                  }}
                >
                  <h4
                    className={classNames(styles.tableCellHeading, {
                      [styles.selectedHeading]: isSelectedItem,
                      [styles.notSelectedHeading]: !isSelectedItem,
                    })}
                  >
                    {item.title}
                  </h4>
                  <p className={styles.tableCellDescription}>
                    {item.description}
                  </p>
                </button>
              </div>
              {item.otherRows.map((row) => (
                <button
                  type="button"
                  onClick={() => {
                    onChange(!isSelectedItem, { id: item.id });
                  }}
                  className={styles.tableCell}
                >
                  {row}
                </button>
              ))}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
