import { StatFilterOptions } from "./StatFilterOptions";
import { useGrowAggregatedStats } from "./useGrowAggregatedStats";

/**
 * returns aggregated stats for campaign with comparison values
 */
export function useAdAggregatedStats(
  adId: string,
  { dateFrom, dateTo }: StatFilterOptions,
  shouldShowComparisonValues: boolean = true,
) {
  const growAggregatedStats = useGrowAggregatedStats({
    others: { adId, insightType: "AD_INSIGHT" },
    dateFrom,
    dateTo,
    shouldShowComparisonValues,
  });

  return growAggregatedStats;
}
