import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import styles from "./StaticAdFieldsShowcase.module.scss";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { AdSingleImageOrVideoFields } from "./AdSingleImageOrVideoFields";
import { AdCarouselFields } from "./AdCarouselFields";

export function StaticAdFieldsShowcase({ selectedAdIndex, selectedAd }) {
  const { setFieldValue } = useFormikContext<ActivityInputV2>();

  return (
    <div className={styles.adFields}>
      <MultilineInput
        disabled={true}
        showCharacterCount={true}
        maxCharacterCount={125}
        value={selectedAd.primaryText}
        onChange={(value) => {
          setFieldValue(`Ads[${selectedAdIndex}].primaryText`, value);
        }}
        label="Primary Text"
        rows={2}
      />
      {selectedAd.LineItems.length === 1 ? (
        <AdSingleImageOrVideoFields
          selectedAd={selectedAd}
          selectedAdIndex={selectedAdIndex}
          setFieldValue={setFieldValue}
        />
      ) : (
        <AdCarouselFields
          selectedAd={selectedAd}
          selectedAdIndex={selectedAdIndex}
          setFieldValue={setFieldValue}
        />
      )}
    </div>
  );
}
