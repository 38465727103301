import { AudienceSetQuery } from "src/graphql/generated/schema";
import { formatNumber } from "src/lib/formatNumber";
import styles from "./AudienceDistribution.module.scss";
import classNames from "classnames";
import { useState } from "react";
import { titleCase } from "src/lib/titleCase";
import { ReactComponent as EscalatorWarningIcon } from "@material-symbols/svg-600/rounded/escalator_warning.svg";
import { ReactComponent as TranslateIcon } from "@material-symbols/svg-600/rounded/translate.svg";
import { ReactComponent as GenderIcon } from "../assets/genderIcon.svg";
import { ReactComponent as LocationIcon } from "@material-symbols/svg-600/rounded/location_on.svg";
import { metaLanguages } from "../misc/metaLanguages";

interface IAudienceDistribution {
  audienceSetId: string;
  audienceSetData: AudienceSetQuery["audienceSet"];
}

const tabOptions = [
  {
    label: "Interest & Behaviours",
    value: "INTEREST",
  },
  {
    label: "Audience Preset",
    value: "AUDIENCE",
  },
  {
    label: "Demographics",
    value: "DEMOGRAPHICS",
  },
];

export function AudienceDistribution({
  audienceSetId,
  audienceSetData,
}: IAudienceDistribution) {
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);

  const tabContents = {
    INTEREST: (
      <div>
        {audienceSetData.Interests.map((interest) => (
          <ListItem
            subtitle={`Path: ${interest.path.join(" > ")}`}
            name={interest.name}
            type={"Interest"}
            audienceSizeLowerBound={interest.audienceSizeLowerBound}
            audienceSizeUpperBound={interest.audienceSizeUpperBound}
            isIncluded={true}
          />
        ))}
        {audienceSetData.ExcludedInterests.map((interest) => (
          <ListItem
            subtitle={`Path: ${interest.path.join(" > ")}`}
            name={interest.name}
            type={"Interest"}
            audienceSizeLowerBound={interest.audienceSizeLowerBound}
            audienceSizeUpperBound={interest.audienceSizeUpperBound}
            isIncluded={false}
          />
        ))}
      </div>
    ),
    AUDIENCE: (
      <div>
        {audienceSetData.Audiences.map((audience) => (
          <ListItem
            subtitle={titleCase(audience.type, "_")}
            type={titleCase(audience.platform)}
            name={audience.name}
            audienceSizeLowerBound={audience.MetaInsights.lowerBoundCount}
            audienceSizeUpperBound={audience.MetaInsights.upperBoundCount}
            isIncluded={true}
          />
        ))}
        {audienceSetData.ExcludedAudiences.map((audience) => (
          <ListItem
            subtitle={titleCase(audience.type, "_")}
            name={audience.name}
            type={titleCase(audience.platform)}
            audienceSizeLowerBound={audience.MetaInsights.lowerBoundCount}
            audienceSizeUpperBound={audience.MetaInsights.upperBoundCount}
            isIncluded={false}
          />
        ))}
      </div>
    ),
    DEMOGRAPHICS: (
      <div className={styles.demographics}>
        <div className={styles.demographicItem}>
          <span className={styles.demographicIcon}>
            <EscalatorWarningIcon
              width={24}
              height={24}
              fill="var(--color-disabled)"
            />
          </span>
          <span className={styles.demographicLabel}>Age Group</span>
          <span className={styles.demographicValue}>
            {audienceSetData.minAge} - {audienceSetData.maxAge}
          </span>
        </div>
        <div className={styles.demographicItem}>
          <span className={styles.demographicIcon}>
            <GenderIcon width={24} height={24} fill="var(--color-disabled)" />
          </span>
          <span className={styles.demographicLabel}>Gender</span>
          <span className={styles.demographicValue}>
            {titleCase(audienceSetData.gender)}
          </span>
        </div>
        <div className={styles.demographicItem}>
          <span className={styles.demographicIcon}>
            <TranslateIcon
              width={24}
              height={24}
              fill="var(--color-disabled)"
            />
          </span>
          <span className={styles.demographicLabel}>Language</span>
          <span className={styles.demographicValue}>
            {audienceSetData.languages
              .map(
                (languageId) =>
                  metaLanguages.find(
                    (ml) => ml.auxiliaryData.key === Number(languageId),
                  )?.name,
              )
              .filter(Boolean)
              .map((l) => titleCase(l))
              .join(", ") || "-"}
          </span>
        </div>
        <div className={styles.demographicItem}>
          <span className={styles.demographicIcon}>
            <LocationIcon width={24} height={24} fill="var(--color-disabled)" />
          </span>
          <span className={styles.demographicLabel}>Locations</span>
          <span className={styles.demographicValue}>
            {audienceSetData.Locations.map((l) => titleCase(l.name)).join(
              ", ",
            ) || "-"}
          </span>
        </div>
      </div>
    ),
  };

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.heading}>List of audiences and presets</h2>
          <h3 className={styles.subheading}>
            Here are the list of audience and preset used in this Audience
          </h3>
        </div>
        <div>
          <div className={styles.tabs}>
            {tabOptions.map((tab) => (
              <button
                className={classNames(styles.tabOption, {
                  [styles.activeTabOption]: tab.value === selectedTab,
                })}
                onClick={() => {
                  setSelectedTab(tab.value);
                }}
                type="button"
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.tabContent}>{tabContents[selectedTab]}</div>
    </div>
  );
}

interface ILineItem {
  isIncluded: boolean;
  name: string;
  subtitle: string;
  type: string;
  audienceSizeLowerBound: number;
  audienceSizeUpperBound: number;
}

function ListItem({
  name,
  subtitle,
  audienceSizeLowerBound,
  audienceSizeUpperBound,
  type,
  isIncluded,
}: ILineItem) {
  return (
    <div className={styles.interestItem}>
      <div className={styles.interestPrimaryRow}>
        <span className={styles.interestName}>{name}</span>
        <span className={styles.interestPath}>{subtitle}</span>
      </div>
      <span className={styles.interestType}>{type}</span>

      <div className={styles.interestSizeWrapper}>
        {audienceSizeLowerBound ? (
          <span className={styles.interestSize}>
            {formatNumber(audienceSizeLowerBound)} -{" "}
            {formatNumber(audienceSizeUpperBound)}
          </span>
        ) : (
          "-"
        )}
      </div>

      <div className={styles.includeExcludeWrapper}>
        <span
          className={classNames({
            [styles.isIncluded]: isIncluded,
            [styles.isExcluded]: !isIncluded,
          })}
        >
          {isIncluded ? "Included" : "Excluded"}
        </span>
      </div>
    </div>
  );
}
