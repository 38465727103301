import Card from "src/components/Card/Card";
import styles from "./AudienceOrInterestsForm.module.scss";
import { Tabs } from "src/components/Tabs/Tabs";
import { useContext, useEffect, useState } from "react";
import { Input } from "src/components/Input/Input";
import { ReactComponent as SearchIcon } from "@material-design-icons/svg/outlined/search.svg";
import { Button } from "src/components/Button/Button";
import { InterestsSelectionLoader } from "./InterestsSelectionLoader";
import { Form, Formik } from "formik";
import { WizardContext } from "src/modules/global/misc/WizardContext";
import Stepper from "src/components/Stepper/Stepper";
import { AudienceSelectionLoader } from "./AudienceSelectionLoader";
import { AudienceSetViewSelection } from "./AudienceSetViewSelection";
import { audienceAndInterestTabs } from "../misc/audienceAndInterestTabs";

export function AudienceOrInterestsForm() {
  const [isShowSelectionOpen, setIsShowSelectionOpen] = useState(false);
  const { currentNode, breadcrumbs, data, markNodeAsComplete, goBack } =
    useContext(WizardContext);
  const [selectedTab, setSelectedTab] = useState<
    (typeof audienceAndInterestTabs)["0"]["value"]
  >(audienceAndInterestTabs[0].value);
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    setSearchInputValue("");
  }, [selectedTab]);

  return (
    <>
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          markNodeAsComplete({
            ...values,
            currentStep: currentNode.getNextStep(values),
          });
        }}
      >
        {({ values, handleSubmit, setValues }) => (
          <Form onSubmit={handleSubmit}>
            <Stepper
              activeStepIndex={breadcrumbs.findIndex((b) => b.isActive)}
              steps={breadcrumbs}
            />
            <div className={styles.layout}>
              <Card>
                <div className={styles.header}>
                  <h2 className={styles.heading}>
                    Reach to people by adding Audiences
                  </h2>
                  <p className={styles.copy}>
                    People who've already interacted with your business or
                    Lookalike Audiences to reach new people who are similar to
                    your existing customers.
                  </p>
                </div>

                <div className={styles.content}>
                  <div className={styles.tabs}>
                    <Tabs
                      selectedTab={selectedTab}
                      onTabChange={setSelectedTab}
                      tabs={audienceAndInterestTabs}
                    />
                  </div>
                  <div className={styles.search}>
                    <Input
                      icon={SearchIcon}
                      name="search"
                      onChange={(e) => setSearchInputValue(e)}
                      value={searchInputValue}
                    />
                  </div>

                  {selectedTab === "INTERESTS" && (
                    <InterestsSelectionLoader
                      shouldShowSelection={true}
                      onShowSelection={() => setIsShowSelectionOpen(true)}
                      searchInputValue={searchInputValue}
                      adAccountId={data.adAccountId}
                    />
                  )}

                  {selectedTab === "AUDIENCE_PRESET" && (
                    <AudienceSelectionLoader
                      shouldShowSelection={true}
                      onShowSelection={() => setIsShowSelectionOpen(true)}
                      searchInputValue={searchInputValue}
                    />
                  )}
                </div>
                {/* forms shall not be nested */}
                <AudienceSetViewSelection
                  initialValues={values}
                  onChange={setValues}
                  initialSelectedTab={selectedTab}
                  isShowSelectionOpen={isShowSelectionOpen}
                  setIsShowSelectionOpen={setIsShowSelectionOpen}
                />
                <div className={styles.footer}>
                  <div className={styles.submitGroup}>
                    <Button style="solid" type="submit">
                      Submit and Continue
                    </Button>
                    <Button
                      onClick={() => {
                        goBack(values);
                      }}
                      type="button"
                      style="outline"
                      color="subdued"
                    >
                      Back
                    </Button>
                  </div>

                  <Button
                    onClick={() => {
                      markNodeAsComplete(data);
                    }}
                    type="button"
                    color="subdued"
                    style="outline"
                  >
                    Skip this Option
                  </Button>
                </div>
              </Card>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
