import { useFormikContext } from "formik";
import { SegmentsQuery } from "src/graphql/generated/schema";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import styles from "./SegmentSelectorItem.module.scss";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import segmentsIcon from "../assets/segmentsIcon.svg";
import { useMemo } from "react";
import format from "date-fns/format";

interface ISegmentSelectorItem {
  segment: SegmentsQuery["segments"]["edges"][0];
}

export function SegmentSelectorItem({ segment }: ISegmentSelectorItem) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();
  const formattedDate = useMemo(
    () => format(new Date(segment.node.createdAt), "dd MMMM, yyyy"),
    [segment],
  );

  const selectedSegmentIds = values.segmentIds ?? [];
  const isSelected = selectedSegmentIds.includes(segment.node.id);

  function handleChange(isChecked: boolean) {
    if (isChecked) {
      setFieldValue("segmentIds", [...selectedSegmentIds, segment.node.id]);
    } else {
      setFieldValue(
        "segmentIds",
        selectedSegmentIds.filter((a) => a !== segment.node.id),
      );
    }
  }

  return (
    <div className={styles.item}>
      <span>
        <Checkbox
          size="small"
          checked={isSelected}
          value={segment.node.id}
          onChange={handleChange}
        />
      </span>

      <span className={styles.titleContainer}>
        <span className={styles.segmentIcon}>
          <img src={segmentsIcon} width={24} height={24} alt="segment" />
        </span>
        <span className={styles.title}>{segment.node.name}</span>
        <span className={styles.activityCount}>{formattedDate}</span>
      </span>

      <span className={styles.categories}>{segment.node.customersCount}</span>
    </div>
  );
}
