import classNames from "classnames";
import Avatar from "react-avatar";
import styles from "./BadgeV2.module.scss";

export interface IBadgeV2 {
  color?: "gray" | "red" | "yellow" | "green" | "blue" | "purple";
  size?: "small" | "medium" | "large";
  label: React.ReactNode;
  leftAvatar?: {
    name?: string;
    email?: string;
    src?: string;
  };
  leftIconButton?: {
    icon?: React.FC<React.SVGAttributes<any>>;
    onClick?: () => void;
  };
  rightIconButton?: {
    icon: React.FC<React.SVGAttributes<any>>;
    onClick: () => void;
  };
  title?: string;
}

// TODO: implement borderless variants
export function BadgeV2({
  label,
  leftAvatar,
  leftIconButton,
  rightIconButton,
  size = "large",
  color = "gray",
  title,
}: IBadgeV2) {
  const RightIcon = rightIconButton?.icon;
  const LeftIcon = leftIconButton?.icon;

  // mapped this way so that if someday designers get their brains back, we can
  // say we were prepared
  const sizeOptions = {
    large: 16,
    medium: 16,
    small: 16,
  };

  return (
    <div
      className={classNames(styles.badge, styles[color], {
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
        [styles.large]: size === "large",
      })}
      title={title}
    >
      {leftIconButton || leftAvatar ? (
        leftIconButton?.icon ? (
          <LeftIcon
            className={styles.avatar}
            height={sizeOptions[size]}
            width={sizeOptions[size]}
          />
        ) : (
          <Avatar
            className={styles.avatar}
            size={`${sizeOptions[size]}`}
            {...leftAvatar}
          />
        )
      ) : null}
      <div className={styles.label}>{label}</div>
      {rightIconButton && (
        <button onClick={rightIconButton.onClick}>
          <RightIcon className={styles.icon} />
        </button>
      )}
    </div>
  );
}
