import Cookies from "js-cookie";

const activeAuthTokenKey = "macroAuthToken";

export function authTokenGet(): string | null {
  const data = Cookies.get(activeAuthTokenKey);

  if (data) {
    return data;
  } else {
    return null;
  }
}

export function authTokenSet(data: string) {
  Cookies.set(activeAuthTokenKey, data);
}

export function authTokenRemove() {
  Cookies.remove(activeAuthTokenKey);
}
