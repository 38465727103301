import styles from "./EngagementSelect.module.scss";
import { Label } from "../../../components/Label/Label";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";
import { ReactComponent as ChevronDownwards } from "@material-design-icons/svg/outlined/expand_more.svg";
import { ReactComponent as ChevronUpwards } from "@material-design-icons/svg/outlined/expand_less.svg";
import { DetailTooltip } from "src/modules/global/components/DetailTooltip";

interface IEngagementSelect {
  options: {
    label: string;
    value: string;
    children?: ReactNode;
    tooltip?: { title: string; description: string; image: string };
    image: { src: string };
  }[];
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  label: string;
  srOnlyLabel?: boolean;
  name: string;
}

export function EngagementSelect({
  options,
  value,
  srOnlyLabel,
  onChange,
  onBlur,
  label,
  name,
}: IEngagementSelect) {
  return (
    <div className={styles.section}>
      <Label srOnly={srOnlyLabel} label={label}>
        <RadioGroup
          as="div"
          className={styles.optionList}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
        >
          {options.map((option) => {
            return (
              <RadioGroup.Option value={option.value}>
                <div
                  className={classNames(styles.optionWrapper, {
                    [styles.selectedOption]: value === option.value,
                  })}
                >
                  <div className={styles.option}>
                    <div className={styles.optionInfo}>
                      <img
                        src={option.image.src}
                        width={32}
                        height={32}
                        className={styles.optionImage}
                      />
                      <span
                        className={classNames(styles.optionLabel, {
                          [styles.selectedOptionLabel]: option.value === value,
                        })}
                      >
                        {option.label}
                        {option.tooltip && (
                          <DetailTooltip
                            image={{ src: option.tooltip.image }}
                            description={option.tooltip.description}
                            title={option.tooltip.title}
                          />
                        )}
                      </span>
                    </div>

                    {option.children && (
                      <span>
                        {option.value === value ? (
                          <ChevronUpwards width={20} height={20} />
                        ) : (
                          <ChevronDownwards width={20} height={20} />
                        )}
                      </span>
                    )}
                  </div>
                  {option.value === value && option.children && (
                    <div>{option.children}</div>
                  )}
                </div>
              </RadioGroup.Option>
            );
          })}
        </RadioGroup>
      </Label>
    </div>
  );
}
