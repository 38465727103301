import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import styles from "./Radio.module.scss";

// because our onChange is incompatible
// with the native implementation
type AllAttributesExceptOnChange = Omit<
  InputHTMLAttributes<unknown>,
  "onChange"
>;

export interface IRadio extends AllAttributesExceptOnChange {
  value: string;
  label?: string;
  name?: string;
  checked: boolean;
  onChange: (newChecked: boolean) => void;
}

export function Radio(props: IRadio) {
  const { label, name, value, checked, onChange, ...otherProps } = props;

  return (
    <label className={styles["radio-button-container"]}>
      <input
        type="radio"
        checked={checked}
        name={name}
        value={value}
        onChange={() => onChange(checked)}
        className={styles.radio}
        {...otherProps}
      />
      <span className={styles.checkmark}></span>
    </label>
  );
}
