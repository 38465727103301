import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ChevronRight } from "@material-symbols/svg-600/rounded/chevron_right.svg";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { loginFormSchema } from "src/lib/yupSchemas";
import { OnboardingLayout2 } from "src/modules/onboarding/components/OnboardingLayout2";
import AuthContext from "src/stores/AuthContext";
import loginIllustration from "../assets/loginIllustration.png";
import styles from "./Login.module.scss";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

export function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
    password: "",
    rememberMe: rememberMe,
  };
  const { login, isAuthenticated } = useContext(AuthContext);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   if (searchParams.get("shop")) {
  //     window.location.href = `${
  //       process.env.REACT_APP_SERVER_URI
  //     }/integrations/shopify/install/init?${searchParams.toString()}`;
  //   }
  // }, []);

  // FIXME: this causes page to reload when user tries to take any input. Occurs occasionally and is not yet replicated
  // if user is already logged in then don't allow user to come to login page.
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/", {
  //       replace: true,
  //     });
  //   }
  // }, [isAuthenticated]);

  async function handleSubmit(values: typeof initialValues) {
    try {
      setLoading(true);
      await login({
        email: values.email,
        password: values.password,
      });
      // TODO: handle user errors
      const redirectUrl = localStorage.getItem("redirect_url");

      if (redirectUrl) {
        localStorage.removeItem("redirect_url");
      }

      window.location.replace(redirectUrl ?? "/");
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    setLoading(false);
  }

  return (
    <>
      <OnboardingLayout2 sideImage={loginIllustration}>
        <div className={styles.card}>
          <h1 className={styles.heading}>Login to your account</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={loginFormSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className={styles.inputRow}>
                  <InputFormikV2
                    name="email"
                    placeholder="Enter your email"
                    type="email"
                  />

                  <InputFormikV2
                    placeholder="Enter your password"
                    type="password"
                    name="password"
                  />
                </div>
                <div className={styles.remember}>
                  <Checkbox
                    label="Remember Me"
                    checked={values.rememberMe}
                    name="rememberMe"
                    onChange={(value) => {
                      setRememberMe(!rememberMe);
                      setFieldValue("rememberMe", value);
                    }}
                    value={String(values.rememberMe)}
                  />
                  <Link to="/auth/password-recovery" className={styles.forgot}>
                    Forgot Password?
                  </Link>
                </div>
                <div className={styles.login}>
                  <ButtonV2
                    type="submit"
                    isFullWidth
                    loading={loading}
                    disabled={loading}
                  >
                    Login
                  </ButtonV2>
                  {/* <div className={styles.formError}>{error}</div> */}
                </div>

                <span className={styles.loginCopy}>
                  Don't have a Macro account?
                  <Link className={styles.loginLink} to="/auth/signup">
                    Let's get started
                    <ChevronRight
                      fill="var(--brand-color)"
                      width={18}
                      height={18}
                    />
                  </Link>
                </span>
              </Form>
            )}
          </Formik>
        </div>
      </OnboardingLayout2>
    </>
  );
}
