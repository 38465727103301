import Modal from "src/components/Modal/Modal";
import { Asset as IAsset, MetaAdTypeEnum } from "src/graphql/generated/schema";
import styles from "./AdTemplatePreviewModal.module.scss";
import { LineItems } from "src/lib/types";
import { useState } from "react";
import { Smartphone } from "src/components/Smartphone/Smartphone";
import { Asset } from "../../../../components/Smartphone/Asset";
import classNames from "classnames";
import { AddMedia } from "./AddMedia";
import { useFormikContext } from "formik";
import { FormValues } from "../../misc/adTemplateCreateTypes";

interface IAdTemplatePreviewModal {
  isOpen: boolean;
  onClose: (i: false) => void;
}

// uses formik values
export function AdTemplatePreviewModal({
  isOpen,
  onClose,
}: IAdTemplatePreviewModal) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="80vw"
      title="Ads Preview"
      subtitle="Here are the previews of all your Ads used in this Activity."
      noContainer
      titleBorder={true}
    >
      <div className={styles.container}>
        <AdTemplateContent />
      </div>
    </Modal>
  );
}

function AdTemplateContent() {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [selectedLineItem, setSelectedLineItem] = useState(0);

  let adType: MetaAdTypeEnum =
    values.type === "SINGLE_IMAGE_OR_VIDEO" || values.type === "CAROUSEL"
      ? "CUSTOM_CREATIVE"
      : values.type === "CATALOGUE_AD"
      ? "CATALOG"
      : "EXISTING_POST";

  const metaActivityAdTemplate = values;

  const formattedLineItems: LineItems[] =
    adType === "CUSTOM_CREATIVE"
      ? metaActivityAdTemplate?.LineItems.map((v, i) => ({
          id: "",
          order: i,
          ...v,
        }))
      : [];

  // NOTE: show preview with the selected lineItem/asset only
  const selectedLineItems = formattedLineItems.filter(
    (item, i) => Boolean(item.PostAsset) && i === selectedLineItem,
  );

  // sets the variant of asset to the selectedLineItem
  function setAsset(asset: IAsset, variant: "PostAsset" | "StoryAsset") {
    // if the variant is PostAsset and there is no StoryAsset
    // then set current PostAsset as StoryAsset and then update PostAsset
    if (variant === "PostAsset") {
      if (!values.LineItems[selectedLineItem].storyAssetId) {
        const currentPostAsset = values.LineItems[selectedLineItem].PostAsset;
        setFieldValue(
          `LineItems[${selectedLineItem}].StoryAsset`,
          currentPostAsset,
        );
        setFieldValue(
          `LineItems[${selectedLineItem}].storyAssetId`,
          currentPostAsset.id,
        );
      }

      setFieldValue(`LineItems[${selectedLineItem}].PostAsset`, asset);
      setFieldValue(`LineItems[${selectedLineItem}].postAssetId`, asset.id);
    } else {
      setFieldValue(`LineItems[${selectedLineItem}].StoryAsset`, asset);
      setFieldValue(`LineItems[${selectedLineItem}].storyAssetId`, asset.id);
    }
  }

  return (
    <div className={styles.adTemplate}>
      <div className={styles.lineItemsListing}>
        <AdsList
          lineItems={values.LineItems.map((v, i) => ({ ...v, order: i }))}
          setSelectedLineItem={setSelectedLineItem}
          selectedLineItem={selectedLineItem}
        />
      </div>
      <div className={styles.previewContainer}>
        <div className={styles.previewWrapper}>
          <div className={styles.previewHeader}>Instagram Story</div>
          <Smartphone
            preview={selectedLineItems}
            variant={"stories"}
            type={"instagram"}
            primaryText={metaActivityAdTemplate?.primaryText}
            callToActionLabel={"Shop Now"}
            accountName={"Macro"}
            profilePicture={""}
            defaultSlide={selectedLineItem}
          />
          <AddMedia
            multiple={false}
            onSelect={(asset) => setAsset(asset[0], "StoryAsset")}
            triggerLabel={"Change Placement"}
          />
        </div>
        <div className={styles.previewWrapper}>
          <div className={styles.previewHeader}>Instagram Feed</div>
          <Smartphone
            preview={selectedLineItems}
            variant={"feed"}
            type={"instagram"}
            primaryText={metaActivityAdTemplate?.primaryText}
            callToActionLabel={"Shop Now"}
            accountName={"Macro"}
            profilePicture={""}
          />
          <AddMedia
            multiple={false}
            onSelect={(asset) => setAsset(asset[0], "PostAsset")}
            triggerLabel={"Change Placement"}
          />
        </div>
        <div className={styles.previewWrapper}>
          <div className={styles.previewHeader}>Facebook Feed</div>
          <Smartphone
            preview={selectedLineItems}
            variant={"feed"}
            type={"facebook"}
            primaryText={metaActivityAdTemplate?.primaryText}
            callToActionLabel={"Shop Now"}
            accountName={"Macro"}
            profilePicture={""}
          />
          <AddMedia
            multiple={false}
            onSelect={(asset) => setAsset(asset[0], "PostAsset")}
            triggerLabel={"Change Placement"}
          />
        </div>
      </div>
      <div className={styles.sidebar}></div>
    </div>
  );
}

interface IAdsList {
  lineItems: LineItems[];
  setSelectedLineItem: (v: number) => void;
  selectedLineItem: number;
}

function AdsList({
  lineItems,
  setSelectedLineItem,
  selectedLineItem,
}: IAdsList) {
  return (
    <div className={styles.flexList}>
      {lineItems.map((v, i) => (
        <button
          type="button"
          style={{
            all: "unset",
          }}
          onClick={() => setSelectedLineItem(i)}
        >
          <LineItemPreview selected={i === selectedLineItem} {...v} />
        </button>
      ))}
    </div>
  );
}

interface ILineItemPreview extends LineItems {
  selected: boolean;
}

function LineItemPreview({ headline, PostAsset, selected }: ILineItemPreview) {
  const asset = PostAsset
    ? PostAsset
    : ({
        uri: "https://placeholder.com/76/56",
        contentType: "IMAGE",
        name: "Placeholder",
      } as IAsset);

  return (
    <div
      className={classNames(styles.lineItem, {
        [styles.selected]: selected,
      })}
    >
      <Asset
        type={asset.contentType}
        name={asset.name}
        preview={asset.uri}
        className={styles.thumbnailAsset}
      />
      <p className={styles.centerAlign}>{headline}</p>
    </div>
  );
}
