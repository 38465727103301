import { useState } from "react";
import { MetaActivityAdTemplate } from "../../../../graphql/generated/schema";
import styles from "./AdTemplateSingle.module.scss";
import { AdTemplateSingleDetails } from "./AdTemplateSingleDetails";
import { Tabs } from "../../../../components/Tabs/Tabs";
import { AdTemplateActivitiesListing } from "./AdTemplateActivitiesListing";

interface IAdTemplateSingle {
  adTemplate: MetaActivityAdTemplate;
}

type AdTemplateTabs = "DETAILS" | "USE_IN_ACTIVITIES";

export function AdTemplateSingle({ adTemplate }: IAdTemplateSingle) {
  const [selectedTab, setSelectedTab] = useState<AdTemplateTabs>("DETAILS");

  return (
    <div className={styles.sectionLayout}>
      <Tabs
        tabs={[
          {
            label: "Details",
            value: "DETAILS",
          },
          {
            label: "Use in activities",
            value: "USE_IN_ACTIVITIES",
          },
        ]}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
      />
      {selectedTab === "USE_IN_ACTIVITIES" && (
        <AdTemplateActivitiesListing adTemplate={adTemplate} />
      )}
      {selectedTab === "DETAILS" && (
        <AdTemplateSingleDetails adTemplate={adTemplate} />
      )}
    </div>
  );
}
