import { Button } from "src/components/Button/Button";
import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import styles from "./WhatsappTemplateButton.module.scss";

export function AddWhatsappItemButton({ onClick, ctaType }) {
  return (
    <Button
      className={styles.ctaBtn}
      color="subdued"
      style="outline"
      size="micro"
      onClick={onClick}
    >
      Add {ctaType === "CTA" ? "CTA" : "Quick Reply"}
      <Add className={styles.addIco} />
    </Button>
  );
}
