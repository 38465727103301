import { AudienceAudienceSetIndexesQuery } from "src/graphql/generated/schema";
import { getAudienceAudienceSetSourcePlatform } from "src/modules/audience/misc/getAudienceAudienceSetSourcePlatforms";
import { ReactComponent as MetaIcon } from "../assets/facebookAudience.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagramAudienceSource.svg";
import { ReactComponent as WebsiteIcon } from "../assets/websiteAudienceSource.svg";
import { SVGProps } from "react";
import { AUDIENCE_PLATFORMS } from "src/modules/audience/misc/audienceListingTypes";
import { formatNumber } from "src/lib/formatNumber";
import styles from "./AudienceSelectorItem.module.scss";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import adsCount from "../assets/adCount.svg";
import Tooltip from "src/components/Tooltip/Tooltip";
import { AudienceCategoryBadge } from "src/modules/global/components/AudienceCategoryBadge";
import { AUDIENCE_CATEGORY } from "src/modules/global/misc/audienceCategories";
import { useFormikContext } from "formik";
import { ActivityInputV2, AdsetInputV2 } from "../misc/createCampaignTypes";
import { useAudienceAggregatedStats } from "src/modules/global/functions/useAudienceAggregatedStats";
import { useAudienceSetAggregatedStats } from "src/modules/global/functions/useAudienceSetAggregatedStats";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";

interface IAudienceSelectorItem {
  audience: AudienceAudienceSetIndexesQuery["audienceAudienceSetIndexes"]["edges"][0];
}

export function AudienceSelectorItem({ audience }: IAudienceSelectorItem) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  const audiencePlatforms = getAudienceAudienceSetSourcePlatform(audience.node);
  const item = audience.node.audienceId
    ? audience.node.Audience
    : audience.node.MetaAudienceSet;

  const selectedAudiences = values.Adsets ?? [];
  const isSelected = !!selectedAudiences.find(
    (adset) => adset.templateId === audience.node.id,
  );

  function handleChange() {
    if (isSelected) {
      return setFieldValue(
        "Adsets",
        selectedAudiences.filter((s) => s.templateId !== audience.node.id),
      );
    }

    setFieldValue("Adsets", [
      ...selectedAudiences,
      {
        budget: "",
        type: audience.node.audienceId ? "AUDIENCE" : "AUDIENCE_SET",
        audienceId: audience.node.audienceId,
        audienceSetId: audience.node.metaAudienceSetId,
        ProductSetSpecs: [],
        templateId: audience.node.id,
      } as AdsetInputV2,
    ]);
  }

  return (
    <div className={styles.item}>
      <span>
        <Checkbox
          size="small"
          checked={isSelected}
          value={audience.node.id}
          onChange={handleChange}
        />
      </span>

      <span className={styles.titleContainer}>
        <span className={styles.platformIcons}>
          {audiencePlatforms.map((platform) => (
            <PlatformIcons
              className={styles.platformIcon}
              platform={platform}
            />
          ))}
        </span>
        <span className={styles.title}>{audience.node.name}</span>
        <span className={styles.activityCount}>
          <img src={adsCount} width={24} height={24} alt="ad count" />
          {item?.activitiesCount}
        </span>
      </span>

      <span className={styles.categories}>
        {audience.node.MetaAudienceSet?.AudienceCategories?.map((v) => (
          <Tooltip supportingText={v.description}>
            <AudienceCategoryBadge
              label={v.name}
              value={String(v.name).toUpperCase() as AUDIENCE_CATEGORY}
            />
          </Tooltip>
        ))}
      </span>

      {item.MetaInsights?.lowerBoundCount ? (
        <span className={styles.estimateSize}>
          {formatNumber(item.MetaInsights.lowerBoundCount)} -
          {formatNumber(item.MetaInsights.upperBoundCount)}
        </span>
      ) : (
        <span>-</span>
      )}
      <Stats
        id={item.id}
        type={audience.node.audienceId ? "AUDIENCE" : "AUDIENCE_SET"}
      />
    </div>
  );
}

interface IStats {
  id: string;
  type: "AUDIENCE" | "AUDIENCE_SET";
}

function Stats({ id, type }: IStats) {
  if (type === "AUDIENCE") {
    return <AudienceStats id={id} />;
  }

  return <AudienceSetStats id={id} />;
}

function AudienceStats({ id }: { id: string }) {
  const { data } = useAudienceAggregatedStats(id, {
    dateFrom: null,
    dateTo: null,
  });

  if (data) {
    return (
      <>
        <span className={styles.statItem}>
          {data.roas}
          {/* <RoasIcon fill="var(--color-text-disabled)" width={16} height={16} /> */}
        </span>
        <span className={styles.statItem}>
          {data.clicks}
          {/* <ClickIcon fill="var(--color-text-disabled)" width={16} height={16} /> */}
        </span>
      </>
    );
  }

  return (
    <>
      <span className={styles.statItem}>-</span>
      <span className={styles.statItem}>-</span>
    </>
  );
}

function AudienceSetStats({ id }: { id: string }) {
  const { data } = useAudienceSetAggregatedStats(id, {
    dateFrom: null,
    dateTo: null,
  });

  if (data) {
    return (
      <>
        <span className={styles.statItem}>
          {formatNumberByType(data.roas, "MULTIPLIER")}
          {/* <RoasIcon fill="var(--color-text-disabled)" width={16} height={16} /> */}
        </span>
        <span className={styles.statItem}>
          {formatNumberByType(data.ctr, "PERCENTAGE")}
          {/* <ClickIcon fill="var(--color-text-disabled)" width={16} height={16} /> */}
        </span>
      </>
    );
  }

  return (
    <>
      <span className={styles.statItem}>-</span>
      <span className={styles.statItem}>-</span>
    </>
  );
}

interface IPlatformIcons extends SVGProps<any> {
  platform: AUDIENCE_PLATFORMS;
}

function PlatformIcons({ platform, ...props }: IPlatformIcons) {
  switch (platform) {
    case "META":
      return <MetaIcon {...props} />;
    case "INSTAGRAM":
      return <InstagramIcon {...props} />;
    default:
      return <WebsiteIcon {...props} />;
  }
}
