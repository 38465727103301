import { Spinner } from "../../../components/Spinner/Spinner";
import { Client } from "../../../graphql/generated/schema";
import styles from "./ClientsTable.module.scss";
import format from "date-fns/format";
import { Button } from "../../../components/Button/Button";
import { ReactComponent as Edit } from "@material-symbols/svg-600/rounded/edit.svg";
import { ClientThumbnail } from "../../global/components/ClientThumbnail";

interface ClientsTableArgs {
  clients: Client[];
  loading?: boolean;
  setSelectedId: (i: string) => void;
}

export function ClientsTable({
  clients,
  loading,
  setSelectedId,
}: ClientsTableArgs) {
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.itemsList}>
      {clients.map((client) => (
        <ClientItem client={client} setSelectedId={setSelectedId} />
      ))}
    </div>
  );
}

function ClientItem({
  client,
  setSelectedId,
}: {
  client: Client;
  setSelectedId: (i: string) => void;
}) {
  return (
    <div className={styles.clientItem}>
      <div className={styles.nameAndThumbnail}>
        <ClientThumbnail client={client} />
        {client.name}
        <div className={styles.subtext}>
          Added on {format(new Date(client.createdAt), "dd MMM, yyyy")}
        </div>
      </div>

      <div>
        <Button
          size="micro"
          color="normal"
          style="plain"
          onClick={() => setSelectedId(client.id)}
        >
          <div className={styles.buttonWithIcon}>
            <Edit height={15} width={15} color={"currentColor"} />
            Edit
          </div>
        </Button>
      </div>
    </div>
  );
}
