import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Tooltip,
  Bar,
  ReferenceLine,
} from "recharts";

type statValues = Record<string, any>;
type statLabels = {
  color: string;
  name: string;
  dataKey: string;
  barSize?: number;
};

interface IBarGraph {
  statValues: Array<statValues>;
  statLabels: Array<statLabels>;
  bar?: {
    size: number;
    borderRadius: number | [number, number, number, number];
  };
  xAxis: {
    tickFormatter?: (i: string) => string;
    label?: string;
    dataKey: string;
  };
  width: number;
  referenceLine?: { y: number; color: string };
  stack: "same" | "separate";
  height: number;
  yAxis: {
    tickFormatter?: (i: string) => string;
    label: string;
    dataKey?: string;
    scale: "log" | "linear";
  };
}

export function BarGraph({
  statValues,
  statLabels,
  referenceLine,
  xAxis,
  width,
  stack,
  bar,
  height,
  yAxis,
}: IBarGraph) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart width={width} height={height} data={statValues}>
        <CartesianGrid
          strokeDasharray="1 0"
          stroke="rgba(201, 204, 207, 0.25)"
          vertical={false}
        />
        <XAxis
          tickLine={false}
          tickFormatter={xAxis.tickFormatter}
          dataKey={xAxis.dataKey}
        >
          {xAxis.label && <Label value={xAxis.label} position="bottom" />}
        </XAxis>

        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={yAxis.tickFormatter}
          scale={yAxis.scale}
          {...(yAxis.scale === "log" && { domain: ["auto", "auto"] })}
          label={{
            label: yAxis.label,
            position: "insideLeft",
            angle: -90,
          }}
        />
        {/* TODO: use a formatter for format the tooltip */}
        <Tooltip />
        {referenceLine && (
          <ReferenceLine y={referenceLine.y} stroke={referenceLine.color} />
        )}

        {statLabels.map((stat) => (
          <Bar
            radius={bar?.borderRadius}
            barSize={bar?.size}
            key={stat.dataKey}
            label={stat.name}
            dataKey={stat.dataKey}
            stackId={stack === "same" ? "sameStack" : stat.dataKey}
            fill={stat.color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
