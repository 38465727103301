import styles from "./SelectLabel.module.scss";
import Tooltip from "src/components/Tooltip/Tooltip";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";

export function SelectLabel({ labelText, tooltipText }) {
  return (
    <span className={styles.selectLabel}>
      {labelText}
      <Tooltip supportingText={tooltipText}>
        <Info width={16} height={16} />
      </Tooltip>
    </span>
  );
}
