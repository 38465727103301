import {
  CustomerFieldMapInput,
  useCsvAssetQuery,
} from "src/graphql/generated/schema";
import { AddFieldModal } from "./AddFieldModal";
import Badge from "src/components/Badge/Badge";
import CreateFlowCard from "./CreateFlowCard";
import { MapColumnsFieldsSelector } from "./MapColumnsFieldsSelector";
import { Spinner } from "src/components/Spinner/Spinner";
import Table from "src/components/Table/Table";
import styles from "./MapColumns.module.scss";
import { useState } from "react";
import { PreviewContacts } from "./PreviewContacts";
import { ErrorHandler } from "src/components/ErrorHandler";

interface IMapColumns {
  back: () => void;
  next: () => void;
  assetId: string;
  mappingColumns: Record<string, any>;
  setMappingColumns: (i: any) => void;
}

type MacroFields = keyof CustomerFieldMapInput;

const macroFields: {
  label: string;
  value: MacroFields;
}[] = [
  {
    label: "firstName",
    value: "firstName",
  },
  {
    label: "lastName",
    value: "lastName",
  },
  {
    label: "email",
    value: "email",
  },
  {
    label: "phone",
    value: "phone",
  },
  {
    label: "lifetimeValue",
    value: "lifetimeValue",
  },
  {
    label: "ordersCount",
    value: "ordersCount",
  },
  {
    label: "city",
    value: "city",
  },
  {
    label: "country",
    value: "country",
  },
];
function getKeyByFieldMapValue(record: any, value: string) {
  const keys = Object.keys(record).find((key) => {
    // TODO: Find a generic way to get key of value where value is list of objects
    if (Array.isArray(record[key])) {
      return "MetaFields";
    }
    if (record[key] === null) return null;
    return record[key].toString() === value.toString();
  });
  return keys;
}

export default function MapColumns({
  back,
  next,
  assetId,
  mappingColumns,
  setMappingColumns,
}: IMapColumns) {
  const { data, loading, error } = useCsvAssetQuery({
    variables: {
      id: assetId,
    },
  });
  const [openAddFieldModal, setOpenAddFieldModal] = useState(false);
  // fieldsMap is for mapping db fields to csv columns.
  // structure for these fields should look like
  // MacroFieldName : string |  MetaFields : {key, as, type}[]
  const [selectedItems, setSelectedItems] = useState([]);
  const [isPreviewContacts, setIsPreviewContacts] = useState(false);

  function handleColumnSelect(
    csvColumn: string,
    item: { label: string; value: string },
  ) {
    const keyToRemoveValueFrom = getKeyByFieldMapValue(
      mappingColumns,
      item.value,
    );

    if (keyToRemoveValueFrom) {
      setMappingColumns({
        ...mappingColumns,
        [keyToRemoveValueFrom]: null,
        [csvColumn]: item.value,
      });
      return;
    }

    setMappingColumns({
      ...mappingColumns,
      [csvColumn]: item.value,
    });
  }

  if (loading) {
    return (
      <div>
        <Spinner height="screen" />
      </div>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const csvColumns = data?.csvAsset?.columns ?? [];

  const verifyColumnMapping = () => {
    const selectedColumns = csvColumns.filter((_, i) =>
      selectedItems.includes(i),
    );

    const selectedColumnHasMapping = selectedColumns.every((v) =>
      Object.keys(mappingColumns).includes(v),
    );

    if (selectedItems.length === 0) return false;

    if (Object.values(mappingColumns).length === 0) return false;

    const hasRequiredFields = selectedColumns
      .map((v) => mappingColumns[v])
      .some((v) => v === "email" || v === "phone");

    if (!hasRequiredFields) return false;

    if (!selectedColumnHasMapping) return false;

    return true;
  };

  function getRows() {
    const { previewData } = data.csvAsset;
    const result = [];
    previewData.forEach((item) => {
      const subArray = Object.values(item);
      result.push(subArray);
    });
    return result;
  }

  // console.log(data);
  // console.log(getRows());

  return (
    <>
      {isPreviewContacts ? (
        <PreviewContacts
          headings={csvColumns.filter((_, i) => selectedItems.includes(i))}
          setIsPreviewContacts={setIsPreviewContacts}
          rows={getRows()}
          totalContacts={data && data.csvAsset.totalRows}
        />
      ) : (
        <CreateFlowCard
          classNames={styles.maxHeight}
          title="Before importing, make sure your data transfers correctly."
          subtitle="Each column from your CSV needs to be mapped to a Macro Customer table field to import. Select the CSV columns you want to import and update any that are not mapped."
          footerInfo={
            <div>
              {" "}
              {data && data.csvAsset.totalRows} contacts are recognized in this
              file
            </div>
          }
          footerActions={[
            {
              children: "Preview Contacts",
              color: "subdued",
              style: !verifyColumnMapping() ? "solid" : "outline",
              disabled: !verifyColumnMapping(),
              onClick: () => setIsPreviewContacts(true),
            },
            {
              children: "Finish Import",
              disabled: !verifyColumnMapping(),
              onClick: () => {
                const selectedMappingColumns = selectedItems.map(
                  (v) => csvColumns[v],
                );
                const formattedFieldsMap = selectedMappingColumns.reduce(
                  (acc, value) => {
                    return {
                      ...acc,
                      [value]: mappingColumns[value],
                    };
                  },
                  {},
                );

                setMappingColumns(formattedFieldsMap);
                next();
              },
            },
          ]}
        >
          <div className={styles.bold}>
            selected : {selectedItems.length}/{data?.csvAsset?.columns.length}
          </div>
          <AddFieldModal {...{ openAddFieldModal, setOpenAddFieldModal }} />
          <div className={styles.paddingTopBottom}>
            <div className={styles.tableWrapper}>
              <Table
                selectable
                noOfRows={data && data?.csvAsset?.columns?.length}
                headings={[
                  // "Import",
                  "CSV Column",
                  "Macro Field",
                  "Type",
                  "Sample Data",
                ]}
                loading={loading}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                className={styles.csvTable}
                rows={
                  data
                    ? csvColumns.map((csvColumn, i) => [
                        // "",
                        csvColumn,
                        <MapColumnsFieldsSelector
                          value={{
                            value: mappingColumns[csvColumn] ?? "Select item",
                            label: mappingColumns[csvColumn] ?? "Select item",
                          }}
                          disabled={!selectedItems.includes(i)}
                          onChange={(item) => {
                            handleColumnSelect(csvColumn, item);
                          }}
                          options={macroFields}
                          showTop={i > 2}
                        />,
                        "TXT",
                        <div className={styles.badgeWrapper}>
                          {data.csvAsset.previewData.map((s) => (
                            <Badge
                              label={s[csvColumn]}
                              status={"transparent"}
                            />
                          ))}
                        </div>,
                        // .join(", "),
                      ])
                    : []
                }
                rounded
              />
            </div>
          </div>
        </CreateFlowCard>
      )}
    </>
  );
}
