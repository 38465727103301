import { useContext, useEffect } from "react";
import { useLazyTinybirdQuery, useTinybirdQuery } from "src/lib/analyticsApi";
import AuthContext from "src/stores/AuthContext";
import { convertDatetimeToDate } from "./convertDatetimeToDate";
import differenceInDays from "date-fns/differenceInDays";
import subDays from "date-fns/subDays";
import { checkIfAllValuesAreNullOrUndefined } from "src/lib/checkIfAllValuesAreNullOrUndefined";
import { GrowAggregateType } from "../misc/GrowAggregateType";

type GrowFilterOptions = {
  dateFrom?: Date;
  others: Record<string, any>;
  dateTo?: Date;
  shouldShowComparisonValues?: boolean;
  groupBy?: "adsetId" | "activityId" | "clientId" | "audienceId";
  orderBy?: keyof GrowAggregateType;
};

export type GrowAggregatedStats = {
  loading: boolean;
  error: { message: string } | null;
  data: GrowAggregateType | null;
  compareData: GrowAggregateType | null;
};

/**
 * returns aggregated stats for campaign with comparison values
 */
export function useGrowAggregatedStats<T = GrowAggregatedStats>({
  dateFrom,
  others,
  dateTo,
  shouldShowComparisonValues: _shouldShowComparisonValues = true,
  groupBy,
  orderBy,
}: GrowFilterOptions): T {
  const shouldShowComparisonValues =
    _shouldShowComparisonValues && Boolean(dateFrom && dateTo);
  const dayGap = shouldShowComparisonValues
    ? differenceInDays(dateTo, dateFrom)
    : null;
  const comparisonTo = shouldShowComparisonValues ? subDays(dateFrom, 1) : null;
  const comparisonFrom = shouldShowComparisonValues
    ? subDays(comparisonTo, dayGap)
    : null;
  const { userToken } = useContext(AuthContext);

  const { data, loading, error } = useTinybirdQuery(
    "grow_aggregate",
    userToken,
    {
      ...others,
      ...(groupBy && {
        groupBy,
      }),
      ...(orderBy && {
        orderBy,
      }),
      ...(dateFrom && { dateFrom: convertDatetimeToDate(dateFrom) }),
      ...(dateTo && { dateTo: convertDatetimeToDate(dateTo) }),
    },
  );

  const [
    fetchComparisonData,
    {
      data: comparisonData,
      loading: comparisonLoading,
      error: comparisonError,
    },
  ] = useLazyTinybirdQuery("grow_aggregate", userToken);

  useEffect(() => {
    if (shouldShowComparisonValues) {
      fetchComparisonData({
        ...others,
        dateFrom: convertDatetimeToDate(comparisonFrom),
        dateTo: convertDatetimeToDate(comparisonTo),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchComparisonData]);

  // if all values inside the data is null there is no value in sending that
  let transformedData = null;

  // if there is more than single data points. ( when groupBy param is passed in that case data might be in more than single data points)
  if (groupBy && data) {
    if (data.length > 0) {
      transformedData = data.filter(
        (v: any) => v && !checkIfAllValuesAreNullOrUndefined(v),
      );
    }
  } else if (data && !checkIfAllValuesAreNullOrUndefined(data[0])) {
    transformedData = data[0];
  } else {
    transformedData = null;
  }

  let transformedComparisonData = null;

  if (
    comparisonData &&
    !checkIfAllValuesAreNullOrUndefined(comparisonData[0])
  ) {
    transformedComparisonData = comparisonData[0];
  }

  const resp = {
    loading: loading || comparisonLoading,
    error: error || comparisonError,
    data: transformedData,
    compareData: transformedComparisonData,
  } as T;

  return resp;
}
