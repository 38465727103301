import { useFormikContext } from "formik";
import styles from "./InterestsSelectionTable.module.scss";
import { AudienceSetCreateType } from "../misc/audienceSetCreateType";
import { ReactComponent as BehaviorIcon } from "@material-symbols/svg-400/rounded/face_5.svg";
import { ReactComponent as DemographicIcon } from "@material-symbols/svg-400/rounded/demography.svg";
import { ReactComponent as InterestIcon } from "@material-symbols/svg-400/rounded/person_play.svg";
import { MetaInterest } from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { formatNumber } from "src/lib/formatNumber";
import { SwitchSelectionTable } from "./SwitchSelectionTable";

export function InterestsSelectionTable({
  interests,
  shouldShowSelection,
  onShowSelection,
}: {
  interests: MetaInterest[];
  shouldShowSelection: boolean;
  onShowSelection?: () => void;
}) {
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<AudienceSetCreateType>();

  return (
    <SwitchSelectionTable
      shouldShowSelection={shouldShowSelection}
      gridTemplate="3fr 1fr 1fr 1fr"
      headers={[
        "Interests, Behaviors and Demographics",
        "Category",
        "Est. Interest Size",
        "Include/Exclude",
      ]}
      items={interests.map((interest) => ({
        id: interest.id,
        description: <>Path: {interest.path.join("> ")}</>,
        icon: (
          <span className={styles.icon}>
            {interest.type === "interests" ? (
              <InterestIcon width={20} height={20} fill="currentColor" />
            ) : interest.type === "behaviors" ? (
              <BehaviorIcon width={20} height={20} fill="currentColor" />
            ) : (
              <DemographicIcon width={20} height={20} fill="currentColor" />
            )}
          </span>
        ),
        title: interest.name,
        otherRows: [
          snakeCaseToTitleCase(interest.type),
          /* this {" "} is necessary else the dash and number will have no space in between */
          <>
            {formatNumber(interest.audienceSizeLowerBound)} -{" "}
            {formatNumber(interest.audienceSizeUpperBound)}
          </>,
        ],
      }))}
      values={values.interests}
      onShowSelection={onShowSelection}
      onChange={(
        value: "INCLUDE" | "EXCLUDE" | "NONE",
        item: { id: string }
      ) => {
        const newInterests = structuredClone(values.interests);
        const selectedInterest = interests.find((i) => i.id === item.id);

        newInterests[item.id] = {
          ...selectedInterest,
          value,
        };

        setFieldTouched("interests");
        setFieldValue("interests", newInterests);
      }}
    />
  );
}
