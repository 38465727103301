import { ReactComponent as Account } from "@material-design-icons/svg/outlined/account_circle.svg";
import { ReactComponent as AdsClick } from "@material-design-icons/svg/outlined/ads_click.svg";
import { AutomationSidebarHeader } from "./AutomationSidebarHeader";
import { ReactComponent as Bag } from "@material-design-icons/svg/outlined/local_mall.svg";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import { MenuItem } from "./MenuItem";
import styles from "./AutomationStartPoint.module.scss";
import { useFormikContext } from "formik";

const startingMenu = [
  {
    icon: <Account />,
    title: "Contact Activity",
    value: "CONTACT",
    description:
      "These starting points add contacts to a journey map based on their activity.",
  },
  {
    icon: <Bag />,
    title: "Shopping Activity",
    value: "CONTACT",
    description:
      "These starting points add contacts to a journey map based on their activity.",
  },
  {
    icon: <AdsClick />,
    title: "Marketing Activity",
    value: "CONTACT",
    description:
      "These starting points add contacts to a journey map based on their activity.",
  },
];

export function AutomationStartPoint() {
  const { setFieldValue } = useFormikContext();
  return (
    <div>
      <AutomationSidebarHeader title="Choose starting point" />
      <ul>
        {startingMenu.map((item, _index) => (
          <MenuItem
            title={item.title}
            icon={item.icon}
            description={item.description}
            action={{
              label: <ChevronRight />,
              onClick: () => {
                setFieldValue("section", item.value);
              },
            }}
          />
        ))}
      </ul>
    </div>
  );
}
