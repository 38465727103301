import { useContext } from "react";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { convertFromArrayToObject } from "src/lib/convertFromArrayToObject";
import { GraphHeader } from "src/modules/global/components/GraphHeader";
import AuthContext from "src/stores/AuthContext";

interface ISegmentGrowthGraphHeader {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}

export function SegmentGrowthGraphHeader({
  segmentId,
  dateFrom,
  dateTo,
}: ISegmentGrowthGraphHeader) {
  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "segment_change_aggregate",
    userToken,
    {
      segmentId,
      dateFrom,
      dateTo,
    }
  );

  if (error) {
    return null;
  }

  if (loading) {
    return null;
  }

  let insights = convertFromArrayToObject(
    data,
    (a) => a.action,
    (a) => a.actionCount
  );

  return (
    <GraphHeader
      title={`Segment Growth`}
      stats={[
        {
          color: "#347C84",
          dataKey: "add_customer_in_segment",
          label: "Customer Added",
          value: insights?.add_customer_in_segment,
          valueType: "NUMBER",
        },
        {
          color: "#FFA7A3",
          dataKey: "remove_customer_from_segment",
          label: "Customer Removed",
          value: insights?.remove_customer_from_segment,
          valueType: "NUMBER",
        },
      ]}
    />
  );
}
