import {
  CustomersSortBy,
  useCustomersLazyQuery,
  useIntegrationsStatusQuery,
} from "src/graphql/generated/schema";
import { useEffect, useMemo, useState } from "react";
import { CustomerPageHeader } from "../components/CustomersPageHeader";
import { CustomersTableWrapper } from "../components/CustomersTableWrapper";
import { AddCustomerModal } from "../components/AddCustomerModal";
import { itemsPerPage } from "../../global/misc/itemsPerPage";
import { ErrorHandler } from "src/components/ErrorHandler";
import { showPaginationChecks } from "src/modules/global/functions/showPaginationCheck";
import { useNavigate } from "react-router-dom";
import { CustomersEmpty } from "../components/CustomersEmpty";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "src/modules/global/misc/dateFilterUtils";
import { CustomerSegmentEditor } from "../components/CustomerSegmentEditor";

export function CustomerListingPage() {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    cursor: null,
    take: itemsPerPage,
  });
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [sortBy, setSortBy] = useState<CustomersSortBy>({
    direction: "DESC",
    field: "CREATED_AT",
  });
  const {
    data: integrationsStatusData,
    loading: integrationsStatusLoading,
    error: integrationsStatusError,
  } = useIntegrationsStatusQuery();

  const [filters, setFilters] = useState({
    searchQuery: "",
    dateFilter: "ALL_TIME" as DateFilter,
  });
  const searchQueryDebounced = useDebouncedVariable(filters.searchQuery);

  const [customersQueryFn, { data, error, loading, called }] =
    useCustomersLazyQuery();

  const areFiltersApplied = useMemo(
    () => filters.dateFilter !== "ALL_TIME" || filters.searchQuery !== "",
    [filters],
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!integrationsStatusData) {
      return;
    }

    if (
      data.customers.edges.length === 0 &&
      integrationsStatusData.integrationsStatus.shopify === "INACTIVE" &&
      areFiltersApplied
    ) {
      navigate("/contacts/create");
    }
  }, [integrationsStatusData, data, areFiltersApplied, navigate]);

  useEffect(() => {
    customersQueryFn({
      variables: {
        input: {
          take: pagination.take,
          cursor: pagination.cursor,
          sortBy: {
            direction: sortBy.direction,
            field: sortBy.field,
          },
          filters: {
            createdAt: getDateFilterFromFilterEnum(filters.dateFilter),
            name: searchQueryDebounced,
            // createdAfter:
          },
        },
      },
    });
  }, [
    filters.dateFilter,
    searchQueryDebounced,
    sortBy,
    pagination,
    customersQueryFn,
  ]);

  const showPagination = useMemo(() => {
    if (!data || loading || !called) {
      return false;
    }

    return showPaginationChecks({
      itemsPerPage: itemsPerPage,
      itemEdges: data?.customers?.edges,
      pageInfo: data?.customers?.pageInfo,
    });
  }, [data, loading, called]);

  if (error || integrationsStatusError) {
    return <ErrorHandler error={error} />;
  }

  // when filters are applied that screen is shown with filters
  if (data?.customers?.edges.length === 0 && !areFiltersApplied) {
    return (
      <CustomersEmpty
        showAddCustomerModal={showAddCustomerModal}
        setShowAddCustomerModal={setShowAddCustomerModal}
      />
    );
  }

  return (
    <div>
      <AddCustomerModal
        showAddCustomerModal={showAddCustomerModal}
        setShowAddCustomerModal={setShowAddCustomerModal}
      />
      <CustomerPageHeader
        setFilters={setFilters}
        filters={filters}
        setSortBy={setSortBy}
        sortBy={sortBy}
        setShowAddCustomerModal={setShowAddCustomerModal}
      />
      {/* <CustomerSegmentEditor /> */}
      <CustomersTableWrapper
        loading={loading || integrationsStatusLoading || !called}
        showPagination={showPagination}
        paginationPayload={data?.customers?.pageInfo}
        customers={data?.customers?.edges}
        filters={filters}
        setFilters={setFilters}
        setPagination={setPagination}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    </div>
  );
}
