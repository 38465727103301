import { useContext, useEffect } from "react";
import { useLazyTinybirdQuery, useTinybirdQuery } from "src/lib/analyticsApi";
import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";
import AuthContext from "src/stores/AuthContext";
import ResourceItem from "src/components/ResourceItem/ResourceItem";
import format from "date-fns/format";
import emailCustomer from "../assets/emailCustomer.svg";
import styles from "./SingleCustomerActivityItem.module.scss";
import { convertFromArrayToObject } from "src/lib/convertFromArrayToObject";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";

interface ISingleCustomerActivityItem {
  outreach: {
    name: string;
    createdAt: string;
    id: string;
  };
  dateFrom: string;
  dateTo: string;
}

export function SingleCustomerActivityItem({
  outreach,
  dateFrom,
  dateTo,
}: ISingleCustomerActivityItem) {
  const { userToken } = useContext(AuthContext);
  const { data, error } = useTinybirdQuery("engage_aggregate", userToken, {
    activityId: outreach.id,
    dateFrom,
    dateTo,
  });
  const { data: customerEngageEventData, error: customerEngageEventError } =
    useTinybirdQuery("customer_engage_events", userToken, {
      activityId: outreach.id,
      dateFrom,
      dateTo,
    });

  if (error) {
    return <GraphLoadingError error={error} height={300} />;
  }

  const insights = convertFromArrayToObject(
    data,
    (item) => item.action,
    (item) => item.actionCount
  );

  const latestEvent = customerEngageEventData?.[0]
    ? snakeCaseToTitleCase(customerEngageEventData[0].action)
    : "No Event";

  return (
    <ResourceItem
      title={outreach.name}
      icon={emailCustomer}
      subtitle={`Sent on ${format(
        new Date(outreach.createdAt),
        "dd MMM yyyy"
      )}`}
      badge={latestEvent}
      className={styles.activity}
      children={
        data && (
          <>
            <div className={styles.conversion}>
              <p className={styles.conversionValue}>
                {insights.view_email ?? 0}
              </p>
              <span className={styles.conversionText}>Open</span>
            </div>
            <div className={styles.conversion}>
              <p className={styles.conversionValue}>
                {insights.click_link ?? 0}
              </p>
              <span className={styles.conversionText}>Clicks</span>
            </div>
          </>
        )
      }
    />
  );
}
