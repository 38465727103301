import { Toaster as HotToaster, resolveValue } from "react-hot-toast";
import { ReactComponent as CloseIcon } from "@material-design-icons/svg/outlined/close.svg";
import styles from "./Toaster.module.scss";

export function Toaster() {
  return (
    <HotToaster
      position="bottom-center"
      toastOptions={{
        style: {
          transition: "all 0.3s ease-in-out",
          display: "inline-flex",
          alignItems: "center",
          padding: "1rem 1.5rem",
          fontWeight: 500,
          gap: "1rem",
          borderRadius: "40px",
          maxHeight: "52px",
          backdropFilter: "blur(10px)",
        },
        success: {
          style: {
            background: "rgb(224, 240, 236)",
            color: "rgb(0, 66, 49)",
            fill: "rgb(0, 66, 49)",
          },
        },
        error: {
          style: {
            backgroundColor: "rgb(255, 242, 242)",
            fill: "rgb(215, 44, 13)",
            color: "rgb(215, 44, 13)",
          },
        },
        loading: {
          style: {},
        },
        blank: {
          style: {
            fill: "var(--color-primary)",
            color: "var(--color-primary)",
            background: "rgba(var(--color-primary-code), 0.125)",
          },
        },
      }}
    >
      {(t) => (
        <div
          style={{
            ...t.style,
            animation: t.visible
              ? "toast-enter 0.25s ease-in-out"
              : "0.25s ease-in-out",
          }}
        >
          {resolveValue(t.message, t)}
          <button type="button" className={styles.crossBtn}>
            <CloseIcon width={20} height={20} />
          </button>
        </div>
      )}
    </HotToaster>
  );
}
