import styles from "./Paywall.module.scss";
import engageSectionIco from "../assets/engageSectionIco.png";
import growSectionIco from "../assets/growSectionIcon.svg";
import upgradeBannerImage from "../assets/upgradeBannerEngage.png";
import growBannerImage from "../assets/upgradeBannerGrow.png";
import { useParams } from "react-router-dom";
import { automationFeaturesArr } from "../misc/automationPlanFeatures";
import { UpgradePlanAutomate } from "../components/UpgradePlanAutomate";
import {
  IPaywallTemplate,
  PaywallTemplate,
} from "../components/PaywallTemplate";
import { audiencePlanFeatures } from "../misc/audiencePlanFeatures";
import classNames from "classnames";
import { UpgradePlanAudience } from "../components/UpgradePlanAudience";
import { UpgradePlanCampaign } from "../components/UpgradePlanCampaign";

export function Paywall() {
  const { module } = useParams();

  const paywallPropsFromModules: {
    [x: string]: IPaywallTemplate;
  } = {
    outreaches: {
      planName: "engage",
      bannerImage: upgradeBannerImage,
      sectionIco: engageSectionIco,
      header: {
        description: `"Macro's automation flow propels brand growth, enhancing user strategies for exponential success."`,
        details: <UpgradePlanCampaign />,
        title: (
          <h1 className={classNames(styles.heading, styles.engage)}>
            Elevate with{" "}
            <span className={styles.highlight}> Multi-Platform Access </span>
          </h1>
        ),
      },
      featuresSection: {
        title: (
          <h2 className={classNames(styles.sectionHead, styles.engage)}>
            Everything you need -
            <span className={styles.highlight}> all in one software </span>
          </h2>
        ),
        description:
          "From Posting Campaign Across Platforms, Seamless Workflow, All in One Place.",
        features: automationFeaturesArr,
      },
      primaryColor: "engage",
    },
    templates: {
      planName: "engage",
      bannerImage: upgradeBannerImage,
      sectionIco: engageSectionIco,
      header: {
        description: `"Macro's automation flow propels brand growth, enhancing user strategies for exponential success."`,
        details: <UpgradePlanAutomate />,
        title: (
          <h1 className={classNames(styles.heading, styles.engage)}>
            Elevate with{" "}
            <span className={styles.highlight}> Template Creation </span>
            Upgrade!
          </h1>
        ),
      },
      featuresSection: {
        title: (
          <h2 className={classNames(styles.sectionHead, styles.engage)}>
            Everything you need -
            <span className={styles.highlight}> all in one software </span>
          </h2>
        ),
        description:
          "From Posting Campaign Across Platforms, Seamless Workflow, All in One Place.",
        features: automationFeaturesArr,
      },
      primaryColor: "engage",
    },
    automations: {
      planName: "engage",
      bannerImage: upgradeBannerImage,
      sectionIco: engageSectionIco,
      header: {
        description: `"Macro's automation flow propels brand growth, enhancing user strategies for exponential success."`,
        details: <UpgradePlanAutomate />,
        title: (
          <h1 className={classNames(styles.heading, styles.engage)}>
            Elevate with <span className={styles.highlight}> Automated </span>
            Upgrade!
          </h1>
        ),
      },
      featuresSection: {
        title: (
          <h2 className={classNames(styles.sectionHead, styles.engage)}>
            Everything you need -
            <span className={styles.highlight}> all in one software </span>
          </h2>
        ),
        description:
          "From Posting Campaign Across Platforms, Seamless Workflow, All in One Place.",
        features: automationFeaturesArr,
      },
      primaryColor: "engage",
    },
    audiences: {
      planName: "grow",
      bannerImage: growBannerImage,
      sectionIco: growSectionIco,
      header: {
        description: `"Macro's automation flow propels brand growth, enhancing user strategies for exponential success."`,
        details: <UpgradePlanAudience />,
        title: (
          <h1 className={classNames(styles.heading, styles.grow)}>
            Elevate with{" "}
            <span className={styles.highlight}> Audience Template </span>
            Upgrade!
          </h1>
        ),
      },
      featuresSection: {
        title: (
          <h2 className={classNames(styles.sectionHead, styles.grow)}>
            Everything you need -
            <span className={styles.highlight}> all in one plan </span>
          </h2>
        ),
        description:
          "From prototyping to production - develop without switching tabs.",
        features: audiencePlanFeatures,
      },
      primaryColor: "grow",
    },
  };

  return (
    <PaywallTemplate
      planName={paywallPropsFromModules[module].planName}
      sectionIco={paywallPropsFromModules[module].sectionIco}
      primaryColor={paywallPropsFromModules[module].primaryColor}
      header={paywallPropsFromModules[module].header}
      featuresSection={paywallPropsFromModules[module].featuresSection}
      bannerImage={paywallPropsFromModules[module].bannerImage}
    />
  );
}
