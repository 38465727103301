import {
  ActionCard,
  IActionCard,
} from "src/modules/global/components/ActionCard";
import styles from "./OverviewIntegrationCards.module.scss";
import metaActionCardIcon from "../assets/metaActionCard.png";
import shopifyActionCardIcon from "../assets/shopifyActionCardIcon.png";
import websiteActionCardIcon from "../assets/webIntegrateOverview.png";
import emailActionCardIcon from "../assets/emailIntegrateOverview.png";
import { IntegrationsStatusPayload } from "src/graphql/generated/schema";
import { ReactComponent as NewReleases } from "@material-design-icons/svg/filled/new_releases.svg";

export default function OverviewIntegrationCards({
  integrationStatus,
  exclude = "",
}: {
  integrationStatus: Pick<IntegrationsStatusPayload, "meta" | "shopify">;
  exclude?: "meta" | "shopify" | "";
}) {
  const actionCards: IActionCard[] = [
    {
      action: {
        children:
          integrationStatus.meta !== "ACTIVE" ? (
            "View Integration"
          ) : (
            <div className={styles.iconWithText}>
              <NewReleases />
              <span>Integrated Successfully</span>
            </div>
          ),
        to: "/settings/integrations/meta",
        style: integrationStatus.meta !== "ACTIVE" ? "outline" : "plain",
      },
      description:
        "Add and connect your facebook ad accounts from ad manager to macro",
      image: metaActionCardIcon,
      title: "Integrate Meta Platform to run Facebook Ads",
      platform: "meta",
    },
    {
      action: {
        children:
          integrationStatus.shopify === "INACTIVE" ? (
            "View Integration"
          ) : (
            <div className={styles.iconWithText}>
              <NewReleases />
              <span>Integrated Successfully</span>
            </div>
          ),
        to: "/settings/integrations/shopify",
        style: integrationStatus.shopify === "INACTIVE" ? "outline" : "plain",
      },
      description:
        "Integrate your shopify store with Macro and we will directly import customers from your store, and they will be synced",
      image: shopifyActionCardIcon,
      title: "Integrate Shopify Store",
      platform: "shopify",
    },
    {
      action: {
        // children:
        //   integrationStatus.website === "INACTIVE" ? (
        //     "View Integration"
        //   ) : (
        //     <div className={styles.iconWithText}>
        //       <NewReleases />
        //       <span>Integrated Successfully</span>
        //     </div>
        //   ),
        children: "View Integration",
        to: "/settings/integrations/website",
        style: integrationStatus.shopify === "INACTIVE" ? "outline" : "plain",
      },
      description:
        "By integrating, you will get real time analytics and KPIs and plan your next move accordingly",
      image: websiteActionCardIcon,
      title: "Connect your website and see analytics in real time",
    },
    {
      action: {
        children: "Coming Soon",
      },
      description:
        "Email campaign can be powerful tool for product sale and personalised communication with your customers",
      image: emailActionCardIcon,
      title: "Launch you first email campaign and boast sales",
    },
  ];
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>Get started with Campaigns</div>
        <div className={styles.subtitle}>
          Setup your macro to launch campaigns and react to your audience
        </div>
      </div>

      <div className={styles.actionCardsListContainer}>
        {actionCards
          .filter((item) => item.platform !== exclude)
          .map((v, idx) => (
            <ActionCard {...v} border="minimal" key={idx} />
          ))}
      </div>
    </div>
  );
}
