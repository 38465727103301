import {
  ActivityStatusEnum,
  useActivityStatusLazyQuery,
} from "src/graphql/generated/schema";
import styles from "./ActivityStatusBadge.module.scss";
import classNames from "classnames";
import { titleCase } from "src/lib/titleCase";
import { useEffect } from "react";

interface IActivityStatusBadge {
  activityId: string;
  status: ActivityStatusEnum;
}

export function ActivityStatusBadge({
  activityId,
  status,
}: IActivityStatusBadge) {
  // refetching the data willnot update the query since the
  // type there is ActivityUnion not Activity
  // const [activityStatusQueryFn, { data, stopPolling, startPolling }] =
  //   useActivityStatusLazyQuery();
  //
  // useEffect(() => {
  //   if (status === "PUBLISHING" && activityId) {
  //     activityStatusQueryFn({
  //       variables: {
  //         activityId: activityId,
  //       },
  //     });
  //     startPolling(1000);
  //   }
  //
  //   return stopPolling;
  // }, [status, activityId]);
  //
  // useEffect(() => {
  //   if (!data) return;
  //
  //   if (data.activity.status !== "PUBLISHING") {
  //     // refetch the all campaigns query and then stop polling
  //     activityStatusQueryFn({
  //       variables:{
  //         activityId,
  //       },
  //
  //     })
  //     stopPolling();
  //   }
  // }, [data]);

  return (
    <span
      className={classNames(styles.badge, {
        [styles.active]: status === "ACTIVE",
        [styles.archived]: status === "ARCHIVED",
        [styles.completed]: status === "COMPLETED",
        [styles.draft]: status === "DRAFT",
        [styles.error]: status === "ERROR",
        [styles.paused]: status === "PAUSED",
        [styles.publishing]: status === "PUBLISHING",
        [styles.scheduled]: status === "SCHEDULED",
      })}
    >
      {titleCase(status === "ERROR" ? "FAILED" : status)}
    </span>
  );
}
