import { ReactComponent as PersonAdd } from "@material-design-icons/svg/outlined/person_add.svg";
import styles from "./ManageTeam.module.scss";
import { useState } from "react";
import { AddMembersModal } from "./AddMembersModal/AddMembersModal";
import Select from "src/components/Select/Select";
import { Button } from "src/components/Button/Button";
import SimpleTable from "src/components/SimpleTable/SimpleTable";
import { PageHeader } from "src/components/PageHeader/PageHeader";

const headings = ["Member", "Email", "Role", "Added on"];

const rows = [
  [
    "Taniyah Combs",
    "TaniyahCombs@gmail.com",
    <Select
      options={[
        { label: "Admin", value: "Admin" },
        { label: "Member", value: "Member" },
      ]}
      value={"Member"}
      onChange={() => {}}
      btnClass={styles.role}
    />,
    "23 March, 2011",
  ],
  [
    "Taniyah Combs",
    "TaniyahCombs@gmail.com",
    <Select
      options={[
        { label: "Admin", value: "Admin" },
        { label: "Member", value: "Member" },
      ]}
      value={"Admin"}
      onChange={() => {}}
      btnClass={styles.role}
    />,
    "23 March, 2011",
  ],
  [
    "Taniyah Combs",
    "TaniyahCombs@gmail.com",
    <Select
      options={[
        { label: "Admin", value: "Admin" },
        { label: "Member", value: "Member" },
      ]}
      value={"Admin"}
      onChange={() => {}}
      btnClass={styles.role}
    />,
    "23 March, 2011",
  ],
];

export function ManageTeam() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <AddMembersModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <PageHeader title={"Manage Team"} />
      <div className={styles.page}>
        <div className={styles.head}>
          <div>
            <h2>List of your Teams</h2>
            <p>
              With the help of third party tools and services, enhance the power
              of macro.
            </p>
          </div>
          <Button
            size="micro"
            className={styles.addBtn}
            onClick={() => setShowModal(true)}
          >
            <span className={styles.addBtn}>
              Add Team
              <PersonAdd className={styles.personIco} />
            </span>
          </Button>
        </div>
        <SimpleTable
          headings={headings}
          rows={rows}
          showHeadings={true}
          className={styles.table}
        />
      </div>
    </>
  );
}
