export function convertFromArrayToObject(
  arr: Record<string, any>[],
  keyFn: (i: Record<string, any>) => string,
  valueFn: (i: Record<string, any>) => any
) {
  if (!arr) {
    return {};
  }

  return arr?.reduce((acc, a) => ({ ...acc, [keyFn(a)]: valueFn(a) }), {});
}
