import { InputFormik } from "src/components/InputFormik/InputFormik";

interface Props {
  name: string;
}

export function AdTitleField({ name }: Props) {
  return (
    <InputFormik
      label="Ad Title"
      placeholder="Write a short headline"
      name={name}
    />
  );
}
