import { Button, IButton } from "../../../components/Button/Button";
import styles from "./OverallUsageStats.module.scss";

interface Stats {
  label: string;
  value: any;
  valueIcon?: React.ReactNode;
  action?: IButton;
}

export function OverallUsageStats({ stats }: { stats: Stats[] }) {
  return (
    <div className={styles.statsList}>
      {stats.map((v) => (
        <div className={styles.stat}>
          <div className={styles.label}>{v.label}</div>
          <div className={styles.value}>
            {v.valueIcon}
            {v.value}
          </div>
          <div>
            {v.action && (
              <Button
                size="micro"
                style="plain"
                className={styles.action}
                {...v.action}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
