import classNames from "classnames";
import { ISmartphone, Smartphone } from "src/components/Smartphone/Smartphone";
import styles from "./FacebookAdPreview.module.scss";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import { LineItems } from "src/lib/types";
import facebookFeed from "../assets/facebookFeed.svg";
import instaStory from "../assets/instaStory.svg";
import instaFeed from "../assets/instaFeed.svg";
import { useState } from "react";
import { Asset } from "src/components/Smartphone/Asset";
import { RadioGroup } from "@headlessui/react";
import { AdInput } from "../misc/createCampaignTypes";

interface Props {
  adType: AdInput["staticAdType"];
  lineItems?: LineItems[];
  defaultSelectedLineItem?: number;
  showModal?: () => void;
  isValid?: boolean;
  primaryText?: string;
  callToActionLabel?: string;
  adAccountName?: string;
  profilePicture?: string;
}

const previewTypes = [
  {
    variant: "feed",
    type: "facebook",
    label: "Facebook Feed",
    icon: facebookFeed,
  },
  {
    variant: "feed",
    type: "instagram",
    label: "Instagram Feed",
    icon: instaFeed,
  },
  {
    variant: "stories",
    type: "instagram",
    label: "Instagram Story",
    icon: instaStory,
  },
];

export function FacebookAdPreview({
  lineItems,
  adType,
  defaultSelectedLineItem,
  showModal,
  primaryText,
  callToActionLabel,
  isValid = false,
  adAccountName,
  profilePicture,
}: Props) {
  const filteredLineItems = lineItems.filter((item) => Boolean(item.PostAsset));
  const [selectedPreview, setSelectedPreview] = useState(0);

  return (
    <div>
      <div className={styles.previewCard}>
        <div className={styles.phonePreviewContainer}>
          <Smartphone
            preview={filteredLineItems}
            adType={adType}
            variant={
              previewTypes[selectedPreview].variant as ISmartphone["variant"]
            }
            type={previewTypes[selectedPreview].type as ISmartphone["type"]}
            primaryText={
              adType === "EXISTING_POST"
                ? lineItems[0]?.description
                : primaryText
            }
            callToActionLabel={callToActionLabel}
            accountName={adAccountName || "Hello"}
            profilePicture={profilePicture}
          />
        </div>
        <div className={styles.thumbnailContainer}>
          <p className={styles.thumbnailHeader}>Ad Placements</p>
          <RadioGroup
            value={selectedPreview}
            onChange={setSelectedPreview}
            className={styles.thumbnailOptions}
          >
            {previewTypes.map((item, idx) => (
              <RadioGroup.Option value={idx} key={idx}>
                <div
                  className={classNames(styles.thumbnailAssetWrapper, {
                    [styles.showOutline]: idx === selectedPreview,
                  })}
                >
                  <Asset
                    type={
                      (filteredLineItems[0]?.StoryAsset
                        ? filteredLineItems[0]?.StoryAsset.contentType
                        : filteredLineItems[0]?.PostAsset?.contentType) ||
                      "IMAGE"
                    }
                    preview={
                      (filteredLineItems[0]?.StoryAsset &&
                      item.variant === "stories"
                        ? filteredLineItems[0]?.StoryAsset?.uri
                        : filteredLineItems[0]?.PostAsset?.uri) || item.icon
                    }
                    name={
                      filteredLineItems[0]?.StoryAsset
                        ? filteredLineItems[0]?.StoryAsset?.name
                        : filteredLineItems[0]?.PostAsset?.name
                    }
                    className={styles.thumbnailAsset}
                    loop={true}
                  />
                  <div
                    className={classNames(styles.overlay, {
                      [styles.showOverlay]: idx === selectedPreview,
                    })}
                  ></div>
                </div>
                <RadioGroup.Label
                  className={classNames(styles.thumbnailLabel, {
                    [styles.thumbnailLabelDark]: idx === selectedPreview,
                  })}
                >
                  {item.label}
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      </div>
      {/* HACK: hidden for the time being */}
      {/* {adType === "CUSTOM_CREATIVE" && showModal && (
        <>
          <Button
            width="full"
            className={classNames(styles.previewBtn, {
              [styles.disableBtn]: !isValid,
            })}
            style="outline"
            color="subdued"
            onClick={showModal}
            disabled={!isValid}
          >
            <span className={styles.previewBtnText}>Full Preview</span>
            <IframeIcon width={18} height={18} />
          </Button>
          <div className={styles.tipContainer}>
            <p className={styles.tip}>
              Once you add all the details for the Ad, you will be able to see
              the preview
            </p>
          </div>
        </>
      )} */}
    </div>
  );
}

function EmptyState() {
  return (
    <div>
      <Info className={styles.infoIco} />
      <p className={styles.emptyText}>
        To see the preview please add details & assets to your ads.
      </p>
    </div>
  );
}
