import { getIn, useFormikContext } from "formik";
import { CampaignInput } from "../misc/createCampaignTypes";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";

export function AdPhoneNumberField({ baseKey }: { baseKey: string }) {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<CampaignInput>();

  const fieldKey = `${baseKey}.phoneNumber`;
  const fieldValue = getIn(values, fieldKey) ?? "";
  const fieldError = getIn(errors, fieldKey);
  const fieldTouched = getIn(touched, fieldKey);

  const countryCodeFieldKey = `${baseKey}.phoneCountryCode`;
  const countryCodeFieldValue = getIn(values, countryCodeFieldKey);

  return (
    <PhoneNumberInput
      defaultPhoneCode="91"
      countryCodeOnChange={(value) => {
        setFieldTouched(countryCodeFieldKey);
        setFieldValue(countryCodeFieldKey, value);
      }}
      countryCodeValue={countryCodeFieldValue}
      label="Phone Number"
      onBlur={() => {
        setFieldTouched(fieldKey);
      }}
      onChange={(value) => {
        setFieldValue(fieldKey, value);
      }}
      error={fieldError && fieldTouched && fieldError}
      value={fieldValue}
      placeholder="XXXXXXXXXX"
      name={fieldKey}
    />
  );
}
