import aiBanner from "../../assets/aiBanner.png";
import styles from "./AIBanner.module.scss";
import { Button } from "src/components/Button/Button";

export function AIBanner() {
  return (
    <div className={styles.aiBanner}>
      <div className={styles.contentSection}>
        <h1 className={styles.heading}>
          Accelerate your ad creation with AI's swift
        </h1>
        <p className={styles.copy}>
          Craft your brand persona effortlessly with our profile builder,
          enhanced by the power of AI. Witness your brand flourish in no time!
        </p>
        <div className={styles.buttonWrapper}>
          <Button disabled>Coming Soon</Button>
        </div>
      </div>
      <div className={styles.imageSection}>
        <img src={aiBanner} width={290} height={148} alt="ai globe" />
      </div>
    </div>
  );
}
