import { Form, Formik } from "formik";
import styles from "./AdTemplatePreviewSection.module.scss";
import { useRef } from "react";
import { AdTemplateCreateForm } from "./AdTemplateCreateForm";
import {
  MetaActivityAdInput,
  useMetaActivityAdTemplateCreateMutation,
} from "../../../../graphql/generated/schema";
import Slider from "../../../global/components/Slider";
import { AdTemplatePreviewSection } from "./AdTemplatePreviewSection";
import { toast } from "react-hot-toast";
import { FormValues } from "../../misc/adTemplateCreateTypes";
import { adTemplateCreateSchema } from "../../misc/adTemplateCreateSchema";

const defaultValuesSingle: FormValues = {
  primaryText: "",
  title: "Create New Ad",
  type: "SINGLE_IMAGE_OR_VIDEO",
  phoneCountryCode: "91",
  LineItems: [
    {
      order: 0,
      headline: "",
      description: "",
      link: "",
    },
  ],
};

const defaultValuesCarousel: FormValues = {
  primaryText: "",
  title: "New Ad Template",
  type: "CAROUSEL",
  phoneCountryCode: "91",
  LineItems: [
    {
      order: 0,
      headline: "",
      description: "",
      link: "",
    },
    {
      order: 1,
      headline: "",
      description: "",
      link: "",
    },
  ],
};

interface IAdTemplateSlideOver {
  isOpen: boolean;
  onClose: () => void;
  isCarousel?: boolean;
  initialValues?: FormValues;
}

function generateActivityAdTemplateFromInput(
  input: FormValues
): MetaActivityAdInput {
  const type = input.type;

  switch (type) {
    case "SINGLE_IMAGE_OR_VIDEO":
    case "CAROUSEL":
      return {
        primaryText: input.primaryText,
        name: input.title.trim(),
        adType: "CUSTOM_CREATIVE",
        phoneNumber: input.phoneNumber,
        phoneCountryCode: input.phoneCountryCode,
        pageWelcomeMessageId: input.pageWelcomeId,
        LineItems: input.LineItems.map((v, i) => ({
          order: i,
          link: v.link,
          headline: v.headline,
          description: v.description,
          postAssetId: v.PostAsset?.id,
          storyAssetId: v.StoryAsset?.id,
        })),
      };
    case "CATALOGUE_AD":
      return {
        name: input.title.trim(),
        adType: "CATALOG",
        primaryText: input.primaryText,
        enabledAccountId: input.adAccountId,
        catalogueId: input.catalogueId,
        MetaProductSetId: input.MetaProductSetId,
        phoneNumber: input.phoneNumber,
        phoneCountryCode: input.phoneCountryCode,
        pageWelcomeMessageId: input.pageWelcomeId,
        LineItems: input.LineItems.map((v, i) => ({
          order: i,
          headline: v.headline,
          description: v.description,
        })),
      };
    case "EXISTING_POST":
      return {
        name: input.title.trim(),
        adType: "EXISTING_POST",
        primaryText: input.existingPost.caption,
        enabledAccountId: input.adAccountId,
        metaPageId: input.metaPageId,
        metaInstagramId: input.metaInstagramPageId,
        metaPageConnectedInstagramBusinessAccountId:
          input.metaPageConnectedInstagramBusinessAccountId,
        existingPostId: input.existingPostId,
        phoneNumber: input.phoneNumber,
        phoneCountryCode: input.phoneCountryCode,
        pageWelcomeMessageId: input.pageWelcomeId,
        LineItems: [],
      };
    default:
      throw new Error("type not found");
  }
}

export function AdTemplateSlideOver({
  isOpen,
  onClose,
  isCarousel = false,
  initialValues = isCarousel ? defaultValuesCarousel : defaultValuesSingle,
}: IAdTemplateSlideOver) {
  const inputRef = useRef<HTMLInputElement>();

  const [metaActivityAdTemplateFunc] =
    useMetaActivityAdTemplateCreateMutation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={adTemplateCreateSchema}
      onSubmit={async (values: typeof initialValues) => {
        const input = generateActivityAdTemplateFromInput(values);
        await metaActivityAdTemplateFunc({
          variables: {
            input,
          },
          refetchQueries: ["metaActivityAdTemplates"],
          awaitRefetchQueries: true,
        });
        toast.success("Successfully created ad template");
        onClose();
      }}
    >
      {({ values }) => (
        <Form>
          <Slider
            isOpen={isOpen}
            onClose={onClose}
            closeOverlayClick={false}
            initialFocus={inputRef}
            title={
              <div className={styles.titleContainer}>
                <span>{values.title}</span>
              </div>
            }
            panelClassNames={styles.panel}
          >
            <div className={styles.slideOverContainer}>
              <AdTemplateCreateForm />
              <AdTemplatePreviewSection
                metaPageName="Hello"
                profilePicture={null}
              />
            </div>
          </Slider>
        </Form>
      )}
    </Formik>
  );
}
