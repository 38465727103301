import classNames from "classnames";
import styles from "./GraphHeader.module.scss";
import { formatNumberByType } from "../functions/formatNumberByType";
import { IValueType } from "src/components/StatCard/StatCard";
import { getRelativePercentage } from "../functions/getRelativePercentage";
import Badge from "src/components/Badge/Badge";
import { ReactComponent as ArrowUp } from "@material-design-icons/svg/outlined/arrow_upward.svg";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/arrow_downward.svg";
import { useGetActiveCurrencyCode } from "../functions/useGetActiveCurrencyCode";

type stat = {
  valueType: IValueType;
  color: string;
  dataKey: string;
  label: string;
  value: number;
  comparisonValue?: number;
};

interface IGraphHeader {
  stats: stat[];
  title: string;
  caption?: string;
}

export function GraphHeader({ stats, title, caption }: IGraphHeader) {
  return (
    <div className={styles.header}>
      <div className={styles.text}>
        <span className={styles.recipients}>{title}</span>
        {caption && <span className={styles.caption}>{caption}</span>}
      </div>
      <div className={styles.statsContainer}>
        {stats.map((stat) => (
          <StatItem
            color={stat.color}
            key={stat.dataKey}
            label={stat.label}
            valueType={stat.valueType}
            value={stat.value}
            comparisonValue={stat.comparisonValue}
          />
        ))}
      </div>
    </div>
  );
}

function StatItem({ label, color, value, valueType, comparisonValue }) {
  // !!showComparisonValue will return false if the value is 0; this will handle that case as well
  const showComparisonValue = typeof comparisonValue === "number";
  const changePercentage = showComparisonValue
    ? getRelativePercentage(value, comparisonValue)
    : 0;

  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const isChangePositive = changePercentage > 0;

  return (
    <div className={styles.graphAttr}>
      <div className={styles.typeContainer}>
        <span
          style={{ backgroundColor: color }}
          className={classNames(styles.circle)}
        ></span>
        <span className={styles.type}>{label}</span>
      </div>
      <div className={styles.valueContainer}>
        <span className={styles.value}>
          {value
            ? formatNumberByType(value, valueType, {
                showDecimal: true,
                selectedCurrencyCode,
              })
            : "-"}
          {comparisonValue && (
            <Badge
              label={`${Math.abs(changePercentage)} %`}
              status={isChangePositive ? "success" : "danger"}
              leftIconButton={{ icon: isChangePositive ? ArrowUp : ArrowDown }}
            />
          )}
        </span>
      </div>
    </div>
  );
}
