import { getIn, useFormikContext } from "formik";
import {
  ActivityPlatformEnum,
  useIntegrationsStatusQuery,
  useMetaIntegrationDefaultSettingsQuery,
} from "src/graphql/generated/schema";
import { Button } from "src/components/Button/Button";
import { CampaignInput } from "../misc/createCampaignTypes";
import Card from "src/components/Card/Card";
import CollectionItem from "src/components/CollectionItem/CollectionItem";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Label } from "src/components/Label/Label";
import { RadioGroup } from "@headlessui/react";
import styles from "./ChoosePlatformAndActivity.module.scss";
import meta from "../assets/meta.svg";
import emailIntegration from "../assets/email.svg";
import smsMethod from "../assets/message.svg";
import whatsappIntegration from "../assets/whatsapp.svg";
import { IntegrateBanner } from "./IntegrateBanner";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { Spinner } from "src/components/Spinner/Spinner";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import Tooltip from "src/components/Tooltip/Tooltip";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-400/rounded/info.svg";
import {
  FBFormValueKey,
  upsertDefaultSettings,
} from "src/modules/global/components/FacebookSettings";
import { useMemo, useState } from "react";
import Alert from "src/components/Alert/Alert";

interface Props {
  disabled: boolean;
  selectedActivityIndex: number;
  handleBack: (i: CampaignInput) => unknown;
}

export function ChoosePlatformAndActivity({
  disabled,
  handleBack,
  selectedActivityIndex,
}: Props) {
  const {
    values,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    isValid,
  } = useFormikContext<CampaignInput>();
  const { isCurrentNodeCompleted, setResetAlert, setSelectedActivityIndex } =
    useWizardContext();

  const [showActivityDeleteAlert, setShowActivityDeleteAlert] = useState(false);

  const { data: integrationStatusData, loading: integrationStatusLoading } =
    useIntegrationsStatusQuery();
  const activityKey = `Activities[${selectedActivityIndex}]`;
  const platformKey = `Activities[${selectedActivityIndex}].platform`;
  const fieldError = getIn(errors, platformKey);
  const isFieldTouched = getIn(touched, platformKey);
  const fieldValue = getIn(values, platformKey);

  const isPlatformIntegrated =
    integrationStatusData &&
    integrationStatusData.integrationsStatus &&
    integrationStatusData.integrationsStatus[fieldValue?.toLowerCase()] ===
      "ACTIVE"
      ? true
      : false;

  const fbSettingsFieldsMapper: Record<FBFormValueKey, string> = useMemo(
    () => ({
      metaIntegrationId: `${activityKey}.metaIntegrationId`,
      adAccountId: `${activityKey}.adAccountId`,
      adAccountName: `${activityKey}.adAccountName`,
      conversionDomain: `${activityKey}.conversionDomain`,
      platformAccountId: `${activityKey}.platformAccountId`,
      instagramPageId: `${activityKey}.instagramPageId`,
      metaPageId: `${activityKey}.metaPageId`,
      pageId: `${activityKey}.pageId`,
      metaInstagramPageId: `${activityKey}.metaInstagramPageId`,
      metaPixelId: `${activityKey}.metaPixelId`,
      metaPageName: `${activityKey}.metaPageName`,
      metaPagePicture: `${activityKey}.metaPagePicture`,
      pixelId: `${activityKey}.pixelId`,
      metaPixelName: `${activityKey}.metaPixelName`,
      instagramPageName: `${activityKey}.instagramPageName`,
      metaPageConnectedInstagramBusinessAccountId: `${activityKey}.metaPageConnectedInstagramBusinessAccountId`,
    }),
    [activityKey],
  );

  const { data: defaultSettingsData, loading: defaultSettingsLoading } =
    useMetaIntegrationDefaultSettingsQuery({
      // HACK: perform a full fetch
      initialFetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data.metaIntegrationDefaultSettings) {
          upsertDefaultSettings(
            data.metaIntegrationDefaultSettings,
            setFieldValue,
            fbSettingsFieldsMapper,
          );
          setFieldValue(`${activityKey}.useDefaultSettings`, true);
        }
      },
    });

  if (integrationStatusLoading && defaultSettingsLoading) {
    <Card disabled={disabled}>
      <Spinner height="screen" />
    </Card>;
  }

  const defaultSettings =
    defaultSettingsData && defaultSettingsData.metaIntegrationDefaultSettings;

  return (
    <div>
      <Card disabled={disabled}>
        <div className={styles.form}>
          <Label label="Select Platform">
            <RadioGroup
              as="div"
              className={styles.platform}
              name={platformKey}
              value={fieldValue}
              onBlur={() => {
                setFieldTouched(platformKey);
              }}
              onChange={(value) => {
                if (isCurrentNodeCompleted) {
                  setResetAlert({
                    data: values,
                    fieldKey: platformKey,
                    fieldValue: value,
                    isShowing: true,
                  });
                  return;
                }

                setFieldValue(platformKey, value);
              }}
            >
              {platforms.map((platform, index) => (
                <RadioGroup.Option
                  value={platform.value}
                  disabled={platform.disable}
                  title={
                    platform.disable
                      ? "Not in your current plan"
                      : platform.title
                  }
                  style={{ opacity: platform.disable ? "0.5" : "1" }}
                  key={index}
                >
                  <CollectionItem
                    title={platform.title}
                    image={platform.image}
                    value={platform.value}
                    key={index}
                  />
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            {isFieldTouched && fieldError && (
              <FormFieldStatus type="error" message={fieldError} />
            )}
          </Label>
          <div className={styles.secondarySection}>
            <div className={styles.inputSection}>
              <InputFormik
                name={`Activities[${selectedActivityIndex}].activityName`}
                label="Activity Name"
                placeholder="Activity-1"
                type="text"
                width="auto"
              />
              {/* <MetaAdAccountSelector
                selectedAdAccountId={getIn(
                  values,
                  `Activities[${selectedActivityIndex}].adAccountId`
                )}
                setSelectedAdAccountId={function (
                  i: string,
                  platformId?: string,
                  metaName?: string
                ): void {
                  return;
                }}
                formKey={`Activities[${selectedActivityIndex}].adAccountId`}
                disabled={true}
                className={styles.adAccountSelector}
              /> */}
            </div>
            {defaultSettings && (
              <div className={styles.checkboxContainer}>
                <Checkbox
                  value={getIn(values, `${activityKey}.useDefaultSettings`)}
                  checked={getIn(values, `${activityKey}.useDefaultSettings`)}
                  onChange={(checked) => {
                    console.log({ checked });
                    setFieldValue(`${activityKey}.useDefaultSettings`, checked);
                  }}
                />
                <span> Use default Ad account settings </span>
                <Tooltip
                  children={<InfoIcon width={15} height={15} />}
                  supportingText={
                    "By opting this option we will use your default settings to launch campaign"
                  }
                />
              </div>
            )}
            {/* <Button
              style="plain"
              color="primary"
              size="micro"
              type="button"
              onClick={() => {
                // TODO: trigger modal
                setShowFacebookSettingsModal(true);
              }}
            >
              <div className={styles.buttonWithIcon}>
                <span>show advance options</span>
                <ExpandMore height={15} width={15} fill="currentColor" />
              </div>
            </Button> */}
          </div>
          <div className={styles.btnGrp}>
            <Button
              loading={isSubmitting || defaultSettingsLoading}
              width="auto"
              color="primary"
              type="submit"
              disabled={
                defaultSettingsLoading || !isPlatformIntegrated || !isValid
              }
            >
              Save and Continue
            </Button>
            {/* <Button
              color="subdued"
              style="outline"
              onClick={() => {
                if (values.selectedActivityIndex > 0) {
                  setShowActivityDeleteAlert(true);
                  return;
                }
                handleBack(values);
              }}
            >
              Back
            </Button> */}
          </div>
        </div>
        {!integrationStatusLoading && !isPlatformIntegrated ? (
          <IntegrateBanner platform={fieldValue} />
        ) : null}
      </Card>
      <Alert
        isOpen={showActivityDeleteAlert}
        onClose={function (): void {
          setShowActivityDeleteAlert(false);
        }}
        title="Are you sure you want to Leave?"
        primaryAction={{
          children: "Go to review",
          onClick: async () => {
            setSelectedActivityIndex(
              values.selectedActivityIndex - 1,
              "review",
              {
                Activities: values.Activities.slice(
                  0,
                  values.selectedActivityIndex,
                ),
              },
            );
          },
        }}
        secondaryAction={{
          children: "Keep Editing",
          style: "outline",
          color: "normal",
          onClick: () => {
            setShowActivityDeleteAlert(false);
          },
        }}
      >
        <div>
          Hitting "go back" discards unsaved changes. You'll return to the
          review page with your previous edits.
        </div>
      </Alert>
      {/* <Modal
        isOpen={showFacebookSettingsModal}
        maxWidth="65%"
        title="Advance Option"
        subtitle="Manage where you want to show Ads"
        className={styles.facebookSettingsModal}
        onClose={() => {
          setShowFacebookSettingsModal(false);
        }}
      >
        <ChooseFacebookAccountSettingsForm
          handleBack={() => {
            setShowFacebookSettingsModal(false);
          }}
          disabled={false}
          fieldsMapper={fbSettingsFieldsMapper}
          handleSubmit={() => setShowFacebookSettingsModal(false)}
        />
      </Modal> */}
    </div>
  );
}

interface PlatformOption {
  title: string;
  image: string;
  value: ActivityPlatformEnum;
  disable: boolean;
}

const platforms: PlatformOption[] = [
  {
    title: "Meta",
    image: meta,
    value: "META",
    disable: false,
  },
  {
    title: "Email",
    image: emailIntegration,
    value: "EMAIL",
    // TODO: remove
    disable: true,
  },
  {
    title: "Text Message",
    image: smsMethod,
    value: "TEXT_MESSAGE",
    disable: true,
  },
  {
    title: "WhatsApp",
    image: whatsappIntegration,
    value: "WHATSAPP",
    disable: true,
  },
  // {
  //   title: "Google",
  //   image: googlePlatform,
  //   value: "GOOGLE",
  //   disable: true,
  // },
];
