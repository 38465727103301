import classNames from "classnames";
import styles from "./SectionTabs.module.scss";
import { ReactNode } from "react";

interface ISectionTabs {
  selectedTab: string;
  setSelectedTab: (i: string) => void;
  tabs: {
    icon?: ReactNode;
    title: string;
    id: string;
    subtitle: string;
  }[];
}

export function SectionTabs({
  selectedTab,
  setSelectedTab,
  tabs,
}: ISectionTabs) {
  return (
    <ul className={styles.tabs}>
      {tabs.map((tab, index) => {
        const isNextTabActive = tabs[index + 1]?.id === selectedTab;
        const isCurrentTabActive = tabs[index].id === selectedTab;
        const shouldRenderNoLeftBorder = index === 0;
        const shouldRenderNoRightBorder = index === tabs.length - 1;

        const shouldRenderFullSizeLeftBorder =
          isCurrentTabActive && !shouldRenderNoLeftBorder;
        const shouldRenderFullSizeRightBorder =
          isCurrentTabActive && !shouldRenderNoRightBorder;

        return (
          <li
            className={classNames(styles.tab, {
              [styles.active]: selectedTab === tab.id,
              // render smaller border only if current tab is not a active tab
              // or there is no active tab after or is not the last tab
              [styles.smallRightBorder]:
                !(isNextTabActive || isCurrentTabActive) &&
                !shouldRenderNoRightBorder,
              [styles.fullSizeLeftBorder]: shouldRenderFullSizeLeftBorder,
              [styles.fullSizeRightBorder]: shouldRenderFullSizeRightBorder,
            })}
            onClick={() => setSelectedTab(tab.id)}
          >
            <span>{tab.icon}</span>
            <div>
              <h2 className={styles.tabTitle}>{tab.title}</h2>
              <p className={styles.tabDescription}>{tab.subtitle}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
