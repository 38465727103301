import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components/Button/Button";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { Spinner } from "../../../components/Spinner/Spinner";
import {
  RoleEnum,
  SortByInput,
  useCanCreateNewClientQuery,
  useGetUserQuery,
  useOrganizationClientsQuery,
} from "../../../graphql/generated/schema";
import { getTinybirdDateFiltersFromDateFilter } from "../../campaign/misc/campaignSingleFilters";
import { SectionHeading } from "../../global/components/SectionHeading";
import { useDebouncedVariable } from "../../global/hooks/useDebouncedVariable";
import { DateFilter } from "../../global/misc/dateFilterUtils";
import { ClientsTable } from "../components/ClientsTable";
import { ClientsTableHeader } from "../components/ClientsTableHeader";
import { CreateClientModal } from "../components/CreateClientModal";
import { EditClientModal } from "../components/EditClientModal";
import styles from "./Clients.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

export function Clients() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const createClient = searchParams.get("createClient");
  const { data: canCreateNewClientData } = useCanCreateNewClientQuery();
  const [showCreateClientModal, setShowCreateClientModal] = useState(
    (createClient && createClient === "true") || false,
  );

  const [selectedId, setSelectedId] = useState(null);
  const [sortBy, setSortBy] = useState<SortByInput>({
    direction: "DESC",
    field: "CREATED_AT",
  });
  const { data: userData, loading: userLoading } = useGetUserQuery();

  const [filter, setFilter] = useState<{
    name?: string;
    createdAt: DateFilter;
  }>({
    createdAt: "ALL_TIME",
  });

  const { dateTo, dateFrom } = useMemo(() => {
    return getTinybirdDateFiltersFromDateFilter(filter.createdAt);
  }, [filter.createdAt]);
  const debouncedName = useDebouncedVariable(filter?.name, 300);
  const { data, loading } = useOrganizationClientsQuery({
    variables: {
      sortBy,
      filter: {
        ...(debouncedName && {
          name: debouncedName,
        }),
        ...(filter?.createdAt &&
          dateTo &&
          dateFrom && {
            createdAt: {
              lte: dateTo,
              gte: dateFrom,
            },
          }),
      },
    },
  });

  if (userLoading) {
    return <Spinner />;
  }

  const userRole: RoleEnum =
    userData && userData.me ? userData.me.role : "MEMBER";

  if (userRole === "MEMBER") {
    return (
      <div className={styles.emptyStateContainer}>
        <EmptyState title={"Members cannot access this page"}>
          Only organization creator can access this page
        </EmptyState>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <SectionHeading
          heading={"Clients"}
          description={"Manage your client information and settings"}
        />
        {canCreateNewClientData?.canCreateNewClient?.response && (
          <ButtonV2
            onClick={() => {
              setShowCreateClientModal(true);
            }}
          >
            Add Client
          </ButtonV2>
        )}
      </div>
      <div className={styles.container}>
        <ClientsTableHeader
          filter={filter}
          setFilter={setFilter}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <ClientsTable
          clients={data ? data.organizationClients : []}
          loading={loading}
          setSelectedId={setSelectedId}
        />
        <CreateClientModal
          isOpen={showCreateClientModal}
          onClose={() => setShowCreateClientModal(false)}
        />
        {Boolean(selectedId) && (
          <EditClientModal
            isOpen={Boolean(selectedId)}
            onClose={() => setSelectedId(null)}
            id={selectedId}
          />
        )}
      </div>
    </div>
  );
}
