import isEqual from "lodash.isequal";
import { LineItems } from "src/lib/types";
import { titleCase } from "./titleCase";

interface Asset {
  fileSize: number;
  height: number;
  width: number;
  contentType: string;
}

interface Result {
  message: string;
  lineItemIndex: number;
  assetType: "StoryAsset" | "PostAsset";
}

export function checkForDuplicatedAssetInLineItems(
  lineItems: LineItems[]
): Result | null {
  for (let [index, item] of lineItems.entries()) {
    if (item.StoryAsset) {
      return compareAssetInBoth({
        asset: item.StoryAsset,
        assetType: "STORY",
        index,
        lineItems,
      });
    }

    if (item.PostAsset) {
      return compareAssetInBoth({
        asset: item.PostAsset,
        assetType: "POST",
        index,
        lineItems,
      });
    }
  }
  return null;
}

function compareAssetInBoth({
  asset,
  assetType,
  index,
  lineItems,
}: {
  asset: any;
  assetType: "STORY" | "POST";
  index: number;
  lineItems: LineItems[];
}): null | Result {
  let transformedAsset = transformAsset(asset);
  for (let [itemIndex, item] of lineItems.entries()) {
    const storyAsset =
      assetType === "STORY" && itemIndex === index
        ? null
        : transformAsset(item.StoryAsset);
    const postAsset =
      assetType === "POST" && itemIndex === index
        ? null
        : transformAsset(item.PostAsset);

    if (postAsset !== null && isEqual(transformedAsset, postAsset)) {
      return {
        message: `${titleCase(assetType)} Placement of slide ${
          index + 1
        } is duplicated with Post of slide ${itemIndex + 1}`,
        lineItemIndex: index,
        assetType: "PostAsset",
      };
    }

    if (storyAsset !== null && isEqual(transformedAsset, storyAsset)) {
      return {
        message: `${titleCase(assetType)} of slide ${
          index + 1
        } is duplicated with Story of slide ${itemIndex + 1}`,
        lineItemIndex: index,
        assetType: "StoryAsset",
      };
    }
  }
}

/**
 * @description returns formatted asset to compare
 */
function transformAsset(asset: any): Asset {
  if (!asset) {
    return null;
  }
  return {
    ...(asset.PromotedObject !== null && {
      name: asset.name,
    }),
    fileSize: asset.fileSize,
    height: asset.height,
    width: asset.width,
    contentType: asset.contentType,
  };
}
