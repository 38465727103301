import { Button } from "src/components/Button/Button";
import styles from "./NotFound.module.scss";
import error from "../assets/notFound.png";

export function NotFound() {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img src={error} alt="404" className={styles.image} />
        <p className={styles.title}>
          Whoops! This is not what you were looking for
        </p>
        <p className={styles.subtitle}>
          {/* But you just found the sock we'd lost, thanks. Try your luck by going
          back to the home page. */}
          Either this page not exist or you've enter wrong page address. Please
          check agian
        </p>
        <Button to="/" color="subdued" style="outline" className={styles.btn}>
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
}
