import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "src/components/Button/Button";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { CampaignFilterEnum } from "src/graphql/generated/schema";
import { campaignFilters, campaignSortBy } from "../misc/campaignFilter";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "src/modules/global/misc/dateFilterUtils";
import styles from "./CampaignListingPage.module.scss";
import { CampaignListingCampaignGrouped } from "../components/CampaignListingCampaignGrouped";
import { CampaignListingStatusGrouped } from "../components/CampaignListingStatusGrouped";
import { ToggleButton } from "src/modules/global/components/ToggleButton";
import { ReactComponent as DataTableIcon } from "@material-symbols/svg-400/rounded/data_table.svg";
import { ReactComponent as CalendarDayViewIcon } from "@material-symbols/svg-400/rounded/calendar_view_day.svg";
import { ReactComponent as Search } from "@material-symbols/svg-400/rounded/search.svg";
import { Input } from "../../../components/Input/Input";
import SortByDropdown from "../../global/components/SortByDropdown";
import sortOptions from "../misc/campaignSortBy";
import DateFilterDropdown from "../../global/components/DateFilterDropdown";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ReactComponent as PersonAdd } from "@material-symbols/svg-600/rounded/person_add.svg";
import { Searchbar } from "src/components/Searchbar/Searchbar";

export function CampaignListingPage() {
  const navigate = useNavigate();
  const [groupBy, setGroupBy] = useState<"campaign" | "status">("campaign");
  const [query, setQuery] = useState("");
  const [searchParams, _setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<campaignFilters>({
    platform: "ALL",
    filterStatus: (searchParams.get("tab") as CampaignFilterEnum) || "ALL",
    dateFilter: "ALL_TIME",
  });
  const [sortBy, setSortBy] = useState<campaignSortBy>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  const createdAtFilters = useMemo(
    () => getDateFilterFromFilterEnum(filters.dateFilter),
    [filters.dateFilter],
  );

  function toggleView() {
    if (groupBy === "campaign") {
      setGroupBy("status");
    } else {
      setGroupBy("campaign");
    }
  }

  return (
    <div className={styles.page}>
      <PageHeader
        title="Campaigns"
        actions={
          <>
            <Searchbar
              value={query}
              onChange={(i) => setQuery(i)}
              input={{ placeholder: "Search campaign & activity" }}
            />
            <SortByDropdown
              sortOptions={sortOptions}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
            <DateFilterDropdown
              setDateFilter={(i: DateFilter) => {
                setFilters({
                  ...filters,
                  dateFilter: i,
                });
              }}
              dateFilter={filters.dateFilter}
            />
            <ToggleButton
              value={groupBy}
              onChange={toggleView}
              options={[
                {
                  value: "campaign",
                  icon: (
                    <CalendarDayViewIcon
                      width="16"
                      height="16"
                      fill="currentColor"
                    />
                  ),
                },
                {
                  value: "status",
                  icon: (
                    <DataTableIcon width="16" height="16" fill="currentColor" />
                  ),
                },
              ]}
            />
            <ButtonV2
              size="small"
              leftIcon={PersonAdd}
              onClick={() => {
                navigate("/campaigns/new");
              }}
            >
              New Campaign
            </ButtonV2>
          </>
        }
      />

      <section className={styles.filterSection}>
        <div className={styles.searchInput}></div>
      </section>

      {groupBy === "campaign" ? (
        <CampaignListingCampaignGrouped
          filters={{
            ...filters,
            ...(createdAtFilters && {
              createdAt: createdAtFilters,
            }),
            query: query,
          }}
          sortBy={sortBy}
        />
      ) : (
        <CampaignListingStatusGrouped
          filters={{
            ...filters,
            ...(createdAtFilters && {
              createdAt: createdAtFilters,
            }),
            query: query,
          }}
          sortBy={sortBy}
        />
      )}
    </div>
  );
}
