export function removeDuplicatesByFieldName<T>(
  arr: Array<T>,
  fieldName: string
) {
  // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
  return arr.filter(
    (item, index) =>
      arr.findIndex((item2) => item2[fieldName] === item[fieldName]) === index
  );
}
