import { useAudienceAudienceSetIndexesQuery } from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { CampaignInput } from "../misc/createCampaignTypes";
import { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import { SelectFacebookAudiencesField } from "./SelectFacebookAudiencesField";
import { PaginationInput } from "src/modules/global/misc/PaginationInput";

interface ISelectFacebookAudiencesLoader {
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => unknown;
}

export function SelectFacebookAudiencesLoader({
  campaignInput,
  handleBack,
}: ISelectFacebookAudiencesLoader) {
  const [audienceNameQuery, setAudienceNameQuery] = useState("");
  const [pagination, setPagination] = useState<PaginationInput>({
    take: 10,
    cursor: null,
  });
  const {
    data,
    loading,
    error,
    refetch: refetchAudienceFunc,
  } = useAudienceAudienceSetIndexesQuery({
    variables: {
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
      filter: {
        take: pagination.take,
        cursor: pagination.cursor,
        // name: audienceNameQuery,
        // FIXME: fix this for google
        platform: "META",
        adAccountId:
          campaignInput.Activities[campaignInput.selectedActivityIndex]
            .adAccountId,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const adAccountId = useMemo(
    () =>
      campaignInput.Activities[campaignInput.selectedActivityIndex]
        ?.adAccountId,
    [campaignInput]
  );

  const filterAudiencesDebouncedCallback = useDebouncedCallback(
    async (name: string, pagination: PaginationInput) => {
      await refetchAudienceFunc({
        sortBy: {
          direction: "DESC",
          field: "CREATED_AT",
        },
        filter: {
          cursor: pagination.cursor,
          take: pagination.take,
          name: name,
          platform: "META",
          adAccountId,
        },
      });
    },
    300,
    [refetchAudienceFunc]
  );

  useEffect(() => {
    filterAudiencesDebouncedCallback(audienceNameQuery, pagination);
  }, [audienceNameQuery, pagination, filterAudiencesDebouncedCallback]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <SelectFacebookAudiencesField
      audienceNameQuery={audienceNameQuery}
      setAudienceNameQuery={setAudienceNameQuery}
      campaignInput={campaignInput}
      handleBack={handleBack}
      audienceAudienceSetIndexEdges={
        data?.audienceAudienceSetIndexes?.edges || []
      }
      pagination={pagination}
      setPagination={setPagination}
      pageInfo={data?.audienceAudienceSetIndexes.pageInfo}
      loading={loading}
    />
  );
}
