import { Fragment, useState } from "react";

import { AutomationSidebarFooter } from "../AutomationSidebarFooter";
import { AutomationSidebarHeader } from "../AutomationSidebarHeader";
import { Button } from "src/components/Button/Button";
import { ConfigureCondition } from "./Conditon/ConfigureCondition";
import styles from "./AutomationConditionSidebar.module.scss";

export const initialConditionState = {
  major: "",
  first: "",
  second: "at least once",
  third: "over all time",
};

export function AutomationConditionSidebar() {
  const [conditions, setConditions] = useState([[initialConditionState]]);

  return (
    <div>
      <AutomationSidebarHeader title="Configure If/Else" onBack={() => {}} />
      <p className={styles.description}>
        Your contact will join the 'Yes' path if they meet your conditions.
      </p>
      <div className={styles.wrapper}>
        {conditions.map((condition, cindex) => (
          <Fragment key={cindex}>
            <ConfigureCondition
              condition={condition}
              cindex={cindex}
              conditions={conditions}
              setConditions={setConditions}
            />
            <div className={styles.andBtn}>
              {conditions.length > 1 && cindex !== conditions.length - 1 && (
                <hr className={styles.line} />
              )}
              <Button
                style="outline"
                color="subdued"
                size="small"
                onClick={() =>
                  setConditions([...conditions, [initialConditionState]])
                }
                className={styles.and}
              >
                AND +
              </Button>
            </div>
          </Fragment>
        ))}
      </div>
      <AutomationSidebarFooter>
        <div className={styles.footerGroup}>
          <Button style="outline" color="subdued">
            Cancel
          </Button>
          <Button>Save</Button>
        </div>
      </AutomationSidebarFooter>
    </div>
  );
}
