import classNames from "classnames";
import styles from "./RangeSlider.module.scss";
import { useEffect, useRef } from "react";

export interface IRangeSlider {
  id: string;
  value: number | [number, number];
  name: string;
  min: number;
  max: number;
  step?: number;
  disabled?: boolean;
  onChange: (i: any) => void;
  onBlur?: () => void;
  showSteps?: boolean;
}

export default function RangeSlider({
  id,
  value,
  min,
  max,
  step,
  disabled,
  name,
  onChange,
  onBlur,
  showSteps = false,
}: IRangeSlider) {
  const rangeRef = useRef<HTMLInputElement | null>(null);

  function handleRange(e) {
    const tempSliderValue = Number(e.target.value);
    if (rangeRef) {
      const progress = (tempSliderValue / Number(e.target.max)) * 100;
      rangeRef.current.style.background = `linear-gradient(to right, #312CCB ${progress}%, #edeeef ${progress}%)`;
    }
  }

  useEffect(() => {
    if (rangeRef && Number(rangeRef.current.value) > 0) {
      const progress =
        (Number(rangeRef.current.value) / Number(rangeRef.current.max)) * 100;
      rangeRef.current.style.background = `linear-gradient(to right, #312CCB ${progress}%, #edeeef ${progress}%)`;
    }
  }, []);

  return (
    <div className={styles.range}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        className={styles.rangeSlider}
        onChange={(e) => {
          handleRange(e);
          onChange(e);
        }}
        onBlur={onBlur}
        ref={rangeRef}
        disabled={disabled}
        value={value as number}
        id={id}
        name={name}
      />
      {showSteps && (
        <datalist id={id} className={styles.datalist}>
          {Array(11)
            .fill("")
            .map((_, index) => (
              <option value={`${index}`} label={`${index}%`}>
                {index}
              </option>
            ))}
        </datalist>
      )}
    </div>
  );
}
