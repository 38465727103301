import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BillingPlanCycleEnum,
  CustomerCountRange,
  MonthlyAdSpendRange,
  MonthlyCreditRange,
  useGetBillingQuery,
} from "src/graphql/generated/schema";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { ContactSales } from "../components/ContactSales";
import { Faq } from "../components/Faq";
import { planFeatures } from "../components/Features";
import { FeaturesTable } from "../components/FeaturesTable";
import { SettingsPricingHeader } from "../components/SettingsPricingHeader";
import { Plans } from "../components/Plans";
import styles from "./SettingsPricing.module.scss";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function SettingsPricing() {
  const { data, loading, error } = useGetBillingQuery();
  const [selectedTab, setSelectedTab] = useState<"ENGAGE" | "GROW">("GROW");
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingPlanCycleEnum>("MONTHLY");
  const [selectedCustomerCount, setSelectedCustomerCount] =
    useState<CustomerCountRange>("CUSTOMER_COUNT_1_TO_2500");
  const [selectedAdSpend, setSelectedAdSpend] = useState<MonthlyAdSpendRange>(
    "AD_SPENT_1_TO_10000"
  );
  const [isEnterprisePlan, setIsEnterprisePlan] = useState(false);
  const [selectedCredits, setSelectedCredits] =
    useState<MonthlyCreditRange>("CREDIT_1_TO_500");
  const navigate = useNavigate();

  const features = planFeatures({
    selectedAdSpend,
    selectedCustomerCount,
    selectedCredits,
    setSelectedCredits,
    setIsEnterprisePlan,
  });

  const featuresTable = {
    GROW: {
      headings: ["Features", "Starter Plan", "Advance Plan"],
      rows: features.growFeatures,
    },
    ENGAGE: {
      headings: ["Features", "Starter Plan", "Advance Plan"],
      rows: features.engageFeatures,
    },
    Enterprise: {
      headings: ["Features", "Enterprise Plan"],
      rows: features.enterpriseFeatures,
    },
  };

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const billingProduct =
    selectedTab === "GROW"
      ? data.billing?.BillingGrowPlan
      : data.billing?.BillingEngagePlan;

  const currentlyActiveTier = billingProduct?.type;

  return (
    <div>
      <PageHeader title="Pricing & Plan" back="/settings/billing" />
      <div className={styles.page}>
        <SettingsPricingHeader
          selectedProduct={selectedTab}
          setSelectedProduct={setSelectedTab}
          setIsEnterprisePlan={setIsEnterprisePlan}
        />

        <Plans
          isUpgrade={data.billing?.status === "ACTIVE"}
          selectedCredits={selectedCredits}
          setSelectedCredits={setSelectedCredits}
          currentlyActiveTier={currentlyActiveTier}
          selectedBillingCycle={selectedBillingCycle}
          setSelectedBillingCycle={setSelectedBillingCycle}
          selectedAdSpend={selectedAdSpend}
          setSelectedAdSpend={setSelectedAdSpend}
          selectedCustomerCount={selectedCustomerCount}
          setSelectedCustomerCount={setSelectedCustomerCount}
          selectedTab={selectedTab}
          isEnterprisePlan={isEnterprisePlan}
          setIsEnterprisePlan={setIsEnterprisePlan}
          handlePaymentIntent={
            ({ paymentIntent, billingId }) =>
              (window.location.href = paymentIntent)
            // navigate({
            //   pathname: "/billing/checkout",
            //   search: `paymentIntent=${paymentIntent}&billingId=${billingId}`,
            // })
          }
        />

        <FeaturesTable
          rows={
            isEnterprisePlan
              ? featuresTable.Enterprise.rows
              : featuresTable[selectedTab].rows
          }
          headings={
            isEnterprisePlan
              ? featuresTable.Enterprise.headings
              : featuresTable[selectedTab].headings
          }
        />

        <ContactSales />
        <Faq />
      </div>
    </div>
  );
}
