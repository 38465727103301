import { CampaignInput } from "../misc/createCampaignTypes";
import * as Yup from "yup";
import { CampaignCreateForm } from "./CampaignCreateForm";
import { ChooseMetaEngagementConversionLocation } from "./ChooseMetaEngagementConversionLocation";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { MetaEngagementDestinationEnum } from "src/graphql/generated/schema";

interface IChooseFacebookEngagementTypeForm {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  presetId?: string;
  handleBack: (i: CampaignInput) => unknown;
}

export function ChooseMetaEngagementConversionLocationForm({
  campaignInput,
  handleBack,
  campaignPresetUpsert,
}: IChooseFacebookEngagementTypeForm) {
  const { selectedActivityIndex } = campaignInput;
  const { currentNode } = useWizardContext();

  return (
    <CampaignCreateForm
      schema={chooseFacebookActivityTypeSchema}
      campaignInput={campaignInput}
      campaignPresetUpsert={campaignPresetUpsert}
    >
      <ChooseMetaEngagementConversionLocation
        handleBack={handleBack}
        selectedActivityIndex={selectedActivityIndex}
        disabled={currentNode.id !== "chooseFacebookEngagementType"}
      />
    </CampaignCreateForm>
  );
}

const chooseFacebookActivityTypeSchema = Yup.object({
  engagementConversionLocation: Yup.string().required(
    "Conversion location is required"
  ),
  engagementDestinations: Yup.array().when(
    "engagementConversionLocation",
    (val) => {
      if (val === "MESSAGE") {
        return Yup.array(
          Yup.mixed<MetaEngagementDestinationEnum>().oneOf([
            "INSTAGRAM_DIRECT",
            "WHATSAPP",
          ])
        )
          .min(1)
          .required("Engagement destination is required");
      }

      return Yup.array(Yup.string()).nullable();
    }
  ),
});
