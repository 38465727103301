import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./FormFieldStatus.module.scss";

export interface IFormFieldStatus {
  type: "info" | "error" | "warning";
  message: string | ReactNode;
}

export function FormFieldStatus({ message, type }: IFormFieldStatus) {
  return (
    <span
      className={classNames(styles.statusText, {
        [styles.statusTextError]: type === "error",
        [styles.statusTextWarning]: type === "warning",
      })}
    >
      {message}
    </span>
  );
}
