import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";
import { createCampaignWizardTree } from "../misc/createCampaignWizardTree";
import { Wizard } from "src/modules/global/components/Wizard2";
import { useGetCampaignPreset } from "../functions/useGetCampaignPreset";

export function CreateCampaignPage() {
  const { data, loading, error } = useGetCampaignPreset();

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading) {
    return <Spinner height="screen" />;
  }

  const campaignTree = createCampaignWizardTree();

  return <Wizard tree={campaignTree} data={data} />;
}
