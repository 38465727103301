import {
  MetaActivityAdTemplate,
  Pagination as IPagination,
} from "../../../../graphql/generated/schema";
import { PaginationInput } from "../../../global/misc/PaginationInput";
import styles from "./AdTemplatesListingTable.module.scss";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { itemsPerPage } from "../../misc/itemsPerPage";
import { Asset } from "../../../../components/Smartphone/Asset";
import { getThumbnailAssetFromAd } from "../../../campaign/functions/getThumbnailAssetFromAd";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { ReactComponent as ActivityCount } from "../../assets/activityCount.svg";
import { Spinner } from "../../../../components/Spinner/Spinner";
import classNames from "classnames";
import { titleCase } from "../../../../lib/titleCase";
import { NoFilteredResultsFound } from "../NoFilteredResultsFound";
import { ReactComponent as MoreVert } from "@material-symbols/svg-600/rounded/more_vert.svg";
import Dropdown from "../../../../components/Dropdown/Dropdown";

interface IAdTemplatesListingTable {
  adTemplates: MetaActivityAdTemplate[];
  pageInfo: IPagination;
  loading: boolean;
  setPagination: (i: PaginationInput) => void;
  setSelectedTemplateId: (i: string) => void;
  duplicateAdTemplate: (i: string) => void;
  editAdTemplate: (i: string) => void;
  deleteAdTemplate: (i: string) => void;
}

export function AdTemplatesListingTable({
  adTemplates,
  pageInfo,
  loading,
  setPagination,
  setSelectedTemplateId,
  duplicateAdTemplate,
  editAdTemplate,
  deleteAdTemplate,
}: IAdTemplatesListingTable) {
  if (loading) {
    return <Spinner />;
  }

  if (!adTemplates.length) {
    return <NoFilteredResultsFound />;
  }

  return (
    <div>
      <div>
        {adTemplates.map((v) => (
          <AdTemplateItem
            duplicateAdTemplate={() => duplicateAdTemplate(v.id)}
            deleteAdTemplate={() => deleteAdTemplate(v.id)}
            editAdTemplate={() => editAdTemplate(v.id)}
            adTemplate={v}
            onClick={() => setSelectedTemplateId(v.id)}
          />
        ))}
      </div>
      {(pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          hasPrevious={pageInfo?.hasPreviousPage}
          hasNext={pageInfo?.hasNextPage}
          borderLess
          onPrevious={function (): void {
            setPagination({
              take: -itemsPerPage,
              cursor: pageInfo?.startCursor,
            });
          }}
          onNext={function (): void {
            setPagination({
              take: itemsPerPage,
              cursor: pageInfo?.endCursor,
            });
          }}
        />
      )}
    </div>
  );
}

interface IAdTemplateItem {
  adTemplate: MetaActivityAdTemplate;
  onClick: () => void;
  deleteAdTemplate: () => void;
  duplicateAdTemplate: () => void;
  editAdTemplate: () => void;
}

function AdTemplateItem({
  adTemplate,
  onClick,
  deleteAdTemplate,
  duplicateAdTemplate,
  editAdTemplate,
}: IAdTemplateItem) {
  const thumbnail = getThumbnailAssetFromAd(adTemplate);
  const count = adTemplate.adsCount;

  return (
    <button onClick={onClick} className={styles.itemWrapper}>
      <div className={styles.primarySection}>
        <div className={styles.title}>
          <Asset
            preview={thumbnail.uri}
            type={thumbnail.contentType}
            name={thumbnail.contentType}
            className={styles.asset}
          />
          <div className={styles.assetTitle}>{adTemplate.name}</div>
        </div>
        <div>
          <div className={styles.countContainer}>
            {/* Activity Count */}
            <Tooltip
              placement={"BOTTOM_CENTER"}
              supportingText="Shows how many Activities are using this assets"
            >
              <div className={styles.countItemStat}>
                <ActivityCount className={styles.icon} />
                <span className={styles.countValue}>
                  {count > 9 ? count : `0${count}`}
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.badge, {
          [styles.inactive]: count === 0,
          [styles.active]: count > 0,
        })}
      >
        {count > 0 ? "In Use" : "Not In Use"}
      </div>

      <div className={styles.adType}>{titleCase(adTemplate.adType, "_")}</div>

      <Dropdown
        title={<MoreVert width={16} height={16} />}
        items={[
          {
            children: "Edit Ad Template",
            disabled: adTemplate.adsCount > 0,
            onClick: () => {
              editAdTemplate();
            },
          },
          {
            children: "Duplicate Ad Template",
            onClick: () => {
              duplicateAdTemplate();
            },
          },
          {
            children: "Delete this media",
            onClick: () => {
              deleteAdTemplate();
            },
          },
        ]}
      />
    </button>
  );
}
