import { SelectableFilter } from "src/modules/global/components/SelectableFilter";
import styles from "./AudienceSelectFilters.module.scss";
import Select from "src/components/Select/Select";
import {
  AudiencePresetFilters,
  AudienceSelectTabOption,
  InterestCategory,
  InterestFilters,
} from "../misc/audienceWorkshop";
import SelectV2 from "src/components/SelectV2/SelectV2";

interface IAudienceSelectPresetFilters {
  selectedTab: AudienceSelectTabOption;
  interestsFilters: InterestFilters;
  setInterestsFilters: (i: InterestFilters) => void;
  audiencePresetFilters: AudiencePresetFilters;
  setAudiencePresetFilters: (i: AudiencePresetFilters) => void;
}

const funnelSteps = [
  {
    label: "Prospecting",
    value: "AudienceCategory_1" as const,
  },
  {
    label: "Retention",
    value: "AudienceCategory_3" as const,
  },
  {
    label: "Win Back",
    value: "AudienceCategory_4" as const,
  },
  {
    label: "Retargeting",
    value: "AudienceCategory_2" as const,
  },
];

const categories = [
  {
    label: "Interests",
    value: "INTERESTS" as const,
  },
  {
    label: "Behaviours",
    value: "BEHAVIOURS" as const,
  },
  {
    label: "Demographics",
    value: "DEMOGRAPHICS" as const,
  },
];

export function AudienceSelectFilters({
  audiencePresetFilters,
  interestsFilters,
  selectedTab,
  setAudiencePresetFilters,
  setInterestsFilters,
}: IAudienceSelectPresetFilters) {
  const isAudiencePresetTabActive = selectedTab === "AUDIENCE_PRESET";

  function handleSelectFunnelType(value: string) {
    const isAlreadySelected = audiencePresetFilters.funnelSteps.includes(value);

    if (isAlreadySelected) {
      setAudiencePresetFilters({
        ...audiencePresetFilters,
        funnelSteps: audiencePresetFilters.funnelSteps.filter(
          (v) => v !== value,
        ),
      });
    } else {
      setAudiencePresetFilters({
        ...audiencePresetFilters,
        funnelSteps: [...audiencePresetFilters.funnelSteps, value],
      });
    }
  }

  function handleSelectCategory(value: InterestCategory) {
    const isAlreadySelected = interestsFilters.categories.includes(value);

    if (isAlreadySelected) {
      setInterestsFilters({
        ...interestsFilters,
        categories: interestsFilters.categories.filter((v) => v !== value),
      });
    } else {
      setInterestsFilters({
        ...interestsFilters,
        categories: [...interestsFilters.categories, value],
      });
    }
  }

  return (
    <div className={styles.filters}>
      <h3 className={styles.filterHeading}>Filters</h3>

      {isAudiencePresetTabActive ? (
        <div className={styles.funnelSection}>
          <h5 className={styles.funnelTypesHeading}>Select Funnel type</h5>
          <div className={styles.funnelTypes}>
            {funnelSteps.map((funnelStep) => (
              <SelectableFilter
                value={funnelStep.value}
                label={funnelStep.label}
                onChange={handleSelectFunnelType}
                checked={audiencePresetFilters.funnelSteps.includes(
                  funnelStep.value,
                )}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.funnelSection}>
          <h5 className={styles.funnelTypesHeading}>Select Category</h5>
          <div className={styles.funnelTypes}>
            {categories.map((category) => (
              <SelectableFilter
                value={category.value}
                label={category.label}
                onChange={handleSelectCategory}
                checked={interestsFilters.categories.includes(category.value)}
              />
            ))}
          </div>
        </div>
      )}
      {selectedTab === "AUDIENCE_PRESET" && (
        <div className={styles.selectCategory}>
          <SelectV2
            isFullWidth
            options={[
              {
                label: "All Sources",
                value: "ALL",
              },
              { label: "Instagram", value: "INSTAGRAM" },
              { label: "Facebook", value: "FACEBOOK" },
              { label: "Website", value: "WEBSITE" },
            ]}
            value={audiencePresetFilters.source}
            onChange={(value) => {
              setAudiencePresetFilters({
                ...audiencePresetFilters,
                source: value,
              });
            }}
            label="Select Source"
          />
        </div>
      )}

      {/* <div className={styles.selectCategory}>
        <Select
          size="small"
          options={[]}
          placeholder="Select"
          value={""}
          onChange={() => {}}
          label="Select Industry"
        />
      </div> */}
    </div>
  );
}
