import {
  MetaGeolocationSearchQuery,
  useMetaGeolocationSearchLazyQuery,
} from "src/graphql/generated/schema";
import ComboBox from "src/components/ComboBox/ComboBox";
import { Label } from "src/components/Label/Label";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import { LocationInput } from "../misc/audienceSetCreateType";
import { ReactComponent as SearchIcon } from "@material-design-icons/svg/outlined/search.svg";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";

export function AudienceLocationInput({
  locations,
  setLocations,
}: {
  locations: LocationInput[];
  setLocations: (value: LocationInput[]) => void;
}) {
  const [metaGeolocationSearch, { called, data, loading }] =
    useMetaGeolocationSearchLazyQuery();

  const locationOptions = transformLocationOptions(data);

  return (
    <>
      <Label label="Search Locations">
        <ComboBoxV2
          isFullWidth
          multiple={true}
          icon={SearchIcon}
          renderSelectedValue={(value) =>
            locations.find((l) => l.id === value).name
          }
          value={locations.map((a) => a.id)}
          loadOptions={async (query) => {
            const payload = await metaGeolocationSearch({
              variables: {
                query,
              },
            });

            const locationOptions = transformLocationOptions(payload.data);

            return locationOptions;
          }}
          onChange={(values: string[]) => {
            const locationValues = values.map((value) => {
              let option = locationOptions.find((item) => item.value === value);

              if (option) {
                return {
                  id: option.value,
                  name: option.label,
                  type: option.type,
                  countryName: option.countryName,
                  countryCode: option.countryCode,
                  regionId: option.regionId,
                  region: option.region,
                };
              } else {
                // if the option is not available in current search result it can be found in the existing location list
                return locations.find((location) => location.id === value);
              }
            });

            setLocations(locationValues);
          }}
          placeholder="Search for region or countries for precise targeting"
        />
      </Label>
    </>
  );
}

function transformLocationOptions(data: MetaGeolocationSearchQuery) {
  if (!data) return [];
  if (!data.metaGeolocationSearch) return [];

  return (
    data.metaGeolocationSearch
      .filter((a) => a.type === "country" || a.type === "region")
      .map((node) => ({
        label: node.name,
        value: node.id,
        type: node.type,
        countryName: node.countryName,
        countryCode: node.countryCode,
        regionId: node.regionId,
        region: node.region,
      })) ?? []
  );
}
