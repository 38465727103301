import { ApolloError, ServerError } from "@apollo/client";
import { useContext } from "react";
import { SomethingWentWrong } from "src/modules/global/components/SomethingWentWrong";
import { hasProperty } from "src/modules/global/functions/hasProperty";
import AuthContext from "src/stores/AuthContext";

export function ErrorHandler({
  error,
}: {
  error: unknown | ApolloError | Error;
}) {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (error.message.includes("Subscription is not active")) {
  //     navigate(`/pricing?message=${encodeURIComponent(error.message)}`);
  //   }
  //   if (error.message === "No Client Email") {
  //     navigate("/organizations/new");
  //   }
  // }, [error]);

  if (!error) return null;

  return (
    <div>
      <ErrorMessage error={error} />
    </div>
  );
}

interface ErrorWithMessage {
  message: string;
}

interface ErrorWithCode {
  code: string;
}

interface ResultWithErrors {
  errors: any[];
}

function ErrorMessage({ error }: { error: unknown | ApolloError | Error }) {
  let errorCode = null;
  const { logout } = useContext(AuthContext);

  if (hasProperty<ErrorWithCode>(error, "code")) {
    errorCode = error.code;
  }

  const isProduction = process.env.REACT_APP_ENV === "production";

  if (errorCode === "NOT_FOUND") {
    return <p>404 Not Found</p>;
  }

  if (isProduction) {
    // return <p> Something went wrong</p>;
    return <SomethingWentWrong />;
  }

  if (hasProperty<ApolloError>(error, "networkError")) {
    const networkError = error.networkError;
    if (networkError.name === "ServerError") {
      const result = (networkError as ServerError)
        .result as unknown as ResultWithErrors;

      if ("errors" in result) {
        const error = result.errors[0];
        if (error.extensions.code === "UNAUTHORIZED") {
          logout();
          return;
        }
      }
    }
  }

  if (hasProperty<ErrorWithMessage>(error, "message")) {
    return <p>{error.message}</p>;
  }

  // return <p>Something went wrong</p>;
  return <SomethingWentWrong />;
}
