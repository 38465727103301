import styles from "./MetaIntegrationAccountDetailsSlider.module.scss";
import { Link } from "react-router-dom";
import EmptyAll from "../assets/emptyAll.png";
import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";

// function SectionDivider({ label }) {
//   return (
//     <p className={classNames(styles.divider, styles.line, styles.oneLine)}>
//       {label}
//     </p>
//   );
// }
// function LoadingItem() {
//   return (
//     <div className={styles.detailItem}>
//       <Skeleton width={20} height={20} borderRadius={"50%"} />
//       <Skeleton className={styles.loadingTitle} />
//       <Skeleton className={styles.loadingStatus} />
//     </div>
//   );
// }
export function MetaIntegrationDetailsEmptyState({
  text,
  link,
  emptyAll = false,
}: {
  text: string;
  link: string;
  emptyAll?: boolean;
}) {
  return (
    <div className={styles.emptyState}>
      {emptyAll ? (
        <img src={EmptyAll} alt="emptyAll" className={styles.emptyImg} />
      ) : (
        <Info className={styles.emptyIco} />
      )}
      <p className={styles.emptyText}>{text}</p>
      <Link to={link} className={styles.learnMore}>
        Learn More
      </Link>
    </div>
  );
}
