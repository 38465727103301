import {
  AUDIENCE_PLATFORMS,
  IAudienceAudienceSetIndexNode,
} from "./audienceListingTypes";

export function getAudienceAudienceSetSourcePlatform(
  item: IAudienceAudienceSetIndexNode,
) {
  const platforms = new Set<AUDIENCE_PLATFORMS>();

  if (item.MetaAudienceSet) {
    const metaAudienceSet = item.MetaAudienceSet;

    // interests
    if (
      metaAudienceSet.Interests.length > 0 ||
      metaAudienceSet.ExcludedInterests.length > 0
    ) {
      platforms.add("META");
      platforms.add("INSTAGRAM");
    }

    // audiences
    if (
      metaAudienceSet.Audiences.length > 0 ||
      metaAudienceSet.ExcludedAudiences.length > 0
    ) {
      const audiences = [
        ...metaAudienceSet.Audiences,
        ...metaAudienceSet.ExcludedAudiences,
      ];
      audiences.forEach((v) => {
        platforms.add(v.platform as AUDIENCE_PLATFORMS);
      });
    }
  }

  if (item.Audience) {
    const audience = item.Audience;
    platforms.add(audience.platform as AUDIENCE_PLATFORMS);
  }

  return Array.from(platforms);
}
