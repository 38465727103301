import { Form, Formik, FormikContextType } from "formik";
import { addValueToFlowList, removeNewVariantSelector } from "./helpers";
import AutomationBuilderSidebar from "src/components/Automations/builder/AutomationBuilderSidebar/AutomationBuilderSidebar";
import { FlowArea } from "./FlowArea";
import { IFlowItem } from "src/components/Automations/builder/AutomationBuilderFlowArea/FlowItem";
import styles from "./CreateAutomationPage.module.scss";

export type FlowItems = IFlowItem | IConditionalItem;

export interface IConditionalItem extends IFlowItem {
  options: [FlowItems[], FlowItems[]] | [];
}

export interface FormValues {
  section:
    | "START"
    | "SIGNUP"
    | "RULES_ACTION"
    | "CONTACT"
    | "UPLOAD"
    | "CONDITION"
    | "EMPTY";
  flowList: FlowItems[];
  selectedItem?: {
    selectedIndex: number;
    parentIndex?: number;
    optionIndex?: number;
  };
}

interface Props {
  initialValues?: FormValues;
}

export default function CreateAutomationPage({
  initialValues = { flowList: [], section: "START" },
}: Props) {
  function addItem(
    item: FlowItems,
    values: FormikContextType<FormValues>["values"],
    setFieldValue: (name: string, value: any) => void
  ) {
    // this may not be required
    const flowItems = removeNewVariantSelector(values.flowList);
    const modifiedItems = addValueToFlowList(
      flowItems,
      values,
      item,
      setFieldValue
    );
    setFieldValue("flowList", modifiedItems);
    setFieldValue("section", "EMPTY");
    setFieldValue("selectedItem", null);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ values }) => (
        <Form>
          <main className={styles.main}>
            <div className={styles.mainInner}>
              <AutomationBuilderSidebar addItem={addItem} />
              <div className={styles.flowArea}>
                <FlowArea values={values} />
              </div>
            </div>
          </main>
        </Form>
      )}
    </Formik>
  );
}
