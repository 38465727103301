import { DateFilter } from "./dateFilterUtils";

export const dateFilterOptions: {
  label: string;
  value: DateFilter;
}[] = [
  {
    label: "Yesterday",
    value: "YESTERDAY",
  },
  {
    label: "Last 7 Days",
    value: "LAST_7_DAYS",
  },
  {
    label: "Last 28 Days",
    value: "LAST_28_DAYS",
  },
  {
    label: "Last 3 months",
    value: "LAST_3_MONTHS",
  },
  {
    label: "Last 6 months",
    value: "LAST_6_MONTHS",
  },
  {
    label: "All time",
    value: "ALL_TIME",
  },
];
