import { LineHeading } from "src/modules/media/components/LineHeading";
import styles from "./ActivityObjectiveAndOthers.module.scss";
import { RadioGroup } from "@headlessui/react";
import facebookCatalogue from "../assets/facebookCatalogue.svg";
import facebookStandard from "../assets/facebookStandard.svg";
import twoSecondViews from "../assets/twoSecondViews.svg";
import videoThruplay from "../assets/videoThruplay.svg";
import postPromotion from "../assets/postPromotion.svg";
import instagram from "../assets/instagram.svg";
import messengerSelectIcon from "../assets/messengerSelectIcon.svg";
import whatsapp from "../assets/whatsapp.svg";
import websiteViews from "../assets/websiteViews.svg";
import call from "../assets/call.svg";
import {
  ActivityInputV2,
  MetaActivityPurpose,
} from "../misc/createCampaignTypes";
import { SelectableObjective } from "./SelectableObjective";
import { ReactComponent as LocalMall } from "@material-symbols/svg-600/rounded/local_mall.svg";
import { ReactComponent as CaptivePortal } from "@material-symbols/svg-600/rounded/captive_portal.svg";
import { ReactComponent as Flag } from "@material-symbols/svg-600/rounded/flag.svg";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-600/rounded/info.svg";
import { SelectOption } from "./SelectOption";
import SelectPlacement from "../assets/SmartPlacement.svg";
import AdvancePlacement from "../assets/AdvancePlacement.svg";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { useContext, useState } from "react";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import Tooltip from "src/components/Tooltip/Tooltip";
import { Form, Formik } from "formik";
import { Button } from "src/components/Button/Button";
import { ActivityFacebookSettingsSelectorModal } from "./ActivityFacebookSettingsSelectorModal";
import { FormikError } from "src/components/FormikError/FormikError";
import * as Yup from "yup";
import { ManualPlacementOptions } from "./ManualPlacementOptions";
import classNames from "classnames";

const options = [
  {
    label: "Standard Audience based Conversion Ads",
    imageSrc: facebookStandard,
    value: "SALES_STANDARD" as MetaActivityPurpose,
    category: "SALES",
  },
  {
    label: "Dynamic Catalog Based Conversion Ads",
    imageSrc: facebookCatalogue,
    value: "SALES_CATALOG" as MetaActivityPurpose,
    category: "SALES",
  },
  // {
  //   label: "Instant Lead Form",
  //   imageSrc: facebookStandard,
  //   value: "SALES_STANDARD" as MetaActivityPurpose,
  // },
  // traffic
  {
    label: "Make more people view your post",
    imageSrc: postPromotion,
    value: "PROMOTE_POST" as MetaActivityPurpose,
    category: "TRAFFIC",
  },
  {
    label: "Make more people visit your website",
    imageSrc: websiteViews,
    value: "WEBSITE_VIEWS" as MetaActivityPurpose,
    category: "TRAFFIC",
  },
  // engagement
  {
    label: "More messages and leads on Instagram",
    imageSrc: instagram,
    value: "MESSAGE_INSTAGRAM" as MetaActivityPurpose,
    category: "ENGAGEMENT",
  },
  {
    label: "More messages and leads on WhatsApp",
    imageSrc: whatsapp,
    value: "MESSAGE_WHATSAPP" as MetaActivityPurpose,
    category: "ENGAGEMENT",
  },
  {
    label: "More messages and leads on Messenger",
    imageSrc: messengerSelectIcon,
    value: "MESSAGE_MESSENGER" as MetaActivityPurpose,
    category: "ENGAGEMENT",
  },
  {
    label: "Get more calls",
    imageSrc: call,
    value: "CALL" as MetaActivityPurpose,
    category: "ENGAGEMENT",
  },
  {
    label: "Maximise video views on fully watched videos",
    imageSrc: videoThruplay,
    value: "VIDEO_THRUPLAY" as MetaActivityPurpose,
    category: "ENGAGEMENT",
  },
  {
    label: "Maximise views on partially watched videos ",
    imageSrc: twoSecondViews,
    value: "VIDEO_2_SEC_VIEW" as MetaActivityPurpose,
    category: "ENGAGEMENT",
  },
];

const objectiveAndOthersValidationSchema = Yup.object({
  metaPurpose: Yup.string()
    .oneOf(options.map((o) => o.value))
    .required("Objective is required"),
  adPlacementType: Yup.string()
    .oneOf(["SMART", "ADVANCED"])
    .required("Placement is required"),
  adPlacementOptions: Yup.array(
    Yup.string().oneOf(["FACEBOOK", "INSTAGRAM"])
  ).when("adPlacementType", {
    is: "ADVANCED",
    then: Yup.array(Yup.string().oneOf(["FACEBOOK", "INSTAGRAM"]))
      .required("Ad placement options is required")
      .min(1, "Select atleast one option"),
  }),
});

export function ActivityObjectiveAndOthers({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const [isAccountSelectorOpened, setIsAccountSelectorOpened] = useState(false);
  const { activeStepIndex, data, markStepAsDone } =
    useContext(StepWizardContext);

  const salesOptions = options.filter((o) => o.category === "SALES");
  const trafficOptions = options.filter((o) => o.category === "TRAFFIC");
  const engagementOptions = options.filter((o) => o.category === "ENGAGEMENT");

  if (data.platform === "WHATSAPP") {
    return <div />;
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={objectiveAndOthersValidationSchema}
      initialValues={data as ActivityInputV2}
      onSubmit={(values) => {
        markStepAsDone(2, values);
      }}
    >
      {({
        values,
        setFieldValue,
        setValues,
        handleSubmit,
        submitCount,
        errors,
        touched,
      }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 1}
            isCompleted={activeStepIndex > 1}
          >
            <div ref={sectionRef} className={styles.section}>
              <div className={styles.header}>
                <div>
                  <h2 className={styles.heading}>
                    Objectives and other settings
                  </h2>
                  <h3 className={styles.subheading}>
                    Select the what you want from this activity, and configure
                    accordingly
                  </h3>
                </div>
                <div>
                  <span className={styles.globalSettingInfo}>
                    {values.adAccountId && (
                      <>
                        We are using your global settings{" "}
                        <Tooltip supportingText="">
                          <InfoIcon width={16} height={16} />
                        </Tooltip>
                      </>
                    )}
                    <button
                      onClick={() => {
                        setIsAccountSelectorOpened(true);
                      }}
                      className={classNames(styles.changeSettingsButton, {
                        [styles.outlinedButton]: !values.adAccountId,
                      })}
                      type="button"
                    >
                      {values.adAccountId
                        ? "Change Settings"
                        : "Select Account Settings"}
                    </button>
                  </span>
                </div>
              </div>

              <ActivityFacebookSettingsSelectorModal
                activeStepIndex={activeStepIndex}
                isOpen={isAccountSelectorOpened}
                onClose={() => setIsAccountSelectorOpened(false)}
                // @ts-ignore
                initialFormValues={values}
                handleSubmit={(newValues) => {
                  setValues({ ...values, ...newValues });
                  setIsAccountSelectorOpened(false);
                }}
              />

              <div
                className={classNames(styles.objectives, {
                  [styles.objectivesDisabled]: !values.adAccountId,
                })}
              >
                <RadioGroup
                  value={values.metaPurpose}
                  onChange={(value) => {
                    setFieldValue("metaPurpose", value);
                  }}
                >
                  <div className={styles.objectiveGroup}>
                    <LineHeading
                      icon={
                        <LocalMall
                          fill="var(--color-disabled)"
                          width={15}
                          height={15}
                        />
                      }
                      content="Sales and Conversion"
                    />
                    <div className={styles.optionsList}>
                      {salesOptions.map((option) => (
                        <SelectableObjective
                          imageSrc={option.imageSrc}
                          content={option.label}
                          value={option.value}
                        />
                      ))}
                    </div>
                  </div>

                  <div className={styles.objectiveGroup}>
                    <LineHeading
                      icon={
                        <CaptivePortal
                          fill="var(--color-disabled)"
                          width={15}
                          height={15}
                        />
                      }
                      content="Traffic & Awareness"
                    />
                    <div className={styles.optionsList}>
                      {trafficOptions.map((option) => (
                        <SelectableObjective
                          imageSrc={option.imageSrc}
                          content={option.label}
                          value={option.value}
                        />
                      ))}
                    </div>
                  </div>

                  <div className={styles.objectiveGroup}>
                    <LineHeading
                      icon={
                        <Flag
                          fill="var(--color-disabled)"
                          width={15}
                          height={15}
                        />
                      }
                      content="Engagement"
                    />
                    <div className={styles.optionsList}>
                      {engagementOptions.map((option) => (
                        <SelectableObjective
                          imageSrc={option.imageSrc}
                          content={option.label}
                          value={option.value}
                        />
                      ))}
                    </div>
                  </div>

                  <FormikError
                    fieldName="metaPurpose"
                    submitCount={submitCount}
                    formikError={errors}
                    touched={touched}
                  />
                </RadioGroup>
              </div>

              <div
                className={classNames(styles.placement, {
                  [styles.placementDisabled]: !values.adAccountId,
                })}
              >
                <h3 className={styles.placementHeading}>Select Ad Placement</h3>
                <h4 className={styles.placementSubheading}>
                  Choose the places where to show your ad
                </h4>

                <RadioGroup
                  value={values.adPlacementType}
                  onChange={(value) => {
                    setFieldValue("adPlacementType", value);
                  }}
                >
                  <div className={styles.placementList}>
                    <SelectOption
                      value="SMART"
                      label={"Smart Placements"}
                      subLabel="Enable us to get the best results available from across all default placements."
                      icon={
                        <img
                          alt="smart placement"
                          src={SelectPlacement}
                          width={48}
                          height={48}
                        />
                      }
                    />

                    <SelectOption
                      subOptions={
                        <ManualPlacementOptions
                          onChange={(value) =>
                            setFieldValue("adPlacementOptions", value)
                          }
                          value={values.adPlacementOptions ?? []}
                        />
                      }
                      value="ADVANCED"
                      label={"Manual Placements"}
                      subLabel="Manually choose the places to show your ad. Only for advance user who knows whom to target."
                      icon={
                        <img
                          alt="advance placement"
                          src={AdvancePlacement}
                          width={48}
                          height={48}
                        />
                      }
                    />
                  </div>

                  <FormikError
                    fieldName="adPlacementType"
                    submitCount={submitCount}
                    formikError={errors}
                    touched={touched}
                  />

                  <FormikError
                    fieldName="adPlacementOptions"
                    submitCount={submitCount}
                    formikError={errors}
                    touched={touched}
                  />
                </RadioGroup>
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  disabled={!values.adAccountId || !values.metaPurpose}
                  onClick={handleSubmit}
                  size="micro"
                >
                  Save & Continue
                </Button>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
