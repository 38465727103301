import Card from "src/components/Card/Card";
import styles from "./FacebookSegmentBasedAudienceForm.module.scss";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { getIn, useFormikContext } from "formik";
import { AudienceInput } from "src/modules/audience/components/CreateAudienceInput";
import { Button } from "src/components/Button/Button";
import { useSegmentsQuery } from "src/graphql/generated/schema";
import { useState } from "react";
import ComboBox from "src/components/ComboBox/ComboBox";
import { Label } from "src/components/Label/Label";
import { FacebookAccountSettings } from "./FacebookAccountSettings";

interface IFacebookSegmentBasedAudienceForm {
  selectedAudienceIndex?: number;
  handleBack: () => void;
}

export function FacebookSegmentBasedAudienceForm({
  selectedAudienceIndex,
  handleBack,
}: IFacebookSegmentBasedAudienceForm) {
  const { values, setFieldValue, handleSubmit, isValid, isSubmitting } =
    useFormikContext<AudienceInput>();
  const audienceKey = `audiences[${selectedAudienceIndex}]`;
  const audienceValuesKey = `audiences[${selectedAudienceIndex}].values`;
  const audienceValues = getIn(values, audienceValuesKey);

  return (
    <Card title="Create a website Custom Audience">
      <FacebookAccountSettings
        audienceValues={audienceValues}
        audienceValuesKey={audienceValuesKey}
        setFieldValue={setFieldValue}
      />
      <div className={styles.nameAndSegment}>
        <InputFormik
          name={`${audienceKey}.audienceName`}
          label={"Audience Name"}
          placeholder="Max limit 50 characters"
        />

        <div className={styles.fbSegmentSearchComboBox}>
          <FacebookSegmentSearchComboBox
            values={audienceValues}
            setSelected={(v: string) => {
              setFieldValue(`${audienceValuesKey}.segmentId`, v);
            }}
          />
        </div>
      </div>
      <div className={styles.btnGroup}>
        <Button
          onClick={handleSubmit}
          type="button"
          disabled={!isValid}
          loading={isSubmitting}
        >
          Save And Continue
        </Button>
        <Button
          style="plain"
          color="subdued"
          type="button"
          onClick={handleBack}
          disabled={isSubmitting}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}

function FacebookSegmentSearchComboBox({ values, setSelected }) {
  const [query, setQuery] = useState("");
  const { data, loading } = useSegmentsQuery({
    variables: {
      filter: {
        name: query,
        take: 5,
      },
    },
  });

  const segments =
    data && data.segments ? data.segments.edges.map((v) => v.node) : [];

  return (
    <Label label={"Select customer segment"}>
      <ComboBox
        onChange={(v) => setQuery(v)}
        loading={loading}
        options={
          loading
            ? []
            : segments.map((v) => ({
                label: v.name,
                value: v.id,
              }))
        }
        multiple={false}
        selected={values?.segmentId}
        placeholder={
          values?.segmentId && segments
            ? segments.find((v) => v.id === values.segmentId)?.name
            : "Select Segment"
        }
        setSelected={setSelected}
        showScrollAfter={3}
      />
    </Label>
  );
}
