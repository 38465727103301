import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import styles from "./WhatsappTemplateButton.module.scss";
import { IconButton } from "src/components/IconButton/IconButton";
import { FieldArray } from "formik";
import { WhatsappFormInput } from "src/pages/CreateTemplate/CreateMessageTemplateTypes";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { AddWhatsappItemButton } from "./AddWhatsappItemButton";

interface IQuickRepliesInput {
  values: WhatsappFormInput;
}
export function QuickRepliesInput({ values }: IQuickRepliesInput) {
  return (
    <FieldArray
      name="buttons.quickReplies"
      render={(arrayHelpers) => (
        <>
          <div className={styles.quickReplies}>
            {values.buttons.quickReplies.map((item, index) => (
              <div className={styles.quickReplyTile} key={index}>
                <InputFormik name={`buttons.quickReplies[${index}]`} />
                <IconButton
                  icon={<Close className={styles.addIco} />}
                  style="plain"
                  color="subdued"
                  onClick={() => {
                    arrayHelpers.remove(index);
                  }}
                />
              </div>
            ))}
          </div>
          <AddWhatsappItemButton
            ctaType={values.buttons.type}
            onClick={() => arrayHelpers.push("")}
          />
        </>
      )}
    />
  );
}
