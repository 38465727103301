import { useEffect, useState } from "react";
import { Breadcrumb, Node } from "../misc/wizardTypes";
import { WizardResetAlert, WizardContext } from "../misc/WizardContext";
import Alert from "src/components/Alert/Alert";

interface dataArgs<TData> {
  currentNodeId: string;
  completedNodes: string[];
  visitedNodes: string[];
  data: TData;
}

interface WizardProps<TData> {
  tree: Record<string, Node<TData>>;
  startingNodeId: string;
  data: TData;
  initiallyCompletedNodes?: string[];
  initiallyVisitedNodes?: string[];
  // for background sync
  onDataUpdate?: (dataArgs: dataArgs<TData>) => void;
}

export function Wizard<TData>({
  tree,
  startingNodeId,
  initiallyCompletedNodes = [],
  initiallyVisitedNodes = [],
  data: initialData,
  onDataUpdate,
}: WizardProps<TData>) {
  const [data, setData] = useState(initialData);
  const _currentNode = tree[startingNodeId];
  const [currentNode, setCurrentNode] = useState<null | Node<TData>>(
    _currentNode
  );
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const resetAlertDefaultValue = {
    isShowing: false,
    fieldKey: "",
    data: data,
    fieldValue: "",
  };
  const [resetAlert, setResetAlert] = useState<WizardResetAlert<TData>>(
    resetAlertDefaultValue
  );
  const [completedNodes, setCompletedNodes] = useState<string[]>(
    initiallyCompletedNodes
  );
  const [visitedNodes, setVisitedNodes] = useState<string[]>(
    initiallyVisitedNodes
  );

  const isCurrentNodeCompleted = currentNode
    ? completedNodes.includes(currentNode.id)
    : false;

  useEffect(() => {
    if (!currentNode) return;
    if (!visitedNodes.includes(currentNode.id)) {
      setVisitedNodes([...visitedNodes, currentNode.id]);
    }

    if ("getBreadcrumbs" in currentNode) {
      const breadcrumbs = currentNode.getBreadcrumbs(data);
      setBreadcrumbs(breadcrumbs);
    }
  }, [currentNode, data, visitedNodes]);

  useEffect(() => {
    // don't update if it is same (based on reference comparison) to the initialData
    if (data !== initialData && typeof onDataUpdate === "function") {
      onDataUpdate({
        currentNodeId: currentNode.id,
        completedNodes,
        visitedNodes,
        data,
      });
    }
  }, [currentNode]);

  function markNodeAsComplete(data: TData) {
    const currentNodeId = currentNode.id;

    if (!completedNodes.includes(currentNode.id)) {
      const newCompletedNodes = completedNodes.concat(currentNodeId);
      setCompletedNodes(newCompletedNodes);
    }

    const newCurrentNodeId = currentNode.getNextStep(data);
    setData(data);
    const newCurrentNode = tree[newCurrentNodeId];

    if (!newCurrentNode) {
      throw new Error("new current node is not available in the tree");
    }

    setCurrentNode(newCurrentNode);
  }

  function goBack(data: TData) {
    setData(data);
    const previousNodeId = currentNode.getPreviousStep(data);
    const previousNode = tree[previousNodeId];

    if (!previousNode) {
      throw new Error("new current node is not available in the tree");
    }

    setCurrentNode(previousNode);
  }

  function navigateToNodeId(nodeId: string) {
    const newNode = tree[nodeId];

    if (!visitedNodes.includes(newNode.id)) {
      // can not navigate to an un-visited node
      return;
    }

    if (!newNode) {
      throw new Error("new current node is not available in the tree");
    }

    setCurrentNode(newNode);
  }

  function purgeDataWhenFieldReset(
    fieldName: string,
    fieldValue: any,
    data: TData
  ) {
    if ("purgeDataWhenFieldReset" in currentNode) {
      const newData = currentNode.purgeDataWhenFieldReset(
        fieldName,
        fieldValue,
        data
      );
      // purge the progress beyond the current node
      const indexOfCurrentNode = completedNodes.findIndex(
        (c) => c === currentNode.id
      );
      setCompletedNodes(completedNodes.slice(0, indexOfCurrentNode));

      const indexOfVisitedNode = visitedNodes.findIndex(
        (c) => c === currentNode.id
      );
      setVisitedNodes(visitedNodes.slice(0, indexOfVisitedNode));

      //
      setData({ ...newData, [fieldName]: fieldValue });
    }
  }

  if (!currentNode) {
    return null;
  }

  return (
    <>
      <WizardContext.Provider
        value={{
          goBack,
          resetAlert,
          setResetAlert,
          navigateToNodeId,
          visitedNodes,
          data,
          currentNode,
          breadcrumbs,
          completedNodes,
          isCurrentNodeCompleted,
          setData,
          markNodeAsComplete,
        }}
      >
        {currentNode.component}
      </WizardContext.Provider>
      <Alert
        isOpen={resetAlert.isShowing}
        onClose={() => setResetAlert({ ...resetAlert, isShowing: false })}
        secondaryAction={{
          children: "Yes",
          color: "primary",
          onClick() {
            purgeDataWhenFieldReset(
              resetAlert.fieldKey,
              resetAlert.fieldValue,
              resetAlert.data
            );
            setResetAlert(resetAlertDefaultValue);
          },
        }}
        primaryAction={{
          children: "No",
          color: "subdued",
          style: "outline",
          onClick() {
            setResetAlert({ ...resetAlert, isShowing: false });
          },
        }}
        title="Doing this change will reset your progress"
      >
        By doing this change you will reset all the changes done in steps after
        this. Do you still want to continue?
      </Alert>
    </>
  );
}
