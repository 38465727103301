import { useGetBillingQuery } from "../../../graphql/generated/schema";
import { SectionHeading } from "../../global/components/SectionHeading";
import styles from "./Plans.module.scss";

export default function Plans() {
  const {} = useGetBillingQuery();
  return (
    <div className={styles.page}>
      <SectionHeading
        heading={"Subscription"}
        description={"About your active plans and related offerings"}
      />
      <div className={styles.wrapper}></div>
    </div>
  );
}
