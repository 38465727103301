import styles from "./EmailVerify.module.scss";

export function EmailVerify({ title, subtitle, bgImg }) {
  return (
    <div className={styles.card} style={{ backgroundImage: `url(${bgImg})` }}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
}
