/* eslint-disable react/style-prop-object */
import { getIn, useFormikContext } from "formik";
import { Button } from "src/components/Button/Button";
import {
  CampaignInput,
  MetaEngagementConversionLocation,
} from "../misc/createCampaignTypes";
import Card from "src/components/Card/Card";
import styles from "./ChooseMetaEngagementConversionLocation.module.scss";
import { FormikError } from "../../../components/FormikError/FormikError";
import { EngagementSelect } from "./EngagementSelect";
import { EngagementMessagingPlatforms } from "./EngagementMessagingPlatforms";
import engagementCall from "../assets/engagementCall.png";
import engagementCallPreview from "../assets/engagementCallPreview.png";
import engagementMessage from "../assets/engagementMessage.png";
import clickToMessageHelpImage from "../assets/clickToMessage.png";
import { Tabs } from "src/components/Tabs/Tabs";
import { useWizardContext } from "../../global/misc/WizardContext2";
import { useState } from "react";

interface Props {
  disabled: boolean;
  selectedActivityIndex: number;
  handleBack: (i: CampaignInput) => unknown;
}

const tabOptions = [
  { label: "Engage with Messages or Calls", value: "MESSAGE_OR_CALL" },
  { label: "Engage with Posts", value: "POST" },
];

export function ChooseMetaEngagementConversionLocation({
  disabled,
  selectedActivityIndex,
  handleBack,
}: Props) {
  const { isCurrentNodeCompleted, setResetAlert } = useWizardContext();
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);
  const {
    values,
    setFieldTouched,
    isSubmitting,
    submitCount,
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<CampaignInput>();

  const fieldKey = `Activities[${selectedActivityIndex}].engagementConversionLocation`;
  const fieldValue = getIn(values, fieldKey) ?? "";

  const conversionLocationOptions = [
    {
      value: "CALL" as MetaEngagementConversionLocation,
      tab: "MESSAGE_OR_CALL",
      image: { src: engagementCall },
      label: "Get more calls",
      useCases: "Get more calls for business",
      title: "Get more calls",
      description: `Get people to engage with your brand on call.`,
    },
    {
      image: { src: engagementMessage },
      value: "MESSAGE" as MetaEngagementConversionLocation,
      tab: "MESSAGE_OR_CALL",
      tooltip: {
        image: clickToMessageHelpImage,
        description: `Destinations are messaging apps where your conversations take place. You can select one or multiple destinations to be available to chat. Your budget will be shared across the destinations that you choose.`,
        title: "How click-to-message destinations work",
      },
      useCases: "Get more messages for business",
      title: "Get more Messages",
      description: `Get people to engage with your brand on Messenger, WhatsApp or Instagram.`,
      label:
        "Get people to engage with your brand on Messenger, WhatsApp or Instagram.",
      children: (
        <EngagementMessagingPlatforms
          baseKey={`Activities[${selectedActivityIndex}]`}
        />
      ),
    },
    {
      value: "VIDEO_THRUPLAY" as MetaEngagementConversionLocation,
      tab: "POST",
      image: { src: engagementCall },
      label: "Maximise video views on fully watched videos",
      useCases: "Get more calls for business",
      title: "Get more calls",
      description: `Get people to engage with your brand on call.`,
    },
    {
      value: "VIDEO_2_SEC_VIEW" as MetaEngagementConversionLocation,
      tab: "POST",
      image: { src: engagementCall },
      label: "Maximise views on partially watched videos (2 sec or more)",
      useCases: "Get more calls for business",
      title: "Get more calls",
      description: `Get people to engage with your brand on call.`,
    },
    {
      value: "PROMOTE_POST" as MetaEngagementConversionLocation,
      tab: "POST",
      image: { src: engagementCall },
      label: "Promote Post",
      useCases: "Get more engagement on post",
      title: "Get more Post Engagement",
      description: `Get people to engage with your post.`,
    },
  ];

  const selectedOption = fieldValue
    ? conversionLocationOptions.find((option) => option.value === fieldValue)
    : null;

  const optionsOfSelectedTab = conversionLocationOptions.filter(
    (c) => c.tab === selectedTab
  );

  return (
    <Card disabled={disabled} title="What do you want from this activity?">
      <div className={styles.layout}>
        <div className={styles.formSection}>
          <Tabs
            onTabChange={(selectedTab) => {
              setSelectedTab(selectedTab);
            }}
            selectedTab={selectedTab}
            tabs={tabOptions}
          />
          <EngagementSelect
            srOnlyLabel={true}
            name={fieldKey}
            value={fieldValue}
            options={optionsOfSelectedTab}
            onBlur={() => {
              setFieldTouched(fieldKey);
            }}
            onChange={(value) => {
              if (isCurrentNodeCompleted) {
                setResetAlert({
                  data: values,
                  fieldKey,
                  fieldValue: value,
                  isShowing: true,
                });
                return;
              }

              setFieldValue(fieldKey, value);
            }}
            label="Select Meta ad activity type"
          />
          <FormikError
            touched={touched}
            formikError={errors}
            submitCount={submitCount}
            fieldName={fieldKey}
          />
        </div>
        {selectedOption && (
          <div className={styles.previewSection}>
            <img
              className={styles.previewImage}
              src={engagementCallPreview}
              alt="people getting attracted to call you"
            />
            <div className={styles.previewInfoSection}>
              <h3 className={styles.previewTitle}>{selectedOption.title}</h3>
              <p className={styles.previewDescription}>
                {selectedOption.description}
              </p>
            </div>
            <div className={styles.previewUseCaseSection}>
              <h3 className={styles.previewUseCaseTitle}>Ideal For:</h3>
              <p className={styles.previewUseCaseDescription}>
                {selectedOption.useCases}
              </p>
            </div>
          </div>
        )}
      </div>

      <div className={styles.submit}>
        <Button disabled={!fieldValue} loading={isSubmitting} type="submit">
          Save and Continue
        </Button>
        <Button
          color="subdued"
          style="outline"
          type="button"
          onClick={() => handleBack(values)}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}
