import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./WebsiteIntegrationPage.module.scss";
import { IntegrationBanner } from "src/modules/global/components/IntegrationBanner";
import { IntegrationNeedHelp } from "src/modules/global/components/IntegrationNeedHelp";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button/Button";
import syncShopify from "../assets/syncShopify.png";
import checkBefore from "../assets/checkBefore.png";
import { ReactComponent as ClipBoard } from "@material-design-icons/svg/outlined/content_copy.svg";
import toast from "react-hot-toast";
import classNames from "classnames";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import { WebsiteConnected } from "../components/WebsiteConnected";
import {
  useWebsiteIntegrationQuery,
  useWebsiteIntegrationVerifyMutation,
} from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import WEBSITE_INTEGRATION from "src/graphql/websiteIntegration";
import { PageLoader } from "src/modules/global/components/PageLoader";
import websiteBackground from "../assets/websiteBg.svg";
import website from "../assets/website.svg";

export function WebsiteIntegrationPage() {
  const { data, loading, error } = useWebsiteIntegrationQuery();
  const [verifyIntegration, { loading: verifying }] =
    useWebsiteIntegrationVerifyMutation({
      refetchQueries: [WEBSITE_INTEGRATION],
    });
  const { activeClient, user } = useContext(AuthContext);

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  async function verifyScript() {
    const payload = await verifyIntegration();

    if (payload.data.websiteIntegrationVerify?.isConnected) {
      toast.success("Integration successfully verified");
    } else {
      toast.error("Not integrated yet");
    }
  }

  let isConnected = data.websiteIntegration?.isConnected;

  if (isConnected) {
    return (
      <WebsiteConnected
        name={data.websiteIntegration?.name}
        domain={data.websiteIntegration?.domain}
      />
    );
  }

  const accessTokens = {
    DEVELOPMENT:
      "p.eyJ1IjogIjhhODEyYjJiLWQyZGUtNDZmMi04M2Q3LTQ5NWRmODc2N2EyOSIsICJpZCI6ICIwZTYwYzZjZi0xOWMzLTQ2NGMtOGU2Zi0wNmRhOWU2MDg2MWYiLCAiaG9zdCI6ICJ1c19lYXN0In0.u7iujJ0e4xNbSlY5QT5_2PRgTdLRVRw6-fzxlEiItO8",
    PRODUCTION:
      "p.eyJ1IjogImJmMTlmMDUwLTZiZjgtNDI2MS04ZDg2LThhYmY2ZjVjODVmNCIsICJpZCI6ICI2OGM0MDZhYy0wYTQ5LTRhMDItYmZiNS0xOWNhZGY1MmRiYTkiLCAiaG9zdCI6ICJ1c19lYXN0In0.AIW4aPy4qDc9D7SzqOuM5hu9LkHroQ5NC90UaZZYcaU",
  };

  let accessToken = accessTokens.DEVELOPMENT;

  if (process.env.REACT_APP_ENV === "production") {
    accessToken = accessTokens.PRODUCTION;
  }

  let code = `<script
  data-host="https://api.us-east.tinybird.co"
  data-token="${accessToken}"
  data-client-id="${activeClient.id}"
  data-organization-id="${user.Organization.id}"
  src="https://macro-tracker-iota.vercel.app/macro-tracker.js"
  ></script>`;

  function copyCode() {
    navigator.clipboard.writeText(code);
    toast.success("Code Copied");
  }
  return (
    <>
      <PageHeader title="Website Integration" back={"/settings/integrations"} />
      <div className={styles.content}>
        <IntegrationBanner
          borderColor="rgba(201, 204, 207, 0.50)"
          title={"Integrate website and see analytics in real time"}
          subtitle={
            "With Web Integration, you will be able to see how your campaigns are contributing towards your goal of sale increase or increase in website reach. You'll get analytics in real time."
          }
          image={website}
          background={`url(${websiteBackground})`}
        />
        <div>
          <h2 className={styles.subHeading}>
            Follow the steps and integrate the script
          </h2>
          <div className={styles.stepContainer}>
            <div className={styles.stepsWrapper}>
              <div>
                <span className={styles.stepHead}>Here is the script</span>
                <div className={styles.codeContainer}>
                  <pre className={styles.code}>
                    {/* Use their own token for individual clients */}
                    {code}
                  </pre>
                  <button className={styles.copyBtn} onClick={copyCode}>
                    <ClipBoard className={styles.copyIco} />
                    Copy
                  </button>
                </div>
              </div>
              <div>
                <span className={styles.stepHead}>
                  If you are using Shopify Store
                </span>
                <ol className={styles.steps}>
                  <li className={styles.step}>
                    Copy the above code and paste it into your theme.liquid
                    file.
                    <Link to={"/"} className={styles.link}>
                      Learn More
                    </Link>
                  </li>
                  <li className={styles.step}>
                    After adding the code, we will verify and if everything is
                    fine you will soon see the analytics on your Macro
                  </li>
                </ol>
              </div>
              <div>
                <span className={styles.stepHead}>
                  If you are using any other Platform
                </span>
                <ol className={styles.steps}>
                  <li className={styles.step}>Copy the above code.</li>
                  <li className={styles.step}>
                    Paste that code into the index.html file. If you are facing
                    any issue check out{" "}
                    <Link to="/" className={styles.link}>
                      Help Center{" "}
                    </Link>
                    or
                    <Link to={"/"} className={styles.link}>
                      {" "}
                      Contact support
                    </Link>
                  </li>
                  <li className={styles.step}>
                    After adding the code, we will verify and if everything is
                    fine you will soon see the analytics on your Macro
                  </li>
                </ol>
              </div>
              <Button loading={verifying} onClick={verifyScript}>
                Verify Website
              </Button>
            </div>
            <img
              src={syncShopify}
              alt="shopify_other_platforms"
              className={styles.image}
            />
          </div>
        </div>
        <div>
          <h2 className={styles.subHeading}>
            Check out the following permission before get started
          </h2>
          <div
            className={classNames(
              styles.stepContainer,
              styles.stepContainerTwo,
            )}
          >
            <div className={styles.stepsWrapper}>
              <div>
                <span className={styles.stepHead}>Online Tracking</span>
                <p className={styles.step}>
                  The Macro App Embed allows you to collect helpful information
                  around browsing activity. When enabled, Macro will begin
                  tracking "Active on Site" events, enable onsite forms, and add
                  any additional tracking features configured below. To modify,
                  view your Shopify App Embed setting.
                </p>
              </div>
              <div>
                <span className={styles.stepHead}>
                  Sync data from Shopify and Website
                </span>
                <p className={styles.step}>
                  Moving forward, new site visitors that subscribe to email
                  marketing through Shopify will be subscribed to your selected
                  Macro list.
                </p>
              </div>
            </div>
            <img
              src={checkBefore}
              alt="Check_Before"
              className={styles.image}
            />
          </div>
        </div>
        <IntegrationNeedHelp />
      </div>
    </>
  );
}
