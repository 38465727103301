import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./WhatsappIntegration.module.scss";
import { IntegrationBanner } from "src/modules/global/components/IntegrationBanner";
import { IntegrationNeedHelp } from "src/modules/global/components/IntegrationNeedHelp";
import { IntegrationSteps } from "src/modules/global/components/IntegrationSteps";
import { Link, useNavigate } from "react-router-dom";
import { enableWhatsappIntegration } from "../functions/enableWhatsappIntegration";
import { useExchangeWhatsappCodeForAccessTokenMutation } from "src/graphql/generated/schema";
import { useCallback, useEffect, useState } from "react";

const beforeArr = [
  "You must have a valid and a dedicated phone number to use WhatsApp business API.",
  <span>
    The phone number for your business must be a valid phone number which meets
    the{" "}
    <Link
      to="https://developers.facebook.com/docs/whatsapp/overview/#about-the-platform"
      className={styles.link}
      target="_blank"
    >
      WhatsApp Business Platform criteria
    </Link>
  </span>,
  "You need a phone number that has not been associated to another Business Service provider.",
  "You must have a personal Facebook account (linked to your business email)",
  "As per Meta's policy, an unverified business account can only be linked to two numbers, Verify your account to link your account with more numbers.",
  "You must have a valid Business website.",
];

const whatArr = [
  "Create WhatsApp activities and campaigns",
  "Automate your activity based on Triggers and Events",
  "Schedule Campaigns and Activities based on your preferred time",
  "Send Bulk messages to various customers or multiple segments.",
  "Manage how your Campaign is performing with respect to certain audience types",
  "Create Audiences at Facebook level and Manage Budgetings.",
  "Create Audiences Sets and Schedule ads",
];

export function WhatsappIntegration() {
  const [accessTokenCode, setAccessTokenCode] = useState("");
  const [phoneNumberId, setPhoneNumberId] = useState("");
  const navigate = useNavigate();
  const [whatsappBusinessAccountId, setWhatsappBusinessAccountId] =
    useState("");
  const [exchangeWhatsappCodeForAccessToken] =
    useExchangeWhatsappCodeForAccessTokenMutation();

  const sessionInfoListener = useCallback((event) => {
    if (event.origin == null) {
      return;
    }

    // Make sure the data is coming from facebook.com
    if (!event.origin.endsWith("facebook.com")) {
      return;
    }

    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        // if user finishes the Embedded Signup flow
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data;
          setPhoneNumberId(phone_number_id);
          setWhatsappBusinessAccountId(waba_id);
        }
        // if user reports an error during the Embedded Signup flow
        else if (data.event === "ERROR") {
          const { error_message } = data.data;
          console.error("error ", error_message);
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
          console.warn("Cancel at ", current_step);
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
      console.log("Non JSON Response", event.data);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", sessionInfoListener);

    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, [sessionInfoListener]);

  useEffect(() => {
    // redeem token when all 3 are captured
    if (!accessTokenCode || !phoneNumberId || !whatsappBusinessAccountId) {
      return;
    }

    exchangeWhatsappCodeForAccessToken({
      variables: {
        input: {
          accessTokenCode: accessTokenCode,
          accessTokenState: "",
          phoneNumberId: phoneNumberId,
          whatsappBusinessAccountId: whatsappBusinessAccountId,
        },
      },
    }).then(() => {
      navigate("/settings/integrations/whatsapp");
    });
  }, [
    phoneNumberId,
    whatsappBusinessAccountId,
    accessTokenCode,
    exchangeWhatsappCodeForAccessToken,
  ]);

  return (
    <>
      {/* Use IntegrationPageHeader INstead of this page Header */}
      <PageHeader
        title={"WhatsApp Business Integration"}
        back={"/settings/integrations"}
      />
      <div className={styles.content}>
        <IntegrationBanner
          title={"Integrate WhatsApp Business"}
          subtitle={
            "You should always analyse phone number quality to ensure that your customers are not blocking or reporting your messages. Businesses need to demonstrate that they can send high or medium quality messages at higher volumes before they can get higher messaging limits"
          }
          // img={WhatsappIntegrationBanner}
          action={{
            label: "Integrate WhatsApp Business",

            onClick: () => {
              enableWhatsappIntegration((code, state) => {
                setAccessTokenCode(code);
              });
            },
          }}
        />
        <IntegrationSteps heading={"Before you start"} steps={beforeArr} />
        <IntegrationSteps
          heading={"What does it do?"}
          steps={whatArr}
          subHeading="Here are the following"
        />
        <IntegrationNeedHelp />
      </div>
    </>
  );
}
