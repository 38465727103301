import {
  CustomerCountRange,
  MonthlyAdSpendRange,
  MonthlyCreditRange,
} from "src/graphql/generated/schema";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { ReactComponent as Cancel } from "@material-design-icons/svg/filled/cancel.svg";
import { ReactComponent as CheckCircle } from "@material-design-icons/svg/filled/check_circle.svg";
import Dropdown from "src/components/Dropdown/Dropdown";
import classNames from "classnames";
import styles from "./FeaturesTable.module.scss";
import {
  premiumAdSpendOptions,
  premiumCustomerCountOptions,
  premiumMonthlyCreditOptions,
} from "../misc/planOptions";

const cancelIco = (
  <Cancel className={classNames(styles.ico, styles.cancelIco)} />
);
const checkIco = (
  <CheckCircle className={classNames(styles.ico, styles.checkIco)} />
);

export function planFeatures({
  selectedAdSpend,
  selectedCustomerCount,
  selectedCredits,
  setSelectedCredits,
  setIsEnterprisePlan,
}: {
  selectedAdSpend: MonthlyAdSpendRange;
  selectedCustomerCount: CustomerCountRange;
  selectedCredits: MonthlyCreditRange;
  setSelectedCredits: (i: MonthlyCreditRange) => void;
  setIsEnterprisePlan: (i: boolean) => void;
}) {
  // not available in standard plan
  const monthlyCreditOptions = premiumMonthlyCreditOptions;
  const adSpendOptions = premiumAdSpendOptions;

  const customerCountOptions = premiumCustomerCountOptions;

  const selectedMonthlyCreditOption = selectedCredits
    ? monthlyCreditOptions.find((m) => m.value === selectedCredits)
    : null;

  const selectedAdSpendOption = selectedAdSpend
    ? adSpendOptions.find((m) => m.value === selectedAdSpend)
    : null;

  const selectedCustomerCountOption = selectedCustomerCount
    ? customerCountOptions.find((m) => m.value === selectedCustomerCount)
    : null;

  const engageFeatures = [
    [
      "Active Customers manage",
      selectedCustomerCountOption.label,
      selectedCustomerCountOption.label,
    ],
    ["Monthly Email send", "upto 1,500 monthly", "upto 2,500 monthly"],
    [
      "Monthly SMS/Whatsapp send credits",
      <div className={styles.icoWrapper}>{cancelIco}</div>,
      <div className={styles.creditsWrapper}>
        <span>{selectedMonthlyCreditOption?.label} monthly credits</span>
        {/* <Dropdown
          title={
            <span className={styles.dropdownTitle}>
              <span className={styles.dropDownText}>More</span>
              <ArrowDown className={styles.dropDownIco} />
            </span>
          }
          items={monthlyCreditOptions.map((credit) => ({
            children: credit.label,
            onClick() {
              setSelectedCredits(credit.value);
            },
          }))}
        /> */}
      </div>,
    ],
    [
      "Monthly Push Message send",
      <div className={styles.icoWrapper}>{cancelIco}</div>,
      "upto 2,500 monthly",
    ],
    [
      "Email Scheduling",
      <div className={styles.icoWrapper}>
        {/* <CheckCircle /> */}
        {checkIco}
      </div>,
      <div className={styles.icoWrapper}>
        {/* <CheckCircle /> */}
        {checkIco}
      </div>,
    ],
    ["Team Member", "5", "10"],
    ["Analytics", "Standard", "Advance"],
    ["Pre-built Templates", "Limited Option", "More Options"],
    ["Support", "Email Based", "Dedicated assistance"],
    [
      "Automation flows",
      <div className={styles.icoWrapper}>
        {/* <CheckCircle /> */}
        {checkIco}
      </div>,
      <div className={styles.icoWrapper}>
        {/* <CheckCircle /> */}
        {checkIco}
      </div>,
    ],
    ["Segmentations", "Standard", "Advance"],
  ];

  const growFeatures = [
    [
      "Ad Spends Monthly",
      selectedAdSpendOption.label,
      selectedAdSpendOption.label,
    ],
    [
      "Google Ads",
      <div className={styles.icoWrapper}>{cancelIco}</div>,
      <div className={styles.icoWrapper}>{checkIco}</div>,
    ],
    [
      "Meta Ads",
      <div className={styles.icoWrapper}>{checkIco}</div>,
      <div className={styles.icoWrapper}>{checkIco}</div>,
    ],
    [
      "Activities Scheduling",
      <div className={styles.icoWrapper}>{checkIco}</div>,
      <div className={styles.icoWrapper}>{checkIco}</div>,
    ],
    ["Analytics", "Standard", "Advance"],
    [
      "Planner Schedule",
      <div className={styles.icoWrapper}>{checkIco}</div>,
      <div className={styles.icoWrapper}>{checkIco}</div>,
    ],
    ["Team Member", "5", "10"],
    [
      "Tracking and Reporting",
      <div className={styles.icoWrapper}>{cancelIco}</div>,
      <div className={styles.icoWrapper}>{checkIco}</div>,
    ],
  ];

  const enterpriseFeatures = [
    ["Active Customers manage", "Contact Sales"],
    ["Monthly Email send", "Contact Sales"],
    ["Monthly SMS/Whatsapp send credits", "Contact Sales"],
    ["Monthly Push Message send", "Contact Sales"],
    ["Email Scheduling", <div className={styles.icoWrapper}>{checkIco}</div>],
    ["Team Member", "Contact Sales"],
    ["Analytics", "Advance"],
    ["Pre-built Templates", "More Options"],
    ["Support", "Dedicated assistance"],
    ["Automation flows", <div className={styles.icoWrapper}>{checkIco}</div>],
    ["Segmentations", "Advance"],
  ];

  return { engageFeatures, growFeatures, enterpriseFeatures };
}
