import { getIn, useFormikContext } from "formik";
import { IInputV2, InputV2 } from "../InputV2/InputV2";

type InputPropsWithoutFormikFields = Omit<
  IInputV2,
  "onChange" | "onBlur" | "value" | "error" | "name"
>;

interface Props extends InputPropsWithoutFormikFields {
  name: string;
}

export function InputFormikV2({ name, ...props }: Props) {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();

  let shouldShowError = false;
  const fieldError = getIn(errors, name, "");
  const isFieldTouched = getIn(touched, name);
  const fieldValue = getIn(values, name, "");

  // if by any chance name has link in it and fieldError is string then getIn(errors, name) will translate to
  // "some string".link which will refer to String.prototype.link. Hence to avoid this we check for fieldError
  // type being string only to proceed and not a function
  if (typeof fieldError === "string" && isFieldTouched) {
    shouldShowError = true;
  }

  return (
    <InputV2
      name={name}
      onChange={(value) => setFieldValue(name, value)}
      // for some alien reason. If below line is uncommented then onBlur does not perform action on relay target
      // .i.e if there is a button that you want to press while editing input then button won't be clicked
      // onBlur={handleBlur}
      value={fieldValue}
      error={
        shouldShowError
          ? typeof fieldError === "function"
            ? fieldError()
            : fieldError
          : null
      }
      {...props}
    />
  );
}
