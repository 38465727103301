import { ReactNode, useMemo } from "react";
import styles from "./SwitchSelectionTable.module.scss";
import classNames from "classnames";
import { SwitchGroup } from "src/modules/global/components/SwitchGroup";
import { Button } from "src/components/Button/Button";
import { ReactComponent as CrossIcon } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as CheckIcon } from "@material-design-icons/svg/outlined/check.svg";
import { ReactComponent as SelectedIcon } from "@material-symbols/svg-400/rounded/position_bottom_left.svg";

type SwitchValueType = "INCLUDE" | "EXCLUDE" | "NONE";

interface SwitchSelectionTableProps {
  headers: Array<ReactNode>;
  items: Array<{
    id: string;
    icon: ReactNode;
    title: ReactNode;
    description: ReactNode;
    otherRows: Array<ReactNode>;
  }>;
  noItemsContent?: React.ReactNode;
  onShowSelection?: () => void;
  shouldShowSelection: boolean;
  onChange: (i: SwitchValueType, item: { id: string }) => void;
  gridTemplate: string;
  values: Record<
    string,
    {
      value: SwitchValueType;
    }
  >;
}

export function SwitchSelectionTable({
  items,
  noItemsContent,
  headers,
  shouldShowSelection,
  onShowSelection,
  onChange,
  gridTemplate,
  values,
}: SwitchSelectionTableProps) {
  const selectionCount = useMemo(
    () => Object.values(values).filter((a) => a.value !== "NONE").length,
    [values],
  );

  return (
    <div
      // @ts-ignore
      style={{ "--grid-template": gridTemplate }}
      className={styles.table}
    >
      <ul className={styles.tableHead}>
        {headers.map((header) => (
          <li className={styles.tableHeading}>{header}</li>
        ))}
      </ul>
      <ul className={styles.tableBody}>
        {!items.length && noItemsContent
          ? noItemsContent
          : items.map((item, index) => (
              <li
                key={index}
                className={classNames(styles.tableRow, {
                  [styles.zebraRow]:
                    (values[item.id]?.value ?? "NONE") === "NONE" &&
                    // we want to color every odd row
                    index % 2 === 1,
                  [styles.includedRow]: values[item.id]?.value === "INCLUDE",
                  [styles.excludedRow]: values[item.id]?.value === "EXCLUDE",
                })}
              >
                <div className={styles.tableMainColumn}>
                  {item.icon}
                  <div>
                    <h4
                      className={classNames(styles.tableCellHeading, {
                        [styles.includedHeading]:
                          values[item.id]?.value === "INCLUDE",
                        [styles.excludedHeading]:
                          values[item.id]?.value === "EXCLUDE",
                        [styles.noneHeading]: values[item.id]?.value === "NONE",
                      })}
                    >
                      {item.title}
                    </h4>
                    <p className={styles.tableCellDescription}>
                      {item.description}
                    </p>
                  </div>
                </div>
                {item.otherRows.map((row) => (
                  <div className={styles.tableCell}>{row}</div>
                ))}

                <div>
                  <SwitchGroup
                    value={values[item.id]?.value ?? "NONE"}
                    onChange={(value: SwitchValueType) =>
                      onChange(value, { id: item.id })
                    }
                    noneValue="NONE"
                    options={[
                      {
                        label: (
                          <CheckIcon
                            fill="currentColor"
                            width={18}
                            height={18}
                          />
                        ),
                        value: "INCLUDE",
                        selectedColor: `rgba(0, 127, 95, 1)`,
                        selectedBackgroundColor: `rgba(241, 248, 245, 1)`,
                      },
                      {
                        label: (
                          <CrossIcon
                            fill="currentColor"
                            width={18}
                            height={18}
                          />
                        ),
                        value: "EXCLUDE",
                        selectedColor: `rgba(215, 44, 13, 1)`,
                        selectedBackgroundColor: `rgba(255, 244, 244, 1)`,
                      },
                    ]}
                  />
                </div>
              </li>
            ))}
      </ul>
      {shouldShowSelection && (
        <div className={styles.tableFooter}>
          <Button onClick={onShowSelection} color="normal" style="plain">
            <span
              className={classNames(styles.viewSelectedBtnText, {
                [styles.activeViewSelectedBtnText]: selectionCount,
              })}
            >
              <SelectedIcon
                fill="currentColor"
                strokeWidth={2}
                width={20}
                height={20}
              />
              View Selection: {selectionCount}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}
