import { Disclosure, Transition } from "@headlessui/react";
import styles from "./FaqDropdown.module.scss";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/arrow_forward_ios.svg";
import classNames from "classnames";

interface IFaqDropdown {
  question: string;
  answer: string;
}

export function FaqDropdown({ question, answer }: IFaqDropdown) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(styles.dropDownBtn, {
              [styles.showBorder]: open,
            })}
          >
            <span className={styles.question}>{question}</span>
            <ArrowDown
              className={classNames(styles.dropDownIco, {
                [styles.rotateDropdownIco]: open,
              })}
            />
          </Disclosure.Button>
          {/* <Transition
            show={open}
            enter={styles.enter}
            enterFrom={styles.enterFrom}
            enterTo={styles.enterTo}
            leave={styles.leave}
            leaveFrom={styles.leaveFrom}
            leaveTo={styles.leaveTo}
          >
          </Transition> */}
          <Disclosure.Panel className={classNames({ [styles.answer]: open })}>
            {answer}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
