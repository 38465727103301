import { CampaignInput } from "../misc/createCampaignTypes";
import { audiencesSchema } from "../misc/audiencesSchema";
import { CampaignCreateForm } from "./CampaignCreateForm";
import { SelectFacebookAudiencesLoader } from "./SelectFacebookAudiencesLoader";

interface ISelectFacebookAudiencesForm {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => unknown;
}

export function SelectFacebookAudiencesForm({
  handleBack,
  campaignPresetUpsert,
  campaignInput,
}: ISelectFacebookAudiencesForm) {
  return (
    <CampaignCreateForm
      schema={audiencesSchema}
      campaignInput={campaignInput}
      campaignPresetUpsert={campaignPresetUpsert}
    >
      <SelectFacebookAudiencesLoader
        handleBack={handleBack}
        campaignInput={campaignInput}
      />
    </CampaignCreateForm>
  );
}
