import Alert from "src/components/Alert/Alert";
import styles from "./CustomerDeleteAlert.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function CustomerDeleteAlert({ isOpen, onClose }: Props) {
  return (
    <Alert
      primaryAction={{
        children: "Delete",
        onClick: () => {},
        color: "danger",
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Are you sure?"
      secondaryAction={{
        children: "Cancel",
        color: "subdued",
        style: "outline",
        className: `${styles.secondary}`,
        onClick: onClose,
      }}
    >
      <p className={styles.content}>
        By deleting this customers you may loose some analytics which are based
        on them and you won't be able to use them.
      </p>
    </Alert>
  );
}
