import { Button } from "src/components/Button/Button";
import styles from "./NotificationAlertSettings.module.scss";
import Select from "src/components/Select/Select";
import NotificationSettingWithToggle from "./NotificationSettingWithToggle";
import NotificationHealth from "../assets/notificationHealth.svg";
import { useFormikContext } from "formik";
import { notificationSettingFormType } from "../misc/notificationSettingFormType";
import { NotifySettingIcon } from "./NotificationAlertSettings";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import { useEffect } from "react";
import { ReportDurationsEnum } from "src/graphql/generated/schema";

export function NotificationHealthReport({
  loading,
  onChange,
}: {
  loading: boolean;
  onChange: (values: notificationSettingFormType) => void;
}) {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<notificationSettingFormType>();

  if (!values.isBusinessHealthNotificationEnabled) {
    return (
      <div>
        <NotifySettingIcon img={NotificationHealth} />
        <p className={styles.title}>Business Health Report</p>
        <p className={styles.description}>
          A quick summary report of your business, ideal for to get an idea
          about what's happening in your business
        </p>

        <Button
          loading={loading}
          onClick={async () => {
            setFieldTouched("isBusinessHealthNotificationEnabled");
            setFieldValue("isBusinessHealthNotificationEnabled", true);
            onChange({
              ...values,
              isBusinessHealthNotificationEnabled: true,
            });
          }}
          type="button"
          size="small"
        >
          Turn on Health Report
        </Button>
      </div>
    );
  }

  return (
    <div>
      <NotifySettingIcon img={NotificationHealth} />
      <p className={styles.title}>Business Health Report</p>
      <p className={styles.description}>
        A quick summary report of your business, ideal for to get an idea about
        what's happening in your business
      </p>
      <Select
        options={[
          { label: "Daily", value: "DAILY" },
          { label: "Weekly", value: "WEEKLY" },
          { label: "Monthly", value: "MONTHLY" },
          { label: "Quarterly", value: "QUARTERLY" },
          { label: "Yearly", value: "YEARLY" },
        ]}
        value={values.reportDurations[0]}
        onChange={async function (value: string) {
          setFieldTouched("reportDurations");
          setFieldValue("reportDurations", [value]);
          onChange({
            ...values,
            reportDurations: [value as ReportDurationsEnum],
          });
        }}
      />
      <div className={styles.subSetList}>
        <p className={styles.subSetTitle}>Receive Report on</p>
        <NotificationSettingWithToggle
          title={"Email"}
          formKey={"EmailBroadcastSettings.isBusinessHealthNotificationEnabled"}
          description={`Connected account: ${values.email}`}
        />

        <NotificationSettingWithToggle
          title={"WhatsApp"}
          disabled={true}
          formKey={
            "WhatsappBroadcastSettings.isBusinessHealthNotificationEnabled"
          }
          description={`Connected number: ${values.whatsappPhoneNumber}`}
        />
      </div>
      <Button
        loading={loading}
        onClick={async () => {
          setFieldTouched("isBusinessHealthNotificationEnabled");
          setFieldValue("isBusinessHealthNotificationEnabled", false);
          onChange({
            ...values,
            isBusinessHealthNotificationEnabled: false,
          });
        }}
        type="button"
        size="small"
      >
        Turn off notification
      </Button>
    </div>
  );
}
