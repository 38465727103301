import { Form, Formik } from "formik";
import { useContext } from "react";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import styles from "./StoreConnectForm.module.scss";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import LinkIcon from "@untitled-ui/icons-react/build/esm/Link01";
import { useShopifyShopNameValidateLazyQuery } from "src/graphql/generated/schema";

export function StoreConnectForm() {
  const { data, activeStepIndex, markStepAsDone } =
    useContext(StepWizardContext);
  const [shopifyShopNameValidate] = useShopifyShopNameValidateLazyQuery();
  const installShopifyUrl = `${process.env.REACT_APP_FUNCTIONS_URI}/shopify/install`;

  return (
    <Formik
      initialValues={data}
      onSubmit={async (values) => {
        await shopifyShopNameValidate({
          variables: {
            shopifyShopName: values.shopifyShopName,
          },
        });
        window.location.href = `${installShopifyUrl}?shop=${values.shopifyShopName}`;
        // markStepAsDone(1, values);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <StepWizardStep
              isStepNotActive={activeStepIndex !== 0}
              isCompleted={activeStepIndex > 0}
            >
              <div className={styles.section}>
                <h2 className={styles.heading}>Verify shopify store</h2>
                <h3 className={styles.subheading}>
                  You need to verify the shopify store in order to proceed
                  further
                </h3>

                <InputFormikV2
                  size="large"
                  isFullWidth={false}
                  icon={LinkIcon}
                  helpText="It is the store url that ends with .myshopify.com"
                  name="shopifyShopName"
                  label="Shopify store link"
                />

                <div className={styles.buttonGroup}>
                  <ButtonV2 loading={isSubmitting} size="large" type="submit">
                    Proceed
                  </ButtonV2>
                </div>
              </div>
            </StepWizardStep>
          </Form>
        );
      }}
    </Formik>
  );
}
