import { ReactComponent as CloseIcon } from "@material-design-icons/svg/outlined/close.svg";
import { IconButton } from "../IconButton/IconButton";
import styles from "./FilePreview.module.scss";
import { formatFileSize } from "src/lib/formatFileSize";

interface File {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  lastModifiedDate: Date;
}

export interface IFilePreview {
  file: File;
  action: () => void;
}

export default function FilePreview(props: IFilePreview) {
  const { file, action } = props;
  return (
    <div className={styles.fileCard}>
      <div>
        <img
          className={styles.preview}
          src="/imagesAndIcons/EmailTemp.svg"
          width={36}
          height={36}
        />
      </div>
      <div className={styles.fileCardBody}>
        <div className={styles.heading}>
          <h4 className={styles.fileName}>{file.name}</h4>
          <span className={styles.fileSize}>{formatFileSize(file.size)}</span>
        </div>
      </div>
      <div className={styles.closeBtn}>
        <IconButton icon={<CloseIcon />} style="plain" onClick={action} />
      </div>
    </div>
  );
}
