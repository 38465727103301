import { Activity, useActivityQuery } from "src/graphql/generated/schema";
import styles from "./CampaignActivityCard.module.scss";
import { ActivityItem } from "src/modules/campaign/components/CampaignActivites";
import { Tabs } from "src/components/Tabs/Tabs";
import { useState } from "react";
import { Button } from "src/components/Button/Button";
import { Spinner } from "src/components/Spinner/Spinner";
import { useActivityAggregatedStats } from "../../global/functions/useActivityAggregatedStats";
import { CampaignActivityCardCreatives } from "./CampaignActivityCardCreatives";
import { CampaignActivityCardAnalytics } from "./CampaignActivityCardAnalytics";
import { useActivityPerTickStats } from "../../global/functions/useActivityPerTickStats";

type ActivitySummary = Pick<
  Activity,
  | "id"
  | "name"
  | "status"
  | "platform"
  | "createdAt"
  | "publishedAt"
  | "failedAt"
  | "scheduledAt"
>;

export function CampaignActivityCard({
  activity,
  campaignId,
  dateFrom,
  dateTo,
}: {
  activity: ActivitySummary;
  campaignId: string;
  dateFrom?: Date;
  dateTo?: Date;
}) {
  const { data, loading } = useActivityQuery({
    variables: {
      id: activity.id,
    },
  });
  const { data: activityStatsData, loading: activityStatsLoading } =
    useActivityAggregatedStats(activity.id, {
      dateFrom,
      dateTo,
    });
  const {
    data: activityPerTickStatsData,
    loading: activityPerTickStatsLoading,
  } = useActivityPerTickStats(activity.id, {
    dateFrom,
    dateTo,
  });
  const [selectedTabs, setSelectedTabs] = useState("ANALYTICS");

  if (loading || activityStatsLoading || activityPerTickStatsLoading) {
    return (
      <div>
        <Spinner height={400} />
      </div>
    );
  }

  const activityCardContent = {
    ANALYTICS: (
      <CampaignActivityCardAnalytics
        activity={data?.activity as any}
        activityAggregateStats={activityStatsData}
        activityPerTickStats={activityPerTickStatsData}
      />
    ),
    CREATIVES: (
      <CampaignActivityCardCreatives
        activity={data?.activity as any}
        dateFrom={dateFrom}
        dateTo={dateTo}
        // activityAggregateStats={activityStatsData}
      />
    ),
  };

  return (
    <div className={styles.activityCard}>
      <CampaignActivityCardHeader
        activity={data?.activity}
        campaignId={campaignId}
      />
      <div className={styles.activityCardContent}>
        <Tabs
          tabs={[
            {
              label: "Analytics",
              value: "ANALYTICS",
            },
            {
              label: "Creatives",
              value: "CREATIVES",
            },
          ]}
          selectedTab={selectedTabs}
          onTabChange={setSelectedTabs}
        />
        <div className={styles.tabContent}>
          {activityCardContent[selectedTabs]}
        </div>
      </div>
    </div>
  );
}

function CampaignActivityCardHeader({
  activity,
  campaignId,
}: {
  activity: ActivitySummary;
  campaignId: string;
}) {
  return (
    <div className={styles.activityCardHeader}>
      <div className={styles.activityCardPrimary}>
        <ActivityItem {...activity} campaignId={campaignId} />
      </div>
      <div>
        <Button to={`activities/${activity.id}`} style="plain" color="primary">
          View Activity
        </Button>
      </div>
    </div>
  );
}
