import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import isEqual from "react-fast-compare";

interface ISaveFromState<T> {
  onSave: (values: T) => Promise<unknown>;
  debounceDuration?: number;
}

export function SaveFormState<T>({
  onSave,
  debounceDuration = 700,
}: ISaveFromState<T>) {
  const { values } = useFormikContext<T>();
  const lastSavedState = useRef<T>(values);

  const saveFn = useDebouncedCallback((values: T) => {
    onSave(values);
    lastSavedState.current = values;
  }, debounceDuration);

  useEffect(() => {
    if (isEqual(values, lastSavedState.current)) {
      return;
    }

    saveFn(values);
  }, [values]);

  return null;
}
