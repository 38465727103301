import { LineHeading } from "src/modules/media/components/LineHeading";
import styles from "./ExistingPostAdFieldsShowcase.module.scss";
import { ReactComponent as ArticleIcon } from "@material-symbols/svg-600/rounded/article.svg";
import { ReactComponent as AddLink } from "@material-symbols/svg-600/rounded/add_link.svg";
import Select from "src/components/Select/Select";
import { useFormikContext } from "formik";
import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { ReactComponent as LoveIcon } from "@material-symbols/svg-600/rounded/favorite.svg";
import { ReactComponent as CommentIcon } from "@material-symbols/svg-600/rounded/tooltip.svg";
import { PageWelcomeMessageSelector } from "./PageWelcomeMessageSelector";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { AdCallToActionLabelField } from "./AdCallToActionLabelField";

interface IExistingPostAdFieldsShowcase {
  selectedAd: AdInputV2;
  selectedAdIndex: number;
}

export function ExistingPostAdFieldsShowcase({
  selectedAdIndex,
  selectedAd,
}: IExistingPostAdFieldsShowcase) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  const shouldShowPageWelcomeMessage =
    values.metaPurpose === "MESSAGE_INSTAGRAM" ||
    values.metaPurpose === "MESSAGE_MESSENGER" ||
    values.metaPurpose === "MESSAGE_WHATSAPP";

  const shouldShowLink =
    values.metaPurpose === "SALES_STANDARD" ||
    values.metaPurpose === "PROMOTE_POST";

  const shouldShowPhoneNumber = values.metaPurpose === "CALL";

  return (
    <div className={styles.adFields}>
      <div className={styles.selectedPost}>
        <div className={styles.postPreview}>
          {selectedAd.ExistingPost.type === "IMAGE" ? (
            <img
              src={selectedAd.ExistingPost.url}
              alt="post"
              width={40}
              height={40}
            />
          ) : (
            <video width={40} height={40} loop muted>
              <source src={selectedAd.ExistingPost.url} />
            </video>
          )}
        </div>

        <div className={styles.postContent}>
          <div className={styles.postTitle}>
            {selectedAd.ExistingPost.caption}
          </div>
          <div className={styles.postMoreInfo}>
            <span className={styles.postLikes}>
              <LoveIcon fill="var(--color-disabled)" width={14} height={14} />
              {selectedAd.ExistingPost.likeCount}
            </span>
            <span className={styles.postComments}>
              <CommentIcon
                fill="var(--color-disabled)"
                width={14}
                height={14}
              />
              {selectedAd.ExistingPost.commentsCount}
            </span>
          </div>
        </div>
      </div>

      {shouldShowPageWelcomeMessage && (
        <div>
          <PageWelcomeMessageSelector
            value={selectedAd.pageWelcomeMessageId}
            onChange={() => {
              /** field is disabled */
            }}
          />
        </div>
      )}

      <LineHeading
        icon={<ArticleIcon width={14} height={14} />}
        content="Destination"
      />
      <div className={styles.linksSection}>
        <AdCallToActionLabelField
          name={`Ads[${selectedAdIndex}].callToActionLabel`}
          value={selectedAd.callToActionLabel}
          adType={selectedAd.adType}
        />

        {shouldShowLink && (
          <InputFormik
            name={`Ads[${selectedAdIndex}].LineItems[0].link`}
            label="Destination Website link*"
            icon={AddLink}
          />
        )}

        {shouldShowPhoneNumber && (
          <PhoneNumberInput
            disabled
            defaultPhoneCode="+91"
            label="Phone Number"
            value={selectedAd.phoneNumber}
            countryCodeValue={selectedAd.phoneCountryCode}
            countryCodeOnChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneCountryCode`, value);
            }}
            onChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneNumber`, value);
            }}
          />
        )}
      </div>
    </div>
  );
}
