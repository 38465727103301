import styles from "./MetaAdAccountsList.module.scss";
import {
  useMetaAllAdAccountsQuery,
  useMetaEnableAdAccountMutation,
  useMetaRemoveAdAccountMutation,
} from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { MetaAccountItem } from "./MetaAccountItem";
import { useState } from "react";
import toast from "react-hot-toast";
import META_AD_ACCOUNTS from "src/graphql/metaAdAccounts";
import metaEnabledAdAccounts from "src/graphql/metaEnabledAdAccounts";
import { META_INTEGRATION_DEFAULT_SETTING } from "src/graphql/metaIntegrationDefaultSetting";

interface IMetaAdAccountsList {
  setShowAdAccountDetails: (i: { isOpen: boolean; id: null | string }) => void;
}

export function MetaAdAccountsList({
  setShowAdAccountDetails,
}: IMetaAdAccountsList) {
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);
  const [metaEnableAdAccountFunc, { loading: enableAdAccountLoading }] =
    useMetaEnableAdAccountMutation();

  const [metaRemoveAdAccountFunc, { loading: removeAdAccountLoading }] =
    useMetaRemoveAdAccountMutation();

  const { data: metaAllAdAccountsData, loading: metaAllAdAccountsLoading } =
    useMetaAllAdAccountsQuery();

  if (metaAllAdAccountsLoading) {
    return <Spinner height={400} />;
  }

  const metaAdAccounts =
    metaAllAdAccountsData && metaAllAdAccountsData.metaAllAdAccounts
      ? metaAllAdAccountsData.metaAllAdAccounts
      : [];

  return (
    <div className={styles.container}>
      {metaAdAccounts.map((adAccount) => (
        <MetaAccountItem
          loading={
            selectedAdAccount === adAccount.id &&
            (enableAdAccountLoading || removeAdAccountLoading)
          }
          onToggle={async () => {
            setSelectedAdAccount(adAccount.id);
            if (adAccount.enabled) {
              await metaRemoveAdAccountFunc({
                variables: {
                  metaAdAccountId: adAccount.id,
                },
                refetchQueries: [
                  { query: META_AD_ACCOUNTS, variables: {} },
                  { query: metaEnabledAdAccounts, variables: {} },
                  { query: META_INTEGRATION_DEFAULT_SETTING, variables: {} },
                ],
                awaitRefetchQueries: true,
              });
              toast("Ad Account Disabled");
            } else {
              const { data } = await metaEnableAdAccountFunc({
                variables: {
                  metaAdAccountId: adAccount.id,
                },
                refetchQueries: [
                  { query: META_AD_ACCOUNTS, variables: {} },
                  { query: metaEnabledAdAccounts, variables: {} },
                  { query: META_INTEGRATION_DEFAULT_SETTING, variables: {} },
                ],
                awaitRefetchQueries: true,
              });
              if (data?.metaAdAccountEnable?.userError) {
                const graphqlError =
                  data?.metaAdAccountEnable?.userError?.message;
                toast.error(graphqlError);
                return;
              }
              setShowAdAccountDetails({ isOpen: true, id: adAccount.id });
              toast("Ad Account Enabled");
            }
          }}
          onViewDetails={() => {
            setShowAdAccountDetails({
              isOpen: true,
              id: adAccount.id,
            });
          }}
          amountSpent={adAccount?.amountSpent || 0}
          enabled={adAccount.enabled}
          status={adAccount.status}
          name={adAccount.metaName}
          imageUrl={adAccount?.Business?.picture}
        />
      ))}
    </div>
  );
}

/*
        <MetaDetailItem
          enableFunc={async () => {
            await metaEnableAdAccountFunc({
              variables: {
                metaAdAccountId: v.id,
              },
              refetchQueries: [
                "metaAllAdAccounts",
                "metaPagesForEnabledAdAccounts",
              ],
              awaitRefetchQueries: true,
            });
          }}
          removeFunc={async () => {
            await metaRemoveAdAccountFunc({
              variables: {
                metaAdAccountId: v.id,
              },
              refetchQueries: [
                "metaAllAdAccounts",
                "metaPagesForEnabledAdAccounts",
              ],
              awaitRefetchQueries: true,
            });
          }}
          title={v.metaName}
          description={`Total Spent Amount: ${
            formatNumberByType(v?.amountSpent, "CURRENCY") || 0
          } `}
          isEnabled={v.enabled}
          loading={enableAdAccountLoading || removeAdAccountLoading}
        />
          */
