import { AllCampaignsQuery } from "src/graphql/generated/schema";
import styles from "./CampaignItem.module.scss";
import { CampaignActivityItem } from "./CampaignActivityItem";
import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { CampaignItemMoreOptions } from "./CampaignItemMoreOptions";
import { ReactComponent as ProgressActivityIcon } from "@material-symbols/svg-400/rounded/progress_activity.svg";

interface ICampaignItem {
  campaign: AllCampaignsQuery["allCampaigns"]["edges"][0]["node"];
}

export function CampaignItem({ campaign }: ICampaignItem) {
  const [isHovered, setIsHovered] = useState(false);
  const [isCampaignUpdating, setIsCampaignUpdating] = useState(false);
  const isPreset = campaign.type === "CampaignPreset";
  const link = isPreset
    ? `/campaigns/new?presetId=${campaign.id}`
    : `/campaigns/${campaign.id}`;

  return (
    <div
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      className={styles.campaignItem}
    >
      <div className={styles.campaignHeader}>
        <div className={styles.campaignNameWrapper}>
          <h3 className={styles.campaignName}>
            <Link to={link}>{campaign.name}</Link>
          </h3>
          {isCampaignUpdating && (
            <ProgressActivityIcon
              className={styles.loadingIcon}
              fill="currentColor"
              width={20}
              height={20}
            />
          )}
          {/* <hr
            className={classNames(
              styles.campaignHeaderSeparator,
              styles.fadeTransition,
              {
                [styles.itemHidden]: !isHovered,
              }
            )}
          />
          <button
            className={classNames(
              styles.createActivityButton,
              styles.fadeTransition,
              {
                [styles.itemHidden]: !isHovered,
              }
            )}
            type="button"
          >
            <span className={styles.buttonWithIcon}>
              <PlusIcon stroke="var(--color-disabled)" width={16} height={16} />
              Add Activity
            </span>
          </button> */}
        </div>

        <div className={styles.moreActionsWrapper}>
          <div
            className={classNames(styles.moreActions, styles.fadeTransition, {
              [styles.itemHidden]: !isHovered,
            })}
          >
            <Link to={link} className={styles.viewCampaignButton}>
              View Campaign
            </Link>
          </div>
          <CampaignItemMoreOptions
            setIsCampaignUpdating={setIsCampaignUpdating}
            isCampaignEmpty={campaign.Activities.length === 0}
            isPreset={isPreset}
            campaignId={campaign.id}
          />
        </div>
      </div>
      <div className={styles.activitiesList}>
        {campaign.Activities.map((activity, index) => (
          <CampaignActivityItem
            campaignPresetId={isPreset ? campaign.id : null}
            activityIndex={isPreset ? index : null}
            campaignId={campaign.id}
            activity={activity}
          />
        ))}
      </div>
    </div>
  );
}
