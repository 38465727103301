import AutomationsTable from "src/components/Automations/listing/AutomationsTable/AutomationsTable";
import { Button } from "src/components/Button/Button";
import Datepicker from "src/components/Datepicker/Datepicker";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { SelectAutomationTemplate } from "src/components/Automations/AutomationTemplateModal/SelectAutomationTemplate";
import { useAutomationsQuery } from "src/graphql/generated/schema";
import { useState } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

interface IAutomationFilters {
  status?: "FLOWS" | "ARCHIVED";
  platform?: "FLOWS" | "ARCHIVED";
}

export default function AutomationsPage() {
  const [showModal, setShowModal] = useState(false);

  const [filters, setFilters] = useState<IAutomationFilters>({
    platform: "FLOWS",
    status: "FLOWS",
  });
  const [view, setView] = useState<"SIMPLE" | "TABLE">("SIMPLE");
  const [showFilters, setShowFilters] = useState(false);

  const { data, loading, error } = useAutomationsQuery({
    variables: {
      sortBy: {
        field: "CREATED_AT",
        direction: "DESC",
      },
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <>
      <SelectAutomationTemplate
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <PageHeader
        title="Automation"
        actions={
          <>
            {/* <Datepicker onChange={() => {}} />
            <Button
              onClick={() => {}}
              color="subdued"
              size="small"
              style="outline"
            >
              Create from Scratch
            </Button> */}
            <Button onClick={() => setShowModal(true)} size="small">
              Create from Template
            </Button>
          </>
        }
      />
      <AutomationsTable automations={loading ? [] : data.automations.edges} />
    </>
  );
}
