import { ReactNode } from "react";
import styles from "./Separator.module.scss";

interface ISeperator {
  title: string | ReactNode;
}

// TODO: find a better name
export function Separator({ title }: ISeperator) {
  return (
    <div className={styles.separator}>
      <span className={styles.text}>{title}</span>
    </div>
  );
}
