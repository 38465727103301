import { gql } from "@apollo/client";

const META_PAGES = gql`
  query metaPages($adAccountId: String!) {
    metaPages(adAccountId: $adAccountId) {
      id
      name
      enabled
      metaPageId
      metaPageConnectedInstagramBusinessId
      Business {
        id
        name
        profilePicture
      }
    }
  }
`;

export default META_PAGES;
