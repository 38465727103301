import LogoutDropdown from "src/components/LogoutDropdown/LogoutDropdown";
import styles from "./OnboardingHeader.module.scss";
import classNames from "classnames";
import macroBlueLogo from "../../global/assets/macroBlueLogo.svg";
import macroMonochrome from "../../global/assets/macroMonochrome.svg";

interface IOnBoardingHeader {
  isDark?: boolean;
  className?: string;
}

export function OnboardingHeader({
  className,
  isDark = true,
}: IOnBoardingHeader) {
  return (
    <header className={classNames(styles.header, className)}>
      <div className={styles.logoContainer}>
        <img src={isDark ? macroBlueLogo : macroMonochrome} alt="macro" />
        <h2
          className={classNames(styles.pageTitle, {
            [styles.darkTitle]: isDark,
          })}
        >
          Account Setup
        </h2>
      </div>
      <div className={styles.logout}>
        <LogoutDropdown />
      </div>
    </header>
  );
}
