import AudienceCard from "src/components/AudienceItem/AudienceCard";
import { Button } from "src/components/Button/Button";
import Calendar from "react-calendar";
import { CampaignInput } from "../misc/createCampaignTypes";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import Modal from "src/components/Modal/Modal";
import format from "date-fns/format";
import styles from "./ScheduleModal.module.scss";
import { useState } from "react";
import scheduleEmail from "../assets/scheduleEmail.svg";

interface Props {
  activities: CampaignInput["Activities"];
  showScheduleModal: boolean;
  setShowScheduleModal: (i: boolean) => void;
  onPublish: (i: Date) => void;
}

// FIXME: use date-fns methods
const today = new Date();
today.setHours(0, 0, 0);
export function ScheduleModal({
  activities,
  showScheduleModal,
  setShowScheduleModal,
  onPublish,
}: Props) {
  const [selectedTime, setSelectedTime] = useState<{
    hours: string;
    minutes: string;
    meridian: string;
  }>({
    minutes: "0",
    hours: "0",
    meridian: "AM",
  });
  const [minDate, _] = useState(today);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  function onTimeChange(t: string) {
    let timeSplit = t.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];

    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = "PM";
    }
    setSelectedTime({
      hours,
      minutes,
      meridian,
    });
  }

  return (
    <Modal
      isOpen={showScheduleModal}
      onClose={() => {
        setShowScheduleModal(false);
      }}
      title="Schedule Activity"
      maxWidth="55.25rem"
      showDismiss={false}
      noContainer
      footer={{
        footerContent: (
          <p className={styles.footerText}>
            <span>
              <Info className={styles.infoIco} />
            </span>{" "}
            Activities will be schedule based on your selected time
          </p>
        ),
        footerActions: (
          <div className={styles.btnGrp}>
            <Button
              style="outline"
              color="subdued"
              size="small"
              onClick={() => setShowScheduleModal(false)}
            >
              Back
            </Button>
            <Button
              color="primary"
              size="small"
              disabled={selectedDate == null}
              onClick={() => {
                let newSelectedDate = selectedDate;
                const hours =
                  selectedTime.meridian === "PM"
                    ? Number(selectedTime.hours) + 12
                    : Number(selectedTime.hours);
                newSelectedDate.setHours(
                  Number(hours),
                  Number(selectedTime.minutes)
                );
                onPublish(newSelectedDate);
              }}
            >
              Publish
            </Button>
          </div>
        ),
      }}
    >
      <div className={styles.container}>
        <div className={styles.activityContainer}>
          <p className={styles.activityTitle}>Activity list</p>
          <div className={styles.options}>
            {activities.map((activity, index) => (
              <AudienceCard
                name="activities"
                onBlur={() => {}}
                checked={true}
                onChange={() => {}}
                image={scheduleEmail}
                title={activity.activityName}
                value={activity.id}
                subtitle={
                  <p>
                    {activity.scheduledAt
                      ? format(
                          new Date(activity.scheduledAt),
                          "dd-MM-yyyy HH:mm:ss"
                        )
                      : "Yet to be scheduled"}
                  </p>
                }
                key={index}
                noCheck={false}
                variant="small"
                noBorder
              />
            ))}
          </div>
        </div>
        <div className={styles.calContainer}>
          <Calendar
            value={selectedDate}
            minDate={minDate}
            onChange={(value: Date) => setSelectedDate(value)}
            className={styles.calendar}
          />
          <div className={styles.timeContainer}>
            <div>
              <p className={styles.timeHeading}>Select Schedule Time</p>
              <p className={styles.timeDescription}>
                Select time slot when activity publish
              </p>
            </div>
            <div>
              <input
                type="time"
                name="time-input"
                id="time-input"
                className={styles.timePicker}
                onChange={(e) => onTimeChange(e.target.value)}
              />
              <p className={styles.selectedTime}>
                {selectedTime.hours}:{selectedTime.minutes}{" "}
                {selectedTime.meridian}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
