import { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { Organization } from "../../../graphql/generated/schema";
import { SectionHeading } from "../../global/components/SectionHeading";
import styles from "./DeleteOrganization.module.scss";
import { DeleteOrganizationAlert } from "./DeleteOrganizationAlert";

export function DeleteOrganization({
  organization,
}: {
  organization: Organization;
}) {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  return (
    <div>
      <SectionHeading
        heading={"Delete Orgainzation"}
        description={
          "If you want to delete your organization and all of it’s information, you can do so, just you need to verify yourself."
        }
      />
      <DeleteOrganizationAlert
        organization={organization}
        isOpen={showDeleteConfirmationModal}
        onClose={function (): void {
          setShowDeleteConfirmationModal(false);
        }}
        onDelete={function (): void {
          // TODO: Add intercom support here @ragzor
        }}
      />
      <div className={styles.wrapper}>
        <Button
          style="solid"
          color="danger"
          size="micro"
          onClick={() => setShowDeleteConfirmationModal(true)}
        >
          Delete this organization
        </Button>
      </div>
    </div>
  );
}
