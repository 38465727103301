import styles from "./SettingsPricingHeader.module.scss";
import { ProductToggle } from "./ProductToggle";

interface Props {
  selectedProduct: string;
  setSelectedProduct: (i: "ENGAGE" | "GROW") => void;
  setIsEnterprisePlan: (i: boolean) => void;
}

export function SettingsPricingHeader({
  selectedProduct,
  setSelectedProduct,
  setIsEnterprisePlan,
}: Props) {
  return (
    <div className={styles.header}>
      <div className={styles.headerText}>
        <h2 className={styles.heading}>
          Take your marketing to the next level
        </h2>
        <p className={styles.description}>
          Join the customer platform proven to drive repeat sales, increase
          retention, and provide higher lifetime value.
        </p>
      </div>
      <ProductToggle
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setIsEnterprisePlan={setIsEnterprisePlan}
      />
    </div>
  );
}
