import { useState } from "react";
import styles from "./PageWelcomeMessageTemplate.module.scss";
import { ReactComponent as PencilIcon } from "@material-design-icons/svg/outlined/edit.svg";
import { Button } from "src/components/Button/Button";
import { getIn, useFormikContext } from "formik";
import {
  CampaignInput,
  PageWelcomeMessageInput,
} from "../misc/createCampaignTypes";
import { EngagementMessageTemplateEditor } from "./EngagementMessageTemplateEditor";

export function PageWelcomeMessageTemplate({ adKey }: { adKey: string }) {
  const [isPageWelcomeMessageOpen, setIsPageWelcomeMessageOpen] =
    useState(false);

  const { values } = useFormikContext<CampaignInput>();
  const selectedActivity = values.Activities[values.selectedActivityIndex];
  const pageWelcomeMessageKey = `${adKey}.PageWelcomeMessage`;
  const pageWelcomeMessage: PageWelcomeMessageInput = getIn(
    values,
    pageWelcomeMessageKey
  );

  const messageOption = pageWelcomeMessage.MessageOptions?.[0]?.text ?? "";
  const greeting = pageWelcomeMessage.greeting ?? "";
  const metaPurpose = selectedActivity.metaPurpose;

  return (
    <>
      <div className={styles.pageWelcomeMessageTemplateSection}>
        <h2 className={styles.sectionHeading}>Message Template</h2>
        <p className={styles.sectionSubheading}>
          Suggest prompts for people to tap to encourage them to start
          conversations with your business
        </p>

        <div className={styles.messageTemplate}>
          <div className={styles.greetingSection}>
            <h4 className={styles.templateHeading}>Greetings</h4>
            <p className={styles.templateText}>{greeting}</p>
          </div>
          <div className={styles.questionSection}>
            <h4 className={styles.templateHeading}>Questions and responses</h4>
            <p className={styles.templateText}>{messageOption}</p>
          </div>
        </div>

        <div className={styles.buttonSection}>
          <Button
            onClick={() => {
              setIsPageWelcomeMessageOpen(true);
            }}
            color="subdued"
            style="outline"
            size="small"
          >
            <span className={styles.buttonWithIcon}>
              Edit Template
              <PencilIcon fill="currentColor" width={16} height={16} />
            </span>
          </Button>
        </div>
      </div>
      <EngagementMessageTemplateEditor
        isOpen={isPageWelcomeMessageOpen}
        onClose={() => {
          setIsPageWelcomeMessageOpen(false);
        }}
        baseKey={pageWelcomeMessageKey}
      />
    </>
  );
}
