import classNames from "classnames";
import { useFormikContext } from "formik";
import { ReactComponent as PlusIcon } from "@material-symbols/svg-600/rounded/add.svg";
import { ReactComponent as CloseIcon } from "@material-symbols/svg-600/rounded/close.svg";
import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import styles from "./SelectAdSwitcher.module.scss";
import { CatalogueProductImage } from "../../global/components/CatalogueProductImage";

export function SelectedAdSwitcher({ onCreateAd }: { onCreateAd: () => void }) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();
  const selectedAdIndex = values.selectedAdIndex ?? 0;
  const ads = values.Ads ?? [];

  return (
    <div className={styles.switcher}>
      <div className={styles.switcherList}>
        {ads.map((ad, adIndex) => (
          <AdThumbnail
            onRemoveAd={(adIndex) => {
              setFieldValue(
                "Ads",
                values.Ads.filter((a, index) => index !== adIndex),
              );
            }}
            adIndex={adIndex}
            ad={ad}
            selectedAdIndex={selectedAdIndex}
          />
        ))}

        <button
          onClick={onCreateAd}
          className={styles.addNewButton}
          type="button"
        >
          <PlusIcon fill="currentColor" width={24} height={24} />
        </button>
      </div>
    </div>
  );
}

interface IAdThumbnail {
  adIndex: number;
  ad: AdInputV2;
  selectedAdIndex: number;
  onRemoveAd: (adIndex: number) => void;
}

function AdThumbnail({
  adIndex,
  ad,
  selectedAdIndex,
  onRemoveAd,
}: IAdThumbnail) {
  const { errors, touched, setFieldValue } =
    useFormikContext<ActivityInputV2>();
  const postAsset = ad.LineItems[0]?.PostAsset;
  const isExistingPostTemplate = ad.adType === "EXISTING_POST";
  const existingPost = ad.ExistingPost;
  const isCatalogue = ad.adType === "CATALOG";
  const hasError = errors?.Ads?.[adIndex] && touched?.Ads?.[adIndex];

  return (
    <div className={styles.wrapper}>
      <button
        onClick={() => onRemoveAd(adIndex)}
        className={styles.closeBtn}
        type="button"
      >
        <CloseIcon width={12} height={12} />
      </button>
      <button
        type="button"
        className={styles.switcherItem}
        onClick={() => {
          setFieldValue("selectedAdIndex", adIndex);
        }}
      >
        <span
          className={classNames(styles.adPreviewContainer, {
            [styles.adPreviewSelected]: selectedAdIndex === adIndex,
            [styles.adPreviewError]: hasError,
          })}
        >
          {isCatalogue ? (
            <CatalogueProductImage catalogueId={ad.catalogueId} />
          ) : isExistingPostTemplate ? (
            // TODO: handle carousel album
            existingPost?.type === "IMAGE" ? (
              <img src={existingPost.url} alt="post" width={40} height={40} />
            ) : (
              <video width={40} height={40} loop muted>
                <source src={existingPost.url} />
              </video>
            )
          ) : postAsset.contentType === "VIDEO" ? (
            <video className={classNames(styles.adPreview)} muted loop autoPlay>
              <source src={postAsset.uri} />
            </video>
          ) : (
            <img
              className={classNames(styles.adPreview)}
              alt="asset "
              src={postAsset.uri}
              width={40}
              height={40}
            />
          )}
        </span>
        <p
          className={classNames(styles.adName, {
            [styles.adNameSelected]: selectedAdIndex === adIndex,
            [styles.adNameError]: hasError,
          })}
        >
          Ad {adIndex + 1}
        </p>
      </button>
    </div>
  );
}
