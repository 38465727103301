import { useSearchParams } from "react-router-dom";
import { useGetBillingQuery } from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { CheckoutForm } from "../components/CheckoutForm";
import { BillingDetails } from "../components/BillingDetails";
import styles from "./Checkout.module.scss";
import { OnboardingHeader } from "src/modules/global/components/OnboardingHeader";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function Checkout() {
  const [params] = useSearchParams();
  const { data, loading, error } = useGetBillingQuery();

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div>
      <OnboardingHeader isDark={true} className={styles.header} />
      <div className={styles.container}>
        <div className={styles.formSection}>
          {/* <h2 className={styles.title}>Setup your Macro Billing</h2>
          <p className={styles.description}>
            Share your billing address for your Macro subscription activation.
            All your invoicing will be sent to your email ID
            <span className={styles.email}> abc@codeword.tech</span> 
          </p> */}
          <h2 className={styles.title}>Payment Details</h2>
          <p className={styles.subtitle}>
            Add your Tax Details so that you can avail the tax benefits if
            applicable on you.
          </p>
          <CheckoutForm paymentIntentSecret={params.get("paymentIntent")} />
        </div>
        <div className={styles.billDetails}>
          <BillingDetails billing={data?.billing} />
        </div>
      </div>
    </div>
  );
}
