import { AudienceTypeEnum } from "src/graphql/generated/schema";
import { ReactComponent as AudienceGroup } from "@material-symbols/svg-400/rounded/groups_2-fill.svg";
import { ReactComponent as Bookmark } from "@material-design-icons/svg/filled/bookmark.svg";
import { ReactComponent as DashboardCustomize } from "@material-design-icons/svg/filled/dashboard_customize.svg";
import { ReactComponent as SettingsAccountBox } from "@material-symbols/svg-400/rounded/settings_account_box-fill.svg";

export function AudienceIcon({
  audience,
  width = 20,
  height = 20,
}: {
  audience: { __typename?: string; type?: AudienceTypeEnum };
  width?: number;
  height?: number;
}) {
  if (!audience.__typename || audience.__typename === "Audience") {
    if (audience.type === "LOOKALIKE") {
      return <AudienceGroup width={width} height={height} />;
    }
    if (audience.type === "CUSTOMER_LIST") {
      return <Bookmark width={width} height={height} />;
    }

    if (audience.type === "PLATFORM_BASED") {
      return <DashboardCustomize width={width} height={height} />;
    }
  }

  if (audience.__typename === "MetaAudienceSet") {
    return <SettingsAccountBox width={width} height={height} />;
  }
}
