import { ReactNode } from "react";
import { ReactComponent as CheckIcon } from "@material-symbols/svg-600/rounded/check.svg";
import styles from "./SelectableFilter.module.scss";
import classNames from "classnames";

export interface ISelectableFilter {
  checked: boolean;
  value: string;
  label: ReactNode;
  onChange: (value: string) => void;
}

export function SelectableFilter({
  label,
  checked,
  value,
  onChange,
}: ISelectableFilter) {
  return (
    <button
      onClick={() => onChange(value)}
      className={classNames(styles.selectableButton, {
        [styles.selected]: checked,
      })}
      type="button"
    >
      <span
        className={classNames(styles.check, {
          [styles.hideCheck]: !checked,
        })}
      >
        <CheckIcon
          strokeWidth={100}
          width={16}
          height={16}
          fill="currentColor"
        />
      </span>
      <span className={styles.label}>{label}</span>
    </button>
  );
}
