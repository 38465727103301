import styles from "./UpgradePlanFeatCard.module.scss";

export function UpgradePlanFeatCard({
  image,
  icon,
  title,
  subtitle,
  primaryColor = "engage",
}) {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img src={image} alt={title} className={styles.image} />
      </div>
      <div className={styles.text}>
        <div className={styles.titleWrapper}>
          <span className={styles[primaryColor]}>{icon}</span>
          <span className={styles.title}>{title}</span>
        </div>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    </div>
  );
}
