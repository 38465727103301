import { toast } from "react-hot-toast";
import {
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from "src/modules/global/misc/maxSizes";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { FileUploadButton } from "../../global/components/FileUpload/FileUploadButton";
import { AssetListingTableLoader } from "../components/AssetListingTable/AssetListingTableLoader";
import styles from "./AssetsListingPage.module.scss";

export default function AssetsListingPage() {
  return (
    <div>
      <PageHeader
        title={"Media Center"}
        actions={
          <>
            <FileUploadButton
              onUpload={() => {
                toast.success("Created Asset successfully");
              }}
              maxVideoFileSizeInBytes={MAX_FILE_SIZE_VIDEO}
              maxImageFileSizeInBytes={MAX_FILE_SIZE_IMAGE}
            />
          </>
        }
      />

      <div className={styles.container}>
        <AssetListingTableLoader />
      </div>
    </div>
  );
}
