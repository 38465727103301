import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { IconButton } from "src/components/IconButton/IconButton";
import styles from "./AutomationSidebarHeader.module.scss";

interface Props {
  title: string | React.ReactNode;
  onBack?: () => void;
  action?: (i: any) => void;
  children?: React.ReactNode;
}

export function AutomationSidebarHeader({
  title,
  onBack,
  action,
  children,
}: Props) {
  return (
    <div className={styles.header}>
      {onBack && (
        <IconButton
          icon={<ArrowBack />}
          onClick={onBack}
          style="plain"
          className={styles.backBtn}
        />
      )}
      <h2 className={styles.title}>{title}</h2>
      {children && children}
    </div>
  );
}
