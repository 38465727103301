import { useEffect } from "react";
import Select from "../../../components/Select/Select";
import {
  MetaProductCatalogueQuery,
  useMetaProductCatalogueLazyQuery,
} from "../../../graphql/generated/schema";
import SelectV2 from "src/components/SelectV2/SelectV2";

interface IMetaCatalogueProductSetSelector {
  catalogueId: string;
  value: string;
  onChange: (
    i: MetaProductCatalogueQuery["metaCatalogue"]["ProductSets"][0],
  ) => void;
}

export function MetaCatalogueProductSetSelector({
  catalogueId,
  value,
  onChange,
}: IMetaCatalogueProductSetSelector) {
  const [getMetaProductCatalogueFunc, { data, loading, error: _err }] =
    useMetaProductCatalogueLazyQuery();

  useEffect(() => {
    getMetaProductCatalogueFunc({
      variables: {
        id: catalogueId,
      },
    });
  }, [catalogueId, getMetaProductCatalogueFunc]);

  return (
    <SelectV2
      disabled={loading}
      label="Product Set"
      placeholder={loading ? "Loading Product Set" : "Select Product Set"}
      value={value}
      options={
        data && data.metaCatalogue
          ? data.metaCatalogue.ProductSets.map((v) => ({
              label: v.name,
              value: v.id,
            }))
          : []
      }
      onChange={(id) => {
        const selectedProductSet = data.metaCatalogue.ProductSets.find(
          (p) => p.id === id,
        );
        onChange(selectedProductSet);
      }}
    />
  );
}
