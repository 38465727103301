export const durationOptions = [
  // { label: "1 hour", value: 3600 },
  // { label: "1 day", value: 86400 },
  // { label: "1 week", value: 604800 },
  { label: "30 Days", value: 2592000 },
  { label: "90 Days", value: 7776000 },
  { label: "180 Days", value: 15552000 },
  // { label: "9 months", value: 23328000 },
  // { label: "1 year", value: 31536000 },
];
