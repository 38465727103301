import { useAudiencesLazyQuery } from "src/graphql/generated/schema";
import styles from "./AudienceSelectionLoader.module.scss";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import { useEffect, useMemo } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";
import { AudiencesSelectionTable } from "./AudiencesSelectionTable";
import { useFormikContext } from "formik";
import { AudienceSetCreateType } from "../misc/audienceSetCreateType";

export function AudienceSelectionLoader({
  searchInputValue,
  onShowSelection,
  shouldShowSelection,
}: {
  searchInputValue: string;
  shouldShowSelection: boolean;
  onShowSelection: () => void;
}) {
  const { values } = useFormikContext<AudienceSetCreateType>();
  const [audiencesFn, { called, data, loading, error }] =
    useAudiencesLazyQuery();
  const audiencesFnDebounced = useDebouncedCallback(audiencesFn, 500);

  useEffect(() => {
    audiencesFnDebounced({
      variables: {
        sortBy: { direction: "DESC", field: "CREATED_AT" },
        filter: {
          name: searchInputValue,
          adAccountId: values.adAccountId,
          take: 10,
        },
      },
    });
  }, [searchInputValue]);

  const audiences = useMemo(
    () => (data ? data.audiences.edges.map((edge) => edge.node) : []),
    [data],
  );

  if (loading || !called) {
    return <Spinner height={350} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <AudiencesSelectionTable
      shouldShowSelection={shouldShowSelection}
      onShowSelection={onShowSelection}
      audiences={audiences}
    />
  );
}
