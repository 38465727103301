import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useMetaIntegrationDefaultSettingsQuery } from "src/graphql/generated/schema";
import { AudienceNameAndAccountFormState } from "../misc/audienceWorkshop";
import styles from "./AudienceUseDefaultSettingsCheckbox.module.scss";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-600/rounded/info.svg";
import { AudienceFacebookSettingsModal } from "./AudienceFacebookSettingsModal";
import classNames from "classnames";

export function AudienceUseDefaultSettingsCheckbox() {
  const { data } = useMetaIntegrationDefaultSettingsQuery();
  const [isChangeAccountsModalOpen, setIsChangeAccountsModalOpen] =
    useState(false);
  const { values, setValues } =
    useFormikContext<AudienceNameAndAccountFormState>();

  useEffect(() => {
    if (!data) return;
    if (!data.metaIntegrationDefaultSettings) return;
    // if already set
    if (values.adAccountId) return;

    if (data) {
      setValues({
        ...values,
        adAccountId: data.metaIntegrationDefaultSettings.metaAdAccountId,
        pageId: data.metaIntegrationDefaultSettings.metaPageId,
        instagramAccountId:
          data.metaIntegrationDefaultSettings.MetaInstagramAccount.id,
        pixelId: data.metaIntegrationDefaultSettings.metaPixelId,
        useDefaultMetaAccounts: true,
      });
    }
  }, [data, setValues, values]);

  return (
    <div className={styles.checkboxWrapper}>
      <span className={styles.checkboxLabel}>
        Using Default Account Settings <InfoIcon width={16} height={16} />
      </span>

      <AudienceFacebookSettingsModal
        initialFormValues={values}
        handleSubmit={(formState) => {
          setValues({
            ...values,
            ...formState,
            useDefaultMetaAccounts: false,
          });
          setIsChangeAccountsModalOpen(false);
        }}
        isOpen={isChangeAccountsModalOpen}
        onClose={() => {
          setIsChangeAccountsModalOpen(false);
        }}
      />
      <button
        onClick={() => {
          setIsChangeAccountsModalOpen(true);
        }}
        className={classNames(styles.changeSettingsButton)}
        type="button"
      >
        Change Settings?
      </button>
    </div>
  );
}
