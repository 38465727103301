import { SmartphoneShell } from "src/modules/global/components/SmartphoneShell";
import { ReactComponent as AppleLineIcon } from "../assets/appleLine.svg";
import { ReactComponent as AndroidLineIcon } from "../assets/androidLine.svg";
import classNames from "classnames";
import styles from "./WhatsappMessageTemplatePreviewSection.module.scss";
import { useState } from "react";
import { TabsV2 } from "src/components/TabsV2/TabsV2";

export function WhatsappMessageTemplatePreviewSection({
  metaPageName,
  profilePicture,
}) {
  const [selectedTab, setSelectedTab] = useState<"ANDROID" | "IOS">("IOS");

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <h2 className={styles.heading}>Message Preview</h2>
        <p className={styles.subheading}>
          Change placement by hovering on preview
        </p>
      </div>

      <div className={styles.tabWrapper}>
        <TabsV2
          tabs={[
            {
              label: "IOS",
              icon: (
                <AppleLineIcon fill="currentColor" width={18} height={18} />
              ),
              value: "IOS",
            },
            {
              icon: (
                <AndroidLineIcon fill="currentColor" width={18} height={18} />
              ),
              label: "Android",
              value: "ANDROID",
            },
          ]}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />
      </div>

      <div className={styles.preview}>
        <SmartphoneShell screenSize="31rem">Hello world</SmartphoneShell>
      </div>
    </div>
  );
}
