import { Label } from "src/components/Label/Label";
import instagramBadge from "../assets/instagramBadge.svg";
import messengerBadge from "../assets/messengerBadge.svg";
import whatsappBadge from "../assets/whatsappBadge.svg";
import { getIn, useFormikContext } from "formik";
import { CampaignInput } from "../misc/createCampaignTypes";
import styles from "./EngagementMessagingPlatforms.module.scss";
import classNames from "classnames";
import { RadioGroup } from "@headlessui/react";
import { Radio } from "src/components/Radio/Radio";
import { FormikError } from "src/components/FormikError/FormikError";

const messagingPlatforms = [
  {
    value: "INSTAGRAM_DIRECT",
    badge: instagramBadge,
    imageValueField: "metaPagePicture",
    title: "Instagram",
    activityValueField: "instagramPageName",
  },
  {
    value: "WHATSAPP",
    badge: whatsappBadge,
    imageValueField: "metaPagePicture",
    title: "Whatsapp",
    activityValueField: "",
  },
  {
    value: "MESSENGER",
    badge: messengerBadge,
    imageValueField: "metaPagePicture",
    title: "Messenger",
    disabled: true,
    activityValueField: "metaPageName",
  },
];

export function EngagementMessagingPlatforms({ baseKey }: { baseKey: string }) {
  const {
    values,
    touched,
    errors,
    setFieldTouched,
    submitCount,
    setFieldValue,
  } = useFormikContext<CampaignInput>();
  const fieldKey = `${baseKey}.engagementDestinations`;
  const selectedActivity = values.Activities[values.selectedActivityIndex];
  const fieldValue = getIn(values, fieldKey) ?? [];

  function handleChange(newValues: string) {
    setFieldValue(fieldKey, [newValues]);
    setFieldTouched(fieldKey);
  }

  const value = fieldValue?.[0];

  return (
    <div className={styles.section}>
      <Label label="Choose at least one destination where you are available to chat. " />
      <div>
        <RadioGroup
          className={styles.optionList}
          value={value}
          onChange={(value) => {
            handleChange(value);
          }}
        >
          {messagingPlatforms.map((platform, index, arr) => (
            <RadioGroup.Option
              disabled={platform.disabled}
              value={platform.value}
            >
              <div
                className={classNames(styles.optionItem, {
                  [styles.optionWithSeparator]: index < arr.length - 1,
                  [styles.optionDisabled]: platform.disabled,
                })}
              >
                <div className={styles.optionImageWrapper}>
                  <img
                    className={styles.optionImage}
                    src={selectedActivity[platform.imageValueField]}
                    width={32}
                    height={32}
                  />
                  <img
                    src={platform.badge}
                    className={styles.optionBadge}
                    width={16}
                    height={16}
                    alt={`Logo of ${platform.title}`}
                  />
                </div>
                <div className={styles.optionInfo}>
                  <p className={styles.optionTitle}>{platform.title}</p>
                  <p className={styles.optionDescription}>
                    {selectedActivity[platform.activityValueField]}
                  </p>
                </div>
                <div className={styles.optionCheckbox}>
                  <Radio
                    disabled={platform.disabled}
                    value={platform.value}
                    onChange={() => {
                      handleChange(platform.value);
                    }}
                    checked={value === platform.value}
                  />
                </div>
              </div>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        <FormikError
          formikError={errors}
          touched={touched}
          submitCount={submitCount}
          fieldName={fieldKey}
        />
      </div>
    </div>
  );
}
