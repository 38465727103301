import { Formik, Form } from "formik";
import Card from "src/components/Card/Card";
import Stepper from "src/components/Stepper/Stepper";
import { AudienceSetCreateType } from "../misc/audienceSetCreateType";
import styles from "./ReviewAudienceSetForm.module.scss";
import { useContext } from "react";
import { WizardContext } from "src/modules/global/misc/WizardContext";
import { metaLanguages } from "../misc/metaLanguages";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Button } from "src/components/Button/Button";
import { formatNumber } from "src/lib/formatNumber";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import {
  MetaAudienceSetCreateInput,
  useAudienceSetEstimateQuery,
  useMetaAudienceSetCreateMutation,
} from "src/graphql/generated/schema";
import { AUDIENCE_SETS } from "src/graphql/audienceSets";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { generateRangeString } from "src/lib/generateRangeString";
import Skeleton from "react-loading-skeleton";

function metaAudienceInputFromAudienceSetCreateType(
  values: AudienceSetCreateType,
) {
  return {
    adAccountId: values.adAccountId,
    audienceIds: Object.entries(values.audiences)
      .filter(([id, payload]) => payload.value === "INCLUDE")
      .map(([id]) => id),
    excludeAudienceIds: Object.entries(values.audiences)
      .filter(([id, payload]) => payload.value === "EXCLUDE")
      .map(([id]) => id),
    gender: values.gender,
    name: values.name,
    // N/A
    description: "",
    interestRelation: "AND",
    maxAge: parseInt(values.maxAge),
    minAge: parseInt(values.minAge),
    Locations: values.locations.map((l) => ({
      metaLocationId: l.id,
      countryCode: l.countryCode,
      regionId: l.regionId,
      region: l.region,
      countryName: l.countryName,
      name: l.name,
      type: l.type,
    })),
    languages: values.languages,
    ExcludeInterests: Object.values(values.interests)
      .filter((i) => i.value === "EXCLUDE")
      .map((i) => ({
        metaInterestId: i.id,
        audienceSizeLowerBound: i.audienceSizeLowerBound,
        audienceSizeUpperBound: i.audienceSizeUpperBound,
        name: i.name,
        path: i.path,
        type: i.type,
      })),
    Interests: Object.values(values.interests)
      .filter((i) => i.value === "INCLUDE")
      .map((i) => ({
        metaInterestId: i.id,
        audienceSizeLowerBound: i.audienceSizeLowerBound,
        audienceSizeUpperBound: i.audienceSizeUpperBound,
        name: i.name,
        path: i.path,
        type: i.type,
      })),
  };
}

export function ReviewAudienceSetForm() {
  // const [metaAudienceSetCreate] = useMetaAudienceSetCreateMutation();
  // const { currentNode, breadcrumbs, data, markNodeAsComplete, goBack } =
  //   useContext(WizardContext);
  // const navigate = useNavigate();
  // const estimateInput = metaAudienceInputFromAudienceSetCreateType(data);
  // const {
  //   data: estimateData,
  //   loading: estimateLoading,
  //   error,
  // } = useAudienceSetEstimateQuery({
  //   variables: {
  //     input: estimateInput,
  //   },
  // });

  return (
    // just in case we need to edit values here
    // <Formik
    //   initialValues={data}
    //   onSubmit={async (values: AudienceSetCreateType) => {
    //     try {
    //       const input = metaAudienceInputFromAudienceSetCreateType(values);
    //       await metaAudienceSetCreate({
    //         refetchQueries: [AUDIENCE_SETS],
    //         awaitRefetchQueries: true,
    //         variables: {
    //           input: input,
    //         },
    //       });

    //       toast.success("Audience Set created successfully");
    //       navigate("/audiences");
    //     } catch (e) {
    //       toast.error(`Error: ${e.message}`);
    //     }
    //   }}
    // >
    //   {({ values, isSubmitting, handleSubmit }) => (
    //     <Form onSubmit={handleSubmit}>
    //       <Stepper
    //         activeStepIndex={breadcrumbs.findIndex((b) => b.isActive)}
    //         steps={breadcrumbs}
    //       />
    //       <div className={styles.layout}>
    //         <Card>
    //           <div className={styles.sectionHeader}>
    //             <h1 className={styles.sectionHeading}>
    //               Review your Audience Set
    //             </h1>
    //             <p className={styles.sectionSubheader}>
    //               Estimated Audience Size:{" "}
    //               {estimateLoading ? (
    //                 <Skeleton width={110} height={30} />
    //               ) : (
    //                 generateRangeString(
    //                   estimateData?.audienceSetEstimate?.lowerBoundCount,
    //                   estimateData?.audienceSetEstimate?.upperBoundCount,
    //                 )
    //               )}
    //             </p>
    //           </div>
    //           <div className={styles.formSection}>
    //             <span className={styles.optionHeading}>Audience Set Name</span>
    //             <div className={styles.optionValue}>{values.name}</div>
    //           </div>
    //           <div className={styles.formSection}>
    //             <span className={styles.optionHeading}>Discover Interests</span>
    //             {Object.values(values.interests)
    //               .filter((i: any) => i.value === "INCLUDE")
    //               .map((interest: any) => (
    //                 <div className={styles.interestItem}>
    //                   <span className={styles.interestTitle}>
    //                     {interest.name}
    //                   </span>
    //                   <span className={styles.interestType}>
    //                     Type: {snakeCaseToTitleCase(interest.type)}
    //                   </span>
    //                   <span className={styles.interestSize}>
    //                     Est. Size:{" "}
    //                     {formatNumber(interest.audienceSizeLowerBound)} -{" "}
    //                     {formatNumber(interest.audienceSizeUpperBound)}
    //                   </span>
    //                 </div>
    //               ))}
    //           </div>
    //           <div className={styles.formSection}>
    //             <span className={styles.optionHeading}>Audiences</span>
    //             {Object.values(values.audiences)
    //               .filter((i: any) => i.value === "INCLUDE")
    //               .map((audience: any) => (
    //                 <div className={styles.interestItem}>
    //                   <span className={styles.interestTitle}>
    //                     {audience.name}
    //                   </span>
    //                   <span className={styles.interestType}>
    //                     Type: {snakeCaseToTitleCase(audience.type)}
    //                   </span>
    //                   <span className={styles.interestSize}>-</span>
    //                 </div>
    //               ))}
    //           </div>
    //           <div className={styles.formSection}>
    //             <span className={styles.optionHeading}>
    //               Demographics Details
    //             </span>
    //             <div className={styles.demographicDetails}>
    //               <span className={styles.optionValue}>
    //                 Location: {values.locations.map((l) => l.name).join(",")}
    //               </span>
    //               <span className={styles.optionValue}>
    //                 Age: {values.minAge} - {values.maxAge}
    //               </span>
    //               <span className={styles.optionValue}>
    //                 Languages:{" "}
    //                 {values.languages
    //                   .map(
    //                     (l) =>
    //                       metaLanguages.find(
    //                         (m) => m.auxiliaryData.key === parseInt(l),
    //                       ).name,
    //                   )
    //                   .join(",")}
    //               </span>
    //             </div>
    //           </div>

    //           <div className={styles.formSection}>
    //             <span className={styles.optionHeading}>Exclude</span>
    //             {Object.values(values.interests)
    //               .filter((i: any) => i.value === "EXCLUDE")
    //               .map((interest: any) => (
    //                 <div className={styles.interestItem}>
    //                   <span className={styles.interestTitle}>
    //                     {interest.name}
    //                   </span>
    //                   <span className={styles.interestType}>
    //                     Type: {snakeCaseToTitleCase(interest.type)}
    //                   </span>
    //                   <span className={styles.interestSize}>
    //                     Est. Size:{" "}
    //                     {formatNumber(interest.audienceSizeLowerBound)} -{" "}
    //                     {formatNumber(interest.audienceSizeUpperBound)}
    //                   </span>
    //                 </div>
    //               ))}
    //           </div>
    //           <div className={styles.formSection}>
    //             <span className={styles.optionHeading}>Exclude Audiences</span>
    //             {Object.values(values.audiences)
    //               .filter((i: any) => i.value === "EXCLUDE")
    //               .map((audience: any) => (
    //                 <div className={styles.interestItem}>
    //                   <span className={styles.interestTitle}>
    //                     {audience.name}
    //                   </span>
    //                   <span className={styles.interestType}>
    //                     Type: {snakeCaseToTitleCase(audience.type)}
    //                   </span>
    //                   <span className={styles.interestSize}>-</span>
    //                 </div>
    //               ))}
    //           </div>
    //         </Card>
    //       </div>
    //       <StickyFooter
    //         actions={
    //           <div className={styles.buttonGroup}>
    //             <Button
    //               onClick={() => goBack(values)}
    //               type="button"
    //               style="outline"
    //               color="subdued"
    //             >
    //               Back
    //             </Button>
    //             <Button type="submit" loading={isSubmitting}>
    //               Create Audience Set
    //             </Button>
    //           </div>
    //         }
    //       />
    //     </Form>
    //   )}
    // </Formik>
    null
  );
}
