import Select from "src/components/Select/Select";
import {
  ButtonType,
  WhatsappFormInput,
} from "src/pages/CreateTemplate/CreateMessageTemplateTypes";
import { WhatsappDetailSection } from "../WhatsappDetailSection/WhatsappDetailSection";
import { CtasInput } from "./CtasInput";
import { QuickRepliesInput } from "./QuickRepliesInput";

const buttonOptions: {
  label: string;
  value: ButtonType;
}[] = [
  {
    label: "None",
    value: "NONE",
  },
  {
    label: "Call to action",
    value: "CTA",
  },
  {
    label: "Quick Reply",
    value: "QUICK_REPLY",
  },
];

export const buttonType = [
  {
    label: "URL",
    value: "URL",
  },
  {
    label: "Ph. No.",
    value: "PHONE",
  },
];

interface IWhatsappTemplateButtons {
  values: WhatsappFormInput;
  setFieldValue: (
    fieldName: string,
    value: any,
    shouldValidate?: boolean
  ) => void;
}

export function WhatsappTemplateButtons({
  values,
  setFieldValue,
}: IWhatsappTemplateButtons) {
  return (
    <WhatsappDetailSection title="Buttons">
      <Select
        options={buttonOptions}
        value={values?.buttons?.type}
        onChange={(value) => {
          setFieldValue("buttons.type", value);
          if (value === "CTA") {
            setFieldValue("buttons.ctas", []);
            return;
          }
          if (value === "QUICK_REPLY") {
            setFieldValue("buttons.quickReplies", []);
            return;
          }
        }}
      />
      <div>
        {values?.buttons?.type === "CTA" && <CtasInput values={values} />}
        {values?.buttons?.type === "QUICK_REPLY" && (
          <QuickRepliesInput values={values} />
        )}
      </div>
    </WhatsappDetailSection>
  );
}
