import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/expand_more.svg";
import { Label } from "../Label/Label";
import classNames from "classnames";
import styles from "./Select.module.scss";
import { Link } from "react-router-dom";

interface Option {
  label: string;
  value: string | number;
  icon?: string;
  disabled?: boolean;
}

interface OptionWithAction extends Omit<Option, "value" | "icon"> {
  onClick: () => void;
  icon?: React.ReactNode;
}

export interface ISelect {
  options: Option[];
  optionsFooter?: OptionWithAction;
  emptyState?: {
    title: string;
    description: string;
    action?: React.ReactNode;
    to?: string;
  };
  label?: React.ReactNode;
  icon?: React.ReactNode;
  borderRadius?: string;
  //for multiple selection purpose
  value: string | number | string[];
  onChange: (i: any) => void;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  status?: { message: string; type: "info" | "error" | "warning" };
  placeholder?: string;
  name?: string;
  multiple?: boolean;
  showScrollAfter?: number;
  btnClass?: string;
  wrapperClass?: string;
  hideArrow?: boolean;
}

/**
 *
 * @deprecated Use SelectV2
 */
export default function Select(props: ISelect) {
  let {
    options,
    optionsFooter,
    label,
    value,
    icon,
    onChange,
    disabled,
    status,
    borderRadius = "6px",
    onBlur,
    name,
    placeholder,
    multiple,
    showScrollAfter = 6,
    hideArrow = false,
    btnClass,
    wrapperClass,
    emptyState = {
      title: "Items not found",
      description: "No items available to select",
    },
  } = props;

  // if (options.length === 0) {
  //   options = [{ label: "", value: "" }];
  // }

  // if selector is multiple then find all options to be highlighted
  // const optionForValue = options.find((o) => o.value === value);
  // const selectedOption = optionForValue ? optionForValue : null;
  const optionForValue: Option[] | Option = multiple
    ? options.filter((o) => (value as any[]).includes(o.value))
    : options.find((o) => o.value === value);
  const selectedOption = optionForValue ? optionForValue : null;

  const isEmptyList = options.length === 0;

  return (
    <div className={classNames(styles.wrapper, wrapperClass)}>
      {label && <Label label={label} />}
      <Listbox
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled}
        multiple={multiple}
      >
        <div className={styles.listBox}>
          <Listbox.Button
            style={{ borderRadius }}
            className={classNames(styles.listboxBtn, btnClass, {
              [styles.listboxBtnDisabled]: disabled,
            })}
          >
            <span className={styles.btnLabel}>
              <span className={styles.icon}>{icon}</span>
              <span>
                {(multiple && (selectedOption as Option[]).length > 0
                  ? `${placeholder} (${(selectedOption as Option[])?.length})`
                  : (selectedOption as Option)?.label) || placeholder}
              </span>
            </span>
            {!hideArrow && (
              <span className={styles.chevronDown}>
                <ArrowDown width={16} height={16} fill="#C9CCCF" />
              </span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave={styles.leave}
            leaveFrom={styles.leaveFrom}
            leaveTo={styles.leaveTo}
          >
            <Listbox.Options
              onBlur={onBlur}
              className={classNames(styles.listContainer, {
                [styles.noScroll]: options.length <= showScrollAfter,
                [styles.hasScroll]: options.length > showScrollAfter,
              })}
              style={{ maxHeight: `${showScrollAfter * 50.53}px` }}
            >
              {isEmptyList ? (
                <SelectEmptyState
                  title={emptyState.title}
                  description={emptyState.description}
                  action={emptyState.action}
                  to={emptyState.to}
                />
              ) : (
                options.map((item, idx) => (
                  <ListboxOption key={idx} item={item} />
                ))
              )}

              {optionsFooter && <ListboxOptionFooter item={optionsFooter} />}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {status && (
        <p
          className={classNames(styles.statusText, {
            [styles.statusTextError]: status?.type === "error",
            [styles.statusTextWarning]: status?.type === "warning",
            [styles.statusTextInfo]: status?.type === "info",
          })}
        >
          {status.message}
        </p>
      )}
    </div>
  );
}

function SelectEmptyState({
  title,
  description,
  action,
  to = "",
}: {
  title: string;
  description: string;
  to?: string;
  action?: React.ReactNode;
}) {
  return (
    <Link className={styles.emptyStateContainer} to={to}>
      <div className={styles.emptyStateContent}>
        <div className={styles.emptyStateTitle}>{title}</div>
        <div className={styles.emptyStateDescription} title={description}>
          {description}
        </div>
      </div>
      {action && <div className={styles.emptyStateAction}>{action}</div>}
    </Link>
  );
}

function ListboxOptionFooter({ item }: { item: OptionWithAction }) {
  return (
    <button
      type="button"
      className={classNames(styles.listOptionFooter, styles.activeOption)}
      onClick={item.onClick}
    >
      <div
        className={classNames(
          styles.optionItem,
          styles.optionText,
          styles.selectedText,
        )}
      >
        {item.label}
        {/* {showChecks && (
            <div className={styles.checkWrapper}>
              <Checkbox
                value={item.label}
                checked={selected}
                onChange={() => {}}
                disabled
              />
            </div>
          )} */}
      </div>

      {item.icon}
    </button>
  );
}

function ListboxOption({ item }: { item: Option }) {
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(styles.listOption, {
          [styles.activeOption]: active,
        })
      }
      disabled={item.disabled}
      value={item.value}
    >
      {({ selected }) => (
        <div
          className={classNames(styles.optionItem, {
            [styles.flexOption]: item.icon,
          })}
        >
          {item.icon && <img src={item.icon} alt="icon" />}
          <span
            className={classNames(styles.optionText, {
              [styles.selectedText]: selected,
            })}
          >
            {item.label}
          </span>
          {/* {showChecks && (
            <div className={styles.checkWrapper}>
              <Checkbox
                value={item.label}
                checked={selected}
                onChange={() => {}}
                disabled
              />
            </div>
          )} */}
        </div>
      )}
    </Listbox.Option>
  );
}
