import Card from "src/components/Card/Card";
import { CampaignInput } from "../misc/createCampaignTypes";
import { ChooseFacebookCatalogueLoader } from "./ChooseFacebookCatalogueLoader";
import { CampaignCreateForm } from "./CampaignCreateForm";
import * as Yup from "yup";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";

interface IChooseFacebookCatalogue {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => unknown;
}

export function ChooseFacebookCatalogue({
  campaignInput,
  campaignPresetUpsert,
  handleBack,
}: IChooseFacebookCatalogue) {
  const { currentNode } = useWizardContext();

  return (
    <CampaignCreateForm
      schema={chooseFacebookCatalogueSchema}
      campaignInput={campaignInput}
      campaignPresetUpsert={campaignPresetUpsert}
    >
      <Card disabled={currentNode.id !== "selectCatalogue"}>
        <ChooseFacebookCatalogueLoader handleBack={handleBack} />
      </Card>
    </CampaignCreateForm>
  );
}

const chooseFacebookCatalogueSchema = Yup.object({
  // Activities: Yup.array(
  //   Yup.object({
  productCatalogueId: Yup.string().required(
    "Please select a catalogue to continue"
  ),
  //   })
  // ),
});
