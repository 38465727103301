import * as Yup from "yup";

export const adsetBudgetSchema = Yup.string()
  .trim()
  .required("Daily budget is required")
  .matches(/^\d+$/, {
    message: "Should be a valid number",
  })
  .min(1, "Daily budget too low")
  .max(6, "Daily budget too high");
