import * as Yup from "yup";
import { FormValues } from "./adTemplateCreateTypes";

const adTemplateName = {
  regex: /^[a-zA-Z0-9|_\-,:\s]+$/,
  message: "Only alphabets, numbers, underscore and dashes are allowed",
};

export const lineItemSchema = Yup.object({
  headline: Yup.string()
    .required("Headline is required")
    .max(50, "Headline should be less than 50 characters"),
  description: Yup.string()
    .required("Description is required")
    .max(50, "Description should be less than 50 characters"),
  link: Yup.string()
    .url("Link should be valid url")
    .nullable()
    .typeError("Link should be valid url"),
  PostAsset: Yup.object({
    id: Yup.string().required("Media is required"),
  }).required("Media is required"),
});

export const adTemplateCreateSchema = Yup.object({
  type: Yup.string()
    .required("type is required")
    .oneOf(
      ["SINGLE_IMAGE_OR_VIDEO", "CAROUSEL", "EXISTING_POST", "CATALOGUE_AD"],
      "Type is not valid"
    ),
  title: Yup.string()
    .required("Name is a required field")
    .matches(adTemplateName.regex, adTemplateName.message),
  primaryText: Yup.string().when(
    "type",
    (type: FormValues["type"], schema: Yup.StringSchema) => {
      if (type === "EXISTING_POST") {
        return schema.nullable();
      }
      return schema
        .max(1000, "Primary text is too long")
        .required("Primary text is required");
    }
  ),
  // for catalogues ads
  catalogueId: Yup.string().when(
    "type",
    (type: FormValues["type"], schema: Yup.StringSchema) => {
      if (type === "CATALOGUE_AD") {
        return schema.required("Catalog is required for catalogue ads");
      }
      return schema.nullable();
    }
  ),
  MetaProductSetId: Yup.string().when(
    "type",
    (type: FormValues["type"], schema: Yup.StringSchema) => {
      if (type === "CATALOGUE_AD") {
        return schema.required("Product set is required for catalogue ads");
      }
      return schema.nullable();
    }
  ),
  // for existing post ad templates
  existingPostId: Yup.string().when(
    "type",
    (type: FormValues["type"], schema: Yup.StringSchema) => {
      if (type === "EXISTING_POST") {
        return schema.required(
          "Selecting post is required for existing post ads"
        );
      }
      return schema.nullable();
    }
  ),
  LineItems: Yup.array(lineItemSchema).when(
    "type",
    (
      type: FormValues["type"],
      schema: Yup.ArraySchema<typeof lineItemSchema>
    ) => {
      if (type === "CAROUSEL") {
        return schema.min(3).max(10);
      }
      if (type === "SINGLE_IMAGE_OR_VIDEO") {
        return schema.min(1).max(1);
      }

      if (type === "CATALOGUE_AD") {
        return Yup.array(
          Yup.object({
            description: Yup.string().required("Description is required"),
            headline: Yup.string().required("Headline is required"),
          })
        )
          .min(1)
          .max(1);
      }

      return Yup.array(Yup.object({}));
    }
  ),
});
