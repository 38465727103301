import { Campaign, CampaignObjectiveEnum } from "src/graphql/generated/schema";
import styles from "./CampaignTitleWithIcon.module.scss";
import speaker from "../assets/brandAwareness.svg";
import magnet2 from "../assets/engagement.svg";
import leads from "../assets/leadConversion.svg";
import sales from "../assets/sales.svg";
import traffic from "../assets/traffic.svg";
import { formatTime } from "src/lib/formatTime";

function getCampaignIconBasedOnObject(objective: CampaignObjectiveEnum) {
  if (objective === "AWARENESS") {
    return speaker;
  }

  if (objective === "ENGAGEMENT") {
    return magnet2;
  }

  if (objective === "CONVERSIONS") {
    return sales;
  }

  if (objective === "LEADS") {
    return leads;
  }

  if (objective === "TRAFFIC") {
    return traffic;
  }

  return "";
}

export default function CampaignTitleWithIcon({
  campaign,
}: {
  campaign: Pick<Campaign, "objective" | "name" | "createdAt">;
}) {
  return (
    <div className={styles.iconAndTitle}>
      <img
        src={getCampaignIconBasedOnObject(campaign.objective)}
        alt={getCampaignIconBasedOnObject(campaign.objective)}
        className={styles.icon}
      />
      <div>
        <div className={styles.title}>{campaign.name}</div>
        <div className={styles.date}>
          Created{" "}
          {formatTime(new Date(campaign.createdAt), {
            showTimeWithDate: false,
          })}
        </div>
      </div>
    </div>
  );
}
