import styles from "./InstagramMessagePreview.module.scss";
import { ReactComponent as PersonIcon } from "@material-design-icons/svg/outlined/person.svg";
import { MetaPageWelcomeMessageInput } from "../../../graphql/generated/schema";

export function InstagramMessagePreview({
  metaPagePicture,
  metaPageName,
  pageWelcomeMessage,
}: {
  metaPagePicture: string;
  metaPageName: string;
  pageWelcomeMessage: MetaPageWelcomeMessageInput;
}) {
  return (
    <section className={styles.backdrop}>
      <div className={styles.instagramPreview}>
        <div className={styles.sheetHeader}>
          <div className={styles.dragHandle} />
          <div className={styles.heading}>Message {metaPageName}</div>
          <div className={styles.subheading}>
            Typically replies within a day
          </div>
        </div>
        <div className={styles.body}>
          {pageWelcomeMessage?.greeting && (
            <div className={styles.greetingArea}>
              {metaPagePicture && (
                <img
                  className={styles.pageAvatar}
                  src={metaPagePicture}
                  width={32}
                  height={32}
                />
              )}
              <span>
                <strong>{metaPageName} </strong> "{pageWelcomeMessage?.greeting}
                "
              </span>
            </div>
          )}
          <div className={styles.messageOptionSection}>
            {pageWelcomeMessage?.MessageOptions?.filter(
              (option) => !!option.text
            ).map((option) => (
              <div className={styles.messageOption}>
                <span>"{option.text}"</span>
                <button className={styles.sendBtn} type="button">
                  Send
                </button>
              </div>
            ))}
          </div>
          <div className={styles.inputSection}>
            <span className={styles.personIcon}>
              <PersonIcon width={20} height={20} />
            </span>
            <input
              placeholder="Write your own message"
              className={styles.input}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
