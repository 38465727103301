import Badge from "src/components/Badge/Badge";
import styles from "./SegmentUsedAudience.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "@material-design-icons/svg/outlined/arrow_outward.svg";
import { SegmentQuery } from "src/graphql/generated/schema";
import { ReactComponent as SegmentIcon } from "../../global/assets/segment.svg";
import { titleCase } from "src/lib/titleCase";
import format from "date-fns/format";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";

export function SegmentUsedAudience({
  segment,
}: {
  segment: SegmentQuery["segment"];
}) {
  const details = [
    {
      label: "Created on",
      value: format(new Date(segment.Audience.createdAt), "dd MMM, yyyy"),
    },
    {
      label: "Customer Count",
      value: segment.customersCount,
    },
    {
      label: "Type",
      value: snakeCaseToTitleCase(segment.Audience.type),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.tile}>
        <div className={styles.segmentInfo}>
          <SegmentIcon className={styles.img} />
          <div>
            <Link
              className={styles.segmentLink}
              to={`/audiences/${segment.Audience.id}`}
            >
              <span className={styles.segmentDetail}>
                {segment.Audience.name}
              </span>
              <ArrowOutward className={styles.arrowIco} />
            </Link>
            <span className={styles.platform}>
              Platform: {titleCase(segment.Audience.platform)}
            </span>
          </div>
        </div>
        <Badge status="success" label="Connected" />
      </div>
      <hr className={styles.line} />
      <div className={styles.itemsContainer}>
        {details.map((item, index) => (
          <div className={styles.item} key={index}>
            <span className={styles.itemLabel}>{item.label}</span>
            <span className={styles.itemValue}>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
