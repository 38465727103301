export const metaLanguages = [
  {
    id: "English (US)",
    name: "English (US)",
    auxiliaryData: {
      name: "English (US)",
      key: 6,
    },
  },
  {
    id: "Catalan",
    name: "Catalan",
    auxiliaryData: {
      name: "Catalan",
      key: 1,
    },
  },
  {
    id: "Czech",
    name: "Czech",
    auxiliaryData: {
      name: "Czech",
      key: 2,
    },
  },
  {
    id: "Cebuano",
    name: "Cebuano",
    auxiliaryData: {
      name: "Cebuano",
      key: 56,
    },
  },
  {
    id: "Welsh",
    name: "Welsh",
    auxiliaryData: {
      name: "Welsh",
      key: 3,
    },
  },
  {
    id: "Danish",
    name: "Danish",
    auxiliaryData: {
      name: "Danish",
      key: 4,
    },
  },
  {
    id: "German",
    name: "German",
    auxiliaryData: {
      name: "German",
      key: 5,
    },
  },
  {
    id: "Basque",
    name: "Basque",
    auxiliaryData: {
      name: "Basque",
      key: 59,
    },
  },
  {
    id: "Spanish",
    name: "Spanish",
    auxiliaryData: {
      name: "Spanish",
      key: 23,
    },
  },
  {
    id: "Spanish (Spain)",
    name: "Spanish (Spain)",
    auxiliaryData: {
      name: "Spanish (Spain)",
      key: 7,
    },
  },
  {
    id: "Guarani",
    name: "Guarani",
    auxiliaryData: {
      name: "Guarani",
      key: 66,
    },
  },
  {
    id: "Finnish",
    name: "Finnish",
    auxiliaryData: {
      name: "Finnish",
      key: 8,
    },
  },
  {
    id: "French (France)",
    name: "French (France)",
    auxiliaryData: {
      name: "French (France)",
      key: 9,
    },
  },
  {
    id: "Galician",
    name: "Galician",
    auxiliaryData: {
      name: "Galician",
      key: 65,
    },
  },
  {
    id: "Hungarian",
    name: "Hungarian",
    auxiliaryData: {
      name: "Hungarian",
      key: 30,
    },
  },
  {
    id: "Italian",
    name: "Italian",
    auxiliaryData: {
      name: "Italian",
      key: 10,
    },
  },
  {
    id: "Japanese",
    name: "Japanese",
    auxiliaryData: {
      name: "Japanese",
      key: 11,
    },
  },
  {
    id: "Korean",
    name: "Korean",
    auxiliaryData: {
      name: "Korean",
      key: 12,
    },
  },
  {
    id: "Norwegian (bokmal)",
    name: "Norwegian (bokmal)",
    auxiliaryData: {
      name: "Norwegian (bokmal)",
      key: 13,
    },
  },
  {
    id: "Norwegian (nynorsk)",
    name: "Norwegian (nynorsk)",
    auxiliaryData: {
      name: "Norwegian (nynorsk)",
      key: 84,
    },
  },
  {
    id: "Dutch",
    name: "Dutch",
    auxiliaryData: {
      name: "Dutch",
      key: 14,
    },
  },
  {
    id: "Frisian",
    name: "Frisian",
    auxiliaryData: {
      name: "Frisian",
      key: 63,
    },
  },
  {
    id: "Polish",
    name: "Polish",
    auxiliaryData: {
      name: "Polish",
      key: 15,
    },
  },
  {
    id: "Portuguese (Brazil)",
    name: "Portuguese (Brazil)",
    auxiliaryData: {
      name: "Portuguese (Brazil)",
      key: 16,
    },
  },
  {
    id: "Portuguese (Portugal)",
    name: "Portuguese (Portugal)",
    auxiliaryData: {
      name: "Portuguese (Portugal)",
      key: 31,
    },
  },
  {
    id: "Romanian",
    name: "Romanian",
    auxiliaryData: {
      name: "Romanian",
      key: 32,
    },
  },
  {
    id: "Russian",
    name: "Russian",
    auxiliaryData: {
      name: "Russian",
      key: 17,
    },
  },
  {
    id: "Slovak",
    name: "Slovak",
    auxiliaryData: {
      name: "Slovak",
      key: 33,
    },
  },
  {
    id: "Slovenian",
    name: "Slovenian",
    auxiliaryData: {
      name: "Slovenian",
      key: 34,
    },
  },
  {
    id: "Swedish",
    name: "Swedish",
    auxiliaryData: {
      name: "Swedish",
      key: 18,
    },
  },
  {
    id: "Thai",
    name: "Thai",
    auxiliaryData: {
      name: "Thai",
      key: 35,
    },
  },
  {
    id: "Turkish",
    name: "Turkish",
    auxiliaryData: {
      name: "Turkish",
      key: 19,
    },
  },
  {
    id: "Northern Kurdish (Kurmanji)",
    name: "Northern Kurdish (Kurmanji)",
    auxiliaryData: {
      name: "Northern Kurdish (Kurmanji)",
      key: 76,
    },
  },
  {
    id: "Simplified Chinese (China)",
    name: "Simplified Chinese (China)",
    auxiliaryData: {
      name: "Simplified Chinese (China)",
      key: 20,
    },
  },
  {
    id: "Traditional Chinese (Hong Kong)",
    name: "Traditional Chinese (Hong Kong)",
    auxiliaryData: {
      name: "Traditional Chinese (Hong Kong)",
      key: 21,
    },
  },
  {
    id: "Traditional Chinese (Taiwan)",
    name: "Traditional Chinese (Taiwan)",
    auxiliaryData: {
      name: "Traditional Chinese (Taiwan)",
      key: 22,
    },
  },
  {
    id: "Afrikaans",
    name: "Afrikaans",
    auxiliaryData: {
      name: "Afrikaans",
      key: 36,
    },
  },
  {
    id: "Albanian",
    name: "Albanian",
    auxiliaryData: {
      name: "Albanian",
      key: 87,
    },
  },
  {
    id: "Armenian",
    name: "Armenian",
    auxiliaryData: {
      name: "Armenian",
      key: 68,
    },
  },
  {
    id: "Azerbaijani",
    name: "Azerbaijani",
    auxiliaryData: {
      name: "Azerbaijani",
      key: 53,
    },
  },
  {
    id: "Belarusian",
    name: "Belarusian",
    auxiliaryData: {
      name: "Belarusian",
      key: 54,
    },
  },
  {
    id: "Bengali",
    name: "Bengali",
    auxiliaryData: {
      name: "Bengali",
      key: 45,
    },
  },
  {
    id: "Bosnian",
    name: "Bosnian",
    auxiliaryData: {
      name: "Bosnian",
      key: 55,
    },
  },
  {
    id: "Bulgarian",
    name: "Bulgarian",
    auxiliaryData: {
      name: "Bulgarian",
      key: 37,
    },
  },
  {
    id: "Croatian",
    name: "Croatian",
    auxiliaryData: {
      name: "Croatian",
      key: 38,
    },
  },
  {
    id: "Flemish",
    name: "Flemish",
    auxiliaryData: {
      name: "Flemish",
      key: 83,
    },
  },
  {
    id: "English (UK)",
    name: "English (UK)",
    auxiliaryData: {
      name: "English (UK)",
      key: 24,
    },
  },
  {
    id: "Esperanto",
    name: "Esperanto",
    auxiliaryData: {
      name: "Esperanto",
      key: 57,
    },
  },
  {
    id: "Estonian",
    name: "Estonian",
    auxiliaryData: {
      name: "Estonian",
      key: 58,
    },
  },
  {
    id: "Faroese",
    name: "Faroese",
    auxiliaryData: {
      name: "Faroese",
      key: 62,
    },
  },
  {
    id: "French (Canada)",
    name: "French (Canada)",
    auxiliaryData: {
      name: "French (Canada)",
      key: 44,
    },
  },
  {
    id: "Georgian",
    name: "Georgian",
    auxiliaryData: {
      name: "Georgian",
      key: 72,
    },
  },
  {
    id: "Greek",
    name: "Greek",
    auxiliaryData: {
      name: "Greek",
      key: 39,
    },
  },
  {
    id: "Gujarati",
    name: "Gujarati",
    auxiliaryData: {
      name: "Gujarati",
      key: 67,
    },
  },
  {
    id: "Hindi",
    name: "Hindi",
    auxiliaryData: {
      name: "Hindi",
      key: 46,
    },
  },
  {
    id: "Icelandic",
    name: "Icelandic",
    auxiliaryData: {
      name: "Icelandic",
      key: 69,
    },
  },
  {
    id: "Indonesian",
    name: "Indonesian",
    auxiliaryData: {
      name: "Indonesian",
      key: 25,
    },
  },
  {
    id: "Irish",
    name: "Irish",
    auxiliaryData: {
      name: "Irish",
      key: 64,
    },
  },
  {
    id: "Javanese",
    name: "Javanese",
    auxiliaryData: {
      name: "Javanese",
      key: 71,
    },
  },
  {
    id: "Kannada",
    name: "Kannada",
    auxiliaryData: {
      name: "Kannada",
      key: 75,
    },
  },
  {
    id: "Kazakh",
    name: "Kazakh",
    auxiliaryData: {
      name: "Kazakh",
      key: 73,
    },
  },
  {
    id: "Latvian",
    name: "Latvian",
    auxiliaryData: {
      name: "Latvian",
      key: 78,
    },
  },
  {
    id: "Lithuanian",
    name: "Lithuanian",
    auxiliaryData: {
      name: "Lithuanian",
      key: 40,
    },
  },
  {
    id: "Macedonian",
    name: "Macedonian",
    auxiliaryData: {
      name: "Macedonian",
      key: 79,
    },
  },
  {
    id: "Malay",
    name: "Malay",
    auxiliaryData: {
      name: "Malay",
      key: 41,
    },
  },
  {
    id: "Marathi",
    name: "Marathi",
    auxiliaryData: {
      name: "Marathi",
      key: 81,
    },
  },
  {
    id: "Mongolian",
    name: "Mongolian",
    auxiliaryData: {
      name: "Mongolian",
      key: 80,
    },
  },
  {
    id: "Nepali",
    name: "Nepali",
    auxiliaryData: {
      name: "Nepali",
      key: 82,
    },
  },
  {
    id: "Punjabi",
    name: "Punjabi",
    auxiliaryData: {
      name: "Punjabi",
      key: 47,
    },
  },
  {
    id: "Serbian",
    name: "Serbian",
    auxiliaryData: {
      name: "Serbian",
      key: 42,
    },
  },
  {
    id: "Swahili",
    name: "Swahili",
    auxiliaryData: {
      name: "Swahili",
      key: 88,
    },
  },
  {
    id: "Filipino",
    name: "Filipino",
    auxiliaryData: {
      name: "Filipino",
      key: 26,
    },
  },
  {
    id: "Tamil",
    name: "Tamil",
    auxiliaryData: {
      name: "Tamil",
      key: 48,
    },
  },
  {
    id: "Telugu",
    name: "Telugu",
    auxiliaryData: {
      name: "Telugu",
      key: 49,
    },
  },
  {
    id: "Malayalam",
    name: "Malayalam",
    auxiliaryData: {
      name: "Malayalam",
      key: 50,
    },
  },
  {
    id: "Ukrainian",
    name: "Ukrainian",
    auxiliaryData: {
      name: "Ukrainian",
      key: 52,
    },
  },
  {
    id: "Uzbek",
    name: "Uzbek",
    auxiliaryData: {
      name: "Uzbek",
      key: 91,
    },
  },
  {
    id: "Vietnamese",
    name: "Vietnamese",
    auxiliaryData: {
      name: "Vietnamese",
      key: 27,
    },
  },
  {
    id: "Khmer",
    name: "Khmer",
    auxiliaryData: {
      name: "Khmer",
      key: 74,
    },
  },
  {
    id: "Tajik",
    name: "Tajik",
    auxiliaryData: {
      name: "Tajik",
      key: 89,
    },
  },
  {
    id: "Arabic",
    name: "Arabic",
    auxiliaryData: {
      name: "Arabic",
      key: 28,
    },
  },
  {
    id: "Hebrew",
    name: "Hebrew",
    auxiliaryData: {
      name: "Hebrew",
      key: 29,
    },
  },
  {
    id: "Urdu",
    name: "Urdu",
    auxiliaryData: {
      name: "Urdu",
      key: 90,
    },
  },
  {
    id: "Persian",
    name: "Persian",
    auxiliaryData: {
      name: "Persian",
      key: 60,
    },
  },
  {
    id: "Pashto",
    name: "Pashto",
    auxiliaryData: {
      name: "Pashto",
      key: 85,
    },
  },
  {
    id: "Sinhala",
    name: "Sinhala",
    auxiliaryData: {
      name: "Sinhala",
      key: 86,
    },
  },
  {
    id: "Japanese (Kansai)",
    name: "Japanese (Kansai)",
    auxiliaryData: {
      name: "Japanese (Kansai)",
      key: 70,
    },
  },
  {
    id: "English (All)",
    name: "English (All)",
    auxiliaryData: {
      name: "English (All)",
      key: 1001,
    },
  },
  {
    id: "Spanish (All)",
    name: "Spanish (All)",
    auxiliaryData: {
      name: "Spanish (All)",
      key: 1002,
    },
  },
  {
    id: "French (All)",
    name: "French (All)",
    auxiliaryData: {
      name: "French (All)",
      key: 1003,
    },
  },
  {
    id: "Chinese (All)",
    name: "Chinese (All)",
    auxiliaryData: {
      name: "Chinese (All)",
      key: 1004,
    },
  },
  {
    id: "Portuguese (All)",
    name: "Portuguese (All)",
    auxiliaryData: {
      name: "Portuguese (All)",
      key: 1005,
    },
  },
];
