import { MutableRefObject, useContext, useState } from "react";
import { Tabs } from "src/components/Tabs/Tabs";
import styles from "./AudiencePresetOrInterestSelector.module.scss";
import { AudienceSelectFilters } from "./AudienceSelectFilters";
import { InterestsAndDemographicsSelectorLoader } from "./InterestsAndDemographicsSelectorLoader";
import { Input } from "src/components/Input/Input";
import { ReactComponent as SearchIcon } from "@material-symbols/svg-400/outlined/search.svg";
import {
  AudiencePresetAndInterestSelectorFormState,
  AudiencePresetFilters,
  AudienceSelectTabOption,
  InterestFilters,
} from "../misc/audienceWorkshop";
import { Form, Formik } from "formik";
import { Button } from "src/components/Button/Button";
import { StepWizardContext } from "../../global/misc/StepWizardContext";
import classNames from "classnames";
import { AudiencePresetSelectorLoader } from "./AudiencePresetSelectorLoader";
import { AudienceSetViewSelection } from "./AudienceSetViewSelection";
import { audienceAndInterestTabs } from "../misc/audienceAndInterestTabs";
import { InputV2 } from "src/components/InputV2/InputV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

interface IAudiencePresetOrInterestSelector {
  stepRef: MutableRefObject<HTMLDivElement>;
}

export function AudiencePresetOrInterestSelector({
  stepRef,
}: IAudiencePresetOrInterestSelector) {
  const [isShowingSelection, setIsShowingSelection] = useState(false);
  const { activeStepIndex, markStepAsDone, data } =
    useContext(StepWizardContext);
  const [selectedTab, setSelectedTab] = useState<AudienceSelectTabOption>(
    audienceAndInterestTabs[0].value,
  );
  const [interestSearchValue, setInterestSearchValue] = useState("Behaviours");
  const [audiencePresetSearchValue, setAudiencePresetSearchValue] =
    useState("");
  const [audiencePresetFilters, setAudiencePresetFilters] =
    useState<AudiencePresetFilters>({
      funnelSteps: [
        "AudienceCategory_1",
        "AudienceCategory_2",
        "AudienceCategory_3",
        "AudienceCategory_4",
      ],
      industry: "ALL",
      source: "ALL",
    });
  const [interestsFilters, setInterestsFilters] = useState<InterestFilters>({
    categories: ["BEHAVIOURS", "DEMOGRAPHICS", "INTERESTS"],
  });

  const isSectionCompleted = activeStepIndex >= 2;
  const isSectionInactive = activeStepIndex < 1;

  function handleSubmit(values: AudiencePresetAndInterestSelectorFormState) {
    function transformSelectedValues<T>(
      values: T,
      type: "INCLUDE" | "EXCLUDE",
    ) {
      return (
        Object.values(values)
          .filter((a) => a.value === type)
          // Id is only in interest and must be omitted
          .map(({ value, id, description, ...others }) => others)
      );
    }

    markStepAsDone(2, {
      ...data,
      ...values,
      audiencePresets: transformSelectedValues(
        values.audiencePresets,
        "INCLUDE",
      ),
      excludeAudiencePresets: transformSelectedValues(
        values.audiencePresets,
        "EXCLUDE",
      ),
      ExcludeInterests: transformSelectedValues(values.interests, "EXCLUDE"),
      Interests: transformSelectedValues(values.interests, "INCLUDE"),
    });
  }

  function handleSkip({ resetForm }: { resetForm: () => void }) {
    // don't save values from this form when skipping
    markStepAsDone(2, data);
    resetForm();
  }

  return (
    <div
      ref={stepRef}
      className={classNames(styles.sectionWrapper, {
        [styles.sectionWrapperCompleted]: isSectionCompleted,
      })}
    >
      {isSectionInactive && <div className={styles.inactiveOverlay} />}
      <Formik
        initialValues={{
          audiencePresets: {},
          interests: {},
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setValues, handleSubmit, resetForm }) => (
          <Form onSubmit={handleSubmit}>
            <div className={styles.section}>
              <h2 className={styles.heading}>Select Preset &amp; Interests</h2>
              <p className={styles.subheading}>
                Create your winning audience using 100+ audience presets or
                interests that cover your entire sales funnel
              </p>

              <div className={styles.filterAndPreset}>
                <div className={styles.tabWrapper}>
                  <Tabs
                    tabs={audienceAndInterestTabs}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                  />

                  <div className={styles.inputWrapper}>
                    <InputV2
                      icon={SearchIcon}
                      value={
                        selectedTab === "AUDIENCE_PRESET"
                          ? audiencePresetSearchValue
                          : interestSearchValue
                      }
                      onChange={
                        selectedTab === "AUDIENCE_PRESET"
                          ? setAudiencePresetSearchValue
                          : setInterestSearchValue
                      }
                    />
                  </div>
                  {selectedTab === "AUDIENCE_PRESET" ? (
                    <AudiencePresetSelectorLoader
                      onShowSelection={() => setIsShowingSelection(true)}
                      filters={audiencePresetFilters}
                      searchValue={audiencePresetSearchValue}
                    />
                  ) : (
                    <InterestsAndDemographicsSelectorLoader
                      filters={interestsFilters}
                      searchValue={interestSearchValue}
                      adAccountId={data.adAccountId}
                      onShowSelection={() => setIsShowingSelection(true)}
                    />
                  )}
                </div>
                <AudienceSelectFilters
                  selectedTab={selectedTab}
                  interestsFilters={interestsFilters}
                  setInterestsFilters={setInterestsFilters}
                  audiencePresetFilters={audiencePresetFilters}
                  setAudiencePresetFilters={setAudiencePresetFilters}
                />
              </div>
              <div className={styles.buttonGroup}>
                <ButtonV2 type="submit">Save & Continue</ButtonV2>
                <ButtonV2
                  onClick={() => handleSkip({ resetForm })}
                  variant="outline"
                >
                  Skip
                </ButtonV2>
              </div>
            </div>
            <AudienceSetViewSelection
              initialSelectedTab={selectedTab}
              initialValues={values}
              isShowSelectionOpen={isShowingSelection}
              onChange={setValues}
              setIsShowSelectionOpen={setIsShowingSelection}
            />
          </Form>
        )}
      </Formik>
      <div className={styles.separatorWrapper}>
        <hr className={styles.separator} />
      </div>
    </div>
  );
}
