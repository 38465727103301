import { Form, Formik } from "formik";
import { CampaignInput } from "../misc/createCampaignTypes";
import { ReactNode } from "react";
import { SaveCampaignWizardFormState } from "./SaveCampaignWizardFormState";
import { validateSelectedActivity } from "../misc/validateSelectedActivity";

interface IChooseFacebookActivityTypeForm {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  schema: any;
  autoSave?: boolean;
  children: ReactNode;
}

export function CampaignCreateForm({
  schema,
  campaignPresetUpsert,
  campaignInput,
  autoSave = true,
  children,
}: IChooseFacebookActivityTypeForm) {
  return (
    <Formik
      enableReinitialize
      validateOnMount
      validate={async (values) =>
        await validateSelectedActivity(schema, values)
      }
      initialValues={campaignInput}
      onSubmit={async (values: CampaignInput) => {
        campaignPresetUpsert(values);
      }}
    >
      <Form>
        {autoSave && <SaveCampaignWizardFormState />}
        {children}
      </Form>
    </Formik>
  );
}
