import {
  CustomerQuery,
  Exact,
  useCustomerTagsCreateMutation,
  useCustomerUpdateMutation,
} from "src/graphql/generated/schema";
import { ApolloQueryResult } from "@apollo/client";
import { Button } from "src/components/Button/Button";
import { CustomerTagsComboBox } from "./CustomerTagsComboBox";
import Modal from "src/components/Modal/Modal";
import styles from "./AddTagsModal.module.scss";
import { useState } from "react";

interface Props {
  showAddTagsModal: boolean;
  setShowAddTagsModal: (i: boolean) => void;
  customerRefetch: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<CustomerQuery>>;
  customerId: string;
}

// const options = [
//   { label: "Random", value: "Random" },
//   { label: "Expensive Buyer", value: "Expensive Buyer" },
//   { label: "Worthy", value: "Worthy" },
// ];

export function AddTagsModal({
  showAddTagsModal,
  setShowAddTagsModal,
  customerRefetch,
  customerId,
}: Props) {
  const [addTagsFunc, { loading: addTagsLoading }] =
    useCustomerTagsCreateMutation();

  const [selectedTags, setSelectedTags] = useState<
    | {
        label: string;
        value: string;
      }[]
  >([]);
  const [customerUpdateFunc, { loading: customerUpdateLoading }] =
    useCustomerUpdateMutation();

  return (
    <Modal
      isOpen={showAddTagsModal}
      onClose={() => setShowAddTagsModal(false)}
      title="Add Tags"
      subtitle="Your segment will be saved in Segments. You can view and edit them later
      on."
      className={styles.modal}
      noContainer
      footer={{
        footerContent: (
          <Button color="primary" style="plain" onClick={() => {}}>
            Manage tags
          </Button>
        ),
        footerActions: (
          <Button
            color="primary"
            size="small"
            onClick={async () => {
              await customerUpdateFunc({
                variables: {
                  id: customerId,
                  input: {
                    // tags can be upserted on basis of name
                    tags: (selectedTags as any[]).map((v) => v.label),
                  },
                },
              });
            }}
            loading={customerUpdateLoading}
            disabled={customerUpdateLoading}
          >
            Add tags
          </Button>
        ),
      }}
    >
      <div className={styles.combo}>
        <CustomerTagsComboBox
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags as any}
          onAddTag={async () => {
            await customerRefetch();
          }}
          addTags={addTagsFunc}
          addTagsLoading={addTagsLoading}
        />
      </div>
    </Modal>
  );
}
