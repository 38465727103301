import { AudienceCreateInput } from "src/graphql/generated/schema";
import { AudienceInput } from "../components/CreateAudienceInput";

export function transformAudienceInput(
  audienceValue: AudienceInput["audiences"][0]
): AudienceCreateInput {
  return {
    adAccountId: audienceValue.values.adAccountId,
    description: "",
    name: audienceValue.audienceName,
    instagramAccountId: audienceValue?.values?.instagramAccountId,
    metaPageId: audienceValue?.values?.metaPageId,
    pixelId: audienceValue?.values?.pixelId,
    type:
      audienceValue?.audienceType === "LOOKALIKE"
        ? "FACEBOOK_LOOKALIKE"
        : audienceValue?.audienceSource,
    platform: "META",
    filter: {
      countries: audienceValue?.values?.countries || ["IN"],
      duration: audienceValue?.values?.duration,
      pixelEventName: audienceValue?.values?.pixelEventName,
      percentage: audienceValue?.values?.percentage,
      seedAudienceId: audienceValue?.values?.seedAudienceId,
      segmentId: audienceValue?.values?.segmentId,
    },
  };
}
