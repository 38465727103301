import styles from "./IntegrationCard.module.scss";
import { BadgeV2, IBadgeV2 } from "../../../modules/global/components/BadgeV2";
import { ButtonV2, IButtonV2 } from "../../../components/ButtonV2/ButtonV2";
import classNames from "classnames";

export interface IIntegrationCard {
  name: string;
  description: string;
  label: string;
  image: string;
  disabled?: boolean;
  /* @deprecated */
  link?: string;
  imageHeight?: number;
  imageWidth?: number;
  badge?: IBadgeV2;
  action?: IButtonV2;
}

export function IntegrationCard({
  name,
  description,
  label,
  image,
  imageHeight = 48,
  imageWidth = 48,
  badge,
  action,
  disabled,
}: IIntegrationCard) {
  return (
    <div
      className={classNames(styles.card, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.label}>
        <img
          className={styles.labelImage}
          src={image}
          alt={label}
          width={imageWidth}
          height={imageHeight}
        />
        {badge && <BadgeV2 size="small" {...badge} />}
      </div>
      <div className={styles.info}>
        <p className={styles.title}>{name}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <div>
        {action && (
          <ButtonV2
            isFullWidth={false}
            size="small"
            variant="outline"
            {...action}
          ></ButtonV2>
        )}
      </div>
    </div>
  );
}
