import { SegmentHistoryTable } from "./SegmentHistoryTable";
import { SegmentSectionHeading } from "./SegmentSectionHeading";
import { SegmentUsedAudience } from "./SegmentUsedAudience";
import styles from "./SegmentHistory.module.scss";
import { SegmentQuery } from "src/graphql/generated/schema";

export function SegmentHistory({
  segment,
}: {
  segment: SegmentQuery["segment"];
}) {
  return (
    <>
      <div>
        <div className={styles.headingWrapper}>
          <SegmentSectionHeading
            subHeading={"List of activities this segment has been used"}
            description={
              "Here are the list of customers which are the member of this segment"
            }
          />
        </div>
        <SegmentHistoryTable segment={segment} />
      </div>
      {segment.Audience && (
        <div>
          <div className={styles.headingWrapper}>
            <SegmentSectionHeading
              subHeading={"Segment used in audience"}
              description={"This segment has been used in Facebook Audience"}
            />
          </div>
          <SegmentUsedAudience segment={segment} />
        </div>
      )}
    </>
  );
}
