import { FormikError } from "src/components/FormikError/FormikError";
import Select from "src/components/Select/Select";
import { SelectLabel } from "./SelectLabel";
import { useFormikContext } from "formik";
import { useMetaEnabledAdAccountsQuery } from "src/graphql/generated/schema";
import { IconButton } from "src/components/IconButton/IconButton";
import { ReactComponent as ArrowOutwardIcon } from "@material-symbols/svg-400/rounded/arrow_outward.svg";
import { ReactComponent as AccountCircle } from "@material-symbols/svg-400/rounded/account_circle.svg";
import SelectV2 from "src/components/SelectV2/SelectV2";

export function MetaAdAccountSelector({
  selectedAdAccountId,
  setSelectedAdAccountId,
  formKey,
  disabled,
  className,
}: {
  selectedAdAccountId: string;
  setSelectedAdAccountId: (
    i: string,
    platformId?: string,
    metaName?: string
  ) => void;
  formKey: string;
  disabled?: boolean;
  className?: string;
}) {
  const {
    data: metaEnabledAdAccountsData,
    loading: metaEnabledAdAccountsLoading,
  } = useMetaEnabledAdAccountsQuery();
  const { submitCount, touched, errors } = useFormikContext();

  const options = metaEnabledAdAccountsData
    ? metaEnabledAdAccountsData?.metaEnabledAdAccounts?.map((v) => ({
        label: v.metaName,
        value: v.id,
        platformAccountId: v.metaAccountId,
      }))
    : [];

  return (
    <div className={className}>
      <SelectV2
        emptyState={{
          title: "Connect Account",
          description: "Connect account from which you want to run ads",
          to: "/settings/integrations/meta",
          action: (
            <IconButton
              icon={<ArrowOutwardIcon height={20} width={20} />}
              to="/settings/integrations/meta"
              type="button"
              color="primary"
              style="plain"
              shape="square"
            />
          ),
        }}
        label={
          <SelectLabel
            labelText={"Choose Ad Account"}
            tooltipText={"Select Ad account to run your Ads"}
          />
        }
        icon={AccountCircle}
        options={options}
        name={formKey}
        // value={activityValues?.adAccountId}
        value={selectedAdAccountId}
        onChange={(value) => {
          const selectedOption = options.find((v) => v.value === value);
          // setActivityValue("adAccountId", value);
          setSelectedAdAccountId(
            value,
            selectedOption?.platformAccountId,
            selectedOption?.label
          );
        }}
        disabled={disabled || metaEnabledAdAccountsLoading}
        placeholder={
          metaEnabledAdAccountsLoading ? "Loading" : "Select Ad Account"
        }
      />
      <FormikError
        touched={touched}
        formikError={errors}
        submitCount={submitCount}
        fieldName={formKey}
      />
    </div>
  );
}
