import classNames from "classnames";
import styles from "./NotificationItem.module.scss";
import NotificationMsgIcon from "./NotificationMsgIcon";
import format from "date-fns/format";
import { INotification } from "../misc/notification";
import { formatTime } from "src/lib/formatTime";
import { useMemo } from "react";

interface INotificationItem {
  notification: INotification;
}

export default function NotificationItem({ notification }: INotificationItem) {
  const formattedDate = useMemo(
    () =>
      formatTime(new Date(notification.createdAt), { showTimeWithDate: false }),
    [notification]
  );

  return (
    <div className={styles.item}>
      <NotificationMsgIcon
        status={notification.state}
        icon={notification.iconType}
      />
      <div>
        <p
          className={classNames(styles.title, {
            [styles.lightTitle]: notification.isRead,
          })}
        >
          {notification.title}
        </p>
        <p
          className={classNames(styles.description, {
            [styles.lightDescription]: notification.isRead,
          })}
        >
          {notification.description}
        </p>
        <span
          className={classNames(styles.date, {
            [styles.lightDate]: notification.isRead,
          })}
        >
          {formattedDate}
        </span>
      </div>
    </div>
  );
}
