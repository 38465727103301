import sub from "date-fns/sub";
import { DateFilter as CampaignDateFilter } from "src/modules/global/misc/dateFilterUtils";

interface TinybirdDateFilter {
  dateFrom: Date;
  dateTo: Date;
}

export function getTinybirdDateFiltersFromDateFilter(
  filterString: CampaignDateFilter
): TinybirdDateFilter {
  switch (filterString) {
    case "ALL_TIME":
      return {
        dateFrom: null,
        dateTo: null,
      };
    case "LAST_7_DAYS":
      return {
        dateTo: new Date(),
        dateFrom: sub(new Date(), { days: 7 }),
      };
    case "LAST_28_DAYS":
      return {
        dateTo: new Date(),
        dateFrom: sub(new Date(), { days: 28 }),
      };
    case "LAST_3_MONTHS":
      return {
        dateTo: new Date(),
        dateFrom: sub(new Date(), {
          months: 3,
        }),
      };
    case "LAST_6_MONTHS":
      return {
        dateTo: new Date(),
        dateFrom: sub(new Date(), {
          months: 6,
        }),
      };
    case "YESTERDAY":
      return {
        dateTo: new Date(),
        dateFrom: sub(new Date(), {
          days: 1,
        }),
      };
    case "TODAY":
      const date = new Date();
      const startDate = new Date();
      startDate.setHours(0, 0, 0);
      return {
        dateTo: date,
        dateFrom: startDate,
      };
    default:
      return {
        dateFrom: null,
        dateTo: null,
      };
  }
}
