import { LineHeading } from "src/modules/media/components/LineHeading";
import { ReactComponent as ArticleIcon } from "@material-symbols/svg-600/rounded/article.svg";
import { ReactComponent as AddLink } from "@material-symbols/svg-600/rounded/add_link.svg";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import styles from "./AdSingleImageOrVideoFields.module.scss";
import { SelectedAsset } from "./SelectedAsset";
import { useFormikContext } from "formik";
import { PageWelcomeMessageSelector } from "./PageWelcomeMessageSelector";
import classNames from "classnames";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { AdCallToActionLabelField } from "./AdCallToActionLabelField";

interface IAdSingleImageOrVideo {
  selectedAdIndex: number;
  selectedAd: AdInputV2;
  setFieldValue: (i: string, value: string) => void;
}

export function AdSingleImageOrVideoFields({
  selectedAdIndex,
  selectedAd,
  setFieldValue,
}: IAdSingleImageOrVideo) {
  const { values } = useFormikContext<ActivityInputV2>();

  const shouldShowPageWelcomeMessage =
    values.metaPurpose === "MESSAGE_INSTAGRAM" ||
    values.metaPurpose === "MESSAGE_MESSENGER" ||
    values.metaPurpose === "MESSAGE_WHATSAPP";

  const shouldShowLink =
    values.metaPurpose === "SALES_STANDARD" ||
    values.metaPurpose === "PROMOTE_POST" ||
    values.metaPurpose === "WEBSITE_VIEWS";

  const shouldShowPhoneNumber = values.metaPurpose === "CALL";

  const showAdTitleAndDescriptionAndCta =
    values.metaPurpose !== "VIDEO_2_SEC_VIEW" &&
    values.metaPurpose !== "VIDEO_THRUPLAY";

  return (
    <div className={styles.adSingleFields}>
      {showAdTitleAndDescriptionAndCta && (
        <InputFormik
          disabled={true}
          name={`Ads[${selectedAdIndex}].LineItems[0].headline`}
          label="Ad Title"
          maxCharacterCount={40}
          showCharacterCount={true}
        />
      )}
      {showAdTitleAndDescriptionAndCta && (
        <InputFormik
          disabled={true}
          name={`Ads[${selectedAdIndex}].LineItems[0].description`}
          label="Ad Description"
          maxCharacterCount={40}
          showCharacterCount={true}
        />
      )}

      {selectedAd.LineItems[0].PostAsset && (
        <SelectedAsset asset={selectedAd.LineItems[0].PostAsset} />
      )}

      {shouldShowPageWelcomeMessage && (
        <div>
          <PageWelcomeMessageSelector
            value={selectedAd.pageWelcomeMessageId}
            onChange={() => {
              /** field is disabled */
            }}
          />
        </div>
      )}

      {showAdTitleAndDescriptionAndCta && (
        <>
          <LineHeading
            icon={<ArticleIcon width={14} height={14} />}
            content="Destination"
          />
          <div className={classNames(styles.linksSection)}>
            <AdCallToActionLabelField
              name={`Ads[${selectedAdIndex}].callToActionLabel`}
              value={selectedAd.callToActionLabel}
              adType={selectedAd.adType}
            />

            {shouldShowPhoneNumber && (
              <PhoneNumberInput
                disabled
                defaultPhoneCode="+91"
                label="Phone Number"
                value={selectedAd.phoneNumber}
                countryCodeValue={selectedAd.phoneCountryCode}
                countryCodeOnChange={(value) => {
                  setFieldValue(
                    `Ads[${selectedAdIndex}].phoneCountryCode`,
                    value,
                  );
                }}
                onChange={(value) => {
                  setFieldValue(`Ads[${selectedAdIndex}].phoneNumber`, value);
                }}
              />
            )}

            {shouldShowLink && (
              <InputFormik
                name={`Ads[${selectedAdIndex}].LineItems[0].link`}
                label="Destination Website link*"
                icon={AddLink}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
