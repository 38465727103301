import EmptyState from "src/components/EmptyState/EmptyState";
import styles from "./CreateAutomationPage.module.scss";

export function CreateAutomationEmptyState() {
  return (
    <div className={styles.center}>
      <EmptyState
        title={"Kicks off your contact's Journey"}
        illustration={"/imagesAndIcons/AutomationEmpty.svg"}
      >
        <p
          style={{
            color: "var(--color-subdued)",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          Choose the starting point, then contacts who meet the criteria will
          enter your map and begin their journey.
        </p>
      </EmptyState>
    </div>
  );
}
