import { Fragment } from "react";
import Select from "src/components/Select/Select";
import { Condition, PersonHasDone } from "../PersonHasDone/PersonHasDone";
import styles from "./Condition.module.scss";
import { majorConditions } from "./SelectionData";

interface Props {
  condition: Condition[];
  cindex: number;
  conditions: Condition[][];
  setConditions: (i: any) => void;
}

export function ConfigureCondition({
  condition,
  cindex,
  conditions,
  setConditions,
}: Props) {
  function updateMajorCondition(idx, value) {
    const resultArr = conditions.map((mitem, midx) =>
      midx === cindex
        ? mitem.map((item, cidx) =>
            cidx === idx ? { ...item, major: value } : item
          )
        : mitem
    );

    setConditions(resultArr);
  }

  return (
    <div className={styles.selectContainer}>
      {condition.map((item, index) => (
        <Fragment key={index}>
          <Select
            placeholder="Select a condition"
            value={item.major}
            options={majorConditions}
            onChange={(e) => updateMajorCondition(index, e)}
          />
          {item.major && (
            <PersonHasDone
              personConditions={item}
              conditions={conditions}
              setConditions={setConditions}
              index={index}
              cindex={cindex}
              subArray={condition}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
}
