import styles from "./PhoneNumberInput.module.scss";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import { Label } from "src/components/Label/Label";
import classNames from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/expand_more.svg";

export interface IPhoneNumberInput {
  onBlur?: () => void;
  onChange: (value: string) => void;
  value: string;
  defaultPhoneCode: string;
  countryCodeOnChange: (value: string) => void;
  countryCodeValue: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  isFullWidth?: boolean;
  error?: string;
}

export function PhoneNumberInput({
  onBlur,
  onChange,
  disabled,
  value,
  defaultPhoneCode,
  countryCodeValue,
  countryCodeOnChange,
  name,
  placeholder,
  isFullWidth = true,
  label,
  error,
}: IPhoneNumberInput) {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.fullWidth]: isFullWidth,
      })}
    >
      <Label label={label} />
      <div
        className={classNames(styles.inputWrapper, {
          [styles.error]: error,
          [styles.disabled]: disabled,
        })}
      >
        <PhoneCountryCodeDropdown
          disabled={disabled}
          value={countryCodeValue}
          onChange={countryCodeOnChange}
          defaultPhoneCode={defaultPhoneCode}
        />
        <input
          disabled={disabled}
          className={classNames(styles.phoneNumberField, {
            [styles.disabled]: disabled,
          })}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          placeholder={placeholder}
          name={name}
        />
      </div>
      {error && <FormFieldStatus message={error} type={"error"} />}
    </div>
  );
}

const countriesWithPhoneCode = [
  {
    countryCode: "IN",
    name: "India",
    phoneCode: "91",
  },
  {
    countryCode: "US",
    name: "USA",
    phoneCode: "1",
  },
];

function PhoneCountryCodeDropdown({
  disabled,
  defaultPhoneCode,
  onChange,
  value,
}) {
  const selectedCountry = countriesWithPhoneCode.find(
    (c) => c.phoneCode === value,
  );

  useEffect(() => {
    if (!value && defaultPhoneCode) {
      onChange(defaultPhoneCode);
    }
  }, [defaultPhoneCode, value]);

  return (
    <Menu as="div" className={styles.menu}>
      <Menu.Button className={styles.countrySelectorButton}>
        <span
          className={classNames(styles.countryCode, {
            [styles.disabledCountryCode]: disabled,
          })}
        >
          <img
            alt={selectedCountry.countryCode}
            src={`https://flagsapi.com/${selectedCountry.countryCode}/flat/64.png`}
            width={20}
            className={styles.countryImage}
          />
          <ArrowDown fill="var(--color-disabled)" width={16} height={16} />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter={styles.enter}
        enterFrom={styles.enterFrom}
        enterTo={styles.enterTo}
        leave={styles.leave}
        leaveFrom={styles.leaveFrom}
        leaveTo={styles.leaveTo}
      >
        <Menu.Items className={classNames(styles.menuContainer)}>
          {countriesWithPhoneCode.map((item, index) => (
            <Menu.Item
              as="div"
              onClick={() => {
                onChange(item.phoneCode);
              }}
              className={styles.menuItem}
              key={index}
            >
              <img
                alt={item.countryCode}
                src={`https://flagsapi.com/${item.countryCode}/flat/64.png`}
                width={24}
                className={styles.countryImage}
              />
              <span className={styles.menuItemText}>{item.name}</span>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
