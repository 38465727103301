import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "src/components/Spinner/Spinner";
import { useIntegrationsStatusQuery } from "src/graphql/generated/schema";

export function CustomerRedirect() {
  const { data, loading, error } = useIntegrationsStatusQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && data) {
      if (data.integrationsStatus.shopify === "INACTIVE") {
        navigate("/contacts/create");
      }
      if (data.integrationsStatus.shopify === "ACTIVE") {
        navigate("/contacts/import");
      }
    }
  }, []);
  if (loading) return <Spinner height="screen" />;

  return <div></div>;
}
