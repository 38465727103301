import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as Error } from "@material-design-icons/svg/outlined/error.svg";
import { IconButton } from "../IconButton/IconButton";
import classNames from "classnames";
import styles from "./Banner.module.scss";

export interface IBanner {
  content: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
  action?: React.ReactNode;
  type?: "warning" | "error" | "success";
  icon?: React.ReactNode;
}

export default function Banner(props: IBanner) {
  const {
    content,
    className,
    action,
    isOpen,
    onClose,
    icon,
    type = "success",
  } = props;
  return (
    <div className={classNames(styles.banner, className, styles[type])}>
      <div className={styles.content}>
        {icon && icon}
        <span className={styles.contentText}>{content}</span>
      </div>
      <div className={styles.actions}>
        <div>{action}</div>
        {onClose && (
          <IconButton
            onClick={onClose}
            style="plain"
            icon={
              <Close
                width={20}
                height={20}
                className={classNames({
                  [styles.errorIco]: type === "error",
                  [styles.warnIco]: type === "warning",
                  [styles.winIco]: type === "success",
                })}
              />
            }
          />
        )}
      </div>
    </div>
  );
}
