import { Spinner } from "../../../../components/Spinner/Spinner";
import { useMetaActivityAdTemplateQuery } from "../../../../graphql/generated/schema";
import Slider from "../../../global/components/Slider";
import { AdTemplateSingle } from "./AdTemplateSingle";
import styles from "./AdTemplateSingleSlideOver.module.scss";

interface IAdTemplateSingleSlideOver {
  adTemplateId: string;
  isOpen: boolean;
  onClose: () => void;
}

export function AdTemplateSingleSlideOver({
  adTemplateId,
  isOpen,
  onClose,
}: IAdTemplateSingleSlideOver) {
  const { data, loading } = useMetaActivityAdTemplateQuery({
    variables: {
      id: adTemplateId,
    },
  });

  return (
    <Slider
      isOpen={isOpen}
      onClose={onClose}
      title={loading ? "" : data?.metaActivityAdTemplate?.name}
      panelClassNames={styles.slideOver}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.wrapper}>
          <AdTemplateSingle adTemplate={data?.metaActivityAdTemplate as any} />
        </div>
      )}
    </Slider>
  );
}
