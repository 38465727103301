import { Spinner } from "src/components/Spinner/Spinner";
import {
  useMetaCataloguesFromPlatformQuery,
  useMetaEnableCatalogueMutation,
  useMetaRemoveCatalogueMutation,
} from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import styles from "./MetaPagesList.module.scss";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";
import { useState } from "react";

export function MetaCataloguesList({ selectedAccountId }) {
  const [selectedCatalogue, setSelectedCatalogue] = useState<null | string>(
    null,
  );
  const { data: metaCataloguesData, loading: metaCataloguesLoading } =
    useMetaCataloguesFromPlatformQuery({
      variables: {
        adAccountId: selectedAccountId,
      },
    });

  const [
    metaEnableCatalogueFunc,
    { loading: metaEnableLoading, error: metaEnableError },
  ] = useMetaEnableCatalogueMutation();
  const [metaRemoveCatalogueFunc, { loading: metaRemoveLoading }] =
    useMetaRemoveCatalogueMutation();

  if (metaCataloguesLoading) {
    return <Spinner height="screen" />;
  }

  const metaCatalogues =
    metaCataloguesData && metaCataloguesData.metaCataloguesFromPlatform
      ? metaCataloguesData?.metaCataloguesFromPlatform
      : [];

  if (metaCatalogues.length === 0) {
    return (
      <div className={styles.emptyStateContainer}>
        <MetaIntegrationDetailsEmptyState
          text={
            "No pages found on your account. Please ensure that you have enabled accounts in macro. Enabled accounts should have a page on facebook"
          }
          link={""}
        />
      </div>
    );
  }

  return (
    <div>
      {metaCatalogues.map((catalogue, index) => (
        <MetaDetailItem
          error={selectedCatalogue === catalogue.id && metaEnableError}
          loading={
            selectedCatalogue === catalogue.id &&
            (metaEnableLoading || metaRemoveLoading)
          }
          enableFunc={async () => {
            setSelectedCatalogue(catalogue.id);
            await metaEnableCatalogueFunc({
              variables: {
                input: {
                  adAccountId: selectedAccountId,
                  metaCatalogueId: catalogue.id,
                },
              },
              refetchQueries: [
                "metaCataloguesForEnabledAdAccounts",
                "metaCataloguesFromPlatform",
              ],
              awaitRefetchQueries: true,
            });
            setSelectedCatalogue(null);
          }}
          removeFunc={async () => {
            setSelectedCatalogue(catalogue.id);
            await metaRemoveCatalogueFunc({
              variables: {
                input: {
                  adAccountId: selectedAccountId,
                  metaCatalogueId: catalogue.id,
                },
              },
              refetchQueries: [
                "metaCataloguesForEnabledAdAccounts",
                "metaCataloguesFromPlatform",
              ],
              awaitRefetchQueries: true,
            });
            setSelectedCatalogue(null);
          }}
          title={catalogue?.name}
          isEnabled={catalogue?.enabled}
          key={index}
          description={`Catalogue ID - ${catalogue?.id}`}
        />
      ))}
    </div>
  );
}
