import classNames from "classnames";
import snakeCaseToTitleCase from "../../../lib/snakeCaseToTitleCase";
import { ToggleSwitch } from "../../global/components/ToggleSwitch";
import { ReactComponent as Progress } from "@material-symbols/svg-400/rounded/progress_activity.svg";
import styles from "./MetaDetailItem.module.scss";
import Badge from "src/components/Badge/Badge";
import { ApolloError } from "@apollo/client";

interface IMetaAccountDetailItem {
  enableFunc: () => void;
  removeFunc: () => void;
  title: string;
  description?: string;
  isEnabled: boolean;
  isDefault?: boolean;
  loading?: boolean;
  error?: ApolloError;
  profilePicture?: string;
}

export function MetaDetailItem({
  profilePicture,
  enableFunc,
  removeFunc,
  isEnabled,
  isDefault = false,
  title,
  description,
  error,
  loading,
}: IMetaAccountDetailItem) {
  return (
    <div className={styles.container}>
      <div className={styles.profileContainer}>
        <img
          src={
            profilePicture ||
            `https://api.dicebear.com/7.x/shapes/svg?seed=${title}`
          }
          alt="meta item profile"
          className={styles.profile}
        />
        <div className={styles.content}>
          <div className={styles.title}>
            {snakeCaseToTitleCase(title || "")}
          </div>
          {description && (
            <div className={styles.descriptionContainer}>
              <span
                className={classNames(styles.description, {
                  [styles.error]: Boolean(error),
                })}
              >
                {error?.message || description}
              </span>

              {/* {error && (
                <Tooltip
                  children={
                    <Info height={15} width={15} className={styles.error} />
                  }
                  text={error.message}
                />
              )} */}
            </div>
          )}
        </div>
      </div>
      <div className={styles.secondarySection}>
        {isDefault && <Badge label={"Default"} status="default" />}
        {loading ? (
          <div className={styles.loading}>
            <Progress className={styles.loadingIcon} />
            <span>Loading</span>
          </div>
        ) : (
          <ToggleSwitch
            checked={isEnabled}
            onChange={() => {
              if (isEnabled) {
                removeFunc();
                return;
              }
              enableFunc();
            }}
            title={title}
            disabled={loading}
          />
        )}
      </div>
    </div>
  );
}
