import {
  AudienceInput,
  FACEBOOK_AUDIENCE_SOURCES,
} from "src/modules/audience/components/CreateAudienceInput";
import { getIn, useFormikContext } from "formik";
import { Button } from "src/components/Button/Button";
import Card from "src/components/Card/Card";
import CollectionItem from "src/components/CollectionItem/CollectionItem";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import { Label } from "src/components/Label/Label";
import { RadioGroup } from "@headlessui/react";
import facebookAudience from "../assets/facebookAudience.svg";
import instagramAudience from "../assets/instagramAudience.svg";
import websiteAudience from "../assets/websiteAudience.svg";
import customerList from "../assets/customerList.svg";

import styles from "./ChooseAudienceType.module.scss";

interface Props {
  activeStep: number;
  setActiveStep: (i: number) => void;
  selectedAudienceIndex: number;
  disabled: boolean;
}

const sources: {
  title: string;
  subtitle: string;
  image: string;
  value: FACEBOOK_AUDIENCE_SOURCES;
}[] = [
  {
    title: "Website",
    subtitle:
      "Create an audience based on website visitors from your Meta pixel.",
    image: websiteAudience,
    value: "FACEBOOK_PIXEL_BASED",
  },
  {
    title: "Segment",
    subtitle: "Create an audience by macro segments",
    image: customerList,
    value: "FACEBOOK_SEGMENT_BASED",
  },
  {
    title: "Instagram Account",
    subtitle:
      "Create an audience of people who interacted with your instagram profile",
    image: instagramAudience,
    value: "INSTAGRAM_ENGAGERS",
  },
  {
    title: "Facebook Page",
    subtitle: "Create an audience of people who have interacted with your page",
    image: facebookAudience,
    value: "FACEBOOK_ENGAGERS",
  },
];

export function ChooseAudienceSource({
  activeStep: activeStep,
  setActiveStep: setActiveStep,
  selectedAudienceIndex,
  disabled,
}: Props) {
  const { values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<AudienceInput>();
  const audienceSourceValue =
    values.audiences[selectedAudienceIndex]?.audienceSource;
  const audienceSourceKey = `audiences[${selectedAudienceIndex}].audienceSource`;
  const fieldError = getIn(errors, audienceSourceKey);
  const isFieldTouched = getIn(touched, audienceSourceKey);
  const fieldValue = getIn(values, audienceSourceKey);

  return (
    <Card disabled={disabled}>
      <Label label="Choose a Custom Audience source">
        <RadioGroup
          as="div"
          className={styles.sourceTypeInput}
          name={audienceSourceKey}
          onBlur={() => {
            setFieldTouched(audienceSourceKey);
          }}
          value={fieldValue}
          onChange={(value) => {
            if (value === "INSTAGRAM_ENGAGERS") {
              setFieldValue(
                `audiences[${selectedAudienceIndex}].values.platform`,
                "INSTAGRAM"
              );
              setFieldValue(audienceSourceKey, value);
              return;
            }
            setFieldValue(
              `audiences[${selectedAudienceIndex}].values.platform`,
              "FACEBOOK"
            );
            setFieldValue(audienceSourceKey, value);
          }}
        >
          {sources.map((item, index) => (
            <CollectionItem
              title={item.title}
              image={item.image}
              value={item.value}
              subtitle={item.subtitle}
              key={index}
            />
          ))}
        </RadioGroup>
        {isFieldTouched && fieldError && (
          <FormFieldStatus type="error" message={fieldError} />
        )}
      </Label>

      <div className={styles.btnGrp}>
        <Button
          type="button"
          disabled={audienceSourceValue ? false : true}
          onClick={() => {
            setActiveStep(activeStep + 1);
            console.log(values);
          }}
        >
          Save and Continue
        </Button>
        <Button
          style="plain"
          color="subdued"
          type="button"
          onClick={() => setActiveStep(activeStep - 1)}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}
