import { FlowItems, FormValues } from "./CreateAutomationPage";
import { formatFlowItemList } from "./formatFlowItemList";

export function addValueToFlowList(
  flowItems: FlowItems[],
  values: FormValues,
  item: FlowItems,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
) {
  if (!values.selectedItem) {
    return insertItemAndFormat({
      flowItems,
      selectedIndex: 0,
      item,
      setFieldValue,
    });
  }
  const selectedIndex = values.selectedItem.selectedIndex;
  const hasParentIndex = values.selectedItem.parentIndex !== undefined;
  const hasOptionIndex = values.selectedItem.optionIndex !== undefined;

  if (hasParentIndex && hasOptionIndex) {
    const parentItem = flowItems[values.selectedItem.parentIndex];
    const option = (parentItem as any).options[values.selectedItem.optionIndex];
    option.splice(selectedIndex, 0, item);
    return formatFlowItemList(flowItems, setFieldValue);
  }

  return insertItemAndFormat({ flowItems, selectedIndex, item, setFieldValue });
}

export function insertItemAndFormat({
  flowItems,
  selectedIndex,
  item,
  setFieldValue,
}: {
  flowItems: FlowItems[];
  selectedIndex: number;
  item: FlowItems;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) {
  if (item.variant === "conditional") {
    flowItems.splice(selectedIndex, 0, item);
    const parent = flowItems.slice(0, selectedIndex + 1);
    const options = flowItems.slice(selectedIndex + 1);
    parent[parent.length - 1] = {
      ...item,
      options: options.length > 0 ? [[...options], []] : [],
    };
    return formatFlowItemList(parent, setFieldValue);
  }
  flowItems.splice(selectedIndex, 0, item);
  return formatFlowItemList(flowItems, setFieldValue);
}

export function removeNewVariantSelector(flowItems: FlowItems[]): FlowItems[] {
  return flowItems.filter((v) => {
    if ("options" in v) {
      if (v.options.length > 0) {
        const filteredOptions = v.options.map((i: any) =>
          removeNewVariantSelector(i)
        ) as [FlowItems[], FlowItems[]];
        v.options = filteredOptions;
      }
    }

    return v.variant !== "new";
  });
}
