import { Disclosure } from "@headlessui/react";
import styles from "./ShopifyWizardSettings.module.scss";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { ReactComponent as KeyboardArrowDown } from "@material-symbols/svg-600/outlined/keyboard_arrow_down.svg";
import HelpCircleIcon from "@untitled-ui/icons-react/build/esm/HelpCircle";
import Tooltip from "src/components/Tooltip/Tooltip";

interface IShopifyWizardSettings {
  title: string;
  subtitle: string;
  imageSrc: string;
  isChecked: boolean;
  onChange: (newValue: boolean) => void;
  options: {
    required: boolean;
    value: string;
    title: string;
    helpText?: string;
    onChange: (newValue: boolean) => void;
    checked: boolean;
    disabled?: boolean;
  }[];
}

export function ShopifyWizardSettings({
  title,
  subtitle,
  imageSrc,
  isChecked,
  onChange,
  options,
}: IShopifyWizardSettings) {
  return (
    <div className={styles.wrapper}>
      <Disclosure>
        <div className={styles.header}>
          <div className={styles.headerLeftSection}>
            <img
              className={styles.headerIcon}
              alt="shopify ingress settings"
              src={imageSrc}
              width={32}
              height={32}
            />
            <div className={styles.headerCopy}>
              <h3 className={styles.headerTitle}>{title}</h3>
              <h4 className={styles.headerSubtitle}>{subtitle}</h4>
            </div>
          </div>

          <div className={styles.headerRightSection}>
            <div className={styles.checkboxWrapper}>
              <Checkbox checked={isChecked} onChange={onChange} value="" />
            </div>
            <Disclosure.Button>
              <KeyboardArrowDown width={14} height={14} />
            </Disclosure.Button>
          </div>
        </div>
        <Disclosure.Panel>
          <div className={styles.optionList}>
            {options.map((option) => (
              <div className={styles.optionItem}>
                <span className={styles.optionTitle}>
                  {option.title}
                  {option.required && (
                    <span className={styles.optionIsRequired}>(Required)</span>
                  )}
                  {option.helpText && (
                    <Tooltip supportingText={option.helpText}>
                      <HelpCircleIcon width={16} height={16} />
                    </Tooltip>
                  )}
                </span>
                <Checkbox
                  disabled={option.disabled}
                  checked={option.checked}
                  onChange={option.onChange}
                  value={option.value}
                />
              </div>
            ))}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
}
