import styles from "./GraphCard.module.scss";
import classNames from "classnames";
import {
  ILineGraphV2,
  LineGraphV2,
} from "src/modules/global/components/LineGraphV2";
import { titleCase } from "../../../lib/titleCase";

export function GraphCard({
  title,
  subtitle,
  action,
  value,
  chartData,
  chartDataKey,
  graphClassName,
  cardClassName,
  yAxis,
}: {
  title: string;
  subtitle: string;
  action?: React.ReactNode;
  value: string;
  chartData: { time: any; [x: string]: any }[];
  chartDataKey: string;
  yAxis?: ILineGraphV2["yAxis"];
  graphClassName?: string;
  cardClassName?: string;
}) {
  return (
    <div className={classNames(styles.card, cardClassName)}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderContent}>
          <div className={styles.cardTitle}>{title}</div>
          <div className={styles.cardSubtitle}>{subtitle}</div>
          <div className={styles.cardValue}>{value}</div>
        </div>
        {action}
      </div>
      <div className={classNames(styles.cardGraph, graphClassName)}>
        <LineGraphV2
          height={"100%"}
          width={"100%"}
          statValues={chartData}
          statLabel={{
            color: "#8884d8",
            name: titleCase(chartDataKey),
            dataKey: chartDataKey,
          }}
          xAxis={{
            dataKey: "time",
          }}
          yAxis={
            yAxis
              ? yAxis
              : {
                  dataKey: chartDataKey,
                  label: chartDataKey,
                  hide: true,
                }
          }
        />
        {/* <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="2%" stopColor="#8884d8" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            {yAxis && (
              <YAxis
                dataKey={chartDataKey}
                interval="preserveStartEnd"
                strokeOpacity={0}
                {...yAxis}
              />
            )}
            <XAxis
              dataKey="time"
              interval="preserveStartEnd"
              strokeOpacity={0}
            />
            <Area
              type="linear"
              dataKey={chartDataKey}
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorValue)"
            />
            <CartesianGrid horizontal={false} stroke="#C9CCCF3D" />
          </AreaChart>
        </ResponsiveContainer> */}
      </div>
    </div>
  );
}
