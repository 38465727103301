import { Button } from "src/components/Button/Button";
import Card from "src/components/Card/Card";
import CollectionItem from "src/components/CollectionItem/CollectionItem";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Label } from "src/components/Label/Label";
import { RadioGroup } from "@headlessui/react";
import Select from "src/components/Select/Select";
import styles from "./SetupTemplate.module.scss";
import { useFormikContext } from "formik";
import { useState } from "react";
import { MessageFormInput, FormInput } from "../../CreateMessageTemplateTypes";
import { WhatsappTemplateLanguageEnum } from "src/graphql/generated/schema";

const whatsappOptions = [
  {
    title: "Utility",
    subtitle: "Use our pre-defined template to get started",
    image: "/imagesAndIcons/SmsMethod.svg",
    value: "UTILITY",
  },
  {
    title: "Conversation",
    subtitle: "Use our pre-defined template to get started",
    image: "/imagesAndIcons/SmsMethod.svg",
    value: "CONVERSATION",
  },
  {
    title: "Authentication",
    subtitle: "Use our pre-defined template to get started",
    image: "/imagesAndIcons/SmsMethod.svg",
    value: "AUTHENTICATION",
  },
];

const smsOptions = [
  {
    title: "Promotional",
    subtitle: "Use our pre-defined template to get started",
    image: "/imagesAndIcons/SmsMethod.svg",
    value: "PROMOTIONAL",
  },
  {
    title: "Service Explicit",
    subtitle: "Use our pre-defined template to get started",
    image: "/imagesAndIcons/SmsMethod.svg",
    value: "SERVICE_EXPLICIT",
  },
  {
    title: "Service Implicit",
    subtitle: "Use our pre-defined template to get started",
    image: "/imagesAndIcons/SmsMethod.svg",
    value: "SERVICE_IMPLICIT",
  },
  {
    title: "Transactional",
    subtitle: "Use our pre-defined template to get started",
    image: "/imagesAndIcons/SmsMethod.svg",
    value: "TRANSACTIONAL",
  },
];

const languageOptions: {
  label: string;
  value: WhatsappTemplateLanguageEnum;
}[] = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "English (US)",
    value: "en_US",
  },
  {
    label: "English (UK)",
    value: "en_GB",
  },
];

interface ISetupTemplate {
  templateType: "WHATSAPP" | "SMS";
}

export function SetupTemplate({ templateType }: ISetupTemplate) {
  const { setFieldValue, values, handleSubmit } = useFormikContext<FormInput>();

  const [chosenCategory, setChosenCategory] = useState(null);
  let categoryOptions =
    templateType === "WHATSAPP" ? whatsappOptions : smsOptions;

  return (
    <div>
      <Card
        title={`Tell us about your  ${
          templateType === "WHATSAPP" ? "Whatsapp" : "Sms"
        } Template`}
      >
        <div className={styles.fieldWrapper}>
          <InputFormik name="name" label="Template Name" />
          {templateType === "WHATSAPP" ? (
            <Select
              label={"Select Language"}
              options={languageOptions}
              value={values.language}
              onChange={(v) => {
                setFieldValue("language", v);
              }}
            />
          ) : (
            <Select
              label="Choose Template type"
              options={[
                {
                  label: "Non DLT based Template",
                  value: "NO_DLT",
                },
                {
                  label: "DLT based Template",
                  value: "DLT_BASED",
                },
              ]}
              value={(values as MessageFormInput).type}
              onChange={(value) => {
                setFieldValue("type", value);
              }}
            />
          )}
        </div>
        <div>
          <Label label="Choose category">
            <RadioGroup
              as="div"
              className={styles.categories}
              name={"category"}
              value={chosenCategory}
              onChange={(value) => {
                setFieldValue("objective", value);
                setChosenCategory(value);
              }}
            >
              {categoryOptions.map((category, index) => (
                <RadioGroup.Option
                  value={category.value}
                  //   disabled={platform.disable}
                  title={category.title}
                  key={index}
                >
                  <CollectionItem
                    title={category.title}
                    subtitle={category.subtitle}
                    image={category.image}
                    value={category.value}
                    key={index}
                  />
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </Label>
        </div>
        <Button className={styles.btn} onClick={handleSubmit}>
          Save and Continue
        </Button>
      </Card>
    </div>
  );
}
