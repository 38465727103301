import { Asset } from "src/graphql/generated/schema";
import styles from "./SelectedAssetItem.module.scss";
import { ReactComponent as DeleteIcon } from "@material-symbols/svg-400/rounded/delete.svg";
import { ReactComponent as RefreshIcon } from "@material-symbols/svg-400/rounded/refresh.svg";
import { formatFileSize } from "src/lib/formatFileSize";

interface ISelectedAssetItem {
  asset: Asset;
  onRemove?: () => void;
}

export function SelectedAssetItem({ asset, onRemove }: ISelectedAssetItem) {
  return (
    <div className={styles.wrapper}>
      {asset.contentType === "IMAGE" ? (
        <img
          className={styles.assetPreview}
          src={asset.uri}
          width={76}
          height={76}
          alt=""
        />
      ) : (
        <video className={styles.assetPreview} autoPlay muted loop>
          <source src={asset.uri} />
        </video>
      )}
      <div className={styles.nameWrapper}>
        <span className={styles.name}>{asset.name}</span>
        <span className={styles.moreInfo}>
          {formatFileSize(asset.fileSize)} | {asset.width} x {asset.height}
        </span>
      </div>
      <div className={styles.actions}>
        {/*
        <button className={styles.actionButton} type="button">
          <RefreshIcon width={20} height={20} />
        </button>
        */}
        <button
          onClick={onRemove}
          className={styles.actionButton}
          type="button"
        >
          <DeleteIcon fill="var(--color-danger)" width={20} height={20} />
        </button>
      </div>
    </div>
  );
}
