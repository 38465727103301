import { Label } from "src/components/Label/Label";
import styles from "./TextMessageObjectDetails.module.scss";
import Select from "src/components/Select/Select";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { useWhatsappTemplatesQuery } from "src/graphql/generated/schema";
import { ActivityInput } from "../misc/createCampaignTypes";
import { Input } from "src/components/Input/Input";

interface IWhatsappMessageObjectDetails {
  selectedActivity: ActivityInput;
  selectedActivityKey: string;
}
export function WhatsappMessageObjectDetails({
  selectedActivity,
  selectedActivityKey,
}: IWhatsappMessageObjectDetails) {
  const { data, loading: templatesLoading } = useWhatsappTemplatesQuery();
  const templates =
    data && data.whatsappTemplates
      ? data.whatsappTemplates.edges.map(({ node }) => ({
          label: node.name,
          value: node.id,
          ...node,
        }))
      : [];

  return (
    <div className={styles.campaign}>
      <div className={styles.section}>
        <span className={styles.sectionTitle}>Campaign Details</span>
        <hr />
      </div>
      <div className={styles.campaignDetails}>
        <Label label="Send From*">
          <Input value={"Default Sender"} disabled />
          {/* <InputFormik
            name={`${selectedActivityKey}.WhatsappMessageObject.sendFrom`}
          /> */}
        </Label>
        <Label label={"Select Template"}>
          <Select
            value={
              selectedActivity?.WhatsappMessageObject?.whatsappTemplateId || ""
            }
            onChange={() => {}}
            placeholder={templatesLoading ? "Loading" : ""}
            options={templates}
            disabled={true}
          />
        </Label>
      </div>
    </div>
  );
}
