import styles from "./AutomationSidebarFooter.module.scss";

export function AutomationSidebarFooter({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className={styles.footer}>
      {/* <Button width="full">Create starting point</Button> */}
      {children}
    </div>
  );
}
