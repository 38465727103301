import classNames from "classnames";
import styles from "./Dropdown.module.scss";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Button, IButton } from "src/components/Button/Button";

export interface DropdownButton extends IButton {
  onClick: (e: any) => void;
  color?: IButton["color"];
}

export function Dropdown({
  title,
  items,
}: {
  title: React.ReactNode;
  items: DropdownButton[];
}) {
  return (
    <Menu as="div" className={styles.menu}>
      <Transition
        as={Fragment}
        enter={styles.enter}
        enterFrom={styles.enterFrom}
        enterTo={styles.enterTo}
        leave={styles.leave}
        leaveFrom={styles.leaveFrom}
        leaveTo={styles.leaveTo}
      >
        <Menu.Items className={classNames(styles.menuItems)}>
          <div className={styles.menuSub}>
            {items.map((item, index) => (
              <Menu.Item key={index}>
                <Button
                  onClick={item.onClick}
                  className={styles.menuItem}
                  size="small"
                  style="plain"
                  color={item.color ? item.color : "subdued"}
                  loading={item?.loading}
                  disabled={item?.disabled}
                >
                  {item.children}
                </Button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
      <Menu.Button>{title}</Menu.Button>
    </Menu>
  );
}
