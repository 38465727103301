import {
  ActivityInputV2,
  AdInput,
  AdInputV2,
} from "../misc/createCampaignTypes";
import * as Accordion from "@radix-ui/react-accordion";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { LineHeading } from "src/modules/media/components/LineHeading";
import { ReactComponent as ArticleIcon } from "@material-symbols/svg-600/rounded/article.svg";
import { ReactComponent as ArrowDown } from "@material-symbols/svg-600/rounded/keyboard_arrow_down.svg";
import Select from "src/components/Select/Select";
import styles from "./AdCarouselFields.module.scss";
import { SelectedAsset } from "./SelectedAsset";
import { useFormikContext } from "formik";
import { PageWelcomeMessageSelector } from "./PageWelcomeMessageSelector";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { AdCallToActionLabelField } from "./AdCallToActionLabelField";

interface IAdCarouselFields {
  selectedAdIndex: number;
  selectedAd: AdInputV2;
  setFieldValue: (i: string, value: string) => void;
}

export function AdCarouselFields({
  selectedAd,
  selectedAdIndex,
  setFieldValue,
}: IAdCarouselFields) {
  const { values } = useFormikContext<ActivityInputV2>();

  const shouldShowPageWelcomeMessage =
    values.metaPurpose === "MESSAGE_INSTAGRAM" ||
    values.metaPurpose === "MESSAGE_MESSENGER" ||
    values.metaPurpose === "MESSAGE_WHATSAPP";
  const shouldShowLink =
    values.metaPurpose === "SALES_STANDARD" ||
    values.metaPurpose === "PROMOTE_POST" ||
    values.metaPurpose === "WEBSITE_VIEWS";

  const shouldShowPhoneNumber = values.metaPurpose === "CALL";

  return (
    <div className={styles.carouselFields}>
      {shouldShowPageWelcomeMessage && (
        <div>
          <PageWelcomeMessageSelector
            value={selectedAd.pageWelcomeMessageId}
            onChange={() => {
              /** field is disabled */
            }}
          />
        </div>
      )}

      <Accordion.Root
        className={styles.carouselList}
        type="single"
        defaultValue="item-1"
        collapsible
      >
        {selectedAd.LineItems.map((lineItem, cardIndex) => (
          <Accordion.Item
            className={styles.carouselItem}
            value={`item-${cardIndex + 1}`}
          >
            <div className={styles.carouselHeader}>
              <span className={styles.cardTitle}>
                Card {cardIndex + 1 > 9 ? cardIndex + 1 : `0${cardIndex + 1}`}
              </span>

              <Accordion.Trigger>
                <ArrowDown width={20} height={20} />
              </Accordion.Trigger>
            </div>
            <Accordion.Content>
              <div className={styles.carouselContent}>
                <div className={styles.disabledItems}>
                  <InputFormik
                    disabled
                    name={`Ads[${selectedAdIndex}].LineItems[${cardIndex}].headline`}
                    label="Ad Title"
                    placeholder="Write your ad title"
                    showCharacterCount={true}
                    maxCharacterCount={40}
                  />

                  <InputFormik
                    disabled
                    name={`Ads[${selectedAdIndex}].LineItems[${cardIndex}].description`}
                    label="Ad Description"
                    placeholder="Write your ad description"
                    showCharacterCount={true}
                    maxCharacterCount={125}
                  />

                  {lineItem.PostAsset && (
                    <SelectedAsset asset={lineItem.PostAsset} />
                  )}
                </div>

                {shouldShowLink && (
                  <InputFormik
                    name={`Ads[${selectedAdIndex}].LineItems[${cardIndex}].link`}
                    label="Destination Website Link*"
                    placeholder="https://example.com/product/item"
                  />
                )}

                {/* <AddMedia
                onSelect={(assets) => handleSelect(assets, cardIndex)}
                multiple={false}
              /> */}
              </div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>

      <div className={styles.ctaSection}>
        <LineHeading
          icon={<ArticleIcon width={14} height={14} />}
          content="Destination"
        />

        <AdCallToActionLabelField
          name={`Ads[${selectedAdIndex}].callToActionLabel`}
          value={selectedAd.callToActionLabel}
          adType={selectedAd.adType}
        />

        {shouldShowPhoneNumber && (
          <PhoneNumberInput
            disabled
            defaultPhoneCode="+91"
            label="Phone Number"
            value={selectedAd.phoneNumber}
            countryCodeValue={selectedAd.phoneCountryCode}
            countryCodeOnChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneCountryCode`, value);
            }}
            onChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneNumber`, value);
            }}
          />
        )}
      </div>
    </div>
  );
}
