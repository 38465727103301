import classNames from "classnames";
import styles from "./Tabs.module.scss";

export interface ITabs {
  tabs: { label: React.ReactNode; value: string }[];
  selectedTab: string;
  onTabChange: (i: any) => void;
}

export function Tabs({ tabs, selectedTab, onTabChange }: ITabs) {
  return (
    <div className={styles.container}>
      {tabs.map((tab, index) => (
        <button
          type="button"
          className={classNames(styles.tab, {
            [styles.tabActive]: tab.value === selectedTab,
          })}
          onClick={() => {
            onTabChange(tab.value);
          }}
          key={index}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}
