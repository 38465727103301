import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";
import { Button, IButton } from "../Button/Button";
import styles from "./CollectionItem.module.scss";
import Tooltip from "../Tooltip/Tooltip";
import { BadgeV2, IBadgeV2 } from "../../modules/global/components/BadgeV2";

export interface ICollectionItem {
  image: string;
  title: string;
  subtitle?: string;
  value: string | number;
  size?: "default" | "small";
  badge?: IBadgeV2;
  disabled?: boolean;
  tooltipText?: string;
  action?: IButton;
  imageHeight?: number | "auto";
  imageWidth?: number | "auto";
  className?: string;
  cardProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

export default function CollectionItem(props: ICollectionItem) {
  const {
    title,
    image,
    value,
    subtitle,
    className,
    disabled = false,
    tooltipText,
    badge,
    size = "default",
    action,
    imageHeight = 48,
    imageWidth = 48,
    cardProps,
  } = props;

  return (
    <RadioGroup.Option disabled={disabled} as={Fragment} value={value}>
      {({ checked }) => (
        <div
          className={classNames(
            styles.card,
            {
              [styles.cardSizeSmall]: size === "small",
              [styles.cardSizeDefault]: size === "default",
              [styles.cardChecked]: checked,
              [styles.cardDisabled]: disabled,
            },
            className
          )}
          {...cardProps}
        >
          <RadioGroup.Label as="div" className={styles.label}>
            <img
              src={image}
              alt={title}
              width={imageWidth}
              height={imageHeight}
            />
            {badge && <BadgeV2 size="small" {...badge} />}
          </RadioGroup.Label>
          <RadioGroup.Description
            as="span"
            className={classNames(styles.title, checked && styles.textChecked)}
          >
            {title}
          </RadioGroup.Description>
          {subtitle && (
            <RadioGroup.Description
              as="p"
              className={classNames(styles.subtitle)}
            >
              {subtitle}
            </RadioGroup.Description>
          )}
          {action && (
            <div className={styles.actionWrapper}>
              <Button {...action} width="full">
                {action.children}
              </Button>
            </div>
          )}
        </div>
      )}
    </RadioGroup.Option>
  );
}
