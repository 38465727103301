import styles from "./AdTemplateSingle.module.scss";
import { ReactComponent as ActivityCount } from "../../assets/activityCount.svg";
import format from "date-fns/format";
import { MetaActivityAdTemplate } from "../../../../graphql/generated/schema";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import emptyCampaign from "../../../campaign/assets/emptyCampaign.png";

export function AdTemplateActivitiesListing({
  adTemplate,
}: {
  adTemplate: MetaActivityAdTemplate;
}) {
  const activities = adTemplate.Activities;

  if (activities.length === 0) {
    return (
      <div className={styles.center}>
        <EmptyState
          title={"No Activities"}
          image={
            <img
              src={emptyCampaign}
              alt="create_campaign"
              width={290}
              height={235}
            />
          }
        >
          This template was not used in activitiy yet
        </EmptyState>
      </div>
    );
  }

  return (
    <div className={styles.activitiesListing}>
      {activities.map((v) => (
        <div className={styles.activityItem}>
          <div className={styles.activityItemPrimarySection}>
            <ActivityCount width={32} height={32} />
            {v.name}
          </div>
          <div className={styles.activityDate}>
            {format(new Date(adTemplate.createdAt), "dd MMM, yyyy")}
          </div>
        </div>
      ))}
    </div>
  );
}
