import styles from "./SomethingWentWrong.module.scss";
import somethingWentWrong from "../assets/somethingWentWrong.png";

export function SomethingWentWrong() {
  return (
    <div className={styles.page}>
      <div>
        <img
          src={somethingWentWrong}
          alt="somethingWentWrong"
          className={styles.image}
        />
        <p className={styles.title}>Something Went Wrong</p>
        <p className={styles.subtitle}>
          Looks like there is an issue from our side, we are looking into it,
          please refresh this page again
        </p>
      </div>
    </div>
  );
}
