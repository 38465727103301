import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./AudienceSinglePage.module.scss";
import { useState } from "react";
import { AudienceQuickOverview } from "../components/AudienceQuickOverview";
import { AudienceSingleCreateAudience } from "../components/AudienceSingleCreateAudience";
import { AudienceSingleAbout } from "../components/AudienceSingleAbout";
import { useParams } from "react-router-dom";
import { Audience, useAudienceQuery } from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";
import subDays from "date-fns/subDays";
import { ResourceOverviewLoader } from "../components/ResourceOverviewLoader";
import { useAudienceAggregatedStats } from "src/modules/global/functions/useAudienceAggregatedStats";

/** @deprecated **/
export function AudienceSingle() {
  const [showModal, setShowModal] = useState(false);
  const dateTo = new Date();
  const dateFrom = subDays(new Date(), 30);
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useAudienceQuery({
    variables: {
      id,
    },
  });
  const {
    data: statData,
    loading: statLoading,
    error: statError,
  } = useAudienceAggregatedStats(id, {
    dateFrom,
    dateTo,
  });

  if (loading || statLoading) {
    return <PageLoader />;
  }

  if (error || statError) {
    return <ErrorHandler error={error || statError} />;
  }
  return (
    <>
      <PageHeader title={data.audience.name} back="/audiences" />
      <AudienceSingleCreateAudience
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
      <div className={styles.page}>
        <ResourceOverviewLoader
          statData={statData}
          dateFrom={dateFrom}
          dateTo={dateTo}
          audienceId={id}
        />
        <AudienceQuickOverview insights={statData} />
        {/* <AudienceSingleGraph
          dateFrom={dateFrom}
          dateTo={dateTo}
          audienceId={id}
        /> */}
        <AudienceSingleAbout audience={data.audience as Audience} />
      </div>
    </>
  );
}
