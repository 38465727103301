import { useEffect, useState } from "react";
import {
  SortByInput,
  useWhatsappTemplatesLazyQuery,
} from "../../../graphql/generated/schema";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "../../global/misc/dateFilterUtils";
import { PaginationInput } from "../../global/misc/PaginationInput";
import { itemsPerPage } from "../misc/itemsPerPage";
import { MessageTemplatesListingTable } from "./MessageTemplatesListingTable";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import { NoResultScreen } from "./NoResultsScreen";

export interface MessageTemplatesFilters {
  query: string;
  createdAt: DateFilter;
}

export function MessageListingTableLoader({
  filters,
  setFilters,
  sortBy,
  setSortBy,
}: {
  filters: MessageTemplatesFilters;
  setFilters: (i: MessageTemplatesFilters) => void;
  sortBy: SortByInput;
  setSortBy: (i: SortByInput) => void;
}) {
  const [pagination, setPagination] = useState<PaginationInput>({
    take: itemsPerPage,
    cursor: null,
  });

  const searchQueryDebounced = useDebouncedVariable(filters.query);

  const [whatsappTemplatesFn, { data, loading, called }] =
    useWhatsappTemplatesLazyQuery({
      variables: {
        sortBy,
        filters: pagination,
      },
    });

  useEffect(() => {
    const query = searchQueryDebounced.trim();
    const createdAt = getDateFilterFromFilterEnum(filters.createdAt);
    whatsappTemplatesFn({
      variables: {
        sortBy,
        filters: {
          take: pagination.take,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          name: query,
          createdAt,
        },
      },
    });
  }, [
    searchQueryDebounced,
    filters.createdAt,
    whatsappTemplatesFn,
    sortBy,
    pagination,
  ]);

  const pageInfo = data?.whatsappTemplates?.pageInfo;
  const areFiltersApplied =
    filters.createdAt !== "ALL_TIME" || filters.query !== "";

  if (
    !loading &&
    called &&
    !data?.whatsappTemplates?.edges?.length &&
    !areFiltersApplied
  ) {
    return (
      <NoResultScreen
        title="Create your Message Creatives"
        description="Right now there is no activity to show."
        buttonLabel="Let's Get Started"
        buttonLink="/library/message-library/create"
      />
    );
  }

  return (
    <div>
      <MessageTemplatesListingTable
        messageTemplates={data?.whatsappTemplates?.edges ?? []}
        pageInfo={pageInfo}
        loading={loading}
        setPagination={setPagination}
      />
    </div>
  );
}
