import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "src/components/Button/Button";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { useWhatsappTemplatesLazyQuery } from "src/graphql/generated/schema";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import styles from "./WhatsappTemplatesLoader.module.scss";
import UserAddIcon from "../assets/userAddIcon.svg";
import createAdImage from "../assets/createAds.svg";
import noResultAd from "../assets/noResultAds.svg";
import { WhatsappTemplateItem } from "./WhatsappTemplateItem";

export function WhatsappTemplatesLoader({
  selectedFilters,
  selectedSortBy,
  selectedWhatsappTemplate,
  setSelectedWhatsappTemplate,
}) {
  const debouncedSearchQuery = useDebouncedVariable(
    selectedFilters.searchQuery,
  );
  const [whatsappTemplatesFn, { data, loading, called, error, fetchMore }] =
    useWhatsappTemplatesLazyQuery();

  const areFiltersApplied =
    debouncedSearchQuery !== "" ||
    selectedFilters.adType !== null ||
    selectedFilters.dateFilter !== "ALL_TIME";

  useEffect(() => {
    whatsappTemplatesFn({
      variables: {
        sortBy: selectedSortBy,
        // filter: {
        //   createdAt: getDateFilterFromFilterEnum(selectedFilters.dateFilter),
        //   ...(selectedFilters.adType && { adType: selectedFilters.adType }),
        //   name: debouncedSearchQuery,
        //   take: 25,
        // },
      },
    });
  }, [
    whatsappTemplatesFn,
    selectedSortBy,
    debouncedSearchQuery,
    selectedFilters.adType,
    selectedFilters.dateFilter,
  ]);

  if (loading || !called) {
    return <Spinner height={400} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  async function fetchMoreAdTemplates() {
    await fetchMore({
      variables: {
        filter: {
          cursor: data.whatsappTemplates.pageInfo.endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.whatsappTemplates?.edges ?? [];
        const oldEdges = previousResult?.whatsappTemplates?.edges ?? [];

        return {
          whatsappTemplates: {
            ...fetchMoreResult.whatsappTemplates,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  // this should be above the generic handler of no result
  // because that one will match both of these cases
  if (!data.whatsappTemplates.edges.length && areFiltersApplied) {
    return (
      <div className={styles.noResultScreen}>
        <img src={noResultAd} width={48} height={48} alt="no result audience" />
        <span className={styles.noResultText}>
          No search results found. Try changing the filters or use a different
          keyword.
        </span>
      </div>
    );
  }

  if (!data.whatsappTemplates.edges.length) {
    return (
      <div className={styles.emptyScreen}>
        <img src={createAdImage} width={48} height={48} alt="create audience" />
        <span className={styles.emptyScreenText}>
          Oops! It looks like you don't have an ads for your campaign launch.
        </span>
        <div className={styles.buttonWrapper}>
          <Button size="micro" to="/library/ad-library">
            <div className={styles.createAudienceBtnContent}>
              <img
                src={UserAddIcon}
                width={16}
                height={16}
                role="none"
                alt="user add icon"
              />
              Create Ads
            </div>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={data.whatsappTemplates.edges.length}
      scrollableTarget="scrollTargetForInfiniteScroll"
      next={fetchMoreAdTemplates}
      hasMore={data.whatsappTemplates.pageInfo.hasNextPage}
      loader={<Spinner height={200} />}
    >
      {data.whatsappTemplates.edges.map((whatsappTemplate) => (
        <WhatsappTemplateItem
          selectedWhatsappTemplate={selectedWhatsappTemplate}
          setSelectedWhatsappTemplate={setSelectedWhatsappTemplate}
          whatsappTemplate={whatsappTemplate}
        />
      ))}
    </InfiniteScroll>
  );
}
