import { AppLayout } from "./components/AppLayout/AppLayout";
import { RequiresAuth } from "./components/RequiresAuth";
import { LoginEmailConfirm } from "./modules/auth/pages/LoginEmailConfirm";
import { SignupEmailConfirm } from "./modules/auth/pages/SignupEmailConfirm";
import { AudienceListingPage } from "./modules/audience/pages/AudienceListingPageV2";
import ShopifyAuthCallbackHandler from "./pages/Auth/Shopify/ShopifyAuthCallbackHandler";
import { CampaignListingPage } from "./modules/campaign/pages/CampaignListingPage";
import { CreateCampaignPage } from "./modules/campaign-wizard/pages/CreateCampaignPage";
import { CreateMessageTemplate } from "./pages/CreateTemplate/CreateMessageTemplate";
import { CustomerListingPage } from "./modules/customer/pages/CustomerListingPage";
import { CustomerSinglePage } from "./modules/customer/pages/CustomerSinglePage";
import { EmailBuilderPage } from "./pages/EmailBuilder/EmailBuilderPage";
import { NotFound } from "./modules/global/pages/NotFound";
import { AboutBusiness } from "./modules/onboarding/pages/AboutBusiness";
import { PreviewEmail } from "./pages/PreviewEmail/PreviewEmail";
import { SegmentListingPage } from "./modules/segment/pages/SegmentListingPage";
import { EmailIntegration } from "./pages/Settings/Integration/EmailIntegration/EmailIntegration";
import { Integration } from "./pages/Settings/Integration/Integration";
import { MessageIntegration } from "./pages/Settings/Integration/MessageIntegration/MessageIntegration";
import { SettingsPricing } from "./modules/billing/pages/SettingsPricing";
import AutomationSinglePage from "./pages/automations/AutomationsPage/AutomationSinglePage";
import AutomationsPage from "./pages/automations/AutomationsPage/AutomationsPage";
import { CreateAutomationLayout } from "./pages/automations/builder/CreateAutomationLayout/CreateAutomationLayout";
import CreateAutomationPage from "./pages/automations/builder/CreateAutomationPage/CreateAutomationPage";
import { MagicSignup } from "./modules/auth/pages/MagicSignup";
import { SignupEmailSent } from "./modules/auth/pages/SignupEmailSent";
import { AuthLayout } from "./modules/auth/components/AuthLayout";
import { PasswordRecovery } from "./modules/auth/pages/PasswordRecovery";
import { MagicLogin } from "./modules/auth/pages/MagicLogin";
import { PasswordReset } from "./modules/auth/pages/PasswordReset";
import { PasswordSet } from "./modules/auth/pages/PasswordSet";
import { Login } from "./modules/auth/pages/Login";
import { Navigate, RouteObject } from "react-router-dom";
import { Checkout } from "./modules/billing/pages/Checkout";
import { PaymentSuccessful } from "./modules/billing/pages/PaymentSuccessful";
import { CustomerTagListingPage } from "./modules/customer/pages/CustomerTagListingPage";
import { CustomerTagSinglePage } from "./modules/customer/pages/CustomerTagSinglePage";
import { CustomerCreatePage } from "./modules/customer/pages/CustomerCreatePage";
import { CustomerImportPage } from "./modules/customer/pages/CustomerImportPage";
import { AudienceCustomCreatePage } from "./modules/audience/pages/AudienceCreatePage";
import { CampaignSinglePage } from "./modules/campaign/pages/CampaignSinglePage";
import { ManageTeam } from "./v2/pages/ManageTeam/ManageTeam";
import { GlobalErrorHandler } from "./modules/global/components/GlobalErrorHandler";
import { WhatsappIntegration } from "./modules/whatsappIntegration/pages/WhatsappIntegration";
import { WhatsappIntegrationSingle } from "./modules/whatsappIntegration/pages/WhatsappIntegrationSingle";
import { SegmentSinglePage } from "./modules/segment/pages/SegmentSinglePage";
import { CreatePreviewBasedAudiencePage } from "./modules/audience/pages/CreatePreviewBasedAudiencePage";
import { ShopifyIntegration } from "./modules/shopifyIntegration/pages/ShopifyIntegrationPage";
import MetaIntegration from "./modules/metaIntegration/pages/MetaIntegration";
import { AudienceSingle } from "./modules/audience/pages/AudienceSinglePage";
import { AudienceSetSinglePage } from "./modules/audience/pages/AudienceSetSinglePage";
import { TemplateListing } from "./modules/templates/pages/TemplateListing";
import { TemplateSingleLayout } from "./modules/templates/pages/TemplateSingleLayout";
import { TemplateSingleWhatsappInfo } from "./modules/templates/components/TemplateSingleWhatsappInfo";
import { TemplateSingleSmsInfo } from "./modules/templates/components/TemplateSingleSmsInfo";
import { Dashboard } from "./modules/dashboard/pages/Dashboard";
import { WebsiteIntegrationPage } from "./modules/websiteIntegration/pages/WebsiteIntegrationPage";
import CampaignOverviewPage from "./modules/campaign/pages/CampaignOverviewPage";
import { Paywall } from "./modules/global/pages/Paywall";
import AudienceOverviewPage from "./modules/audience/pages/AudienceOverviewPage";
import CustomerOverviewPage from "./modules/customer/pages/CustomerOverviewPage";
import { ActivitySinglePage } from "./modules/campaign/pages/ActivitySinglePage";
import { CustomerRedirect } from "./modules/customer/pages/CustomerRedirect";
import { AudienceSetCreatePage } from "./modules/audience/pages/AudienceSetCreatePage";
import EngageWaitlistPage from "./modules/global/pages/EngageWaitlistPage";
import LibraryWaitlistPage from "./modules/global/pages/LibraryWaitlistPage";
import AnalyticsWaitlistPage from "./modules/global/pages/AnalyticsWaitlistPage";
import AssetsListingPage from "./modules/media/pages/AssetsListingPage";
import { CreativesListingPage } from "./modules/media/pages/CreativesListingPage";
import { AudienceWorkshopPage } from "./modules/audience/pages/AudienceWorkshopPage";
import { SettingsLayout } from "./modules/settings/pages/SettingsLayout";
import { Accounts } from "./modules/settings/pages/Accounts";
import { Billing } from "./modules/settings/pages/Billing";
import { CampaignWorkshopPage } from "./modules/campaign-wizard/pages/CampaignWorkshopPage";
import WhatsappMessageTemplateCreatePage from "./modules/media/pages/WhatsappMessageTemplateCreatePage";
import AdTemplateCreatePage from "./modules/media/pages/AdTemplateCreatePage";
import { Usage } from "./modules/settings/pages/Usage";
import AdTemplateEditPage from "./modules/media/pages/AdTemplateEditPage";
import Plans from "./modules/settings/pages/Plans";
import { Members } from "./modules/settings/pages/Members";
import { OnboardingPage } from "./modules/onboarding/pages/OnboardingPage";
import { OnboardingPage2 } from "./modules/onboarding/pages/OnboardingPage2";
import { OnboardingPage3 } from "./modules/onboarding/pages/OnboardingPage3";
import { Clients } from "./modules/settings/pages/Clients";
import { Notification } from "./modules/settings/pages/clients/Notification";
import { AcceptInvite } from "./modules/auth/pages/AcceptInvite";
import { OnboardingPage4 } from "./modules/onboarding/pages/OnboardingPage4";

const routes: RouteObject[] = [
  // pages the need the sidebar and are also require the user to be authenticated
  {
    element: (
      <RequiresAuth>
        <AppLayout showSidebar={true} />
      </RequiresAuth>
    ),
    errorElement: <GlobalErrorHandler />,
    children: [
      {
        path: "/",
        errorElement: <GlobalErrorHandler />,
        element: <Dashboard />,
      },
      {
        path: "/dashboard",
        errorElement: <GlobalErrorHandler />,
        element: <Dashboard />,
      },
      {
        path: "*",
        errorElement: <GlobalErrorHandler />,
        element: <NotFound />,
      },
      {
        path: "/contacts/create",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerCreatePage />,
      },
      {
        path: "/contacts",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerListingPage />,
      },
      // Waitlist routes start
      {
        path: "/engage/join-waitlist",
        errorElement: <GlobalErrorHandler />,
        element: <EngageWaitlistPage />,
      },
      {
        path: "/analytics/join-waitlist",
        errorElement: <GlobalErrorHandler />,
        element: <AnalyticsWaitlistPage />,
      },
      {
        path: "/library/media-center",
        errorElement: <GlobalErrorHandler />,
        element: <AssetsListingPage />,
      },
      {
        path: "/library/ad-library",
        errorElement: <GlobalErrorHandler />,
        element: <CreativesListingPage />,
      },
      {
        path: "/library/catalogues",
        errorElement: <GlobalErrorHandler />,
        element: <LibraryWaitlistPage />,
      },
      {
        path: "/library/join-waitlist",
        errorElement: <GlobalErrorHandler />,
        element: <LibraryWaitlistPage />,
      },
      // Waitlist routes end
      {
        path: "/contacts/overview",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerOverviewPage />,
      },
      {
        path: "/contacts/redirect",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerRedirect />,
      },
      {
        path: "/contacts/:id",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerSinglePage />,
      },
      {
        path: "/manage-team",
        errorElement: <GlobalErrorHandler />,
        element: <ManageTeam />,
      },
      {
        path: "/:module/paywall",
        errorElement: <GlobalErrorHandler />,
        element: <Paywall />,
      },
      {
        path: "/settings/pricing",
        errorElement: <GlobalErrorHandler />,
        element: <SettingsPricing />,
      },
      {
        element: <SettingsLayout />,
        children: [
          {
            path: "/settings/account",
            errorElement: <GlobalErrorHandler />,
            element: <Accounts />,
          },
          {
            path: "/settings/billing",
            errorElement: <GlobalErrorHandler />,
            element: <Billing />,
          },
          {
            path: "/settings/usage",
            errorElement: <GlobalErrorHandler />,
            element: <Usage />,
          },
          {
            path: "/settings/plans",
            errorElement: <GlobalErrorHandler />,
            element: <Plans />,
          },
          {
            path: "/settings/clients",
            errorElement: <GlobalErrorHandler />,
            element: <Clients />,
          },
          {
            path: "/settings/members",
            errorElement: <GlobalErrorHandler />,
            element: <Members />,
          },
          {
            path: "/settings/notifications",
            errorElement: <GlobalErrorHandler />,
            element: <Notification />,
          },
          {
            path: "/settings/integrations",
            errorElement: <GlobalErrorHandler />,
            element: <Integration />,
          },
          // {
          //   path: "/settings/configure",
          //   errorElement: <GlobalErrorHandler />,
          //   element: <Billing />,
          // },
        ],
      },
      {
        path: "/settings/integrations/shopify",
        errorElement: <GlobalErrorHandler />,
        element: <ShopifyIntegration />,
      },
      {
        path: "/settings/integrations/meta",
        errorElement: <GlobalErrorHandler />,
        element: <MetaIntegration />,
      },
      {
        path: "/settings/integrations/whatsapp/setup",
        errorElement: <GlobalErrorHandler />,
        element: <WhatsappIntegration />,
      },
      {
        path: "/settings/integrations/whatsapp",
        errorElement: <GlobalErrorHandler />,
        element: <WhatsappIntegrationSingle />,
      },
      {
        path: "/settings/integrations/message",
        errorElement: <GlobalErrorHandler />,
        element: <MessageIntegration />,
      },
      {
        path: "/settings/integrations/website",
        errorElement: <GlobalErrorHandler />,
        element: <WebsiteIntegrationPage />,
      },
      {
        path: "/settings/integrations/email",
        errorElement: <GlobalErrorHandler />,
        element: <EmailIntegration />,
      },
      {
        path: "/customer-tags/:tagId",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerTagSinglePage />,
      },
      {
        path: "/templates",
        errorElement: <GlobalErrorHandler />,
        // element: <Content />,
        element: <TemplateListing />,
      },
      {
        element: <TemplateSingleLayout />,
        children: [
          {
            path: "/templates/whatsapp/:id",
            errorElement: <GlobalErrorHandler />,
            element: <TemplateSingleWhatsappInfo />,
          },
          {
            path: "/templates/sms/:id",
            errorElement: <GlobalErrorHandler />,
            element: <TemplateSingleSmsInfo />,
          },
        ],
      },
      {
        path: "/customer-tags",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerTagListingPage />,
      },
      {
        path: "/segments",
        errorElement: <GlobalErrorHandler />,
        element: <SegmentListingPage />,
      },
      // {
      //   path: "/segments/:id",
      //   errorElement: <GlobalErrorHandler />,
      //   element: <SegmentSinglePage />,
      // },
      {
        path: "/segments/:id",
        errorElement: <GlobalErrorHandler />,
        element: <SegmentSinglePage />,
      },
      {
        path: "/audiences",
        errorElement: <GlobalErrorHandler />,
        element: <AudienceListingPage />,
      },
      {
        path: "/audiences/overview",
        errorElement: <GlobalErrorHandler />,
        element: <AudienceOverviewPage />,
      },
      {
        path: "/audiences/new",
        errorElement: <GlobalErrorHandler />,
        element: <AudienceWorkshopPage />,
      },
      {
        path: "/audiences/:id",
        errorElement: <GlobalErrorHandler />,
        element: <AudienceSingle />,
      },
      {
        path: "/audience-sets/:id",
        errorElement: <GlobalErrorHandler />,
        element: <AudienceSetSinglePage />,
      },
      {
        path: "/automations",
        errorElement: <GlobalErrorHandler />,
        element: <AutomationsPage />,
      },
      {
        path: "/campaigns",
        errorElement: <GlobalErrorHandler />,
        element: <CampaignListingPage />,
      },
      {
        path: "/campaigns/overview",
        errorElement: <GlobalErrorHandler />,
        element: <CampaignOverviewPage />,
      },
      {
        path: "/campaigns/:id",
        errorElement: <GlobalErrorHandler />,
        element: <CampaignSinglePage />,
      },
      {
        path: "/campaigns/new/",
        errorElement: <GlobalErrorHandler />,
        element: <CampaignWorkshopPage />,
      },
      {
        path: "/campaigns/:campaignId/activities/:id",
        errorElement: <GlobalErrorHandler />,
        element: <ActivitySinglePage />,
      },
    ],
  },

  // TODO: Add way to modify header
  {
    element: (
      <RequiresAuth>
        <AppLayout showSidebar={false} />
      </RequiresAuth>
    ),
    children: [
      {
        path: "templates/builder/email/:templateId",
        errorElement: <GlobalErrorHandler />,
        element: <EmailBuilderPage />,
      },
      {
        path: "/contacts/import",
        errorElement: <GlobalErrorHandler />,
        element: <CustomerImportPage />,
      },
      {
        path: "/library/ad-library/create",
        errorElement: <GlobalErrorHandler />,
        element: <AdTemplateCreatePage />,
      },
      {
        path: "/library/message-library/create",
        errorElement: <GlobalErrorHandler />,
        element: <WhatsappMessageTemplateCreatePage />,
      },
      {
        path: "/library/ad-library/:id/edit",
        errorElement: <GlobalErrorHandler />,
        element: <AdTemplateEditPage />,
      },
      {
        path: "/campaigns/new/",
        errorElement: <GlobalErrorHandler />,
        element: <CreateCampaignPage />,
      },
      {
        path: "/audiences-sets/new",
        errorElement: <GlobalErrorHandler />,
        element: <AudienceSetCreatePage />,
      },
      {
        path: "/templates/builder/whatsapp/:templateId",
        errorElement: <GlobalErrorHandler />,
        element: <CreateMessageTemplate platform="WHATSAPP" />,
      },
      {
        path: "/templates/builder/sms/:templateId",
        errorElement: <GlobalErrorHandler />,
        element: <CreateMessageTemplate platform="SMS" />,
      },
      {
        path: "/automations/:id",
        errorElement: <GlobalErrorHandler />,
        element: <AutomationSinglePage />,
      },
      {
        path: "/audiences/template/create",
        errorElement: <GlobalErrorHandler />,
        element: <CreatePreviewBasedAudiencePage />,
      },
      {
        path: "/audiences/custom/create",
        errorElement: <GlobalErrorHandler />,
        element: <AudienceCustomCreatePage />,
      },
      {
        path: "/automations/new",
        errorElement: <GlobalErrorHandler />,
        element: (
          <RequiresAuth>
            <CreateAutomationLayout />,
          </RequiresAuth>
        ),
        children: [
          {
            path: "/automations/new",
            errorElement: <GlobalErrorHandler />,
            element: <CreateAutomationPage />,
          },
        ],
      },
    ],
  },
  {
    element: <ShopifyAuthCallbackHandler />,
    path: "/auth/shopify/callback",
    errorElement: <GlobalErrorHandler />,
  },
  // pages with no header and sidebar
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/auth/password-recovery",
        errorElement: <GlobalErrorHandler />,
        element: <PasswordRecovery />,
      },
      {
        path: "/auth/login-magic",
        errorElement: <GlobalErrorHandler />,
        element: <MagicLogin />,
      },
      {
        path: "/auth/login/email-confirmation",
        errorElement: <GlobalErrorHandler />,
        element: <LoginEmailConfirm />,
      },
      {
        path: "/auth/password-set",
        errorElement: <GlobalErrorHandler />,
        element: <PasswordSet />,
      },
    ],
  },
  {
    // element: <SignupLayout />,
    children: [
      {
        path: "/auth/signup",
        errorElement: <GlobalErrorHandler />,
        element: <OnboardingPage />,
      },
      {
        path: "/auth/signup-magic",
        errorElement: <GlobalErrorHandler />,
        element: <MagicSignup />,
      },
      {
        path: "/auth/signup/email-sent",
        errorElement: <GlobalErrorHandler />,
        element: <SignupEmailSent />,
      },
      {
        path: "/auth/signup/email-confirmation",
        errorElement: <GlobalErrorHandler />,
        element: <SignupEmailConfirm />,
      },
    ],
  },
  {
    path: "/auth/password-reset",
    errorElement: <GlobalErrorHandler />,
    element: <PasswordReset />,
  },
  {
    path: "/auth/accept-invite",
    errorElement: <GlobalErrorHandler />,
    element: <AcceptInvite />,
  },
  {
    path: "/auth/login",
    errorElement: <GlobalErrorHandler />,
    element: <Login />,
  },
  {
    path: "/onboarding",
    errorElement: <GlobalErrorHandler />,
    element: <Navigate to="/onboarding/about-you" />,
  },
  {
    path: "/onboarding/about-you",
    errorElement: <GlobalErrorHandler />,
    element: <OnboardingPage2 />,
  },
  {
    path: "/onboarding/about-business",
    errorElement: <GlobalErrorHandler />,
    element: <AboutBusiness />,
  },
  {
    path: "/onboarding/pricing",
    errorElement: <GlobalErrorHandler />,
    element: <OnboardingPage3 />,
  },
  {
    path: "/billing/checkout",
    errorElement: <GlobalErrorHandler />,
    element: <Checkout />,
  },
  {
    path: "/onboarding/success",
    errorElement: <GlobalErrorHandler />,
    element: <OnboardingPage4 />,
  },
  {
    path: "/email/preview/:id",
    errorElement: <GlobalErrorHandler />,
    element: <PreviewEmail />,
  },
];

export default routes;
