import { Segment } from "src/graphql/generated/schema";
import { ReactComponent as Settings } from "@material-design-icons/svg/outlined/settings.svg";
import styles from "./SegmentTitleWithIcon.module.scss";
import format from "date-fns/format";
import classNames from "classnames";

interface ISegmentTitleWithIcon {
  segment: Segment;
  iconColor?: "primary" | "warn" | "success";
}

export function SegmentTitleWithIcon({
  segment,
  iconColor = "primary",
}: ISegmentTitleWithIcon) {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.icon, styles[iconColor])}>
        <Settings width={20} height={20} />
      </div>

      <div className={styles.name}>
        <div className={styles.title}>{segment.name}</div>
        <div className={styles.subtitle}>
          Created {format(new Date(segment.createdAt), "dd MMM yyyy")}
        </div>
      </div>
    </div>
  );
}
