import { Dialog, Transition } from "@headlessui/react";
import styles from "./Slider.module.scss";
import { Fragment, MutableRefObject } from "react";
import { IconButton } from "src/components/IconButton/IconButton";
import { ReactComponent as ArrowLeft } from "@material-symbols/svg-600/rounded/arrow_back.svg";
import classNames from "classnames";

interface ISlider {
  isOpen: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  panelClassNames?: string;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  closeOverlayClick?: boolean;
}

export default function Slider({
  isOpen,
  title,
  children,
  onClose,
  panelClassNames,
  initialFocus,
  closeOverlayClick = true,
}: ISlider) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={styles.dialog}
        onClose={closeOverlayClick ? onClose : () => {}}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter={styles.backdropEnter}
          enterFrom={styles.backdropEnterFrom}
          enterTo={styles.backdropEnterTo}
          leave={styles.backdropLeave}
          leaveFrom={styles.backdropLeaveFrom}
          leaveTo={styles.backdropLeaveTo}
        >
          <div className={styles.backdrop} />
        </Transition.Child>

        <div className={styles.panelWrapper}>
          <Transition.Child
            as={Fragment}
            enter={styles.panelEnter}
            enterFrom={styles.panelEnterFrom}
            enterTo={styles.panelEnterTo}
            leave={styles.panelLeave}
            leaveFrom={styles.panelLeaveFrom}
            leaveTo={styles.panelLeaveTo}
          >
            <Dialog.Panel className={classNames(styles.panel, panelClassNames)}>
              <Dialog.Title className={styles.dialogTitleWrapper}>
                <IconButton
                  className={styles.arrowLeftButton}
                  icon={
                    <ArrowLeft
                      className={styles.arrowLeftIco}
                      height={26}
                      width={26}
                    />
                  }
                  style="plain"
                  onClick={onClose}
                />
                <div className={styles.dialogTitle}>{title}</div>
              </Dialog.Title>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
