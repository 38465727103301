import Avatar from "react-avatar";
import { Asset } from "../../../components/Smartphone/Asset";
import {
  Asset as IAsset,
  Organization,
} from "../../../graphql/generated/schema";
import { SectionHeading } from "../../global/components/SectionHeading";
import { AddMedia } from "../../media/components/AdTemplateCreateSlideOver/AddMedia";
import styles from "./OrganizationProfile.module.scss";

export function OrganizationProfile({
  organization,
  updateThumbnail,
}: {
  organization: Organization;
  updateThumbnail: (i: IAsset) => void;
}) {
  return (
    <div>
      <SectionHeading
        heading={"Organization Profile"}
        description={"Manage your client information and setting"}
      />

      <div className={styles.uploadSection}>
        {organization?.Thumbnail ? (
          <Asset
            type={organization.Thumbnail?.contentType}
            preview={organization?.Thumbnail?.uri}
            name={organization?.Thumbnail?.name}
            className={styles.asset}
          />
        ) : (
          <Avatar name={organization.name} className={styles.asset} />
        )}

        <div className={styles.actionContainer}>
          <div>
            <AddMedia
              multiple={false}
              triggerLabel={"Upload"}
              onSelect={function (i: IAsset[]): void {
                const asset = i[0];
                updateThumbnail(asset);
              }}
              buttonProps={{
                size: "micro",
                color: "subdued",
                style: "outline",
              }}
            />
          </div>
          <div className={styles.description}>
            Pick a logo for your organisation. Recommended size is 256px x 256px
          </div>
        </div>
      </div>
    </div>
  );
}
