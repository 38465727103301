import {
  useBillingLatestCardQuery,
  useGetBillingQuery,
  useGetUserQuery,
  useOrganizationUsageQuery,
} from "src/graphql/generated/schema";
import styles from "./Billing.module.scss";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { SectionHeading } from "../../../modules/global/components/SectionHeading";
import EmptyState from "../../../components/EmptyState/EmptyState";
import walletIllustration from "../../../modules/billing/assets/wallet.svg";
import { PlanDetails } from "../components/PlanDetails";
import { BillingInformation } from "../components/BillingInformation";
import { BillHistory } from "../components/BillHistory";

export function Billing() {
  const {
    data: billingData,
    loading: billingLoading,
    error: billingError,
  } = useGetBillingQuery();
  const {
    data: billingLatestCardData,
    loading: billingLatestCardLoading,
    error: billingLatestCardError,
  } = useBillingLatestCardQuery();
  const {
    data: _organizationUsageData,
    loading: organizationLoading,
    error: organizationError,
  } = useOrganizationUsageQuery();

  const {
    data: getUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useGetUserQuery();

  if (
    billingLoading ||
    organizationLoading ||
    getUserLoading ||
    billingLatestCardLoading
  ) {
    return <PageLoader />;
  }

  if (billingError) {
    return <ErrorHandler error={billingError} />;
  }

  if (organizationError) {
    return <ErrorHandler error={organizationError} />;
  }

  if (getUserError) {
    return <ErrorHandler error={getUserError} />;
  }

  if (billingLatestCardError) {
    return <ErrorHandler error={billingLatestCardError} />;
  }

  return (
    <div className={styles.page}>
      {billingData.billing && <PlanDetails billing={billingData.billing} />}
      <div className={styles.details}>
        <div className={styles.item}>
          <BillingInformation
            billingCard={billingLatestCardData.billingLatestCard}
            billing={billingData.billing}
            organization={{
              email: getUserData.me.email,
              Address: getUserData.me.Organization.Address,
              id: getUserData.me.id,
              name: getUserData.me.name,
            }}
          />
        </div>
        <div className={styles.item}>
          {/* <UpcomingBill billing={billingData.billing} /> */}
          <SectionHeading heading={"Wallet"} />
          <div className={styles.emptyState}>
            <EmptyState
              title={"Credits for SMS and Whatsapp"}
              illustration={walletIllustration}
              action={{
                title: "Subscribe to Engage",
                to: "/billing",
              }}
            >
              <div className={styles.textCenter}>
                You need to subscribe Macro Engage to send SMS and WhatsApp to
                your customers and segment
              </div>
            </EmptyState>
          </div>
        </div>
      </div>
      <BillHistory billing={billingData.billing} />
    </div>
  );
}
