import powerfulCustomerSegmentation from "../assets/audiencePlanFeatCard1.png";
import multiPlatformAccess from "../assets/multiPlatformAccess.png";
import analytics from "../assets/audiencePlanFeatCard3.png";
import magnet from "../assets/magnet.svg";
import branch from "../assets/branchIco.svg";
import { UpgradePlanFeatCard } from "./UpgradePlanFeatCard";

export function UpgradePlanAudience() {
  const automateArr = [
    {
      icon: <img src={branch} alt="magnet" width={20} height={20} />,
      title: "Powerful customer segmentation",
      subtitle:
        "From deployments to tasks, work with your team every step of the way.",
      image: powerfulCustomerSegmentation,
    },
    {
      icon: <img src={magnet} alt="magnet" width={20} height={20} />,
      title: "Multi-Platform",
      subtitle:
        "From deployments to tasks, work with your team every step of the way.",
      image: multiPlatformAccess,
    },
    {
      icon: <img src={magnet} alt="magnet" width={20} height={20} />,
      title: "Analytics",
      subtitle:
        "From deployments to tasks, work with your team every step of the way.",
      image: analytics,
    },
  ];

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: "1.4rem",
        paddingTop: "2.25rem",
      }}
    >
      {automateArr.map((item, idx) => (
        <UpgradePlanFeatCard {...item} key={idx} primaryColor="grow" />
      ))}
    </div>
  );
}
