import emptyAudience from "../assets/emptyAudience.png";
import EmptyState from "../../../components/EmptyState/EmptyState";
import styles from "./AudienceEmptyState.module.scss";

export function AudienceEmptyState() {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyState
        title={"You don't have any audience"}
        image={
          <img
            src={emptyAudience}
            alt="emptyAudienceSet"
            width={220}
            height={215}
          />
        }
        children={
          <p className={styles.description}>
            Create an audience and who can interact with your Ads or content
            whichever you shared on Meta platform
          </p>
        }
        action={{
          title: "Create New Audience",
          to: "/audiences/new",
        }}
      />
    </div>
  );
}
