import { InputFormik } from "src/components/InputFormik/InputFormik";
import onboarding2 from "../assets/onboarding2.png";
import { Formik } from "formik";
import { Form, useNavigate } from "react-router-dom";
import { useOrganizationCreateMutation } from "src/graphql/generated/schema";
import { Button } from "src/components/Button/Button";
import { ReactComponent as OrganizationIcon } from "@material-symbols/svg-600/rounded/corporate_fare.svg";
import styles from "./OnboardingPage2.module.scss";
import { OnboardingLayout2 } from "../components/OnboardingLayout2";
import Select from "src/components/Select/Select";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const roleOptions = [
  {
    label: "Designer",
    value: "designer",
  },
  {
    label: "Market Manager",
    value: "market manager",
  },
  {
    label: "Business Analyst",
    value: "business analyst",
  },
  {
    label: "Social Media Manager",
    value: "social media manager",
  },
  {
    label: "Product Manager",
    value: "product manager",
  },
];

const companySizeOptions = [
  {
    label: "1-10 People",
    value: "1-10",
  },
  {
    label: "11-100 People",
    value: "11-100",
  },
  {
    label: "101-5000 People",
    value: "1-10",
  },
  {
    label: "5000+ People",
    value: "5000-50000",
  },
];

export function OnboardingPage2() {
  const { logout } = useContext(AuthContext);
  const [organizationCreate] = useOrganizationCreateMutation();
  const navigate = useNavigate();
  const shopifyIntegration = localStorage.getItem("shopifyIntegration");

  return (
    <Formik
      initialValues={{
        organizationName: "",
        companySize: "",
        userDesignation: "",
      }}
      onSubmit={async (values) => {
        let shopifyShopName;

        if (shopifyIntegration) {
          const data = JSON.parse(shopifyIntegration);
          shopifyShopName = data.shopifyShopName;
        }

        await organizationCreate({
          variables: {
            input: {
              name: values.organizationName,
              ...(shopifyShopName && { shopifyShopName }),
            },
          },
        });
        navigate("/onboarding/pricing");
      }}
    >
      {({
        isSubmitting,
        setFieldValue,
        handleSubmit,
        values,
        errors,
        touched,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <OnboardingLayout2 sideImage={onboarding2}>
              <div className={styles.card}>
                <h1 className={styles.heading}>Create a new organisation</h1>
                <p className={styles.copy}>
                  Organisation are shared environment where teams can work on
                  different or shared clients
                </p>

                <div className={styles.formFields}>
                  <div className={styles.inputs}>
                    <InputFormikV2
                      label=""
                      icon={OrganizationIcon}
                      placeholder="Organization Name"
                      name="organizationName"
                    />

                    <SelectV2
                      placeholder="Select a designation"
                      options={roleOptions}
                      value={values.userDesignation}
                      onChange={(value) =>
                        setFieldValue("userDesignation", value)
                      }
                      label="What's your designation?"
                      name="userDesignation"
                      error={
                        !!errors.userDesignation &&
                        touched.userDesignation &&
                        errors.userDesignation
                      }
                    />
                    <SelectV2
                      placeholder="Select your company size"
                      options={companySizeOptions}
                      value={values.companySize}
                      onChange={(value) => setFieldValue("companySize", value)}
                      label="How big is your company?"
                      name="companySize"
                      error={
                        !!errors.companySize &&
                        touched.companySize &&
                        errors.companySize
                      }
                    />
                  </div>

                  <ButtonV2 type="submit" loading={isSubmitting}>
                    Create Organization
                  </ButtonV2>
                </div>

                <div className={styles.tocAndNavigation}>
                  <ButtonV2 variant="plain" onClick={logout}>
                    Logout
                  </ButtonV2>
                </div>
              </div>
            </OnboardingLayout2>
          </Form>
        );
      }}
    </Formik>
  );
}
