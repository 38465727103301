import {
  CustomerDocument,
  CustomerNote,
  useCustomerNoteCreateMutation,
  useCustomerNoteDeleteMutation,
} from "src/graphql/generated/schema";
import { Button } from "src/components/Button/Button";
import { ReactComponent as Delete } from "@material-symbols/svg-400/rounded/delete.svg";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import format from "date-fns/format";
import styles from "./SingleCustomerNotes.module.scss";
import { useState } from "react";

interface SingleCustomerNotesProps {
  customerNotes: CustomerNote[];
  customerId: string;
  // userId: string;
}

export function SingleCustomerNotes({
  customerNotes,
  customerId,
}: // userId,
SingleCustomerNotesProps) {
  const [noteInput, setNoteInput] = useState("");
  const [customerNoteCreateFunc, { loading: creatingCustomerNote }] =
    useCustomerNoteCreateMutation();
  const [customerNoteDeleteFunc, { loading: deletingCustomerNote }] =
    useCustomerNoteDeleteMutation();

  return (
    <div>
      <h2 className={styles.heading}>Add Note</h2>
      <div>
        <MultilineInput
          placeholder="Add additional details of your ads"
          onChange={(s) => setNoteInput(s)}
          value={noteInput}
        />
        <div className={styles.addNoteWrapper}>
          <Button
            color="primary"
            // style="outline"
            size="small"
            loading={creatingCustomerNote}
            disabled={creatingCustomerNote || noteInput.trim().length === 0}
            onClick={async () => {
              await customerNoteCreateFunc({
                variables: {
                  input: {
                    content: noteInput,
                    customerId: customerId,
                  },
                },
                refetchQueries: [
                  {
                    query: CustomerDocument,
                    variables: {
                      id: customerId,
                    },
                  },
                ],
                awaitRefetchQueries: true,
              });
              setNoteInput("");
            }}
          >
            Add Notes
          </Button>
        </div>
      </div>
      <div className={styles.notesSection}>
        <h2 className={styles.heading}>Previous added notes</h2>
        <div className={styles.notesList}>
          {customerNotes.map((item, index) => (
            <div key={index} className={styles.note}>
              <p className={styles.noteText}>
                <div className={styles.noteTitle}>
                  <p className={styles.date}>
                    Added on {format(new Date(item.createdAt), "dd MMMM yyyy")}
                  </p>
                  <Button
                    style="plain"
                    size="small"
                    color="danger"
                    className={styles.deleteBtn}
                    loading={deletingCustomerNote}
                    // disabled={item.userId !== userId || deletingCustomerNote}
                    onClick={async () => {
                      await customerNoteDeleteFunc({
                        variables: {
                          id: item.id,
                        },
                        refetchQueries: [
                          {
                            query: CustomerDocument,
                            variables: {
                              id: customerId,
                            },
                          },
                        ],
                        awaitRefetchQueries: true,
                      });
                    }}
                  >
                    <span>Delete</span>{" "}
                    <Delete
                      width={18}
                      height={18}
                      fill={
                        // item.userId !== userId
                        "var(--color-disabled)"
                        // : "var(--color-danger)"
                      }
                    />
                  </Button>
                </div>
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
