import Alert from "../../../../components/Alert/Alert";
import illustration from "../../assets/illustration.png";
import styles from "./AssetDeleteAlert.module.scss";

interface IAssetDeleteAlert {
  showAssetDeleteAlert: boolean;
  setShowAssetDeleteAlert: (i: boolean) => void;
  deleteAssetHandler: (i: string) => Promise<void>;
  selectedAssetId?: string;
  assetDeleteLoading?: boolean;
}

export function AssetDeleteAlert({
  showAssetDeleteAlert,
  setShowAssetDeleteAlert,
  deleteAssetHandler,
  selectedAssetId,
  assetDeleteLoading,
}: IAssetDeleteAlert) {
  return (
    <Alert
      isOpen={showAssetDeleteAlert}
      title={"Do you want to delete this media?"}
      onClose={() => setShowAssetDeleteAlert(false)}
      primaryAction={{
        children: "Delete Asset",
        onClick: async () => {
          await deleteAssetHandler(selectedAssetId);
          setShowAssetDeleteAlert(false);
        },
        color: "danger",
        loading: assetDeleteLoading,
      }}
      secondaryAction={{
        children: "Cancel",
        color: "subdued",
        style: "outline",
        onClick: () => setShowAssetDeleteAlert(false),
        disabled: assetDeleteLoading,
      }}
      showCloseButton={false}
    >
      <div className={styles.deleteModal}>
        <div className={styles.deleteText}>
          If you delete this media you are no longer able to use this media
          further for your future campaigns
        </div>
        {/*
        <div>
          <img
            src={illustration}
            alt={illustration}
            className={styles.deleteIllustration}
          />
        </div>
        */}
      </div>
    </Alert>
  );
}
