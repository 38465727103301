import {
  TextMessageTemplateCreateMutationHookResult,
  TextMessageTemplateUpdateMutationHookResult,
} from "src/graphql/generated/schema";
import { MessageFormInput } from "../CreateMessageTemplateTypes";

export async function upsertTextMessageTemplate({
  templateId,
  values,
  textMessageTemplateCreate,
  textMessageTemplateUpdate,
}: {
  templateId: string;
  values: MessageFormInput;
  textMessageTemplateCreate: TextMessageTemplateCreateMutationHookResult[0];
  textMessageTemplateUpdate: TextMessageTemplateUpdateMutationHookResult[0];
}) {
  if (templateId === "new") {
    await textMessageTemplateCreate({
      variables: {
        input: {
          content: values.content,
          name: values.name,
          ...(values.dltId && { dltTemplateId: values.dltId }),
        },
      },
    });
  } else {
    await textMessageTemplateUpdate({
      variables: {
        id: templateId,
        input: {
          content: values.content,
          name: values.name,
          ...(values.dltId && { dltTemplateId: values.dltId }),
        },
      },
    });
  }
}
