import format from "date-fns/format";
import { formatRelativeTime } from "./formatRelativeTime";

interface FormatTimeOptions {
  showTimeWithDate?: boolean;
  showRelativeTimeForToday?: boolean;
}

export function formatTime(datetime: Date, options?: FormatTimeOptions) {
  const { showRelativeTimeForToday = true, showTimeWithDate = true } =
    options ?? {};
  const isoDate = datetime.toISOString();
  const [dateStr, _timeStr] = isoDate.split("T");
  const currentDatetime = new Date();
  const [todayDateStr, _todayTimeStr] = currentDatetime
    .toISOString()
    .split("T");

  if (todayDateStr === dateStr && showRelativeTimeForToday) {
    return formatRelativeTime(datetime, currentDatetime);
  }

  const formatStr = showTimeWithDate ? `dd MMM, yyyy hh:mm aa` : `dd MMM, yyyy`;

  return format(datetime, formatStr);
}
