import { formatNumberByType } from "../functions/formatNumberByType";
import styles from "./SpendBreakdownWidget.module.scss";
import { ReactComponent as FacebookIcon } from "../assets/meta.svg";
import { ReactComponent as GoogleAdsIcon } from "../assets/Google-Ads.svg";
import { GrowAggregateType } from "../misc/GrowAggregateType";
import { useGetActiveCurrencyCode } from "../functions/useGetActiveCurrencyCode";

const ComingSoonText = () => (
  <div
    style={{
      fontSize: "12px",
      opacity: 0.4,
    }}
  >
    Coming Soon
  </div>
);

function SpendBreakdownWidget({ insights }: { insights: GrowAggregateType }) {
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const items = [
    {
      icon: <FacebookIcon />,
      value: formatNumberByType(insights?.spend, "CURRENCY", {
        showDecimal: true,
        selectedCurrencyCode,
      }),
      label: "Meta Ad Spends",
    },
    {
      icon: <GoogleAdsIcon />,
      value: <ComingSoonText />,
      label: "Google Ad Spends",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="18"
          viewBox="0 0 21 18"
          fill="none"
        >
          <g clip-path="url(#clip0_3532_32210)">
            <path
              d="M0.078125 5.88002C0.078125 4.19986 0.078125 3.35978 0.405105 2.71805C0.692726 2.15356 1.15167 1.69462 1.71615 1.407C2.35789 1.08002 3.19797 1.08002 4.87813 1.08002H15.2781C16.9583 1.08002 17.7984 1.08002 18.4401 1.407C19.0046 1.69462 19.4635 2.15356 19.7511 2.71805C20.0781 3.35978 20.0781 4.19986 20.0781 5.88002V12.28C20.0781 13.9602 20.0781 14.8003 19.7511 15.442C19.4635 16.0065 19.0046 16.4654 18.4401 16.753C17.7984 17.08 16.9583 17.08 15.2781 17.08H4.87813C3.19797 17.08 2.35789 17.08 1.71615 16.753C1.15167 16.4654 0.692726 16.0065 0.405105 15.442C0.078125 14.8003 0.078125 13.9602 0.078125 12.28V5.88002Z"
              fill="#FFA561"
            />
            <path
              d="M0.078125 5.88002C0.078125 4.19986 0.078125 3.35978 0.405105 2.71805C0.692726 2.15356 1.15167 1.69462 1.71615 1.407C2.35789 1.08002 3.19797 1.08002 4.87813 1.08002H15.2781C16.9583 1.08002 17.7984 1.08002 18.4401 1.407C19.0046 1.69462 19.4635 2.15356 19.7511 2.71805C20.0781 3.35978 20.0781 4.19986 20.0781 5.88002V12.28C20.0781 13.9602 20.0781 14.8003 19.7511 15.442C19.4635 16.0065 19.0046 16.4654 18.4401 16.753C17.7984 17.08 16.9583 17.08 15.2781 17.08H4.87813C3.19797 17.08 2.35789 17.08 1.71615 16.753C1.15167 16.4654 0.692726 16.0065 0.405105 15.442C0.078125 14.8003 0.078125 13.9602 0.078125 12.28V5.88002Z"
              fill="black"
              fill-opacity="0.16"
            />
            <path
              d="M0.00331489 3.7902C0.00873828 2.20347 1.29656 0.920044 2.8833 0.920044H17.28C18.8706 0.920044 20.16 2.20946 20.16 3.80004V4.76004L11.0322 9.97573C10.4419 10.313 9.71726 10.313 9.12699 9.97569L0 4.76004L0.00331489 3.7902Z"
              fill="#FFD1AD"
            />
          </g>
          <defs>
            <clipPath id="clip0_3532_32210">
              <rect
                width="20.16"
                height="16.16"
                fill="white"
                transform="translate(0 0.920044)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      value: <ComingSoonText />,
      label: "Email Sends",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 12C21 16.9706 16.9706 21 12 21C10.8029 21 9.6603 20.7663 8.61549 20.3419C8.41552 20.2607 8.31554 20.2201 8.23472 20.202C8.15566 20.1843 8.09715 20.1778 8.01613 20.1778C7.9333 20.1778 7.84309 20.1928 7.66265 20.2229L4.10476 20.8159C3.73218 20.878 3.54589 20.909 3.41118 20.8512C3.29328 20.8007 3.19933 20.7067 3.14876 20.5888C3.09098 20.4541 3.12203 20.2678 3.18413 19.8952L3.77711 16.3374C3.80718 16.1569 3.82222 16.0667 3.82221 15.9839C3.8222 15.9028 3.81572 15.8443 3.798 15.7653C3.77988 15.6845 3.73927 15.5845 3.65806 15.3845C3.23374 14.3397 3 13.1971 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            fill="#2C6ECB"
          />
          <rect x="8" y="9" width="5" height="1.5" rx="0.75" fill="#2C6ECB" />
          <rect x="8" y="9" width="5" height="1.5" rx="0.75" fill="white" />
          <rect x="8" y="12" width="9" height="1.5" rx="0.75" fill="#2C6ECB" />
          <rect x="8" y="12" width="9" height="1.5" rx="0.75" fill="white" />
        </svg>
      ),
      value: <ComingSoonText />,
      label: "SMS Sends",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.0742 4.92804C16.9922 2.84504 14.1522 1.79404 11.1702 2.03404C7.16121 2.35604 3.64721 5.14404 2.47121 8.99004C1.63121 11.738 1.98421 14.607 3.35221 16.977L2.05621 21.28C1.93221 21.693 2.30921 22.082 2.72621 21.971L7.23021 20.764C8.68921 21.56 10.3312 21.979 12.0032 21.98H12.0072C16.2022 21.98 20.0782 19.414 21.4192 15.439C22.7252 11.563 21.7592 7.61604 19.0742 4.92804ZM16.8952 15.554C16.6872 16.137 15.6682 16.699 15.2102 16.74C14.7522 16.782 14.3232 16.947 12.2152 16.116C9.67821 15.116 8.07621 12.515 7.95221 12.349C7.82721 12.182 6.93321 10.996 6.93321 9.76804C6.93321 8.54004 7.57821 7.93604 7.80721 7.68704C8.03621 7.43704 8.30621 7.37504 8.47321 7.37504C8.63921 7.37504 8.80621 7.37504 8.95121 7.38104C9.12921 7.38804 9.32621 7.39704 9.51321 7.81204C9.73521 8.30604 10.2202 9.54004 10.2822 9.66504C10.3442 9.79004 10.3862 9.93604 10.3032 10.102C10.2202 10.268 10.1782 10.372 10.0542 10.518C9.92921 10.664 9.79221 10.843 9.68021 10.955C9.55521 11.079 9.42521 11.215 9.57021 11.464C9.71621 11.714 10.2162 12.531 10.9582 13.192C11.9122 14.042 12.7152 14.305 12.9652 14.431C13.2152 14.556 13.3602 14.535 13.5062 14.368C13.6522 14.202 14.1302 13.64 14.2962 13.39C14.4622 13.14 14.6292 13.182 14.8582 13.265C15.0872 13.348 16.3142 13.952 16.5632 14.077C16.8132 14.202 16.9792 14.264 17.0412 14.368C17.1032 14.471 17.1032 14.971 16.8952 15.554Z"
            fill="#00A47C"
          />
        </svg>
      ),
      value: <ComingSoonText />,
      label: "Whatsapp Sends",
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Overall spends and sends</h3>
      <div className={styles.itemContainer}>
        {items.map((v) => (
          <div className={styles.item}>
            <div className={styles.titleContainer}>
              <div className={styles.icon}>{v.icon}</div>
              <span className={styles.itemTitle}>{v.label}</span>
            </div>
            <span className={styles.value}>{v.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SpendBreakdownWidget;
