import { gql } from "@apollo/client";

const META_PIXELS = gql`
  query metaPixels($adAccountId: String!) {
    metaPixels(adAccountId: $adAccountId) {
      id
      name
      metaPixelId
    }
  }
`;

export default META_PIXELS;
