import styles from "./AudienceTitleWithIcon.module.scss";
import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import format from "date-fns/format";
import classNames from "classnames";
import { AudienceIcon } from "./AudienceIcon";

// TODO: make this a generic component which can be also reused as /src/modules/campaign/components/CampaignTitleWithIcon.tsx
export function AudienceTitleWithIcon({
  audience,
  iconColor = "subdued",
  isActive = false,
}: {
  audience:
    | Pick<Audience, "createdAt" | "name" | "type" | "__typename">
    | Pick<MetaAudienceSet, "createdAt" | "name" | "__typename">;
  iconColor?: "primary" | "warn" | "success" | "subdued";
  isActive?: boolean;
}) {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.icon, styles[iconColor])}>
        <AudienceIcon audience={audience as Audience} />
      </div>
      <div
        className={classNames(styles.name, {
          [styles.active]: isActive,
        })}
      >
        <div className={styles.title}>{audience.name}</div>
        <div className={styles.subtitle}>
          Created {format(new Date(audience.createdAt), "dd MMM yyyy")}
        </div>
      </div>
    </div>
  );
}
