import { ProgressBar } from "../../../../components/ProgressBar/ProgressBar";
import { formatFileSize } from "src/lib/formatFileSize";
import styles from "./FileUploadPage.module.scss";
import { IconButton } from "../../../../components/IconButton/IconButton";
import { ReactComponent as Cancel } from "@material-design-icons/svg/outlined/close.svg";
import csvFile from "../../assets/csvFile.svg";

interface IFileCard {
  item: File;
  progress?: number;
  showProgress?: boolean;
  onRemove?: () => void;
}

export default function FileCard({
  item,
  progress,
  showProgress,
  onRemove,
}: IFileCard) {
  return (
    <div className={styles.progressCard}>
      <img src={csvFile} width={48} height={48} />
      <div className={styles.progressCardFileInfo}>
        <p className={styles.progressCardFileName}>{item.name}</p>
        <span className={styles.progressCardFileSize}>
          {formatFileSize(item.size)}
        </span>
        {showProgress && <ProgressBar width={progress} />}
      </div>
      {onRemove && (
        <IconButton
          icon={<Cancel fill="rgba(217, 217, 217, 1)" width={20} height={20} />}
          onClick={onRemove}
          style="plain"
          className={styles.cancelIco}
        />
      )}
    </div>
  );
}
