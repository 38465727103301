import Badge from "src/components/Badge/Badge";
import { Button } from "src/components/Button/Button";
import Skeleton from "react-loading-skeleton";
import Table from "src/components/Table/Table";
import { ReactComponent as TemplateDashboard } from "@material-symbols/svg-400/rounded/team_dashboard.svg";
import styles from "./SegmentReviewStep.module.scss";
import { useFormikContext } from "formik";
import { useSegmentFilterCustomersQuery } from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import Tooltip from "src/components/Tooltip/Tooltip";

interface SegmentReviewStepProps {
  filter: string;
}

export function SegmentReviewStep({ filter }: SegmentReviewStepProps) {
  const { values, setFieldValue } = useFormikContext<any>();
  const { data, error } = useSegmentFilterCustomersQuery({
    variables: {
      filter: filter,
    },
  });

  // console.log(values.template.description);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const headings = [
    "Customers Name",
    "Email",
    "LTV",
    "Ph. number",
    "Orders Count",
    "Country",
    "Subscribed To",
  ];

  const loadingRows = Array(5)
    .fill("")
    .map(() => headings.map((v) => <Skeleton />));

  const rows =
    data && data.segmentFilterCustomers
      ? data.segmentFilterCustomers.edges.map(({ node }) => [
          node.firstName || "",
          node.email,
          String(node.lifetimeValue),
          node.phone,
          String(node.ordersCount),
        ])
      : loadingRows;

  return (
    <div>
      <div className={styles.reviewSegmentHeadContainer}>
        <div className={styles.reviewSegmentHeadContent}>
          Template Used :{" "}
          <Tooltip
            supportingText={
              <>
                <p className={styles.descTitle}>Description</p>
                <p className={styles.templateDescription}>
                  {values.template.description}
                </p>
                <div className={styles.queryContainer}>
                  <p className={styles.descTitle}>Query</p>
                  <pre className={styles.templateQuery}>
                    {values.template.filter}
                  </pre>
                </div>
              </>
            }
          >
            <Badge label={values.template.title} status={"success"} />
          </Tooltip>
        </div>
        <Button
          style="plain"
          color="subdued"
          size="small"
          onClick={() => {
            setFieldValue("step", "SELECT_TEMPLATE");
          }}
        >
          <span className={styles.chooseTemplateButton}>
            <span>Choose another template</span>
            <TemplateDashboard height={20} width={20} />
          </span>
        </Button>
      </div>

      <div
        style={{
          padding: "1rem",
        }}
      >
        <Table
          headings={headings}
          rows={rows}
          className={styles.tableBorder}
          rounded
        />
      </div>
    </div>
  );
}
