import { Form, Formik } from "formik";
import { ReactNode, useState } from "react";
import {
  AudienceTypeEnum,
  usePlatformAudiencesCreateMutation,
} from "src/graphql/generated/schema";
import {
  AudienceInput,
  audienceInput,
  createAudienceSchema,
} from "../components/CreateAudienceInput";

import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { useNavigate } from "react-router-dom";
import PlatformBasedAudienceForm from "src/components/AudienceCreate/PlatformBasedAudienceForms/PlatformBasedAudienceForm";
import Header from "src/components/Header/Header";
import styles from "./AudienceCreatePage.module.scss";
import toast from "react-hot-toast";
import { transformAudienceInput } from "../misc/transformAudienceInput";
import { CreateLookalikeAudienceForm } from "../components/CreateLookalikeAudienceForm";
import { ChooseAudienceType } from "../components/ChooseAudienceType";
import { ChooseAudienceSource } from "../components/ChooseAudienceSource";

type FormSteps = Record<AudienceTypeEnum, ReactNode>;

export function AudienceCustomCreatePage() {
  const [activeStep, setActiveStep] = useState(0);
  // TODO: to add multiple audience creation in current flow.
  const selectedAudienceIndex = 0;
  const navigate = useNavigate();
  const [platformAudiencesCreateFunc, { data, loading, error }] =
    usePlatformAudiencesCreateMutation();

  const primaryAction = {
    label: (
      <>
        <span>Cancel</span>
        <Close fill="white" width={18} height={18} />
      </>
    ),
    onClick() {
      navigate("/audiences");
    },
  };

  const formSteps: FormSteps = {
    PLATFORM_BASED: (
      <>
        <ChooseAudienceSource
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          disabled={activeStep > 1}
          selectedAudienceIndex={selectedAudienceIndex}
        />
        <div className={styles.cardContainer}>
          {activeStep > 1 && (
            <PlatformBasedAudienceForm
              selectedAudienceIndex={selectedAudienceIndex}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          )}
        </div>
      </>
    ),
    LOOKALIKE: (
      <>
        <CreateLookalikeAudienceForm
          selectedAudienceIndex={selectedAudienceIndex}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </>
    ),
    CUSTOMER_LIST: <></>,
  };

  return (
    <div>
      <Header
        title="Create Audience"
        mode="DISTRACTION_FREE"
        primaryAction={primaryAction}
      />
      <main className={styles.layout}>
        <div className={styles.head}>
          <h2 className={styles.heading}>Build New Audience</h2>
          <p className={styles.subHeading}>
            Build an audience that is interested in what you have to offer
          </p>
        </div>
        <Formik
          initialValues={audienceInput}
          validationSchema={createAudienceSchema}
          onSubmit={async (values: AudienceInput) => {
            const audienceValue: AudienceInput["audiences"][0] =
              values.audiences[0];

            const transformedAudience = transformAudienceInput(audienceValue);

            try {
              await platformAudiencesCreateFunc({
                variables: {
                  input: {
                    audiences: [transformedAudience],
                  },
                },
              });
              toast.success("Audience has been created successfully");
            } catch (err) {
              toast.error(error.message);
              return;
            }

            navigate("/audiences");
            // reset touched after successful submit
            // because we don;t want user to see error for fields they haven't used
            // in the next step of form
            // options.setTouched({});
          }}
        >
          {({ values }) => (
            <Form>
              <div className={styles.pageLayout}>
                <ChooseAudienceType
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  disabled={activeStep > 0}
                  selectedAudienceIndex={selectedAudienceIndex}
                />

                {activeStep > 0 && (
                  <div>
                    {
                      formSteps[
                        values.audiences[selectedAudienceIndex].audienceType
                      ]
                    }
                  </div>
                )}

                {/* <ChooseAudienceType
                activeSubstep={activeSubstep}
                setActiveSubstep={setActiveSubstep}
                disabled={activeSubstep > 0}
              />
              {activeSubstep > 0 && (
                <ChooseAudienceSource
                  activeSubstep={activeSubstep}
                  setActiveSubstep={setActiveSubstep}
                  disabled={activeSubstep > 1}
                />
              )}
              {activeSubstep > 1 && <CreateCustomAudience />}
              <LookAlikeAudience /> */}
              </div>
            </Form>
          )}
        </Formik>
      </main>
    </div>
  );
}
