import styles from "./Spinner.module.scss";

interface ISpinner {
  height?: "screen" | "auto" | "full" | number;
}

export function Spinner({ height }: ISpinner) {
  let _heightValue: string | number;

  if (height === "auto") {
    _heightValue = "auto";
  } else if (height === "full") {
    _heightValue = "100%";
  } else if (height === "screen") {
    _heightValue = "100vh";
  } else {
    _heightValue = height;
  }

  return (
    <div style={{ height: _heightValue }} className={styles.loading}>
      <div className={styles.ring}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
