import { Audience, useAudiencesByIdQuery } from "src/graphql/generated/schema";
import { CampaignInput } from "../misc/createCampaignTypes";
import { ReviewForm } from "./ReviewForm";
import styles from "./Review.module.scss";

interface Props {
  setActiveFormStep: (i: any) => void;
  addNewActivity: () => Promise<unknown>;
  showUpgradePlan: boolean;
  upsertLoading: boolean;
  handleBack: () => void;
}

export function Review({
  setActiveFormStep,
  addNewActivity,
  showUpgradePlan,
  handleBack,
}: Props) {
  return (
    <div className={styles.pageLayout}>
      <ReviewForm
        handleBack={handleBack}
        showUpgradePlan={showUpgradePlan}
        addNewActivity={addNewActivity}
        setActiveFormStep={setActiveFormStep}
      />
    </div>
  );
}
