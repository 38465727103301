import Modal from "src/components/Modal/Modal";
import styles from "./AudienceSingleCreateAudience.module.scss";
import Select from "src/components/Select/Select";
import ComboBox from "src/components/ComboBox/ComboBox";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import { ReactComponent as Bulb } from "@material-design-icons/svg/filled/emoji_objects.svg";
import { Button } from "src/components/Button/Button";
import RangeSlider from "src/components/Range/RangeSlider";
import { useState } from "react";
import { Link } from "react-router-dom";
import ComboTagMaker from "src/components/ComboTagMaker/ComboTagMaker";

interface IAudienceSingleCreateAudience {
  isOpen: boolean;
  onClose: () => void;
}

export function AudienceSingleCreateAudience({
  isOpen,
  onClose,
}: IAudienceSingleCreateAudience) {
  const [audienceSize, setAudienceSize] = useState(1);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="75rem"
      title="Create a Lookalike Audience"
      footer={{
        footerContent: (
          <div className={styles.footerContent}>
            <Info className={styles.infoIco} />
            <span className={styles.footerText}>
              Reach new people who are similar to audiences that you already
              care about.
            </span>
          </div>
        ),
        footerActions: (
          <div className={styles.btnWrapper}>
            <Button color="subdued" style="outline">
              Back
            </Button>
            <Button>Save and Continue</Button>
          </div>
        ),
      }}
      noContainer
    >
      <div className={styles.modalContent}>
        <Select
          label="Source of your alike"
          options={[]}
          value={""}
          onChange={function (i: any): void {
            throw new Error("Function not implemented.");
          }}
        />
        <div className={styles.comboWrapper}>
          <div className={styles.comboLabelWrapper}>
            <label htmlFor="" className={styles.comboLabel}>
              Select audience location
            </label>
            <Button style="plain" className={styles.browseBtn}>
              Browse Countries
            </Button>
          </div>
          <ComboTagMaker
            multiple={false}
            selected={undefined}
            setSelected={() => {}}
            options={[]}
          />
        </div>
        <div className={styles.sizeWrapper}>
          <span className={styles.sizeText}>Select audience size</span>
          <Select
            options={[]}
            value={""}
            onChange={function (i: any): void {
              throw new Error("Function not implemented.");
            }}
            label="Number of Lookalike Audience"
          />
        </div>
        <div className={styles.sliderWrapper}>
          <RangeSlider
            id="audienceSize"
            name={"audienceSize"}
            min={0}
            max={10}
            value={audienceSize}
            onChange={(e) => setAudienceSize(e.target.value)}
            step={1}
            showSteps={true}
          />
        </div>
        <div className={styles.noticeWrapper}>
          <div className={styles.noticeTile}>
            <Bulb className={styles.bulbIco} />
            <div>
              <span className={styles.noticeTitle}>Notice</span>
              <p className={styles.noticeText}>
                A 1% lookalike consists of the{" "}
                <Link to={"/"} className={styles.accountLink}>
                  Accounts Centre accounts{" "}
                </Link>{" "}
                most similar to your Lookalike Audience source. Increasing the
                percentage creates a bigger, broader audience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
