import styles from "./OnboardingLayout.module.scss";
import { NeedHelpCard } from "./NeedHelpCard";
import { ReactNode } from "react";
import { OnboardingHeader } from "src/modules/global/components/OnboardingHeader";

interface Props {
  color: string;
  imageUrl: string;
  children: ReactNode;
}

export function OnboardingLayout(props: Props) {
  return (
    <main className={styles.onBoarding}>
      <OnboardingHeader />
      <div
        className={styles.leftContainer}
        style={{ backgroundColor: props.color }}
      >
        <div className={styles.imageAndText}>
          <div className={styles.imgContainer}>
            <img
              src={props.imageUrl}
              alt="onBoarding"
              className={styles.illustration}
            />
            <p className={styles.illustrateText}>
              Compile multiple marketing platforms and make them accessible from
              one destination.
            </p>
          </div>
        </div>
        <div className={styles.helpCard}>
          <NeedHelpCard />
        </div>
      </div>
      <div className={styles.rightContainer}>{props.children}</div>
    </main>
  );
}
