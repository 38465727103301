import { useEffect, useState } from "react";
import styles from "./StoriesVariant.module.scss";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as ActionLink } from "@material-symbols/svg-400/rounded/link.svg";
import { Asset } from "../Asset";
import { useKeenSlider } from "keen-slider/react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";

export function StoriesVariant({
  preview,
  primaryText,
  callToActionLabel,
  accountName,
  profilePicture,
  onSlideChange,
  defaultActiveSlide = 0,
}: {
  preview?: any;
  primaryText?: string;
  callToActionLabel?: string;
  accountName?: string;
  profilePicture?: string;
  onSlideChange?: (activeSlide: number) => void;
  defaultActiveSlide?: number;
}) {
  const [activeSlide, setActiveSlide] = useState(defaultActiveSlide);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: defaultActiveSlide,
    slideChanged: (slider) => {
      const activeSlide = slider.track.details.abs;
      setActiveSlide(activeSlide);
      if (onSlideChange) {
        onSlideChange(activeSlide);
      }
    },
  });

  // to manual trigger update of slider if previews change
  useEffect(() => {
    if (!instanceRef) return;
    if (!instanceRef.current) return;
    instanceRef.current.update();
  }, [preview, instanceRef]);

  return (
    <div className={styles.storyVariant}>
      <div className={styles.storyHeader}>
        <ul className={styles.storyItems}>
          {Array.isArray(preview) && preview.length > 0 ? (
            preview.map((_: unknown, idx: number) => (
              <li
                className={classNames(styles.storyItem, {
                  [styles.activeStoryItem]: idx === activeSlide,
                })}
                key={idx}
              />
            ))
          ) : (
            <li
              className={classNames(styles.storyItem, styles.activeStoryItem)}
            />
          )}
        </ul>
        <div className={styles.storyProfile}>
          <div className={styles.profile}>
            <img
              width={26}
              height={26}
              className={styles.avatar}
              src={profilePicture}
              // alt={"Profile Picture"}
            />
            <div className={styles.userDetails}>
              <div className={styles.username}>{accountName}</div>
              <div className={styles.time}>Sponsored</div>
            </div>
          </div>
          {/* <Close className={styles.storyClose} /> */}
        </div>
      </div>
      {/* <div className={classNames(styles.overlay, styles.overlayDark)}></div> */}
      {/* {preview.length > 1 ? (
        <button
          onClick={handleStorySwitch}
          type="button"
          className={styles.preview}
        >
          <Asset
            type={preview[currentCarouselStory]?.PostAsset?.contentType}
            preview={preview[currentCarouselStory]?.PostAsset?.uri}
            name={preview[currentCarouselStory]?.PostAsset?.name}
          />
        </button>
      ) : (
        
      )} */}
      <div className="keen-slider" ref={sliderRef}>
        {Array.isArray(preview) && preview.length > 0 ? (
          preview.map((v, idx) => (
            <>
              <div key={idx} className="keen-slider__slide">
                <Asset
                  className={styles.asset}
                  type={
                    v.StoryAsset
                      ? v.StoryAsset.contentType
                      : v?.PostAsset?.contentType
                  }
                  preview={
                    v?.StoryAsset ? v?.StoryAsset?.uri : v?.PostAsset?.uri
                  }
                  name={v.StoryAsset ? v.StoryAsset.name : v?.PostAsset?.name}
                />
                <div className={classNames(styles.overlay)}>
                  <div className={styles.overlayContainer}>
                    <p className={styles.overlayUsername}>{accountName}</p>
                    <p className={styles.overlayLink}>
                      <Link to={"/"}>{v.link}</Link>
                    </p>
                    {callToActionLabel && (
                      <span className={styles.overlayAction}>
                        {snakeCaseToTitleCase(callToActionLabel)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))
        ) : (
          <div className="keen-slider__slide">
            <Asset
              className={styles.asset}
              type={
                preview.StoryAsset
                  ? preview.StoryAsset.contentType
                  : preview?.PostAsset?.contentType
              }
              preview={
                preview?.StoryAsset
                  ? preview?.StoryAsset?.uri
                  : preview?.PostAsset?.uri
              }
              name={
                preview.StoryAsset
                  ? preview.StoryAsset.name
                  : preview?.PostAsset?.name
              }
            />
          </div>
        )}
      </div>
      {primaryText && <p className={styles.primaryText}>{primaryText}</p>}
      <div className={styles.storyFooter}>
        {/* <div className={styles.storyFooterSection}>
          <div className={styles.swipeSection}>
            <div className={styles.swipeUp}>
              <ExpandLess className={styles.swipeUpIco} />
            </div>
            <p className={styles.swipeText}>Shop Now</p>
          </div>
          <Send className={styles.storySendIco} />
          <MoreHoriz className={styles.storyMoreIco} />
        </div> */}
        {callToActionLabel && Array.isArray(preview) && preview.length > 0 ? (
          <span className={styles.learnMore}>
            <ActionLink className={styles.actionIco} />
            {snakeCaseToTitleCase(callToActionLabel)}
          </span>
        ) : null}
      </div>
    </div>
  );
}
