import { EmailObject, FormSteps } from "../misc/createCampaignTypes";

import { Button } from "src/components/Button/Button";
import styles from "./ReviewCampaign.module.scss";

const recommendations = [
  "Remove all placeholder text",
  "Try to follow design style based on your brand style",
  "Make sure you add your logo",
  "Try to make your email more personal and informative",
];

interface IContentDetails {
  setActiveFormStep: (i: FormSteps) => void;
  previewHtml: string;
  typeOfEmail: EmailObject;
}

export function ContentDetails({
  setActiveFormStep,
  previewHtml,
  typeOfEmail,
}: IContentDetails) {
  return (
    <div>
      <div className={styles.section}>
        <span className={styles.sectionTitle}>Content Details</span>
        <hr />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.previewWrapper}>
          <iframe
            srcDoc={previewHtml}
            className={styles.contentImageContainer}
          ></iframe>
        </div>
        <div>
          <h1 className={styles.recommend}>Recommendations</h1>
          <ol className={styles.recommendContainer}>
            {recommendations.map((item, index) => (
              <li key={index} className={styles.recommendations}>
                {item}
              </li>
            ))}
          </ol>
          <Button
            color="subdued"
            style="outline"
            onClick={() => {
              if (
                "emailHTMLTemplateId" in typeOfEmail &&
                typeOfEmail.emailHTMLTemplateId
              ) {
                setActiveFormStep("htmlEditor");
              } else if (
                "emailTextTemplateId" in typeOfEmail &&
                typeOfEmail.emailTextTemplateId
              ) {
                setActiveFormStep("textEditor");
              } else if (
                "emailDesignTemplateId" in typeOfEmail &&
                typeOfEmail.emailDesignTemplateId
              ) {
                setActiveFormStep("emailBuilder");
              }
            }}
          >
            Edit Email
          </Button>
        </div>
      </div>
    </div>
  );
}
