import styles from "./OverviewTable.module.scss";
import { Tabs } from "src/components/Tabs/Tabs";
import Table from "src/components/Table/Table";
import classNames from "classnames";
import EmptyState from "src/components/EmptyState/EmptyState";
import noInsights from "../assets/noRecentCampaign.png";

interface IOverviewTable<T> {
  title: string;
  selectedTab?: T;
  setSelectedTab?: (i: T) => void;
  loading: boolean;
  rows: React.ReactNode[][];
  headings: string[];
  itemsPerPage?: number;
  border?: boolean;
  tabsList?: { label: string; value: T }[];
  emptyState?: {
    title: string;
    icon?: string;
    action?: {
      title: string;
      link: string;
    };
  };
}

export function OverviewTable<T extends string>({
  title,
  selectedTab,
  setSelectedTab,
  loading,
  rows,
  headings,
  itemsPerPage,
  border = true,
  tabsList,
  emptyState,
}: IOverviewTable<T>) {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeading}>{title}</div>
      <div className={classNames({ [styles.tableBorderContainer]: border })}>
        {selectedTab && (
          <div className={styles.tableTabs}>
            <Tabs
              tabs={tabsList}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
            />
          </div>
        )}
        {rows.length === 0 && !loading ? (
          <div className={styles.emptyStateContainer}>
            <EmptyState
              illustration={emptyState?.icon || noInsights}
              title={emptyState ? emptyState.title : "No items found"}
              action={
                emptyState
                  ? emptyState.action
                  : {
                      title: "Go Back",
                      link: "/",
                    }
              }
            />
          </div>
        ) : (
          <Table
            loading={loading}
            noOfRows={itemsPerPage}
            headings={headings}
            rows={rows}
          />
        )}
      </div>
    </div>
  );
}
