import {
  AudienceInput,
  AudienceValues,
} from "src/modules/audience/components/CreateAudienceInput";
import styles from "./FacebookAccountSettings.module.scss";
import { FormikContextType } from "formik";
import FacebookSettings, {
  upsertDefaultSettings,
} from "src/modules/global/components/FacebookSettings";
import { useMetaIntegrationDefaultSettingsQuery } from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";

interface IFacebookAccountSettings {
  audienceValuesKey: string;
  audienceValues: AudienceValues;
  setFieldValue: FormikContextType<AudienceInput>["setFieldValue"];
  isLookalike?: boolean;
}

export function FacebookAccountSettings({
  audienceValuesKey,
  audienceValues,
  isLookalike,
  setFieldValue,
}: IFacebookAccountSettings) {
  const fieldsMapper = {
    adAccountId: `${audienceValuesKey}.enabledAccountId`,
    adAccountName: `${audienceValuesKey}.enabledAccountName`,
    pageId: `${audienceValuesKey}.metaPageId`,
    metaPagePicture: `${audienceValuesKey}.metaPagePicture`,
    platformAccountId: `${audienceValuesKey}.adAccountId`,
    conversionDomain: `${audienceValuesKey}.conversionDomain`,
    instagramPageId: `${audienceValuesKey}.instagramAccountId`,
    metaPageConnectedInstagramBusinessAccountId: `${audienceValuesKey}.metaPageConnectedInstagramBusinessAccountId`,
    pixelId: `${audienceValuesKey}.pixelId`,
  };

  const { loading } = useMetaIntegrationDefaultSettingsQuery({
    onCompleted: (data) =>
      upsertDefaultSettings(
        data?.metaIntegrationDefaultSettings as any,
        setFieldValue,
        fieldsMapper,
      ),
  });

  if (loading) {
    return (
      <div className={styles.section}>
        <Spinner height="screen" />
      </div>
    );
  }
  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>Facebook Settings</div>
      <FacebookSettings
        containerClass={styles.sectionLayout}
        showInstagramFields={
          audienceValues?.platform?.includes("INSTAGRAM") || isLookalike
        }
        showConversionDomainInput={false}
        fieldsMapper={fieldsMapper}
      />
    </div>
  );
}
