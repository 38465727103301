import { Button, IButton } from "../Button/Button";

import { Template } from "./TemplateSelector";
import styles from "./SelectedTemplatePreview.module.scss";

interface Props {
  selectedTemplate: Template;
  primaryAction: IButton;
  footerContent: React.ReactNode;
  children?: React.ReactNode;
}

export function SelectedTemplatePreview({
  selectedTemplate,
  footerContent,
  primaryAction,
  children,
}: Props) {
  return (
    <div className={styles.templatePreview}>
      <div className={styles.content}>
        <div className={styles.selectedTemplate}>
          <div className={styles.templateIconContainer}>
            <div className={styles.templateIconWrapper}>
              <img
                src={selectedTemplate?.icon}
                alt="template"
                className={styles.icon}
              />
            </div>
          </div>
          <div className={styles.templateText}>
            <h2 className={styles.title}>{selectedTemplate?.title}</h2>
            <p className={styles.based}>Based on your current customer data</p>
            <p className={styles.subtitle}>{selectedTemplate?.subtitle}</p>
          </div>
        </div>

        {children}
      </div>
      {/* Footer */}
      <div className={styles.footer}>
        <div className={styles.integration}>{footerContent}</div>
        <Button color="primary" size="small" {...primaryAction} />
      </div>
    </div>
  );
}
