import { useContext } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { GraphNotAvailableYet } from "src/modules/global/components/GraphNotAvailableYet";
import AuthContext from "src/stores/AuthContext";
import styles from "./SegmentEngagementInsight.module.scss";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";

export function SegmentEngagementInsight({
  segmentId,
  dateFrom,
  dateTo,
}: {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}) {
  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "engage_engagement",
    userToken,
    {
      segmentId,
      dateFrom,
      dateTo,
    },
  );

  if (error) {
    console.log({ error });
    return <GraphLoadingError error={error} height={310} />;
  }

  if (loading) {
    return <Spinner height={300} />;
  }

  let insights = data[0];

  const engagementData = [
    {
      percent: Math.round(
        (insights?.noEngagement / insights?.totalCount) * 100,
      ),
      label: "No engagement",
      color: "#EDEEEF",
      textColor: "#575959",
      value: insights?.noEngagement,
    },
    {
      percent: Math.round(
        (insights?.barelyEngaged / insights?.totalCount) * 100,
      ),
      label: "Barely engaged",
      color: "#FFF5EA",
      textColor: "#916A00",
      value: insights?.barelyEngaged,
    },
    {
      percent: Math.round((insights?.veryEngaged / insights?.totalCount) * 100),
      label: "Very engaged",
      color: "#FFF4F4",
      textColor: "#D72C0D",
      value: insights?.veryEngaged,
    },
  ];

  const areAllValuesFalsy = Object.values(insights).every((v) => v === 0);

  if (areAllValuesFalsy) {
    return <GraphNotAvailableYet type="barGraph" height={300} />;
  }

  return (
    <div className={styles.container}>
      <h5 className={styles.title}>Member engagement Insight</h5>
      <div className={styles.progressWrapper}>
        {engagementData.map((item, index) => (
          <div key={index} className={styles.progressBar}>
            <span style={{ color: item.textColor }}>{item.percent}%</span>
            <span className={styles.labelWrapper}>
              {item.label}
              <Info className={styles.infoIco} />
              <span className={styles.value}>{item.value}</span>
            </span>
            <div
              className={styles.progress}
              style={{
                backgroundColor: `${item.color}`,
                width: `${item.percent}%`,
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
