import styles from "./SettingsWrapper.module.scss";
import { ReactComponent as CorporateFare } from "@material-symbols/svg-600/rounded/corporate_fare.svg";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useActiveClientGet } from "../../global/functions/activeClient";

export function SettingsWrapper({ children }) {
  return (
    <div className={styles.pageContainer}>
      <Sidebar />
      <div className={styles.contentWrapper}>{children}</div>
    </div>
  );
}

export function Sidebar() {
  const location = useLocation();
  const activeClient = useActiveClientGet();
  const baseLink = `/settings`;
  const organizationSettingsLinks = [
    {
      label: "General",
      link: "account",
    },
    {
      label: "Billing & Invoices",
      link: "billing",
    },
    {
      label: "Overall Usage",
      link: "usage",
    },
    // {
    //   label: "Plans",
    //   link: "plans",
    // },
    {
      label: "Clients",
      link: "clients",
    },
    {
      label: "Members",
      link: "members",
    },
  ];

  const clientLinks = [
    {
      label: "Integrations",
      link: "integrations",
    },
    // {
    //   label: "Usage",
    //   link: "usage",
    // },
    // {
    //   label: "Profile",
    //   link: "profile",
    // },
    // {
    //   label: "Teams",
    //   link: "teams",
    // },
    // {
    //   label: "Notifications",
    //   link: "notifications",
    // },
  ];

  return (
    <div className={styles.sidebar}>
      <div className={styles.section}>
        <div className={styles.sidebarHeading}>
          <CorporateFare width={16} height={16} fill="currentColor" />
          <div>Organization</div>
        </div>
        {organizationSettingsLinks.map((v) => (
          <Link
            to={`${baseLink}/${v.link}`}
            className={classNames(styles.sidebarLink, {
              [styles.active]: `${baseLink}/${v.link}` === location.pathname,
            })}
          >
            {v.label}
          </Link>
        ))}
      </div>
      <div className={styles.section}>
        <div className={styles.sidebarHeading}>
          <CorporateFare width={16} height={16} fill="currentColor" />
          <div>{activeClient?.name}</div>
        </div>
        {clientLinks.map((v) => (
          <Link
            to={`${baseLink}/${v.link}`}
            className={classNames(styles.sidebarLink, {
              [styles.active]: `${baseLink}/${v.link}` === location.pathname,
            })}
          >
            {v.label}
          </Link>
        ))}
      </div>
    </div>
  );
}
