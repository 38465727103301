import { IButton } from "../Button/Button";
import ButtonOptions from "../ButtonOptions/ButtonOptions";
import { ReactComponent as Forward } from "@material-symbols/svg-400/rounded/forward.svg";
import { IconButton } from "../IconButton/IconButton";
import { ReactComponent as Reply } from "@material-symbols/svg-400/rounded/reply.svg";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import styles from "./EmailBuilderHeader.module.scss";

export interface IEmailBuilderHeader {
  title: string;
  setTitle: (i: string) => void;
  onUndo?: () => void;
  onRedo?: () => void;
  onSave?: () => void;
  onPreview?: () => void;
  preview: boolean;
  loading?: boolean;
  options: IButton[];
  goBack?: (i: any) => void;
}

export function EmailBuilderHeader({
  title,
  setTitle,
  onRedo,
  onUndo,
  onPreview,
  preview,
  onSave,
  options,
  loading,
  goBack,
}: IEmailBuilderHeader) {
  return (
    <div className={styles.header}>
      <div className={styles.headerBack}>
        {goBack && (
          <IconButton
            icon={<ArrowBack width={20} height={20} />}
            style="plain"
            onClick={goBack}
          />
        )}
        <input
          className={styles.title}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        ></input>
      </div>
      <div className={styles.headerActions}>
        <div className={styles.actions}>
          {onUndo && (
            <IconButton
              icon={<Reply />}
              style="outline"
              onClick={onUndo}
              disabled={true}
            />
          )}
          {onRedo && (
            <IconButton
              icon={<Forward />}
              style="outline"
              onClick={onRedo}
              disabled={true}
            />
          )}
        </div>
        <div className={styles.btnGrp}>
          {/* <Button size="small" onClick={onPreview} disabled={true}>
            {preview ? "Exit Preview" : "Show Preview"}
          </Button> */}
          <ButtonOptions
            items={options}
            style="solid"
            color="primary"
            size="small"
            onClick={onSave}
            loading={loading}
            disabled={loading}
            menuBtnClass={styles.menuBtn}
            className={styles.opBtn}
          >
            Save and Exit
          </ButtonOptions>
        </div>
      </div>
    </div>
  );
}
