import styles from "./MetaIntegrationAccountDetailsSlider.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLink } from "@material-design-icons/svg/outlined/arrow_outward.svg";
import { MetaUser, useMetaUserQuery } from "src/graphql/generated/schema";
import { MetaIntegrationAccountDetailsContent } from "./MetaIntegrationAccountDetailsContent";
import { MetaProfileSection } from "./MetaProfileSection";
import Slider from "src/modules/global/components/Slider";

interface IMetaIntegrationAccountDetails {
  isOpen: boolean;
  onClose: () => void;
  selectedAdAccountId?: string;
  user: MetaUser;
}

export function MetaIntegrationAccountDetailsSlider({
  isOpen,
  onClose,
  selectedAdAccountId,
  user,
}: IMetaIntegrationAccountDetails) {
  return (
    <Slider isOpen={isOpen} onClose={onClose} title={"Account Details"}>
      <div className={styles.userContainer}>
        <MetaProfileSection user={user} />
      </div>
      <hr className={styles.line} />
      <MetaIntegrationAccountDetailsContent
        selectedAccountId={selectedAdAccountId}
      />
      {/* <div className={styles.footer}>
        <h4 className={styles.footerTitle}>
          Not able to see your page, account or catalogue?
        </h4>
        <p className={styles.footerDescription}>
          If you are unable to see your page, instagram account or catalogue
          here then probably you don't have admin access to respected account.
          Try to check necessary permission on facebook.
        </p>
        <Link to={"/"} className={styles.learnMore}>
          Learn More
          <ArrowLink className={styles.arrowLinkIco} />
        </Link>
      </div> */}
    </Slider>
  );
}
