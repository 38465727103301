import { useState } from "react";
import styles from "./AddMedia.module.scss";
import { ReactComponent as AddPhotoAlternative } from "@material-design-icons/svg/outlined/add_photo_alternate.svg";
import { ReactComponent as SearchIcon } from "@material-design-icons/svg/outlined/search.svg";
import Modal from "src/components/Modal/Modal";
import { Input } from "src/components/Input/Input";
import { Button, IButton } from "src/components/Button/Button";
import { MediaLoader } from "./MediaLoader";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Asset, AssetsQuery } from "src/graphql/generated/schema";
import { FileUploadButton } from "../../../global/components/FileUpload/FileUploadButton";
import toast from "react-hot-toast";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import sortOptions from "../../misc/assetsSortBy";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import { dateFilterOptions } from "src/modules/global/misc/dateFilterOptions";
import {
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from "src/modules/global/misc/maxSizes";

interface IAddMedia {
  multiple: boolean;
  onSelect: (i: Asset[]) => void;
  triggerLabel?: React.ReactNode;
  buttonProps?: Omit<IButton, "children">;
}

const defaultTriggerLabel = (
  <div className={styles.btnWithIcon}>
    <AddPhotoAlternative fill="currentColor" height={21} width={21} />
    Add Media
  </div>
);

export function AddMedia({
  multiple,
  onSelect,
  triggerLabel = defaultTriggerLabel,
  buttonProps,
}: IAddMedia) {
  const [isMediaPickerOpen, setIsMediaPickerOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    dateFilter: "ALL_TIME" as DateFilter,
    take: 25,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "DESC" | "ASC",
    field: "CREATED_AT" as const,
  });
  const [selectedAssets, setSelectedAssets] = useState<
    AssetsQuery["assets"]["edges"]
  >([]);

  function handleSubmit() {
    onSelect(selectedAssets.map((a) => a.node));
    setIsMediaPickerOpen(false);
  }

  return (
    <div className={styles.addMediaWrapper}>
      <Button
        onClick={() => {
          setIsMediaPickerOpen(true);
        }}
        type="button"
        className={buttonProps ? "" : styles.addMediaBtn}
        {...buttonProps}
      >
        {triggerLabel}
      </Button>
      <Modal
        maxWidth="1200px"
        isOpen={isMediaPickerOpen}
        title="Select Media"
        subtitle="You can select media either from media library or upload your own media for the Ad"
        onClose={() => setIsMediaPickerOpen(false)}
      >
        <div className={styles.filters}>
          <div className={styles.filterSectionLeft}>
            <Input
              value={search}
              onChange={(value) => setSearch(value)}
              icon={SearchIcon}
            />
            <SortByDropdown
              setSortBy={setSelectedSortBy}
              sortBy={selectedSortBy}
              sortOptions={sortOptions}
            />
            <DateFilterDropdown
              dateFilter={selectedFilters.dateFilter}
              setDateFilter={(dateFilterValue) => {
                setSelectedFilters({
                  ...selectedFilters,
                  dateFilter: dateFilterValue,
                });
              }}
              filterOptions={dateFilterOptions}
            />
          </div>

          {/*
              <Button color="subdued" size="micro" style="outline">
              <div className={styles.uploadButtonContent}>
              <BackupIcon width={20} height={20} />
              Upload Media
              </div>
              </Button>
          */}

          <FileUploadButton
            onUpload={() => {
              toast.success("Created Asset successfully");
            }}
            maxVideoFileSizeInBytes={MAX_FILE_SIZE_VIDEO}
            maxImageFileSizeInBytes={MAX_FILE_SIZE_IMAGE}
            color="subdued"
            style="outline"
            size="micro"
          />
        </div>
        <div className={styles.mediaContent}>
          <MediaLoader
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
            multiple={multiple}
            filters={selectedFilters}
            search={search}
            sortBy={selectedSortBy}
          />
        </div>

        <StickyFooter
          label={multiple ? "You can select upto 10 carousel assets" : ""}
          actions={
            <div className={styles.buttonGroup}>
              <Button size="micro" color="subdued" style="outline">
                Cancel
              </Button>
              <Button onClick={handleSubmit} size="micro">
                Select Media
              </Button>
            </div>
          }
        />
      </Modal>
    </div>
  );
}
