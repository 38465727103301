import EmptyState from "src/components/EmptyState/EmptyState";
import styles from "./CampaignsEmptyState.module.scss";
import emptyCampaign from "../assets/emptyCampaign.png";
import classNames from "classnames";

export function CampaignsEmptyState({className}: {className?: string}) {
  return (
    <div className={classNames(styles.emptyStateContainer, className)}>
      <EmptyState
        title={"Create Campaign"}
        image={
          <img
            src={emptyCampaign}
            alt="create_campaign"
            width={290}
            height={235}
          />
        }
        action={{
          title: "Let's Get Started",
          link: "/campaigns/new",
        }}
      >
        <div className={styles.emptyStateParagraph}>
          <p>Get started by creating your first campaign</p>
        </div>
      </EmptyState>
    </div>
  );
}
