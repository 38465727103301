import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./SelectableObjective.module.scss";

export function SelectableObjective({
  value,
  imageSrc,
  content,
}: {
  value: string;
  imageSrc: string;
  content: ReactNode;
}) {
  return (
    <RadioGroup.Option value={value}>
      {({ checked }) => (
        <div
          className={classNames(styles.option, {
            [styles.checkedOption]: checked,
          })}
        >
          <img src={imageSrc} alt="" width={28} height={28} />
          <span>{content}</span>
        </div>
      )}
    </RadioGroup.Option>
  );
}
