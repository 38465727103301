import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import styles from "./PreviewContacts.module.scss";
import { Button } from "src/components/Button/Button";
import SimpleTable from "src/components/SimpleTable/SimpleTable";
import { StickyFooter } from "src/modules/global/components/StickyFooter";

interface IPreviewContacts {
  setIsPreviewContacts: (i: boolean) => void;
  headings?: React.ReactNode[];
  rows?: React.ReactNode[][];
  totalContacts: number;
}

export function PreviewContacts({
  setIsPreviewContacts,
  headings,
  rows,
  totalContacts,
}: IPreviewContacts) {
  return (
    <div>
      <h1 className={styles.heading}>Manage Contacts</h1>
      <div className={styles.tableWrapper}>
        <SimpleTable
          headings={headings}
          rows={rows}
          showHeadings={true}
          className={styles.table}
        />
      </div>
      <StickyFooter
        label={
          <div className={styles.footerLabel}>
            <Info className={styles.infoIco} />
            <span>{totalContacts} contacts were recognized in this file</span>
          </div>
        }
        actions={
          <Button size="small" onClick={() => setIsPreviewContacts(false)}>
            Back to Import
          </Button>
        }
      />
      <div className={styles.textContainer}>
        <h2 className={styles.about}>About Preview</h2>
        <p className={styles.description}>
          This is the basic representation of imported customers in Macro. Only
          initial 10 rows are being shown in this preview.
        </p>
      </div>
    </div>
  );
}
