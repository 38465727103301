import { useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button/Button";
import Card from "src/components/Card/Card";
import { Mobile } from "src/components/Mobile/Mobile";
import { SmsReview } from "../SmsTemplate/SmsReview/SmsReview";
import { WhatsappReview } from "../WhatsappTemplate/WhatsappReview/WhatsappReview";
import styles from "./ReviewTemplate.module.scss";
import {
  FormInput,
  MessageFormInput,
  WhatsappFormInput,
} from "../CreateMessageTemplateTypes";
import { StickyFooter } from "src/modules/global/components/StickyFooter";

interface IReviewTemplate {
  templateType: "WHATSAPP" | "SMS";
  setActiveStep: any;
}

export function ReviewTemplate({
  templateType,
  setActiveStep,
}: IReviewTemplate) {
  const { handleSubmit, isSubmitting, values } = useFormikContext<FormInput>();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.cardWrapper}>
          <Card title="Review & Submit">
            <div className={styles.cardContent}>
              {templateType === "WHATSAPP" ? (
                <WhatsappReview values={values as WhatsappFormInput} />
              ) : (
                <SmsReview values={values as MessageFormInput} />
              )}
            </div>
          </Card>
        </div>
        <div className={styles.mobileWrapper}>
          <Mobile templateType={templateType} input={values} />
        </div>
      </div>
      <StickyFooter
        actions={
          <>
            <Button
              color="subdued"
              style="outline"
              size="small"
              onClick={() => {
                setActiveStep((prev) => prev - 1);
              }}
              disabled={isSubmitting}
            >
              Back
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={handleSubmit}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </Button>
          </>
        }
        label={
          templateType === "WHATSAPP" ? (
            <p>
              This template will be review by WhatsApp, See
              <Link to={"#"} className={styles.guideline}>
                {" "}
                WhatsApp Template Guideline
              </Link>
            </p>
          ) : (
            <p>
              Make sure your template has been validated by DLT, See{" "}
              <Link to={"#"} className={styles.guideline}>
                DLT Template Guideline
              </Link>
            </p>
          )
        }
      />
    </>
  );
}
