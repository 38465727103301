export function hasProperty<T>(
  error: unknown,
  propertyName: string
): error is T {
  if (typeof error === "object" && error !== null && !Array.isArray(error)) {
    return propertyName in error && error[propertyName];
  }

  return false;
}
