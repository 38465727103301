import styles from "./CreateClientModal.module.scss";
import Modal from "../../../components/Modal/Modal";
import { Button } from "../../../components/Button/Button";
import {
  Asset as IAsset,
  useClientUpdateMutation,
  useClientQuery,
} from "src/graphql/generated/schema";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Avatar from "react-avatar";
import { Asset } from "../../../components/Smartphone/Asset";
import { AddMedia } from "../../media/components/AdTemplateCreateSlideOver/AddMedia";
import { ClientFieldsForm } from "./ClientFieldsForm";
import { ClientInput } from "../misc/clientInputType";
import { useMemo } from "react";
import { Spinner } from "../../../components/Spinner/Spinner";

const updateClientSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Client Name should be more than 3 characters")
    .required("Client name is required")
    .matches(
      /^[a-zA-Z0-9_\-,:\s]+$/g,
      "name should not have any special characters"
    ),
});

export function EditClientModal({ isOpen, onClose, id }) {
  const { data: clientData, loading } = useClientQuery({
    variables: {
      id,
    },
  });
  const [clientUpdateFunc] = useClientUpdateMutation();

  async function handleSubmit(values: ClientInput) {
    try {
      const { data } = await clientUpdateFunc({
        variables: {
          input: {
            id: values.id,
            name: values.name,
            ...(values.industry && {
              industry: values.industry,
            }),
            ...(values.Thumbnail && {
              assetId: values.Thumbnail.id,
            }),
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: ["organizationClients", "getUser", "activeClient"],
      });

      if (data) {
        toast.success("Successfully updated client");
        onClose();
      }
    } catch (err) {
      if (err.extensions.code === "FORBIDDEN") {
        toast.error(err.message);
        return;
      }
      toast.error("An unknown error occurred");
    }
  }

  const initialValues: ClientInput = useMemo(() => {
    if (clientData && clientData.client) {
      const client = clientData.client;
      return {
        timezoneCode: client.timeZone,
        currencyCode: client.currencyCode,
        id: id,
        name: client.name,
        Thumbnail: client.Thumbnail,
        members: [],
        industry: client.industry,
      };
    }

    return {
      timezoneCode: "Asia/Kolkata",
      currencyCode: "",
      id: id,
      name: "",
      Thumbnail: null,
      members: [],
    };
  }, [clientData, loading]);

  return (
    <Formik
      isInitialValid
      enableReinitialize
      validateOnMount
      validationSchema={updateClientSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue, isValid, isSubmitting }) => (
        <Form>
          <Modal
            isOpen={isOpen}
            closeOnBlur={false}
            onClose={onClose}
            title="Edit Client"
            subtitle="Edit client and manage your marketing need"
            noContainer
            maxWidth={"60%"}
            className={styles.modal}
            footer={{
              footerContent: "",
              hideBorder: true,
              footerActions: (
                <Button
                  width="auto"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  disabled={isSubmitting || !isValid}
                >
                  Edit Client
                </Button>
              ),
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <ModalContent values={values} setFieldValue={setFieldValue} />
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
}

function ModalContent({ values, setFieldValue }) {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.uploadSection}>
        {values?.Thumbnail ? (
          <Asset
            type={values.Thumbnail?.contentType}
            preview={values?.Thumbnail?.uri}
            name={values?.Thumbnail?.name}
            className={styles.asset}
          />
        ) : (
          <Avatar name={values.name} className={styles.asset} size="64px" />
        )}

        <div className={styles.actionContainer}>
          <div>
            <AddMedia
              multiple={false}
              triggerLabel={"Upload"}
              onSelect={function (i: IAsset[]): void {
                const asset = i[0];
                setFieldValue("Thumbnail", asset);
              }}
              buttonProps={{
                size: "micro",
                color: "subdued",
                style: "outline",
              }}
            />
          </div>
          <div className={styles.uploadButtonDescription}>
            Recommended size is 256px x 256px
          </div>
        </div>
      </div>
      <ClientFieldsForm
        showMembers={false}
        disableCurrencySelector={true}
        disableTimeZoneSelector={true}
      />
    </div>
  );
}
