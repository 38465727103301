import { WhatsappFormInput } from "../CreateMessageTemplateTypes";
import {
  WhatsappTemplateComponentButtonCreateInput,
  WhatsappTemplateCreateInput,
  WhatsappTemplateCreateMutationHookResult,
} from "src/graphql/generated/schema";

function generateWhatsappCreateTemplateInput(
  values: WhatsappFormInput,
): WhatsappTemplateCreateInput {
  const components: WhatsappTemplateCreateInput = {
    language: values.language,
    category: "MARKETING",
    name: values.name,
    // category: "MARKETING",
    body: {
      variables: values.body.variables,
      text: values.body.text,
    },
    buttons: [],
  };

  if (values.header && values.header.headerType !== "NONE") {
    components["header"] = {
      format:
        values.header.headerType === "UPLOAD"
          ? "IMAGE"
          : values.header.headerType,
      ...(values.header.text && { text: values.header.text }),
      ...(values.header.variables && { variables: values.header.variables }),
      ...(values.header.assetUrl && {
        assetUrl: values.header.assetUrl,
      }),
    };
  }

  if (values.footer) {
    components["footer"] = {
      text: values.footer,
    };
  }

  if (values.buttons && values.buttons.type !== "NONE") {
    let buttonsInput: WhatsappTemplateComponentButtonCreateInput[] = [];
    if (values.buttons.type === "CTA") {
      buttonsInput = values.buttons.ctas.map((v) => ({
        text: v.text,
        type: v.type === "PHONE" ? "PHONE_NUMBER" : "URL",
        ...(v.phone && { phone_number: v.phone }),
        ...(v.url && { url: v.url }),
      }));
    }

    if (values.buttons.type === "QUICK_REPLY") {
      buttonsInput = values.buttons.quickReplies.map((v) => ({
        text: v,
        type: "QUICK_REPLY",
      }));
    }

    components["buttons"] = buttonsInput;
  }
  return components;
}

export async function upsertWhatsappMessageTemplate({
  values,
  whatsappTemplateCreate,
}: {
  values: WhatsappFormInput;
  whatsappTemplateCreate: WhatsappTemplateCreateMutationHookResult[0];
}) {
  const input = generateWhatsappCreateTemplateInput(values);

  await whatsappTemplateCreate({
    variables: {
      input: input,
    },
  });
}
