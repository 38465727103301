import styles from "./TipTapEditor.module.scss";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";
import { Autocomplete } from "./TipTapCustomAutocompletePlugin";
import { ISuggestion, Suggestion } from "./AutocompleteSuggestions";
import classNames from "classnames";

export interface ITipTapEditor {
  content: string;
  onChange: (v: string) => void;
  className?: string;
  autoCompleteSuggestions?: ISuggestion[];
  placeholder?: string;
}

// TODO: Add other necessary plugins from tip-tap
// TODO: find a way to parse variables from content
export function TipTapEditor({
  content,
  onChange,
  className,
  autoCompleteSuggestions,
  placeholder,
}: ITipTapEditor) {
  const editor = useEditor({
    editorProps: {
      attributes: {
        class: className || styles.editor,
      },
    },
    onUpdate: ({ editor }) => {
      onChange(editor.getText());
    },
    extensions: [
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Autocomplete.configure({
        HTMLAttributes: {
          class: styles.variable,
        },
        suggestion: Suggestion(autoCompleteSuggestions || []),
      }),
    ],
    content: content,
  });

  return (
    <div>
      <EditorContent editor={editor} />
    </div>
  );
}
