import { Link } from "react-router-dom";
import styles from "./BriefItem.module.scss";
import classNames from "classnames";

export interface IBriefItem {
  image?: string;
  icon?: React.ReactNode;
  title: string | React.ReactNode;
  subtitle: string;
  to?: string;
  className?: string;
}

export default function BriefItem({
  image,
  icon,
  title,
  subtitle,
  to,
  className,
}: IBriefItem) {
  const TitleElement = to ? Link : ("span" as any);
  return (
    <div className={classNames(styles.item, className)}>
      <div className={styles.imageContainer}>
        {image && <img src={image} alt="image" className={styles.image} />}
        {icon && icon}
      </div>
      <div className={styles.textContainer}>
        <TitleElement
          to={TitleElement !== "span" ? to : null}
          className={styles.title}
        >
          {title}
        </TitleElement>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </div>
  );
}
