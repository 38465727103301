import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import styles from "./AudienceReportingCard.module.scss";
import { AudienceTitleWithIcon } from "src/modules/global/components/AudienceTitleWIthIcon";
import classNames from "classnames";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Spinner } from "src/components/Spinner/Spinner";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { generateRangeString } from "src/lib/generateRangeString";
import { AdsetAggregateType } from "src/modules/global/functions/useAdsetAggregatedStats";
import { useAdsetPerTickStats } from "src/modules/global/functions/useAdsetPerTickStats";
import { getMonthFromNumber } from "src/modules/campaign/misc/getMonthFromNumber";
import { GraphCard } from "src/modules/campaign/components/GraphCard";
import format from "date-fns/format";
import { Button } from "src/components/Button/Button";
import { ReactComponent as KeyboardArrowRight } from "@material-symbols/svg-400/rounded/keyboard_arrow_right.svg";

interface MetaActivityAdsetSummary {
  id: string;
  Audience?: Pick<
    Audience,
    "id" | "name" | "createdAt" | "MetaInsights" | "type" | "__typename"
  >;
  MetaAudienceSet?: Pick<
    MetaAudienceSet,
    "id" | "name" | "createdAt" | "MetaInsights" | "__typename"
  >;
}

interface AudienceReportingCard {
  title: string;
  subtitle: string;
  metaActivityAdsets: MetaActivityAdsetSummary[];
  dateFrom?: Date;
  dateTo?: Date;
  adsetAggregateData: AdsetAggregateType[];
  activityId?: string;
}

export function AudienceReportingCard({
  title,
  subtitle,
  metaActivityAdsets,
  dateFrom,
  dateTo,
  adsetAggregateData: data,
  activityId,
}: AudienceReportingCard) {
  const [selectedAdset, setSelectedAdset] = useState(
    metaActivityAdsets.length > 0 ? metaActivityAdsets[0].id : null,
  );
  const metaActivityAdsetWithStats = metaActivityAdsets.map((v) => ({
    ...v,
    stats: data?.find((s) => s.adsetId === v.id),
  }));

  const selectedActivityAdset = metaActivityAdsetWithStats.find(
    (v) => v.id === selectedAdset,
  );

  return (
    <div className={styles.card}>
      {/* Side Panel */}
      <div className={styles.sidePanel}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{subtitle}</div>
        </div>
        <RadioGroup
          as="div"
          className={styles.audienceSelectorList}
          value={selectedAdset}
          onChange={setSelectedAdset}
        >
          {metaActivityAdsetWithStats.map((v) => (
            <RadioGroup.Option
              as="div"
              key={v.id}
              value={v.id}
              className={classNames(styles.audienceSelectorItem, {
                [styles.audienceSelectorItemActive]: selectedAdset === v.id,
              })}
              onKeyUp={(e) => e.preventDefault()}
              onKeyDown={(e) => e.preventDefault()}
            >
              <AudienceTitleWithIcon
                audience={v.Audience ?? v.MetaAudienceSet}
                iconColor="primary"
                isActive={selectedAdset === v.id}
              />
              <div className={styles.audienceSelectorItemSecondary}>
                CTR: {formatNumberByType(v.stats?.ctr || 0, "PERCENTAGE")}
              </div>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {/*
        {footer && (
          <div className={styles.footerWrapper}>
            <div className={styles.footer}>
              <div className={styles.footerHeading}>{footer.heading}</div>
              <div className={styles.footerValue}>{footer.value}</div>
            </div>
          </div>
        )}
        */}
      </div>

      {selectedActivityAdset && (
        <AdSetGraphStats
          adset={selectedActivityAdset}
          dateFrom={dateFrom}
          dateTo={dateTo}
          activityId={activityId}
        />
      )}
    </div>
  );
}

function AdSetGraphStats({
  adset,
  dateFrom,
  dateTo,
  activityId,
}: {
  adset: MetaActivityAdsetSummary & {
    stats: AdsetAggregateType;
  };
  dateFrom?: Date;
  dateTo?: Date;
  activityId?: string;
}) {
  const { data, loading } = useAdsetPerTickStats(
    adset.id,
    {
      dateFrom,
      dateTo,
    },
    activityId,
  );

  const adsetItem = adset?.Audience || adset?.MetaAudienceSet;

  if (!adsetItem) return <div></div>;
  if (loading) {
    return (
      <div className={styles.graphSection}>
        <Spinner height="full" />
      </div>
    );
  }

  const name = adsetItem.name;
  const estimatedSizeUpperBound = adsetItem.MetaInsights?.upperBoundCount;
  const estimatedSizeLowerBound = adsetItem.MetaInsights?.lowerBoundCount;
  const createdAt = new Date(adsetItem.createdAt);
  const estimatedSizeRange = generateRangeString(
    estimatedSizeLowerBound,
    estimatedSizeUpperBound,
  );

  const chartData = data
    ? data.map((v) => ({
        time:
          "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
        ...v,
      }))
    : [];

  return (
    <div className={styles.graphSection}>
      <div className={styles.graphSectionHeader}>
        <div>
          <div className={styles.graphStatsTitle}>{name}</div>
          <div className={styles.graphStatsSubtitle}>
            Estimate size: {estimatedSizeRange}
          </div>
        </div>
      </div>
      <div className={styles.graphList}>
        <GraphCard
          title={"Impressions"}
          subtitle={"as per selected date"}
          value={formatNumberByType(adset.stats?.impressions, "NUMBER")}
          chartData={chartData}
          chartDataKey={"impressions"}
        />
        <GraphCard
          title={"Clicks"}
          subtitle={"as per selected date"}
          value={formatNumberByType(adset.stats?.clicks, "NUMBER")}
          chartData={chartData}
          chartDataKey={"clicks"}
        />
      </div>

      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <div className={styles.footerLabel}>
            Audience created on {format(createdAt, "dd MMM, yyyy")}
          </div>

          <div className={styles.footerAction}>
            <Button
              style="plain"
              size="micro"
              to={
                adsetItem.__typename === "Audience"
                  ? `/audiences/${adsetItem.id}`
                  : `/audiences-sets/${adsetItem.id}`
              }
            >
              <span className={styles.buttonWithIcon}>
                <span>Learn more about this audience</span>
                <KeyboardArrowRight
                  height={18}
                  width={18}
                  fill="currentColor"
                />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
