import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./AudienceWorkshopPage.module.scss";
import { AudienceNameSection } from "../components/AudienceNameSection";
import { AudiencePresetOrInterestSelector } from "../components/AudiencePresetOrInterestSelector";
import { AudienceAdvanceTargeting } from "../components/AudienceAdvanceTargeting";
import { StepWizard } from "../../global/components/StepWizard";
import { useRef, useState } from "react";
import Banner from "src/components/Banner/Banner";
import { ReactComponent as Info } from "@material-symbols/svg-600/rounded/info.svg";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

export function AudienceWorkshopPage() {
  const audiencePresetRef = useRef<HTMLDivElement>();
  const advanceTargetingRef = useRef<HTMLDivElement>();
  const [existingAudience, setExistingAudience] = useState<{
    id: string;
    name: string;
  } | null>(null);

  function handleScrollTo(activeStepIndex: number) {
    if (activeStepIndex === 0) {
      // no jump
    } else if (activeStepIndex === 1) {
      audiencePresetRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (activeStepIndex === 2) {
      advanceTargetingRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <>
      <PageHeader title="Audience Workshop" />
      {existingAudience && (
        <Banner
          onClose={() => setExistingAudience(null)}
          content={
            <>
              Looks like same setting is also exist in your existing audience{" "}
              <ButtonV2
                variant="link"
                to={`/audience-sets/${existingAudience.id}`}
              >
                {existingAudience.name}
              </ButtonV2>
              . Please change settings to continue{" "}
            </>
          }
          icon={<Info height={14} width={14} />}
          type="error"
        />
      )}
      <div>
        {/*
        <WorkshopBanner
          backgroundGradient="linear-gradient(89.63deg, rgba(249, 244, 250, 0) 13.31%, #f9f4fa 99.68%)"
          heading="Elevate Your Sales Funnel With Audience"
          subheading="Crafted by market experts, these presets span the entire sales
              funnel, ensuring your campaigns hit the mark."
          image={{
            src: audienceCreateBanner,
            height: 148,
            width: 303,
            alt: "globe",
          }}
        />
      */}
        <StepWizard
          onResetField={(fieldKey, value, data) => {
            return data;
          }}
          onStepComplete={handleScrollTo}
          initialData={{}}
        >
          <AudienceNameSection />
          <AudiencePresetOrInterestSelector stepRef={audiencePresetRef} />
          <AudienceAdvanceTargeting
            stepRef={advanceTargetingRef}
            setExisitingAudience={setExistingAudience}
          />
        </StepWizard>
      </div>
    </>
  );
}
