import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  useBillingInvoicesQuery,
  useGetUserQuery,
} from "src/graphql/generated/schema";
import AuthContext from "src/stores/AuthContext";
import { ErrorHandler } from "../ErrorHandler";
import Layout from "../Layout/Layout";
import { BillingAndUsageBanner } from "./BillingAndUsageBanner";
import { BillingOverlay } from "src/modules/global/components/BillingOverlay";
import { ShopifyBillingOverlay } from "../../modules/global/components/ShopifyBillingOverlay";

interface Props {
  showSidebar?: boolean;
}

const engagePlanRequiredPages = ["automations", "templates"];

const growPlanRequiredPage = ["audiences"];

export function AppLayout({ showSidebar = true }: Props) {
  const { isAuthenticated, user, setUser, billing } = useContext(AuthContext);
  const [isBillingOverlayActive, setIsBillingOverlayActive] = useState(false);
  const [isShopifyBillingOverlayActive, setShopifyBillingOverlayActive] =
    useState(false);
  const location = window.location;
  const navigate = useNavigate();
  const { data: getUserData, error } = useGetUserQuery();
  const {
    data: openInvoiceData,
    loading: openInvoiceLoading,
    error: openInvoiceDataError,
  } = useBillingInvoicesQuery({
    variables: {
      status: "OPEN",
    },
  });

  useEffect(() => {
    if (getUserData && getUserData.me) {
      const user = getUserData.me;

      // if (!user.firstName && !user.lastName) {
      //   navigate("/onboarding/about-you");
      //   return;
      // }

      if (!user.Organization) {
        navigate("/onboarding/about-you");
        return;
      }

      if (!user.Client) {
        navigate("/onboarding/success");
        return;
      }

      setUser(getUserData.me);
    }
  }, [user, getUserData, isAuthenticated, navigate, setUser]);

  useEffect(() => {
    // if billing is empty then it means the billing is not finished
    if (!billing) {
      navigate("/onboarding/pricing");
      return;
    }

    if (billing) {
      const billingStatus = billing.status;

      const engagePlan = billing?.BillingEngagePlan;
      const growPlan = billing?.BillingGrowPlan;
      const primaryPath = location.pathname
        .split("/")
        .filter((v) => v.length > 0)[0];

      switch (billingStatus) {
        case "CANCELED":
          navigate("/onboarding/pricing");
          break;
        case "INCOMPLETE":
          navigate("/onboarding/pricing");
          break;
        case "INCOMPLETE_EXPIRED":
          navigate("/onboarding/pricing");
          break;
        case "PAST_DUE":
          break;
        case "UNPAID":
          if (billing.paymentProvider === "STRIPE") {
            setIsBillingOverlayActive(true);
          } else {
            setShopifyBillingOverlayActive(true);
          }
          break;
        case "TRIALING":
          break;
        case "ACTIVE":
          break;
      }

      if (
        openInvoiceData &&
        openInvoiceData.billingInvoices &&
        openInvoiceData.billingInvoices.length > 1
      ) {
        setIsBillingOverlayActive(true);
        return;
      }

      if (!engagePlan && engagePlanRequiredPages.includes(primaryPath)) {
        navigate(`/${primaryPath}/paywall`);
        return;
      }

      if (!growPlan && growPlanRequiredPage.includes(primaryPath)) {
        navigate(`/${primaryPath}/paywall`);
        return;
      }
    }
  }, [billing, openInvoiceData]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (isBillingOverlayActive) {
    return <BillingOverlay />;
  }

  if (isShopifyBillingOverlayActive) {
    return <ShopifyBillingOverlay />;
  }

  return (
    <>
      <BillingAndUsageBanner
        showSidebar={showSidebar}
        billing={billing}
        openInvoices={openInvoiceData ? openInvoiceData.billingInvoices : []}
      />
      <Layout showSidebar={showSidebar} user={user}>
        <Outlet />
      </Layout>
    </>
  );
}
