import { Button } from "../../../../components/Button/Button";
import styles from "./AdTemplateCreateForm.module.scss";
import { ReactComponent as Article } from "@material-symbols/svg-600/rounded/article.svg";
import { ReactComponent as PencilIcon } from "@material-symbols/svg-600/rounded/edit.svg";
import { useState } from "react";
import { ExistingPostModal } from "../../../campaign-wizard/components/ExistingPostModal";
import { useFormikContext } from "formik";
import { ExistingPostThumbnail } from "../../../campaign-wizard/components/ExistingPostThumbnail";
import { FormikError } from "../../../../components/FormikError/FormikError";
import { FormValues } from "../../misc/adTemplateCreateTypes";

export function AdTemplateExistingPostForm() {
  const { values, setFieldValue, setFieldTouched, errors } =
    useFormikContext<FormValues>();
  const [showExistingPostModal, setShowExisitingPostModal] = useState(false);
  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>Select Existing Post</div>
        <div className={styles.description}>
          Select the media, text and destination for your ad. You can also
          customise your media and text for each placement
        </div>
        <div className={styles.existingPostContainer}>
          {values.existingPostId ? (
            <>
              <ExistingPostThumbnail post={values.existingPost} />
              <div>
                <Button
                  onClick={() => {
                    setShowExisitingPostModal(true);
                  }}
                  size="micro"
                  style="outline"
                  color="subdued"
                >
                  <div className={styles.buttonWithIcon}>
                    Change Post
                    <PencilIcon width={16} height={16} fill="currentColor" />
                  </div>
                </Button>
              </div>
            </>
          ) : (
            <div>
              <Button
                width="auto"
                size="small"
                onClick={() => {
                  setShowExisitingPostModal(true);
                }}
                disabled={!values.adAccountId}
              >
                <div className={styles.buttonWithIcon}>
                  <Article width={16} height={16} fill="currentColor" />
                  Select Post
                </div>
              </Button>
            </div>
          )}
        </div>
        <FormikError fieldName="existingPostId" formikError={errors} />
      </div>

      <ExistingPostModal
        facebookConfig={{
          adAccountId: values.adAccountId,
          metaPageConnectedInstagramBusinessAccountId:
            values.metaPageConnectedInstagramBusinessAccountId,
        }}
        assetType={"IMAGE_AND_VIDEO"}
        selectedPost={values.existingPost}
        setSelectedPost={async (value) => {
          setFieldValue(`existingPostId`, value.id, true);
          setFieldValue(`existingPost`, value);
          setTimeout(() => setFieldTouched(`existingPostId`), 500);
        }}
        isOpen={showExistingPostModal}
        onClose={() => setShowExisitingPostModal(false)}
      />
    </div>
  );
}
