import { Link } from "react-router-dom";
import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./IconButton.module.scss";

export interface IIconButton {
  onClick?: (e: any) => void;
  color?: "primary" | "subdued" | "danger" | "normal";
  style?: "solid" | "outline" | "plain";
  disabled?: boolean;
  className?: string;
  rounded?: boolean;
  icon: ReactNode;
  shape?: "square" | "circle";
  size?: "micro" | "small" | "default";
  type?: "button" | "submit" | "reset";
  to?: string;
}

export function IconButton(props: IIconButton) {
  const {
    onClick,
    icon,
    className,
    disabled,
    shape = "circle",
    to,
    size = "default",
    color = "subdued",
    style = "solid",
    type = "button",
    ...otherProps
  } = props;

  const BaseElement = to ? Link : ("button" as any);

  return (
    <BaseElement
      to={to}
      type={BaseElement === "button" ? type : undefined}
      className={classNames(
        className,
        styles.button,
        styles[color],
        styles[style],
        styles[size],
        {
          [styles.shapeCircle]: shape === "circle",
          [styles.shapeSquare]: shape === "square",
          [styles.buttonDisabled]: disabled,
        }
      )}
      disabled={disabled}
      onClick={onClick}
      {...otherProps}
    >
      {icon}
    </BaseElement>
  );
}
