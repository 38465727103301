import { Spinner } from "src/components/Spinner/Spinner";
import { useAllCampaignsLazyQuery } from "src/graphql/generated/schema";
import { CampaignList } from "./CampaignList";
import { CampaignPagination } from "./CampaignPagination";
import { useState, useMemo, useEffect } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { showPaginationChecks } from "src/modules/global/functions/showPaginationCheck";
import { PaginationInput } from "../misc/paginationInput";
import { campaignFilters, campaignSortBy } from "../misc/campaignFilter";
import { campaignItemsPerPage } from "../misc/campaignItemsPerPage";
import { useDebounceEffect } from "../../campaign-wizard/functions/useDebounceEffect";

interface ICampaignListingCampaignGrouped {
  filters: campaignFilters;
  sortBy: campaignSortBy;
}

export function CampaignListingCampaignGrouped({
  filters,
  sortBy,
}: ICampaignListingCampaignGrouped) {
  const [pagination, setPagination] = useState<PaginationInput>({
    limit: campaignItemsPerPage,
    cursor: null,
  });
  const [allCampaignsFunc, { error, data, loading }] =
    useAllCampaignsLazyQuery();

  async function triggerAllCampaigns({ filters, pagination, sortBy }) {
    const query = filters.query.trim();
    await allCampaignsFunc({
      variables: {
        filters: {
          name: query,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          limit: pagination.limit,
          createdAt: filters.createdAt,
        },
        sortBy,
      },
    });
  }

  useEffect(() => {
    triggerAllCampaigns({ filters, pagination, sortBy });
  }, [sortBy, pagination, filters]);

  useDebounceEffect(
    async () => {
      await triggerAllCampaigns({ filters, pagination, sortBy });
    },
    300,
    [filters.query],
  );

  const showPagination = useMemo(() => {
    if (!data) {
      return false;
    }

    return showPaginationChecks({
      itemEdges: data.allCampaigns?.edges,
      pageInfo: data.allCampaigns?.pageInfo,
      itemsPerPage: campaignItemsPerPage,
    });
  }, [data]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading) {
    return <Spinner height={500} />;
  }

  return (
    <>
      <CampaignList campaigns={data?.allCampaigns?.edges || []} />

      {showPagination && (
        <CampaignPagination
          noPadding
          setPagination={({ limit, cursor }) => {
            if (filters.filterStatus === "ALL") {
              setPagination({
                limit,
                cursor,
              });
              return;
            }
          }}
          pageInfo={data?.allCampaigns?.pageInfo}
        />
      )}
    </>
  );
}
