import styles from "./Accounts.module.scss";
import {
  useGetBillingQuery,
  useGetUserQuery,
  useOrganizationUpdateMutation,
  useOrganizationUsageQuery,
} from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { UsageAndLimit } from "../components/UsageAndLimits";
import { AccountSecurity } from "../components/AccountSecurity";
import { ManageSubscription } from "../components/ManageSubscription";
import { OrganizationProfile } from "../components/OrganizationProfile";
import toast from "react-hot-toast";
import { ContactInformation } from "../components/ContactInformation";
import { GeneralInformation } from "../components/GeneralInformation";
import { InformationForm } from "../components/InformationForm";
import { DeleteOrganization } from "../components/DeleteOrganization";

export function Accounts() {
  const { data: userData, loading: userLoading, error } = useGetUserQuery();
  const {
    data: getBillingData,
    loading: getBillingLoading,
    error: getBillingError,
  } = useGetBillingQuery();
  const [organizationUpdateFunc, { loading: organizationUpdateLoading }] =
    useOrganizationUpdateMutation();
  const {
    data: organizationUsageData,
    loading: organizationUsageLoading,
    error: organizationUsageError,
  } = useOrganizationUsageQuery();

  if (userLoading || getBillingLoading || organizationUsageLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (getBillingError) {
    return <ErrorHandler error={getBillingError} />;
  }

  if (organizationUsageError) {
    return <ErrorHandler error={organizationUsageError} />;
  }
  return (
    <div className={styles.page}>
      <OrganizationProfile
        organization={userData.me.Organization}
        updateThumbnail={async (asset) => {
          try {
            await organizationUpdateFunc({
              variables: {
                input: {
                  thumbnailId: asset.id,
                },
              },
              refetchQueries: ["getUser"],
              awaitRefetchQueries: true,
            });

            toast.success("Updated profile picture successfully");
          } catch (err) {
            toast.error(err.message);
          }
        }}
      />
      <InformationForm
        organization={userData?.me?.Organization}
        updateOrganization={async ({ name }) => {
          try {
            await organizationUpdateFunc({
              variables: {
                input: {
                  name: name,
                },
              },
              refetchQueries: ["getUser"],
              awaitRefetchQueries: true,
            });

            toast.success("Updated organization settings successfully");
            return;
          } catch (err) {
            toast.error(err.message);
          }
        }}
      />
      <AccountSecurity email={userData.me.email} />
      {/* <ManageSubscription billing={getBillingData.billing} /> */}
      {userData.me.role === "ADMIN" && (
        <DeleteOrganization organization={userData.me.Organization} />
      )}
    </div>
  );
}
