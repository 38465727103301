import styles from "./CreateClientModal.module.scss";
import Modal from "../../../components/Modal/Modal";
import { Button } from "../../../components/Button/Button";
import {
  useClientCreateMutation,
  Asset as IAsset,
  useInviteUsersToClientMutation,
} from "src/graphql/generated/schema";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Avatar from "react-avatar";
import { Asset } from "../../../components/Smartphone/Asset";
import { AddMedia } from "../../media/components/AdTemplateCreateSlideOver/AddMedia";
import { ClientFieldsForm } from "./ClientFieldsForm";
import { ClientInput } from "../misc/clientInputType";
// import { Input } from "../../../components/Input/Input";
// import { ReactComponent as Search } from "@material-symbols/svg-600/rounded/search.svg";
// import AuthContext from "src/stores/AuthContext";
// import { useContext } from "react";

const createClientSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Client Name should be more than 3 characters")
    .required("Client name is required")
    .matches(
      /^[a-zA-Z0-9_\-,:\s]+$/g,
      "name should not have any special characters",
    ),
  timezoneCode: Yup.string()
    .required("Timezone is required")
    .typeError("Timezone is required"),
  currencyCode: Yup.string()
    .required("Currency is required")
    .typeError("Currency is required"),
  invitedMembers: Yup.array(Yup.string().email("Value must be a valid email")),
});

export function CreateClientModal({ isOpen, onClose }) {
  const [clientCreateFunc] = useClientCreateMutation();
  const [inviteUsersToClientFunc] = useInviteUsersToClientMutation();

  async function handleSubmit(values: ClientInput) {
    try {
      const { data } = await clientCreateFunc({
        variables: {
          input: {
            name: values.name,
            ...(values.Thumbnail && {
              assetId: values.Thumbnail.id,
            }),
            ...(values.industry && {
              industry: values.industry,
            }),
            currencyCode: values.currencyCode,
            timeZone: values.timezoneCode,
            users: values.members,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: ["organizationClients", "getUser"],
      });

      if (data) {
        if (values.invitedMembers) {
          await inviteUsersToClientFunc({
            variables: {
              input: {
                emails: values.invitedMembers,
                clientId: data.clientCreate.id,
              },
            },
          });
        }

        toast.success("Successfully created client");
        // setActiveClient(data.clientCreate);
        onClose();
      }
    } catch (err) {
      console.log(err);
      toast.error("An unknown error occurred");
    }
  }

  return (
    <Formik
      isInitialValid
      validateOnMount
      validationSchema={createClientSchema}
      initialValues={
        {
          name: "",
          Thumbnail: null,
          members: [],
          invitedMembers: [],
          currencyCode: null,
          timezoneCode: "Asia/Kolkata",
        } as ClientInput
      }
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue, isValid, isSubmitting }) => (
        <Form>
          <Modal
            isOpen={isOpen}
            closeOnBlur={false}
            onClose={onClose}
            title="Add Client"
            subtitle="Add client and manage your marketing need"
            noContainer
            maxWidth={"60%"}
            className={styles.modal}
            footer={{
              footerContent: "",
              hideBorder: true,
              footerActions: (
                <Button
                  width="auto"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  disabled={isSubmitting || !isValid}
                >
                  Add Client
                </Button>
              ),
            }}
          >
            <div className={styles.modalContainer}>
              <div className={styles.uploadSection}>
                {values?.Thumbnail ? (
                  <Asset
                    type={values.Thumbnail?.contentType}
                    preview={values?.Thumbnail?.uri}
                    name={values?.Thumbnail?.name}
                    className={styles.asset}
                  />
                ) : (
                  <Avatar
                    name={values.name}
                    className={styles.asset}
                    size="64px"
                  />
                )}

                <div className={styles.actionContainer}>
                  <div>
                    <AddMedia
                      multiple={false}
                      triggerLabel={"Upload"}
                      onSelect={function (i: IAsset[]): void {
                        const asset = i[0];
                        setFieldValue("Thumbnail", asset);
                      }}
                      buttonProps={{
                        size: "micro",
                        color: "subdued",
                        style: "outline",
                      }}
                    />
                  </div>
                  <div className={styles.uploadButtonDescription}>
                    Recommended size is 256px x 256px
                  </div>
                </div>
              </div>
              <ClientFieldsForm />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
