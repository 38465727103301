import { IBanner } from "src/components/Banner/Banner";
import { Button, IButton } from "src/components/Button/Button";
import styles from "./ExpandedBanner.module.scss";
import { ReactComponent as Error } from "@material-design-icons/svg/filled/error.svg";
import classNames from "classnames";

interface IExpandedBanner {
  title: String;
  type: IBanner["type"];
  actions: IButton[];
  children: string;
  className?: string;
}
export function ExpandedBanner({
  title,
  type,
  actions,
  children,
  className,
}: IExpandedBanner) {
  function getIcon() {
    if (type === "error") {
      return <Error width={16} height={16} />;
    }
  }
  return (
    <div className={classNames(styles[type], styles.card, className)}>
      <div className={styles.cardContent}>
        <div className={styles.bannerTitle}>
          <span className={styles.titleIcon}>{getIcon()}</span>
          <span className={styles[type]}>{title}</span>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.cardFooter}>
        <div className={classNames(styles.actionButtons)}>
          {actions.map((v) => (
            <Button {...v} />
          ))}
        </div>
      </div>
    </div>
  );
}
