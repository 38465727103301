import cookie from "js-cookie";
import { uuidv4 } from "../../../lib/uuidv4";

const tracingIdCookieKey = "tracing-id";

export function tracingIdGet() {
  const cookieTracingId = cookie.get(tracingIdCookieKey);

  if (cookieTracingId) {
    return cookieTracingId;
  } else {
    const newTracingId = tracingIdCreate();
    cookie.set(tracingIdCookieKey, newTracingId);
    return newTracingId;
  }
}

function tracingIdCreate() {
  const tracingId = uuidv4();

  return tracingId;
}
