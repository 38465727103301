import { useFormikContext, getIn, FormikContextType } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { Button } from "src/components/Button/Button";
import { CampaignInput } from "../misc/createCampaignTypes";
import { AdSwitcherSlider } from "./AdSwitcherSlider";
import { CreateAdFields } from "./CreateAdFields";
import styles from "./CreateFacebookAd.module.scss";
import { FacebookAdPreview } from "./FacebookAdPreview";
import { FacebookAdPreviewModal } from "./FacebookAdPreviewModal";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import {
  useMetaCatalogueProductsQuery,
  useMetaMediaByIdQuery,
} from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { getLineItemsFromAdInput } from "../functions/getLineItemsFromAdInput";
import { checkForDuplicatedAssetInLineItems } from "src/lib/checkForDuplicatedAssetInLineItems";

interface Props {
  setErrorBanner: (i: {
    content: ReactNode;
    isOpen: boolean;
    type: "error";
    onClose: () => void;
  }) => void;
  isCatalogueActivity: boolean;
}

function verifyLineItemAssets({
  values,
  setFieldError,
  baseKey,
}: {
  values: CampaignInput;
  setFieldError: FormikContextType<CampaignInput>["setFieldError"];
  baseKey: string;
}) {
  const activity = values.Activities[values.selectedActivityIndex];
  if (!activity.Ads) return;

  for (const ad of activity.Ads) {
    const hasDuplicatedAssets = checkForDuplicatedAssetInLineItems(
      ad.LineItems,
    );

    if (hasDuplicatedAssets) {
      setFieldError(
        `${baseKey}.LineItems[${hasDuplicatedAssets.lineItemIndex}].${hasDuplicatedAssets.assetType}`,
        hasDuplicatedAssets.message,
      );

      return {
        key: `${baseKey}.LineItems[${hasDuplicatedAssets.lineItemIndex}].${hasDuplicatedAssets.assetType}`,
        message: hasDuplicatedAssets.message,
      };
    }
  }
  return null;
}

export function CreateFacebookAd({
  isCatalogueActivity,
  setErrorBanner,
}: Props) {
  const { goBack } = useWizardContext();
  const {
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
    isValid,
    setFieldError,
  } = useFormikContext<CampaignInput>();
  const [showFullPreviewModal, setShowFullPreviewModal] = useState(false);
  const selectedActivityIndex = values.selectedActivityIndex;
  const baseKey = `Activities[${selectedActivityIndex}]`;
  const activity: CampaignInput["Activities"][number] = getIn(values, baseKey);
  const selectedAdIndex = activity.selectedAdIndex ?? 0;
  const adKey = `${baseKey}.Ads[${selectedAdIndex}]`;
  const ad = getIn(values, adKey);
  const {
    data: catalogueProductsData,
    loading,
    // error,
  } = useMetaCatalogueProductsQuery({
    variables: {
      metaCatalogueId: activity?.productCatalogueId,
    },
  });
  const { data: metaMediaByIdData } = useMetaMediaByIdQuery({
    variables: {
      id: activity?.Ads?.[selectedAdIndex]?.existingPostId,
    },
  });

  function setSelectedAdIndex(index: number) {
    setFieldValue(`${baseKey}.selectedAdIndex`, index);
  }

  // FIXME: this only works when values are changed. For rest of deps this doesn't work
  useEffect(() => {
    verifyLineItemAssets({ values, setFieldError, baseKey: adKey });
  }, [values, adKey, setFieldError]);

  if (activity.metaPurpose === "SALES_CATALOG" && loading) {
    return <Spinner height="screen" />;
  }

  const catalogueProducts =
    catalogueProductsData && catalogueProductsData.metaCatalogueProducts
      ? catalogueProductsData.metaCatalogueProducts
      : [];

  const metaMedia =
    metaMediaByIdData && metaMediaByIdData.metaMediaById
      ? metaMediaByIdData.metaMediaById
      : null;

  const lineItems = getLineItemsFromAdInput({
    ad,
    catalogueProducts,
    metaMedia,
  });

  return (
    <>
      <div className={styles.pageLayout}>
        <div className={styles.createAdSection}>
          <AdSwitcherSlider
            selectedActivityIndex={selectedActivityIndex}
            selectedAdIndex={selectedAdIndex}
            setSelectedAdIndex={setSelectedAdIndex}
          />
          <CreateAdFields
            isCatalogueActivity={isCatalogueActivity}
            setErrorBanner={setErrorBanner}
            // this makes sure the internal (formik)'s state is cleared if index is changed
            key={selectedAdIndex}
            selectedActivityIndex={selectedActivityIndex}
            selectedAdIndex={selectedAdIndex}
            adAccountName={activity?.adAccountName || "untitled"}
          />
          {/* <CreateNewAdBtn
            selectedActivityIndex={selectedActivityIndex}
            setSelectedAdIndex={setSelectedAdIndex}
          /> */}
        </div>
        <div>
          <FacebookAdPreview
            adType={ad.staticAdType}
            lineItems={lineItems}
            defaultSelectedLineItem={selectedAdIndex}
            showModal={() => setShowFullPreviewModal(true)}
            isValid={isValid}
            primaryText={getIn(
              values,
              `${baseKey}.Ads[${selectedAdIndex}].primaryText`,
            )}
            callToActionLabel={getIn(
              values,
              `${baseKey}.Ads[${selectedAdIndex}].callToActionLabel`,
            )}
            adAccountName={activity?.adAccountName}
            profilePicture={activity?.metaPagePicture}
          />
          {/* <FacebookAdPreviewModal
            isOpen={showFullPreviewModal}
            activityKey={baseKey}
            adKey={`${baseKey}.Ads[${selectedAdIndex}]`}
            values={values}
            onClose={() => setShowFullPreviewModal(false)}
          /> */}
        </div>
        <div className={styles.stickyActions}>
          <div className={styles.footer}>
            <p className={styles.adCountText}>
              Ad Count:{" "}
              <span className={styles.adCount}>
                {selectedAdIndex + 1}/
                {values.Activities[selectedActivityIndex].Ads?.length}
              </span>
            </p>
            <div className={styles.footerActions}>
              <Button
                size="small"
                style="outline"
                color="subdued"
                type="button"
                onClick={() => goBack(values)}
              >
                Back
              </Button>
              <Button
                disabled={
                  !isValid
                  // (!isCatalogueActivity && verifyLineItems(values) !== true)
                }
                onClick={() => {
                  // verifyLineItems();
                  const payload = verifyLineItemAssets({
                    values,
                    setFieldError,
                    baseKey: adKey,
                  });
                  if (payload) {
                    return;
                  }
                  handleSubmit();
                }}
                loading={isSubmitting}
                size="small"
                color="primary"
              >
                Save & Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
