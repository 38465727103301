import { LineItems } from "src/lib/types";
import { Button } from "src/components/Button/Button";
import Modal from "src/components/Modal/Modal";
import { Smartphone } from "src/components/Smartphone/Smartphone";
import styles from "./ManageAssetsModal.module.scss";
import { ReactComponent as Refresh } from "@material-design-icons/svg/outlined/refresh.svg";
import { useRef, useState } from "react";
import { useFormikContext } from "formik";
import { useCreateAsset } from "../functions/useCreateAsset";
import {
  imageAndVideoMimeType,
  videoMimeTypes,
  imageMimeTypes,
} from "../misc/supportedAssetTypes";
import {
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from "src/modules/global/misc/maxSizes";

interface Props {
  showManageAssetModal: boolean;
  onClose: () => void;
  lineItems?: LineItems[];
  assetType: "IMAGE" | "VIDEO" | "IMAGE_AND_VIDEO";
  selectedLineItemIndex: number;
  baseKey: string;
  adAccountId: string;
  profilePicture: string;
  setSelectedImageForCrop: (i: {
    baseKey: string;
    aspectRatio: number;
    src: string;
  }) => void;
  adAccountName: string;
}

type TName = "PostAsset" | "StoryAsset";

export function ManageAssetsModal({
  showManageAssetModal,
  onClose,
  lineItems,
  assetType,
  baseKey,
  profilePicture,
  adAccountId,
  selectedLineItemIndex,
  adAccountName,
}: Props) {
  const [loading, setLoading] = useState<TName | null>(null);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const postFileRef = useRef<HTMLInputElement>(null);
  const storyFileRef = useRef<HTMLInputElement>(null);
  const { handleFileUpload } = useCreateAsset({
    validMimeType: imageAndVideoMimeType,
    videoMimeTypes,
    imageMimeTypes,
    // 20 MB is max file limit
    maxVideoFileSizeInBytes: MAX_FILE_SIZE_VIDEO,
    maxImageFileSizeInBytes: MAX_FILE_SIZE_IMAGE,
    adAccountId,
  });

  async function handleUpload(files: FileList, name: TName) {
    setLoading(name);
    const storyAsset = lineItems[selectedLineItemIndex]?.StoryAsset;
    const assetPayload = await handleFileUpload(files);

    if (name === "PostAsset" && !storyAsset) {
      const postAsset = lineItems[selectedLineItemIndex].PostAsset;
      setFieldValue(`${baseKey}.StoryAsset`, postAsset);
      setFieldValue(`${baseKey}.PostAsset`, assetPayload);
      setFieldTouched(`${baseKey}.PostAsset`);
      setLoading(null);
      return;
    }

    setFieldTouched(`${baseKey}.${name}`);
    setFieldValue(`${baseKey}.${name}`, assetPayload);
    setLoading(null);
  }

  let validMimeType = imageAndVideoMimeType;

  if (assetType === "IMAGE") {
    validMimeType = imageMimeTypes;
  } else if (assetType === "VIDEO") {
    validMimeType = videoMimeTypes;
  }

  return (
    <Modal
      isOpen={showManageAssetModal}
      closeOnBlur={false}
      onClose={onClose}
      title="Manage Assets and Placements"
      subtitle="We evaluated your image and cropped it to fill the placement when we think it may perform better. You can edit these crops or upload new media for each placement by hovering over the image."
      className={styles.assetModal}
      noContainer
    >
      <div className={styles.assetsContainer}>
        <div className={styles.assetTile}>
          <p className={styles.assetTileText}>
            Feeds, In-stream ads for videos and reels
          </p>
          <Smartphone
            preview={lineItems[selectedLineItemIndex]}
            variant="feed"
            accountName={adAccountName || "Hello"}
            profilePicture={profilePicture}
          />
          <div className={styles.btnContainer}>
            {/* <Button
              onClick={() => {
                if (lineItems[selectedLineItemIndex].PostAsset.uri) {
                  setSelectedImageForCrop({
                    aspectRatio: 1,
                    baseKey: `${baseKey}.PostAsset`,
                    src: lineItems[selectedLineItemIndex].PostAsset.uri,
                  });
                }
              }}
              color="subdued"
              size="small"
              style="outline"
            >
              <span>Crop</span>
              <Crop className={styles.icon} />
            </Button> */}
            <Button
              loading={loading === "PostAsset"}
              onClick={() => {
                postFileRef.current?.click();
              }}
              color="subdued"
              size="small"
              style="outline"
            >
              <span>Replace</span>
              <Refresh className={styles.icon} />
            </Button>
            <input
              type="file"
              ref={postFileRef}
              accept={imageAndVideoMimeType.join(",")}
              onChange={(e) => handleUpload(e.target.files, "PostAsset")}
              hidden
            />
          </div>
        </div>
        <div className={styles.assetTile}>
          <p className={styles.assetTileText}>
            Stories and Reels, Apps and sites
          </p>
          <Smartphone
            preview={lineItems[selectedLineItemIndex]}
            variant="stories"
            accountName={adAccountName || "Hello"}
            profilePicture={profilePicture}
          />
          <div className={styles.btnContainer}>
            {/* <Button
              onClick={() => {
                if (!lineItems[selectedLineItemIndex].StoryAsset) {
                  const postAsset = lineItems[selectedLineItemIndex].PostAsset;
                  setFieldValue(`${baseKey}.StoryAsset`, postAsset);
                }
                const imgSource = lineItems[selectedLineItemIndex].StoryAsset
                  ?.uri
                  ? lineItems[selectedLineItemIndex].StoryAsset?.uri
                  : lineItems[selectedLineItemIndex].PostAsset.uri;
                if (imgSource) {
                  setSelectedImageForCrop({
                    baseKey: `${baseKey}.StoryAsset`,
                    aspectRatio: 9 / 16,
                    src: imgSource,
                  });
                }
              }}
              color="subdued"
              size="small"
              style="outline"
            >
              <span>Crop</span>
              <Crop className={styles.icon} />
            </Button> */}

            <Button
              onClick={() => {
                storyFileRef.current?.click();
              }}
              loading={loading === "StoryAsset"}
              color="subdued"
              size="small"
              style="outline"
            >
              <span>Replace</span>
              <Refresh className={styles.icon} />
            </Button>
            <input
              accept={validMimeType.join(", ")}
              type="file"
              ref={storyFileRef}
              onChange={(e) => handleUpload(e.target.files, "StoryAsset")}
              hidden
            />
          </div>
        </div>
        {/* <div className={styles.assetTile}>
          <p className={styles.assetTileText}>
            Right column, Search results, Instant Articles
          </p>
          <Smartphone
            preview={lineItems[selectedLineItemIndex]}
            variant="search"
          />
          <div className={styles.btnContainer}>
            <Button color="subdued" size="small" style="outline">
              <span>Crop</span>
              <Crop className={styles.icon} />
            </Button>

            <Button
              onClick={() => {
                storyFileRef.current?.click();
              }}
              loading={}
              color="subdued"
              size="small"
              style="outline"
            >
              <span>Replace</span>
              <Refresh className={styles.icon} />
            </Button>
            <input
              type="file"
              ref={storyFileRef}
              accept={validMimeType.join(",")}
              onChange={(e) => handleFileUpload(e.target.files, "StoryAsset")}
              hidden
            />
          </div>
        </div> */}
      </div>
    </Modal>
  );
}
