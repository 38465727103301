import * as Yup from "yup";

export const audiencesSchema = Yup.object({
  // Activities: Yup.array(
  //   Yup.object({
  Adsets: Yup.array()
    .min(1, "Should have atleast 1 audience connected")
    .max(20, "Can't have more than 20 adsets"),
  //   })
  // ),
});
