import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./OnboardingDashboard.module.scss";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import { NextStepCard } from "./NextStepsCard";
import { AIBanner } from "./AIBanner";
import CodewordResourcesList from "../CodewordResourcesList/CodewordResourcesList";
import DashboardFooter from "../DashboardFooter/DashboardFooter";

interface IOnboardingDashboard {
  setIsOnboarded: (i: boolean) => void;
  selectedDate: any;
}

export function OnboardingDashboard({
  setIsOnboarded,
  selectedDate,
}: IOnboardingDashboard) {
  const { user } = useContext(AuthContext);

  return (
    <div className={styles.onboardingDashboard}>
      <PageHeader
        title={
          <div>
            Hey <span className={styles.gradientText}>{user?.firstName}</span>,
            see what's happening
          </div>
        }
      />

      <div className={styles.cards}>
        <NextStepCard setIsOnboarded={setIsOnboarded} />
        <AIBanner />
        <div className={styles.checkoutResources}>
          <CodewordResourcesList />
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
}
