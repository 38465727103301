import classNames from "classnames";
import styles from "./CountryFlag.module.scss";

export interface ICountryFlag {
  countryCode: string;
  rounded: boolean;
  showCountryCode: boolean;
}

export default function CountryFlag({
  countryCode,
  rounded,
  showCountryCode,
}: ICountryFlag) {
  return (
    <div className={styles.location}>
      {countryCode ? (
        <>
          <img
            alt={countryCode}
            src={`https://flagsapi.com/${countryCode}/flat/64.png`}
            height={24}
            width={24}
            className={classNames({ [styles.roundFlag]: rounded })}
          />
          {showCountryCode && <span>{countryCode}</span>}
        </>
      ) : (
        <span>-</span>
      )}
    </div>
  );
}
