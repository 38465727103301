import classNames from "classnames";
import { formatNumberByType } from "../functions/formatNumberByType";
import styles from "./StatCard.module.scss";
import { useGetActiveCurrencyCode } from "../functions/useGetActiveCurrencyCode";

export default function StatCard({
  title,
  value,
  valueType,
  contributionList,
  className,
}: {
  title: string;
  value: any;
  valueType: "CURRENCY" | "PERCENTAGE" | "NUMBER" | "MULTIPLIER";
  contributionList: { src: string; alt: string }[];
  className?: string;
}) {
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  return (
    <div className={classNames(styles.statCard, className)}>
      <div className={styles.statCardHeader}>
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>
          {formatNumberByType(value, valueType, {
            showDecimal: true,
            selectedCurrencyCode,
          })}
        </div>
      </div>
      <div className={styles.footer}>
        <span className={styles.contribution}>Contribution </span>
        <div className={styles.contributionIconList}>
          {contributionList.map((contribution) => (
            <img
              src={contribution.src}
              alt={contribution.alt}
              className={styles.contributionIcon}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
