import { SegmentDeviceStats } from "./SegmentDeviceStats";
import { SegmentEngagementInsight } from "./SegmentEngagementInsight";
import { SegmentSectionHeading } from "./SegmentSectionHeading";
import { SegmentSingleStats } from "./SegmentSingleStats";
import styles from "./SegmentOverview.module.scss";
import { useState } from "react";
import { SegmentOverviewGraph } from "./SegmentOverviewGraph";
import { SegmentCustomerEngageGraph } from "./SegmentCustomerEngageGraph";
import { SegmentGrowthGraph } from "./SegmentGrowthGraph";
import { SegmentQuery } from "src/graphql/generated/schema";
import { Tabs } from "src/components/Tabs/Tabs";
import { ReactComponent as SegmentIcon } from "../../global/assets/segment.svg";
import { titleCase } from "src/lib/titleCase";
import format from "date-fns/format";

interface ISegmentOverview {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
  segment: SegmentQuery["segment"];
}

export function SegmentOverview({
  segmentId,
  dateFrom,
  segment,
  dateTo,
}: ISegmentOverview) {
  const [selectedGraphTab, setSelectedGraphTab] = useState("OVERVIEW");

  return (
    <>
      <div>
        <h2 className={styles.subHeading}>About this segment</h2>
        <SegmentSingleStats segment={segment} />
      </div>
      <div>
        <h2 className={styles.subHeading}>Segment Performance by this week</h2>
        <p className={styles.description}>
          Here Conversion is refer to order value and order count, Metrics based
          on Segment
        </p>

        <div className={styles.card}>
          <div className={styles.tabWrapper}>
            <Tabs
              onTabChange={setSelectedGraphTab}
              selectedTab={selectedGraphTab}
              tabs={graphTabs}
            />
          </div>
          <div className={styles.childrenWrapper}>
            {selectedGraphTab === "OVERVIEW" && (
              <SegmentOverviewGraph
                dateFrom={dateFrom}
                dateTo={dateTo}
                segmentId={segmentId}
              />
            )}
            {selectedGraphTab === "CUSTOMER_ENGAGE" && (
              <SegmentCustomerEngageGraph
                dateFrom={dateFrom}
                dateTo={dateTo}
                segmentId={segmentId}
              />
            )}
            {selectedGraphTab === "SEGMENT_GROWTH" && (
              <SegmentGrowthGraph
                dateFrom={dateFrom}
                dateTo={dateTo}
                segmentId={segmentId}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <SegmentSectionHeading
          subHeading={"Customers Insight in this segment"}
          description={
            "Here Conversion is refer to order value and order count, Metrics based on Channels"
          }
        />
        <div className={styles.insightsAndDevice}>
          <div className={styles.insightWrapper}>
            <SegmentEngagementInsight
              segmentId={segmentId}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </div>
          <div className={styles.devicesWrapper}>
            <SegmentDeviceStats
              segmentId={segmentId}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={styles.lastHeadingWrapper}>
          <SegmentSectionHeading
            subHeading="About this segment"
            description="Information about this segment. You will get to know about brief of this segment."
          />
        </div>
        <div className={styles.tile}>
          <div className={styles.imgContainer}>
            <SegmentIcon className={styles.img} />
          </div>
          <div>
            <span className={styles.title}>{segment.name}</span>
            <p className={styles.convertDescription}>
              {titleCase(segment.type)}
            </p>
          </div>
        </div>
        {segment.filter && (
          <div className={styles.codeContainer}>
            <pre className={styles.code}>{segment.filter}</pre>
          </div>
        )}
      </div>
    </>
  );
}

const graphTabs = [
  {
    label: "Overview",
    value: "OVERVIEW",
  },
  {
    label: "Customer Engage",
    value: "CUSTOMER_ENGAGE",
  },
  {
    label: "Segment Growth",
    value: "SEGMENT_GROWTH",
  },
];
