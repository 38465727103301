import Dropdown from "src/components/Dropdown/Dropdown";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { Tabs } from "src/components/Tabs/Tabs";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import styles from "./TemplateListing.module.scss";
import { useState } from "react";
import { TemplateListingEmailTable } from "../components/TemplateListingEmailTable";
import { Button } from "src/components/Button/Button";
import { TemplateListingOptionModal } from "../components/TemplateListingOptionModal";
import { TemplateSortBy } from "src/graphql/generated/schema";
import { TemplateListingCommonTable } from "../components/TemplateListingCommonTable";

const tabs = [
  {
    label: "Email",
    value: "Email",
  },
  {
    label: "SMS",
    value: "SMS",
  },
  {
    label: "WhatsApp",
    value: "WhatsApp",
  },
];

export function TemplateListing() {
  const [selectedTab, setSelectedTab] = useState("Email");
  const [showChooseTemplate, setShowChooseTemplate] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState(null);
  const [sortBy, setSortBy] = useState<TemplateSortBy>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  const tableOptions = {
    Email: <TemplateListingEmailTable />,
    SMS: <TemplateListingCommonTable />,
    WhatsApp: <TemplateListingCommonTable />,
  };

  return (
    <div>
      <PageHeader
        title={"Templates"}
        actions={
          <div className={styles.headerActions}>
            {/* <Dropdown
              title={
                <span className={styles.sortTitle}>
                  Sort by{" "}
                  <ArrowDown
                    width={20}
                    height={20}
                    className={styles.sortIco}
                  />
                </span>
              }
              items={[
                {
                  children: "Created At: Newest",
                  onClick: () => {
                    setSortBy({
                      direction: "DESC",
                      field: "CREATED_AT",
                    });
                  },
                },
                {
                  children: "Created At: Oldest",
                  onClick: () => {
                    setSortBy({
                      direction: "ASC",
                      field: "CREATED_AT",
                    });
                  },
                },
                {
                  children: "Name: A-Z",
                  onClick: () => {
                    setSortBy({
                      direction: "ASC",
                      field: "NAME",
                    });
                  },
                },
                {
                  children: "Name: Z-A",
                  onClick: () => {
                    setSortBy({
                      direction: "DESC",
                      field: "NAME",
                    });
                  },
                },
              ]}
            /> */}
            <Button
              size="small"
              color="primary"
              onClick={() => setShowChooseTemplate(true)}
            >
              Create Template
            </Button>
          </div>
        }
      />
      <div className={styles.tabsWrapper}>
        <Tabs
          // actions={""}
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />
      </div>
      <TemplateListingOptionModal
        isOpen={showChooseTemplate}
        onClose={() => {
          setShowChooseTemplate(false);
          setSelectedTemplateType(null);
        }}
        selectedTemplateType={selectedTemplateType}
        setSelectedTemplateType={setSelectedTemplateType}
      />
      <div style={{ padding: "1rem 1.5rem" }}>{tableOptions[selectedTab]}</div>
    </div>
  );
}
