import Modal from "src/components/Modal/Modal";
import styles from "./AudienceListViewSelection.module.scss";
import { useState } from "react";
import { Button } from "src/components/Button/Button";
import { Form, Formik } from "formik";
import Alert from "src/components/Alert/Alert";
import { AdsetInput } from "../misc/createCampaignTypes";
import { AudienceListSelectionTable } from "./AudienceListSelectionTable";
import { AudienceListNoResults } from "./AudienceListNoResults";

interface IAudienceSetViewSelection {
  isShowSelectionOpen: boolean;
  setIsShowSelectionOpen: (i: boolean) => void;
  initialValues: { adsets: AdsetInput[] };
  onChange: (i: { adsets: AdsetInput[] }) => void;
}

export function AudienceSetViewSelection({
  isShowSelectionOpen,
  setIsShowSelectionOpen,
  initialValues,
  onChange,
}: IAudienceSetViewSelection) {
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);

  // Mocking audienceAudienceSetIndex so we don't have to refetch
  // all the selected audience set indexes
  function createAudienceAudienceSetIndexFromAdsets(): any {
    return initialValues.adsets.map((adset) => {
      return {
        __typename: "AudienceAudienceSetIndexEdge" as const,
        cursor: "NONE",
        node: {
          __typename: "AudienceAudienceSetIndex" as const,
          id: adset.templateId,
          name: adset.name,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          audienceId: adset.audienceId,
          metaAudienceSetId: adset.audienceSetId,
          ...(adset.audienceId && {
            Audience: {
              id: adset.audienceId,
              type: adset.audienceType,
              name: adset.name,
              description: adset.description,
            },
          }),
          ...(adset.audienceSetId && {
            MetaAudienceSet: {
              id: adset.audienceSetId,
              name: adset.name,
              description: adset.description,
            },
          }),
        },
      };
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        setIsShowSelectionOpen(false);
        onChange(values);
      }}
    >
      {({ values, dirty, handleSubmit, resetForm }) => {
        // show alert only when user made any changes
        function handleBack() {
          if (dirty) {
            setIsDiscardAlertShowing(true);
          } else {
            setIsShowSelectionOpen(false);
          }
        }

        function stay() {
          setIsDiscardAlertShowing(false);
        }

        function discardChanges() {
          // reset the internal form state
          resetForm();
          setIsDiscardAlertShowing(false);
          setIsShowSelectionOpen(false);
        }

        return (
          <Modal
            maxWidth={"75rem"}
            title="Manage Selection"
            isOpen={isShowSelectionOpen}
            onClose={() => handleBack()}
          >
            <Form onSubmit={handleSubmit}>
              <div className={styles.wrapper}>
                {/* <div className={styles.helpText}>
                  <InfoIcon
                    fill="var(--color-disabled)"
                    width={18}
                    height={18}
                  />
                  Here are your selected Interests, Behaviour and Demographics.
                  These selection will be used for the creation of your audience
                </div> */}

                <div className={styles.container}>
                  {initialValues.adsets.length ? (
                    <div className={styles.tableWrapper}>
                      <AudienceListSelectionTable
                        adsets={values.adsets}
                        audienceAudienceSetIndexEdges={createAudienceAudienceSetIndexFromAdsets()}
                        baseKey="adsets"
                      />
                    </div>
                  ) : (
                    <AudienceListNoResults />
                  )}
                </div>

                <div className={styles.footer}>
                  <Button onClick={handleBack} color="subdued" style="outline">
                    Back
                  </Button>
                  <Button type="submit">Save and Exit</Button>
                </div>
              </div>
              <Alert
                isOpen={isDiscardAlertShowing}
                onClose={() => setIsDiscardAlertShowing(false)}
                secondaryAction={{
                  children: "Yes",
                  color: "primary",
                  onClick: discardChanges,
                }}
                primaryAction={{
                  children: "No",
                  color: "subdued",
                  style: "outline",
                  onClick: stay,
                }}
                title="Do you want to leave without saving the changes?"
              >
                The changes you made inside the modal will be lost
              </Alert>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
