import styles from "./AppInitializingLoader.module.scss";
import { ReactComponent as MacroLogo } from "../assets/macroLogoPrimaryIcon.svg";

export function AppInitializingLoader() {
  return (
    <div className={styles.expandedSection}>
      <span className={styles.animation}>
        <MacroLogo />
      </span>
    </div>
  );
}
