import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./ToggleButton.module.scss";

interface IToggleButton {
  options: [
    { icon: ReactNode; value: string },
    { icon: ReactNode; value: string }
  ];
  value: string;
  onChange: (i: string) => void;
}

export function ToggleButton({ options, value, onChange }: IToggleButton) {
  const firstOption = options[0];
  const secondOption = options[1];

  function handleSelect(value: string) {
    onChange(value);
  }

  return (
    <div className={styles.wrapper}>
      <button
        onClick={() => handleSelect(firstOption.value)}
        className={classNames(styles.toggleItem, styles.firstOption, {
          [styles.selectedToggleItem]: value === firstOption.value,
        })}
        type="button"
      >
        {firstOption.icon}
      </button>
      <button
        onClick={() => handleSelect(secondOption.value)}
        className={classNames(styles.toggleItem, styles.secondOption, {
          [styles.selectedToggleItem]: value === secondOption.value,
        })}
        type="button"
      >
        {secondOption.icon}
      </button>
    </div>
  );
}
