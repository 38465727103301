import { getIn, useFormikContext } from "formik";
import { IBanner } from "src/components/Banner/Banner";
import { FilesInput } from "src/modules/global/components/FileUpload/FileUploadSimple";
import { FileCard } from "./FileCard";
import {
  imageMimeTypes,
  imageAndVideoMimeType,
  videoMimeTypes,
} from "../misc/supportedAssetTypes";
import { useCreateAsset } from "../functions/useCreateAsset";
import { LineItems } from "src/lib/types";
import toast from "react-hot-toast";
import {
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from "src/modules/global/misc/maxSizes";

interface Props {
  baseKey: string;
  lineItemIndex: number;
  showManageAssetModal?: boolean;
  assetType?: "IMAGE" | "VIDEO" | "IMAGE_AND_VIDEO";
  adAccountId?: string;
  setShowManageAssetModal?: (i: number) => void;
  /** @deprecated */
  setErrorBanner?: (i: IBanner | null) => void;
  shouldTriggerAssetManageModal: boolean;
}

export function AdAssetField({
  lineItemIndex,
  baseKey,
  adAccountId,
  assetType = "IMAGE_AND_VIDEO",
  showManageAssetModal: _showManageAssetModal,
  setShowManageAssetModal,
  shouldTriggerAssetManageModal = true,
}: Props) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const lineItem: LineItems = getIn(values, `${baseKey}`);
  const postAssetValue: LineItems["PostAsset"] = getIn(
    values,
    `${baseKey}.PostAsset`
  );
  const { loading, handleFileUpload } = useCreateAsset({
    maxVideoFileSizeInBytes: MAX_FILE_SIZE_VIDEO,
    maxImageFileSizeInBytes: MAX_FILE_SIZE_IMAGE,
    validMimeType:
      assetType === "IMAGE_AND_VIDEO"
        ? imageAndVideoMimeType
        : assetType === "VIDEO"
        ? videoMimeTypes
        : imageMimeTypes,
    videoMimeTypes,
    imageMimeTypes,
    // handle minimum height and width according to asset
    minimumHeight: 480,
    minimumWidth: 480,
    adAccountId,
  });

  // bug in formik : https://github.com/jaredpalmer/formik/issues/2059 - Apparently it's not fixed yet
  // source : https://github.com/jaredpalmer/formik/issues/2059#issuecomment-920227938
  const setFormikFieldValueResolved = async (key: string, value: any) => {
    await setFieldValue(key, value, true); // shouldValidate ( set true ) should work by default but due to above mentioned bug this does not seems to work
    setFieldTouched(key, true);
  };

  function removeUploadedFile(name: "PostAsset" | "StoryAsset" | "BOTH") {
    if (name === "BOTH") {
      setFormikFieldValueResolved(`${baseKey}.PostAsset`, null);
      setFormikFieldValueResolved(`${baseKey}.StoryAsset`, null);
      return;
    }
    setFormikFieldValueResolved(`${baseKey}.${name}`, null);
  }

  // function handleErrorBanner(fileRejections: FileRejection[]) {
  //   if (fileRejections.length > 0) {
  //     setErrorBanner({
  //       content:
  //         fileRejections[0].errors[0].code === "file-too-large"
  //           ? `Cannot upload file bigger than ${
  //               MAX_FILE_SIZE / (1024 * 1024)
  //             } MB`
  //           : fileRejections[0].errors[0].message,
  //       isOpen: true,
  //       type: "error",
  //       onClose: () => {
  //         setErrorBanner(null);
  //       },
  //     });
  //   }
  // }

  async function handleUpload(
    files: FileList,
    name: "PostAsset" | "StoryAsset"
  ) {
    try {
      const payload = await handleFileUpload(files);
      setFieldTouched(`${baseKey}.${name}`);
      setFieldValue(`${baseKey}.${name}`, payload);
      if (shouldTriggerAssetManageModal) {
        setShowManageAssetModal(lineItemIndex);
      }
    } catch (err) {
      toast.error(err.message);
      return;
    }
  }

  // default is IMAGE_AND_VIDEO case
  let description = "Accepts jpg, png and mp4";
  let title = "Upload Photo/Video or Drag & Drop here";

  if (assetType === "IMAGE") {
    description = "Accepts jpg, png and jpeg";
    title = "Upload Photo or Drag & Drop here";
  }

  if (assetType === "VIDEO") {
    description = "Accepts mp4, quickTime and mov";
    title = "Upload Video or Drag & Drop here";
  }

  return (
    <>
      <FilesInput
        accept={{
          ...((assetType === "IMAGE" || assetType === "IMAGE_AND_VIDEO") && {
            "image/png": [".png"],
            "image/gif": [".gif"],
            "image/jpeg": [".jpg", ".jpeg"],
          }),
          ...((assetType === "VIDEO" || assetType === "IMAGE_AND_VIDEO") && {
            "video/*": [".mp4", ".mov", ".quicktime"],
          }),
        }}
        files={postAssetValue as any}
        setFiles={(file) => handleUpload(file as any, "PostAsset")}
        // onError={handleErrorBanner}
        showErrors={true}
        description={`${description}: Max Size ${
          MAX_FILE_SIZE_IMAGE / (1024 * 1024)
        } MB`}
        maxSize={MAX_FILE_SIZE_IMAGE}
        disabled={loading}
        title={<p>{title}</p>}
        showLoading={true}
        loading={loading}
      />

      {postAssetValue && (
        <FileCard
          showViewPlacement={shouldTriggerAssetManageModal}
          onRemove={() => removeUploadedFile("BOTH")}
          lineItem={lineItem}
          setShowManageAssetModal={() => setShowManageAssetModal(lineItemIndex)}
        />
      )}
    </>
  );
}
