import { Button } from "src/components/Button/Button";
import Modal from "src/components/Modal/Modal";
import FacebookSettings from "src/modules/global/components/FacebookSettings";
import styles from "./AdTemplateFacebookSettingsModal.module.scss";
import { Form, Formik } from "formik";
import { StickyFooter } from "src/modules/global/components/StickyFooter";

const fieldsMapper = {
  adAccountId: `adAccountId`,
  pageId: `pageId`,
  instagramPageId: `instagramAccountId`,
  metaPageConnectedInstagramBusinessAccountId:
    "metaPageConnectedInstagramBusinessAccountId",
  pixelId: `pixelId`,
};

interface FormState {
  adAccountId: string;
  pageId: string;
  instagramAccountId: string;
  metaPageConnectedInstagramBusinessAccountId: string;
  pixelId: string;
}

interface IAdTemplateFacebookSettingsModal {
  isOpen: boolean;
  onClose: () => void;
  initialFormValues: FormState;
  handleSubmit: (i: FormState) => void;
}

export function AdTemplateFacebookSettingsModal({
  isOpen,
  initialFormValues,
  onClose,
  handleSubmit,
}: IAdTemplateFacebookSettingsModal) {
  return (
    <Modal
      title="Choose Facebook Account Settings"
      isOpen={isOpen}
      maxWidth="860px"
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={(values: any) => {
          handleSubmit(values);
        }}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.modalWrapper}>
                <FacebookSettings
                  showConversionDomainInput={false}
                  containerClass={styles.container}
                  fieldsMapper={fieldsMapper}
                  showInstagramFields={true}
                />
                <div className={styles.footerWrapper}>
                  <StickyFooter
                    label={
                      <span className={styles.helpMessage}>
                        Select a pixel to use website and pixel based audiences
                      </span>
                    }
                    actions={
                      <div className={styles.buttonGroup}>
                        <Button type="submit" size="small">
                          Submit
                        </Button>
                        <Button
                          onClick={onClose}
                          style="outline"
                          color="subdued"
                          size="small"
                        >
                          Cancel
                        </Button>
                      </div>
                    }
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
