import styles from "./SegmentCustomerEngageGraph.module.scss";
import { BarGraph } from "src/modules/global/components/BarGraph";
import { formatNumber } from "src/lib/formatNumber";
import { useContext } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { GraphNotAvailableYet } from "src/modules/global/components/GraphNotAvailableYet";
import AuthContext from "src/stores/AuthContext";
import { highPerformanceDateFormatter } from "src/lib/highPerformanceDateFormatter";
import { SegmentCustomerEngageGraphHeader } from "./SegmentCustomerEngageGraphHeader";
import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";

export function SegmentCustomerEngageGraph({
  dateFrom,
  dateTo,
  segmentId,
}: {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}) {
  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "engage_engagement_per_tick",
    userToken,
    {
      segmentId,
      dateFrom,
      dateTo,
    },
  );

  if (error) {
    return <GraphLoadingError error={error} height={300} />;
  }

  if (loading) {
    return <Spinner height={300} />;
  }

  let insights = data;

  if (!insights.length) {
    return <GraphNotAvailableYet height={300} type="barGraph" />;
  }

  return (
    <>
      <div className={styles.statTile}>
        <SegmentCustomerEngageGraphHeader
          segmentId={segmentId}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
      <BarGraph
        width={500}
        height={300}
        stack="same"
        bar={{ borderRadius: 0, size: 68 }}
        statLabels={[
          {
            name: "No Engagement",
            dataKey: "noEngagement",
            color: "#00A0AC",
          },
          {
            name: "Barely Engaged",
            dataKey: "barelyEngaged",
            color: "#A4E8F2",
          },
          {
            name: "Very Engaged",
            dataKey: "veryEngaged",
            color: "#D5F3F8",
          },
        ]}
        statValues={insights}
        xAxis={{
          dataKey: "tickDate",
          label: "Timeline",
          tickFormatter: highPerformanceDateFormatter,
        }}
        yAxis={{
          scale: "linear",
          tickFormatter: formatNumber,
          label: "Number of Customers",
        }}
      />
    </>
  );
}
