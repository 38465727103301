import Modal from "src/components/Modal/Modal";
import styles from "./FacebookAdPreviewModal.module.scss";
import { useMetaAdPreviewLazyQuery } from "src/graphql/generated/schema";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { Spinner } from "src/components/Spinner/Spinner";
import { useCallback, useEffect, useMemo, useState } from "react";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { SomethingWentWrong } from "src/modules/global/components/SomethingWentWrong";
import { getMetaObjective } from "../functions/transformCampaignInput";

interface IFacebookAdPreviewModal {
  isOpen: boolean;
  onClose: () => void;
  adIndex: number;
  values: ActivityInputV2;
}

export function FacebookAdPreviewModal({
  isOpen,
  onClose,
  adIndex,
  values,
}: IFacebookAdPreviewModal) {
  const [adPreviews, setAdPreviews] = useState<
    { label: string; content: string }[]
  >([]);
  const adValue = values.Ads ? values.Ads[adIndex ?? 0] : null;

  const [metaAdPreviewFunc, { data, loading, error }] =
    useMetaAdPreviewLazyQuery();

  const updatedLineItems = useMemo(
    () =>
      adValue?.LineItems?.map(
        ({ PostAsset, order, headline, description, link, StoryAsset }) => ({
          order,
          headline,
          description,
          link,
          postAssetId: PostAsset?.id || "",
          storyAssetId: StoryAsset?.id || "",
        }),
      ),
    [adValue],
  );

  const updatePreview = useCallback(
    async function updatePreview() {
      const { data } = await metaAdPreviewFunc({
        variables: {
          input: {
            objective: getMetaObjective(values),
            conversionDomain: values.conversionDomain,
            metaAdAccountId: values.adAccountId,
            metaInstagramAccountId: values.metaInstagramPageId,
            metaPageId: values.metaPageId,
            metaPixelId: values.metaPixelId,
            metaPageConnectedInstagramBusinessAccountId:
              values.metaPageConnectedInstagramBusinessAccountId,
            ad: {
              primaryText: adValue?.primaryText,
              // name of ad in preview is irrelevant
              name: "ad name " + adIndex,
              LineItems: updatedLineItems,
              adType: adValue?.adType,
              CTAText: adValue?.callToActionLabel,
            },
          },
        },
      });

      const updatedAdPreviews =
        data && data.metaAdPreview
          ? data.metaAdPreview.map((v) => ({
              label: snakeCaseToTitleCase(v.adFormat),
              content: v.body,
            }))
          : [];

      setAdPreviews(updatedAdPreviews);
      return updatedAdPreviews;
    },
    [
      values,
      adIndex,
      adValue,
      setAdPreviews,
      metaAdPreviewFunc,
      updatedLineItems,
    ],
  );

  useEffect(() => {
    if (isOpen === true) {
      updatePreview();
    }
  }, [isOpen, updatePreview]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Full Preview"
      subtitle="Ad rendering and interaction may vary based on device, format and other factors"
      maxWidth="90%"
      noContainer
    >
      {error && (
        <div>
          <SomethingWentWrong />
        </div>
      )}

      {loading ? (
        <Spinner height="screen" />
      ) : (
        <div className={styles.content}>
          {adPreviews.map((item, idx) => (
            <div className={styles.container} key={idx}>
              <div className={styles.wrapper}>
                <iframe src={item.content} className={styles.iframe}></iframe>
              </div>
              <p className={styles.label}>{item.label}</p>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
}
