import { gql } from "@apollo/client";

const metaEnabledAdAccounts = gql`
  query metaEnabledAdAccounts {
    metaEnabledAdAccounts {
      id
      metaName
      metaAccountId
      enabled
    }
  }
`;

export default metaEnabledAdAccounts;
