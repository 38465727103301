import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import { Label } from "src/components/Label/Label";
import styles from "./SmsReview.module.scss";
import { MessageFormInput } from "../../CreateMessageTemplateTypes";

export function SmsReview({ values }: { values: MessageFormInput }) {
  return (
    <>
      <Label label="Template Name">
        <span className={styles.value}>Sale SMS Template </span>
      </Label>
      <div className={styles.rowTile}>
        <Label label="Category">
          <span className={styles.value}>{values.objective}</span>
        </Label>
        <Label label="Language">
          <span className={styles.value}>{values.language}</span>
        </Label>
      </div>
      <div className={styles.rowTile}>
        <Label label="Template type">
          <span className={styles.value}>{values.type}</span>
        </Label>
        {values.type === "DLT_BASED" && (
          <Label
            label={
              <span className={styles.label}>
                DLT <Info className={styles.ico} />
              </span>
            }
          >
            <span className={styles.value}>{values.dltId}</span>
          </Label>
        )}
      </div>
      <Label label="Body">
        <p>{values.content}</p>
      </Label>
    </>
  );
}
