import { Button, IButton } from "../Button/Button";
import { Menu, Transition } from "@headlessui/react";

import { Fragment } from "react";
import { IconButton } from "../IconButton/IconButton";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/outlined/more_vert.svg";
import classNames from "classnames";
import styles from "./ButtonOptions.module.scss";

interface Item extends IButton {}

export interface IButtonOptions extends IButton {
  items: Item[];
  menuClass?: string;
  menuBtnClass?: string;
  position?: "TOP_OF_BUTTON" | "BOTTOM_OF_BUTTON";
}

export default function ButtonOptions({
  position = "BOTTOM_OF_BUTTON",
  items,
  menuClass,
  menuBtnClass,
  ...rest
}: IButtonOptions) {
  return (
    <div>
      <Menu as="div" className={styles.menu}>
        <Transition
          as={Fragment}
          enter={styles.enter}
          enterFrom={styles.enterFrom}
          enterTo={styles.enterTo}
          leave={styles.leave}
          leaveFrom={styles.leaveFrom}
          leaveTo={styles.leaveTo}
        >
          <Menu.Items
            className={classNames(styles.menuItems, menuClass, {
              [styles.bottomMenuItem]: position === "BOTTOM_OF_BUTTON",
              [styles.topMenuItem]: position === "TOP_OF_BUTTON",
            })}
          >
            <div className={styles.menuSub}>
              {items.map((item, index) => (
                <Button
                  key={index}
                  onClick={item.onClick}
                  className={styles.menuItem}
                  {...item}
                  loading={rest.loading}
                  size="small"
                  style="plain"
                  color={item.color ? item.color : "subdued"}
                >
                  {item.children}
                </Button>
              ))}
            </div>
          </Menu.Items>
        </Transition>
        <div
          className={classNames(
            styles.btnGroup,
            styles[rest.color],
            styles[rest.style],
            styles[rest.size],
            styles[rest.width]
          )}
        >
          <Button
            {...rest}
            borderRadius="0.375rem 0 0 0.375rem"
            className={classNames(styles.mainBtn, rest.className)}
          />
          <Menu.Button className={classNames(styles[rest.color], menuBtnClass)}>
            <IconButton
              icon={<MoreVert width={18} height={18} />}
              shape="square"
              {...rest}
              // do not let rest override the onclick of menu button
              onClick={() => {}}
              className={styles.iconBtn}
            />
          </Menu.Button>
        </div>
      </Menu>
    </div>
  );
}
