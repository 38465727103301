import { ActivityPlatformEnum } from "src/graphql/generated/schema";
import { ReactComponent as MetaIcon } from "../../global/assets/meta.svg";

export function ActivityItemLogo({
  platform,
}: {
  platform: ActivityPlatformEnum;
}) {
  switch (platform) {
    case "META":
      return <MetaIcon width={24} height={24} />;
    default:
      return <div></div>;
  }
}
