import { Input } from "src/components/Input/Input";
import Modal from "src/components/Modal/Modal";
import styles from "./TemplateSingleSendModal.module.scss";
import { Button } from "src/components/Button/Button";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";

interface ITemplateTestingModal {
  isOpen: boolean;
  onClose: () => void;
}

export function TemplateSingleSendModal({
  isOpen,
  onClose,
}: ITemplateTestingModal) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="686px"
      title="Whom you want to send test?"
      noContainer
    >
      <div className={styles.modalContent}>
        <Input label="Send a test email to?" placeholder="johndoe@gmail.com" />
        <span className={styles.inputText}>
          Add multiple members by adding commas in between and hit enter.
          Maximum 5 Email allowed
        </span>
        <div className={styles.tagsContainer}></div>
        <div className={styles.infoTextWrapper}>
          <Info className={styles.infoIco} />
          <p className={styles.infoText}>
            Test helps you to get better idea about how your message will appear
            to you customers. Standard template messaging charges will apply for
            these test messages
          </p>
        </div>
        <Button>Send Test Email</Button>
      </div>
    </Modal>
  );
}
