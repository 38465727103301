export const videoMimeTypes = ["video/quicktime", "video/mov", "video/mp4"];

export const imageMimeTypes = [
  "image/gif",
  "image/webp",
  "image/jpeg",
  "image/jpg",
  "image/png",
];

export const imageAndVideoMimeType = [...videoMimeTypes, ...imageMimeTypes];
