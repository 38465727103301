import {
  AutomationStepTypeEnum,
  useAutomationQuery,
} from "src/graphql/generated/schema";
import CreateAutomationPage, {
  FlowItems,
} from "../builder/CreateAutomationPage/CreateAutomationPage";
import { ReactComponent as Bolt } from "@material-design-icons/svg/outlined/bolt.svg";
import { ReactComponent as EmailRead } from "@material-design-icons/svg/outlined/mark_email_unread.svg";
import EmptyState from "src/components/EmptyState/EmptyState";
import Header from "src/components/Header/Header";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/outlined/more_vert.svg";
import { ReactNode } from "react";
import { ReactComponent as Schedule } from "@material-design-icons/svg/filled/schedule.svg";
import formatDistance from "date-fns/formatDistance";
import { useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

type IAutomationChildren = {
  [x in AutomationStepTypeEnum]: ReactNode;
};

const AutomationStepIcons: IAutomationChildren = {
  CONDITIONAL: "",
  EMAIL: <EmailRead />,
  TEXT_MESSAGE: (
    <img
      src="/imagesAndIcons/SendSms.svg"
      alt="send_sms"
      width={24}
      height={24}
    />
  ),
  WHATSAPP: (
    <img
      src="/imagesAndIcons/SendWhatsapp.svg"
      alt="send_whatsapp"
      width={24}
      height={24}
    />
  ),
};

const AutomationItemChildren: IAutomationChildren = {
  CONDITIONAL: "if/else",
  EMAIL: "Email",
  TEXT_MESSAGE: "Text Message",
  WHATSAPP: "Whatsapp",
};

export default function AutomationSinglePage() {
  const { id } = useParams();
  const { data, loading, error } = useAutomationQuery({
    variables: {
      id,
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    // return (
    //   <div>
    //     <EmptyState title={"Item Not Found"} />
    //   </div>
    // );
    return <ErrorHandler error={error} />;
  }

  const flowListHeader: FlowItems = {
    variant: "attention",
    children: <div>{data.automation.name}</div>,
    icon: <Bolt />,
    name: data.automation.id,
    id: data.automation.id,
    onClick: () => {},
    action: {
      label: <MoreVert />,
      onClick: () => {},
    },
  };
  const flowListItems: FlowItems[] = data.automation.AutomationSteps.flatMap(
    (value) => [
      {
        action: {
          label: <MoreVert />,
          onClick: () => {},
        },
        onClick: () => {},
        icon: <Schedule />,
        children: <div>{formatDistance(0, value.delay * 1000)}</div>,
        variant: "info",
        name: value.id,
        id: value.id,
      },
      {
        action: {
          label: <MoreVert />,
          onClick: () => {},
        },
        onClick: () => {},
        icon: AutomationStepIcons[value.automationStepType],
        children: AutomationItemChildren[value.automationStepType],
        variant: "success",
        name: value.id,
        id: value.id,
      },
    ]
  );

  return (
    <>
      <Header mode="DISTRACTION_FREE" title={data.automation.name} />
      <CreateAutomationPage
        initialValues={{
          flowList: [
            flowListHeader,
            ...flowListItems,
            {
              variant: "exit",
            },
          ],
          section: "EMPTY",
        }}
      />
    </>
  );
}
