import { Spinner } from "src/components/Spinner/Spinner";
import {
  useMetaEnablePageMutation,
  useMetaPagesFromPlatformQuery,
  useMetaRemovePageMutation,
} from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import styles from "./MetaPagesList.module.scss";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";
import { useState } from "react";

export function MetaPagesList({ selectedAccountId }) {
  const [selectedPage, setSelectedPage] = useState<null | string>(null);
  const { data: metaPagesData, loading: metaPagesLoading } =
    useMetaPagesFromPlatformQuery({
      variables: {
        adAccountId: selectedAccountId,
      },
    });

  const [
    metaEnablePageFunc,
    { loading: metaEnableLoading, error: metaEnablePageError },
  ] = useMetaEnablePageMutation();
  const [metaRemovePageFunc, { loading: metaRemoveLoading }] =
    useMetaRemovePageMutation();

  if (metaPagesLoading) {
    return <Spinner height="screen" />;
  }

  const metaPages =
    metaPagesData && metaPagesData.metaPagesFromPlatform
      ? metaPagesData?.metaPagesFromPlatform
      : [];

  if (metaPages.length === 0) {
    return (
      <div className={styles.emptyStateContainer}>
        <MetaIntegrationDetailsEmptyState
          text={
            "No pages found on your account. Please ensure that you have enabled accounts in macro. Enabled accounts should have a page on facebook"
          }
          link={""}
        />
      </div>
    );
  }

  return (
    <div>
      {metaPages.map((page, index) => (
        <MetaDetailItem
          isDefault={page?.isDefault}
          error={selectedPage === page.id && metaEnablePageError}
          loading={
            selectedPage === page.id && (metaEnableLoading || metaRemoveLoading)
          }
          enableFunc={async () => {
            setSelectedPage(page.id);
            await metaEnablePageFunc({
              variables: {
                input: {
                  adAccountId: selectedAccountId,
                  metaPageId: page.id,
                },
              },
              refetchQueries: ["metaPagesFromPlatform", "metaPages"],
              awaitRefetchQueries: true,
            });
            setSelectedPage(null);
          }}
          removeFunc={async () => {
            setSelectedPage(page.id);
            await metaRemovePageFunc({
              variables: {
                input: {
                  adAccountId: selectedAccountId,
                  metaPageId: page.id,
                },
              },
              refetchQueries: ["metaPagesFromPlatform", "metaPages"],
              awaitRefetchQueries: true,
            });
            setSelectedPage(null);
          }}
          profilePicture={page?.Business?.profilePicture}
          title={page?.name}
          isEnabled={page?.enabled}
          key={index}
          description={`Page ID - ${page?.id}`}
        />
      ))}
    </div>
  );
}
