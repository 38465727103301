import { Activity } from "src/graphql/generated/schema";
import styles from "./CampaignActivityCardAnalytics.module.scss";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import speaker from "../assets/brandAwareness.svg";
import magnet2 from "../assets/engagement.svg";
import leads from "../assets/leadConversion.svg";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import { GrowPerTickResponse } from "../../global/misc/growPerTickResponse";
import { getMonthFromNumber } from "../misc/getMonthFromNumber";
import { CampaignActivityCardStatItem } from "./CampaignActivityCardStatItem";
import { GraphCard } from "./GraphCard";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

export function CampaignActivityCardAnalytics({
  activity,
  activityAggregateStats,
  activityPerTickStats,
}: {
  activity: Activity;
  activityAggregateStats: GrowAggregateType;
  activityPerTickStats: GrowPerTickResponse["data"];
}) {
  const selectedCurrencyCode = useGetActiveCurrencyCode();
  const stats = [
    {
      label: "Objective",
      value: snakeCaseToTitleCase(activity?.Campaign?.objective ?? ""),
      icon: leads,
    },
    {
      label: "Ad Type",
      value: snakeCaseToTitleCase(activity?.MetaActivity?.activityType ?? ""),
      icon: magnet2,
    },
    {
      label: "Ad Spend",
      value: formatNumberByType(activityAggregateStats?.spend, "CURRENCY", {
        showDecimal: true,
        selectedCurrencyCode,
      }),
      icon: leads,
    },
    {
      label: "Audience Count",
      value: activity?.MetaActivity?.MetaActivityAdsets?.length,
      icon: speaker,
    },
  ];

  const chartData = activityPerTickStats.map((v) => ({
    time: "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
    cpp: v.costPerPurchase,
    reach: v.reach,
    orderCount: v.countPurchase,
  }));

  return (
    <div className={styles.activityCardAnalyticsLayout}>
      <div className={styles.statList}>
        {stats.map((v) => (
          <CampaignActivityCardStatItem {...v} />
        ))}
      </div>
      <div className={styles.graphList}>
        <GraphCard
          subtitle="Revenue for selected time"
          title="Total Revenue"
          value={formatNumberByType(
            activityAggregateStats?.totalValuePurchase,
            "CURRENCY",
            {
              showDecimal: true,
              selectedCurrencyCode,
            },
          )}
          chartData={chartData}
          chartDataKey="cpp"
        />
        <GraphCard
          title="Reach"
          subtitle="Reach for selected time"
          value={formatNumberByType(activityAggregateStats?.reach, "NUMBER")}
          chartData={chartData}
          chartDataKey="reach"
        />
        <GraphCard
          title="Order Counts"
          subtitle="Order counts for selected time"
          value={formatNumberByType(
            activityAggregateStats?.countPurchase,
            "NUMBER",
          )}
          chartData={chartData}
          chartDataKey="orderCount"
        />
      </div>
    </div>
  );
}
