import Logo from "src/components/Logo/Logo";
import styles from "./AboutMacroSection.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

interface IAboutMacroSection {
  title: string;
  description: string;
  image: {
    alt: string;
    src: string;
    width: number;
    height: number;
  };
  onClick: () => void;
}

export function AboutMacroSection({
  title,
  description,
  image,
  onClick,
}: IAboutMacroSection) {
  return (
    <div className={styles.section}>
      <Logo width={32} height={32} style="primary" type="icon" />
      <h1 className={styles.sectionHeading}>{title}</h1>
      <p className={styles.sectionCopy}>{description}</p>
      <img
        className={styles.sectionImage}
        src={image.src}
        alt={image.alt}
        width={image.width}
        height={image.height}
      />
      <div className={styles.sectionButtonWrapper}>
        <ButtonV2 size="large" onClick={onClick} isFullWidth>
          Continue
        </ButtonV2>
      </div>
    </div>
  );
}
