import classNames from "classnames";
import styles from "./AudienceCategoryBadge.module.scss";
import { AUDIENCE_CATEGORY } from "../misc/audienceCategories";

interface IAudienceCategoryBadge {
  label: string;
  value: AUDIENCE_CATEGORY;
}

export function AudienceCategoryBadge({
  label,
  value,
}: IAudienceCategoryBadge) {
  return (
    <div className={classNames(styles.badge, styles[value.toLowerCase()])}>
      {label}
    </div>
  );
}
