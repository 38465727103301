import { useContext, useMemo, useState } from "react";
import {
  useAudiencesQuery,
  useCampaignsQuery,
  useCoreInsightsQuery,
} from "src/graphql/generated/schema";
import { PageLoader } from "src/modules/global/components/PageLoader";
import styles from "./Dashboard.module.scss";
import HappenedStats from "src/modules/global/components/HappenedStats";
import SpendBreakdownWidget from "src/modules/global/components/SpendBreakdownWidget";
import GrowthOverviewWidget from "src/modules/global/components/GrowthOverviewWidget";
import MonitorReturns from "../components/MonitorReturns/MonitorReturns";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import AuthContext from "src/stores/AuthContext";
import CodewordResourcesList from "../components/CodewordResourcesList/CodewordResourcesList";
import DashboardFooter from "../components/DashboardFooter/DashboardFooter";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import { getTinybirdDateFiltersFromDateFilter } from "src/modules/campaign/misc/campaignSingleFilters";
import { useGrowAggregatedStats } from "src/modules/global/functions/useGrowAggregatedStats";
import { getMonthFromNumber } from "src/modules/campaign/misc/getMonthFromNumber";
import Dropdown from "src/components/Dropdown/Dropdown";
import { dateFilterOptions } from "src/modules/global/misc/dateFilterOptions";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import RecentlyLaunchedCampaigns from "../components/RecentlyLaunchedCampaigns/RecentlyLaunchedCampaigns";
import classNames from "classnames";
import { useOverallPerTickStats } from "../functions/useOverallPerTickStats";
import { BestPerformingAudience } from "../components/BestPerformingAudience/BestPerformingAudience";
import { OnboardingDashboard } from "../components/OnboardingDashboard/OnboardingDashboard";
import { AdsetsAggregatedStats } from "src/modules/global/functions/useAdsetAggregatedStats";

/*
const dateFilters: Record<DateFilterKeys, any> = {
  "7_DAYS": {
    label: "7 Days",
    value: {
      gte: sub(new Date(), {
        days: 7,
      }),
      lte: new Date(),
    },
  },
  LAST_MONTH: {
    label: "Last Month",
    value: {
      gte: sub(new Date(), {
        months: 30,
      }),
      lte: new Date(),
    },
  },
  LAST_YEAR: {
    label: "Last Year",
    value: {
      gte: sub(new Date(), {
        years: 1,
      }),
      lte: new Date(),
    },
  },
};
*/

export function Dashboard() {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateFilter>("LAST_3_MONTHS");
  const { dateFrom, dateTo } = useMemo(
    () => getTinybirdDateFiltersFromDateFilter(selectedDate),
    [selectedDate]
  );
  const { data: growAggregatedStats, loading } = useGrowAggregatedStats({
    dateFrom: dateFrom,
    dateTo: dateTo,
    others: {},
  });
  const { data: coreInsightsData, loading: coreInsightsLoading } =
    useCoreInsightsQuery({
      variables: {
        filter: {
          gte: dateFrom,
          lte: dateTo,
        },
      },
    });

  const { data: adsetAggregatedStats, loading: adsetStatsLoading } =
    useGrowAggregatedStats<AdsetsAggregatedStats>({
      dateFrom: dateFrom,
      dateTo: dateTo,
      others: {
        insightType: "ADSET_INSIGHT",
      },
      groupBy: "adsetId",
    });

  // INFO: since we don't support hourly graph and graph for yesterday's date is
  // irrelevant without hours hence all the graphs for yesterday show stats
  // for last 7 days.
  const overallPerTickStatsArgs = { dateFrom, dateTo };

  const {
    data: perTickStats,
    loading: perTickStatsLoading,
    interval,
  } = useOverallPerTickStats(overallPerTickStatsArgs);

  const { user } = useContext(AuthContext);
  // const {
  //   data: _integrationsStatusData,
  //   loading: integrationsStatusLoading,
  //   error: _integrationsStatusError,
  // } = useIntegrationsStatusQuery();
  // const {
  //   data: _billingData,
  //   loading: getBillingLoading,
  //   error: _error,
  // } = useGetBillingQuery();

  const { data: _campaignsData, loading: campaignsLoading } = useCampaignsQuery(
    {
      variables: {
        sortBy: {
          direction: "DESC",
          field: "CREATED_AT",
        },
        filters: {
          limit: 5,
          filter: "ACTIVE",
        },
      },
    }
  );

  const { data: _audiencesData, loading: audiencesLoading } = useAudiencesQuery(
    {
      variables: {
        sortBy: {
          direction: "DESC",
          field: "CREATED_AT",
        },
        filter: {
          take: 5,
        },
      },
    }
  );

  if (
    loading ||
    coreInsightsLoading ||
    // integrationsStatusLoading ||
    // getBillingLoading ||
    campaignsLoading ||
    audiencesLoading ||
    perTickStatsLoading ||
    adsetStatsLoading
  ) {
    return <PageLoader />;
  }

  // const { platformCounts, integratedPlatformCount } = integrationsStatusError
  //   ? { platformCounts: 0, integratedPlatformCount: 0 }
  //   : getPlatformsFromIntegrationStatus(integrationsStatusData);

  // const userPlan = getUserPlan(billingData);

  const statValues = perTickStats
    ? perTickStats.map((v) => {
        return {
          time:
            interval === "DAY"
              ? (v as any).tickDate
              : `${getMonthFromNumber(v.tickMonth)}`,
          ...v,
        };
      })
    : [];

  if (!isOnboarded) {
    return (
      <OnboardingDashboard
        setIsOnboarded={setIsOnboarded}
        selectedDate={selectedDate}
      />
    );
  }

  return (
    <div>
      <PageHeader
        title={
          <div>
            Hey <span className={styles.gradientText}>{user?.firstName}</span>,
            see what's happening
          </div>
        }
        actions={
          <Dropdown
            className={styles.moreActionDropdown}
            items={dateFilterOptions.map((v) => ({
              children: v.label,
              style: "plain",
              onClick: () => setSelectedDate(v.value),
              color: v.value === selectedDate ? "primary" : "normal",
            }))}
            title={
              <span className={styles.dropdownText}>
                {dateFilterOptions.find((v) => v.value === selectedDate)?.label}
                <ArrowDown width={16} height={16} />
              </span>
            }
          />
        }
      />
      <div className={classNames(styles.flexList, styles.sectionBottomPadding)}>
        <div className={classNames(styles.flexList, styles.container)}>
          <GrowthOverviewWidget
            insights={growAggregatedStats}
            statValues={statValues}
          />
          <div className={styles.gridSection}>
            <MonitorReturns
              insights={growAggregatedStats}
              statValues={statValues}
            />
            <SpendBreakdownWidget insights={growAggregatedStats} />
          </div>

          <HappenedStats stats={coreInsightsData?.coreInsights} />
        </div>
        <RecentlyLaunchedCampaigns />
        <div className={styles.container}>
          <BestPerformingAudience
            adsetAggregatedStats={adsetAggregatedStats || []}
          />
        </div>
        <CodewordResourcesList />
      </div>
      <div>
        <DashboardFooter />
      </div>
    </div>
  );
}
