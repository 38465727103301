import Select from "src/components/Select/Select";
import styles from "./SegmentDeviceStats.module.scss";
import segmentDevice from "../assets/segmentDevice.svg";
import { useContext, useState } from "react";
import { PieChart } from "src/modules/global/components/PieChart";
import { GraphNotAvailableYet } from "src/modules/global/components/GraphNotAvailableYet";
import { Spinner } from "src/components/Spinner/Spinner";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import AuthContext from "src/stores/AuthContext";
import { titleCase } from "src/lib/titleCase";
import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";

const colors = ["blue", "green", "pink", "grey", "white"];

interface ISegmentDeviceStats {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}

export function SegmentDeviceStats({
  segmentId,
  dateFrom,
  dateTo,
}: ISegmentDeviceStats) {
  const [selectedAction, setSelectedAction] = useState("view_page");

  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "website_per_device",
    userToken,
    {
      action: selectedAction,
      segmentId,
      dateFrom,
      dateTo,
    },
  );

  if (error) {
    console.log({ error });
    return <GraphLoadingError error={error} height={300} />;
  }

  if (loading) {
    return <Spinner height={300} />;
  }

  let insights = data;

  const total = insights.reduce((acc, i) => acc + i.actionCount, 0);

  const deviceStats = insights.map((insight: { actionCount: number }) => ({
    ...insight,
    percent: Math.round((insight.actionCount / total) * 100),
  }));

  if (!insights.length) {
    return <GraphNotAvailableYet height={300} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h5 className={styles.title}>Device based</h5>
        <div className={styles.selectWrapper}>
          <Select
            options={[
              { label: "Views", value: "view_page" },
              { label: "Clicks", value: "click_links" },
            ]}
            value={selectedAction}
            onChange={setSelectedAction}
            btnClass={styles.selectBtn}
          />
        </div>
      </div>
      {/* Graph */}
      <PieChart
        cells={colors.map((a) => ({
          color: a,
        }))}
        height={320}
        width={320}
        statKey="actionCount"
        statValues={insights}
      />
      {/* Stat Items */}
      <div className={styles.statsContainer}>
        {deviceStats.map((item, index) => (
          <DeviceStatItem
            label={titleCase(item.device)}
            color={colors[index]}
            value={item.percent}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

function DeviceStatItem({ label, color, value }) {
  return (
    <div>
      <div className={styles.valueWrapper}>
        <img
          src={segmentDevice}
          alt={label}
          width={24}
          height={24}
          className={styles.deviceImg}
        />
        <span className={styles.value}>{value}%</span>
      </div>
      <div className={styles.labelWrapper}>
        <span
          style={{ backgroundColor: color }}
          className={styles.circle}
        ></span>
        <span className={styles.label}>{label}</span>
      </div>
    </div>
  );
}
