import Logo from "src/components/Logo/Logo";
import styles from "./OnboardingLayout2.module.scss";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";

export function OnboardingLayout2({
  sideImage,
  children,
}: {
  sideImage: string;
  children: React.ReactNode;
}) {
  const { user } = useContext(AuthContext);
  const email = user?.email;

  return (
    <section className={styles.wrapper}>
      <div className={styles.primarySection}>
        <div className={styles.logoContainer}>
          {email && (
            <>
              <span className={styles.logoWrapper}>
                <Logo width={16} style="primary" type="icon" />
                <span className={styles.accountSetupText}>Account Setup</span>
              </span>
              <span className={styles.loggedInWrapper}>
                <span className={styles.loggedInAsText}>Logged in as </span>
                <span className={styles.emailText}>{email}</span>
              </span>
            </>
          )}
          {!email && <Logo width={96} style="primary" type="full" />}
        </div>
        <div className={styles.cardContainer}>{children}</div>
      </div>

      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={sideImage}
          alt="illustration of ad"
        />
      </div>
    </section>
  );
}
