import { useState } from "react";
import Modal from "src/components/Modal/Modal";
import Table from "src/components/Table/Table";
import { MetaMedia, useMetaMediaQuery } from "src/graphql/generated/schema";
import styles from "./ExistingPostModal.module.scss";
import format from "date-fns/format";
import { titleCase } from "src/lib/titleCase";
import { Spinner } from "src/components/Spinner/Spinner";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Button } from "src/components/Button/Button";
import { ReactComponent as ExclamationIcon } from "@material-symbols/svg-400/rounded/exclamation.svg";
import { ReactComponent as LikeIcon } from "@material-design-icons/svg/outlined/favorite_border.svg";
import { ReactComponent as TooltipIcon } from "@material-symbols/svg-400/rounded/tooltip.svg";
import { ErrorHandler } from "src/components/ErrorHandler";
import classNames from "classnames";
import { Pagination } from "src/components/Pagination/Pagination";
import { metaMediaItemsPerPage } from "../misc/metaMediaItemsPerPage";

// const tabOptions = [
//   { label: "Instagram", value: "INSTAGRAM" },
//   { label: "Facebook", value: "FACEBOOK" },
// ];

interface IExistingPostModal {
  selectedPost: MetaMedia;
  setSelectedPost: (i: MetaMedia) => void;
  assetType: "IMAGE_AND_VIDEO" | "VIDEO" | "IMAGE";
  isOpen: boolean;
  onClose: () => void;
  facebookConfig: {
    adAccountId: string;
    metaPageConnectedInstagramBusinessAccountId: string;
  };
}

export function ExistingPostModal({
  setSelectedPost,
  selectedPost,
  assetType,
  isOpen,
  onClose,
  facebookConfig,
}: IExistingPostModal) {
  // const [selectedTab, setSelectedTab] =
  //   useState<MetaPlatformsEnum>("INSTAGRAM");
  const [pagination, setPagination] = useState({
    limit: metaMediaItemsPerPage,
    cursor: null,
  });
  const { data, loading, error } = useMetaMediaQuery({
    variables: {
      limit: pagination.limit,
      cursor: pagination.cursor,
      adAccountId: facebookConfig.adAccountId,
      type: "INSTAGRAM",
      // TODO: add facebook posts
      facebookPageId: "",
      instagramUserId:
        facebookConfig.metaPageConnectedInstagramBusinessAccountId,
    },
  });

  const posts = data?.metaMedia?.edges.map((media) => media.node);

  if (error) {
    return (
      <Modal
        maxWidth="1200px"
        title="Select Existing Post"
        subtitle="Select posts from Instagram to promote and run as ads"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ErrorHandler error={error} />
      </Modal>
    );
  }

  function handleSelect(item: MetaMedia) {
    if (item.type === "VIDEO" && assetType === "IMAGE") {
      return;
    }

    if (item.type === "IMAGE" && assetType === "VIDEO") {
      return;
    }

    if (
      item.copyrightStatus === "ERROR" ||
      item.copyrightStatus === "IN_PROGRESS" ||
      item.copyrightStatus === "NOT_STARTED"
    ) {
      return;
    }

    setSelectedPost(item);
  }

  let validTypes = ["IMAGE", "VIDEO", "CAROUSEL_ALBUM"];

  if (assetType === "IMAGE") {
    validTypes = ["IMAGE", "CAROUSEL_ALBUM"];
  }

  if (assetType === "VIDEO") {
    validTypes = ["VIDEO"];
  }

  return (
    <Modal
      maxWidth="1200px"
      title="Select Existing Post"
      subtitle="Select posts from Instagram to promote and run as ads"
      isOpen={isOpen}
      onClose={onClose}
      noContainer
    >
      <div className={styles.container}>
        {/* <div className={styles.tabContainer}>
          <Tabs
            onTabChange={setSelectedTab}
            selectedTab={selectedTab}
            tabs={tabOptions}
          />
        </div> */}
        <div className={styles.table}>
          {loading ? (
            <Spinner height={600} />
          ) : (
            <Table
              highlightedRowIndex={posts.findIndex(
                (p) => p.id === selectedPost?.id,
              )}
              headings={[
                "Instagram Posts",
                "Post ID",
                "Source",
                "Media",
                "Created On",
              ]}
              rows={posts.map((post) => [
                <div
                  onClick={() => {
                    handleSelect(post);
                  }}
                  className={classNames(styles.primaryColumn, {
                    [styles.selectedColumn]: selectedPost?.id === post.id,
                    [styles.disabledColumn]:
                      post?.copyrightStatus === "ERROR" ||
                      post?.copyrightStatus === "NOT_STARTED" ||
                      post?.copyrightStatus === "IN_PROGRESS" ||
                      // when the post is not valid media type
                      !validTypes.includes(post.type),
                  })}
                >
                  {post?.copyrightStatus === "ERROR" ||
                    post?.copyrightStatus === "NOT_STARTED" ||
                    post?.copyrightStatus === "IN_PROGRESS" ||
                    // when the post is not valid media type
                    (!validTypes.includes(post.type) && (
                      <ExclamationIcon
                        className={styles.badgeIcon}
                        title="This asset has copyright issues. Cannot run ads on restricted asset"
                      />
                    ))}
                  {post.type === "VIDEO" ? (
                    <video
                      autoPlay
                      muted={true}
                      loop={true}
                      className={styles.thumbnail}
                    >
                      <source src={post.url} />
                    </video>
                  ) : (
                    <img className={styles.thumbnail} src={post.url} />
                  )}
                  <span>
                    <p className={styles.caption}>{post.caption}</p>
                    <span className={styles.mediaEngagement}>
                      <span className={styles.likeCount}>
                        <LikeIcon width={13} height={14} />
                        {post.likeCount}
                      </span>
                      <span className={styles.commentCount}>
                        <TooltipIcon width={13} height={14} />
                        {post.commentsCount}
                      </span>
                    </span>
                  </span>
                </div>,
                <span
                  onClick={() => {
                    handleSelect(post);
                  }}
                  className={classNames(styles.column)}
                >
                  {post.id}
                </span>,
                <span
                  onClick={() => {
                    handleSelect(post);
                  }}
                  className={classNames(styles.column)}
                >
                  {titleCase(post.mediaProductType, "_")}
                </span>,
                <span
                  onClick={() => {
                    handleSelect(post);
                  }}
                  className={classNames(styles.column)}
                >
                  {titleCase(post.type, "_")}
                </span>,
                <span
                  onClick={() => {
                    handleSelect(post);
                  }}
                  className={classNames(styles.column)}
                >
                  {format(new Date(post.createdAt), "dd MMM, yyyy")}
                </span>,
              ])}
            />
          )}
        </div>
        {data && (
          <div className={styles.pagination}>
            <Pagination
              hasNext={data.metaMedia.pageInfo.hasNextPage}
              hasPrevious={data.metaMedia.pageInfo.hasPreviousPage}
              onNext={() => {
                setPagination({
                  // don't use the last element as cursor, facebook uses special cursor strings
                  // not your usual itemId
                  cursor: data.metaMedia.pageInfo.endCursor,
                  limit: metaMediaItemsPerPage,
                });
              }}
              onPrevious={() => {
                setPagination({
                  cursor: data.metaMedia.pageInfo.startCursor,
                  limit: -metaMediaItemsPerPage,
                });
              }}
              label=""
            />
          </div>
        )}
      </div>
      <StickyFooter
        label="You can select multiple posts by creating more ads inside this activity"
        actions={
          <>
            <Button onClick={onClose} color="subdued" style="outline">
              Back
            </Button>
            <Button onClick={onClose}>Save and Continue</Button>
          </>
        }
      />
    </Modal>
  );
}
