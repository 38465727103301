import toFixed from "./toFixed";

export function formatNumber(number: any, options?: { showDecimal: boolean }) {
  const { showDecimal } = options || { showDecimal: true };
  const numberArr = parseInt(number).toString();

  if (number.length >= 10) {
    return numberArr;
  }

  if (numberArr.length >= 9) {
    return `${numberArr[0]}${numberArr[1]}${numberArr[2]}M`;
  }

  if (numberArr.length >= 8) {
    return `${numberArr[0]}${numberArr[1]}M`;
  }

  if (numberArr.length >= 7) {
    if ((numberArr[1] !== "0" || numberArr[2] !== "0") && showDecimal) {
      return `${numberArr[0]}.${numberArr[1]}M`;
    }

    return `${numberArr[0]}M`;
  }

  if (numberArr.length >= 6) {
    if (showDecimal) {
      return `${numberArr[0]}${numberArr[1]}${numberArr[2]}.${numberArr[3]}${numberArr[4]}K`;
    }
    return `${numberArr[0]}${numberArr[1]}${numberArr[2]}K`;
  }

  if (numberArr.length >= 5) {
    if (showDecimal) {
      return `${numberArr[0]}${numberArr[1]}.${numberArr[2]}${numberArr[3]}K`;
    }
    return `${numberArr[0]}${numberArr[1]}K`;
  }

  if (numberArr.length >= 4) {
    if ((numberArr[1] !== "0" || numberArr[2] !== "0") && showDecimal) {
      return `${numberArr[0]}.${numberArr[1]}K`;
    }

    return `${numberArr[0]}K`;
  }

  if (number === "0") {
    return "-";
  }

  return showDecimal ? toFixed(Number(numberArr), 2) : numberArr;
}
