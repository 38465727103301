import styles from "./CustomersEmpty.module.scss";
import { Button } from "src/components/Button/Button";
import EmptyState from "src/components/EmptyState/EmptyState";
import { AddCustomerModal } from "./AddCustomerModal";
import emptyState from "../assets/emptyCustomers.png";
import { PageHeader } from "src/components/PageHeader/PageHeader";

interface ICustomersEmpty {
  setShowAddCustomerModal: (i: boolean) => void;
  showAddCustomerModal: boolean;
}

export function CustomersEmpty({
  showAddCustomerModal,
  setShowAddCustomerModal,
}: ICustomersEmpty) {
  return (
    <>
      <PageHeader title="All Customers" />
      <AddCustomerModal
        showAddCustomerModal={showAddCustomerModal}
        setShowAddCustomerModal={setShowAddCustomerModal}
      />
      <div className={styles.emptyPage}>
        <EmptyState
          title={"Ready to Grow? Add Your First Customer Here"}
          image={
            <img
              src={emptyState}
              alt="emptyCustomer"
              width={220}
              height={215}
            />
          }
          children={
            <>
              <p className={styles.text}>
                Create lasting connections and set the stage for success. Your
                growth story starts now
              </p>
              <Button
                style="outline"
                color="normal"
                onClick={() => setShowAddCustomerModal(true)}
              >
                Add Manual Customer
              </Button>
            </>
          }
        />
      </div>
    </>
  );
}
