import classNames from "classnames";
import { IconButton } from "../IconButton/IconButton";
import styles from "./Tag.module.scss";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";

export interface ITag {
  label: React.ReactNode;
  icon?: React.ReactNode;
  onRemove?: () => void;
  color?: "primary" | "danger" | "warn" | "success";
}

export default function Tag({ label, onRemove, icon, color }: ITag) {
  return (
    <span className={classNames(styles.badge, styles[color])}>
      {icon && icon}
      <span className={styles.label}>{label}</span>
      {onRemove && (
        <IconButton
          icon={<Close className={styles.closeIco} />}
          style="plain"
          className={classNames(styles.removeBtn)}
          shape="square"
          onClick={onRemove}
        />
      )}
    </span>
  );
}
