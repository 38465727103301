import Select from "src/components/Select/Select";
import styles from "./AdStaticTypeField.module.scss";
import { MetaAdTypeEnum } from "src/graphql/generated/schema";
import { getIn, useFormikContext } from "formik";
import { CampaignInput } from "../misc/createCampaignTypes";

export function AdStaticTypeField({ name }: { name: string }) {
  const { values, setFieldValue } = useFormikContext<CampaignInput>();
  const value = getIn(values, name);

  const adTypeTabs = [
    { label: "Create Ad", value: "CUSTOM_CREATIVE" as MetaAdTypeEnum },
    {
      label: "Existing Post",
      value: "EXISTING_POST" as MetaAdTypeEnum,
    },
  ];

  function handleAdTypeChange(value: MetaAdTypeEnum) {
    setFieldValue(name, value);
  }

  return (
    <div className={styles.staticAdType}>
      <Select
        label=""
        onChange={handleAdTypeChange}
        options={adTypeTabs}
        value={value}
      />
    </div>
  );
}
