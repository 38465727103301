import { ReactComponent as OpenInNew } from "@material-design-icons/svg/outlined/open_in_new.svg";
import { Button } from "src/components/Button/Button";
import styles from "./Dashboard.module.scss";
import socialLinks from "../../misc/socialLinks";

export default function DashboardFooter() {
  return (
    <div className={styles.container}>
      <div className={styles.primarySection}>
        <Button color="subdued" to="https://macrostack.co" style="plain">
          <div className={styles.buttonWithIcon}>
            <span>Learn more about Macro</span>{" "}
            <OpenInNew width={20} height={20} />
          </div>
        </Button>
        <div className={styles.separator}>
          {/* Separator Svg */}
          <svg
            width="2"
            height="20"
            viewBox="0 0 2 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path opacity="0.5" d="M1 0V20" stroke="#C9CCCF" />
          </svg>{" "}
        </div>

        <div className={styles.socialLinks}>
          {socialLinks.map((v) => (
            <IconLink {...v} />
          ))}
        </div>
      </div>
      <div className={styles.secondarySection}>Copyright @ Macrostack</div>
    </div>
  );
}

function IconLink({ to, icon }: { to: string; icon: React.ReactNode }) {
  return (
    <a href={to} className={styles.IconLink}>
      {icon}
    </a>
  );
}
