/** CSS files defined below can override
 * styles from the one above it
 */
import { init as initFullStory } from "@fullstory/browser";
import "keen-slider/keen-slider.min.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-loading-skeleton/dist/skeleton.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ApolloWrapper } from "./ApolloWrapper";
import { AuthDataLoader } from "./components/AuthDataLoader";
import { ScreenNotSupported } from "./modules/global/components/ScreenNotSupported";
import { Toaster } from "./modules/global/components/Toaster";
import { authTokenGet } from "./modules/global/functions/authToken";
import { tracingIdGet } from "./modules/global/functions/tracingId";
import { useDeviceType } from "./modules/global/hooks/useDeviceType";

import routes from "./routes";

const router = createBrowserRouter(routes);

if (process.env.REACT_APP_ENV === "production") {
  initFullStory({ orgId: "o-1ZQ8B4-na1" });
}

export default function App() {
  const userToken = authTokenGet();
  const tracingId = tracingIdGet();
  const deviceType = useDeviceType();

  if (deviceType !== "DESKTOP") {
    return <ScreenNotSupported />;
  }
  return (
    <ApolloWrapper tracingId={tracingId} userToken={userToken}>
      {/* Passing active client so as it stays updated in ApolloWrapper*/}
      <AuthDataLoader userToken={userToken}>
        <RouterProvider router={router} />
        <Toaster />
      </AuthDataLoader>
    </ApolloWrapper>
  );
}
