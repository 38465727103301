import styles from "./SelectOption.module.scss";
import { ReactNode } from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { ReactComponent as KeyboardArrowDown } from "@material-symbols/svg-600/rounded/keyboard_arrow_down.svg";

interface ISelectOption {
  label: string;
  subLabel?: string;
  subOptions?: React.ReactNode;
  icon: ReactNode;
  value: string;
}

export function SelectOption({
  label,
  subLabel,
  icon,
  value,
  subOptions,
}: ISelectOption) {
  return (
    <RadioGroup.Option value={value}>
      {({ checked }) => (
        <div
          className={classNames(styles.wrapper, {
            [styles.selectedWrapper]: checked,
          })}
        >
          <div className={styles.selectOption}>
            <div className={styles.selectorNameAndIcon}>
              <span>{icon}</span>
              <div className={styles.labelWrapper}>
                <span className={styles.label}>{label}</span>
                {subLabel && (
                  <span className={styles.subLabel}>{subLabel}</span>
                )}
              </div>
            </div>

            {subOptions && (
              <div>
                <KeyboardArrowDown
                  className={styles.expandIcon}
                  style={{ rotate: checked ? "180deg" : "0deg" }}
                  fill="currentColor"
                  width={24}
                  height={24}
                />
              </div>
            )}
          </div>
          {subOptions && checked && (
            <div className={styles.subOptions}>{subOptions}</div>
          )}
        </div>
      )}
    </RadioGroup.Option>
  );
}
