import { CustomersQuery } from "src/graphql/generated/schema";
import styles from "./CustomerListItem.module.scss";
import { Link } from "react-router-dom";
import CountryFlag from "src/components/CountryFlag/CountryFlag";
import { ReactComponent as PhoneIcon } from "@material-symbols/svg-600/rounded/call.svg";
import { ReactComponent as PaidIcon } from "@material-symbols/svg-600/rounded/paid.svg";
import { ReactComponent as WhatsappIcon } from "../assets/whatsappSubscribedIcon.svg";
import { ReactComponent as EmailIcon } from "../assets/emailSubscribedIcon.svg";
import { ReactComponent as TextMessageIcon } from "../assets/textSubscribedIcon.svg";
import classNames from "classnames";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

export function CustomerListItem({
  customer,
}: {
  customer: CustomersQuery["customers"]["edges"][0]["node"];
}) {
  const selectedCurrencySymbol = useGetActiveCurrencyCode();
  const initialCharacter = customer.name ? customer.name[0] : "&";
  const colorArray = [
    styles.greenAvatar,
    styles.redAvatar,
    styles.blueAvatar,
    styles.yellowAvatar,
  ];

  const colorIndex = initialCharacter.charCodeAt(0) % 4;
  const colorForAvatar = colorArray[colorIndex];

  return (
    <div className={styles.customerListItem}>
      <div className={styles.primaryRow}>
        <span className={classNames(styles.initialBasedAvatar, colorForAvatar)}>
          {customer.name[0]}
        </span>
        <Link className={styles.nameAndEmail} to={`/contacts/${customer.id}`}>
          <span className={styles.name}>{customer.name}</span>
          <span className={styles.email}>{customer.email}</span>
        </Link>
      </div>
      <div className={styles.flagContainer}>
        <CountryFlag
          countryCode={customer.Address?.countryCode}
          rounded={false}
          showCountryCode={false}
        />
        <span>{customer.Address?.city} </span>
      </div>
      <span className={styles.phone}>
        <PhoneIcon fill="var(--color-disabled)" width={16} height={16} />
        {customer.phone}
      </span>
      <span className={styles.ltv}>
        {customer.lifetimeValue
          ? `${selectedCurrencySymbol} ${customer.lifetimeValue}`
          : "-"}
        <PaidIcon fill="var(--color-disabled)" width={16} height={16} />
      </span>
      <span className={styles.subscribedTo}>
        {customer.WhatsappConsent.state === "SUBSCRIBED" && (
          <WhatsappIcon width={18} height={18} />
        )}
        {customer.EmailConsent.state === "SUBSCRIBED" && (
          <EmailIcon width={18} height={18} />
        )}
        {customer.TextMessageConsent.state === "SUBSCRIBED" && (
          <TextMessageIcon width={18} height={18} />
        )}
      </span>
    </div>
  );
}
