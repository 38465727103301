import { AdsetInput, CampaignInput } from "../misc/createCampaignTypes";
import { getIn, useFormikContext } from "formik";
import { ReactComponent as EditIcon } from "@material-design-icons/svg/outlined/edit.svg";
import styles from "./AudienceBudgetCard.module.scss";
import audienceIcon from "../assets/audience.svg";
import { AudienceBudgetInput } from "./AudienceBudgetInput";

interface Props {
  baseKey: string;
  index: number;
  handleAudiencePopupOpen?: (s: number) => void;
  hideEdit?: boolean;
}

export function AudienceBudgetCard({
  baseKey,
  index,
  handleAudiencePopupOpen,
  hideEdit = false,
}: Props) {
  const { values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<CampaignInput>();
  const fieldValueObj: AdsetInput = getIn(values, baseKey);
  const audienceName = fieldValueObj.name;
  // const isAudienceSet = fieldValueObj.AudienceSet;

  const fieldKey = `${baseKey}.budget`;
  const fieldError = getIn(errors, fieldKey);
  const fieldValue = getIn(values, fieldKey);
  const isFieldTouched = getIn(touched, fieldKey);

  return (
    <div className={styles.card}>
      <div className={styles.audienceInfo}>
        <img
          className={styles.icon}
          width={40}
          height={40}
          alt="audience icon"
          src={audienceIcon}
        />
        <div>
          <p className={styles.title}>{audienceName}</p>
          {/* {isAudienceSet && (
            <>
              <div className={styles.info}>
                <span className={styles.infoKey}>Age</span>{" "}
                {fieldValueObj.AudienceSet.minAge} -{" "}
                {fieldValueObj.AudienceSet.maxAge}
              </div>
              {Boolean(fieldValueObj.AudienceSet.countries.length) && (
                <div className={styles.info}>
                  <span className={styles.infoKey}>Countries</span>{" "}
                  {fieldValueObj.AudienceSet.countries.join(", ")}
                </div>
              )}
              {Boolean(fieldValueObj.AudienceSet.interests.length) && (
                <div className={styles.info}>
                  <span className={styles.infoKey}>Interest</span>{" "}
                  {fieldValueObj.AudienceSet.interests
                    .map((value) => value.name)
                    .join(", ")}
                </div>
              )}
            </>
          )} */}
        </div>
      </div>

      <div className={styles.action}>
        {!hideEdit && (
          <button
            onClick={() => {
              handleAudiencePopupOpen(index);
            }}
            type="button"
            className={styles.editBtn}
          >
            <EditIcon width={14} height={14} />
            Edit
          </button>
        )}
        <div>
          <AudienceBudgetInput
            error={isFieldTouched && fieldError}
            value={fieldValue}
            onBlur={() => setFieldTouched(fieldKey)}
            onChange={(value) => {
              setFieldValue(fieldKey, value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
