import { ReactNode } from "react";
import { IFormFieldStatus } from "../FormFieldStatus/FormFieldStatus";
import { InputV2 } from "../InputV2/InputV2";

export interface IInput {
  value?: string;
  onChange?: (i: string) => void;
  onBlur?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  type?: "text" | "password" | "email";
  label?: React.ReactNode;
  status?: IFormFieldStatus;
  width?: "full" | "auto";
  icon?: React.FC<any>;
  placeholder?: string;
  borderRadius?: string;
  name?: string;
  autoComplete?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  prefix?: string;
  showCharacterCount?: boolean;
  maxCharacterCount?: number;
  helpText?: string;
  // No longer needed we will default to one size until component library is made
  // size?: "small" | "default" | "micro";
}

/**
 *
 * @deprecated Use InputV2
 */
export function Input(props: IInput) {
  const {
    type,
    helpText,
    status,
    onKeyPress,
    label,
    placeholder,
    width = "auto",
    icon,
    name,
    disabled,
    autoFocus,
    borderRadius = "0.375rem",
    value,
    onChange,
    showCharacterCount,
    maxCharacterCount,
    prefix,
    autoComplete,
    onBlur,
  } = props;

  if (prefix && icon) {
    throw new Error(
      "You can't pass both icon and prefix in Input at the same time." +
        " prefix: " +
        prefix +
        " icon: " +
        icon,
    );
  }

  return (
    <InputV2
      label={label}
      showCharacterCount={showCharacterCount}
      maxCharacterCount={maxCharacterCount}
      type={type}
      helpText={helpText}
      placeholder={placeholder}
      icon={icon}
      name={name}
      disabled={disabled}
      autoFocus={autoFocus}
      value={value}
      onChange={onChange}
      prefix={prefix}
      isFullWidth={width === "full" ? true : false}
      error={status?.type === "error" ? status.message : ""}
      onBlur={onBlur}
    />
  );
}
