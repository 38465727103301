import styles from "./NotificationSettingItem.module.scss";
import { ReactComponent as ArrowRight } from "@material-design-icons/svg/outlined/keyboard_arrow_right.svg";

export default function NotificationSettingItem({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className={styles.item}>
      <div>
        <span className={styles.title}>{title}</span>
        <p className={styles.description}>{description}</p>
      </div>
      <ArrowRight className={styles.ico} />
    </div>
  );
}
