import { useFormikContext } from "formik";
import { Button } from "../../../../components/Button/Button";
import { ReactComponent as FacebookIcon } from "../../../global/assets/meta.svg";
import { LineHeading } from "../LineHeading";
import { ReactComponent as AdsClick } from "@material-design-icons/svg/outlined/ads_click.svg";
import { ReactComponent as Handyman } from "@material-design-icons/svg/outlined/handyman.svg";
import Select from "src/components/Select/Select";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Asset } from "src/graphql/generated/schema";
import styles from "./AdTemplateCreateForm.module.scss";
import classNames from "classnames";
import { AdTemplateSingleForm } from "./AdTemplateSingleForm";
import { AdTemplateCarouselForm } from "./AdTemplateCarouselForm";
import { ReactComponent as Article } from "@material-symbols/svg-400/rounded/article.svg";
import { AdTemplateExistingPostForm } from "./AdTemplateExisitingPostForm";
import { AdTemplateUseDefaultSettingsCheckBox } from "./AdTemplatUseDefaultSettingsCheckBox";
import { AdTemplateCatalogueAdForm } from "./AdTemplateCatalogueAdForm";
import { FormValues } from "../../misc/adTemplateCreateTypes";
import { MetaPageWelcomeMessageSelector } from "../MetaPageWelcomeMessageSelector";
import { AdTemplateCatalogueSelector } from "./AdTemplateCatalogueSelector";
import { CloseIcon } from "src/components/AppLayout/CloseIcon";
import { Link } from "react-router-dom";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const adTypeOptions: Array<{
  label: string;
  value: FormValues["type"];
}> = [
  { label: "Single Image or Video", value: "SINGLE_IMAGE_OR_VIDEO" },
  { label: "Carousel", value: "CAROUSEL" },
  { label: "Existing Post Ads", value: "EXISTING_POST" },
  { label: "Dynamic & Catalogue Ads", value: "CATALOGUE_AD" },
];

export function AdTemplateCreateForm() {
  const {
    values,
    isSubmitting,
    setFieldValue,
    setValues,
    setFieldTouched,
    handleSubmit,
  } = useFormikContext<FormValues>();

  function handleSelect(assets: Asset[], cardIndex: number) {
    setFieldValue(`LineItems[${cardIndex}].PostAsset`, assets[0]);
  }

  const formContent: Record<FormValues["type"], any> = {
    SINGLE_IMAGE_OR_VIDEO: (
      <AdTemplateSingleForm
        handleSelect={handleSelect}
        lineItems={values.LineItems}
      />
    ),
    CAROUSEL: (
      <AdTemplateCarouselForm
        handleSelect={handleSelect}
        setFieldValue={setFieldValue}
        lineItems={values.LineItems}
      />
    ),
    EXISTING_POST: (
      <>
        <AdTemplateExistingPostForm />
      </>
    ),
    CATALOGUE_AD: (
      <>
        <AdTemplateCatalogueAdForm />
      </>
    ),
  };

  return (
    <div className={styles.uploadAreaSection}>
      <div className={styles.formTitleSection}>
        <div className={styles.formTitle}>
          <FacebookIcon width={27} height={27} />
          <h4>Create New Ad</h4>
        </div>
        <Link to={"/library/ad-library"} className={styles.formCloseIcon}>
          <CloseIcon />
        </Link>
      </div>
      <div className={styles.formInner}>
        <div className={styles.nameSection}>
          <LineHeading
            className={styles.lineHeading}
            icon={
              <AdsClick width={15} height={15} fill="var(color-disabled)" />
            }
            content="Ad Setup"
          />
          <div>
            <div className={styles.nameSectionLayout}>
              <InputFormik
                autoFocus={true}
                name="title"
                label="Ad Name"
                width={"full"}
              />
              <SelectV2
                options={adTypeOptions}
                name="type"
                value={values.type}
                isFullWidth
                label="Ad Type"
                onChange={(value: FormValues["type"]) => {
                  setValues({
                    primaryText: "",
                    title: values.title,
                    type: value,
                    LineItems: [
                      {
                        description: "",
                        link: "",
                        order: 1,
                        headline: "",
                      },
                    ],
                  });
                }}
              />
            </div>

            {(values.type === "CATALOGUE_AD" ||
              values.type === "EXISTING_POST") && (
              <AdTemplateUseDefaultSettingsCheckBox />
            )}
          </div>
        </div>

        {values.type === "CATALOGUE_AD" && (
          <div className={styles.adSingleSection}>
            <LineHeading
              className={styles.lineHeading}
              icon={
                <Article width={15} height={15} fill="var(color-disabled)" />
              }
              content="Select Catalogs"
            />

            <AdTemplateCatalogueSelector />
          </div>
        )}

        <div className={styles.adSingleSection}>
          <LineHeading
            className={styles.lineHeading}
            icon={<Article width={15} height={15} fill="var(color-disabled)" />}
            content="Content"
          />

          {formContent[values.type]}
        </div>

        <div className={styles.additionalOptionsSection}>
          <LineHeading
            className={styles.lineHeading}
            content="Additional Options"
            icon={
              <Handyman width={15} height={15} fill="var(color-disabled)" />
            }
          />

          <div className={styles.additonalOptionsFormContainer}>
            {/*
          <div className={styles.supportingHeader}>
            <div className={styles.supportingTextWrapper}>
              <span className={styles.additionalOptionsIcon}>
                <ArrowSplit
                  width={16}
                  height={16}
                  fill="var(--color-warning-text)"
                />
              </span>
              <div>
                <span className={styles.additionalOptionsTitle}>
                  Support Multi-Purpose ads
                  <Tooltip supportingText="Yolo">
                    <HelpIcon width={16} height={16} />
                  </Tooltip>
                </span>
                <span className={styles.additionalOptionsSubTitle}>
                  Use same ad for multiple Ad types
                </span>
              </div>
            </div>

              <ToggleSwitch
                checked={values.isMultipurposeAd}
                onChange={() => {
                  setFieldValue("isMultipurposeAd", !values.isMultipurposeAd);
                }}
                title=""
              />
          </div>
          */}

            <div className={styles.additionalFieldsWrapper}>
              <div className={styles.additionalFieldsFlexRow}>
                <PhoneNumberInput
                  isFullWidth
                  countryCodeOnChange={() => {}}
                  countryCodeValue="91"
                  defaultPhoneCode="91"
                  label="Ad Phone Number"
                  name="adPhoneNumber"
                  placeholder="XXXXXXXX"
                  onChange={(value) => {
                    setFieldValue("adPhoneNumber", value.trim());
                  }}
                  onBlur={() => {
                    setFieldTouched("adPhoneNumber", true);
                  }}
                  value={values.adPhoneNumber}
                />
              </div>
              <MetaPageWelcomeMessageSelector
                selectedValue={values.pageWelcomeId}
                setSelectedValue={(i: string) => {
                  setFieldValue("pageWelcomeId", i);
                }}
              />
            </div>

            <div className={styles.callToAction}>
              <SelectV2
                isFullWidth
                label={<span>Call to action</span>}
                disabled={true}
                onChange={() => {}}
                value={""}
                options={[
                  { label: "To be selected on campaign flow", value: "" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.buttonList, styles.footer)}>
        <ButtonV2
          to="/library/ad-library"
          variant="outline"
          disabled={isSubmitting}
        >
          Cancel
        </ButtonV2>
        <ButtonV2
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Save Ad
        </ButtonV2>
      </div>
    </div>
  );
}
