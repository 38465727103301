import CollectionItem from "src/components/CollectionItem/CollectionItem";
import { Label } from "src/components/Label/Label";
import metaImage from "../assets/meta-outlined.svg";
import emailImage from "../assets/email-outlined.svg";
import textMessageImage from "../assets/sms-outlined.svg";
import snapchatImage from "../assets/snapchat-outlined.svg";
import pushNotificationImage from "../assets/notification-box-outlined.svg";
import googleAdsImage from "../assets/googleads-outlined.svg";
import tiktokImage from "../assets/tiktok-outlined.svg";
import whatsappImage from "../assets/whatsapp-outlined.svg";
import { RadioGroup } from "@headlessui/react";
import styles from "./ActivityGetStarted.module.scss";
import { Button } from "src/components/Button/Button";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { useContext } from "react";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import { Formik, Form } from "formik";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import * as Yup from "yup";
import { FormikError } from "src/components/FormikError/FormikError";
import { IBadgeV2 } from "../../global/components/BadgeV2";

const getStartedSchema = Yup.object({
  activityName: Yup.string().required("Name is required"),
  platform: Yup.string()
    .oneOf(["META", "WHATSAPP"])
    .required("Platform is required"),
});

const platformOptions = [
  {
    value: "META",
    image: metaImage,
    title: "Meta",
    subtitle: "Targeting based on behaviour",
  },
  {
    value: "WHATSAPP",
    image: whatsappImage,
    title: "Whatsapp",
    subtitle: "Multimedia content supported",
    disabled: false,
  },
  {
    value: "EMAIL",
    image: emailImage,
    title: "Emails",
    subtitle: "Direct engage with your customers",
    disabled: true,
  },
  {
    value: "TEXT_MESSAGE",
    image: textMessageImage,
    title: "Text Message",
    subtitle: "Concise messages with clear action",
    disabled: true,
  },
  {
    value: "PUSH_NOTIFICATION",
    image: pushNotificationImage,
    title: "Push Notification",
    subtitle: "Instantly alert users with timely updates",
    disabled: true,
  },
  {
    value: "GOOGLE_ADS",
    image: googleAdsImage,
    title: "Google Ads",
    subtitle: "Targeted ads to reach your ideal audience",
    disabled: true,
  },
  {
    value: "TIKTOK_ADS",
    image: tiktokImage,
    title: "Tiktok Ads",
    subtitle: "Creative ads that captivate and engage users",
    disabled: true,
  },
  {
    value: "SNAPCHAT_ADS",
    image: snapchatImage,
    title: "Snapchat Ads",
    subtitle: "Interactive Ads that boost brand visibility",
    disabled: true,
  },
];

export function ActivityGetStarted({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } =
    useContext(StepWizardContext);

  function renderBadge(option: (typeof platformOptions)[0]): IBadgeV2 | null {
    if (option.value === "WHATSAPP") {
      return {
        label: "Beta",
        color: "purple",
      };
    }

    if (option.disabled) {
      return {
        label: "Coming soon",
      };
    }
    return null;
  }

  return (
    <Formik
      validationSchema={getStartedSchema}
      initialValues={data}
      enableReinitialize
      onSubmit={(values) => {
        console.log({ values });
        markStepAsDone(1, values);
      }}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        errors,
        submitCount,
        touched,
      }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 0}
            isCompleted={activeStepIndex > 0}
          >
            <div ref={sectionRef} className={styles.section}>
              <h2 className={styles.heading}>Get Started</h2>
              <h3 className={styles.subheading}>
                Add activity and select platform to get started
              </h3>

              <InputFormik
                width="auto"
                name="activityName"
                label="Activity Name"
              />

              <div className={styles.platformInput}>
                <RadioGroup
                  value={values.platform}
                  onChange={(value) => {
                    setFieldValue("platform", value);
                  }}
                >
                  <Label label="Select Platform where you want to run this marketing activity" />
                  <div className={styles.platforms}>
                    {platformOptions.map((option) => (
                      <div className={styles.platformItem}>
                        <CollectionItem
                          value={option.value}
                          image={option.image}
                          imageWidth={24}
                          imageHeight={24}
                          title={option.title}
                          badge={renderBadge(option)}
                          subtitle={option.subtitle}
                          disabled={option.disabled}
                        />
                      </div>
                    ))}
                  </div>
                  <FormikError
                    fieldName="platform"
                    formikError={errors}
                    submitCount={submitCount}
                    touched={touched}
                  />
                </RadioGroup>
              </div>

              <Button onClick={handleSubmit} size="micro">
                Save & Continue
              </Button>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
