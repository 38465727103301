import { Asset } from "src/components/Smartphone/Asset";
import { AdInput } from "../misc/createCampaignTypes";
import styles from "./ReviewActivityAdItem.module.scss";
import { ReactComponent as OpenInNew } from "@material-design-icons/svg/outlined/open_in_new.svg";
import { ReactComponent as EditDocument } from "@material-symbols/svg-400/rounded/edit_document.svg";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";

export function ReviewActivityAdItem({
  ad,
  idx,
  setShowAdPreviewModal,
  handleEditAd,
}: {
  ad: AdInput;
  idx: number;
  setShowAdPreviewModal: (v: any) => void;
  handleEditAd: (idx: number) => void;
}) {
  return (
    <div className={styles.adItem}>
      <div className={styles.thumbnailWrapper}>
        <button
          onClick={() => setShowAdPreviewModal(true)}
          type="button"
          className={styles.openPreview}
        >
          <OpenInNew width={24} height={24} />
        </button>
        {thumbnailAssetContent(ad)}
      </div>
      <div className={styles.previewInfo}>
        {thumbnailPreviewContent(ad)}
        <button
          type="button"
          onClick={() => handleEditAd(idx)}
          className={styles.editBtn}
        >
          <span>Edit</span>
          <EditDocument className={styles.editArrow} />
        </button>
      </div>
    </div>
  );
}
function ExistingPostAdThumbnailAsset({ ad }: { ad: AdInput }) {
  return (
    <Asset
      type={ad?.existingPost?.type}
      preview={ad?.existingPost?.url}
      name={ad?.existingPost?.caption}
      className={styles.thumbnailAsset}
      loop={true}
    />
  );
}
function CatalogAdThumbnailAsset({ ad }: { ad: AdInput }) {
  return (
    <Asset
      type={"IMAGE"}
      preview={""}
      name={ad?.primaryText}
      className={styles.thumbnailAsset}
      loop={false}
    />
  );
}
function StaticAdThumbnailAsset({ ad }) {
  return (
    <Asset
      type={
        (ad.LineItems[0]?.StoryAsset
          ? ad.LineItems[0]?.StoryAsset.contentType
          : ad.LineItems[0]?.PostAsset?.contentType) || "IMAGE"
      }
      preview={
        ad.LineItems[0]?.StoryAsset
          ? ad.LineItems[0]?.StoryAsset?.uri
          : ad.LineItems[0]?.PostAsset?.uri
      }
      name={
        ad.LineItems[0]?.StoryAsset
          ? ad.LineItems[0]?.StoryAsset?.name
          : ad.LineItems[0]?.PostAsset?.name
      }
      className={styles.thumbnailAsset}
      loop={true}
    />
  );
}

export function thumbnailAssetContent(ad: AdInput) {
  switch (ad.staticAdType) {
    case "CUSTOM_CREATIVE":
      return <StaticAdThumbnailAsset ad={ad} />;
    case "CATALOG":
      return <CatalogAdThumbnailAsset ad={ad} />;
    case "EXISTING_POST":
      return <ExistingPostAdThumbnailAsset ad={ad} />;
    default:
      return <></>;
  }
}
export function thumbnailPreviewContent(ad: AdInput) {
  switch (ad.staticAdType) {
    case "CUSTOM_CREATIVE":
      return <StaticAdPreviewInfo ad={ad} />;
    case "CATALOG":
      return <CatalogAdPreviewInfo ad={ad} />;
    case "EXISTING_POST":
      return <ExistingPostPreviewInfo ad={ad} />;
    default:
      return <></>;
  }
}
function StaticAdPreviewInfo({ ad }) {
  return (
    <div>
      <p className={styles.adTitle}>{ad.LineItems[0]?.headline}</p>
      <p className={styles.adTypeValue}>
        Format: {snakeCaseToTitleCase(ad.manualAdType)}
      </p>
    </div>
  );
}
function CatalogAdPreviewInfo({ ad }: { ad: AdInput }) {
  return (
    <div>
      <p className={styles.adTitle}>
        {/* {ad?.primaryText || "Catalog Ad"} */}
        Catalog Ad
      </p>
      <p className={styles.adTypeValue}>
        Format: {snakeCaseToTitleCase(ad.staticAdType)}
      </p>
    </div>
  );
}
function ExistingPostPreviewInfo({ ad }: { ad: AdInput }) {
  return (
    <div>
      <p className={styles.adTitle}>
        {/* {ad?.primaryText || "Catalog Ad"} */}
        {ad?.existingPost?.caption}
      </p>
      <p className={styles.adTypeValue}>
        Format: {snakeCaseToTitleCase(ad.staticAdType)}
      </p>
    </div>
  );
}
