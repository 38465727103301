import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "src/components/Button/Button";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { useSegmentsLazyQuery } from "src/graphql/generated/schema";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import { getDateFilterFromFilterEnum } from "src/modules/global/misc/dateFilterUtils";
import { SegmentSelectorItem } from "./SegmentSelectorItem";
import styles from "./SegmentSelectorLoader.module.scss";
import UserAddIcon from "../assets/userAddIcon.svg";
import createAudienceImage from "../assets/createAudiences.svg";
import noResultAudiences from "../assets/noResultAudiences.svg";

export function SegmentSelectorLoader({ selectedFilters, selectedSortBy }) {
  const debouncedSearchQuery = useDebouncedVariable(
    selectedFilters.searchQuery,
  );
  const [segmentsFn, { called, data, loading, error, fetchMore }] =
    useSegmentsLazyQuery();
  const areFiltersApplied =
    debouncedSearchQuery !== "" ||
    selectedFilters.audienceCategoryId !== null ||
    selectedFilters.date !== "ALL_TIME";

  useEffect(() => {
    segmentsFn({
      variables: {
        sortBy: {
          direction: selectedSortBy.direction,
          field: selectedSortBy.field,
        },
        filter: {
          name: debouncedSearchQuery,
          take: selectedFilters.take,
          // FIXME: date filter
          // createdAt: getDateFilterFromFilterEnum(selectedFilters.date),
        },
      },
    });
  }, [
    segmentsFn,
    selectedSortBy.direction,
    selectedSortBy.field,
    debouncedSearchQuery,
    selectedFilters.date,
    selectedFilters.take,
  ]);

  if (loading || !called) {
    return <Spinner height={300} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  async function fetchMoreSegments() {
    await fetchMore({
      variables: {
        filter: {
          cursor: data.segments.pageInfo.endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.segments?.edges ?? [];
        const oldEdges = previousResult?.segments?.edges ?? [];

        return {
          segments: {
            ...fetchMoreResult.segments,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  // this should be above the generic handler of no result
  // because that one will match both of these cases
  if (!data.segments.edges.length && areFiltersApplied) {
    return (
      <div className={styles.noResultScreen}>
        <img
          src={noResultAudiences}
          width={48}
          height={48}
          alt="no result audience"
        />
        <span className={styles.noResultText}>
          No search results found. Try changing the filters or use a different
          keyword.
        </span>
      </div>
    );
  }

  if (!data.segments.edges.length) {
    return (
      <div className={styles.emptyScreen}>
        <img
          src={createAudienceImage}
          width={48}
          height={48}
          alt="create audience"
        />
        <span className={styles.emptyScreenText}>
          Oops! It looks like you don't have an segment for your campaign
          launch.
        </span>
        <div className={styles.buttonWrapper}>
          <Button size="micro" to="/segments/new">
            <div className={styles.createSegmentBtnContent}>
              <img
                src={UserAddIcon}
                width={16}
                height={16}
                role="none"
                alt="user add icon"
              />
              Create Segments
            </div>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={data.segments.edges.length}
      scrollableTarget="scrollTargetForInfiniteScroll"
      next={fetchMoreSegments}
      hasMore={data.segments.pageInfo.hasNextPage}
      loader={<Spinner height={200} />}
    >
      {data.segments.edges.map((segment) => (
        <SegmentSelectorItem segment={segment} />
      ))}
    </InfiniteScroll>
  );
}
