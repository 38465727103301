import CreateFlowCard from "./CreateFlowCard";
import { Spinner } from "src/components/Spinner/Spinner";
import format from "date-fns/format";
import styles from "./Review.module.scss";
import { useCsvAssetQuery } from "src/graphql/generated/schema";
import Select from "src/components/Select/Select";
import { useState } from "react";
import importCustomersFinal from "../assets/importCustomersFinal.png";
import { ErrorHandler } from "src/components/ErrorHandler";

const customerStatusArr = [
  {
    label: "Cleaned",
    value: "Cleaned",
  },
  {
    label: "Subscribed",
    value: "Subscribed",
  },
  {
    label: "Non Subscribed",
    value: "Non Subscribed",
  },
  {
    label: "Unsubscribed",
    value: "Unsubscribed",
  },
];

interface IReview {
  back: () => void;
  handleSubmit: () => void;
  loading: boolean;
  columnMapping: Record<string, any>;
  assetId: string;
  selectedTags: any[];
}

function Review({
  back,
  handleSubmit,
  loading,
  columnMapping,
  assetId,
  selectedTags,
}: IReview) {
  const [customerStatus, setCustomerStatus] = useState("Cleaned");
  const {
    data,
    error,
    loading: loadingAsset,
  } = useCsvAssetQuery({
    variables: {
      id: assetId,
    },
  });

  if (loadingAsset) {
    return (
      <div>
        <Spinner height="screen" />
      </div>
    );
  }

  const reviewPageInfo = [
    {
      title: "Tag Added",
      value: selectedTags.map((v) => v.label).join(", "),
    },
    {
      title: "No. of column skipped",
      value: `${
        data?.csvAsset?.columns?.length - Object.values(columnMapping).length
      } column has been skipped from CSV File`,
    },
    {
      title: "No. of column imported",
      value: `${
        Object.values(columnMapping).length
      } column has been imported from CSV File`,
    },
    {
      title: "Date and Time",
      value: format(new Date(), "PP p"),
    },
    {
      title: "Customer status",
      value: (
        <Select
          options={customerStatusArr}
          value={customerStatus}
          onChange={setCustomerStatus}
        />
      ),
    },
  ];

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div>
      <CreateFlowCard
        title={`${data?.csvAsset?.totalRows} contacts will be added to Macro`}
        footerActions={[
          {
            children: "Back",
            color: "subdued",
            style: "outline",
            onClick: back,
            loading: loading,
            disabled: loading,
          },
          {
            children: "Finish Imports",
            color: "primary",
            style: "solid",
            loading: loading,
            disabled: loading,
            onClick: handleSubmit,
          },
        ]}
      >
        <div className={styles.reviewContent}>
          <div className={styles.reviewList}>
            {reviewPageInfo.map((v) => (
              <div className={styles.listItem}>
                <div className={styles.listHeading}>{v.title}</div>
                <div className={styles.listChildren}>{v.value}</div>
              </div>
            ))}
          </div>
          <img
            src={importCustomersFinal}
            alt="customer import final"
            width={290}
            height={290}
          />
        </div>
      </CreateFlowCard>
    </div>
  );
}

export default Review;
