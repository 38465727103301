import { uuidv4 } from "../../../lib/uuidv4";
import { AdInput, CampaignInput } from "./createCampaignTypes";

export const newCustomCreativeAdTemplate: AdInput = {
  staticAdType: "CUSTOM_CREATIVE",
  manualAdType: "STATIC_IMAGE_OR_VIDEO",
  callToActionLabel: "SHOP_NOW",
  existingPostCTALink: "",
  existingPostId: "",
  primaryText: "",
  LineItems: [
    {
      id: uuidv4(),
      description: "",
      headline: "",
      link: "",
      PostAsset: null,
      StoryAsset: null,
      order: 0,
    },
  ],
};

export const newCatalogAdTemplate: AdInput = {
  staticAdType: "CATALOG",
  manualAdType: "STATIC_IMAGE_OR_VIDEO",
  callToActionLabel: "SHOP_NOW",
  existingPostCTALink: "",
  existingPostId: "",
  primaryText: "",
  LineItems: [
    {
      id: uuidv4(),
      description: "",
      headline: "",
      link: "",
      PostAsset: null,
      StoryAsset: null,
      order: 0,
    },
  ],
};

export const engagementMessageAdTemplate: AdInput = {
  staticAdType: "CUSTOM_CREATIVE",
  manualAdType: "STATIC_IMAGE_OR_VIDEO",
  // to remove any call to action by default
  callToActionLabel: "" as any,
  existingPostCTALink: "",
  existingPostId: "",
  primaryText: "",
  PageWelcomeMessage: {
    greeting: "Hi! Please let us know how we can help you.",
    selectedWhatsappCustomerAction: "MESSAGE_OPTIONS",
    MessageOptions: [
      {
        text: "Can I make a purchase?",
        id: "033",
        automatedReply: "",
      },
      {
        text: "I'm interested. Can you tell me more?",
        id: "133",
        automatedReply: "",
      },
      {
        text: "Can I learn more about your business?",
        id: "233",
        automatedReply: "",
      },
    ],
    prefilledText: null,
  },
  LineItems: [
    {
      id: uuidv4(),
      description: "",
      headline: "",
      link: "",
      PostAsset: null,
      StoryAsset: null,
      order: 0,
    },
  ],
};

export const engagementAdTemplate: AdInput = {
  staticAdType: "CUSTOM_CREATIVE",
  manualAdType: "STATIC_IMAGE_OR_VIDEO",
  callToActionLabel: "" as any,
  existingPostCTALink: "",
  existingPostId: "",
  primaryText: "",
  LineItems: [
    {
      id: uuidv4(),
      description: "",
      headline: "",
      link: "",
      PostAsset: null,
      StoryAsset: null,
      order: 0,
    },
  ],
};

export const promotePostAdTemplate: AdInput = {
  staticAdType: "EXISTING_POST",
  manualAdType: "STATIC_IMAGE_OR_VIDEO",
  callToActionLabel: "" as any,
  existingPostCTALink: "",
  existingPostId: "",
  primaryText: "",
  LineItems: [
    {
      id: uuidv4(),
      description: "",
      headline: "",
      link: "",
      PostAsset: null,
      StoryAsset: null,
      order: 0,
    },
  ],
};

export function getNewAdTemplate(input: CampaignInput): AdInput {
  const { selectedActivityIndex, Activities } = input;
  const selectedActivity = Activities[selectedActivityIndex];

  if (
    selectedActivity.metaPurpose === "MESSAGE_INSTAGRAM" ||
    selectedActivity.metaPurpose === "MESSAGE_MESSENGER" ||
    selectedActivity.metaPurpose === "MESSAGE_WHATSAPP"
  ) {
    return engagementMessageAdTemplate;
  } else if (selectedActivity.metaPurpose === "PROMOTE_POST") {
    return promotePostAdTemplate;
  } else if (
    selectedActivity.metaPurpose === "CALL" ||
    selectedActivity.metaPurpose === "VIDEO_2_SEC_VIEW" ||
    selectedActivity.metaPurpose === "VIDEO_THRUPLAY"
  ) {
    return engagementAdTemplate;
  } else if (selectedActivity.metaPurpose === "SALES_CATALOG") {
    return newCatalogAdTemplate;
  }

  return newCustomCreativeAdTemplate;
}
