import * as Yup from "yup";

import {
  EmailIntegrationDocument,
  useEmailIntegrationCreateMutation,
  useEmailIntegrationDeleteMutation,
  useEmailIntegrationDomainVerifyStatusMutation,
  useEmailIntegrationQuery,
} from "src/graphql/generated/schema";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { AddSenderEmails } from "./AddSender/AddSenderEmails";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { Button } from "src/components/Button/Button";
import { EmailCnameAndDomain } from "./CnameAndDomainOwnership/EmailCnameAndDomain";
import { EmailDomainDetails } from "./EmailDomainDetails";
import { EmailDomainTxt } from "./CnameAndDomainOwnership/EmailDomainTxt";
import { EmailVerify } from "./CnameAndDomainOwnership/EmailVerify";
import { IconButton } from "src/components/IconButton/IconButton";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { Spinner } from "src/components/Spinner/Spinner";
import { Support } from "../Common/Support";
import styles from "./EmailIntegration.module.scss";
import { RevokeIntegrationModal } from "src/modules/global/components/RevokeIntegrationModal";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function EmailIntegration() {
  // define state based on whether email integration is already created or not
  const {
    data: integrationData,
    loading: integrationLoading,
    error: integrationError,
  } = useEmailIntegrationQuery();
  const [emailIntegrationCreate, { loading: creatingIntegration }] =
    useEmailIntegrationCreateMutation({
      refetchQueries: [
        {
          query: EmailIntegrationDocument,
        },
      ],
      awaitRefetchQueries: true,
    });
  const [emailIntegrationDelete] = useEmailIntegrationDeleteMutation();
  const [emailIntegrationDomainVerifyStatus, { loading: verifyingDomain }] =
    useEmailIntegrationDomainVerifyStatusMutation();
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [isDnsVerified, setIsDnsVerified] = useState(false);
  const [isIntegrationComplete, setIsIntegrationComplete] = useState(false);
  const [verifyStepNumber, setVerifyStepNumber] = useState(0);

  useEffect(() => {
    if (!integrationData) return;
    if (!integrationData.emailIntegration) return;
    if (integrationData.emailIntegration.domainVerified) {
      setIsDnsVerified(true);
      setIsIntegrationComplete(true);
      setVerifyStepNumber(2);
      return;
    }
    if (integrationData.emailIntegration.dnsRecords.length > 0) {
      setIsDnsVerified(true);
      setVerifyStepNumber(1);
      return;
    }
  }, [integrationData]);

  if (integrationLoading) {
    return <PageLoader />;
  }

  if (integrationError) {
    return <ErrorHandler error={integrationError} />;
  }

  const senderEmails =
    integrationData &&
    integrationData.emailIntegration &&
    integrationData.emailIntegration?.SenderEmails.map(
      (v) => v.email.split("@")[0],
    );
  return (
    <div>
      <RevokeIntegrationModal
        isOpen={showRevokeModal}
        onClose={() => setShowRevokeModal(false)}
        onRevokeIntegration={async () => {
          await emailIntegrationDelete();
          setShowRevokeModal(false);
        }}
      />
      <Formik
        initialValues={{
          domain: integrationData?.emailIntegration?.domain || "",
          mailFromSubdomain:
            integrationData?.emailIntegration?.mailFromSubdomain || "",
        }}
        onSubmit={async (values) => {
          await emailIntegrationCreate({
            variables: {
              input: {
                domain: values.domain,
                mailFromSubdomain: values.mailFromSubdomain,
              },
            },
          });

          setVerifyStepNumber(verifyStepNumber + 1);
        }}
        validationSchema={Yup.object().shape({
          // TODO: check for @ and https:// separately also write a better message than Invalid Domain
          domain: Yup.string()
            .matches(
              /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
              "Invalid Domain",
            )
            .required("Domain is required"),
          mailFromSubdomain: Yup.string()
            .trim()
            .required("Mail From is required"),
        })}
      >
        {({ isValid, dirty, handleSubmit }) => (
          <Form>
            <PageHeader
              title={
                <div className={styles.pageTitleContainer}>
                  <IconButton
                    icon={<ArrowBack className={styles.backIcon} />}
                    style="plain"
                    to="/settings/integrations"
                  />
                  <h2 className={styles.pageTitle}>Email Integration</h2>
                </div>
              }
              actions={
                <>
                  {verifyStepNumber < 1 ? (
                    <Button
                      size="small"
                      disabled={!dirty || !isValid}
                      onClick={() => {
                        handleSubmit();
                      }}
                      loading={creatingIntegration}
                    >
                      Continue
                    </Button>
                  ) : (
                    <>
                      <>
                        {/* <Button
                              color="danger"
                              size="small"
                              style="outline"
                              onClick={() => setShowRevokeModal(true)}
                            >
                              Remove Domain
                            </Button> */}
                        <Button
                          color="primary"
                          size="small"
                          loading={verifyingDomain}
                          onClick={async () => {
                            const { data } =
                              await emailIntegrationDomainVerifyStatus();
                            if (data?.emailIntegrationDomainVerifyStatus) {
                              setIsIntegrationComplete(true);
                              setIsDnsVerified(true);
                              setVerifyStepNumber(2);
                            }
                          }}
                          disabled={!isDnsVerified}
                        >
                          Verify CNAME
                        </Button>
                        {/* <Button size="small">Verify Domain Ownership</Button> */}
                      </>

                      {/* {isDnsVerified && !isIntegrationComplete && (
                        <Button
                          loading={creatingIntegration}
                          color="primary"
                          size="small"
                          type="submit"
                        >
                          Verify Domain
                        </Button>
                      )} */}
                      {/* {isIntegrationComplete && (
                          <Button
                            color="danger"
                            size="small"
                            style="outline"
                            onClick={() => setShowRevokeModal(true)}
                          >
                            Remove Integration
                          </Button>
                        )} */}
                    </>
                  )}
                </>
              }
            />
            <div className={styles.pageContent}>
              {!isIntegrationComplete && verifyStepNumber === 0 && (
                <EmailDomainDetails />
              )}
              {isDnsVerified &&
              !isIntegrationComplete &&
              verifyStepNumber === 1 ? (
                <EmailCnameAndDomain
                  dnsRecords={integrationData?.emailIntegration?.dnsRecords}
                />
              ) : (
                <></>
              )}
              {isIntegrationComplete && isDnsVerified && (
                <EmailVerify
                  title="CNAME has been verified"
                  subtitle="Your MX record has been verified."
                  bgImg={"/imagesAndIcons/CnameVerify.svg"}
                />
              )}
              {isIntegrationComplete &&
                isDnsVerified &&
                verifyStepNumber > 1 && (
                  <AddSenderEmails
                    sendersEmail={senderEmails}
                    subdomain={
                      integrationData.emailIntegration.mailFromSubdomain
                    }
                    domain={integrationData.emailIntegration.domain}
                  />
                )}
              <Support />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
