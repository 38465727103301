import { MetaActivityAdTemplate } from "../../../graphql/generated/schema";
import { LineItems } from "../../../lib/types";
import { generateLineItemsFromCatalogueProducts } from "../../global/functions/generateLineItemsFromCatalogueProducts";
import { generateLineItemsFromExistingPost } from "../../global/functions/generateLineItemsFromExistingPost";

export function transformAdTemplateToLineItems(
  adTemplate: MetaActivityAdTemplate
): LineItems[] | LineItems {
  if (adTemplate.adType === "CATALOG" || adTemplate.catalogueId) {
    return generateLineItemsFromCatalogueProducts(
      adTemplate.Catalogue ? adTemplate.Catalogue?.Products : []
    );
  }

  if (adTemplate.adType === "EXISTING_POST") {
    return generateLineItemsFromExistingPost(adTemplate?.ExistingPost, "");
  }

  if (
    adTemplate.LineItems.length === 1 &&
    adTemplate.adType === "CUSTOM_CREATIVE"
  ) {
    return {
      ...adTemplate.LineItems[0],
      link: "",
    };
  }

  return adTemplate?.LineItems.map((v) => ({ link: "", ...v }));
}
