import { Button } from "src/components/Button/Button";
import styles from "./Plans.module.scss";
import Select from "src/components/Select/Select";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as Check } from "@material-design-icons/svg/outlined/check.svg";
import {
  BillingPlanCycleEnum,
  BillingPlanTypeEnum,
  CustomerCountRange,
  MonthlyAdSpendRange,
  MonthlyCreditRange,
  useCreateBillingMutation,
  useUpgradeBillingMutation,
} from "src/graphql/generated/schema";
import {
  premiumCustomerCountOptions,
  premiumAdSpendOptions,
  plans,
} from "../misc/planOptions";
import { getPrice } from "../misc/getPrice";
import { useEffect, useState } from "react";
import GET_BILLING from "../api/getBilling";
import { formatPrice } from "src/lib/formatPrice";
import toast from "react-hot-toast";

const enterpriseCardArr = [
  "Dedicated Onboarding",
  "Tech Setup Help & Customisation",
  "Premium Support",
];

interface IPlans {
  selectedBillingCycle: BillingPlanCycleEnum;
  setSelectedBillingCycle: (i: BillingPlanCycleEnum) => void;
  selectedAdSpend: MonthlyAdSpendRange;
  setSelectedAdSpend: (i: MonthlyAdSpendRange) => void;
  selectedCustomerCount: CustomerCountRange;
  setSelectedCustomerCount: (i: CustomerCountRange) => void;
  currentlyActiveTier: BillingPlanTypeEnum;
  isUpgrade: boolean;
  selectedCredits: MonthlyCreditRange;
  setSelectedCredits: (i: MonthlyCreditRange) => void;
  selectedTab: "ENGAGE" | "GROW";
  isEnterprisePlan: boolean;
  setIsEnterprisePlan: (i: boolean) => void;
  handlePaymentIntent: ({
    paymentIntent,
    billingId,
  }: {
    paymentIntent: string;
    billingId: string;
  }) => void;
}

export function Plans({
  isEnterprisePlan,
  selectedCredits,
  setIsEnterprisePlan,
  isUpgrade,
  selectedBillingCycle,
  setSelectedBillingCycle,
  selectedAdSpend,
  setSelectedAdSpend,
  selectedCustomerCount,
  setSelectedCustomerCount,
  currentlyActiveTier,
  selectedTab,
  handlePaymentIntent,
}: IPlans) {
  const isEngageTabSelected = selectedTab === "ENGAGE";
  const [planClicked, setPlanClicked] = useState<"STANDARD" | "PREMIUM" | null>(
    null,
  );
  const [billingCreate, { data, loading }] = useCreateBillingMutation({
    refetchQueries: [{ query: GET_BILLING }],
  });
  const [billingUpgrade, { data: upgradeData, loading: upgradeLoading }] =
    useUpgradeBillingMutation({ refetchQueries: [{ query: GET_BILLING }] });

  const createdBilling = data?.billingCreate || upgradeData?.billingUpgrade;

  useEffect(() => {
    if (createdBilling) {
      handlePaymentIntent({
        paymentIntent: createdBilling.paymentIntent,
        billingId: createdBilling.Billing.id,
      });
    }
  }, [createdBilling]);

  return (
    <div className={styles.planDetails}>
      <div className={styles.contactWrapper}>
        <div>
          <p className={styles.contactTitle}>
            {isEngageTabSelected
              ? "How many Contacts do you have"
              : "What's your monthly ad spend? (INR)"}
          </p>
          <p className={styles.contactDescription}>
            {isEngageTabSelected
              ? "Plans pricing for 0-2500 customers"
              : "Pick the right plan for your ad budget"}
          </p>
          <Select
            options={
              isEngageTabSelected
                ? premiumCustomerCountOptions
                : premiumAdSpendOptions
            }
            value={
              isEngageTabSelected ? selectedCustomerCount : selectedAdSpend
            }
            onChange={(e) => {
              if (isEngageTabSelected) {
                setSelectedCustomerCount(e);
              } else {
                setSelectedAdSpend(e);
              }
              // if (isEngage && e === "60,000 +") {
              //   setIsEnterprisePlan(true);
              // } else {
              //   setIsEnterprisePlan(false);
              // }
            }}
          />
        </div>
        <p className={styles.customerLimit}>
          <Link to={"/"} className={styles.link}>
            *Overage fees
          </Link>{" "}
          applies if{" "}
          {isEngageTabSelected ? "customer sent" : "monthly ad spent"} limit is
          exceeded
        </p>
      </div>
      <div className={styles.planOptions}>
        {isEnterprisePlan ? (
          <div className={classNames(styles.planCard, styles.enterpriseCard)}>
            <div>
              <p className={styles.enterpriseHead}>Enterprise</p>
              <p className={styles.enterpriseTitle}>Let's talk</p>
              <p className={styles.enterpriseDescription}>
                Make your marketing go even further by reaching customers
                through both email and SMS.
              </p>
            </div>
            <div>
              <p className={styles.enterpriseFeatTitle}>
                Top tier plan, also includes
              </p>
              <div className={styles.enterpriseFeatures}>
                {enterpriseCardArr.map((item, index) => (
                  <span key={index} className={styles.enterpriseFeat}>
                    <Check className={styles.checkIco} />
                    {item}
                  </span>
                ))}
              </div>
            </div>
            <Button className={styles.enterpriseBtn} width="full">
              Contact Sales
            </Button>
          </div>
        ) : (
          plans.map((item, index) => (
            <div
              key={index}
              className={classNames(styles.planCard, {
                [styles.standardCard]: item.type === "STANDARD",
                [styles.advanceCard]: item.type === "PREMIUM",
              })}
            >
              <div>
                <p className={styles.cardTitle}>{item.title}</p>
                <p className={styles.cardDescription}>{item.description}</p>
              </div>
              <div
                className={classNames(styles.btnGrpWrapper, {
                  [styles.standardGrp]: item.type === "STANDARD",
                  [styles.advanceGrp]: item.type === "PREMIUM",
                })}
              >
                {item.options.map((op, idx) => (
                  <button
                    type="button"
                    key={idx}
                    className={classNames(styles.btn, {
                      [styles.standardBtn]: item.type === "STANDARD",
                      [styles.advanceBtn]: item.type === "PREMIUM",
                      [styles.standardBtnGlow]:
                        item.type === "STANDARD" &&
                        selectedBillingCycle === op.value,
                      [styles.advanceBtnGlow]:
                        item.type === "PREMIUM" &&
                        selectedBillingCycle === op.value,
                    })}
                    onClick={() => setSelectedBillingCycle(op.value)}
                  >
                    {op.label}
                  </button>
                ))}
              </div>
              <div className={styles.price}>
                <span className={styles.priceAmount}>
                  {formatPrice(
                    getPrice({
                      plan: item,
                      selectedTab,
                      selectedCustomerCount,
                      selectedAdSpend,
                      selectedBillingCycle,
                      selectedCredits,
                    }),
                  )}
                </span>
                <span className={styles.priceText}>/monthly</span>
              </div>
              <Button
                // color={
                //   currentlyActiveTier !== item.type ? "primary" : "subdued"
                // }
                color="primary"
                style="solid"
                // style={currentlyActiveTier !== item.type ? "solid" : "outline"}
                width="full"
                loading={
                  (loading || upgradeLoading) && planClicked === item.type
                }
                onClick={() => {
                  setPlanClicked(item.type);
                  const variables = {
                    input: {
                      ...(!isUpgrade && {
                        cycle: selectedBillingCycle,
                      }),
                      ...(isEngageTabSelected && {
                        customerCountRange: selectedCustomerCount,
                      }),
                      [isEngageTabSelected ? "engagePlanType" : "growPlanType"]:
                        item.type,
                      ...(!isEngageTabSelected && {
                        monthlyAdSpendRange: selectedAdSpend,
                      }),
                      ...(isEngageTabSelected && {
                        monthlyCreditRange: selectedCredits,
                      }),
                    },
                  };

                  try {
                    if (isUpgrade) {
                      billingUpgrade({ variables });
                    } else {
                      billingCreate({ variables });
                    }
                  } catch (e) {
                    toast.error(e.message);
                  }
                }}
              >
                Purchase {item.title}
              </Button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
