import styles from "./AddAudienceDetails.module.scss";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { useFormikContext } from "formik";
import { Button } from "src/components/Button/Button";
import {
  ICreatePreviewBasedAudienceFormInput,
  WizardFormStepValue,
} from "../misc/createPreviewBasedAudienceTypes";
import FacebookSettings, {
  upsertDefaultSettings,
} from "src/modules/global/components/FacebookSettings";
import { useMetaIntegrationDefaultSettingsQuery } from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";

interface IAddAudienceDetails {
  changeStep: (stepName: WizardFormStepValue) => void;
}

export function AddAudienceDetails({ changeStep }: IAddAudienceDetails) {
  const { handleSubmit, values, setFieldValue } =
    useFormikContext<ICreatePreviewBasedAudienceFormInput>();

  const fieldsMapper = {
    adAccountId: "enabledAccountId",
    adAccountName: "enabledAccountName",
    platformAccountId: "adAccountId",
    conversionDomain: "conversionDomain",
    instagramPageId: "instagramPageId",
    metaPageConnectedInstagramBusinessAccountId:
      "metaPageConnectedInstagramBusinessAccountId",
    pageId: "metaPageId",
    pixelId: "pixelId",
  };
  const { loading } = useMetaIntegrationDefaultSettingsQuery({
    onCompleted: (data) =>
      upsertDefaultSettings(
        data.metaIntegrationDefaultSettings,
        setFieldValue,
        fieldsMapper,
      ),
  });

  const selectedPresetsPlatform = Array.from(
    new Set(values.selectedPresets.flatMap((v) => v.platforms)),
  );

  if (loading) {
    return (
      <div>
        <Spinner height="screen" />
      </div>
    );
  }

  const FooterAction = (
    <>
      <Button
        color="subdued"
        style="outline"
        size="small"
        onClick={() => changeStep("presetAudienceSelector")}
      >
        Back
      </Button>
      <Button size="small" onClick={handleSubmit}>
        Next
      </Button>
    </>
  );

  return (
    <div>
      <div className={styles.head}>
        <div className={styles.heading}>Add Audience Details</div>
        <div className={styles.subHeading}>
          We have pre set audiences which has been created by our expert for
          best possible result
        </div>
      </div>
      <div className={styles.sectionHeader}>
        Select source from you want to target your audience
      </div>

      <FacebookSettings
        containerClass={styles.sectionForm}
        showConversionDomainInput={false}
        showInstagramFields={selectedPresetsPlatform.includes("INSTAGRAM")}
        fieldsMapper={fieldsMapper}
      />
      <StickyFooter actions={FooterAction} />
    </div>
  );
}
