import { Button } from "src/components/Button/Button";
import styles from "./AccountSecurity.module.scss";
import { toast } from "react-hot-toast";
import { usePasswordUpdateInitiateMutation } from "src/graphql/generated/schema";
import { useState } from "react";
import { SectionHeading } from "../../global/components/SectionHeading";

interface AccountSecurityProps {
  email: string;
}

export function AccountSecurity({ email }: AccountSecurityProps) {
  const [resetPasswordInitiated, setResetPasswordInitiated] = useState(false);
  const [passwordUpdateInitiateFunc, { loading }] =
    usePasswordUpdateInitiateMutation();

  return (
    <div>
      <SectionHeading
        heading={"Security & Password"}
        description={
          "Ensure your account's security with a new password. Click here to update your password now."
        }
      />
      <div className={styles.wrapper}>
        <Button
          loading={loading}
          disabled={loading}
          size="micro"
          style="outline"
          color="normal"
          onClick={async () => {
            try {
              const { data } = await passwordUpdateInitiateFunc({
                variables: {
                  input: {
                    email,
                  },
                },
              });

              if (data?.passwordUpdateInitiate?.userError) {
                console.log(data?.passwordUpdateInitiate?.userError);
                toast.error("An error occured. Try again later");
                return;
              }

              if (data?.passwordUpdateInitiate?.response) {
                toast.success("Reset password mail has been sent to you");

                setResetPasswordInitiated(true);
                return;
              }
            } catch (e) {
              toast.error(e.message);
            }
          }}
        >
          Set Password
        </Button>
      </div>
    </div>
  );
}
