import { AdsetInput, CampaignInput } from "./createCampaignTypes";

// Define initial blank values if preset id is not available
export const defaultInitialValues: CampaignInput = {
  campaignName: "Untitled Campaign",
  selectedActivityIndex: 0,
  isCampaignNameEditing: true,
  objective: null,
  publishAfterSave: false,
  Activities: [
    {
      completedSteps: [],
      metaPurpose: "SALES_STANDARD",
      visitedSteps: [],
      currentStep: "choosePlatformAndActivityName",
      platform: "META",
      activityName: "",
      facebookActivityType: "META_STANDARD",
    },
  ],
};

export const defaultAdset: AdsetInput = {
  budget: "",
  description: ``,
  name: ``,
  selected: true,
  type: "INDIVIDUAL",
  audienceId: null,
  templateId: null,
  promotedProductSetId: null,
  audienceSetId: null,
  ProductSetSpecs: [],
};
