const industries = [
  { value: "AUTO", label: "Automobile" },
  { value: "BEAUTY", label: "Beauty" },
  { value: "APPAREL", label: "Apparel & Clothing" },
  { value: "EDU", label: "Education" },
  { value: "ENTERTAIN", label: "Entertainment" },
  { value: "EVENT_PLAN", label: "Event Planning" },
  { value: "FINANCE", label: "Finance" },
  { value: "GROCERY", label: "Grocery" },
  { value: "GOVT", label: "Government" },
  { value: "HOTEL", label: "Hotel" },
  { value: "HEALTH", label: "Health" },
  { value: "NONPROFIT", label: "Non Profit" },
  { value: "PROF_SERVICES", label: "Professional Services" },
  { value: "RETAIL", label: "Retail" },
  { value: "TRAVEL", label: "Travel" },
  { value: "RESTAURANT", label: "Restaurant" },
  { value: "NOT_A_BIZ", label: "Not a business" },
  { value: "OTHER", label: "Other" },
];

export default industries;
