import {
  Cell,
  Pie,
  PieChart as RechartPieChart,
  ResponsiveContainer,
} from "recharts";

interface IPieChart {
  width: number;
  height: number;
  statValues: Record<string, string | number>[];
  statKey: string;
  startRadius?: number;
  thickness?: number;
  cells: {
    color: string;
  }[];
}

export function PieChart({
  statKey,
  statValues,
  cells,
  height,
  width,
  startRadius = 66,
  thickness = 57,
}: IPieChart) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <RechartPieChart width={width} height={height}>
        <Pie
          data={statValues}
          dataKey={statKey}
          cx="50%"
          cy="50%"
          innerRadius={startRadius}
          outerRadius={startRadius + thickness}
          fill="#2C6ECB"
          label={false}
          stroke="none"
          labelLine={false}
        >
          {cells.map((item, index) => (
            <Cell
              key={index}
              fill={item.color}
              stroke="none"
              style={{ outline: "none" }}
            />
          ))}
        </Pie>
      </RechartPieChart>
    </ResponsiveContainer>
  );
}
