import { MetaActivityAdTemplate } from "../../../graphql/generated/schema";
import { FormValues } from "../misc/adTemplateCreateTypes";

export function transformTemplateInputToMetaActivityAdTemplate(
  values: FormValues
): MetaActivityAdTemplate {
  return {
    adType:
      values.type === "SINGLE_IMAGE_OR_VIDEO" || values.type === "CAROUSEL"
        ? "CUSTOM_CREATIVE"
        : values.type === "CATALOGUE_AD"
        ? "CATALOG"
        : "EXISTING_POST",
    catalogueId: values.catalogueId,
    LineItems: values.LineItems.map((v, i) => ({
      ...v,
      id: String(i),
      order: i,
    })),
    ExistingPost: values.existingPost,
    primaryText: values.primaryText,
    name: values.title,
    createdAt: new Date(),
    updatedAt: new Date(),
    id: "ADTEMP",
  };
}
