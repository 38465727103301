import styles from "./SegmentGrowthGraph.module.scss";
import { useContext } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { GraphNotAvailableYet } from "src/modules/global/components/GraphNotAvailableYet";
import AuthContext from "src/stores/AuthContext";
import { transformPerTickInsights } from "src/modules/global/functions/transformPerTickInsights";
import { BarGraph } from "src/modules/global/components/BarGraph";
import { highPerformanceDateFormatter } from "src/lib/highPerformanceDateFormatter";
import { formatNumber } from "src/lib/formatNumber";
import { SegmentGrowthGraphHeader } from "./SegmentGrowthGraphHeader";
import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";

interface ISegmentGrowthGraph {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}

export function SegmentGrowthGraph({
  segmentId,
  dateFrom,
  dateTo,
}: ISegmentGrowthGraph) {
  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "segment_change_per_tick",
    userToken,
    {
      segmentId,
      dateFrom,
      dateTo,
    },
  );

  if (error) {
    return <GraphLoadingError error={error} height={300} />;
  }

  if (loading) {
    return <Spinner height={300} />;
  }

  let insights = transformPerTickInsights(data).map((r) => ({
    ...r,
    // show the decrement value as negative in graph
    remove_customer_from_segment: r["remove_customer_from_segment"]
      ? -r["remove_customer_from_segment"]
      : 0,
  }));

  if (!insights.length) {
    return <GraphNotAvailableYet height={300} type="barGraph" />;
  }

  return (
    <>
      <div className={styles.statTile}>
        <SegmentGrowthGraphHeader
          dateFrom={dateFrom}
          dateTo={dateTo}
          segmentId={segmentId}
        />
      </div>
      <BarGraph
        width={500}
        height={300}
        stack="separate"
        bar={{ size: 32, borderRadius: 0 }}
        statLabels={[
          {
            dataKey: "add_customer_in_segment",
            color: "#347C84",
            name: "Customer Added",
          },
          {
            dataKey: "remove_customer_from_segment",
            color: "#FFA7A3",
            name: "Customer Removed",
          },
        ]}
        statValues={insights}
        xAxis={{
          dataKey: "tickDate",
          label: "Timeline",
          tickFormatter: highPerformanceDateFormatter,
        }}
        yAxis={{
          label: "Customer Count",
          scale: "linear",
          tickFormatter: formatNumber,
        }}
        referenceLine={{ color: "black", y: 0 }}
      />
    </>
  );
}
