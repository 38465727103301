import * as Yup from "yup";
import { campaignAndActivityRegex } from "./campaignName";

// export const choosePlatformAndActivitySchema = Yup.object({
//   Activities: Yup.array(
//     Yup.object({
//       activityName: Yup.string()
//         .required("Activity name is required")
//         .matches(
//           // Resource: https://saasbase.dev/tools/regex-generator
//           campaignAndActivityRegex.regex,
//           campaignAndActivityRegex.message
//         )
//         .max(50, "Activity name cannot be more than 150 characters"),
//       platform: Yup.string().required("Platform is required").nullable(),
//     })
//   )
//     .min(1)
//     .max(10),
// });

export const choosePlatformAndActivitySchema = Yup.object({
  activityName: Yup.string()
    .required("Activity name is required")
    .min(2)
    .matches(
      // Resource: https://saasbase.dev/tools/regex-generator
      campaignAndActivityRegex.regex,
      campaignAndActivityRegex.message
    )
    .max(50, "Activity name cannot be more than 150 characters"),
  platform: Yup.string().required("Platform is required").nullable(),
  // objective: Yup.string().required("Objective is required"),
  // adAccountId: Yup.string().required("Ad account is required"),
  // pixelId: Yup.string().required("Pixel is required"),
  // instagramPageId: Yup.string().required("Instagram is required"),
  // pageId: Yup.string().required("Meta pages is required"),
  // conversionDomain: Yup.string()
  //   .matches(
  //     /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[\w.-]*)*/g,
  //     "Should be a valid domain name"
  //   )
  //   .required("Conversion domain is required"),
});
