// import { ReactComponent as Insights } from "@material-design-icons/svg/outlined/insights.svg";
// import { ReactComponent as VerticalAlignBottom } from "@material-design-icons/svg/outlined/vertical_align_bottom.svg";
import { Button } from "src/components/Button/Button";
import { Input } from "src/components/Input/Input";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import { ReactComponent as Search } from "@material-design-icons/svg/outlined/search.svg";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import { dateFilterOptions } from "src/modules/global/misc/dateFilterOptions";
import { CustomersSortBy } from "src/graphql/generated/schema";
import { ReactComponent as ArrowUp } from "@material-design-icons/svg/outlined/arrow_upward.svg";
import { ReactComponent as ArrowDownward } from "@material-design-icons/svg/outlined/arrow_downward.svg";
import { ReactComponent as PersonAdd } from "@material-symbols/svg-600/rounded/person_add.svg";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Searchbar } from "src/components/Searchbar/Searchbar";

interface ICustomerPageHeader {
  setShowAddCustomerModal: (i: boolean) => void;
  setFilters: (i: any) => void;
  filters: any;
  sortBy: CustomersSortBy;
  setSortBy: (i: CustomersSortBy) => void;
}

export function CustomerPageHeader({
  filters,
  setFilters,
  setSortBy,
  sortBy,
  setShowAddCustomerModal,
}: ICustomerPageHeader) {
  return (
    <PageHeader
      title="Contacts"
      actions={
        <>
          <Searchbar
            onChange={(value) => {
              setFilters({
                ...filters,
                searchQuery: value,
              });
            }}
            value={filters.searchQuery}
            input={{ placeholder: "Search Contacts" }}
          />
          <SortByDropdown
            setSortBy={setSortBy}
            sortBy={{
              // direction is optional in customer sort
              direction: sortBy.direction || "DESC",
              field: sortBy.field,
            }}
            sortOptions={customerSortOptions}
          />
          <DateFilterDropdown
            dateFilter={filters.dateFilter}
            setDateFilter={(dateFilter) => {
              setFilters({
                ...filters,
                dateFilter,
              });
            }}
            filterOptions={dateFilterOptions}
          />
          <ButtonV2
            leftIcon={PersonAdd}
            size="small"
            onClick={() => setShowAddCustomerModal(true)}
          >
            Create Contact
          </ButtonV2>
        </>
      }
    />
  );
}

const customerSortOptions = [
  {
    type: "Created At",
    value: "CREATED_AT",
    options: [
      {
        label: "Newest",
        value: "DESC",
        icon: <ArrowUp />,
      },
      {
        label: "Oldest",
        value: "ASC",
        icon: <ArrowDownward />,
      },
    ],
  },
  {
    type: "Alphabetic",
    value: "FIRST_NAME",
    options: [
      {
        label: "A to Z",
        value: "ASC",
        icon: <ArrowUp />,
      },
      {
        label: "Z to A",
        value: "DESC",
        icon: <ArrowDownward />,
      },
    ],
  },
];
