import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  SortByInput,
  useMetaActivityAdTemplateDeleteMutation,
  useMetaActivityAdTemplatesLazyQuery,
} from "../../../../graphql/generated/schema";
import { useDebounceEffect } from "../../../campaign-wizard/functions/useDebounceEffect";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "../../../global/misc/dateFilterUtils";
import { PaginationInput } from "../../../global/misc/PaginationInput";
import { itemsPerPage } from "../../misc/itemsPerPage";
import { AdTemplateSingleSlideOver } from "../AdTemplateSingleSlideOver/AdTemplateSingleSlideOver";
import { AdTemplatesListingTable } from "./AdTemplatesListingTable";
import { NoResultScreen } from "../NoResultsScreen";

export interface AdTemplatesFilters {
  query: string;
  createdAt: DateFilter;
}

export function AdTemplatesListingTableLoader({
  filters,
  setFilters,
  sortBy,
  setSortBy,
}: {
  filters: AdTemplatesFilters;
  setFilters: (i: AdTemplatesFilters) => void;
  sortBy: SortByInput;
  setSortBy: (i: SortByInput) => void;
}) {
  const navigate = useNavigate();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );
  const [pagination, setPagination] = useState<PaginationInput>({
    take: itemsPerPage,
    cursor: null,
  });

  const [metaActivityAdTemplateDeleteFunc, { loading: deleteLoading }] =
    useMetaActivityAdTemplateDeleteMutation();

  const [metaActivityAdTemplatesFunc, { data, loading, called }] =
    useMetaActivityAdTemplatesLazyQuery({
      variables: {
        sortBy,
        filter: pagination,
      },
    });

  async function handleDeleteAdTemplate(id: string) {
    try {
      const { data } = await metaActivityAdTemplateDeleteFunc({
        variables: {
          id: id,
        },
        refetchQueries: ["metaActivityAdTemplates"],
        awaitRefetchQueries: true,
      });

      if (
        data &&
        data.metaActivityAdTemplateDelete &&
        data.metaActivityAdTemplateDelete.userError
      ) {
        const errorCode = data.metaActivityAdTemplateDelete.userError.code;
        if (errorCode === "ALREADY_IN_USE") {
          toast.error("Ad template is already in use");
          return;
        }
      }

      toast.success("Ad template deleted successfully");
    } catch (err) {
      toast.error(err.message);
    }
  }

  async function triggerMetaActivityAdTemplates({
    filters,
    pagination,
    sortBy,
  }) {
    const query = filters.query.trim();
    const createdAt = getDateFilterFromFilterEnum(filters.createdAt);
    await metaActivityAdTemplatesFunc({
      variables: {
        sortBy,
        filter: {
          take: pagination.take,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          name: query,
          createdAt,
        },
      },
    });
  }

  useEffect(() => {
    triggerMetaActivityAdTemplates({ filters, pagination, sortBy });
  }, [filters.createdAt, sortBy, pagination]);

  useDebounceEffect(
    () => {
      triggerMetaActivityAdTemplates({ filters, pagination, sortBy });
    },
    300,
    [filters.query]
  );

  const adTemplates =
    data && data.metaActivityAdTemplates
      ? data.metaActivityAdTemplates.edges.map((v) => v.node)
      : [];
  const areFiltersApplied =
    filters.createdAt !== "ALL_TIME" || filters.query !== "";

  const pageInfo = data?.metaActivityAdTemplates?.pageInfo;

  if (
    !loading &&
    called &&
    !data?.metaActivityAdTemplates?.edges?.length &&
    !areFiltersApplied
  ) {
    return (
      <NoResultScreen
        title="Create your Ad Library"
        description="Right now there is no activity to show."
        buttonLabel="Let's Get Started"
        buttonLink="/library/ad-library/create"
      />
    );
  }

  return (
    <div>
      <AdTemplatesListingTable
        // @ts-ignore
        adTemplates={adTemplates}
        pageInfo={pageInfo}
        loading={loading || deleteLoading}
        setPagination={setPagination}
        setSelectedTemplateId={setSelectedTemplateId}
        editAdTemplate={async (id) => {
          setSelectedTemplateId(null);
          navigate(`/library/ad-library/${id}/edit`);
        }}
        deleteAdTemplate={async (id) => {
          setSelectedTemplateId(null);
          handleDeleteAdTemplate(id);
        }}
        duplicateAdTemplate={(id) => {
          setSelectedTemplateId(null);
          navigate(`/library/ad-library/create?id=${id}`);
        }}
      />
      {Boolean(selectedTemplateId) && (
        <AdTemplateSingleSlideOver
          adTemplateId={selectedTemplateId}
          isOpen={Boolean(selectedTemplateId)}
          onClose={() => {
            setSelectedTemplateId(null);
          }}
        />
      )}
    </div>
  );
}
