import styles from "./WhatsappDetailSection.module.scss";

interface IDetailSection {
  title: string | React.ReactNode;
  subtitle?: string;
  children: React.ReactNode;
}

export function WhatsappDetailSection({
  title,
  subtitle,
  children,
}: IDetailSection) {
  return (
    <div className={styles.section}>
      <div>
        <span className={styles.title}>{title}</span>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      {children}
    </div>
  );
}
