import styles from "./SelectFacebookAudiencesField.module.scss";
import { Button } from "src/components/Button/Button";
import { AdsetInput, CampaignInput } from "../misc/createCampaignTypes";
import { getIn, useFormikContext } from "formik";
import { Input } from "src/components/Input/Input";
import { ReactComponent as SearchIcon } from "@material-design-icons/svg/outlined/search.svg";
import { ReactComponent as ExternalLinkIcon } from "@material-design-icons/svg/outlined/arrow_outward.svg";
import { ReactComponent as PositionTopRightIcon } from "@material-symbols/svg-400/rounded/position_top_right.svg";
import {
  AudienceAudienceSetIndexesQuery,
  PageInfoFragment,
} from "src/graphql/generated/schema";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Spinner } from "src/components/Spinner/Spinner";
import { PaginationInput } from "src/modules/global/misc/PaginationInput";
import { Link } from "react-router-dom";
import { AudienceListSelectionTable } from "./AudienceListSelectionTable";
import { AudienceListEmptyScreen } from "./AudienceListEmptyScreen";
import { AudienceListNoResults } from "./AudienceListNoResults";
import { Pagination } from "src/components/Pagination/Pagination";
import { AudienceSetViewSelection } from "./AudienceListViewSelection";
import { FormEvent, useState } from "react";

interface ISelectFacebookAudiencesField {
  audienceAudienceSetIndexEdges: AudienceAudienceSetIndexesQuery["audienceAudienceSetIndexes"]["edges"];
  handleBack: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  loading: boolean;
  audienceNameQuery: string;
  setAudienceNameQuery: (i: string) => void;
  pagination: PaginationInput;
  setPagination: (i: PaginationInput) => void;
  pageInfo: PageInfoFragment;
}

export function SelectFacebookAudiencesField({
  handleBack,
  campaignInput,
  audienceAudienceSetIndexEdges,
  pagination,
  pageInfo,
  setPagination,
  loading,
  audienceNameQuery,
  setAudienceNameQuery,
}: ISelectFacebookAudiencesField) {
  const { values, setFieldValue, isSubmitting, handleSubmit } =
    useFormikContext<CampaignInput>();
  const baseKey = `Activities[${values.selectedActivityIndex}].Adsets`;
  const adsets: AdsetInput[] = getIn(values, baseKey) ?? [];
  const [isShowSelectionOpen, setIsShowSelectionOpen] = useState(false);

  function handleSearchInput(value: string): void {
    setAudienceNameQuery(value);
    // reset pagination when searching
    setPagination({ take: Math.abs(pagination.take), cursor: null });
  }

  const isAudienceAudienceSetIndexEmpty =
    audienceAudienceSetIndexEdges.length === 0;

  function suppressEnter(e: any) {
    // search automatically works on input change
    // this is to prevent submission of the outside form due to enter on this field
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.heading}>Select audiences to target</h1>
        <h3 className={styles.subheading}>
          Choose from your saved audiences and audience sets
        </h3>
      </div>
      <div className={styles.table}>
        <div>
          <div className={styles.search}>
            <Input
              onKeyPress={suppressEnter}
              placeholder="Search your audience by name"
              icon={SearchIcon}
              onChange={handleSearchInput}
              value={audienceNameQuery}
              width="full"
            />
          </div>
          {loading ? (
            <Spinner height={400} />
          ) : isAudienceAudienceSetIndexEmpty && audienceNameQuery ? (
            <AudienceListNoResults />
          ) : isAudienceAudienceSetIndexEmpty ? (
            <AudienceListEmptyScreen />
          ) : (
            <>
              <AudienceListSelectionTable
                audienceAudienceSetIndexEdges={audienceAudienceSetIndexEdges}
                adsets={adsets}
                baseKey={baseKey}
              />
              <div className={styles.paginationWrapper}>
                <Pagination
                  borderLess
                  label={
                    <Button
                      onClick={() => setIsShowSelectionOpen(true)}
                      style="plain"
                    >
                      <span className={styles.viewSelectionButton}>
                        <PositionTopRightIcon
                          fill="currentColor"
                          width={20}
                          height={20}
                        />
                        View Selection: {adsets.length}/20
                      </span>
                    </Button>
                  }
                  hasPrevious={pageInfo ? pageInfo?.hasPreviousPage : false}
                  hasNext={pageInfo ? pageInfo?.hasNextPage : false}
                  onPrevious={() => {
                    setPagination({
                      take: -pagination.take,
                      cursor: pageInfo?.startCursor,
                    });
                  }}
                  onNext={() => {
                    setPagination({
                      take: Math.abs(pagination.take),
                      cursor: pageInfo?.endCursor,
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <AudienceSetViewSelection
        initialValues={{ adsets }}
        onChange={(values) => setFieldValue(baseKey, values.adsets)}
        isShowSelectionOpen={isShowSelectionOpen}
        setIsShowSelectionOpen={setIsShowSelectionOpen}
      />
      <StickyFooter
        label={
          <span className={styles.tableFooterText}>
            <span>Don't see the right audience for campaign?</span>
            <Link
              className={styles.createAudienceLink}
              to="/audiences?createaudience=true"
            >
              Create New Audience
              <ExternalLinkIcon fill="currentColor" width={16} height={16} />
            </Link>
          </span>
        }
        actions={
          <>
            <Button
              style="outline"
              color="subdued"
              onClick={() => {
                handleBack(campaignInput);
              }}
              children="Back"
            />
            <Button
              onClick={handleSubmit}
              loading={isSubmitting}
              type="submit"
              children="Continue"
              disabled={adsets.length === 0}
            />
          </>
        }
      />
    </>
  );
}
