import Card from "src/components/Card/Card";
import { CreateCampaignPageLayout } from "./CreateCampaignPageLayout";
import Modal from "src/components/Modal/Modal";
import { RetargetAudiencesForm } from "./RetargetAudiencesForm";
import { RetargetAudiencesBudgetLoader } from "./RetargetAudienceBudgetLoader";
import { useContext } from "react";
import { WizardContext } from "src/modules/global/misc/WizardContext2";
import styles from "./RetargetAudiencesStep.module.scss";

export function RetargetAudiencesStep() {
  const { breadcrumbs, currentNode, goBack, data, markNodeAsComplete } =
    useContext(WizardContext);

  return (
    <CreateCampaignPageLayout
      breadcrumbs={breadcrumbs}
      campaignInput={data}
      campaignPresetUpsert={markNodeAsComplete}
    >
      <Card>
        <RetargetAudiencesForm
          handleBack={goBack}
          campaignPresetUpsert={markNodeAsComplete}
          campaignInput={data}
        />
      </Card>
      <Modal
        title="Set Budget & Select Product Set"
        maxWidth="1200px"
        isOpen={currentNode.id === "setRetargetAudiencesBudget"}
        onClose={() => {}}
        noContainer
      >
        <div className={styles.modalContent}>
          <RetargetAudiencesBudgetLoader
            handleBack={goBack}
            footerText="Add budget to all your Product Set for Facebook Catalogue Ads"
            campaignInput={data}
            campaignPresetUpsert={markNodeAsComplete}
          />
        </div>
      </Modal>
    </CreateCampaignPageLayout>
  );
}
