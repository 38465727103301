import { PageHeader } from "src/components/PageHeader/PageHeader";
import { Tabs } from "src/components/Tabs/Tabs";
import { useState } from "react";
import styles from "./SegmentSinglePage.module.scss";
import { SegmentCustomerList } from "../components/SegmentCustomerList";
import { SegmentOverview } from "../components/SegmentOverview";
import { SegmentHistory } from "../components/SegmentHistory";
import { useParams } from "react-router-dom";
import subDays from "date-fns/subDays";
import { useSegmentQuery } from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { convertDatetimeToDate } from "src/modules/global/functions/convertDatetimeToDate";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function SegmentSinglePage() {
  const { id } = useParams<{ id: string }>();
  const [selectedTab, setSelectedTab] = useState<tabValue>("OVERVIEW");
  const dateTo = convertDatetimeToDate(new Date());
  const dateFrom = convertDatetimeToDate(subDays(new Date(), 7));
  const { data, loading, error } = useSegmentQuery({
    variables: { id, outreachFilter: { cursor: null, take: 25 } },
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div>
      <PageHeader title={data.segment.name} back="/segments" />
      <div className={styles.tabsContainer}>
        <Tabs
          tabs={tabsArr}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />
      </div>
      <div className={styles.page}>
        {selectedTab === "OVERVIEW" && (
          <SegmentOverview
            segment={data.segment}
            dateFrom={dateFrom}
            dateTo={dateTo}
            segmentId={id}
          />
        )}
        {selectedTab === "CUSTOMER_LIST" && (
          <SegmentCustomerList segmentId={id} />
        )}
        {selectedTab === "HISTORY" && <SegmentHistory segment={data.segment} />}
      </div>
    </div>
  );
}

type tabValue = "OVERVIEW" | "CUSTOMER_LIST" | "HISTORY";

const tabsArr = [
  {
    label: "Overview",
    value: "OVERVIEW",
  },
  {
    label: "Customer List",
    value: "CUSTOMER_LIST",
  },
  {
    label: "History",
    value: "HISTORY",
  },
];
