import { InputFormik } from "src/components/InputFormik/InputFormik";
import Modal from "src/components/Modal/Modal";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";
import styles from "./EngagementMessageTemplateEditor.module.scss";
import { ReactComponent as PlusIcon } from "@material-design-icons/svg/filled/add.svg";
import { ReactComponent as DeleteIcon } from "@material-symbols/svg-400/rounded/delete.svg";
import { Button } from "src/components/Button/Button";
import { getIn, useFormikContext } from "formik";
import { CampaignInput, MessageOptionInput } from "../misc/createCampaignTypes";
import { generateRandomNumber } from "src/lib/generateRandomNumber";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { TabInput } from "./TabInput";
import staticImageOrVideo from "../assets/staticAsset.svg";
import carousel from "../assets/carousel.svg";
import { WhatsappTabValue } from "../misc/whatsappTabType";
import { InstagramMessagePreview } from "../../global/components/InstagramMessagePreview";
import { WhatsappPreview } from "../../global/components/WhatsappPreview";
import { SmartphoneShell } from "../../global/components/SmartphoneShell";

const whatsappCustomerActionTabs = [
  {
    label: "Frequently asked questions",
    image: { src: carousel, size: 32 },
    value: "MESSAGE_OPTIONS" as WhatsappTabValue,
  },
  {
    label: "Pre-filled Message",
    image: { src: staticImageOrVideo, size: 32 },
    value: "PREFILLED_MESSAGE" as WhatsappTabValue,
  },
];

export function EngagementMessageTemplateEditor({
  baseKey,
  isOpen,
  onClose,
}: {
  baseKey: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { values, setFieldValue } = useFormikContext<CampaignInput>();

  const selectedActivity = values.Activities[values.selectedActivityIndex];
  const pageWelcomeMessage = getIn(values, baseKey);
  const greetingKey = `${baseKey}.greeting`;
  const messageOptionsBaseKey = `${baseKey}.MessageOptions`;
  const messageOptions: MessageOptionInput[] = getIn(
    values,
    `${baseKey}.MessageOptions`
  );

  function handleAddNewMessageOption() {
    setFieldValue(messageOptionsBaseKey, [
      ...messageOptions,
      {
        text: "",
        automatedReply: "",
        id: generateRandomNumber({ min: 100, max: 999 }).toString(),
      } as MessageOptionInput,
    ]);
  }

  function handleMessageOptionDelete(messageOption: MessageOptionInput) {
    setFieldValue(
      messageOptionsBaseKey,
      messageOptions.filter(
        (m: MessageOptionInput) => m.id !== messageOption.id
      )
    );
  }

  const metaPurpose = selectedActivity.metaPurpose;

  const selectedWhatsappCustomerAction =
    pageWelcomeMessage.selectedWhatsappCustomerAction;

  function handleChangeActionType(value: WhatsappTabValue) {
    setFieldValue(`${baseKey}.selectedWhatsappCustomerAction`, value);
  }

  return (
    <Modal
      maxWidth={"1200px"}
      isOpen={isOpen}
      noContainer
      onClose={onClose}
      title="Choose templates for response"
      subtitle="Create new segments using customizable templates."
      footer={{
        footerActions: null,
        footerContent: (
          <StickyFooter
            actions={
              <Button onClick={onClose} style="outline" color="subdued">
                Save & Exit
              </Button>
            }
          />
        ),
      }}
    >
      <section className={styles.messageTemplateEditor}>
        <div className={styles.formArea}>
          <div className={styles.greetingSection}>
            <div className={styles.header}>
              <h2 className={styles.subheading}>Greeting</h2>
              <p className={styles.copyText}>
                Welcome people to the conversation after they've tapped on your
                ad
              </p>
            </div>
            <InputFormik
              placeholder="Write a greeting text"
              name={greetingKey}
            />
          </div>
          <div className={styles.faqSection}>
            <div className={styles.header}>
              <h2 className={styles.subheading}>Customer actions</h2>
              <p className={styles.copyText}>
                Suggest questions or messages that people can send.
              </p>
            </div>
            {metaPurpose === "MESSAGE_WHATSAPP" && (
              <div>
                <TabInput
                  onBlur={() => {}}
                  onChange={handleChangeActionType}
                  value={selectedWhatsappCustomerAction}
                  tabs={whatsappCustomerActionTabs}
                  justifyContent="center"
                />
              </div>
            )}

            {selectedWhatsappCustomerAction === "PREFILLED_MESSAGE" ? (
              <>
                <InputFormik
                  label="Pre-filled Message"
                  name={`${baseKey}.prefilledText`}
                  maxCharacterCount={80}
                  showCharacterCount={true}
                  helpText="This message will be pre-filled"
                />
              </>
            ) : (
              <>
                {messageOptions.map((messageOption, index) => (
                  <div className={styles.messageOption}>
                    <div className={styles.formControl}>
                      <InputFormik
                        name={`${messageOptionsBaseKey}[${index}].text`}
                        label={`Option ${index + 1}`}
                      />
                      {metaPurpose === "MESSAGE_INSTAGRAM" && (
                        <MultilineInputFormik
                          rows={3}
                          name={`${messageOptionsBaseKey}[${index}].automatedReply`}
                          label="Automated response - Optional"
                        />
                      )}
                    </div>
                    {messageOptions.length > 1 && (
                      <button
                        className={styles.deleteMessageOption}
                        type="button"
                        onClick={() => handleMessageOptionDelete(messageOption)}
                      >
                        <DeleteIcon width={20} height={20} />
                      </button>
                    )}
                  </div>
                ))}

                <div className={styles.addMoreQuestionBtn}>
                  <Button
                    onClick={handleAddNewMessageOption}
                    size="small"
                    color="subdued"
                    style="outline"
                  >
                    <span className={styles.addNewQuestionText}>
                      Add more question <PlusIcon width={16} height={16} />
                    </span>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.smartphonePreview}>
          <SmartphoneShell screenSize="35rem">
            {metaPurpose === "MESSAGE_WHATSAPP" ? (
              <WhatsappPreview
                selectedPreviewTab={selectedWhatsappCustomerAction}
                metaPageName={selectedActivity.metaPageName}
                metaPagePicture={selectedActivity.metaPagePicture}
                pageWelcomeMessage={pageWelcomeMessage}
              />
            ) : (
              <InstagramMessagePreview
                metaPageName={selectedActivity.metaPageName}
                metaPagePicture={selectedActivity.metaPagePicture}
                pageWelcomeMessage={pageWelcomeMessage}
              />
            )}
          </SmartphoneShell>
        </div>
      </section>
    </Modal>
  );
}
