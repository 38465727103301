import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Pagination } from "../../../../components/Pagination/Pagination";
import {
  AssetsOrderBy,
  useAssetDeleteMutation,
  useAssetsLazyQuery,
} from "../../../../graphql/generated/schema";
import { useDebounceEffect } from "../../../campaign-wizard/functions/useDebounceEffect";
import { getDateFilterFromFilterEnum } from "../../../global/misc/dateFilterUtils";
import { PaginationInput } from "../../../global/misc/PaginationInput";
import { assetListingFitler } from "../../misc/assetListing";
import { itemsPerPage } from "../../misc/itemsPerPage";
import { AdTemplateCreateSlideOverLoader } from "./AdTemplateCreateSlideOverLoader";
import { AssetDeleteAlert } from "./AssetDeleteAlert";
import { AssetDetailsSlideOverLoader } from "./AssetDetailsSlideOverLoader";
import { AssetListingTable } from "./AssetListingTable";
import { AssetListingTableHeader } from "./AssetListingTableHeader";

export function AssetListingTableLoader() {
  const [pagination, setPagination] = useState<PaginationInput>({
    take: itemsPerPage,
    cursor: null,
  });
  const [filters, setFilters] = useState<assetListingFitler>({
    dateFilter: "ALL_TIME",
    query: "",
  });
  const [selectedAssetId, setSelectedAssetId] = useState<null | string>(null);
  const [showAdTemplateCreateSlideOver, setShowAdTemplateCreateSlideOver] =
    useState(false);
  const [showAssetDetails, setShowAssetDetailsSlideOver] = useState(false);
  const [showAssetDeleteAlert, setShowAssetDeleteAlert] = useState(false);

  const [sortBy, setSortBy] = useState<AssetsOrderBy>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  const [assetDeleteFunc, { loading: assetDeleteLoading }] =
    useAssetDeleteMutation();
  const [assetsFunc, { data, loading }] = useAssetsLazyQuery();

  async function triggerAssetsQuery({ sortBy, filters, pagination }) {
    const query = filters.query.trim();
    const createdAt = getDateFilterFromFilterEnum(filters.dateFilter);
    await assetsFunc({
      variables: {
        sortBy: sortBy,
        filters: {
          name: query,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          take: pagination.take,
          createdAt,
        },
      },
    });
  }

  useEffect(() => {
    triggerAssetsQuery({
      filters,
      sortBy,
      pagination,
    });
  }, [filters.dateFilter, sortBy, pagination]);

  useDebounceEffect(
    () => {
      triggerAssetsQuery({
        filters,
        sortBy,
        pagination,
      });
    },
    300,
    [filters.query],
  );

  const assets = data?.assets.edges.map((v) => v.node) || [];
  const pageInfo = data?.assets?.pageInfo;

  async function deleteAssetHandler(id: string) {
    try {
      const { data } = await assetDeleteFunc({
        variables: {
          id: id,
        },
        refetchQueries: ["assets"],
        awaitRefetchQueries: true,
      });

      const assetData = data?.assetDelete;

      if (assetData && assetData.asset) {
        toast.success("Asset deleted successfully");
      }
      if (assetData && assetData.userError) {
        toast.error(assetData.userError.message);
      }
      return;
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <div>
      <AssetListingTableHeader
        filters={filters}
        setFilters={setFilters}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      <AssetListingTable
        assets={assets}
        triggerAdTemplateCreateSlideOver={(id) => {
          setSelectedAssetId(id);
          setShowAdTemplateCreateSlideOver(true);
        }}
        triggerAdDetailsSlideOver={(id) => {
          setSelectedAssetId(id);
          setShowAssetDetailsSlideOver(true);
        }}
        deleteAsset={(id) => {
          setSelectedAssetId(id);
          setShowAssetDeleteAlert(true);
        }}
        loading={loading}
      />
      <Pagination
        hasPrevious={pageInfo?.hasPreviousPage}
        hasNext={pageInfo?.hasNextPage}
        onPrevious={function (): void {
          setPagination({
            take: -itemsPerPage,
            cursor: pageInfo?.startCursor,
          });
        }}
        onNext={function (): void {
          setPagination({
            take: itemsPerPage,
            cursor: pageInfo?.endCursor,
          });
        }}
      />

      <AdTemplateCreateSlideOverLoader
        isOpen={showAdTemplateCreateSlideOver}
        onClose={() => {
          setShowAdTemplateCreateSlideOver(false);
        }}
        assetId={selectedAssetId}
      />

      <AssetDetailsSlideOverLoader
        isOpen={showAssetDetails}
        onClose={() => {
          setShowAssetDetailsSlideOver(false);
        }}
        assetId={selectedAssetId}
      />

      <AssetDeleteAlert
        showAssetDeleteAlert={showAssetDeleteAlert}
        setShowAssetDeleteAlert={setShowAssetDeleteAlert}
        deleteAssetHandler={deleteAssetHandler}
        selectedAssetId={selectedAssetId}
        assetDeleteLoading={assetDeleteLoading}
      />
    </div>
  );
}
