import styles from "./InterestsAndDemographicsSelector.module.scss";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-400/outlined/info.svg";
import { SwitchSelectionTable } from "./SwitchSelectionTable";
import Tooltip from "src/components/Tooltip/Tooltip";
import { MetaInterest } from "src/graphql/generated/schema";
import { titleCase } from "src/lib/titleCase";
import { useFormikContext } from "formik";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceWorkshop";
import interestIcon from "../assets/interestIcon.svg";
import demographicIcon from "../assets/demographicIcon.svg";
import behaviorIcon from "../assets/behaviourIcon.svg";
import { SelectorNoResults } from "./SelectorNoResults";

interface IInterestsAndDemographicsSelector {
  interests: MetaInterest[];
  onShowSelection?: () => void;
  shouldShowSelection: boolean;
}

export function InterestsAndDemographicsSelector({
  interests,
  shouldShowSelection,
  onShowSelection,
}: IInterestsAndDemographicsSelector) {
  const { values, setFieldValue } =
    useFormikContext<AudiencePresetAndInterestSelectorFormState>();

  function handleChange(newValue, item: { id: string }) {
    const interest = interests.find((i) => i.id === item.id);

    setFieldValue("interests", {
      ...values.interests,
      [item.id]: {
        value: newValue,
        audienceSizeLowerBound: interest.audienceSizeLowerBound,
        audienceSizeUpperBound: interest.audienceSizeUpperBound,
        name: interest.name,
        id: interest.id,
        metaInterestId: interest.id,
        description: interest.description,
        path: interest.path,
        type: interest.type,
      },
    });
  }

  const icons = {
    interests: (
      <img src={interestIcon} width={32} height={32} alt="interest icon" />
    ),
    behaviors: (
      <img src={behaviorIcon} width={32} height={32} alt="interest icon" />
    ),
    others: (
      <img src={demographicIcon} width={32} height={32} alt="interest icon" />
    ),
  };

  return (
    <div className={styles.wrapper}>
      <SwitchSelectionTable
        noItemsContent={<SelectorNoResults />}
        onShowSelection={onShowSelection}
        shouldShowSelection={shouldShowSelection}
        // fixed width for the switcher; else the header (which is empty) will not align
        gridTemplate="3fr 1fr 69px"
        headers={[
          "Interest, Behaviour & Demographics",
          <span className={styles.source}>
            Source
            <Tooltip supportingText="Some text">
              <InfoIcon width={16} height={16} />
            </Tooltip>
          </span>,
          "",
        ]}
        items={interests.map((interest) => ({
          description: interest.path.join(" > "),
          icon: icons[interest.type] ?? icons.others,
          id: interest.id,
          name: interest.name,
          otherRows: [titleCase(interest.type, "_")],
          title: interest.name,
        }))}
        onChange={handleChange}
        values={values.interests}
      />
    </div>
  );
}
