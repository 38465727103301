import { CampaignInput } from "./createCampaignTypes";
import { setIn } from "formik";
import * as Yup from "yup";

export async function validateSelectedActivity(
  schema: Yup.AnyObjectSchema,
  values: CampaignInput
) {
  const selectedActivity = values.Activities[values.selectedActivityIndex];
  try {
    await schema.validate(selectedActivity, {
      abortEarly: false,
      context: {
        selectedActivity,
      },
    });
    return null;
  } catch (error) {
    // handle only in case of validation error
    if (error instanceof Yup.ValidationError) {
      const errorInnerFormatted = error.inner.map((v) => ({
        key: v.path,
        error: v.errors?.[0],
      }));
      const errors = errorInnerFormatted.reduce(
        (acc, v) =>
          (acc = setIn(
            acc,
            `Activities[${values.selectedActivityIndex}].${v.key}`,
            v.error
          )),
        {}
      );

      return errors;
    }

    console.log(error);

    throw new Error("Unknown error occurred");
  }
}
