import { Input } from "src/components/Input/Input";
import { AudienceSelectorLoader } from "./AudienceSelectorLoader";
import styles from "./AudienceSelector.module.scss";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import defaultSortOption from "src/modules/global/misc/defaultSortOptions";
import { ReactComponent as FilterIcon } from "@material-symbols/svg-600/rounded/filter_list.svg";
import { ReactComponent as SearchIcon } from "@material-symbols/svg-600/rounded/search.svg";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import { useContext, useState } from "react";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import { Form, Formik, FormikHelpers } from "formik";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { AudienceBudget } from "./AudienceBudget";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import * as Yup from "yup";
import { adsetBudgetSchema } from "../misc/adsetBudgetSchema";
import { Tabs } from "src/components/Tabs/Tabs";
import { RetargetingAudienceList } from "./RetargetingAudienceList";
import { audienceCategories } from "src/modules/global/misc/audienceCategories";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import { FormikError } from "src/components/FormikError/FormikError";
import Dropdown from "src/components/Dropdown/Dropdown";
import { InputV2 } from "src/components/InputV2/InputV2";
import { SelectButton } from "src/components/SelectButton/SelectButton";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";
import AuthContext from "src/stores/AuthContext";

const audienceSelectorSchema = Yup.object({
  Adsets: Yup.array(
    Yup.object({
      budget: adsetBudgetSchema,
      type: Yup.string()
        .oneOf(["AUDIENCE", "AUDIENCE_SET", "PRODUCT_RETARGET"])
        .required("type is required"),
      audienceId: Yup.string().nullable(),
      audienceSetId: Yup.string().nullable(),
      ProductSetSpecs: Yup.array().nullable(),
      promotedProductSetId: Yup.string().nullable(),
      templateId: Yup.string().required("template id is required"),
    }),
  )
    .required("Select atleast one audience")
    .min(1, "Select atleast one adset")
    .max(10, "Can't select more than 10 adsets"),
});

const tabOptions = [
  {
    label: "Static Audiences",
    value: "STATIC_AUDIENCES",
  },
  {
    label: "Retargeting Audiences",
    value: "RETARGETING_AUDIENCES",
  },
];

export function AudienceSelector({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);
  const [selectedFilters, setSelectedFilters] = useState({
    searchQuery: "",
    date: "ALL_TIME" as DateFilter,
    platform: "META" as const,
    audienceCategoryId: null,
    take: 25,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "DESC" | "ASC",
    field: "CREATED_AT" as const,
  });
  const { activeStepIndex, data, markStepAsDone } =
    useContext(StepWizardContext);
  const { activeClient } = useContext(AuthContext);

  return (
    <Formik
      validationSchema={audienceSelectorSchema}
      enableReinitialize
      initialValues={data as ActivityInputV2}
      onSubmit={(
        values: ActivityInputV2,
        formikHelpers: FormikHelpers<ActivityInputV2>,
      ) => {
        // check the minimum ad budget
        // $1 for dollars
        // 100 inr
        for (let [index, adset] of values.Adsets.entries()) {
          console.log({ activeClient });

          if (activeClient.currencyCode === "USD" && Number(adset.budget) < 1) {
            formikHelpers.setFieldError(
              `Adsets[${index}].budget`,
              "Daily budget should be more than or equal to $1",
            );
            return;
          }

          if (
            activeClient.currencyCode !== "USD" &&
            Number(adset.budget) < 100
          ) {
            formikHelpers.setFieldError(
              `Adsets[${index}].budget`,
              "Daily budget should be more than or equal to ₹100",
            );
            return;
          }
        }

        markStepAsDone(3, values);
      }}
    >
      {({
        values,
        errors,
        submitCount,
        touched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 2}
            isCompleted={activeStepIndex > 2}
          >
            <section ref={sectionRef} className={styles.section}>
              <div>
                <h2 className={styles.heading}>
                  Select Audiences for marketing activity
                </h2>
                <h3 className={styles.subheading}>
                  Select audience whom you want to target for your marketing
                  activity
                </h3>
              </div>

              <div className={styles.filters}>
                <InputV2
                  value={selectedFilters.searchQuery}
                  onChange={(value) => {
                    setSelectedFilters({
                      ...selectedFilters,
                      searchQuery: value,
                    });
                  }}
                  icon={SearchIcon}
                  placeholder="Search your audience here"
                />
                <SortByDropdown
                  setSortBy={setSelectedSortBy}
                  sortBy={selectedSortBy}
                  sortOptions={defaultSortOption}
                />
                <SelectButton
                  size="small"
                  icon={FilterIcon}
                  label="Filter"
                  value={selectedFilters.audienceCategoryId}
                  onChange={(value) => {
                    if (!value) {
                      setSelectedFilters({
                        ...selectedFilters,
                        audienceCategoryId: null,
                      });
                      return;
                    }

                    setSelectedFilters({
                      ...selectedFilters,
                      audienceCategoryId: value,
                    });
                  }}
                  options={[
                    {
                      label: "All",
                      value: "",
                    },
                    ...audienceCategories,
                  ]}
                />

                <DateFilterDropdown
                  dateFilter={selectedFilters.date}
                  setDateFilter={(value) => {
                    setSelectedFilters({
                      ...selectedFilters,
                      date: value,
                    });
                  }}
                />
              </div>

              {values.metaPurpose === "SALES_CATALOG" && (
                <div className={styles.tabWrapper}>
                  <Tabs
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    tabs={tabOptions}
                  />
                </div>
              )}

              <div className={styles.audienceBox}>
                <div className={styles.audienceListHeader}>
                  <span>Audience Name</span>
                  <span>Category</span>
                  <span>Est. Size</span>
                  <span className={styles.alignRight}>ROAS</span>
                  <span className={styles.alignRight}>CTR</span>
                </div>

                <div
                  id="scrollTargetForInfiniteScroll"
                  className={styles.audienceList}
                >
                  {selectedTab === "STATIC_AUDIENCES" ? (
                    <AudienceSelectorLoader
                      adAccountId={values.adAccountId}
                      selectedFilters={selectedFilters}
                      selectedSortBy={selectedSortBy}
                    />
                  ) : (
                    <RetargetingAudienceList
                      selectedFilters={selectedFilters}
                    />
                  )}
                </div>
              </div>

              <FormikError
                fieldName="Adsets"
                formikError={errors}
                submitCount={submitCount}
                touched={touched}
              />
            </section>
            <AudienceBudget />
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
