import { Button } from "src/components/Button/Button";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import styles from "./MessageIntegration.module.scss";
import IntegrationSms from "../assets/IntegrationSms.png";
import { useNavigate } from "react-router-dom";

export function MessageIntegration() {
  const navigate = useNavigate();
  return (
    <div>
      <PageHeader
        title={
          <div className={styles.title}>
            <Button
              style="plain"
              size="micro"
              onClick={() => navigate("/settings/integrations")}
            >
              <ArrowBack />
            </Button>
            <h1 className={styles.titleText}>Message Integration</h1>
          </div>
        }
      />
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.text}>
            <h5 className={styles.heading}>No integration required</h5>
            <p className={styles.description}>
              We would like to inform you that we will be utilising our
              shortcode to send SMS to your customers. In order to facilitate
              this process, we kindly request that you create messaging
              templates before sending any communication. Please note that the
              character length for each message is limited to{" "}
              <strong>160 characters</strong>.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <img
              src={IntegrationSms}
              alt="message_integration"
              className={styles.image}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
