interface ResizeImageOptions {
  maxWidthOrHeight: number;
  file: File;
}

export async function resizeImage({
  file,
  maxWidthOrHeight,
}: ResizeImageOptions): Promise<File> {
  // ignore other file types
  if (!file.type.startsWith("image/")) {
    return file;
  }

  // resizing the image
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const image = await fileAsImage(file);
  const originalWidth = image.width;
  const originalHeight = image.height;

  // if size is below the threshold skip resizing
  if (originalWidth < maxWidthOrHeight && originalHeight < maxWidthOrHeight) {
    return file;
  }

  const resizingFactor =
    maxWidthOrHeight / Math.max(originalHeight, originalWidth);

  const canvasWidth = originalWidth * resizingFactor;
  console.log({ canvasWidth });
  const canvasHeight = originalHeight * resizingFactor;
  console.log({ canvasHeight });

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

  // reducing the quality of the image
  const imageUrl = await canvasToFile(canvas, file);

  return imageUrl;
}

function canvasToFile(canvas: HTMLCanvasElement, file: File): Promise<File> {
  return new Promise((resolve, reject) =>
    canvas.toBlob((blob) => {
      if (blob) {
        const resizedFile = new File([blob], file.name, {
          lastModified: file.lastModified,
          type: file.type,
        });

        // showing the compressed image
        resolve(resizedFile);
      }
    }, file.type),
  );
}

function fileAsImage(file: File): Promise<HTMLImageElement> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.addEventListener(
      "load",
      () => {
        var blob = new Blob([reader.result]); // create blob...
        window.URL = window.URL || window.webkitURL;
        var blobURL = window.URL.createObjectURL(blob); // and get it's URL
        var image = new Image();
        image.src = blobURL;
        image.onload = function () {
          // have to wait till it's loaded
          resolve(image); // send it to canvas
        };
      },

      false,
    );

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  });
}
