import styles from "./PlatformIcons.module.scss";
import { CampaignPlatformEnum } from "src/graphql/generated/schema";
import emailIco from "../assets/email.svg";
import messageIco from "../assets/message.svg";
import googleIco from "../assets/google.svg";
import facebookIco from "../assets/metaIco.svg";
import whatsappIco from "../assets/whatsapp.svg";
import classNames from "classnames";

// type Platform = "whatsapp" | "mail" | "message" | "facebook" | "google";
type Platform =
  // | AutomationStepTypeEnum
  CampaignPlatformEnum | "CONDITIONAL";

type PlatformItem = {
  platform: Platform;
  disabled?: boolean;
};

export interface IPlatformIcons {
  platforms: PlatformItem[];
  className?: string;
}

function getIcon(platform: CampaignPlatformEnum | "CONDITIONAL") {
  switch (platform) {
    case "WHATSAPP":
      return <img src={whatsappIco} width={22} height={22} />;
    case "EMAIL":
      return <img src={emailIco} width={22} height={22} />;
    case "TEXT_MESSAGE":
      return <img src={messageIco} width={22} height={22} />;
    case "GOOGLE":
      return <img src={googleIco} width={22} height={22} />;
    case "META":
      return <img src={facebookIco} width={22} height={22} />;
    case "CONDITIONAL":
      return (
        <img
          src="/imagesAndIcons/Platforms/metaIco.svg"
          width={7}
          height={14}
        />
      );
    default:
      return null;
  }
}

export default function PlatformIcons({
  platforms,
  className,
}: IPlatformIcons) {
  if (platforms.length === 0) {
    return <div>-</div>;
  }
  return (
    <div className={styles.platformContainer}>
      {platforms.slice(0, 5).map((item, index) => (
        <div
          key={index}
          className={classNames(styles.platform, {
            [styles.disabledPlatform]: item.disabled,
          })}
        >
          {platforms.length > 5 && index === 4 ? (
            <img
              src="/imagesAndIcons/Platforms/count.png"
              alt="count"
              width={22}
              height={22}
            />
          ) : (
            getIcon(item.platform)
          )}
        </div>
      ))}
    </div>
  );
}
