import {
  AudienceInput,
  FACEBOOK_AUDIENCE_SOURCES,
} from "src/modules/audience/components/CreateAudienceInput";
import { getIn, useFormikContext } from "formik";
import { WebsiteEngagerForm } from "./WebsiteEngagerForm";
import { FacebookSegmentBasedAudienceForm } from "./FacebookSegmentBasedAudienceForm";
import FacebookEngagersForm from "./FacebookEngagersForm";

interface Props {
  selectedAudienceIndex: number;
  setActiveStep: (i: number) => void;
  activeStep: number;
}
export default function PlatformBasedAudienceForm({
  selectedAudienceIndex,
  setActiveStep,
  activeStep,
}: Props) {
  const { values } = useFormikContext<AudienceInput>();
  const selectedAudienceSourceKey = `audiences[${selectedAudienceIndex}].audienceSource`;
  const selectedAudienceSourceValue: FACEBOOK_AUDIENCE_SOURCES = getIn(
    values,
    selectedAudienceSourceKey
  );

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  if (selectedAudienceSourceValue === "FACEBOOK_PIXEL_BASED")
    return (
      <WebsiteEngagerForm
        selectedAudienceIndex={selectedAudienceIndex}
        handleBack={handleBack}
      />
    );

  if (
    selectedAudienceSourceValue === "FACEBOOK_ENGAGERS" ||
    selectedAudienceSourceValue === "INSTAGRAM_ENGAGERS"
  )
    return (
      <FacebookEngagersForm
        selectedAudienceIndex={selectedAudienceIndex}
        handleBack={handleBack}
      />
    );

  if (selectedAudienceSourceValue === "FACEBOOK_SEGMENT_BASED") {
    return (
      <FacebookSegmentBasedAudienceForm
        handleBack={handleBack}
        selectedAudienceIndex={selectedAudienceIndex}
      />
    );
  }
  return <></>;
}
