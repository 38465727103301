import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./Card.module.scss";

export interface ICard {
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  primaryAction?: { label: string; onClick: () => void };
  children: ReactNode;
  size?: "default" | "small";
  style?: any;
}

export default function Card(props: ICard) {
  const {
    title,
    subtitle,
    disabled,
    size = "default",
    primaryAction,
    children,
    style,
  } = props;

  return (
    <div
      style={style}
      className={classNames(styles.card, {
        [styles.defaultSize]: size === "default",
        [styles.smallSize]: size === "small",
      })}
    >
      {title && (
        <div>
          <div className={styles.primary}>
            <h3 className={styles.title}>{title}</h3>
            {primaryAction && (
              <button
                type="button"
                className={styles.primaryAction}
                onClick={primaryAction.onClick}
              >
                {primaryAction.label}
              </button>
            )}
          </div>
          <h4 className={styles.subtitle}>{subtitle}</h4>
        </div>
      )}
      {children}
      {disabled && <div className={styles.disabled} />}
    </div>
  );
}
