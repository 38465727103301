import {
  CustomerDocument,
  useCustomerTagsRemoveMutation,
} from "src/graphql/generated/schema";
import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { ReactComponent as Cancel } from "@material-design-icons/svg/outlined/close.svg";
import { IconButton } from "src/components/IconButton/IconButton";
import styles from "./MoreInformation.module.scss";
import mapImage from "../assets/map.svg";
import { formatNumberOrDefault } from "src/lib/formatNumberOrDefault";
import { addCommas } from "src/lib/addCommas";

interface Props {
  // todo: fix autogenerated types
  customer: any;
  setShowAddTagsModal: (i: boolean) => void;
}

export function MoreInformation({ customer, setShowAddTagsModal }: Props) {
  const personalDetails = [
    {
      label: "NAME",
      value: customer?.name,
    },
    {
      label: "LTV",
      value: customer?.lifetimeValue ? addCommas(customer?.lifetimeValue) : "-",
    },
    {
      label: "CITY",
      value: customer?.Address?.city,
    },
    {
      label: "Country",
      value: customer?.Address?.country,
    },
    {
      label: "EMAIL",
      value: customer?.email,
    },
    {
      label: "PHONE",
      value: customer?.phone,
    },
  ];
  const [delTagFunc, { data: delData, loading: delLoading }] =
    useCustomerTagsRemoveMutation();

  return (
    <div className={styles.sidebar}>
      <div className={styles.mapContainer}>
        <img src={mapImage} alt="map" className={styles.map} />
        <div
          className={styles.pointer}
          style={{ top: "2rem", right: "2rem" }}
        ></div>
      </div>
      <div className={styles.sidebarInfo}>
        <h2 className={styles.sidebarTitle}>More Information</h2>
        <div className={styles.personalDetails}>
          {personalDetails.slice(0, 4).map(({ label, value }) => (
            <ValueWithLabel {...{ label, value }} />
          ))}
        </div>
        {personalDetails.slice(4).map(({ label, value }) => (
          <ValueWithLabel {...{ label, value }} />
        ))}
        <div className={styles.valueItem}>
          <span className={styles.heading}>TAGS</span>
          <ul className={styles.tags}>
            {customer.Tags.map((item, index) => (
              <li key={index} className={styles.tag}>
                <span className={styles.tagText}>{item.name}</span>
                {console.log(item)}
                <button
                  type="button"
                  className={styles.cancelBtn}
                  onClick={async () =>
                    await delTagFunc({
                      variables: { customerId: customer.id, ids: item.id },
                      refetchQueries: [
                        {
                          query: CustomerDocument,
                          variables: { id: customer.id },
                        },
                      ],
                      awaitRefetchQueries: true,
                    })
                  }
                >
                  <Cancel className={styles.cancelIco} />
                </button>
              </li>
            ))}
            <IconButton
              icon={<Add />}
              color="subdued"
              style="outline"
              onClick={() => setShowAddTagsModal(true)}
            />
          </ul>
        </div>
        <div className={styles.valueItem}>
          <span className={styles.heading}>USER SEGMENTS</span>
          <div>{customer?.Segments?.map((v) => v.name).join(" ,")}</div>
        </div>
      </div>
    </div>
  );
}

function ValueWithLabel({ label, value }) {
  return (
    <div className={styles.valueItem}>
      <span className={styles.heading}>{label}</span>
      <p className={styles.value}>{value ? value : "-"}</p>
    </div>
  );
}
