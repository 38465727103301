import { ReactComponent as ProgressActivityIcon } from "@material-symbols/svg-400/rounded/progress_activity.svg";
import { ReactComponent as Scheduled } from "@material-symbols/svg-400/rounded/clock_loader_60.svg";
import { ReactComponent as Info } from "@material-symbols/svg-400/rounded/info.svg";
import { ReactComponent as Edit } from "@material-symbols/svg-400/rounded/edit.svg";
import { ReactComponent as Cancel } from "@material-symbols/svg-400/rounded/cancel.svg";
import { ReactComponent as Sweep } from "@material-symbols/svg-400/rounded/sweep.svg";
import { ReactComponent as DoNotDisturb } from "@material-design-icons/svg/outlined/do_not_disturb_on.svg";
import { ReactComponent as Check } from "@material-design-icons/svg/outlined/check.svg";
import classNames from "classnames";
import styles from "./CampaignBadgeDropdownOptions.module.scss";

export const campaignBadgeDropdownOptions = [
  {
    status: "ALL_ACTIVE",
    label: "Active",
    editable: true,
    dropdownOptions: [
      {
        label: "Active",
        value: "ACTIVE",
      },
      {
        label: "Pause",
        value: "PAUSED",
      },
    ],
    icon: Check,
  },
  {
    status: "SOME_ACTIVE",
    label: "Active",
    editable: false,
    icon: Info,
  },
  {
    status: "ALL_SCHEDULED",
    label: "Scheduled",
    editable: true,
    dropdownOptions: [
      {
        label: "Cancel",
        value: "CANCEL",
      },
    ],
    icon: Scheduled,
  },
  {
    status: "SOME_SCHEDULED",
    label: "Scheduled",
    editable: false,
    icon: Info,
  },
  {
    status: "ALL_PAUSED",
    label: "Inactive",
    editable: true,
    dropdownOptions: [
      {
        label: "Active",
        value: "ACTIVE",
      },
      {
        label: "Inactive",
        value: "PAUSED",
      },
    ],
    icon: DoNotDisturb,
  },
  {
    status: "SOME_PAUSED",
    label: "Inactive",
    editable: false,
    icon: DoNotDisturb,
  },
  {
    status: "ALL_ARCHIVED",
    label: "Canceled",
    editable: false,
    icon: Cancel,
  },
  {
    status: "SOME_ARCHIVED",
    label: "Canceled",
    editable: false,
    icon: Cancel,
  },
  {
    status: "ALL_SENT",
    label: "Sent",
    editable: false,
    icon: Sweep,
  },
  {
    status: "SOME_SENT",
    label: "Sent",
    editable: false,
    icon: Cancel,
  },
  {
    status: "ALL_ERROR",
    label: "Failed",
    editable: false,
    icon: Cancel,
  },
  {
    status: "SOME_ERROR",
    label: "Failed",
    editable: false,
    icon: Cancel,
  },
  {
    status: "PUBLISHING",
    label: "Publishing",
    editable: false,
    icon: ProgressActivityIcon,
  },
  {
    status: "DRAFT",
    label: "Draft",
    editable: false,
    icon: Edit,
  },
];
