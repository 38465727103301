import {
  AutomationsDocument,
  useAutomationCreateWithTemplateMutation,
  useAutomationTemplatesQuery,
} from "src/graphql/generated/schema";

import Modal from "../../Modal/Modal";
import TemplateSelector from "../../TemplateSelector/TemplateSelector";
import styles from "./SelectAutomationTemplate.module.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";

interface Props {
  showModal: boolean;
  setShowModal: (i: boolean) => void;
}

function getTemplateIcon(name: string) {
  switch (name.toLowerCase()) {
    case "abandoned checkout":
      return "/imagesAndIcons/AbandonedCheckout.svg";
    case "post purchase":
      return "/imagesAndIcons/PostPurchase.svg";
    case "welcome series":
      return "/imagesAndIcons/Subscribe.svg";
    case "customer winback":
      return "/imagesAndIcons/CustomerWinback.svg";
    case "first purchase upsell":
      return "/imagesAndIcons/FirstPurchase.svg";
    default:
      return "icon";
  }
}

export function SelectAutomationTemplate({ showModal, setShowModal }: Props) {
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const { data, loading, error } = useAutomationTemplatesQuery();
  const navigate = useNavigate();

  const [automationCreateFunc, { loading: automationCreateLoading }] =
    useAutomationCreateWithTemplateMutation();

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading) {
    return <Spinner height="screen" />;
  }

  const automationTemplatesList = data.automationTemplates.map((template) => ({
    ...template,
    icon: getTemplateIcon(template.title),
  }));

  return (
    <Modal
      title="Choose template for Automation"
      subtitle="Start with these flows to increase revenue and engagement with little effort"
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      maxWidth="1236px"
    >
      <TemplateSelector
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        primaryAction={{
          children: "Use this template",
          loading: automationCreateLoading,
          disabled: automationCreateLoading,
          onClick: async (selectedTemplateData) => {
            const { data } = await automationCreateFunc({
              variables: {
                input: {
                  templateId: selectedTemplateData.id,
                },
              },
              refetchQueries: [
                {
                  query: AutomationsDocument,
                  variables: {
                    sortBy: {
                      field: "CREATED_AT",
                      direction: "DESC",
                    },
                  },
                },
              ],
            });
            setShowModal(false);
            navigate(`/automations/${data.automationCreateWithTemplate.id}`);
          },
        }}
        templatesList={automationTemplatesList}
        footer={
          <>
            <h4 className={styles.integrationText}>Integration require:</h4>
            <div>
              <img
                src="/imagesAndIcons/ShopifyIco.svg"
                alt="shopify_icon"
                width={24}
                height={24}
                className={styles.footerImg}
              />
            </div>
          </>
        }
      >
        <div className={styles.descriptionContainer}>
          <h5 className={styles.subHeading}>Description</h5>
          <p className={styles.description}>
            {selectedTemplate?.description ||
              automationTemplatesList[0].description}
          </p>
        </div>
        <div>
          <h5 className={styles.subHeading}>Flow</h5>
          {/* TODO: preview diagram to be added here */}
          <div className={styles.preview}></div>
        </div>
      </TemplateSelector>
    </Modal>
  );
}
