import { Button } from "src/components/Button/Button";
import styles from "./ContactSales.module.scss";

export function ContactSales() {
  return (
    <div>
      <p className={styles.title}>Looking for Enterprise Solution?</p>
      <p className={styles.subtitle}>
        Above plans are not according to your requirement? Don't worry you can
        contact us for your specific requirements.
      </p>
      <Button
        size="small"
        className={styles.darkBtn}
        to="mailto:sales@codeword.tech"
      >
        Contact Sales
      </Button>
    </div>
  );
}
