import { ReactComponent as Checked } from "@material-design-icons/svg/filled/check_circle.svg";
import { ReactComponent as Error } from "@material-design-icons/svg/outlined/error.svg";
import { ReactComponent as Cycle } from "@material-design-icons/svg/outlined/cached.svg";
import styles from "./NotificationMsgIcon.module.scss";
import classNames from "classnames";
import {
  NotificationIconTypeEnum,
  NotificationStateEnum,
} from "src/graphql/generated/schema";
import metaNotification from "../assets/metaNotification.svg";
import automationReportNotification from "../assets/automationReportNotification.svg";
import audienceSetNotification from "../assets/audienceSetNotification.svg";
import billingNotification from "../assets/billingNotification.svg";
import campaignNotification from "../assets/campaignNotification.svg";
import clientAnalyticsNotification from "../assets/clientAnalyticsNotification.svg";
import emailIntegrationNotification from "../assets/emailIntegrationNotification.png";
import lookalikeAudiencesNotification from "../assets/lookalikeAudiencesNotification.svg";
import metaIntegrationNotification from "../assets/metaIntegrationNotification.svg";
import organizationClientNotification from "../assets/organizationClientNotification.svg";
import settingsNotification from "../assets/settingsNotification.svg";
import segmentsAutomationNotification from "../assets/segmentsAutomationNotification.svg";
import segmentAudiencesNotification from "../assets/segmentAudiencesNotification.svg";
import segmentAudiencesNotification2 from "../assets/segmentAudiencesNotification2.svg";
import promotionsNotification from "../assets/promotionsNotification.svg";
import platformAudienceNotification from "../assets/platformAudienceNotification.svg";
import othersNotification from "../assets/othersNotification.svg";
import organizationNotification from "../assets/organizationNotification.svg";
import shopifyIntegrationNotification from "../assets/shopifyIntegrationNotification.png";
import websiteIntegrationNotification from "../assets/websiteIntegrationNotification.png";
import emailNotification from "../assets/emailNotification.svg";

interface INotificationMsgIcon {
  icon: NotificationIconTypeEnum;
  status: NotificationStateEnum;
}

export default function NotificationMsgIcon({
  icon: iconType,
  status,
}: INotificationMsgIcon) {
  let icons: Record<NotificationIconTypeEnum, string> = {
    AUDIENCE_SET: audienceSetNotification,
    ANALYTICS: clientAnalyticsNotification,
    AUTOMATED_SEGMENT: segmentsAutomationNotification,
    AUTOMATION: automationReportNotification,
    BILLING: billingNotification,
    CAMPAIGN: campaignNotification,
    CLIENT: organizationClientNotification,
    CUSTOMER: segmentAudiencesNotification2,
    EMAIL_ACTIVITY: emailNotification,
    EMAIL_INTEGRATION: emailIntegrationNotification,
    LOOKALIKE_AUDIENCE: lookalikeAudiencesNotification,
    MACRO_SUGGESTION_OR_PROMOTION: promotionsNotification,
    MANUAL_SEGMENT: segmentAudiencesNotification,
    META_ACTIVITY: metaNotification,
    META_INTEGRATION: metaIntegrationNotification,
    ORGANIZATION: organizationNotification,
    ORGANIZATION_TEAM: organizationNotification,
    OTHERS: othersNotification,
    PLATFORM_AUDIENCE: platformAudienceNotification,
    REPORT: automationReportNotification,
    BUSINESS_HEALTH: automationReportNotification,
    SEGMENT_AUDIENCE: segmentAudiencesNotification,
    SETTINGS_EXCEPT_BILLING: settingsNotification,
    SHOPIFY_INTEGRATION: shopifyIntegrationNotification,
    WEBSITE_INTEGRATION: websiteIntegrationNotification,
  };

  let icon = icons[iconType] ?? othersNotification;

  function getStatusIcon(status: NotificationStateEnum) {
    switch (status) {
      case "SUCCESS":
        return <Checked className={classNames(styles.ico, styles.tickIco)} />;
      case "FAILURE":
        return <Error className={classNames(styles.ico, styles.errorIco)} />;
      case "IN_PROGRESS":
        return <Cycle className={classNames(styles.ico, styles.progressIco)} />;
      default:
        return null;
    }
  }
  return (
    <div className={styles.iconWrapper}>
      <img src={icon} alt="icon" className={styles.image} />
      <span
        className={classNames(styles.statusWrapper, {
          [styles.tickWrapper]: status === "SUCCESS",
          [styles.errorWrapper]: status === "FAILURE",
          [styles.cycleWrapper]: status === "IN_PROGRESS",
        })}
      >
        {getStatusIcon(status)}
      </span>
    </div>
  );
}
