import styles from "./CharacterCounter.module.scss";

interface ICharacterCounter {
  maxCharacterCount: number;
  characterCount?: number;
}

export function CharacterCounter({
  maxCharacterCount,
  characterCount,
}: ICharacterCounter) {
  return (
    <span className={styles.characterCount}>
      {characterCount ?? 0}/{maxCharacterCount}
    </span>
  );
}
