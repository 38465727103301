import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as ExpandMore } from "@material-design-icons/svg/outlined/expand_more.svg";
import classNames from "classnames";
import styles from "./MapColumns.module.scss";

export interface IMapColumnsFieldsSelector {
  value: { label: string; value: string };
  onChange: (s: IMapColumnsFieldsSelector["value"]) => void;
  options: {
    label: string;
    value: string;
  }[];
  onAdd?: (s: any) => void;
  disabled?: boolean;
  showTop?: boolean;
}

export function MapColumnsFieldsSelector({
  value,
  onChange,
  options,
  onAdd,
  showTop = false,
  disabled = false,
}: IMapColumnsFieldsSelector) {
  // const [allOptions, setAllOptions] = useState([]);
  // FIXME: Placement of Dropdown should be automatically decided based on current bounding scroll element

  return (
    <Menu
      as="div"
      className={classNames(styles.menu, {
        [styles.disabled]: disabled,
      })}
    >
      {" "}
      <Menu.Button className={styles.menuBtn}>
        {value.label ?? "-"}
        <ExpandMore />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter={styles.enter}
        enterFrom={styles.enterFrom}
        enterTo={styles.enterTo}
        leave={styles.leave}
        leaveFrom={styles.leaveFrom}
        leaveTo={styles.leaveTo}
      >
        <Menu.Items
          className={classNames(styles.menuContainer, styles.menuSub, {
            [styles.placeTop]: showTop,
          })}
        >
          {options.map((v, i) => (
            <Menu.Item
              as="button"
              className={classNames(styles.menuItem)}
              onClick={() => {
                onChange(v);
              }}
              key={i}
            >
              {v.label ?? ""}
            </Menu.Item>
          ))}

          {/* <Menu.Item
            as="button"
            className={classNames(styles.menuItem)}
            onClick={() => {}}
          >
            Add New Field
          </Menu.Item> */}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
