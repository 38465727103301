import { ReactNode } from "react";
import styles from "./SwitchGroup.module.scss";
import classNames from "classnames";

interface SwitchGroupProps {
  onChange: (value: string) => void;
  value: string;
  noneValue: string;
  options: [
    {
      label: ReactNode;
      value: string;
      selectedBackgroundColor: string;
      selectedColor: string;
    },
    {
      label: ReactNode;
      value: string;
      selectedBackgroundColor: string;
      selectedColor: string;
    }
  ];
}

export function SwitchGroup({
  noneValue,
  onChange,
  value,
  options,
}: SwitchGroupProps) {
  return (
    <span className={styles.switchGroup}>
      {options.map((option) => (
        <button
          type="button"
          style={{
            // @ts-ignore
            "--selected-background-color": option.selectedBackgroundColor,
            "--selected-color": option.selectedColor,
          }}
          className={classNames(styles.button, {
            [styles.selectedButton]: value === option.value,
          })}
          onClick={() => {
            if (value === option.value) {
              onChange(noneValue);
            } else {
              onChange(option.value);
            }
          }}
        >
          {option.label}
        </button>
      ))}
    </span>
  );
}
