import { Checkbox } from "src/components/Checkbox/Checkbox";
import { retargetingAudiences } from "../misc/retargetingAudiences";
import styles from "./RetargetingAudienceList.module.scss";
import { useFormikContext } from "formik";
import { ActivityInputV2, AdsetInputV2 } from "../misc/createCampaignTypes";

export function RetargetingAudienceList({
  selectedFilters,
}: {
  selectedFilters: {
    searchQuery: string;
  };
}) {
  const filteredRetargetingAudiences = retargetingAudiences.filter((a) => {
    const titleLowerCase = a.title.toLowerCase();
    const descriptionLowerCase = a.description.toLowerCase();
    const queryLowerCase = selectedFilters.searchQuery.toLowerCase();

    return (
      titleLowerCase.includes(selectedFilters.searchQuery.toLowerCase()) ||
      descriptionLowerCase.toLowerCase().includes(queryLowerCase)
    );
  });

  return (
    <div className={styles.list}>
      {filteredRetargetingAudiences.map((a) => (
        <RetargetingAudienceItem retargetingAudience={a} />
      ))}
    </div>
  );
}

interface IRetargetingAudienceItem {
  retargetingAudience: (typeof retargetingAudiences)[0];
}

function RetargetingAudienceItem({
  retargetingAudience,
}: IRetargetingAudienceItem) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  const selectedAudiences = values.Adsets ?? [];
  const isSelected = !!selectedAudiences.find(
    (adset) => adset.templateId === retargetingAudience.id,
  );

  function handleChange() {
    if (isSelected) {
      return setFieldValue(
        "Adsets",
        selectedAudiences.filter(
          (s) => s.templateId !== retargetingAudience.id,
        ),
      );
    }

    setFieldValue("Adsets", [
      ...selectedAudiences,
      {
        budget: "",
        type: "PRODUCT_RETARGET",
        audienceId: null,
        audienceSetId: null,
        ProductSetSpecs: retargetingAudience.productSetSpecs,
        promotedProductSetId: null,
        templateId: retargetingAudience.id,
      } as AdsetInputV2,
    ]);
  }

  return (
    <div className={styles.item}>
      <Checkbox
        size="small"
        value={retargetingAudience.id}
        checked={isSelected}
        onChange={handleChange}
      />
      <span
        style={{ backgroundColor: retargetingAudience.iconBackgroundColor }}
        className={styles.icon}
      >
        {retargetingAudience.icon}
      </span>
      <span className={styles.title}>{retargetingAudience.title}</span>
    </div>
  );
}
