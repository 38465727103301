export function highPerformanceDateFormatter(dateInyyyyMMdd: string) {
  let [year, month, date] = dateInyyyyMMdd.split("-");

  // if (month?.startsWith("0")) {
  //   month = month.replace("0", "");
  // }

  // if the year is 2024 remove the first 20 and return the last 24
  let formattedYear = year.slice(2, 4);

  return `${date}/${month}/${formattedYear}`;
}
