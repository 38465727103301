import React from "react";

interface IStepWizardContext<T = any> {
  markStepAsDone: (index: number, data: T) => Promise<void> | void;
  data: T;
  activeStepIndex: number;
  resetAlert: StepWizardResetAlert<T>;
  setResetAlert: (i: StepWizardResetAlert<T>) => void;
}

export type StepWizardResetAlert<TData> = {
  isShowing: boolean;
  fieldKey: string;
  data: TData;
  stepIndex: number;
  fieldValue:
    | string
    | { adAccountId: string; platformAccountId: string; metaName: string }
    | {
        metaPageId: string;
        connectedInstagramAccount: string;
        metaPageName: string;
        metaPagePicture?: string;
      };
};

export const StepWizardContext = React.createContext<IStepWizardContext>(null);

StepWizardContext.displayName = "StepWizardContext";
