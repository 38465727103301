import {
  Activity,
  useCampaignPresetDuplicateMutation,
  useCampaignQuery,
} from "src/graphql/generated/schema";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./CampaignSinglePage.module.scss";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { useMemo, useState } from "react";
import Dropdown from "src/components/Dropdown/Dropdown";
import { ReactComponent as Today } from "@material-design-icons/svg/outlined/today.svg";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { ReactComponent as Error } from "@material-design-icons/svg/filled/error.svg";
import Banner from "src/components/Banner/Banner";
import { CampaignActivityCard } from "../components/CampaignActivityCard";
import { CampaignSingleOverview } from "../components/CampaignSingleOverview";
import { DateFilter as CampaignDateFilter } from "src/modules/global/misc/dateFilterUtils";
import { getTinybirdDateFiltersFromDateFilter } from "../misc/campaignSingleFilters";
import { dateFilterOptions } from "src/modules/global/misc/dateFilterOptions";

export function CampaignSinglePage() {
  const { id } = useParams<"id">();
  const [selectedDate, setSelectedDate] =
    useState<CampaignDateFilter>("ALL_TIME");
  const { dateFrom, dateTo } = useMemo(
    () => getTinybirdDateFiltersFromDateFilter(selectedDate),
    [selectedDate],
  );
  const { data, loading, error } = useCampaignQuery({
    variables: {
      campaignId: id,
    },
  });
  const [campaignPresetDuplicate] = useCampaignPresetDuplicateMutation();
  const navigate = useNavigate();

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const campaign = data.campaign;
  const hasScheduled = campaign.Activities.some((item) => item.scheduledAt);
  const bannerCopy = campaign.Activities.every((item) => item.scheduledAt)
    ? "This campaign is scheduled to be published"
    : "Some of your activities are still in schedule which implies these metrics may be change later";

  return (
    <div className={styles.page}>
      <PageHeader
        back={() => {
          navigate("/campaigns");
        }}
        title={campaign.name}
        actions={
          <>
            <Dropdown
              className={styles.moreActionDropdown}
              items={dateFilterOptions.map((v) => ({
                children: v.label,
                style: "plain",
                onClick: () => setSelectedDate(v.value),
                color: v.value === selectedDate ? "primary" : "normal",
              }))}
              title={
                <span className={styles.dropdownText}>
                  {
                    dateFilterOptions.find((v) => v.value === selectedDate)
                      ?.label
                  }
                  <ArrowDown width={16} height={16} />
                </span>
              }
            />
            <Dropdown
              className={styles.moreActionDropdown}
              items={[
                {
                  children: "Duplicate as New",
                  loading: loading,
                  disabled: loading,
                  async onClick() {
                    const resp = await campaignPresetDuplicate({
                      variables: { campaignId: data.campaign.id },
                    });

                    if (resp.data) {
                      navigate(
                        `/campaigns/new?presetId=${resp.data.campaignPresetDuplicate.id}`,
                      );
                    }
                  },
                },
              ]}
              title={
                <span className={styles.dropdownText}>
                  More Actions
                  <ArrowDown width={16} height={16} />
                </span>
              }
            />
          </>
        }
      />
      {campaign.status.includes("ERROR") && (
        <Banner
          type="error"
          icon={<Error height={17} width={17} />}
          content={
            "There are some critical issues in your activities, please check and fix them"
          }
          action={
            <a
              href={"#campaign_activities"}
              className={styles.viewActivityError}
            >
              View Activity
            </a>
          }
          className={styles.bannerWrapper}
        />
      )}
      {hasScheduled && (
        <Banner
          icon={<Today width={20} height={20} style={{ fill: "#007F5F" }} />}
          content={bannerCopy}
          type="success"
          action={
            <a href={"#campaign_activities"} className={styles.viewActivity}>
              View Activity
            </a>
          }
          className={styles.notify}
        />
      )}
      <div className={styles.main}>
        <CampaignSingleOverview
          dateFrom={dateFrom}
          dateTo={dateTo}
          campaignId={campaign.id}
          campaign={campaign}
        />
        <CampaignSingleActivitiesList
          dateFrom={dateFrom}
          dateTo={dateTo}
          activities={data.campaign.Activities}
        />
      </div>
    </div>
  );
}

function CampaignSingleActivitiesList({
  activities,
  dateFrom,
  dateTo,
}: {
  activities: Pick<
    Activity,
    "id" | "name" | "status" | "platform" | "createdAt" | "publishedAt"
  >[];
  dateFrom?: Date;
  dateTo?: Date;
}) {
  const { id: campaignId } = useParams<"id">();
  return (
    <div className={styles.activitiesList} id="campaign_activities">
      {activities.map((v) => (
        <CampaignActivityCard
          dateFrom={dateFrom}
          dateTo={dateTo}
          activity={v}
          campaignId={campaignId}
        />
      ))}
    </div>
  );
}
