import { AutomationSettingsFooter } from "./AutomationSettingsFooter";
import { AutomationSidebarFooter } from "./AutomationSidebarFooter";
import { AutomationSidebarHeader } from "./AutomationSidebarHeader";
import { ReactComponent as Bolt } from "@material-design-icons/svg/filled/bolt.svg";
import { Button } from "src/components/Button/Button";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { FormValues } from "src/pages/automations/builder/CreateAutomationPage/CreateAutomationPage";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/filled/more_vert.svg";
import classNames from "classnames";
import styles from "./AutomationSignup.module.scss";
import { useFormikContext } from "formik";
import { useState } from "react";

const listItems = [
  "Welcome new signups",
  "Introduce your brand",
  "Turn new contact into new customer",
];

interface Props {
  addItem: any;
}

export function AutomationSignup({ addItem }: Props) {
  const [importContacts, setImportContacts] = useState(false);
  const { setFieldValue, values } = useFormikContext<FormValues>();
  return (
    <div>
      <AutomationSidebarHeader
        onBack={() => {
          setFieldValue("section", "CONTACT");
        }}
        title="Signup"
      />
      <div className={styles.container}>
        <div className={styles.importContacts}>
          <Checkbox
            checked={importContacts}
            value=""
            onChange={() => setImportContacts(!importContacts)}
          />
          <h2 className={styles.importText}>Include imported contacts</h2>
        </div>
        <p className={styles.description}>
          Contacts you've added to your audience can also enter through this
          starting point.
        </p>
        <div className={styles.listContainer}>
          <h3 className={styles.listTitle}>About this Trigger</h3>
          <ul className={styles.list}>
            {listItems.map((item, index) => (
              <li key={index} className={styles.listItem}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <AutomationSidebarFooter>
        <Button
          width="full"
          onClick={() => {
            setFieldValue("section", "EMPTY");
            addItem(
              {
                variant: "attention",
                icon: <Bolt />,
                onClick: () => {},
                action: {
                  label: <MoreVert />,
                  onClick: () => {
                    console.log("Hello");
                  },
                },
                children: <div>When someone Signs up</div>,
              },
              values,
              setFieldValue
            );
          }}
        >
          Create starting point
        </Button>
      </AutomationSidebarFooter>
    </div>
  );
}

export function AutomationDefaultSidebar() {
  return (
    <div>
      <AutomationSidebarHeader title="Macro">
        <div className={styles.metaContainer}>
          <span className={styles.metaBorder}></span>
          <h2 className={styles.dripTitle}>Drip Builder</h2>
        </div>
      </AutomationSidebarHeader>
      <p className={classNames(styles.dripText, styles.container)}>
        Guide each contact's journey by plotting the points they can visit along
        the way.
      </p>
      <AutomationSettingsFooter />
    </div>
  );
}
