import classNames from "classnames";
import { Asset } from "../../../components/Smartphone/Asset";
import { AssetContentType } from "../../../graphql/generated/schema";
import styles from "./TitleWithThumbnail.module.scss";

interface ITitleWithThumbnail {
  thumbnail: {
    uri: string;
    contentType: AssetContentType;
  };
  name: string;
  descriptionItems: string[];
  width?: "full" | "auto";
  size?: "medium" | "small";
}

export function TitleWithThumbnail({
  thumbnail,
  name,
  descriptionItems,
  width = "full",
  size = "medium",
}: ITitleWithThumbnail) {
  return (
    <div
      className={classNames(styles.mediaTitleContainer, {
        [styles.full]: width === "full",
        [styles.small]: size === "small",
      })}
    >
      <Asset
        type={thumbnail.contentType}
        name={name}
        preview={thumbnail.uri}
        className={styles.asset}
      />
      <div className={styles.mediaTitle}>
        <div className={styles.title}>{name}</div>
        <div className={styles.description}>
          {descriptionItems.map((v) => (
            <div className={styles.descriptionItem}>{v}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
