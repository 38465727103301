import {
  BillingPlanCycleEnum,
  CustomerCountRange,
  MonthlyAdSpendRange,
  MonthlyCreditRange,
} from "src/graphql/generated/schema";

export const plans = [
  {
    title: "Standard",
    type: "STANDARD" as const,
    description:
      "Make your marketing go even further by reaching customers through both email and SMS.",
    options: [
      {
        label: "Monthly",
        value: "MONTHLY" as BillingPlanCycleEnum,
      },
      { label: "Annually 20% off", value: "ANNUAL" as BillingPlanCycleEnum },
    ],
  },
  {
    title: "Premium",
    type: "PREMIUM" as const,
    description:
      "Make your marketing go even further by reaching customers through both email and SMS.",
    options: [
      {
        label: "Monthly",
        value: "MONTHLY" as BillingPlanCycleEnum,
      },
      { label: "Annually 20% off", value: "ANNUAL" as BillingPlanCycleEnum },
    ],
  },
];

type priceOption<T> = {
  label: string;
  value: T;
  price: { amount: number; currencyCode: string };
};

export const standardAdSpendOptions: priceOption<MonthlyAdSpendRange>[] = [
  {
    label: "0 - 1,00,000",
    value: "AD_SPENT_1_TO_10000",
    price: { amount: 9_000_00, currencyCode: "INR" },
  },
  {
    label: "1,00,000 - 5,00,000",
    value: "AD_SPENT_10000_TO_100000",
    price: { amount: 18_000_00, currencyCode: "INR" },
  },
  {
    label: "5,00,000 - 10,00,000",
    value: "AD_SPENT_100000_TO_500000",
    price: { amount: 30_000_00, currencyCode: "INR" },
  },
];

export const premiumAdSpendOptions: priceOption<MonthlyAdSpendRange>[] = [
  {
    label: "0 - 1,00,000",
    value: "AD_SPENT_1_TO_10000",
    price: { amount: 15_000_00, currencyCode: "INR" },
  },
  {
    label: "1,00,000 - 5,00,000",
    value: "AD_SPENT_10000_TO_100000",
    price: { amount: 25_000_00, currencyCode: "INR" },
  },
  {
    label: "5,00,000 - 10,00,000",
    value: "AD_SPENT_100000_TO_500000",
    price: { amount: 50_000_00, currencyCode: "INR" },
  },
];

export const standardCustomerCountOptions: priceOption<CustomerCountRange>[] = [
  {
    label: "0 - 5000",
    value: "CUSTOMER_COUNT_1_TO_2500",
    price: { amount: 5_000_00, currencyCode: "INR" },
  },
  {
    label: "5000 - 25,000",
    value: "CUSTOMER_COUNT_2500_TO_10000",
    price: { amount: 15_000_00, currencyCode: "INR" },
  },
  {
    label: "25,000 - 50,000",
    value: "CUSTOMER_COUNT_10000_TO_50000",
    price: { amount: 2500000, currencyCode: "INR" },
  },
];

export const premiumCustomerCountOptions: priceOption<CustomerCountRange>[] = [
  {
    label: "0 - 5000",
    value: "CUSTOMER_COUNT_1_TO_2500",
    price: { amount: 500000, currencyCode: "INR" },
  },
  {
    label: "5000 - 25,000",
    value: "CUSTOMER_COUNT_2500_TO_10000",
    price: { amount: 1500000, currencyCode: "INR" },
  },
  {
    label: "25,000 - 50,000",
    value: "CUSTOMER_COUNT_10000_TO_50000",
    price: { amount: 2500000, currencyCode: "INR" },
  },
];

// export const standardMonthlyCreditOptions: priceOption<MonthlyCreditRange>[] = [
//   {
//     label: "1 - 500",
//     value: "CREDIT_1_TO_500",
//     price: { amount: 900, currencyCode: "INR" },
//   },
//   {
//     label: "500 - 2,000",
//     value: "CREDIT_500_TO_2000",
//     price: { amount: 1800, currencyCode: "INR" },
//   },
//   {
//     label: "2,000 - 10,000",
//     value: "CREDIT_2000_TO_10000",
//     price: { amount: 2700, currencyCode: "INR" },
//   },
// ];

export const premiumMonthlyCreditOptions: priceOption<MonthlyCreditRange>[] = [
  {
    label: "100",
    value: "CREDIT_1_TO_500",
    price: { amount: 9_000_00, currencyCode: "INR" },
  },
  {
    label: "5000 - 25,000",
    value: "CREDIT_500_TO_2000",
    price: { amount: 25_000_00, currencyCode: "INR" },
  },
  {
    label: "25,000 - 50,000",
    value: "CREDIT_2000_TO_10000",
    price: { amount: 50_000_00, currencyCode: "INR" },
  },
];
