import { IButton } from "../Button/Button";
import { SelectedTemplatePreview } from "./SelectedTemplatePreview";
import { TemplateItem } from "./TemplateItem";
import styles from "./TemplateSelector.module.scss";
import { useState } from "react";

export interface Template {
  description: string;
  icon: string;
  id: string;
  subtitle: string;
  title: string;
  [x: string]: any;
}

interface TemplateSelectorPrimaryAction extends IButton {
  onClick: (template: Template) => void;
}

interface ITemplateSelector {
  primaryAction: TemplateSelectorPrimaryAction;
  templatesList: Template[];
  footer: React.ReactNode;
  children: React.ReactNode;
  selectedTemplate: Template;
  setSelectedTemplate: (i: Template) => void;
}

export default function TemplateSelector({
  primaryAction,
  templatesList,
  footer,
  children,
  selectedTemplate,
  setSelectedTemplate,
}: ITemplateSelector) {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.templateItems}>
          {templatesList.map((template, index) => (
            <TemplateItem
              item={template}
              key={index}
              setSelectedTemplate={setSelectedTemplate}
              selectedTemplate={selectedTemplate || templatesList[0]}
            />
          ))}
        </div>
        <div>
          <SelectedTemplatePreview
            selectedTemplate={selectedTemplate || templatesList[0]}
            primaryAction={{
              ...primaryAction,
              onClick: () =>
                primaryAction.onClick(selectedTemplate || templatesList[0]),
            }}
            footerContent={footer}
          >
            {children}
          </SelectedTemplatePreview>
        </div>
      </div>
    </div>
  );
}
