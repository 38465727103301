import styles from "./WhatsappTemplatePreviewSection.module.scss";
import { ReactComponent as AppleLineIcon } from "../assets/appleLine.svg";
import { ReactComponent as AndroidLineIcon } from "../assets/androidLine.svg";
import { useState } from "react";
import { Smartphone } from "src/components/Smartphone/Smartphone";
import { SmartphoneShell } from "src/modules/global/components/SmartphoneShell";
import { TabsV2 } from "src/components/TabsV2/TabsV2";

const previewTabs = [
  {
    label: <AndroidLineIcon width={16} height={16} fill="currentColor" />,
    value: "ANDROID",
  },
  {
    label: <AppleLineIcon width={16} height={16} fill="currentColor" />,
    value: "APPLE",
  },
];

export function WhatsappTemplatePreviewSection() {
  const [selectedTab, setSelectedTab] = useState(previewTabs[0].value);

  return (
    <div className={styles.wrapper}>
      <div className={styles.previewContainer}>
        <div className={styles.placementHeader}>
          <div>
            <h3 className={styles.heading}>Message Preview</h3>
            <h4 className={styles.subheading}>
              Change placement by hovering on preview
            </h4>
          </div>
          <div>
            <TabsV2
              tabs={[
                {
                  label: "",
                  icon: (
                    <AppleLineIcon fill="currentColor" width={18} height={18} />
                  ),
                  value: "IOS",
                },
                {
                  icon: (
                    <AndroidLineIcon
                      fill="currentColor"
                      width={18}
                      height={18}
                    />
                  ),
                  label: "",
                  value: "ANDROID",
                },
              ]}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
            />
          </div>
        </div>

        <div className={styles.preview}>
          <SmartphoneShell screenSize="31rem">Hello world</SmartphoneShell>
        </div>
      </div>
    </div>
  );
}
