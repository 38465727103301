import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { ReactComponent as CallSplit } from "@material-design-icons/svg/outlined/call_split.svg";
import { ReactComponent as Logout } from "@material-design-icons/svg/outlined/logout.svg";
import classNames from "classnames";
import styles from "./FlowItem.module.scss";
import { useFormikContext } from "formik";
import { useState } from "react";

export interface IFlowItem {
  variant: "attention" | "new" | "exit" | "info" | "success" | "conditional";
  icon?: React.ReactNode;
  children?: React.ReactNode;
  action?: {
    label: React.ReactNode;
    onClick: () => void;
  };
  onClick?: () => void;
  id?: string | number;
  name?: string;
}

export function FlowItem({
  variant,
  icon,
  children,
  action,
  onClick,
  id,
  name,
}: IFlowItem) {
  // const { setFieldValue } = useFormikContext();
  const [active, setActive] = useState(false);

  if (variant === "new") {
    return (
      <button
        id={String(id)}
        name={name}
        type="button"
        className={classNames(
          styles.flowItem,
          styles[variant],
          styles.default,
          styles.hoverable
        )}
        onBlur={() => {
          setActive(false);
        }}
        onClick={(e) => {
          setActive(true);
          // TODO: fix this
          // setFieldValue("selectedItem", {
          //   selectedIndex: id,
          // });
          onClick();
        }}
      >
        {active ? "Add an action or conditions" : <Add />}
      </button>
    );
  }

  if (variant === "exit") {
    return (
      <div
        id={String(id)}
        className={classNames(styles.flowItem, styles[variant], styles.small)}
      >
        <div className={classNames(styles.flowItemIcon, styles[variant])}>
          <Logout />
        </div>
        <div>Exit</div>
      </div>
    );
  }

  if (variant === "conditional") {
    return (
      <div
        id={String(id)}
        className={classNames(styles.flowItem, styles.info, styles.default)}
      >
        <div className={classNames(styles.flowItemIcon, styles.info)}>
          <CallSplit />
        </div>
        <button
          type="button"
          name={name}
          onClick={onClick}
          className={styles.content}
        >
          If/else
        </button>
        {action && <button onClick={action.onClick}>{action.label}</button>}
      </div>
    );
  }

  return (
    <div
      id={String(id)}
      className={classNames(styles.flowItem, styles[variant], styles.default)}
    >
      {icon && (
        <div className={classNames(styles.flowItemIcon, styles[variant])}>
          {icon}
        </div>
      )}
      <button
        type="button"
        name={name}
        onClick={onClick}
        className={styles.content}
      >
        {children}
      </button>
      {action && (
        <button type="button" onClick={action.onClick}>
          {action.label}
        </button>
      )}
    </div>
  );
}
