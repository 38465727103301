import { Button } from "src/components/Button/Button";
import { IconButton } from "src/components/IconButton/IconButton";
import { Label } from "src/components/Label/Label";
import Select from "src/components/Select/Select";
import styles from "./PersonHasDone.module.scss";
import { ReactComponent as Trash } from "@material-symbols/svg-400/rounded/delete.svg";
import { initialConditionState } from "../AutomationConditionSidebar";
import {
  customerActions,
  logicalConditions,
  timeConditions,
} from "../Conditon/SelectionData";

export interface Condition {
  major: string;
  first: string;
  second: string;
  third: string;
}

interface Props {
  personConditions: Condition;
  conditions: Condition[][];
  setConditions: (i: any) => void;
  index: number;
  cindex: number;
  subArray: Condition[];
}

export function PersonHasDone({
  personConditions,
  conditions,
  setConditions,
  index,
  cindex,
  subArray,
}: Props) {
  function updateConditions(position, value) {
    const result = conditions.map((subArray, subIdx) => {
      if (subIdx === cindex) {
        return subArray.map((condition, conIdx) => {
          if (conIdx === index) {
            return { ...condition, [position]: value };
          }
          return condition;
        });
      } else {
        return subArray;
      }
    });
    setConditions(result);
  }

  function handleDelete() {}

  function addCondition() {
    const result = conditions.map((mitem, midx) =>
      midx === cindex ? [...mitem, initialConditionState] : mitem
    );
    setConditions(result);
  }

  return (
    <div className={styles.personChecks}>
      <Label label="Person has done" />
      <div className={styles.selectWrapper}>
        <Select
          placeholder="Choose Metric"
          value={personConditions.first}
          options={customerActions}
          onChange={(e) => {
            updateConditions("first", e);
          }}
        />
      </div>
      <div className={styles.selectWrapper}>
        <Select
          placeholder="at least once"
          value={personConditions.second}
          options={logicalConditions}
          onChange={(e) => {
            updateConditions("second", e);
          }}
        />
      </div>
      <div className={styles.selectWrapper}>
        <Select
          placeholder="over all time"
          value={personConditions.third}
          options={timeConditions}
          onChange={(e) => {
            updateConditions("third", e);
          }}
        />
      </div>

      <div className={styles.addCondition}>
        <Button style="plain" color="primary" onClick={addCondition}>
          Add condition +
        </Button>
        <IconButton
          style="plain"
          icon={<Trash fill="#5C5F62" width={24} height={24} />}
          onClick={() => handleDelete()}
        />
      </div>
    </div>
  );
}
