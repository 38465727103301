import { Asset } from "../../../../graphql/generated/schema";
import { ReactComponent as ThreeDots } from "@material-symbols/svg-600/rounded/more_vert.svg";
import { ReactComponent as ChevronDown } from "@material-symbols/svg-600/rounded/keyboard_arrow_down.svg";
import { ReactComponent as Add } from "@material-symbols/svg-600/rounded/add.svg";
import { MultilineInputFormik } from "../../../../components/MultilineInputFormik/MultilineFormik";
import { InputFormik } from "../../../../components/InputFormik/InputFormik";
import { AddMedia } from "./AddMedia";
import { SelectedAssetItem } from "./SelectedAssetItem";
import styles from "./AdTemplateCreateForm.module.scss";
import { FieldArray, FormikContextType, useFormikContext } from "formik";
import * as Accordion from "@radix-ui/react-accordion";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { Button } from "../../../../components/Button/Button";
import classNames from "classnames";
import { FormValues } from "../../misc/adTemplateCreateTypes";
import { FormikError } from "src/components/FormikError/FormikError";
import { getErrorAssetType } from "../../misc/getErrorAssetType";

export function AdTemplateCarouselForm({
  lineItems,
  setFieldValue,
  handleSelect,
}: {
  lineItems: FormValues["LineItems"];
  setFieldValue: FormikContextType<FormValues>["setFieldValue"];
  handleSelect: (assets: Asset[], cardIndex: number) => void;
}) {
  const { errors, submitCount, touched } = useFormikContext();

  return (
    <>
      <MultilineInputFormik
        label="Primary Text*"
        rows={3}
        placeholder="Tip: Primary text should span 2-3 lines at most."
        name={"primaryText"}
      />

      <FieldArray name={"LineItems"}>
        {({ push, remove }) => (
          <>
            {lineItems.map((lineItem, cardIndex) => (
              <Accordion.Root
                className={styles.cardContainer}
                type="single"
                defaultValue="item-1"
                collapsible
              >
                <Accordion.Item value="item-1">
                  <div className={styles.carouselCardHeader}>
                    <span>
                      Card{" "}
                      {cardIndex + 1 > 9 ? cardIndex + 1 : `0${cardIndex + 1}`}
                    </span>
                    <div className={styles.cardActions}>
                      <Dropdown
                        title={<ThreeDots width={20} height={20} />}
                        items={[
                          {
                            children: "Delete",
                            onClick: () => remove(cardIndex),
                            isDestructive: true,
                            disabled: lineItems.length === 1,
                          },
                          {
                            children: "Duplicate",
                            onClick: () =>
                              push({
                                ...lineItem,
                              }),
                          },
                          {
                            children: "New",
                            onClick: () =>
                              push({
                                headline: "",
                                description: "",
                                link: "",
                              }),
                          },
                        ]}
                      />
                      <Accordion.Trigger>
                        <ChevronDown width={20} height={20} />
                      </Accordion.Trigger>
                    </div>
                  </div>
                  <Accordion.Content
                    className={classNames(
                      styles.adSingleSection,
                      styles.paddingTop
                    )}
                  >
                    <InputFormik
                      name={`LineItems[${cardIndex}].headline`}
                      label="Ad Title"
                      placeholder="Write your ad title"
                      showCharacterCount={true}
                      maxCharacterCount={40}
                      width="full"
                    />

                    <InputFormik
                      name={`LineItems[${cardIndex}].description`}
                      label="Ad Description"
                      placeholder="Write your ad description"
                      showCharacterCount={true}
                      maxCharacterCount={125}
                      width="full"
                    />

                    <InputFormik
                      name={`LineItems[${cardIndex}].link`}
                      label="Ad Link"
                      placeholder="https://example.com/product/item"
                      width="full"
                    />

                    <AddMedia
                      onSelect={(assets) => handleSelect(assets, cardIndex)}
                      multiple={false}
                    />
                    <FormikError
                      fieldName={`LineItems[${cardIndex}].${getErrorAssetType(
                        errors,
                        cardIndex
                      )}`}
                      formikError={errors}
                      submitCount={submitCount}
                      touched={touched}
                    />

                    <FormikError
                      fieldName={`LineItems[${cardIndex}].${getErrorAssetType(
                        errors,
                        cardIndex
                      )}.id`}
                      formikError={errors}
                      submitCount={submitCount}
                      touched={touched}
                    />

                    {lineItems[cardIndex].PostAsset && (
                      <SelectedAssetItem
                        onRemove={() => {
                          setFieldValue(
                            `LineItems[${cardIndex}].PostAsset`,
                            null
                          );
                          setFieldValue(
                            `LineItems[${cardIndex}].postAssetId`,
                            null
                          );
                        }}
                        asset={lineItems[cardIndex].PostAsset}
                      />
                    )}
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Root>
            ))}

            <FormikError
              fieldName={`LineItems`}
              formikError={errors}
              submitCount={submitCount}
              touched={touched}
            />

            <div className={styles.itemsCenter}>
              <Button
                width="auto"
                color="normal"
                style="outline"
                type="button"
                size="micro"
                onClick={() => {
                  push({
                    headline: "",
                    description: "",
                    link: "",
                    order: lineItems.length - 1,
                  });
                }}
              >
                <div className={styles.buttonWithIcon}>
                  <span>Add Another Card</span>
                  <Add width={16} height={16} />
                </div>
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
}
