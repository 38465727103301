import { Activity, MetaActivityAd } from "src/graphql/generated/schema";
import styles from "./CampaignActivityCardCreatives.module.scss";
import { useState } from "react";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import speaker from "../assets/brandAwareness.svg";
import magnet2 from "../assets/engagement.svg";
import leads from "../assets/leadConversion.svg";
import { Asset } from "src/components/Smartphone/Asset";
import classNames from "classnames";
import { CampaignActivityCardStatItem } from "./CampaignActivityCardStatItem";
import { useAdAggregatedStats } from "../../global/functions/useAdAggregatedStats";
import { Spinner } from "src/components/Spinner/Spinner";
import { getThumbnailAssetFromAd } from "../functions/getThumbnailAssetFromAd";
import { getAdTypeText } from "../functions/getAdTypeText";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

// MARK: Creative
export function CampaignActivityCardCreatives({
  activity,
  dateFrom,
  dateTo,
}: {
  activity: Activity;
  dateFrom?: Date;
  dateTo?: Date;
}) {
  const metaActivity = activity?.MetaActivity;
  const [selectedAd, setSelectedAd] = useState<string | null>(
    metaActivity?.MetaActivityAds?.[0]?.id,
  );

  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const { data: adStats, loading } = useAdAggregatedStats(selectedAd, {
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  if (loading) {
    return (
      <div>
        <Spinner height={224} />
      </div>
    );
  }

  const stats = [
    {
      label: "Spends",
      value: formatNumberByType(adStats?.spend, "CURRENCY", {
        showDecimal: true,
        selectedCurrencyCode,
      }),
      icon: leads,
    },
    {
      label: "Ad Count",
      value: activity?.MetaActivity?.MetaActivityAds.length,
      icon: leads,
    },
    {
      label: "Reach",
      value: formatNumberByType(adStats?.reach, "NUMBER"),
      icon: magnet2,
    },
    {
      label: "CTR",
      value: formatNumberByType(adStats?.ctr, "PERCENTAGE"),
      icon: speaker,
    },
  ];

  return (
    <div className={styles.activityCardCreativesLayout}>
      <div className={styles.statList}>
        {stats.map((v) => (
          <CampaignActivityCardStatItem {...v} />
        ))}
      </div>
      <div className={styles.creativesList}>
        {metaActivity?.MetaActivityAds.slice(0, 4).map((v) => (
          <CampaignActivityCreativeCard
            ad={v as MetaActivityAd}
            isSelected={selectedAd === v.id}
            setSelectedAd={setSelectedAd}
          />
        ))}
      </div>
    </div>
  );
}

function CampaignActivityCreativeCard({
  ad,
  isSelected,
  setSelectedAd,
}: {
  ad: MetaActivityAd;
  isSelected: boolean;
  setSelectedAd: any;
}) {
  const lineItem = getThumbnailAssetFromAd(ad);

  const adTypeText = getAdTypeText(ad);

  return (
    <button
      type="button"
      onClick={() => {
        setSelectedAd(ad.id);
      }}
      className={classNames(styles.creativeCard)}
    >
      <Asset
        preview={lineItem.uri}
        type={lineItem.contentType}
        name={ad.name}
        loop={false}
        className={classNames(styles.creativeCardAsset)}
      />
      <div
        className={classNames(styles.creativeCardContent, {
          [styles.creativeCardSelected]: isSelected,
        })}
      >
        <span className={styles.adTypeBadge}>{adTypeText}</span>
        {isSelected && <div className={styles.adName}>{ad?.name}</div>}
      </div>
    </button>
  );
}
