import * as Accordion from "@radix-ui/react-accordion";
import {
  AssetContentType,
  MetaActivity,
  MetaActivityAd,
} from "src/graphql/generated/schema";
import styles from "./ActivityAdAccordion.module.scss";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { ReactComponent as ArrowDownIcon } from "@material-symbols/svg-400/rounded/keyboard_arrow_down.svg";
import { ReactComponent as FootstepIcon } from "@material-symbols/svg-400/rounded/footprint.svg";
import { ReactComponent as EyeIcon } from "@material-symbols/svg-400/rounded/visibility.svg";
import { ReactComponent as ClickIcon } from "@material-symbols/svg-400/rounded/web_traffic.svg";
import { ReactComponent as ConversionIcon } from "@material-symbols/svg-400/rounded/cycle.svg";
import { ReactComponent as ConversionAmountIcon } from "@material-symbols/svg-400/rounded/currency_exchange.svg";
import { ReactComponent as MonetizationIcon } from "@material-symbols/svg-400/rounded/monetization_on.svg";
import { ReactComponent as ExpandContent } from "@material-symbols/svg-400/rounded/expand_content.svg";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { Smartphone } from "src/components/Smartphone/Smartphone";
import { formatNumber } from "src/lib/formatNumber";
import Badge from "src/components/Badge/Badge";
import { Asset } from "src/components/Smartphone/Asset";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import { LineItems } from "src/lib/types";
import { Button } from "src/components/Button/Button";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface IActivityAdAccordion {
  ad: MetaActivityAd;
  activity: Pick<
    MetaActivity,
    "productCatalogueId" | "activityType" | "MetaAdAccount"
  >;
  thumbnail: {
    contentType: AssetContentType;
    uri: string;
  };
  lineItems: LineItems[];
  adInsight?: GrowAggregateType;
  compareAdInsight?: GrowAggregateType;
  setSelectedAd: any;
}

function ActivityAdAccordion({
  ad,
  activity,
  adInsight,
  compareAdInsight,
  thumbnail,
  lineItems,
  setSelectedAd,
}: IActivityAdAccordion) {
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const adStats = [
    {
      icon: (
        <FootstepIcon width={24} height={24} fill="var(--color-interactive)" />
      ),
      label: "Reach",
      value: adInsight?.reach ? formatNumber(adInsight.reach) : "-",
    },
    {
      icon: <EyeIcon width={24} height={24} fill="var(--color-interactive)" />,
      label: "Impression",
      value: adInsight?.impressions ? formatNumber(adInsight.impressions) : "-",
    },
    {
      icon: (
        <ClickIcon width={24} height={24} fill="var(--color-interactive)" />
      ),
      label: "Clicks",
      value: adInsight?.clicks ? formatNumber(adInsight.clicks) : "-",
    },
    {
      icon: (
        <ConversionIcon
          width={16}
          height={16}
          fill="var(--color-interactive)"
        />
      ),
      label: "Conversions",
      value: adInsight?.countPurchase
        ? formatNumber(adInsight.countPurchase)
        : "-",
    },
    {
      icon: (
        <ConversionAmountIcon
          width={18}
          height={18}
          fill="var(--color-interactive)"
        />
      ),
      label: "Amount Spend",
      value: adInsight?.spend
        ? formatNumberByType(adInsight.spend, "CURRENCY", {
            showDecimal: true,
            selectedCurrencyCode,
          })
        : "-",
    },
    {
      icon: (
        <MonetizationIcon
          width={18}
          height={18}
          fill="var(--color-interactive)"
        />
      ),
      label: "Cost per click",
      value: adInsight?.cpc
        ? formatNumberByType(adInsight.cpc, "CURRENCY", {
            showDecimal: true,
            selectedCurrencyCode,
          })
        : "-",
    },
  ];
  return (
    <Accordion.Item className={styles.accordionItem} value={ad.id}>
      <Accordion.Trigger className={styles.adHeader}>
        <div className={styles.headerPrimarySection}>
          <Asset
            type={thumbnail.contentType}
            preview={thumbnail.uri}
            name={thumbnail.uri}
            className={styles.thumbnail}
          />
          <div className={styles.name}>
            <div className={styles.title}>{ad.name}</div>
            <div className={styles.description}>
              <span>Ad Type: {snakeCaseToTitleCase(ad.adType)}</span>
              {" | "}
              <span>
                Activity Type: {snakeCaseToTitleCase(activity.activityType)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.headerSecondarySection}>
          <div className={styles.ctrStat}>
            <div className={styles.ctrStatLabel}>CTR</div>
            <div className={styles.ctrValueContainer}>
              <div className={styles.titleValue}>
                {formatNumberByType(adInsight?.ctr, "PERCENTAGE")}
              </div>
              {compareAdInsight?.ctr && (
                <Badge
                  status="success"
                  label={formatNumberByType(
                    compareAdInsight?.ctr,
                    "PERCENTAGE",
                  )}
                />
              )}
            </div>
          </div>
          <ArrowDownIcon
            className={styles.arrowDownIcon}
            height={20}
            width={20}
          />
        </div>
      </Accordion.Trigger>
      <Accordion.Content className={styles.adContent}>
        <section className={styles.container}>
          <div className={styles.reportSection}>
            <div className={styles.reportSectionMain}>
              <h2 className={styles.sectionHeading}>Ads Stats</h2>

              {/* Stats */}
              <div className={styles.stats}>
                {adStats.map((adStat) => (
                  <div className={styles.statItem}>
                    <span className={styles.statIcon}>{adStat.icon}</span>
                    <div className={styles.statContent}>
                      <span className={styles.statLabel}>{adStat.label}</span>
                      <span className={styles.statValue}>{adStat.value}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.expandPreviewButton}>
                <Button
                  size="micro"
                  color="normal"
                  style="outline"
                  onClick={() => {
                    setSelectedAd(ad.id);
                  }}
                >
                  <div className={styles.buttonWithIcon}>
                    <span>Expand preview</span>
                    <ExpandContent height={16} width={16} />
                  </div>
                </Button>
              </div>
            </div>

            {/* footer */}
            <div className={styles.footer}>
              <div className={styles.activityType}>
                <span className={styles.activityTypeLabel}>Activity Type</span>
                <span className={styles.activityTypeValue}>
                  {snakeCaseToTitleCase(activity.activityType)}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.previewSection}>
            <div className={styles.previewSmartphones}>
              <div className={styles.smartphoneWrapper}>
                <Smartphone
                  screenSize="16rem"
                  accountName={activity?.MetaAdAccount?.metaName}
                  adType={ad.adType}
                  callToActionLabel={ad.CTAText}
                  preview={lineItems}
                  primaryText={ad.primaryText}
                  profilePicture={activity?.MetaAdAccount?.Business?.picture}
                  type="facebook"
                  variant="feed"
                />
              </div>

              <div className={styles.smartphoneWrapper}>
                <Smartphone
                  screenSize="16rem"
                  accountName={activity?.MetaAdAccount?.metaName}
                  adType={ad.adType}
                  callToActionLabel={ad.CTAText}
                  preview={lineItems}
                  primaryText={ad.primaryText}
                  profilePicture={activity?.MetaAdAccount?.Business?.picture}
                  type="instagram"
                  variant="stories"
                />
              </div>

              <div className={styles.smartphoneWrapper}>
                <Smartphone
                  screenSize="16rem"
                  accountName={activity?.MetaAdAccount?.metaName}
                  adType={ad.adType}
                  callToActionLabel={ad.CTAText}
                  preview={lineItems}
                  primaryText={ad.primaryText}
                  profilePicture={activity?.MetaAdAccount?.Business?.picture}
                  type="instagram"
                  variant="feed"
                />
              </div>
            </div>
          </div>
        </section>
      </Accordion.Content>
    </Accordion.Item>
  );
}

export default ActivityAdAccordion;
