import * as Yup from "yup";

export const passwordValidationSchema = Yup.string()
  .required("password is required")
  .min(8, "password is too short")
  .matches(
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/g,
    "password should have atleast one capital letter, one number and one special character and no spaces"
  );

export const signupUserFormSchema = Yup.object({
  email: Yup.string()
    .email("Given email is not valid")
    .required("email is required"),
  password: passwordValidationSchema,
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords don't match"
  ),
});

export const facebookIntegrationSchema = Yup.object({
  adAccountId: Yup.string(),
  businessAccountId: Yup.string(),
  businessPageId: Yup.string(),
  facebookPixelId: Yup.string(),
  instagramAccountId: Yup.string(),
  instagramBusinessId: Yup.string(),
  conversionDomain: Yup.string().test(
    "valid-facebook-conversion-domain",
    ({ label }) =>
      label || "Should be a valid conversion domain. For example facebook.com",
    (value) => {
      // source : https://regexr.com/3au3g
      const isValidUrl = value.match(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/gm
      );
      if (!isValidUrl) return false;
      if (isValidUrl.length > 0) {
        const hasHttps = value.includes("https");
        if (hasHttps) return false;
        return true;
      }
    }
  ),
});

export const shopifyIntegrationSchema = Yup.object({
  shop: Yup.string()
    .required("Shopname is required")
    .matches(/[\w\d_-]+\.myshopify\.com/g, "Shop is not valid shopify shop"),
});

export const adsetSchema = Yup.object().shape({
  adsets: Yup.array()
    .required("Adsets are required")
    .of(
      Yup.object().shape({
        audienceIds: Yup.array().required("Array is required"),
        budget: Yup.number().required("Budget is required"),
        countries: Yup.array(Yup.string())
          .required("Countries are required to target")
          .min(1),
        gender: Yup.mixed().oneOf(["ALL", "MALE", "FEMALE"]),
        interest: Yup.array(),
        maxAge: Yup.number().required("Max Age is required"),
        minAge: Yup.number()
          .required("Min age is required")
          .min(18, "Minimum Age should not be lower than 18"),
        interestRelation: Yup.mixed().oneOf(["OR", "AND"]),
      })
    ),
});

export const adSchema = Yup.object().shape({
  name: Yup.string().required("Ad name is required field"),
  primaryText: Yup.string().required("Primary text is required field"),
  CTAText: Yup.string().required("Please Select A CTA"),
  LineItems: Yup.array()
    .required("Lineitems are required")
    .min(1, "Atleast one creative is required to make ad")
    .max(10, "Lineitems should not be more than 10")
    .of(
      Yup.object().shape({
        order: Yup.number(),
        headline: Yup.string().required(),
        description: Yup.string()
          .required()
          .max(50, "Description should be less than 50 characters"),
        link: Yup.string().url().required(),
        PromotedObject: Yup.object({
          objectType: Yup.mixed().oneOf(["PRODUCT", "COLLECTION"]),
          platformObjectHandle: Yup.string(),
          platformObjectId: Yup.string(),
          platform: Yup.mixed().oneOf(["SHOPIFY", "WOOCOMMERCE"]),
        })
          .nullable()
          .notRequired(),
        PostAsset: Yup.object({
          // facebookImageHash: Yup.string().nullable(),
          name: Yup.string().required(),
          uri: Yup.string().url(),
          metaVideoId: Yup.string().nullable(),
          contentType: Yup.mixed().oneOf(["IMAGE", "VIDEO"]).required(),
        }).nullable(),
        StoryAsset: Yup.object({
          // facebookImageHash: Yup.string().nullable(),
          name: Yup.string().required(),
          uri: Yup.string().url(),
          metaVideoId: Yup.string().nullable(),
          contentType: Yup.mixed().oneOf(["IMAGE", "VIDEO"]).required(),
        }).nullable(),
      })
    ),
});

export const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Should be valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required to login"),
});

export const organizaitionFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Should be valid email")
    .required("Email is required"),
  name: Yup.string().required("Name is also required"),
});

export const existingPostAdSchema = Yup.object({
  name: Yup.string().required("Name is required field"),
  CTAText: Yup.string().required("Please Select A CTA"),
  existingPostCTALink: Yup.string().required("Link is required field"),
  existingPostId: Yup.string().required("Please select a post"),
});

export const segmentValidation = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  relation: Yup.string().oneOf(["AND", "OR"]).required(),
  conditions: Yup.array().required(),
});

export const activityValidationSchema = Yup.object({
  name: Yup.string().required("Activity Name Is Required"),
  platform: Yup.string().required("Please Select a Platform"),
});

export const emailVerficationSchema = Yup.object({
  otp: Yup.string().min(4).max(7).required(),
});
