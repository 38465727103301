import Avatar from "react-avatar";
import Badge from "src/components/Badge/Badge";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { IntegrationNeedHelp } from "src/modules/global/components/IntegrationNeedHelp";
import styles from "./WebsiteConnected.module.scss";
import { ReactComponent as CheckIcon } from "@material-design-icons/svg/outlined/check.svg";
import globeWithMagnifyGlass from "../assets/globeWithMagnifyGlass.png";

export function WebsiteConnected({
  domain,
  name,
}: {
  name: string;
  domain: string;
}) {
  return (
    <div>
      <PageHeader title="Website Integration" back={"/settings/integrations"} />
      <div className={styles.layout}>
        <section className={styles.websiteConnectedSection}>
          <Avatar name={name} className={styles.avatar} />
          <div>
            <h2 className={styles.websiteTitle}>
              {name}
              <Badge
                leftIconButton={{ icon: CheckIcon }}
                label="Connected"
                status="success"
              />
            </h2>
            <span className={styles.websiteDomain}>
              <a target="_blank" href={`https://${domain}`}>
                {domain}
              </a>
            </span>
          </div>
        </section>

        <section className={styles.moreInfoSection}>
          <h2 className={styles.moreInfoHeading}>
            Check out the following permissions before get started
          </h2>

          <div className={styles.moreInfoBody}>
            <div className={styles.moreInfoContent}>
              <div className={styles.onlineTrackingSection}>
                <h3 className={styles.onlineTrackingHeading}>
                  Online Tracking
                </h3>
                <p className={styles.onlineTrackingContent}>
                  The Macro App Embed allows you to collect helpful information
                  around browsing activity. When enabled, Macro will begin
                  tracking "Active on Site" events, enable onsite forms, and add
                  any additional tracking features configured below. To modify,
                  view your Shopify App Embed setting.
                </p>
              </div>
              <div className={styles.syncShopifySection}>
                <h3 className={styles.syncShopifyHeading}>
                  Sync data from Shopify and Website
                </h3>
                <p className={styles.syncShopifyContent}>
                  Moving forward, new site visitors that subscribe to email
                  marketing through Shopify will be subscribed to your selected
                  Macro list.
                </p>
              </div>
            </div>
            <img
              src={globeWithMagnifyGlass}
              className={styles.moreInfoSideImage}
              alt="globe with magnify class"
            />
          </div>
        </section>

        <div className={styles.footer}>
          <IntegrationNeedHelp />
        </div>
      </div>
    </div>
  );
}
