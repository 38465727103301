// FIXME: remove this icon use material icons
export function ErrorIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M9.99984 14.1667C10.2359 14.1667 10.4339 14.0868 10.5936 13.9271C10.7533 13.7674 10.8332 13.5694 10.8332 13.3333C10.8332 13.0972 10.7533 12.8993 10.5936 12.7396C10.4339 12.5799 10.2359 12.5 9.99984 12.5C9.76373 12.5 9.56581 12.5799 9.40609 12.7396C9.24637 12.8993 9.1665 13.0972 9.1665 13.3333C9.1665 13.5694 9.24637 13.7674 9.40609 13.9271C9.56581 14.0868 9.76373 14.1667 9.99984 14.1667ZM9.1665 10.8333H10.8332V5.83333H9.1665V10.8333ZM9.99984 18.3333C8.84706 18.3333 7.76373 18.1146 6.74984 17.6771C5.73595 17.2396 4.854 16.6458 4.104 15.8958C3.354 15.1458 2.76025 14.2639 2.32275 13.25C1.88525 12.2361 1.6665 11.1528 1.6665 10C1.6665 8.84722 1.88525 7.76389 2.32275 6.75C2.76025 5.73611 3.354 4.85417 4.104 4.10417C4.854 3.35417 5.73595 2.76042 6.74984 2.32292C7.76373 1.88542 8.84706 1.66667 9.99984 1.66667C11.1526 1.66667 12.2359 1.88542 13.2498 2.32292C14.2637 2.76042 15.1457 3.35417 15.8957 4.10417C16.6457 4.85417 17.2394 5.73611 17.6769 6.75C18.1144 7.76389 18.3332 8.84722 18.3332 10C18.3332 11.1528 18.1144 12.2361 17.6769 13.25C17.2394 14.2639 16.6457 15.1458 15.8957 15.8958C15.1457 16.6458 14.2637 17.2396 13.2498 17.6771C12.2359 18.1146 11.1526 18.3333 9.99984 18.3333Z" />
      </g>
    </svg>
  );
}
