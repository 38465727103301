import styles from "./SectionHeading.module.scss";

interface ISectionHeading {
  heading: React.ReactNode;
  description?: React.ReactNode;
}

export function SectionHeading({ heading, description }: ISectionHeading) {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{heading}</h2>
      <p className={styles.description}>{description}</p>
    </div>
  );
}
