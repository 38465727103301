import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { choosePlatformAndActivitySchema } from "../misc/choosePlatformAndActivitySchema";
import { CampaignInput } from "../misc/createCampaignTypes";
import { CampaignCreateForm } from "./CampaignCreateForm";
import { ChooseMetaObjectiveAndSubtypes } from "./ChooseMetaObjectiveAndSubtypes";

interface IChooseMetaObjectiveAndSubtypesForm {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => unknown;
}

export function ChooseMetaObjectiveAndSubtypesForm({
  campaignInput,
  handleBack,
  campaignPresetUpsert,
}: IChooseMetaObjectiveAndSubtypesForm) {
  const selectedActivityIndex = campaignInput.selectedActivityIndex ?? 0;
  const { currentNode } = useWizardContext<CampaignInput>();

  return (
    <CampaignCreateForm
      schema={choosePlatformAndActivitySchema}
      campaignInput={campaignInput}
      campaignPresetUpsert={campaignPresetUpsert}
    >
      <ChooseMetaObjectiveAndSubtypes
        handleBack={handleBack}
        selectedActivityIndex={selectedActivityIndex}
        disabled={currentNode.id !== "chooseObjectiveAndSubtypes"}
      />
    </CampaignCreateForm>
  );
}
