import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import styles from "./ActivityAudienceReporting.module.scss";
import { useMemo } from "react";
import { useAdsetAggregatedStats } from "../../global/functions/useAdsetAggregatedStats";
import { Spinner } from "src/components/Spinner/Spinner";
import { AudienceReportingCard } from "src/modules/global/components/AudienceReportingCard";

interface MetaActivityAdsetSummary {
  id: string;
  Audience?: Pick<
    Audience,
    "id" | "name" | "createdAt" | "MetaInsights" | "type"
  >;
  MetaAudienceSet?: Pick<
    MetaAudienceSet,
    "id" | "name" | "createdAt" | "MetaInsights"
  >;
}

interface IActivityAudienceReporting {
  activityId: string;
  metaActivityAdsets: MetaActivityAdsetSummary[];
  dateFrom?: Date;
  dateTo?: Date;
}

export function ActivityAudienceReporting({
  activityId,
  metaActivityAdsets,
  dateFrom,
  dateTo,
}: IActivityAudienceReporting) {
  const metaActivityAdsetIds = useMemo(
    () => metaActivityAdsets.map((v) => v.id),
    [metaActivityAdsets],
  );

  const { data, loading } = useAdsetAggregatedStats(
    metaActivityAdsetIds,
    {
      dateFrom,
      dateTo,
    },
    activityId,
  );

  if (loading) {
    return (
      <div className={styles.card}>
        <Spinner height={300} />
      </div>
    );
  }
  return (
    <AudienceReportingCard
      title={"Audience Reporting"}
      subtitle={"Overall creative details used in activity for this campaign"}
      metaActivityAdsets={metaActivityAdsets}
      adsetAggregateData={data}
      dateTo={dateTo}
      dateFrom={dateFrom}
      activityId={activityId}
    />
  );
}
