import Table from "src/components/Table/Table";
import styles from "./SegmentCustomerList.module.scss";
import { Pagination } from "src/components/Pagination/Pagination";
import { SegmentSectionHeading } from "./SegmentSectionHeading";
import { useSegmentCustomersQuery } from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { formatNumber } from "src/lib/formatNumber";

const headings = [
  "Customers Name",
  "Email",
  "Ph. number",
  "LTV",
  "City",
  "Country",
  "Subscribed to",
];

export function SegmentCustomerList({ segmentId }: { segmentId: string }) {
  const { data, loading, error } = useSegmentCustomersQuery({
    variables: { ids: [segmentId] },
  });

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading) {
    return <Spinner height="screen" />;
  }

  const customerEdges = data.segmentCustomers.edges;

  const rows = customerEdges.map((c) => [
    c.node.firstName + " " + c.node.lastName,
    c.node.email,
    c.node.phone,
    formatNumber(c.node.lifetimeValue),
    "-",
    "-",
  ]);

  return (
    <div>
      <div className={styles.headingWrapper}>
        <SegmentSectionHeading
          subHeading={"List of customers in this Segment"}
          description={
            "Here are the list of customers which are the member of this segment"
          }
        />
      </div>
      <div className={styles.tableWrapper}>
        <Table headings={headings} rows={rows} rounded />
        <div className={styles.paginationWrapper}>
          <Pagination
            hasPrevious={false}
            hasNext={false}
            onPrevious={function (): void {
              throw new Error("Function not implemented.");
            }}
            onNext={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
    </div>
  );
}
