import { createContext, useContext } from "react";
import { Node, Breadcrumb } from "./wizardTypes";

type WizardContextType<TData> = {
  data: TData;
  setData: (data: TData) => void;
  currentNode: Node<TData>;
  completedNodes: string[];
  markNodeAsComplete: (data: TData) => void;
  navigateToNodeId: (nodeId: string) => void;
  goBack: (data: TData) => void;
  breadcrumbs: Breadcrumb[];
  isCurrentNodeCompleted: boolean;
  resetAlert: WizardResetAlert<TData>;
  setResetAlert: (i: WizardResetAlert<TData>) => void;
  visitedNodes: string[];
};

export type WizardResetAlert<TData> = {
  isShowing: boolean;
  fieldKey: string;
  data: TData;
  fieldValue:
    | string
    | { adAccountId: string; platformAccountId: string; metaName: string }
    | { metaPageId: string; connectedInstagramAccount: string };
};

// using the hook below we cast the value back to its original generic
// can't find a way to directly cast it here
export const WizardContext = createContext<WizardContextType<any>>(undefined);

export function useWizardContext<TData>() {
  const data = useContext(WizardContext);

  return data as WizardContextType<TData>;
}
