import { Button } from "src/components/Button/Button";
import styles from "./LibraryWaitlist.module.scss";
import WaitlistLibrary1 from "../assets/waitlist-library-1.png";
import WaitlistLibrary2 from "../assets/waitlist-library-2.png";
import WaitlistLibrary3 from "../assets/waitlist-library-3.png";
import WaitlistLibrary4 from "../assets/waitlist-library-4.png";
import WaitlistLibrary5 from "../assets/waitlist-library-5.png";
import RunningTrainCarousel from "../components/RunningTrainCarousel";
import { useState } from "react";
import toast from "react-hot-toast";
import { ReactComponent as MagicWand } from "../assets/magicWand.svg";

const slides = Array(10)
  .fill(null)
  .flatMap(() => [
    WaitlistLibrary1,
    WaitlistLibrary2,
    WaitlistLibrary3,
    WaitlistLibrary4,
    WaitlistLibrary5,
  ]);

export default function LibraryWaitlistPage() {
  const [inWaitlist, setInWaitlist] = useState(
    Boolean(localStorage.getItem("in-waitlist")) ?? false
  );
  return (
    <div className={styles.container}>
      <div>
        <span className={styles.iconWithLabel}>
          <MagicWand />
          <span className={styles.comingSoonLabel}>
            Announcing our new feature
          </span>
        </span>
        <h4 className={styles.title}>Product catalogues are coming soon</h4>
        <p className={styles.description}>
          Sync or create your product catalogues in a single place and feed it
          to marketing paltforms like meta
        </p>
        <Button
          title={inWaitlist ? "You are already in waitlist" : ""}
          disabled={inWaitlist}
          onClick={() => {
            toast.success("Successfully joined waitlist");
            setInWaitlist(true);
            localStorage.setItem("in-waitlist", "true");
          }}
        >
          Join Waitlist
        </Button>
      </div>
      <RunningTrainCarousel
        slides={slides}
        slideHeight={220}
        slideWidth={184}
        gap={16}
      />
    </div>
  );
}
