import Card from "src/components/Card/Card";
import { CreateCampaignPageLayout } from "./CreateCampaignPageLayout";
import { SelectFacebookAudiencesLoader } from "./SelectFacebookAudiencesLoader";
import Modal from "src/components/Modal/Modal";
import { AudiencesBudgetForm } from "./AudiencesBudgetForm";
import { useMetaProductCatalogueQuery } from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { useContext } from "react";
import { WizardContext } from "src/modules/global/misc/WizardContext2";
import { SelectFacebookAudiencesForm } from "./SelectFacebookAudiencesForm";

export function SelectFacebookAudiencesStep() {
  const {
    breadcrumbs,
    goBack,
    currentNode,
    data: campaignInput,
    markNodeAsComplete,
  } = useContext(WizardContext);
  const { data, loading, error } = useMetaProductCatalogueQuery({
    variables: {
      id: campaignInput.Activities[campaignInput.selectedActivityIndex]
        .productCatalogueId,
    },
  });

  // if (error) {
  //   return <ErrorHandler error={error} />;
  // }

  if (loading) {
    return <Spinner height={400} />;
  }

  function handleBack() {
    goBack(campaignInput);
  }

  return (
    <CreateCampaignPageLayout
      breadcrumbs={breadcrumbs}
      campaignInput={campaignInput}
      campaignPresetUpsert={markNodeAsComplete}
    >
      <Card>
        <SelectFacebookAudiencesForm
          handleBack={handleBack}
          campaignPresetUpsert={markNodeAsComplete}
          campaignInput={campaignInput}
        />
      </Card>
      <Modal
        title="Select Budget"
        subtitle="Select a daily budget for all your individual audiences"
        maxWidth="1200px"
        isOpen={currentNode.id === "setAudiencesBudget"}
        onClose={handleBack}
        noContainer
      >
        <AudiencesBudgetForm
          handleBack={handleBack}
          footerText="Make sure you add a budget to all audiences before continuing"
          productSets={
            data && data.metaCatalogue ? data.metaCatalogue.ProductSets : []
          }
          campaignInput={campaignInput}
          campaignPresetUpsert={markNodeAsComplete}
        />
      </Modal>
    </CreateCampaignPageLayout>
  );
}
