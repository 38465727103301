import Avatar from "react-avatar";
import { ErrorHandler } from "../../../components/ErrorHandler";
import { Spinner } from "../../../components/Spinner/Spinner";
import { UserInviteCode } from "../../../graphql/generated/schema";
import styles from "./OrganizationInvitesTable.module.scss";
import isAfter from "date-fns/isAfter";
import classNames from "classnames";

interface IOrganizationInvitesTable {
  isCurrentUserAdmin?: boolean;
  error?: any;
  loading?: boolean;
  organizationInvites: UserInviteCode[];
}

export function OrganizationInvitesTable({
  isCurrentUserAdmin = false,
  error,
  loading,
  organizationInvites,
}: IOrganizationInvitesTable) {
  if (error) {
    return (
      <div>
        <ErrorHandler error={error} />
      </div>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.layout}>
      {organizationInvites.map((v) => (
        <InviteItem userInviteCode={v} disableActions={!isCurrentUserAdmin} />
      ))}
    </div>
  );
}

function InviteItem({
  userInviteCode,
  disableActions = false,
}: {
  userInviteCode: UserInviteCode;
  disableActions?: boolean;
}) {
  const isExpired = isAfter(new Date(), new Date(userInviteCode.expiresAt));
  return (
    <div className={styles.itemLayout}>
      <div className={styles.primarySection}>
        <Avatar
          email={userInviteCode.email}
          className={styles.avatar}
          size="24"
        />
        <div className={styles.title}>{userInviteCode.email}</div>
        {/*
        <div className={styles.subtext}>{user.email}</div>
        */}
      </div>
      <div>
        <div className={styles.avatarWithIcon}>
          <Avatar
            name={userInviteCode.Client?.name}
            size="24"
            className={styles.avatar}
          />
          <div className={styles.text}>{userInviteCode?.Client?.name}</div>
        </div>
      </div>
      <div
        className={classNames(styles.actionWrapper, {
          [styles.expired]: isExpired,
        })}
      >
        {isExpired ? "Expired" : "Pending"}
      </div>
    </div>
  );
}
