import getDifferenceInSeconds from "date-fns/differenceInSeconds";

// Only supports max unit of hours
//
export function formatRelativeTime(dateTime: Date, currentDateTime: Date) {
  const differenceInSeconds = getDifferenceInSeconds(currentDateTime, dateTime);
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOURS = 60 * 60;
  const differenceInHours = Math.round(differenceInSeconds / SECONDS_IN_HOURS);
  const differenceInMinutes = Math.round(
    differenceInSeconds / SECONDS_IN_MINUTE
  );

  if (differenceInHours) {
    if (differenceInHours === 1) {
      return `${differenceInHours} hour ago`;
    }

    return `${differenceInHours} hours ago`;
  }

  if (differenceInMinutes) {
    if (differenceInMinutes === 1) {
      return `${differenceInMinutes} minute ago`;
    }

    return `${differenceInMinutes} minutes ago`;
  }

  return "Just Now";
}
