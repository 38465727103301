import { Spinner } from "src/components/Spinner/Spinner";
import {
  useMetaEnableInstagramAccountMutation,
  useMetaInstagramAccountsFromPlatformQuery,
  useMetaRemoveInstagramAccountMutation,
} from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import { useState } from "react";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";
import styles from "./MetaPagesList.module.scss";

export function MetaInstagramAccountsList({ selectedAccountId }) {
  const [selectedInstagram, setSelectedInstagram] = useState<null | string>(
    null,
  );
  const { data, loading } = useMetaInstagramAccountsFromPlatformQuery({
    variables: {
      adAccountId: selectedAccountId,
    },
  });
  const [enableFunc, { loading: enableLoading, error }] =
    useMetaEnableInstagramAccountMutation();
  const [removeFunc, { loading: removeLoading }] =
    useMetaRemoveInstagramAccountMutation();

  if (loading) {
    return <Spinner height="screen" />;
  }

  const metaInstagramAccounts = data?.metaInstagramAccountsFromPlatform
    ? data?.metaInstagramAccountsFromPlatform
    : [];

  if (metaInstagramAccounts.length === 0) {
    return (
      <div className={styles.emptyStateContainer}>
        <MetaIntegrationDetailsEmptyState
          text={
            "No pages found on your account. Please ensure that you have enabled accounts in macro. Enabled accounts should have a page on facebook"
          }
          link={""}
        />
      </div>
    );
  }

  return (
    <div>
      {metaInstagramAccounts.map((instagramAccount) => (
        <div>
          <MetaDetailItem
            isDefault={instagramAccount?.isDefault}
            error={selectedInstagram === instagramAccount.id && error}
            loading={
              selectedInstagram === instagramAccount.id &&
              (enableLoading || removeLoading)
            }
            enableFunc={async () => {
              setSelectedInstagram(instagramAccount.id);
              await enableFunc({
                variables: {
                  input: {
                    adAccountId: selectedAccountId,
                    metaInstagramId: instagramAccount.id,
                  },
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  "metaInstagramAccountsFromPlatform",
                  "metaInstagramAccounts",
                ],
              });
              setSelectedInstagram(null);
            }}
            removeFunc={async () => {
              setSelectedInstagram(instagramAccount.id);
              await removeFunc({
                variables: {
                  input: {
                    adAccountId: selectedAccountId,
                    metaInstagramId: instagramAccount.id,
                  },
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  "metaInstagramAccountsFromPlatform",
                  "metaInstagramAccounts",
                ],
              });
              setSelectedInstagram(null);
            }}
            title={instagramAccount.name}
            isEnabled={instagramAccount.enabled}
            description={`Instagram Account Id : ${instagramAccount?.id}`}
          />
        </div>
      ))}
    </div>
  );
}
