import { useSearchParams } from "react-router-dom";
import ALL_CAMPAIGNS from "src/graphql/allCampaigns";
import CAMPAIGN_PRESETS from "src/graphql/campaignPresets";
import {
  useCampaignPresetCreateMutation,
  useCampaignPresetUpdateMutation,
} from "src/graphql/generated/schema";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";

export function useCampaignPresetUpsert({ presetId }: { presetId: string }) {
  // Initialize router navigate
  const [searchParams, setSearchParams] = useSearchParams();

  // Define mutations
  const [campaignPresetCreate] = useCampaignPresetCreateMutation();
  const [campaignPresetUpdate] = useCampaignPresetUpdateMutation();
  const campaignName = searchParams.get("campaignName");

  // Define the main function which will update or create a preset
  // This function is passed deeper in the campaign flow to be updated on every sub-step
  async function campaignPresetUpsert(values: Record<string, any>) {
    if (presetId) {
      const { data } = await campaignPresetUpdate({
        variables: {
          input: { id: presetId, data: { ...values, campaignName } },
        },
      });
      return data.campaignPresetUpdate;
    }

    const { data } = await campaignPresetCreate({
      variables: { input: { data: { ...values, campaignName } } },
      refetchQueries: [
        {
          query: ALL_CAMPAIGNS,
          variables: {
            filters: {
              limit: itemsPerPage,
            },
            sortBy: {
              direction: "DESC",
              field: "CREATED_AT",
            },
          },
        },
        {
          query: CAMPAIGN_PRESETS,
          variables: {
            filters: {
              limit: itemsPerPage,
            },
            sortBy: {
              direction: "DESC",
              field: "CREATED_AT",
            },
          },
        },
      ],
    });

    // Add presetId to the current URL
    setSearchParams(
      {
        presetId: data.campaignPresetCreate.id,
        ...(campaignName && { campaignName }),
      },
      {
        replace: true,
      },
    );

    return data.campaignPresetCreate;
  }

  return campaignPresetUpsert;
}
