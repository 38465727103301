import styles from "./AudienceSetSinglePage.module.scss";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { useState } from "react";
import { AudienceQuickOverview } from "../components/AudienceQuickOverview";
import { AudienceSingleAbout } from "../components/AudienceSingleAbout";
import { useAudienceSetQuery } from "src/graphql/generated/schema";
import { useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";
import DateFilterDropdown from "../../global/components/DateFilterDropdown";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "../../global/misc/dateFilterUtils";
import { ResourceOverviewLoader } from "../components/ResourceOverviewLoader";
import { useAudienceSetAggregatedStats } from "src/modules/global/functions/useAudienceSetAggregatedStats";
import { AudienceDistribution } from "../components/AudienceDistribution";
import { Dropdown } from "src/modules/global/components/Dropdown";
import { ReactComponent as MoreVerticalIcon } from "@material-symbols/svg-600/rounded/more_vert.svg";

export function AudienceSetSinglePage() {
  const { id } = useParams<{ id: string }>();
  const [dateFilter, setDateFilter] = useState<DateFilter>("ALL_TIME");

  const { data, loading, error } = useAudienceSetQuery({
    variables: {
      id,
    },
  });

  const dateTo = getDateFilterFromFilterEnum(dateFilter)?.lte;
  const dateFrom = getDateFilterFromFilterEnum(dateFilter)?.gte;

  const {
    data: statData,
    loading: statLoading,
    error: statError,
  } = useAudienceSetAggregatedStats(id, {
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  if (loading || statLoading) {
    return <PageLoader />;
  }

  if (error || statError) {
    return <ErrorHandler error={error || statError} />;
  }

  const audienceSet = data.audienceSet;

  return (
    <>
      <PageHeader
        title={data.audienceSet.name}
        back="/audiences"
        actions={
          <div className={styles.headerActions}>
            <DateFilterDropdown
              showLabel={true}
              setDateFilter={setDateFilter}
              dateFilter={dateFilter}
            />
            <Dropdown
              items={[{ children: "Delete Audience", onClick: () => {} }]}
              title={
                <MoreVerticalIcon
                  fill="var(--color-disabled)"
                  width={18}
                  height={18}
                />
              }
            />
          </div>
        }
      />

      <div className={styles.page}>
        <ResourceOverviewLoader
          statData={statData}
          dateFrom={dateFrom}
          dateTo={dateTo}
          audienceId={id}
        />
        <AudienceQuickOverview loading={statLoading} insights={statData} />
        <div>
          <AudienceDistribution
            audienceSetData={audienceSet}
            audienceSetId={id}
          />
        </div>
        <AudienceSingleAbout audience={audienceSet as any} />
      </div>
    </>
  );
}
