import { Input } from "../../../components/Input/Input";
import styles from "./ClientsTableHeader.module.scss";
import { ReactComponent as Search } from "@material-symbols/svg-600/rounded/search.svg";
import SortByDropdown from "../../global/components/SortByDropdown";
import defaultSortOptions from "../../global/misc/defaultSortOptions";
import { FilterInput, SortByInput } from "../../../graphql/generated/schema";
import DateFilterDropdown from "../../global/components/DateFilterDropdown";
import { DateFilter } from "../../global/misc/dateFilterUtils";

export function ClientsTableHeader({
  sortBy,
  filter,
  setSortBy,
  setFilter,
}: {
  sortBy: SortByInput;
  setSortBy: (i: SortByInput) => void;
  filter: any;
  setFilter: (i: any) => void;
}) {
  return (
    <div className={styles.tableHeader}>
      <Input
        icon={Search}
        width={"auto"}
        placeholder="Search client"
        value={filter.name}
        onChange={(value) =>
          setFilter({
            ...filter,
            name: value,
          })
        }
      />
      <SortByDropdown
        sortOptions={defaultSortOptions}
        sortBy={sortBy}
        setSortBy={(i) => {
          setSortBy(i);
        }}
      />
      <DateFilterDropdown
        setDateFilter={function (i: DateFilter): void {
          setFilter({
            ...filter,
            createdAt: i,
          });
        }}
        dateFilter={filter.createdAt}
      />
    </div>
  );
}
