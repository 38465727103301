import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import styles from "./WhatsappTemplateSelector.module.scss";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { Button } from "src/components/Button/Button";
import { ReactComponent as DescriptionIcon } from "@material-symbols/svg-600/rounded/description.svg";
import { WhatsappTemplateFieldsShowcase } from "./WhatsappTemplateFieldsShowcase";
import { WhatsappTemplateEmptyScreen } from "./WhatsappTemplateEmptyScreen";
import { WhatsappTemplatePreviewSection } from "./WhatsappTemplatePreviewSection";
import { WhatsappTemplateSelectorModal } from "./WhatsappTemplateSelectorModal";
import { WhatsappSenderDetails } from "./WhatsappSenderDetails";

export function WhatsappTemplateSelector({ sectionRef }: { sectionRef }) {
  const [isTemplateSelectorModalOpen, setIsTemplateSelectorModalOpen] =
    useState(false);
  const { activeStepIndex, data, markStepAsDone } =
    useContext(StepWizardContext);

  return (
    <Formik
      enableReinitialize
      initialValues={data as ActivityInputV2}
      // FIXME: add validation
      // validationSchema={campaignCreateSchema}
      onSubmit={(values) => {
        markStepAsDone(4, values);
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 3}
            isCompleted={activeStepIndex > 3}
          >
            <section ref={sectionRef} className={styles.section}>
              <div className={styles.header}>
                <div>
                  <h2 className={styles.heading}>
                    Choose the Perfect Ads for your marketing need
                  </h2>
                  <h3 className={styles.subheading}>
                    Marketing creative can be your Ads, Emails, SMS or WhatsApp
                    templates which have created in Library
                  </h3>
                </div>

                {values.WhatsappMessageObject && (
                  <div>
                    <Button
                      onClick={() => {
                        setIsTemplateSelectorModalOpen(true);
                      }}
                      size="small"
                      style="outline"
                      color="subdued"
                    >
                      <span className={styles.messageSelectButtonContent}>
                        <DescriptionIcon
                          fill="var(--color-disabled)"
                          width={16}
                          height={16}
                        />
                        Select Template
                      </span>
                    </Button>
                  </div>
                )}
              </div>

              <WhatsappTemplateSelectorModal
                onSelect={(whatsappTemplate) => {
                  setFieldValue("WhatsappMessageObject", whatsappTemplate);

                  setIsTemplateSelectorModalOpen(false);
                }}
                isOpen={isTemplateSelectorModalOpen}
                onClose={() => setIsTemplateSelectorModalOpen(false)}
              />

              <div className={styles.messageSection}>
                {values.WhatsappMessageObject ? (
                  <>
                    <div className={styles.currentMessageEditor}>
                      <div className={styles.currentMessageEditorHeader}>
                        <h3 className={styles.currentAdHeaderTitle}>
                          This is ad title
                        </h3>
                        <p className={styles.currentMessageEditorSubtitle}>
                          Single Images & Video
                        </p>
                      </div>
                      <WhatsappSenderDetails />

                      <WhatsappTemplateFieldsShowcase />
                    </div>
                  </>
                ) : (
                  <WhatsappTemplateEmptyScreen
                    onSelectTemplate={() =>
                      setIsTemplateSelectorModalOpen(true)
                    }
                  />
                )}

                <WhatsappTemplatePreviewSection />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  disabled={!values.WhatsappMessageObject}
                  size="micro"
                  onClick={handleSubmit}
                >
                  Save & Continue
                </Button>
              </div>
            </section>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
