import styles from "./ChooseFacebookCatalogueFields.module.scss";
import { getIn, useFormikContext } from "formik";
import { ReactComponent as ShoppingBagIcon } from "@material-design-icons/svg/outlined/shopping_bag.svg";
import { RadioGroup } from "@headlessui/react";
import { CampaignInput } from "../misc/createCampaignTypes";
import classNames from "classnames";
import { Label } from "src/components/Label/Label";
import { Button } from "src/components/Button/Button";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { MetaCatalogue } from "src/graphql/generated/schema";

interface IChooseFacebookCatalogueFields {
  catalogues: Pick<
    MetaCatalogue,
    "id" | "name" | "productCount" | "metaCatalogueId"
  >[];
  handleBack: (i: CampaignInput) => unknown;
}

export function ChooseFacebookCatalogueFields({
  catalogues,
  handleBack,
}: IChooseFacebookCatalogueFields) {
  const { setResetAlert, isCurrentNodeCompleted } = useWizardContext();
  const { values, isSubmitting, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<CampaignInput>();
  const { Activities, selectedActivityIndex } = values;

  const name = `Activities[${selectedActivityIndex}].productCatalogueId`;

  const error = getIn(errors, name);
  // if there is no touched it means the value doesn't exits
  const isTouched = getIn(touched, name) ?? false;

  return (
    <>
      <RadioGroup
        value={Activities[selectedActivityIndex].productCatalogueId}
        name={name}
        className={styles.catalogueRadio}
        onBlur={handleBlur}
        onChange={(value) => {
          if (isCurrentNodeCompleted) {
            setResetAlert({
              data: values,
              fieldKey: name,
              fieldValue: value,
              isShowing: true,
            });
            return;
          }

          setFieldValue(name, value);
        }}
      >
        <Label label="Select Catalogue" htmlFor={name}></Label>

        <div className={styles.options}>
          {catalogues.length ? (
            catalogues.map((catalogue, index) => (
              <RadioGroup.Option value={catalogue.metaCatalogueId}>
                {({ checked }) => (
                  <FacebookCatalogueItem
                    checked={checked}
                    catalogue={catalogue}
                  />
                )}
              </RadioGroup.Option>
            ))
          ) : (
            <div className={styles.noCatalogueSection}>
              <span>No catalogues found</span>
            </div>
          )}
        </div>
        {isTouched && error && <span>{error}</span>}
      </RadioGroup>
      <div className={styles.actions}>
        <Button
          disabled={!catalogues.length}
          loading={isSubmitting}
          type="submit"
        >
          Continue
        </Button>
        <Button
          onClick={() => {
            handleBack(values);
          }}
          style="outline"
          color="subdued"
        >
          Back
        </Button>
      </div>
    </>
  );
}

interface IFacebookCatalogueItem {
  catalogue: Pick<MetaCatalogue, "id" | "name" | "productCount">;
  checked: boolean;
}

function FacebookCatalogueItem({ catalogue, checked }: IFacebookCatalogueItem) {
  return (
    <div
      className={classNames(styles.catalogueItem, {
        [styles.catalogueItemChecked]: checked,
      })}
    >
      <div className={styles.catalogueIcon}>
        <ShoppingBagIcon width={24} height={24} fill="currentColor" />
      </div>
      <h3
        className={classNames(styles.catalogueTitle, {
          [styles.catalogueTitleChecked]: checked,
        })}
      >
        {catalogue.name}
      </h3>
      <p className={styles.catalogueDescription}>
        {catalogue.productCount} Products
      </p>
    </div>
  );
}
