import { Link } from "react-router-dom";
import styles from "./CampaignActivities.module.scss";
import { ReactComponent as ArrowRight } from "@material-design-icons/svg/outlined/keyboard_arrow_right.svg";
import SimpleTable from "../../../components/SimpleTable/SimpleTable";
import {
  ActivityStatusEnum,
  CampaignQuery,
  CampaignUpdateStatus,
  useActivityStatusUpdateMutation,
} from "src/graphql/generated/schema";
import { useContext, useMemo, useState } from "react";
import format from "date-fns/format";
import BadgeDropdown from "src/components/BadgeDropdown/BadgeDropdown";
import meta from "../../global/assets/meta.svg";
import google from "../../global/assets/google.svg";
import message from "../../global/assets/message.svg";
import whatsapp from "../../global/assets/whatsapp.svg";
import mail from "../../global/assets/email.svg";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import AuthContext from "src/stores/AuthContext";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { activitiesDefaultBadgeDropdown } from "../misc/activityDefaultBadgeDropdown";
import classNames from "classnames";

const headings = ["Activity", "Status", "Impression", "Clicks", "CTR."];

// interface Option extends IBadgeDropdown {
//   status: ActivityStatusEnum;
// }

// type Options = Option[];

export function CampaignActivities({
  activities,
  campaignId,
}: {
  activities: CampaignQuery["campaign"]["Activities"];
  campaignId: string;
}) {
  const { userToken } = useContext(AuthContext);
  const [selectedRow, setSelectedRow] = useState<null | string>();
  const [activityStatusUpdateFunc, { loading }] =
    useActivityStatusUpdateMutation();

  const { data, error } = useTinybirdQuery(
    "campaign_activity_grow_aggregate",
    userToken,
    {
      campaignId: campaignId,
    }
  );

  const activitiesWithStats = activities.map((activity) => {
    const stats = data ? data.find((d) => d.activityId === activity.id) : null;
    return {
      ...activity,
      Stats: {
        impressions: stats ? stats?.impressions : 0,
        clicks: stats ? stats?.clicks : 0,
        ctr: stats ? stats?.ctr : 0,
      },
    };
  });

  const rows = activitiesWithStats.map((activity) => [
    <ActivityItem
      id={activity.id}
      campaignId={campaignId}
      name={activity.name}
      platform={activity.platform}
      createdAt={activity.createdAt}
      publishedAt={activity.publishedAt}
      scheduledAt={activity.scheduledAt}
    />,

    <BadgeDropdown
      options={activitiesDefaultBadgeDropdown}
      type={activity.status as any}
      loading={loading && activity.id === selectedRow}
      onChange={async function (i: CampaignUpdateStatus) {
        setSelectedRow(activity.id);
        await activityStatusUpdateFunc({
          variables: {
            input: {
              id: activity.id,
              status: i,
            },
          },
        });
      }}
    />,
    formatNumberByType(activity?.Stats?.impressions, "NUMBER"),
    formatNumberByType(activity?.Stats?.clicks, "NUMBER"),
    formatNumberByType(activity?.Stats?.ctr, "PERCENTAGE"),
  ]);

  if (!activities.length) {
    return null;
  }
  return (
    <div id="campaign_activities">
      <div className={styles.activitiesHead}>
        <h2 className={styles.heading}>Activities in this campaign</h2>
        <Link to={"/"} className={styles.viewActivity}>
          View Activity Analytics <ArrowRight className={styles.arrowIco} />
        </Link>
      </div>
      <div>
        <SimpleTable
          headings={headings}
          rows={rows}
          showHeadings={true}
          className={styles.activityTable}
        />
      </div>
    </div>
  );
}

export function ActivityItem({
  createdAt,
  name,
  publishedAt,
  scheduledAt,
  failedAt,
  id,
  campaignId,
  platform,
  status,
}: {
  name: string;
  id: string;
  campaignId: string;
  createdAt: string;
  publishedAt?: string;
  failedAt?: string;
  scheduledAt?: string;
  platform: string;
  status?: ActivityStatusEnum;
}) {
  function getIcon(platform: string) {
    switch (platform) {
      case "WHATSAPP":
        return whatsapp;
      case "EMAIL":
        return mail;
      case "TEXT_MESSAGE":
        return message;
      case "GOOGLE":
        return google;
      case "META":
        return meta;
      case "CONDITIONAL":
        return meta;
      default:
        return null;
    }
  }

  const formattedFailedAt = useMemo(
    () => (failedAt ? format(new Date(failedAt), "dd MMM hh:mm") : null),
    [failedAt]
  );

  const formattedCreatedAt = useMemo(
    () => (createdAt ? format(new Date(createdAt), "dd MMM hh:mm ") : null),
    [createdAt]
  );

  const formattedPublishedAt = useMemo(
    () => (publishedAt ? format(new Date(publishedAt), "dd MMM hh:mm ") : null),
    [publishedAt]
  );

  const formattedScheduledAt = useMemo(
    () => (scheduledAt ? format(new Date(scheduledAt), "dd MMM hh:mm ") : null),
    [scheduledAt]
  );

  let subText: {
    message: string;
    type: "ERROR" | "DRAFT" | "ACTIVE";
  };

  if (failedAt && status === "ERROR") {
    subText = { type: "ERROR", message: `Failed at: ${formattedFailedAt}` };
  } else if (publishedAt) {
    subText = {
      type: "ACTIVE",
      message: `Published at: ${formattedPublishedAt}`,
    };
  } else if (scheduledAt) {
    subText = {
      type: "ACTIVE",
      message: `Scheduled at: ${formattedScheduledAt}`,
    };
  } else {
    subText = { type: "DRAFT", message: `Created at: ${formattedCreatedAt}` };
  }

  return (
    <Link to={`/campaigns/${campaignId}/activities/${id}`}>
      <div className={styles.activityItem}>
        <img
          alt="campaign platform icon"
          src={getIcon(platform)}
          className={styles.image}
        />
        <div>
          <p className={styles.activityTitle}>{name}</p>
          <p
            className={classNames(styles.time, {
              [styles.error]: subText.type === "ERROR",
            })}
          >
            {subText.message}
          </p>
        </div>
      </div>
    </Link>
  );
}
