import {
  DropzoneInputProps,
  DropzoneOptions,
  DropzoneRootProps,
  DropzoneState,
  useDropzone,
} from "react-dropzone";
import EmptyState, {
  IEmptyState,
} from "../../../../components/EmptyState/EmptyState";
import { useEffect, useState } from "react";

import { Button } from "../../../../components/Button/Button";
import FileCard from "./FileCard";
import styles from "./FileUploadPage.module.scss";
import Card from "../../../../components/Card/Card";
import { Link } from "react-router-dom";

export type FileUploadScreens = "UPLOAD" | "LOADING" | "COMPLETED";

type IEmptyStateAction = IEmptyState["action"];

interface FileUploadAction extends Omit<IEmptyStateAction, "onClick"> {}

export interface IFileUploadPage {
  width?: string | number;
  title?: string;
  illustration?: string;
  action?: FileUploadAction;
  sampleFileLink?: string;
  description?: string;
  loading?: boolean;
  /**
   * all accepted files in input. Check [docs](https://react-dropzone.js.org/#section-accepting-specific-file-types) for more.
   *
   * Example
   * `{ 'image/png': ['.png'] }`.
   * */
  accept: DropzoneOptions["accept"];
  disabled?: boolean;
  /**
   * maximum size of file in bytes. default is 1mb .i.e 1024 * 1024
   * */
  maxSize?: number;
  fileUploadScreen: FileUploadScreens;
  files: File[];
  setFiles: (i: File[]) => void;
  onUpload: (file: File[]) => void;
  content?: React.ReactNode;
}

export default function FileUploadPage({
  width = "80%",
  title,
  illustration,
  action,
  sampleFileLink,
  description,
  onUpload,
  loading,
  disabled,
  accept,
  fileUploadScreen: screen,
  maxSize = 1024 * 1024,
  files,
  setFiles,
  content,
}: IFileUploadPage) {
  const {
    fileRejections,
    getRootProps,
    acceptedFiles,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDropAccepted: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
    disabled: disabled,
    maxFiles: 1,
    maxSize: maxSize,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.cardWrapper}>
        <Card>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <div className={styles.heading}>Import customers by CSV</div>
              <div className={styles.subheading}>
                Add your Macro Customers by importing contacts
              </div>
            </div>

            <div className={styles.uploadArea}>
              <UploadAreaContent
                fileUploadScreen={screen}
                title={title}
                illustration={illustration}
                action={action}
                sampleFileLink={sampleFileLink}
                description={description}
                rootProps={getRootProps()}
                inputProps={getInputProps()}
                files={screen === "COMPLETED" ? files : acceptedFiles}
                content={content}
                onUpload={onUpload}
                setFiles={setFiles}
              />
              {fileRejections.length > 0 && (
                <p className={styles.error}>
                  {fileRejections[0].errors[0].message}
                </p>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

interface IUploadAreaContent
  extends Omit<IFileUploadPage, "accept" | "setFiles"> {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  files: File[];
  setFiles: (i: File[]) => void;
  content?: React.ReactNode;
}

function UploadAreaContent({
  title,
  illustration,
  description,
  sampleFileLink,
  action,
  rootProps,
  inputProps,
  fileUploadScreen: screen,
  files,
  onUpload,
  setFiles,
  content,
}: IUploadAreaContent) {
  const [progress, setProgress] = useState(0);
  let progressInterval;

  // HACK: MOCK Loading Progress
  useEffect(() => {
    if (screen === "LOADING") {
      progressInterval = setInterval(() => {
        setProgress((prev) => prev + 10);
      }, 1200);
    } else {
      clearInterval(progressInterval);
      setProgress(0);
    }
    return () => {
      clearInterval(progressInterval);
      setProgress(0);
    };
  }, [screen === "LOADING"]);

  useEffect(() => {
    if (progress === 100) {
      clearInterval(progressInterval);
      setProgress(0);
    }
  }, [progress]);

  if (screen === "UPLOAD" || files.length < 1)
    return (
      <EmptyState title={title} illustration={illustration}>
        <p className={styles.uploadAreaContent}>
          {description}{" "}
          {sampleFileLink && (
            <>
              <a className={styles.link} href={sampleFileLink}>
                Download Sample CSV
              </a>
            </>
          )}
          <div {...rootProps} className={styles.buttonContainer}>
            <input {...inputProps} />
            <Button
              {...action}
              color="primary"
              style="solid"
              width="auto"
              size="small"
              type="button"
            >
              {action.title}
            </Button>
          </div>
        </p>
      </EmptyState>
    );

  if (screen === "LOADING")
    // TODO: improve ui
    return (
      <div className={styles.uploadAreaContentWrapper}>
        <div className={styles.textContent}>{content}</div>
        <div className={styles.fileCardWrapper}>
          {files.map((item, idx) => (
            <FileCard key={idx} {...{ item, showProgress: true, progress }} />
          ))}
        </div>
      </div>
    );

  if (screen === "COMPLETED") {
    // TODO: improve ui
    return (
      <div className={styles.uploadAreaContentWrapper}>
        <div className={styles.textContent}>{content}</div>
        <div className={styles.fileCardWrapper}>
          {files.map((item, idx) => (
            <FileCard
              key={idx}
              {...{ item, showProgress: false }}
              onRemove={() => {
                setFiles(files.filter((file) => item.name !== file.name));
              }}
            />
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <Button style="solid" color="primary" onClick={onUpload}>
            Continue
          </Button>
        </div>
      </div>
    );
  }
}
