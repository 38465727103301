import { useTinybirdQuery } from "src/lib/analyticsApi";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import { Spinner } from "src/components/Spinner/Spinner";
import { LineGraph } from "src/modules/global/components/LineGraph";
import { highPerformanceDateFormatter } from "src/lib/highPerformanceDateFormatter";
import { formatNumber } from "src/lib/formatNumber";
import { SegmentOverviewGraphHeader } from "./SegmentOverviewGraphHeader";
import { transformPerTickInsights } from "src/modules/global/functions/transformPerTickInsights";
import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";
import { GraphNotAvailableYet } from "src/modules/global/components/GraphNotAvailableYet";

export function SegmentOverviewGraph({
  segmentId,
  dateFrom,
  dateTo,
}: {
  segmentId: string;
  dateFrom: string;
  dateTo: string;
}) {
  const { userToken } = useContext(AuthContext);
  const { data, error, loading } = useTinybirdQuery(
    "engage_per_tick",
    userToken,
    {
      segmentId,
      dateFrom,
      dateTo,
    },
  );

  if (error) {
    return <GraphLoadingError error={error} height={300} />;
  }

  if (loading) {
    return <Spinner height={300} />;
  }

  let insights = transformPerTickInsights(data);

  if (!insights.length) {
    return <GraphNotAvailableYet height={300} />;
  }

  return (
    <>
      <SegmentOverviewGraphHeader
        segmentId={segmentId}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
      <LineGraph
        height={300}
        width={500}
        statLabels={[
          // TODO: use platform independent data keys
          { dataKey: "click_link", name: "Clicks", color: "#2E997E" },
          { dataKey: "view_email", name: "Views", color: "#B98900" },
          { dataKey: "send_email", name: "Sends", color: "#4FBCC4" },
          {
            dataKey: "unsubscribe_email",
            name: "Unsubscribe",
            color: "red",
          },
        ]}
        statValues={insights}
        xAxis={{
          dataKey: "tickDate",
          label: "Timeline",

          tickFormatter: highPerformanceDateFormatter,
        }}
        yAxis={{ label: "", scale: "linear", tickFormatter: formatNumber }}
      />
    </>
  );
}
