import { Outlet } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { SettingsWrapper } from "../components/SettingsWrapper";
import styles from "./SettingsLayout.module.scss";

export function SettingsLayout() {
  return (
    <div>
      <PageHeader title="Settings" actions={null} />
      <div className={styles.content}>
        <SettingsWrapper>
          <Outlet />
        </SettingsWrapper>
      </div>
    </div>
  );
}
