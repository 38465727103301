import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import styles from "./AddMembersModal.module.scss";
import { useState } from "react";
import classNames from "classnames";
import Modal from "src/components/Modal/Modal";
import { Button } from "src/components/Button/Button";
import Select from "src/components/Select/Select";
import { ReactComponent as Shield } from "@material-design-icons/svg/outlined/shield.svg";
import Tag from "src/components/Tags/Tag";

interface IAddMembersModal {
  isOpen: boolean;
  onClose: () => void;
}

// minu@gmail.com,chinu@gmail.com,tinu@gmail.com,pinu@gmail.com,minu@gmail.com,chinu@gmail.com,tinu@gmail.com,pinu@gmail.com

export function AddMembersModal({ isOpen, onClose }: IAddMembersModal) {
  const [selectedRole, setSelectedRole] = useState("Member");
  const [inputValue, setInputValue] = useState("");
  const [members, setMembers] = useState([]);

  function resetValues() {
    setInputValue("");
    setMembers([]);
    setSelectedRole("Member");
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        resetValues();
      }}
      title="Add Members"
      subtitle="Add members and assign roles for client access."
      maxWidth="37.5rem"
      footer={{
        footerContent: (
          <p
            className={classNames(styles.footerContent, {
              [styles.error]: members.length > 10,
            })}
          >
            <Info
              className={classNames(styles.ico, {
                [styles.errorIco]: members.length > 10,
              })}
            />
            <span>
              {members.length > 10
                ? "You have reached the max limit"
                : "You can invite upto 10 people"}
            </span>
          </p>
        ),
        footerActions: (
          <>
            {members.length > 0 && (
              <Button
                size="small"
                style="outline"
                color="subdued"
                onClick={() => {
                  resetValues();
                }}
              >
                Clear All
              </Button>
            )}
            <Button size="small">Send Invite</Button>
          </>
        ),
      }}
      noContainer
    >
      <div className={styles.content}>
        <div className={styles.inputWrapper}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  const data = inputValue.split(",").map((item) => {
                    return { value: item, role: selectedRole };
                  });
                  setMembers([...members, ...data]);
                  setInputValue("");
                  setSelectedRole("Member");
                }
              }}
              className={styles.input}
              disabled={members.length >= 10}
            />
            <div className={styles.dropContainer}>
              <Select
                options={[
                  { label: "Member", value: "Member" },
                  { label: "Admin", value: "Admin" },
                ]}
                value={selectedRole}
                onChange={setSelectedRole}
                btnClass={styles.select}
              />
            </div>
          </div>
          <p className={styles.description}>
            Add multiple members by adding commas in between and hit enter
          </p>
        </div>
        <div className={styles.tagsArea}>
          {members.length > 0 ? (
            <div className={styles.tagsWrapper}>
              {members.map((item, index) => (
                <Tag
                  label={item.value}
                  icon={
                    item.role.toLowerCase() === "admin" && (
                      <Shield className={styles.shieldIco} />
                    )
                  }
                  onRemove={() => {
                    const filteredMembers = members.filter(
                      (mem) => mem.value !== item.value
                    );
                    setMembers(filteredMembers);
                  }}
                  key={index}
                />
              ))}
            </div>
          ) : (
            <img
              src="/imagesAndIcons/AddMembers.png"
              alt="image"
              className={styles.image}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
