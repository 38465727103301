import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentForm } from "./PaymentForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

interface CheckoutFormProps {
  paymentIntentSecret: string;
}

export function CheckoutForm({ paymentIntentSecret }: CheckoutFormProps) {
  const appearance = {
    theme: "stripe" as "stripe" | "night" | "flat" | "none",
    variables: {
      // spacingGridColumn: "6fr",
      // spacingTab: "24",
    },
  };

  const options = {
    clientSecret: paymentIntentSecret,
    appearance,
  };

  return (
    <div>
      {paymentIntentSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm />
        </Elements>
      )}
    </div>
  );
}
