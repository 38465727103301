import { InputFormik } from "src/components/InputFormik/InputFormik";
import onboarding1 from "../assets/onboarding1.png";
import { Formik } from "formik";
import { Form, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "src/components/Button/Button";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "@material-symbols/svg-600/rounded/chevron_right.svg";
import styles from "./OnboardingPage.module.scss";
import { OnboardingLayout2 } from "../components/OnboardingLayout2";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validationSchema = Yup.object({
  firstName: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().min(6).max(72).required("Password is required"),
});

export function OnboardingPage() {
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("code");

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: "", password: "", inviteCode, name: "" }}
      onSubmit={async (values) => {
        const [firstName, ...lastName] = values.name.split(" ");
        await signup({
          email: values.email,
          password: values.password,
          inviteCode,
          firstName,
          lastName: lastName.join(),
        });
        // navigate("/onboarding/about-you");
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <OnboardingLayout2 sideImage={onboarding1}>
              <div className={styles.card}>
                <h1 className={styles.heading}>
                  Supercharge your marketing game
                </h1>
                <p className={styles.copy}>
                  Reach new customers and deepen connections with your existing
                  audience across multiple channels
                </p>

                <div className={styles.formFields}>
                  <div className={styles.inputs}>
                    <InputFormik
                      type="text"
                      label=""
                      placeholder="John Doe"
                      name="name"
                    />
                    <InputFormik
                      type="email"
                      label=""
                      placeholder="frendy@gmail.com"
                      name="email"
                    />
                    <InputFormik
                      name="password"
                      label=""
                      placeholder="Enter the password"
                      type="password"
                    />
                  </div>

                  <Button type="submit" loading={isSubmitting}>
                    Continue
                  </Button>
                </div>

                <div className={styles.tocAndNavigation}>
                  <span className={styles.tocText}>
                    By signing up, you agree to our Terms of Service and Data
                    Processing Agreement
                  </span>
                  <span className={styles.loginCopy}>
                    Already have an account?{" "}
                    <Link className={styles.loginLink} to="/auth/login">
                      Login
                      <ChevronRight
                        fill="var(--brand-color)"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </span>
                </div>
              </div>
            </OnboardingLayout2>
          </Form>
        );
      }}
    </Formik>
  );
}
