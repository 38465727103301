import { ReactComponent as Logout } from "@material-design-icons/svg/outlined/logout.svg";
import Header from "src/components/Header/Header";
import Stepper from "src/components/Stepper/Stepper";
import styles from "./CreateCampaignLayout.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactNode, useState } from "react";
import classNames from "classnames";
import Banner from "src/components/Banner/Banner";
import { ErrorIcon } from "src/components/AppLayout/ErrorIcon";
import Alert from "src/components/Alert/Alert";
import { Breadcrumb } from "src/modules/global/misc/wizardTypes";
import { CampaignInput } from "../misc/createCampaignTypes";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { ReactComponent as PencilIcon } from "@material-symbols/svg-400/rounded/edit.svg";
import { Form, Formik } from "formik";

interface CreateCampaignPageLayoutProps {
  children: ReactNode;
  breadcrumbs: Breadcrumb[];
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  errorBanner?: {
    content: ReactNode;
    isOpen: boolean;
    type: "error" | "warning";
    action?: React.ReactNode;
    onClose: () => void;
  };
}

export function CreateCampaignPageLayout({
  children,
  breadcrumbs,
  errorBanner,
}: CreateCampaignPageLayoutProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const { currentNode, visitedNodes, navigateToNodeId } = useWizardContext();
  const currentNodeId = currentNode.id;

  const primaryAction = {
    label: (
      <>
        <span>Exit</span>
        <Logout fill="white" width={18} height={18} />
      </>
    ),
    onClick() {
      setShowAlert(true);
    },
  };

  function handleSaveCampaignName(campaignName: string) {
    const presetId = searchParams.get("presetId");
    let newSearchParams = new URLSearchParams();
    newSearchParams.set("campaignName", campaignName);

    if (presetId) {
      newSearchParams.set("presetId", presetId);
    }

    setSearchParams(newSearchParams.toString(), {
      replace: true,
    });
  }

  return (
    <div className={styles.page}>
      <Formik
        initialValues={{
          campaignName: searchParams.get("campaignName"),
          isCampaignNameEditing: !searchParams.get("campaignName"),
        }}
        onSubmit={(values) => {
          handleSaveCampaignName(values.campaignName);
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form>
            <Header
              title={
                <EditableText
                  onEdit={() => {
                    setFieldValue("isCampaignNameEditing", true);
                  }}
                  text={values.campaignName}
                  setText={(value) => {
                    setFieldValue("campaignName", value);
                  }}
                  isEditable={values.isCampaignNameEditing}
                  handleClose={() => {
                    setFieldValue("isCampaignNameEditing", false);
                    if (!values.campaignName) {
                      setFieldValue("campaignName", "Untitled Campaign");
                    }
                    handleSubmit();
                  }}
                />
              }
              mode="DISTRACTION_FREE"
              primaryAction={primaryAction}
            />
          </Form>
        )}
      </Formik>
      <Stepper
        steps={breadcrumbs}
        activeStepIndex={breadcrumbs.findIndex((a) => a.isActive)}
        onClick={({ label }) => {
          const startingStep = breadcrumbs.find(
            (b) => b.label === label
          )?.startingStep;

          if (startingStep && visitedNodes.includes(startingStep)) {
            navigateToNodeId(startingStep);
          }
        }}
      />
      <Alert
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
        secondaryAction={{
          children: "Confirm",
          color: "primary",
          onClick: () => {
            setShowAlert(false);
            navigate("/campaigns");
          },
        }}
        primaryAction={{
          children: "Cancel",
          color: "subdued",
          style: "outline",
          onClick: () => {
            setShowAlert(false);
          },
        }}
        title="Are you sure you want to cancel ?"
      >
        The progress will be saved as a draft campaign
      </Alert>
      {errorBanner?.isOpen && (
        <Banner
          content={errorBanner.content}
          isOpen={errorBanner.isOpen}
          type={errorBanner.type}
          icon={<ErrorIcon />}
          onClose={errorBanner.onClose}
          action={errorBanner?.action}
          className={styles.fixedBanner}
        />
      )}
      <main
        className={classNames({
          [styles.layout]: currentNodeId !== "emailBuilder",
          [styles.fullHeight]:
            currentNodeId === "emailBuilder" || currentNodeId === "htmlEditor",
          [styles.fullWidth]:
            currentNodeId === "textEditor" || currentNodeId === "htmlEditor",
        })}
      >
        {children}
      </main>
    </div>
  );
}

interface IEditableText {
  text: string;
  handleClose: () => void;
  setText: (i: string) => void;
  isEditable: boolean;
  onEdit: () => void;
}

function EditableText({
  text,
  onEdit,
  setText,
  isEditable,
  handleClose,
}: IEditableText) {
  if (isEditable) {
    return (
      <input
        value={text}
        onBlur={handleClose}
        style={{ backgroundColor: "transparent", outline: "none" }}
        autoFocus={isEditable}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClose();
          }
        }}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    );
  }

  return (
    <div className={styles.campaignText}>
      {text}

      <button onClick={() => onEdit()} type="button">
        <PencilIcon fill="currentColor" width={16} height={16} />
      </button>
    </div>
  );
}
