import styles from "./UpgradePlanSectionHeading.module.scss";

interface IUpgradePlanSectionHeading {
  icon: string;
  title: React.ReactNode;
  description: string;
}

export function UpgradePlanSectionHeading({
  icon,
  title,
  description,
}: IUpgradePlanSectionHeading) {
  return (
    <div className={styles.sectionHeading}>
      <img src={icon} alt="icon" className={styles.icon} />
      {title}
      <p className={styles.description}>{description}</p>
    </div>
  );
}
