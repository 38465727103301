import { MetaCatalogueProducts, MetaMedia } from "src/graphql/generated/schema";
import { LineItems } from "src/lib/types";
import { generateLineItemsFromCatalogueProducts } from "../../global/functions/generateLineItemsFromCatalogueProducts";
import { generateLineItemsFromExistingPost } from "../../global/functions/generateLineItemsFromExistingPost";

interface getLineItemsFromAdInputArgs {
  ad: {
    staticAdType: string;
    existingPostCTALink: string;
    LineItems: LineItems[];
  };
  catalogueProducts: MetaCatalogueProducts[];
  metaMedia: Pick<MetaMedia, "url" | "caption" | "type">;
}
export function getLineItemsFromAdInput({
  ad,
  catalogueProducts,
  metaMedia,
}: getLineItemsFromAdInputArgs): LineItems[] {
  let lineItems: LineItems[] = [];

  if (ad.staticAdType === "CATALOG") {
    lineItems = generateLineItemsFromCatalogueProducts(catalogueProducts);
  }

  if (ad.staticAdType === "EXISTING_POST") {
    lineItems = generateLineItemsFromExistingPost(
      metaMedia,
      ad?.existingPostCTALink
    );
  }

  if (ad.staticAdType === "CUSTOM_CREATIVE") {
    lineItems = ad?.LineItems || [];
  }

  return lineItems;
}
