import { useFormikContext } from "formik";
import { InputFormik } from "../../../components/InputFormik/InputFormik";
import Select from "../../../components/Select/Select";
import styles from "./ClientFieldsForm.module.scss";
import { currencyList } from "src/currency";
import { FormikError } from "../../../components/FormikError/FormikError";
import { OrganizationUserSelector } from "./OrganizationUserSelector";
import { MultiValue } from "react-select";
import { ClientInput } from "../misc/clientInputType";
import { timezonesList } from "src/modules/global/misc/timezonesList";
import industries from "src/modules/global/misc/clientIndustries";

export function ClientFieldsForm({
  showMembers = true,
  disableCurrencySelector = false,
  disableTimeZoneSelector = false,
}) {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<ClientInput>();
  return (
    <div className={styles.layout}>
      <InputFormik
        label="Add client's name"
        name="name"
        placeholder="Your Client Name"
        showError
      />
      <Select
        options={industries}
        showScrollAfter={4}
        label={"Which industry your client belongs?"}
        value={values.industry}
        onChange={(v) => {
          setFieldValue("industry", v);
        }}
        placeholder="Select Industry"
      />

      <div>
        <Select
          label="Currency"
          placeholder="Currency"
          disabled={disableCurrencySelector}
          showScrollAfter={4}
          options={currencyList.map((v) => ({
            label: v.currencyName,
            value: v.code,
          }))}
          value={values.currencyCode}
          onChange={function (i: any): void {
            setFieldValue("currencyCode", i);
          }}
        />
        {errors && (
          <FormikError
            formikError={errors}
            touched={touched}
            fieldName={`currencyCode`}
          />
        )}
      </div>
      <div>
        <Select
          label="Timezone"
          placeholder="Timezone"
          disabled={disableTimeZoneSelector}
          options={timezonesList}
          showScrollAfter={4}
          value={values.timezoneCode}
          onChange={function (i: any): void {
            setFieldValue("timezoneCode", i);
          }}
        />
        {errors && (
          <FormikError
            formikError={errors}
            touched={touched}
            fieldName={`timezoneCode`}
          />
        )}
      </div>

      {showMembers && (
        <div className={styles.fullColumn}>
          <OrganizationUserSelector
            onChange={(newValues: MultiValue<any>) => {
              const invitedUsers = newValues
                .filter((v) => v.__isNew__)
                .map((v) => v.value);
              const existingUsers = newValues
                .filter((v) => !v.__isNew__)
                .map((v) => v.value);

              setFieldValue("invitedMembers", invitedUsers);
              setFieldValue("members", existingUsers);
              setFieldTouched("members", true);
              setFieldTouched("invitedMembers", true);
            }}
          />

          {errors?.invitedMembers &&
            Array.isArray(errors?.invitedMembers) &&
            errors.invitedMembers.map((_, i) => (
              <FormikError
                formikError={errors}
                fieldName={`invitedMembers[${i}]`}
              />
            ))}
        </div>
      )}
    </div>
  );
}
