import {
  AudienceInput,
  metaEventsOption,
} from "src/modules/audience/components/CreateAudienceInput";

import { Button } from "../../Button/Button";
import Card from "../../Card/Card";
import { InputFormik } from "../../InputFormik/InputFormik";
import { Label } from "../../Label/Label";
import Select from "../../Select/Select";
import { durationOptions } from "src/lib/durationOptions";
import styles from "../CreateCustomAudience/CreateCustomAudience.module.scss";
import { useFormikContext } from "formik";
import { FacebookAccountSettings } from "./FacebookAccountSettings";

interface Props {
  selectedAudienceIndex: number;
  handleBack: () => void;
}

export default function FacebookEngagersForm({
  selectedAudienceIndex,
  handleBack,
}: Props) {
  const { values, setFieldValue, isValid, handleSubmit } =
    useFormikContext<AudienceInput>();
  const audienceKey = `audiences[${selectedAudienceIndex}]`;
  const audienceValuesKey = `audiences[${selectedAudienceIndex}].values`;
  const audienceValues = values.audiences[selectedAudienceIndex]?.values;
  return (
    <Card>
      <Label
        label="Create a website Custom Audience"
        className={styles.cardTitle}
      >
        <FacebookAccountSettings
          audienceValuesKey={audienceValuesKey}
          audienceValues={audienceValues}
          setFieldValue={setFieldValue}
        />
        <div className={styles.container}>
          <div>
            <Label label="Audience Name" />
            <InputFormik
              placeholder="Max limit 50 characters"
              name={`${audienceKey}.audienceName`}
            />
          </div>
          <div>
            <Label label="Select Events" />
            <Select
              options={metaEventsOption}
              value={audienceValues?.eventName}
              onChange={(value) => {
                setFieldValue(`${audienceValuesKey}.eventName`, value);
              }}
              name={`${audienceValuesKey}.eventName`}
            />
          </div>
          <div>
            <Label label="Retention" />
            {/* <Input placeholder="30(in Days)" /> */}
            <Select
              options={durationOptions}
              value={audienceValues?.duration}
              onChange={(value) => {
                setFieldValue(`${audienceValuesKey}.duration`, value);
              }}
            />
            {/* <InputFormik
                placeholder="30(in Days)"
                name={`${audienceValuesKey}.duration`}
              /> */}
          </div>
        </div>
      </Label>

      <div className={styles.btnGrp}>
        <Button type="button" disabled={!isValid} onClick={handleSubmit}>
          Save and Continue
        </Button>
        <Button
          type="button"
          style="plain"
          color="subdued"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}
