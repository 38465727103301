import multiPlatformAccess from "../assets/multiPlatformAccess.png";
import organicGrowth from "../assets/multiPlatformOrganicGrowth.png";
import magnet from "../assets/magnet.svg";
import { UpgradePlanFeatCard } from "./UpgradePlanFeatCard";
import styles from "./UpgradePlanCampaign.module.scss";

export function UpgradePlanCampaign() {
  const automateArr = [
    {
      icon: <img src={magnet} alt="magnet" />,
      title: "Organic Growth",
      subtitle:
        "From deployments to tasks, work with your team every step of the way.",
      image: organicGrowth,
    },
    {
      icon: <img src={magnet} alt="magnet" />,
      title: "Multiple Platform Access",
      subtitle:
        "From deployments to tasks, work with your team every step of the way.",
      image: multiPlatformAccess,
    },
  ];

  return (
    <div className={styles.gridView}>
      {automateArr.map((item, idx) => (
        <UpgradePlanFeatCard {...item} key={idx} primaryColor="grow" />
      ))}
    </div>
  );
}
