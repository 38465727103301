import styles from "./BillingOverlay.module.scss";
import payBill from "../assets/billingOverlayIllustration.png";
import {
  useBillingInvoicesQuery,
  useGetBillingQuery,
} from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { TitleWithThumbnail } from "./TitleWithThumbnail";
import macroGrowInvoiceIcon from "../assets/macroGrowInvoice.svg";
import format from "date-fns/format";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { ReactComponent as Person } from "@material-symbols/svg-600/rounded/person.svg";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";

export function BillingOverlay() {
  // const { data, loading, error } = useBillingCurrentInvoiceQuery();
  const { logout } = useContext(AuthContext);
  const {
    data: billingData,
    loading: billingLoading,
    error: billingError,
  } = useGetBillingQuery();
  const {
    data: draftInvoicesData,
    loading: draftInvoicesLoading,
    error: draftInvoicesError,
  } = useBillingInvoicesQuery({
    variables: {
      status: "DRAFT",
    },
  });

  const {
    data: openInvoiceData,
    loading: openInvoiceLoading,
    error: openInvoiceDataError,
  } = useBillingInvoicesQuery({
    variables: {
      status: "OPEN",
    },
  });

  const {
    data: uncollectibleInvoicesData,
    loading: uncollectibleInvoicesLoading,
    error: uncollectibleInvoicesError,
  } = useBillingInvoicesQuery({
    variables: {
      status: "UNCOLLECTIBLE",
    },
  });

  console.log(draftInvoicesData, uncollectibleInvoicesData, openInvoiceData);

  if (
    draftInvoicesLoading ||
    openInvoiceLoading ||
    uncollectibleInvoicesLoading ||
    billingLoading
  ) {
    return <Spinner height={"screen"} />;
  }

  if (
    draftInvoicesError ||
    uncollectibleInvoicesError ||
    openInvoiceDataError
  ) {
    return (
      <ErrorHandler error={draftInvoicesError || uncollectibleInvoicesError} />
    );
  }

  const invoicesList = [
    ...draftInvoicesData.billingInvoices,
    ...uncollectibleInvoicesData.billingInvoices,
    ...openInvoiceData.billingInvoices,
  ];

  return (
    <div className={styles.page}>
      <PageHeader
        title={"Access Restricted"}
        actions={
          <>
            <ButtonV2 leftIcon={Person} variant="link" onClick={logout}>
              Logout
            </ButtonV2>
          </>
        }
      />
      <div className={styles.billingOverlay}>
        <div className={styles.billingOverlayContent}>
          <div className={styles.billingHeaderAndIllustration}>
            <img
              className={styles.payBillImage}
              src={payBill}
              alt="credit card"
            />
            <h1 className={styles.payBillHeading}>
              Oops! Unpaid bill causing the fuss.
            </h1>
            <p className={styles.payBillCopy}>
              Pay your bills now to fuel the amazing services of Macro and keep
              growing your brand!
            </p>
          </div>

          <div className={styles.invoiceList}>
            {invoicesList.map((v) => (
              <div className={styles.invoiceCard}>
                <TitleWithThumbnail
                  thumbnail={{
                    contentType: "IMAGE",
                    uri: macroGrowInvoiceIcon,
                  }}
                  width={"full"}
                  size={"small"}
                  name={"Macro Grow Plan"}
                  descriptionItems={[
                    `Cycle ${format(
                      new Date(v.periodStart),
                      "dd MMM, yyyy",
                    )} - ${format(new Date(v.periodEnd), "dd MMM, yyyy")}`,
                  ]}
                />
                <div className={styles.horizontalSeperator} />
                <div className={styles.headingWithDescription}>
                  <div className={styles.heading}>
                    ₹{v.totalAmount / 100}/
                    {billingData?.billing?.cycle === "MONTHLY" ? "mo" : "year"}
                  </div>
                  <div className={styles.description}>incld. GST</div>
                </div>
                <div className={styles.actions}>
                  <ButtonV2
                    target="_blank"
                    to={v.invoicePdf}
                    size="small"
                    variant="outline"
                  >
                    Download
                  </ButtonV2>
                  <ButtonV2
                    target="_blank"
                    to={v?.hostedInvoiceUrl ?? "#"}
                    size="small"
                  >
                    Pay Invoice
                  </ButtonV2>
                </div>
              </div>
            ))}
            {/* <Button
            target="_blank"
            rel="noopener noreferrer"
            to={data ? data.billingCurrentInvoice?.hostedInvoiceUrl : "#"}
            loading={loading}
            size="small"
          >
            Pay Invoice
          </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
