import { IInput, Input } from "../Input/Input";
import { getIn, useFormikContext } from "formik";

type InputPropsWithoutFormikFields = Omit<
  IInput,
  "onChange" | "onBlur" | "value" | "status" | "name"
>;

interface Props extends InputPropsWithoutFormikFields {
  name: string;
  showError?: boolean;
}

/**
 * @deprecated Use InputFormikV2
 */
export function InputFormik({ name, showError = true, ...props }: Props) {
  const { values, setFieldValue, handleBlur, errors, touched } =
    useFormikContext<any>();

  let shouldShowError = false;
  const fieldError = getIn(errors, name, "");
  const isFieldTouched = getIn(touched, name);
  const fieldValue = getIn(values, name, "");

  // if by any chance name has link in it and fieldError is string then getIn(errors, name) will translate to
  // "some string".link which will refer to String.prototype.link. Hence to avoid this we check for fieldError
  // type being string only to proceed and not a function
  if (typeof fieldError === "string" && isFieldTouched) {
    shouldShowError = true;
  }

  return (
    <Input
      name={name}
      onChange={(value) => setFieldValue(name, value)}
      // for some alien reason. If below line is uncommented then onBlur does not perform action on relay target
      // .i.e if there is a button that you want to press while editing input then button won't be clicked
      // onBlur={handleBlur}
      value={fieldValue}
      width="full"
      status={
        shouldShowError && showError
          ? {
              type: "error",
              message:
                typeof fieldError === "function" ? fieldError() : fieldError,
            }
          : undefined
      }
      {...props}
    />
  );
}
