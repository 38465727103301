import { PageHeader } from "../../../components/PageHeader/PageHeader";
import styles from "./AudienceListingPageV2.module.scss";
import { useMemo, useState } from "react";
import SortByDropdown from "../../global/components/SortByDropdown";
import sortOptions from "../misc/audienceSortBy";
import {
  AudiencesSortBy,
  useAudienceSetsCountQuery,
} from "../../../graphql/generated/schema";
import { AudienceListLoader } from "../components/AudienceListLoader";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "../../global/misc/dateFilterUtils";
import DateFilterDropdown from "../../global/components/DateFilterDropdown";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ReactComponent as PersonAdd } from "@material-symbols/svg-600/rounded/person_add.svg";
import { Searchbar } from "src/components/Searchbar/Searchbar";

interface audienceFilter {
  dateFilter: DateFilter;
  adAccountId?: string;
}

export function AudienceListingPage() {
  const { data } = useAudienceSetsCountQuery();
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState<AudiencesSortBy>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  const [filters, setFilters] = useState<audienceFilter>({
    dateFilter: "ALL_TIME",
  });

  const createdAt = useMemo(
    () => getDateFilterFromFilterEnum(filters.dateFilter),
    [filters.dateFilter],
  );

  return (
    <div>
      <PageHeader
        title="Audiences"
        titleMetadata={data?.audienceSetsCount?.count}
        actions={
          <>
            <Searchbar
              value={query}
              onChange={(i) => setQuery(i)}
              input={{ placeholder: "Search for audiences" }}
            />
            <SortByDropdown
              sortOptions={sortOptions}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
            <DateFilterDropdown
              setDateFilter={(v: DateFilter) => {
                setFilters({
                  ...filters,
                  dateFilter: v,
                });
              }}
              dateFilter={filters.dateFilter}
            />
            <ButtonV2 size="small" leftIcon={PersonAdd} to="/audiences/new">
              Create Audience
            </ButtonV2>
          </>
        }
      />

      <section className={styles.filterSection}>
        <div className={styles.searchInput}></div>
      </section>
      <div>
        <AudienceListLoader
          sortBy={sortBy}
          filters={{
            name: query,
            ...(createdAt && {
              createdAt: createdAt,
            }),
          }}
        />
      </div>
    </div>
  );
}
