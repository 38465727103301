import { AdLineItemField } from "./AdLineItemField";
import { AdManualTypeField } from "./AdManualTypeField";
import { ReactComponent as AddIcon } from "@material-design-icons/svg/outlined/add.svg";
import { Button } from "src/components/Button/Button";
import { CampaignInput } from "../misc/createCampaignTypes";
import Card from "src/components/Card/Card";
import { IBanner } from "src/components/Banner/Banner";
import { ManageAssetsModal } from "./ManageAssetsModal";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";
import styles from "./CreateAdFields.module.scss";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { AssetEditorModal } from "./AssetEditorModal";
import EmptyState from "src/components/EmptyState/EmptyState";
import { ExistingPostModal } from "./ExistingPostModal";
import { AdStaticTypeField } from "./AdStaticTypeField";
import toast from "react-hot-toast";
import { PageWelcomeMessageTemplate } from "./PageWelcomeMessageTemplate";

interface Props {
  selectedAdIndex: number;
  selectedActivityIndex: number;
  setErrorBanner: (i: IBanner | null) => void;
  isCatalogueActivity: boolean;
  adAccountName: string;
}

export function CreateAdFields({
  selectedAdIndex,
  selectedActivityIndex,
  isCatalogueActivity,
  setErrorBanner,
  adAccountName,
}: Props) {
  const { values, setFieldValue, setFieldTouched, validateForm } =
    useFormikContext<CampaignInput>();
  const selectedActivity = values.Activities[selectedActivityIndex];
  const isMessageEngagementActivity =
    selectedActivity.metaPurpose === "MESSAGE_INSTAGRAM" ||
    selectedActivity.metaPurpose === "MESSAGE_MESSENGER" ||
    selectedActivity.metaPurpose === "MESSAGE_WHATSAPP";
  const isCallActivity = selectedActivity.metaPurpose === "CALL";
  const isVideoEngagementActivity =
    selectedActivity.metaPurpose === "VIDEO_THRUPLAY" ||
    selectedActivity.metaPurpose === "VIDEO_2_SEC_VIEW";
  const isPromotedPostActivity =
    selectedActivity.metaPurpose === "PROMOTE_POST";
  const metaAdAccountId = selectedActivity?.adAccountId;
  const ad = selectedActivity.Ads?.[selectedAdIndex];
  const metaProfilePicture = selectedActivity.metaPagePicture;
  const adKey = `Activities[${selectedActivityIndex}].Ads[${selectedAdIndex}]`;
  let adType: "STATIC_IMAGE_OR_VIDEO" | "CAROUSEL" = getIn(
    values,
    `${adKey}.manualAdType`
  );
  const [isExistingPostModalOpen, setIsExistingPostModalOpen] = useState(false);
  const [showManageAssetModal, setShowManageAssetModal] = useState(false);
  const [selectedLineItemIndex, setSelectedLineItemIndex] = useState(0);
  const [selectedImageForCrop, setSelectedImageForCrop] = useState({
    aspectRatio: 9 / 16,
    src: null,
    baseKey: "",
  });

  useEffect(() => {
    validateForm(values);
  }, [adType]);

  function handleAddLineItem() {
    if (ad.LineItems.length < 10) {
      setFieldTouched(`${adKey}.LineItems`);
      setFieldValue(`${adKey}.LineItems`, [
        ...ad?.LineItems,
        {
          description: "",
          headline: "",
          link: "",
          order: 0,
        },
      ]);
    } else {
      toast.error("You've reached maximum limit of cards");
    }
  }

  const lineItems =
    values.Activities[selectedActivityIndex]?.Ads?.[selectedAdIndex]
      ?.LineItems ?? [];

  if (!ad) {
    return (
      <EmptyState title={"No Ad Found"}>
        No ad found. Please create a add to proceed forward
      </EmptyState>
    );
  }

  function handleChooseExistingPost() {
    setIsExistingPostModalOpen(true);
  }

  // function Fields() {
  //   switch (values.objective) {
  //     case "CONVERSIONS":
  //       switch (selectedActivity.facebookActivityType) {
  //         case "META_STANDARD":
  //           switch (ad.staticAdType) {
  //             case "CUSTOM_CREATIVE":
  //             case "EXISTING_POST":
  //           }
  //           break;
  //         case "META_DYNAMIC":
  //           break;
  //       }
  //       break;
  //     case "ENGAGEMENT":
  //       switch (selectedActivity.engagementConversionLocation) {
  //         case "CALL":
  //           break;
  //         case "MESSAGE":
  //           break;
  //         case "VIDEO_2_SEC_VIEW":
  //           break;
  //         case "VIDEO_THRUPLAY":
  //           break;
  //       }
  //       break;
  //   }
  // }

  return (
    <>
      <Card
        size="small"
        title="Create New Creative"
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: "none",
          width: "100%",
        }}
      >
        {!isCatalogueActivity && !isCallActivity && !isPromotedPostActivity && (
          <AdStaticTypeField name={`${adKey}.staticAdType`} />
        )}

        {!isCatalogueActivity &&
          ad.staticAdType === "CUSTOM_CREATIVE" &&
          !isVideoEngagementActivity && (
            <div className={styles.adTypeSelector}>
              {/* FIXME: error handling of this input */}
              <AdManualTypeField
                name={`${adKey}.manualAdType`}
                adKey={adKey}
                currentAd={ad}
                setErrorBanner={setErrorBanner}
              />
            </div>
          )}

        <div className={styles.formLayout}>
          {ad.staticAdType !== "EXISTING_POST" && (
            <div className={styles.primaryTextWrapper}>
              <MultilineInputFormik
                label="Primary Text"
                rows={3}
                placeholder="Tip: Primary text should span 2-3 lines at most."
                name={`${adKey}.primaryText`}
              />
            </div>
          )}

          <AdLineItemField
            handleChooseExistingPost={handleChooseExistingPost}
            isCatalogueActivity={isCatalogueActivity}
            isVideoEngagementActivity={isVideoEngagementActivity}
            isCallActivity={isCallActivity}
            isPromotedPostActivity={isPromotedPostActivity}
            baseKey={adKey}
            ad={ad}
            setErrorBanner={setErrorBanner}
            showManageAssetModal={showManageAssetModal}
            setShowManageAssetModal={(selectedLineItemIndex: number) => {
              if (isCallActivity) return;

              setSelectedLineItemIndex(selectedLineItemIndex);
              setShowManageAssetModal(true);
            }}
          />

          {ad.staticAdType === "CUSTOM_CREATIVE" &&
            ad.manualAdType === "CAROUSEL" && (
              <Button
                onClick={handleAddLineItem}
                color="subdued"
                width="full"
                size="small"
                style="outline"
              >
                <span className={styles.buttonIcon}>
                  Add Carousel Cards{" "}
                  <AddIcon width={18} height={18} fill="currentColor" />
                </span>
              </Button>
            )}
        </div>
        {isMessageEngagementActivity && (
          <PageWelcomeMessageTemplate adKey={adKey} />
        )}
      </Card>

      {/* call option doesn't support placements */}
      {!isCallActivity && (
        <AssetEditorModal
          adAccountId={metaAdAccountId}
          aspectRatio={selectedImageForCrop.aspectRatio}
          imageUrl={selectedImageForCrop.src}
          baseKey={selectedImageForCrop.baseKey}
          onClose={() => {
            setSelectedImageForCrop({
              src: null,
              baseKey: "",
              aspectRatio: selectedImageForCrop.aspectRatio,
            });
          }}
          isOpen={!!selectedImageForCrop.src}
        />
      )}

      {/* call option doesn't support existing post */}
      {!isCallActivity && (
        <ExistingPostModal
          assetType={isVideoEngagementActivity ? "VIDEO" : "IMAGE_AND_VIDEO"}
          selectedPost={ad.existingPost}
          setSelectedPost={async (value) => {
            setFieldValue(`${adKey}.existingPostId`, value.id, true);
            setFieldValue(`${adKey}.existingPost`, value);
            setTimeout(() => setFieldTouched(`${adKey}.existingPostId`), 500);
          }}
          isOpen={isExistingPostModalOpen}
          onClose={() => setIsExistingPostModalOpen(false)}
          facebookConfig={{
            metaPageConnectedInstagramBusinessAccountId:
              selectedActivity.metaPageConnectedInstagramBusinessAccountId,
            adAccountId: selectedActivity.adAccountId,
          }}
        />
      )}

      {/* call option doesn't support placement */}
      {!isCallActivity && (
        <ManageAssetsModal
          assetType={isVideoEngagementActivity ? "VIDEO" : "IMAGE_AND_VIDEO"}
          adAccountName={adAccountName}
          adAccountId={metaAdAccountId}
          baseKey={`${adKey}.LineItems[${selectedLineItemIndex}]`}
          profilePicture={metaProfilePicture}
          showManageAssetModal={showManageAssetModal}
          selectedLineItemIndex={selectedLineItemIndex}
          setSelectedImageForCrop={setSelectedImageForCrop}
          onClose={() => {
            setShowManageAssetModal(false);
          }}
          lineItems={lineItems}
        />
      )}
    </>
  );
}
