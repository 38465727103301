import { Fragment } from "react";
import styles from "./EmailCname.module.scss";
import { Button } from "src/components/Button/Button";

interface StepItem {
  step: string;
  children: React.ReactNode[];
}

interface Props {
  steps: StepItem[];
}

export function EmailCname({ steps }: Props) {
  return (
    <div className={styles.stepsContainer}>
      <ol className={styles.stepNoList}>
        {steps.map((item, index) => (
          <Fragment key={index}>
            <li className={styles.step}>
              {/* <span className={styles.stepNumber}>{index + 1 + "."}</span>{" "} */}
              {item.step}
            </li>
            <ul className={styles.stepList}>
              {item.children.map((child, idx) => (
                <li key={idx} className={styles.stepListItem}>
                  {child}
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
      </ol>
    </div>
  );
}
