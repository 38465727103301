import {
  useCampaignDeleteMutation,
  useCampaignPresetDeleteMutation,
  useCampaignPresetDuplicateMutation,
} from "src/graphql/generated/schema";
import { DropdownOptionOrSeparator, DropdownV2 } from "./DropdownV2";
import { ReactComponent as MoreOptionsIcon } from "@material-symbols/svg-400/rounded/more_vert.svg";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface ICampaignItemMoreOptions {
  campaignId: string;
  setIsCampaignUpdating: (i: boolean) => void;
  isPreset: boolean;
  isCampaignEmpty: boolean;
}

export function CampaignItemMoreOptions({
  isPreset,
  setIsCampaignUpdating,
  isCampaignEmpty,
  campaignId,
}: ICampaignItemMoreOptions) {
  const isStatusDraftOrEmpty = isCampaignEmpty || isPreset;
  const [duplicatePresetFn] = useCampaignPresetDuplicateMutation();
  const [deleteCampaign] = useCampaignDeleteMutation();
  const [deleteCampaignPreset] = useCampaignPresetDeleteMutation();
  const navigate = useNavigate();

  let options: DropdownOptionOrSeparator[] = [
    {
      label: "Duplicate this campaign",
      value: "DUPLICATE",
    },
    {
      type: "separator",
    },
    {
      label: "Delete this campaign",
      value: "DELETE",
      disabled: !isStatusDraftOrEmpty,
      variant: "danger",
    },
  ];

  return (
    <DropdownV2
      onSelect={async (value) => {
        setIsCampaignUpdating(true);
        try {
          switch (value) {
            case "DELETE":
              if (isPreset) {
                await deleteCampaignPreset({
                  variables: {
                    id: campaignId,
                  },
                  refetchQueries: ["allCampaigns"],
                });
              } else {
                await deleteCampaign({
                  variables: {
                    id: campaignId,
                  },
                  refetchQueries: ["allCampaigns"],
                });
              }
              break;
            case "DUPLICATE":
              const { data } = await duplicatePresetFn({
                variables: {
                  campaignId,
                },
              });

              navigate(
                `/campaigns/new?presetId=${data.campaignPresetDuplicate.id}`
              );

              break;
          }
        } catch (e) {
          toast.error(e.message);
        } finally {
          setIsCampaignUpdating(false);
        }
      }}
      options={options}
      trigger={<MoreOptionsIcon width={16} height={16} />}
    />
  );
}
