import { Link } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "@material-design-icons/svg/outlined/arrow_outward.svg";
import styles from "./Support.module.scss";
import classNames from "classnames";

export function Support() {
  return (
    <div className={styles.stepContainer}>
      <h4 className={styles.stepTitle}>Need Help?</h4>
      <ul className={classNames(styles.stepsContainer, styles.noListStyle)}>
        <li className={styles.link}>
          <Link to="#">Visit Help and Support</Link>
        </li>
        <li className={styles.link}>
          <Link to="#">Contact Support</Link>
          <ArrowOutward width={18} height={18} />
        </li>
      </ul>
    </div>
  );
}
