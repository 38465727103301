import Table, { ITable } from "src/components/Table/Table";
import styles from "./FullPageTable.module.scss";
import classNames from "classnames";

export interface IFullPageTable extends ITable {
  tableClass?: string;
  wrapperClass?: string;
}

export function FullPageTable({
  headings,
  rows,
  tableClass,
  loading,
  selectActions,
  selectable,
  selectedItems,
  setSelectedItems,
  wrapperClass,
  noOfRows,
  actionDropdown,
  action,
}: IFullPageTable) {
  return (
    <div className={classNames(styles.tableWrapper, wrapperClass)}>
      <Table
        headings={headings}
        rows={rows}
        rounded
        className={classNames(tableClass, styles.fullTable)}
        noOfRows={noOfRows}
        loading={loading}
        selectActions={selectActions}
        selectable={selectable}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        actionDropdown={actionDropdown}
        action={action}
      />
    </div>
  );
}
