export function FacebookCommentIcon({
  ...options
}: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="100"
      height="100"
      fill="none"
      {...options}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M80.0003 50C80.0003 33.4315 66.5689 20 50.0003 20C33.4318 20 20.0003 33.4315 20.0003 50C20.0003 66.5685 33.4318 80 50.0003 80C55.9253 80 61.455 78.2799 66.1097 75.312L75.6783 78.661C78.4678 79.6373 81.1369 76.9305 80.1215 74.155L76.4606 64.1484C78.7197 59.9314 80.0003 55.112 80.0003 50ZM50.0003 25C63.8074 25 75.0003 36.1929 75.0003 50C75.0003 54.6245 73.747 58.9486 71.5631 62.6596C71.1839 63.304 71.113 64.0843 71.3699 64.7865L74.336 72.8938L66.5842 70.1807C65.8245 69.9148 64.9833 70.0309 64.3241 70.4926C60.2682 73.3334 55.3326 75 50.0003 75C36.1932 75 25.0003 63.8071 25.0003 50C25.0003 36.1929 36.1932 25 50.0003 25Z" />
    </svg>
  );
}
