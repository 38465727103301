import { gql } from "@apollo/client";

const CAMPAIGN_PRESETS = gql`
  query campaignPresets(
    $filters: CampaignsFilterInput
    $sortBy: CampaignsSortBy
  ) {
    campaignPresets(filters: $filters, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          type
          name
          platforms
          objective
          # data
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        itemsPerPage
        startCursor
        totalItems
      }
    }
  }
`;

export default CAMPAIGN_PRESETS;
