import { CampaignInput } from "../misc/createCampaignTypes";
import { ChoosePlatformAndActivity } from "./ChoosePlatformAndActivity";
import { CampaignCreateForm } from "./CampaignCreateForm";
import { choosePlatformAndActivitySchema } from "../misc/choosePlatformAndActivitySchema";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { CreateCampaignPageLayout } from "./CreateCampaignPageLayout";

interface Props {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => unknown;
}

export function PlatformAndReach({
  campaignPresetUpsert,
  campaignInput,
  handleBack,
}: Props) {
  const selectedActivityIndex = campaignInput.selectedActivityIndex ?? 0;
  const { currentNode } = useWizardContext<CampaignInput>();

  return (
    <CampaignCreateForm
      autoSave={false}
      schema={choosePlatformAndActivitySchema}
      campaignInput={campaignInput}
      campaignPresetUpsert={campaignPresetUpsert}
    >
      <ChoosePlatformAndActivity
        handleBack={handleBack}
        selectedActivityIndex={selectedActivityIndex}
        disabled={currentNode.id !== "choosePlatformAndActivityName"}
      />
    </CampaignCreateForm>
  );
}
