import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { ReactComponent as ArticleIcon } from "@material-symbols/svg-600/rounded/article.svg";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { LineHeading } from "src/modules/media/components/LineHeading";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import Select from "src/components/Select/Select";
import { ReactComponent as AddLinkIcon } from "@material-symbols/svg-600/rounded/add_link.svg";
import { CloseIcon } from "src/components/AppLayout/CloseIcon";
import styles from "./WhatsappTemplateFieldShowcase.module.scss";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import SelectV2 from "src/components/SelectV2/SelectV2";

const actionTypeOptions = [
  {
    label: "Website Link",
    value: "URL",
  },
  {
    label: "Quick Replies",
    value: "QUICK_REPLY",
  },
  {
    label: "None",
    value: "NONE",
  },
];

export function WhatsappTemplateFieldsShowcase() {
  const { values } = useFormikContext<ActivityInputV2>();
  const actionType = values.WhatsappMessageObject.Buttons?.[0]?.type ?? "NONE";

  return (
    <div>
      <div className={styles.mainFields}>
        <LineHeading
          icon={<ArticleIcon width={14} height={14} />}
          content="Message Content"
        />
        {values.WhatsappMessageObject?.Header?.format === "TEXT" && (
          <InputFormikV2
            disabled
            label="Heading Text"
            name={`WhatsappMessageObject.Header.text`}
          />
        )}
        {/* {values.WhatsappMessageObject?.Header.format === "MEDIA" && (
          <InputFormik name={`WhatsappMessageObject.Header.text`} />
        )} */}
        <MultilineInput
          disabled
          label="Message Body"
          value={values.WhatsappMessageObject.Body.text}
          name={`WhatsappMessageObject.Body.text`}
          rows={5}
        />
      </div>

      <div className={styles.destinationSection}>
        {actionType !== "NONE" && (
          <LineHeading
            icon={<ArticleIcon width={14} height={14} />}
            content="Destination"
          />
        )}

        {actionType === "URL" && (
          <div className={styles.urlSection}>
            <div className={styles.urlAndLabelWrapper}>
              <SelectV2
                label="Call to action*"
                value={actionType}
                options={actionTypeOptions}
                disabled
                onChange={(value) => {
                  // readonly field
                }}
              />
              <InputFormikV2
                label="CTA Label"
                name="WhatsappMessageObject.Buttons.[0].text"
                disabled
                icon={AddLinkIcon}
              />
            </div>
            <InputFormikV2
              name="WhatsappMessageObject.Buttons.[0].url"
              label="Destination Website Link"
              disabled
              icon={AddLinkIcon}
            />
          </div>
        )}
        {actionType === "QUICK_REPLY" && (
          <div className={styles.repliesSection}>
            <SelectV2
              label="Call to action*"
              value={actionType}
              options={actionTypeOptions}
              disabled
              onChange={(value) => {
                // readonly field
              }}
            />

            <div className={styles.repliesList}>
              {values.WhatsappMessageObject.Buttons.map(
                (button, replyIndex) => (
                  <div className={styles.replyInputWrapper} key={replyIndex}>
                    <InputFormikV2
                      label="Add Option name"
                      disabled
                      name={`WhatsappMessageObject.Buttons[${replyIndex}].text`}
                    />
                    <button
                      className={styles.removeOptionButton}
                      onClick={() => {
                        // readonly
                      }}
                      type="button"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
