import {
  MetaPageWelcomeMessageInput,
  useMetaPageWelcomeMessageCreateMutation,
  useMetaPageWelcomeMessagesQuery,
} from "../../../graphql/generated/schema";
import { ReactComponent as Add } from "@material-symbols/svg-600/rounded/add.svg";
import { PageWelcomeMessageTemplateEditorModal } from "../../global/components/PageWelcomeMessageTemplateEditorModal";
import { useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { FormValues } from "../misc/adTemplateCreateTypes";
import * as Yup from "yup";
import SelectV2 from "src/components/SelectV2/SelectV2";

const pageWelcomeMessageValidationSchema = Yup.object({
  PageWelcomeMessage: Yup.object({
    greeting: Yup.string().required("Greeting is required field"),
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name should be atleast 3 characters"),
    selectedWhatsappCustomerAction: Yup.mixed().oneOf([
      "PREFILLED_MESSAGE",
      "MESSAGE_OPTIONS",
    ]),
    prefilledText: Yup.string().when(
      ["selectedWhatsappCustomerAction", "selectedPlatform"],
      (selectedWhatsappCustomerAction: any, selectedPlatform: any) => {
        if (
          selectedWhatsappCustomerAction === "PREFILLED_MESSAGE" &&
          selectedPlatform === "WHATSAPP"
        ) {
          return Yup.string()
            .required("Prefilled text is required")
            .min(3, "Should be 3 characters long");
        }
        return Yup.string().notRequired();
      },
    ),
    MessageOptions: Yup.array(
      Yup.object({
        text: Yup.string().required("text is required"),
        automatedReply: Yup.string().nullable(),
      }),
    ).when(
      "selectedWhatsappCustomerAction",
      (
        selectedWhatsappCustomerAction: string,
        schema: Yup.ArraySchema<any>,
      ) => {
        if (selectedWhatsappCustomerAction === "PREFILLED_MESSAGE") {
          return schema.nullable().notRequired();
        }
        return schema.min(1, "Should have atleast one message option");
      },
    ),
  }),
});

export function MetaPageWelcomeMessageSelector({
  selectedValue,
  setSelectedValue,
}) {
  const { setFieldValue } = useFormikContext<FormValues>();

  // const [selectedPurpose, setSelectedPurpose] = useState<
  //   "MESSAGE_INSTAGRAM" | "MESSAGE_WHATSAPP"
  // >("MESSAGE_INSTAGRAM");
  const [
    showPageWelcomeMessageEditorModal,
    setShowPageWelcomeMessageEditorModal,
  ] = useState(false);
  const [metaPageWelcomeMessageCreateFunc] =
    useMetaPageWelcomeMessageCreateMutation();
  const { data } = useMetaPageWelcomeMessagesQuery({
    variables: {
      sortBy: {
        field: "CREATED_AT",
        direction: "DESC",
      },
      filter: {
        take: 10,
      },
    },
  });

  const metaPageWelcomeMessages =
    data && data.metaPageWelcomeMessages
      ? data.metaPageWelcomeMessages.edges.map(({ node }) => ({
          label: node.name,
          value: node.id,
        }))
      : [];

  const initialValues = {
    PageWelcomeMessage: {
      name: "New Page Welcome Message",
      greeting: "",
      selectedWhatsappCustomerAction: "MESSAGE_OPTIONS",
      selectedPlatform: "WHATSAPP",
      MessageOptions: [
        {
          text: "",
        },
      ],
    },
  };
  return (
    <div>
      <SelectV2
        isFullWidth
        label={
          "Choose a template for beginning the chat after people tap on your ad"
        }
        stickyAction={{
          onClick: () => {
            setShowPageWelcomeMessageEditorModal(true);
          },
          label: "Create new response template",
          icon: <Add width={16} height={16} fill="currentColor" />,
        }}
        placeholder="Select quick messaging response"
        options={metaPageWelcomeMessages}
        value={selectedValue}
        onChange={(i: any) => {
          setSelectedValue(i);
        }}
      />
      <Formik
        validationSchema={pageWelcomeMessageValidationSchema}
        initialValues={initialValues}
        onSubmit={async (values: {
          PageWelcomeMessage: MetaPageWelcomeMessageInput;
        }) => {
          const pageWelcomeMessage = values.PageWelcomeMessage;
          const { data } = await metaPageWelcomeMessageCreateFunc({
            variables: {
              input: {
                name: pageWelcomeMessage.name,
                greeting: pageWelcomeMessage.greeting,
                MessageOptions: pageWelcomeMessage.MessageOptions?.map((v) => ({
                  text: v.text,
                  automatedReply: v.automatedReply,
                })),
                prefilledText: pageWelcomeMessage?.prefilledText,
              },
            },
            refetchQueries: ["metaPageWelcomeMessages"],
            awaitRefetchQueries: true,
          });
          if (data && data.metaPageWelcomeMessageCreate.id) {
            setFieldValue(
              "pageWelcomeId",
              data.metaPageWelcomeMessageCreate.id,
            );
            setShowPageWelcomeMessageEditorModal(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <PageWelcomeMessageTemplateEditorModal
              baseKey={"PageWelcomeMessage"}
              isOpen={showPageWelcomeMessageEditorModal}
              loading={isSubmitting}
              onSubmit={handleSubmit}
              onClose={() => {
                setShowPageWelcomeMessageEditorModal(false);
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
