import { Button } from "src/components/Button/Button";
import styles from "./EngageWaitlist.module.scss";
import WaitlistEngage1 from "../assets/waitlist-engage-slide-1.png";
import WaitlistEngage2 from "../assets/waitlist-engage-slide-2.png";
import WaitlistEngage3 from "../assets/waitlist-engage-slide-3.png";
import WaitlistEngage4 from "../assets/waitlist-engage-slide-4.png";
import WaitlistEngage5 from "../assets/waitlist-engage-slide-5.png";
import WaitlistEngage6 from "../assets/waitlist-engage-slide-6.png";
import WaitlistEngage7 from "../assets/waitlist-engage-slide-7.png";
import WaitlistEngage8 from "../assets/waitlist-engage-slide-8.png";
import WaitlistEngage9 from "../assets/waitlist-engage-slide-9.png";
import WaitlistEngage10 from "../assets/waitlist-engage-slide-10.png";
import RunningTrainCarousel from "../components/RunningTrainCarousel";
import { useState } from "react";
import toast from "react-hot-toast";
import { ReactComponent as MagicWand } from "../assets/magicWand.svg";

const slides = Array(10)
  .fill(null)
  .flatMap(() => [
    WaitlistEngage1,
    WaitlistEngage2,
    WaitlistEngage3,
    WaitlistEngage4,
    WaitlistEngage5,
    WaitlistEngage6,
    WaitlistEngage7,
    WaitlistEngage8,
    WaitlistEngage9,
    WaitlistEngage10,
  ]);

export default function EngageWaitlistPage() {
  const [inWaitlist, setInWaitlist] = useState(
    Boolean(localStorage.getItem("in-waitlist")) ?? false
  );
  return (
    <div className={styles.container}>
      <div>
        <span className={styles.iconWithLabel}>
          <MagicWand />
          <span className={styles.comingSoonLabel}>
            Announcing our new feature
          </span>
        </span>
        <h4 className={styles.title}>Automate your Customer Engagement</h4>
        <p className={styles.description}>
          Deepen customer relationships with drip flow marketing and engagement
          campaigns
        </p>
        <Button
          title={inWaitlist ? "You are already in waitlist" : ""}
          disabled={inWaitlist}
          onClick={() => {
            toast.success("Successfully joined waitlist");
            setInWaitlist(true);
            localStorage.setItem("in-waitlist", "true");
          }}
        >
          Join Waitlist
        </Button>
      </div>
      <RunningTrainCarousel
        slides={slides}
        slideHeight={220}
        slideWidth={184}
        gap={16}
      />
    </div>
  );
}
