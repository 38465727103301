import styles from "./LineGraph.module.css";
import {
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  LineChart,
} from "recharts";

type statValues = Record<string, any>;
type statLabels = {
  color: string;
  name: string;
  dataKey: string;
};

interface ILineGraph {
  statValues: Array<statValues>;
  statLabels: Array<statLabels>;
  xAxis: {
    tickFormatter?: (i: string) => string;
    label: string;
    dataKey: string;
  };
  width: number;
  height: number;
  yAxis: {
    tickFormatter?: (i: string) => string;
    label: string;
    dataKey?: string;
    scale: "log" | "linear";
  };
}

export function LineGraph({
  statLabels,
  statValues,
  xAxis,
  width,
  height,
  yAxis,
}: ILineGraph) {
  return (
    <div className={styles.lineGraph}>
      <ResponsiveContainer width="100%" height={height}>
        <LineChart
          margin={{ bottom: 0, top: 0, left: 0, right: 0 }}
          width={width}
          height={height}
          data={statValues}
        >
          <CartesianGrid
            // strokeDasharray="1 0"
            stroke="rgba(var(--color-subdued-button-code), 0.24)"
            vertical={false}
          />
          <XAxis
            tickMargin={10}
            fontSize={10}
            stroke="var(--color-subdued)"
            tickLine={false}
            tickFormatter={xAxis.tickFormatter}
            dataKey={xAxis.dataKey}
          >
            <Label value={xAxis.label} position="bottom" offset={-5} />
          </XAxis>
          <YAxis
            axisLine={false}
            stroke="var(--color-subdued)"
            fontSize={10}
            // tickMargin={40}
            // tickSize={40}
            tickLine={false}
            tickFormatter={yAxis.tickFormatter}
            scale={yAxis.scale}
            {...(yAxis.scale === "log" && { domain: ["auto", "auto"] })}
            label={{
              label: yAxis.label,
              position: "insideLeft",
              angle: -90,
            }}
          />
          {/* TODO: use a formatter for format the tooltip */}
          {/* <Tooltip /> */}
          {statLabels.map((stat) => (
            <Line
              dot={true}
              strokeWidth={2}
              key={stat.dataKey}
              label={stat.name}
              dataKey={stat.dataKey}
              stroke={stat.color}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
