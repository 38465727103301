import { Button } from "src/components/Button/Button";
import styles from "./IntegrateBanner.module.scss";
import emailIntegrate from "../assets/emailIntegrate.png";
import metaIntegrate from "../assets/metaIntegrate.png";
import whatsappIntegrate from "../assets/whatsappIntegrate.png";
import smsIntegrate from "../assets/textIntegrate.png";
import integrateBannerBg from "../assets/integrateBannerBg.svg";
import classNames from "classnames";

interface IIntegrateBanner {
  platform: "META" | "EMAIL" | "WHATSAPP" | "SMS";
}

const bannerContent = {
  META: {
    image: metaIntegrate,
    description:
      "You need to setup Meta, to get started using Meta Network. Just visit the setting page, go to integration section, and follow the steps.",
    label: "Setup Meta",
    link: "/settings/integrations/meta",
  },
  EMAIL: {
    image: emailIntegrate,
    description:
      "You need to setup Email, to get started using it. Just visit the setting page, go to integration section, and follow the steps.",
    label: "Setup Email",
    link: "/settings/integrations/email",
  },
  WHATSAPP: {
    image: whatsappIntegrate,
    description:
      "You need to setup WhatsApp, to get started using WhatsApp activity. Go to integration section, and follow the steps.",
    label: "Setup Whatsapp",
    link: "/settings/integrations/whatsapp",
  },
  SMS: {
    image: smsIntegrate,
    description:
      "You need to setup SMS, to get started using SMS activity. Just visit the setting page, go to integration section, and follow the steps.",
    label: "Setup Sms",
    link: "/settings/integrations/message",
  },
};

export function IntegrateBanner({ platform = "META" }: IIntegrateBanner) {
  return (
    <div
      className={classNames(styles.banner, {
        [styles.metaBanner]: platform === "META",
        [styles.emailBanner]: platform === "EMAIL",
        [styles.whatsappBanner]: platform === "WHATSAPP",
        [styles.smsBanner]: platform === "SMS",
      })}
      style={{
        backgroundImage: `url(${integrateBannerBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
    >
      <div className={styles.content}>
        <h4 className={styles.heading}>Setup required</h4>
        <p className={styles.description}>
          {bannerContent[platform].description}
        </p>
        <Button style="plain" to={bannerContent[platform].link}>
          {bannerContent[platform].label}
        </Button>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={bannerContent[platform].image}
          alt="image"
          className={styles.image}
        />
      </div>
    </div>
  );
}
