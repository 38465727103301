import { useFormikContext } from "formik";
import { LineHeading } from "./LineHeading";
import { ReactComponent as AdsClick } from "@material-symbols/svg-600/rounded/ads_click.svg";
import { FormValues } from "../misc/whatsappTemplateCreateTypes";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import styles from "./MessageTemplateCreateForm.module.scss";
import { Link } from "react-router-dom";
import { CloseIcon } from "src/components/AppLayout/CloseIcon";
import { ReactComponent as WhatsappIcon } from "../assets/whatsappLogo.svg";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-600/rounded/info.svg";
import { ReactComponent as AddLinkIcon } from "@material-symbols/svg-600/rounded/add_link.svg";
import { AddMedia } from "./AdTemplateCreateSlideOver/AddMedia";
import { SelectedAssetItem } from "./AdTemplateCreateSlideOver/SelectedAssetItem";
import { FormikError } from "src/components/FormikError/FormikError";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const messageCategoryOptions = [
  // {
  //   label: "Authentication",
  //   value: "AUTHENTICATION",
  // },
  // {
  //   label: "Transactional",
  //   value: "TRANSACTIONAL",
  // },
  // {
  //   label: "Support",
  //   value: "SUPPORT",
  // },
  {
    label: "Promotional",
    value: "MARKETING",
  },
  // {
  //   label: "Reminder",
  //   value: "REMINDERS",
  // },
];

const headerTypeOptions = [
  {
    label: "None",
    value: "NONE",
  },
  {
    label: "Text",
    value: "TEXT",
  },
  {
    label: "Media",
    value: "MEDIA",
  },
  {
    label: "Location",
    value: "LOCATION",
  },
];

const ctaOptions = [
  {
    label: "None",
    value: "NONE",
  },
  {
    label: "Call To Action",
    value: "ACTION",
  },
  {
    label: "Quick Reply",
    value: "QUICK_REPLIES",
  },
];

const actionTypeOptions = [
  {
    label: "Website Link",
    value: "URL",
  },
];

export function WhatsappMessageTemplateForm() {
  const { values, setFieldValue, errors, submitCount, touched, isSubmitting } =
    useFormikContext<FormValues>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.formTitleSection}>
        <div className={styles.formTitle}>
          <WhatsappIcon width={27} height={27} />
          <h4>New Message</h4>
        </div>
        <Link to={"/library/ad-library"} className={styles.formCloseIcon}>
          <CloseIcon />
        </Link>
      </div>
      <div className={styles.formFields}>
        <LineHeading
          icon={<AdsClick width={15} height={15} />}
          content="Message Setup"
        />
        <div className={styles.setupSection}>
          <InputFormikV2 name="name" label="Message Name" />
          <SelectV2
            label="Message Category"
            options={messageCategoryOptions}
            name="name"
            onChange={(value) => {
              setFieldValue("category", value);
            }}
            value={values.category}
          />
        </div>

        <div className={styles.contentSection}>
          <LineHeading
            icon={<AdsClick width={15} height={15} />}
            content="Message Content"
          />
          <div className={styles.contentFields}>
            <SelectV2
              options={headerTypeOptions}
              label="Header Type"
              onChange={(value) => {
                setFieldValue("header.format", value);
              }}
              value={values.header.format}
            />
            {values.header.format === "TEXT" && (
              <InputFormikV2 name="header.text" label="Header Text" />
            )}
            <MultilineInput
              value={values.body.text}
              onChange={(value) => {
                setFieldValue("body.text", value);
              }}
              showCharacterCount
              maxCharacterCount={1024}
              rows={5}
              name="name"
              label="Body content"
              placeholder="Primary text goes here"
            />
            <InputFormikV2
              name="footer"
              showCharacterCount
              maxCharacterCount={1024}
              label="Footer Content (optional)"
            />

            {values.header.format === "MEDIA" && (
              <>
                <AddMedia
                  multiple={false}
                  onSelect={(assets) => {
                    if (assets.length > 0) {
                      setFieldValue("header.asset", assets[0]);
                    }
                  }}
                />
                <FormikError
                  fieldName="header.asset"
                  formikError={errors}
                  submitCount={submitCount}
                  touched={touched}
                />
                {values.header.asset && (
                  <SelectedAssetItem
                    onRemove={() => {
                      setFieldValue("header.asset", null);
                    }}
                    asset={values.header.asset}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className={styles.destinationSection}>
          <LineHeading
            icon={<AdsClick width={15} height={15} />}
            content="Destination"
          />
          <SelectV2
            label="Destination Action (Optional)"
            options={ctaOptions}
            value={values.cta.type}
            onChange={(value) => {
              setFieldValue("cta.type", value);
            }}
          />

          {values.cta.type === "ACTION" && (
            <div className={styles.actionSection}>
              <div className={styles.actionTypeGrid}>
                <SelectV2
                  label="Call to Action"
                  options={actionTypeOptions}
                  value={values.cta.action.type}
                  onChange={(value) => {
                    setFieldValue("cta.action.type", value);
                  }}
                />
                <InputFormikV2
                  label="CTA Label"
                  name="cta.action.label"
                  icon={AddLinkIcon}
                />
              </div>
              <InputFormikV2
                name="cta.action.link"
                label="Destination Website Link"
                icon={AddLinkIcon}
              />
            </div>
          )}

          {values.cta.type === "QUICK_REPLIES" && (
            <div className={styles.repliesSection}>
              <div className={styles.repliesList}>
                {values.cta.quickReplies.map((reply, replyIndex) => (
                  <div className={styles.replyInputWrapper} key={replyIndex}>
                    <InputFormikV2
                      label="Add Option name"
                      name={`cta.quickReplies[${replyIndex}]`}
                    />
                    <button
                      className={styles.removeOptionButton}
                      onClick={() => {
                        setFieldValue(
                          "cta.quickReplies",
                          values.cta.quickReplies.filter(
                            (r, index) => index !== replyIndex,
                          ),
                        );
                      }}
                      type="button"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))}
              </div>

              <div className={styles.addOptionButtonWrapper}>
                <ButtonV2
                  variant="outline"
                  disabled={values.cta.quickReplies.length >= 3}
                  onClick={() => {
                    // add new option
                    setFieldValue("cta.quickReplies", [
                      ...values.cta.quickReplies,
                      "",
                    ]);
                  }}
                >
                  Add Option
                </ButtonV2>
              </div>
            </div>
          )}
        </div>

        <div className={styles.submitSection}>
          <span className={styles.helpMessage}>
            Submit your message for review
            <InfoIcon fill="var(--color-disabled)" width={20} height={20} />
          </span>

          <div className={styles.buttonGroup}>
            <ButtonV2 variant="outline">Cancel</ButtonV2>
            <ButtonV2 loading={isSubmitting} type="submit">
              Submit
            </ButtonV2>
          </div>
        </div>
      </div>
    </div>
  );
}
