import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import {
  ShopifyIntegrationDocument,
  useShopifyIntegrationConnectMutation,
} from "src/graphql/generated/schema";
import AuthContext from "src/stores/AuthContext";

export default function ShopifyAuthCallbackHandler() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [shopifyIntegrationConnect, { error }] =
    useShopifyIntegrationConnectMutation();

  async function connectShopifyIntegration() {
    const shopifyCallbackCredentials =
      localStorage.getItem("shopifyIntegration");

    const { shopifyShopName, tokenId } = shopifyCallbackCredentials
      ? JSON.parse(shopifyCallbackCredentials)
      : { shopifyShopName: "", tokenId: "" };

    await shopifyIntegrationConnect({
      variables: {
        input: {
          tokenId: tokenId,
        },
      },
      refetchQueries: [{ query: ShopifyIntegrationDocument }],
    });

    // since it is no longer required after offline token is generated
    localStorage.removeItem("shopifyIntegration");
    navigate(
      `/settings/integrations/shopify?shopifyShopName=${shopifyShopName}&view=wizard`,
    );
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenId = searchParams.get("tokenId");

    const shopifyShopName = searchParams.get("shopifyShopName");

    // if they are not in the header
    // they might have been already saved in local storage
    if (tokenId && shopifyShopName) {
      localStorage.setItem(
        "shopifyIntegration",
        JSON.stringify({
          shopifyShopName,
          tokenId,
        }),
      );
    }

    if (isAuthenticated) {
      connectShopifyIntegration();
    } else {
      localStorage.setItem("redirect_url", "/auth/shopify/callback");
      navigate("/auth/signup");
    }
  }, []);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return <></>;
}
