import { ReactComponent as Today } from "@material-symbols/svg-600/rounded/calendar_today.svg";
import styles from "./DateFilterDropdown.module.scss";
import { dateFilterOptions } from "../../global/misc/dateFilterOptions";
import { DateFilter } from "../misc/dateFilterUtils";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { SelectButton } from "src/components/SelectButton/SelectButton";

interface IDateFilterDropdown {
  setDateFilter: (i: DateFilter) => void;
  dateFilter: DateFilter;
  filterOptions?: typeof dateFilterOptions;
  showLabel?: boolean;
}

export default function DateFilterDropdown({
  setDateFilter,
  dateFilter,
  filterOptions = dateFilterOptions,
  showLabel = false,
}: IDateFilterDropdown) {
  return (
    <SelectButton
      size="small"
      icon={Today}
      label={
        showLabel && filterOptions && filterOptions.length > 0
          ? filterOptions.find((v) => v.value === dateFilter)?.label
          : ""
      }
      value={dateFilter}
      onChange={(value) => {
        setDateFilter(value);
      }}
      options={filterOptions}
    />
  );
}
