import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styles from "./DropdownV2.module.scss";
import { ReactNode } from "react";
import classNames from "classnames";

export type DropdownOption = {
  variant?: "danger" | "default";
  disabled?: boolean;
  label: string;
  value: string;
};

export type DropdownSeparator = { type: "separator" };

export type DropdownOptionOrSeparator = DropdownOption | DropdownSeparator;

export type IDropdownV2 = {
  trigger: ReactNode;
  onSelect: (i: string) => void;
  // TODO: use sections instead of separators
  // {type: "section", options: {label: string, value: string}[] }
  options: DropdownOptionOrSeparator[];
};

export function DropdownV2({ trigger, options, onSelect }: IDropdownV2) {
  return (
    <div className={styles.dropdown}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className={styles.trigger}>
          {trigger}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className={styles.menuItems}>
          {options.map((option, index) => {
            if ("type" in option && option.type === "separator") {
              return (
                <DropdownMenu.Separator
                  key={index}
                  className={styles.menuSeparator}
                />
              );
            }

            if ("type" in option) {
              throw new Error("Invalid options");
            }

            return (
              <DropdownMenu.Item
                key={index}
                onClick={async (e) => {
                  // stop else the click will propagate to the item
                  e.stopPropagation();
                  onSelect(option.value);
                }}
                className={classNames(styles.menuItem, {
                  [styles.deleteMenuItem]: option.variant === "danger",
                  [styles.disabled]: option.disabled,
                })}
              >
                {option.label}
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
