import { Button } from "src/components/Button/Button";
import styles from "./PasswordSet.module.scss";

export function PasswordSet() {
  return (
    <div className={styles.loginCard}>
      <h2 className={styles.title}>Back to login</h2>
      <p className={styles.subtitle}>
        Password has been set, please login back with your new password.
      </p>
      <Button width="full" color="primary" to="/auth/login">
        Login
      </Button>
    </div>
  );
}
