import styles from "./GraphNotAvailableYet.module.scss";
import { ReactComponent as barGraphNotAvailable } from "../assets/barGraphNotAvailable.svg";
import { ReactComponent as lineGraphNotAvailable } from "../assets/lineGraphNotAvailable.svg";

interface IGraphNotAvailableYet {
  height?: number;
  type?: "lineGraph" | "barGraph";
}

export function GraphNotAvailableYet({
  type = "lineGraph",
  height = 300,
}: IGraphNotAvailableYet) {
  const Illustration =
    type === "barGraph" ? barGraphNotAvailable : lineGraphNotAvailable;

  return (
    <div style={{ height: height }} className={styles.statNotFound}>
      <Illustration />
      <span className={styles.message}>
        Your Insights will appear here soon.
      </span>
    </div>
  );
}
