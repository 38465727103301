import {
  Pagination as IPagination,
  SegmentsQuery,
} from "src/graphql/generated/schema";
import { Link } from "react-router-dom";
import styles from "./SegmentListTable.module.scss";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";
import { Pagination } from "src/components/Pagination/Pagination";
import segmentsIcon from "../../global/assets/segment.svg";
import { FullPageTable } from "src/modules/global/components/FullPageTable";
import { formatNumber } from "src/lib/formatNumber";
import format from "date-fns/format";
import { titleCase } from "src/lib/titleCase";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";

export function SegmentListTable({
  segments,
  segmentsPagination,
  setPagination,
}: {
  segments: SegmentsQuery["segments"]["edges"];
  segmentsPagination: IPagination;
  setPagination: (i: any) => void;
}) {
  const rows = segments.map((value) => [
    <Link to={`/segments/${value.node.id}`}>
      <div className={styles.segmentMainColumn}>
        <img alt="segment icon" src={segmentsIcon} height={24} width={24} />
        <div className={styles.segmentTitleWrapper}>
          <span className={styles.segmentTitle}>{value.node.name}</span>
          <span className={styles.segmentCreatedAt}>
            Created on {format(new Date(value.node.createdAt), "dd MMM, yyyy")}
          </span>
        </div>
      </div>
    </Link>,
    titleCase(value.node.type),
    formatNumber(value.node.customersCount),
    `${formatNumberByType(value.node.customersPercent, "PERCENTAGE")}`,
  ]);

  return (
    <div>
      <FullPageTable
        headings={["Segment", "Type", "Members", "% of Customers"]}
        rows={rows}
        noOfRows={segmentsPagination.itemsPerPage}
      />
      <div className={styles.paginationWrapper}>
        <Pagination
          hasPrevious={segmentsPagination.hasPreviousPage}
          hasNext={segmentsPagination.hasNextPage}
          onPrevious={() => {
            setPagination({
              cursor: segmentsPagination.startCursor,
              take: -itemsPerPage,
            });
          }}
          onNext={() => {
            setPagination({
              cursor: segmentsPagination.endCursor,
              take: itemsPerPage,
            });
          }}
          label={`Showing ${
            segmentsPagination.itemsPerPage > segmentsPagination.totalItems
              ? segmentsPagination.totalItems
              : segments.length
          } out ${segmentsPagination.totalItems}`}
        />
      </div>
    </div>
  );
}
