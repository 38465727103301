import { Asset as IAsset } from "../../../../graphql/generated/schema";
import styles from "./AssetListingTable.module.scss";
import { getAspectRatio } from "../../misc/getAspectRatio";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { ReactComponent as AdCount } from "../../assets/adCount.svg";
import { ReactComponent as ActivityCount } from "../../assets/activityCount.svg";
import { ReactComponent as AspectRatio } from "@material-symbols/svg-600/rounded/aspect_ratio.svg";
import { ReactComponent as Package } from "@material-symbols/svg-600/rounded/package_2.svg";
import { ReactComponent as ThreeDot } from "@material-symbols/svg-600/rounded/more_vert.svg";
import classNames from "classnames";
import { Asset } from "../../../../components/Smartphone/Asset";
import toFixed from "../../../../lib/toFixed";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { Dropdown } from "../../../global/components/Dropdown";

interface IAssetListingTable {
  assets: IAsset[];
  triggerAdTemplateCreateSlideOver: (i: string | null) => void;
  triggerAdDetailsSlideOver: (i: string | null) => void;
  deleteAsset?: (id: string) => void;
  loading?: boolean;
}

export function AssetListingTable({
  assets,
  triggerAdTemplateCreateSlideOver,
  triggerAdDetailsSlideOver,
  deleteAsset,
  loading,
}: IAssetListingTable) {
  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      {assets.map((v) => (
        <AssetItem
          asset={v}
          deleteAsset={() => deleteAsset(v.id)}
          openAdTemplateCreateView={() =>
            triggerAdTemplateCreateSlideOver(v.id)
          }
          openAssetDetailedView={() => triggerAdDetailsSlideOver(v.id)}
        />
      ))}
    </div>
  );
}

function AssetItem({
  asset,
  deleteAsset,
  openAssetDetailedView,
  openAdTemplateCreateView,
}: {
  asset: IAsset;
  deleteAsset: () => void;
  openAdTemplateCreateView: () => void;
  openAssetDetailedView: () => void;
}) {
  const adsCount = asset.adsCount || 0;
  const activitiesCount = asset.activitiesCount || 0;
  return (
    <div className={styles.itemWrapper}>
      {/* TITLE SECTION */}
      <button onClick={openAssetDetailedView} className={styles.titleSection}>
        {/* Platform  */}
        <div className={styles.iconWithTitle}>
          <div className={styles.iconStacking}>
            <Asset
              type={asset.contentType}
              name={asset.name}
              preview={asset.uri}
              className={styles.asset}
            />
          </div>

          <div className={styles.iconTitle}>{asset.name}</div>
        </div>
        <div className={styles.countContainer}>
          {/* Activity Count */}
          <Tooltip
            placement={"BOTTOM_CENTER"}
            supportingText="Shows how many Activities are using this assets"
          >
            <div className={styles.countItemStat}>
              <ActivityCount className={styles.icon} />
              <span className={styles.countValue}>
                {activitiesCount > 9 ? activitiesCount : `0${activitiesCount}`}
              </span>
            </div>
          </Tooltip>
          {/* Ad Count */}
          <Tooltip
            placement={"BOTTOM_CENTER"}
            supportingText="Shows how many Ads are using this assets"
          >
            <div className={styles.countItemStat}>
              <AdCount className={styles.icon} />
              <span className={styles.countValue}>
                {adsCount > 9 ? adsCount : `0${adsCount}`}
              </span>
            </div>
          </Tooltip>
        </div>
      </button>

      {/* TAGS */}
      <div className={classNames(styles.center, styles.badgeList)}>
        <div
          className={classNames(styles.badge, {
            [styles.inactive]: adsCount === 0,
            [styles.active]: adsCount > 0,
          })}
        >
          {adsCount > 0 ? "In Use" : "Not In Use"}
        </div>
      </div>

      <div className={styles.aspectRatio}>
        {getAspectRatio(asset.width, asset.height)}
      </div>

      <div className={styles.statsList}>
        <div className={styles.stat}>
          <span>{toFixed(asset.fileSize / (1024 * 1024))}MB</span>
          <AspectRatio width={16} height={16} />
        </div>
        <div className={styles.stat}>
          <span>
            {asset.width}x{asset.height}
          </span>
          <Package width={16} height={16} />
        </div>
      </div>

      <Dropdown
        title={<ThreeDot width={16} height={16} />}
        items={[
          {
            children: "Use Ad in template",
            onClick: openAdTemplateCreateView,
          },
          {
            children: "Delete this media",
            onClick: deleteAsset,
          },
        ]}
      />
    </div>
  );
}
