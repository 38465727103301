import { Button } from "src/components/Button/Button";
import { ReactComponent as CloseIcon } from "@material-design-icons/svg/outlined/close.svg";
import { IconButton } from "src/components/IconButton/IconButton";
import { formatFileSize } from "src/lib/formatFileSize";
import styles from "./FileCard.module.scss";
import { Asset } from "src/components/Smartphone/Asset";
import { LineItems } from "src/lib/types";

interface Props {
  lineItem: LineItems;
  onRemove?: () => void;
  setShowManageAssetModal: (i: boolean) => void;
  showViewPlacement: boolean;
}

export function FileCard({
  lineItem: lineItem,
  onRemove,
  showViewPlacement,
  setShowManageAssetModal,
}: Props) {
  return (
    <div className={styles.fileCard}>
      <div className={styles.fileContainer}>
        <Asset
          type={lineItem?.PostAsset?.contentType || "IMAGE"}
          name={lineItem?.PostAsset?.name || ""}
          preview={
            lineItem?.PostAsset?.uri || "http://via.placeholder.com/41/41"
          }
          className={styles.preview}
        />
        <div className={styles.fileCardBody}>
          <div className={styles.heading}>
            <h4 className={styles.fileName}>{lineItem?.PostAsset?.name}</h4>
            <span className={styles.break} />
            <span className={styles.fileSize}>
              {formatFileSize(lineItem?.PostAsset?.fileSize)}
            </span>
          </div>
          {showViewPlacement && (
            <Button
              size="micro"
              style="plain"
              // onClick={() => setShowFeedAndStories(!showFeedAndStories)}
              onClick={() => setShowManageAssetModal(true)}
            >
              View Placements
            </Button>
          )}
        </div>
        <div className={styles.closeBtn}>
          <IconButton icon={<CloseIcon />} style="plain" onClick={onRemove} />
        </div>
      </div>
      {/* {showAssetsPlacement && (
        <AssetsPlacement
          preview={lineItem}
          setShowManageAssetModal={setShowManageAssetModal}
        />
      )} */}
    </div>
  );
}
