import { IntegrationsStatusQuery } from "src/graphql/generated/schema";

export function checkIntegrationStatus(
  integrationStatusData: IntegrationsStatusQuery
):
  | "ACTIVE"
  | Omit<IntegrationsStatusQuery["integrationsStatus"], "__typename"> {
  if (!integrationStatusData) return;

  const { __typename, ...formattedIntegrationStatus } =
    integrationStatusData.integrationsStatus;

  return Object.values(formattedIntegrationStatus).every((v) => v === "ACTIVE")
    ? "ACTIVE"
    : integrationStatusData.integrationsStatus;
}
