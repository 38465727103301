import { ReactNode } from "react";
import cn from "classnames";
import styles from "./CreateFlowCard.module.scss";
import { Button, IButton } from "src/components/Button/Button";

interface ICreateFlowCard {
  title: string;
  subtitle?: string;
  children: ReactNode;
  footerActions?: IButton[];
  footerInfo?: ReactNode;
  classNames?: string;
}
export default function CreateFlowCard({
  title,
  children,
  subtitle,
  footerActions = [],
  footerInfo,
  classNames,
}: ICreateFlowCard) {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.card, classNames)}>
        <div className={styles.cardHeader}>
          <div className={styles.heading}>{title}</div>
          <div className={styles.subheading}>{subtitle}</div>
        </div>
        <div className={styles.childrenContainer}>{children}</div>
        <div className={styles.cardFooter}>
          <div className={styles.cardFooterInfo}>{footerInfo}</div>
          <div className={styles.cardFooterActions}>
            {footerActions.map((props) => (
              <Button {...props} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
