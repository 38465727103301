import { Outlet, useNavigate } from "react-router-dom";

import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import Header from "src/components/Header/Header";
import styles from "./CreateAutomationLayout.module.scss";

export function CreateAutomationLayout() {
  const navigate = useNavigate();
  return (
    <div>
      <Header
        title="Create Automation"
        mode="DISTRACTION_FREE"
        primaryAction={{
          label: (
            <>
              <span>Cancel</span>
              <Close fill="white" width={18} height={18} />
            </>
          ),
          onClick() {
            navigate("/automations");
          },
        }}
      />
      <main className={styles.layout}>
        <Outlet />
      </main>
    </div>
  );
}
