import classNames from "classnames";
import { TPlacement } from "src/modules/global/components/DetailTooltip";
import styles from "./Tooltip.module.scss";

export interface ITooltip {
  children: React.ReactNode;
  supportingText: string | React.ReactNode;
  className?: string;
  placement?: TPlacement;
}

export default function Tooltip({
  children,
  supportingText,
  className,
  placement = "BOTTOM_CENTER",
}: ITooltip) {
  const placementToClassName: Record<TPlacement, string> = {
    BOTTOM_LEFT: styles.tooltipBottomLeft,
    TOP_LEFT: styles.tooltipTopLeft,
    TOP_RIGHT: styles.tooltipTopRight,
    BOTTOM_RIGHT: styles.tooltipBottomRight,
    TOP_CENTER: styles.tooltipTopCenter,
    BOTTOM_CENTER: styles.tooltipBottomCenter,
  };

  return (
    <div className={styles.tooltip}>
      {children}
      <div
        className={classNames(
          styles.tooltipText,
          placementToClassName[placement],
          className
        )}
      >
        {supportingText}
      </div>
    </div>
  );
}
