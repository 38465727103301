import { Spinner } from "src/components/Spinner/Spinner";
import {
  ActivitiesByStatusQuery,
  ActivityStatusEnum,
} from "src/graphql/generated/schema";
import { CampaignActivityItem } from "./CampaignActivityItem";
import { ReactNode, useMemo, useState } from "react";
import { useActivitiesByStatusQuery } from "src/graphql/generated/schema";
import { showPaginationChecks } from "src/modules/global/functions/showPaginationCheck";
import { campaignItemsPerPage } from "../misc/campaignItemsPerPage";
import { ErrorHandler } from "src/components/ErrorHandler";
import { CampaignPagination } from "./CampaignPagination";
import { PaginationInput } from "../misc/paginationInput";
import { campaignFilters, campaignSortBy } from "../misc/campaignFilter";
import styles from "./CampaignListingStatusItem.module.scss";
import { useDebounceEffect } from "../../campaign-wizard/functions/useDebounceEffect";

interface ICampaignListingStatusItem {
  name: string;
  color: string;
  value: ActivityStatusEnum;
  icon: ReactNode;
  filters: campaignFilters;
  sortBy: campaignSortBy;
  activitiesCount: number;
}

export function CampaignListingStatusItem({
  icon,
  color,
  name,
  value,
  filters,
  sortBy,
  activitiesCount,
}: ICampaignListingStatusItem) {
  const [pagination, setPagination] = useState<PaginationInput>({
    limit: campaignItemsPerPage,
    cursor: null,
  });
  const {
    data,
    error,
    loading,
    refetch: activitiesByStatusRefetch,
  } = useActivitiesByStatusQuery({
    variables: {
      filters: {
        // ...filters,
        status: value,
        limit: pagination.limit,
        cursor: pagination.cursor,
        ...(filters.createdAt && {
          createdAt: filters.createdAt as any,
        }),
      },
      sortBy,
    },
  });

  useDebounceEffect(
    async () => {
      const query = filters.query.trim();
      await activitiesByStatusRefetch({
        filters: {
          // ...filters,
          name: query,
          status: value,
          limit: pagination.limit,
          cursor: pagination.cursor,
          ...(filters.createdAt && { createdAt: filters.createdAt as any }),
        },
        sortBy,
      });
    },
    300,
    [filters.query],
  );

  const showPagination = useMemo(() => {
    if (!data) {
      return false;
    }

    return showPaginationChecks({
      itemEdges: data?.activitiesByStatus?.edges,
      pageInfo: data?.activitiesByStatus?.pageInfo,
      itemsPerPage: campaignItemsPerPage,
    });
  }, [data]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (data && data.activitiesByStatus.edges.length === 0) {
    return <></>;
  }

  return (
    <section className={styles.item}>
      <div className={styles.itemHeader}>
        <span className={styles.icon}>{icon}</span>
        <div style={{ color: color }} className={styles.itemNameAndCount}>
          <span className={styles.name}>{name}</span>
          <StatusActivitiesCount count={activitiesCount} />
        </div>
      </div>

      {loading ? (
        <Spinner height={500} />
      ) : (
        <ActivitiesList activityEdges={data.activitiesByStatus.edges} />
      )}

      {showPagination && (
        <div className={styles.paginationWrapper}>
          <CampaignPagination
            noPadding
            borderLess
            setPagination={({ limit, cursor }) => {
              setPagination({
                limit,
                cursor,
              });
            }}
            pageInfo={data?.activitiesByStatus?.pageInfo}
          />
        </div>
      )}
    </section>
  );
}

interface IStatusActivitiesCount {
  count: number;
}

function StatusActivitiesCount({ count }: IStatusActivitiesCount) {
  return <span className={styles.count}>{count}</span>;
}

function ActivitiesList({
  activityEdges,
}: {
  activityEdges: ActivitiesByStatusQuery["activitiesByStatus"]["edges"];
}) {
  return (
    <>
      {activityEdges.map((activityEdge) => (
        <CampaignActivityItem
          campaignId={activityEdge.node.Campaign.id}
          activity={activityEdge.node}
        />
      ))}
    </>
  );
}
