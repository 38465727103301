import { getMonthFromNumber } from "src/modules/campaign/misc/getMonthFromNumber";

/* Date Month and year separator with a dash (-)
 */
export function monthAndYearFormatter(
  str: string | undefined,
  option?: { formatYear: boolean }
) {
  let _option = option || { formatYear: true };

  let [year, month, _day] = str.split("-");

  if (_option.formatYear) {
    year = year.toString().slice(2, 4);
  }

  return `${getMonthFromNumber(Number(month) - 1)} ${year}`;
}
