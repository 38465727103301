import classNames from "classnames";
import styles from "./StickyFooter.module.scss";

interface Props {
  actions?: React.ReactNode;
  label?: React.ReactNode;
}

export function StickyFooter({ actions, label }: Props) {
  return (
    <div className={styles.stickyActions}>
      <div className={classNames(styles.footer, label && styles.label)}>
        {label}
        <div className={styles.footerActions}>{actions}</div>
      </div>
    </div>
  );
}
