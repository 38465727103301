import styles from "./CampaignSingleOverviewTabContent.module.scss";
import { ReactComponent as Help } from "@material-design-icons/svg/outlined/help_outline.svg";
import { DetailTooltip } from "src/modules/global/components/DetailTooltip";
import campaignStatsTooltip from "../assets/campaignStatsTooltip.png";
import metaPresetIcon from "../assets/metaPresetIcon.png";
import { ReactComponent as KeyboardArrowDownIcon } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import { IOverviewTabs } from "../misc/campaignSingleUiTypes";
import { getGrowAggregateStatsByType } from "src/modules/global/functions/getGrowAggregateStatsByType";
import StatCard from "src/modules/global/components/StatCard";

interface ICampaignSingleOverviewTabContent {
  setIsMoreDetailsOpen: (i: boolean) => void;
  selectedTab: IOverviewTabs;
  isMoreDetailsOpen: boolean;
  insights: GrowAggregateType;
}

export function CampaignSingleOverviewTabContent({
  setIsMoreDetailsOpen,
  isMoreDetailsOpen,
  selectedTab,
  insights,
}: ICampaignSingleOverviewTabContent) {
  // let the stats handle the loading
  const stats = getGrowAggregateStatsByType({ insights, type: selectedTab });

  return (
    <div className={styles.conversionWrapper}>
      <span className={styles.tooltip}>
        <DetailTooltip
          description="Here you will find all the main key metrics related to all of your activity inside this campaign are grouped, which gives you better idea about how your activities are performing."
          title="About campaign summary"
          image={{
            src: campaignStatsTooltip,
            alt: campaignStatsTooltip,
            height: "132px",
            width: "267px",
          }}
          placement="BOTTOM_LEFT"
          trigger={<Help height={16} width={16} />}
        ></DetailTooltip>
      </span>
      {stats.map((v, i) => (
        <StatCard
          {...v}
          className={styles.overviewStatCard}
          contributionList={[
            {
              alt: metaPresetIcon,
              src: metaPresetIcon,
            },
          ]}
        />
      ))}

      {!isMoreDetailsOpen && (
        <button
          type="button"
          onClick={() => {
            setIsMoreDetailsOpen(true);
          }}
          className={styles.moreDetailsBtn}
        >
          More Details
          <KeyboardArrowDownIcon width={14} height={14} />
        </button>
      )}
    </div>
  );
}
