import styles from "./AdTemplatePreviewSection.module.scss";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Smartphone } from "../../../../components/Smartphone/Smartphone";
import { Asset } from "../../../../components/Smartphone/Asset";
import {
  AssetContentType,
  MetaAdTypeEnum,
} from "../../../../graphql/generated/schema";
import adThumbnailImage from "../../../campaign-wizard/assets/adThumbnail.svg";
import { getThumbnailAssetFromAd } from "../../../campaign/functions/getThumbnailAssetFromAd";
import { FormValues } from "../../misc/adTemplateCreateTypes";
import { transformAdTemplateToLineItems } from "../../functions/transformAdTemplateToLineItems";
import { transformTemplateInputToMetaActivityAdTemplate } from "../../functions/transformTemplateInputToMetaActivityAdTemplate";
import { titleCase } from "../../../../lib/titleCase";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { Button } from "../../../../components/Button/Button";
import { AdTemplatePreviewModal } from "./AdTemplatePreviewModal";

const fallBackThumbnailAsset = {
  uri: adThumbnailImage,
  contentType: "IMAGE" as AssetContentType,
  alt: "fallback",
};

interface IAdTemplatePreviewSection {
  metaPageName: string;
  profilePicture: string;
}

export function AdTemplatePreviewSection({
  metaPageName,
  profilePicture,
}: IAdTemplatePreviewSection) {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [selectedPreview, setSelectedPreview] = useState<"FEED" | "STORY">(
    "FEED"
  );
  const [showChangePlacementModal, setShowChangePlacementModal] =
    useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedPlatform, setSelectedPlatform] = useState<
    "INSTAGRAM" | "FACEBOOK"
  >("INSTAGRAM");

  let adType: MetaAdTypeEnum =
    values.type === "SINGLE_IMAGE_OR_VIDEO" || values.type === "CAROUSEL"
      ? "CUSTOM_CREATIVE"
      : values.type === "CATALOGUE_AD"
      ? "CATALOG"
      : "EXISTING_POST";

  const previewSwitcherOptions = [
    {
      value: "FEED" as const,
      smartphoneVariant: "feed" as const,
    },
    {
      value: "STORY" as const,
      smartphoneVariant: "stories" as const,
    },
  ];

  const selectedPreviewOption = previewSwitcherOptions.find(
    (v) => v.value === selectedPreview
  );

  const smartphonePreview = transformAdTemplateToLineItems(
    transformTemplateInputToMetaActivityAdTemplate(values)
  );

  return (
    <div className={styles.previewSection}>
      <div className={styles.previewContainer}>
        <div className={styles.previewHeader}>
          <div className={styles.previewHead}>
            <div className={styles.title}>Placement Preview</div>
            <div className={styles.subtitle}>
              Change media by hovering on mockups
            </div>
          </div>
          <div className={styles.platformSwitcher}>
            {(["INSTAGRAM", "FACEBOOK"] as const).map((v) => (
              <button
                type="button"
                onClick={() => setSelectedPlatform(v)}
                className={classNames(styles.platform, {
                  [styles.activePlatform]: selectedPlatform === v,
                })}
              >
                {v === "INSTAGRAM" ? (
                  <div className={styles.platformSwitcherButton}>
                    <Instagram width={20} height={20} />
                    Instagram
                  </div>
                ) : (
                  <div className={styles.platformSwitcherButton}>
                    <Facebook width={20} height={20} />
                    Facebook
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.previewSectionContainer}>
          <div className={styles.previewSmartphoneContainer}>
            <Smartphone
              variant={"feed"}
              preview={smartphonePreview}
              primaryText={values.primaryText}
              accountName={metaPageName}
              profilePicture={profilePicture}
              type={selectedPlatform.toLowerCase() as any}
              adType={adType}
              onSlideChange={(slide) => {
                setActiveSlide(slide);
              }}
            />
          </div>
          <div className={styles.previewSmartphoneContainer}>
            <Smartphone
              variant={"stories"}
              preview={smartphonePreview}
              primaryText={values.primaryText}
              accountName={metaPageName}
              profilePicture={profilePicture}
              type={selectedPlatform.toLowerCase() as any}
              adType={adType}
              onSlideChange={(slide) => {
                setActiveSlide(slide);
              }}
            />
          </div>
          <div className={styles.previewSmartphoneContainer}>
            <Smartphone
              variant={selectedPreviewOption.smartphoneVariant}
              preview={smartphonePreview}
              primaryText={values.primaryText}
              accountName={metaPageName}
              profilePicture={profilePicture}
              type={selectedPlatform.toLowerCase() as any}
              adType={adType}
              onSlideChange={(slide) => {
                setActiveSlide(slide);
              }}
            />
          </div>
        </div>
        <div className={styles.previewChangePlacement}>
          {adType === "CUSTOM_CREATIVE" && (
            <div className={styles.changePlacementButtonContainer}>
              <Button
                style="outline"
                color="subdued"
                size="micro"
                onClick={() => {
                  setShowChangePlacementModal(true);
                }}
              >
                Change Placement
              </Button>
              <AdTemplatePreviewModal
                isOpen={showChangePlacementModal}
                onClose={(i) => {
                  setShowChangePlacementModal(false);
                }}
              />
              {/*
            <AddMedia
              multiple={false}
              onSelect={function (assets: IAsset[]): void {
                if (selectedPreview === "FEED") {
                  const previousPostAsset =
                    values.LineItems[activeSlide].PostAsset;
                  setFieldValue(
                    `LineItems[${activeSlide}].PostAsset`,
                    assets[0]
                  );

                  setFieldValue(
                    `LineItems[${activeSlide}].postAssetId`,
                    assets[0].id
                  );

                  if (
                    !values.LineItems[activeSlide].StoryAsset &&
                    previousPostAsset
                  ) {
                    setFieldValue(
                      `LineItems[${activeSlide}].StoryAsset`,
                      previousPostAsset
                    );
                    setFieldValue(
                      `LineItems[${activeSlide}].storyAssetId`,
                      previousPostAsset.id
                    );
                  }
                }
                if (selectedPreview === "STORY") {
                  setFieldValue(
                    `LineItems[${activeSlide}].StoryAsset`,
                    assets[0]
                  );
                  setFieldValue(
                    `LineItems[${activeSlide}].storyAssetId`,
                    assets[0].id
                  );
                }
              }}
              triggerLabel={"Change Placement"}
            />
          */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function PreviewSwitcherOption({
  selectedPreview,
  setSelectedPreview,
  values,
  option,
  adType,
}) {
  const asset = getThumbnailAssetFromAd(
    {
      name: values.title,
      id: "",
      adType,
      ExistingPost: values.existingPost,
      createdAt: new Date(),
      updatedAt: new Date(),
      LineItems: values.LineItems.map((v, i) => ({
        id: String(i),
        order: i,
        ...v,
      })),
    },
    option?.value
  );

  const switcherThumbnail = {
    alt: asset?.uri || fallBackThumbnailAsset.alt,
    uri: asset?.uri || fallBackThumbnailAsset.uri,
    assetType: asset?.contentType || fallBackThumbnailAsset?.contentType,
  };

  return (
    <button
      type="button"
      className={classNames(styles.previewSwitcherOption, {
        [styles.active]: option.value === selectedPreview,
      })}
      onClick={() => setSelectedPreview(option.value)}
    >
      <Asset
        type={switcherThumbnail.assetType}
        name={switcherThumbnail.alt}
        preview={switcherThumbnail.uri}
        className={classNames(styles.asset, {
          [styles.active]: option.value === selectedPreview,
        })}
      />
      <span className={classNames(styles.adName)}>
        {titleCase(option.value)}
      </span>
    </button>
  );
}
