import { AutomationDefaultSidebar, AutomationSignup } from "./AutomationSignup";
import {
  FlowItems,
  FormValues,
} from "src/pages/automations/builder/CreateAutomationPage/CreateAutomationPage";
import { FormikValues, useFormikContext } from "formik";

import { AutomationConditionSidebar } from "./ConditionSidebar/AutomationConditionSidebar";
import { AutomationContactActivity } from "./AutomationContactActivity";
import { AutomationRulesAndActions } from "./AutomationRulesAndActions";
import { AutomationStartPoint } from "./AutomationStartPoint";
import { AutomationUploadEmail } from "./AutomationUploadEmail";
import styles from "./AutomationBuilderSidebar.module.scss";

interface SidebarProps {
  addItem: (
    item: FlowItems,
    values: FormikValues,
    setFieldValue: (i: string, v: any) => void
  ) => void;
}

function SidebarContent({ addItem }: SidebarProps) {
  const { values } = useFormikContext<FormValues>();
  const section = values.section;
  if (section === "START") return <AutomationStartPoint />;
  if (section === "SIGNUP") return <AutomationSignup addItem={addItem} />;
  if (section === "CONTACT") return <AutomationContactActivity />;
  if (section === "RULES_ACTION")
    return <AutomationRulesAndActions addItem={addItem} />;
  if (section === "UPLOAD") return <AutomationUploadEmail />;
  if (section === "CONDITION") return <AutomationConditionSidebar />;
  if (section === "EMPTY") return <AutomationDefaultSidebar />;
  return <div></div>;
}

export default function AutomationBuilderSidebar({ addItem }: SidebarProps) {
  return (
    <div className={styles.sidebar}>
      <SidebarContent addItem={addItem} />
    </div>
  );
}

// <Droppable key={1} droppableId="components" isDropDisabled>
//   {(provided) => (
//     <div
//       ref={provided.innerRef}
//       {...provided.droppableProps}
//       className={styles.sidebar}
//     >
//       <div>
//         {components.map((value, index) => (
//           <Draggable key={value.id} draggableId={value.id} index={index}>
//             {(provided, snapshot) => (
//               <>
//                 <div
//                   ref={provided.innerRef}
//                   {...provided.draggableProps}
//                   {...provided.dragHandleProps}
//                 >
//                   {value.content}
//                 </div>
//                 {snapshot.isDragging && <div>{value.content}</div>}
//               </>
//             )}
//           </Draggable>
//         ))}
//       </div>
//       {provided.placeholder}
//     </div>
//   )}
// </Droppable>
