import { useState } from "react";
import NotificationSettingsContent from "../../../global/components/NotificationSettingsContent";
import { NotificationSettingsForm } from "../../../global/components/NotificationSettingsForm";

export function Notification() {
  const [selectedSetting, setSelectedSetting] = useState(null);

  return (
    <div>
      <NotificationSettingsForm>
        <NotificationSettingsContent
          selectedSetting={selectedSetting}
          setSelectedSetting={setSelectedSetting}
        />
      </NotificationSettingsForm>
    </div>
  );
}
