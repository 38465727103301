const monthNames = [
  "0", // 0 MONTH
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function getMonthFromNumber(monthNumber: number) {
  return monthNames[monthNumber];
}
