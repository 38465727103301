import {
  MetaActivity,
  MetaActivityAd,
  useMetaCatalogueProductsQuery,
} from "src/graphql/generated/schema";
import { useAdAggregatedStats } from "../../global/functions/useAdAggregatedStats";
import { getLineItemsFromAdInput } from "src/modules/campaign-wizard/functions/getLineItemsFromAdInput";
import { getThumbnailAssetFromAd } from "../functions/getThumbnailAssetFromAd";
import { Spinner } from "src/components/Spinner/Spinner";
import ActivityAdAccordion from "./ActivityAdAccordion";
import { ActivityAdPreviewModal } from "./ActivityAdPreviewModal";
import { useState } from "react";

interface IActivityAdAccordionLoader {
  ad: MetaActivityAd;
  activity: Pick<
    MetaActivity,
    "productCatalogueId" | "activityType" | "MetaAdAccount"
  >;
  dateFrom: Date;
  dateTo: Date;
  selectedAd: string;
  setSelectedAd: any;
}

export function ActivityAdAccordionLoader({
  ad,
  activity,
  dateFrom,
  dateTo,
  selectedAd,
  setSelectedAd,
}: IActivityAdAccordionLoader) {
  const {
    data: adInsight,
    loading,
    compareData: compareAdInsight,
  } = useAdAggregatedStats(
    ad.id,
    {
      dateFrom,
      dateTo,
    },
    false,
  );

  const {
    data: catalogueProductsData,
    loading: catalogueProductLoading,
    // error,
  } = useMetaCatalogueProductsQuery({
    variables: {
      metaCatalogueId: activity?.productCatalogueId,
    },
  });

  if (loading || catalogueProductLoading) {
    return <Spinner height={400} />;
  }

  const catalogueProducts = catalogueProductsData
    ? catalogueProductsData.metaCatalogueProducts
    : [];

  const lineItems = getLineItemsFromAdInput({
    ad: {
      existingPostCTALink: ad.existingPostCTALink,
      LineItems: ad.LineItems,
      staticAdType: ad.adType,
    },
    catalogueProducts: catalogueProducts,
    metaMedia: ad?.ExistingPost,
  });

  const thumbnail = getThumbnailAssetFromAd(ad);

  return (
    <div>
      <ActivityAdAccordion
        activity={activity}
        ad={ad}
        lineItems={lineItems}
        thumbnail={thumbnail}
        adInsight={adInsight}
        compareAdInsight={compareAdInsight}
        setSelectedAd={setSelectedAd}
      />
    </div>
  );
}
