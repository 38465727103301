import classNames from "classnames";
import styles from "./SimpleTable.module.scss";
import { IconButton } from "../IconButton/IconButton";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back_ios.svg";
import { ReactComponent as ArrowForward } from "@material-design-icons/svg/outlined/arrow_forward_ios.svg";

export interface ISimpleTable {
  headings: React.ReactNode[];
  rows: React.ReactNode[][];
  showHeadings: boolean;
  onPrevious?: (i: any) => void;
  onNext?: (i: any) => void;
  hasPrevious?: boolean;
  hasNext?: boolean;
  showFooter?: boolean;
  className?: string;
  label?: React.ReactNode;
}

export default function SimpleTable({
  headings,
  rows,
  onPrevious,
  onNext,
  hasPrevious,
  hasNext,
  className,
  label,
  showHeadings = true,
  showFooter = false,
}: ISimpleTable) {
  return (
    <div className={styles.tableContainer}>
      <table className={classNames(styles.table, className)}>
        <thead
          className={classNames({ [styles.tableHeadings]: !showHeadings })}
        >
          <tr className={styles.tableHeader}>
            {headings.map((heading, index) => (
              <th key={index} className={styles.tableHeading}>
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              className={classNames(styles.bodyRow, {
                [styles.lastRow]: index + 1 === rows.length,
              })}
            >
              {/* mapping over headings prevents extra rows and helps with auto fill empty fields */}
              {headings.map((_, idx) => (
                <td
                  key={idx}
                  className={classNames(styles.tableDimension, {
                    [styles.badgeCol]: !showHeadings,
                  })}
                >
                  {row[idx] ? row[idx] : "-"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* Footer */}
      {showFooter && (
        <div className={styles.tableFooter}>
          <div className={styles.footer}>
            <IconButton
              icon={
                <ArrowBack
                  width={18}
                  height={18}
                  className={classNames({
                    [styles.hasPrev]: hasPrevious,
                    [styles.notPrev]: !hasPrevious,
                  })}
                />
              }
              style="plain"
              onClick={onPrevious}
            />
            <span className={styles.footerLabel}>{label}</span>
            <IconButton
              icon={
                <ArrowForward
                  width={18}
                  height={18}
                  className={classNames({
                    [styles.hasNext]: hasNext,
                    [styles.notNext]: !hasNext,
                  })}
                />
              }
              style="plain"
              onClick={onNext}
            />
          </div>
        </div>
      )}
    </div>
  );
}
