import { FormikContextType } from "formik";
import { checkForDuplicatedAssetInLineItems } from "src/lib/checkForDuplicatedAssetInLineItems";
import { checkPlacementsInLineItems } from "src/lib/checkPlacementsInLineItems";
import { LineItems } from "src/lib/types";

interface VerifyLineItemFormValues {
  LineItems: LineItems[];
  [x: string]: any;
}

interface VerifyLineItemAssetsArgs<I = any> {
  values: VerifyLineItemFormValues;
  setFieldError: FormikContextType<I>["setFieldError"];
}

export function verifyLineItemAssets({
  values,
  setFieldError,
}: VerifyLineItemAssetsArgs) {
  const hasInvalidPlacement = checkPlacementsInLineItems(values.LineItems);
  const hasDuplicatedAssets = checkForDuplicatedAssetInLineItems(
    values.LineItems
  );

  if (hasInvalidPlacement) {
    setFieldError(
      `LineItems[${hasInvalidPlacement.lineItemIndex}].${hasInvalidPlacement.assetType}`,
      hasInvalidPlacement.message
    );

    return {
      key: `LineItems[${hasInvalidPlacement.lineItemIndex}].${hasInvalidPlacement.assetType}`,
      message: hasInvalidPlacement.message,
    };
  }

  if (hasDuplicatedAssets) {
    setFieldError(
      `LineItems[${hasDuplicatedAssets.lineItemIndex}].${hasDuplicatedAssets.assetType}`,
      hasDuplicatedAssets.message
    );

    return {
      key: `LineItems[${hasDuplicatedAssets.lineItemIndex}].${hasDuplicatedAssets.assetType}`,
      message: hasDuplicatedAssets.message,
    };
  }
  return null;
}
