import { gql } from "@apollo/client";

const GET_BILLING = gql`
  query getBilling {
    billing {
      id
      createdAt
      paymentProvider
      status
      updatedAt
      startedAt
      renewsAt
      expiresAt
      totalAmount
      taxAmount
      taxIds
      subtotalAmount
      cycle
      BillingEngagePlan {
        id
        createdAt
        updatedAt
        type
        customerCount
        emailsCount
        monthlyCredits
      }
      BillingGrowPlan {
        id
        createdAt
        updatedAt
        type
        monthlyAdSpend
      }
    }
  }
`;

export default GET_BILLING;
