import styles from "./FacebookSubSteps.module.scss";
import { CampaignInput } from "../misc/createCampaignTypes";
import { PlatformAndReach } from "./PlatformAndReach";
import { useEffect } from "react";
import { ChooseFacebookCatalogue } from "./ChooseFacebookCatalogue";
import { SelectAudienceType } from "./SelectAudienceType";
import { useNavigate } from "react-router-dom";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";
import { ChooseMetaEngagementConversionLocationForm } from "./ChooseMetaEngagementConversionLocationForm";
import ChooseMetaAdPlacementTypeForm from "./ChooseMetaAdPlacementType";
import { ChooseMetaAdPlacementOptionsForm } from "./ChooseMetaAdPlacementOptions";
import ChooseFacebookAccountSettings from "./ChooseFacebookAccountSettings";
import { ChooseMetaObjectiveAndSubtypesForm } from "./ChooseMetaObjectiveAndSubtypesForm";
import { CreateCampaignPageLayout } from "./CreateCampaignPageLayout";

const supportedPlatforms = ["META", "EMAIL"];

export function FacebookSubSteps() {
  const {
    currentNode,
    goBack,
    data,
    breadcrumbs,
    setData,
    markNodeAsComplete,
  } = useWizardContext<CampaignInput>();
  const currentStep = currentNode.id;
  const navigate = useNavigate();
  const selectedActivity = data.Activities[data.selectedActivityIndex];

  useEffect(() => {
    if (currentStep !== "choosePlatformAndActivityName") {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [currentStep]);

  function handlePlatformUpsert(v: CampaignInput) {
    const selectedActivity = v.Activities[v.selectedActivityIndex];
    if (supportedPlatforms.includes(selectedActivity.platform)) {
      markNodeAsComplete(v);
    } else {
      navigate("/outreaches/paywall");
    }
  }

  return (
    <CreateCampaignPageLayout
      breadcrumbs={breadcrumbs}
      campaignInput={data}
      campaignPresetUpsert={setData}
    >
      <div className={styles.pageLayout}>
        <PlatformAndReach
          campaignPresetUpsert={handlePlatformUpsert}
          campaignInput={data}
          handleBack={goBack}
        />
        {!selectedActivity.useDefaultSettings &&
          (currentStep === "chooseFacebookAccount" ||
            currentStep === "chooseAdPlacementType" ||
            currentStep === "chooseAdPlacementOptions" ||
            currentStep === "chooseFacebookAdType" ||
            currentStep === "chooseFacebookEngagementType" ||
            currentStep === "selectCatalogue") && (
            <ChooseFacebookAccountSettings
              handleBack={goBack}
              campaignPresetUpsert={markNodeAsComplete}
              campaignInput={data}
            />
          )}
        {(currentStep === "chooseObjectiveAndSubtypes" ||
          currentStep === "chooseAdPlacementType" ||
          currentStep === "chooseAdPlacementOptions" ||
          currentStep === "chooseFacebookAdType" ||
          currentStep === "chooseFacebookEngagementType" ||
          currentStep === "selectCatalogue") && (
          <ChooseMetaObjectiveAndSubtypesForm
            handleBack={goBack}
            campaignPresetUpsert={markNodeAsComplete}
            campaignInput={data}
          />
        )}
        {(currentStep === "chooseAdPlacementType" ||
          currentStep === "chooseAdPlacementOptions" ||
          currentStep === "chooseFacebookAdType" ||
          currentStep === "chooseFacebookEngagementType" ||
          currentStep === "selectCatalogue") && (
          <ChooseMetaAdPlacementTypeForm
            handleBack={goBack}
            campaignPresetUpsert={markNodeAsComplete}
            campaignInput={data}
          />
        )}
        {selectedActivity.adPlacementType === "ADVANCED" &&
          (currentStep === "chooseAdPlacementOptions" ||
            currentStep === "chooseFacebookAdType" ||
            currentStep === "chooseFacebookEngagementType" ||
            currentStep === "selectCatalogue") && (
            <ChooseMetaAdPlacementOptionsForm
              handleBack={goBack}
              campaignPresetUpsert={markNodeAsComplete}
              campaignInput={data}
            />
          )}
        {/**
        {(currentStep === "chooseFacebookAdType" ||
          currentStep === "selectCatalogue") && (
          <ChooseFacebookActivityTypeForm
            handleBack={goBack}
            campaignPresetUpsert={markNodeAsComplete}
            campaignInput={data}
          />
        )}
        */}
        {currentStep === "chooseFacebookEngagementType" && (
          <ChooseMetaEngagementConversionLocationForm
            handleBack={goBack}
            campaignPresetUpsert={markNodeAsComplete}
            campaignInput={data}
          />
        )}
        {(currentStep === "selectCatalogue" ||
          currentStep === "selectAudienceType") && (
          <ChooseFacebookCatalogue
            handleBack={goBack}
            campaignPresetUpsert={markNodeAsComplete}
            campaignInput={data}
          />
        )}
        {currentStep === "selectAudienceType" && (
          <SelectAudienceType
            handleBack={goBack}
            campaignPresetUpsert={markNodeAsComplete}
            campaignInput={data}
          />
        )}
      </div>
    </CreateCampaignPageLayout>
  );
}
