import { useEffect } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { useMetaInterestsLazyQuery } from "src/graphql/generated/schema";
import { InterestsSelectionTable } from "./InterestsSelectionTable";
import { useDebouncedCallback } from "../../../lib/useDebouncedCallback";
import { removeDuplicatesByFieldName } from "../misc/removeDuplicatesByFieldName";
import styles from "./InterestsSelectionLoader.module.scss";

export function InterestsSelectionLoader({
  searchInputValue,
  onShowSelection,
  shouldShowSelection,
  adAccountId,
}: {
  shouldShowSelection: boolean;
  searchInputValue: string;
  onShowSelection: () => void;
  adAccountId: string;
}) {
  const [metaInterests, { called, data, loading, error }] =
    useMetaInterestsLazyQuery();
  const metaInterestsDebounced = useDebouncedCallback(metaInterests, 500);

  useEffect(() => {
    if (searchInputValue.length) {
      metaInterestsDebounced({
        variables: {
          limit: 10,
          query: searchInputValue,
          adAccountId: adAccountId,
        },
      });
    }
  }, [searchInputValue, adAccountId]);

  if (!called) {
    // show preselected interests

    return <p className={styles.emptyScreen}>Type to search interests</p>;
  }

  if (loading) {
    return <Spinner height={350} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const interests = removeDuplicatesByFieldName(
    data.metaInterests,
    "id",
  ).filter(
    (a) =>
      a.type === "interests" ||
      a.type === "behaviors" ||
      a.type === "work_employers",
  );

  return (
    <InterestsSelectionTable
      shouldShowSelection={shouldShowSelection}
      onShowSelection={onShowSelection}
      interests={interests}
    />
  );
}
