import { Form, Formik } from "formik";
import { CampaignInput } from "../misc/createCampaignTypes";
import { AudienceBudgetFields } from "./AudienceBudgetFields";
import { MetaProductSet } from "src/graphql/generated/schema";
import * as Yup from "yup";
import { adsetBudgetSchema } from "../misc/adsetBudgetSchema";
import { SaveCampaignWizardFormState } from "./SaveCampaignWizardFormState";
import { validateSelectedActivity } from "../misc/validateSelectedActivity";

interface IAudiencesBudgetForm {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  productSets: MetaProductSet[];
  footerText: string;
  handleBack: (i: CampaignInput) => unknown;
}

export function AudiencesBudgetForm({
  campaignPresetUpsert,
  productSets,
  footerText,
  handleBack,
  campaignInput,
}: IAudiencesBudgetForm) {
  const { Activities, selectedActivityIndex } = campaignInput;
  const isCatalogueActivity =
    Activities[selectedActivityIndex].metaPurpose === "SALES_CATALOG";

  const schema = isCatalogueActivity ? budgetAndProductSetSchema : budgetSchema;
  return (
    <Formik
      validate={async (values) =>
        await validateSelectedActivity(schema, values)
      }
      initialValues={campaignInput}
      onSubmit={(values: CampaignInput) => {
        campaignPresetUpsert(values);
      }}
    >
      <Form>
        <SaveCampaignWizardFormState />

        <AudienceBudgetFields
          handleBack={handleBack}
          footerText={footerText}
          isCatalogueActivity={isCatalogueActivity}
          productSets={productSets}
        />
      </Form>
    </Formik>
  );
}
const budgetAndProductSetSchema = Yup.object({
  // Activities: Yup.array(
  //   Yup.object({
  Adsets: Yup.array(
    Yup.object({
      budget: adsetBudgetSchema,
      promotedProductSetId: Yup.string()
        .required("promoted product set id is required")
        .nullable()
        .typeError("promoted product set id is required"),
    })
  ),
  //   })
  // ),
});

const budgetSchema = Yup.object({
  // Activities: Yup.array(
  //   Yup.object({
  Adsets: Yup.array(
    Yup.object({
      budget: adsetBudgetSchema,
    })
  ),
  //   })
  // ),
});
