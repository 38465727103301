import { useCustomerQuery } from "src/graphql/generated/schema";
import { useState } from "react";
import { AddTagsModal } from "../components/AddTagsModal";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { Button } from "src/components/Button/Button";
import { CustomerDeleteAlert } from "../components/CustomerDeleteAlert";
import { ManageCustomerModal } from "../components/ManageCustomerModal";
import { MoreInformation } from "../components/MoreInformation";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { SingleCustomerActivity } from "../components/SingleCustomerActivity";
import { SingleCustomerAnalytics } from "../components/SingleCustomerAnalytics";
import { SingleCustomerNotes } from "../components/SingleCustomerNotes";
import { Tabs } from "src/components/Tabs/Tabs";
import styles from "./CustomerSinglePage.module.scss";
import { useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import subDays from "date-fns/subDays";
import { convertDatetimeToDate } from "src/modules/global/functions/convertDatetimeToDate";
import { PageLoader } from "src/modules/global/components/PageLoader";

const pageTabs = [
  {
    label: "Activity",
    value: "ACTIVITY",
  },
  {
    label: "Analytics",
    value: "ANALYTICS",
  },
  {
    label: "Notes",
    value: "NOTES",
  },
];

export function CustomerSinglePage() {
  const { id } = useParams();
  const dateFrom = convertDatetimeToDate(subDays(new Date(), 30));
  const dateTo = convertDatetimeToDate(new Date());
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showManageCustomerModal, setShowManageCustomerModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("ACTIVITY");

  const {
    data,
    loading,
    error,
    refetch: customerRefetch,
  } = useCustomerQuery({
    variables: {
      id: id,
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }
  const customer = data.customer;

  return (
    <div className={styles.customerPage}>
      <div className={styles.mainSection}>
        <div className={styles.pageHeader}>
          <PageHeader
            title={
              <div className={styles.title}>
                <Button style="plain" size="micro" to="/contacts">
                  <ArrowBack />
                </Button>
                <h1 className={styles.titleText}>{customer.name}</h1>
              </div>
            }
          />
          <div className={styles.tabContainer}>
            <Tabs
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              tabs={pageTabs}
            />
          </div>
        </div>
        <div className={styles.bodyContainer}>
          {selectedTab === "ACTIVITY" && (
            <SingleCustomerActivity
              dateFrom={dateFrom}
              dateTo={dateTo}
              // @ts-ignore
              outreaches={data.customer.Outreaches}
            />
          )}
          {selectedTab === "ANALYTICS" && (
            <SingleCustomerAnalytics
              customer={data.customer}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          )}
          {selectedTab === "NOTES" && (
            <SingleCustomerNotes
              customerNotes={data.customer.Notes}
              customerId={id}
              // FIXME: why a userId is being sent here
              // userId={""}
            />
          )}
        </div>
      </div>

      <MoreInformation
        customer={customer}
        setShowAddTagsModal={setShowAddTagsModal}
      />
      <AddTagsModal
        showAddTagsModal={showAddTagsModal}
        setShowAddTagsModal={setShowAddTagsModal}
        customerId={id}
        customerRefetch={customerRefetch}
      />
      <CustomerDeleteAlert
        isOpen={showDeleteAlert}
        onClose={() => setShowDeleteAlert(false)}
      />
      <ManageCustomerModal
        isOpen={showManageCustomerModal}
        onClose={() => setShowManageCustomerModal(false)}
        showDeleteAlert={() => {
          setShowDeleteAlert(true);
        }}
      />
    </div>
  );
}
