import { useEffect } from "react";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { useAssetLazyQuery } from "../../../../graphql/generated/schema";
import { FormValues } from "../../misc/adTemplateCreateTypes";
import { AdTemplateSlideOver } from "../AdTemplateCreateSlideOver/AdTemplateCreateSlideOver";

interface IAdTemplateCreateSlideOverLoader {
  assetId: string;
  isOpen: boolean;
  onClose: () => void;
}
export function AdTemplateCreateSlideOverLoader({
  assetId,
  isOpen,
  onClose,
}: IAdTemplateCreateSlideOverLoader) {
  const [assetQueryFunc, { data, loading: assetLoading }] = useAssetLazyQuery();

  useEffect(() => {
    if (!assetId) return;
    assetQueryFunc({
      variables: {
        id: assetId,
      },
    });
  }, [assetId]);

  if (assetLoading) {
    return (
      <div>
        <Spinner height="screen" />
      </div>
    );
  }

  const asset = data?.asset || null;

  if (!asset) {
    return <div></div>;
  }

  const initialValues: FormValues = {
    primaryText: "",
    title: "New Ad Template",
    type: "SINGLE_IMAGE_OR_VIDEO",
    LineItems: [
      {
        order: 0,
        headline: "",
        description: "",
        postAssetId: asset.id,
        PostAsset: asset,
        link: "",
      },
    ],
  };

  return (
    <AdTemplateSlideOver
      isOpen={isOpen}
      onClose={onClose}
      initialValues={initialValues}
    />
  );
}
