import { AutomationSidebarFooter } from "./AutomationSidebarFooter";
import { ReactComponent as Toggle } from "@material-design-icons/svg/outlined/toggle_on.svg";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import styles from "./AutomationSettingsFooter.module.scss";

export function AutomationSettingsFooter() {
  return (
    <AutomationSidebarFooter>
      <div className={styles.dripFooter}>
        <div className={styles.settingsBtn}>
          <span className={styles.toggle}>
            <span>
              <Toggle />
            </span>
            <p className={styles.settingsText}>Settings</p>
          </span>
          <ChevronRight className={styles.icon} />
        </div>
      </div>
    </AutomationSidebarFooter>
  );
}
