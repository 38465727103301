import { getIn, useFormikContext } from "formik";
import { AdsetInput, CampaignInput } from "../misc/createCampaignTypes";
import styles from "./RetargetAudienceBudgetFields.module.scss";
import Select from "src/components/Select/Select";
import { retargetingAudiences } from "../misc/retargetingAudiences";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Button } from "src/components/Button/Button";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";
import { MetaProductSet } from "src/graphql/generated/schema";
import { ReactComponent as SettingsAccountBoxIcon } from "../assets/settingsAccountBox.svg";
import { Input } from "src/components/Input/Input";
import { ReactComponent as Accounts } from "@material-design-icons/svg/filled/manage_accounts.svg";
import { ReactComponent as BookMark } from "@material-design-icons/svg/filled/bookmark.svg";
import { ReactComponent as DashboardCustomize } from "@material-design-icons/svg/filled/dashboard_customize.svg";
import classNames from "classnames";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import { AudienceBudgetInput } from "./AudienceBudgetInput";

interface IAudienceBudgetFields {
  productSets: MetaProductSet[];
  isCatalogueActivity: boolean;
  footerText: string;
  handleBack: (i: CampaignInput) => void;
}

export function AudienceBudgetFields({
  productSets,
  footerText,
  isCatalogueActivity,
  handleBack,
}: IAudienceBudgetFields) {
  const { isSubmitting, values, isValid, errors } =
    useFormikContext<CampaignInput>();
  const adsets = values.Activities[values.selectedActivityIndex].Adsets;
  const name = `Activities[${values.selectedActivityIndex}].Adsets`;

  const adsetsBudgetFilled = adsets.filter((item) => item.budget);
  // const errorTag = `Activities[0].Adsets`;
  // console.log(getIn(errors, errorTag));

  // function getError(idx: number) {
  //   const errorArr = getIn(errors, errorTag);
  //   if (errorArr[idx].budget) {
  //     return true;
  //   }
  //   return false;
  // }

  return (
    <div className={styles.content}>
      <div className={styles.options}>
        {adsets.map((adset, index) => (
          <AudienceBudgetCard
            isCatalogueActivity={isCatalogueActivity}
            title={adset.name}
            productSets={productSets}
            baseKey={`${name}[${index}]`}
            description={``}
            templateSource={adset.type}
            templateId={adset.templateId}
            // hasError={getError(index)}
          />
        ))}
        <StickyFooter
          label={
            <span className={styles.footerLabel}>
              <InfoIcon width={20} height={20} fill="currentColor" />
              {footerText}
            </span>
          }
          actions={
            <span className={styles.actions}>
              <Button
                type="button"
                style="outline"
                color="subdued"
                onClick={() => handleBack(values)}
              >
                Back
              </Button>
              <Button
                loading={isSubmitting}
                type="submit"
                disabled={
                  !isValid || adsets.length !== adsetsBudgetFilled.length
                }
              >
                Save and Continue
              </Button>
            </span>
          }
        />
      </div>
    </div>
  );
}

interface IAudienceBudgetCard {
  title: string;
  baseKey: string;
  description: string;
  templateSource: AdsetInput["type"];
  templateId?: string;
  productSets: MetaProductSet[];
  isCatalogueActivity: boolean;
}

function AudienceBudgetCard({
  title,
  description,
  baseKey,
  isCatalogueActivity,
  templateSource,
  productSets,
  templateId,
}: IAudienceBudgetCard) {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleBlur,
  } = useFormikContext<CampaignInput>();

  const productSetIdName = `${baseKey}.promotedProductSetId`;
  const productSetIdValue = getIn(values, productSetIdName);

  const audienceIcon = {
    SET: <DashboardCustomize width={24} height={24} fill="#9C48AA" />,
    INDIVIDUAL: <Accounts width={24} height={24} fill="#9C48AA" />,
    SAVED_SET: <BookMark width={24} height={24} fill="#9C48AA" />,
  };

  const audienceSource =
    templateSource === "PRODUCT_RETARGET"
      ? retargetingAudiences.find((r) => r.id === templateId)
      : {
          iconBackgroundColor: "#F8F0FA",
          icon:
            // <SettingsAccountBoxIcon
            //   width={24}
            //   height={24}
            //   fill="var(--color-primary)"
            // />
            audienceIcon[templateSource],
        };

  const error = getIn(errors, baseKey);
  // if touched is not found it means this field is not edited
  const isTouched = getIn(touched, baseKey) ?? true;

  const fieldKey = `${baseKey}.budget`;
  const fieldValue = getIn(values, fieldKey);
  const fieldError = getIn(errors, fieldKey);
  const isFieldTouched = getIn(touched, fieldKey);

  return (
    <div className={styles.card}>
      <div className={styles.cardInfoSection}>
        <div className={styles.cardTitleWrapper}>
          <div
            style={{ backgroundColor: audienceSource.iconBackgroundColor }}
            className={styles.cardIcon}
          >
            {audienceSource.icon}
          </div>
          <div>
            <h3 className={styles.cardTitle}>{title}</h3>
            <p className={styles.cardDescription}>{description}</p>
          </div>
        </div>
        <div>
          <div className={styles.cardInputSection}>
            {isCatalogueActivity && (
              <Select
                value={productSetIdValue}
                placeholder="Select from catalog"
                onBlur={handleBlur}
                name={productSetIdName}
                onChange={(value) => {
                  setFieldValue(`${baseKey}.promotedProductSetId`, value);
                }}
                options={productSets.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
                label="Select Product Set"
                showScrollAfter={3}
              />
            )}
            {/* TODO: add Error Styling for field */}
            <div>
              <AudienceBudgetInput
                value={fieldValue}
                onChange={(value) => {
                  setFieldValue(fieldKey, value);
                }}
                onBlur={() => setFieldTouched(fieldKey)}
                error={isFieldTouched && fieldError}
              />
            </div>
          </div>
          {isCatalogueActivity && error?.promotedProductSetId && isTouched && (
            <span className={styles.error}>{error.promotedProductSetId}</span>
          )}
        </div>
      </div>
    </div>
  );
}
