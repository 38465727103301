import { ReactNode, useState } from "react";
import { CampaignInput } from "../misc/createCampaignTypes";
import { CreateCampaignPageLayout } from "./CreateCampaignPageLayout";
import { CreateFacebookAd } from "./CreateFacebookAd";
import { CampaignCreateForm } from "./CampaignCreateForm";
import {
  createFacebookDynamicActivitySchema,
  createFacebookEngagementActivitySchema,
  createFacebookPromotePostSchema,
  createFacebookStaticActivitySchema,
  createFacebookVideoActivitySchema,
} from "../misc/adSchema";
import { getNewAdTemplate } from "../misc/newAdTemplate";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";

export function CreateFacebookAdForm() {
  const {
    breadcrumbs,
    data: campaignInput,
    markNodeAsComplete,
  } = useWizardContext<CampaignInput>();
  const initialErrorBannerState = {
    content: "" as ReactNode,
    isOpen: false,
    type: "error" as const,
    onClose: () => {},
  };
  const [errorBanner, setErrorBanner] = useState(initialErrorBannerState);
  const _campaignInput = injectFirstAdIfNotAvailable(campaignInput);
  const selectedActivity =
    campaignInput.Activities[campaignInput.selectedActivityIndex];
  const isCatalogueActivity = selectedActivity.metaPurpose === "SALES_CATALOG";
  const isVideoEngagementActivity =
    selectedActivity.metaPurpose === "VIDEO_THRUPLAY" ||
    selectedActivity.metaPurpose === "VIDEO_2_SEC_VIEW";
  const isPromotePostActivity = selectedActivity.metaPurpose === "PROMOTE_POST";
  const isEngagementActivity =
    selectedActivity.metaPurpose !== "SALES_CATALOG" &&
    selectedActivity.metaPurpose !== "SALES_STANDARD";

  const campaignCreateSchema = isPromotePostActivity
    ? createFacebookPromotePostSchema
    : isVideoEngagementActivity
    ? createFacebookVideoActivitySchema
    : isEngagementActivity
    ? createFacebookEngagementActivitySchema
    : isCatalogueActivity
    ? createFacebookDynamicActivitySchema
    : createFacebookStaticActivitySchema;

  return (
    <CreateCampaignPageLayout
      campaignInput={campaignInput}
      campaignPresetUpsert={markNodeAsComplete}
      errorBanner={errorBanner}
      breadcrumbs={breadcrumbs}
    >
      <CampaignCreateForm
        schema={campaignCreateSchema}
        campaignInput={_campaignInput}
        campaignPresetUpsert={markNodeAsComplete}
      >
        <CreateFacebookAd
          isCatalogueActivity={isCatalogueActivity}
          setErrorBanner={setErrorBanner}
        />
      </CampaignCreateForm>
    </CreateCampaignPageLayout>
  );
}

function injectFirstAdIfNotAvailable(input: CampaignInput): CampaignInput {
  const { selectedActivityIndex, Activities } = input;

  const campaignWithAds = {
    ...input,
    Activities: Activities.map((a, index) => {
      if (
        index === selectedActivityIndex &&
        (!a?.Ads || a?.Ads?.length === 0)
      ) {
        return {
          ...a,
          Ads: [getNewAdTemplate(input)],
        };
      } else {
        return a;
      }
    }),
  };

  return campaignWithAds;
}
