import { CampaignInput } from "../misc/createCampaignTypes";
import { useMetaProductCatalogueQuery } from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { AudiencesBudgetForm } from "./AudiencesBudgetForm";

interface IRetargetAudiencesBudgetLoader {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  footerText: string;
  handleBack: (i: CampaignInput) => unknown;
}

export function RetargetAudiencesBudgetLoader({
  campaignPresetUpsert,
  footerText,
  campaignInput,
  handleBack,
}: IRetargetAudiencesBudgetLoader) {
  const { data, loading, error } = useMetaProductCatalogueQuery({
    variables: {
      metaCatalogueId:
        campaignInput.Activities[campaignInput.selectedActivityIndex]
          .productCatalogueId,
    },
  });

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading) {
    return <Spinner height="screen" />;
  }

  return (
    <AudiencesBudgetForm
      footerText={footerText}
      campaignInput={campaignInput}
      campaignPresetUpsert={campaignPresetUpsert}
      productSets={data.metaCatalogue.ProductSets}
      handleBack={handleBack}
    />
  );
}
