import { gql } from "@apollo/client";

const ALL_CAMPAIGNS = gql`
  query allCampaigns(
    $filters: AllCampaignsFiltersInput
    $sortBy: CampaignsSortBy
  ) {
    allCampaigns(filters: $filters, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          name
          objective
          type
          status
          platforms
          Activities {
            id
            name
            status
            createdAt
            platform
          }
          # Outreaches {
          #   id
          #   name
          #   createdAt
          # }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        itemsPerPage
        totalItems
      }
    }
  }
`;

export default ALL_CAMPAIGNS;
