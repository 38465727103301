import { Button } from "src/components/Button/Button";
import styles from "./MetaIntegrationAdmin.module.scss";
import { MetaUser } from "src/graphql/generated/schema";
import { MetaProfileSection } from "./MetaProfileSection";

interface IMetaIntegrationAdmin {
  user: MetaUser;
  revokeAccess: () => void;
}
export function MetaIntegrationAdmin({
  user,
  revokeAccess,
}: IMetaIntegrationAdmin) {
  return (
    <div className={styles.container}>
      <MetaProfileSection user={user} />
      <div className={styles.actionsContainer}>
        <Button onClick={revokeAccess} style="outline" color="danger">
          Revoke Access
        </Button>
      </div>
    </div>
  );
}
