import Avatar from "react-avatar";
import Badge from "../../../components/Badge/Badge";
import { ErrorHandler } from "../../../components/ErrorHandler";
import { Spinner } from "../../../components/Spinner/Spinner";
import { User } from "../../../graphql/generated/schema";
import { ReactComponent as MoreVert } from "@material-symbols/svg-600/rounded/more_vert.svg";
import { Dropdown } from "../../global/components/Dropdown";
import styles from "./OrganizationMembersTable.module.scss";

interface IOrganizationMembersTable {
  error: any;
  loading: boolean;
  organizationUsers: User[];
  isCurrentUserAdmin: boolean;
  creatorId: string;
  triggerManageMemberModal: (i: string) => void;
}

export function OrganizationMembersTable({
  error,
  loading,
  isCurrentUserAdmin = false,
  creatorId,
  organizationUsers,
  triggerManageMemberModal,
}: IOrganizationMembersTable) {
  if (error) {
    return (
      <div>
        <ErrorHandler error={error} />
      </div>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.layout}>
      {organizationUsers.map((v) => (
        <MemberItem
          user={v}
          // if the user is creator of organization
          disableActions={creatorId === v.id}
          triggerMemberManageModal={() => triggerManageMemberModal(v.id)}
        />
      ))}
    </div>
  );
}

function MemberItem({
  user,
  disableActions = false,
  triggerMemberManageModal,
}: {
  user: User;
  disableActions?: boolean;
  triggerMemberManageModal: () => void;
}) {
  return (
    <div className={styles.itemLayout}>
      <div className={styles.primarySection}>
        <Avatar name={user.name} className={styles.avatar} size="24" />
        <div className={styles.title}>{user.name}</div>
        <div className={styles.subtext}>{user.email}</div>
      </div>
      <div className={styles.clientsList}>
        {user.Clients?.slice(0, 2).map((v) => (
          <Badge
            className={styles.badge}
            leftAvatar={{
              name: v.name,
            }}
            label={v.name}
          />
        ))}
        {user.Clients?.length > 2 && (
          <Badge
            title={user?.Clients?.slice(2)
              .map((v) => v.name)
              .join(", ")}
            className={styles.badge}
            label={`+${user.Clients.length - 2}`}
          />
        )}
      </div>
      <div className={styles.actionWrapper}>
        <Dropdown
          title={<MoreVert height={24} width={24} />}
          items={[
            {
              children: "Manage permissions",
              onClick: triggerMemberManageModal,
              disabled: disableActions,
            },
            // {
            //   children: "Add Client",
            //   onClick: triggerAddClientModal,
            // },
          ]}
        />
      </div>
    </div>
  );
}
