import { useEffect } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { useMetaInterestsLazyQuery } from "src/graphql/generated/schema";
import { useDebouncedCallback } from "../../../lib/useDebouncedCallback";
import { removeDuplicatesByFieldName } from "../misc/removeDuplicatesByFieldName";
import styles from "./InterestsSelectionLoader.module.scss";
import { InterestsAndDemographicsSelector } from "./InterestsAndDemographicsSelector";
import { InterestFilters } from "../misc/audienceWorkshop";

interface IInterestsAndDemographicSelectorLoader {
  adAccountId: string;
  searchValue: string;
  filters: InterestFilters;
  onShowSelection: () => void;
}

export function InterestsAndDemographicsSelectorLoader({
  adAccountId,
  searchValue,
  filters,
  onShowSelection,
}: IInterestsAndDemographicSelectorLoader) {
  const [metaInterests, { called, data, loading, error }] =
    useMetaInterestsLazyQuery();
  const metaInterestsDebounced = useDebouncedCallback(metaInterests, 500);

  useEffect(() => {
    if (searchValue.length) {
      metaInterestsDebounced({
        variables: {
          limit: 10,
          query: searchValue,
          adAccountId: adAccountId,
        },
      });
    }
  }, [searchValue, adAccountId, metaInterestsDebounced]);

  if (!called) {
    // show preselected interests
    return <p className={styles.emptyScreen}>Type to search interests</p>;
  }

  if (loading) {
    return <Spinner height={350} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const interests = removeDuplicatesByFieldName(
    data.metaInterests,
    "id",
  ).filter((a) => {
    if (a.type === "interests" && filters.categories.includes("INTERESTS")) {
      return true;
    }

    if (a.type === "behaviors" && filters.categories.includes("BEHAVIOURS")) {
      return true;
    }

    if (
      a.type === "work_employers" &&
      filters.categories.includes("DEMOGRAPHICS")
    ) {
      return true;
    }

    return false;
  });

  return (
    <InterestsAndDemographicsSelector
      shouldShowSelection={true}
      onShowSelection={onShowSelection}
      interests={interests}
    />
  );
}
