import EmptyState from "src/components/EmptyState/EmptyState";
import { Spinner } from "src/components/Spinner/Spinner";
import { useMetaAdSetByIdsQuery } from "src/graphql/generated/schema";
import { AdsetAggregateType } from "src/modules/global/functions/useAdsetAggregatedStats";
import { AudienceReportingCard } from "src/modules/global/components/AudienceReportingCard";
import EmptyStateIcon from "src/modules/dashboard/assets/bestPerformingAudience.svg";
import styles from "./BestPerformingAudience.module.scss";

export function BestPerformingAudience({
  adsetAggregatedStats,
}: {
  adsetAggregatedStats: AdsetAggregateType[];
}) {
  const adsetIds = adsetAggregatedStats.map((v) => v.adsetId);
  const { data, loading } = useMetaAdSetByIdsQuery({
    variables: {
      ids: adsetIds,
    },
  });

  if (loading) {
    return <Spinner height={384} />;
  }

  if (adsetAggregatedStats.length === 0) {
    return (
      <div className={styles.emptyStateCard}>
        <div className={styles.container}>
          <EmptyState
            illustration={{
              width: 60,
              height: 60,
              source: EmptyStateIcon,
            }}
            title={"Best performing Audience"}
            action={{
              title: "Create Audience",
              link: "/audiences",
            }}
          >
            <div className={styles.emptyStateText}>
              It appears there's currently no readily available top-performing
              audience data sources.
            </div>
          </EmptyState>
        </div>
      </div>
    );
  }

  return (
    <AudienceReportingCard
      title={"Best performing Audience"}
      subtitle={"See which audience is performing good for campaign"}
      metaActivityAdsets={data.metaAdSetByIds}
      adsetAggregateData={adsetAggregatedStats || null}
    />
  );
}
