import Modal from "src/components/Modal/Modal";
import { AssetEditor } from "./AssetEditor";
import { Button } from "src/components/Button/Button";
import styles from "./AssetEditorModal.module.scss";
import { useEffect, useRef, useState } from "react";
import { PixelCrop } from "react-image-crop";
import { useCreateAsset } from "../functions/useCreateAsset";
import {
  imageMimeTypes,
  imageAndVideoMimeType,
  videoMimeTypes,
} from "../misc/supportedAssetTypes";
import { useFormikContext } from "formik";
import toast from "react-hot-toast";
import {
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from "src/modules/global/misc/maxSizes";

interface IAssetEditorModal {
  isOpen: boolean;
  imageUrl: string;
  onClose: () => void;
  aspectRatio: number;
  baseKey: string;
  adAccountId: string;
}

export function AssetEditorModal({
  isOpen,
  imageUrl: originalUrl,
  onClose,
  baseKey,
  aspectRatio,
  adAccountId,
}: IAssetEditorModal) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [startingImageUrl, setStartingImageUrl] = useState<string>();
  const { loading, handleFileUpload } = useCreateAsset({
    maxVideoFileSizeInBytes: MAX_FILE_SIZE_VIDEO,
    maxImageFileSizeInBytes: MAX_FILE_SIZE_IMAGE,
    validMimeType: imageAndVideoMimeType,
    videoMimeTypes,
    imageMimeTypes,
    adAccountId,
  });

  async function onCropApply() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    (ctx as any).drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await (offscreen as any).convertToBlob({
      type: "image/png",
    });

    const random = Math.floor(Math.random() * 1000000);
    const file = new File([blob], `cropped_${random}.png`, {
      type: "image/png",
    });

    const asset = await handleFileUpload([file]);
    setFieldValue(baseKey, asset);
    setFieldTouched(baseKey);
    onClose();
  }

  async function fetchOriginalImage(uri: string) {
    try {
      const res = await fetch(uri);
      const imageBlob = await res.blob();
      const imageObjectUrl = URL.createObjectURL(imageBlob);
      setStartingImageUrl(imageObjectUrl);
    } catch (error) {
      toast.error("Something went wrong");
    }
  }
  useEffect(() => {
    fetchOriginalImage(originalUrl);
  }, [originalUrl]);

  useEffect(() => {
    return () => {
      if (startingImageUrl) {
        URL.revokeObjectURL(startingImageUrl);
      }
    };
  }, [startingImageUrl]);

  return (
    <Modal
      title="Image Editor"
      maxWidth={"760px"}
      isOpen={isOpen && !!startingImageUrl}
      footer={{
        footerContent: "",
        footerActions: (
          <div className={styles.editorActions}>
            <Button onClick={onClose} color="subdued" style="outline">
              Cancel
            </Button>
            <Button loading={loading} onClick={onCropApply}>
              Apply
            </Button>
          </div>
        ),
      }}
      onClose={onClose}
      noContainer
    >
      <AssetEditor
        imgRef={imgRef}
        previewCanvasRef={previewCanvasRef}
        setCompletedCrop={setCompletedCrop}
        completedCrop={completedCrop}
        aspectRatio={aspectRatio}
        imageUrl={startingImageUrl}
      />
    </Modal>
  );
}
