import DefaultHeader from "./DefaultHeader";
import DistractionFreeHeader from "./DistractionFreeHeader";
import { ReactNode } from "react";
import { User } from "src/graphql/generated/schema";

export interface IHeader {
  title?: React.ReactNode | string;
  primaryAction?: {
    label: ReactNode;
    onClick: () => void;
  };
  actions?: React.ReactNode;
  titleMetaData?: {
    content?: string;
    status?: "danger" | "success" | "attention" | "default";
    onClick?: () => void;
  };
  user?: User;
  mode?: "DEFAULT" | "DISTRACTION_FREE";
}

export default function Header(props: IHeader) {
  const {
    mode = "DEFAULT",
    title,
    primaryAction,
    titleMetaData,
    user,
    actions,
  } = props;

  if (mode === "DISTRACTION_FREE") {
    return (
      <DistractionFreeHeader
        title={title}
        primaryAction={primaryAction}
        actions={actions}
      />
    );
  }
  return (
    <DefaultHeader title={title} titleMetaData={titleMetaData} user={user} />
  );
}
