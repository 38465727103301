import styles from "./SegmentSectionHeading.module.scss";

interface ISegmentSectionHeading {
  subHeading: React.ReactNode;
  description: React.ReactNode;
}

export function SegmentSectionHeading({
  subHeading,
  description,
}: ISegmentSectionHeading) {
  return (
    <>
      <h2 className={styles.subHeading}>{subHeading}</h2>
      <p className={styles.description}>{description}</p>
    </>
  );
}
