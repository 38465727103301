import classNames from "classnames";
import styles from "./StepWizardStep.module.scss";

interface IStepWizardStep {
  children: React.ReactNode;
  isCompleted: boolean;
  isLastStep?: boolean;
  isStepNotActive?: boolean;
}

export function StepWizardStep({
  isLastStep,
  isCompleted,
  isStepNotActive,
  children,
}: IStepWizardStep) {
  return (
    <div
      className={classNames(styles.sectionWrapper, {
        [styles.sectionWrapperCompleted]: isCompleted,
        [styles.sectionWrapperLastItem]: isLastStep,
      })}
    >
      {isStepNotActive && <div className={styles.isStepNotActiveYetOverlay} />}

      {children}
    </div>
  );
}
