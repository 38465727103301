import { Button } from "src/components/Button/Button";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { SegmentReviewStep } from "./SegmentReviewStep";
import TemplateSelector from "src/components/TemplateSelector/TemplateSelector";
import styles from "./SegmentCreateModalContent.module.scss";
import { ReactComponent as CheckCircle } from "@material-design-icons/svg/filled/check_circle.svg";
import { ReactComponent as Error } from "@material-design-icons/svg/filled/error.svg";
import { useFormikContext } from "formik";
import classNames from "classnames";

export function SegmentCreateModalContent({ segmentTemplateList }) {
  const { values, isValid, handleSubmit, setFieldValue } =
    useFormikContext<any>();

  switch (values.step) {
    case "NAME_FORM":
      return (
        <div className={styles.modalContent}>
          <h3 className={styles.title}>Name your new segment</h3>
          <p className={styles.description}>
            Give name to your new created segment which may define the purpose
            of segment
          </p>

          <InputFormik name="name" placeholder="Enter Segment Name" />
          <Button
            width="full"
            style="solid"
            color="primary"
            disabled={!isValid}
            // TODO: for some reason submit is not working ???
            // type="submit"
            onClick={handleSubmit}
          >
            Save Segment
          </Button>
        </div>
      );

    case "SELECT_TEMPLATE":
      return (
        <div className={styles.templateSelectWrapper}>
          <TemplateSelector
            primaryAction={{
              children: "Use Template",
              onClick: () => {
                handleSubmit();
              },
              disabled: values.template.customersCount.selectedCustomers === 0,
            }}
            templatesList={segmentTemplateList}
            footer={
              <div
                className={classNames(styles.footerText, {
                  [styles.greenText]:
                    values &&
                    values.template.customersCount?.selectedCustomers > 0,
                })}
              >
                {values &&
                values.template.customersCount?.selectedCustomers > 0 ? (
                  <>
                    <CheckCircle
                      width={16}
                      height={16}
                      fill="var(--color-success)"
                    />
                    Compatible according to your customer
                  </>
                ) : (
                  <>
                    <Error width={16} height={16} fill="var(--color-danger)" />
                    Not compatible according to your customer
                  </>
                )}
              </div>
            }
            selectedTemplate={values.template}
            setSelectedTemplate={(value) => setFieldValue("template", value)}
          >
            <div className={styles.descriptionContainer}>
              <h5 className={styles.subHeading}>Description</h5>
              <p className={styles.tempDescription}>
                {values.template.description}
              </p>
            </div>
            <div>
              <h5 className={styles.subHeading}>Filter Query</h5>
              <div className={styles.filterQuery}>
                <code>{values.template.filter}</code>
              </div>
            </div>
          </TemplateSelector>
        </div>
      );

    case "REVIEW":
      if (values.template) {
        return <SegmentReviewStep filter={values.template.filter} />;
      } else {
        return <div />;
      }
    default:
      return <div></div>;
  }
}
