import { ReactComponent as VisibilityIcon } from "@material-design-icons/svg/outlined/visibility.svg";
import { ReactComponent as ShoppingBagIcon } from "@material-design-icons/svg/outlined/shopping_cart.svg";
import { ReactComponent as AirlineStop } from "@material-design-icons/svg/outlined/airline_stops.svg";
import { ProductSetSpec } from "./createCampaignTypes";

export const retargetingAudiences = [
  {
    title: "Viewed and add to cart in last 7 days",
    description: "Lookalike from people who have visited in last 7 days",
    type: "VIEWERS",
    iconBackgroundColor: "#F2F6FC",
    id: "VIEWERS_7",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "ViewContent",
            eventOperator: "EQUALS",
            // 7 days
            retentionSeconds: 86400 * 7,
            ruleType: "INCLUSION",
          },
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 7,
            ruleType: "INCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    icon: (
      <VisibilityIcon width={16} height={16} fill="var(--color-interactive)" />
    ),
  },
  {
    title: "Viewed and add to cart  in last 30 days",
    description: "Lookalike from people who have visited in last 30 days",
    type: "VIEWERS",
    iconBackgroundColor: "#F2F6FC",
    id: "VIEWERS_30",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "ViewContent",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 30,
            ruleType: "INCLUSION",
          },
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 30,
            ruleType: "INCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    icon: (
      <VisibilityIcon width={16} height={16} fill="var(--color-interactive)" />
    ),
  },
  {
    title: "Viewed and add to cart  in last 60 days",
    description: "Lookalike from people who have visited in last 60 days",
    type: "VIEWERS",
    iconBackgroundColor: "#F2F6FC",
    id: "VIEWERS_60",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "ViewContent",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 60,
            ruleType: "INCLUSION",
          },
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 60,
            ruleType: "INCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    icon: (
      <VisibilityIcon width={16} height={16} fill="var(--color-interactive)" />
    ),
  },
  {
    title: "Viewed and add to cart  in last 90 days",
    description: "Lookalike from people who have visited in last 90 days",
    type: "VIEWERS",
    iconBackgroundColor: "#F2F6FC",
    id: "VIEWERS_90",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "ViewContent",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 90,
            ruleType: "INCLUSION",
          },
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 90,
            ruleType: "INCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    icon: (
      <VisibilityIcon width={16} height={16} fill="var(--color-interactive)" />
    ),
  },
  {
    title: "Viewed and add to cart  in last 180 days",
    description: "Lookalike from people who have visited in last 180 days",
    type: "VIEWERS",
    iconBackgroundColor: "#F2F6FC",
    id: "VIEWERS_180",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "ViewContent",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 180,
            ruleType: "INCLUSION",
          },
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 180,
            ruleType: "INCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    icon: (
      <VisibilityIcon width={16} height={16} fill="var(--color-interactive)" />
    ),
  },
  // {
  //   title: "Viewed and add to cart  in last 270 days",
  //   description: "Lookalike from people who have visited in last 270 days",
  //   type: "VIEWERS",
  //   iconBackgroundColor: "#F2F6FC",
  //   id: "VIEWERS_270",
  //   productSetSpecs: [
  //     {
  //       productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
  //       Rules: [
  //         {
  //           eventName: "ViewContent",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 270,
  //           ruleType: "INCLUSION",
  //         },
  //         {
  //           eventName: "AddToCart",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 270,
  //           ruleType: "INCLUSION",
  //         },
  //       ],
  //     },
  //   ] as ProductSetSpec[],
  //   icon: (
  //     <VisibilityIcon width={16} height={16} fill="var(--color-interactive)" />
  //   ),
  // },
  // {
  //   title: "Viewed and add to cart  in last 360 days",
  //   description: "Lookalike from people who have visited in last 360 days",
  //   type: "VIEWERS",
  //   iconBackgroundColor: "#F2F6FC",
  //   id: "VIEWERS_360",
  //   productSetSpecs: [
  //     {
  //       productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
  //       Rules: [
  //         {
  //           eventName: "ViewContent",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 360,
  //           ruleType: "INCLUSION",
  //         },
  //         {
  //           eventName: "AddToCart",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 360,
  //           ruleType: "INCLUSION",
  //         },
  //       ],
  //     },
  //   ] as ProductSetSpec[],
  //   icon: (
  //     <VisibilityIcon width={16} height={16} fill="var(--color-interactive)" />
  //   ),
  // },
  {
    title: "Abandoned in last 7 days",
    description: "Lookalike from people who have visited in last 7 days",
    type: "ABANDONED",
    id: "ABANDONED_7",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "InitiateCheckout",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 7,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 7,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#F1F8F5",
    icon: (
      <ShoppingBagIcon width={16} height={16} fill="var(--color-success)" />
    ),
  },
  {
    title: "Abandoned in last 30 days",
    description: "Lookalike from people who have visited in last 30 days",
    type: "ABANDONED",
    id: "ABANDONED_30",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "InitiateCheckout",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 30,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 30,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#F1F8F5",
    icon: (
      <ShoppingBagIcon width={16} height={16} fill="var(--color-success)" />
    ),
  },
  {
    title: "Abandoned in last 60 days",
    description: "Lookalike from people who have visited in last 60 days",
    type: "ABANDONED",
    id: "ABANDONED_60",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "InitiateCheckout",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 60,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 60,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#F1F8F5",
    icon: (
      <ShoppingBagIcon width={16} height={16} fill="var(--color-success)" />
    ),
  },
  {
    title: "Abandoned in last 90 days",
    description: "Lookalike from people who have visited in last 90 days",
    type: "ABANDONED",
    id: "ABANDONED_90",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "InitiateCheckout",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 90,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 90,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#F1F8F5",
    icon: (
      <ShoppingBagIcon width={16} height={16} fill="var(--color-success)" />
    ),
  },
  {
    title: "Abandoned in last 180 days",
    description: "Lookalike from people who have visited in last 180 days",
    type: "ABANDONED",
    id: "ABANDONED_180",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "InitiateCheckout",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 180,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 180,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#F1F8F5",
    icon: (
      <ShoppingBagIcon width={16} height={16} fill="var(--color-success)" />
    ),
  },
  // {
  //   title: "Abandoned in last 270 days",
  //   description: "Lookalike from people who have visited in last 270 days",
  //   type: "ABANDONED",
  //   id: "ABANDONED_270",
  //   productSetSpecs: [
  //     {
  //       productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
  //       Rules: [
  //         {
  //           eventName: "InitiateCheckout",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 270,
  //           ruleType: "INCLUSION",
  //         },
  //         {
  //           eventName: "Purchase",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 270,
  //           ruleType: "EXCLUSION",
  //         },
  //       ],
  //     },
  //   ] as ProductSetSpec[],
  //   iconBackgroundColor: "#F1F8F5",
  //   icon: (
  //     <ShoppingBagIcon width={16} height={16} fill="var(--color-success)" />
  //   ),
  // },
  // {
  //   title: "Abandoned in last 360 days",
  //   description: "Lookalike from people who have visited in last 360 days",
  //   type: "ABANDONED",
  //   id: "ABANDONED_360",
  //   productSetSpecs: [
  //     {
  //       productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
  //       Rules: [
  //         {
  //           eventName: "InitiateCheckout",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 360,
  //           ruleType: "INCLUSION",
  //         },
  //         {
  //           eventName: "Purchase",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 360,
  //           ruleType: "EXCLUSION",
  //         },
  //       ],
  //     },
  //   ] as ProductSetSpec[],
  //   iconBackgroundColor: "#F1F8F5",
  //   icon: (
  //     <ShoppingBagIcon width={16} height={16} fill="var(--color-success)" />
  //   ),
  // },
  {
    title: "Add to cart in last 7 days",
    description: "Lookalike from people who have visited in last 7 days",
    type: "ADD_TO_CART",
    id: "ADD_TO_CART_7",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 7,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 7,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#FFF5EA",
    icon: <AirlineStop width={16} height={16} fill="var(--color-warning)" />,
  },
  {
    title: "Add to cart in last 30 days",
    description: "Lookalike from people who have visited in last 30 days",
    type: "ADD_TO_CART",
    id: "ADD_TO_CART_30",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 30,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 30,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#FFF5EA",
    icon: <AirlineStop width={16} height={16} fill="var(--color-warning)" />,
  },
  {
    title: "Add to cart in last 60 days",
    description: "Lookalike from people who have visited in last 60 days",
    type: "ADD_TO_CART",
    id: "ADD_TO_CART_60",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 60,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 60,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#FFF5EA",
    icon: <AirlineStop width={16} height={16} fill="var(--color-warning)" />,
  },
  {
    title: "Add to cart in last 90 days",
    description: "Lookalike from people who have visited in last 90 days",
    type: "ADD_TO_CART",
    id: "ADD_TO_CART_90",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 90,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 90,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#FFF5EA",
    icon: <AirlineStop width={16} height={16} fill="var(--color-warning)" />,
  },
  {
    title: "Add to cart in last 180 days",
    description: "Lookalike from people who have visited in last 180 days",
    type: "ADD_TO_CART",
    id: "ADD_TO_CART_180",
    productSetSpecs: [
      {
        productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
        Rules: [
          {
            eventName: "AddToCart",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 180,
            ruleType: "INCLUSION",
          },
          {
            eventName: "Purchase",
            eventOperator: "EQUALS",
            retentionSeconds: 86400 * 180,
            ruleType: "EXCLUSION",
          },
        ],
      },
    ] as ProductSetSpec[],
    iconBackgroundColor: "#FFF5EA",
    icon: <AirlineStop width={16} height={16} fill="var(--color-warning)" />,
  },
  // {
  //   title: "Add to cart in last 270 days",
  //   description: "Lookalike from people who have visited in last 270 days",
  //   type: "ADD_TO_CART",
  //   id: "ADD_TO_CART_270",
  //   productSetSpecs: [
  //     {
  //       productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
  //       Rules: [
  //         {
  //           eventName: "AddToCart",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 270,
  //           ruleType: "INCLUSION",
  //         },
  //         {
  //           eventName: "Purchase",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 270,
  //           ruleType: "EXCLUSION",
  //         },
  //       ],
  //     },
  //   ] as ProductSetSpec[],
  //   iconBackgroundColor: "#FFF5EA",
  //   icon: <AirlineStop width={16} height={16} fill="var(--color-warning)" />,
  // },
  // {
  //   title: "Add to cart in last 360 days",
  //   description: "Lookalike from people who have visited in last 360 days",
  //   type: "ADD_TO_CART",
  //   id: "ADD_TO_CART_360",
  //   productSetSpecs: [
  //     {
  //       productSetId: "TO_BE_FILLED_IN_NEXT_STEP",
  //       Rules: [
  //         {
  //           eventName: "AddToCart",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 360,
  //           ruleType: "INCLUSION",
  //         },
  //         {
  //           eventName: "Purchase",
  //           eventOperator: "EQUALS",
  //           retentionSeconds: 86400 * 360,
  //           ruleType: "EXCLUSION",
  //         },
  //       ],
  //     },
  //   ] as ProductSetSpec[],
  //   iconBackgroundColor: "#FFF5EA",
  //   icon: <AirlineStop width={16} height={16} fill="var(--color-warning)" />,
  // },
];
