import { ReactComponent as InfoIcon } from "@material-symbols/svg-400/outlined/info.svg";
import Tooltip from "src/components/Tooltip/Tooltip";
import styles from "./AudiencePresetSelector.module.scss";
import metaPresetIcon from "../assets/metaPreset.svg";
import instagramPresetIcon from "../assets/instagramPreset.svg";
import { SwitchSelectionTable } from "./SwitchSelectionTable";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceWorkshop";
import { useFormikContext } from "formik";
import { SelectorNoResults } from "./SelectorNoResults";
import { IAudiencePreset } from "../misc/createPreviewBasedAudienceTypes";

interface IAudiencePresetSelector {
  shouldShowSelection: boolean;
  onShowSelection?: () => void;
  audiencePresets: IAudiencePreset[];
}

export function AudiencePresetSelector({
  audiencePresets,
  shouldShowSelection,
  onShowSelection,
}: IAudiencePresetSelector) {
  const { values, setFieldValue } =
    useFormikContext<AudiencePresetAndInterestSelectorFormState>();

  function handleChange(newValue, item) {
    const audiencePreset = audiencePresets.find((a) => a.id === item.id);

    setFieldValue("audiencePresets", {
      ...values.audiencePresets,
      [item.id]: {
        value: newValue,
        category: audiencePreset.group.value,
        filter: audiencePreset.filter,
        type: audiencePreset.audienceType,
      },
    });
  }

  return (
    <div className={styles.wrapper}>
      <SwitchSelectionTable
        onShowSelection={onShowSelection}
        shouldShowSelection={shouldShowSelection}
        noItemsContent={<SelectorNoResults />}
        // fixed width for the switcher; else the header (which is empty) will not align
        gridTemplate="4fr 1fr 1fr 69px"
        headers={[
          "Preset Name",
          <span className={styles.source}>
            Source
            <Tooltip supportingText="Some text">
              <InfoIcon width={16} height={16} />
            </Tooltip>
          </span>,
          "Category",
        ]}
        items={audiencePresets.map((audience) => ({
          description: audience.description,
          icon: audience.icon,
          id: audience.id,
          name: audience.name,
          otherRows: [
            <AudiencePresetIcon platforms={audience.platforms} />,
            audience.group.label,
          ],
          title: audience.name,
        }))}
        onChange={handleChange}
        values={values.audiencePresets}
      />
    </div>
  );
}

function AudiencePresetIcon({
  platforms,
}: {
  platforms: Array<"FACEBOOK" | "INSTAGRAM">;
}) {
  const platformToIcon = {
    FACEBOOK: (
      <img alt="meta logo" src={metaPresetIcon} width={24} height={24} />
    ),
    INSTAGRAM: (
      <img
        alt="instagram logo"
        src={instagramPresetIcon}
        width={24}
        height={24}
      />
    ),
  };

  return (
    <span className={styles.platforms}>
      {platforms.map((platform) => (
        <span className={styles.platformItem}>{platformToIcon[platform]}</span>
      ))}
    </span>
  );
}
