import { ReactComponent as CircleExt } from "@material-symbols/svg-400/rounded/circles_ext-fill.svg";
import { ReactComponent as AltRoute } from "@material-design-icons/svg/outlined/alt_route.svg";
import { ReactComponent as Campaign } from "@material-design-icons/svg/outlined/campaign.svg";
import { ReactComponent as DataExploration } from "@material-design-icons/svg/filled/data_exploration.svg";
import { ReactComponent as Reset } from "@material-symbols/svg-400/rounded/reset_wrench.svg";

export const automationFeaturesArr = [
  {
    icon: <CircleExt />,
    title: "Share team inboxes",
    subtitle:
      "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the ",
  },
  {
    icon: <AltRoute />,
    title: "Cross Multi-platform",
    subtitle:
      "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the ",
  },
  {
    icon: <Campaign />,
    title: "Personalized Campaigns",
    subtitle:
      "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the ",
  },
  {
    icon: <DataExploration />,
    title: "Powerful Analytics",
    subtitle:
      "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the ",
  },
  {
    icon: <Reset />,
    title: "Campaign Builder",
    subtitle:
      "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the ",
  },
];
