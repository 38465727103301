import { useCustomerTagsQuery } from "src/graphql/generated/schema";
import { useEffect, useState } from "react";
import styles from "./CustomerTagsComboBox.module.scss";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";
import ComboTagMaker, {
  IComboTagMaker,
} from "src/components/ComboTagMaker/ComboTagMaker";
import { ErrorHandler } from "src/components/ErrorHandler";

export interface ICustomerTagsComboBox {
  onAddTag?: () => void;
  selectedTags: IComboTagMaker["selected"];
  setSelectedTags: IComboTagMaker["setSelected"];
  addTags: any;
  addTagsLoading: boolean;
  placeholder?: string;
}

export function CustomerTagsComboBox({
  onAddTag,
  selectedTags,
  setSelectedTags,
  addTags,
  addTagsLoading,
  placeholder,
}: ICustomerTagsComboBox) {
  const [options, setOptions] = useState([]);

  const {
    data: customerTagsData,
    loading: customerTagsLoading,
    error: customerTagsError,
    refetch: customerTagsDataRefetch,
  } = useCustomerTagsQuery();

  const debouncedCustomerTagsFetch = useDebouncedCallback(
    async (query: string) => {
      await customerTagsDataRefetch({
        query,
      });
    },
    200
  );

  useEffect(() => {
    if (!customerTagsData) return;
    if (!customerTagsData.customerTags) return;
    setOptions(
      customerTagsData.customerTags.map((v) => ({ label: v.name, value: v.id }))
    );
  }, [customerTagsData]);

  if (customerTagsError) {
    return <ErrorHandler error={customerTagsError} />;
  }

  return (
    <div className={styles.comboWrapper}>
      <ComboTagMaker
        options={
          customerTagsLoading
            ? [
                {
                  value: "",
                  label: "Loading Tags",
                },
              ]
            : options
        }
        multiple={true}
        selected={selectedTags}
        setSelected={setSelectedTags as any}
        placeholder={placeholder ? placeholder : ""}
        loading={addTagsLoading}
        onAddTag={async (addedTag: string, updatedTags: any[]) => {
          const createdTagData = await addTags({
            variables: {
              input: {
                tags: [addedTag],
              },
            },
          });

          const { data } = createdTagData;
          const updatedTagsWithId = updatedTags.map((v) => {
            return v.value === addedTag
              ? {
                  label: data.customerTagsCreate[0].name,
                  value: data.customerTagsCreate[0].id,
                }
              : v;
          });
          setSelectedTags(updatedTagsWithId);
          if (onAddTag) {
            onAddTag();
          }
        }}
        showTags={true}
        onChange={(value) => {
          debouncedCustomerTagsFetch(value);
        }}
        tagsTitle="Added Tags"
        tagsTitleClass={styles.separator}
      />
    </div>
  );
}
