import { useEffect } from "react";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { useAssetLazyQuery } from "../../../../graphql/generated/schema";
import AssetDetailsSlideOver from "./AssetDetailsSlideOver";

interface IAssetDetailsSlideOverLoader {
  assetId: string | null;
  isOpen: boolean;
  onClose: () => void;
}
export function AssetDetailsSlideOverLoader({
  assetId,
  isOpen,
  onClose,
}: IAssetDetailsSlideOverLoader) {
  const [assetQueryFunc, { data, loading: assetLoading }] = useAssetLazyQuery();

  useEffect(() => {
    if (!assetId) return;
    assetQueryFunc({
      variables: {
        id: assetId,
      },
    });
  }, [assetId]);

  if (assetLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const asset = data?.asset || null;

  if (!asset) {
    return <div></div>;
  }

  return (
    <AssetDetailsSlideOver isOpen={isOpen} onClose={onClose} asset={asset} />
  );
}
