import { useFormikContext } from "formik";
import { FormikError } from "src/components/FormikError/FormikError";
import { IconButton } from "src/components/IconButton/IconButton";
import Select from "src/components/Select/Select";
import {
  MetaPixelsQuery,
  useMetaPixelsLazyQuery,
  useMetaPixelsQuery,
} from "src/graphql/generated/schema";
import { SelectLabel } from "src/modules/campaign-wizard/components/SelectLabel";
import { ReactComponent as ArrowOutwardIcon } from "@material-symbols/svg-400/rounded/arrow_outward.svg";
import { ReactComponent as CaptivePortalIcon } from "@material-symbols/svg-400/rounded/captive_portal.svg";
import { useEffect } from "react";
import SelectV2 from "src/components/SelectV2/SelectV2";

interface IMetaPixelSelector {
  adAccountId: string;
  name: string;
  setSelectedPixel: (i: string, value: MetaPixelsQuery["metaPixels"]) => void;
  selectedPixelId: string;
}

export function MetaPixelSelector({
  adAccountId,
  name,
  setSelectedPixel,
  selectedPixelId,
}: IMetaPixelSelector) {
  const { touched, errors: formikError, submitCount } = useFormikContext();
  const [getMetaPixels, { data: metaPixelsData, loading: metaPixelsLoading }] =
    useMetaPixelsLazyQuery();

  useEffect(() => {
    if (!adAccountId) return;
    getMetaPixels({
      variables: {
        adAccountId: adAccountId,
      },
    });
  }, [adAccountId, getMetaPixels]);

  const metaPixels =
    metaPixelsData && metaPixelsData.metaPixels
      ? metaPixelsData.metaPixels.map((v) => ({
          label: v.name,
          value: v.id,
        }))
      : [];
  return (
    <div>
      <SelectV2
        icon={CaptivePortalIcon}
        emptyState={{
          title: "Connect Pixel",
          description: "Connect facebook pixel from which you want to run ads",
          to: "/settings/integrations/meta",
          action: (
            <IconButton
              icon={<ArrowOutwardIcon height={20} width={20} />}
              to="/settings/integrations/meta"
              type="button"
              color="primary"
              style="plain"
              shape="square"
            />
          ),
        }}
        label={
          <SelectLabel
            labelText={"Add Pixel ID"}
            tooltipText={"Add your Pixel ID for better tracking and analytics"}
          />
        }
        name={name}
        options={metaPixels}
        disabled={metaPixelsLoading}
        value={selectedPixelId}
        onChange={(value) => setSelectedPixel(value, metaPixelsData.metaPixels)}
        placeholder={metaPixelsLoading ? "Loading Pixels" : "Select Pixel ID"}
      />
      <FormikError
        touched={touched}
        formikError={formikError}
        submitCount={submitCount}
        fieldName={name}
      />
    </div>
  );
}
