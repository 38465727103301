import { Form, Formik } from "formik";
import styles from "./CreatePreviewBasedAudiencePage.module.scss";
import Header from "src/components/Header/Header";
import { ReactComponent as Store } from "@material-design-icons/svg/filled/store.svg";
import { ReactComponent as Group3 } from "@material-design-icons/svg/filled/groups_3.svg";
import { ReactComponent as Task } from "@material-design-icons/svg/filled/task.svg";
import Stepper from "src/components/Stepper/Stepper";
import { useState } from "react";
import Card from "src/components/Card/Card";
import { PresetAudienceSelector } from "../components/PresetAudienceSelector";
import { AddAudienceDetails } from "../components/AddAudienceDetails";
import { Review } from "../components/Review";
import {
  IAudiencePreset,
  IAudienceSetPreset,
  IAudienceWizardPreset,
  ICreatePreviewBasedAudienceFormInput,
  WizardFormStepValue,
  WizardStep,
} from "../misc/createPreviewBasedAudienceTypes";
import {
  usePlatformAudienceSetsCreateMutation,
  usePlatformAudiencesCreateMutation,
  useSegmentAudiencesCreateMutation,
} from "src/graphql/generated/schema";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function CreatePreviewBasedAudiencePage() {
  const [platformAudiencesCreateFn] = usePlatformAudiencesCreateMutation();
  const [segmentAudiencesCreateFn] = useSegmentAudiencesCreateMutation();
  const [platformAudienceSetsCreateFn] =
    usePlatformAudienceSetsCreateMutation();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const primaryAction = {
    label: <div>Cancel</div>,
    onClick: () => {
      navigate("/audiences");
    },
  };

  const initialValues: ICreatePreviewBasedAudienceFormInput = {
    selectedPresets: [],
    adAccountId: "",
    instagramPageId: "",
    metaPageId: "",
    pixelId: "",
    // TODO: use adAccounts from DB in switcher instead of meta ad accounts
    enabledAccountId: "",
  };

  function changeStep(v: WizardFormStepValue) {
    const activeStepIndex = wizardSteps.findIndex((item) => item.value === v);
    setActiveStep(activeStepIndex);
  }

  const wizardSteps: WizardStep[] = [
    {
      icon: <Store />,
      label: "Select Audience",
      value: "presetAudienceSelector",
      component: <PresetAudienceSelector />,
    },
    {
      icon: <Group3 />,
      label: "Add Audience Details",
      value: "addAudienceDetails",
      component: <AddAudienceDetails changeStep={changeStep} />,
    },
    {
      icon: <Task />,
      label: "Review And Save",
      value: "review",
      component: <Review changeStep={changeStep} />,
    },
  ];

  async function handleSubmit(values: ICreatePreviewBasedAudienceFormInput) {
    const activeStepName = wizardSteps[activeStep].value;

    if (activeStepName === "presetAudienceSelector") {
      setActiveStep(activeStep + 1);
    }

    if (activeStepName === "addAudienceDetails") {
      setActiveStep(activeStep + 1);
    }

    if (activeStepName === "review") {
      console.log({ values });

      const accountInfo = {
        adAccountId: values.adAccountId,
        ...(values.instagramPageId && {
          instagramAccountId: values.instagramPageId,
        }),
        ...(values.metaPageId && { metaPageId: values.metaPageId }),
        ...(values.pixelId && { pixelId: values.pixelId }),
      };

      const audiences = values.selectedPresets
        .filter((a) => !checkIsAudienceSet(a))
        .map((v: IAudiencePreset) => ({
          platform: "META" as const,
          ...accountInfo,
          name: v.name,
          description: v.description,
          type: v.audienceType,
          filter: v.filter,
        }));

      const audienceSets = values.selectedPresets
        .filter((a) => checkIsAudienceSet(a))
        .map((v: IAudienceSetPreset) => ({
          platform: "META" as const,
          adAccountId: values.adAccountId,
          ...accountInfo,
          name: v.name,
          description: v.description,
          filters: v.filters,
        }));

      try {
        const platformAudiences = audiences.filter(
          (a) => !a.type.includes("SEGMENT")
        );
        const segmentAudiences = audiences.filter((a) =>
          a.type.includes("SEGMENT")
        );

        if (platformAudiences.length) {
          await platformAudiencesCreateFn({
            variables: {
              input: {
                audiences: platformAudiences,
              },
            },
          });
        }

        if (segmentAudiences.length) {
          await segmentAudiencesCreateFn({
            variables: {
              input: {
                audiences: segmentAudiences,
              },
            },
          });
        }

        if (audienceSets.length) {
          await platformAudienceSetsCreateFn({
            variables: {
              input: {
                audienceSets: audienceSets,
              },
            },
          });
        }

        toast.success("Your audience will be created shortly");

        navigate("/audiences");
      } catch (err) {
        toast.error("Unknown error occurred. Please try again later");
      }
    }
  }

  return (
    <div>
      <Header
        title="Create Audience"
        mode="DISTRACTION_FREE"
        primaryAction={primaryAction}
      />
      <Stepper steps={wizardSteps as any} activeStepIndex={activeStep} />
      <main className={styles.layout}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <Card>{wizardSteps[activeStep].component}</Card>
          </Form>
        </Formik>
      </main>
    </div>
  );
}

function checkIsAudienceSet(a: IAudienceWizardPreset): a is IAudienceSetPreset {
  if ("filters" in a) {
    return true;
  }
}
