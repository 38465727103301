import { Spinner } from "src/components/Spinner/Spinner";
import { useActivityPerTickStats } from "../../global/functions/useActivityPerTickStats";
import { ErrorHandler } from "src/components/ErrorHandler";
import { ResourceOverview } from "../../global/components/ResourceOverview";
import { getMonthFromNumber } from "../misc/getMonthFromNumber";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { useMemo } from "react";
import { ReactComponent as MetaIcon } from "../../global/assets/meta.svg";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface IActivityOverview {
  activityId: string;
  dateFrom: Date;
  dateTo: Date;
  statData: GrowAggregateType;
}

export function ActivityOverviewLoader({
  activityId,
  dateFrom,
  dateTo,
  statData,
}: IActivityOverview) {
  const { data, loading, error } = useActivityPerTickStats(activityId, {
    dateFrom,
    dateTo,
  });
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const keyStats = useMemo(
    () => [
      {
        label: "Total Revenue",
        description: "Revenue generated this activity",
        value: formatNumberByType(
          statData?.totalValuePurchase ?? 0,
          "CURRENCY",
          {
            showDecimal: true,
            selectedCurrencyCode,
          },
        ),
        id: "REVENUE",
        statKey: "totalValuePurchase",
        statType: "CURRENCY" as const,
      },
      {
        label: "Reach",
        description: "No. of unique users who saw your ads",
        value: formatNumberByType(statData?.reach ?? 0, "NUMBER"),
        id: "REACH",
        statKey: "reach",
        statType: "NUMBER" as const,
      },
      {
        label: "Impressions",
        description: "No. of times your ads have been seen",
        value: formatNumberByType(statData?.impressions ?? 0, "NUMBER"),
        id: "IMPRESSIONS",
        statKey: "impressions",
        statType: "NUMBER" as const,
      },
      {
        label: "Clicks",
        description: "No. of clicks, taps or swipes on your ads",
        value: formatNumberByType(statData?.clicks ?? 0, "NUMBER"),
        id: "CLICKS",
        statKey: "clicks",
        statType: "NUMBER" as const,
      },
      {
        label: "Conversions",
        description: "No. of times your ad achieved an outcome",
        // TODO: this needs a different stat
        value: formatNumberByType(
          statData?.countInitiateCheckout ?? 0,
          "NUMBER",
        ),
        id: "CONVERSIONS",
        statKey: "countInitiateCheckout",
        statType: "NUMBER" as const,
      },
    ],
    [statData],
  );

  if (loading) {
    return <Spinner height={400} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const formattedData = data
    ? data.map((v) => ({
        ...v,
        time:
          "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
      }))
    : [];

  return (
    <ResourceOverview
      source={{
        label: "Meta",
        icon: <MetaIcon width={20} height={20} />,
      }}
      stats={keyStats}
      chartData={formattedData}
    />
  );
}
