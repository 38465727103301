import format from "date-fns/format";
import { Asset } from "../../../../components/Smartphone/Asset";
import { Asset as IAsset } from "../../../../graphql/generated/schema";
import { titleCase } from "../../../../lib/titleCase";
import { getAspectRatio } from "../../misc/getAspectRatio";
import styles from "./AssetDetails.module.scss";

interface IAssetDetails {
  asset: IAsset;
}
export default function AssetDetails({ asset }: IAssetDetails) {
  const fileDetails = [
    {
      label: "Type",
      value: titleCase(asset.contentType),
    },
    {
      label: "Size",
      value: `${(asset.fileSize / (1024 * 1024)).toFixed(2)}MB`,
    },
    {
      label: "Aspect Ratio",
      value: getAspectRatio(asset.width, asset.height),
    },
    {
      label: "Dimensions",
      value: `${asset.width} x ${asset.height}`,
    },
    {
      label: "Added on",
      value: format(new Date(asset.createdAt), "dd MMM, yyyy"),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.assetContianer}>
        <Asset type={asset.contentType} name={asset.name} preview={asset.uri} />
      </div>
      <div className={styles.fileDetailsContainer}>
        <div className={styles.title}>File details</div>
        <div className={styles.infoSection}>
          {fileDetails.map((v) => (
            <div className={styles.infoSectionItem}>
              <div className={styles.infoSectionLabel}>{v.label}</div>
              <div>{v.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
