import classNames from "classnames";
import styles from "./InputV2.module.scss";
import { ReactComponent as ExclamationIcon } from "../../modules/global/assets/alertIcon.svg";
import { ReactComponent as HelpIcon } from "../../modules/global/assets/helpIcon.svg";
import { ReactComponent as EyeIcon } from "../../modules/global/assets/eyeIcon.svg";
import Tooltip from "../Tooltip/Tooltip";
import { useState } from "react";
import { Label } from "../Label/Label";
import { CharacterCounter } from "src/modules/global/components/CharacterCounter";

export interface IInputV2 {
  value: string;
  disabled?: boolean;
  onChange?: (i: string) => void;
  onBlur?: (e: any) => void;
  type?: "text" | "password" | "email";
  label?: React.ReactNode;
  showCharacterCount?: boolean;
  maxCharacterCount?: number;
  isFullWidth?: boolean;
  icon?: React.FC<React.SVGAttributes<any>>;
  placeholder?: string;
  name?: string;
  autoFocus?: boolean;
  // don't use icon and prefix both
  prefix?: string;
  helpText?: string;
  error?: React.ReactNode;
  size?: "small" | "large" | "medium";
}

export function InputV2({
  value,
  disabled,
  onChange,
  onBlur,
  type = "text",
  label,
  showCharacterCount,
  maxCharacterCount,
  isFullWidth = true,
  icon: Icon,
  placeholder,
  name,
  autoFocus,
  prefix,
  size = "medium",
  helpText,
  error,
}: IInputV2) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className={classNames({
        [styles.inputWithLabel]: isFullWidth,
      })}
    >
      {label && (
        <div className={styles.labelWrapper}>
          <Label
            label={label}
            htmlFor={name}
            // help text is now shown inside the input
            // helpText={helpText}
            status={error ? "error" : "default"}
          />
          {showCharacterCount && (
            <CharacterCounter
              characterCount={value?.length}
              maxCharacterCount={maxCharacterCount}
            />
          )}
        </div>
      )}
      <div
        className={classNames(styles.fieldWrapper, {
          [styles.fieldWrapperFullWidth]: isFullWidth,
        })}
      >
        {Boolean(Icon) ? (
          <Icon className={styles.icon} width={20} height={20} />
        ) : prefix ? (
          <span className={styles.prefix}>{prefix}</span>
        ) : null}
        <input
          className={classNames(styles.input, {
            [styles.inputFixedWidth]: !isFullWidth,
            [styles.inputFullWidth]: isFullWidth,
            [styles.inputLarge]: size === "large",
            [styles.inputMedium]: size === "medium",
            [styles.inputSmall]: size === "small",
            [styles.inputError]: error,
            [styles.inputWithIcon]: Boolean(Icon),
            [styles.inputWithPrefix]: Boolean(prefix),
            [styles.inputWithHelpOrError]: error && Boolean(helpText),
          })}
          name={name}
          type={showPassword ? "text" : type}
          value={value}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
        />

        {error ? (
          <ExclamationIcon
            width={16}
            height={16}
            className={styles.errorIcon}
          />
        ) : type === "password" ? (
          <button
            onClick={() => setShowPassword(!showPassword)}
            type="button"
            className={styles.showPasswordButton}
          >
            <EyeIcon width={16} height={16} className={styles.eyeIcon} />
          </button>
        ) : helpText ? (
          <Tooltip supportingText={helpText}>
            <HelpIcon width={16} height={16} className={styles.helpIcon} />
          </Tooltip>
        ) : null}
      </div>
      {error && (
        <div
          className={classNames({
            [styles.errorText]: error,
          })}
        >
          {error}
        </div>
      )}
    </div>
  );
}
