import { Combobox, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useState } from "react";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/expand_more.svg";
import { Checkbox } from "../Checkbox/Checkbox";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import styles from "./ComboBox.module.scss";

interface Option {
  label: string;
  value: string;
}

export interface IComboBox {
  multiple: boolean;
  selected: string | string[];
  renderTagLabel?: (value: string) => void;
  setSelected: (i: any) => void;
  options: Option[];
  showTags?: boolean;
  onBlur?: () => void;
  placeholder?: string;
  showArrow?: boolean;
  showScrollAfter?: number;
  onChange?: (i: string) => void;
  disabled?: boolean;
  // for handling dynamic options
  loading?: boolean;
  icon?: ReactNode;
}

/**
 * @deprecated Use ComboxboxV2
 */
export default function ComboBox(props: IComboBox) {
  const {
    multiple,
    renderTagLabel,
    options,
    selected,
    setSelected,
    icon,
    showArrow = true,
    showTags,
    onBlur,
    placeholder = "",
    onChange,
    disabled,
    showScrollAfter = 6,
    loading,
  } = props;
  const [query, setQuery] = useState("");

  const filteredOption = options.filter((o) =>
    o.label.toLowerCase().includes(query.toLowerCase()),
  );

  function removeTag(tag: string) {
    if (Array.isArray(selected))
      setSelected(selected.filter((item) => item !== tag));
  }
  return (
    <>
      <Combobox
        value={selected}
        onChange={setSelected}
        disabled={disabled}
        multiple={multiple as any}
      >
        <div className={styles.box}>
          <div className={styles.comboBox}>
            <div>
              <div className={styles.inputAndIcon}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <Combobox.Input
                  className={styles.input}
                  placeholder={placeholder}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    if (onChange) {
                      onChange(event.target.value);
                    }
                  }}
                  value={query}
                  onBlur={onBlur}
                  autoComplete="off"
                />
              </div>
              {showArrow && (
                <Combobox.Button className={styles.button}>
                  <div className={styles.dropIcon}>
                    <ArrowDown fill="#C9CCCF" />
                  </div>
                </Combobox.Button>
              )}
            </div>
          </div>
          <Transition
            as={Fragment}
            leave={styles.comboPanelLeave}
            leaveFrom={styles.comboPanelLeaveFrom}
            leaveTo={styles.comboPanelLeaveTo}
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options
              className={styles.Options}
              style={{ maxHeight: `${showScrollAfter * 53}px` }}
            >
              {filteredOption.length === 0 && query !== "" ? (
                <div className={styles.notFound}>
                  {loading ? "Loading" : "Nothing found."}
                </div>
              ) : (
                filteredOption.map((opt, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) =>
                      `${styles.comboOption} ${
                        active ? styles.activeOption : styles.inactiveOption
                      } ${
                        index !== filteredOption.length - 1 &&
                        styles.borderOption
                      }`
                    }
                    value={opt.value}
                  >
                    {({ selected: checked }) => (
                      <div className={styles.option}>
                        {/* Disabled will make sure parent onchange is triggered */}
                        <Checkbox
                          value={opt.value}
                          onChange={() => {}}
                          disabled
                          checked={checked}
                        />
                        <span
                          className={`block truncate ${
                            checked ? "font-medium" : "font-normal"
                          }`}
                        >
                          {opt.label}
                        </span>
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {multiple && showTags && (
        <div className={styles.tags}>
          {Array.isArray(selected)
            ? selected.map((item, index) => (
                <span className={styles.badge} key={index}>
                  {renderTagLabel ? renderTagLabel(item) : item}
                  <button
                    type="button"
                    className={styles.closeBtn}
                    onClick={() => removeTag(item)}
                  >
                    <Close width={12} height={12} />
                  </button>
                </span>
              ))
            : ""}
        </div>
      )}
    </>
  );
}
