import { getIn, useFormikContext } from "formik";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import {
  MetaIntegrationDefaultSettingsQuery,
  MetaPagesQuery,
} from "src/graphql/generated/schema";
import { MetaAdAccountSelector } from "src/modules/campaign-wizard/components/MetaAdAccountSelector";
import { MetaInstagramPageSelector } from "src/modules/campaign-wizard/components/MetaInstagramPageSelector";
import { MetaPageSelector } from "src/modules/campaign-wizard/components/MetaPageSelector";
import { SelectLabel } from "src/modules/campaign-wizard/components/SelectLabel";
import { MetaPixelSelector } from "./MetaPixelSelector";

export type FBFormValueKey =
  // adAccount
  | "adAccountId"
  | "adAccountName"
  | "platformAccountId"
  // pixel
  | "pixelId"
  | "metaPixelId"
  | "metaPixelName"
  // pages
  | "pageId"
  | "metaPageId"
  | "metaPageName"
  | "metaPagePicture"
  // instagram
  | "instagramPageId"
  | "metaInstagramPageId"
  | "metaPageConnectedInstagramBusinessAccountId"
  | "instagramPageName"
  // misc
  | "metaIntegrationId"
  | "conversionDomain";

interface IFacebookAccountSettings {
  fieldsMapper?: Partial<Record<FBFormValueKey, string>>;
  containerClass: string;
  showInstagramFields: boolean;
  showConversionDomainInput: boolean;
  onReset?: () => void;
  onAdAccountChange?: (
    value: string,
    platformAccountId: string,
    metaName: string
  ) => void;
  onMetaPageChange?: (value: string, arr: MetaPagesQuery["metaPages"]) => void;
}

const defaultFieldsMapper: Partial<Record<FBFormValueKey, string>> = {
  metaIntegrationId: "metaIntegrationId",
  adAccountId: "adAccountId",
  adAccountName: "adAccountName",
  conversionDomain: "conversionDomain",
  platformAccountId: "enabledAccountId",
  instagramPageId: "instagramPageId",
  metaPageConnectedInstagramBusinessAccountId:
    "metaPageConnectedInstagramBusinessAccountId",
  metaPageName: "metaPageName",
  metaPageId: "metaPageId",
  pixelId: "pixelId",
  metaPagePicture: "metaPagePicture",
  instagramPageName: "instagramPageName",
  pageId: "pageId",
  metaInstagramPageId: "metaInstagramPageId",
  metaPixelId: "metaPixelId",
  metaPixelName: "metaPixelName",
};

const defaultSettingsFieldsMapper: Partial<Record<FBFormValueKey, string>> = {
  metaIntegrationId: "id",
  adAccountId: "metaAdAccountId",
  adAccountName: "MetaAdAccount.metaName",
  platformAccountId: "MetaAdAccount.metaAccountId",
  conversionDomain: "conversionDomain",
  instagramPageId: "metaInstagramAccountId",
  metaInstagramPageId: "MetaInstagramAccount.metaInstagramId",
  instagramPageName: "MetaInstagramAccount.name",
  metaPageConnectedInstagramBusinessAccountId:
    "MetaPage.metaPageConnectedInstagramBusinessId",
  pageId: "metaPageId",
  metaPageId: "MetaPage.metaPageId",
  metaPageName: "MetaPage.name",
  metaPagePicture: "MetaPage.Business.profilePicture",
  pixelId: "metaPixelId",
  metaPixelId: "MetaPixel.metaPixelId",
  metaPixelName: "MetaPixel.name",
};

export function upsertDefaultSettings(
  defaultSettings: MetaIntegrationDefaultSettingsQuery["metaIntegrationDefaultSettings"],
  setFormikFieldValue: (key: string, value: any) => void,
  fieldsMapper: Partial<Record<FBFormValueKey, string>> = defaultFieldsMapper
) {
  const fieldMapperWithValues = Object.keys(defaultSettingsFieldsMapper).reduce(
    (acc, key: FBFormValueKey) => {
      return {
        ...acc,
        [key]: getIn(defaultSettings, defaultSettingsFieldsMapper[key]),
      };
    },
    {}
  );
  Object.keys(fieldMapperWithValues).forEach((key: FBFormValueKey) => {
    setFormikFieldValue(fieldsMapper[key], fieldMapperWithValues[key]);
  });
}

export default function FacebookSettings({
  containerClass,
  fieldsMapper = defaultFieldsMapper,
  onAdAccountChange,
  onMetaPageChange,
  showInstagramFields,
  showConversionDomainInput,
  onReset,
}: IFacebookAccountSettings) {
  const { values, setFieldValue: setFormikFieldValue } = useFormikContext();
  const adAccountId = getIn(values, fieldsMapper.adAccountId);

  function setFieldValue(key: FBFormValueKey, value: any) {
    if (fieldsMapper[key]) {
      setFormikFieldValue(fieldsMapper[key], value);
    }
  }

  return (
    <div className={containerClass}>
      <MetaAdAccountSelector
        selectedAdAccountId={adAccountId}
        setSelectedAdAccountId={(value, platformAccountId, metaName) => {
          if (onReset) {
            onReset();
          }

          if (onAdAccountChange) {
            onAdAccountChange(value, platformAccountId, metaName);
            return;
          }
          setFieldValue("adAccountId", value);
          setFieldValue("platformAccountId", platformAccountId);
          setFieldValue("adAccountName", metaName);
        }}
        formKey={fieldsMapper.adAccountId}
      />
      <MetaPageSelector
        disabled={false}
        selectedAdAccountId={adAccountId}
        selectedPageId={getIn(values, fieldsMapper.pageId)}
        setSelectedPageId={(value, arr) => {
          if (onMetaPageChange) {
            onMetaPageChange(value, arr);
            return;
          }

          const connectedPage = arr.find((i) => i.id === value);

          setFieldValue("pageId", value);
          setFieldValue("metaPageId", connectedPage.metaPageId);
          setFieldValue("metaPageName", connectedPage.name);
          setFieldValue(
            "metaPagePicture",
            connectedPage.Business?.profilePicture
          );

          if (showInstagramFields) {
            setFieldValue(
              "metaPageConnectedInstagramBusinessAccountId",
              connectedPage.metaPageConnectedInstagramBusinessId
            );
          }
        }}
        formKey={fieldsMapper.metaPageId}
      />

      {showInstagramFields && (
        <MetaInstagramPageSelector
          disabled={false}
          selectedAdAccountId={adAccountId}
          selectedInstagramPageId={getIn(values, fieldsMapper.instagramPageId)}
          setSelectedInstagramPageId={(value, arr) => {
            const instagramPage = arr.find((a) => a.id === value);

            setFieldValue("instagramPageId", value);
            setFieldValue("instagramPageName", instagramPage?.name);
            setFieldValue("metaInstagramPageId", instagramPage.metaInstagramId);
          }}
          formKey={fieldsMapper.instagramPageId}
        />
      )}
      <MetaPixelSelector
        name={fieldsMapper.pixelId}
        selectedPixelId={getIn(values, fieldsMapper.pixelId)}
        adAccountId={adAccountId}
        setSelectedPixel={(value, arr) => {
          const pixel = arr?.find((a) => a.id === value);
          setFieldValue("pixelId", value);
          setFieldValue("metaPixelId", pixel.metaPixelId);
          setFieldValue("metaPixelName", pixel.name);
        }}
      />
      {showConversionDomainInput && (
        <div>
          <InputFormik
            name={fieldsMapper.conversionDomain}
            label={
              <SelectLabel
                labelText={"Conversion Domain"}
                tooltipText={
                  "Add your domain to track and measure conversion from your ads"
                }
              />
            }
            placeholder="for eg:something.com"
            showError={true}
          />
        </div>
      )}
    </div>
  );
}
