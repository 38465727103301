import { EditorRef, EmailEditor } from "react-email-editor";
import { useEmailDesignTemplateQuery } from "src/graphql/generated/schema";
import { useRef, useState } from "react";
import { EmailBuilderHeader } from "src/components/EmailBuilderHeader/EmailBuilderHeader";
import { IButton } from "src/components/Button/Button";
import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";

interface IEmailTemplateEditor {
  templateId: string;
  templateName: string;
  setTemplateName: (i: string) => void;
  handleSave: (
    html: string,
    json: string,
    saveAsNewTemplate: boolean,
  ) => Promise<void>;
  loading?: boolean;
  onBack?: (i: string) => void;
  additionalOptions?: IButton[];
}

export default function EmailTemplateEditor({
  templateId,
  templateName,
  setTemplateName,
  handleSave,
  loading,
  onBack,
  additionalOptions = [],
}: IEmailTemplateEditor) {
  const [preview, setPreview] = useState(false);
  const {
    error,
    data: emailDesignTemplateData,
    loading: emailDesignTemplateLoading,
  } = useEmailDesignTemplateQuery({
    variables: {
      emailDesignTemplateId: templateId,
    },
  });
  const emailEditorRef = useRef<EditorRef | null>(null);

  console.log({ emailDesignTemplateData });
  if (emailDesignTemplateLoading) {
    return (
      <div>
        <Spinner height="screen" />
      </div>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const emailDesignTemplate =
    emailDesignTemplateData && emailDesignTemplateData.emailDesignTemplate;

  const saveDesign = async (saveAsNewTemplate: boolean) => {
    console.log(saveAsNewTemplate);
    // to save image we need full api access for unlayer
    emailEditorRef.current?.editor?.exportHtml(async (data) => {
      const { design, html } = data;
      const designJson = JSON.stringify(design);
      await handleSave(html, designJson, saveAsNewTemplate);
    });
  };

  const togglePreview = () => {
    if (preview) {
      emailEditorRef.current?.editor?.hidePreview();
      setPreview(false);
    } else {
      emailEditorRef.current?.editor?.showPreview("desktop");
      setPreview(true);
    }
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onReady = () => {
    console.log("onLoad");

    // emailEditorRef.current?.editor?.setMergeTags()
    emailEditorRef.current?.editor?.addEventListener(
      "design:loaded",
      onDesignLoad,
    );

    if (templateId === "") {
      return;
    }
    emailEditorRef.current?.editor?.loadDesign(
      JSON.parse(emailDesignTemplate.designJson),
    );
  };

  return (
    <>
      <div>
        <EmailBuilderHeader
          setTitle={setTemplateName}
          title={templateName}
          preview={preview}
          options={[
            {
              children: "Save as new template",
              onClick: () => saveDesign(true),
            },
            ...additionalOptions,
          ]}
          onSave={() => saveDesign(false)}
          loading={loading}
          onPreview={togglePreview}
          goBack={onBack}
        />
      </div>
      <EmailEditor
        options={{
          features: {
            undoRedo: true,
            preview: true,
          },
        }}
        // appearance={{ panels: { tools: { dock: "left" } } }}
        minHeight={"100%"}
        ref={emailEditorRef}
        onReady={onReady}
      />
    </>
  );
}
