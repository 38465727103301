import {
  useCustomerTagsCreateMutation,
  useCustomersUpdateMutation,
} from "src/graphql/generated/schema";
import { Button } from "src/components/Button/Button";
import { CustomerTagsComboBox } from "./CustomerTagsComboBox";
import Modal from "src/components/Modal/Modal";
import styles from "./AddTagToCustomersModal.module.scss";
import { useState } from "react";
import toast from "react-hot-toast";

interface IAddTagToCustomersModal {
  showAddTagsModal: boolean;
  setShowAddTagsModal: (i: boolean) => void;
  customers: any[];
  selectedItems: any[];
  setSelectedItems: (i: any[]) => void;
}
export function AddTagToCustomersModal({
  showAddTagsModal,
  setShowAddTagsModal,
  customers,
  selectedItems,
  setSelectedItems,
}: IAddTagToCustomersModal) {
  const [selectedTags, setSelectedTags] = useState([]);

  const [addTagsFunc, { loading: addTagsLoading }] =
    useCustomerTagsCreateMutation();
  const [customersUpdateFunc, { loading: customerUpdateLoading }] =
    useCustomersUpdateMutation();

  async function handleAddTags() {
    const selectedCustomers = customers
      .filter((_, index) => selectedItems.includes(index))
      .map((v) => v.node.id);
    await customersUpdateFunc({
      variables: {
        input: selectedCustomers.map((customerId) => ({
          customerId: customerId,
          tags: selectedTags.map((v) => v.label),
        })),
      },
    });
    setSelectedTags([]);
    setSelectedItems([]);
    setShowAddTagsModal(false);
    toast.success("Tags have been added");
  }
  return (
    <Modal
      isOpen={showAddTagsModal}
      onClose={() => setShowAddTagsModal(false)}
      className={styles.modal}
      maxWidth="615px"
      title="Add tags"
      subtitle="Your segment will be saved in Segments. You can view and edit them later on."
      noContainer
      footer={{
        footerContent: (
          <Button style="plain" size="micro" to="/customer-tags">
            Manage tags
          </Button>
        ),
        footerActions: (
          <Button
            size="micro"
            disabled={selectedTags.length === 0 || customerUpdateLoading}
            onClick={handleAddTags}
            loading={customerUpdateLoading}
          >
            Add Tags
          </Button>
        ),
      }}
    >
      <div className={styles.modalContent}>
        {/* <h3 className={styles.title}>Add Tags To Customer</h3>
        <p className={styles.description}>
          Add multiple tags to the selected customers
        </p> */}

        <CustomerTagsComboBox
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags as any}
          addTags={addTagsFunc}
          addTagsLoading={addTagsLoading}
        />
        {/* <Button width="full" style="solid" color="primary">
          Save Customers
        </Button> */}
      </div>
    </Modal>
  );
}
