import { ActivityInput } from "../misc/createCampaignTypes";

// TODO: extend this to support google and other meta activities
export function getValidActivities(activities: ActivityInput[]) {
  const validActivities = activities.filter((v) => {
    return v.platform === "META" && v?.Ads?.length > 0 && v?.Adsets?.length > 0;
  });

  return validActivities;
}
