import { Button } from "src/components/Button/Button";
import Modal from "src/components/Modal/Modal";
import FacebookSettings from "src/modules/global/components/FacebookSettings";
import styles from "./ActivityFacebookSettingsSelectorModal.module.scss";
import { Form, Formik } from "formik";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import {
  useMetaIntegrationDefaultSettingsQuery,
  useMetaIntegrationDefaultSettingsUpsertMutation,
} from "src/graphql/generated/schema";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { Label } from "src/components/Label/Label";
import { transformUrlToConversionDomain } from "../functions/transformUrlToConversionDomain";

interface FormState {
  adAccountId: string;
  metaAdAccountId: string;
  pixelId: string;
  metaPixelId: string;
  instagramPageId: string;
  metaInstagramPageId: string;
  pageId: string;
  metaPageId: string;
  metaPagePicture: string;
  metaPageName: string;
  metaPageConnectedInstagramBusinessAccountId: string;
  conversionDomain: string;
}

const validationSchema = Yup.object({
  adAccountId: Yup.string().required("Ad account is required"),
  instagramPageId: Yup.string().required("instagram account is required"),
  metaPageId: Yup.string().required("meta page is required"),
});

interface IActivityAccountSelectorModal {
  activeStepIndex: number;
  isOpen: boolean;
  onClose: () => void;
  initialFormValues: FormState;
  handleSubmit: (i: FormState) => void;
}

export function ActivityFacebookSettingsSelectorModal({
  activeStepIndex,
  isOpen,
  initialFormValues,
  onClose,
  handleSubmit,
}: IActivityAccountSelectorModal) {
  const { data } = useMetaIntegrationDefaultSettingsQuery();
  const [shouldSaveAsDefault, setShouldSaveAsDefault] = useState(false);
  const [metaIntegrationDefaultSettingsUpsertFunc] =
    useMetaIntegrationDefaultSettingsUpsertMutation();

  useEffect(() => {
    if (!data) return;
    if (!data.metaIntegrationDefaultSettings) return;

    // if it is already set
    if (initialFormValues.adAccountId) {
      return;
    }

    handleSubmit({
      ...initialFormValues,
      adAccountId: data.metaIntegrationDefaultSettings.metaAdAccountId,
      metaAdAccountId:
        data.metaIntegrationDefaultSettings.MetaAdAccount.metaAccountId,
      metaPixelId: data.metaIntegrationDefaultSettings.MetaPixel.metaPixelId,
      pixelId: data.metaIntegrationDefaultSettings.metaPixelId,
      metaInstagramPageId:
        data.metaIntegrationDefaultSettings.MetaInstagramAccount
          .metaInstagramId,
      instagramPageId:
        data.metaIntegrationDefaultSettings.metaInstagramAccountId,
      metaPageId: data.metaIntegrationDefaultSettings.MetaPage.metaPageId,
      pageId: data.metaIntegrationDefaultSettings.metaPageId,
      metaPagePicture:
        data.metaIntegrationDefaultSettings.MetaPage?.Business?.profilePicture,
      metaPageName: data.metaIntegrationDefaultSettings.MetaPage.name,
      metaPageConnectedInstagramBusinessAccountId:
        data.metaIntegrationDefaultSettings.MetaPage
          .metaPageConnectedInstagramBusinessId,
      conversionDomain: data.metaIntegrationDefaultSettings.conversionDomain,
    });
  }, [data, activeStepIndex, initialFormValues]);

  return (
    <Modal
      title="Choose Facebook Account Settings"
      isOpen={isOpen}
      maxWidth="960px"
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialFormValues}
        onSubmit={async (values, formikHelpers) => {
          handleSubmit(values);
          // submit as default meta integrations settings
          formikHelpers.setFieldValue(
            "conversionDomain",
            transformUrlToConversionDomain(values.conversionDomain),
          );

          if (shouldSaveAsDefault) {
            await metaIntegrationDefaultSettingsUpsertFunc({
              variables: {
                input: {
                  adAccountId: values.adAccountId,
                  instagramAccountId: values.instagramPageId,
                  pageId: values.pageId,
                  pixelId: values.pixelId,
                  conversionDomain: transformUrlToConversionDomain(
                    values.conversionDomain,
                  ),
                },
              },
              awaitRefetchQueries: true,
              refetchQueries: ["metaIntegrationDefaultSettings"],
            });
          }
        }}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.modalWrapper}>
                <FacebookSettings
                  showConversionDomainInput={true}
                  containerClass={styles.container}
                  fieldsMapper={{
                    adAccountId: "adAccountId",
                    adAccountName: "adAccountName",
                    platformAccountId: "metaAdAccountId",
                    pixelId: "pixelId",
                    metaPixelId: "metaPixelId",
                    instagramPageName: "instagramPageName",
                    metaPageName: "metaPageName",
                    metaPixelName: "metaPixelName",
                    instagramPageId: "instagramPageId",
                    metaInstagramPageId: "metaInstagramPageId",
                    pageId: "pageId",
                    metaPageId: "metaPageId",
                    metaPagePicture: "metaPagePicture",
                    metaPageConnectedInstagramBusinessAccountId:
                      "metaPageConnectedInstagramBusinessAccountId",
                    conversionDomain: "conversionDomain",
                  }}
                  showInstagramFields={true}
                />

                <div className={styles.footerWrapper}>
                  <Checkbox
                    size="small"
                    checked={shouldSaveAsDefault}
                    onChange={() => {
                      setShouldSaveAsDefault(!shouldSaveAsDefault);
                    }}
                    value="saveAsDefault"
                    label="Save as default Meta Integration"
                  />

                  <div className={styles.buttonGroup}>
                    <Button
                      onClick={onClose}
                      style="outline"
                      color="subdued"
                      size="small"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" size="small">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
