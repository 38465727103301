import { useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import {
  useExchangeMetaCodeForAccessTokenMutation,
  useMetaIntegrationRevokeMutation,
  useMetaIntegrationStatusQuery,
} from "src/graphql/generated/schema";
import IntegrationPageHeader from "src/modules/global/components/IntegrationPageHeader";
import META_INTEGRATION_STATUS from "../api/metaIntegrationStatus";
import MetaIntegrationInstructions from "../components/MetaIntegrationInstructions";
import styles from "./MetaIntegration.module.scss";
import { MetaIntegrationSingle } from "../components/MetaIntegrationSingle";
import AuthContext from "src/stores/AuthContext";
import META_AD_ACCOUNTS from "src/graphql/metaAdAccounts";
import META_PIXELS from "src/graphql/metaPixels";
import META_PAGES from "src/graphql/metaPages";
import { PageLoader } from "src/modules/global/components/PageLoader";

export default function MetaIntegration() {
  // Check if this page is redirected back with the code so we exchange it for access token
  const [searchParams, setSearchParams] = useSearchParams();
  const metaCode = searchParams.get("code");
  const metaState = searchParams.get("state");

  const { user } = useContext(AuthContext);
  // Data loading and mutations
  const {
    data: statusData,
    error,
    loading: statusLoading,
  } = useMetaIntegrationStatusQuery();

  const [onRevokeIntegration, { loading: revokeLoading }] =
    useMetaIntegrationRevokeMutation({
      refetchQueries: [{ query: META_INTEGRATION_STATUS }],
    });

  const [exchangeMetaCodeForAccessToken, { loading: accessTokenLoading }] =
    useExchangeMetaCodeForAccessTokenMutation();

  // Exchange meta code for access token
  async function exchangeMetaCode(metaCode: string, metaState) {
    await exchangeMetaCodeForAccessToken({
      variables: {
        input: {
          accessTokenCode: metaCode,
          accessTokenState: metaState,
        },
      },
      refetchQueries: [
        { query: META_INTEGRATION_STATUS },
        { query: META_AD_ACCOUNTS },
        { query: META_PIXELS },
        { query: META_PAGES },
      ],
      awaitRefetchQueries: true,
    });
    // remove query params from url
    setSearchParams({});
  }

  useEffect(() => {
    if (metaCode && metaState) {
      exchangeMetaCode(metaCode, metaState);
    }
  }, [metaCode, metaState]);

  if (statusLoading || accessTokenLoading) {
    return <PageLoader />;
  }

  const isIntegrated =
    statusData && statusData.metaIntegrationStatus === "ACTIVE" ? true : false;

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div>
      <IntegrationPageHeader title="Meta Integration" />
      <div className={styles.page}>
        {isIntegrated ? (
          <MetaIntegrationSingle
            revokeAccess={async () => {
              await onRevokeIntegration({
                refetchQueries: [
                  {
                    query: META_INTEGRATION_STATUS,
                  },
                ],
                awaitRefetchQueries: true,
              });
            }}
            revokeLoading={revokeLoading}
            me={user}
          />
        ) : (
          <MetaIntegrationInstructions />
        )}
      </div>
    </div>
  );
}
