import { InputFormik } from "../../../../components/InputFormik/InputFormik";
import { MultilineInputFormik } from "../../../../components/MultilineInputFormik/MultilineFormik";

export function AdTemplateCatalogueAdForm() {
  return (
    <>
      <MultilineInputFormik
        label="Primary Text*"
        rows={3}
        placeholder="Tip: Primary text should span 2-3 lines at most."
        name={"primaryText"}
      />

      <InputFormik
        name={`LineItems[0].headline`}
        label="Ad Title"
        placeholder="Write a short headline"
        showCharacterCount={true}
        maxCharacterCount={40}
        width="full"
      />

      <InputFormik
        name={`LineItems[0].description`}
        label="Ad Description"
        placeholder="Add additional details of your ads"
        showCharacterCount={true}
        maxCharacterCount={125}
        width="full"
      />
    </>
  );
}
