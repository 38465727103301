import styles from "./FilterSidebar.module.scss";

const filterOptions = [
  {
    label: "Status",
    // this is the key for the item in filter state
    key: "status",
    options: [
      {
        label: "All",
        value: "ALL",
      },
      {
        label: "Active",
        value: "ACTIVE",
      },
      {
        label: "Inactive",
        value: "INACTIVE",
      },
      {
        label: "Draft",
        value: "DRAFT",
      },
      {
        label: "Trash",
        value: "TRASH",
      },
    ],
  },
  {
    label: "Platform",
    // this is the key for the item in filter state
    key: "platform",
    options: [
      {
        label: "All",
        value: "ALL",
      },
      {
        label: "Meta",
        value: "FACEBOOK",
      },
      {
        label: "Google",
        value: "GOOGLE",
      },
      {
        label: "Email",
        value: "EMAIL",
      },
      {
        label: "Message",
        value: "MESSAGE",
      },
    ],
  },
];

export default function FilterSidebar<T>({
  filters,
  setFilters,
}: {
  filters: T;
  setFilters: (i: T) => void;
}) {
  return (
    <div className={styles.filterSidebar}>
      {filterOptions.map((value) => (
        <div>
          <div className={styles.filterLabel}>{value.label}</div>
          <div className={styles.filterOptions}>
            {value.options.map((option) => (
              <div className={styles.filterOption}>
                <input
                  type="radio"
                  value={option.value}
                  checked={filters[value.key] === option.value}
                  onChange={({ target }) => {
                    const updatedFilters = {
                      ...filters,
                      [value.key]: target.value,
                    };
                    setFilters(updatedFilters);
                  }}
                />
                <span> {option.label} </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
