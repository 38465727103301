import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/arrow_downward.svg";
import styles from "./TemplateListingEmailTable.module.scss";
import BriefItem from "src/components/BriefItem/BriefItem";
import { TemplateListingAction } from "./TemplateListingAction";
import { FullPageTable } from "src/modules/global/components/FullPageTable";
import { Pagination } from "src/components/Pagination/Pagination";
import { useEmailDesignTemplatesQuery } from "src/graphql/generated/schema";
import format from "date-fns/format";
import { SomethingWentWrong } from "src/modules/global/components/SomethingWentWrong";

export function TemplateListingEmailTable() {
  // replace with query for all email templates
  const { data, loading, error } = useEmailDesignTemplatesQuery();
  const HEADINGS = [
    "Templates",
    <span className={styles.heading}>
      Use Count <ArrowDown className={styles.arrowIco} />
    </span>,
    "Action",
  ];

  const ROWS =
    data && data.emailDesignTemplates
      ? data.emailDesignTemplates.edges.map((v) => [
          <BriefItem
            title={v?.node?.name}
            subtitle={`Created on : ${format(
              new Date(v?.node?.createdAt) || new Date(),
              "dd-mm-yy"
            )}`}
          />,
          0,
          <TemplateListingAction />,
        ])
      : [];

  if (error) {
    console.error(error);
    return (
      <div>
        <SomethingWentWrong />
      </div>
    );
  }

  return (
    <div>
      <FullPageTable
        loading={loading}
        headings={HEADINGS}
        rows={ROWS}
        tableClass={styles.table}
        wrapperClass={styles.wrapper}
      />
      <div className={styles.paginationWrapper}>
        <Pagination
          hasPrevious={false}
          hasNext={false}
          onPrevious={function (): void {
            throw new Error("Function not implemented.");
          }}
          onNext={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </div>
    </div>
  );
}
