import { Tabs } from "src/components/Tabs/Tabs";
import styles from "./CampaignSingleOverview.module.scss";
import { CampaignSingleOverviewTabContent } from "./CampaignSingleOverviewTabContent";
import { useState } from "react";
import metaIcon from "../../global/assets/meta.svg";
import { ReactComponent as AudienceGroup } from "@material-symbols/svg-400/rounded/groups_2-fill.svg";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-400/rounded/info.svg";
import { ReactComponent as KeyboardArrowDown } from "@material-symbols/svg-400/rounded/keyboard_arrow_down.svg";
import segment from "../../global/assets/segmentOutline.svg";
import classNames from "classnames";
import Tooltip from "src/components/Tooltip/Tooltip";
import { ToggleSwitch } from "src/modules/global/components/ToggleSwitch";
import { Button } from "src/components/Button/Button";
import { CampaignQuery } from "src/graphql/generated/schema";
import { useCampaignAggregatedStats } from "../../global/functions/useCampaignAggregatedStats";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { IOverviewTabs } from "../misc/campaignSingleUiTypes";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

export interface ICampaignOverview {
  campaignId: string;
  campaign: CampaignQuery["campaign"];
  dateFrom?: Date;
  dateTo?: Date;
}

const tabOptions: Array<{ label: string; value: IOverviewTabs }> = [
  {
    label: "Transactional",
    value: "TRANSACTIONAL",
  },
  // {
  //   label: "Lead",
  //   value: "LEAD",
  // },
  {
    label: "Traffic",
    value: "TRAFFIC",
  },
  {
    label: "Engagement",
    value: "ENGAGEMENT",
  },
  {
    label: "Awareness",
    value: "AWARENESS",
  },
];

export function CampaignSingleOverview({
  campaignId,
  dateFrom,
  dateTo,
  campaign,
}: ICampaignOverview) {
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);
  const { data } = useCampaignAggregatedStats(campaignId, {
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  const selectedCurrencyCode = useGetActiveCurrencyCode();

  const insights = data;

  const audienceCount = campaign.Activities.reduce(
    (acc, a) => a.MetaActivity?.MetaActivityAdsets.length + acc,
    0,
  );

  const segmentCount = campaign.Outreaches.reduce(
    (acc, o) => o.Segments?.length + acc,
    0,
  );

  const adsCreativeCount = campaign.Activities.reduce(
    (acc, a) => a.MetaActivity?.MetaActivityAds?.length + acc,
    0,
  );

  // every outreach has 1 creative only
  const emailCreativeCount = campaign.Outreaches.length;
  const totalCreativeCount = adsCreativeCount + emailCreativeCount;

  const totalAudienceAndSegmentCount = audienceCount + segmentCount;

  return (
    <section className={styles.campaignOverview}>
      <div className={styles.performanceSummaryHeader}>
        <div>
          <h2 className={styles.performanceSummaryTitle}>
            Overview of this campaign
          </h2>
        </div>
      </div>
      <div className={styles.overviewStatsSection}>
        <Tabs
          tabs={tabOptions}
          onTabChange={(tab: IOverviewTabs) => setSelectedTab(tab)}
          selectedTab={selectedTab}
        />
        <div className={styles.tabContentWrapper}>
          <CampaignSingleOverviewTabContent
            insights={insights}
            selectedTab={selectedTab}
            isMoreDetailsOpen={isMoreDetailsOpen}
            setIsMoreDetailsOpen={setIsMoreDetailsOpen}
          />
          {isMoreDetailsOpen && (
            <>
              <div className={styles.campaignOtherStats}>
                <div
                  className={classNames(
                    styles.statItem,
                    styles.statItemWithSeparator,
                  )}
                >
                  <span className={styles.statLabel}>Activity Count</span>
                  <span className={styles.statValueContainer}>
                    <span
                      className={classNames(
                        styles.statValue,
                        styles.statWithDistribution,
                      )}
                    >
                      {campaign.activitiesCount}
                    </span>
                    <span className={styles.statDistributionList}>
                      <span className={styles.statDistributionItem}>
                        <img
                          className={styles.metaIcon}
                          alt="meta icon"
                          src={metaIcon}
                          width={20}
                          height={20}
                        />
                        <span>{campaign.activitiesCount}</span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  className={classNames(
                    styles.statItem,
                    styles.statItemWithSeparator,
                  )}
                >
                  <span className={styles.statLabel}>
                    Audience & Segment Split
                  </span>
                  <span className={styles.statValueContainer}>
                    <span
                      className={classNames(
                        styles.statValue,
                        styles.statWithDistribution,
                      )}
                    >
                      {totalAudienceAndSegmentCount}
                    </span>
                    <span className={styles.statDistributionList}>
                      <span className={styles.statDistributionItem}>
                        <span className={styles.audienceGroupIcon}>
                          <AudienceGroup
                            fill="currentColor"
                            width={12}
                            height={12}
                          />
                        </span>
                        <span>{audienceCount}</span>
                      </span>
                      {segmentCount > 0 && (
                        <span className={styles.statDistributionItem}>
                          <img
                            className={styles.segmentIcon}
                            alt="segment icon"
                            src={segment}
                            width={12}
                            height={12}
                          />

                          <span>{segmentCount}</span>
                        </span>
                      )}
                    </span>
                  </span>
                </div>
                <div className={styles.statItem}>
                  <span className={styles.statLabel}>No of Creatives</span>
                  <span className={styles.statValue}>{totalCreativeCount}</span>
                </div>
              </div>

              <div className={styles.overviewFooter}>
                <div className={styles.overviewFooterContent}>
                  <div className={styles.footerStatsSection}>
                    <div>
                      <span className={styles.footerLabel}>
                        Total Meta's Spend
                        <Tooltip supportingText="hello world">
                          <InfoIcon
                            color="var(--color-disabled)"
                            width={14}
                            height={14}
                          />
                        </Tooltip>
                      </span>
                      <span className={styles.footerValue}>
                        {formatNumberByType(insights?.spend, "CURRENCY", {
                          showDecimal: true,
                          selectedCurrencyCode,
                        })}
                      </span>
                    </div>
                    <div>
                      <span className={styles.footerLabel}>
                        Total Emails Sent
                        <Tooltip supportingText="hello world">
                          <InfoIcon
                            color="var(--color-disabled)"
                            width={14}
                            height={14}
                          />
                        </Tooltip>
                      </span>
                      {/* no emails are sent for now; */}
                      <span className={styles.footerValue}>0</span>
                    </div>
                  </div>

                  <div className={styles.footerActionsSection}>
                    <div className={styles.compareField}>
                      <label>Compare</label>
                      <ToggleSwitch
                        checked={true}
                        onChange={() => {}}
                        title="Compare"
                      />
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setIsMoreDetailsOpen(false);
                        }}
                        style="plain"
                        color="subdued"
                      >
                        <span className={styles.moreActionBtn}>
                          More Details
                          <KeyboardArrowDown width={14} height={14} />
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
