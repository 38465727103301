import styles from "./IntegrationHeader.module.scss";
import { ShopifyIntegrationRevokeMutationHookResult } from "src/graphql/generated/schema";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";

interface IIntegrationHeader {
  isConnected: boolean;
  revokeIntegrationFn: ShopifyIntegrationRevokeMutationHookResult[0];
  revokeLoading: boolean;
  title: string;
  description: string;
  imageSrc: string;
  isBeta: boolean;
}

export function IntegrationHeader({
  isConnected,
  revokeIntegrationFn,
  revokeLoading,
  title,
  description,
  imageSrc,
  isBeta,
}: IIntegrationHeader) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.profileContainer}>
        <img
          src={imageSrc}
          alt="shopifyProfile"
          width={44}
          height={44}
          className={styles.profileImage}
        />
        <div className={styles.profileText}>
          <div>
            <div className={styles.title}>
              <span className={styles.name}>{title}</span>
              {isBeta && <BadgeV2 size="small" label="Beta" color="purple" />}
            </div>

            <p className={styles.description}>{description}</p>
          </div>
          <div>
            {isConnected && (
              <ButtonV2
                onClick={revokeIntegrationFn}
                variant="outline"
                isDestructive
                size="small"
                loading={revokeLoading}
              >
                Revoke Integration
              </ButtonV2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
