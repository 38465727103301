import { useNavigate, useParams } from "react-router-dom";
import {
  AllCampaignsDocument,
  CampaignDocument,
  CampaignPresetsDocument,
  CampaignsDocument,
  useActivityDeleteMutation,
  useActivityPublishMutation,
  useActivityQuery,
  useCampaignDeleteMutation,
  useCampaignPresetDuplicateMutation,
  useCampaignQuery,
} from "src/graphql/generated/schema";
import styles from "./ActivitySinglePage.module.scss";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { SectionHeading } from "src/modules/global/components/SectionHeading";
import { useMemo, useState } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { ActivitySingleStatCards } from "../components/ActivitySingleStatCards";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/filled/more_vert.svg";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { ReactComponent as PageInfo } from "@material-symbols/svg-400/rounded/page_info.svg";
import { ExpandedBanner } from "src/modules/global/components/ExpandedBanner";
import { itemsPerPage } from "./CampaignOverviewPage";
import Alert from "src/components/Alert/Alert";
import Dropdown from "src/components/Dropdown/Dropdown";
import allCampaigns from "src/graphql/allCampaigns";
import { ActivityAdsList } from "../components/ActivityAdsList";
import { ActivityOverviewLoader } from "../components/ActivityOverviewLoader";
import { ActivityAudienceReporting } from "../components/ActivityAudienceReporting";
import { useActivityAggregatedStats } from "../../global/functions/useActivityAggregatedStats";
import { IconButton } from "src/components/IconButton/IconButton";
import { ActivityAboutCard } from "../components/ActivityAboutCard";
import { DateFilter as CampaignDateFilter } from "src/modules/global/misc/dateFilterUtils";
import { getTinybirdDateFiltersFromDateFilter } from "../misc/campaignSingleFilters";
import { ActivityOverviewStatCards } from "../components/ActivityOverviewStatCards";

const dateFilterOptions: {
  label: string;
  value: CampaignDateFilter;
}[] = [
  {
    label: "Last 7 Days",
    value: "LAST_7_DAYS",
  },
  {
    label: "Last 28 Days",
    value: "LAST_28_DAYS",
  },
  {
    label: "Last 3 months",
    value: "LAST_3_MONTHS",
  },
  {
    label: "Last 6 months",
    value: "LAST_6_MONTHS",
  },
  {
    label: "All time",
    value: "ALL_TIME",
  },
];

export function ActivitySinglePage() {
  const { id, campaignId } = useParams<"id" | "campaignId">();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedDate, setSelectedDate] =
    useState<CampaignDateFilter>("ALL_TIME");
  const { dateFrom, dateTo } = useMemo(
    () => getTinybirdDateFiltersFromDateFilter(selectedDate),
    [selectedDate],
  );
  const navigate = useNavigate();
  const [activityPublishFunc, { loading: activityPublishLoading }] =
    useActivityPublishMutation();
  const [activityDeleteFunc, { loading: activityDeleteLoading }] =
    useActivityDeleteMutation();
  const [campaignDeleteFunc, { loading: campaignDeleteLoading }] =
    useCampaignDeleteMutation();
  const [
    campaignPresetDuplicateFunc,
    { loading: campaignPresetDuplicateLoading },
  ] = useCampaignPresetDuplicateMutation();
  const {
    data: campaignData,
    loading: campaignLoading,
    error: campaignError,
  } = useCampaignQuery({
    variables: {
      campaignId: campaignId,
    },
  });

  const {
    data: activityData,
    loading: activityLoading,
    error: activityError,
  } = useActivityQuery({
    variables: {
      id,
    },
  });

  const { data: activityStatData, loading: activityStatLoading } =
    useActivityAggregatedStats(id, {
      dateFrom,
      dateTo,
    });

  if (activityLoading || campaignLoading || activityStatLoading) {
    return <PageLoader />;
  }

  if (activityError || campaignError) {
    return <ErrorHandler error={activityError || campaignError} />;
  }

  const activity = activityData ? activityData.activity : null;
  const metaActivity = activity?.MetaActivity;
  const activitiesCount = campaignData
    ? campaignData?.campaign?.activitiesCount
    : 0;
  const metaAdsets = metaActivity?.MetaActivityAdsets || [];
  const hasProductSets =
    metaAdsets.length > 0
      ? !metaAdsets[0]?.Audience && !metaAdsets[0]?.MetaAudienceSet
      : true;

  async function onDelete() {
    if (activitiesCount > 1) {
      await activityDeleteFunc({
        variables: {
          id: id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CampaignDocument,
            variables: {
              campaignId: campaignId,
            },
          },
        ],
      });
      navigate(`/campaigns/${campaignId}`);
      return;
    }
    await campaignDeleteFunc({
      variables: {
        id: campaignId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CampaignsDocument,
          variables: {
            filters: {
              limit: itemsPerPage,
              filter: "ERROR",
            },
            sortBy: {
              field: "CREATED_AT",
              direction: "DESC",
            },
          },
        },
      ],
    });
    navigate(`/campaigns`, {
      replace: true,
    });
    setShowDeleteAlert(false);
  }

  return (
    <div className={styles.page}>
      <PageHeader
        title={activity.name}
        back={() => {
          navigate(`/campaigns/${activity.Campaign.id}`);
        }}
        actions={
          <>
            <Dropdown
              className={styles.moreActionDropdown}
              items={dateFilterOptions.map((v) => ({
                children: v.label,
                style: "plain",
                onClick: () => setSelectedDate(v.value),
                color: v.value === selectedDate ? "primary" : "normal",
              }))}
              title={
                <span className={styles.dropdownText}>
                  {
                    dateFilterOptions.find((v) => v.value === selectedDate)
                      ?.label
                  }
                  <ArrowDown width={16} height={16} />
                </span>
              }
            />
            <Dropdown
              items={[
                {
                  children: "Delete Activity",
                  loading: activityDeleteLoading,
                  disabled:
                    activity.status !== "ERROR" ? true : activityDeleteLoading,
                  async onClick() {
                    setShowDeleteAlert(true);
                  },
                },
                // {
                //   children: activity.status === "ACTIVE" ? "Active" : "Pause",
                //   loading: activityDeleteLoading,
                //   disabled: ["ACTIVE", "PAUSED"].includes(activity.status)
                //     ? false
                //     : true,
                //   async onClick() {
                //     // setShowDeleteAlert(true);
                //   },
                // },
              ]}
              title={
                <span className={styles.dropdownText}>
                  <MoreVert height={20} width={20} />
                </span>
              }
            />
          </>
        }
      />
      {activity.status === "ERROR" && (
        <ExpandedBanner
          title={
            "Here are the possible issues, try again after fixing all issues"
          }
          type={"error"}
          actions={[
            {
              children: "Try Again",
              color: "danger",
              style: "solid",
              size: "small",
              borderRadius: "8px",
              width: "auto",
              loading:
                campaignDeleteLoading ||
                activityDeleteLoading ||
                activityPublishLoading,
              disabled:
                campaignDeleteLoading ||
                activityDeleteLoading ||
                activityPublishLoading,
              onClick: async () => {
                await activityPublishFunc({
                  variables: {
                    activityId: id,
                  },
                  refetchQueries: [
                    {
                      query: allCampaigns,
                      variables: {
                        sortBy: {
                          direction: "DESC",
                          field: "CREATED_AT",
                        },
                        filters: {
                          limit: 25,
                        },
                      },
                    },
                  ],
                });
                navigate("/campaigns");
              },
            },
            // {
            //   children: "Clone And Edit",
            //   color: "danger",
            //   style: "outline",
            //   size: "small",
            //   borderRadius: "8px",
            //   width: "auto",
            //   loading: campaignPresetDuplicateLoading,
            //   disabled:
            //     campaignPresetDuplicateLoading ||
            //     campaignDeleteLoading ||
            //     activityDeleteLoading ||
            //     activityPublishLoading,
            //   onClick: async () => {
            //     const { data } = await campaignPresetDuplicateFunc({
            //       variables: {
            //         campaignId,
            //         shouldResetSteps: false,
            //       },
            //       refetchQueries: [
            //         {
            //           query: AllCampaignsDocument,
            //           variables: {
            //             filter: {
            //               limit: 25,
            //             },
            //             sortBy: {
            //               direction: "DESC",
            //               field: "CREATED_AT",
            //             },
            //           },
            //         },
            //         {
            //           query: CampaignPresetsDocument,
            //           variables: {
            //             filter: {
            //               limit: 25,
            //             },
            //             sortBy: {
            //               direction: "DESC",
            //               field: "CREATED_AT",
            //             },
            //           },
            //         },
            //       ],
            //       awaitRefetchQueries: true,
            //     });

            //     if (data && data.campaignPresetDuplicate) {
            //       navigate(
            //         `/campaigns/new?presetId=${data.campaignPresetDuplicate.id}`,
            //       );
            //     }
            //   },
            // },
          ]}
          children={activity?.errorMessage || "Unknown error occurred"}
          className={styles.bannerWrapper}
        />
      )}

      <div className={styles.main}>
        {metaActivity?.MetaActivityAds && (
          <>
            <ActivityOverviewStatCards
              statData={activityStatData}
              metaActivity={metaActivity}
            />
            <ActivityOverviewLoader
              statData={activityStatData}
              activityId={activity.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </>
        )}

        <div className={styles.section}>
          <div className={styles.headerWithAction}>
            <SectionHeading heading="Other Relevant Metrics" />
            <IconButton
              icon={
                <PageInfo fill="rgba(26, 28, 29, 1)" width={16} height={16} />
              }
              color="subdued"
              style="outline"
              size="small"
            />
          </div>
          <div className={styles.statCardList}>
            <ActivitySingleStatCards activityStat={activityStatData} />
          </div>
        </div>

        {/* HACK: If campaign uses ProductSets instead of normal audience and audience set then hide this section */}
        {!hasProductSets && (
          <div className={styles.section}>
            <ActivityAudienceReporting
              dateFrom={dateFrom}
              dateTo={dateTo}
              activityId={activity.id}
              metaActivityAdsets={metaAdsets}
            />
          </div>
        )}

        {metaActivity?.MetaActivityAds && (
          <ActivityAdsList
            activity={activity}
            ads={metaActivity?.MetaActivityAds}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        )}

        <div className={styles.section}>
          <SectionHeading
            heading={"About this Activity"}
            description={"Description about your activity"}
          />
          <div>
            <ActivityAboutCard
              activity={activity as any}
              details={[
                {
                  label: "Ad Account",
                  value: metaActivity?.MetaAdAccount?.metaName ?? "-",
                },
                {
                  label: "Facebook Page",
                  value: metaActivity?.MetaPage?.name ?? "-",
                },
                {
                  label: "Instagram Page",
                  value: metaActivity?.MetaInstagramAccount?.name
                    ? `@${metaActivity?.MetaInstagramAccount?.name}`
                    : "-",
                },
                {
                  label: "Pixel Id",
                  value: metaActivity?.metaPixelId,
                },
                {
                  label: "Conversion Domain",
                  value: metaActivity?.conversionDomain,
                },
              ]}
            />
          </div>
        </div>

        <Alert
          title={`Delete ${activitiesCount > 1 ? "activity" : "campaign"}`}
          isOpen={showDeleteAlert}
          onClose={function (): void {
            setShowDeleteAlert(false);
          }}
          primaryAction={{
            children: "Delete",
            loading: campaignDeleteLoading || activityDeleteLoading,
            disabled: campaignDeleteLoading || activityDeleteLoading,
            color: "danger",
            size: "small",
            style: "solid",
            onClick: onDelete,
          }}
          secondaryAction={{
            children: "Cancel",
            color: "subdued",
            size: "small",
            style: "outline",
            disabled: campaignDeleteLoading || activityDeleteLoading,
            onClick: () => {
              setShowDeleteAlert(false);
            },
          }}
        >
          <div className={styles.deleteAlertContent}>
            {activitiesCount > 1
              ? "All the ads in this activity will be deleted. Are you sure you want to delete"
              : "Are you sure you want to delete this campaign?"}
          </div>
        </Alert>
      </div>
    </div>
  );
}
