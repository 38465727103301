import styles from "./DetailTooltip.module.scss";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";
import classNames from "classnames";

export type TPlacement =
  | "TOP_LEFT"
  | "TOP_RIGHT"
  | "TOP_CENTER"
  | "BOTTOM_CENTER"
  | "BOTTOM_LEFT"
  | "BOTTOM_RIGHT";

interface IDetailTooltip {
  title: string;
  description: string;
  image: { src: string; width?: string; height?: string; alt?: string };
  trigger?: React.ReactNode;
  placement?: TPlacement;
}

/**
 * If you don't pass he trigger it uses the info icon with width and height of 16px
 * If you don't pass placement it will use top-right by default
 */
export function DetailTooltip({
  trigger = <InfoIcon fill="currentColor" width={16} height={16} />,
  title,
  description,
  image,
  placement = "TOP_RIGHT",
}: IDetailTooltip) {
  const placementToClassName: Record<TPlacement, string> = {
    BOTTOM_LEFT: styles.tooltipBottomLeft,
    TOP_LEFT: styles.tooltipTopLeft,
    TOP_RIGHT: styles.tooltipTopRight,
    BOTTOM_RIGHT: styles.tooltipBottomRight,
    TOP_CENTER: styles.tooltipTopCenter,
    BOTTOM_CENTER: styles.tooltipBottomCenter,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.trigger}>{trigger}</div>
      {/* TODO: use a library like popper.js for placement */}
      <div
        className={classNames(
          styles.detailTooltip,
          placementToClassName[placement]
        )}
      >
        <div className={styles.imageSection}>
          <img
            className={styles.image}
            src={image.src}
            alt={image.alt}
            width={image.width ?? 265}
            height={image.height ?? 132}
          />
        </div>
        <div className={styles.infoSection}>
          <h6 className={styles.title}>{title}</h6>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </div>
  );
}
