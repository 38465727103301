import { ReactComponent as MoreOptionsIcon } from "@material-symbols/svg-400/rounded/more_vert.svg";
import {
  ActivityStatusEnum,
  useActivityDeleteMutation,
  useActivityPresetDeleteMutation,
  useActivityStatusUpdateMutation,
} from "src/graphql/generated/schema";
import { DropdownOptionOrSeparator, DropdownV2 } from "./DropdownV2";

interface IActivityItemMoreOptions {
  // send this if campaign is not a preset
  activityId?: string;
  // send this incase campaign is a preset
  activityIndex?: number;
  campaignPresetId?: string;
  status: ActivityStatusEnum;
  setIsActivityUpdating: (i: boolean) => void;
}

export function ActivityItemMoreOptions({
  activityId,
  activityIndex,
  campaignPresetId,
  status,
  setIsActivityUpdating,
}: IActivityItemMoreOptions) {
  const [activityStatusUpdateFn] = useActivityStatusUpdateMutation({
    refetchQueries: ["allCampaigns", "activitiesByStatus", "activitiesCount"],
    awaitRefetchQueries: true,
  });
  const [activityPresetDelete] = useActivityPresetDeleteMutation({
    refetchQueries: ["allCampaigns"],
    awaitRefetchQueries: true,
  });
  const [deleteActivityFn] = useActivityDeleteMutation({
    refetchQueries: ["allCampaigns"],
    awaitRefetchQueries: true,
  });

  const isStatusActiveOrPaused = status === "ACTIVE" || status === "PAUSED";
  const isPreset = campaignPresetId && typeof activityIndex === "number";
  const isStatusFailedOrDraft = status === "DRAFT" || status === "ERROR";
  let options: DropdownOptionOrSeparator[] = [
    {
      label: isPreset ? "Delete Draft" : "Delete Activity",
      value: "DELETE",
      disabled: !isStatusFailedOrDraft,
      variant: "danger",
    },
  ];

  if (isStatusActiveOrPaused) {
    options = [
      {
        label: status === "ACTIVE" ? "Pause Activity" : "Active Activity",
        value: status === "ACTIVE" ? "PAUSED" : "ACTIVE",
      },
      {
        type: "separator",
      },
      ...options,
    ];
  }

  return (
    <DropdownV2
      onSelect={async (value) => {
        setIsActivityUpdating(true);
        try {
          switch (value) {
            case "DELETE":
              if (isPreset) {
                await activityPresetDelete({
                  variables: {
                    campaignPresetId,
                    activityIndex,
                  },
                });
              } else {
                await deleteActivityFn({
                  variables: {
                    id: activityId,
                  },
                });
              }
              break;
            case "ACTIVE":
              await activityStatusUpdateFn({
                variables: {
                  input: {
                    id: activityId,
                    status: "ACTIVE",
                  },
                },
              });
              break;
            case "PAUSED":
              await activityStatusUpdateFn({
                variables: {
                  input: {
                    id: activityId,
                    status: "PAUSED",
                  },
                },
              });
              break;
          }
        } finally {
          setIsActivityUpdating(false);
        }
      }}
      options={options}
      trigger={<MoreOptionsIcon width={16} height={16} />}
    />
  );
}
