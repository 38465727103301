import * as Yup from "yup";

import {
  EmailIntegrationDocument,
  useEmailIntegrationUpdateMutation,
} from "src/graphql/generated/schema";
import { FieldArray, Form, Formik, FormikHelpers, FormikValues } from "formik";

import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { Button } from "src/components/Button/Button";
import { IconButton } from "src/components/IconButton/IconButton";
import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import { Input } from "src/components/Input/Input";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { ReactComponent as Minus } from "@material-design-icons/svg/outlined/do_not_disturb_on.svg";
import Tooltip from "src/components/Tooltip/Tooltip";
import styles from "./AddSenderEmails.module.scss";

interface AddSenderEmailsProps {
  sendersEmail: string[];
  subdomain: string;
  domain: string;
}

export function AddSenderEmails({
  sendersEmail,
  subdomain,
  domain,
}: AddSenderEmailsProps) {
  const [emailIntegrationUpdateFunc, { loading }] =
    useEmailIntegrationUpdateMutation();
  // const [savedEmails, setSavedEmails] = useState(sendersEmailData);
  return (
    <div>
      <div className={styles.header}>
        <span className={styles.heading}>Add Sender Emails</span>
        <Tooltip
          supportingText={
            "Add sender email to send emails to your customer. Customer will see this email address on their email."
          }
        >
          <Info className={styles.infoIco} />
        </Tooltip>
      </div>
      <Formik
        initialValues={{ senders: [""] }}
        validationSchema={Yup.object({
          senders: Yup.array(
            Yup.string()
              .matches(/^[a-zA-Z0-9_-]+$/, "Invalid name")
              .required("Add a sender to save")
          ),
        })}
        onSubmit={async (values, formHelpers) => {
          const filteredValues = values.senders.filter((item) => item !== "");
          // console.log(values, filteredValues);
          await emailIntegrationUpdateFunc({
            variables: {
              input: {
                senderEmails: filteredValues.map((v) => ({
                  email: `${v}@${subdomain}.${domain}`,
                  name: v,
                })),
              },
            },
            refetchQueries: [
              {
                query: EmailIntegrationDocument,
              },
            ],
            awaitRefetchQueries: true,
          });
          // setSavedEmails([...savedEmails, ...filteredValues]);
          formHelpers.resetForm({ values: { senders: [""] } });
        }}
      >
        {({ values, setValues, handleSubmit }) => (
          <Form>
            <FieldArray
              name="senders"
              render={(arrayHelpers) => (
                <div className={styles.tiles}>
                  {values.senders.map((item, index) => (
                    <div className={styles.tile} key={index}>
                      <div className={styles.fieldWrapper}>
                        {/* <Input
                      placeholder="Add email sender (help, support, core etc..)"
                      type="text"
                      value={item}
                      onChange={(e) =>
                        setSendersEmail(
                          sendersEmail.map((item, idx) =>
                            idx === index ? (item = e.target.value) : null
                          )
                        )
                      }
                    /> */}
                        <InputFormik
                          placeholder="Add email sender (help, support, core etc...)"
                          type="text"
                          name={`senders.${index}`}
                        />
                      </div>
                      <span className={styles.domainName}>
                        @{subdomain}.{domain}
                      </span>
                      {index !== 0 && (
                        <IconButton
                          icon={<Minus />}
                          style="plain"
                          onClick={
                            () =>
                              // setSendersEmail(
                              //   sendersEmail.filter((_, idx) => idx !== index)
                              // )
                              arrayHelpers.remove(index)
                            // arrayHelpers.insert(index, "")
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            />
            <div className={styles.btnGrp}>
              <Button
                onClick={() => {
                  setValues({
                    senders: [...values.senders, ""],
                  });
                }}
                className={styles.addMoreBtn}
                color="subdued"
                style="outline"
                size="micro"
              >
                <span className={styles.addMoreBtn}>
                  <span>Add more sender</span>
                  <span>
                    <Add className={styles.addMoreIco} />
                  </span>
                </span>
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                size="micro"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {/* Saved Emails */}
      {sendersEmail.length > 0 && (
        <div className={styles.savedTiles}>
          {sendersEmail.map((item, index) => (
            <div className={styles.tile} key={index}>
              <div className={styles.fieldWrapper}>
                <Input disabled value={item} />
              </div>
              <span className={styles.domainName}>
                @{subdomain}.{domain}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
