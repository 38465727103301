import { formatFileSize } from "src/lib/formatFileSize";
import { Asset as IAsset } from "src/lib/types";
import styles from "./SelectedAsset.module.scss";
import { Asset } from "src/components/Smartphone/Asset";

export function SelectedAsset({ asset }: { asset: IAsset }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.previewSection}>
        <Asset
          name="line item preview"
          type={asset.contentType}
          className={styles.preview}
          preview={asset.uri}
          loop={true}
        />
      </div>
      <div className={styles.content}>
        <p className={styles.name}>{asset.name}</p>
        <p className={styles.moreInfo}>
          <span className={styles.fileSize}>
            {formatFileSize(asset.fileSize)}
          </span>
          <span className={styles.fileDimensions}>
            {asset.width} x {asset.height}
          </span>
        </p>
      </div>
    </div>
  );
}
