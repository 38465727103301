import { Button } from "src/components/Button/Button";
import styles from "./GraphLoadingError.module.scss";

export function GraphLoadingError({
  error,
  height,
}: {
  error: Error;
  height?: number;
}) {
  // TODO: send the error to error service

  return (
    <div style={height ? { height } : null} className={styles.errorSection}>
      <span className={styles.errorText}>
        Could not load the data at this moment
      </span>
      <Button style="solid" color="primary">
        Try Again
      </Button>
    </div>
  );
}
