import styles from "./CampaignActivityCardStatItem.module.scss";

export function CampaignActivityCardStatItem({
  label,
  icon,
  value,
}: {
  label: string;
  icon: string;
  value: string | number;
}) {
  return (
    <div className={styles.statItem}>
      <div className={styles.statItemLabel}>
        <img src={icon} alt={icon} className={styles.icon} />
        <span>{label}</span>
      </div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}
