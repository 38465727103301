import styles from "./BillHistory.module.scss";
import { Billing, useBillingInvoicesQuery } from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import format from "date-fns/format";
import { titleCase } from "src/lib/titleCase";
import classNames from "classnames";
import { Button } from "../../../components/Button/Button";
import { SectionHeading } from "../../global/components/SectionHeading";
import Table from "../../../components/Table/Table";

export function BillHistory({ billing }: { billing: Billing }) {
  const { data, loading, error: _error } = useBillingInvoicesQuery();

  const plan = billing.BillingGrowPlan
    ? billing.BillingGrowPlan
    : billing.BillingEngagePlan;

  const planType = plan.__typename === "BillingEngagePlan" ? "ENGAGE" : "GROW";

  if (loading) {
    return <Spinner height={300} />;
  }

  // if (error) {
  //   return <ErrorHandler error={error} />;
  // }

  // console.log({ data });

  const headings = ["Date", "Invoice", "Status", "Amount", "Action"];
  const rows =
    data && data.billingInvoices.length > 0
      ? data.billingInvoices.map((v) => [
          format(new Date(v.createdAt), "MMM dd, yyyy"),
          `Macro ${titleCase(planType)}/${
            billing.cycle === "ANNUAL" ? "year" : "mo"
          }`,
          <div
            className={classNames(styles.invoiceStatus, {
              [styles.paid]: v.status === "PAID",
              [styles.pending]:
                v.status === "OPEN" || v.status === "UNCOLLECTIBLE",
            })}
          >
            {titleCase(v.status)}
          </div>,
          v.totalAmount / 100,
          <Button style="plain" size="micro" to={v.hostedInvoiceUrl}>
            View Invoice
          </Button>,
        ])
      : [];

  return (
    <div>
      <SectionHeading heading={"Billing History"} />
      <div className={styles.container}>
        {rows.length > 0 ? (
          <Table headings={headings} rows={rows} noOfRows={rows.length} />
        ) : (
          <div className={styles.emptyCard}>
            <img src="/imagesAndIcons/EmptyBillHistory.png" alt="emptyBill" />
            <p>No History Yet</p>
          </div>
        )}
      </div>
    </div>
  );

  // return (
  //   <div>
  //     <SectionHeading heading={"Billing History"} />
  //     <div className={styles.container}>
  //       {data && data.billingInvoices && data.billingInvoices.length > 0 ? (
  //         <div className={styles.card}>
  //           <ul>
  //             {data.billingInvoices.map((invoice) => (
  //               <li className={styles.invoiceItem}>
  //                 <span className={styles.invoiceDate}>
  //                   {format(new Date(invoice.periodStart), "MMMM dd, yyyy")}
  //                   {/* this is a unicode dash */} —{" "}
  //                   {format(new Date(invoice.periodEnd), "MMMM dd, yyyy")}
  //                 </span>
  //                 <span className={styles.invoiceStatus}>
  //                   {titleCase(invoice.status)}
  //                 </span>
  //                 <span className={styles.invoiceTotalAmount}>
  //                   {formatPrice(invoice.totalAmount / 100)}
  //                 </span>
  //                 <span className={styles.invoiceActions}>
  //                   <Button
  //                     target="_blank"
  //                     to={invoice.hostedInvoiceUrl}
  //                     style="plain"
  //                   >
  //                     {invoice.status === "OPEN"
  //                       ? "Pay Invoice"
  //                       : "View Invoice"}
  //                   </Button>
  //                   <Button
  //                     target="_blank"
  //                     to={invoice.invoicePdf}
  //                     style="plain"
  //                   >
  //                     Download Invoice
  //                   </Button>
  //                 </span>
  //               </li>
  //             ))}
  //           </ul>
  //         </div>
  //       ) : (
  //         <div className={styles.emptyCard}>
  //           <img src="/imagesAndIcons/EmptyBillHistory.png" alt="emptyBill" />
  //           <p>No History Yet</p>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
}
