import styles from "./PreviewEmailFooter.module.scss";
import { ReactComponent as Desktop } from "@material-design-icons/svg/outlined/desktop_windows.svg";
import { ReactComponent as Mobile } from "@material-design-icons/svg/outlined/phone_android.svg";
import classNames from "classnames";

const modes = [
  {
    label: "Desktop",
    icon: <Desktop className={styles.ico} width={20} height={20} />,
  },
  {
    label: "Mobile",
    icon: <Mobile className={styles.ico} width={20} height={20} />,
  },
];

interface IPreviewEmailFooter {
  selectedMode: "Desktop" | "Mobile";
  setSelectedMode: (i: any) => void;
}

export function PreviewEmailFooter({
  selectedMode,
  setSelectedMode,
}: IPreviewEmailFooter) {
  return (
    <div className={styles.stickyFooter}>
      {modes.map((item, index) => (
        <button
          type="button"
          key={index}
          className={classNames(styles.btn, {
            [styles.glowBtn]: selectedMode === item.label,
          })}
          onClick={() => setSelectedMode(item.label)}
        >
          {item.icon} <span>{item.label}</span>
        </button>
      ))}
    </div>
  );
}
