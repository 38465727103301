import { Button, IButton } from "src/components/Button/Button";
import styles from "./ActionCard.module.scss";
import classNames from "classnames";

type PLATFORM = "shopify" | "meta";

export interface IActionCard {
  image: string;
  title: string;
  description: string;
  action: IButton;
  border?: "minimal" | "full";
  platform?: PLATFORM;
}

export function ActionCard({
  image,
  title,
  description,
  action,
  border = "full",
}: IActionCard) {
  return (
    <div
      className={classNames(styles.card, {
        [styles.minimalBorder]: border === "minimal",
        [styles.fullBorder]: border === "full",
      })}
    >
      <img src={image} alt="image" width={64} height={64} />
      <div className={styles.text}>
        <span className={styles.title}>{title}</span>
        <p className={styles.description}>{description}</p>
      </div>
      <Button color="normal" style="outline" {...action} className={styles.btn}>
        {action.children}
      </Button>
    </div>
  );
}
