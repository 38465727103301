import { Input } from "../../../../components/Input/Input";
import { ReactComponent as Search } from "@material-symbols/svg-400/rounded/search.svg";
import SortByDropdown from "../../../global/components/SortByDropdown";
import sortOptions from "../../misc/assetsSortBy";
import DateFilterDropdown from "../../../global/components/DateFilterDropdown";
import { DateFilter } from "../../../global/misc/dateFilterUtils";
import styles from "./AssetListingTableHeader.module.scss";
import { assetListingFitler } from "../../misc/assetListing";
import { AssetsOrderBy } from "../../../../graphql/generated/schema";

interface IAssetListingTableHeader {
  filters: assetListingFitler;
  setFilters: (i: assetListingFitler) => void;
  sortBy: AssetsOrderBy;
  setSortBy: (i: AssetsOrderBy) => void;
}

export function AssetListingTableHeader({
  filters,
  setFilters,
  sortBy,
  setSortBy,
}: IAssetListingTableHeader) {
  return (
    <div className={styles.tableHeader}>
      <div className={styles.searchInput}>
        <Input
          width="full"
          icon={Search}
          value={filters.query}
          onChange={(i) =>
            setFilters({
              ...filters,
              query: i,
            })
          }
          placeholder={"Search campaign & activity"}
        />
        <SortByDropdown
          sortOptions={sortOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <DateFilterDropdown
          setDateFilter={(i: DateFilter) => {
            setFilters({
              ...filters,
              dateFilter: i,
            });
          }}
          dateFilter={filters.dateFilter}
        />
      </div>
    </div>
  );
}
