import { CustomerTagsComboBox } from "./CustomerTagsComboBox";
import { useCustomerTagsCreateMutation } from "src/graphql/generated/schema";
import CreateFlowCard from "./CreateFlowCard";

interface IAddTags {
  next: () => void;
  back: () => void;
  selectedTags: any[];
  setSelectedTags: any;
}

function AddTags({ next, back, selectedTags, setSelectedTags }: IAddTags) {
  const [addTagFunc, { loading }] = useCustomerTagsCreateMutation();
  return (
    <CreateFlowCard
      title={"Add tags to your customers"}
      subtitle="Easily organize your contacts using simple words that make you more sense."
      footerActions={[
        {
          children: "Back",
          style: "outline",
          color: "subdued",
          onClick: back,
        },
        {
          children: "Continue to Import",
          onClick: next,
        },
      ]}
    >
      <div style={{ maxWidth: "518px" }}>
        <CustomerTagsComboBox
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags as any}
          addTags={addTagFunc}
          addTagsLoading={loading}
          placeholder={"Hit enter to add tags"}
        />
      </div>
    </CreateFlowCard>
  );
}

export default AddTags;
