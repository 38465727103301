import { ReactComponent as AllMatch } from "@material-symbols/svg-400/rounded/all_match-fill.svg";
import magnet from "../assets/magnet.svg";
import customerRetention from "../assets/customerRetention.png";
import customerReactivation from "../assets/customerReactivation.png";
import { UpgradePlanFeatCard } from "./UpgradePlanFeatCard";

export function UpgradePlanAutomate() {
  const automateArr = [
    {
      icon: <img src={magnet} alt="magnet" />,
      title: "Customer Retention",
      subtitle:
        "From deployments to tasks, work with your team every step of the way.",
      image: customerRetention,
    },
    {
      icon: <AllMatch width={24} height={24} fill="#309D34" />,
      title: "Customer Reactivation",
      subtitle:
        "From deployments to tasks, work with your team every step of the way.",
      image: customerReactivation,
    },
  ];

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gap: "1.88rem",
        paddingTop: "2.25rem",
      }}
    >
      {automateArr.map((item, idx) => (
        <UpgradePlanFeatCard {...item} key={idx} primaryColor="engage" />
      ))}
    </div>
  );
}
