import { WhatsappDetailSection } from "../WhatsappDetailSection/WhatsappDetailSection";
import { Button } from "src/components/Button/Button";
import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import styles from "./WhatsappTemplateBody.module.scss";
import { IconButton } from "src/components/IconButton/IconButton";
import { Input } from "src/components/Input/Input";
import { WhatsappFormInput } from "src/pages/CreateTemplate/CreateMessageTemplateTypes";
import {
  // addNewVariableToText,
  removeAllVariables,
  suggestions,
} from "../WhatsappDetailSection/WhatsappTemplateDetails";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { TipTapEditor } from "src/modules/global/components/TipTapEditor";

interface IWhatsappTemplateBody {
  values: WhatsappFormInput;
  setFieldValue: (
    fieldName: string,
    value: any,
    shouldValidate?: boolean
  ) => void;
}

export function WhatsappTemplateBody({
  values,
  setFieldValue,
}: IWhatsappTemplateBody) {
  // const [bodyVariables, setBodyVariables] = useState([]);
  return (
    <>
      <WhatsappDetailSection
        title="Body"
        subtitle="Enter the text for your message in the language you have selected."
      >
        <div className={styles.container}>
          <TipTapEditor
            content={values?.body?.text}
            onChange={(value) => {
              setFieldValue("body.text", value);
              const variables = value.match(/{{\w+}}/g);
              setFieldValue(
                "body.variables",
                variables ? variables.map((v) => v.replace(/[{{}}]/g, "")) : []
              );
            }}
            placeholder="Enter message body"
            className={styles.editor}
            autoCompleteSuggestions={suggestions}
          />
          <p className={styles.chrLimit}>Character limit: 0/1024</p>
          {/* <Button
            className={styles.btn}
            size="micro"
            color="subdued"
            style="outline"
            onClick={() =>
              addNewVariableToText(values?.body?.text, (i) => {
                const matches = i.match(/{{\d+}}/g);
                setFieldValue("body.text", i);
                setFieldValue(
                  "body.example[0].variableName",
                  matches.length.toString()
                );
              })
            }
          >
            Add Variables <Add className={styles.addIco} />
          </Button> */}
        </div>
      </WhatsappDetailSection>
      {values.body && values.body?.example?.length > 0 && (
        <WhatsappDetailSection
          title={
            <div className={styles.tempBodyVarTitle}>
              <p className={styles.titleText}>
                <span>Define sample variable value for body.</span>
                <Info className={styles.infoIco} />
              </p>
              <IconButton
                icon={<Close className={styles.closeIco} />}
                style="plain"
                onClick={() =>
                  removeAllVariables(values?.body?.text, (i) => {
                    setFieldValue("body.text", i);
                    setFieldValue("body.example", []);
                  })
                }
              />
            </div>
          }
        >
          <ul className={styles.variables}>
            {values.body.example.map((item, idx) => (
              <li key={idx} className={styles.variableTile}>
                {/* use InputFormik Component here */}
                <Input
                  label={idx === 0 && "Variable"}
                  value={item.variableName}
                  disabled
                />
                <InputFormik
                  label={idx === 0 && "Sample Value"}
                  name={`body.example[${idx}].variableExample`}
                />
                {/* TODO: implement this later */}
                {/* <IconButton
                  icon={<Close className={styles.closeIco} />}
                  style="plain"
                  onClick={() => {
                    // const filtered = bodyVariables.filter(
                    //   (_, index) => index !== idx
                    // );
                    // setBodyVariables(filtered);
                  }}
                /> */}
              </li>
            ))}
          </ul>
        </WhatsappDetailSection>
      )}
    </>
  );
}
