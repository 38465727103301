import { Wizard } from "src/modules/global/components/Wizard";
import { AudienceSetNameForm } from "../components/AudienceSetNameForm";
import styles from "./AudienceSetCreatePage.module.scss";
import DistractionFreeHeader from "src/components/Header/DistractionFreeHeader";
import { ReactComponent as CancelIcon } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as ManageAccountsIcon } from "@material-design-icons/svg/outlined/manage_accounts.svg";
import { ReactComponent as PageInfoIcon } from "@material-symbols/svg-400/rounded/page_info.svg";
import { ReactComponent as TaskIcon } from "@material-design-icons/svg/outlined/task.svg";
import { Breadcrumb } from "src/modules/global/misc/wizardTypes";
import { AudienceOrInterestsForm } from "../components/AudienceOrInterestsForm";
import { AudienceSetCreateType } from "../misc/audienceSetCreateType";
import { useState } from "react";
import { AudienceDemographicsForm } from "../components/AudienceDemographicsForm";
import { ReviewAudienceSetForm } from "../components/ReviewAudienceSetForm";
import Alert from "src/components/Alert/Alert";
import { useNavigate } from "react-router-dom";

const defaultValue: AudienceSetCreateType = {
  name: "",
  adAccountId: "",
  adAccountName: "",
  platformAccountId: "",
  locations: [],
  languages: [],
  gender: "ALL",
  minAge: "18",
  maxAge: "65",
  audiences: {},
  interests: {},
  currentStep: "audienceSetName",
};

function getBreadcrumbs(index: number): Breadcrumb[] {
  return [
    {
      label: "Setup Audience Set",
      icon: <ManageAccountsIcon />,
      isActive: index === 0,
      startingStep: "",
      value: "setupAudienceSet",
    },
    {
      label: "Advance Targeting",
      icon: <PageInfoIcon />,
      isActive: index >= 1,
      startingStep: "",
      value: "advanceTargeting",
    },
    {
      label: "Review & Save",
      icon: <TaskIcon />,
      isActive: index >= 2,
      startingStep: "",
      value: "review",
    },
  ];
}

export function AudienceSetCreatePage() {
  const [formData, setFormData] = useState(defaultValue);
  const [isCancelSelected, setIsCancelSelected] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <DistractionFreeHeader
        title="Create Audience Set"
        primaryAction={{
          label: (
            <>
              Cancel <CancelIcon fill="currentColor" />
            </>
          ),
          onClick() {
            setIsCancelSelected(true);
          },
        }}
      />

      <Alert
        isOpen={isCancelSelected}
        onClose={() => setIsCancelSelected(false)}
        secondaryAction={{
          children: "Yes",
          color: "primary",
          onClick: () => {
            setIsCancelSelected(false);
            navigate("/audiences");
          },
        }}
        primaryAction={{
          children: "No",
          color: "subdued",
          style: "outline",
          onClick: () => {
            setIsCancelSelected(false);
          },
        }}
        title="Are you sure you want to cancel ?"
      >
        The progress will be lost
      </Alert>

      <Wizard
        data={formData}
        tree={{
          audienceSetName: {
            id: "audienceSetName",
            isRoot: true,
            getNextStep: () => "audienceOrInterests",
            component: <AudienceSetNameForm />,
            getBreadcrumbs: () => getBreadcrumbs(0),
          },
          audienceOrInterests: {
            id: "audienceOrInterests",
            isRoot: false,
            getNextStep: () => "audienceDemographics",
            getPreviousStep: () => "audienceSetName",
            component: <AudienceOrInterestsForm />,
            getBreadcrumbs: () => getBreadcrumbs(0),
          },
          audienceDemographics: {
            id: "audienceDemographics",
            isRoot: false,
            getNextStep: () => "review",
            getPreviousStep: () => "audienceOrInterests",
            component: <AudienceDemographicsForm />,
            getBreadcrumbs: () => getBreadcrumbs(0),
          },
          review: {
            id: "review",
            getPreviousStep: () => "audienceDemographics",
            getNextStep: () => "review",
            isRoot: false,
            component: <ReviewAudienceSetForm />,
            getBreadcrumbs: () => getBreadcrumbs(2),
          },
        }}
        startingNodeId={formData.currentStep}
      />
    </div>
  );
}
