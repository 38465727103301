import {
  CampaignPlatformEnum,
  CustomerEdge,
  CustomersDocument,
  CustomersSortBy,
  useCustomersDeleteMutation,
  useSegmentCreateMutation,
} from "src/graphql/generated/schema";
import { AddTagToCustomersModal } from "./AddTagToCustomersModal";
import Alert from "src/components/Alert/Alert";
import EmptyState from "src/components/EmptyState/EmptyState";
import { SegmentCreateModal } from "./SegmentCreateModal";
import styles from "./CustomerTable.module.scss";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { itemsPerPage } from "../../global/misc/itemsPerPage";
import searchNotFound from "../assets/searchNotFound.png";
import PlatformIcons from "src/modules/global/components/PlatformIcons";
import { CustomerListItem } from "./CustomerListItem";
import { PageLoader } from "src/modules/global/components/PageLoader";

interface ICustomersTable {
  customers: CustomerEdge[];
  filters: any;
  loading: boolean;
  sortBy: CustomersSortBy;
}

export function CustomersTable({
  customers,
  filters,
  sortBy,
  loading,
}: ICustomersTable) {
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const [showCreateSegmentModal, setShowCreateSegmentModal] = useState(false);
  const [showDeleteCustomersConfirmation, setShowDeleteCustomersConfirmation] =
    useState(false);
  const [segmentCreate, { loading: segmentCreateLoading }] =
    useSegmentCreateMutation();
  const [customersDelete, { loading: customersDeleteLoading }] =
    useCustomersDeleteMutation();
  const [segmentName, setSegmentName] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  // this creates segment from all the selected customers
  async function handleCreateSegment() {
    // Selected Items are just indexes
    const getSelectedItems = customers
      .filter((_, index) => selectedItems.includes(index))
      .map((v) => v.node.id);

    const payload = await segmentCreate({
      variables: {
        input: {
          customerIds: getSelectedItems,
          name: segmentName,
          filter: "",
        },
      },
    });
    if (payload.errors) {
      toast.error("An error occurred");
      return null;
    }
    return payload.data.segmentCreate;
  }

  async function handleDeleteSelectedItems() {
    // Selected Items are just indexes
    const getSelectedItems = customers
      .filter((_, index) => selectedItems.includes(index))
      .map((v) => v.node.id);

    await customersDelete({
      variables: {
        ids: getSelectedItems,
      },
      refetchQueries: [
        {
          query: CustomersDocument,
          variables: {
            input: {
              filters: filters,
              sortBy: sortBy,
              take: itemsPerPage,
              cursor: null,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    // Remove all the selection after deleting
    setSelectedItems([]);
    // Close the confirmation
    setShowDeleteCustomersConfirmation(false);
  }

  if (loading) {
    return <PageLoader />;
  }

  // this is when customer are search with filters
  if (customers && customers.length === 0) {
    return (
      <div className={styles.emptyContainer}>
        <EmptyState
          image={
            <img
              src={searchNotFound}
              alt="searchNotFound"
              width={220}
              height={215}
            />
          }
          title="No Results found"
          children={
            <>
              <p
                style={{
                  marginBottom: "1rem",
                  textAlign: "center",
                  color: "var(--color-subdued)",
                }}
              >
                Double-check your spelling or try another search the information
                might be playing hide and seek!
              </p>
            </>
          }
        />
      </div>
    );
  }

  return (
    <div className={styles.customerTable}>
      <SegmentCreateModal
        {...{
          showCreateSegmentModal,
          setShowCreateSegmentModal,
          segmentName,
          setSegmentName,
          segmentCreateLoading,
          handleCreateSegment,
          setSelectedItems,
        }}
      />
      <AddTagToCustomersModal
        {...{
          showAddTagsModal,
          setShowAddTagsModal,
          customers,
          selectedItems,
          setSelectedItems,
        }}
      />
      <Alert
        isOpen={showDeleteCustomersConfirmation}
        title="Delete Customers"
        onClose={() => {
          if (customersDeleteLoading) return;
          setShowDeleteCustomersConfirmation(false);
        }}
        primaryAction={{
          children: "Delete",
          color: "danger",
          onClick: handleDeleteSelectedItems,
          loading: customersDeleteLoading,
          size: "micro",
        }}
        secondaryAction={{
          children: "Cancel",
          onClick: () => setShowDeleteCustomersConfirmation(false),
          loading: customersDeleteLoading,
          disabled: customersDeleteLoading,
          style: "outline",
          size: "micro",
        }}
      >
        <div className={styles.deleteAlertContainer}>
          Are you sure that you want to delete the selected customers
        </div>
      </Alert>
      <div className={styles.customerList}>
        {customers.map(({ node }) => (
          <CustomerListItem customer={node} />
        ))}
      </div>
    </div>
  );
}
