import { Smartphone } from "src/components/Smartphone/Smartphone";
import styles from "./AdPreviewSection.module.scss";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { ReactComponent as FacebookTabIcon } from "../assets/facebookTabIcon.svg";
import { ReactComponent as InstagramTabIcon } from "../assets/instagramTabIcon.svg";
import { ReactComponent as PanZoomIcon } from "@material-symbols/svg-600/rounded/pan_zoom.svg";
import classNames from "classnames";
import { useState } from "react";
import postThumbnail from "../assets/postThumbnail.svg";
import storyThumbnail from "../assets/storyThumbnail.svg";
import { transformAdTemplateToLineItems } from "../../media/functions/transformAdTemplateToLineItems";
import { transformTemplateInputToMetaActivityAdTemplate } from "../../media/functions/transformTemplateInputToMetaActivityAdTemplate";

const previewTabs = [
  {
    label: <InstagramTabIcon width={16} height={16} fill="currentColor" />,
    value: "INSTAGRAM",
  },
  {
    label: <FacebookTabIcon width={16} height={16} fill="currentColor" />,
    value: "FACEBOOK",
  },
];

const formatOptions = [
  { image: postThumbnail, value: "feed" as const, label: "Feed" },
  { image: storyThumbnail, value: "stories" as const, label: "Stories" },
  // { image: instaStory, value: "stories" as const, label: "Reels" },
];

interface IAdPreviewSection {
  onExpandPreview: () => void;
}

export function AdPreviewSection({ onExpandPreview }: IAdPreviewSection) {
  const [selectedTab, setSelectedTab] = useState(previewTabs[0].value);
  const [selectedFormat, setSelectedFormat] = useState<"feed" | "stories">(
    "feed"
  );
  const { values } = useFormikContext<ActivityInputV2>();
  const selectedAdIndex = values.selectedAdIndex ?? 0;
  const selectedAd = values.Ads ? values.Ads[selectedAdIndex] : null;

  const smartphonePreview = Boolean(selectedAd)
    ? transformAdTemplateToLineItems({
        ...selectedAd,
        id: selectedAd.adTemplateId,
        createdAt: new Date(),
        updatedAt: new Date(),
        name: "Ad",
      })
    : null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.previewContainer}>
        <div className={styles.placementHeader}>
          <h3 className={styles.heading}>Placement Preview</h3>
          <h4 className={styles.subheading}>
            Change placement by hovering on preview
          </h4>
        </div>

        <div className={styles.preview}>
          <Smartphone
            emptyScreenMessage="Select ad to see the preview"
            accountName={values.metaPageName}
            adType={selectedAd?.adType}
            callToActionLabel={selectedAd?.callToActionLabel}
            preview={smartphonePreview}
            primaryText={selectedAd?.primaryText}
            profilePicture={values.metaPagePicture}
            type={selectedTab === "FACEBOOK" ? "facebook" : "instagram"}
            variant={selectedFormat}
            screenSize="23rem"
          />
        </div>

        <div className={styles.footer}>
          <div className={styles.tabWrapper}>
            {previewTabs.map((tab) => (
              <button
                onClick={() => {
                  setSelectedTab(tab.value);
                }}
                className={classNames(styles.tab, {
                  [styles.selected]: selectedTab === tab.value,
                })}
                type="button"
              >
                {tab.label}
              </button>
            ))}
          </div>

          <div>
            {selectedAd?.adType === "CUSTOM_CREATIVE" && (
              <button
                onClick={onExpandPreview}
                className={styles.expandButton}
                type="button"
              >
                <PanZoomIcon width={12} height={12} fill="currentColor" />
                Expand
              </button>
            )}
          </div>
        </div>
      </div>

      <div className={styles.previewSelectorSection}>
        <h4 className={styles.placementHeading}>Placement</h4>
        {formatOptions.map((format) => (
          <button
            type="button"
            onClick={() => {
              setSelectedFormat(format.value);
            }}
            className={styles.previewSelector}
          >
            <span
              className={classNames(styles.previewThumbnailWrapper, {
                [styles.previewThumbnailWrapperSelected]:
                  selectedFormat === format.value,
              })}
            >
              <img
                src={format.image}
                alt={format.value}
                width={44}
                height="auto"
                className={styles.previewThumbnail}
              />
            </span>
            <span
              className={classNames(styles.placementTitle, {
                [styles.placementTitleSelected]: true,
              })}
            >
              {format.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}
