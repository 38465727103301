import format from "date-fns/format";
import {
  Pagination as IPagination,
  useSegmentsQuery,
} from "src/graphql/generated/schema";
import { useMemo, useState } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";
import { Button } from "src/components/Button/Button";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { SegmentEmpty } from "../components/SegmentEmpty";
import { SegmentListTable } from "../components/SegmentListTable";
import { useSearchParams } from "react-router-dom";
import { SegmentCreateModalLoader } from "../components/SegmentCreateModalLoader";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function SegmentListingPage() {
  const [pagination, setPagination] = useState({
    cursor: "",
    take: itemsPerPage,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const showCreateModal = useMemo(
    () => searchParams.get("createSegment") === "true",
    [searchParams]
  );

  const {
    data: segmentsData,
    loading: segmentsLoading,
    error: segmentsError,
  } = useSegmentsQuery({
    variables: {
      filter: {
        cursor: pagination.cursor,
        take: pagination.take,
      },
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
    },
  });

  if (segmentsError) {
    return <ErrorHandler error={segmentsError} />;
  }

  if (segmentsLoading) {
    return <PageLoader />;
  }

  const segments = segmentsData?.segments.edges ?? [];

  const segmentsPagination: IPagination =
    segmentsData && segmentsData.segments
      ? segmentsData.segments.pageInfo
      : {
          hasNextPage: false,
          hasPreviousPage: false,
          itemsPerPage: itemsPerPage,
          totalItems: 0,
        };

  return (
    <div>
      <PageHeader
        title="Segments"
        actions={
          segments.length > 0 ? (
            <div>
              <Button
                color="primary"
                size="small"
                onClick={() =>
                  setSearchParams({
                    createSegment: "true",
                  })
                }
              >
                New Segment
              </Button>
            </div>
          ) : null
        }
      />

      {segments.length === 0 ? (
        <SegmentEmpty
          getStarted={() => {
            let params = {
              createSegment: "true",
            };
            setSearchParams(params);
          }}
        />
      ) : (
        <SegmentListTable
          segments={segments}
          segmentsPagination={segmentsPagination}
          setPagination={setPagination}
        />
      )}

      <SegmentCreateModalLoader
        isCreateModalOpen={showCreateModal}
        onCreateModalClose={() => {
          setSearchParams({});
        }}
      />
    </div>
  );
}
