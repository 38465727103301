import { Form, Formik } from "formik";
import { ReactNode, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddTemplateDetails } from "./Common/AddTemplateDetails/AddTemplateDetails";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as Group } from "@material-design-icons/svg/filled/groups_3.svg";
import Header from "src/components/Header/Header";
import { ReviewTemplate } from "./ReviewTemplate/ReviewTemplate";
import { SetupTemplate } from "./Common/SetupTemplate/SetupTemplate";
import Stepper from "src/components/Stepper/Stepper";
import { ReactComponent as Store } from "@material-design-icons/svg/filled/store.svg";
import { ReactComponent as Task } from "@material-design-icons/svg/outlined/task.svg";
import styles from "./CreateTemplate.module.scss";
import { toast } from "react-hot-toast";
import {
  ICreateTemplate,
  CreateMessageTemplateStepType,
  WHATSAPP_STEPS,
  SMS_STEPS,
  MessageFormInput,
  FormInput,
  WhatsappFormInput,
} from "./CreateMessageTemplateTypes";
import { generateFormikInput } from "./helper/generateFormikInput";
import { generateValidationSchema } from "./helper/generateValidationSchema";
import { upsertTextMessageTemplate } from "./helper/upsertTextMessageTemplate";
import {
  useTextMessageTemplateCreateMutation,
  useTextMessageTemplateQuery,
  useTextMessageTemplateUpdateMutation,
  useWhatsappTemplateCreateMutation,
} from "src/graphql/generated/schema";
import { upsertWhatsappMessageTemplate } from "./helper/upsertWhatsappMessageTemplate";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";

export function CreateMessageTemplate({ platform }: ICreateTemplate) {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { data, error, loading } = useTextMessageTemplateQuery({
    variables: {
      id: templateId,
    },
  });
  const [textMessageTemplateUpdate] = useTextMessageTemplateUpdateMutation();
  const [textMessageTemplateCreate] = useTextMessageTemplateCreateMutation();
  const [whatsappTemplateCreate] = useWhatsappTemplateCreateMutation();
  const createTemplatesStep: Readonly<CreateMessageTemplateStepType[]> =
    useMemo(() => {
      const steps = platform === "WHATSAPP" ? WHATSAPP_STEPS : SMS_STEPS;
      return steps;
    }, [platform]);

  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const formChildren: Record<CreateMessageTemplateStepType, ReactNode> = {
    SETUP: <SetupTemplate templateType={platform} />,
    ADD_DETAILS_SMS: (
      <AddTemplateDetails
        templateType={platform}
        creationStep={activeStepIndex}
        setCreationStep={setActiveStepIndex}
      />
    ),
    ADD_DETAILS_WHATSAPP: (
      <AddTemplateDetails
        templateType={platform}
        creationStep={activeStepIndex}
        setCreationStep={setActiveStepIndex}
      />
    ),
    REVIEW: (
      <ReviewTemplate
        templateType={platform}
        setActiveStep={setActiveStepIndex}
      />
    ),
  };

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  async function handleSubmit(values: FormInput) {
    const activeStep = createTemplatesStep[activeStepIndex];
    if (activeStep === "SETUP") {
      setActiveStepIndex(activeStepIndex + 1);
      return;
    }

    if (activeStep === "ADD_DETAILS_SMS") {
      setActiveStepIndex(activeStepIndex + 1);
      return;
    }

    if (activeStep === "ADD_DETAILS_WHATSAPP") {
      setActiveStepIndex(activeStepIndex + 1);
      return;
    }

    if (activeStep === "REVIEW") {
      if (platform === "SMS") {
        await upsertTextMessageTemplate({
          templateId,
          textMessageTemplateCreate,
          values: values as MessageFormInput,
          textMessageTemplateUpdate,
        });

        toast.success("Successfully create text message template");
      }

      if (platform === "WHATSAPP") {
        try {
          await upsertWhatsappMessageTemplate({
            values: values as WhatsappFormInput,
            whatsappTemplateCreate,
          });

          toast.success("Successfully created whatsapp template");
          navigate("/templates");
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  return (
    <div className={styles.page}>
      <Header
        title="Create Template"
        mode="DISTRACTION_FREE"
        primaryAction={{
          label: (
            <div className={styles.primaryAction}>
              Cancel <Close className={styles.cancelIco} />
            </div>
          ),
          onClick: () => {
            navigate("/templates");
          },
        }}
      />
      <Stepper
        steps={[
          { label: "Setup Template", icon: <Store /> },
          { label: "Add Details", icon: <Group /> },
          { label: "Review & submit", icon: <Task /> },
        ]}
        activeStepIndex={activeStepIndex}
      />
      <div className={styles.container}>
        <Formik
          enableReinitialize
          validationSchema={generateValidationSchema({ platform })}
          initialValues={generateFormikInput(platform, data)}
          onSubmit={handleSubmit}
        >
          <Form>{formChildren[createTemplatesStep[activeStepIndex]]}</Form>
        </Formik>
      </div>
    </div>
  );
}
