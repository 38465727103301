import { AutomationEdge } from "src/graphql/generated/schema";
import { AutomationEmptyState } from "../../AutomationEmptyState/AutomationEmptyState";
import { AutomationTableHeader } from "./AutomationTableHeader";
import { AutomationsTableItem } from "./AutomationsTableItem";
import styles from "./AutomationsTable.module.scss";
import { useState } from "react";
import FilterSidebar from "src/modules/global/components/FilterSidebar";

export default function AutomationsTable({
  automations,
}: {
  automations: AutomationEdge[];
}) {
  const [view, setView] = useState("All");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<any>({
    platform: "ALL",
    status: "ALL",
  });
  if (automations.length === 0) {
    return <AutomationEmptyState />;
  }
  return (
    <div className={styles.wrapper}>
      <AutomationTableHeader
        view={view}
        setView={setView}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
      />
      <div className={styles.tableLayout}>
        {showFilters ? (
          <FilterSidebar filters={filters} setFilters={setFilters} />
        ) : null}
        <div className={styles.table}>
          {automations.map((automation, index) => (
            <AutomationsTableItem automation={automation.node} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
