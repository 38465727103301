import instagramPlacementIcon from "../assets/instagramPlacementIcon.svg";
import facebookPlacementIcon from "../assets/facebookPlacementIcon.svg";
import { ReactNode } from "react";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import styles from "./ManualPlacementOptions.module.scss";
import classNames from "classnames";
import {
  CheckboxGroup,
  CheckboxOption,
} from "src/components/CheckboxGroup/CheckboxGroup";

interface IManualPlacementOptions {
  value: string[];
  onChange: (value: string[]) => void;
}

export function ManualPlacementOptions({
  value,
  onChange,
}: IManualPlacementOptions) {
  const options = [
    {
      label: "Facebook",
      subLabel: "Includes Feeds, Stories, Reels and In-Stream Ads in Facebook",
      value: "FACEBOOK",
      icon: (
        <img
          src={facebookPlacementIcon}
          alt="facebook icon"
          width={40}
          height={40}
        />
      ),
    },
    {
      label: "Instagram",
      subLabel: "Includes Feeds, Stories, Reels and In-Stream Ads in Instagram",
      value: "INSTAGRAM",
      icon: (
        <img
          src={instagramPlacementIcon}
          alt="instagram icon"
          width={40}
          height={40}
        />
      ),
    },
  ];

  return (
    <CheckboxGroup value={value} onChange={onChange}>
      <div className={styles.list}>
        {options.map((option) => (
          <CheckboxOption value={option.value}>
            {({ checked, onChange }) => (
              <SelectOption
                label={option.label}
                subLabel={option.subLabel}
                icon={option.icon}
                value={option.value}
                checked={checked}
                onChange={onChange}
              />
            )}
          </CheckboxOption>
        ))}
      </div>
    </CheckboxGroup>
  );
}

interface ISelectOption {
  label: string;
  subLabel: string;
  icon: ReactNode;
  checked: boolean;
  value: string;
  onChange: (i: string) => void;
}

// this is different than normal select option
// because it has a checkbox
// and also it has different highlighted state
function SelectOption({
  label,
  subLabel,
  icon,
  value,
  onChange,
  checked,
}: ISelectOption) {
  return (
    <div className={styles.selectOption}>
      <span>{icon}</span>
      <div className={styles.contentContainer}>
        <span
          className={classNames(styles.label, {
            [styles.labelSelected]: checked,
          })}
        >
          {label}
        </span>
        <span className={styles.subLabel}>{subLabel}</span>
      </div>
      <span>
        <Checkbox
          size="small"
          value={value}
          checked={checked}
          onChange={() => onChange(value)}
        />
      </span>
    </div>
  );
}
