import { FullStory } from "@fullstory/browser";
import Intercom from "@intercom/messenger-js-sdk";
import { ReactNode, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  Client,
  useGetBillingQuery,
  useLoginUserMutation,
  useSignupUserWithEmailPasswordMutation,
} from "src/graphql/generated/schema";
import { AppInitializingLoader } from "src/modules/global/components/AppInitializingLoader";
import {
  activeClientRemove,
  activeClientSet,
  useActiveClientGet,
} from "src/modules/global/functions/activeClient";
import {
  authTokenRemove,
  authTokenSet,
} from "src/modules/global/functions/authToken";
import {
  userDetailGet,
  userDetailRemove,
  userDetailSet,
} from "src/modules/global/functions/userDetail";
import AuthContext, { AuthContextProps } from "src/stores/AuthContext";

interface IAuth {
  email: string;
  password: string;
  inviteCode?: string;
  firstName?: string;
  lastName?: string;
}

interface Props {
  children: ReactNode;
  userToken?: string;
}

export function AuthDataLoader({ userToken, children }: Props) {
  const isAuthenticated = userToken ? true : false;
  const [userDetail, setUserDetail] = useState(userDetailGet());
  const [loginFn] = useLoginUserMutation();
  const [signupFn] = useSignupUserWithEmailPasswordMutation();
  const { data: getBillingData, loading: billingLoading } =
    useGetBillingQuery();
  const activeClient = useActiveClientGet();

  // update to cookie
  useEffect(() => {
    if (userDetail) {
      if (process.env.REACT_APP_ENV === "production") {
        Intercom({
          app_id: "ra3fku79",
          user_id: userDetail.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
          name: userDetail.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
          email: userDetail.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
          ...(userDetail.Organization && {
            company: {
              id: userDetail.Organization?.id,
              name: userDetail.Organization?.name,
            },
          }),
        });
        FullStory("setIdentity", {
          uid: userDetail.id,
          properties: {
            displayName: userDetail.name,
            email: userDetail.email,
            // Add your own custom user variables here, details at
            // https://developer.fullstory.com/browser/identification/set-user-properties/
            ...(userDetail.Organization && {
              company: {
                id: userDetail.Organization?.id,
                name: userDetail.Organization?.name,
              },
            }),
            clients: userDetail.Clients,
          },
        });
        Sentry.setUser({
          email: userDetail?.email,
          username: userDetail?.firstName + userDetail?.lastName,
        });
      }
      userDetailSet(userDetail);
    }
  }, [userDetail]);

  function setActiveClient(activeClient: Client, location?: string): void {
    activeClientSet(activeClient);
    // this will refresh the apollo cache and redirect the user
    window.location.href = location || "/dashboard";
  }

  async function updateAuthCredentials({
    token,
    user,
  }: {
    token: string;
    user: any;
  }) {
    userDetailSet(user);
    authTokenSet(token);
    if (user.Clients.length) {
      activeClientSet(user.Clients[0]);
    }
  }

  async function signup({
    email,
    password,
    inviteCode,
    firstName,
    lastName,
  }: IAuth) {
    const payload = await signupFn({
      variables: {
        input: {
          email,
          password,
          ...(firstName && {
            firstName,
          }),
          ...(lastName && {
            lastName,
          }),
        },
        ...(inviteCode && {
          inviteCode,
        }),
      },
    });
    const { authPayload, userError } = payload.data.signupUserWithEmailPassword;

    if (userError) {
      throw userError;
    }

    updateAuthCredentials(authPayload);

    if (authPayload.user?.Organization) {
      window.location.href = "/";
      return;
    }

    // this will do a browser refresh of the page; wiping all apollo cache which is important
    window.location.href = "/onboarding/about-you";
    return;
  }

  async function login({ email, password }) {
    const payload = await loginFn({
      variables: {
        input: {
          email: email,
          password: password,
        },
      },
    });

    const { authPayload, userError } = payload.data.loginUserWithEmailPassword;
    if (userError) {
      throw userError;
    }

    updateAuthCredentials(authPayload);

    window.location.href = "/dashboard";
    return;
  }

  function logout() {
    // resets token
    authTokenRemove();
    activeClientRemove();
    userDetailRemove();

    window.location.href = "/auth/login";
  }

  const defaultValues: AuthContextProps = {
    user: userDetail,
    isAuthenticated,
    setUser: setUserDetail,
    userToken,
    activeClient,
    setActiveClient,
    login,
    logout,
    signup,
    billing: getBillingData?.billing,
  };

  if (billingLoading) {
    return <AppInitializingLoader />;
  }

  return (
    <AuthContext.Provider value={defaultValues}>
      {children}
    </AuthContext.Provider>
  );
}
