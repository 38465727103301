import { Tabs } from "src/components/Tabs/Tabs";
import styles from "./NotificationTabs.module.scss";
import {
  NotificationReadStatus,
  useNotificationsCountQuery,
} from "src/graphql/generated/schema";
import Skeleton from "react-loading-skeleton";

export function NotificationTabs({
  selectedReadStatus,
  setSelectedReadStatus,
}: {
  selectedReadStatus: NotificationReadStatus;
  setSelectedReadStatus: (i: NotificationReadStatus) => void;
}) {
  const { data, loading } = useNotificationsCountQuery({
    pollInterval: 12 * 1000,
    variables: {
      filter: { readStatus: "UNREAD" },
    },
  });

  return (
    <div className={styles.tabWrapper}>
      <Tabs
        tabs={[
          { label: "All", value: "ALL" },
          {
            label: (
              <span className={styles.unread}>
                Unread{" "}
                <UnreadCount
                  loading={loading}
                  count={data?.notificationsCount}
                />
              </span>
            ),
            value: "UNREAD",
          },
          { label: "Read", value: "READ" },
        ]}
        selectedTab={selectedReadStatus}
        onTabChange={setSelectedReadStatus}
      />
    </div>
  );
}

function UnreadCount({ loading, count }: { loading: boolean; count: number }) {
  if (loading) {
    return <Skeleton width={20} height={20} />;
  }

  if (!count) {
    return null;
  }

  return (
    <span className={styles.noOfNotifications}>{Math.min(99, count)}</span>
  );
}
