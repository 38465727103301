import { useEffect, useState } from "react";

type DEVICE_TYPE = "MOBILE" | "TABLET" | "DESKTOP";

export function useDeviceType() {
  const [deviceType, setDeviceType] = useState<DEVICE_TYPE>(
    window.innerWidth > 1000 ? "DESKTOP" : "MOBILE"
  );

  const handleDeviceTypeChange = (width: number) => {
    if (width > 1024) {
      setDeviceType("DESKTOP");
      return;
    }

    if (width < 1024 || width > 800) {
      setDeviceType("TABLET");
    }

    if (width < 800) {
      setDeviceType("MOBILE");
    }
  };
  useEffect(() => {
    handleDeviceTypeChange(window.innerWidth);
    window.addEventListener("resize", () => {
      handleDeviceTypeChange(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        handleDeviceTypeChange(window.innerWidth);
      });
    };
  }, []);

  return deviceType;
}
