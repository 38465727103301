import { Form, Formik } from "formik";
import { MutableRefObject, useContext, useState } from "react";
import { FormikError } from "src/components/FormikError/FormikError";
import { Input } from "src/components/Input/Input";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import styles from "./SegmentSelector.module.scss";
import { ReactComponent as FilterIcon } from "@material-symbols/svg-600/rounded/filter_list.svg";
import { ReactComponent as SearchIcon } from "@material-symbols/svg-600/rounded/search.svg";
import sortOptions from "src/modules/campaign/misc/campaignSortBy";
import { SegmentSelectorLoader } from "./SegmentSelectorLoader";
import { Button } from "src/components/Button/Button";

export function SegmentSelector({
  sectionRef,
}: {
  sectionRef: MutableRefObject<HTMLDivElement>;
}) {
  const [selectedFilters, setSelectedFilters] = useState({
    searchQuery: "",
    date: "ALL_TIME" as DateFilter,
    platform: "META" as const,
    audienceCategoryId: null,
    take: 25,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "DESC" | "ASC",
    field: "CREATED_AT" as const,
  });
  const { activeStepIndex, data, markStepAsDone } =
    useContext(StepWizardContext);

  return (
    <Formik
      // validationSchema={audienceSelectorSchema}
      enableReinitialize
      initialValues={data as ActivityInputV2}
      onSubmit={(values) => {
        markStepAsDone(3, values);
      }}
    >
      {({
        values,
        errors,
        submitCount,
        touched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 1}
            isCompleted={activeStepIndex > 1}
          >
            <section ref={sectionRef} className={styles.section}>
              <div>
                <h2 className={styles.heading}>
                  Select Segments for marketing activity
                </h2>
                <h3 className={styles.subheading}>
                  Select segment whom you want to target for your marketing
                  activity
                </h3>
              </div>

              <div className={styles.filters}>
                <Input
                  value={selectedFilters.searchQuery}
                  onChange={(value) => {
                    setSelectedFilters({
                      ...selectedFilters,
                      searchQuery: value,
                    });
                  }}
                  icon={SearchIcon}
                  placeholder="Search your segment here"
                />
                <SortByDropdown
                  setSortBy={setSelectedSortBy}
                  sortBy={selectedSortBy}
                  sortOptions={sortOptions}
                />
                {/* <Dropdown
                  items={[
                    {
                      children: "All",
                      onClick: () => {
                        setSelectedFilters({
                          ...selectedFilters,
                          audienceCategoryId: null,
                        });
                      },
                    },
                    ...audienceCategories.map((a) => ({
                      children: a.label,
                      onClick: () => {
                        setSelectedFilters({
                          ...selectedFilters,
                          audienceCategoryId: a.value,
                        });
                      },
                    })),
                  ]}
                  title={
                    <button type="button" className={styles.filterButton}>
                      <FilterIcon
                        fill="var(--color-disabled)"
                        width={18}
                        height={18}
                      />
                      Filter
                    </button>
                  }
                /> */}

                <DateFilterDropdown
                  dateFilter={selectedFilters.date}
                  setDateFilter={(value) => {
                    setSelectedFilters({
                      ...selectedFilters,
                      date: value,
                    });
                  }}
                />
              </div>

              <div className={styles.segmentBox}>
                <div className={styles.segmentListHeader}>
                  <span>Segment Name</span>
                  <span>Size</span>
                  <span>Avg. LTV</span>
                  <span className={styles.alignRight}>LTV</span>
                  <span className={styles.alignRight}>Subscribed on</span>
                </div>

                <div
                  id="scrollTargetForInfiniteScroll"
                  className={styles.segmentList}
                >
                  <SegmentSelectorLoader
                    selectedFilters={selectedFilters}
                    selectedSortBy={selectedSortBy}
                  />
                </div>
              </div>

              <FormikError
                fieldName="Adsets"
                formikError={errors}
                submitCount={submitCount}
                touched={touched}
              />
              <div className={styles.actions}>
                <Button onClick={handleSubmit} size="micro">
                  Save & Continue
                </Button>
              </div>
            </section>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
