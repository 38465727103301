import { Automation } from "src/graphql/generated/schema";
import Badge from "src/components/Badge/Badge";
import { Link } from "react-router-dom";
import classNames from "classnames";
import format from "date-fns/format";
import styles from "./AutomationsTableItem.module.scss";
import PlatformIcons from "src/modules/global/components/PlatformIcons";

interface Props {
  automation: Automation;
}

function getStatusColor(status) {
  switch (status.toLowerCase()) {
    case "active":
      return "success";
    case "draft":
      return "default";
    default:
      return "default";
  }
}

export function AutomationsTableItem({ automation }: Props) {
  const platformIco = automation.AutomationStepPlatforms.map((item) => {
    return {
      platform: item,
    };
  });
  return (
    <div className={classNames("campaign-items", styles.container)}>
      <Link
        // to={`/automations/${automation.id}`}
        to={`/automations`}
        className={styles.platformAndTitle}
      >
        <div className={styles.platform}>
          <img
            src={
              automation.AutomationTriggerPlatforms[0] === "SHOPIFY"
                ? "/imagesAndIcons/ShopifyAuto.svg"
                : "/imagesAndIcons/MacroSample.svg"
            }
          />
        </div>
        <div>
          <p className={styles.title}>{automation.name}</p>
          <span className={styles.date}>
            Created on : {format(new Date(automation.createdAt), "dd MMM yyyy")}
          </span>
        </div>
      </Link>
      <div className={styles.badgeContainer}>
        <Badge
          label={automation.status}
          status={getStatusColor(automation.status)}
        />
      </div>
      <div className={styles.platformIconsContainer}>
        <PlatformIcons platforms={platformIco} />
      </div>
      <div className={styles.conversion}>
        <p className={styles.conversionValue}>
          {automation.AutomationInsights.sendCount}
        </p>
        <span className={styles.conversionText}>Sends</span>
      </div>
      <div className={styles.conversion}>
        <p className={styles.conversionValue}>
          {/* If either numbers are 0 return 0 */}
          {automation.AutomationInsights.linkClickCount === 0 ||
          automation.AutomationInsights.sendCount === 0
            ? 0
            : (
                automation.AutomationInsights.linkClickCount /
                automation.AutomationInsights.sendCount
              ).toFixed(2)}
          %
        </p>
        <span className={styles.conversionText}>Open Rate</span>
      </div>
      <div className={styles.conversion}>
        <p className={styles.conversionValue}>
          {/* If either numbers are 0 return 0 */}
          {automation.AutomationInsights.linkClickCount === 0 ||
          automation.AutomationInsights.sendCount === 0
            ? 0
            : (
                automation.AutomationInsights.linkClickCount /
                automation.AutomationInsights.sendCount
              ).toFixed(2)}
          %
        </p>
        <span className={styles.conversionText}>Click Rate</span>
      </div>
    </div>
  );
}
