import styles from "./Smartphone.module.scss";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import { LineItems } from "src/lib/types";
import { Asset } from "./Asset";
import { StoriesVariant } from "./StoriesVariant/StoriesVariant";
import { FeedVariant } from "./FeedVariant";
import { CatalogueStoryPreview } from "./StoriesVariant/CatalogueStoriesVariant";
import { MetaAdTypeEnum } from "src/graphql/generated/schema";
import { SmartphoneShell } from "../../modules/global/components/SmartphoneShell";

export interface ISmartphone {
  preview?: LineItems | LineItems[];
  emptyScreenMessage?: string;
  className?: string;
  variant?: "simple" | "feed" | "stories" | "search";
  type?: "facebook" | "instagram";
  primaryText?: string;
  callToActionLabel?: string;
  accountName?: string;
  profilePicture?: string;
  adType?: MetaAdTypeEnum;
  onSlideChange?: (activeSlide: number) => void;
  defaultSlide?: number;
  screenSize?: string;
}

export function Smartphone({
  className,
  emptyScreenMessage = "To see the preview please add details & assets to your ads.",
  variant = "stories",
  preview = null,
  type = "facebook",
  primaryText,
  profilePicture,
  callToActionLabel,
  accountName,
  adType,
  screenSize,
  onSlideChange,
  defaultSlide,
}: ISmartphone) {
  function getVariant(v, preview: LineItems | LineItems[]) {
    switch (v.toLowerCase()) {
      case "stories":
        if (adType === "CATALOG") {
          return (
            <CatalogueStoryPreview
              preview={preview}
              primaryText={primaryText}
              callToActionLabel={callToActionLabel}
              accountName={accountName || "Hello"}
              profilePicture={profilePicture}
            />
          );
        }

        return (
          <StoriesVariant
            preview={preview}
            primaryText={adType === "EXISTING_POST" ? "" : primaryText}
            callToActionLabel={callToActionLabel}
            accountName={accountName || "Hello"}
            profilePicture={profilePicture}
            onSlideChange={onSlideChange}
            defaultActiveSlide={defaultSlide}
          />
        );
      case "feed":
        return (
          <FeedVariant
            preview={preview}
            type={type}
            primaryText={primaryText}
            callToActionLabel={callToActionLabel}
            accountName={accountName || "Hello"}
            profilePicture={profilePicture}
            onSlideChange={onSlideChange}
            defaultActiveSlide={defaultSlide}
          />
        );
      case "search":
        return (
          <CardVariant preview={preview} profilePicture={profilePicture} />
        );
      default:
        return;
    }
  }

  return (
    <SmartphoneShell screenSize={screenSize ?? "23rem"} className={className}>
      {preview ? (
        getVariant(variant, preview)
      ) : (
        <EmptyPreview message={emptyScreenMessage} />
      )}
    </SmartphoneShell>
  );
}

function EmptyPreview({ message }: { message: string }) {
  return (
    <div className={styles.emptyScreen}>
      <Info width={14} height={14} className={styles.infoIco} />
      <p className={styles.emptyText}>{message}</p>
    </div>
  );
}

function CardVariant({ preview, profilePicture }) {
  return (
    <div className={styles.cardVariant}>
      <div className={styles.cardHeader}>
        <div className={styles.feedHeader}>
          <div className={styles.profile}>
            <div className={styles.avatar}>
              <img src={profilePicture} />
            </div>
            <div className={styles.userDetails}>
              <div className={styles.username}></div>
              <div className={styles.time}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardTextLines}>
        <div className={styles.textline}></div>
        <div className={styles.textline}></div>
      </div>
      <div className={styles.cardImage}>
        <Asset
          type={preview?.PostAsset?.contentType}
          preview={preview?.PostAsset?.uri}
          name={preview?.PostAsset?.name}
        />
      </div>
    </div>
  );
}
