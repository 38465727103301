import {
  CustomerCountRange,
  MonthlyAdSpendRange,
  BillingPlanCycleEnum,
  MonthlyCreditRange,
} from "src/graphql/generated/schema";
import {
  premiumAdSpendOptions,
  premiumCustomerCountOptions,
  premiumMonthlyCreditOptions,
  standardAdSpendOptions,
  standardCustomerCountOptions,
  plans,
} from "./planOptions";

export interface getPriceArgs {
  plan: typeof plans[0];
  selectedTab: "ENGAGE" | "GROW";
  selectedCustomerCount: CustomerCountRange;
  selectedAdSpend: MonthlyAdSpendRange;
  selectedBillingCycle: BillingPlanCycleEnum;
  selectedCredits: MonthlyCreditRange;
}

export function getPrice({
  plan,
  selectedTab,
  selectedCustomerCount,
  selectedAdSpend,
  selectedBillingCycle,
  selectedCredits,
}: getPriceArgs) {
  let price: number;
  const adSpendOptions =
    plan.type === "PREMIUM" ? premiumAdSpendOptions : standardAdSpendOptions;

  const customerCountOptions =
    plan.type === "PREMIUM"
      ? premiumCustomerCountOptions
      : standardCustomerCountOptions;

  const monthlyCreditOptions =
    plan.type === "PREMIUM" ? premiumMonthlyCreditOptions : [];

  if (selectedTab === "ENGAGE") {
    const monthlyCreditOption = monthlyCreditOptions.find(
      (a) => a.value === selectedCredits
    );
    const customerCountOption = customerCountOptions.find(
      (a) => a.value === selectedCustomerCount
    );

    price =
      (monthlyCreditOption?.price.amount ?? 0) +
      customerCountOption.price.amount;
  } else {
    const adSpendOption = adSpendOptions.find(
      (a) => a.value === selectedAdSpend
    );

    price = adSpendOption.price.amount;
  }

  if (selectedBillingCycle === "ANNUAL") {
    // we offer 2 months free
    price = Math.round((price * 10) / 12);
  }

  // cause the price is paisas
  return price / 100;
}
