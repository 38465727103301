import styles from "./AudienceListSelectionTable.module.scss";
import { SelectionTable } from "../../global/components/SelectionTable";
import { AudienceIcon } from "src/modules/global/components/AudienceIcon";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";
import {
  AudienceAudienceSetIndexesQuery,
  AudienceTypeEnum,
  MetaAudienceInsightsObject,
} from "src/graphql/generated/schema";
import { AdsetInput, CampaignInput } from "../misc/createCampaignTypes";
import { generateRangeString } from "src/lib/generateRangeString";
import { getFormattedAudienceType } from "src/modules/global/misc/getFormattedAudienceType";
import { useFormikContext } from "formik";
import { defaultAdset } from "../misc/defaultInitialValue";
import Tooltip from "src/components/Tooltip/Tooltip";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface IAudienceListSelectionTable {
  audienceAudienceSetIndexEdges: AudienceAudienceSetIndexesQuery["audienceAudienceSetIndexes"]["edges"];
  adsets: AdsetInput[];
  baseKey: string;
}

export function AudienceListSelectionTable({
  audienceAudienceSetIndexEdges,
  adsets,
  baseKey,
}: IAudienceListSelectionTable) {
  const { setFieldTouched, setFieldValue } = useFormikContext<CampaignInput>();
  const selectedCurrencySymbol = useGetActiveCurrencyCode();

  function buildUniqueIdForAdset(adset: AdsetInput) {
    return adset.templateId;
  }

  function handleSelect(value: boolean, item: { id: string }) {
    const audienceAudienceSetIndex = audienceAudienceSetIndexEdges.find(
      (a) =>
        // compare id
        a.node.id === item.id,
    );

    const type = audienceAudienceSetIndex.node.audienceId
      ? "AUDIENCE"
      : "AUDIENCE_SET";

    const isAudience = type === "AUDIENCE";
    const isAudienceSet = type === "AUDIENCE_SET";

    const audience = audienceAudienceSetIndex.node.Audience;
    const audienceSet = audienceAudienceSetIndex.node.MetaAudienceSet;

    let newValue: AdsetInput[];

    // if deselect
    if (value === false) {
      newValue = adsets.filter(
        (adset) => adset.templateId !== audienceAudienceSetIndex.node.id,
      );
    } else {
      newValue = adsets.concat({
        ...defaultAdset,
        name: isAudience ? audience.name : audienceSet.name,
        templateId: audienceAudienceSetIndex.node.id,
        audienceType: isAudience ? audience.type : null,
        audienceId: isAudience ? audience.id : null,
        description: isAudience ? audience.description : "",
        type: type === "AUDIENCE" ? "INDIVIDUAL" : "SET",
        audienceSetId: isAudienceSet ? audienceSet.id : null,
      });
    }

    setFieldValue(baseKey, newValue);
    setFieldTouched(baseKey);
  }

  function getEstimatedSize(metaAudienceInsight: {
    lowerBoundCount?: number;
    upperBoundCount?: number;
  }) {
    if (!metaAudienceInsight) {
      return "N/A";
    }

    return generateRangeString(
      metaAudienceInsight.lowerBoundCount,
      metaAudienceInsight.upperBoundCount,
    );
  }

  function getAudienceIndexType(audienceIndex: {
    Audience?: {
      type: AudienceTypeEnum;
      MetaInsights?: MetaAudienceInsightsObject;
    };
  }) {
    if (audienceIndex.Audience) {
      return getFormattedAudienceType(audienceIndex.Audience);
    }

    return "Audience Set";
  }

  return (
    <SelectionTable
      gridTemplate="3fr 1fr 1fr repeat(3, 0.75fr)"
      headers={[
        <span>Audience List</span>,
        <span className={styles.headingWithTooltip}>
          Activity Count
          <Tooltip
            supportingText="It shows the count of activities in other campaigns are using this
                audience"
            children={
              <InfoIcon
                width={16}
                height={16}
                fill="var(--color-icon-hovered)"
              />
            }
          />
        </span>,
        <span className={styles.headingWithTooltip}>
          Est. Size{" "}
          <Tooltip
            supportingText="It shows the estimate size of audience may have, these information
                are totally based on facebook data center"
            children={
              <InfoIcon
                width={16}
                height={16}
                fill="var(--color-icon-hovered)"
              />
            }
          />
        </span>,
        "ROAS",
        "CTR (%)",
        `CPC (${selectedCurrencySymbol})`,
      ]}
      items={audienceAudienceSetIndexEdges.map((audienceIndex) => {
        const audienceOrAudienceSet =
          audienceIndex.node.Audience || audienceIndex.node.MetaAudienceSet;

        return {
          id: audienceIndex.node.id,
          description: getAudienceIndexType(audienceIndex.node),
          icon: (
            <span className={styles.icon}>
              <AudienceIcon
                width={16}
                height={16}
                audience={{
                  type: audienceIndex.node.Audience?.type,
                  __typename: audienceIndex.node.Audience
                    ? "Audience"
                    : "MetaAudienceSet",
                }}
              />
            </span>
          ),
          title: audienceIndex.node.name,
          otherRows: [
            audienceOrAudienceSet?.activitiesCount ?? "0",
            getEstimatedSize(audienceOrAudienceSet?.MetaInsights),
            "-",
            "-",
            "-",
          ],
        };
      })}
      values={adsets.map((a) => buildUniqueIdForAdset(a))}
      onChange={handleSelect}
    />
  );
}
