import { useFormikContext } from "formik";
import shopifyPixel from "../assets/shopifyPixel.svg";
import { FormData } from "../misc/shopifyIntegrationTypes";
import { ShopifySettingsCard } from "./ShopifySettingsCard";

export function ShopifyPixelSettings() {
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<FormData>();

  const options = [
    {
      value: "captureUTM",
      title: "Capture UTM tags data",
      required: false,
      checked: values.shopifyPixel.captureUTM && values.shopifyPixel.enabled,
      onChange(newValue: boolean) {
        setFieldTouched("shopifyPixel.captureUTM");
        setFieldValue("shopifyPixel.captureUTM", newValue);
      },
      helpText: "",
    },
    {
      value: "anonymousProfile",
      title:
        "Use IP based profiles instead of cookie-based for GDPR compliance",
      required: false,
      checked:
        values.shopifyPixel.anonymousProfile && values.shopifyPixel.enabled,
      onChange(newValue: boolean) {
        setFieldTouched("shopifyPixel.anonymousProfile");
        setFieldValue("shopifyPixel.anonymousProfile", newValue);
      },
      helpText: "",
    },
  ];

  return (
    <ShopifySettingsCard
      imageSrc={shopifyPixel}
      title="Shopify Pixel"
      subtitle="Allow permissions to add a pixel on your shopify store"
      options={options}
    />
  );
}
