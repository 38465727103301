import { AutomationTemplate } from "src/graphql/generated/schema";
import { Template } from "./TemplateSelector";
import classNames from "classnames";
import styles from "./Template.module.scss";

interface Props {
  item: Template;
  selectedTemplate: Template;
  setSelectedTemplate: (i: Template) => void;
}

export function TemplateItem({
  item,
  selectedTemplate,
  setSelectedTemplate,
}: Props) {
  return (
    <button
      className={classNames(styles.item, {
        [styles.selected]: selectedTemplate.id === item.id,
      })}
      onClick={() => {
        setSelectedTemplate(item);
      }}
    >
      <div className={styles.iconContainer}>
        <div className={styles.iconWrapper}>
          <img src={item.icon} alt="icon" className={styles.icon} />
        </div>
      </div>
      <div className={styles.text}>
        <p className={styles.title}>{item.title}</p>
        <p className={styles.description}>{item.subtitle}</p>
        <div>
          <p className={styles.useTemplate}>Use Template</p>
        </div>
      </div>
    </button>
  );
}
