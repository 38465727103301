import { ReactComponent as Encrypted } from "@material-design-icons/svg/outlined/shield.svg";
import { ReactComponent as CreditCard } from "@material-design-icons/svg/outlined/credit_card.svg";
import styles from "./BillingDetails.module.scss";
import classNames from "classnames";
import { GetBillingQuery } from "src/graphql/generated/schema";
import { titleCase } from "src/lib/titleCase";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";

export function BillingDetails({
  billing,
}: {
  billing: GetBillingQuery["billing"];
}) {
  // const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const { activeClient } = useContext(AuthContext);
  let selectedCurrencySymbol = useGetActiveCurrencyCode();

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.sectionIntro}>
          <h1 className={styles.sectionTitle}>Purchase Summary</h1>
          <div className={styles.billedIn}>
            <span className={styles.billedInText}>Billed in</span>
            <div className={styles.currencyWrapper}>
              {activeClient.currencyCode}
            </div>
          </div>
        </div>
        {billing?.BillingEngagePlan && (
          <>
            <div className={classNames(styles.flexTile)}>
              <div className={styles.flexCol}>
                <span className={styles.productType}>
                  Engage {titleCase(billing.BillingEngagePlan.type)}
                </span>
              </div>
              <div className={styles.flexCol}>
                <span className={styles.monthlyText}>
                  {titleCase(billing.cycle)} Plan
                </span>

                {/* <span className={styles.amount}>{}</span>
                <span className={styles.monthlyText}>Billed Monthly</span> */}
              </div>
            </div>
            <ul className={styles.billDetails}>
              <li className={styles.flexTile}>
                <span>Contacts Limit</span>
                <span>{billing.BillingEngagePlan.customerCount}</span>
              </li>
              <li className={styles.flexTile}>
                <span>Total Email Sent</span>
                <span>{billing.BillingEngagePlan.emailsCount}</span>
              </li>
              <li className={styles.flexTile}>
                <span>Total SMS or Whatsapp Credits</span>
                <span>{billing.BillingEngagePlan.monthlyCredits}</span>
              </li>
              {/* <li className={styles.flexTile}>
                <span>Team Members Allowed</span>
                <span>
                  {billing.BillingEngagePlan.type === "STANDARD" ? 5 : 10}
                </span>
              </li> */}
            </ul>
          </>
        )}

        {/* show this divider when both plan are purchased at the same time */}
        {billing?.BillingGrowPlan && billing?.BillingEngagePlan && (
          <hr className={styles.separator} />
        )}

        {billing?.BillingGrowPlan && (
          <>
            <div className={classNames(styles.flexTile)}>
              <div className={styles.flexCol}>
                <span className={styles.productType}>
                  Grow {titleCase(billing.BillingGrowPlan.type)}
                </span>
              </div>
              <div className={styles.flexCol}>
                <span className={styles.monthlyText}>
                  {titleCase(billing.cycle)} Plan
                </span>
                {/* <span className={styles.amount}>{}</span>
                <span className={styles.monthlyText}>Billed Monthly</span> */}
              </div>
            </div>
            <ul className={styles.billDetails}>
              <li className={styles.flexTile}>
                <span>Monthly Ad Spent</span>
                <span>{billing.BillingGrowPlan.monthlyAdSpend}</span>
              </li>
              {/* <li className={styles.flexTile}>
                <span>Team Members Allowed</span>
                <span>
                  {billing.BillingEngagePlan.type === "STANDARD" ? 5 : 10}
                </span>
              </li> */}
            </ul>
          </>
        )}
        <div className={styles.subtotalRow}>
          <span className={styles.subtotal}>Subtotal</span>{" "}
          <span className={styles.subtotal}>
            {selectedCurrencySymbol}
            {billing?.subtotalAmount / 100}
          </span>
        </div>
        <div className={styles.taxRow}>
          <span className={styles.tax}>TAX (GST)</span>{" "}
          <span className={styles.tax}>
            {selectedCurrencySymbol}
            {billing?.taxAmount / 100}
          </span>
        </div>
        <div className={styles.totalRow}>
          <span className={styles.total}>Total</span>
          <span className={styles.total}>
            {selectedCurrencySymbol}
            {billing?.totalAmount / 100}
          </span>
        </div>
      </div>
      <div className={styles.features}>
        <div className={styles.featContainer}>
          <span className={styles.icoWrapper}>
            <Encrypted className={styles.ico} />
          </span>
          <p>
            You will only be charged according to your selected plan limit. We
            won't allow you to exceed your limit which will leads to you
            additional bill amount unless you switch to higher plan.
          </p>
        </div>
        <div className={styles.featContainer}>
          <span className={styles.icoWrapper}>
            <CreditCard className={styles.ico} />
          </span>
          <p>
            I understand that I am enrolling in a subscription plan and I
            authorize Macro to charge my payment method each month at the
            then-current list price {"(plus applicable taxes)"} unless I cancel.
            I can cancel anytime by going to my Billing page in the Account
            Settings.
          </p>
        </div>
      </div>
    </div>
  );
}
