import { useMemo, useState } from "react";
import { Button } from "../../../components/Button/Button";
import { ErrorHandler } from "../../../components/ErrorHandler";
import { Spinner } from "../../../components/Spinner/Spinner";
import {
  SortByInput,
  useGetUserQuery,
  useOrganizationInvitesQuery,
  useOrganizationUsersQuery,
} from "../../../graphql/generated/schema";
import { SectionHeading } from "../../global/components/SectionHeading";
import { useDebouncedVariable } from "../../global/hooks/useDebouncedVariable";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "../../global/misc/dateFilterUtils";
import { AddMembersModal } from "../components/AddMembersModal";
import { ManageMemberModal } from "../components/ManageMemberModal";
import { OrganizationInvitesTable } from "../components/OrganizationInvitesTable";
import { OrganizationMembersTable } from "../components/OrganizationMembersTable";
import { OrganizationMembersTableHeader } from "../components/OrganizationMembersTableHeader";
import styles from "./Members.module.scss";

export function Members() {
  const [filters, setFilters] = useState<{
    name?: string;
    createdAt?: DateFilter;
  }>({
    name: "",
    createdAt: "ALL_TIME",
  });
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [tab, setTab] = useState<"MEMBERS" | "INVITES">("MEMBERS");

  const name = useDebouncedVariable(filters.name, 300);
  const [sortBy, setSortBy] = useState<SortByInput>({
    field: "CREATED_AT",
    direction: "DESC",
  });

  const createdAtFilter = useMemo(() => {
    return getDateFilterFromFilterEnum(filters.createdAt);
  }, [filters.createdAt]);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useGetUserQuery();

  const {
    data: organizationInvitesData,
    loading: organizationInvitesLoading,
    error: organizationInvitesError,
  } = useOrganizationInvitesQuery({
    variables: {
      filter: {
        ...(name && {
          name: name,
        }),
        ...(createdAtFilter && {
          createdAt: createdAtFilter,
        }),
      },
      sortBy: sortBy,
    },
  });

  const {
    data: organizationUsersData,
    loading: organizationUsersLoading,
    error: organizationUsersError,
  } = useOrganizationUsersQuery({
    variables: {
      filter: {
        ...(name && {
          name: name,
        }),
        ...(createdAtFilter && {
          createdAt: createdAtFilter,
        }),
      },
      sortBy: sortBy,
    },
  });

  const isCurrentUserAdmin =
    userData && userData.me ? userData.me.role === "ADMIN" : false;

  if (userError) {
    return <ErrorHandler error={userError} />;
  }

  if (userLoading) {
    return <Spinner />;
  }

  const creatorId = userData?.me?.Organization?.Creator?.id;

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <SectionHeading
          heading={"Members"}
          description={"Manage teams, roles and permissions and settings"}
        />
        <Button
          size="micro"
          onClick={() => {
            setShowAddMembersModal(true);
          }}
          disabled={!isCurrentUserAdmin}
          title={
            isCurrentUserAdmin
              ? "Invite members"
              : "Members cannot invite other members"
          }
        >
          Invite Member
        </Button>
      </div>
      <div className={styles.wrapper}>
        <OrganizationMembersTableHeader
          sortBy={sortBy}
          setSortBy={setSortBy}
          filter={filters}
          setFilter={setFilters}
          selectedTab={tab}
          setSelectedTab={setTab}
        />
        {tab === "MEMBERS" ? (
          <OrganizationMembersTable
            creatorId={creatorId}
            triggerManageMemberModal={setSelectedId}
            error={organizationUsersError}
            loading={organizationUsersLoading}
            isCurrentUserAdmin={isCurrentUserAdmin}
            organizationUsers={
              organizationUsersData && organizationUsersData.organizationUsers
                ? organizationUsersData.organizationUsers.edges.map(
                    (v) => v.node
                  )
                : []
            }
          />
        ) : (
          <OrganizationInvitesTable
            error={organizationInvitesError}
            loading={organizationInvitesLoading}
            isCurrentUserAdmin={isCurrentUserAdmin}
            organizationInvites={
              organizationInvitesData &&
              organizationInvitesData.organizationInvites
                ? organizationInvitesData.organizationInvites.edges.map(
                    (v) => v.node
                  )
                : []
            }
          />
        )}
      </div>
      {Boolean(selectedId) && (
        <ManageMemberModal
          selectedUserId={selectedId}
          isOpen={Boolean(selectedId)}
          onClose={() => {
            setSelectedId(null);
          }}
        />
      )}
      {/*
      {Boolean(selectedMemberForRoleChange) && (
        <ChangeMemberRoleModal
          isOpen={Boolean(selectedMemberForRoleChange)}
          onClose={() => setSelectedMemberForRoleChange(null)}
          memberId={selectedMemberForRoleChange}
        />
      )}
      {Boolean(selectedMemberForClientAdd) && (
        <AddMemberToClientModal
          isOpen={Boolean(selectedMemberForClientAdd)}
          onClose={() => {
            setSelectedMemberForClientAdd(null);
          }}
        />
      )}
      */}
      <AddMembersModal
        isOpen={showAddMembersModal}
        onClose={() => setShowAddMembersModal(false)}
      />
    </div>
  );
}
