import { MutableRefObject, useContext } from "react";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import { AudienceSelector } from "./AudienceSelector";
import { SegmentSelector } from "./SegmentSelector";

export function AudienceOrSegmentSelector({
  sectionRef,
}: {
  sectionRef: MutableRefObject<HTMLDivElement>;
}) {
  const { data } = useContext(StepWizardContext);

  if (data.platform === "WHATSAPP") {
    return <SegmentSelector sectionRef={sectionRef} />;
  }

  return <AudienceSelector sectionRef={sectionRef} />;
}
