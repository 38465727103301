import audiencePresets from "../misc/audiencePresets";
import { AudiencePresetFilters } from "../misc/audienceWorkshop";
import { AudiencePresetSelector } from "./AudiencePresetSelector";

interface IAudiencePresetSelectorLoader {
  filters: AudiencePresetFilters;
  searchValue: string;
  onShowSelection: () => void;
}

export function AudiencePresetSelectorLoader({
  filters,
  searchValue,
  onShowSelection,
}: IAudiencePresetSelectorLoader) {
  const filteredAudiencePresets = audiencePresets.filter((audiencePreset) => {
    const name = audiencePreset.name.toLowerCase();
    // const description = audiencePreset.description.toLowerCase();
    const query = searchValue.toLowerCase();

    // if the preset doesn't match query
    if (!name.includes(query)) {
      return false;
    }

    // if the preset doesn't selected types
    if (!filters.funnelSteps.includes(audiencePreset.group.value)) {
      return false;
    }

    if (filters.source !== "ALL") {
      if (filters.source !== audiencePreset.filteringSource) {
        return false;
      }
    }

    return true;
  });

  return (
    <AudiencePresetSelector
      onShowSelection={onShowSelection}
      shouldShowSelection={true}
      audiencePresets={filteredAudiencePresets}
    />
  );
}
