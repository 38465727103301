export function FacebookShareIcon({
  ...options
}: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="100"
      height="100"
      fill="none"
      {...options}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M49.2717 59.6316C36.8225 55.4147 26.7406 65.47 21.826 73.1839C21.3322 73.959 20 73.6162 20 72.6972V67.3427C20 51.1771 33.1048 38.0723 49.2705 38.0723C49.4738 38.0723 49.6386 37.9075 49.6386 37.7042V22.6337C49.6386 21.6618 50.8136 21.1751 51.5008 21.8623L77.9554 48.3168C79.0846 49.446 79.0846 51.2769 77.9554 52.4061L51.5009 78.8606C50.8136 79.5478 49.6386 79.0651 49.6386 78.0932V60.1475C49.6386 59.9175 49.4895 59.7054 49.2717 59.6316Z" />
    </svg>
  );
}
