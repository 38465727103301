import { Menu, Transition } from "@headlessui/react";
import styles from "./SortByDropdown.module.scss";
import { ReactComponent as SwapVert } from "@material-symbols/svg-600/rounded/swap_vert.svg";
import { Fragment } from "react";
import classNames from "classnames";
import { Radio } from "../../../components/Radio/Radio";

export interface sortBy<T = any> {
  direction: "DESC" | "ASC";
  field: T;
}

interface ISortByDropdown<T = any> {
  sortOptions: {
    type: string;
    value: string;
    options: {
      label: string;
      value: string;
      icon: any;
    }[];
  }[];
  sortBy: sortBy<T>;
  setSortBy: (i: sortBy<T>) => void;
}

export default function SortByDropdown<T = any>({
  sortOptions,
  sortBy,
  setSortBy,
}: ISortByDropdown<T>) {
  return (
    <Menu as="div" className={styles.sortMenu}>
      <Menu.Button
        className={classNames(styles.triggerButton, styles.dropdownTitle)}
      >
        <SwapVert className={styles.todayIco} />
        <div className={styles.sortByTitle}>Sort By</div>
      </Menu.Button>

      <Menu.Items className={styles.menuItems}>
        <div className={styles.menuSub}>
          {sortOptions.map((item, idx) => (
            <Menu.Item key={idx}>
              <label
                className={classNames(styles.menuItem, {
                  [styles.lightMenuLabel]: item.value === sortBy.field,
                })}
              >
                <Radio
                  value={item.type}
                  name="sort"
                  onChange={() =>
                    setSortBy({
                      field: item.value as any,
                      direction:
                        "options" in item
                          ? (item.options[0].value as "ASC" | "DESC")
                          : null,
                    })
                  }
                  checked={sortBy.field === item.value}
                />
                {item.type}
              </label>
            </Menu.Item>
          ))}
        </div>
        <div className={styles.options}>
          {sortOptions
            .find((item) => item.value === sortBy.field)
            .options.map((item, idx) => (
              <button
                type="button"
                className={classNames(styles.optionItem, {
                  [styles.lightBtn]: item.value === sortBy.direction,
                })}
                key={idx}
                onClick={() =>
                  setSortBy({
                    ...sortBy,
                    direction: item.value as any,
                  })
                }
              >
                <span
                  className={classNames(styles.optionItemIcon, {
                    [styles.lightIcon]: item.value === sortBy.direction,
                  })}
                >
                  {item.icon}
                </span>
                <span
                  className={classNames(styles.optionItemLabel, {
                    [styles.lightMenuLabel]: item.value === sortBy.direction,
                  })}
                >
                  {item.label}
                </span>
              </button>
            ))}
        </div>
      </Menu.Items>
    </Menu>
  );
}
