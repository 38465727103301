import { PageHeader } from "src/components/PageHeader/PageHeader";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { ReactComponent as Export } from "@material-design-icons/svg/outlined/vertical_align_top.svg";
import { ReactComponent as Help } from "@material-symbols/svg-400/rounded/help.svg";
import { Button } from "src/components/Button/Button";
import styles from "./CustomerTagSinglePage.module.scss";
import Table from "src/components/Table/Table";
import { useState } from "react";
import CountryFlag from "src/components/CountryFlag/CountryFlag";
import PlatformIcons from "src/modules/global/components/PlatformIcons";

const HEADINGS = [
  "Customers Name",
  "Email",
  <span className={styles.ltv}>
    LTV
    <Help width={14} height={14} />
  </span>,
  "Ph.number",
  "City",
  "Country",
  "Subscribed to",
];

const ROWS = [
  [
    "Neelkant Tiwari",
    "lushe@comcast.net",
    "$1,00,000",
    "+1 505-658-2849",
    "New York",
    <CountryFlag countryCode="CN" showCountryCode={true} rounded={false} />,
    <PlatformIcons platforms={[{ platform: "WHATSAPP", disabled: false }]} />,
  ],
  [
    "Raghav Joshi",
    "lushe@comcast.net",
    "$1,00,000",
    "+1 505-658-2849",
    "New York",
    <CountryFlag countryCode="UA" showCountryCode={true} rounded={false} />,
    <PlatformIcons platforms={[{ platform: "EMAIL", disabled: false }]} />,
  ],
  [
    "Shreya Aggarwal",
    "dinther@yahoo.net",
    "$1,00,000",
    "+1 505-658-2849",
    "New York",
    "-",
    <PlatformIcons
      platforms={[
        { platform: "EMAIL", disabled: false },
        { platform: "TEXT_MESSAGE", disabled: false },
      ]}
    />,
  ],
  [
    "Neelkant Tiwari",
    "lushe@comcast.net",
    "$1,00,000",
    "",
    "",
    <CountryFlag countryCode="IN" showCountryCode={true} rounded={false} />,
    <PlatformIcons
      platforms={[
        { platform: "EMAIL", disabled: false },
        { platform: "TEXT_MESSAGE", disabled: false },
        { platform: "WHATSAPP", disabled: false },
      ]}
    />,
  ],
  [
    "Neelkant Tiwari",
    "lushe@comcast.net",
    "$1,00,000",
    "+1 505-658-2849",
    "New York",
    <CountryFlag countryCode="US" showCountryCode={true} rounded={false} />,
    <PlatformIcons platforms={[{ platform: "WHATSAPP", disabled: false }]} />,
  ],
  [
    "Neelkant Tiwari",
    "lushe@comcast.net",
    "$1,00,000",
    "+1 505-658-2849",
    "New York",
    <CountryFlag countryCode="IN" showCountryCode={true} rounded={false} />,
    <PlatformIcons platforms={[{ platform: "EMAIL", disabled: false }]} />,
  ],
  [
    "Neelkant Tiwari",
    "lushe@comcast.net",
    "$1,00,000",
    "",
    "",
    <CountryFlag countryCode="CN" showCountryCode={true} rounded={false} />,
    <PlatformIcons
      platforms={[
        { platform: "EMAIL", disabled: false },
        { platform: "TEXT_MESSAGE", disabled: false },
        { platform: "WHATSAPP", disabled: false },
      ]}
    />,
  ],
];

export function CustomerTagSinglePage() {
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div>
      <PageHeader
        title={
          <div className={styles.title}>
            <ArrowBack />
            <h1>Tag: User</h1>
          </div>
        }
        actions={
          <div className={styles.actionGroup}>
            <Button
              color="subdued"
              style="plain"
              className={styles.exportBtn}
              onClick={() => {}}
            >
              <Export width={20} height={20} />
              <div>Export</div>
            </Button>
            <Button color="primary" onClick={() => {}}>
              Create Outreach
            </Button>
          </div>
        }
      />
      <div className={styles.table}>
        <Table
          headings={HEADINGS}
          rows={ROWS}
          selectable={true}
          selectedItems={selectedRows}
          setSelectedItems={setSelectedRows}
        />
      </div>
    </div>
  );
}
