import { Button } from "src/components/Button/Button";
import styles from "./PasswordResetLinkExpired.module.scss";
// import linkBrokenImage from "../assets/linkBroken.png";
import MacroLogo from "../assets/macro-logo-primary-full.svg";
import passwordLinkExpired from "../assets/passwordLinkExpired.png";

export function PasswordResetLinkExpired() {
  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <nav>
          <img src={MacroLogo} alt="macro_logo" className={styles.logo} />
        </nav>
      </header>
      <div className={styles.content}>
        <div className={styles.container}>
          <img
            src={passwordLinkExpired}
            alt="linkBroken.png"
            className={styles.image}
          />
          <div className={styles.heading}>Password Reset Link Expired</div>
          <p className={styles.description}>
            Oops! Your password reset link has expired. Please request a new one
            to regain access to your account.
          </p>
          <Button to="/auth/login">Back to login</Button>
        </div>
      </div>
    </div>
  );
}
