import { Listbox } from "@headlessui/react";
import styles from "./BadgeDropdown.module.scss";
import classNames from "classnames";
import { ReactComponent as Down } from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { CampaignStatusEnum } from "src/graphql/generated/schema";
import { campaignBadgeDropdownOptions } from "src/modules/campaign/misc/campaignBadgeDropdownOptions";

export interface IBadgeDropdown {
  type: CampaignStatusEnum;
  options?: {
    status: string;
    label: string;
    editable: boolean;
    dropdownOptions?: {
      label: string;
      value: string;
    }[];
    icon: React.FC<React.SVGAttributes<any>>;
  }[];
  onChange: (i: string) => void;
  loading?: boolean;
}

export default function BadgeDropdown({
  type,
  onChange,
  options = campaignBadgeDropdownOptions,
  loading,
}: IBadgeDropdown) {
  const selectedOption = options.find((o) => o.status === type);
  const SelectedOptionIcon: React.FC<React.SVGAttributes<any>> =
    selectedOption?.icon;
  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={classNames(styles.trigger, styles.draftColor)}>
          <span>Loading</span>
        </div>
      </div>
    );
  }

  if (!selectedOption.dropdownOptions) {
    return (
      <div
        className={classNames(styles.trigger, {
          [styles.triggerDisabled]: selectedOption.editable === true,
          [styles.errorColor]: type.includes("ERROR"),
          [styles.activeColor]: type.includes("ACTIVE"),
          [styles.scheduledColor]: type.includes("SCHEDULED"),
          [styles.inactiveColor]: type.includes("PAUSED"),
          [styles.canceledColor]: type.includes("ARCHIVED"),
          [styles.sentColor]: type.includes("SENT"),
          [styles.draftColor]: type.includes("DRAFT"),
        })}
      >
        {selectedOption.icon && (
          <SelectedOptionIcon
            className={classNames(styles.ico, styles.downIco, {
              [styles.errorIco]: type.includes("ERROR"),
              [styles.activeIco]: type.includes("ACTIVE"),
              [styles.scheduledIco]: type.includes("SCHEDULED"),
              [styles.inactiveIco]: type.includes("PAUSED"),
              [styles.canceledIco]: type.includes("ARCHIVED"),
              [styles.sentIco]: type.includes("SENT"),
              [styles.draftIco]: type.includes("DRAFT"),
            })}
          />
        )}
        <span>{selectedOption.label}</span>
        {selectedOption.editable && (
          <Down
            className={classNames(styles.ico, styles.downIco, {
              [styles.errorIco]: type.includes("ERROR"),
              [styles.activeIco]: type.includes("ACTIVE"),
              [styles.scheduledIco]: type.includes("SCHEDULED"),
              [styles.inactiveIco]: type.includes("PAUSED"),
              [styles.canceledIco]: type.includes("ARCHIVED"),
              [styles.sentIco]: type.includes("SENT"),
              [styles.draftIco]: type.includes("DRAFT"),
            })}
          />
        )}
      </div>
    );
  }

  return (
    <Listbox
      className={styles.selectableBadge}
      as="div"
      value={type}
      onChange={onChange}
      disabled={!selectedOption.editable}
    >
      <Listbox.Button
        className={classNames(styles.trigger, {
          [styles.triggerDisabled]: selectedOption.editable === true,
          [styles.errorColor]: type.includes("ERROR"),
          [styles.activeColor]: type.includes("ACTIVE"),
          [styles.scheduledColor]: type.includes("SCHEDULED"),
          [styles.inactiveColor]: type.includes("PAUSED"),
          [styles.canceledColor]: type.includes("ARCHIVED"),
          [styles.sentColor]: type.includes("SENT"),
          [styles.draftColor]: type.includes("DRAFT"),
        })}
      >
        {selectedOption.icon && (
          <SelectedOptionIcon
            className={classNames(styles.ico, styles.downIco, {
              [styles.errorIco]: type.includes("ERROR"),
              [styles.activeIco]: type.includes("ACTIVE"),
              [styles.scheduledIco]: type.includes("SCHEDULED"),
              [styles.inactiveIco]: type.includes("PAUSED"),
              [styles.canceledIco]: type.includes("ARCHIVED"),
              [styles.sentIco]: type.includes("SENT"),
              [styles.draftIco]: type.includes("DRAFT"),
            })}
          />
        )}
        <span>{selectedOption.label}</span>
        {selectedOption.editable && (
          <Down
            className={classNames(styles.ico, styles.downIco, {
              [styles.errorIco]: type.includes("ERROR"),
              [styles.activeIco]: type.includes("ACTIVE"),
              [styles.scheduledIco]: type.includes("SCHEDULED"),
              [styles.inactiveIco]: type.includes("PAUSED"),
              [styles.canceledIco]: type.includes("ARCHIVED"),
              [styles.sentIco]: type.includes("SENT"),
              [styles.draftIco]: type.includes("DRAFT"),
            })}
          />
        )}
      </Listbox.Button>
      <Listbox.Options className={styles.popup}>
        {selectedOption?.dropdownOptions?.map((option, index) => (
          <Listbox.Option
            className={classNames(styles.option, {
              [styles.selectedOption]: option.label === selectedOption.label,
            })}
            value={option.value}
            key={index}
          >
            {option.label}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}
