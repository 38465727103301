import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  AudienceAudienceSetIndexByIdsQuery,
  useAudienceAudienceSetIndexByIdsQuery,
} from "src/graphql/generated/schema";
import styles from "./AudienceBudgetLoader.module.scss";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { getAudienceAudienceSetSourcePlatform } from "src/modules/audience/misc/getAudienceAudienceSetSourcePlatforms";
import { formatNumber } from "src/lib/formatNumber";
import { AUDIENCE_PLATFORMS } from "src/modules/audience/misc/audienceListingTypes";
import { ReactComponent as MetaIcon } from "../assets/facebookAudience.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagramAudienceSource.svg";
import { ReactComponent as WebsiteIcon } from "../assets/websiteAudienceSource.svg";
import { SVGProps } from "react";
import { AdsetInputV2 } from "../misc/createCampaignTypes";
import { retargetingAudiences } from "../misc/retargetingAudiences";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { useGetActiveCurrencyCode } from "src/modules/global/functions/useGetActiveCurrencyCode";

interface IAudienceBudgetLoader {
  adsets: AdsetInputV2[];
}

export function AudienceBudgetLoader({ adsets }: IAudienceBudgetLoader) {
  const audienceAudienceSetIndexIds = adsets
    .filter((a) => a.type !== "PRODUCT_RETARGET")
    .map((a) => a.templateId);

  const retargetingAdsets = adsets.filter((a) => a.type === "PRODUCT_RETARGET");

  const { data, loading, error } = useAudienceAudienceSetIndexByIdsQuery({
    variables: {
      ids: audienceAudienceSetIndexIds,
    },
  });

  if (loading) {
    return (
      <div className={styles.loader}>
        <Spinner height={100} />
      </div>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <>
      {data.audienceAudienceSetIndexByIds.map(
        (audienceAudienceSetIndex, index) => (
          <AudienceBudgetCard
            index={adsets.findIndex(
              (a) => a.templateId === audienceAudienceSetIndex.id,
            )}
            audience={audienceAudienceSetIndex}
          />
        ),
      )}
      {retargetingAdsets.map((retargetAudience) => (
        <RetargetAudienceBudgetCard
          index={adsets.findIndex(
            (a) => a.templateId === retargetAudience.templateId,
          )}
          retargetAudience={retargetAudience}
        />
      ))}
    </>
  );
}

function RetargetAudienceBudgetCard({
  retargetAudience,
  index,
}: {
  retargetAudience: AdsetInputV2;
  index: number;
}) {
  const sourceRetargetingAudience = retargetingAudiences.find(
    (a) => a.id === retargetAudience.templateId,
  );

  return (
    <div className={styles.audienceCard}>
      <span className={styles.audiencePlatform}>
        <span
          style={{
            backgroundColor: sourceRetargetingAudience.iconBackgroundColor,
          }}
          className={styles.icon}
        >
          {sourceRetargetingAudience.icon}
        </span>
      </span>
      <span className={styles.titleContainer}>
        <span className={styles.audienceTitle}>
          {sourceRetargetingAudience.title}
        </span>
      </span>
      <InputFormik name={`Adsets[${index}].budget`} width="full" />
    </div>
  );
}

interface IAudienceBudgetCard {
  audience: AudienceAudienceSetIndexByIdsQuery["audienceAudienceSetIndexByIds"][0];
  index: number;
}

function AudienceBudgetCard({ audience, index }: IAudienceBudgetCard) {
  const selectedCurrencySymbol = useGetActiveCurrencyCode();

  const audiencePlatforms = getAudienceAudienceSetSourcePlatform(audience);
  const item = audience.audienceId
    ? audience.Audience
    : audience.MetaAudienceSet;

  return (
    <div className={styles.audienceCard}>
      <span className={styles.audiencePlatform}>
        {audiencePlatforms?.map((platform) => (
          <PlatformIcons className={styles.platformIcon} platform={platform} />
        ))}
      </span>
      <span className={styles.titleContainer}>
        <span className={styles.audienceTitle}>{audience.name}</span>

        {item?.MetaInsights?.lowerBoundCount && (
          <span className={styles.audienceSize}>
            Est. size: {formatNumber(item.MetaInsights.lowerBoundCount)}-
            {formatNumber(item.MetaInsights.upperBoundCount)}
          </span>
        )}
      </span>
      <InputFormikV2
        prefix={selectedCurrencySymbol}
        name={`Adsets[${index}].budget`}
      />
    </div>
  );
}

interface IPlatformIcons extends SVGProps<any> {
  platform: AUDIENCE_PLATFORMS;
}

function PlatformIcons({ platform, ...props }: IPlatformIcons) {
  switch (platform) {
    case "META":
      return <MetaIcon {...props} />;
    case "INSTAGRAM":
      return <InstagramIcon {...props} />;
    default:
      return <WebsiteIcon {...props} />;
  }
}
