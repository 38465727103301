import { Formik } from "formik";
import { useContext } from "react";
import { Form } from "react-router-dom";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import styles from "./ActivityMoreOptions.module.scss";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import { ActivityCampaignSelector } from "./ActivityCampaignSelector";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { Button } from "src/components/Button/Button";
import * as Yup from "yup";
import { FormikError } from "src/components/FormikError/FormikError";

const validationSchema = Yup.object({
  campaignId: Yup.string().nullable(),
  campaignName: Yup.string().nullable(),
  shouldCreateCampaign: Yup.boolean(),
  activityDescription: Yup.string().nullable(),
});

export function ActivityMoreOptions({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } =
    useContext(StepWizardContext);

  return (
    <Formik
      initialValues={data as ActivityInputV2}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values) => {
        await markStepAsDone(5, values);
      }}
    >
      {({
        values,
        setFieldValue,
        isSubmitting,
        handleSubmit,
        errors,
        submitCount,
        touched,
      }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 4}
            isCompleted={activeStepIndex > 4}
            isLastStep={true}
          >
            <section ref={sectionRef} className={styles.section}>
              <div className={styles.header}>
                <div>
                  <h2 className={styles.heading}>Publishing options</h2>
                  <h3 className={styles.subheading}>
                    What you want to do with this activity?
                  </h3>
                </div>
              </div>

              <div className={styles.fields}>
                <ActivityCampaignSelector
                  onCreateNew={(value) => {
                    setFieldValue("campaignName", value);
                    setFieldValue("shouldCreateCampaign", true);
                    // reset campaign id
                    setFieldValue("campaignId", null);
                  }}
                  onChange={(value) => {
                    setFieldValue("campaignId", value);
                    // reset campaign name and shouldCreateCampaign field
                    setFieldValue("campaignName", null);
                    setFieldValue("shouldCreateCampaign", false);
                  }}
                  campaignName={values.campaignName}
                  value={values.campaignId}
                />
                <FormikError
                  fieldName="campaignId"
                  formikError={errors}
                  submitCount={submitCount}
                  touched={touched}
                />

                {/* <ActivityTagsSelector /> */}

                <MultilineInput
                  label="About this activity"
                  placeholder="Write a helpful description about this activity"
                  value={values.activityDescription}
                  onChange={(value) => {
                    setFieldValue("activityDescription", value);
                  }}
                />
              </div>
            </section>
          </StepWizardStep>
          <div className={styles.buttonGroup}>
            <Button loading={isSubmitting} onClick={handleSubmit} size="micro">
              Publish Activity
            </Button>
            {/* <Button style="outline" color="subdued" size="micro">
              Schedule Activity
            </Button> */}
          </div>
        </Form>
      )}
    </Formik>
  );
}
