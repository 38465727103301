import { MutableRefObject, useContext } from "react";
import { StepWizardContext } from "src/modules/global/misc/StepWizardContext";
import { AdSelector } from "./AdSelector";
import { WhatsappTemplateSelector } from "./WhatsappTemplateSelector";

export function CreativeSelector({
  sectionRef,
}: {
  sectionRef: MutableRefObject<HTMLDivElement>;
}) {
  const { data } = useContext(StepWizardContext);

  if (data.platform === "WHATSAPP") {
    return <WhatsappTemplateSelector sectionRef={sectionRef} />;
  }

  return <AdSelector sectionRef={sectionRef} />;
}
