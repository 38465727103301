import {
  IAudiencePreset,
  IAudienceWizardPreset,
} from "../misc/createPreviewBasedAudienceTypes";
import instagramPresetIcon from "../assets/instagramPresetIcon.svg";
import videoPresetIcon from "../assets/videoPresetIcon.svg";
import facebookPresetIcon from "../assets/facebookPresetIcon.svg";
import adPresetIcon from "../assets/adPresetIcon.svg";
import classNames from "classnames";
import facebookIcon from "../assets/facebookIcon.svg";
import styles from "./AudiencePresetCard.module.scss";
import { titleCase } from "src/lib/titleCase";
import Badge from "src/components/Badge/Badge";

interface IAudiencePresetCard {
  isActive?: boolean;
  audiencePreset: IAudienceWizardPreset;
}

const presetSourceIcons = {
  INSTAGRAM: instagramPresetIcon,
  VIDEO: videoPresetIcon,
  AD: adPresetIcon,
  FACEBOOK: facebookPresetIcon,
  WEBSITE: adPresetIcon,
};

export function AudiencePresetCard({
  isActive,
  audiencePreset,
}: IAudiencePresetCard) {
  return (
    <div
      className={classNames(styles.card, {
        [styles.active]: isActive,
      })}
    >
      <div className={styles.cardIcon}>
        <img
          src={presetSourceIcons[audiencePreset.filteringSource]}
          alt={"Preset Icon"}
        />
      </div>
      <div className={styles.title}>{audiencePreset.name}</div>
      <div className={styles.description}>{audiencePreset.description}</div>

      <div className={styles.platformBadges}>
        {audiencePreset.platforms.map((v) => (
          <Badge
            leftAvatar={{
              src: presetSourceIcons[v],
            }}
            label={titleCase(v)}
            status={v === "FACEBOOK" ? "success" : "danger"}
          />
        ))}
      </div>
    </div>
  );
}
