import styles from "./ChooseFacebookAccountSettings.module.scss";
import { CampaignInput } from "../misc/createCampaignTypes";
import { Form, Formik, FormikHelpers, getIn, useFormikContext } from "formik";
import { Button } from "src/components/Button/Button";
import { transformUrlToConversionDomain } from "../functions/transformUrlToConversionDomain";
import FacebookSettings, {
  FBFormValueKey,
  upsertDefaultSettings,
} from "src/modules/global/components/FacebookSettings";
import { useContext } from "react";
import {
  WizardContext,
  useWizardContext,
} from "src/modules/global/misc/WizardContext2";
import { SaveCampaignWizardFormState } from "./SaveCampaignWizardFormState";
import { facebookSettingsSchema } from "../misc/facebookSettingSchema";
import { validateSelectedActivity } from "../misc/validateSelectedActivity";
import Card from "src/components/Card/Card";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import {
  MetaIntegrationDefaultSettingsDocument,
  useMetaIntegrationDefaultSettingsUpsertMutation,
} from "src/graphql/generated/schema";
import Tooltip from "src/components/Tooltip/Tooltip";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";

interface IChooseFacebookAccountSettings {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => void;
}

export default function ChooseFacebookAccountSettings({
  campaignInput,
  campaignPresetUpsert,
  handleBack,
}: IChooseFacebookAccountSettings) {
  const { currentNode } = useContext(WizardContext);

  const [metaIntegrationDefaultSettingsUpsertFunc] =
    useMetaIntegrationDefaultSettingsUpsertMutation();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={campaignInput}
      validate={async (values) =>
        await validateSelectedActivity(facebookSettingsSchema, values)
      }
      onSubmit={async (
        values: CampaignInput,
        formikHelpers: FormikHelpers<CampaignInput>
      ) => {
        const selectedActivity =
          values.Activities[values.selectedActivityIndex];

        if (selectedActivity.saveDefaultSettings) {
          await metaIntegrationDefaultSettingsUpsertFunc({
            variables: {
              input: {
                adAccountId: selectedActivity.adAccountId,
                instagramAccountId: selectedActivity.instagramPageId,
                pageId: selectedActivity.pageId,
                pixelId: selectedActivity.pixelId,
                conversionDomain: transformUrlToConversionDomain(
                  selectedActivity.conversionDomain
                ),
              },
            },
            refetchQueries: [
              {
                query: MetaIntegrationDefaultSettingsDocument,
              },
            ],
          });
        }

        const formattedValues = {
          ...values,
          Activities: values.Activities.map((activity, index) => {
            // only run the checks on current activity
            if (
              values.selectedActivityIndex === index &&
              activity.conversionDomain
            ) {
              return {
                ...activity,
                conversionDomain: transformUrlToConversionDomain(
                  activity.conversionDomain
                ),
              };
            }

            return activity;
          }),
        };

        formikHelpers.setValues(formattedValues);

        await campaignPresetUpsert(formattedValues);
      }}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <SaveCampaignWizardFormState />

          <ChooseFacebookAccountSettingsForm
            handleBack={() => {
              handleBack(values);
            }}
            handleSubmit={handleSubmit}
            disabled={currentNode.id !== "chooseFacebookAccount"}
          />
        </Form>
      )}
    </Formik>
  );
}

export function ChooseFacebookAccountSettingsForm({
  disabled,
  handleBack,
  handleSubmit,
}: {
  disabled: boolean;
  handleBack: () => void;
  handleSubmit: () => void;
}) {
  const { isCurrentNodeCompleted, setResetAlert } = useWizardContext();
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<CampaignInput>();
  const activityKey = `Activities[${values.selectedActivityIndex}]`;

  const fbSettingsFieldsMapper: Record<FBFormValueKey, string> = {
    metaIntegrationId: `${activityKey}.metaIntegrationId`,
    adAccountId: `${activityKey}.adAccountId`,
    adAccountName: `${activityKey}.adAccountName`,
    conversionDomain: `${activityKey}.conversionDomain`,
    platformAccountId: `${activityKey}.platformAccountId`,
    instagramPageId: `${activityKey}.instagramPageId`,
    metaPageId: `${activityKey}.metaPageId`,
    pageId: `${activityKey}.pageId`,
    metaInstagramPageId: `${activityKey}.metaInstagramPageId`,
    metaPixelId: `${activityKey}.metaPixelId`,
    metaPageName: `${activityKey}.metaPageName`,
    metaPagePicture: `${activityKey}.metaPagePicture`,
    pixelId: `${activityKey}.pixelId`,
    metaPixelName: `${activityKey}.metaPixelName`,
    instagramPageName: `${activityKey}.instagramPageName`,
    metaPageConnectedInstagramBusinessAccountId: `${activityKey}.metaPageConnectedInstagramBusinessAccountId`,
  };

  function setFormValue(formikKey: string, value: any) {
    setFieldValue(formikKey, value);
  }

  return (
    <Card disabled={disabled}>
      <FacebookSettings
        onAdAccountChange={(value, platformAccountId, metaName) => {
          if (isCurrentNodeCompleted) {
            setResetAlert({
              data: values,
              fieldKey: `${activityKey}.adAccountId`,
              fieldValue: { adAccountId: value, platformAccountId, metaName },
              isShowing: true,
            });
            return;
          }
          upsertDefaultSettings(
            {
              id: "",
              conversionDomain: "",
              metaAdAccountId: value,
              MetaAdAccount: {
                id: value,
                metaName,
                metaAccountId: platformAccountId,
              },
              MetaInstagramAccount: null,
              metaInstagramAccountId: "",
              MetaPage: null,
              metaPageId: null,
              MetaPixel: null,
              metaPixelId: null,
            },
            setFieldValue,
            fbSettingsFieldsMapper
          );

          // setFormValue(`${activityKey}.adAccountId`, value);
          // setFormValue(`${activityKey}.platformAccountId`, platformAccountId);
          // setFormValue(`${activityKey}.adAccountName`, metaName);
        }}
        onMetaPageChange={(value, arr) => {
          const page = arr.find((i) => i.id === value);

          if (isCurrentNodeCompleted) {
            setResetAlert({
              data: values,
              fieldKey: `${activityKey}.pageId`,
              fieldValue: {
                connectedInstagramAccount:
                  page.metaPageConnectedInstagramBusinessId,
                metaPageId: value,
                metaPagePicture: page.Business?.profilePicture,
                metaPageName: page.name,
              },
              isShowing: true,
            });
            return;
          }

          setFormValue(
            `${activityKey}.metaPageConnectedInstagramBusinessAccountId`,
            page.metaPageConnectedInstagramBusinessId
          );
          setFormValue(`${activityKey}.pageId`, value);
          if (page.Business?.profilePicture) {
            setFormValue(
              `${activityKey}.metaPagePicture`,
              page.Business?.profilePicture
            );
          }
          setFormValue(`${activityKey}.metaPageId`, page.metaPageId);
          setFormValue(`${activityKey}.metaPageName`, page.name);
        }}
        containerClass={styles.container}
        fieldsMapper={fbSettingsFieldsMapper}
        showInstagramFields={true}
        showConversionDomainInput={true}
      />

      <div className={styles.checkboxWithLabel}>
        <div>
          <Checkbox
            value={`${activityKey}.saveDefaultSettings`}
            checked={getIn(values, `${activityKey}.saveDefaultSettings`)}
            onChange={(checked) => {
              setFieldValue(`${activityKey}.saveDefaultSettings`, checked);
            }}
          />
        </div>
        <div>Use these options for future campaigns</div>
        <Tooltip
          children={<Info width={15} height={15} />}
          supportingText={
            "If you select this option, you don’t have to select options each time when you launch campaigns, we will save your settings for the future campaigns as well."
          }
        />
      </div>
      <div className={styles.submit}>
        <Button
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Continue
        </Button>
        <Button
          onClick={() => {
            handleBack();
          }}
          color="subdued"
          // eslint-disable-next-line react/style-prop-object
          style="outline"
          disabled={isSubmitting}
        >
          Back
        </Button>
      </div>
    </Card>
  );
}
