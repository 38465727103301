import classNames from "classnames";
import styles from "./ProductToggle.module.scss";

interface IPlansArr {
  label: string;
  value: "GROW" | "ENGAGE";
}

const plansArr: IPlansArr[] = [
  { label: "Grow", value: "GROW" },
  { label: "Engage", value: "ENGAGE" },
];

interface Props {
  selectedProduct: string;
  setSelectedProduct: (i: "ENGAGE" | "GROW") => void;
  setIsEnterprisePlan: (i: boolean) => void;
}

export function ProductToggle({
  selectedProduct,
  setSelectedProduct,
  setIsEnterprisePlan,
}: Props) {
  return (
    <div className={styles.btnGrp}>
      {plansArr.map((item, index) => (
        <button
          key={index}
          type="button"
          className={classNames(styles.btn, {
            [styles.selected]: item.value === selectedProduct,
          })}
          onClick={() => {
            setSelectedProduct(item.value);
            if (item.value === "GROW") {
              // setSelectedAdOrCustomer("0-10K");
              setIsEnterprisePlan(false);
            } else {
              // setSelectedAdOrCustomer("0 - 500");
              setIsEnterprisePlan(false);
            }
          }}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}
