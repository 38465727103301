import { Button } from "src/components/Button/Button";
import CollectionItem from "src/components/CollectionItem/CollectionItem";
import Modal from "src/components/Modal/Modal";
import { RadioGroup } from "@headlessui/react";
import styles from "./TemplateListingOptionModal.module.scss";
import ChooseTempEmail from "../assets/chooseTempEmail.png";
import ChooseTempSms from "../assets/chooseTempSms.png";
import ChooseTempWhatsapp from "../assets/chooseTempWhatsapp.png";

const templateOptions = [
  {
    title: "Create Email Template",
    subtitle: "Use our pre-defined template to get started",
    image: ChooseTempEmail,
    value: "email",
  },
  {
    title: "Create SMS Template",
    subtitle: "Use our pre-defined template to get started",
    image: ChooseTempSms,
    value: "sms",
  },
  {
    title: "Create WhatsApp Template",
    subtitle: "Use our pre-defined template to get started",
    image: ChooseTempWhatsapp,
    value: "whatsapp",
  },
];

interface ITemplateListingOptionModal {
  isOpen: boolean;
  onClose: () => void;
  selectedTemplateType: null | string;
  setSelectedTemplateType: (i: any) => void;
}

export function TemplateListingOptionModal({
  isOpen,
  onClose,
  selectedTemplateType,
  setSelectedTemplateType,
}: ITemplateListingOptionModal) {
  function getLink() {
    switch (selectedTemplateType) {
      case "whatsapp":
        return "/templates/builder/whatsapp/new";
      case "sms":
        return "/templates/builder/sms/new";
      case "email":
        return "/templates/builder/email/new";
      default:
        return "404";
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Choose platform for want to create template"
      subtitle="Create new segments using customizable templates."
      footer={{
        footerActions: (
          <>
            <Button color="subdued" style="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={selectedTemplateType ? false : true}
              to={getLink()}
            >
              Create template
            </Button>
          </>
        ),
        footerContent: "",
      }}
      noContainer
      maxWidth="944px"
    >
      <div>
        <RadioGroup
          as="div"
          className={styles.templates}
          name={"TemplateType"}
          value={selectedTemplateType}
          // onBlur={() => {
          //   setFieldTouched(platformKey);
          // }}
          onChange={(value) => {
            // setFieldValue(platformKey, value);
            setSelectedTemplateType(value);
          }}
        >
          {templateOptions.map((template, index) => (
            <RadioGroup.Option
              value={template.value}
              //   disabled={template.disable}
              title={template.title}
              //   style={{ opacity: template.disable ? "0.5" : "1" }}
              key={index}
            >
              <CollectionItem
                title={template.title}
                subtitle={template.subtitle}
                image={template.image}
                value={template.value}
                key={index}
                className={styles.templateItem}
                imageHeight={100}
                imageWidth={100}
              />
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
    </Modal>
  );
}
