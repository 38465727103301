import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import FacebookSettings, {
  FBFormValueKey,
  upsertDefaultSettings,
} from "src/modules/global/components/FacebookSettings";
import styles from "./MetaIntegrationDefaultSettingForm.module.scss";
import { Button } from "src/components/Button/Button";
import {
  MetaIntegrationDefaultSettingsQuery,
  useMetaIntegrationDefaultSettingsUpsertMutation,
} from "src/graphql/generated/schema";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useEffect } from "react";
import { transformUrlToConversionDomain } from "src/modules/campaign-wizard/functions/transformUrlToConversionDomain";

const metaIntegrationFormValidationSchema = Yup.object({
  adAccountId: Yup.string().required("Ad account is required"),
  pixelId: Yup.string().required("Pixel is required"),

  instagramPageId: Yup.string().required("Instagram is required"),
  pageId: Yup.string().required("Meta pages is required"),
  conversionDomain: Yup.string()
    .test(
      "isValidConversionDomain",
      "Should be a valid domain name",
      async (value) => {
        const validConversionDomainRegex =
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[\w.-]*)*/g;
        if (!value) {
          return true;
        }
        return validConversionDomainRegex.test(value);
      }
    )
    .notRequired(),
});

type FormValues = Partial<Record<FBFormValueKey, string>>;

export function MetaIntegrationDefaultSettingForm({
  metaDefaultSettings,
}: {
  metaDefaultSettings: MetaIntegrationDefaultSettingsQuery["metaIntegrationDefaultSettings"];
}) {
  const [
    metaIntegrationDefaultSettingsUpsertFunc,
    { loading: metaIntegrationDefaultSettingsUpsertLoading },
  ] = useMetaIntegrationDefaultSettingsUpsertMutation();

  const initialValues: FormValues = {
    adAccountId: "",
    adAccountName: "",
    conversionDomain: "",
    instagramPageId: "",
    instagramPageName: "",
    metaPageConnectedInstagramBusinessAccountId: "",
    metaPageId: "",
    metaPageName: "",
    metaPagePicture: "",
    metaPixelName: "",
    pixelId: "",
    platformAccountId: "",
  };

  async function handleSubmit(
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) {
    formikHelpers.setFieldValue(
      "conversionDomain",
      transformUrlToConversionDomain(values.conversionDomain)
    );
    await metaIntegrationDefaultSettingsUpsertFunc({
      variables: {
        input: {
          adAccountId: values.adAccountId,
          instagramAccountId: values.instagramPageId,
          pageId: values.pageId,
          pixelId: values.pixelId,
          conversionDomain: transformUrlToConversionDomain(
            values.conversionDomain
          ),
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: ["metaIntegrationDefaultSettings"],
    });
    toast.success("Updated default settings successfully");
    return;
  }

  return (
    <div className={styles.container}>
      <Formik
        validateOnMount
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={metaIntegrationFormValidationSchema}
      >
        <DefaultSettingsForm
          metaIntegrationDefaultSettings={metaDefaultSettings}
          loading={metaIntegrationDefaultSettingsUpsertLoading}
        />
      </Formik>
    </div>
  );
}

function DefaultSettingsForm({ loading, metaIntegrationDefaultSettings }) {
  const { setFieldValue, resetForm, isValid } = useFormikContext();
  useEffect(() => {
    if (!metaIntegrationDefaultSettings) return;
    upsertDefaultSettings(metaIntegrationDefaultSettings, setFieldValue);
  }, [metaIntegrationDefaultSettings, setFieldValue]);

  return (
    <Form>
      <FacebookSettings
        onReset={() => {
          resetForm({});
        }}
        containerClass={styles.formContainer}
        showInstagramFields={true}
        showConversionDomainInput={true}
      />
      <div className={styles.actionContainer}>
        <Button
          type="submit"
          style="solid"
          color="primary"
          disabled={loading || !isValid}
          loading={loading}
        >
          Save changes
        </Button>
        <Button
          type="button"
          style="outline"
          color="subdued"
          onClick={() => {
            // handle reset was not working for some reason
            resetForm({
              values: {},
            });
          }}
          disabled={loading}
        >
          Reset
        </Button>
      </div>
    </Form>
  );
}
