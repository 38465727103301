import { Button } from "src/components/Button/Button";
import Card from "src/components/Card/Card";
import styles from "./AudienceSetNameForm.module.scss";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import Stepper from "src/components/Stepper/Stepper";
import { useContext } from "react";
import { WizardContext } from "src/modules/global/misc/WizardContext";
import { Form, Formik } from "formik";
import { AudienceSetCreateType } from "../misc/audienceSetCreateType";
import * as Yup from "yup";
import { AudienceSetAdAccountInput } from "./AudienceSetAdAccountInput";

export function AudienceSetNameForm() {
  const { breadcrumbs, currentNode, data, markNodeAsComplete } =
    useContext(WizardContext);

  return (
    <Formik
      initialValues={data}
      validationSchema={Yup.object({
        name: Yup.string().required("Name is required"),
        adAccountId: Yup.string().required("Ad account is required"),
        adAccountName: Yup.string().required("Ad account is required"),
        platformAccountId: Yup.string().required("Ad account is required"),
      })}
      onSubmit={(values) => {
        console.log({ values });
        markNodeAsComplete({
          ...values,
          currentStep: currentNode.getNextStep(values),
        });
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Stepper
            activeStepIndex={breadcrumbs.findIndex((b) => b.isActive)}
            steps={breadcrumbs}
          />
          <div className={styles.layout}>
            <Card>
              <div>
                <div className={styles.header}>
                  <h1 className={styles.heading}>Name your new Audience Set</h1>
                  <p className={styles.copy}>
                    Add unique name which defines the purpose of this Audience
                    Set and easy to manage
                  </p>
                </div>
                <div className={styles.formInput}>
                  <InputFormik name="name" label="Audience Set Name" />
                  <AudienceSetAdAccountInput />
                </div>
                <Button type="submit">Save and Continue</Button>
              </div>
            </Card>
          </div>
        </Form>
      )}
    </Formik>
  );
}
