import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import {
  PasswordUpdateInput,
  useIsResetCodeExpiredQuery,
  usePasswordUpdateMutation,
} from "src/graphql/generated/schema";
import { useContext, useEffect, useState } from "react";
import AuthContext from "src/stores/AuthContext";
import { Button } from "src/components/Button/Button";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import styles from "./PasswordReset.module.scss";
import { PasswordResetLinkExpired } from "../components/PassswordResetLinkExpired";
import macroLogo from "src/modules/global/assets/macroLogo.svg";
import passwordReset from "../assets/passwordReset.png";
import LogoutDropdown from "src/components/LogoutDropdown/LogoutDropdown";
import PasswordResetSuccessful from "./PasswordResetSuccessful";

const resetSchema = Yup.object().shape({
  password: Yup.string().required("Enter New Password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Enter Confirm Password"),
});

export function PasswordReset() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const { login } = useContext(AuthContext);
  const [passwordUpdateFunc] = usePasswordUpdateMutation();
  const {
    data,
    loading: isResetCodeExpiredLoading,
    error,
  } = useIsResetCodeExpiredQuery({
    variables: {
      code: urlSearchParams.get("code"),
    },
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues: PasswordUpdateInput = {
    password: "",
    resetPasswordCode: urlSearchParams.get("code"),
  };

  if ((data && data.resetPasswordCodeExpiredCheck) || error) {
    return <PasswordResetLinkExpired />;
  }

  async function handleSubmit(values) {
    setLoading(true);
    try {
      const { data } = await passwordUpdateFunc({
        variables: {
          input: {
            password: values.password,
            resetPasswordCode: values.resetPasswordCode,
          },
        },
      });
      // navigate("/login");
      const { userError, authPayload } = data.passwordUpdate;
      if (userError) {
        console.log(userError);
        setLoading(false);
        throw new Error();
      }
      if (authPayload) {
        await login({
          email: authPayload?.user?.email,
          password: values.password,
        });
      }
      setLoading(false);
      setIsPasswordReset(true);
      // navigate("/");
    } catch (err) {
      console.log(err);
      setLoading(false);
      throw new Error();
    }
  }

  return (
    <>
      {isPasswordReset ? (
        <PasswordResetSuccessful />
      ) : (
        <div className={styles.container}>
          <div className={styles.topBar}>
            <img src={macroLogo} alt="macroLogo" />
            <div className={styles.logout}>
              <LogoutDropdown />
            </div>
          </div>
          <div className={styles.leftContainer}>
            <img src={passwordReset} alt="Lock" className={styles.lockImg} />
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.resetContainer}>
              <h2 className={styles.title}>Reset Password</h2>
              <Formik
                initialValues={initialValues}
                validationSchema={resetSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <InputFormik
                    name="password"
                    placeholder="New Password"
                    type="password"
                  />
                  <div className={styles.input}>
                    <InputFormik
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                    />
                  </div>
                  <Button
                    type="submit"
                    width="full"
                    color="primary"
                    loading={loading}
                    disabled={loading || isResetCodeExpiredLoading}
                  >
                    {isResetCodeExpiredLoading
                      ? "Checking Code"
                      : "Change my password"}
                  </Button>
                  <div className={styles.orWrapper}>
                    <hr />
                    <span className={styles.or}>or</span>
                  </div>
                  <p className={styles.backToLogin}>
                    <span>Back to </span>
                    <Link to="/auth/login" className={styles.login}>
                      Login
                    </Link>
                  </p>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
