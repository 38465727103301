import Badge from "../Badge/Badge";
import UserMenu from "../UserMenu/UserMenu";
import styles from "./DefaultHeader.module.scss";
import { IHeader } from "./Header";

export default function DefaultHeader({ title, titleMetaData, user }: IHeader) {
  return (
    <div className={styles.header}>
      {title && (
        <div className={styles.titleContainer}>
          <div className={styles.title}>{user?.Client?.name || "user"}</div>
          {titleMetaData && (
            <button
              type="button"
              onClick={titleMetaData.onClick}
              className={styles.titleMetadata}
            >
              <Badge
                label={titleMetaData.content}
                status={titleMetaData.status}
              />
            </button>
          )}
        </div>
      )}
      {/* <Input
            // icon={<SearchIcon />}
            onChange={() => {}}
            placeholder="Search here"
            type="text"
            value=""
          /> */}
      <div className={styles.actions}>
        {/* <IconButton
            icon={<Notifications />}
            onClick={() => {
              console.log("Notifications");
            }}
            style="plain"
          />
          <IconButton
            icon={<Tips />}
            onClick={() => {
              console.log("Tips");
            }}
            style="plain"
          /> */}
        <UserMenu user={user} />
      </div>
    </div>
  );
}
