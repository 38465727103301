import { Button } from "src/components/Button/Button";
// import BannerImg from "../assets/upgradeBanner.png";
import upgradePlanCircleOne from "../assets/upgradePlanCircle.svg";
import upgradePlanCircleTwo from "../assets/upgradePlanCircle2.svg";
import upgradePlanCircleThree from "../assets/upgradePlanCircle3.svg";
import styles from "./UpgradePlanBanner.module.scss";
import classNames from "classnames";
import { titleCase } from "src/lib/titleCase";

interface IUpgradePlanBanner {
  bannerImage: string;
  primaryColor: "engage" | "grow";
  planName: "engage" | "grow";
}

export function UpgradePlanBanner({
  bannerImage,
  primaryColor = "engage",
  planName = "engage",
}: IUpgradePlanBanner) {
  return (
    <div className={classNames(styles.banner, styles[primaryColor])}>
      <div className={styles.container}>
        <div>
          <h3 className={styles.title}>
            Upgrade to Macro {titleCase(planName)}
          </h3>
          <p className={styles.description}>
            Supercharge your journey with our Upgrade Plan for ultimate
            possibilities.
          </p>
          <Button to="/settings/pricing" size="small">
            View Pricing Plan
          </Button>
        </div>
        <img src={bannerImage} alt="bannerImg" className={styles.bannerImg} />
      </div>
      <img
        src={upgradePlanCircleOne}
        alt="circle1"
        className={styles.circleOne}
      />
      <img
        src={upgradePlanCircleTwo}
        alt="circle2"
        className={styles.circleTwo}
      />
      <img
        src={upgradePlanCircleThree}
        alt="circle3"
        className={styles.circleThree}
      />
    </div>
  );
}
