import { useEffect } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  AssetsOrderBy,
  AssetsQuery,
  useAssetsLazyQuery,
} from "src/graphql/generated/schema";
import { MediaPicker } from "./MediaPicker";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "src/modules/global/misc/dateFilterUtils";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import styles from "./MediaLoader.module.scss";
import noResults from "../../assets/noResultAssets.svg";
import createAssets from "../../assets/createAssets.svg";

interface IMediaLoader {
  filters: { dateFilter: DateFilter; take: number };
  sortBy: AssetsOrderBy;
  search: string;
  selectedAssets: AssetsQuery["assets"]["edges"];
  setSelectedAssets: (i: AssetsQuery["assets"]["edges"]) => void;
  multiple: boolean;
}

export function MediaLoader({
  filters,
  sortBy,
  selectedAssets,
  setSelectedAssets,
  search,
  multiple,
}: IMediaLoader) {
  const [assetQuery, { called, data, loading, error }] = useAssetsLazyQuery();
  const debouncedSearchValue = useDebouncedVariable(search);

  useEffect(() => {
    assetQuery({
      variables: {
        filters: {
          take: filters.take,
          createdAt: getDateFilterFromFilterEnum(filters.dateFilter),
          name: debouncedSearchValue,
        },
        sortBy,
      },
    });
  }, [sortBy, filters, assetQuery, debouncedSearchValue]);

  if (!called) {
    return (
      <div>
        <Spinner height={400} />
      </div>
    );
  }

  if (loading) {
    return <Spinner height={400} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const areFiltersApplied =
    filters.dateFilter !== "ALL_TIME" || debouncedSearchValue !== "";

  // this should be above the generic handler of no result
  // because that one will match both of these cases
  if (!data.assets.edges.length && areFiltersApplied) {
    return (
      <div className={styles.noResultScreen}>
        <img src={noResults} width={48} height={48} alt="no result audience" />
        <span className={styles.noResultText}>
          No search results found. Try changing the filters or use a different
          keyword.
        </span>
      </div>
    );
  }

  if (!data.assets.edges.length) {
    return (
      <div className={styles.emptyScreen}>
        <img src={createAssets} width={48} height={48} alt="upload assets" />
        <span className={styles.emptyScreenText}>
          Oops! It looks like you don't have an assets.
        </span>
      </div>
    );
  }

  return (
    <MediaPicker
      selectedAssets={selectedAssets}
      setSelectedAssets={setSelectedAssets}
      multiple={multiple}
      assets={data.assets.edges}
    />
  );
}
