import classNames from "classnames";
import Avatar from "react-avatar";
import { Asset } from "../../../components/Smartphone/Asset";
import styles from "./ClientThumbnail.module.scss";

export function ClientThumbnail({
  client,
  className,
  size = "25",
}: {
  client: any;
  className?: string;
  size?: string;
}) {
  if (client.Thumbnail) {
    return (
      <Asset
        type={client.Thumbnail?.contentType}
        name={client.Thumbnail?.name}
        preview={client.Thumbnail?.uri}
        className={classNames(styles.avatar, className)}
      />
    );
  }

  return (
    <Avatar
      name={client.name}
      size={size}
      maxInitials={2}
      alt="User Avatar"
      round={true}
      textSizeRatio={2.8}
      className={styles.avatar}
    />
  );
}
