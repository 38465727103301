import styles from "./NotificationSettingWithToggle.module.scss";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { getIn, useFormikContext } from "formik";
import { notificationSettingFormType } from "../misc/notificationSettingFormType";

interface INotificationSettingWithToggle {
  title: string;
  description: string;
  formKey: string;
  disabled?: boolean;
  onChange?: (value: boolean) => unknown;
}

// TODO: Use toggle switch here
export default function NotificationSettingWithToggle({
  title,
  description,
  formKey,
  disabled,
  onChange,
}: INotificationSettingWithToggle) {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<notificationSettingFormType>();
  const formValue = getIn(values, formKey);

  return (
    <div className={styles.item}>
      <div>
        <span className={styles.title}>{title}</span>
        <p className={styles.description}>{description}</p>
      </div>
      <div>
        <Switch
          checked={formValue}
          disabled={disabled}
          onChange={(value) => {
            setFieldTouched(formKey);
            setFieldValue(formKey, value);
            if (onChange) {
              onChange(value);
            }
          }}
          className={classNames(styles.switchContainer, {
            [styles.darkContainer]: formValue,
            [styles.lightContainer]: !formValue,
          })}
        >
          <span className={styles.srOnly}>{title}</span>
          <span
            aria-hidden="true"
            className={classNames(styles.circle, {
              [styles.enabledCircle]: formValue,
              [styles.disabledCircle]: !formValue,
            })}
          />
        </Switch>
      </div>
    </div>
  );
}
