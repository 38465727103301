import { GrowAggregateType } from "../misc/GrowAggregateType";

export function getGrowAggregateStatsByType({
  insights,
  type,
}: {
  insights?: GrowAggregateType;
  type: "TRANSACTIONAL" | "ENGAGEMENT" | "AWARENESS" | "TRAFFIC" | "LEADS";
}): {
  title: string;
  valueKey: keyof GrowAggregateType | "not_tracked";
  value: any;
  valueType: "NUMBER" | "CURRENCY" | "PERCENTAGE" | "MULTIPLIER";
}[] {
  if (type === "TRANSACTIONAL") {
    return [
      {
        title: "Total Sales",
        valueKey: "totalValuePurchase",
        value: insights?.totalValuePurchase,
        valueType: "CURRENCY" as const,
      },
      {
        title: "Total Orders",
        valueKey: "countPurchase",
        value: insights?.countPurchase,
        valueType: "NUMBER" as const,
      },
      {
        title: "Add to Cart",
        valueKey: "countAddToCart",
        value: insights?.countAddToCart,
        valueType: "NUMBER" as const,
      },
      {
        title: "Initiate Checkout",
        valueKey: "countInitiateCheckout",
        value: insights?.countInitiateCheckout,
        valueType: "NUMBER" as const,
      },
      {
        title: "Abandoned Checkout",
        valueKey: "countInitiateCheckout",
        value: insights?.countInitiateCheckout ?? 0,
        valueType: "NUMBER" as const,
      },
    ];
  } else if (type === "ENGAGEMENT") {
    return [
      {
        title: "DMs/Inbox Counts",
        valueKey: "messagingConversationStarted7d",
        value: insights?.messagingConversationStarted7d,
        valueType: "NUMBER" as const,
      },
      {
        title: "Engagement Count",
        valueKey: "countPostEngagement",
        value: insights?.countPostEngagement,
        valueType: "NUMBER" as const,
      },
      {
        title: "CTR",
        valueKey: "ctr",
        value: insights?.ctr,
        valueType: "PERCENTAGE" as const,
      },
      {
        title: "Video Thru play",
        valueKey: "videoThruplayWatched",
        value: insights?.videoThruplayWatched,
        valueType: "NUMBER" as const,
      },
      {
        title: "Video 3s Views",
        valueKey: "countVideoView",
        value: insights?.countVideoView,
        valueType: "NUMBER" as const,
      },
    ];
  }

  if (type === "AWARENESS") {
    return [
      {
        title: "Reach",
        valueKey: "reach",
        value: insights?.reach,
        valueType: "NUMBER" as const,
      },
      {
        title: "Impressions",
        valueKey: "impressions",
        value: insights?.impressions,
        valueType: "NUMBER" as const,
      },
      {
        title: "Clicks",
        valueKey: "clicks",
        value: insights?.clicks,
        valueType: "NUMBER" as const,
      },
      {
        title: "CTR",
        valueKey: "ctr",
        value: insights?.ctr,
        valueType: "PERCENTAGE" as const,
      },
      {
        title: "Frequency",
        valueKey: "frequency",
        value: insights?.frequency,
        valueType: "NUMBER" as const,
      },
    ];
  }

  if (type === "TRAFFIC") {
    return [
      {
        title: "Total visits on Website",
        valueKey: "countLandingPageView",
        value: insights?.countLandingPageView,
        valueType: "NUMBER" as const,
      },
      {
        title: "Link Clicks",
        valueKey: "countLinkClick",
        value: insights?.countLinkClick,
        valueType: "NUMBER" as const,
      },
      {
        title: "Profile Visits",
        valueKey: "not_tracked",
        // not tracked yet
        value: 0,
        valueType: "NUMBER" as const,
      },
      {
        title: "Product Page Views",
        valueKey: "countViewContent",
        value: insights?.countViewContent,
        valueType: "NUMBER" as const,
      },
      {
        title: "Cost Per Click",
        valueKey: "cpc",
        value: insights?.cpc,
        valueType: "NUMBER" as const,
      },
    ];
  }

  // TODO: Fix this
  if (type === "LEADS") {
    return [
      {
        title: "Customer Added",
        valueKey: "not_tracked",
        value: 0,
        valueType: "NUMBER" as const,
      },
      {
        title: "Signups/Registrations",
        valueKey: "countLinkClick",
        value: insights?.countLinkClick,
        valueType: "NUMBER" as const,
      },
      //   {
      //     title: "DMs/Inbox Counts",
      //     valueKey: "not_tracked",
      //     // not tracked yet
      //     value: insights?.messagingConversationStarted7d,
      //     valueType: "NUMBER" as const,
      //   },
      {
        title: "Form Submissions",
        valueKey: "countViewContent",
        value: insights?.countViewContent,
        valueType: "NUMBER" as const,
      },
    ];
  }
}
