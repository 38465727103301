import { MetaAdAccountSelector } from "src/modules/campaign-wizard/components/MetaAdAccountSelector";
import styles from "./AudienceSetAdAccountInput.module.scss";
import { useFormikContext } from "formik";
import { AudienceSetCreateType } from "../misc/audienceSetCreateType";
import { useMetaEnabledAdAccountsQuery } from "src/graphql/generated/schema";

export function AudienceSetAdAccountInput() {
  // const { data } = useMetaEnabledAdAccountsQuery();
  const { values, setFieldValue } = useFormikContext<AudienceSetCreateType>();

  return (
    <MetaAdAccountSelector
      // metaAdAccounts={data?.metaEnabledAdAccounts ?? []}
      selectedAdAccountId={values.adAccountId}
      setSelectedAdAccountId={(value, platformAccountId, metaName) => {
        setFieldValue("adAccountId", value);
        setFieldValue("platformAccountId", platformAccountId);
        setFieldValue("adAccountName", metaName);
      }}
      formKey={`adAccountId`}
    />
  );
}
