import { Label } from "src/components/Label/Label";
import styles from "./WhatsappReview.module.scss";
import { Radio } from "src/components/Radio/Radio";
import { Link } from "react-router-dom";
import { WhatsappFormInput } from "../../CreateMessageTemplateTypes";

const buttonTypeRadios = [
  {
    value: "NONE",
    label: "None",
  },
  {
    value: "CTA",
    label: "Call To Action",
  },
  {
    value: "QUICK_REPLY",
    label: "Quick Reply",
  },
];

export function WhatsappReview({ values }: { values: WhatsappFormInput }) {
  return (
    <>
      <Label label="Template Name">
        <p className={styles.value}>{values.name}</p>
      </Label>
      <div className={styles.rowTile}>
        <Label label="Category">
          <p className={styles.value}>{values.objective}</p>
        </Label>
        <Label label="Language">
          <p className={styles.value}>{values.language}</p>
        </Label>
      </div>
      <Label label="Header">
        {/* TODO: add the variable if given */}
        <p className={styles.value}>
          {values.header.headerType === "TEXT"
            ? values.header.text
            : values.header.headerType}
        </p>
      </Label>
      <div>
        <Label label="Body">
          <p>{values?.body?.text}</p>
        </Label>
      </div>
      <Label label="Footer">
        <p className={styles.value}>{values?.footer}</p>
      </Label>
      <div className={styles.rowTile}>
        {buttonTypeRadios.map(({ value, label }) => (
          <div className={styles.radioWrapper}>
            <Radio
              value={value}
              checked={values.buttons.type === value}
              disabled
              onChange={() => {}}
            />
            <label htmlFor="" className={styles.radioLabel}>
              {label}
            </label>
          </div>
        ))}
      </div>

      {values.buttons.type === "CTA" &&
        values.buttons.ctas.map((value) => (
          <div>
            <p className={styles.value}>{value?.text}</p>
            <small>
              <Link to={value?.url} className={styles.link}>
                {value?.url}
              </Link>
            </small>
          </div>
        ))}

      {/* FIX: Quick Reply Preview */}
      {values.buttons.type === "QUICK_REPLY" &&
        values.buttons.quickReplies.map((value) => (
          <div>
            <p className={styles.value}>{value}</p>
          </div>
        ))}
    </>
  );
}
