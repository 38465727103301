import Banner from "src/components/Banner/Banner";
import { ErrorIcon } from "./ErrorIcon";
import { Link } from "react-router-dom";
import styles from "./BillingAndUsageBanner.module.scss";
import {
  Billing,
  BillingInvoice,
  OrganizationUsagePayload,
  useOrganizationUsageQuery,
} from "src/graphql/generated/schema";
import classNames from "classnames";

export function BillingAndUsageBanner({
  billing,
  openInvoices,
  showSidebar,
}: {
  billing: Billing;
  openInvoices: BillingInvoice[];
  showSidebar: boolean;
}) {
  const { data, loading, error } = useOrganizationUsageQuery();
  const { message, buttonText, bannerType } = getBillingBanner({
    billing,
    organizationUsage: data?.organizationUsage,
  });

  if (error) {
    console.error(error);
    return null;
  }

  if (loading) {
    return null;
  }

  if (!message) return null;

  return (
    <div
      className={classNames({
        [styles.sidebarPadding]: showSidebar,
      })}
    >
      <Banner
        content={message}
        icon={<ErrorIcon />}
        action={
          <Link
            to={
              openInvoices.length > 0
                ? openInvoices[0].hostedInvoiceUrl
                : "/settings/billing"
            }
            className={styles.upgradePlanBtn}
          >
            {buttonText}
          </Link>
        }
        type={bannerType}
        // only hide when message is null which is handled above
        isOpen={true}
      />
    </div>
  );
}

interface getBillingBannerArgs {
  billing: Billing;
  organizationUsage: OrganizationUsagePayload;
}

function getBillingBanner({
  billing,
  organizationUsage,
}: getBillingBannerArgs) {
  let bannerType: "warning" | "error" = "warning";
  let buttonText = "Upgrade Plan";
  let message = "";

  if (billing?.status !== "ACTIVE" && billing?.status !== "TRIALING") {
    message =
      "We couldn't charge your payment method. Please pay the latest invoice as soon as possible. Account will be locked after 3 tries.";
    bannerType = "error";
    buttonText = "Pay Invoice";
  } else if (!organizationUsage) {
    message = "";
  } else if (organizationUsage.isCreditCountExhausted) {
    message = "You have exhausted credit limits. Please consider upgrade plan";
  } else if (organizationUsage.isCustomersCountExhausted) {
    message =
      "You have exhausted customer limits. Please consider upgrade plan";
  } else if (organizationUsage.isEmailCountExhausted) {
    message =
      "You have exhausted email send limits. Please consider upgrade plan";
  } else if (organizationUsage.isTotalAdSpendExhausted) {
    message = "You have exhausted ad spend. Please consider upgrade plan";
  }

  return { message, buttonText, bannerType };
}
