import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination/Pagination";
import { Spinner } from "../../../components/Spinner/Spinner";
import {
  AudienceAudienceSetFilterInput,
  AudiencesSortBy,
  useAudienceAudienceSetIndexesLazyQuery,
} from "../../../graphql/generated/schema";
import { useDebounceEffect } from "../../campaign-wizard/functions/useDebounceEffect";
import { PaginationInput } from "../../global/misc/PaginationInput";
import { itemsPerPage } from "../misc/itemsPerPages";
import { AudienceEmptyState } from "./AudienceEmptyState";
import { AudienceList } from "./AudienceList";

interface IAudienceListLoader {
  filters: AudienceAudienceSetFilterInput;
  sortBy: AudiencesSortBy;
}

export function AudienceListLoader({ filters, sortBy }: IAudienceListLoader) {
  const [pagination, setPagination] = useState<PaginationInput>({
    cursor: null,
    take: itemsPerPage,
  });

  const [audienceAudienceSetFunc, { data, loading, error }] =
    useAudienceAudienceSetIndexesLazyQuery({
      variables: {
        filter: {
          name: "",
          take: pagination.take,
          ...(pagination.cursor && {
            cursor: pagination?.cursor,
          }),
          ...(filters.createdAt && {
            createdAt: filters.createdAt,
          }),
        },
        sortBy: sortBy,
      },
    });

  async function triggerAudienceAudienceSetQuery({
    filters,
    pagination,
    sortBy,
  }) {
    const query = filters.name.trim();
    await audienceAudienceSetFunc({
      variables: {
        filter: {
          name: query,
          take: pagination.take,
          ...(pagination.cursor && {
            cursor: pagination?.cursor,
          }),
          ...(filters.createdAt && {
            createdAt: filters.createdAt,
          }),
        },
        sortBy: sortBy,
      },
    });
  }

  useEffect(() => {
    triggerAudienceAudienceSetQuery({ pagination, filters, sortBy });
  }, [pagination, sortBy, filters]);

  useDebounceEffect(
    async () =>
      await triggerAudienceAudienceSetQuery({ pagination, sortBy, filters }),
    300,
    [filters.name],
  );

  if (loading) {
    return <Spinner height="screen" />;
  }

  if (error) {
    return <div> {error.message}</div>;
  }

  const audienceAudienceSetIndexes =
    data && data.audienceAudienceSetIndexes
      ? data.audienceAudienceSetIndexes.edges.map((v) => v.node)
      : [];

  if (audienceAudienceSetIndexes.length === 0) {
    return <AudienceEmptyState />;
  }

  const pageInfo = data && data.audienceAudienceSetIndexes.pageInfo;
  return (
    <div>
      <AudienceList
        items={audienceAudienceSetIndexes}
        dateFilters={{
          dateFrom: filters?.createdAt?.gte,
          dateTo: filters?.createdAt?.lte,
        }}
      />
      <Pagination
        borderLess
        noTopPadding
        hasPrevious={pageInfo.hasPreviousPage}
        hasNext={pageInfo.hasNextPage}
        onPrevious={() =>
          setPagination({
            take: -itemsPerPage,
            cursor: pageInfo.startCursor,
          })
        }
        onNext={() =>
          setPagination({
            take: itemsPerPage,
            cursor: pageInfo.endCursor,
          })
        }
      />
    </div>
  );
}
