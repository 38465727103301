import styles from "./FeaturesTable.module.scss";
import Table, { ITable } from "src/components/Table/Table";

interface IFeaturesTable extends ITable {
  className?: string;
}

export function FeaturesTable({ rows, headings, className }: IFeaturesTable) {
  return (
    <div className={className}>
      <h2 className={styles.tableTitle}>
        Here is what you get in different plans
      </h2>
      <div className={styles.tableWrapper}>
        <Table
          headings={headings}
          rows={rows}
          className={styles.table}
          rounded
        />
      </div>
    </div>
  );
}
