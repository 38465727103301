import { Pagination as IPagination } from "src/graphql/generated/schema";
import styles from "./CampaignPagination.module.scss";
import { Pagination } from "src/components/Pagination/Pagination";
import classNames from "classnames";
import { campaignItemsPerPage } from "../misc/campaignItemsPerPage";

const defaultPageInfo: IPagination = {
  hasNextPage: false,
  hasPreviousPage: false,
  itemsPerPage: 0,
  totalItems: 0,
  endCursor: null,
  startCursor: null,
};

interface ICampaignPagination {
  setPagination?: ({ limit, cursor }) => void;
  pageInfo: IPagination | null | undefined;
  noPadding?: boolean;
  borderLess?: boolean;
}

export function CampaignPagination({
  pageInfo: _pageInfo,
  noPadding = false,
  setPagination,
  borderLess,
}: ICampaignPagination) {
  const pageInfo = _pageInfo || defaultPageInfo;

  const hasNext = pageInfo.hasNextPage;
  const hasPrevious = pageInfo.hasPreviousPage;
  const nextCursor = pageInfo.endCursor;
  const previousCursor = pageInfo.startCursor;
  const label = `Showing result ${pageInfo.itemsPerPage} of ${pageInfo.totalItems}`;

  return (
    <div
      className={classNames(styles.paginationWrapper, {
        [styles.noPadding]: noPadding,
        [styles.padding]: !noPadding,
      })}
    >
      <Pagination
        hasNext={hasNext}
        borderLess={borderLess}
        hasPrevious={hasPrevious}
        onPrevious={() => {
          setPagination({
            cursor: previousCursor,
            limit: -campaignItemsPerPage,
          });
        }}
        label={label}
        onNext={() => {
          setPagination({
            cursor: nextCursor,
            limit: campaignItemsPerPage,
          });
        }}
      />
    </div>
  );
}
