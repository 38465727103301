import { CampaignInput } from "../misc/createCampaignTypes";
import { CampaignCreateForm } from "./CampaignCreateForm";
import * as Yup from "yup";
import { SelectAudienceTypeForm } from "./SelectAudienceTypeForm";

interface ISelectAudienceType {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => unknown;
}

export function SelectAudienceType({
  campaignInput,
  campaignPresetUpsert,
  handleBack,
}: ISelectAudienceType) {
  return (
    <CampaignCreateForm
      schema={selectAudienceTypeSchema}
      campaignInput={campaignInput}
      campaignPresetUpsert={campaignPresetUpsert}
    >
      <SelectAudienceTypeForm handleBack={handleBack} />
    </CampaignCreateForm>
  );
}

const selectAudienceTypeSchema = Yup.object({
  // Activities: Yup.array(
  //   Yup.object({
  audienceType: Yup.string().required(
    "Please select an audience type to continue"
  ),
  //   })
  // ),
});
