import { Button, IButton } from "src/components/Button/Button";
import styles from "./IntegrationBanner.module.scss";
import classNames from "classnames";

interface Props {
  title: React.ReactNode;
  subtitle: string | React.ReactNode;
  background?: string;
  borderColor?: string;
  action?: {
    label: string;
    link?: string;
    onClick?: () => void;
  };
  children?: React.ReactNode;
  className?: string;
  image?: string;
}

export function IntegrationBanner({
  title,
  subtitle,
  action,
  background,
  borderColor,
  children,
  className,
  image,
}: Props) {
  return (
    <div
      style={{
        ...(background && { background }),
        ...(borderColor && { borderColor }),
      }}
      className={classNames(styles.banner, className)}
    >
      <div className={styles.bannerContent}>
        <h2 className={styles.bannerTitle}>{title}</h2>
        <p className={styles.bannerDescription}>{subtitle}</p>
        {action && (
          <div className={styles.actionWrapper}>
            <Button to={action?.link} onClick={action?.onClick} size="small">
              {action.label}
            </Button>
          </div>
        )}
        {children}
      </div>
      {image && (
        <div className={styles.imageWrapper}>
          <img src={image} alt="image" className={styles.image} />
        </div>
      )}
    </div>
  );
}
