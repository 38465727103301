import { Tabs } from "src/components/Tabs/Tabs";
import { Button } from "../../../components/Button/Button";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import {
  AdTemplatesFilters,
  AdTemplatesListingTableLoader,
} from "../components/AdTemplatesListingTable/AdTemplatesListingTableLoader";
import { useState } from "react";
import { MessageListingTableLoader } from "../components/MessageListingTableLoader";
import styles from "./CreativesListingPage.module.scss";
import SortByDropdown from "../../global/components/SortByDropdown";
import sortOptions from "../misc/assetsSortBy";
import DateFilterDropdown from "../../global/components/DateFilterDropdown";
import { DateFilter } from "../../global/misc/dateFilterUtils";
import { SortByInput } from "src/graphql/generated/schema";
import { Searchbar } from "src/components/Searchbar/Searchbar";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ReactComponent as PersonAdd } from "@material-symbols/svg-600/rounded/person_add.svg";

const tabOptions = [
  {
    label: "Ad Creatives",
    value: "AD_LIBRARY",
  },
  {
    label: "Message Creatives",
    value: "MESSAGE_LIBRARY",
  },
  // {
  //   label: "Email Creatives",
  //   value: "EMAIL_LIBRARY",
  // },
];

export function CreativesListingPage() {
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);

  const [filters, setFilters] = useState<AdTemplatesFilters>({
    query: "",
    createdAt: "ALL_TIME",
  });

  const [sortBy, setSortBy] = useState<SortByInput>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  return (
    <div>
      <PageHeader
        title="Creatives"
        actions={
          <>
            <Searchbar
              value={filters.query}
              onChange={(i) =>
                setFilters({
                  ...filters,
                  query: i,
                })
              }
              input={{
                placeholder: "Search creatives",
              }}
            />
            <SortByDropdown
              sortOptions={sortOptions}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
            <DateFilterDropdown
              setDateFilter={(i: DateFilter) => {
                setFilters({
                  ...filters,
                  createdAt: i,
                });
              }}
              dateFilter={filters.createdAt}
            />
            <ButtonV2
              leftIcon={PersonAdd}
              size="small"
              to={
                selectedTab === "AD_LIBRARY"
                  ? "/library/ad-library/create"
                  : "/library/message-library/create"
              }
            >
              Create New Creative
            </ButtonV2>
          </>
        }
      />
      <div className={styles.content}>
        <Tabs
          tabs={tabOptions}
          onTabChange={setSelectedTab}
          selectedTab={selectedTab}
        />
        {selectedTab === "AD_LIBRARY" && (
          <AdTemplatesListingTableLoader
            filters={filters}
            setFilters={setFilters}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        )}
        {selectedTab === "MESSAGE_LIBRARY" && (
          <MessageListingTableLoader
            filters={filters}
            setFilters={setFilters}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        )}
        {/* selectedTab === "EMAIL_LIBRARY" && (
          <AdTemplatesListingTableLoader
            filters={filters}
            setFilters={setFilters}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        ) */}
      </div>
    </div>
  );
}
