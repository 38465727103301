import classNames from "classnames";
import styles from "./TabsV2.module.scss";

export interface ITabsV2 {
  tabs: { icon: React.ReactNode; label: React.ReactNode; value: string }[];
  selectedTab: string;
  onTabChange: (i: any) => void;
}

export function TabsV2({ tabs, selectedTab, onTabChange }: ITabsV2) {
  return (
    <div className={styles.tab}>
      {tabs.map((tab, index) => (
        <button
          onClick={() => {
            onTabChange(tab.value);
          }}
          className={classNames(styles.tabItem, {
            [styles.activeTabItem]: selectedTab === tab.value,
          })}
          type="button"
        >
          {tab.icon}
          <span className={styles.tabContent}>{tab.label}</span>
        </button>
      ))}
    </div>
  );
}
