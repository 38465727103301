import styles from "./ExistingPostThumbnail.module.scss";
import { MetaMedia } from "src/graphql/generated/schema";
import { ReactComponent as LikeIcon } from "@material-design-icons/svg/outlined/favorite_border.svg";
import { ReactComponent as TooltipIcon } from "@material-symbols/svg-400/rounded/tooltip.svg";

export function ExistingPostThumbnail({ post }: { post: MetaMedia }) {
  return (
    <div className={styles.postThumbnail}>
      {post.type !== "VIDEO" ? (
        <img alt={post.caption} src={post.url} className={styles.postImage} />
      ) : (
        <video autoPlay muted={true} loop={true} className={styles.postImage}>
          <source src={post.url} />
        </video>
      )}
      <div className={styles.postInfo}>
        <h4 className={styles.caption}>{post.caption}</h4>

        <span className={styles.mediaEngagement}>
          <span className={styles.likeCount}>
            <LikeIcon width={13} height={14} />
            {post.likeCount}
          </span>
          <span className={styles.commentCount}>
            <TooltipIcon width={13} height={14} />
            {post.commentsCount}
          </span>
        </span>
      </div>
    </div>
  );
}
