import { SaveFormState } from "src/modules/global/components/SaveFormState";
import { CampaignInput } from "../misc/createCampaignTypes";
import { useCampaignPresetUpsert } from "../functions/useCampaignPresetUpsert";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

export function SaveCampaignWizardFormState() {
  const [qs] = useSearchParams();
  const presetId = qs.get("presetId");

  const campaignPresetUpsert = useCampaignPresetUpsert({ presetId });

  async function handleSave(values: CampaignInput) {
    try {
      await campaignPresetUpsert(values);
    } catch (e) {
      toast.error("Failed to auto save");
      console.log({ e });
    }
  }

  return <SaveFormState onSave={handleSave} />;
}
