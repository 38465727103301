import { useFormikContext } from "formik";
import { useMetaProductCataloguesLazyQuery } from "../../../../graphql/generated/schema";
import styles from "./AdTemplateCatalogueSelector.module.scss";
import { useEffect } from "react";
import Select from "../../../../components/Select/Select";
import { MetaCatalogueProductSetSelector } from "../../../global/components/MetaCatalogueProductSetSelector";
import { FormikError } from "../../../../components/FormikError/FormikError";
import { FormValues } from "../../misc/adTemplateCreateTypes";
import { AdCatalogueSelector } from "src/modules/global/components/AdCatalogueSelector";

export function AdTemplateCatalogueSelector() {
  const { values, setFieldValue, setFieldTouched, errors } =
    useFormikContext<FormValues>();

  return (
    <div className={styles.adCatalogueList}>
      <div>
        <AdCatalogueSelector
          adAccountId={values.adAccountId}
          placeholder="Select a Catalogue"
          emptyState={{
            to: "/settings/integrations/meta",
            title: "No Catalogue found",
            description: "Please enable a catalogue to proceed",
          }}
          value={values.catalogueId}
          onChange={(catalogue) => {
            setFieldValue("catalogueId", catalogue.id);
            setFieldValue("metaCatalogueId", catalogue.metaCatalogueId);
            setTimeout(() => {
              setFieldTouched("catalogueId", true);
            }, 100);
          }}
        />

        <FormikError formikError={errors} fieldName={"catalogueId"} />
      </div>
      <div>
        <MetaCatalogueProductSetSelector
          catalogueId={values.catalogueId}
          value={values.MetaProductSetId}
          onChange={(productSet) => {
            setFieldValue("MetaProductSetId", productSet.id);
            setTimeout(() => {
              setFieldTouched("MetaProductSetId", true);
            }, 100);
          }}
        />
        <FormikError formikError={errors} fieldName={"MetaProductSetId"} />
      </div>
    </div>
  );
}
