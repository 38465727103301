import Modal from "src/components/Modal/Modal";
import styles from "./ReviewAdsPreviewModal.module.scss";
import { Button } from "src/components/Button/Button";
import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Asset } from "src/components/Smartphone/Asset";
import { Smartphone } from "src/components/Smartphone/Smartphone";
import classNames from "classnames";
import { ActivityInput, AdInput } from "../misc/createCampaignTypes";
import {
  useMetaCatalogueProductsQuery,
  useMetaMediaByIdQuery,
} from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { generateLineItemsFromCatalogueProducts } from "../../global/functions/generateLineItemsFromCatalogueProducts";
import { generateLineItemsFromExistingPost } from "../../global/functions/generateLineItemsFromExistingPost";

interface IReviewAdsPreviewModal {
  isOpen: boolean;
  onClose: () => void;
  activity: ActivityInput;
  defaultSelectedAd?: number;
}

// TODO: extract logic to show formatLineItems
export function ReviewAdsPreviewModal({
  isOpen,
  onClose,
  activity,
  defaultSelectedAd = 0,
}: IReviewAdsPreviewModal) {
  console.log({ defaultSelectedAd });
  const [selectedAd, setSelectedAd] = useState(defaultSelectedAd);
  const ads = activity.Ads;
  const accountName = activity.adAccountName;
  const { data: catalogueProductsData, loading } =
    useMetaCatalogueProductsQuery({
      variables: {
        metaCatalogueId: activity?.productCatalogueId,
      },
    });
  const { data: metaMediaByIdData } = useMetaMediaByIdQuery({
    variables: {
      id: ads?.[selectedAd]?.existingPostId,
    },
  });

  // in case default selectedAd is updated from outside
  useEffect(() => {
    setSelectedAd(defaultSelectedAd);
  }, [defaultSelectedAd]);

  if (activity.metaPurpose === "SALES_CATALOG" && loading) {
    return <Spinner height="screen" />;
  }

  const catalogueProducts =
    catalogueProductsData && catalogueProductsData.metaCatalogueProducts
      ? catalogueProductsData.metaCatalogueProducts
      : [];

  const metaMedia =
    metaMediaByIdData && metaMediaByIdData.metaMediaById
      ? metaMediaByIdData.metaMediaById
      : null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Ads Preview"
      subtitle="Here are the previews of all your Ads used in this Activity."
      footer={{
        hideBorder: false,
        footerActions: (
          <Button
            onClick={onClose}
            size="micro"
            style="outline"
            color="subdued"
          >
            Close
          </Button>
        ),
        footerContent: "",
      }}
      maxWidth="77.25rem"
      noContainer
    >
      <div className={styles.modalContent}>
        <div className={styles.adsWrapper}>
          <p className={styles.adsHeading}>Ads</p>
          <RadioGroup className={styles.adsContainer} onChange={setSelectedAd}>
            {ads.map((ad, idx: number) =>
              ad.staticAdType === "CUSTOM_CREATIVE" ? (
                <StaticAdPreviewRadioGroupOption
                  selectedAd={selectedAd}
                  idx={idx}
                  ad={ad}
                />
              ) : ad.staticAdType === "CATALOG" ? (
                <CatalogAdPreviewRadioGroupOption
                  selectedAd={selectedAd}
                  ad={ad}
                  idx={idx}
                />
              ) : (
                <ExistingPostAdPreviewRadioGroupOption
                  selectedAd={selectedAd}
                  ad={ad}
                  idx={idx}
                />
              ),
            )}
          </RadioGroup>
        </div>
        {/*  */}
        <PreviewSection
          accountName={accountName}
          ad={ads[selectedAd]}
          catalogueProducts={catalogueProducts}
          metaMedia={metaMedia}
        />
      </div>
    </Modal>
  );
}

function PreviewSection({
  ad,
  accountName,
  metaMedia,
  catalogueProducts,
}: {
  ad: AdInput;
  accountName: string;
  metaMedia: any;
  catalogueProducts: any;
}) {
  // formatLineItems
  const lineItems =
    ad.staticAdType === "CATALOG"
      ? generateLineItemsFromCatalogueProducts(catalogueProducts)
      : ad.staticAdType === "EXISTING_POST"
      ? generateLineItemsFromExistingPost(metaMedia, ad?.existingPostCTALink)
      : ad?.LineItems || [];
  return (
    <div className={styles.phoneContainer}>
      <Smartphone
        type="facebook"
        variant="feed"
        preview={lineItems}
        callToActionLabel={ad?.callToActionLabel}
        primaryText={ad?.primaryText}
        accountName={accountName}
      />
      <Smartphone
        type="instagram"
        variant="feed"
        preview={lineItems}
        callToActionLabel={ad?.callToActionLabel}
        primaryText={ad?.primaryText}
        accountName={accountName}
      />
      <Smartphone
        type="instagram"
        variant="stories"
        preview={lineItems}
        callToActionLabel={ad?.callToActionLabel}
        primaryText={ad?.primaryText}
        accountName={accountName}
      />
    </div>
  );
}

function ExistingPostAdPreviewRadioGroupOption({
  ad,
  idx,
  selectedAd,
}: {
  ad: AdInput;
  idx: any;
  selectedAd: any;
}) {
  return (
    <RadioGroup.Option value={idx} key={idx}>
      <div
        className={classNames(styles.thumbnailWrapper, {
          [styles.highlightAsset]: selectedAd === idx,
        })}
      >
        <Asset
          type={ad?.existingPost?.type || "IMAGE"}
          preview={ad?.existingPost?.url}
          name={ad?.existingPost?.caption}
          className={styles.thumbnailAsset}
          loop={false}
        />
      </div>
      <RadioGroup.Label
        className={classNames(styles.adLabel, {
          [styles.highlightLabel]: selectedAd === idx,
        })}
      >
        {ad?.existingPost?.caption || "Existing Post"}
      </RadioGroup.Label>
    </RadioGroup.Option>
  );
}

function CatalogAdPreviewRadioGroupOption({
  ad,
  idx,
  selectedAd,
}: {
  ad: AdInput;
  idx: any;
  selectedAd: any;
}) {
  return (
    <RadioGroup.Option value={idx} key={idx}>
      <div
        className={classNames(styles.thumbnailWrapper, {
          [styles.highlightAsset]: selectedAd === idx,
        })}
      >
        <Asset
          type={ad.LineItems[0]?.PostAsset?.contentType || "IMAGE"}
          preview={ad.LineItems[0]?.PostAsset?.uri}
          name={ad.LineItems[0]?.PostAsset?.name}
          className={styles.thumbnailAsset}
          loop={false}
        />
      </div>
      <RadioGroup.Label
        className={classNames(styles.adLabel, {
          [styles.highlightLabel]: selectedAd === idx,
        })}
      >
        {ad?.primaryText || "Catalog Ad"}
      </RadioGroup.Label>
    </RadioGroup.Option>
  );
}

function StaticAdPreviewRadioGroupOption({ ad, idx, selectedAd }) {
  return (
    <RadioGroup.Option value={idx} key={idx}>
      <div
        className={classNames(styles.thumbnailWrapper, {
          [styles.highlightAsset]: selectedAd === idx,
        })}
      >
        <Asset
          type={ad.LineItems[0]?.PostAsset?.contentType || "IMAGE"}
          preview={ad.LineItems[0]?.PostAsset?.uri}
          name={ad.LineItems[0]?.PostAsset?.name}
          className={styles.thumbnailAsset}
          loop={false}
        />
      </div>
      <RadioGroup.Label
        className={classNames(styles.adLabel, {
          [styles.highlightLabel]: selectedAd === idx,
        })}
      >
        {ad.LineItems[0].headline}
      </RadioGroup.Label>
    </RadioGroup.Option>
  );
}
