import SimpleTable from "src/components/SimpleTable/SimpleTable";
import styles from "./CustomerEngageActionHistory.module.scss";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { Spinner } from "src/components/Spinner/Spinner";
import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";
import format from "date-fns/format";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as CampaignIcon } from "@material-design-icons/svg/outlined/campaign.svg";
import { ReactComponent as ActivityIcon } from "@material-design-icons/svg/outlined/ads_click.svg";

export function CustomerEngageActionHistory({
  customerId,
  dateFrom,
  dateTo,
}: {
  dateFrom: string;
  dateTo: string;
  customerId: string;
}) {
  const { userToken } = useContext(AuthContext);
  const { data, loading, error } = useTinybirdQuery(
    "customer_engage_events",
    userToken,
    {
      customerId,
      dateFrom,
      dateTo,
    },
  );

  if (loading) {
    return <Spinner height={400} />;
  }

  if (error) {
    return <GraphLoadingError error={error} height={400} />;
  }

  const headings = [
    "Event Name",
    "Time",
    <span className={styles.timeTitleActions}>Actions</span>,
  ];

  const rows = data.map((event) => [
    <span className={classNames(styles.column, styles.primaryColumn)}>
      {snakeCaseToTitleCase(event.action)}
    </span>,
    <span className={classNames(styles.column)}>
      {format(new Date(event.timestamp), "dd MMM, yyyy hh:mm aaa")}
    </span>,
    <span className={styles.actionColumn}>
      <Link
        to={`/campaigns/${event.campaignId}/activities/${event.activityId}`}
      >
        <ActivityIcon width={20} height={20} className={styles.actionIcon} />
      </Link>
      <Link to={`/campaigns/${event.campaignId}`}>
        <CampaignIcon width={26} height={26} className={styles.actionIcon} />
      </Link>
    </span>,
  ]);

  return <SimpleTable headings={headings} rows={rows} showHeadings={true} />;
}
