import { Button } from "src/components/Button/Button";
import { ReactComponent as CancelIcon } from "@material-symbols/svg-600/rounded/close.svg";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { ActivityGetStarted } from "../components/ActivityGetStarted";
import { ActivityObjectiveAndOthers } from "../components/ActivityObjectiveAndOthers";
import { StepWizard } from "src/modules/global/components/StepWizard";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { ActivityMoreOptions } from "../components/ActivityMoreOptions";
import {
  useActivityCreateMutation,
  useActivityPublishMutation,
  useOutreachCreateMutation,
  useOutreachPublishMutation,
} from "src/graphql/generated/schema";
import {
  transformActivity,
  transformOutreach,
} from "../functions/transformCampaignInput";
import { useNavigate } from "react-router-dom";
import ALL_CAMPAIGNS from "src/graphql/allCampaigns";
import styles from "./CampaignWorkshopPage.module.scss";
import { useRef, useState } from "react";
import Alert from "src/components/Alert/Alert";
import { AudienceOrSegmentSelector } from "../components/AudienceOrSegmentSelector";
import { CreativeSelector } from "../components/CreativeSelector";

export function CampaignWorkshopPage() {
  const [activityCreateFn] = useActivityCreateMutation();
  const [outreachCreateFn] = useOutreachCreateMutation();

  const [publishActivity] = useActivityPublishMutation();
  const [publishOutreach] = useOutreachPublishMutation();
  const navigate = useNavigate();
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const getStartedRef = useRef<HTMLDivElement>();
  const objectiveAndOthersRef = useRef<HTMLDivElement>();
  const audienceSelectorRef = useRef<HTMLDivElement>();
  const adSelectorRef = useRef<HTMLDivElement>();
  const moreOptionsRef = useRef<HTMLDivElement>();

  async function handleCampaignCreate(values: ActivityInputV2) {
    if (values.platform === "META") {
      const transformedValues = transformActivity(values);

      const { data } = await activityCreateFn({
        variables: {
          input: transformedValues,
        },
      });

      const { data: publishDataPayload } = await publishActivity({
        variables: {
          activityId: data.activityCreate.id,
        },
        refetchQueries: [
          {
            query: ALL_CAMPAIGNS,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                limit: 10,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      // if (publishDataPayload.campaignPublish.userError) {
      //   throw publishDataPayload.campaignPublish.userError;
      // }

      navigate(
        `/campaigns/${data.activityCreate.Campaign.id}/activities/${data.activityCreate.id}`,
      );
    } else if (values.platform === "WHATSAPP") {
      const transformedValues = transformOutreach(values);

      const { data } = await outreachCreateFn({
        variables: {
          input: transformedValues,
        },
      });

      console.log({ data });

      const { data: publishDataPayload } = await publishOutreach({
        variables: {
          outreachId: data.outreachCreate.id,
        },
        refetchQueries: [
          {
            query: ALL_CAMPAIGNS,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                limit: 10,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      // if (publishDataPayload.campaignPublish.userError) {
      //   throw publishDataPayload.campaignPublish.userError;
      // }

      navigate(`/campaigns/${data.outreachCreate.Campaign.id}`);
    }
  }

  function discardChanges() {
    setIsDiscardAlertShowing(false);
    navigate("/campaigns");
  }

  function stay() {
    setIsDiscardAlertShowing(false);
  }

  return (
    <div>
      <PageHeader
        actions={
          <Button
            onClick={() => {
              setIsDiscardAlertShowing(true);
            }}
            style="outline"
            size="micro"
            color="subdued"
          >
            <span className={styles.cancelButtonContent}>
              <CancelIcon fill="var(--color-disabled)" width={18} height={18} />
              Cancel
            </span>
          </Button>
        }
        title="Activity Builder"
      />

      <Alert
        isOpen={isDiscardAlertShowing}
        onClose={() => setIsDiscardAlertShowing(false)}
        secondaryAction={{
          children: "Reset Progress",
          color: "primary",
          onClick: discardChanges,
        }}
        primaryAction={{
          children: "Cancel",
          color: "subdued",
          style: "outline",
          onClick: stay,
        }}
        title="All the progress will be reset"
      >
        Are you sure? This action won't be possible to revert back. All the
        progress will lost. Click “Reset Progress” to continue.
      </Alert>
      {/*
        <WorkshopBanner
          height={148}
          backgroundGradient="linear-gradient(90deg, rgba(203, 225, 251, 0.00) 13.31%, #CBE7FB 99.68%)"
          heading="Streamlined marketing activity creation for maximum impact"
          subheading="Effortlessly create Standard Ads, Dynamic Ads, and Existing Post Ads with our user-friendly interface. Enhance your marketing campaigns with impactful, clutter-free ads and engage your audience like never before!"
          image={{
            src: bannerImage,
            height: 148,
            width: 303,
            alt: "globe",
          }}
        />
      */}
      <StepWizard
        onResetField={(fieldKey, value, data) => {
          return data;
        }}
        initialData={
          {
            activityName: "",
            platform: "META",
            adPlacementType: "SMART",
          } as ActivityInputV2
        }
        onStepComplete={async (stepIndex, data) => {
          if (stepIndex === 0) {
            // getStartedRef.current?.scrollIntoView({ behavior: "smooth" });
          } else if (stepIndex === 1) {
            objectiveAndOthersRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          } else if (stepIndex === 2) {
            audienceSelectorRef.current?.scrollIntoView({ behavior: "smooth" });
          } else if (stepIndex === 3) {
            adSelectorRef.current?.scrollIntoView({ behavior: "smooth" });
          } else if (stepIndex === 4) {
            moreOptionsRef.current?.scrollIntoView({ behavior: "smooth" });
          } else if (stepIndex === 5) {
            await handleCampaignCreate(data);
          }
        }}
      >
        <div className={styles.steps}>
          <ActivityGetStarted sectionRef={getStartedRef} />
          <ActivityObjectiveAndOthers sectionRef={objectiveAndOthersRef} />
          <AudienceOrSegmentSelector sectionRef={audienceSelectorRef} />
          <CreativeSelector sectionRef={adSelectorRef} />
          <ActivityMoreOptions sectionRef={moreOptionsRef} />
        </div>
      </StepWizard>
    </div>
  );
}
