import { FormikErrors } from "formik";
import { LineItems } from "src/lib/types";
import { FormValues } from "./adTemplateCreateTypes";

export function getErrorAssetType(errors: FormikErrors<FormValues>, index = 0) {
  const errorAssetType =
    errors &&
    errors?.LineItems &&
    typeof errors?.LineItems?.[index] === "object" &&
    (errors?.LineItems?.[index] as FormikErrors<LineItems>)?.StoryAsset
      ? "StoryAsset"
      : "PostAsset";

  return errorAssetType;
}
