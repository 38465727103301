import { getIn, useFormikContext } from "formik";
import Card from "src/components/Card/Card";
import { CampaignInput } from "../misc/createCampaignTypes";
import styles from "./SelectAudienceTypeForm.module.scss";
import { Button } from "src/components/Button/Button";
import { RadioGroup } from "@headlessui/react";
import { Label } from "src/components/Label/Label";
import CollectionItem from "src/components/CollectionItem/CollectionItem";
import facebookStandardActivity from "../assets/facebookStandardActivity.svg";
import facebookDynamicActivity from "../assets/facebookDynamicActivity.svg";
import { useWizardContext } from "src/modules/global/misc/WizardContext2";

const options = [
  {
    title: "Standard Audiences",
    id: "STANDARD_AUDIENCE" as const,
    description:
      "Find new customers or retarget audiences that have interacted with your business",
    image: facebookStandardActivity,
  },
  {
    title: "Catalogue Audiences",
    id: "RETARGETED_AUDIENCE" as const,
    description:
      "Target customers who have interacted with your Meta product catalogue",
    image: facebookDynamicActivity,
  },
];

interface ISelectAudienceTypeForm {
  handleBack: (i: CampaignInput) => unknown;
}

export function SelectAudienceTypeForm({
  handleBack,
}: ISelectAudienceTypeForm) {
  const { currentNode, isCurrentNodeCompleted, setResetAlert } =
    useWizardContext();
  const { values, isSubmitting, handleBlur, errors, touched, setFieldValue } =
    useFormikContext<CampaignInput>();
  const { Activities, selectedActivityIndex } = values;

  const name = `Activities[${selectedActivityIndex}].audienceType`;

  const error = getIn(errors, name);
  // if there is no touched it means the value doesn't exits
  const isTouched = getIn(touched, name) ?? true;

  return (
    <Card disabled={currentNode.id !== "selectAudienceType"}>
      <RadioGroup
        value={Activities[selectedActivityIndex].audienceType}
        name={name}
        className={styles.catalogueRadio}
        onBlur={handleBlur}
        onChange={(value) => {
          if (isCurrentNodeCompleted) {
            setResetAlert({
              data: values,
              fieldKey: name,
              fieldValue: value,
              isShowing: true,
            });
            return;
          }
          setFieldValue(name, value);
        }}
      >
        <Label label="Select Audience Type" htmlFor={name}></Label>

        <div className={styles.options}>
          {options.map((option, index) => (
            <CollectionItem
              size="small"
              value={option.id}
              image={option.image}
              title={option.title}
              subtitle={option.description}
            />
          ))}
        </div>
        {isTouched && error && <p className={styles.error}>{error}</p>}
      </RadioGroup>

      <div className={styles.actions}>
        <Button loading={isSubmitting} type="submit">
          Continue
        </Button>
        <Button
          onClick={() => {
            handleBack(values);
          }}
          style="outline"
          color="subdued"
        >
          Back
        </Button>
      </div>
    </Card>
  );
}
