import { ActivityInput } from "../misc/createCampaignTypes";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Label } from "src/components/Label/Label";
import Select from "src/components/Select/Select";
import styles from "./EmailObjectDetails.module.scss";
import { useEmailIntegrationQuery } from "src/graphql/generated/schema";

interface IEmailObjectDetails {
  selectedActivity: ActivityInput;
  selectedActivityKey: string;
  sendersOptions: {
    label: string;
    value: string;
    name: string;
  }[];
}

export function EmailObjectDetails({
  selectedActivity,
  selectedActivityKey,
  sendersOptions,
}: IEmailObjectDetails) {
  return (
    <div className={styles.campaign}>
      <div className={styles.section}>
        <span className={styles.sectionTitle}>Campaign Details</span>
        <hr />
      </div>
      <div className={styles.campaignDetails}>
        <Label label="Send From*">
          <Select
            options={sendersOptions}
            value={selectedActivity?.EmailObject?.senderEmailId}
            onChange={() => {}}
          />
        </Label>
        <Label label="Subject*">
          <InputFormik name={`${selectedActivityKey}.EmailObject.subject`} />
        </Label>
        <Label
          label={
            <div className={styles.optionalLabel}>
              <span>Add Sender Name</span>
              <span>{"(optional)"}</span>
            </div>
          }
        >
          <InputFormik name={`${selectedActivityKey}.EmailObject.senderName`} />
        </Label>
        <Label
          label={
            <div className={styles.optionalLabel}>
              <span>Preview Text</span>
              <span>{"(optional)"}</span>
            </div>
          }
        >
          <InputFormik
            name={`${selectedActivityKey}.EmailObject.previewText`}
          />
        </Label>
      </div>
    </div>
  );
}
