import { ReactComponent as ArrowDown } from "@material-design-icons/svg/outlined/arrow_downward.svg";
import styles from "./TemplateListingCommonTable.module.scss";
import BriefItem from "src/components/BriefItem/BriefItem";
import { TemplateListingAction } from "./TemplateListingAction";
import { FullPageTable } from "src/modules/global/components/FullPageTable";
import { Pagination } from "src/components/Pagination/Pagination";

const HEADINGS = [
  "Templates",
  "Type",
  <span className={styles.heading}>
    Use Count <ArrowDown className={styles.arrowIco} />
  </span>,
  "Status",
  "Action",
];

const ROWS = [
  [
    <BriefItem
      title={"Sales_Whatsapp_Template"}
      subtitle={"Created on Just Now"}
    />,
    "SMS",
    "",
    "inReview",
    <TemplateListingAction />,
  ],
];

export function TemplateListingCommonTable() {
  return (
    <div>
      <FullPageTable
        headings={HEADINGS}
        rows={ROWS}
        tableClass={styles.table}
        wrapperClass={styles.wrapper}
      />
      <div className={styles.paginationWrapper}>
        <Pagination
          hasPrevious={false}
          hasNext={false}
          onPrevious={function (): void {
            throw new Error("Function not implemented.");
          }}
          onNext={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </div>
    </div>
  );
}
