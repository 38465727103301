import { Button } from "src/components/Button/Button";
import styles from "./SegmentEmpty.module.scss";
import emptySegments from "../assets/emptySegment.png";

export function SegmentEmpty({ getStarted }: { getStarted: () => void }) {
  return (
    <div className={styles.page}>
      <div className={styles.card}>
        <img
          src={emptySegments}
          alt="segments_empty"
          width={300}
          height={166}
        />
        <div>
          <p className={styles.title}>Manage your customers with segments</p>
          <p className={styles.subtitle}>
            Segments are the best way to manage and target you customers. create
            segment on the basis of certain attributes
          </p>
        </div>
        <Button onClick={getStarted} color="normal" style="outline">
          Create New Segment
        </Button>
      </div>
    </div>
  );
}
