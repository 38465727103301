import { Button } from "src/components/Button/Button";
import styles from "./AnalyticsWaitlist.module.scss";
import WaitlistAnalytics1 from "../assets/waitlist-analytics-1.png";
import WaitlistAnalytics2 from "../assets/waitlist-analytics-2.png";
import WaitlistAnalytics3 from "../assets/waitlist-analytics-3.png";
import WaitlistAnalytics4 from "../assets/waitlist-analytics-4.png";
import WaitlistAnalytics5 from "../assets/waitlist-analytics-5.png";
import WaitlistAnalytics6 from "../assets/waitlist-analytics-6.png";
import WaitlistAnalytics7 from "../assets/waitlist-analytics-7.png";
import WaitlistAnalytics8 from "../assets/waitlist-analytics-8.png";
import WaitlistAnalytics9 from "../assets/waitlist-analytics-9.png";
import WaitlistAnalytics10 from "../assets/waitlist-analytics-10.png";

import RunningTrainCarousel from "../components/RunningTrainCarousel";
import { useState } from "react";
import toast from "react-hot-toast";
import { ReactComponent as MagicWand } from "../assets/magicWand.svg";

const slides = [
  { src: WaitlistAnalytics1, height: Math.ceil(321 / 3), width: 220 },
  { src: WaitlistAnalytics2, height: Math.ceil(396 / 3), width: 220 },
  { src: WaitlistAnalytics3, height: Math.ceil(469 / 3), width: 220 },
  { src: WaitlistAnalytics4, height: Math.ceil(321 / 3), width: 220 },
  { src: WaitlistAnalytics5, height: Math.ceil(468 / 3), width: 220 },
  { src: WaitlistAnalytics6, height: Math.ceil(321 / 3), width: 220 },
  { src: WaitlistAnalytics7, height: Math.ceil(395 / 3), width: 220 },
  { src: WaitlistAnalytics8, height: Math.ceil(332 / 3), width: 220 },
  { src: WaitlistAnalytics9, height: Math.ceil(569 / 3), width: 220 },
  { src: WaitlistAnalytics10, height: Math.ceil(568 / 3), width: 220 },
];

export default function AnalyticsWaitlistPage() {
  const [inWaitlist, setInWaitlist] = useState(
    Boolean(localStorage.getItem("in-waitlist")) ?? false
  );
  return (
    <div className={styles.container}>
      <div>
        <span className={styles.iconWithLabel}>
          <MagicWand />
          <span className={styles.comingSoonLabel}>
            Announcing our new feature
          </span>
        </span>
        <h4 className={styles.title}>Harness Business Intelligence</h4>
        <p className={styles.description}>
          Leverage the power of advanced analytics and insights to drive
          business decisions and marketing strategy
        </p>
        <Button
          title={inWaitlist ? "You are already in waitlist" : ""}
          disabled={inWaitlist}
          onClick={() => {
            toast.success("Successfully joined waitlist");
            setInWaitlist(true);
            localStorage.setItem("in-waitlist", "true");
          }}
        >
          Join Waitlist
        </Button>
      </div>
      <RunningTrainCarousel
        slides={slides}
        duplicateCount={10}
        // slideHeight={124}
        // slideWidth={220}
        gap={16}
      />
    </div>
  );
}
