import { Formik, Form } from "formik";
import { Button } from "../../../components/Button/Button";
import { Organization } from "../../../graphql/generated/schema";
import { ContactInformation } from "./ContactInformation";
import { GeneralInformation } from "./GeneralInformation";

export function InformationForm({
  organization,
  updateOrganization,
}: {
  organization: Organization;
  updateOrganization: (i: { name?: string }) => Promise<void>;
}) {
  return (
    <Formik
      initialValues={{
        name: organization.name,
        email: organization.Creator.email,
        address: organization.Address?.addressLine1,
        city: organization.Address?.city,
        state: organization.Address?.state,
        country: organization.Address?.country,
        countryCode: organization.Address?.countryCode,
        zip: organization.Address?.zip,
      }}
      onSubmit={async (values) => {
        await updateOrganization({ name: values.name });
      }}
    >
      {({ handleSubmit, dirty, isSubmitting }) => (
        <Form>
          <GeneralInformation />
          <ContactInformation />
          <Button
            onClick={handleSubmit}
            disabled={!dirty || isSubmitting}
            size="small"
            loading={isSubmitting}
          >
            Save Changes
          </Button>
        </Form>
      )}
    </Formik>
  );
}
