import { formatNumber } from "./formatNumber";

export function formatNumberOrDefault(
  number: number | string,
  defaultValue = "N/A",
  options?: {
    showDecimal: boolean;
  }
): string {
  if (number) {
    return formatNumber(number, options);
  } else {
    return defaultValue.toString();
  }
}
