import styles from "./FeedbackCard.module.scss";

export interface IFeedbackCard {
  image: string;
  title?: string;
  description: React.ReactNode;
}

export default function FeedbackCard(props: IFeedbackCard) {
  const { image, title, description } = props;

  return (
    <div className={styles.card}>
      <img src={image} alt="image" className={styles.image} />
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.description}>{description}</div>
    </div>
  );
}
