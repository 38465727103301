import OverviewBanner from "src/components/OverviewBanner/OverviewBanner";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./CustomerOverviewPage.module.scss";
import { OverviewTable } from "src/modules/global/components/OverviewTable";
import leftOverviewImage from "../assets/customerOverviewLeftImage.png";
import rightOverviewImage from "../assets/customerOverviewRightImage.png";
import {
  Segment,
  SegmentByIdsQuery,
  SegmentsQuery,
  useCustomersCountQuery,
  useIntegrationsStatusQuery,
  useSegmentByIdsLazyQuery,
  useSegmentsCountQuery,
  useSegmentsQuery,
} from "src/graphql/generated/schema";
import { SegmentTitleWithIcon } from "src/modules/global/components/SegmentTitleWithIcon";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { useContext, useEffect, useState } from "react";
import AuthContext from "src/stores/AuthContext";
import CodewordResourcesList from "src/modules/dashboard/components/CodewordResourcesList/CodewordResourcesList";
import StatCard, { IStatCard } from "src/components/StatCard/StatCard";
import { Spinner } from "src/components/Spinner/Spinner";
import { checkIntegrationStatus } from "src/modules/global/functions/checkIntegrationStatus";
import OverviewIntegrationCards from "src/modules/global/components/OverviewIntegrationCards";
import { PageLoader } from "src/modules/global/components/PageLoader";
import emptySegments from "../assets/emptySegments.png";

type StatCardKeys =
  | "totalSegments"
  | "totalCustomers"
  | "avgOrderValue"
  | "avgEngagementRate"
  | "optInCount";

const statCardPropByKeys: Record<StatCardKeys, Omit<IStatCard, "value">> = {
  totalSegments: {
    title: "Total Segments",
    subtitle: "Segments count in Macro",
    valueType: "NUMBER",
  },
  totalCustomers: {
    title: "Total Customers",
    subtitle: "Customers count in Macro",
    valueType: "NUMBER",
  },
  avgEngagementRate: {
    title: "Avg. Engagement rate",
    subtitle: "Engagement based on activities",
    valueType: "PERCENTAGE",
  },
  avgOrderValue: {
    title: "Avg. Order Value",
    subtitle: "Order value based on activities",
    valueType: "CURRENCY",
  },
  optInCount: {
    title: "Opt in Email",
    subtitle: "Opted with email consents",
    valueType: "NUMBER",
  },
};

export default function CustomerOverviewPage() {
  const { userToken } = useContext(AuthContext);
  const [stats, setStats] = useState<Record<StatCardKeys, number> | null>(null);
  const { data: integrationStatusData, loading: integrationStatusLoading } =
    useIntegrationsStatusQuery();

  const integrationStatus = checkIntegrationStatus(integrationStatusData);
  const { data: tinybirdTopSegmentsData } = useTinybirdQuery(
    "top_segments",
    userToken
  );

  const { data: tinybirdCustomerEngagementAggData } = useTinybirdQuery(
    "customer_engagement_aggregate",
    userToken,
    {
      actions: ["click_link", "send_email"] as any,
    }
  );

  const { data: tinybirdGrowAggData } = useTinybirdQuery(
    "grow_aggregate",
    userToken
  );

  const { data: segmentsData, loading: segmentsLoading } = useSegmentsQuery({
    variables: {
      filter: {
        take: 5,
      },
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
    },
  });

  const { data: segmentsCountData, loading: segmentsCountLoading } =
    useSegmentsCountQuery();

  const { data: customersCountData, loading: customersCountLoading } =
    useCustomersCountQuery();
  const [
    segmentByIdsFunc,
    { data: segmentByIdsData, loading: segmentByIdsLoading },
  ] = useSegmentByIdsLazyQuery();

  useEffect(() => {
    if (integrationStatusLoading) return;
    if (integrationStatus !== "ACTIVE") return;

    if (!tinybirdTopSegmentsData) return;
    if (tinybirdTopSegmentsData.length === 0) return;
    const getSegmentByIds = async (ids: string[]) => {
      await segmentByIdsFunc({
        variables: {
          ids: ids,
        },
      });
    };

    getSegmentByIds(tinybirdTopSegmentsData.map((v: any) => v.segmentId));
  }, [tinybirdTopSegmentsData, integrationStatusLoading, integrationStatus]);

  useEffect(() => {
    if (integrationStatusLoading) return;
    if (integrationStatus !== "ACTIVE") return;

    if (!segmentsCountData) return;
    if (!customersCountData) return;
    if (!tinybirdCustomerEngagementAggData) return;
    if (!tinybirdGrowAggData) return;

    const costPerPurchase =
      tinybirdGrowAggData.length > 0
        ? tinybirdGrowAggData[0].costPerPurchase
        : 0;
    const engagementCount =
      tinybirdCustomerEngagementAggData.length > 0
        ? tinybirdCustomerEngagementAggData[0].engagementCount
        : 0;
    const totalCustomersCount = customersCountData.customersCount;
    const formattedStats: typeof stats = {
      totalSegments: segmentsCountData.segmentsCount,
      totalCustomers: totalCustomersCount,
      avgEngagementRate:
        engagementCount && totalCustomersCount
          ? (engagementCount * 100) / totalCustomersCount
          : 0,
      avgOrderValue: costPerPurchase,
      // TODO: get customers that are opted in
      optInCount: totalCustomersCount,
    };
    setStats(formattedStats);
  }, [
    segmentsCountData,
    customersCountData,
    tinybirdCustomerEngagementAggData,
    tinybirdGrowAggData,
    integrationStatus,
    integrationStatusLoading,
  ]);

  if (integrationStatusLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <PageHeader title={"Customer Overview"} />
      <div className={styles.page}>
        <OverviewBanner
          title={"Tailor Campaign with Precision, Amplify Impact!"}
          description={
            "From defining your audience to choosing compelling creatives, every step is simplified for a seamless experience impact with precision targeting"
          }
          bgGradient="linear-gradient(180deg, #FFF 0%, rgba(204, 251, 243, 0.50) 53.91%, #FFF 100%)"
          link={
            integrationStatusData.integrationsStatus.shopify === "ACTIVE"
              ? "/contacts"
              : "/contacts/create"
          }
          leftImage={leftOverviewImage}
          rightImage={rightOverviewImage}
          action={{
            children:
              integrationStatusData.integrationsStatus.shopify === "ACTIVE"
                ? "View Contacts"
                : "Let's get started",
          }}
        />
        {integrationStatus !== "ACTIVE" ? (
          <OverviewIntegrationCards
            integrationStatus={integrationStatus}
            exclude="meta"
          />
        ) : (
          <CustomerOverviewContent
            stats={stats}
            segmentsData={segmentsData}
            segmentByIdsData={segmentByIdsData}
            tinybirdTopSegmentsData={tinybirdTopSegmentsData}
            segmentsCountLoading={segmentsCountLoading}
            customersCountLoading={customersCountLoading}
            segmentsLoading={segmentsLoading}
            segmentByIdsLoading={segmentByIdsLoading}
          />
        )}
        <div>
          <CodewordResourcesList />
        </div>
      </div>
    </div>
  );
}

function CustomerOverviewContent({
  stats,
  segmentsData,
  segmentByIdsData,
  tinybirdTopSegmentsData,
  segmentsLoading,
  segmentByIdsLoading,
  segmentsCountLoading,
  customersCountLoading,
}: {
  stats: Record<StatCardKeys, number> | null;
  tinybirdTopSegmentsData: any;
  segmentsData: SegmentsQuery;
  segmentByIdsData: SegmentByIdsQuery;
  segmentsLoading: boolean;
  segmentByIdsLoading: boolean;
  segmentsCountLoading: boolean;
  customersCountLoading: boolean;
}) {
  const segments =
    segmentsData && segmentsData.segments
      ? segmentsData.segments.edges.map((v) => v.node)
      : [];

  const topSegments =
    segmentByIdsData && segmentByIdsData.segmentByIds
      ? segmentByIdsData.segmentByIds
      : [];

  return (
    <>
      {" "}
      <div className={styles.actionCardsContainer}>
        {segmentsCountLoading || customersCountLoading ? (
          <Spinner height="auto" />
        ) : (
          stats != null && (
            <>
              <div className={styles.sectionHeader}>
                <div className={styles.sectionTitle}>Overview of Customers</div>
                <div className={styles.sectionSubtitle}>
                  Last update : 4 hours ago
                </div>
              </div>
              <div className={styles.statCards}>
                {Object.keys(stats).map((v) => (
                  <StatCard
                    title={statCardPropByKeys[v].title}
                    subtitle={statCardPropByKeys[v].subtitle}
                    value={stats[v] || 0}
                    valueType={statCardPropByKeys[v].valueType}
                    learnMoreText={statCardPropByKeys[v].learnMoreText}
                  />
                ))}
              </div>
            </>
          )
        )}
      </div>
      <OverviewTable
        title={"Recently added Segments"}
        loading={segmentsLoading}
        border={false}
        rows={segments.map((v) => [
          <SegmentTitleWithIcon segment={v} />,
          "",
          v?.customersCount,
        ])}
        headings={["Segments", "Type", "Customer Count"]}
        emptyState={{
          icon: emptySegments,
          title: "No Segments Found",
          action: {
            title: "Create Segment",
            link: "/segments?createSegment=true",
          },
        }}
      />
      {segments.length > 0 && (
        <OverviewTable
          title={"Top 10 segments"}
          loading={segmentByIdsLoading}
          border={false}
          rows={topSegments.map((v) => [
            <SegmentTitleWithIcon segment={v as Segment} />,
            "",
            v?.customersCount,
            tinybirdTopSegmentsData.find((t: any) => t.segmentId === v.id)
              .count,
          ])}
          headings={["Segments", "Type", "Customer Count", "Order Count"]}
        />
      )}
    </>
  );
}
