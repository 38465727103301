import { Button } from "src/components/Button/Button";
import styles from "./NoResultScreen.module.scss";
import createItems from "../assets/createItems.png";

export function NoResultScreen({
  title,
  description,
  buttonLabel,
  buttonLink,
}: {
  title: string;
  description: string;
  buttonLabel: string;
  buttonLink: string;
}) {
  return (
    <div className={styles.emptyState}>
      <img src={createItems} className={styles.image} alt="creative" />
      <h2 className={styles.heading}>{title}</h2>
      <h3 className={styles.description}>{description}</h3>
      <Button size="micro" to={buttonLink}>
        {buttonLabel}
      </Button>
    </div>
  );
}
