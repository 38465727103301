import { ActivityQuery } from "src/graphql/generated/schema";
import { Accordion } from "@radix-ui/react-accordion";
import styles from "./ActivityAdsList.module.scss";
import { SectionHeading } from "src/modules/global/components/SectionHeading";
import { ActivityAdAccordionLoader } from "./ActivityAdAccordionLoader";
import { ActivityAdPreviewModal } from "./ActivityAdPreviewModal";
import { useState } from "react";

interface IActivityCreativesReportLoader {
  ads: ActivityQuery["activity"]["MetaActivity"]["MetaActivityAds"];
  dateFrom: Date;
  dateTo: Date;
  activity: ActivityQuery["activity"];
}

export function ActivityAdsList({
  ads,
  dateFrom,
  dateTo,
  activity,
}: IActivityCreativesReportLoader) {
  const [selectedAd, setSelectedAd] = useState<null | string>(null);
  return (
    <div>
      <div>
        <SectionHeading heading={"List of your Ads in this activity"} />
      </div>
      <Accordion className={styles.accordionListContainer} type="multiple">
        {ads.map((v) => (
          <ActivityAdAccordionLoader
            selectedAd={selectedAd}
            setSelectedAd={setSelectedAd}
            activity={activity?.MetaActivity}
            ad={v}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        ))}
      </Accordion>

      {selectedAd && (
        <ActivityAdPreviewModal
          isOpen={Boolean(selectedAd)}
          onClose={function () {
            setSelectedAd(null);
          }}
          id={selectedAd}
        />
      )}
    </div>
  );
}
