import metaDynamicImage from "../assets/facebookCatalogue.svg";
import metaStandardImage from "../assets/facebookStandard.svg";
import { SelectOption } from "./SelectOption";

// function getFieldValue(activity: ActivityInput): ActivityPurpose {
//   if (activity.facebookActivityType === "META_DYNAMIC") {
//     return "SALES_CATALOG";
//   }
//   if (activity.engagementConversionLocation) {
//     if (activity.engagementConversionLocation === "MESSAGE") {
//       if (activity.engagementDestinations[0] === "INSTAGRAM_DIRECT") {
//         return "MESSAGE_INSTAGRAM";
//       }
//       if (activity.engagementDestinations[0] === "WHATSAPP") {
//         return "MESSAGE_WHATSAPP";
//       }
//       if (activity.engagementDestinations[0] === "MESSENGER") {
//         return "MESSAGE_MESSENGER";
//       }
//       throw new Error("Unknown engagement destination");
//     }
//     return activity.engagementConversionLocation;
//   }
//   return "SALES_STANDARD";
// }
export function SalesAndLeadsOptions() {
  return (
    <>
      <SelectOption
        value="SALES_STANDARD"
        icon={
          <img
            src={metaStandardImage}
            alt="meta standard"
            width={32}
            height={32}
          />
        }
        label="Standard Audience Based Conversion Ads"
      />
      <SelectOption
        value="SALES_CATALOG"
        icon={
          <img
            src={metaDynamicImage}
            alt="meta dynamic"
            width={32}
            height={32}
          />
        }
        label="Dynamic Catalog Based Conversion Ads"
      />
    </>
  );
}
