import { useEffect, useState } from "react";
import { Breadcrumb, Node } from "../misc/wizardTypes";
import { WizardResetAlert, WizardContext } from "../misc/WizardContext2";
import Alert from "src/components/Alert/Alert";
import {
  ActivityInput,
  CampaignInput,
} from "src/modules/campaign-wizard/misc/createCampaignTypes";

interface WizardProps {
  tree: Record<string, Node<CampaignInput>>;
  data: CampaignInput;
}

export function Wizard({ tree, data: initialData }: WizardProps) {
  const [data, setData] = useState(initialData);
  const selectedActivityIndex = data.selectedActivityIndex;
  const selectedActivity = data.Activities[selectedActivityIndex];
  const { visitedSteps, completedSteps } = selectedActivity;
  const startingStep = tree[selectedActivity.currentStep];
  const [currentStep, setCurrentNode] = useState<null | Node<CampaignInput>>(
    startingStep
  );
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const resetAlertDefaultValue = {
    isShowing: false,
    fieldKey: "",
    data: data,
    fieldValue: "",
  };
  const [resetAlert, setResetAlert] = useState<WizardResetAlert<CampaignInput>>(
    resetAlertDefaultValue
  );

  const isCurrentNodeCompleted = selectedActivity.completedSteps?.includes(
    currentStep?.id
  );

  function setActivity(
    partialActivityData: Partial<ActivityInput>,
    baseData = data,
    currentActivityIndex = selectedActivityIndex
  ) {
    const activities = baseData.Activities.map((a, index) => {
      if (index === currentActivityIndex) {
        return {
          ...a,
          ...partialActivityData,
        };
      }

      return a;
    });

    setData({
      ...baseData,
      Activities: activities,
    });
  }

  useEffect(() => {
    const currentStepId = selectedActivity.currentStep;

    const newCurrentStepNode = tree[currentStepId];

    if (newCurrentStepNode !== currentStep) {
      setCurrentNode(newCurrentStepNode);
    }
  }, [data]);

  useEffect(() => {
    if (!currentStep) return;

    if (!visitedSteps.includes(currentStep.id)) {
      setActivity({
        visitedSteps: [...selectedActivity.visitedSteps, currentStep.id],
      });
    }

    if ("getBreadcrumbs" in currentStep) {
      const breadcrumbs = currentStep.getBreadcrumbs(data);
      setBreadcrumbs(breadcrumbs);
    }
  }, [currentStep]);

  function markNodeAsComplete(data: CampaignInput) {
    const currentNodeId = currentStep.id;
    let newCompletedSteps = completedSteps;

    if (!newCompletedSteps.includes(currentStep.id)) {
      newCompletedSteps = [...newCompletedSteps, currentNodeId];
    }

    const newCurrentNodeId = currentStep.getNextStep(data);

    setActivity(
      {
        currentStep: newCurrentNodeId,
        completedSteps: newCompletedSteps,
      },
      data
    );
  }

  function goBack(data: CampaignInput) {
    const previousNodeId = currentStep.getPreviousStep(data);
    const previousNode = tree[previousNodeId];

    if (!previousNode) {
      throw new Error("new current node is not available in the tree");
    }

    setActivity({ currentStep: previousNodeId }, data);
  }

  function navigateToNodeId(stepId: string) {
    const stepNode = tree[stepId];

    if (!visitedSteps.includes(stepNode.id)) {
      console.log("can not navigate to an un-visited node");
      return;
    }

    if (!stepNode) {
      throw new Error("new current node is not available in the tree");
    }

    setActivity({ currentStep: stepNode.id });
  }

  function purgeDataWhenFieldReset(
    fieldName: string,
    fieldValue: any,
    data: CampaignInput
  ) {
    if ("purgeDataWhenFieldReset" in currentStep) {
      const newData = currentStep.purgeDataWhenFieldReset(
        fieldName,
        fieldValue,
        data
      );

      // purge the progress beyond the current node
      const indexOfCurrentNode = completedSteps.findIndex(
        (c) => c === currentStep.id
      );

      let newCompletedSteps = completedSteps.slice(0, indexOfCurrentNode);

      const indexOfVisitedNode = visitedSteps.findIndex(
        (c) => c === currentStep.id
      );

      let newVisitedSteps = visitedSteps.slice(0, indexOfVisitedNode);

      setActivity(
        { visitedSteps: newVisitedSteps, completedSteps: newCompletedSteps },
        newData
      );
    }
  }

  function setSelectedActivityIndex(
    selectedActivityIndex: number,
    startingStep?: string,
    dataUpdates?: Partial<CampaignInput>
  ) {
    let baseData: CampaignInput = data;

    if (dataUpdates) {
      baseData = { ...baseData, ...dataUpdates, selectedActivityIndex };
    } else {
      baseData = { ...baseData, selectedActivityIndex };
    }

    const newSelectedActivity = baseData.Activities[selectedActivityIndex];

    setActivity(
      { currentStep: startingStep ?? newSelectedActivity.currentStep },
      baseData,
      selectedActivityIndex
    );
  }

  if (!currentStep) {
    return null;
  }

  return (
    <>
      <WizardContext.Provider
        value={{
          goBack,
          resetAlert,
          setResetAlert,
          navigateToNodeId,
          visitedNodes: visitedSteps,
          data,
          currentNode: currentStep,
          breadcrumbs,
          completedNodes: completedSteps,
          isCurrentNodeCompleted,
          setData,
          setSelectedActivityIndex,
          markNodeAsComplete,
        }}
      >
        {currentStep.component}
      </WizardContext.Provider>
      <Alert
        isOpen={resetAlert.isShowing}
        onClose={() => setResetAlert({ ...resetAlert, isShowing: false })}
        secondaryAction={{
          children: "Yes",
          color: "primary",
          onClick() {
            purgeDataWhenFieldReset(
              resetAlert.fieldKey,
              resetAlert.fieldValue,
              resetAlert.data
            );
            setResetAlert(resetAlertDefaultValue);
          },
        }}
        primaryAction={{
          children: "No",
          color: "subdued",
          style: "outline",
          onClick() {
            setResetAlert({ ...resetAlert, isShowing: false });
          },
        }}
        title="Doing this change will reset your progress"
      >
        By doing this change you will reset all the changes done in steps after
        this. Do you still want to continue?
      </Alert>
    </>
  );
}
