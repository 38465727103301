import { Tabs } from "src/components/Tabs/Tabs";
import styles from "./NotificationList.module.scss";
import NotificationItem from "./NotificationItem";
import { RadioGroup } from "@headlessui/react";
import notificationsCount from "../../../graphql/notificationsCount";
import { useNotificationMarkAsReadMutation } from "src/graphql/generated/schema";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "src/components/Spinner/Spinner";
import { INotification } from "../misc/notification";

interface INotificationList {
  setSelectedNotification: (i: INotification) => void;
  selectedNotification: INotification;
  hasMoreNotifications: boolean;
  fetchMoreNotifications: () => Promise<void>;
  notifications: INotification[];
}

export default function NotificationList({
  notifications,
  fetchMoreNotifications,
  hasMoreNotifications,
  setSelectedNotification,
  selectedNotification,
}: INotificationList) {
  const [markAsRead] = useNotificationMarkAsReadMutation();

  return (
    <div>
      <RadioGroup
        value={selectedNotification}
        onChange={(notification) => {
          // don't await mark as read should be done in background
          markAsRead({
            variables: {
              id: notification.id,
            },
            refetchQueries: [notificationsCount],
          });
          setSelectedNotification(notification);
        }}
      >
        <InfiniteScroll
          dataLength={notifications.length}
          scrollableTarget="scrollTargetForInfiniteScroll"
          next={fetchMoreNotifications}
          hasMore={hasMoreNotifications}
          loader={<Spinner height={200} />}
        >
          {notifications.map((notification, idx) => (
            <RadioGroup.Option value={notification} key={idx}>
              <NotificationItem notification={notification} />
              <hr className={styles.line} />
            </RadioGroup.Option>
          ))}
        </InfiniteScroll>
      </RadioGroup>
    </div>
  );
}
