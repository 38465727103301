import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Label } from "src/components/Label/Label";
import styles from "./EmailDomainDetails.module.scss";
import { Input } from "src/components/Input/Input";

export function EmailDomainDetails() {
  const { values } =
    useFormikContext<{
      domain: string;
      mailFromSubdomain: string;
    }>();
  return (
    <div>
      <h4 className={classNames(styles.subHeadings, styles.firstSubHeading)}>
        Add domain details for Email Ingeration
      </h4>
      <div className={styles.highlightContainer}>
        <div className={styles.domainInp}>
          <Label
            label={
              <div className={styles.inputLabel}>
                <span>Domain</span>
                <Info className={styles.infoIcon} />
              </div>
            }
          >
            <InputFormik name="domain" placeholder="Eg: abc.com" />
          </Label>
        </div>
        <div className={styles.subDomainContainer}>
          <h4 className={styles.subHeadings}>Add Sub domain for send email</h4>
          <p className={styles.subDomainDescription}>
            Email you send, will be using sub domain as a default MAIL FROM
            domain. Setting the MAIL FROM to a domain you own enables you to
            comply with Domain-based Message Authentication and Reporting and
            Conformance (DMARC)
          </p>
          <div className={styles.domainInp}>
            <Label
              label={
                <div className={styles.inputLabel}>
                  <span>MAIL FROM domain</span>
                  <span>
                    <Info className={styles.infoIcon} />
                  </span>
                </div>
              }
            />
            <div className={styles.subDomainInp}>
              <InputFormik name="mailFromSubdomain" placeholder="Eg: mailer" />
              {values.domain && (
                <span className={styles.domain}>.{values.domain}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
