import { ReactComponent as BackArrow } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { ReactComponent as VideoCamIcon } from "@material-design-icons/svg/filled/videocam.svg";
import { ReactComponent as CameraIcon } from "@material-design-icons/svg/outlined/photo_camera.svg";
import { ReactComponent as AttachmentIcon } from "@material-design-icons/svg/outlined/attach_file.svg";
import { ReactComponent as CloseIcon } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as EmojiIcon } from "@material-design-icons/svg/outlined/mood.svg";
import { ReactComponent as CallIcon } from "@material-design-icons/svg/filled/call.svg";
import { ReactComponent as ThreeDotIcon } from "@material-design-icons/svg/outlined/more_vert.svg";
import { ReactComponent as SendIcon } from "@material-design-icons/svg/filled/send.svg";
import styles from "./WhatsappPreview.module.scss";
import classNames from "classnames";
import { useRef } from "react";
import { MetaPageWelcomeMessageInput } from "../../../graphql/generated/schema";

interface IWhatsappPreview {
  metaPageName: string;
  metaPagePicture: string;
  selectedPreviewTab: "PREFILLED_MESSAGE" | "MESSAGE_OPTIONS";
  pageWelcomeMessage: MetaPageWelcomeMessageInput;
}

export function WhatsappPreview({
  metaPageName,
  metaPagePicture,
  selectedPreviewTab,
  pageWelcomeMessage,
}: IWhatsappPreview) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  function handleChange() {
    if (!textareaRef.current) {
      return;
    }

    const height = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = `${height}px`;
  }

  return (
    <section className={styles.whatsappPreview}>
      <div className={styles.header}>
        <BackArrow fill="currentColor" height={22} width={22} />
        <div className={styles.brandInfo}>
          {metaPagePicture && (
            <img
              src={metaPagePicture}
              width={28}
              height={28}
              className={styles.brandLogo}
            />
          )}
          <div className={styles.brandDetail}>
            <h4 className={styles.brandName}>{metaPageName || "Macro"}</h4>
            <p className={styles.accountType}>Business Account</p>
          </div>
        </div>
        <div className={styles.headerQuickActions}>
          <VideoCamIcon fill="currentColor" width={20} height={20} />
          <CallIcon fill="currentColor" width={20} height={20} />
          <ThreeDotIcon fill="currentColor" width={20} height={20} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.messageForm}>
          <div className={styles.inputAndPreview}>
            <div className={styles.adLinkPreview}>
              <div className={styles.adLinkImage}>
                <img
                  className={styles.adLinkImage}
                  src={metaPagePicture}
                  width={64}
                  height={64}
                />
              </div>
              <div className={styles.adLinkBody}>
                <p className={styles.adLinkTitle}>{metaPageName}</p>
                <p className={styles.adLinkSubtitle}>facebook.com/pages</p>
              </div>
              <button
                className={styles.closeLinkPreview}
                type="button"
                disabled
              >
                <CloseIcon width={18} height={18} />
              </button>
            </div>
            <div className={styles.greetingText}>
              {pageWelcomeMessage?.greeting}
            </div>
            {/* multi options */}

            {selectedPreviewTab === "MESSAGE_OPTIONS" && (
              <div className={styles.messageOptionList}>
                {pageWelcomeMessage?.MessageOptions?.map((option) => {
                  return (
                    <div className={styles.messageOption}>
                      {option.text}
                      <button
                        className={styles.messageOptionSendBtn}
                        type="button"
                      >
                        <SendIcon fill="currentColor" width={16} height={16} />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}

            <div className={styles.inputWrapper}>
              <span
                className={classNames(
                  styles.inputActionGroup,
                  styles.inputLeftActionGroup
                )}
              >
                <button className={styles.inputAction} disabled type="button">
                  <EmojiIcon width={16} height={16} fill="currentColor" />
                </button>
              </span>
              <textarea
                ref={textareaRef}
                onChange={handleChange}
                value={
                  selectedPreviewTab === "PREFILLED_MESSAGE"
                    ? pageWelcomeMessage?.prefilledText
                    : ""
                }
                readOnly
                className={classNames(styles.input, {
                  [styles.singleLine]:
                    pageWelcomeMessage?.prefilledText?.length <= 28,
                })}
              />
              <span
                className={classNames(
                  styles.inputActionGroup,
                  styles.inputRightActionGroup
                )}
              >
                <button className={styles.inputAction} disabled type="button">
                  <AttachmentIcon width={16} height={16} fill="currentColor" />
                </button>
                <button
                  className={classNames(styles.inputAction)}
                  disabled
                  type="button"
                >
                  <CameraIcon width={16} height={16} fill="currentColor" />
                </button>
              </span>
            </div>
          </div>
          <button type="button" disabled className={styles.sendBtn}>
            <SendIcon fill="currentColor" width={18} height={18} />
          </button>
        </div>
      </div>
    </section>
  );
}
