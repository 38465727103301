export function getNodesPosition(nodeList: NodeListOf<ChildNode>) {
  return Array.from(nodeList).map((value) => {
    if (value.hasChildNodes() && (value as any).id.includes("conditionsList")) {
      return Array.from(value.childNodes).map((c) =>
        c.hasChildNodes() ? getNodesPosition(c.childNodes) : null
      );
    }
    const x = (value as any).offsetLeft + (value as any).offsetWidth / 2;
    const y = (value as any).offsetTop + (value as any).offsetHeight / 2;
    return { x, y };
  });
}
