import {
  FlowItems,
  FormValues,
  IConditionalItem,
} from "./CreateAutomationPage";
import { useEffect, useRef, useState } from "react";

import { CreateAutomationEmptyState } from "./CreateAutomationEmptyState";
import { FlowItem } from "src/components/Automations/builder/AutomationBuilderFlowArea/FlowItem";
import { PanAndZoom } from "src/components/PanAndZoom/PanAndZoom";
import { getNodesPosition } from "src/lib/getNodesPosition";
import styles from "./FlowArea.module.scss";
import { useFormikContext } from "formik";

interface LinePositions {
  x: number;
  y: number;
}

function generateConnectorPositions(connectorPositions: any[]) {
  return connectorPositions.map((value, i) => {
    const nextItem = connectorPositions[i + 1];
    if (Array.isArray(nextItem)) {
      const [rhs, lhs] = nextItem;
      return [
        <SvgSeparator to={rhs[0]} from={value as LinePositions} />,
        <SvgSeparator to={lhs[0]} from={value as LinePositions} />,
      ];
    }
    if (Array.isArray(value)) {
      const [rhs, lhs] = value;
      return [generateConnectorPositions(lhs), generateConnectorPositions(rhs)];
    }

    if (i >= connectorPositions.length - 1) return;
    return (
      <SvgSeparator
        from={value as LinePositions}
        to={nextItem as LinePositions}
      />
    );
  });
}

function generateRenderList(renderList: FlowItems[]) {
  return renderList.map((itemProps, i) => {
    if ("options" in itemProps && itemProps.options.length > 0) {
      return (
        <>
          <FlowItem {...itemProps} id={i} />
          <RenderConditions {...itemProps} id={i} parentIndex={i} />
        </>
      );
    }

    return (
      <>
        <FlowItem {...itemProps} key={"flowItem" + i} id={i} />
      </>
    );
  });
}

export function FlowArea({
  values,
}: {
  values: {
    [x: string]: any;
    flowList: FlowItems[];
  };
}) {
  const [connectorPositions, setConnectorPositions] = useState([]);
  const nodesListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!nodesListRef) return;
    if (!nodesListRef.current) return;
    const nodesList = nodesListRef.current;
    const positions = getNodesPosition(nodesList.childNodes);
    setConnectorPositions(positions);
  }, [values.flowList]);

  if (values.flowList.length === 0) {
    return <CreateAutomationEmptyState />;
  }

  return (
    <PanAndZoom>
      <svg className={styles.connector}>
        {generateConnectorPositions(connectorPositions)}
      </svg>
      <div id="nodesList" className={styles.listContainer} ref={nodesListRef}>
        {generateRenderList(values.flowList)}
      </div>
    </PanAndZoom>
  );
}

interface RenderConditionProps {
  id: string | number;
  options: IConditionalItem["options"];
  // parent index in formik list
  parentIndex: number;
}
function RenderConditions({ id, options }: RenderConditionProps) {
  const [rhs, lhs] = options;

  return (
    <div id={`conditionsList-${id}`} className={styles.conditionsList}>
      <div
        id={"trueConditonValues"}
        className={styles.listContainer}
        key={"trueConditionValues"}
      >
        {generateRenderList(rhs)}
      </div>
      <div
        id={"falseConditionValues"}
        className={styles.listContainer}
        key={"falseConditionValuesb"}
      >
        {generateRenderList(lhs)}
      </div>
    </div>
  );
}

export function SvgSeparator({
  to,
  from,
}: {
  to: LinePositions;
  from: LinePositions;
}) {
  return (
    <line
      x1={from.x}
      x2={to.x}
      y1={from.y}
      y2={to.y}
      stroke="black"
      strokeWidth={1}
    />
  );
}
