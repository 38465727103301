import { useRef } from "react";
import { Button, IButton } from "../../../../components/Button/Button";
import { useCreateAsset } from "../../../campaign-wizard/functions/useCreateAsset";
import { imageAndVideoMimeType } from "../../../campaign-wizard/misc/supportedAssetTypes";
import { Asset as IAsset } from "../../../../graphql/generated/schema";
import { ReactComponent as Backup } from "@material-symbols/svg-600/rounded/backup.svg";
import styles from "./FileUploadButton.module.scss";
import toast from "react-hot-toast";

type ButtonWithoutChildren = Omit<IButton, "children">;
export interface IFileUploadButton extends ButtonWithoutChildren {
  onUpload: (uploadedMedia: IAsset) => void;
  maxVideoFileSizeInBytes: number;
  maxImageFileSizeInBytes: number;
  loading?: boolean;
}

export function FileUploadButton({
  onUpload,
  maxVideoFileSizeInBytes,
  maxImageFileSizeInBytes,
  loading,
  color = "primary",
  size = "default",
  style = "solid",
  ...props
}: IFileUploadButton) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleFileUpload, loading: createAssetLoading } = useCreateAsset({
    validMimeType: imageAndVideoMimeType,
    maxVideoFileSizeInBytes: maxVideoFileSizeInBytes,
    maxImageFileSizeInBytes: maxImageFileSizeInBytes,
  });

  function handleTrigger() {
    if (!inputRef.current) return;
    inputRef.current.click();
  }

  return (
    <div>
      <input
        ref={inputRef}
        hidden
        type="file"
        onChange={async (e) => {
          try {
            const uploadedFiles = await handleFileUpload(e.target.files);
            onUpload(uploadedFiles);
          } catch (err) {
            toast.error(err.message);
          }
        }}
        accept={imageAndVideoMimeType.join(",")}
      />
      <Button
        onClick={handleTrigger}
        loading={loading || createAssetLoading}
        disabled={loading || createAssetLoading}
        color={color}
        style={style}
        size={size}
        {...props}
      >
        <span className={styles.uploadButtonContent}>
          <Backup fill="var(--color-text-disabled)" width={20} height={20} />
          Upload File
        </span>
      </Button>
    </div>
  );
}
