import { Form, Formik } from "formik";
import styles from "./CreateClientForm.module.scss";
import Logo from "src/components/Logo/Logo";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { timezonesList } from "src/modules/global/misc/timezonesList";
import { currencyList } from "src/currency";
import { useClientCreateMutation } from "src/graphql/generated/schema";
import industries from "src/modules/global/misc/clientIndustries";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import * as Yup from "yup";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import SelectV2 from "src/components/SelectV2/SelectV2";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  companySize: Yup.string().required("Company size is required"),
  timezone: Yup.string().required("Timezone is required"),
  currency: Yup.string().required("Currency is required"),
  industry: Yup.string().required("Industry is required"),
});

export function CreateClientForm() {
  const [createClient] = useClientCreateMutation();
  const navigate = useNavigate();
  const { setActiveClient } = useContext(AuthContext);

  return (
    <div className={styles.wrapper}>
      <Logo width={32} height={32} style="primary" type="icon" />
      <h1 className={styles.heading}>Create your first client</h1>
      <p className={styles.subheading}>
        Accelerate your ad creation with AI's swift, new Media Library,
        Integration, Analytics and lot more...
      </p>

      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: "",
          companySize: "",
          timezone: "",
          currency: "",
          industry: "",
          invitees: [],
        }}
        onSubmit={async (values) => {
          const payload = await createClient({
            variables: {
              input: {
                name: values.name,
                currencyCode: values.currency,
                industry: values.industry,
                timeZone: values.timezone,
              },
            },
          });

          const redirectUrl = localStorage.getItem("redirect_url");
          setActiveClient(payload.data.clientCreate, redirectUrl);

          if (redirectUrl) {
            localStorage.removeItem("redirect_url");
          }
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <div className={styles.formFields}>
              <InputFormikV2
                isFullWidth={false}
                label="Client Name"
                name="name"
              />
              <SelectV2
                isFullWidth={false}
                options={[
                  {
                    label: "1 - 20 Employees",
                    value: "1 - 20",
                  },
                  {
                    label: "21 - 100 Employees",
                    value: "21 - 100",
                  },
                  {
                    label: "101 - 500 Employees",
                    value: "101 - 500",
                  },
                  {
                    label: "501 - 2000 Employees",
                    value: "501 - 2000",
                  },
                  {
                    label: "2000+ Employees",
                    value: "2000+",
                  },
                ]}
                value={values.companySize}
                onChange={(value) => {
                  setFieldValue("companySize", value);
                }}
                label="How big is your company?"
              />
              <SelectV2
                isFullWidth={false}
                options={industries}
                value={values.industry}
                onChange={(value) => {
                  setFieldValue("industry", value);
                }}
                label="Industry"
              />

              <div className={styles.fieldRow}>
                <SelectV2
                  // isFullWidth={false}
                  options={timezonesList}
                  value={values.timezone}
                  onChange={(value) => {
                    setFieldValue("timezone", value);
                  }}
                  label="Select Timezone"
                />
                <SelectV2
                  // isFullWidth={false}
                  options={currencyList.map((c) => ({
                    label: c.currencyName,
                    value: c.code,
                  }))}
                  value={values.currency}
                  onChange={(value) => {
                    setFieldValue("currency", value);
                  }}
                  label="Select Currency"
                />
              </div>

              <div className={styles.buttonWrapper}>
                <ButtonV2 type="submit" loading={isSubmitting} isFullWidth>
                  Complete Setup
                </ButtonV2>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
