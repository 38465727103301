import styles from "./AdTemplateSingle.module.scss";
import {
  ISmartphone,
  Smartphone,
} from "../../../../components/Smartphone/Smartphone";
import {
  MetaActivityAdLineItem,
  MetaActivityAdTemplate,
} from "../../../../graphql/generated/schema";
import { getThumbnailAssetFromAd } from "../../../campaign/functions/getThumbnailAssetFromAd";
import { transformAdTemplateToLineItems } from "../../functions/transformAdTemplateToLineItems";
import { Button } from "../../../../components/Button/Button";
import { titleCase } from "../../../../lib/titleCase";
import { Asset } from "../../../../components/Smartphone/Asset";
import * as Accordion from "@radix-ui/react-accordion";
import { ReactComponent as ChevronDownIcon } from "@material-symbols/svg-600/rounded/keyboard_arrow_down.svg";
import format from "date-fns/format";

export function AdTemplateSingleDetails({
  adTemplate,
}: {
  adTemplate: MetaActivityAdTemplate;
}) {
  const storyAsset = getThumbnailAssetFromAd(adTemplate, "STORY");
  const postAsset = getThumbnailAssetFromAd(adTemplate, "FEED");
  const smartphonePreview = transformAdTemplateToLineItems(adTemplate);

  const previewList: ISmartphone[] = [
    {
      variant: "feed",
      preview: smartphonePreview,
      type: "facebook",
      adType: adTemplate.adType,
      accountName: "macrostack",
      primaryText: adTemplate.primaryText,
    },
    {
      variant: "stories",
      preview: smartphonePreview,
      type: "instagram",
      adType: adTemplate.adType,
      accountName: "macrostack",
      primaryText: adTemplate.primaryText,
    },
    {
      variant: "feed",
      preview: smartphonePreview,
      type: "instagram",
      adType: adTemplate.adType,
      accountName: "macrostack",
      primaryText: adTemplate.primaryText,
    },
  ];

  return (
    <div className={styles.detailsSectionWrapper}>
      <div className={styles.previewSection}>
        {previewList.map((v) => (
          <div>
            <Smartphone screenSize="20rem" preview={smartphonePreview} {...v} />
          </div>
        ))}
      </div>

      <div className={styles.detailSection}>
        <div className={styles.sectionTitle}>Ad Details</div>
        <div className={styles.detailItem}>
          <div className={styles.detailItemHeading}>Primary Text</div>
          <div className={styles.detailItemSubtitle}>
            {adTemplate.primaryText}
          </div>
        </div>

        {adTemplate.LineItems.length === 1 ? (
          <>
            <div className={styles.detailItem}>
              <div className={styles.detailItemHeading}>Ad Title</div>
              <div className={styles.detailItemSubtitle}>
                {adTemplate.LineItems[0].headline}
              </div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemHeading}>Ad Description</div>
              <div className={styles.detailItemSubtitle}>
                {adTemplate.LineItems[0].description}
              </div>
            </div>
            {adTemplate.adType === "CUSTOM_CREATIVE" && (
              <>
                {storyAsset && (
                  <AssetWithLink
                    asset={storyAsset}
                    variant={"STORY"}
                    hasMultiplacements={Boolean(storyAsset)}
                  />
                )}
                {postAsset && (
                  <AssetWithLink
                    asset={postAsset}
                    variant={"POST"}
                    hasMultiplacements={Boolean(storyAsset)}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <div className={styles.cardList}>
            {adTemplate.LineItems.map((v, i) => (
              <LineItemCard lineItem={v} order={i} />
            ))}
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <div className={styles.detailItem}>
          <div className={styles.detailItemHeading}>Ad Type</div>
          <div className={styles.detailItemSubtitle}>
            {titleCase(adTemplate.adType, "_")}
          </div>
        </div>

        <div className={styles.detailItem}>
          <div className={styles.detailItemHeading}>Created on</div>
          <div className={styles.detailItemSubtitle}>
            {format(new Date(adTemplate.createdAt), "dd MMM, yyyy")}
          </div>
        </div>
      </div>
    </div>
  );
}

function LineItemCard({
  lineItem,
  order,
}: {
  lineItem: MetaActivityAdLineItem;
  order: number;
}) {
  return (
    <Accordion.Root
      defaultValue={lineItem.id}
      type="single"
      collapsible
      className={styles.card}
    >
      <Accordion.Item value={lineItem.id}>
        <Accordion.Trigger className={styles.cardHeader}>
          <div>Card {order + 1 > 9 ? order + 1 : `0${order + 1}`}</div>
          <div>
            <ChevronDownIcon
              width={24}
              height={24}
              aria-hidden
              className={styles.accordionChevron}
            />
          </div>
        </Accordion.Trigger>
        <Accordion.Content className={styles.cardContent}>
          <div className={styles.detailItem}>
            <div className={styles.detailItemHeading}>Ad Title</div>
            <div className={styles.detailItemSubtitle}>{lineItem.headline}</div>
          </div>
          <div className={styles.detailItem}>
            <div className={styles.detailItemHeading}>Ad Description</div>
            <div className={styles.detailItemSubtitle}>
              {lineItem.description}
            </div>
          </div>
          {lineItem.StoryAsset && (
            <AssetWithLink
              asset={lineItem.StoryAsset}
              variant={"STORY"}
              hasMultiplacements={Boolean(lineItem.StoryAsset)}
            />
          )}
          {lineItem.PostAsset && (
            <AssetWithLink
              asset={lineItem.PostAsset}
              variant={"POST"}
              hasMultiplacements={Boolean(lineItem.StoryAsset)}
            />
          )}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
}

function AssetWithLink({ asset, variant, hasMultiplacements }) {
  return (
    <div className={styles.detailItem}>
      {/* if there are multiple placements only then make distinction of this to be story or post media*/}
      <div className={styles.detailItemHeading}>
        {hasMultiplacements ? `${titleCase(variant)} Media` : "Media"}
      </div>
      <div className={styles.assetContainer}>
        <Asset
          type={asset?.contentType}
          name={variant}
          preview={asset?.uri}
          className={styles.asset}
        />
        <Button
          to={`/library/assets?id=${asset?.id}`}
          style="plain"
          size="micro"
        >
          {asset?.name}
        </Button>
      </div>
    </div>
  );
}
