import styles from "./ResourceOverview.module.scss";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/outlined/info.svg";
import { RadioGroup } from "@headlessui/react";
import { ReactNode, useMemo, useState } from "react";
import classNames from "classnames";
import Tooltip from "src/components/Tooltip/Tooltip";
import { GrowPerTickType } from "../misc/GrowPerTickType";
import { formatNumberByType } from "../functions/formatNumberByType";
import { GraphNotAvailableYet } from "src/modules/global/components/GraphNotAvailableYet";
import { addCommas } from "src/lib/addCommas";
import { removeDecimalIfZeros } from "src/lib/removeDecimalIfZeros";
import { TimelineGraph } from "src/modules/global/components/TimelineGraph";
import { CustomizeMetricsSelector } from "./CustomizeMetricsSelector";
import { useGetActiveCurrencyCode } from "../functions/useGetActiveCurrencyCode";

interface IResourceOverview {
  chartData: GrowPerTickType[];
  stats: {
    label: string;
    description: string;
    value: string;
    id: string;
    statKey: string;
    statType: "CURRENCY" | "NUMBER" | "MULTIPLIER" | "PERCENTAGE";
  }[];
  source?: {
    icon: ReactNode;
    label: string;
  };
}

export function ResourceOverview({
  chartData,
  stats,
  source,
}: IResourceOverview) {
  const [selectedStatId, setSelectedStatId] = useState(stats[0].id);
  // const [showCompareAt, setShowCompareAt] = useState(false);
  const selectedCurrencyCode = useGetActiveCurrencyCode();

  function handleSelectedStatIdChange(value: string) {
    setSelectedStatId(value);
  }

  const selectedKeyStat = useMemo(
    () => stats.find((v) => v.id === selectedStatId),
    [selectedStatId, stats]
  );

  return (
    <section className={styles.overviewSection}>
      <div className={styles.keyStatsSection}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.sectionTitle}>Overview</h2>
          {/* <CustomizeMetricsSelector /> */}
        </div>
        <RadioGroup
          as="div"
          className={styles.keyStatsList}
          onChange={handleSelectedStatIdChange}
          value={selectedStatId}
        >
          {stats.map((stat) => (
            <RadioGroup.Option value={stat.id}>
              {({ checked }) => (
                <div
                  className={classNames(styles.keyStatItem, {
                    [styles.selectedKeyStatItem]: checked,
                  })}
                >
                  <div className={styles.keyStatContent}>
                    <span
                      className={classNames(styles.keyStatTitle, {
                        [styles.selectedKeyStatTitle]: checked,
                      })}
                    >
                      {stat.label}
                    </span>
                    <span
                      className={classNames(styles.keyStatDescription, {
                        [styles.selectedKeyStatDescription]: checked,
                      })}
                    >
                      {stat.description}
                    </span>
                  </div>
                  <div>
                    <span
                      className={classNames(styles.keyStatValue, {
                        [styles.selectedKeyStatValue]: checked,
                      })}
                    >
                      {stat.value}
                    </span>
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>

      <div className={styles.graphSection}>
        <div className={styles.graphHeader}>
          <div className={styles.totalSection}>
            <span className={styles.totalLabel}>
              {selectedKeyStat.label}
              <Tooltip supportingText="Hello world">
                <InfoIcon width={14} height={14} />
              </Tooltip>
            </span>
            <span className={styles.totalValue}>{selectedKeyStat.value}</span>
            {/* TODO: get last updated */}
            <span className={styles.lastUpdated}>Last updated 4 hours ago</span>
          </div>
        </div>

        {chartData?.length === 0 ? (
          <GraphNotAvailableYet />
        ) : (
          <div className={styles.graph}>
            <TimelineGraph
              statValues={chartData}
              height={200}
              statLabel={{
                color: "var(--color-primary)",
                name: selectedKeyStat.label,
                dataKey: selectedKeyStat?.statKey,
              }}
              width={"100%"}
              yAxis={{
                tooltipFormatter: (val) =>
                  addCommas(removeDecimalIfZeros(Number(val))),
                tickFormatter: (v) =>
                  formatNumberByType(v as any, selectedKeyStat.statType, {
                    showDecimal: true,
                    selectedCurrencyCode,
                  }),
                dataKey: selectedKeyStat?.statKey,
                label: selectedKeyStat?.label,
              }}
            />
          </div>
        )}

        <div className={styles.graphFooter}>
          <div>
            {source && (
              <span className={styles.graphPlatforms}>
                {source.label}
                {source.icon}
              </span>
            )}
          </div>
          <div className={styles.legendSection}>
            <span className={classNames(styles.legend)}>
              <span
                className={classNames(
                  styles.legendIndicator,
                  styles.mainValueIndicator
                )}
              />
              Last 7 Days
            </span>
            <span className={classNames(styles.legend)}>
              <span
                className={classNames(
                  styles.legendIndicator,
                  styles.compareValueIndicator
                )}
              />
              Previous 7 Days
            </span>
          </div>
        </div>
        {/* <LineGraph /> */}
      </div>
    </section>
  );
}
