import {
  AudienceTypeEnum,
  MetaAudienceInsightsObject,
} from "src/graphql/generated/schema";

export function getFormattedAudienceType(audience: {
  type: AudienceTypeEnum;
  MetaInsights?: MetaAudienceInsightsObject;
}) {
  switch (audience?.type) {
    case "CUSTOMER_LIST":
      return "Segment Audience";
    case "LOOKALIKE":
      return "Lookalike Audience";
    case "PLATFORM_BASED": {
      if (audience?.MetaInsights?.metaPageId) {
        return "Page Audience";
      }

      if (audience?.MetaInsights?.metaInstagramId) {
        return "Instagram Audience";
      }

      if (audience?.MetaInsights?.metaPixelId) {
        return "Pixel Audience";
      }

      return "Custom Audience";
    }
  }
}
