import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  SegmentTemplate,
  SegmentsDocument,
  useSegmentCreateMutation,
} from "src/graphql/generated/schema";
import { Button } from "src/components/Button/Button";
import { SegmentCreateModalContent } from "./SegmentCreateModalContent";
import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import Modal from "src/components/Modal/Modal";
import { Template } from "src/components/TemplateSelector/TemplateSelector";
import styles from "./SegmentCreateModal.module.scss";
import abandonedCart from "../assets/abandonedCart.png";
import customerWinback from "../assets/customerWinback.png";
import firstPurchaseUpsell from "../assets/firstPurchaseUpsell.png";
import postPurchase from "../assets/postPurchase.png";
import welcomeSeries from "../assets/welcomeSeries.png";

interface ISegmentCreateModal {
  isOpen: boolean;
  onClose: () => void;
  segmentTemplates: SegmentTemplate[];
}

type Step = "NAME_FORM" | "SELECT_TEMPLATE" | "REVIEW";

interface StepActionProps {
  step: Step;
  setStep: (i: Step) => void;
}

interface SegmentCreateForm {
  name: string;
  step: Step;
  template: any;
}

const createSegmentSchema = Yup.object({
  name: Yup.string()
    .max(30, "Name cannot be more than 30 characters")
    .required(),
});

const stepsOrder: Step[] = ["NAME_FORM", "SELECT_TEMPLATE", "REVIEW"];

const segmentIconsArr = [
  abandonedCart,
  postPurchase,
  firstPurchaseUpsell,
  customerWinback,
  welcomeSeries,
  postPurchase,
];

function handleBack({ step, setStep }: StepActionProps) {
  const currentStepOrder = stepsOrder.findIndex((v) => v === step);
  if (currentStepOrder === 0) {
    return;
  }

  setStep(stepsOrder[currentStepOrder - 1]);
  return;
}

function handleNext({ step, setStep }: StepActionProps) {
  const currentStepOrder = stepsOrder.findIndex((v) => v === step);
  if (currentStepOrder === stepsOrder.length - 1) {
    return;
  }

  setStep(stepsOrder[currentStepOrder + 1]);
  return;
}

export function SegmentCreateModal({
  isOpen,
  onClose,
  segmentTemplates,
}: ISegmentCreateModal) {
  const [segmentCreateFunc] = useSegmentCreateMutation();

  const segmentTemplateList: Template[] = segmentTemplates.map(
    (value, idx) => ({
      description: value.description,
      icon: segmentIconsArr[idx],
      id: value.id,
      title: value.name,
      subtitle: `${value?.customersCount?.selectedCustomers} customers, ${value?.customersCount?.percentage}% of your customer base`,
      filter: value.filter,
      customersCount: value.customersCount,
    })
  );

  const initialValues: SegmentCreateForm = {
    step: "NAME_FORM",
    name: "",
    // select first template by default
    template: segmentTemplateList[0],
  };

  function handleClose({ resetForm, validateForm }) {
    onClose();

    // HACK: resetForm was triggering before onClose which results in weird bugs
    setTimeout(() => {
      resetForm();
      validateForm();
    }, 100);
  }

  async function handleFormSubmit(values, helpers) {
    if (values.step === "REVIEW") {
      const payload = await segmentCreateFunc({
        variables: {
          input: {
            templateId: values.template.id,
            name: values.name,
            description: values.template.description,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: SegmentsDocument,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filter: {
                take: 10,
              },
            },
          },
        ],
      });
      // console.log({ payload });
      handleClose({
        resetForm: helpers.resetForm,
        validateForm: helpers.validateForm,
      });
      return;
    }

    handleNext({
      step: values.step,
      setStep: (value) => helpers.setFieldValue("step", value),
    });
  }

  return (
    <Formik
      validationSchema={createSegmentSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        resetForm,
        validateForm,
        isSubmitting,
      }) => (
        <Form>
          <Modal
            isOpen={isOpen}
            title={
              values.step === "REVIEW"
                ? values.name
                : values.step === "SELECT_TEMPLATE"
                ? "Choose templates for segment"
                : ""
            }
            subtitle={
              values.step === "SELECT_TEMPLATE"
                ? "Create new segments using customizable templates."
                : ""
            }
            onClose={() => {
              handleClose({ resetForm, validateForm });
            }}
            className={styles.modal}
            maxWidth={values.step === "NAME_FORM" ? "600px" : "85%"}
            noContainer
            footer={
              values.step === "REVIEW"
                ? {
                    footerActions: (
                      <>
                        <Button
                          style="solid"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={() =>
                            handleBack({
                              step: values.step,
                              setStep: (value) => setFieldValue("step", value),
                            })
                          }
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                        >
                          Save Segment
                        </Button>
                      </>
                    ),
                    footerContent: (
                      <div className={styles.footerContent}>
                        <Info width={20} height={20} />
                        {values.template.subtitle}
                      </div>
                    ),
                  }
                : null
            }
          >
            <div className={styles.modalContentWrapper}>
              <SegmentCreateModalContent
                segmentTemplateList={segmentTemplateList}
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
