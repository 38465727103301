import { Link } from "react-router-dom";
import styles from "./NeedHelpCard.module.scss";
import help from "../assets/help.png";

export function NeedHelpCard() {
  return (
    <div className={styles.needHelp}>
      <img src={help} alt="help_center" height={62} width={84} />
      <div>
        <h4 className={styles.title}>Need help?</h4>
        <p className={styles.subtitle}>
          You can contact us for setup and demo, we are happy to help you.{" "}
          <Link to="/" className={styles.contactUs}>
            Contact Us
          </Link>
        </p>
      </div>
    </div>
  );
}
