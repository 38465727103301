import {
  CustomerFieldsEnum,
  TextMessageTemplateInput,
  WhatsappTemplateLanguageEnum,
} from "src/graphql/generated/schema";

export interface ICreateTemplate {
  platform: "WHATSAPP" | "SMS";
}
export const WHATSAPP_STEPS = [
  "SETUP",
  "ADD_DETAILS_WHATSAPP",
  "REVIEW",
] as const;
export const SMS_STEPS = ["SETUP", "ADD_DETAILS_SMS", "REVIEW"] as const;
export type CreateMessageTemplateStepType =
  | typeof WHATSAPP_STEPS[number]
  | typeof SMS_STEPS[number];
export interface MessageFormInput extends TextMessageTemplateInput {
  type: string;
  dltId: string;
  objective: string;
  language: string;
  optOutLink?: string;
}

export type HeaderTypeOptionValue = "NONE" | "TEXT" | "UPLOAD" | "LOCATION";
export type ButtonType = "CTA" | "QUICK_REPLY" | "NONE";
export interface WhatsappFormInput {
  name: string;
  objective: string;
  header: {
    headerType: HeaderTypeOptionValue;
    text?: string;
    assetUrl?: string;
    example?: {
      variableName?: string;
      variableExample?: string;
    }[];
    variables: CustomerFieldsEnum[];
  };
  body: {
    text?: string;
    example?: {
      variableName?: string;
      variableExample?: string;
    }[];
    variables: CustomerFieldsEnum[];
  };
  footer?: string;
  language?: WhatsappTemplateLanguageEnum;
  buttons: {
    type: ButtonType;
    quickReplies?: string[];
    ctas?: {
      type: "URL" | "PHONE";
      text?: string;
      url?: string;
      phone?: string;
    }[];
  };
}
export type FormInput = WhatsappFormInput | MessageFormInput;
