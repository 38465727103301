import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./ResourceItem.module.scss";
import Badge from "../Badge/Badge";

export interface IResourceItem {
  title?: string;
  subtitle?: React.ReactNode;
  icon: string;
  badge: string;
  children: ReactNode;
  className?: string;
}

export default function ResourceItem(props: IResourceItem) {
  const { title, subtitle, icon, children, className, badge = "draft" } = props;

  // TODO: pass status color from props
  function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case "view email":
        return "attention";
      case "send email":
        return "default";
      case "click link":
        return "success";
      case "active":
      case "seen":
        return "success";
      case "delivered":
        return "attention";
      case "draft":
        return "default";
      default:
        return "default";
    }
  }

  return (
    <div className={classNames("campaign-items", styles.container, className)}>
      <div className={styles.platformAndTitle}>
        <div className={styles.platform}>
          <img src={icon} alt="image" className={styles.icon} />
        </div>
        <div>
          <p className={styles.title}>{title}</p>
          <span className={styles.date}>{subtitle}</span>
        </div>
      </div>
      <div className={styles.badgeContainer}>
        <Badge label={badge} status={getStatusColor(badge)} />
        {/* <Badge label={badge} status={"attention"} /> */}
      </div>
      {/* Children */}
      <div className={styles.children}>{children}</div>
      {/* <div className={styles.platformIconsContainer}>
        {automation.automations.map((item, idx) => (
          <div
            className={classNames(
              styles.platformIcon,
              styles[item.toLowerCase()]
            )}
            key={item + idx}
          >
            {typeof automateMedium[item] === "string" ? (
              <img src={automateMedium[item]} alt={automateMedium[item]} />
            ) : (
              automateMedium[item]
            )}
          </div>
        ))}
      </div>
      <div className={styles.conversion}>
        <p className={styles.conversionValue}>{}</p>
        <span className={styles.conversionText}>Conversion</span>
      </div>
      <div className={styles.conversion}>
        <p className={styles.conversionValue}>{}%</p>
        <span className={styles.conversionText}>Conversion Rate</span>
      </div> */}
    </div>
  );
}
