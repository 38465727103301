import { Formik, Form } from "formik";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  useUserNotificationSettingsQuery,
  useUserNotificationSettingsUpsertMutation,
} from "src/graphql/generated/schema";
import { notificationSettingFormType } from "../misc/notificationSettingFormType";
import { ReactNode } from "react";
import toast from "react-hot-toast";

const defaultSettings: notificationSettingFormType = {
  reportDurations: [],
  whatsappPhoneNumber: "",
  email: "",
  WhatsappBroadcastSettings: { isBusinessHealthNotificationEnabled: false },
  EmailBroadcastSettings: { isBusinessHealthNotificationEnabled: false },
  isCampaignNotificationEnabled: true,
  isCustomerNotificationEnabled: true,
  isBusinessHealthNotificationEnabled: false,
  isAudienceNotificationEnabled: true,
  isSettingsNotificationEnabled: true,
  isBillingNotificationEnabled: true,
  isAutomationNotificationEnabled: true,
  isReportNotificationEnabled: true,
  isPromotionalNotificationEnabled: true,
  isOrganizationTeamNotificationEnabled: true,
  isOrganizationNotificationEnabled: true,
};

export function NotificationSettingsForm({
  children,
}: {
  children: ReactNode;
}) {
  const { data, loading, error } = useUserNotificationSettingsQuery();
  const [userNotificationSettingsUpsert] =
    useUserNotificationSettingsUpsertMutation();

  if (loading) {
    return <Spinner height={500} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const formInitialValues = data?.userNotificationSettings
    ? {
        reportDurations: data.userNotificationSettings.reportDurations,
        whatsappPhoneNumber: data.userNotificationSettings.whatsappPhoneNumber,
        email: data.userNotificationSettings.email,
        WhatsappBroadcastSettings: {
          isBusinessHealthNotificationEnabled:
            data.userNotificationSettings.WhatsappBroadcastSettings
              ?.isBusinessHealthNotificationEnabled ?? false,
        },
        EmailBroadcastSettings: {
          isBusinessHealthNotificationEnabled:
            data.userNotificationSettings.EmailBroadcastSettings
              ?.isBusinessHealthNotificationEnabled ?? false,
        },
        isCampaignNotificationEnabled:
          data.userNotificationSettings.isCampaignNotificationEnabled,
        isCustomerNotificationEnabled:
          data.userNotificationSettings.isCustomerNotificationEnabled,
        isAudienceNotificationEnabled:
          data.userNotificationSettings.isAudienceNotificationEnabled,
        isSettingsNotificationEnabled:
          data.userNotificationSettings.isSettingsNotificationEnabled,
        isBillingNotificationEnabled:
          data.userNotificationSettings.isBillingNotificationEnabled,
        isBusinessHealthNotificationEnabled:
          data.userNotificationSettings?.isBusinessHealthNotificationEnabled,
        isAutomationNotificationEnabled:
          data.userNotificationSettings.isAutomationNotificationEnabled,
        isReportNotificationEnabled:
          data.userNotificationSettings.isReportNotificationEnabled,
        isPromotionalNotificationEnabled:
          data.userNotificationSettings.isPromotionalNotificationEnabled,
        isOrganizationTeamNotificationEnabled:
          data.userNotificationSettings.isOrganizationTeamNotificationEnabled,
        isOrganizationNotificationEnabled:
          data.userNotificationSettings.isOrganizationNotificationEnabled,
      }
    : defaultSettings;

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={async (values: notificationSettingFormType) => {
        const { errors } = await userNotificationSettingsUpsert({
          variables: {
            input: values,
          },
        });

        if (!errors) {
          toast.success("Successfully updated settings");
        } else {
          toast.error(errors[0].message || "Something Went Wrong!");
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
