import { Button } from "src/components/Button/Button";
import { Input } from "src/components/Input/Input";
import Modal from "src/components/Modal/Modal";
import Select from "src/components/Select/Select";
import styles from "./MapColumns.module.scss";

interface IAddFieldModal {
  openAddFieldModal: boolean;
  setOpenAddFieldModal: (i: boolean) => void;
}
export function AddFieldModal({
  openAddFieldModal,
  setOpenAddFieldModal,
}: IAddFieldModal) {
  return (
    <Modal
      maxWidth="600px"
      className={styles.modal}
      isOpen={openAddFieldModal}
      onClose={() => {
        setOpenAddFieldModal(false);
      }}
    >
      <div className={styles.modalHeader}>
        <div className={styles.modalTitle}>Create new field property</div>
        <div className={styles.modalSubtitle}>
          Give a name to newly added field property, also define it’s data type
          for better organization and analytics
        </div>
      </div>
      <div className={styles.modalForm}>
        <Input
          value=""
          onChange={(s) => {
            console.log(s);
          }}
        ></Input>
        <Select
          options={[]}
          value={""}
          onChange={function (i: any): void {
            throw new Error("Function not implemented.");
          }}
        />
        <Button width="full">Save Property</Button>
      </div>
    </Modal>
  );
}
