import { AutomationSettingsFooter } from "./AutomationSettingsFooter";
import { AutomationSidebarHeader } from "./AutomationSidebarHeader";
import { ReactComponent as CallSplit } from "@material-design-icons/svg/outlined/call_split.svg";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import { ReactComponent as EmailRead } from "@material-design-icons/svg/outlined/mark_email_read.svg";
import { FlowItems } from "src/pages/automations/builder/CreateAutomationPage/CreateAutomationPage";
import { ReactComponent as Mail } from "@material-design-icons/svg/outlined/mail.svg";
import { MenuItem } from "./MenuItem";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/outlined/more_vert.svg";
import { ReactComponent as Notifications } from "@material-design-icons/svg/outlined/notifications.svg";
import { ReactNode } from "react";
import { ReactComponent as Schedule } from "@material-design-icons/svg/outlined/schedule.svg";
import styles from "./AutomationRulesAndActions.module.scss";
import { useFormikContext } from "formik";

interface Rule {
  icon: ReactNode;
  title: String;
  value: FlowItems;
}

const rules: Rule[] = [
  {
    icon: <CallSplit />,
    title: "If/Else",
    value: {
      variant: "conditional",
      options: [],
      action: {
        label: <MoreVert />,
        onClick: () => {
          console.log("Hello");
        },
      },
      onClick: () => {},
    },
  },
  {
    icon: <Schedule />,
    title: "Delay",
    value: {
      variant: "info",
      children: <div>3 Hours</div>,
      icon: <Schedule />,
      action: {
        label: <MoreVert />,
        onClick: () => {
          console.log("Hello");
        },
      },
      onClick: () => {},
    },
  },
];

const actions = [
  {
    icon: <EmailRead />,
    value: {
      variant: "info",
      children: <div>Email</div>,
      icon: <Mail />,
      action: {
        label: <MoreVert />,
        onClick: () => {
          console.log("Hello");
        },
      },
      onClick: () => {},
    },
    title: "Send Email",
  },
  {
    icon: (
      <img
        src="/imagesAndIcons/SendSms.svg"
        alt="send_sms"
        width={24}
        height={24}
      />
    ),
    value: "",
    title: "Send SMS",
  },
  {
    icon: (
      <img
        src="/imagesAndIcons/SendWhatsapp.svg"
        alt="send_whatsapp"
        width={24}
        height={24}
      />
    ),
    value: "",
    title: "Send Whatsapp",
  },
  {
    icon: <Notifications />,
    title: "Send Notification",
    value: "",
  },
];

interface Props {
  addItem: any;
}

export function AutomationRulesAndActions({ addItem }: Props) {
  const { setFieldValue, values } = useFormikContext();
  return (
    <div>
      <AutomationSidebarHeader title="Rules" />
      <div className={styles.rules}>
        <ul>
          {rules.map((rule, index) => (
            <MenuItem
              key={index}
              title={rule.title}
              icon={rule.icon}
              action={{
                onClick: () => {
                  addItem(rule.value, values, setFieldValue);
                  setFieldValue("section", "EMPTY");
                },
                label: <ChevronRight />,
              }}
            />
          ))}
        </ul>
      </div>

      <div className={styles.actionsContainer}>
        <AutomationSidebarHeader title="Action" />
        <div className={styles.actions}>
          <ul>
            {actions.map((action, index) => (
              <MenuItem
                key={index}
                title={action.title}
                icon={action.icon}
                action={{
                  onClick: () => {
                    addItem(action.value, values, setFieldValue);
                    setFieldValue("section", "EMPTY");
                  },
                  label: <ChevronRight />,
                }}
              />
            ))}
          </ul>
        </div>
      </div>
      <AutomationSettingsFooter />
    </div>
  );
}
