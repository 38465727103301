import styles from "./EmailCname.module.scss";

const mxRecords = [
  {
    img: "/imagesAndIcons/MxRecords/1.svg",
    text: "Choose MX for the type of record.",
  },
  {
    img: "/imagesAndIcons/MxRecords/2.svg",
    text: "Look for a field labeled Name, Host, or Alias. Enter @",
  },
  {
    img: "/imagesAndIcons/MxRecords/3.svg",
    text: (
      <span>
        Add “<strong>mailhost1.example.com</strong>” into content
      </span>
    ),
  },
  {
    img: "/imagesAndIcons/MxRecords/4.svg",
    text: "Set Priority to 1 in Priority Field",
  },
];

const txRecords = [
  {
    img: "/imagesAndIcons/TxtRecords/1.svg",
    text: "Choose MX for the type of record.",
  },
  {
    img: "/imagesAndIcons/TxtRecords/2.svg",
    text: "Look for a field labeled Name, Host, or Alias. Enter @",
  },
  {
    img: "/imagesAndIcons/TxtRecords/3.svg",
    text: (
      <span>
        Add
        <strong>“@ 3600 IN TXT "v=spf1 a:mail.example.com -all”</strong>
        into content
      </span>
    ),
  },
  {
    img: "/imagesAndIcons/TxtRecords/4.svg",
    text: "Set Priority to 1 in Priority Field",
  },
];

export const CnameSteps = [
  {
    step: "Find your DNS records",
    children: [
      <div>
        <p>
          In a new tab, sign into your domain registrar with the account and
          password you used to buy your domain or to manage your website.
        </p>
        <div>
          <p>
            Go to the section where you can update your domain's MX records.
            This section should have one of the following labels:
          </p>
          <ul className={styles.boldList}>
            <li>
              <b>DNS settings or DNS management</b>
            </li>
            <li>
              <b>Mail settings</b>
            </li>
            <li>
              <b>Advanced settings</b>
            </li>
          </ul>
          <p>
            Important: If you have trouble finding MX records, contact your
            domain registrar for help.
          </p>
        </div>
      </div>,
    ],
  },
  {
    step: "Set up the MX records for Macro",
    children: [
      "Delete your existing MX records",
      "Click on create a new record.",
      <div>
        <p>Fill in the fields for the new MX record</p>
        <ul className={styles.recordImagesContainer}>
          {mxRecords.map((item, index) => (
            <li key={index} className={styles.recordImageItem}>
              <img src={item.img} />
              <span className={styles.recordImageText}>{item.text}</span>
            </li>
          ))}
        </ul>
      </div>,
      "Add another Record, follow the above step, but now this time add <strong>“mailhost2.example.com”<strong/> in content.",
    ],
  },
  {
    step: "Tell Macro to Verify",
    children: [
      "Return to the browser tab where you have the Macro Integration setup open. (You can reopen the tool if you closed it.)",
      "Click to verify records below",
    ],
  },
];

export const DomainSteps = [
  {
    step: "Find your DNS records",
    children: [
      <div>
        <p>
          In a new tab, sign into your domain registrar with the account and
          password you used to buy your domain or to manage your website.
        </p>
        <div>
          <p>
            Go to the section where you can update your domain's MX records.
            This section should have one of the following labels:
          </p>
          <ul className={styles.boldList}>
            <li>
              <b>DNS settings or DNS management</b>
            </li>
            <li>
              <b>Mail settings</b>
            </li>
            <li>
              <b>Advanced settings</b>
            </li>
          </ul>
          <p>
            Important: If you have trouble finding MX records, contact your
            domain registrar for help.
          </p>
        </div>
      </div>,
    ],
  },
  {
    step: "Set up the TXT records for Macro",
    children: [
      "Delete your existing TXT records",
      "Click on create a new record.",
      <div>
        <p>Fill in the fields for the new MX record</p>
        <ul className={styles.recordImagesContainer}>
          {txRecords.map((item, index) => (
            <li key={index} className={styles.recordImageItem}>
              <img src={item.img} />
              <span className={styles.recordImageText}>{item.text}</span>
            </li>
          ))}
        </ul>
      </div>,
      "Add another Record, follow the above step, but now this time add <strong>“mailhost2.example.com”<strong/> in content.",
    ],
  },
  {
    step: "Tell Macro to Verify",
    children: [
      "Return to the browser tab where you have the Macro Integration setup open. (You can reopen the tool if you closed it.)",
      "Click to verify records below",
    ],
  },
];
