import Card from "src/components/Card/Card";
import { InfoCard } from "../InfoCard/InfoCard";
import { Mobile } from "src/components/Mobile/Mobile";
import { SmsTemplateDetails } from "../../SmsTemplate/SmsTemplateDetails/SmsTemplateDetails";
import { WhatsappTemplateDetails } from "../../WhatsappTemplate/TemplateDetails/WhatsappDetailSection/WhatsappTemplateDetails";
import styles from "./AddTemplateDetails.module.scss";
import { useFormikContext } from "formik";

const whatsAppCard = {
  title:
    "Please note that it is now mandatory to provide samples while creating Message Templates.",
  description:
    "Samples are a way of providing an example of possible data for your template. This helps us during the review and approval process, so we can understand what kind of message you plan to send.",
  link: {
    label: "Learn more about templates",
    to: "#",
  },
};

const smsCard = {
  title: "Things to note before creating templates",
  description:
    "Ensure the template you are adding is approved in DLT. SMS sent using unapproved DLT templates will be blocked by operators",
  link: {
    label: "Learn more about SMS templates",
    to: "#",
  },
};

interface IAddTemplateDetails {
  templateType: "WHATSAPP" | "SMS";
  creationStep: number;
  setCreationStep: (i: number) => void;
}

export function AddTemplateDetails({
  templateType,
  creationStep,
  setCreationStep,
}: IAddTemplateDetails) {
  const { values } = useFormikContext<any>();
  return (
    <Card
      title={`Add Content on your ${
        templateType === "WHATSAPP" ? "Whatsapp" : "SMS"
      } Template`}
    >
      <InfoCard
        {...(templateType === "WHATSAPP"
          ? { ...whatsAppCard }
          : { ...smsCard })}
      />
      <div className={styles.container}>
        <div className={styles.detailsContainer}>
          {templateType === "WHATSAPP" ? (
            <WhatsappTemplateDetails
              creationStep={creationStep}
              setCreationStep={setCreationStep}
            />
          ) : (
            <SmsTemplateDetails
              creationStep={creationStep}
              setCreationStep={setCreationStep}
            />
          )}
        </div>
        <Mobile templateType={templateType} input={values} />
      </div>
    </Card>
  );
}
