import { Form, Formik } from "formik";
import { AdsetInput, CampaignInput } from "../misc/createCampaignTypes";
import { retargetingAudiences } from "../misc/retargetingAudiences";
import { RetargetAudiencesField } from "./RetargetAudiencesField";
import { SaveCampaignWizardFormState } from "./SaveCampaignWizardFormState";

interface IRetargetAudiencesForm {
  campaignPresetUpsert: (i: CampaignInput) => unknown;
  campaignInput: CampaignInput;
  handleBack: (i: CampaignInput) => unknown;
}

export function RetargetAudiencesForm({
  campaignInput,
  campaignPresetUpsert,
  handleBack,
}: IRetargetAudiencesForm) {
  const { selectedActivityIndex, Activities } = campaignInput;
  const selectedPresets =
    Activities[selectedActivityIndex].Adsets?.filter((a) => a.templateId).map(
      (a) => a.templateId
    ) ?? [];

  return (
    <Formik
      initialValues={{ selectedPresets: selectedPresets }}
      onSubmit={async (values) => {
        const input = JSON.parse(JSON.stringify(campaignInput));

        input.Activities[selectedActivityIndex].Adsets =
          values.selectedPresets.map((presetId) => {
            const audienceTemplate = retargetingAudiences.find(
              (r) => r.id === presetId
            );

            return {
              audienceId: null,
              budget: "",
              type: "PRODUCT_RETARGET",
              templateId: presetId,
              name: audienceTemplate.title,
              selected: true,
              ProductSetSpecs: audienceTemplate.productSetSpecs,
            } as AdsetInput;
          });

        await campaignPresetUpsert({
          ...input,
          currentStep: "setRetargetAudiencesBudget",
        });
      }}
    >
      <Form>
        <SaveCampaignWizardFormState />

        <RetargetAudiencesField handleBack={() => handleBack(campaignInput)} />
      </Form>
    </Formik>
  );
}
