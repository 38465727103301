import Card from "src/components/Card/Card";
import { CampaignCreateForm } from "./CampaignCreateForm";
import * as Yup from "yup";
import { Label } from "src/components/Label/Label";
import { getIn, useFormikContext } from "formik";
import { CampaignInput } from "../misc/createCampaignTypes";
import styles from "./ChooseMetaAdPlacementOptions.module.scss";
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import {
  CheckboxGroup,
  CheckboxOption,
} from "src/components/CheckboxGroup/CheckboxGroup";
import classNames from "classnames";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";
import { Button } from "src/components/Button/Button";
import { toggleArray } from "src/lib/toggleArray";

const ChooseMetaAdPlacementOptionSchema = Yup.object({
  adPlacementOptions: Yup.array(Yup.mixed().oneOf(["FACEBOOK", "INSTAGRAM"]))
    .required()
    .min(1),
});

export function ChooseMetaAdPlacementOptionsForm({
  campaignPresetUpsert,
  campaignInput,
  handleBack,
}: {
  campaignInput: CampaignInput;
  campaignPresetUpsert: (data: CampaignInput) => void;
  handleBack: (data: CampaignInput) => void;
}) {
  const selectedActivityIndex = campaignInput.selectedActivityIndex;
  return (
    <CampaignCreateForm
      campaignPresetUpsert={campaignPresetUpsert}
      campaignInput={campaignInput}
      schema={ChooseMetaAdPlacementOptionSchema}
    >
      <ChooseMetaAdPlacementOptions
        selectedActivityIndex={selectedActivityIndex}
        handleBack={handleBack}
        disabled={
          campaignInput.Activities[selectedActivityIndex].currentStep !==
          "chooseAdPlacementOptions"
        }
      />
    </CampaignCreateForm>
  );
}

const options = [
  {
    value: "FACEBOOK",
    image: Facebook,
    title: "Facebook",
    description: "Includes Feeds, Stories, Reels and In-Stream Ads in Facebook",
  },
  {
    image: Instagram,
    value: "INSTAGRAM",
    title: "Instagram",
    description:
      "Includes Feeds, Stories, Reels and In-Stream Ads in Instagram",
  },
];
function ChooseMetaAdPlacementOptions({
  selectedActivityIndex,
  handleBack,
  disabled,
}) {
  // const { setResetAlert, isCurrentNodeCompleted } = useWizardContext();
  const { values, isSubmitting, setFieldValue, errors, touched, isValid } =
    useFormikContext<CampaignInput>();

  const key = `Activities[${selectedActivityIndex}].adPlacementOptions`;
  const selectedAdPlacementOptions = getIn(values, key) || [];
  const fieldError = getIn(errors, key);
  const isFieldTouched = getIn(touched, key);

  return (
    <div>
      <Card disabled={disabled}>
        <Label
          htmlFor={key}
          label="Select one or multiple platform where you want to run ads?"
        >
          <CheckboxGroup
            name={key}
            value={selectedAdPlacementOptions}
            onChange={(val) => {
              setFieldValue(key, val);
            }}
          >
            <div className={styles.container}>
              {options.map((v, index) => (
                <MultiSelectCollectionItem
                  image={v.image}
                  selected={selectedAdPlacementOptions.includes(v.value)}
                  subtitle={v.description}
                  title={v.title}
                  value={v.value}
                  key={index}
                  onChange={(value) => {
                    setFieldValue(
                      key,
                      toggleArray(selectedAdPlacementOptions, value)
                    );
                  }}
                />
              ))}
            </div>
          </CheckboxGroup>

          {isFieldTouched && fieldError && (
            <FormFieldStatus type="error" message={fieldError} />
          )}
        </Label>

        <div className={styles.btnGroup}>
          <Button loading={isSubmitting} disabled={!isValid} type="submit">
            Save and Continue
          </Button>
          <Button
            color="subdued"
            style="outline"
            type="button"
            onClick={() => handleBack(values)}
          >
            Back
          </Button>
        </div>
      </Card>
    </div>
  );
}

function MultiSelectCollectionItem({
  image,
  title,
  subtitle,
  value,
  selected,
  imageHeight = 60,
  imageWidth = 60,
  onChange,
}) {
  return (
    <CheckboxOption
      value={value}
      className={classNames(styles.collectionItem, {
        [styles.collectionItemChecked]: selected,
      })}
    >
      <img src={image} alt={image} width={imageWidth} height={imageHeight} />
      <div className={styles.collectionItemTextContainer}>
        <div className={classNames(styles.title)}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.actionWrapper}>
        <Checkbox
          checked={selected}
          value={value}
          onChange={(s) => {
            onChange(value);
          }}
        />
      </div>
    </CheckboxOption>
  );
}
