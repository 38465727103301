import Select from "src/components/Select/Select";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import styles from "./WhatsappTemplateButton.module.scss";
import { IconButton } from "src/components/IconButton/IconButton";
import { FieldArray } from "formik";
import { WhatsappFormInput } from "src/pages/CreateTemplate/CreateMessageTemplateTypes";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { buttonType } from "./WhatsappTemplateButtons";
import { AddWhatsappItemButton } from "./AddWhatsappItemButton";

interface ICtasInput {
  values: WhatsappFormInput;
}
export function CtasInput({ values }: ICtasInput) {
  return (
    <FieldArray
      name="buttons.ctas"
      render={(arrayHelpers) => (
        <>
          <div className={styles.ctaContainer}>
            {values.buttons?.ctas?.map((item, index) => (
              <div className={styles.btnTypeTile} key={index}>
                <Select
                  options={buttonType}
                  value={item?.type}
                  onChange={(v) => {
                    arrayHelpers.replace(index, {
                      type: v,
                      url: null,
                      text: null,
                      phone: null,
                    });
                  }}
                />
                <InputFormik
                  name={`buttons.ctas[${index}].text`}
                  placeholder="Button Label"
                />
                {/* <Select options={[]} value={""} onChange={() => {}} /> */}
                <InputFormik
                  name={
                    item?.type === "URL"
                      ? `buttons.ctas[${index}].url`
                      : `buttons.ctas[${index}].phone`
                  }
                />
                <IconButton
                  icon={<Close className={styles.addIco} />}
                  style="plain"
                  color="subdued"
                  onClick={() => {
                    arrayHelpers.remove(index);
                  }}
                />
              </div>
            ))}
          </div>
          {values?.buttons?.ctas.length < 2 && (
            <AddWhatsappItemButton
              ctaType={values.buttons.type}
              onClick={() => {
                arrayHelpers.push({
                  type: "URL",
                  url: "",
                });
              }}
            />
          )}
        </>
      )}
    />
  );
}
