import { useState } from "react";
import { ReactComponent as SearchIcon } from "@material-symbols/svg-600/rounded/search.svg";
import { ReactComponent as CancelIcon } from "@material-symbols/svg-600/rounded/close.svg";
import styles from "./Searchbar.module.scss";
import classNames from "classnames";

export interface ISearchbar {
  value: string;
  onChange: (inputValue: string) => unknown;
  disabled?: boolean;
  input?: {
    placeholder?: string;
  };
}

export function Searchbar({ value, onChange, input, disabled }: ISearchbar) {
  const [isInputVisible, setIsInputVisible] = useState(value ? true : false);

  return (
    <div className={classNames(styles.container)}>
      {isInputVisible ? (
        <div className={styles.inputWrapper}>
          <SearchIcon fill="var(--color-disabled)" width={16} height={16} />
          <input
            type="search"
            autoFocus={true}
            className={styles.input}
            value={value}
            placeholder={input?.placeholder}
            onBlur={() => {
              if (!value) {
                setIsInputVisible(false);
              }
            }}
            onChange={(e) => onChange(e.target.value)}
          />
          <button
            className={classNames(styles.cancelButton, {
              [styles.buttonHidden]: !value,
            })}
            onClick={() => {
              // reset on change
              onChange("");
              setIsInputVisible(false);
            }}
            type="button"
          >
            <CancelIcon fill="var(--color-disabled)" width={16} height={16} />
          </button>
        </div>
      ) : (
        <button
          disabled={disabled}
          className={classNames(styles.searchButton, {
            [styles.searchDisabledButton]: disabled,
          })}
          type="button"
          onClick={() => setIsInputVisible(true)}
        >
          <SearchIcon fill="var(--color-disabled)" width={16} height={16} />
        </button>
      )}
    </div>
  );
}
