import { Button } from "../Button/Button";
import { ReactNode } from "react";
import styles from "./EmptyState.module.scss";
import classNames from "classnames";

export interface IEmptyState {
  action?: {
    title: string;
    onClick?: () => void;
    link?: string;
    loading?: boolean;
    disabled?: boolean;
    [x: string]: any;
  };
  children?: ReactNode;
  title: ReactNode;
  illustration?:
    | string
    | {
        source: string;
        height: string | number;
        width: string | number;
      };
  image?: React.ReactNode;
  /** Container Class name */
  containerCls?: string;
}

export default function EmptyState({
  children,
  title,
  image,
  illustration,
  action,
  containerCls,
}: IEmptyState) {
  return (
    <div className={classNames(styles.container, containerCls)}>
      {illustration && (
        <img
          className={styles.illustration}
          src={
            typeof illustration === "string"
              ? illustration
              : illustration.source
          }
          width={typeof illustration === "string" ? 110 : illustration.width}
          height={typeof illustration === "string" ? 110 : illustration.height}
        />
      )}
      {image}
      <div className={styles.title}>{title}</div>
      {children}
      {action && (
        <div className={styles.actionWrapper}>
          <Button
            {...action}
            size="small"
            to={action?.link}
            onClick={action?.onClick}
            loading={action?.loading}
            disabled={action?.disabled}
          >
            {action.title}
          </Button>
        </div>
      )}
    </div>
  );
}
