import * as Yup from "yup";

import { Form, Formik } from "formik";

import { Button } from "src/components/Button/Button";
import { EmailSent } from "../components/EmailSent";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { Link } from "react-router-dom";
import { ReactComponent as Mail } from "@material-design-icons/svg/outlined/mail.svg";
import classNames from "classnames";
import styles from "./PasswordRecovery.module.scss";
import { usePasswordUpdateInitiateMutation } from "src/graphql/generated/schema";
import { useState } from "react";
import toast from "react-hot-toast";
import Banner from "src/components/Banner/Banner";

export const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
});

export function PasswordRecovery() {
  const [passwordUpdateInitiateFunc, { loading, data }] =
    usePasswordUpdateInitiateMutation();
  const [showError, setShowError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  function generateContentBasedOnErrorCode(error: string) {
    if (error === "USER_NOT_FOUND") {
      return "Provided email was not found. Make sure the user is registered";
    }
    return "Unknown error occurred";
  }

  return (
    <>
      {showError && data && data?.passwordUpdateInitiate?.userError && (
        <Banner
          className={styles.errorBanner}
          isOpen={showError}
          onClose={() => {
            setShowError(false);
          }}
          content={generateContentBasedOnErrorCode(
            data?.passwordUpdateInitiate?.userError?.code,
          )}
          type="error"
        />
      )}
      {emailSent ? (
        <EmailSent
          email={email}
          resendMail={async () => {
            try {
              await passwordUpdateInitiateFunc({
                variables: { input: { email: email } },
              });
              toast.success("Reset password email resent");
            } catch (e) {
              toast.error(e.message);
            }
          }}
        />
      ) : (
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Password Recovery</h2>
          <p className={styles.subtitle}>
            We will send you password recovery link.
          </p>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={schema}
            onSubmit={async (values) => {
              try {
                const { data } = await passwordUpdateInitiateFunc({
                  variables: {
                    input: {
                      email: values.email,
                    },
                  },
                });

                if (data?.passwordUpdateInitiate?.userError) {
                  setShowError(true);
                  return;
                }
                setEmail(values.email);
                setEmailSent(true);
                setShowError(false);
              } catch (e) {
                toast.error(e.message);
              }
            }}
          >
            {({ errors }) => (
              <Form>
                <div
                  className={classNames(styles.input, {
                    [styles.inputError]: errors.email,
                  })}
                >
                  <InputFormik
                    placeholder="Enter your email"
                    icon={Mail}
                    type="email"
                    name="email"
                  />
                </div>
                <Button
                  type="submit"
                  width="full"
                  loading={loading}
                  disabled={loading}
                >
                  Continue
                </Button>
                <div className={styles.orWrapper}>
                  <hr />
                  <span className={styles.or}>or</span>
                </div>
                <p className={styles.loginBack}>
                  <span>Back to </span>
                  <Link to="/auth/login" className={styles.login}>
                    Login
                  </Link>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
