import styles from "./MenuItem.module.scss";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";

interface Props {
  icon?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
  action?: {
    onClick: () => void;
    label: React.ReactNode;
  };
}

export function MenuItem({ icon, title, description, action }: Props) {
  return (
    <li className={styles.menuItem}>
      <div className={styles.menuIcon}>{icon}</div>
      <div className={styles.menuText}>
        <h5 className={styles.menuTitle}>{title}</h5>
        <p className={styles.menuDescription}>{description}</p>
      </div>
      {action && (
        <div className={styles.action}>
          <button type="button" onClick={action.onClick}>
            {action.label}
          </button>
        </div>
      )}
    </li>
  );
}
