export function transformPerTickInsights(
  arr: { actionCount: number; action: string; tickDate: string }[]
): Array<Record<string, any>> {
  let transformedObj = {};

  if (!arr) return [];

  for (let item of arr) {
    if (transformedObj[item.tickDate]) {
      transformedObj[item.tickDate] = {
        ...transformedObj[item.tickDate],
        [item.action]: item.actionCount,
      };
    } else {
      transformedObj[item.tickDate] = {
        tickDate: item.tickDate,
        [item.action]: item.actionCount,
      };
    }
  }

  return Object.values(transformedObj);
}
