import Alert from "src/components/Alert/Alert";
import { Organization } from "../../../graphql/generated/schema";

interface IDeleteOrganizationAlert {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
  onDelete: () => void;
  loading?: boolean;
}

export function DeleteOrganizationAlert({
  isOpen,
  onClose,
  onDelete,
  organization,
  loading = false,
}: IDeleteOrganizationAlert) {
  return (
    <Alert
      title="Deletion request"
      primaryAction={{
        loading: loading,
        onClick: onDelete,
        children: "Raise Delete Request",
        color: "danger",
        size: "small",
      }}
      isOpen={isOpen}
      onClose={onClose}
      secondaryAction={{
        children: "Cancel",
        color: "subdued",
        style: "outline",
        onClick: onClose,
        size: "small",
      }}
    >
      <p
        style={{
          color: "var(--color-subdued)",
          fontSize: "0.875rem",
        }}
      >
        This is to confirm that you want to proceed with the deletion of the
        organisation "{organization.name}". Keep in mind this operation is
        irreversible and will result in a complete deletion of all the data
        associated with the organisation. If you understand above then after
        confirmation a support agent will contact you and will proceed with
        deletion.
      </p>
    </Alert>
  );
}
