import { CampaignInput, FormSteps } from "../misc/createCampaignTypes";
import { ActivityListFields } from "./ActivityListFields";
import { Button } from "src/components/Button/Button";
import Card from "src/components/Card/Card";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import styles from "./ReviewForm.module.scss";
import { useFormikContext } from "formik";
import { useState } from "react";
import { ScheduleModal } from "./ScheduleModal";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import ButtonOptions from "src/components/ButtonOptions/ButtonOptions";

interface Props {
  setActiveFormStep: (v: FormSteps) => any;
  addNewActivity: () => Promise<unknown>;
  showUpgradePlan: boolean;
  handleBack: () => void;
}

export function ReviewForm({
  setActiveFormStep,
  addNewActivity,
  showUpgradePlan,
  handleBack,
}: Props) {
  const { setFieldValue, handleSubmit, isSubmitting, values, isValid } =
    useFormikContext<CampaignInput>();
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const selectedActivityIndex = values.selectedActivityIndex;

  return (
    <>
      <ScheduleModal
        activities={values.Activities}
        showScheduleModal={showScheduleModal}
        setShowScheduleModal={setShowScheduleModal}
        onPublish={(date) => {
          setShowScheduleModal(false);
          setFieldValue(
            `Activities[${selectedActivityIndex}].scheduledAt`,
            date
          );
          handleSubmit();
        }}
      />

      {/* <Card> */}
      {/*   <section> */}
      {/*     <h1 className={styles.pageTitle}>Basic Details</h1> */}
      {/**/}
      {/*     <div className={styles.campaignFields}> */}
      {/*       <InputFormik name="campaignName" label="Campaign Name" /> */}
      {/*       <InputFormik name="objective" label="Objective" disabled /> */}
      {/*     </div> */}
      {/*   </section> */}
      {/* </Card> */}

      <section className={styles.activitySection}>
        <ActivityListFields setActiveFormStep={setActiveFormStep} />
      </section>

      <StickyFooter
        actions={
          !showUpgradePlan ? (
            <>
              <Button
                size="small"
                style="outline"
                color="subdued"
                type="button"
                onClick={async () => {
                  // const previousStep =
                  //   values.Activities[selectedActivityIndex].platform;
                  // const previousStepMapper: Record<
                  //   ActivityPlatformEnum,
                  //   FormSteps
                  // > = {
                  //   META: "createFacebookAd",
                  //   EMAIL: "createEmail",
                  //   TEXT_MESSAGE: "createSms",
                  //   GOOGLE: "choosePlatformAndActivityName",
                  //   WHATSAPP: "createWhatsapp",
                  //   PUSH_NOTIFICATION: "choosePlatformAndActivityName",
                  // };
                  // await setActiveFormStep(previousStepMapper[previousStep]);
                  handleBack();
                }}
              >
                Back
              </Button>
              <Button
                size="small"
                style="outline"
                color="subdued"
                onClick={addNewActivity}
              >
                Add New Activity
              </Button>
              <ButtonOptions
                position="TOP_OF_BUTTON"
                menuClass={styles.publishMenu}
                color="primary"
                size="small"
                className={styles.publishBtn}
                menuBtnClass={styles.menuBtn}
                items={[
                  {
                    children: "Save As Draft",
                    onClick: () => {
                      setFieldValue("publishAfterSave", false);
                      handleSubmit();
                    },
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                  {
                    children: "Schedule Campaign",
                    onClick: () => {
                      setShowScheduleModal(true);
                      // setFieldValue("publishAfterSave", false);
                      // handleSubmit();
                    },
                    disabled: isSubmitting,
                    loading: isSubmitting,
                  },
                ]}
                onClick={() => {
                  setFieldValue("publishAfterSave", true);
                  handleSubmit();
                }}
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
              >
                Publish
              </ButtonOptions>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setFieldValue("publishAfterSave", false);
                  handleSubmit();
                }}
                loading={isSubmitting}
                size="small"
              >
                Upgrade Plan
              </Button>
            </>
          )
        }
        label={
          showUpgradePlan ? (
            <p>
              Don't worry your activity will be “Saved as Draft”. You can visit
              anytime and resume your journey
            </p>
          ) : (
            <p>
              By clicking "Publish", you agree to Macro's{" "}
              <span className={styles.footerText}>
                Terms and Advertising Guidelines
              </span>
              .
            </p>
          )
        }
      />
    </>
  );
}
