import { Button } from "src/components/Button/Button";
import styles from "./AudienceNameSection.module.scss";
import { Form, Formik } from "formik";
import { AudienceUseDefaultSettingsCheckbox } from "./AudienceUseDefaultSettingsCheckbox";
import { useContext } from "react";
import { StepWizardContext } from "../../global/misc/StepWizardContext";
import classNames from "classnames";
import * as Yup from "yup";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required").max(50),
  useDefaultMetaAccounts: Yup.bool().required(),
  pageId: Yup.string().required("Meta page id is required"),
  adAccountId: Yup.string().required("ad account id is required"),
  instagramAccountId: Yup.string().required("instagram account id is required"),
  pixelId: Yup.string(),
});

export function AudienceNameSection() {
  const { activeStepIndex, markStepAsDone } = useContext(StepWizardContext);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: "",
        useDefaultMetaAccounts: false,
        pageId: "",
        adAccountId: "",
        instagramAccountId: "",
        pixelId: "",
      }}
      onSubmit={(values) => {
        markStepAsDone(1, values);
      }}
    >
      {({ dirty, values, setFieldValue }) => {
        return (
          <Form>
            <div
              className={classNames(styles.nameSectionWrapper, {
                [styles.sectionWrapperActive]: activeStepIndex >= 1,
              })}
            >
              <div className={styles.nameSection}>
                <h2 className={styles.heading}>Get Started</h2>
                <p className={styles.subheading}>
                  Name your audience so that you can find and manage them later
                </p>

                <div className={styles.inputWrapper}>
                  <InputFormik name="name" label="Audience Name" />
                  <AudienceUseDefaultSettingsCheckbox />
                </div>

                <ButtonV2
                  // disabled={activeStepIndex !== 0 && !dirty}
                  type="submit"
                >
                  Save & Continue
                </ButtonV2>
              </div>
              <div className={styles.separatorWrapper}>
                <hr className={styles.separator} />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
