import Tooltip from "src/components/Tooltip/Tooltip";
import styles from "./AdTemplateCreateForm.module.scss";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-600/rounded/info.svg";
import { Button } from "../../../../components/Button/Button";
import { AdTemplateFacebookSettingsModal } from "./AdTemplateFacebookSettingsModal";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useMetaIntegrationDefaultSettingsQuery } from "../../../../graphql/generated/schema";
import classNames from "classnames";
import { FormValues } from "../../misc/adTemplateCreateTypes";

export function AdTemplateUseDefaultSettingsCheckBox() {
  const { values, setValues } = useFormikContext<FormValues>();
  const { data, loading } = useMetaIntegrationDefaultSettingsQuery();
  const [showFacebookSettingsModal, setShowFacebookSettingsModal] =
    useState(false);

  useEffect(() => {
    if (!data) return;
    // if already set
    if (values.adAccountId) return;

    if (data) {
      setValues({
        ...values,
        adAccountId: data.metaIntegrationDefaultSettings.metaAdAccountId,
        pageId: data.metaIntegrationDefaultSettings.metaPageId,
        metaPageConnectedInstagramBusinessAccountId:
          data.metaIntegrationDefaultSettings?.MetaPage
            ?.metaPageConnectedInstagramBusinessId,
        pixelId: data.metaIntegrationDefaultSettings.metaPixelId,
        useDefaultSettings: true,
      });
    }
  }, [data, setValues, values]);

  return (
    <div
      className={classNames(styles.defaultSettingsCheckbox, {
        [styles.loading]: loading,
      })}
    >
      <div className={styles.titleWithIcon}>
        <span>Using Default Account Settings</span>
        <Tooltip
          children={<InfoIcon width={16} height={16} />}
          supportingText={"YOLO"}
        />
      </div>
      <Button
        width="auto"
        style="plain"
        color="primary"
        size="micro"
        loading={loading}
        disabled={loading}
        onClick={() => {
          setShowFacebookSettingsModal(true);
        }}
      >
        Change Settings?
      </Button>

      <AdTemplateFacebookSettingsModal
        isOpen={showFacebookSettingsModal}
        onClose={() => setShowFacebookSettingsModal(false)}
        initialFormValues={{
          adAccountId: values.adAccountId || "",
          metaPageConnectedInstagramBusinessAccountId:
            values.metaPageConnectedInstagramBusinessAccountId || "",
          pageId: values.pageId || "",
          pixelId: values.pixelId || "",
          instagramAccountId: values.instagramPageId || "",
        }}
        handleSubmit={(v) => {
          setValues({
            ...values,
            metaPageConnectedInstagramBusinessAccountId:
              v.metaPageConnectedInstagramBusinessAccountId,
            pageId: v.pageId,
            pixelId: v.pixelId,
            adAccountId: v.adAccountId,
          });
          setShowFacebookSettingsModal(false);
        }}
      />
    </div>
  );
}
