import { LineItems } from "./types";

interface Result {
  message: string;
  lineItemIndex: number;
  assetType: "StoryAsset" | "PostAsset";
}

export function checkPlacementsInLineItems(
  lineItems: LineItems[]
): Result | null {
  // confirm if all LineItems has placement if one LineItem has placement
  const oneHasPlacement = lineItems.some((v) => v.StoryAsset);
  if (oneHasPlacement) {
    const allHavePlacements = lineItems.every(
      (v) => v.StoryAsset && v.PostAsset
    );

    if (!allHavePlacements) {
      const index = lineItems.findIndex((v) => !v.StoryAsset || !v.PostAsset);
      const itemWithoutAsset = lineItems[index];
      return {
        // detect which asset is missing
        assetType: itemWithoutAsset?.PostAsset ? "StoryAsset" : "PostAsset",
        lineItemIndex: index,
        message: "Item does not have a placement while others have",
      };
    }
  }

  return null;
}
