import { useCallback, useRef } from "react";
import debounce from "lodash.debounce";

// https://stackoverflow.com/questions/59183495/cant-get-lodash-debounce-to-work-properly-executed-multiple-times-reac
export const useDebouncedCallback = <T, U>(
  callback: (arg0: T, ...arg1: Array<U>) => void,
  delay: number,
  deps = []
): ((arg0: T, ...arg1: Array<U>) => void) => {
  const callbackRef = useRef<(arg0: T, ...arg1: Array<U>) => void>();
  callbackRef.current = callback;

  return useCallback(
    debounce((...args) => {
      if (callbackRef.current) {
        callbackRef.current(args[0], ...args.slice(1));
      }
    }, delay),
    deps
  );
};
