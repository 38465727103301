import AudienceCard from "src/components/AudienceItem/AudienceCard";
import { Segment } from "src/graphql/generated/schema";
import format from "date-fns/format";
import styles from "./ReceiveMailSegment.module.scss";
import segmentsIcon from "../assets/segmentsIcon.svg";

interface Props {
  setShowAddRecipient: (i: boolean) => void;
  segments: Segment[];
}

export function ReceiveMailSegment({ setShowAddRecipient, segments }: Props) {
  return (
    <div>
      <div className={styles.section}>
        <div className={styles.sectionText}>
          <p className={styles.sectionTitle}>Segments to receive emails</p>
          {/* <Button
            style="plain"
            color="primary"
            type="button"
            onClick={() => setShowAddRecipient(true)}
          >
            Edit recipients
          </Button> */}
        </div>
        <hr />
      </div>
      <div className={styles.options}>
        {segments.map((segment, index) => (
          <AudienceCard
            name="segments"
            onBlur={() => {}}
            checked={false}
            onChange={() => {}}
            image={segmentsIcon}
            title={segment.name}
            value={segment.id}
            subtitle={`Created on: ${format(new Date(), "dd MMM, yy")}`}
            key={index}
            noCheck={true}
          />
        ))}
      </div>
    </div>
  );
}
